import React from 'react';
import DynamicForm from '../DynamicForm/DynamicForm';
import LoginLinks from './LoginLinks';

function LoginEmail({ onSubmit, submitting, email }) {
  const LoginEmailForm = {
    name: 'Login Email Form',
    order: ['email', 'submit'],
    fields: {
      email: {
        type: 'email',
        label: 'Email',
        validations: ['required', 'email'],
        defaultValue: email,
        disabled: submitting,
      },
      submit: {
        type: 'button',
        color: 'pink',
        loading: submitting,
        label: 'Log In',
        disabled: submitting,
        className: 'w-full grid justify-items-center',
      },
    },
  };
  return (
    <>
      <h1 className="my-8 text-2xl font-black text-center md:text-3xl lg:text-5xl">Login</h1>
      <DynamicForm customClassName="text-base" form={LoginEmailForm} onSubmit={onSubmit} />
      <LoginLinks omitForgotPasswordLink omitRegisterLink />
    </>
  );
}

export default LoginEmail;
