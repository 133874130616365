import React from 'react';
import RemoteDynamicForm from '../../components/DynamicForm/RemoteDynamicForm';
import { APP_FORM_IDS } from '../../constants';

const modifyForm = (form) => {
  const modifiedForm = { ...form };
  modifiedForm.fields.submit.style = {
    borderRadius: '2px',
    backgroundColor: '#E2037A',
    color: '#fff',
    padding: '0.625rem 1.5rem',
    lineHeight: '1.25rem',
    fontSize: '0.875rem',
    margin: '1rem 0',
  };
  return modifiedForm;
};

function CancellationSurvey({ cancelSubscription }) {
  const onSubmit = (formPermalink, response, data) => {
    cancelSubscription(data);
  };
  return (
    <div className="pt-12 pb-8">
      <h2 className="mb-4 text-2xl	text-black">Insider Pro Cancellation Survey</h2>
      <RemoteDynamicForm formId={APP_FORM_IDS.CIP.CANCELLATION} formPermalink="cancellation-survey" modifyForm={modifyForm} onSubmit={onSubmit} hideToast />
      <p className="mt-4 text-sm">
        Submitting this survery will <span className="font-bold">cancel</span> your Insider Pro subscription.
      </p>
    </div>
  );
}

export default CancellationSurvey;
