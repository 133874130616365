import React, { useMemo, useState, useCallback } from 'react';
import { CircularProgressBar } from '@tomik23/react-circular-progress-bar';
import { useToggle } from 'usehooks-ts';
import Icon from '../../Icon/Icon';
import FormatUtil from '../../../utils/formatUtil';
import Collapsible from '../../Collapsible/Collapsible';
import If from '../../If/If';
import { useCybAssessmentAnalytics } from '../../../providers/CybAssessmentAnalyticsProvider';
import CybraryAssessmentsRadarChart from '../../Dashboard/CybraryAssessmentsWidget/CybraryAssessmentsRadarChart';
import CollapsibleTriggerIcon from '../../Collapsible/CollapsibleTriggerIcon';

function getScoreColor(score) {
  if (score === 1) {
    return 'bg-lime-500';
  }
  if (score >= 0.5) {
    return 'bg-yellow-500';
  }
  return 'bg-red-500';
}

function TrackProgress({ percent = 0 }) {
  const circleProps = {
    percent,
    colorSlice: '#ffffff',
    colorCircle: '#cccccc',
    size: 50,
    stroke: 8,
    strokeBottom: 8,
    speed: 120,
    fill: 'transparent',
    unit: '',
    animationOff: false,
    inverse: false,
    round: true,
    number: false,
    linearGradient: ['#fc58ae', '#cf287f'],
  };
  return (
    <div className="flex flex-row justify-center items-center">
      <div className="relative">
        <CircularProgressBar {...circleProps}>
          <div className="flex absolute top-0 left-0 justify-center items-center w-full h-full">
            <Icon name="trophy" className="p-2 my-2 w-10 h-10 text-black fill-current" />
          </div>
        </CircularProgressBar>
      </div>
    </div>
  );
}

function ScoreBadge({ score = 0 }) {
  return <div className="py-1 px-2 w-28 text-sm font-semibold text-center rounded-md border-1 border-pink-600">Score: {Math.round(score * 100, 10)}%</div>;
}

function TrackAssessmentResultsRow({ title, score, itemsCorrect = 0, itemsTotal = 0, children }) {
  const scoreClassName = useMemo(() => getScoreColor(score), [score]);
  return (
    <div className="flex grow items-center p-4 w-full">
      <div className="flex flex-col gap-4 justify-between items-center w-full md:flex-row md:gap-0">
        <div className="flex flex-row items-center md:w-2/5">
          <div className={`mr-3 w-4 h-4 ${scoreClassName} rounded-full shrink-0`} />
          <span className="text-sm font-semibold">{title}</span>
        </div>
        <If condition={itemsTotal > 0}>
          <span className="text-sm font-normal">
            {itemsCorrect}/{itemsTotal} Questions
          </span>
        </If>
        <ScoreBadge score={score} />
      </div>
      <div className="w-8">{children}</div>
    </div>
  );
}

export function TrackAssessmentActivity({ assessment }) {
  const activities = assessment?.children?.[0].children;
  // Sort activities so that "Knowledge Check" activities are always first
  activities.sort((a, b) => {
    const aIsKnowledgeCheck = a['content-name'].includes('Knowledge Check');
    const bIsKnowledgeCheck = b['content-name'].includes('Knowledge Check');
    if (aIsKnowledgeCheck && !bIsKnowledgeCheck) {
      return -1;
    }
    if (!aIsKnowledgeCheck && bIsKnowledgeCheck) {
      return 1;
    }
    return 0;
  });

  return activities?.map((activity, i) => {
    const isLastItem = i === activities.length - 1;
    /**
     * Use regex to extract "Skill Check" or "Knowledge Check" from the activity title
     * Fallback to the activity title if it doesn't match
     * Fallback to "Assessment" if the activity title is undefined
     */
    const activityType = activity['content-name'].match(/(Skill|Knowledge) Check/)?.[0] || activity['content-name'] || 'Assessment';
    return (
      <>
        <TrackAssessmentResultsRow
          key={activity['content-id']}
          title={activityType}
          score={activity.score}
          itemsCorrect={activity['items-correct']}
          itemsTotal={activity['items-total']}
        />
        {!isLastItem && <div className="flex grow w-5/6 h-[1px] bg-neutral-500" />}
      </>
    );
  });
}

function TrackAssessment({ assessment, index, total, isOpen }) {
  const [open, toggleOpen] = useToggle();
  const [hasOpened, setHasOpened] = useState(isOpen);
  const isLastItem = useMemo(() => index === total - 1, []);
  const onOpening = useCallback(() => setHasOpened(true), []);

  return (
    <div className={`flex flex-col p-4 rounded-sm border border-neutral-600 ${!isLastItem && 'mb-4'}`} key={assessment.id}>
      <TrackAssessmentResultsRow title={assessment['content-topic']} score={assessment.score}>
        <div className="ml-3">
          <CollapsibleTriggerIcon name={assessment['content-topic']} toggleOpen={toggleOpen} open={open} />
        </div>
      </TrackAssessmentResultsRow>
      <Collapsible
        triggerTagName="div"
        open={open}
        className="cursor-default"
        openedClassName="cursor-default"
        transitionTime={200}
        ariaLabel={assessment['content-topic']}
        onOpening={onOpening}
        collapsibleId={FormatUtil.lowerCaseHyphenText(assessment['content-topic'])}
      >
        <If condition={hasOpened}>
          <div className="flex flex-col grow items-center mt-4 bg-white rounded-sm">
            <TrackAssessmentActivity assessment={assessment} />
          </div>
        </If>
      </Collapsible>
    </div>
  );
}

function AssessmentSummaryTrack() {
  const { currentTrack } = useCybAssessmentAnalytics();
  const { description } = currentTrack || {};
  const trackProgressPercent = Math.round((currentTrack['items-correct'] / currentTrack['items-total']) * 100);
  // Use regex to remove "Assessment Track -" from the track title
  const trackTitle = currentTrack['content-name'].replace(/Assessment Track - /, '');
  return (
    <div className="flex flex-col w-full">
      {/* Header */}
      <div className="flex flex-row justify-between items-center p-4 mb-4 w-full rounded-sm border border-neutral-600">
        <p className="mb-0 text-2xl font-bold">{trackTitle}</p>
        <TrackProgress percent={trackProgressPercent} />
      </div>
      {/* Description */}
      <p>
        {/** Show description from contentful if we have one */}
        <If condition={!!description}>{description}</If>
        {/** Otherwise show default copy */}
        <If condition={!description}>
          Track Your Progress for the <span className="font-semibold">{trackTitle} assessment path</span>. This path is designed to assess your knowledge and skills in core{' '}
          {trackTitle} topics. You are encouraged to re-take each assessment as needed to improve your score over time.
        </If>
      </p>
      {/* Related Track Assessments / Radar Graph */}
      <div className="flex space-x-6 w-full min-h-[24rem] md:flex-row">
        <div className="flex flex-col grow mb-4 md:w-1/2">
          {currentTrack.children.map((assessment, index) => (
            <TrackAssessment assessment={assessment} index={index} total={currentTrack.children.length} key={assessment['content-id']} />
          ))}
        </div>
        <div className="flex justify-center items-center w-full h-[30rem] rounded-sm border border-neutral-600 md:p-3 md:w-1/2">
          <div className="relative px-8 w-full h-full max-h-[24rem]">
            {/** IMPORTANT - pointLables = label surrounding the radar chart. These chartOptions increease font-size only on results page */}
            <CybraryAssessmentsRadarChart containerClasses="min-h-[24rem]" chartOptions={{ scale: { pointLabels: { fontSize: 14 } } }} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AssessmentSummaryTrack;
