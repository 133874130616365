import React from 'react';
import Icon from '../Icon/Icon';
import Label from '../Label/Label';

function SelectionChip({ text, handleClose }) {
  if (!text) {
    return null;
  }
  return (
    <Label color="black" className="flex items-center p-2 text-xs rounded-sm border-0">
      <span>{text}</span>
      {!!handleClose && (
        <button className="ml-2" onClick={handleClose} aria-label={`Remove ${text} selection`}>
          <Icon name="x" className="w-4 h-4" />
        </button>
      )}
    </Label>
  );
}

export default SelectionChip;
