import moment from 'moment';
import axios from 'axios';
import UserStore from '../stores/userStore';
import AuthStore from '../stores/authStore';

// The name of our event cache in localstorage
const cacheName = 'ecache';
const attributionApiBaseURL = `${process.env.REACT_APP_ATTRIBUTION_URL}`;

// Get the cache key for the current user
const getCacheKey = () => {
  const { user } = UserStore;
  return user && user.id ? `u_${user.id}` : null;
};

// Get the full cache object, should basically always consist of 0 or 1 items keyed by user id
const getFullCache = () => {
  return window.localStorage[cacheName] ? JSON.parse(window.localStorage[cacheName]) : {};
};

// Cache an event, adding it to the array of events for the current user
export const cacheEvent = (event, videoId, contentDescriptionId, data) => {
  const key = getCacheKey();
  if (!key) {
    // We can't cache events when there's no user present
    throw new Error('Cannot access cache without a user');
  }
  const fullCache = getFullCache();
  const cache = fullCache[key] || [];
  // We have the full cache and the current cache array at this point, let's append to it
  const time = moment().unix();
  cache.push({
    __time: time,
    event,
    video_id: videoId,
    content_description_id: contentDescriptionId,
    ...data,
  });
  // Now update the full cache in localstorage
  fullCache[key] = cache;
  window.localStorage[cacheName] = JSON.stringify(fullCache);
};

// Make the actual request to send off the bulk events
export const batchAttributionEvents = (data) => {
  return new Promise((resolve) => {
    // Don't fire if we don't have an attribution URL, shouldn't really happen
    if (process.env.REACT_APP_ATTRIBUTION_URL) {
      const uuid = AuthStore.getAttributionUuid();
      AuthStore.getGeoInfo()
        .then((geo) => {
          AuthStore.getToken().then((token) => {
            const countryCode = geo && geo.countryCode ? geo.countryCode : 'US';
            const eventData = {
              location: countryCode,
              data,
              tracking_id: uuid,
            };
            // Removing a circular dependency by just directly calling the attribution post
            axios({
              method: 'post',
              url: `${attributionApiBaseURL}/events/batch`,
              data: eventData,
              headers: { Authorization: `Bearer ${token}` },
            })
              .then(() => {
                resolve(true);
              })
              .catch(() => {
                resolve(false);
              });
          });
        })
        .catch(() => {
          resolve(false);
        });
    } else {
      resolve(false);
    }
  });
};

// Send the cache to the batch attribution events handler, and clear it out locally
export const flushCache = async () => {
  // Grab the cache
  const cache = getFullCache();
  // Clear out the cache
  window.localStorage.removeItem(cacheName);

  const key = getCacheKey();
  const userEvents = cache && cache[key] ? cache[key] : [];
  if (userEvents && userEvents.length) {
    // Send the events to the server
    await batchAttributionEvents(userEvents);
  }
};
