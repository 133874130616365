import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import VisibilitySensor from 'react-visibility-sensor';
import queryString from 'query-string';
import moment from 'moment';

import './styles.css';

import Agents from '../../agents/agents';

import ContentfulUtil from '../../utils/contentfulUtil';
import EnrollmentUtil from '../../utils/enrollmentUtil';
import SearchUtil from '../../utils/searchUtil';
import StyleUtil from '../../utils/styleUtil';

import CareerPathOverview from './DynamicCollectionOverview';
import CareerPathOutline from './DynamicCollectionOutline';
import FAQ from '../../components/CareerPaths/Faqs';

import CircularProgress from '../../components/ProgressBar/CircularProgress';
import Container from '../../components/Container/Container';
import ContentHero from '../../components/BrowsePromo/ContentHero';
import ContentTabs from '../../components/ContentTabs/ContentTabs';
import EnrollmentButton from '../../components/BrowsePromo/EnrollmentButton';
import FeedbackModal from '../../components/Feedback/FeedbackModal';
import Icon from '../../components/Icon/Icon';
import Loading from '../../components/Loading/Loading';
import StickyContentTabs from '../../components/ContentTabs/StickyContentTabs';
import withRouter from '../../components/Router/withRouter';
import FormatUtil from '../../utils/formatUtil';

function getCourseCardImg(item) {
  let courseCardImg = 'https://images.ctfassets.net/kvf8rpi09wgk/7o0j1vNbZ201f4n6lhRV2O/8da23ca4ad01c4c7cccd0a0cfd65afb1/soc_background.jpg?w=1000';
  if (item.thumbnail_url) {
    courseCardImg = `${item.thumbnail_url}?w=1000`;
  }
  return courseCardImg;
}

function StickyContentTabCTA({ comingSoon, type, item, courseProgress, handleEnroll }) {
  return !comingSoon ? <EnrollmentButton type={type} handleEnroll={handleEnroll} progress={courseProgress} item={item} buttonClasses="w-full lg:w-auto" /> : null;
}
const DynamicCollectionPage = inject(
  'commonStore',
  'catalogStore',
  'certificateStore',
  'userStore',
  'enrollmentStore',
  'feedbackStore',
  'authStore'
)(
  observer(
    class DynamicCollectionPage extends Component {
      constructor(props) {
        super(props);

        this.state = {
          queryID: '',
          objectID: '',
          careerPath: null,
          careerPathCardItems: null,
          loading: true,
          activeTab: 'Overview',
          isStickyTabActive: false,
        };
      }

      componentDidMount() {
        const { item } = this.props.catalogStore;

        const { permalink, id } = item;
        this.props.commonStore.setPageTitle(`${item.title} | Cybrary`);
        this.props.authStore.fireAttributionEvent();
        this.props.feedbackStore.setContentDescriptionId(item.id);
        this.setSearchInsightParams();
        this.fetchFromContentful(permalink);
        this.fetchCareerPathCardItems(id);
      }

      componentDidUpdate(prevProps) {
        if (!!prevProps.location && prevProps.location.search) {
          const oldQueryParms = queryString.parse(prevProps.location.search);
          const { queryID, objectID } = oldQueryParms;
          if (queryID !== this.state.queryID || objectID !== this.state.objectID) {
            const newState = { ...this.state };
            newState.queryID = queryID;
            newState.objectID = objectID;
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState(newState);
          }
        }
      }

      componentWillUnmount() {
        const newState = { ...this.state };
        newState.queryID = '';
        newState.objectID = '';
        this.setState(newState);
      }

      fetchCareerPathCardItems = (contentDescriptionId) => {
        // fetch content for content cards of careerPath with user progress
        Agents.enrollments.getChildProgress(contentDescriptionId, '?unenrolledProgress=1').then((response) => {
          if (response) {
            const newState = { ...this.state, careerPathCardItems: response };
            this.setState(newState);
          }
        });
      };

      getStartDate = () => {
        const d = moment();
        // Show tomorrow's date
        return d.add(1, 'days').format('MMMM Do');
      };

      fetchFromContentful = (permalink) => {
        ContentfulUtil.getByPermalink(`${permalink}`, 'contentCollection').then((response) => {
          if (response) {
            this.setCareerPath(response);
          }
        });
      };

      setCareerPath = (response) => {
        const careerPath = !!response && response.items && response.items[0] && response.items[0].fields;
        if (careerPath) {
          const newState = { ...this.state, careerPath, loading: false };
          this.setState(newState);
        }
      };

      setSearchInsightParams = () => {
        const { location } = this.props;
        const { search } = location;
        if (search) {
          const queryParms = queryString.parse(search);
          const { queryID, objectID } = queryParms;
          if (!!queryID && !!objectID) {
            const newState = { ...this.state };
            newState.queryID = queryID;
            newState.objectID = objectID;
            this.setState(newState);
          }
        }
      };

      handleEnroll = (item, data) => {
        const { queryID, objectID } = this.state;
        if (!!queryID && !!objectID) {
          // send algolia search conversion event on enrollment
          SearchUtil.searchInsightEvent(this.props.userStore.user.id, queryID, objectID);
        }
        EnrollmentUtil.handleStartEnroll(this.props.userStore, this.props.enrollmentStore, this.props.commonStore, this.props.navigate, item, data);
      };

      handleInitialEnroll = () => {
        const { item } = this.props.catalogStore;
        this.handleEnroll(item);
      };

      setIsStickyTabActive = (isVisible) => {
        this.setState({ isStickyTabActive: !isVisible });
      };

      handleSectionInView = (isVisible, tab) => {
        let activeTab = 'Overview';

        if (tab === 'Outline' && isVisible) {
          activeTab = 'Outline';
        }

        if (tab === 'FAQ') {
          activeTab = isVisible ? 'FAQ' : 'Outline';
        }
        const newState = {
          activeTab,
        };
        this.setState(newState);
      };

      setActiveTab = (tabName) => {
        this.setState({
          activeTab: tabName,
        });
      };

      render() {
        if (this.state.loading) {
          return (
            <Container>
              <Loading message="Loading..." />
            </Container>
          );
        }

        const { item } = this.props.catalogStore;

        // Career Path data coming from our CMS Contentful
        const { subtitle, nickname, othernames, catalogRedirect, shortDescription, heroDescription } = this.state.careerPath;
        // Item for the career path coming from backend service
        const { content_item, content_type } = item;
        const type = content_type.nice_name;
        const { meta } = content_item;
        const syllabus = meta ? meta.syllabus : null;
        const courseCardImage = getCourseCardImg(item);
        const metaImage = meta && !!meta.backgroundImage ? meta.backgroundImage : courseCardImage;
        const bgStyles = {
          backgroundImage: `url("${metaImage}")`,
          backgroundSize: `cover`,
        };
        const { authStore, userStore, enrollmentStore } = this.props;
        const { userType } = userStore;
        const typeStyle = StyleUtil.getTypeStyle(type);

        const comingSoon = !!item && !!item.status ? item.status === 'Coming Soon' : false;
        const bookmarkCount = !!item && !!item.bookmarks_count ? item.bookmarks_count : 0;
        const ratingsAverage = !!item && !!item.average_rating ? item.average_rating : 0;
        const ratingsCount = !!item && !!item.rating_count ? item.rating_count : 0;
        const enrollmentCount = !!item && !!item.enrollment_count ? item.enrollment_count : 0;
        const { courseProgress } = this.props.certificateStore;

        const contentProgress = courseProgress ? courseProgress.content_progress : '';
        const percentCompleted = contentProgress ? contentProgress.percent_completed : '';
        const progressEvent = contentProgress ? contentProgress.progress_event : '';
        const started = progressEvent && progressEvent === 'started';

        const isBookmarked = Array.isArray(this.props.userStore.bookmarks) ? this.props.userStore.bookmarks.indexOf(item.id) !== -1 : false;
        const careerPathSubTitle = heroDescription || subtitle;

        const contentTabs = [
          {
            name: 'Overview',
            scrollSelector: '#career-path-overview',
          },
          {
            name: 'Outline',
            scrollSelector: '#career-path-outline',
          },
        ];

        if (this.state.careerPath?.faqs) {
          contentTabs.push({
            name: 'FAQ',
            scrollSelector: '#career-path-faq',
          });
        }

        const displayClass = started ? 'block' : 'hidden';
        return (
          <>
            <Container omitPadding>
              <div style={bgStyles}>
                <div className="bg-black bg-gradient-to-r from-black lg:bg-black/60">
                  <Container size="md-1164">
                    <VisibilitySensor onChange={this.setIsStickyTabActive} partialVisibility>
                      <ContentHero
                        title={item.title}
                        type={item.content_type.nice_name}
                        nickName={nickname}
                        otherNames={othernames}
                        catalogRedirect={catalogRedirect}
                        typeStyle={typeStyle}
                        id={item.id}
                        comingSoon={comingSoon}
                        difficulty={item.level.name}
                        syllabus={syllabus}
                        handleEnroll={this.handleInitialEnroll}
                        careerPathSubTitle={careerPathSubTitle}
                        item={item}
                        progress={courseProgress}
                        certificateStore={this.props.certificateStore}
                        userType={userType}
                        license={{ is_free: !!item.is_free }}
                        feedbackStore={this.props.feedbackStore}
                        isBookmarked={isBookmarked}
                        bookmarkCount={bookmarkCount}
                        ratingsAverage={ratingsAverage}
                        ratingsCount={ratingsCount}
                        enrollmentCount={enrollmentCount}
                        getStartDate={this.getStartDate}
                        subTitle={subtitle}
                        style={{ padding: '2rem 0rem' }}
                        enrollmentStore={enrollmentStore}
                        authStore={authStore}
                        userStore={userStore}
                        commonStore={this.props.commonStore}
                        careerPathPage
                        time={FormatUtil.formatTime(item.duration_seconds, 'hm')}
                        ceu={item.ceu_count}
                      />
                    </VisibilitySensor>
                  </Container>
                </div>
              </div>
              {this.state.isStickyTabActive ? (
                <StickyContentTabs
                  image={this.state.careerPath?.image?.fields?.file?.url}
                  displayClassName="hidden md:block"
                  item={item}
                  progress={courseProgress}
                  title={item.title}
                  type={item.content_type.nice_name}
                  commonStore={this.props.commonStore}
                  tabs={contentTabs}
                  setActiveTab={this.setActiveTab}
                  activeTab={this.state.activeTab}
                  backButtonUrl="/browse/refined"
                  permalink={item.permalink || ''}
                  buttons={<StickyContentTabCTA comingSoon={comingSoon} type={type} item={item} courseProgress={courseProgress} handleEnroll={this.handleInitialEnroll} />}
                  circularProgress={
                    <CircularProgress classes={`${displayClass} ml-4 w-12 h-12 mx-auto`} strokeWidth={4} percentageValue={percentCompleted}>
                      <Icon name="trophy" className="mx-auto w-6 h-6 text-gray-600" />
                    </CircularProgress>
                  }
                />
              ) : null}
              <Container size="md-1164">
                <ContentTabs wrapperClasses="mt-12 lg:mb-8 border-t-xs md:border-t-0" tabs={contentTabs} setActiveTab={this.setActiveTab} activeTab={this.state.activeTab} />
                <div id="career-path-overview" className="mt-12 scroll-mt-64 lg:mt-0">
                  <CareerPathOverview
                    contentfulCareerPathData={this.state.careerPath}
                    data={item}
                    shortDescription={shortDescription}
                    userType={userType}
                    isEnterpriseUser={this.props.userStore.isEnterprise}
                  />
                </div>
                <VisibilitySensor onChange={(isVisible) => this.handleSectionInView(isVisible, 'Outline')} partialVisibility>
                  {/* added the div b/c ScrollIntoView errors out if you click outline too soon b/c of the early return in CareerPathOutline */}
                  <div id="career-path-outline" className="scroll-mt-32">
                    <CareerPathOutline contents={this.state.careerPathCardItems} />
                  </div>
                </VisibilitySensor>

                {Boolean(this.state.careerPath?.faqs) && (
                  <VisibilitySensor onChange={(isVisible) => this.handleSectionInView(isVisible, 'FAQ')} partialVisibility>
                    <div id="career-path-faq" className="scroll-mt-32">
                      <FAQ careerPathFaqs={this.state.careerPath?.faqs} className="my-12" />
                    </div>
                  </VisibilitySensor>
                )}
              </Container>
            </Container>
            <FeedbackModal />
          </>
        );
      }
    }
  )
);

export default withRouter(DynamicCollectionPage);
