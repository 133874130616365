import { TEAMS_NAV } from './enterprisePrimaryNavItems';
import { TEAMS_DEMO_NAV } from './nonEnterprisePrimaryNavItems';
import { CAREER_PROGRAMS_NAV, MEASURE_NAV, DASHBOARD_NAV, CERT_PREP_PROGRAMS_NAV, STAY_AHEAD_NAV, FORUMS_NAV } from './sharedPrimaryNavItems';

export default {
  label: 'Navigation',
  /* 
    These are the primary navigation items with icons. 
    Click into the parent primary nav item to see the secondary navigation or "tertiary" navigation items 
    related to its parent 
  */
  items: [DASHBOARD_NAV, TEAMS_NAV, TEAMS_DEMO_NAV, MEASURE_NAV, FORUMS_NAV, CAREER_PROGRAMS_NAV, CERT_PREP_PROGRAMS_NAV, STAY_AHEAD_NAV],
};
