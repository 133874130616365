/* eslint-disable no-console */
import Guacamole from 'guacamole-common-js';
import { Event } from '../agents';
import { isFirefox } from '../utils/helpers';

export default class GuacConnection {
  client;

  tunnel;

  connectionString = '';

  mouse;

  keyboard;

  input = '';

  userData;

  error;

  withCredentials;

  ONENTER = 65293;

  constructor(url, userData) {
    this.withCredentials = process.env.REACT_APP_BASE_URL !== process.env.REACT_APP_CLAB_API_URL;
    const tunnel = new Guacamole.WebSocketTunnel(url);
    this.tunnel = tunnel;
    this.client = new Guacamole.Client(tunnel);
    this.userData = userData;
  }

  /**
   * Convert param object to string
   * @param params
   */
  // private paramsToString(params: ConnectionParams) {
  paramsToString(params) {
    this.connectionString = params;
  }

  /**
   * Connect to Guac and set the mouse and keyboard
   * @param params
   */
  // public connect(params: ConnectionParams) {
  connect(params, ip, errorHandler) {
    console.log(new Date(), 'CALLING CONNECT', params, ip);
    this.paramsToString(params);
    this.client.connect(this.connectionString);

    const displayElement = this.client.getDisplay().getElement();
    this.mouse = new Guacamole.Mouse(displayElement);

    const el = document.getElementById(ip);
    this.keyboard = new Guacamole.Keyboard(el);

    this.tunnel.onerror = (error) => {
      this.error = error;
      if (errorHandler) {
        errorHandler(error.message);
      }
    };

    this.client.onerror = (error) => {
      this.error = error;
      if (errorHandler) {
        errorHandler(error.message);
      }
    };

    this.client.onclipboard = (stream, type) => {
      if (type === 'text/plain') {
        const reader = new Guacamole.StringReader(stream);
        let str = '';
        reader.ontext = (newText) => {
          str = `${str}${newText}`;
        };
        reader.onend = () => {};
      }
    };
  }

  /**
   * Start mouse event listener
   */
  startMouseListener() {
    const adjustMouseState = (mouseState, xOffset) => {
      // If this is firefox, just return the mouseState, there's no offset needed
      if (isFirefox()) {
        return mouseState;
      }
      return {
        ...mouseState,
        x: mouseState.x - xOffset,
        y: mouseState.y - 64,
      };
    };

    this.mouse.onmousedown = (mouseState) => {
      const xOffset = window.innerWidth - this.client.getDisplay().getElement().offsetWidth;
      this.client.sendMouseState(adjustMouseState(mouseState, xOffset));
    };
    this.mouse.onmouseup = (mouseState) => {
      const xOffset = window.innerWidth - this.client.getDisplay().getElement().offsetWidth;
      this.client.sendMouseState(adjustMouseState(mouseState, xOffset));
    };
    this.mouse.onmousemove = (mouseState) => {
      const xOffset = window.innerWidth - this.client.getDisplay().getElement().offsetWidth;
      this.client.sendMouseState(adjustMouseState(mouseState, xOffset));
    };
  }

  saveKeyBoardEvents() {
    const keyEvent = { ...this.userData, keyValue: this.input, created: Date.now(), keyCode: this.ONENTER };
    Event.saveUserKeyEvent(keyEvent);
    this.input = '';
  }

  /**
   * Start keyboard event listener
   */
  startKeyboardListener() {
    this.keyboard.onkeydown = (keysym) => {
      this.client.sendKeyEvent(1, keysym);
    };

    // On enter, save user input
    this.keyboard.onkeyup = (keysym) => {
      if (keysym === this.ONENTER) {
        this.saveKeyBoardEvents();
      } else {
        this.input += String.fromCharCode(keysym);
      }
      this.client.sendKeyEvent(0, keysym);
    };
  }

  /**
   * Resize guacamole terminal
   * @param containerRef
   * @returns
   */
  resizeTerminal(containerElement) {
    if (!containerElement) {
      return;
    }
    const width = Math.round(containerElement.clientWidth);
    const height = Math.round(containerElement.clientHeight);
    this.client.sendSize(width, height);
  }

  /**
   * Send text to the guac clipboard
   */
  sendToClipboard(value) {
    const stream = this.client.createClipboardStream('text/plain');
    const writer = new Guacamole.StringWriter(stream);
    writer.sendText(value);
    writer.sendEnd();
  }
}
