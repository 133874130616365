import React from 'react';
import moment from 'moment';
import Loading from '../Loading/Loading';
import StyledError from '../Error/StyledError';
import Container from '../Container/Container';
import CircularProgress from '../ProgressBar/CircularProgress';
import PracticeTestDisplayLabel from './PracticeTestDisplayLabel';
import ResultsSummary from '../AdminTable/ResultsSummary';
import OptionsMenu from '../OptionsMenu/OptionsMenu';
import GoalsUtil from '../../utils/goalsUtil';
import FormatUtil from '../../utils/formatUtil';
import Check from '../../Icons/Check';
import RecurringGoalIcon from './RecurringGoalIcon';

function GoalDueDate({ goal }) {
  if (!goal || !goal.due_date) {
    return null;
  }

  return (
    <p className="flex items-center mb-0 text-sm text-gray-600">
      {`Due: ${moment(goal.due_date).format('MM/DD/YYYY')}`}
      {goal.due_type === 'recurring' && <RecurringGoalIcon />}
    </p>
  );
}

function GoalStatusAction({ goal, continueLearning }) {
  if (!goal) {
    return null;
  }

  if (goal.status === 'in_progress') {
    return (
      <button className="text-sm text-cyb-pink-500 hover:text-black underline cursor-pointer" onClick={() => continueLearning(goal)}>
        {goal.progress && goal.progress.percent_completed ? 'Continue' : 'Start'} Learning
      </button>
    );
  }
  const labelClasses = 'uppercase rounded-sm text-white text-xs px-2 py-1 flex items-center';

  if (goal.status === 'expired') {
    return <div className={`${labelClasses} bg-red-600 items-center`}>Expired</div>;
  }

  if (goal.status === 'completed') {
    return (
      <div className={`${labelClasses} bg-green-700`}>
        <Check classes="w-4 h-4 mr-2" />
        Completed
      </div>
    );
  }

  return null;
}

function OptionsMenuWrapper({ menuOptions }) {
  return menuOptions && menuOptions.length ? (
    <div className="ml-4 md:ml-8">
      <OptionsMenu menuClasses="right-0" options={menuOptions} alignRight />
    </div>
  ) : (
    <div className="hidden ml-4 md:block md:ml-8 md:w-10" />
  );
}

function getMenuOptions(goal, viewGoal, setTrackingGoal, canTrackGoal) {
  if (!goal) {
    return [];
  }
  const options = [
    {
      text: 'View info',
      action: () => viewGoal(goal.id),
    },
  ];
  if (canTrackGoal) {
    options.push({
      text: 'Set as achievement tracker',
      action: () => setTrackingGoal(goal),
    });
  }
  return options;
}

function GoalOutcomeDisplay({ goal, metric, practiceTestStatus }) {
  if (!goal || !metric) {
    return null;
  }
  const outcomeLabels = GoalsUtil.getTileOutcomeDisplay(goal, metric, practiceTestStatus);

  return (
    <>
      <p className="mb-0 text-sm text-gray-600">Goal: {outcomeLabels.targetOutcome}</p>
      <p className="mb-0 text-sm text-gray-600">
        {metric !== 'certification' ? 'Completed: ' : ''}
        {outcomeLabels.currentProgress}
      </p>
    </>
  );
}

function GoalsTiles({ data, viewGoal, continueLearning, setTrackingGoal, trackingGoal, loading, error }) {
  if (loading) {
    return (
      <Container>
        <Loading message="Loading..." />
      </Container>
    );
  }
  if (error) {
    return (
      <Container>
        <StyledError error={error} />
      </Container>
    );
  }

  if (!data || !data.length) {
    return <p>You have no goals to display at this time.</p>;
  }

  return (
    <div>
      <ResultsSummary numOfResults={data.length} srOnly />
      {data.map((goal) => {
        const goalHasPracticeTest = goal.progress && 'score' in goal.progress;
        const trackedGoal = !!trackingGoal && trackingGoal.id && trackingGoal.id === goal.id;
        const canTrackGoal = !trackedGoal && goal.status === 'in_progress';
        const goalMetric = GoalsUtil.getPrimaryGoalMetric(goal);
        return (
          <div key={goal.id} className="flex flex-col items-center py-4 px-8 mb-4 rounded-sm border border-gray-400 cursor-pointer md:flex-row">
            <button className="flex flex-col mb-4 md:flex-row md:items-center md:mb-0 md:w-3/4" onClick={() => viewGoal(goal.id)}>
              <div className="hidden overflow-hidden md:block" style={{ width: '129px' }}>
                <CircularProgress classes="w-32" strokeWidth={8} percentageValue={goal.percent_completed ? Math.floor(goal.percent_completed) : 0}>
                  {goalHasPracticeTest ? <PracticeTestDisplayLabel passed={1 * goal.percent_completed === 100} omitText /> : null}
                </CircularProgress>
              </div>
              <div className="flex flex-col flex-1 md:ml-8 xl:flex-row xl:ml-16">
                <div className="mb-6 xl:mb-0 xl:w-1/2">
                  <div className="text-left">
                    <h2 className="mb-6 font-bold md:mb-2" style={{ maxWidth: '40ch' }}>
                      {goal.name}
                    </h2>
                    <div className="md:w-64">
                      <GoalOutcomeDisplay metric={goalMetric} goal={goal} practiceTestStatus={GoalsUtil.getPracticeTestStatus(goal)} />
                      {goal.start_date ? <p className="mb-0 text-sm text-gray-600">{`Start: ${moment(goal.start_date).format('MM/DD/YYYY')}`}</p> : null}
                      <GoalDueDate goal={goal} />
                    </div>
                  </div>
                </div>
                <div className="xl:w-1/2">
                  {goal.description ? <div className="text-sm text-left text-gray-600">{FormatUtil.formatLongText(goal.description, 180)}</div> : null}
                </div>
              </div>
            </button>
            <div className="flex justify-between w-full md:justify-end md:w-1/4">
              <GoalStatusAction goal={goal} continueLearning={continueLearning} />
              <OptionsMenuWrapper menuOptions={getMenuOptions(goal, viewGoal, setTrackingGoal, canTrackGoal)} />
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default GoalsTiles;
