import React, { forwardRef, useEffect } from 'react';
import { twMerge } from 'tailwind-merge';
import useUniqueKey from '../../hooks/useUniqueKey';
import Icon from '../Icon/Icon';
import useCarousel from '../../hooks/useCarousel';
import Header from '../Header/Header';
import If from '../If/If';

/**
 * @param {React.Component} children - children of the carousel
 * @param {string} classes - (optional) additional classes to be added to the carousel
 * @returns carousel base
 */
function CarouselBase({ children, classes, id }) {
  if (!children) return null;

  const carouselBaseClasses = twMerge('flex flex-col w-full carousel-base', classes);
  return (
    <div id={id} className={carouselBaseClasses}>
      {children}
    </div>
  );
}

/**
 * @param {array} items - items to be displayed in the carousel should be an array of react components
 * @param {string} classes - (optional) additional classes to be added to the items container
 */
const CarouselItemsContainer = forwardRef(function CarouselItemsContainer({ items = [], classes = '' }, ref) {
  if (!items?.length) return null;
  const wrapperClasses = twMerge('carousel-items-container flex overflow-hidden relative z-0 gap-2 scroll-smooth snap-x snap-mandatory touch-pan-x', classes);
  const uniqueKey = useUniqueKey();

  return (
    <div id={`carousel-item-wrapper-${uniqueKey}`} className={wrapperClasses} ref={ref} role="region" aria-live="polite">
      {items.map((item, index) => {
        const key = `carousel-item-${uniqueKey}-${index}`;
        return (
          <div id={key} key={key} className={classes}>
            {item}
          </div>
        );
      })}
    </div>
  );
});

/**
 * Plug-and-play carousel component. Takes in various options and returns a fully built carousel component.
 * You can also build a custom carousel with custom controls,
 * using the useCarousel hook and the provided compound components
 * @param {array} items - items to be displayed in the carousel should be an array of react components
 * @param {string} title - title of the carousel section (optional)
 * @param {string} description - description of the carousel section (optional)
 * @param {React.Component} customHeaderJsx - custom jsx to be added to the header (optional)
 * @param {boolean} autoScroll - boolean to enable auto scroll (default false)
 * @param {number} autoScrollInterval - interval for auto scroll (optional) (default 5000)
 * @param {array} actions - actions to be added to the header. This should be an array of react component(s)
 * @param {boolean} showNav - boolean to show the navigation buttons (default true)
 * @param {string} className - (optional) additional classes to be added to the carousel
 * @param {function} onClose - function to be called when the carousel is closed. If not null, X is shown in top right. (optional) (default null)
 * @returns default carousel component
 */
function Carousel({
  items = [],
  noItemsText = 'No items to display',
  noItemsJsx,
  title = '',
  description = '',
  customHeaderJsx,
  autoScroll = false,
  autoScrollInterval,
  actions = [],
  showNav = true,
  className,
  headerWrapperClassName,
  onClose,
}) {
  const { moveNext, movePrev, isPrevDisabled, isNextDisabled, carouselItemWrapper, refresh, resetCurrentIndex } = useCarousel({ autoScroll, autoScrollInterval });
  const uniqueKey = useUniqueKey();
  const baseClasses = twMerge('w-full', className);
  const headerWrapperClasses = twMerge('carousel-header flex flex-col-reverse md:flex-row justify-between w-full mb-4', headerWrapperClassName);
  useEffect(() => {
    refresh();
    resetCurrentIndex();
  }, [items]);
  return (
    <CarouselBase id={`carousel-${uniqueKey}`} classes={baseClasses}>
      {/** Header */}
      <div className={headerWrapperClasses}>
        <div className="grow">
          {!!title && (
            <Header as="h2" className="mb-1">
              {title}
            </Header>
          )}
          {!!description && <p className="mb-1 text-sm text-gray-600">{description}</p>}
          {!!customHeaderJsx && customHeaderJsx}
        </div>
        <div className="text-right">
          {!!onClose && (
            <button className="pr-2 mb-4 cursor-pointer" aria-label="Dismiss carousel" onClick={() => onClose()}>
              <Icon name="x" />
              <span className="sr-only">Close carousel</span>
            </button>
          )}
          <div className="flex justify-evenly w-full">
            <div className="grow">{actions}</div>
            <If condition={!!showNav}>
              <div className="flex ml-2">
                <button
                  aria-label="See previous items"
                  className="text-gray-600 disabled:opacity-50"
                  disabled={isPrevDisabled}
                  onClick={movePrev}
                  title={!isPrevDisabled && 'Previous'}
                >
                  <Icon name="chevron-left" />
                  <span className="sr-only">Prev</span>
                </button>
                <button aria-label="See next items" className="text-gray-600 disabled:opacity-50" disabled={isNextDisabled} onClick={moveNext} title={!isNextDisabled && 'Next'}>
                  <Icon name="chevron-right" />
                  <span className="sr-only">Next</span>
                </button>
              </div>
            </If>
          </div>
        </div>
      </div>
      {/** Body */}
      <If condition={items.length}>
        <CarouselItemsContainer items={items} ref={carouselItemWrapper} />
      </If>
      {/** No items text */}
      <If condition={!items?.length}>
        {/** If no items jsx is provided, show that instead of the default text */}
        <If condition={noItemsJsx}>{noItemsJsx}</If>
        {/** If no items jsx is not provided, show the default text */}
        <If condition={!noItemsJsx}>
          <p className="w-full text-xl text-center">{noItemsText}</p>
        </If>
      </If>
    </CarouselBase>
  );
}

export default Carousel;
