import React, { createContext, useContext, useState, useMemo, useCallback } from 'react';
import Agents from '../agents/agents';
import BugsnagUtil from '../utils/bugsnagUtil';
import useSandboxCookie from '../hooks/cookies/useSandboxCookie';
import DEMO_ASSESSMENT_PATH_TOPIC_BREAKDOWN from '../pages/Baseline/Demo/Reports/assessment-path-topic-breakdown.json';
import DEMO_ASSESSMENT_PATHS_OVERVIEW from '../pages/Baseline/Demo/Reports/assessment-paths-overview.json';
import DEMO_ASSESSMENT_PATHS_OVERVIEW_TABLE from '../pages/Baseline/Demo/Reports/assessment-paths-overview-table.json';
import DEMO_ASSESSMENT_PATHS_LEARNERS from '../pages/Baseline/Demo/Reports/assessment-path-learners.json';
import DEMO_ASSESSMENT_PATHS_LEARNERS_TABLE from '../pages/Baseline/Demo/Reports/assessment-path-learners-table.json';
import DEMO_ASSESSMENT_PATH_SBPI from '../pages/Baseline/Demo/Reports/assessment-path-score-breakdown-per-interval.json';
import DEMO_ASSESSMENT_PATH_ISPI from '../pages/Baseline/Demo/Reports/assessment-path-individual-scores.json';
import DEMO_CUMULATIVE_ATPI from '../pages/Baseline/Demo/Reports/cumulative-assessment-team-per-interval.json';
import DEMO_CUMULATIVE_ATL from '../pages/Baseline/Demo/Reports/cumulative-assessment-team-learners.json';
import DEMO_CUMULATIVE_ATL_TABLE from '../pages/Baseline/Demo/Reports/cumulative-assessment-team-learners-table.json';
import DEMO_ASSESSMENT_PATH_ISA from '../pages/Baseline/Demo/Reports/assessment-path-individual-attempts.json';

const CybAssessmentPathContext = createContext();

/**
 * Cybrary Assessment Path Provider
 *
 * Provider to interface with teams assessment paths.
 * @param {*} children - React children
 * @returns <CybAssessmentPathProvider.Provider />
 */
function CybAssessmentPathProvider({ children }) {
  const { isBaselineSandboxEnabled } = useSandboxCookie();
  // array of assessment paths from api
  const [assessmentPaths, setAssessmentPaths] = useState([]);
  // index of assessment path topic breakdowns
  const [topicBreakdowns, setTopicBreakdowns] = useState({});
  // index of assessment path learners
  const [learners, setLearners] = useState({});
  // index of assessment path score distributions
  const [scoreDistributions, setScoreDistributions] = useState({});
  // index of assessment path overviews
  const [overviews, setOverviews] = useState({});
  // index of cumulative assessment summaries
  const [cumulativeSummaries, setCumulativeSummaries] = useState({});
  // index of cumulative assessment learners
  const [cumulativeLearners, setCumulativeLearners] = useState({});
  // index of cumulative scores
  const [cumulativeScores, setCumulativeScores] = useState({});
  // index of cumulative scores for a single user
  const [cumulativeUserScores, setCumulativeUserScores] = useState({});
  // index of cumulative attempts for a single user
  const [cumulativeUserAttempts, setCumulativeUserAttempts] = useState({});
  // loading states
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTopicBreakdown, setIsLoadingTopicBreakdown] = useState(false);
  const [isLoadingLearners, setIsLoadingLearners] = useState(false);
  const [isLoadingScoreDistributions, setIsLoadingScoreDistributions] = useState(false);
  const [isLoadingOverview, setIsLoadingOverviews] = useState(false);
  const [isLoadingCumulativeLearners, setIsLoadingCumulativeLearners] = useState(false);
  const [isLoadingCumulativeSummaries, setIsLoadingCumulativeSummaries] = useState(false);
  const [isLoadingCumulativeScores, setIsLoadingCumulativeScores] = useState(false);
  const [isLoadingCumulativeUserScores, setIsLoadingCumulativeUserScores] = useState(false);
  const [isLoadingCumulativeUserAttempts, setIsLoadingCumulativeUserAttempts] = useState(false);
  // Error states
  const [error, setError] = useState(null);
  const [topicBreakdownError, setTopicBreakdownError] = useState(null);
  const [learnersError, setLearnersError] = useState(null);
  const [scoreDistributionsError, setScoreDistributionsError] = useState(null);
  const [overviewsError, setOverviewsError] = useState(null);
  const [cumulativeLearnersError, setCumulativeLearnersError] = useState(null);
  const [cumulativeSummariesError, setCumulativeSummariesError] = useState(null);
  const [cumulativeScoresError, setCumulativeScoresError] = useState(null);
  const [cumulativeUserScoresError, setCumulativeUserScoresError] = useState(null);
  const [cumulativeUserAttemptsError, setCumulativeUserAttemptsError] = useState(null);
  // Current assessment path - stored here to avoid re-computing this data in components since its shared
  const [currentAssessmentPath, setCurrentAssessmentPath] = useState(null);
  // Topic breakdown for the current assessment path - stored here to avoid re-computing this data in components since its shared
  const [currentTopicBreakdown, setCurrentTopicBreakdown] = useState(null);
  // Learners for the current assessment path - stored here to avoid re-computing this data in components since its shared
  const [currentLearners, setCurrentLearners] = useState(null);
  // Score distributions for the current assessment path - stored here to avoid re-computing this data in components since its shared
  const [currentScoreDistributions, setCurrentScoreDistributions] = useState(null);

  /**
   * get or fetch assessment path by `orgId` and `id` and set `currentAssessmentPath`
   * @param {number} orgId - Org ID
   * @param {number} id - Assessment Path ID
   */
  const getAssessmentPath = useCallback(
    async (orgId, id) => {
      try {
        const parsedOrgId = parseInt(orgId, 10);

        let assessmentPath = assessmentPaths.find((path) => path.org_id === parsedOrgId && path.id === parseInt(id, 10));

        if (!assessmentPath) {
          setIsLoading(true);
          setError(null);

          // we get the content via `catalog` api and not the `getCurriculum` api becuase it gives more data
          const content = await Agents.catalog.getContentDescriptionById(id);

          assessmentPath = {
            ...content,
            org_id: parsedOrgId,
          };

          setAssessmentPaths((prevState) => [...prevState, assessmentPath]);
        }

        setCurrentAssessmentPath(assessmentPath);
      } catch (getAssessmentPathError) {
        setError(getAssessmentPathError);
        BugsnagUtil.notify(getAssessmentPathError);
      } finally {
        setIsLoading(false);
      }
    },
    [assessmentPaths]
  );

  /**
   * get or fetch topic breakdown for `currentAssessmentPath` by `orgId` and set `currentTopicBreakdown`;
   * or directly fetch topic breakdown for a given `pathId` and set in `topicBreakdowns` index
   * @param {number} orgId - Org ID
   * @param {number} pathId - (optional) An Assessment Path ID
   */
  const getTopicBreakdown = useCallback(
    async (orgId, pathId) => {
      if (!currentAssessmentPath && !pathId) {
        return;
      }

      try {
        let topicBreakdown = topicBreakdowns[currentAssessmentPath?.id ?? pathId];

        if (!topicBreakdown) {
          if (isBaselineSandboxEnabled) {
            topicBreakdown = {
              default: DEMO_ASSESSMENT_PATH_TOPIC_BREAKDOWN[pathId],
              table: {}, // table format currently not utilized,
            };

            setTopicBreakdowns((prevState) => ({
              ...prevState,
              [currentAssessmentPath?.id ?? pathId]: topicBreakdown,
            }));
          } else {
            setIsLoadingTopicBreakdown(true);
            setTopicBreakdownError(null);

            // first, make a call to get topic breakdown with no specific data format
            const res = await Agents.reports.getReportData(orgId, 'assessment-path-topic-breakdown', `?contentDescriptionId=${currentAssessmentPath?.id ?? pathId}`);

            // secondly, make a call to get topic breakdown in table format
            const resTableFormat = await Agents.reports.getReportData(
              orgId,
              'assessment-path-topic-breakdown',
              `?contentDescriptionId=${currentAssessmentPath?.id ?? pathId}&format=table`
            );

            topicBreakdown = {
              default: res,
              table: resTableFormat,
            };

            setTopicBreakdowns((prevState) => ({
              ...prevState,
              [currentAssessmentPath?.id ?? pathId]: topicBreakdown,
            }));
          }
        }

        if (!currentAssessmentPath) {
          return;
        }

        setCurrentTopicBreakdown(topicBreakdown);
      } catch (getTopicBreakdownError) {
        if (!currentAssessmentPath) {
          return;
        }

        setTopicBreakdownError(getTopicBreakdownError);
        BugsnagUtil.notify(getTopicBreakdownError);
      } finally {
        setIsLoadingTopicBreakdown(false);
      }
    },
    [currentAssessmentPath, topicBreakdowns, isBaselineSandboxEnabled]
  );

  /**
   * get or fetch learners for `currentAssessmentPath` by `orgId` and set `currentLearners`;
   * or directly fetch learners for a given `pathId` and set in `learners` index
   * @param {number} orgId - Org ID
   * @param {number} pathId - (optional) An Assessment Path ID
   */
  const getLearners = useCallback(
    async (orgId, pathId) => {
      if (!currentAssessmentPath && !pathId) {
        return;
      }

      try {
        let learnersEntry = learners[currentAssessmentPath?.id ?? pathId];

        if (!learnersEntry) {
          if (isBaselineSandboxEnabled) {
            learnersEntry = {
              default: DEMO_ASSESSMENT_PATHS_LEARNERS[currentAssessmentPath?.id ?? pathId],
              table: DEMO_ASSESSMENT_PATHS_LEARNERS_TABLE[currentAssessmentPath?.id ?? pathId],
            };

            setLearners((prevState) => ({
              ...prevState,
              [currentAssessmentPath?.id ?? pathId]: learnersEntry,
            }));
          } else {
            setIsLoadingLearners(true);
            setLearnersError(null);

            // first, make request to get assessment path learners with no specific data format
            const res = await Agents.reports.getReportData(orgId, 'assessment-path-learners', `?contentDescriptionId=${currentAssessmentPath?.id ?? pathId}`);

            // secondly, make request to get assessment path learners in table format
            const resTableFormat = await Agents.reports.getReportData(
              orgId,
              'assessment-path-learners',
              `?contentDescriptionId=${currentAssessmentPath?.id ?? pathId}&format=table`
            );

            learnersEntry = {
              default: res,
              table: resTableFormat,
            };

            setLearners((prevState) => ({
              ...prevState,
              [currentAssessmentPath?.id ?? pathId]: learnersEntry,
            }));
          }
        }

        if (!currentAssessmentPath) {
          return;
        }

        setCurrentLearners(learnersEntry);
      } catch (getLearnersError) {
        if (!currentAssessmentPath) {
          return;
        }

        setLearnersError(getLearnersError);
        BugsnagUtil.notify(getLearnersError);
      } finally {
        setIsLoadingLearners(false);
      }
    },
    [currentAssessmentPath, learners, isBaselineSandboxEnabled]
  );

  /**
   * get or fetch score distributions for current assessment path by `orgId` and set `currentScoreDistributions`
   * @param {number} orgId - Org ID
   */
  const getScoreDistributions = useCallback(
    async (orgId) => {
      if (!currentAssessmentPath) {
        return;
      }

      try {
        let entry = scoreDistributions[currentAssessmentPath.id];

        if (!entry) {
          setIsLoadingScoreDistributions(true);
          setScoreDistributionsError(null);

          // first, make request to get score distributions with no specific data format
          const res = await Agents.reports.getReportData(orgId, 'assessment-path-score-range', `?contentDescriptionId=${currentAssessmentPath.id}`);

          // secondly, make request to get score distributions in table format
          const resTableFormat = await Agents.reports.getReportData(orgId, 'assessment-path-score-range', `?contentDescriptionId=${currentAssessmentPath.id}&format=table`);

          entry = {
            default: res,
            table: resTableFormat,
          };

          setScoreDistributions((prevState) => ({
            ...prevState,
            [currentAssessmentPath.id]: entry,
          }));
        }

        setCurrentScoreDistributions(entry);
      } catch (getScoreDistributionsError) {
        setScoreDistributionsError(getScoreDistributionsError);
        BugsnagUtil.notify(getScoreDistributionsError);
      } finally {
        setIsLoadingScoreDistributions(false);
      }
    },
    [currentAssessmentPath, scoreDistributions]
  );

  /**
   * get or fetch assessment paths overview for a given organization by `orgId` and set `overviews`
   * @param {number} orgId - Org ID
   */
  const getOverview = useCallback(
    async (orgId) => {
      if (!orgId) {
        return;
      }

      try {
        let overviewsEntry = overviews[orgId];

        if (!overviewsEntry) {
          if (isBaselineSandboxEnabled) {
            overviewsEntry = {
              default: DEMO_ASSESSMENT_PATHS_OVERVIEW.reverse(),
              table: {
                ...DEMO_ASSESSMENT_PATHS_OVERVIEW_TABLE,
                tableData: DEMO_ASSESSMENT_PATHS_OVERVIEW_TABLE.tableData.reverse(),
              },
            };

            setOverviews((prevState) => ({
              ...prevState,
              [orgId]: overviewsEntry,
            }));
          } else {
            setIsLoadingOverviews(true);
            setOverviewsError(null);

            // first, make request to get assessment paths overview with no specific data format
            const res = await Agents.reports.getReportData(orgId, 'assessment-paths-overview', '');

            // secondly, make request to get assessment paths overview in table format
            const resTableFormat = await Agents.reports.getReportData(orgId, 'assessment-paths-overview', `?format=table`);

            overviewsEntry = {
              default: res.reverse(),
              table: {
                ...resTableFormat,
                tableData: resTableFormat.tableData.reverse(),
              },
            };

            setOverviews((prevState) => ({
              ...prevState,
              [orgId]: overviewsEntry,
            }));
          }
        }
      } catch (getOverviewsError) {
        setOverviewsError(getOverviewsError);
        BugsnagUtil.notify(getOverviewsError);
      } finally {
        setIsLoadingOverviews(false);
      }
    },
    [overviews, isBaselineSandboxEnabled]
  );

  /**
   * get or fetch cumulative assessment path learners for a given organization by `orgId` and set `cumulativeLearners`
   * @param {number} orgId - Org ID
   */
  const getCumulativeLearners = useCallback(
    async (orgId) => {
      if (!orgId) {
        return;
      }

      try {
        let cumulativeLearnersEntry = cumulativeLearners[orgId];

        if (!cumulativeLearnersEntry) {
          if (isBaselineSandboxEnabled) {
            cumulativeLearnersEntry = {
              default: DEMO_CUMULATIVE_ATL,
              table: DEMO_CUMULATIVE_ATL_TABLE,
            };

            setCumulativeLearners((prevState) => ({
              ...prevState,
              [orgId]: cumulativeLearnersEntry,
            }));
          } else {
            setIsLoadingCumulativeLearners(true);
            setCumulativeLearnersError(null);

            // first, make request to get assessment paths cumulative learners with no specific data format
            const res = await Agents.reports.getReportData(orgId, 'cumulative-assessment-learners', '');

            // secondly, make request to get assessment paths cumulative learners in table format
            const resTableFormat = await Agents.reports.getReportData(orgId, 'cumulative-assessment-learners', `?format=table`);

            cumulativeLearnersEntry = {
              default: res,
              table: resTableFormat,
            };

            setCumulativeLearners((prevState) => ({
              ...prevState,
              [orgId]: cumulativeLearnersEntry,
            }));
          }
        }
      } catch (getCumulativeLearnersError) {
        setCumulativeLearnersError(getCumulativeLearnersError);
        BugsnagUtil.notify(getCumulativeLearnersError);
      } finally {
        setIsLoadingCumulativeLearners(false);
      }
    },
    [cumulativeLearners, isBaselineSandboxEnabled]
  );

  /**
   * get or fetch cumulative assessment path summary for a given organization by `orgId` and set `cumulativeSummaries`
   * @param {number} orgId - Org ID
   */
  const getCumulativeSummary = useCallback(
    async (orgId) => {
      if (!orgId) {
        return;
      }

      try {
        let cumulativeSummariesEntry = cumulativeSummaries[orgId];

        if (!cumulativeSummariesEntry) {
          if (isBaselineSandboxEnabled) {
            cumulativeSummariesEntry = {
              default: DEMO_CUMULATIVE_ATPI,
              table: {}, // table format currently not utilized
            };

            setCumulativeSummaries((prevState) => ({
              ...prevState,
              [orgId]: cumulativeSummariesEntry,
            }));
          } else {
            setIsLoadingCumulativeSummaries(true);
            setCumulativeSummariesError(null);

            // first, make request to get assessment paths cumulative summary with no specific data format
            const res = await Agents.reports.getReportData(orgId, 'cumulative-assessment-team-per-interval', '?interval=month');

            // secondly, make request to get assessment paths cumulative summary in table format
            const resTableFormat = await Agents.reports.getReportData(orgId, 'cumulative-assessment-team-per-interval', `?interval=month&format=table`);

            cumulativeSummariesEntry = {
              default: res,
              table: resTableFormat,
            };

            setCumulativeSummaries((prevState) => ({
              ...prevState,
              [orgId]: cumulativeSummariesEntry,
            }));
          }
        }
      } catch (getCumulativeSummaryError) {
        setCumulativeSummariesError(getCumulativeSummaryError);
        BugsnagUtil.notify(getCumulativeSummaryError);
      } finally {
        setIsLoadingCumulativeSummaries(false);
      }
    },
    [cumulativeSummaries, isBaselineSandboxEnabled]
  );

  /**
   * get or fetch cumulative assessment path scores for a given organization by `orgId` & `pathId` then set `cumulativeScores`
   * @param {number} orgId - Org ID
   * @param {number} pathId - Assessment Path ID
   */
  const getCumulativeScores = useCallback(
    async (orgId, pathId) => {
      if (!orgId || !pathId) {
        return;
      }

      try {
        let cumulativeScoresEntry = cumulativeScores[`${orgId}${pathId}`];

        if (!cumulativeScoresEntry) {
          if (isBaselineSandboxEnabled) {
            cumulativeScoresEntry = {
              default: DEMO_ASSESSMENT_PATH_SBPI[pathId],
              table: {}, // table format currently not utilized,
            };

            setCumulativeScores((prevState) => ({
              ...prevState,
              [`${orgId}${pathId}`]: cumulativeScoresEntry,
            }));
          } else {
            setIsLoadingCumulativeScores(true);
            setCumulativeScoresError(null);

            // first, make request to get assessment paths cumulative scores with no specific data format
            const res = await Agents.reports.getReportData(orgId, 'assessment-path-score-breakdown-per-interval', `?contentDescriptionId=${pathId}&interval=month`);

            // secondly, make request to get assessment paths cumulative scores in table format
            const resTableFormat = await Agents.reports.getReportData(
              orgId,
              'assessment-path-score-breakdown-per-interval',
              `?contentDescriptionId=${pathId}&interval=month&format=table`
            );

            cumulativeScoresEntry = {
              default: res,
              table: resTableFormat,
            };

            setCumulativeScores((prevState) => ({
              ...prevState,
              [`${orgId}${pathId}`]: cumulativeScoresEntry,
            }));
          }
        }
      } catch (getCumulativeScoresError) {
        setCumulativeScoresError(getCumulativeScoresError);
        BugsnagUtil.notify(getCumulativeScoresError);
      } finally {
        setIsLoadingCumulativeScores(false);
      }
    },
    [cumulativeSummaries, isBaselineSandboxEnabled]
  );

  /**
   * get or fetch cumulative assessment path scores for a given user by `orgId`, `pathId` & `userId` then set `cumulativeUserScores`
   * @param {number} orgId - Org ID
   * @param {number} pathId - Assessment Path ID
   * @param {number} userId - user ID
   */
  const getCumulativeUserScores = useCallback(
    async (orgId, pathId, userId) => {
      if (!orgId || !pathId || !userId) {
        return;
      }

      try {
        const indexKey = `${orgId}${pathId}${userId}`;
        let cumulativeUserScoresEntry = cumulativeUserScores[indexKey];

        if (!cumulativeUserScoresEntry) {
          if (isBaselineSandboxEnabled) {
            cumulativeUserScoresEntry = {
              default: DEMO_ASSESSMENT_PATH_ISPI[pathId][1],
              table: {}, // table format currently not utilized
            };

            setCumulativeUserScores((prevState) => ({
              ...prevState,
              [indexKey]: cumulativeUserScoresEntry,
            }));
          } else {
            setIsLoadingCumulativeUserScores(true);
            setCumulativeUserScoresError(null);

            // first, make request to get user assessment path cumulative scores with no specific data format
            const res = await Agents.reports.getReportData(orgId, 'assessment-path-individual-scores', `?contentDescriptionId=${pathId}&userId=${userId}`);

            // secondly, make request to get user assessment path cumulative scores in table format
            const resTableFormat = await Agents.reports.getReportData(orgId, 'assessment-path-individual-scores', `?contentDescriptionId=${pathId}&userId=${userId}&format=table`);

            cumulativeUserScoresEntry = {
              default: res,
              table: resTableFormat,
            };

            setCumulativeUserScores((prevState) => ({
              ...prevState,
              [indexKey]: cumulativeUserScoresEntry,
            }));
          }
        }
      } catch (getCumulativeUserScoresError) {
        setCumulativeUserScoresError(getCumulativeUserScoresError);
        BugsnagUtil.notify(getCumulativeUserScoresError);
      } finally {
        setIsLoadingCumulativeUserScores(false);
      }
    },
    [cumulativeUserScores, isBaselineSandboxEnabled]
  );

  /**
   * get or fetch cumulative assessment path attempts for a given user by `orgId`, `pathId` & `userId` then set `cumulativeUserAttempts`
   * @param {number} orgId - Org ID
   * @param {number} pathId - Assessment Path ID
   * @param {number} userId - user ID
   */
  const getCumulativeUserAttempts = useCallback(
    async (orgId, pathId, userId) => {
      if (!orgId || !pathId || !userId) {
        return;
      }

      try {
        const indexKey = `${orgId}${pathId}${userId}`;
        let cumulativeUserAttemptsEntry = cumulativeUserAttempts[indexKey];

        if (!cumulativeUserAttemptsEntry) {
          if (isBaselineSandboxEnabled) {
            cumulativeUserAttemptsEntry = {
              default: DEMO_ASSESSMENT_PATH_ISA[pathId]?.[1] || {},
              table: {}, // table format currently not utilized
            };

            setCumulativeUserAttempts((prevState) => ({
              ...prevState,
              [indexKey]: cumulativeUserAttemptsEntry,
            }));
          } else {
            setIsLoadingCumulativeUserAttempts(true);
            setCumulativeUserAttemptsError(null);

            // first, make request to get user assessment path cumulative attempts in chart format
            const res = await Agents.reports.getReportData(orgId, 'assessment-path-individual-attempts', `?contentDescriptionId=${pathId}&userId=${userId}&format=chart`);

            // secondly, make request to get user assessment path cumulative attempts in table format
            const resTableFormat = await Agents.reports.getReportData(
              orgId,
              'assessment-path-individual-attempts',
              `?contentDescriptionId=${pathId}&userId=${userId}&format=table`
            );

            cumulativeUserAttemptsEntry = {
              default: res,
              table: resTableFormat,
            };

            setCumulativeUserAttempts((prevState) => ({
              ...prevState,
              [indexKey]: cumulativeUserAttemptsEntry,
            }));
          }
        }
      } catch (getCumulativeUserAttemptsError) {
        setCumulativeUserAttemptsError(getCumulativeUserAttemptsError);
        BugsnagUtil.notify(getCumulativeUserAttemptsError);
      } finally {
        setIsLoadingCumulativeUserAttempts(false);
      }
    },
    [cumulativeUserAttempts, isBaselineSandboxEnabled]
  );

  const actions = useMemo(
    () => ({
      getAssessmentPath,
      getTopicBreakdown,
      getLearners,
      getScoreDistributions,
      getOverview,
      getCumulativeLearners,
      getCumulativeSummary,
      getCumulativeScores,
      getCumulativeUserScores,
      getCumulativeUserAttempts,
    }),
    [
      getAssessmentPath,
      getTopicBreakdown,
      getLearners,
      getScoreDistributions,
      getOverview,
      getCumulativeLearners,
      getCumulativeSummary,
      getCumulativeScores,
      getCumulativeUserScores,
      getCumulativeUserAttempts,
    ]
  );

  const value = useMemo(
    () => ({
      // State
      assessmentPaths,
      currentAssessmentPath,
      currentTopicBreakdown,
      currentLearners,
      currentScoreDistributions,
      error,
      topicBreakdownError,
      learnersError,
      scoreDistributionsError,
      overviewsError,
      cumulativeLearnersError,
      cumulativeSummariesError,
      cumulativeScoresError,
      cumulativeUserScoresError,
      cumulativeUserAttemptsError,
      learners,
      topicBreakdowns,
      overviews,
      cumulativeLearners,
      cumulativeSummaries,
      cumulativeScores,
      cumulativeUserScores,
      cumulativeUserAttempts,
      isLoading,
      isLoadingTopicBreakdown,
      isLoadingLearners,
      isLoadingScoreDistributions,
      isLoadingOverview,
      isLoadingCumulativeSummaries,
      isLoadingCumulativeScores,
      isLoadingCumulativeUserScores,
      isLoadingCumulativeUserAttempts,
      // Actions
      actions,
    }),
    [
      assessmentPaths,
      currentAssessmentPath,
      currentTopicBreakdown,
      currentLearners,
      currentScoreDistributions,
      error,
      topicBreakdownError,
      learnersError,
      scoreDistributionsError,
      overviewsError,
      cumulativeLearnersError,
      cumulativeSummariesError,
      cumulativeScoresError,
      cumulativeUserScoresError,
      cumulativeUserAttemptsError,
      learners,
      topicBreakdowns,
      overviews,
      cumulativeLearners,
      cumulativeSummaries,
      cumulativeScores,
      cumulativeUserScores,
      cumulativeUserAttempts,
      isLoading,
      isLoadingTopicBreakdown,
      isLoadingLearners,
      isLoadingScoreDistributions,
      isLoadingOverview,
      isLoadingCumulativeLearners,
      isLoadingCumulativeSummaries,
      isLoadingCumulativeScores,
      isLoadingCumulativeUserScores,
      isLoadingCumulativeUserAttempts,
      actions,
    ]
  );

  return <CybAssessmentPathContext.Provider value={value}>{children}</CybAssessmentPathContext.Provider>;
}

export const useCybAssessmentPath = () => useContext(CybAssessmentPathContext);

export default CybAssessmentPathProvider;
