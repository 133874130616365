import React from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import Loading from '../Loading/Loading';
import StyledError from '../Error/StyledError';
import AdminTable from '../AdminTable/AdminTable';
import FormatUtil from '../../utils/formatUtil';
import StatSectionContainer from '../Analytics/StatSectionContainer';

export default observer(function ReportGraph({ loading, error, data, interval, tabularView, heading, reportsStore, tableSort, graphKey }) {
  if (loading) {
    return <Loading message="Loading..." wrapperClassName="py-8" />;
  }
  if (error) {
    return <StyledError error={error} />;
  }
  if (tabularView) {
    return (
      <div className="my-8">
        <h2 className="text-2xl font-black">Completed {heading}</h2>
        <AdminTable
          headings={reportsStore.reportItem.graph.data.columns}
          data={reportsStore.reportItem.graph.data.tableData}
          headerClickFunc={(th) => tableSort(th, true)}
          sortCol={reportsStore.reportItem.graph.tableParams.sortCol}
          sortDirection={reportsStore.reportItem.graph.tableParams.sortDirection}
          fillEmptyCols="0"
          formatColumns={[
            {
              method: (col) => (col.value ? moment(col.value).format('MM/DD/YYYY') : ''),
              colIdx: FormatUtil.getColIndex(reportsStore.reportItem.graph.data.columns, 'period'),
            },
          ]}
        />
      </div>
    );
  }

  return (
    <div className="my-8 rounded-sm border-xs border-gray-400">
      <StatSectionContainer
        type="line"
        interval={interval}
        displayData={data}
        emptyText="No results during this time"
        borderColor="#00a4ed"
        backgroundColor="#00a4ed"
        showLegend={false}
        graphContainerClasses="h-80"
        tabularView={tabularView}
        yAxisLabel={graphKey || 'Count'}
      />
    </div>
  );
});
