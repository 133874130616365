import React from 'react';

function Growth({ classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';

  return (
    <svg className={className} viewBox="0 0 158 135" fill="none" xmlns="http://www.w3.org/2000/svg" {...accessibilityProps}>
      <path
        d="M131.217 13.9241C131.58 21.9661 125.324 28.7786 117.247 
      29.1392C109.169 29.4997 102.327 23.2718 101.965 15.23C101.603 
      7.18704 107.857 0.375471 115.935 0.0148973C124.013 -0.345618 
      130.855 5.8811 131.217 13.9241Z"
      />
      <path
        d="M154.691 5.4652C151.697 3.64304 147.703 4.80312 145.708 
      7.61894L131.565 26.9994C129.901 29.3184 127.905 31.3059 125.41 
      32.6311C116.925 37.1038 107.275 34.4532 102.118 27.4964L87.6439 
      7.61924C85.6478 4.80342 81.6544 3.64346 78.6603 5.4655C75.1659 
      7.45288 74.3349 11.9257 76.6642 15.2383L91.3054 35.447C93.8005 
      38.7596 96.7957 41.5756 100.123 43.5639C101.62 44.5576 102.452 
      46.2145 102.452 47.8704V77.3552L93.3015 125.392C92.6365 128.705 
      93.9665 132.184 96.7959 133.84C98.127 134.668 99.4581 135 100.789 
      135C104.449 135 107.611 132.516 108.276 128.871L116.76 84.4785L125.079 
      128.871C125.744 132.516 128.905 135 132.566 135C133.897 135 135.228 
      134.668 136.559 133.84C139.387 132.183 140.718 128.705 140.053 
      125.392L130.902 77.3552L130.899 47.7047C130.899 45.8826 131.731 
      44.2256 133.228 43.3982C136.555 41.2445 139.717 38.5951 142.046 
      35.2813L156.687 15.0726C159.016 11.9251 158.184 7.45353 154.691 
      5.4652H154.691Z"
      />
      <path
        d="M68.1771 43.5634C66.3469 41.2445 62.8526 41.2445 61.0237 
      43.5634L44.0521 65.4292C41.7229 68.4102 43.8861 72.7179 47.7123 
      72.7179H55.1988V131.688C55.1988 133.178 56.5299 134.503 58.027 134.503H71.5029C73 
      134.503 74.3311 133.178 74.3311 131.688L74.3323 72.7179H81.8188C85.6451 72.7179 
      87.8082 68.4114 85.479 65.4292L68.1771 43.5634Z"
      />
      <path
        d="M25.0861 72.2207C23.2559 69.9017 19.7615 69.9017 17.9326 72.2207L0.962256 
      94.0864C-1.36691 97.0674 0.796302 101.375 4.62247 101.375H12.109V131.854C12.109 
      133.344 13.4401 134.67 14.9372 134.67H28.4131C29.9102 134.67 31.2413 133.344 31.2413 
      131.854L31.2425 101.375H38.729C42.5552 101.375 44.7184 97.0686 42.3892 94.0864L25.0861 
      72.2207Z"
      />
    </svg>
  );
}
export default Growth;
