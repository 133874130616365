import React from 'react';
import Header from '../Header/Header';
import Segment from '../Segment/Segment';
import './analytics.css';
import Container from '../Container/Container';

function FeaturesSummary(props) {
  return (
    <Container className="organization">
      <Segment className="border-none features-summary">
        <div className="max-w-240">
          {props.featureType === 'reporting' ? (
            <>
              <img
                className="mb-4"
                src="https://images.ctfassets.net/kvf8rpi09wgk/5tqhGUfTjJVhfyFPgOHkTh/9e93a769f764bf1c17f0593510d83b01/reporting-teaser.png?h=325"
                alt="Reporting Screenshot"
              />
              <Header as="h1">Reports</Header>
              <p className="mb-4 text-sm text-gray-600">
                Gain access to user, path, and course data across the entire team or by an individual group or member. Filter, view and download your team&apos;s data to get an
                in-depth view and insight into your team&apos;s performance.
              </p>
            </>
          ) : (
            <>
              <img
                className="mb-4"
                src="https://images.ctfassets.net/kvf8rpi09wgk/2a0akXS5Op7yvYj1at0gLU/968a035567ec851767ce8c354b69efe9/dashboard-teaser.png?h=325"
                alt="Dashboard Screenshot"
              />
              <Header as="h1">Dashboards and Analytics</Header>
              <p className="mb-4 text-sm text-gray-600">
                See how Cybrary is powering your workforce&apos;s development in a centralized place. Unlock instant access to key metrics including total learning hours, member
                activity, and more, at your fingertips.
              </p>
            </>
          )}
          <button
            onClick={() => window.zE('webWidget', 'open')}
            className="py-2.5 px-6 text-sm font-bold leading-5 text-center text-white hover:text-white bg-cyb-pink-500 hover:bg-pink-600 rounded-sm"
          >
            Contact Us
          </button>
        </div>
      </Segment>
    </Container>
  );
}

export default FeaturesSummary;
