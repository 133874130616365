import React, { useMemo, useCallback } from 'react';
import { observer, inject } from 'mobx-react';
import AdminTable from '../../AdminTable/AdminTable';
import Loading from '../../Loading/Loading';
import StyledError from '../../Error/StyledError';
import Container from '../../Container/Container';
import { useCybAssessmentPath } from '../../../providers/CybAssessmentPathProvider';
import { useCybAssessmentAnalytics } from '../../../providers/CybAssessmentAnalyticsProvider';
import { renderCommunityScoreColumn, renderTrendingColumn, formatTableColumns } from '../shared';
import BaselineEmptyText from './BaselineEmptyText';

const COLUMN_DESCRIPTIONS = {
  community_score: 'The average score of all completed Topic Assessments within the Assessment Path by all Cybrary users',
  company_score: 'The average score of all completed Topic Assessments within the Assessment Path',
  completions: 'The count of Topic Assessments completed within the Assessment Path',
  assessment_path_trend: 'Change in the user’s score over the past 90 days',
};

/**
 * `BaselineTeamsOverviewTable` - data table representing all assessments and their completions, company score, and community score.
 * Used in `BaselineTeams` Page `BaselineTeamsOverview` component.
 * @returns JSX.Element
 */
const BaselineTeamsOverviewTable = inject('userStore')(
  observer(({ userStore }) => {
    const orgId = userStore?.adminTeam?.id;

    const { overviews, isLoadingOverview, overviewsError } = useCybAssessmentPath();

    const { tracks } = useCybAssessmentAnalytics();

    const userTracks = tracks[userStore?.user?.id];

    const assessmentPathsOverview = useMemo(() => {
      const overview = overviews[orgId];

      const unlistedTrackIds = userTracks?.filter((track) => track.status === 'Unlisted').map((track) => track['content-id']);

      const filteredTableData = overview?.table?.tableData?.filter((data) => !unlistedTrackIds?.includes(data[0].value));

      if (!overview || !filteredTableData) {
        return null;
      }

      return {
        ...overview,
        table: {
          ...overview.table,
          tableData: filteredTableData,
        },
      };
    }, [overviews, orgId, userTracks]);

    // add tooltip to applicable column headers
    const updatedColumnHeadings = useMemo(
      () =>
        assessmentPathsOverview?.table?.columns?.map((col) => ({
          ...col,
          tooltip: COLUMN_DESCRIPTIONS[col.key],
        })),
      [assessmentPathsOverview]
    );

    const renderColumn = useCallback((row, fullRow, headings, column) => {
      if (row.value !== undefined && column.showCol) {
        const percentage = Math.round(parseFloat(row.value * 100));

        if (column.key === 'community_score') {
          return renderCommunityScoreColumn(row, fullRow, headings, percentage, 'community_score');
        }

        if (column.key === 'assessment_path_trend') {
          return renderTrendingColumn(fullRow, headings, 'assessment_path_trend');
        }

        return `${percentage}%`;
      }

      return '';
    }, []);

    const formatColumns = useMemo(
      () => formatTableColumns(assessmentPathsOverview?.table?.columns, ['company_score', 'community_score', 'assessment_path_trend'], renderColumn),
      [renderColumn, assessmentPathsOverview?.table?.columns]
    );

    const tableData = useMemo(() => assessmentPathsOverview?.table?.tableData, [assessmentPathsOverview?.table]);

    if (isLoadingOverview) {
      return (
        <Container className="mt-10">
          <Loading message="Loading..." />
        </Container>
      );
    }

    if (overviewsError) {
      return (
        <Container className="mt-10">
          <StyledError error={overviewsError} />
        </Container>
      );
    }

    if (!assessmentPathsOverview) {
      return (
        <Container className="mt-10">
          <BaselineEmptyText orgId={orgId} />
        </Container>
      );
    }

    return (
      <div className="flex flex-col mt-12">
        <AdminTable
          wrapperClassName="m-0 w-full"
          colClasses="whitespace-nowrap"
          headings={updatedColumnHeadings}
          data={tableData}
          formatColumns={formatColumns}
          noResultsMsg={<BaselineEmptyText orgId={orgId} />}
        />
      </div>
    );
  })
);

export default BaselineTeamsOverviewTable;
