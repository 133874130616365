import React from 'react';
import { inject, observer } from 'mobx-react';
import Icon from '../Icon/Icon';
import Tooltip from '../Tooltip/Tooltip';
import FormatUtil from '../../utils/formatUtil';
import './bookmarks.css';

const BookmarkToggle = inject('userStore')(
  observer(function BookmarkToggle({ userStore, theme, contentDescriptionId, bookmarkCount, useIcon, textColor, title }) {
    const toggleBookmark = (e) => {
      e.preventDefault();
      userStore.toggleBookmark(contentDescriptionId, title);
    };
    const isMarked = Array.isArray(userStore.bookmarks) ? userStore.bookmarks.indexOf(contentDescriptionId) !== -1 : false;
    const markedClass = isMarked ? 'text-cyb-pink-500' : 'text-gray-600';
    const textColorClass = textColor || 'text-white';
    const count = isMarked ? bookmarkCount + 1 : bookmarkCount;
    const formattedCount = FormatUtil.numberWithCommas(count);
    const text = formattedCount || 'Bookmark';
    const ariaLabelText = `Click to bookmark ${title} content (bookmarked ${formattedCount} ${FormatUtil.pluralize(count, 'time')})`;

    return (
      <Tooltip
        position="top-end"
        size="small"
        theme={theme || 'light'}
        style={{ display: 'flex', alignItems: 'center' }}
        content={<div className="font-semibold text-center">{isMarked ? 'Remove' : 'Bookmark'}</div>}
        omitTabIndex
        triggerContent={
          <button aria-label={ariaLabelText} aria-pressed={isMarked} className="text-sm leading-5 bg-none rounded-sm cursor-pointer book-toggle" onClick={toggleBookmark}>
            <div className="flex items-center">
              <Icon className={`bookmark-icon w-6 h-6 ${markedClass}`} active={isMarked} name="bookmark" />
              {useIcon ? null : <div className={`bookmark-text inline text-sm ml-1 ${textColorClass}`}>{text}</div>}
            </div>
          </button>
        }
      />
    );
  })
);

export default BookmarkToggle;
