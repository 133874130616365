import React, { useMemo, useState } from 'react';
import { find } from 'lodash';
import { twMerge } from 'tailwind-merge';
import { Slide } from 'react-awesome-reveal';
import AddLink from '../../AddLink/AddLink';
import nav from '../../../navigation/nav';
import Footer from './Footer';
import SecondarySidebar from './SecondarySidebar';
import PlatformFeedback from '../PlatformFeedback';
import InviteReferralButton from '../InviteReferralButton';
import { HELP_DESK_LINKS } from '../../../constants';
import If from '../../If/If';
import Icon from '../../Icon/Icon';

function hasChildren(item) {
  return item && item.children && item.children.length;
}

function handleClick(item, primary, toggleMobileSidebar, setActiveSidebar) {
  if (hasChildren(item) && item.label) {
    const secondarySideMenu = find(primary.items, (primaryItem) => {
      return primaryItem.label === item.label;
    });
    setActiveSidebar(secondarySideMenu);
  } else {
    toggleMobileSidebar(false);
    setActiveSidebar(null);
  }
}

function PrimarySidebarHeader({ children, className, omitLi = false }) {
  const classes = twMerge('pb-2 pl-6 text-sm font-semibold uppercase', className);
  if (omitLi) {
    return <p className={classes}>{children}</p>;
  }
  return (
    <li>
      <p className={classes}>{children}</p>
    </li>
  );
}

function PrimarySidebarLabel({ children, className }) {
  const classes = twMerge('text-lg text-gray-600 group-hover:text-black mb-0', className);
  return <p className={classes}>{children}</p>;
}

function PrimarySidebarHR({ className, omitLi = false }) {
  const classes = twMerge('mt-4 mb-8 w-full h-px border-b border-gray-300', className);
  if (omitLi) {
    return <div className={classes} />;
  }
  return (
    <li>
      <div className={classes} />
    </li>
  );
}

function PrimarySidebarIcon({ name, active, className, id }) {
  const classes = twMerge('h-8 w-8 mr-3 text-gray-600 group-hover:text-black', className);
  return <Icon name={name} className={classes} active={active} id={id} />;
}

function PrimarySidebarChevronRight() {
  return <PrimarySidebarIcon name="chevron-right" className="ml-3 w-5 h-5" />;
}

function PrimarySidebarUserRow({ userStore, setActiveSidebar, activeSidebar, isDefaultUserAvatar }) {
  return (
    <li>
      <button
        onClick={() => setActiveSidebar('profile')}
        className="group flex items-center py-4 px-6 cursor-pointer"
        aria-label={`${activeSidebar ? 'Close User Menu' : 'Open User Menu'}`}
      >
        <If condition={isDefaultUserAvatar}>
          <PrimarySidebarIcon name="user-icon" id="-mobile-primary" />
        </If>
        <If condition={!isDefaultUserAvatar}>
          <div className="mr-3 w-8 h-8 cursor-pointer">
            <img src={userStore.avatarUrl} className="w-full rounded-full" alt="Avatar" />
          </div>
        </If>
        <PrimarySidebarLabel>{userStore?.user?.real_name || userStore?.user?.name}</PrimarySidebarLabel>
        <PrimarySidebarChevronRight />
      </button>
    </li>
  );
}

function PrimarySidebar({ mobileSidebar, toggleMobileSidebar, userStore, commonStore }) {
  const [activeSidebar, setActiveSidebar] = useState(null);
  const { primary } = nav;

  const isDefaultUserAvatar = !userStore.avatarUrl || (userStore.avatarUrl && userStore.avatarUrl === 'https://assets.cybrary.it/uploads/user-avatars/profile.jpg');

  // filter out items that don't have a display function or are hidden
  const itemsToDisplay = useMemo(() => primary.items.filter((item) => !item.display || item.display(userStore)), [primary.items, userStore]);

  return (
    <div className={`fixed top-0 left-0 w-full h-full lg:hidden transition-all ease-in duration-400 ${mobileSidebar ? 'visible' : 'invisible'}`} style={{ zIndex: '1500' }}>
      <button onClick={() => toggleMobileSidebar(!mobileSidebar)} aria-label="Toggle Sidebar Overlay" className="w-full h-full">
        <div id="sidebar-overlay" style={{ zIndex: '1600' }} className="w-full h-full bg-black/50" />
      </button>
      <div className={`overflow-hidden absolute top-0 left-0 z-220 bg-white w-4/5 h-full transition-all ease-in-out duration-500 ${mobileSidebar ? 'ml-0' : '-ml-152'}`}>
        <div className="overflow-y-scroll pb-10 h-full second-container" style={{ width: '103%' }}>
          {/* Header */}
          <If condition={!activeSidebar}>
            <AddLink onClick={() => handleClick(null, primary, toggleMobileSidebar, setActiveSidebar)} to="/" aria-label="Home" className="cursor-pointer">
              <div className="flex justify-center items-center py-3 px-6 bg-gray-200">
                <img
                  src="https://images.ctfassets.net/kvf8rpi09wgk/6HtXDpiYT7GMU8yp0DS4Dy/5b42c213353afb410ac8e52e7db7c77f/cybrary_logo_black.svg"
                  alt="Cybrary"
                  width="180"
                  height="45"
                />
              </div>
            </AddLink>
            <PrimarySidebarHR omitLi className="mt-0" />

            <Slide duration={300} triggerOnce>
              <ul className="mb-10">
                {/* Navigation */}
                <PrimarySidebarHeader>Navigation</PrimarySidebarHeader>
                {itemsToDisplay.map((item) => {
                  let actualHref = item.href;
                  // Some URLs require dynamic replacement values, send along the href and the user store to the provided function
                  if (item.hrefReplace) {
                    actualHref = item.hrefReplace(item.href, userStore);
                  }
                  return (
                    <li key={item.label}>
                      <AddLink
                        onClick={() => handleClick(item, primary, toggleMobileSidebar, setActiveSidebar)}
                        to={!hasChildren(item) ? actualHref : ''}
                        className="group flex items-center py-4 px-6 cursor-pointer"
                      >
                        <PrimarySidebarIcon name={item.icon} className="stroke-current" id="-primary-sidebar" />
                        <PrimarySidebarLabel>{item.label}</PrimarySidebarLabel>
                        <If condition={hasChildren(item)}>
                          <PrimarySidebarChevronRight />
                        </If>
                      </AddLink>
                    </li>
                  );
                })}
                <PrimarySidebarHR />

                {/* Feedback */}
                <PrimarySidebarHeader>Feedback</PrimarySidebarHeader>
                <PlatformFeedback iconClasses="scale-125 translate-y-3px" buttonClasses="pl-6 py-4" />
                <PrimarySidebarHR />

                {/* Invite */}
                <PrimarySidebarHeader>Invite</PrimarySidebarHeader>
                <li>
                  <InviteReferralButton buttonClasses="pl-6" label="Invite" />
                </li>
                <PrimarySidebarHR />

                {/* Account */}
                <PrimarySidebarHeader>Account</PrimarySidebarHeader>
                <PrimarySidebarUserRow userStore={userStore} setActiveSidebar={setActiveSidebar} activeSidebar={activeSidebar} isDefaultUserAvatar={isDefaultUserAvatar} />
                <li>
                  <AddLink onClick={() => toggleMobileSidebar(false)} to="/notification-center" className="group flex items-center py-4 px-6 cursor-pointer">
                    <PrimarySidebarIcon name="bell-icon" id="-primary-sidebar" />
                    <PrimarySidebarLabel>Notifications</PrimarySidebarLabel>
                  </AddLink>
                </li>
                <li>
                  <AddLink to={HELP_DESK_LINKS.HOMEPAGE} className="group flex items-center py-4 px-6 cursor-pointer">
                    <PrimarySidebarIcon name="help-icon" id="-primary-sidebar" />
                    <PrimarySidebarLabel>Help Center</PrimarySidebarLabel>
                  </AddLink>
                </li>
              </ul>
            </Slide>
          </If>
          <SecondarySidebar
            activeSidebar={activeSidebar}
            setActiveSidebar={setActiveSidebar}
            toggleMobileSidebar={toggleMobileSidebar}
            isDefaultUserAvatar={isDefaultUserAvatar}
            userStore={userStore}
            commonStore={commonStore}
          />
          <Footer commonStore={commonStore} userStore={userStore} />
        </div>
      </div>
    </div>
  );
}

export default PrimarySidebar;
