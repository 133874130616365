import React from 'react';
import Collapsible from 'react-collapsible';
import './admin-table.css';

export default function TableRowExpansion(props) {
  const { open: isOpen, openMethod, row, headings } = props;
  return (
    <Collapsible open={isOpen} transitionTime={200}>
      <div className={`expanded-row-contents${isOpen ? '' : ' hidden'}`}>{isOpen && openMethod(row, headings)}</div>
    </Collapsible>
  );
}
