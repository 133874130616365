import React from 'react';
import Collapse from '../../Icons/Collapse';
import SidebarTabsMenu from './SidebarTabsMenu';

export default function DoubleSidebar({ isOpen, enrollmentTitle, activityTitle, isDoubleSidebar, setIsDoubleSidebar, tabs }) {
  const title = enrollmentTitle || activityTitle;
  const sidebarWidth = isDoubleSidebar ? 'w-[760px]' : 'w-[500px]';

  const leftSide = tabs.slice(0, 1);
  // Left side has a 1px border on the right, so subtract one from the width
  const leftWidth = 'w-[379px]';
  const rightSide = tabs.slice(1);
  const rightWidth = 'w-[380px]';

  const toggle = () => {
    setIsDoubleSidebar(false);
  };

  return (
    <div aria-hidden={!isOpen} aria-label={`${title} resources`} className={`hidden overflow-hidden ${sidebarWidth} bg-white border-r border-gray-400 lg:block`}>
      <div className={`overflow-hidden relative top-0 ${sidebarWidth} lg:pb-0 second-container`}>
        <div className="flex overflow-hidden w-full">
          <div className={`relative border-r-1 border-gray-400 immersive-sidebar overflow-auto ${leftWidth}`} id="left-scroll-container">
            <SidebarTabsMenu key="first" tabs={leftSide} fixedWidth={leftWidth} scrollId="left-scroll-container" ignoreDefaultTabIndex />
          </div>
          <div className={`relative immersive-sidebar overflow-auto ${rightWidth}`} id="right-scroll-container">
            <SidebarTabsMenu key="first" tabs={rightSide} fixedWidth={rightWidth} scrollId="right-scroll-container" ignoreDefaultTabIndex />
          </div>
        </div>
        <button onClick={toggle} className="absolute top-5 right-2 z-20 text-gray-600">
          <Collapse classes="h-6 w-6" />
        </button>
      </div>
    </div>
  );
}
