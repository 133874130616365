import Bugsnag from '@bugsnag/js';
import moment from 'moment';

const getUserLicenseType = (user) => {
  let licenseType = 'Free';
  if (user.is_cip) {
    licenseType = 'CIP';
  } else if (user.is_enterprise) {
    licenseType = 'Teams';
  }
  return licenseType;
};

const getCountryCode = async (authStore) => {
  let countryCode = 'US';
  // Try to get user's geo info
  try {
    const geoLocation = await authStore.getGeoInfo();
    countryCode = geoLocation.countryCode;
  } catch (error) {
    Bugsnag.notify(error);
  }
  return countryCode;
};

const getPendoInitData = async (user, userStore, authStore, additionalVisitorInfo) => {
  if (!user) {
    return {};
  }

  const countryCode = await getCountryCode(authStore);
  const userId = process.env.REACT_APP_BUGSNAG_RELEASE_STAGE === 'production' ? user.id : `u${user.id}`;
  const role = user.onboarding_role ? user.onboarding_role : userStore.role;
  const { inferredRole } = userStore;
  const goal = user.onboarding_goal ? user.onboarding_goal : userStore.goal;
  const onboardingData = user?.onboarding_data || {};
  const isPaying = user.is_paid ? 'Paid' : 'Free';

  // Get user's license type
  const licenseType = getUserLicenseType(user);
  const account = {
    id: licenseType,
  };

  // Get Potential team data -- Team that a user is eligible to join
  const potentialTeamData = {};
  if (userStore.potentialTeam && userStore.potentialTeam.invite_url) {
    potentialTeamData.potentialTeamName = userStore.potentialTeam.name;
    potentialTeamData.potentialTeamDomain = userStore.potentialTeam.domain;
    potentialTeamData.potentialTeamInviteLink = userStore.potentialTeam.invite_url;
  }

  let visitor = {
    id: userId,
    full_name: user.real_name,
    email: user.email,
    country: countryCode,
    registrationDate: moment(user.registered_at).toISOString(),
    licenseType,
    role,
    inferredRole,
    goal,
    onboardingExperienceLevel: onboardingData?.experienceLevel,
    onboardingGoal: onboardingData?.goal,
    onboardingMembershipFor: onboardingData?.membershipFor,
    onboardingTeamLead: onboardingData?.teamLead,
    onboardingTitle: onboardingData?.title,
    ...potentialTeamData,
  };

  if (userStore.profileData && userStore.profileData.company) {
    visitor.company = userStore.profileData.company;
  }

  // If additional visitor data is explicitly passed in, include that
  if (additionalVisitorInfo) {
    visitor = {
      ...visitor,
      ...additionalVisitorInfo,
    };
  }

  // Get user's team information
  let { team } = userStore;
  if (user.is_enterprise && !team && userStore.userTeams) {
    // Attempt to grab the team from userTeams
    const keys = Object.keys(userStore.userTeams);
    team = userStore.userTeams[keys[0]] || null;
  }
  if (team) {
    visitor.userRole = team.role;
    account.packageTypes = team.package_types && team.package_types.length ? team.package_types.join(', ') : null;
    account.name = team.name;
    account.id = team.id;
    account.is_paying = isPaying;
    account.teamType = team.type || 'Enterprise';
    visitor.teamJoinDate = team.joined_at;
    account.licenseSource = team.license_source;
  }

  return {
    visitor,
    account,
  };
};

/**
 * Boot up Pendo with data about the user
 * @param {*} user
 * @param {*} userStore
 * @param {*} authStore
 * @param {*} isUpdateInfo
 */
export const bootPendo = async (user, userStore, authStore, isUpdateInfo, additionalVisitorInfo) => {
  if (window.pendo) {
    const initData = await getPendoInitData(user, userStore, authStore, additionalVisitorInfo);
    if (isUpdateInfo) {
      window.pendo.updateOptions(initData);
    } else {
      window.pendo.initialize(initData);
    }
  }
};

export const firePendoTrackEvent = (name, event) => {
  if (window.pendo) {
    window.pendo.track(name, event);
  }
};

/**
 * Manually trigger a pendo guide
 * @param {*} guideId
 * @param {*} omitIfHasBeenSeen
 * @param {*} delay
 */
export const triggerGuide = (guideId, omitIfHasBeenSeen, delay) => {
  if (!guideId) {
    return false;
  }
  // Set timeout/delay, if needed, to either prevent it from trying to load before pendo is ready, or before the app is even loaded (prevent pendo guide before ui even shows)
  setTimeout(() => {
    if (window.pendo && window.pendo.findGuideById && ((window.pendo.findGuideById(guideId) && !window.pendo.findGuideById(guideId).hasBeenSeen()) || !omitIfHasBeenSeen)) {
      window.pendo.showGuideById(guideId);
    }
    return true;
  }, delay || 0);
  return true;
};
