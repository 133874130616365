import React from 'react';
import ExampleSection from './ExampleSection';
import Code from '../../../components/Code/Code';
import Chip from '../../../components/Chip/Chip';
import Header from '../../../components/Header/Header';

function ChipExampleSection() {
  return (
    <ExampleSection title="Chips">
      <Header as="h2">Default</Header>
      <Chip>Default Chip</Chip>
      <Code>
        {`
    <Chip>Example Chip</Chip>
      `}
      </Code>
      <Header as="h2">Colored</Header>
      <div className="flex gap-2">
        <Chip color="cyb-pink-500">Pink Chip</Chip>
        <Chip color="cyb-blue-500">Blue Chip</Chip>
        <Chip color="cyb-red-700">Red Chip</Chip>
      </div>
      <Code>
        {`
        <div className="flex gap-2">
          <Chip color="cyb-pink-500">Pink Chip</Chip>
          <Chip color="cyb-blue-500">Blue Chip</Chip>
          <Chip color="cyb-red-700">Red Chip</Chip>
        </div>
      `}
      </Code>
    </ExampleSection>
  );
}

export default ChipExampleSection;
