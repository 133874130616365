import React from 'react';
import Icon from '../Icon/Icon';

function RemoveAllSelections({ clearValue }) {
  return (
    <button aria-label="Clear all selections" onClick={clearValue} className="px-1" type="button">
      <Icon name="x" className="w-4 h-4 text-gray-600" />
    </button>
  );
}

export default RemoveAllSelections;
