import Cookies from 'js-cookie';
import { useMemo, useState } from 'react';

/**
 * Check if a cookie is enabled
 * Used in static functions outside of React components
 * @param {string} name - The name of the cookie to check
 * @param {string} mode - The cookies key in which to check for the name
 * @returns {boolean} - Whether the cookie is enabled
 */
export const isCookieEnabled = ({ name, mode }) => {
  const cookie = Cookies.get(mode);
  const contexts = cookie ? JSON.parse(cookie) : [];
  return contexts.includes(name);
};

/**
 * Cookie Mode Hook
 *
 * @description Defines a cookie that sets the app into a `mode` (i.e., beta or sandbox) which enables different contexts to be unlocked throughout the app.
 * These contexts are defined as an array of strings & stored in the cookie value.
 *
 * @returns {CookieModeInterface} `cookieModeInterface`
 */
const useCookieMode = ({ mode }) => {
  const cookie = Cookies.get(mode);
  const initContexts = cookie ? JSON.parse(cookie) : [];
  const [enabledContexts, setEnabledContexts] = useState(initContexts);

  /**
   * Enable a context by adding it to the `enabledContexts` and setting to `cookie`
   *
   * @param {string} contextId - The id of the context to enable
   */
  const enableContext = useMemo(
    () => (contextId) => {
      if (!enabledContexts.includes(contextId)) {
        // Build a new array so we don't mutate the state
        const newFeatures = [...enabledContexts, contextId];
        newFeatures.push(contextId);
        // Update the state and the cookie
        setEnabledContexts([...enabledContexts, contextId]);
        Cookies.set(mode, JSON.stringify(newFeatures));
      }
    },
    [enabledContexts]
  );

  /**
   * Disable all contexts by removing the `cookie` and setting `enabledContexts` to an empty array
   */
  const disableAllContexts = useMemo(
    () => () => {
      setEnabledContexts([]);
      Cookies.set(mode, JSON.stringify([]));

      // Reload the page to ensure all beta features are disabled
      window.location.reload();
    },
    []
  );

  /**
   * `CookieModeInterface` data and actions returned from the hook
   */
  return useMemo(
    () => ({
      enabledContexts,
      enableContext,
      disableAllContexts,
    }),
    [enabledContexts, enableContext, disableAllContexts]
  );
};

export default useCookieMode;
