import React from 'react';
import Icon from '../Icon/Icon';

function NoResultsMessage({ className, message }) {
  return (
    <div className={className || 'text-gray-600 text-center flex flex-col items-center text-sm'}>
      <Icon name="rocket" className="mb-4 w-12 h-12 text-gray-600 fill-current" />
      {message || 'No data to display'}
    </div>
  );
}

export default NoResultsMessage;
