import React from 'react';

export default function Ribbon2({ classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';
  return (
    <svg className={className} viewBox="0 0 112 135" fill="none" xmlns="http://www.w3.org/2000/svg" {...accessibilityProps}>
      <path
        d="M56.0015 89.9735C65.7848 89.9735 75.1669 86.1024 82.0846 
      79.2127C89.0014 72.3221 92.8877 62.9768 92.8877 53.2318C92.8877 
      43.4868 89.0014 34.1415 82.0846 27.2509C75.1669 20.3612 65.7848 
      16.4901 56.0015 16.4901C46.2182 16.4901 36.8361 20.3612 29.9184 
      27.2509C23.0016 34.1415 19.1153 43.4868 19.1153 53.2318C19.1329 
      62.9708 23.0252 72.3057 29.9382 79.193C36.8526 86.0792 46.2242 
      89.9562 56.0015 89.9735ZM50.5688 47.6874L56.0015 36.7984L61.4342 
      47.6874L73.5729 49.4908L64.7872 57.9751L66.8666 69.9998L56.0016 
      64.3218L45.1365 69.9998L47.216 57.9751L38.4303 49.492L50.5688 
      47.6874ZM90.4044 90.3075V90.3086C94.4966 90.5137 98.479 88.9561 
      101.337 86.0332C103.227 82.331 103.423 77.9974 101.873 74.1425C101.266 
      72.1073 101.039 69.9789 101.203 67.862C102.272 66.1472 103.661 64.6501 
      105.294 63.4539C108.911 61.1764 111.359 57.4473 112 53.2327C111.359 
      49.0178 108.911 45.2887 105.294 43.0114C103.661 41.815 102.272 
      40.318 101.203 38.6033C101.039 36.4865 101.266 34.3583 101.873 
      32.3228C103.423 28.4679 103.227 24.1343 101.337 20.4321C98.3633 
      17.4743 94.2652 15.919 90.07 16.1567C87.9742 16.1776 85.896 15.7687 
      83.966 14.9544C82.5883 13.3724 81.5392 11.5329 80.8819 9.54306C79.2726 
      5.80236 77.4609 1.52693 73.3034 0.190858C69.2345 -0.481323 65.0746 
      0.64053 61.9015 3.26412C60.1308 4.51994 58.124 5.40649 56 5.86898C53.8761 
      5.40649 51.8691 4.51997 50.0985 3.26412C46.9254 0.640619 42.7655 -0.481353 
      38.6966 0.190858C34.5388 1.52708 32.7271 5.80236 31.1181 9.54306C30.4608 
      11.5328 29.4117 13.3723 28.0339 14.9544C26.1042 15.7688 24.0258 16.1777 
      21.93 16.1567C17.7348 15.919 13.6369 17.4743 10.6626 20.4321C8.7726 
      24.1343 8.5773 28.4679 10.1269 32.3228C10.7339 34.358 10.9608 36.4864 
      10.7971 38.6033C9.72812 40.3181 8.3387 41.8152 6.70603 43.0114C3.08864 
      45.2889 0.640723 49.0181 0 53.2327C0.640902 57.4475 3.08864 61.1766 
      6.70603 63.4539C8.3387 64.6503 9.7282 66.1473 10.7971 67.862C10.9608 
      69.9788 10.7339 72.1071 10.1269 74.1425C8.57727 77.9974 8.7726 82.331 
      10.6626 86.0332C13.521 88.9561 17.5033 90.5135 21.5956 90.3086L3.82212 
      113.423C3.02801 114.511 2.95666 115.963 3.64084 117.124C4.32503 118.283 
      5.63372 118.928 6.97529 118.767L22.4005 116.696L24.48 132.127H24.4788C24.6788 
      133.454 25.6565 134.533 26.9606 134.866C27.2448 134.944 27.5384 134.988 
      27.8331 135C28.8868 134.993 29.8774 134.499 30.5148 133.664L55.7991 
      100.662C55.9184 100.573 56.0822 100.573 56.2015 100.662L81.4858 
      133.664C82.1232 134.499 83.1138 134.993 84.1675 135C84.4622 
      134.988 84.7558 134.944 85.04 134.866C86.3441 134.533 87.3218 
      133.454 87.5218 132.127L89.6012 116.696L105.026 118.767H105.025C106.367 
      118.928 107.675 118.283 108.36 117.124C109.044 115.963 108.973 114.511 
      108.178 113.423L90.4044 90.3075ZM16.0955 82.0921C15.3576 81.0903 16.0955 
      77.9506 16.6324 75.6125V75.6113C17.6721 72.4414 17.8581 69.055 17.1692 
      65.7918C15.8114 62.8363 13.7084 60.2814 11.0665 58.3769C9.12152 56.7063 
      6.70746 54.5685 6.70746 53.2336C6.70746 51.8974 9.1214 49.7598 11.0665 
      48.0226V48.0215C13.7202 46.1564 15.8264 43.6202 17.1692 40.6728C17.8581 
      37.4098 17.6721 34.0231 16.6324 30.8532C16.0955 28.5152 15.3576 25.3757 
      16.0955 24.3736C16.8335 23.3715 20.1198 23.0374 22.5339 22.837C25.8579 
      22.8452 29.122 21.9691 31.9909 20.2974C34.4481 18.1224 36.2783 15.3313 
      37.2888 12.2148C38.2279 9.9431 39.5016 6.9376 40.7762 6.53677C40.8768 
      6.4762 40.995 6.45173 41.1119 6.46921C43.1048 6.97596 44.9901 7.8357 
      46.6778 9.00765C49.4204 10.9823 52.6344 12.2032 56.0003 12.5491C59.3662 
      12.2031 62.5803 10.9823 65.3228 9.00765C67.3345 7.80543 70.1519 6.20244 
      71.2244 6.53559C72.4992 6.93636 73.7728 9.94317 74.7118 12.2136V12.2148C75.7223 
      15.331 77.5527 18.1224 80.0098 20.2974C82.8786 21.9691 86.1427 22.8452 89.4667 
      22.837C91.8806 23.0374 95.167 23.3717 95.9051 24.3736C96.6431 25.3754 95.9051 
      28.5151 95.3682 30.8532C94.3285 34.0231 94.1425 37.4095 94.8314 40.6728C96.174 
      43.6201 98.2805 46.1563 100.934 48.0215C102.879 49.7584 105.293 51.8961 105.293 
      53.2324C105.293 54.5688 102.879 56.7062 100.934 58.3757V58.3769C98.2922 60.2816 
      96.1892 62.8363 94.8314 65.7918C94.1425 69.0547 94.3285 72.4414 95.3682 
      75.6113C95.9051 77.9494 96.643 81.0889 95.9051 82.091C95.1671 83.093 
      91.8808 83.4272 89.4667 83.6275V83.6287C87.4025 83.7498 85.3568 
      84.0853 83.3628 84.6306C83.2961 84.6306 83.2283 84.697 83.1616 
      84.697C82.999 84.7471 82.8412 84.8146 82.6926 84.8973C81.7184 
      85.1268 80.8038 85.5591 80.0097 86.1671C77.5525 88.3421 
      75.7223 91.1333 74.7118 94.2498C73.7726 96.5215 72.499 
      99.527 71.2243 99.9278C70.1507 100.262 67.3345 98.658 
      65.3228 97.4557V97.4569C63.5217 96.3152 61.6117 95.353 
      59.6225 94.5841C59.488 94.5841 59.4213 94.5177 59.3546 
      94.5177H59.3535C59.1055 94.3884 58.8295 94.3196 58.5488 
      94.3173C58.4821 94.2509 58.4821 94.2509 58.4143 94.2509V94.2498C56.8366 
      93.8047 55.1643 93.8047 53.5864 94.2498C53.5198 94.2498 53.5198 94.2498 
      53.4519 94.3162V94.3173C53.1713 94.3196 52.8952 94.3884 52.6473 
      94.5177C52.5806 94.5177 52.5128 94.5841 52.3795 94.5841H52.3783C50.3889 
      95.353 48.4792 96.3152 46.678 97.4569C44.6663 98.6591 41.8489 100.33 
      40.7764 99.929C39.5016 99.5282 38.228 96.5214 37.289 94.2509V94.2498C36.2785 
      91.1336 34.4482 88.3421 31.991 86.1671C31.1841 85.5649 30.2753 85.1117 29.3081 
      84.8309C28.9526 84.6504 28.5654 84.5362 28.1678 84.4966C26.3199 84.0399 24.4346 
      83.7487 22.5342 83.6287C20.1203 83.4283 16.8339 83.0939 16.0959 82.0921L16.0955 
      82.0921Z"
      />
    </svg>
  );
}
