import React, { useMemo, useState, useCallback } from 'react';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import Loading from '../Loading/Loading';
import StyledError from '../Error/StyledError';
import FormatUtil from '../../utils/formatUtil';
import AdminEmailButtons from './CybraryAdminEmailButtons';
import Agents from '../../agents/agents';
import Button from '../Button/Button';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import Header from '../Header/Header';
import If from '../If/If';
import WidgetContainer from '../Container/WidgetContainer';

const breadCrumbs = [
  {
    href: '/admin',
    label: 'Admin',
  },
  {
    href: '/admin/user',
    label: 'Browse User',
  },
];

function CybraryAdminMemberDashboardHeadingContainer({ children, name }) {
  const crumbs = useMemo(() => {
    return [
      ...breadCrumbs,
      {
        label: name || 'User',
      },
    ];
  }, [name]);
  return (
    <WidgetContainer className="pb-4" omitBorder omitBackground>
      <Breadcrumbs crumbs={crumbs} wrapperClasses="pb-6" />
      {children}
    </WidgetContainer>
  );
}

function CybraryAdminMemberDashboardHeadingComponent({ loading, error, data, adminStore, commonStore }) {
  const [disableButtonLoading, setDisableButton] = useState(false);

  const accountCreated = useMemo(() => {
    if (data?.registered_at) {
      return FormatUtil.convertUtcToLocal(data.registered_at).format('MMM Do, YYYY');
    }
    return null;
  }, [data]);

  const accountLoggedIn = useMemo(() => {
    if (data?.registered_at) {
      return moment(data.last_login).format('MMM Do, YYYY');
    }
    return null;
  }, [data]);

  const primaryEmailState = useMemo(() => {
    let verified = false;
    let disabled = false;
    let isFirebaseUser = false;
    if (data && data.userEmails && data.userEmails.length) {
      data.userEmails.forEach((email) => {
        if (email.email !== data.email) {
          return;
        }
        if (email.verified) {
          verified = true;
        }
        if (email.firebaseData) {
          isFirebaseUser = true;
        }
        if (email.firebaseData && email.firebaseData.disabled) {
          disabled = true;
        }
      });
    }
    return { verified, disabled, isFirebaseUser };
  }, [data]);

  const disableText = useMemo(() => `${!primaryEmailState.disabled ? 'Disable' : 'Activate'} User`, [primaryEmailState]);

  const name = useMemo(() => {
    if (data?.name) {
      return data.name;
    }
    if (data?.real_name) {
      return data.real_name;
    }
    if (data?.first_name) {
      return `${data.first_name} ${data.last_name}`.trim();
    }
    return 'User';
  }, [data]);

  const updateUserStatus = useCallback(async (userId, disabled) => {
    setDisableButton(true);
    try {
      await Agents.admin.disableUser(userId, { disabled: !!disabled });
      adminStore.getUserData(userId);
      commonStore.triggerToast('success', {
        content: `User has been ${disabled ? 'disabled' : 'activated'} successfully`,
      });
    } catch (e) {
      commonStore.triggerToast('error', {
        content: 'Something went wrong. Please try again.',
      });
    }
    setDisableButton(false);
  }, []);

  if (loading) {
    return (
      <CybraryAdminMemberDashboardHeadingContainer name={name}>
        <Loading message="Loading..." />
      </CybraryAdminMemberDashboardHeadingContainer>
    );
  }

  if (error) {
    return (
      <CybraryAdminMemberDashboardHeadingContainer name={name}>
        <StyledError error={error} />
      </CybraryAdminMemberDashboardHeadingContainer>
    );
  }
  return (
    <div className="header-container">
      <CybraryAdminMemberDashboardHeadingContainer name={name}>
        <div className="flex justify-between items-center w-full">
          <Header as="h1" className="mb-2">
            {data.real_name ? data.real_name : data.name}
          </Header>
          <div>
            <If condition={!primaryEmailState.isFirebaseUser}>
              <AdminEmailButtons text="Password Reset" email={data.email} commonStore={commonStore} sendEmail={Agents.authGoogle.passwordResetEmail} />
            </If>
            <If condition={primaryEmailState.isFirebaseUser}>
              <Button className="ml-3" color="gray" loading={disableButtonLoading} onClick={() => updateUserStatus(data.id, !primaryEmailState.disabled)}>
                {disableText}
              </Button>
            </If>
          </div>
        </div>
        <div className="md:grid md:grid-cols-2">
          <div className="flex flex-col header-info">
            <If condition={data.job_title}>
              <span className="profile-title">{data.job_title}</span>
            </If>

            <If condition={data.email}>
              <div className="user-detail">
                <span className="font-bold">Email:</span> {data.email}
              </div>
            </If>

            <If condition={data.username}>
              <div className="user-detail">
                <span className="font-bold">Username:</span> {data.username}
              </div>
            </If>

            <div className="user-detail">
              <span className="font-bold">User ID:</span> {data.id}
            </div>

            <If condition={data.member_status}>
              <div className="user-detail">
                <span className="font-bold">Status:</span> {data.member_status}
              </div>
            </If>
          </div>
          <div className="flex flex-col profile-data sub-data">
            <If condition={data.last_login}>
              <div className="user-detail">
                <span className="font-bold">Last Login: </span> {accountLoggedIn}
              </div>
            </If>
            <If condition={data.created_at || data.registered_at}>
              <div className="user-detail">
                <span className="font-bold">Account Creation: </span> {accountCreated}
              </div>
            </If>
          </div>
        </div>
      </CybraryAdminMemberDashboardHeadingContainer>
    </div>
  );
}

const CybraryAdminMemberDashboardHeading = inject('adminStore', 'commonStore')(observer(CybraryAdminMemberDashboardHeadingComponent));

export default CybraryAdminMemberDashboardHeading;
