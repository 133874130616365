import React from 'react';

export default function Ribbon({ classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';
  return (
    <svg className={className} viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...accessibilityProps}>
      <path
        d="M7.02271 2.53125C5.21387 2.53125 3.74219 4.00293 3.74219 5.81177C3.74219 7.62074 5.21387 9.09242 7.02271 9.09242C8.83167 9.09242 10.3034 7.62074 10.3034 
        5.81177C10.3034 4.00293 8.83167 2.53125 7.02271 2.53125ZM7.02271 8.14027C5.73877 8.14027 4.69422 7.09571 4.69422 5.81177C4.69422 4.52796 5.73889 3.4834 7.02271 
        3.4834C8.30665 3.4834 9.35121 4.52796 9.35121 5.81177C9.35121 7.09571 8.30665 8.14027 7.02271 8.14027Z"
        fill="black"
      />
      <path
        d="M13.0545 5.59168L12.2826 4.10157L12.0312 2.44239C12.008 2.28943 11.912 2.15735 11.7737 2.08814L10.2735 1.33631L9.09477 0.141727C8.98613 0.0316201 8.83098 
        -0.0187949 8.67827 0.00635156L7.02262 0.279911L5.36698 0.00635156C5.21439 -0.0187949 5.05912 0.0316201 4.95048 0.141727L3.77189 1.33619L2.27152 2.08814C2.13333 
        2.15735 2.03726 2.28943 2.01419 2.44239L1.76273 4.10144L0.990875 5.59156C0.919708 5.72888 0.919708 5.89221 0.990875 6.02954L1.76273 7.51966L2.01419 9.17883C2.03726 
        9.33167 2.13333 9.46375 2.27152 9.53308L3.77189 10.285L3.99698 10.5132V15.5239C3.99698 15.689 4.08243 15.8422 4.22281 15.9288C4.29923 15.9762 4.38602 16 4.47306 
        16C4.54581 16 4.61881 15.9833 4.68595 15.9497L7.02262 14.7814L9.3593 15.9497C9.50688 16.0234 9.68217 16.0156 9.82243 15.9288C9.96281 15.8422 10.0483 15.6888 10.0483 
        15.5239V10.5133L10.2735 10.285L11.7737 9.53308C11.912 9.46387 12.008 9.33179 12.0312 9.17883L12.2826 7.51978L13.0545 6.02954C13.1255 5.89221 13.1255 5.72888 13.0545 
        5.59168ZM9.09611 14.7537L7.23564 13.8234C7.10148 13.7563 6.94377 13.7563 6.80973 13.8234L4.94913 14.7537V11.4782L4.95048 11.4795C5.05912 11.5896 5.21439 11.6401 
        5.36698 11.6149L7.02262 11.3413L8.67827 11.6149C8.70415 11.6191 8.73015 11.6212 8.7559 11.6212C8.88225 11.6212 9.00456 11.5709 9.09477 11.4796L9.09611 
        11.4782V14.7537ZM11.401 7.15161C11.377 7.19788 11.3608 7.24768 11.353 7.2992L11.1268 8.79236L9.77653 9.46912C9.73003 9.49243 9.68755 9.52319 9.65105 9.5603L8.59025 
        10.6353L7.10026 10.389C7.04899 10.3806 6.9965 10.3805 6.94499 10.389L5.45499 10.6353L4.81022 9.98181C4.80985 9.98145 4.80961 9.9812 4.80924 9.98084L4.39432 
        9.5603C4.3577 9.52319 4.31534 9.49243 4.26871 9.46912L2.91849 8.79236L2.69217 7.29932C2.68436 7.2478 2.66825 7.19788 2.6442 7.15161L1.94962 5.81067L2.6442 
        4.46961C2.66825 4.42334 2.68436 4.37354 2.69217 4.32202L2.91849 2.82886L4.26871 2.15222C4.31534 2.12891 4.3577 2.09803 4.39432 2.06092L5.45499 0.985966L6.94499 
        1.23218C6.99638 1.2406 7.04887 1.2406 7.10026 1.23218L8.59013 0.985966L9.65092 2.06092C9.68755 2.09803 9.73003 2.12891 9.77653 2.15222L11.1266 2.82886L11.353 
        4.32202C11.3608 4.37354 11.377 4.42334 11.4009 4.46961L12.0955 5.81067L11.401 7.15161Z"
        fill="black"
      />
    </svg>
  );
}
