/* eslint-disable no-unused-vars */
import React, { useMemo, useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { ResponsiveLine } from '@nivo/line';
import moment from 'moment';
import Tooltip from '../../Tooltip/Tooltip';
import Icon from '../../Icon/Icon';
import StyleUtil from '../../../utils/styleUtil';
import Loading from '../../Loading/Loading';
import StyledError from '../../Error/StyledError';
import Container from '../../Container/Container';
import If from '../../If/If';
import { useCybAssessmentPath } from '../../../providers/CybAssessmentPathProvider';
import BaselineEmptyText from './BaselineEmptyText';
import { ChartSliceTooltip } from '../shared';

const graphColorDefaults = StyleUtil.getGraphColorDefaults();

const styleById = {
  'Community Score': {
    strokeDasharray: '8, 12',
    strokeWidth: 2,
    strokeLinecap: 'round',
  },
  default: {
    strokeWidth: 2,
  },
};

const DashedLine = ({ series, lineGenerator, xScale, yScale }) => {
  return series.map(({ id, data, color }) => (
    <path
      key={id}
      d={lineGenerator(
        data.map((d) => ({
          x: xScale(d.data.x),
          y: yScale(d.data.y),
        }))
      )}
      fill="none"
      stroke={color}
      style={styleById[id] || styleById.default}
    />
  ));
};

const BaselineTeamsOverviewChart = inject('userStore')(
  observer(({ userStore }) => {
    const {
      cumulativeSummaries,
      isLoadingCumulativeSummaries,
      cumulativeSummariesError,
      actions: { getCumulativeSummary },
    } = useCybAssessmentPath();

    const orgId = userStore?.adminTeam?.id;

    const assessmentPathsCumulativeSummary = cumulativeSummaries[orgId];

    const chartData = useMemo(() => {
      const initialState = [
        {
          id: 'Company Score',
          data: [],
          color: graphColorDefaults.primaryColors[1],
        },
        {
          id: 'Community Score',
          data: [],
          color: graphColorDefaults.primaryColors[0],
        },
      ];

      return assessmentPathsCumulativeSummary?.default?.reduce((result, item) => {
        const companyScore = Math.round(parseFloat(item.company_score));
        const companyScoreY = Number(companyScore);

        const communityScore = Math.round(parseFloat(item.community_score));
        const communityScoreY = Number(communityScore);

        result[0].data.push({
          x: moment(item.period).format('YYYY-MM-DD'),
          y: companyScoreY,
        });

        result[1].data.push({
          x: moment(item.period).format('YYYY-MM-DD'),
          y: communityScoreY,
        });

        return result;
      }, initialState);
    }, [assessmentPathsCumulativeSummary?.default]);

    const gridXValues = useMemo(() => chartData?.[0]?.data?.map((struct) => moment(struct.x).toDate()), [chartData]);

    useEffect(() => {
      if (!assessmentPathsCumulativeSummary) {
        getCumulativeSummary(orgId);
      }
    }, [assessmentPathsCumulativeSummary]);

    if (isLoadingCumulativeSummaries) {
      return (
        <Container>
          <Loading message="Loading..." />
        </Container>
      );
    }

    if (cumulativeSummariesError) {
      return (
        <Container>
          <StyledError error={cumulativeSummariesError} />
        </Container>
      );
    }

    if (!assessmentPathsCumulativeSummary) {
      return (
        <Container className="mt-10">
          <BaselineEmptyText orgId={orgId} />
        </Container>
      );
    }

    return (
      <div className="relative">
        <div className="flex flex-row justify-between items-center mr-[40px]">
          <h3 className=" font-bold">Company performance over time</h3>
          <Tooltip
            maxWidth={320}
            content={<span className="group flex w-full font-semibold text-center">The average of your Team’s best attempts across all Assessment Topics</span>}
          >
            <div className="flex justify-center items-center h-full">
              <Icon name="question-circle" className="w-5 h-5 text-stone-400 hover:text-black" />
            </div>
          </Tooltip>
        </div>
        <div className="relative w-full h-[300px]">
          <ResponsiveLine
            animate
            margin={{ top: 30, right: 40, bottom: 50, left: 60 }}
            data={chartData}
            curve="monotoneX"
            xFormat="time:%Y-%m-%d"
            xScale={{
              format: '%Y-%m-%d',
              precision: 'month',
              type: 'time',
              useUTC: false,
            }}
            yFormat=" >-.2f"
            yScale={{
              type: 'linear',
              min: 0,
              max: 100,
            }}
            axisBottom={{
              tickSize: 10,
              tickPadding: 10,
              tickRotation: 0,
              format: '%b %d %Y',
              tickValues: 'every month',
            }}
            axisLeft={{
              tickSize: 0,
              tickPadding: 10,
              tickRotation: 0,
              tickValues: [0, 20, 40, 60, 80, 100],
              format: (value) => `${value}%`,
            }}
            sliceTooltip={ChartSliceTooltip}
            layers={['grid', 'markers', 'areas', DashedLine, 'slices', 'points', 'axes', 'crosshair']}
            colors={{ datum: 'color' }}
            pointSize={10}
            pointColor="#FFFFFF"
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            gridXValues={gridXValues}
            crosshairType="cross"
            enableSlices="x"
            useMesh
          />
        </div>
        <If condition={chartData?.length > 0}>
          <div className="flex flex-row items-start ml-9">
            <div className="flex flex-row items-center mr-3">
              <div className="mr-1 w-2 h-2 bg-[#4B7BEC] rounded-full" />
              <span className="text-xs text-gray-600">Company Score</span>
            </div>
            <div className="flex flex-row items-center">
              <div className="mr-1 w-2 h-2 rounded-full border border-[#FC5C65] border-dashed" />
              <span className="text-xs text-gray-600">Community Score</span>
            </div>
          </div>
        </If>
      </div>
    );
  })
);

export default BaselineTeamsOverviewChart;
