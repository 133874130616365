import React, { useMemo, useCallback } from 'react';
import { observer, inject } from 'mobx-react';
import { twMerge } from 'tailwind-merge';
import Dropdown from '../../Dropdown/Dropdown';
import { useCybAssessmentAnalytics } from '../../../providers/CybAssessmentAnalyticsProvider';

/**
 * Converts tracks data to dropdown options
 * @param {*} tracks Tracks from api
 * @returns {array} - Dropdown options [{ key, text, value }, ...]
 */
function transformTracksToDropdownOptions(tracks) {
  const options = tracks?.map((track) => {
    const { 'content-id': id, 'content-name': name } = track;
    return {
      key: id,
      text: name,
      value: id,
    };
  });
  return options || [];
}

/**
 * Dropdown component for selecting a job role for the Cybrary Assessments widget
 * @returns <CybAssessmentsRoleDropdown />
 */
const RoleDropdown = inject('userStore')(
  observer(({ userStore, optionData, controlledValue, onChange, isCompact, wrapperClasses }) => {
    const mergedWrapperClasses = useMemo(() => twMerge('flex flex-col justify-center items-center', wrapperClasses), [wrapperClasses]);

    const { tracks, actions, currentTrack } = useCybAssessmentAnalytics();

    const authUserTracks = tracks[userStore.user?.id];

    const options = useMemo(() => transformTracksToDropdownOptions(optionData ?? authUserTracks), [authUserTracks, optionData]);

    const onChangeHandler = useCallback(
      ({ value }) => {
        if (onChange) {
          return onChange(value);
        }
        return actions.setCurrentTrackById(value);
      },
      [onChange, actions.setCurrentTrackById]
    );

    const value = useMemo(() => controlledValue?.id ?? controlledValue?.['content-id'] ?? currentTrack?.['content-id'], [controlledValue, currentTrack]);

    return (
      <div className={mergedWrapperClasses}>
        <Dropdown
          ariaLabel="Select a job role"
          options={options}
          classes="text-xs sm:my-0 rounded z-20 min-w-[250px]"
          value={value}
          onChange={onChangeHandler}
          hideSelectedOptions={false}
          isCompact={isCompact}
        />
      </div>
    );
  })
);

export default RoleDropdown;
