import React from 'react';
import UserOnboardingStep from '../../UserOnboardingStep';
import { RECOMMENDATIONS_OPTIONS, USER_ONBOARDING_STEPS, useUserOnboarding } from '../../../../providers/UserOnboardingProvider';

function RecommendationsStep() {
  const { setCipGoal, setStep } = useUserOnboarding();

  const handleStep = async (goal) => {
    setCipGoal(goal.value);
    setStep(USER_ONBOARDING_STEPS.SELECT_PACKAGE);
  };

  return (
    <UserOnboardingStep title="What do you want to accomplish?">
      <div className="flex flex-col justify-center items-center md:flex-row">
        {Object.values(RECOMMENDATIONS_OPTIONS).map((goal) => (
          <UserOnboardingStep.Button key={goal.key} title={goal.title} icon={goal.icon} onClick={() => handleStep(goal)} className="justify-center mb-8 w-64 h-64" />
        ))}
      </div>
    </UserOnboardingStep>
  );
}

export default RecommendationsStep;
