import React from 'react';
import AddLink from '../AddLink/AddLink';

/**
 * Empty state for the continue learning block's tabs.
 * @param {string} title the title to show
 * @param {string} body the body text to show
 * @returns <ContinueLearningNoContent />
 */
export default function ContinueLearningNoContent({ title = 'No content found!', body = 'You have no current courses to show here.' }) {
  return (
    <div className="flex flex-col justify-center items-center pt-4 mb-2 w-full text-center">
      <h3 className="mb-0 text-lg font-bold">{title}</h3>
      <p className="mb-0 text-sm text-gray-600">{body}</p>
      <p className="mb-4 text-sm text-gray-600">Want to start something new instead?</p>
      <AddLink to="/browse/refined" className="py-2 px-4 text-sm text-white bg-cyb-pink-500 rounded">
        Explore Catalog
      </AddLink>
    </div>
  );
}
