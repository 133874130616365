import React from 'react';

function XP({ classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';

  return (
    <svg className={className} viewBox="0 0 14 8" xmlns="http://www.w3.org/2000/svg" {...accessibilityProps}>
      <path
        d="M2.4905 0.727273L3.68368 2.84375H3.7405L4.94789 0.727273H7.14959L4.9763 4.36364L7.23482 8H4.9763L3.7405 5.84091H3.68368L2.44789 8H0.203569L2.43368 4.36364L0.274592 0.727273H2.4905ZM8.00098 8V0.727273H11.1402C11.68 0.727273 12.1523 0.833807 12.5571 1.04688C12.9619 1.25994 13.2768 1.55942 13.5017 1.94531C13.7266 2.3312 13.839 2.7822 13.839 3.2983C13.839 3.81913 13.723 4.27012 13.491 4.65128C13.2614 5.03243 12.9382 5.32599 12.5216 5.53196C12.1073 5.73793 11.6231 5.84091 11.0692 5.84091H9.19416V4.30682H10.6714C10.9034 4.30682 11.1011 4.26657 11.2645 4.18608C11.4302 4.10322 11.5568 3.98603 11.6444 3.83452C11.7344 3.683 11.7794 3.50426 11.7794 3.2983C11.7794 3.08996 11.7344 2.91241 11.6444 2.76562C11.5568 2.61648 11.4302 2.50284 11.2645 2.42472C11.1011 2.34422 10.9034 2.30398 10.6714 2.30398H9.97541V8H8.00098Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default XP;
