import React, { lazy, Suspense } from 'react';
import CountUp from 'react-countup';
import PercentageBar from './PercentageBar';
import Icon from '../Icon/Icon';
import Loading from '../Loading/Loading';
import StyledError from '../Error/StyledError';
import AddLink from '../AddLink/AddLink';
import StyleUtil from '../../utils/styleUtil';
import DoubleChevronRight from '../../Icons/DoubleChevronRight';
import './analytics.css';
import Tooltip from '../Tooltip/Tooltip';
import Header from '../Header/Header';
import Container from '../Container/Container';
import NoResultsMessage from '../NoResultsMessage/NoResultsMessage';

const Doughnut = lazy(() => import('./Chart/Doughnut'));
function SkillFilterLink({ skillName, getAlgoliaFilter, omitSkillNameInText }) {
  if (!skillName) {
    return null;
  }
  // format for search filters -- "Attack and Exploitation" --> "skillset|attack-and-exploitation"
  const skillFilter = getAlgoliaFilter(skillName);
  const linkText = omitSkillNameInText ? 'related' : skillName;
  return (
    <AddLink className="text-cyb-pink-500 hover:text-black underline" target="_blank" to={`/browse/refined/?terms_info=${skillFilter}`}>
      See all {linkText} content <DoubleChevronRight classes="inline-block w-3 h-3" />
    </AddLink>
  );
}
const getProficiencyTooltip = (score) => {
  const tooltips = {
    Novice: (
      <p>
        A novice user has little knowledge in the skillset that was assessed. Click{' '}
        <AddLink className="text-cyb-pink-500 hover:text-black underline" target="_blank" to="http://help.cybrary.it/hc/en-us/articles/360057227934-Skill-Assessments">
          here
        </AddLink>{' '}
        for more information.
      </p>
    ),
    Proficient: (
      <p>
        The user has a high level of knowledge and has mastered or nearly mastered the skillset that was assessed. Click{' '}
        <AddLink className="text-cyb-pink-500 hover:text-black underline" target="_blank" to="http://help.cybrary.it/hc/en-us/articles/360057227934-Skill-Assessments">
          here
        </AddLink>{' '}
        for more information.
      </p>
    ),
    Advanced: (
      <p>
        The user has a solid knowledge base but has not mastered the skillset that was assessed. Click{' '}
        <AddLink className="text-cyb-pink-500 hover:text-black underline" target="_blank" to="http://help.cybrary.it/hc/en-us/articles/360057227934-Skill-Assessments">
          here
        </AddLink>{' '}
        for more information.
      </p>
    ),
    Intermediate: (
      <p>
        An intermediate user has some working knowledge but needs more training to grasp all relevant concepts of the skillset that was assessed. Click{' '}
        <AddLink className="text-cyb-pink-500 hover:text-black underline" target="_blank" to="http://help.cybrary.it/hc/en-us/articles/360057227934-Skill-Assessments">
          here
        </AddLink>{' '}
        for more information.
      </p>
    ),
  };
  return tooltips[score];
};
const transformData = (data) => {
  const transformedData = {
    title: data.title,
    score: [],
  };
  // Need to do a deep clone
  const graphColors = JSON.parse(JSON.stringify(StyleUtil.getGraphColorDefaults()));
  transformedData.graphData = {
    labels: [],
    datasets: [
      {
        label: 'Score',
        fill: false,
        borderWidth: 1,
        data: [],
      },
    ],
  };
  // Set up graph data for doughnut graph
  data.score.forEach((item) => {
    // If the term is null, it's the overall data
    if (!item.term) {
      transformedData.overall = { ...item };
      transformedData.overall.scorePercent = Math.floor((item.points_earned / item.points_possible) * 100);
    } else {
      transformedData.graphData.labels.push(item.term);
      transformedData.graphData.datasets[0].data.push(item.points_earned);
      const transformedItem = { ...item };
      transformedItem.scorePercent = Math.floor((item.points_earned / item.points_possible) * 100);
      transformedItem.skillColor = graphColors.primaryColors[transformedData.score.length];
      transformedData.score.push(transformedItem);
    }
  });
  // Add in the total incorrect to the end
  transformedData.graphData.datasets[0].data.push(transformedData.overall.points_possible - transformedData.overall.points_earned);
  transformedData.graphData.labels.push('Incorrect');
  graphColors.primaryColors[transformedData.graphData.labels.indexOf('Incorrect')] = '#eaeaea';
  transformedData.graphData.datasets[0].backgroundColor = graphColors.primaryColors;
  transformedData.graphData.datasets[0].borderColor = graphColors.primaryColors;
  return transformedData;
};
const getAlgoliaFilter = (skill) => {
  return `skillset|${skill.toLowerCase().split(' ').join('-')}`;
};
function WorkroleReport(props) {
  if (props.loading) {
    return (
      <Container>
        <Loading message="Loading..." />
      </Container>
    );
  }
  if (props.error) {
    return (
      <Container>
        <StyledError error={props.error} />
      </Container>
    );
  }
  const emptyData = (
    <Container className="p-4 mt-4">
      <NoResultsMessage message="We're sorry, there is no report information to display at this time" />
    </Container>
  );
  if (!props.data) {
    return emptyData;
  }
  const transformedData = transformData(props.data);
  return (
    <div className="pt-8 mx-8 workrole-report">
      <div className="md-991:grid md-991:grid-cols-3 md-991:grid-flow-row summary-row">
        <div className="flex items-center">
          <h2 id="workrole-title" className="text-2xl font-black">
            {transformedData.title}
          </h2>
        </div>
        <div className="flex justify-start items-center md:justify-center">
          <div className="w-full doughnut-container">
            <Suspense fallback={<Loading message="Loading..." />}>
              <Doughnut
                legend={{
                  display: false,
                  position: 'right',
                  labels: {
                    boxWidth: 15,
                  },
                }}
                data={transformedData.graphData}
                options={{
                  animation: {
                    duration: 1000,
                    easing: 'linear',
                  },
                  cutoutPercentage: 70,
                  responsive: true,
                  maintainAspectRatio: false,
                }}
              />
            </Suspense>
          </div>
          <div className="score-proficiency-container">
            <span className="score">{transformedData.overall.scorePercent}</span>
            <span className="proficiency">{transformedData.overall.grade}</span>
          </div>
        </div>
        <div className="tooltip-container">
          {!props.adminMemberDashboard ? (
            <Tooltip interactive position="bottom-end" html={getProficiencyTooltip(transformedData.overall.grade)}>
              <Icon name="question-circle" className="float-right w-6 h-6 text-gray-700 hover:text-black" />
            </Tooltip>
          ) : null}
        </div>
      </div>
      {transformedData.score.map((skill) => {
        return (
          <div className="mb-4 md-991:grid md-991:grid-cols-6 md-991:gap-8 workrole-skill-container" key={skill.term}>
            <div className="md-991:col-span-3">
              <div>
                <Header as="h3" className="mb-0 text-xl font-bold">
                  {skill.term}
                </Header>
                {skill.term && !props.adminMemberDashboard ? <SkillFilterLink skillName={skill.term} getAlgoliaFilter={getAlgoliaFilter} omitSkillNameInText /> : null}
                <p className="skill-description">{skill.term_description}</p>
              </div>
            </div>
            <div className="md-991:col-span-2">
              <PercentageBar color={skill.skillColor} maxHeight="2em" width={`calc(${skill.scorePercent}% - 2em)`} />
              <span className="score">
                <CountUp end={skill.points_earned} duration={2} />/{skill.points_possible}
              </span>
            </div>
            <div>
              <span className="proficiency">{skill.grade}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
}
export default WorkroleReport;
