import React from 'react';

export default function Mic({ classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';
  return (
    <svg
      clipRule="evenodd"
      fillRule="evenodd"
      className={className}
      {...accessibilityProps}
      imageRendering="optimizeQuality"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      viewBox="0 0 4335 4335"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Layer_x0020_1">
        <path d="m2996 4335h-1657c-59 0-94-67-60-116l193-282c13-20 36-32 60-32h408v-591c-702-109-1240-718-1240-1450v-211c0-41 33-74 73-74h361v-545c0-570 463-1034 1033-1034s1034 464 1034 1034v545h361c40 0 73 33 73 74v211c0 732-538 1341-1240 1450v591h407c25 0 48 12 61 32l188 275c46 58 1 123-55 123zm-978-1159c148 10 145 14 299 0 658-74 1171-634 1171-1312v-137h-287v159c0 570-464 1034-1034 1034s-1033-464-1033-1034v-159h-287v137c0 678 513 1238 1171 1312zm69 154v575h161v-575zm80-3183c-332 0-621 184-773 455h1547c-152-271-442-455-774-455zm-839 602c-26 77-42 160-46 245h507c97 0 97 147 0 147h-508v245h508c97 0 97 148 0 148h-508v245h508c97 0 97 147 0 147h-507c4 85 20 167 46 245h461c97 0 97 147 0 147h-395c339 604 1208 604 1547 0h-395c-97 0-97-147 0-147h461c26-78 42-160 46-245h-507c-97 0-97-147 0-147h508v-245h-508c-97 0-97-148 0-148h508v-245h-508c-97 0-97-147 0-147h507c-4-85-20-168-46-245zm151 3438h1377l-92-135h-1193z" />
      </g>
    </svg>
  );
}
