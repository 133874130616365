import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Loading from '../Loading/Loading';
import StyledError from '../Error/StyledError';
import AssessmentSummaryHeader from './modules/AssessmentSummaryHeader';
import AssessmentSummaryScore from './modules/AssessmentSummaryScore';
import AssessmentSummaryRecommendedLearning from './modules/AssessmentSummaryRecommendedLearning';
import { useCybAssessmentAnalytics } from '../../providers/CybAssessmentAnalyticsProvider';
import ActionUtil from '../../utils/actionsUtil';
import useQueryParams from '../../hooks/useQueryParams';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import If from '../If/If';

function AssessmentSummary({ pathId, contentId, userId, orgId, breadcrumbs }) {
  const { isLoading, error, currentTrack, currentAssessment, actions, tracks, isLoadingAssessmentAttempts } = useCybAssessmentAnalytics();
  const { trackId, assessmentId } = useParams();
  const { fromImmersive } = useQueryParams();

  // Use passed in props (if any) or url params (if any) to initialize the currentTrack and currentAssessment for this view
  useEffect(() => {
    actions.setCurrentAssessmentById(pathId ?? trackId, contentId ?? assessmentId, userId);
    ActionUtil.scrollToTop();
  }, [pathId, trackId, contentId, assessmentId, userId, tracks]);

  useEffect(() => {
    /**
     * If we're coming from an immersive assessment, then load the RECENT tracks data
     */
    if (fromImmersive) {
      actions.loadTracks({ recent: true });
    } else if (userId) {
      actions.loadTracks({ userId });
    } else if (!tracks?.length && !isLoading) {
      /**
       * If we don't have any tracks data, and we're not already loading,
       * then load the BEST tracks data
       */
      actions.loadTracks();
    }

    /**
     * Reload our tracks data when we leave this page if:
     * 1. We're coming from an immersive assessment
     * 2. We're viewing the AssessmentSummary for a specific user on our team
     */
    return () => {
      if (fromImmersive || userId) {
        actions.loadTracks();
      }
    };
  }, [userId]);

  useEffect(() => {
    const targetTrackId = pathId ?? trackId;
    const targetAssessmentId = contentId ?? assessmentId;

    if (targetTrackId && targetAssessmentId) {
      if (orgId && userId) {
        actions.getMemberAssessmentAttempts(targetTrackId, targetAssessmentId, userId, orgId);
      } else {
        actions.getOwnAssessmentAttempts(targetTrackId, targetAssessmentId, fromImmersive);
      }
    }
  }, [pathId, trackId, contentId, assessmentId, orgId, userId]);

  if ((isLoading || (fromImmersive && isLoadingAssessmentAttempts)) && !tracks?.length) {
    return <Loading message="Loading..." />;
  }

  if (error) {
    return (
      <div>
        <StyledError error={error} />
      </div>
    );
  }

  if (!currentAssessment || !currentTrack) {
    return null;
  }

  return (
    <div id="Assessment-Summary" className="flex flex-col grow py-6">
      <If condition={Boolean(breadcrumbs)}>
        <Breadcrumbs crumbs={breadcrumbs} wrapperClasses="pb-8" />
      </If>
      {/* Header */}
      <AssessmentSummaryHeader userId={userId} orgId={orgId} />
      {/* First Row */}
      <div className="flex flex-col w-full md:flex-row">
        {/* Score */}
        <AssessmentSummaryScore />
        <div className="grow w-6" />
        {/* Recommended Learning */}
        <AssessmentSummaryRecommendedLearning />
      </div>
    </div>
  );
}

export default AssessmentSummary;
