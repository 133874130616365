import React from 'react';
import ExampleSection from './ExampleSection';
import Header from '../../../components/Header/Header';
import Button from '../../../components/Button/Button';
import Code from '../../../components/Code/Code';
import commonStore from '../../../stores/commonStore';
import AddLink from '../../../components/AddLink/AddLink';

function ToastsExampleSection() {
  const showSuccessToast = () => {
    commonStore.triggerToast('success', {
      header: 'Success!',
      content: 'Your action was completed successfully.',
      icon: 'thumbs-up',
      color: 'green',
    });
  };

  const showErrorToast = () => {
    commonStore.triggerToast('error', {
      header: 'Error',
      content: 'Something went wrong. Please try again.',
      icon: 'thumbs-down',
      color: 'red',
    });
  };

  const showInfoToast = () => {
    commonStore.triggerToast('info', {
      header: 'Notification',
      content: 'Check your notifications for important updates.',
      icon: 'bell',
      color: 'blue',
    });
  };

  const showCustomToast = () => {
    commonStore.triggerToast(
      'info',
      {
        header: 'A Member is requesting to join your Team.',
        content: (
          <div>
            Click{' '}
            <AddLink to="/team/123/members" className="underline">
              here
            </AddLink>{' '}
            to review the request.
          </div>
        ),
      },
      10000
    ); // Custom duration of 10 seconds
  };

  return (
    <ExampleSection
      title="Toasts"
      description="Toast notifications for displaying temporary messages to users. Toasts automatically dismiss after a set duration (default 6 seconds)."
    >
      <Header as="h2">Basic Toasts</Header>
      <div className="flex gap-2 mb-2">
        <Button color="green" onClick={showSuccessToast}>
          Show Success Toast
        </Button>
        <Button color="red" onClick={showErrorToast}>
          Show Error Toast
        </Button>
        <Button color="gray" onClick={showInfoToast}>
          Show Info Toast
        </Button>
      </div>
      <Code>
        {`
// Success Toast
commonStore.triggerToast('success', {
  header: 'Success!',
  content: 'Your action was completed successfully.',
  icon: 'thumbs-up',
  color: 'green'
});

// Error Toast
commonStore.triggerToast('error', {
  header: 'Error',
  content: 'Something went wrong. Please try again.',
  icon: 'thumbs-down',
  color: 'red'
});

// Info Toast
commonStore.triggerToast('info', {
  header: 'Notification',
  content: 'Check your notifications for important updates.',
  icon: 'info',
  color: 'blue'
});`}
      </Code>

      <Header as="h2">Custom Duration</Header>
      <div className="flex gap-2 mb-2">
        <Button onClick={showCustomToast}>Show Custom Duration Toast</Button>
      </div>
      <Code>
        {`
// Custom Toast with 10 second duration
commonStore.triggerToast('info', {
  header: 'A Member is requesting to join your Team.',
  content: <div>Click <AddLink to="/team/123/members" className="underline">here</AddLink> to review the request.</div>,
}, 10000); // Duration in milliseconds`}
      </Code>
    </ExampleSection>
  );
}

export default ToastsExampleSection;
