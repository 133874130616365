import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import './app-page.css';
import BrowsePromo from '../../components/BrowsePromo/BrowsePromo';
import CareerPathDynamic from '../DynamicCollectionPage/DynamicCollectionPage';
import CertificationPrep from '../../components/Certifications/Certification';
import Container from '../../components/Container/Container';
import Loading from '../../components/Loading/Loading';
import StyledError from '../../components/Error/StyledError';
import withRouter from '../../components/Router/withRouter';

import FormatUtil from '../../utils/formatUtil';
import EnrollmentUtil from '../../utils/enrollmentUtil';
import GaUtil from '../../utils/gaUtil';
import SearchUtil from '../../utils/searchUtil';

const AppPage = inject(
  'catalogStore',
  'commonStore',
  'enrollmentStore',
  'userStore',
  'certificateStore',
  'feedbackStore',
  'authStore'
)(
  observer(
    class AppPage extends Component {
      state = {
        queryID: '',
        objectID: '',
      };

      componentDidMount() {
        const { match, location } = this.props;
        const { params } = match;
        const { id } = params;
        const { search } = location;
        const permalink = this.getPermalinkFromParams(params);
        const errorTrackingPayload = {
          href: window.location.href,
          user: this.props.userStore?.user,
        };

        if (search) {
          const queryParms = queryString.parse(search);
          const { queryID, objectID } = queryParms;
          if (!!queryID && !!objectID) {
            const newState = { ...this.state };
            newState.queryID = queryID;
            newState.objectID = objectID;
            this.setState(newState);
          }
        }

        if (id) {
          this.fetchById(id, errorTrackingPayload);
        } else if (permalink) {
          this.fetchByPermalink(permalink, errorTrackingPayload);
          this.fetchComprehensiveCourses(permalink);
          this.fetchComprehensiveCareerPaths(permalink);
        }
      }

      componentDidUpdate(prevProps) {
        const oldId = prevProps.match.params.id;
        const currId = this.props.match.params.id;
        const errorTrackingPayload = {
          href: window.location.href,
          user: this.props.userStore?.user,
        };

        if (currId && oldId !== currId) {
          this.fetchById(currId, errorTrackingPayload);
        } else {
          // See if we have an old Permalink (instead of an ID)
          const oldParams = prevProps.match.params;
          const currParams = this.props.match.params;
          const oldPermalink = this.getPermalinkFromParams(oldParams);
          const currPermalink = this.getPermalinkFromParams(currParams);
          if (oldPermalink !== currPermalink) {
            this.fetchByPermalink(currPermalink, errorTrackingPayload);
          }
        }
        if (!!this.props.catalogStore && this.props.catalogStore.item) {
          this.props.commonStore.setPageTitle(`${this.props.catalogStore.item.title} | Cybrary`);
        }
        if (!!prevProps.location && prevProps.location.search) {
          const datedQueryParms = queryString.parse(prevProps.location.search);
          const { queryID, objectID } = datedQueryParms;
          if (queryID !== this.state.queryID || objectID !== this.state.objectID) {
            const updateState = { ...this.state };
            updateState.queryID = queryID;
            updateState.objectID = objectID;
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState(updateState);
          }
        }
      }

      componentWillUnmount() {
        const updateState = { ...this.state };
        updateState.queryID = '';
        updateState.objectID = '';
        this.setState(updateState);
        // Remove our content description
        this.props.catalogStore.reset();
      }

      getPermalinkFromParams = (params) => {
        return params && typeof params === 'object' && params['*'] ? params['*'] : null;
      };

      fetchComprehensiveCourses = (permalink) => {
        // fetch comprehensive learning courses data
        this.props.catalogStore.getComprehensiveLearningCourses(permalink);
      };

      fetchComprehensiveCareerPaths = (permalink) => {
        // fetch comprehensive learning career paths data
        this.props.catalogStore.getComprehensiveLearningCareerPaths(permalink);
      };

      fetchById = (id, errorTrackingPayload) => {
        // Fetch our content description from the server.
        this.props.catalogStore.getContentDescriptionById(id, errorTrackingPayload).then(this.fetchCallback);
      };

      fetchByPermalink = (permalink, errorTrackingPayload) => {
        // Fetch our content description from the server.
        this.props.catalogStore.getContentDescriptionByPermalink(permalink, errorTrackingPayload).then(this.fetchCallback);
      };

      fetchCallback = (item) => {
        if (item) {
          this.props.authStore.fireAttributionEvent();
          // If we are looking at a cybrary live series or session, go directly to the page for that session/series
          if (item.content_type && ['Cybrary Live Series', 'Cybrary Live Session'].indexOf(item.content_type.nice_name) !== -1) {
            this.props.navigate(`/${item.permalink}`);
          } else {
            this.props.certificateStore.setCertificateFromItem(item.content_item);
            this.props.certificateStore.getCourseProgress(item.id);
            this.props.feedbackStore.setContentDescriptionId(item.id);
            this.props.catalogStore.getCollectionContent(item.id);
          }
        }
      };

      // Item click handler, for display pages with lists of sub items (courses, curricula, etc)
      handleItemClick = (href) => {
        this.props.navigate(href);
      };

      handleEnroll = (item, data) => {
        const { queryID, objectID } = this.state;
        if (queryID && objectID) {
          // send algolia search conversion event on enrollment
          SearchUtil.searchInsightEvent(this.props.userStore.user.id, queryID, objectID);
        }

        if (item) {
          EnrollmentUtil.handleStartEnroll(this.props.userStore, this.props.enrollmentStore, this.props.commonStore, this.props.navigate, item, data);
        }
      };

      // Submit logic for the enrollment form.
      submitEnrollmentForm = (data) => {
        GaUtil.fireEvent('CourseEnrollment', 'PQL', this.props.catalogStore.item.title);
        this.handleEnroll(this.props.catalogStore.item, data);
      };

      // Helper to format a sub-item
      formatSubItem = (lesson) => {
        return {
          icon: FormatUtil.getModalityMeta(lesson.type).icon,
          href: `/browse/${lesson.permalink}`,
          content: {
            title: lesson.title,
            description: FormatUtil.formatLongText(lesson.short_description, 150),
            duration: FormatUtil.formatTime(lesson.duration, 'hma'),
            type: lesson.type,
            is_free: lesson.is_free,
            id: lesson.id,
          },
          experience_points_total: lesson.experience_points_total,
          optional: lesson.optional,
        };
      };

      getCourseDurationByContentType = (learningActivities = []) => {
        const contentTypeDurations = {};
        // if no learning activities do nothing
        if (!learningActivities || !learningActivities.length) {
          return null;
        }

        // loop over each module
        learningActivities.forEach((module) => {
          // check for activities && activities length
          if (module.activities && module.activities.length) {
            // loop over "activity"
            module.activities.forEach((activity) => {
              // convert activity type to more generic type name
              const genericActivityType = FormatUtil.formatContentType(activity.type);
              // if it doesn't exist, add it and initialize the total duration of that activity to 0
              if (!contentTypeDurations[genericActivityType]) {
                contentTypeDurations[genericActivityType] = 0;
              }
              contentTypeDurations[genericActivityType] += activity.duration;
            });
          }
        });

        return contentTypeDurations;
      };

      render() {
        const { userStore, certificateStore, enrollmentStore, catalogStore } = this.props;
        const { userType, isFree, isUserOnActiveTeam } = userStore;
        const store = catalogStore;

        if (store.loading) {
          return (
            <Container>
              <div className="app-page">
                <Loading message="Loading..." wrapperClassName="py-8" />
              </div>
            </Container>
          );
        }

        if (store.error) {
          return (
            <Container>
              <div className="app-page">
                <StyledError error={store.error} />
              </div>
            </Container>
          );
        }

        const { item } = store;
        if (!item) {
          return null;
        }
        let additionalMaterials = '';
        let certificationOfferText = '';

        let type = '';
        if (item.content_type && item.content_type.nice_name) {
          type = FormatUtil.ucwords(item.content_type.nice_name);
        } else if (item.content_type) {
          type = FormatUtil.ucwords(item.content_type.name);
        }

        if (type === 'Career Path' || type === 'Collection' || type === 'Assessment Path') {
          return <CareerPathDynamic />;
        }

        if (type === 'Certification Prep') {
          return <CertificationPrep />;
        }

        const provider = [];
        const providerItem = {};
        if (item.instructors_info && item.instructors_info.length) {
          item.instructors_info.forEach((instructor) => {
            providerItem.type = 'Instructor';
            providerItem.instructor_level = instructor.instructor_level;
            providerItem.image = instructor.thumbnail;
            providerItem.description = instructor.short_bio;
            providerItem.name = instructor.name;
            providerItem.title = instructor.title;
            providerItem.permalink = instructor.permalink;
            provider.push({
              ...providerItem,
            });
          });
        } else if (item.author) {
          providerItem.type = 'Instructor';
          providerItem.image = item.author.avatar_url;
          providerItem.description = item.author.bio;
          providerItem.name = item.author.name;
          provider.push({
            ...providerItem,
          });
        } else if (item.vendor) {
          providerItem.type = 'Vendor';
          providerItem.name = item.vendor.name;
          providerItem.image = item.vendor.thumbnail_url;
          providerItem.description = item.vendor.description;
          providerItem.permalink = item.vendor.permalink;
          providerItem.vendor = item.vendor;
          provider.push({
            ...providerItem,
          });
        }

        // Not including this with above if/else because you can have an alliance with an instructor separately
        if (item.alliances_info && item.alliances_info.length) {
          item.alliances_info.forEach((alliance) => {
            const allianceInfo = {};
            allianceInfo.type = 'Alliance';
            allianceInfo.image = alliance.thumbnail;
            allianceInfo.name = alliance.name;
            allianceInfo.permalink = alliance.permalink;
            provider.push({
              ...allianceInfo,
            });
          });
        }

        const learningActivities = [];
        let meta;
        const contentItem = item.content_item;
        if (contentItem && contentItem.meta) {
          meta = contentItem.meta;
        }

        if (contentItem && contentItem.learning_modules) {
          if (contentItem.recommended_materials) {
            additionalMaterials = contentItem.recommended_materials;
          }
          contentItem.learning_modules.forEach((module) => {
            if (module.activities && module.activities.length) {
              const moduleLessons = [];
              module.activities.forEach((lesson) => {
                if (lesson.id) {
                  moduleLessons.push(this.formatSubItem(lesson));
                }
              });
              learningActivities.push({
                title: module.title,
                lessons: moduleLessons,
                duration: FormatUtil.formatTime(module.duration, 'hma'),
              });
            }
          });
          if (item.has_certificate) {
            certificationOfferText = `This course offers a certificate after completion of the entire course`;
          }
        }
        const contentTypeDurations = this.getCourseDurationByContentType(contentItem.learning_modules);
        const bgImage = `${item.thumbnail_url}?w=800&q=55`;
        const { courseProgress, certificate } = certificateStore;
        const isBookmarked = Array.isArray(userStore.bookmarks) ? userStore.bookmarks.indexOf(item.id) !== -1 : false;
        return (
          <>
            {item.thumbnail_url ? (
              <Helmet>
                <meta property="og:image" content={item.thumbnail_url} />
              </Helmet>
            ) : null}
            <BrowsePromo
              id={item.id}
              type={type}
              title={item.title}
              syllabus={item.syllabus}
              provider={provider}
              providerDesc={FormatUtil.truncateString(provider.description, 20)}
              shortDescription={FormatUtil.formatLongText(item.short_description, 350)}
              description={item.post_content || item.long_description || item.short_description}
              duration={FormatUtil.formatTime(item.duration_seconds, 'hm')}
              prereqs={[]}
              level={item.level ? item.level.name : ''}
              ceu={item.ceu_count}
              certificationOfferText={certificationOfferText}
              certificate={certificate}
              collection={store.collection}
              additionalMaterials={additionalMaterials}
              meta={meta}
              bgImage={bgImage}
              learningActivities={learningActivities}
              handleItemClick={this.handleItemClick}
              handleEnroll={this.handleEnroll}
              item={item}
              courseId={item.courseId}
              courseProgress={courseProgress}
              certificateStore={certificateStore}
              userType={userType}
              userIsFree={isFree}
              isUserOnActiveTeam={isUserOnActiveTeam}
              courseIsFree={item.is_free}
              isBookmarked={isBookmarked}
              feedbackStore={this.props.feedbackStore}
              enrollmentStore={enrollmentStore}
              comprehensiveCourses={store.comprehensiveLearningCourses}
              comprehensiveCareerPaths={store.comprehensiveLearningCareerPaths}
              contentTypeDurations={contentTypeDurations}
            />
          </>
        );
      }
    }
  )
);

export default withRouter(AppPage);
