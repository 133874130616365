import moment from 'moment';

class TimeUtil {
  /**
   * Given a number representing hours, minutes, or seconds;
   * prepend a zero if the time is less than 10. For use in generating human-readable time displays
   *
   * @author EG
   *
   * @param time
   * @returns {*}
   */
  static padTimeWithZero(time) {
    if (time < 10) {
      return `0${time}`;
    }
    return time;
  }

  /**
   * Displays the days, hours, minutes, and seconds duration
   *
   * @author KM
   *
   * @param durationInSeconds
   * @returns object
   */
  static getDHMSForDurationInSeconds(providedDuration) {
    let durationInSeconds = providedDuration;
    const days = Math.floor(durationInSeconds / (3600 * 24));
    durationInSeconds -= days * 3600 * 24;
    const hours = Math.floor(durationInSeconds / 3600);
    durationInSeconds -= hours * 3600;
    const minutes = Math.floor(durationInSeconds / 60);
    durationInSeconds -= minutes * 60;
    const seconds = parseInt(durationInSeconds % 60, 10);

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  }

  /**
   * Displays the hours and minutes duration
   *
   * @author EG
   *
   * @param durationInSeconds
   * @returns object
   */
  static getHMForDurationInSeconds(durationInSeconds) {
    let seconds = durationInSeconds;
    const hours = Math.floor(seconds / 3600);
    seconds -= hours * 3600;
    const minutes = Math.floor(seconds / 60);

    return {
      hours,
      minutes,
    };
  }

  /**
   * Convert duration to human readable display
   *
   * @author EG
   *
   * @param durationInSeconds
   * @returns string span displaying a second duration in HH:MM:SS format, or null if the time is invalid
   */
  static convertDurationToColonDelimitedDisplay(durationInSeconds) {
    if (!durationInSeconds || durationInSeconds < 0) {
      return null;
    }
    const { days, hours, minutes, seconds } = this.getDHMSForDurationInSeconds(durationInSeconds);
    const dayDisplay = days ? `${TimeUtil.padTimeWithZero(days)}:` : '';
    const hourDisplay = hours ? `${TimeUtil.padTimeWithZero(hours)}:` : '';

    const minuteDisplay = `${TimeUtil.padTimeWithZero(minutes)}:`;
    const secondDisplay = TimeUtil.padTimeWithZero(seconds);

    return `${dayDisplay}${hourDisplay}${minuteDisplay}${secondDisplay}`;
  }

  /**
   * Formats hours, minutes, seconds with their respective unit or an empty string
   *
   * @author KM
   *
   * @param count
   * @param unit
   * @returns string
   */
  static createDisplay(count, unit, abbreviated) {
    if (abbreviated) {
      return !count ? '' : `${count}${unit}`;
    }
    const unitString = `${unit}${count > 1 ? 's' : ''}`;
    return count ? `${count} ${unitString}` : '';
  }

  /**
   * Displays the days and hours and minutes duration, or null if duration is invalid
   *
   * @author KM
   *
   * @param durationInSeconds
   * @returns string
   */
  static convertDurationToDaysHoursAndMinutesDisplay(durationInSeconds) {
    if (!durationInSeconds || durationInSeconds < 0) {
      return null;
    }
    const { days, hours, minutes, seconds } = this.getDHMSForDurationInSeconds(durationInSeconds);

    const dayDisplay = TimeUtil.createDisplay(days, 'day');
    const hourDisplay = TimeUtil.createDisplay(hours, 'hour');
    const minuteDisplay = TimeUtil.createDisplay(minutes, 'minute');
    const secondDisplay = TimeUtil.createDisplay(seconds, 'second');

    return durationInSeconds > 60 ? `${dayDisplay} ${hourDisplay} ${minuteDisplay}` : `${secondDisplay}`;
  }

  /**
   * Displays the hours and minutes or null if duration is invalid
   * @param durationInSeconds
   * @returns {*}
   */
  static convertDurationToHoursAndMinutesDisplay(durationInSeconds, abbreviated) {
    if (!durationInSeconds || durationInSeconds < 0) {
      return null;
    }
    const { hours, minutes } = this.getHMForDurationInSeconds(durationInSeconds);

    const hourDisplay = TimeUtil.createDisplay(hours, abbreviated ? 'h' : 'hour', !!abbreviated);
    const minuteDisplay = TimeUtil.createDisplay(minutes, abbreviated ? 'm' : 'minute', !!abbreviated);

    if (hourDisplay || minuteDisplay) {
      return `${hourDisplay} ${minuteDisplay}`;
    }
    return abbreviated ? '1m' : '1 minute';
  }

  /**
   * Determines what interval to use for a given date range - Used for determining graph intervals
   * @param startDate
   * @param endDate
   * @returns {*}
   */
  static getDateRangeInterval(startDate, endDate) {
    const startMoment = moment(startDate);
    const endMoment = moment(endDate);

    const daysBetween = endMoment.diff(startMoment, 'days');
    if (daysBetween <= 1) {
      return 'hour';
    }
    if (daysBetween < 14) {
      return 'day';
    }
    if (daysBetween < 56) {
      return 'week';
    }
    if (daysBetween < 712) {
      return 'month';
    }
    return 'year';
  }

  /**
   * Determines what interval label to use
   * @param startDate
   * @param endDate
   * @returns {*}
   */
  static getMetricIntervalLabel = (period, interval) => {
    if (interval === 'hour') {
      return moment(period).format('h:mm A');
    }
    if (interval === 'day') {
      return moment(period).format('MM/DD/YY');
    }
    if (interval === 'week') {
      return `${moment(period).format('MM/DD/YY')} - ${moment(period).add(7, 'd').format('MM/DD/YY')}`;
    }
    if (interval === 'month') {
      return moment(period).format('MMM');
    }

    return moment(period).format('MMM, YYYY');
  };

  static getTimeRemaining(totalTime, timeCompleted = 0) {
    if (!totalTime) {
      return null;
    }
    return this.convertDurationToHoursAndMinutesDisplay(totalTime - timeCompleted, true);
  }
}

export default TimeUtil;
