import React from 'react';

function Paths({ active }) {
  if (active) {
    return (
      <>
        <path
          d="M13.2499 4H5.74994C4.78497 4 4 4.78497 4 5.74994V10.2499C4 11.2151 4.78497 12.0001 5.74994 12.0001H13.2499C14.2151 12.0001 15.0001 11.2151 15.0001
            10.2499V5.74994C15.0001 4.78497 14.2151 4 13.2499 4Z"
          fill="black"
        />
        <path
          d="M13.2499 14H5.74994C4.78497 14 4 14.785 4 15.7501V26.2501C4 27.2151 4.78497 28.0001 5.74994 28.0001H13.2499C14.2151 28.0001 15.0001 27.2151 15.0001
            26.2501V15.7501C15.0001 14.785 14.2151 14 13.2499 14Z"
          fill="black"
        />
        <path
          d="M26.2501 20H18.7501C17.785 20 17 20.785 17 21.7501V26.2501C17 27.2151 17.785 28.0001 18.7501 28.0001H26.2501C27.2151 28.0001 28.0001 27.2151 28.0001
            26.2501V21.7501C28.0001 20.785 27.2151 20 26.2501 20Z"
          fill="black"
        />
        <path
          d="M26.2501 4H18.7501C17.785 4 17 4.78497 17 5.74994V16.2499C17 17.2151 17.785 18.0001 18.7501 18.0001H26.2501C27.2151 18.0001 28.0001 17.2151 28.0001
            16.2499V5.74994C28.0001 4.78497 27.2151 4 26.2501 4V4Z"
          fill="black"
        />
      </>
    );
  }
  return (
    <>
      <path
        d="M5.74994 4.8H13.2499C13.7733 4.8 14.2001 5.22686 14.2001 5.74994V10.2499C14.2001 10.7733 13.7733 11.2001 13.2499 11.2001H5.74994C5.22686 11.2001 4.8 10.7733
          4.8 10.2499V5.74994C4.8 5.2268 5.2268 4.8 5.74994 4.8Z"
        strokeWidth="1.6"
      />
      <path
        d="M5.74994 14.8H13.2499C13.7733 14.8 14.2001 15.2268 14.2001 15.7501V26.2501C14.2001 26.7732 13.7733 27.2001 13.2499 27.2001H5.74994C5.2268 27.2001 4.8 26.7733
          4.8 26.2501V15.7501C4.8 15.2267 5.22686 14.8 5.74994 14.8Z"
        strokeWidth="1.6"
      />
      <path
        d="M18.7501 20.8H26.2501C26.7732 20.8 27.2001 21.2267 27.2001 21.7501V26.2501C27.2001 26.7733 26.7733 27.2001 26.2501 27.2001H18.7501C18.2267 27.2001 17.8 26.7732
          17.8 26.2501V21.7501C17.8 21.2268 18.2268 20.8 18.7501 20.8Z"
        strokeWidth="1.6"
      />
      <path
        d="M25.4501 4.8H26.2501C26.7733 4.8 27.2001 5.2268 27.2001 5.74994V16.2499C27.2001 16.7733 26.7732 17.2001 26.2501 17.2001H18.7501C18.2268 17.2001 17.8 16.7733
          17.8 16.2499V5.74994C17.8 5.22686 18.2267 4.8 18.7501 4.8H25.4501Z"
        strokeWidth="1.6"
      />
    </>
  );
}

export default function DashboardIcon({ active, classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';
  const strokeProps = active ? {} : { stroke: 'currentColor' };

  return (
    <svg className={className} fill="none" {...strokeProps} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...accessibilityProps}>
      <Paths active={active} />
    </svg>
  );
}
