import React, { useState } from 'react';
import DynamicForm from '../DynamicForm/DynamicForm';
import LoginLinks from './LoginLinks';
import AuthUtil from '../../utils/authUtil';
import PasswordRequirements from './PasswordRequirements';

function ResetPw({ onSubmit, submitting }) {
  const [failedValidation, setFailedValidation] = useState(false);
  const [isConfirmShown, setIsConfirmShown] = useState(false);
  const [passwordValidations, setPasswordValidations] = useState({
    length: false,
    number: false,
    lowercase: false,
    uppercase: false,
  });
  const forgotPasswordForm = {
    name: 'Forgot Password Form',
    order: ['password', 'password_requirements', 'password_confirm', 'submit'],
    fields: {
      password: {
        type: 'password',
        label: 'Password',
        autocomplete: 'new_password',
        onChangeCallback: (val) => {
          const isValid = !!val && AuthUtil.checkIsPasswordValid(val);
          setIsConfirmShown(isValid);
        },
        validations: ['required', AuthUtil.validatePassword],
      },
      password_confirm: {
        type: 'password',
        label: 'Confirm Password',
        autocomplete: 'new_password',
        disabled: !isConfirmShown,
        validations: ['required', (val) => (val.password === val.password_confirm ? null : 'The two passwords do not match, please try again.')],
      },
      password_requirements: {
        type: 'insert',
        insertComponent: <PasswordRequirements passwordValidations={passwordValidations} failedValidation={failedValidation} />,
      },
      submit: {
        type: 'button',
        color: 'pink',
        loading: submitting,
        label: 'Submit',
        disabled: submitting,
        className: 'w-full grid justify-items-center',
      },
    },
  };

  const handleFieldChange = (data) => {
    const passwordStatus = AuthUtil.checkPassword(data.password);
    setPasswordValidations(passwordStatus);
  };

  const checkValidationStatus = (valid) => {
    setFailedValidation(!valid);
  };

  return (
    <>
      <h1 className="my-8 text-2xl font-black text-center md:text-3xl lg:text-5xl">Reset Password</h1>
      <DynamicForm
        customClassName="text-base"
        form={forgotPasswordForm}
        endValidation={checkValidationStatus}
        onSubmit={onSubmit}
        handleOnChange={handleFieldChange}
        discardSnowplowEvent
      />
      <LoginLinks omitForgotPasswordLink omitRegisterLink />
    </>
  );
}

export default ResetPw;
