import React from 'react';
import { twMerge } from 'tailwind-merge';

export const cybrarySealUrl = 'https://images.ctfassets.net/kvf8rpi09wgk/4997f2phKSqVqCVXpNiGAZ/04d497a7d24bb0895dd5be608848078e/seal.png?h=250';

function Image({ src, alt, className, onClick, circle, size, style, ariaLabel, omitMarginAuto, marginBottom }) {
  let defaultStyles = '';

  defaultStyles = circle ? 'rounded-full border-gray-400 border-2 p-1 ' : '';
  if (size) {
    defaultStyles += size;
  } else {
    defaultStyles += 'h-32 w-32';
  }

  if (!omitMarginAuto) {
    defaultStyles += ' m-auto';
  }
  const classes = twMerge(`inline-block`, defaultStyles, marginBottom || 'mb-3', className);
  // if we have onClick, wrap image in button and provide aria-label (accessibility), else return just the image
  return onClick ? (
    <button onClick={onClick} aria-label={ariaLabel || ''}>
      <img src={src} className={classes} style={style} alt={alt} />
    </button>
  ) : (
    <img src={src} className={classes} style={style} alt={alt} />
  );
}

export default Image;
