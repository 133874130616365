import React from 'react';

export default function Clock({ classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';

  return (
    <svg className={className} {...accessibilityProps} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
    </svg>
  );
}
