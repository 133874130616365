import React from 'react';
import { observer, inject } from 'mobx-react';
import withRouter from '../../components/Router/withRouter';
import AssignmentDashboardHeading from '../../components/Enterprise/AssignmentDashboardHeading';
import AssignmentDashboardMembers from '../../components/Enterprise/AssignmentDashboardMembers';
import StatSectionContainer from '../../components/Analytics/StatSectionContainer';
import Container from '../../components/Container/Container';
import Card from '../../components/Card/Card';
import Label from '../../components/Label/Label';

import './enterprise.css';
import '../../components/Analytics/analytics.css';
import StyleUtil from '../../utils/styleUtil';

const OrganizationAssignmentDashboard = inject(
  'enterpriseStore',
  'commonStore',
  'userStore',
  'authStore'
)(
  observer(
    class OrganizationAssignmentDashboard extends React.Component {
      state = {
        orgId: null,
        teamGroupId: null,
        assignmentId: null,
        hideMetrics: false,
        tabularView: false,
      };

      componentDidMount() {
        this.props.authStore.fireAttributionEvent();
        this.props.userStore.setPreferredTeamInit('', this.props.match.params.orgId, this.init);
      }

      componentWillUnmount() {
        this.props.enterpriseStore.setDefaultAssignmentDashboardData();
      }

      init = (orgId) => {
        const teamGroupId = this.props.userStore.team.assignmentsTeamGroupId;
        const hideMetrics = !this.props.userStore.team.package_types || !this.props.userStore.team.package_types.length;

        this.props.enterpriseStore.setStartDate('assignmentDashboardData', this.props.userStore.team.created_at);
        const newState = {
          ...this.state,
          orgId,
          teamGroupId,
          assignmentId: this.props.match.params.id,
          hideMetrics,
        };
        this.setState(newState, () => {
          this.props.enterpriseStore.getAssignmentDashboardHeading(teamGroupId, this.props.match.params.id);
          this.getAssignmentTableData(teamGroupId, this.props.match.params.id);

          if (!hideMetrics) {
            this.getStatSections();
          }
        });
      };

      getBreadCrumbs = () => {
        const crumbs = [];
        crumbs.push({
          href: `/enterprise/${this.state.orgId}/assignments`,
          label: 'Assignments',
        });

        return crumbs;
      };

      getStats = (item, tabularViewChange) => {
        this.props.enterpriseStore.getDashboardMetrics(
          'assignmentDashboardData',
          this.state.teamGroupId,
          this.props.match.params.id,
          item,
          null,
          this.state.tabularView,
          tabularViewChange
        );
      };

      getStatSections = (tabularViewChange) => {
        // Stats Cards
        const statsConfig = this.props.enterpriseStore.assignmentDashboardData.config.stats;
        statsConfig.stat_cards.forEach((item) => {
          this.getStats(item, !!tabularViewChange);
        });
        statsConfig.mixed_stat.forEach((item) => {
          this.getStats(item, !!tabularViewChange);
        });
      };

      setGraphView = (e, { checked }) => {
        const newState = {
          ...this.state,
          tabularView: checked,
        };
        this.setState(newState, () => {
          this.getStatSections(true);
        });
      };

      deleteAssignment = (id) => {
        this.props.enterpriseStore
          .deleteAssignment(this.props.userStore.team.assignmentsTeamGroupId, id)
          .then(() => {
            this.props.commonStore.triggerToast('success', {
              content: 'Assignment has been removed',
            });
            setTimeout(() => this.props.navigate(`/enterprise/${this.state.orgId}/assignments`), 3000);
          })
          .catch((e) => {
            this.props.commonStore.triggerToast('error', {
              errorCode: e.response.status,
            });
          });
      };

      getAssignmentTableData = (teamGroupId, assignmentId) => {
        this.props.enterpriseStore.getAssignmentDashboardMembersTable(teamGroupId, assignmentId);
      };

      getProgressLabel = (data) => {
        const progressNum = data.value;
        let labelClass = 'yellow';
        if (progressNum === 0) {
          labelClass = '';
        } else if (progressNum === '100') {
          labelClass = 'green';
        } else if (progressNum === null) {
          return '-';
        }

        return <Label className={`progress-label ${labelClass}`}>{data.value}%</Label>;
      };

      render() {
        const { team } = this.props.userStore;
        if (!team) {
          return null;
        }
        const storeData = this.props.enterpriseStore.assignmentDashboardData;
        const graphColorDefaults = StyleUtil.getGraphColorDefaults();
        const statCardQueryParams = `?assignment=${this.state.assignmentId}&assignmentName=${storeData.heading.data ? encodeURIComponent(storeData.heading.data.name) : ''}`;
        return (
          <Container className="organization">
            <div className="organization-assignment-dashboard dashboard">
              <AssignmentDashboardHeading
                orgId={this.state.orgId}
                data={storeData.heading}
                userStore={this.props.userStore}
                assignmentId={this.state.assignmentId}
                commonStore={this.props.commonStore}
                deleteAssignment={this.deleteAssignment}
                canManageAssignments={!!team.permissions && team.permissions.canManageAssignments}
                breadcrumbs={this.getBreadCrumbs()}
                setGraphView={this.setGraphView}
                graphView={this.state.tabularView}
              />
              {!this.state.hideMetrics && !!storeData.stats && Object.keys(storeData.stats).length ? (
                <div className="mb-8">
                  <div className="grid grid-cols-1 gap-6 mb-6 sm:grid-cols-2 lg:grid-cols-4 stat-cards-group">
                    <Card className="p-0 my-0 stat-card summary">
                      <StatSectionContainer
                        type="summary"
                        orgId={this.state.orgId}
                        filters={storeData.filters}
                        loading={storeData.stats.learningHoursSummary.loading}
                        error={storeData.stats.learningHoursSummary.error}
                        displayData={storeData.stats.learningHoursSummary.data}
                        emptyText={`There are no activities for this ${this.state.view} during this time period`}
                        tooltip="The total hours earned through Cybrary by watching videos and completing hands-on activities over time."
                        href={`/enterprise/${this.state.orgId}/reporting/report/learning-hours/${statCardQueryParams}`}
                        tabularView={this.state.tabularView && storeData.stats.learningHoursSummary.tableView}
                      />
                    </Card>
                    <Card className="p-0 my-0 stat-card summary">
                      <StatSectionContainer
                        type="summary"
                        orgId={this.state.orgId}
                        filters={storeData.filters}
                        loading={storeData.stats.learningActivityInteractions.loading}
                        error={storeData.stats.learningActivityInteractions.error}
                        displayData={storeData.stats.learningActivityInteractions.data}
                        emptyText={`There are no activities for this ${this.state.view} during this time period`}
                        tooltip={
                          'The total learning activities that a user has interacted with. ' +
                          'Example interactions include enrolling in a course, watching a video, and launching a lab.'
                        }
                        href={`/enterprise/${this.state.orgId}/reporting/report/learning-activities/${statCardQueryParams}`}
                        tabularView={this.state.tabularView && storeData.stats.learningActivityInteractions.tableView}
                      />
                    </Card>
                    <Card className="p-0 my-0 stat-card pie">
                      <StatSectionContainer
                        type="pie"
                        header="Learning Activities"
                        orgId={this.state.orgId}
                        filters={storeData.filters}
                        loading={storeData.stats.learningActivities.loading}
                        error={storeData.stats.learningActivities.error}
                        displayData={storeData.stats.learningActivities.data}
                        emptyText={`There are no activities for this ${this.state.view} during this time period`}
                        tooltip={
                          'The total learning activities completed filtered by the activity type.' +
                          'Hands-on activities include all labs, assessments, and practice tests. Other activities consist of articles.'
                        }
                        href={`/enterprise/${this.state.orgId}/reporting/report/learning-activities/${statCardQueryParams}`}
                        primaryColors={graphColorDefaults.primaryColors}
                        legendPosition="right"
                        tabularView={this.state.tabularView && storeData.stats.learningActivities.tableView}
                      />
                    </Card>
                    <Card className="p-0 my-0 stat-card doughnut">
                      <StatSectionContainer
                        type="doughnut"
                        header="Assignments"
                        orgId={this.state.orgId}
                        filters={storeData.filters}
                        loading={storeData.stats.assignments.loading}
                        error={storeData.stats.assignments.error}
                        displayData={storeData.stats.assignments.data}
                        emptyText="Create an assignment to start tracking performance."
                        tooltip="The total assignments filtered by their completion status."
                        href={`/enterprise/${this.state.orgId}/assignments/${statCardQueryParams}`}
                        reportLinkIcon="list"
                        primaryColors={graphColorDefaults.primaryColors}
                        legendPosition="left"
                        displayTotal
                        tabularView={this.state.tabularView && storeData.stats.assignments.tableView}
                      />
                    </Card>
                  </div>
                  <div className="grid grid-cols-1 gap-2 mb-4 learning-hours">
                    <Card className="p-0 my-0 stat-card mixed">
                      <StatSectionContainer
                        type="mixed"
                        mixedTypes={['bar', 'bar', 'line']}
                        header="Learning Hours"
                        orgId={this.state.orgId}
                        filters={storeData.filters}
                        interval={storeData.filters.interval}
                        yAxisLabel="Hours"
                        loading={storeData.stats.learningHoursAssignment.loading}
                        error={storeData.stats.learningHoursAssignment.error}
                        displayData={storeData.stats.learningHoursAssignment.data}
                        emptyText="Add members to start tracking activity."
                        tooltip="The total hours earned through Cybrary by watching videos and completing hands-on activities."
                        href={`/enterprise/${this.state.orgId}/reporting/report/learning-hours/${statCardQueryParams}`}
                        primaryColors={graphColorDefaults.primaryColors}
                        tabularView={this.state.tabularView && storeData.stats.learningHoursAssignment.tableView}
                      />
                    </Card>
                  </div>
                </div>
              ) : null}
              <AssignmentDashboardMembers
                orgId={this.state.teamGroupId}
                assignmentId={this.state.assignmentId}
                loading={storeData.members.loading}
                error={storeData.members.error}
                data={storeData.members.data}
                queryParams={storeData.members.queryParams}
                getProgressLabel={this.getProgressLabel}
                getAssignmentTableData={this.getAssignmentTableData}
                canManageAssignments={!!team.permissions && team.permissions.canManageAssignments}
              />
            </div>
          </Container>
        );
      }
    }
  )
);

export default withRouter(OrganizationAssignmentDashboard);
