import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../components/Loading/Loading';
import { useImmersive } from '../../providers/ImmersiveProvider';

/**
 * This page controls the immersive when we have an enrollment with a curriculum item, but no activity is known yet.
 * We load the enrollment, then the item, and determine the correct activity to send the user to, and redirect.
 * Example url: /immersive/:enrollmentId/item/:itemId
 */
const ImmersiveEnrollmentWithItemNoActivity = inject('commonStore')(
  observer(({ commonStore }) => {
    const navigate = useNavigate();
    const params = useParams();
    const { enrollment, loadEnrollment, loadItem, item, findFirstIncompleteActivityIdFromItem, leaveImmersiveWithError } = useImmersive();

    useEffect(() => {
      // Remove the app's main navigation by letting the common store know we are in the immersive ux
      commonStore.setIsInImmersive(true);
      return () => commonStore.setIsInImmersive(false);
    }, []);

    const { enrollmentId, itemId } = params;
    useEffect(() => {
      if (!enrollmentId) {
        return;
      }

      loadEnrollment(1 * enrollmentId);
    }, [enrollmentId]);

    useEffect(() => {
      if (!enrollment || !itemId) {
        return;
      }

      loadItem(1 * itemId);
    }, [itemId, enrollment]);

    useEffect(() => {
      if (!item) {
        return;
      }
      const activityId = findFirstIncompleteActivityIdFromItem();
      if (!activityId) {
        leaveImmersiveWithError('missingFirstToDoActivityIdOnInit');
        return;
      }
      navigate(`/immersive/${enrollmentId}/item/${itemId}/activity/${activityId}`, { replace: true });
    }, [item]);

    return <Loading wrapperClassName="py-16" />;
  })
);

export default ImmersiveEnrollmentWithItemNoActivity;
