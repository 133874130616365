import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';
import Bugsnag from '@bugsnag/js';
import WidgetContainer from '../Container/WidgetContainer';
import Header from '../Header/Header';
import DynamicForm from '../DynamicForm/DynamicForm';
import Button from '../Button/Button';
import If from '../If/If';
import AddLink from '../AddLink/AddLink';
import LoadingText from '../Loading/LoadingText';
import Icon from '../Icon/Icon';
import agents from '../../agents/agents';
import { useABM } from '../../providers/ABMProvider';
import ActionUtil from '../../utils/actionsUtil';
import { APP_FORM_IDS } from '../../constants';

// Different possible steps for the email verification process
const STEPS = {
  EMAIL_INPUT: 'EMAIL_INPUT', // Default state
  PENDING_VERIFICATION: 'PENDING_VERIFICATION', // When the user has submitted their email and is now asked to verify it via code
  VERIFIED: 'VERIFIED', // When the user has successfully verified their email and is now on the ABM team
  REVERIFY_EMAIL_INPUT: 'REVERIFY_EMAIL_INPUT', // When the users email verification term has expired and they need to re-verify their email
};

function LoadingDisplay() {
  return (
    <div className="flex flex-col space-y-2">
      <LoadingText className="w-2/3 h-4" />
      <div className="flex space-x-2">
        <LoadingText wrapperClassName="w-1/4" />
        <LoadingText wrapperClassName="w-1/4" />
        <LoadingText wrapperClassName="w-2/4" />
      </div>
      <div className="flex space-x-2">
        <LoadingText wrapperClassName="w-2/3" />
        <LoadingText wrapperClassName="w-1/3" />
      </div>
      <div className="flex space-x-2">
        <LoadingText wrapperClassName="w-1/3" />
        <LoadingText wrapperClassName="w-2/3" />
      </div>
      <div className="flex space-x-2">
        <LoadingText wrapperClassName="w-1/4" />
        <LoadingText wrapperClassName="w-1/4" />
        <LoadingText wrapperClassName="w-2/4" />
      </div>
      <div className="flex space-x-2">
        <LoadingText wrapperClassName="w-2/3" />
        <LoadingText wrapperClassName="w-1/3" />
      </div>
    </div>
  );
}

function PrivacyDisclaimer() {
  return <p>All data is anonymous and based on verified Cybrary learners using verified company emails. Verification of a valid company email is required every 6 months.</p>;
}

function VerificationCodeSecondaryButtons({ onResendCode, onChangeEmail, isResendCodeLoading, isChangeEmailLoading }) {
  // Change "Resend code" and "Change email" buttons to "Resending code..." and "Changing email..." when loading
  const [resendCodeMessage, setResendCodeMessage] = useState('Resend code');

  useEffect(() => {
    (async () => {
      if (isResendCodeLoading) {
        setResendCodeMessage('Resending code...');
        return;
      }
      if (resendCodeMessage === 'Resending code...') {
        setResendCodeMessage('Code resent!');
        // Start a 5 second timer to reset the resend code message back to its original state
        await ActionUtil.sleep(5000);
        setResendCodeMessage('Resend code');
        return;
      }
      setResendCodeMessage('Resend code');
    })();
  }, [isResendCodeLoading]);
  return (
    <div className="flex mb-4 space-x-2">
      <Button color="gray" onClick={onResendCode}>
        {resendCodeMessage}
      </Button>
      <Button color="gray" onClick={onChangeEmail}>
        {isChangeEmailLoading ? 'Changing email...' : 'Change email'}
      </Button>
    </div>
  );
}

/**
 * This component is a prime example of why javascript is so toxic to work with.
 * @param {Error|APIError|String} error - The error to display, in all of its polymorphic glory
 * @returns {JSX} - The error message
 */
function ErrorMessage({ error }) {
  return (
    <If condition={error}>
      <div className="flex items-center mb-2 space-x-1 text-sm text-red-500">
        <Icon name="exclamation-circle" className="shrink-0 w-4 h-4" />
        <p>{error?.response?.data?.message || error.message || error}</p>
      </div>
    </If>
  );
}

/**
 * Leave Team Button
 * @returns {JSX} - The Leave Team Button
 */
function LeaveTeamButton({ className = '', handleLeaveTeam }) {
  const { leaveTeamError, isLeaveTeamLoading } = useABM();
  return (
    <div className={className}>
      <If condition={leaveTeamError}>
        <ErrorMessage error={leaveTeamError} />
      </If>
      <If condition={isLeaveTeamLoading}>Leaving team...</If>
      <If condition={!isLeaveTeamLoading}>
        <AddLink onClick={handleLeaveTeam}>
          <span className="flex justify-center items-center space-x-1">
            <span>Leave team</span>
            <Icon name="x" className="w-3 h-3" />
          </span>
        </AddLink>
      </If>
    </div>
  );
}

function ABMEmailVerificationWidget() {
  const navigate = useNavigate();
  const { abmTeam, isABMTeamLoading, fetchABMTeam, isVerified, isVerificationExpired, leaveTeamError, leaveTeam, isLeaveTeamLoading, company } = useABM();

  const [currentStep, setCurrentStep] = useState(STEPS.EMAIL_INPUT);

  useEffect(() => {
    if (isVerified) {
      if (isVerificationExpired) {
        setCurrentStep(STEPS.REVERIFY_EMAIL_INPUT);
      } else {
        setCurrentStep(STEPS.VERIFIED);
      }
    } else if (abmTeam?.email) {
      setCurrentStep(STEPS.PENDING_VERIFICATION);
    } else {
      setCurrentStep(STEPS.EMAIL_INPUT);
    }
  }, [abmTeam, isVerified, isVerificationExpired]);

  // Loading States
  const [isSubmitEmailLoading, setIsSubmitEmailLoading] = React.useState(false);
  const [isSubmitCodeLoading, setIsSubmitCodeLoading] = React.useState(false);
  const [isResendCodeLoading, setIsResendCodeLoading] = React.useState(false);

  // Message/Error Feedbacks for user
  const [secondaryButtonsMessage, setSecondaryButtonsMessage] = React.useState('');
  const [emailError, setEmailError] = React.useState('');
  const [verificationCodeError, setVerificationCodeError] = React.useState('');
  const [resendCodeError, setResendCodeError] = React.useState('');

  const clearAllErrors = () => {
    setEmailError('');
    setVerificationCodeError('');
    setResendCodeError('');
  };

  const title = useMemo(() => {
    switch (currentStep) {
      case STEPS.REVERIFY_EMAIL_INPUT:
        return 'Reverify your company email';
      case STEPS.VERIFIED:
        return company?.name || 'Your company';
      default:
        return 'Verify your company email';
    }
  }, [currentStep]);

  // Step 1: Submit email for verification
  const handleSubmitEmailForVerification = async (formData) => {
    clearAllErrors();
    setIsSubmitEmailLoading(true);
    try {
      const { email, captchav3 } = formData || {};
      await agents.abm.setCompany({ email, captchav3 });

      setCurrentStep(STEPS.PENDING_VERIFICATION);
    } catch (error) {
      Bugsnag.notify(error);
      const responseData = error?.response?.data;
      setEmailError(responseData?.errors?.email?.[0] || responseData?.message || error?.message || 'We could not verify your email.');
    } finally {
      setIsSubmitEmailLoading(false);
    }
  };

  // Step 2: Submit verification code
  const handleSubmitVerificationCode = async (formData) => {
    clearAllErrors();
    setSecondaryButtonsMessage('');
    setIsSubmitCodeLoading(true);
    try {
      const { code } = formData || {};
      await agents.abm.verifyCompany({ code });

      await fetchABMTeam();
    } catch (error) {
      Bugsnag.notify(error);
      setVerificationCodeError(error);
      setSecondaryButtonsMessage('');
    } finally {
      setIsSubmitCodeLoading(false);
    }
  };

  // Step 2 & 4 Option: Resend code
  const handleResendCode = async () => {
    clearAllErrors();
    setIsResendCodeLoading(true);
    setSecondaryButtonsMessage('Resending code...');
    try {
      await agents.abm.resendCompanyVerification();

      setSecondaryButtonsMessage('Code resent');
    } catch (error) {
      Bugsnag.notify(error);
      setResendCodeError(error);
      setSecondaryButtonsMessage('');
    } finally {
      setIsResendCodeLoading(false);
    }
  };

  const handleLeaveTeam = () => {
    // Leave team resolves to a boolean indicating wether or not we left the team
    leaveTeam().then((isUserOffTeam) => {
      if (isUserOffTeam) {
        clearAllErrors();
        setCurrentStep(STEPS.EMAIL_INPUT);
      }
    });
  };

  const EMAIL_INPUT_FORM = {
    name: 'ABM Email Input Form',
    order: [['email', 'submit']],
    fields: {
      email: {
        id: 'abmEmail',
        type: 'email',
        validations: ['required', 'email'],
      },
      submit: {
        type: 'submit-captcha-v3',
        color: 'pink',
        label: 'Verify Email',
        className: 'w-full px-6 py-3 text-sm md-991:w-auto',
        loading: isSubmitEmailLoading,
      },
    },
  };

  const VERIFICATION_CODE_FORM = {
    name: 'ABM Email Verification Code Form',
    order: [['code', 'submit']],
    fields: {
      code: {
        id: 'abmVerificationCode',
        type: 'code',
        validations: ['required'],
      },
      submit: {
        type: 'submit-captcha-v3',
        color: 'pink',
        label: 'Submit code',
        className: 'w-full px-6 py-3 text-sm md-991:w-auto',
        loading: isSubmitCodeLoading,
      },
    },
  };

  return (
    <WidgetContainer className="h-fit">
      {/* Loading State */}
      <If condition={isABMTeamLoading}>
        <LoadingDisplay />
      </If>
      {/* Default State */}
      <If condition={!isABMTeamLoading}>
        <Header as="h2" className="mr-16 mb-4">
          {title}
        </Header>
        <Fade triggerOnce>
          {/** Step 1: Email Input Form */}
          <If condition={currentStep === STEPS.EMAIL_INPUT}>
            {/** Dynamic form validation only seems to run if we provide a function to handleOnChange. */}
            <DynamicForm
              formId={APP_FORM_IDS.ABM.EMAIL_VERIFICATION}
              form={EMAIL_INPUT_FORM}
              customClassName="mb-0"
              onSubmit={handleSubmitEmailForVerification}
              handleOnChange={() => {}}
            />
            {/** Email Error Message */}
            <div className="flex justify-start mb-4 space-x-2">
              <ErrorMessage error={emailError} />
              <If condition={emailError === 'You already have a company email set'}>
                <span>|</span>
                <LeaveTeamButton className="text-sm text-red-500" handleLeaveTeam={handleLeaveTeam} />
              </If>
            </div>
            <div className="text-sm">
              <p>Unlock insights into what other learners from your organization are learning on Cybrary!</p>
              <PrivacyDisclaimer />
            </div>
          </If>
          {/** Step 2: Verification Code Form */}
          <If condition={currentStep === STEPS.PENDING_VERIFICATION}>
            {/** Dynamic form validation only seems to run if we provide a function to handleOnChange. */}
            <DynamicForm form={VERIFICATION_CODE_FORM} customClassName="mb-0" onSubmit={handleSubmitVerificationCode} handleOnChange={() => {}} />
            {/** Verification Code Error Message */}
            <ErrorMessage error={verificationCodeError} />
            <div className="text-sm">
              <p>We are validating the email you submitted and once verified we will email you a code. Please submit the code above to verify ownership of the email.</p>
              <VerificationCodeSecondaryButtons
                onResendCode={handleResendCode}
                onChangeEmail={handleLeaveTeam}
                secondaryButtonsMessage={secondaryButtonsMessage}
                isResendCodeLoading={isResendCodeLoading}
                isChangeEmailLoading={isLeaveTeamLoading}
              />
              {/** Resend Code Error Message */}
              <ErrorMessage error={resendCodeError} />
              {/** Change Email Code Error Message */}
              <ErrorMessage error={leaveTeamError} />
              <p>Unlock insights into what other learners from your organization are learning on Cybrary!</p>
              <PrivacyDisclaimer />
            </div>
          </If>
          {/** Step 3: Verified */}
          <If condition={currentStep === STEPS.VERIFIED}>
            <div className="text-sm">
              {/** Leave Team Error Message */}
              <ErrorMessage error={leaveTeamError} />
              <p>
                <AddLink to="https://www.cybrary.it/demo-request" className="text-cyb-pink-500" target="_blank">
                  Schedule a demo
                </AddLink>{' '}
                to take control over the visibility of this profile, see more advanced learning/performance analytics, manage the career development of professionals in your
                organization
              </p>
              <PrivacyDisclaimer />
              <div className="flex justify-evenly mb-4 space-x-2 md:justify-start">
                <Button onClick={() => navigate('/refer-a-friend')}>Invite a colleague</Button>
                <Button href="https://www.cybrary.it/demo-request" target="_blank">
                  Schedule a demo
                </Button>
              </div>
              <div className="absolute top-6 right-6 text-xs">
                <LeaveTeamButton handleLeaveTeam={handleLeaveTeam} />
              </div>
            </div>
          </If>
          {/** Step 4: Reverify Email Input Form */}
          <If condition={currentStep === STEPS.REVERIFY_EMAIL_INPUT}>
            <DynamicForm form={EMAIL_INPUT_FORM} customClassName="mb-0" />
            <div className="text-sm">
              <p>You verification period has expired. Reenter your company email to retain access to your teams insights.</p>
              <VerificationCodeSecondaryButtons
                onResendCode={handleResendCode}
                onChangeEmail={handleLeaveTeam}
                secondaryButtonsMessage={secondaryButtonsMessage}
                isResendCodeLoading={isResendCodeLoading}
                isChangeEmailLoading={isLeaveTeamLoading}
              />
              <PrivacyDisclaimer />
            </div>
          </If>
        </Fade>
      </If>
      {/* Error State? */}
    </WidgetContainer>
  );
}
export default ABMEmailVerificationWidget;
