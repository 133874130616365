import React, { useMemo } from 'react';
import { observer, inject } from 'mobx-react';
import Loading from '../Loading/Loading';
import StyledError from '../Error/StyledError';
import Container from '../Container/Container';
import { useCybAssessmentAnalytics } from '../../providers/CybAssessmentAnalyticsProvider';
import BaselineOverviewTable from './modules/BaselineOverviewTable';
import NoResultsMessage from '../NoResultsMessage/NoResultsMessage';

/**
 * Transform assessment track/path data to compliant format for table data
 *
 * @param {Track} track
 * @returns {tableData} { columns: [], tableData: [][] }
 */
const generateTableData = (track) => {
  const tableData = track.children.map((item, index) => [
    {
      value: item['content-id'],
      type: 'string',
    },
    {
      value: item['content-topic'],
      type: 'string',
    },
    {
      value: item.score,
      type: 'string',
    },
    {
      value: item['community-score'],
      type: 'string',
    },
    {
      value: index,
      type: 'string',
    },
  ]);
  return {
    columns: [
      {
        key: 'assessment_id',
        type: 'string',
        showCol: false,
        sortKey: 'course_id',
      },
      {
        key: 'name',
        type: 'string',
        showCol: true,
        display: 'Name',
        sortKey: 'name',
      },
      {
        key: 'user_score',
        type: 'string',
        showCol: true,
        display: 'Your Score',
        sortKey: 'user_score',
        tooltip: 'Click your score to view your personalized recommendations and improve your score!',
      },
      {
        key: 'community_score',
        type: 'string',
        showCol: true,
        display: 'Community Score',
        sortKey: 'community_score',
        tooltip: 'The average score of all completed Topic Assessments within the Assessment Path by all Cybrary users',
      },
      {
        key: 'user_action',
        type: 'string',
        showCol: true,
        display: '',
        sortKey: 'user_action',
      },
    ],
    tableData,
  };
};

const BaselineOverview = inject('userStore')(
  observer(({ userStore }) => {
    const { tracks, isLoading, error } = useCybAssessmentAnalytics();

    const userTracks = tracks[userStore?.user?.id];

    const userTrackTables = useMemo(
      () =>
        userTracks
          ?.filter((userTrack) => userTrack.status !== 'Unlisted')
          .map((track) => ({
            id: track['content-id'],
            name: track['content-name'],
            assessments: track.children,
            tableData: generateTableData(track),
          })),
      [userTracks]
    );

    if (isLoading) {
      return (
        <Container>
          <Loading message="Loading..." />
        </Container>
      );
    }

    if (error) {
      return (
        <Container>
          <StyledError error={error} />
        </Container>
      );
    }

    if (!userTracks || !userTrackTables) {
      return <NoResultsMessage message="No Assessment Paths" />;
    }

    return (
      <div className="flex flex-col">
        {userTrackTables.map((table) => (
          <BaselineOverviewTable table={table} key={table.id} />
        ))}
      </div>
    );
  })
);

export default BaselineOverview;
