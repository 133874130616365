import React, { useMemo, useState } from 'react';
import { Fade } from 'react-awesome-reveal';
import { twMerge } from 'tailwind-merge';
import { useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import Segment from '../../components/Segment/Segment';
import Price from '../../components/Checkout/Price';
import {
  CHECKOUT_MONTHS_PER_CYCLE,
  CHECKOUT_PRICE_BOX_TITLES,
  PADDLE_DISCOUNT_ID_CIP_THREE_FOR_TWO,
  PADDLE_PRICE_ID_CIP_ANNUAL,
  PADDLE_PRICE_ID_CIP_MONTHLY,
  PADDLE_PRICE_ID_TEAM_ANNUAL,
  PADDLE_SUBSCRIPTIONS,
} from '../../constants';
import DiscountBubble from '../../components/Checkout/DiscountBubble';
import If from '../../components/If/If';
import Header from '../../components/Header/Header';
import Tooltip from '../../components/Tooltip/Tooltip';
import Icon from '../../components/Icon/Icon';

function PricingBillingCycleToggle({ optimisticToggleState, handleToggle, isDisabled }) {
  return (
    <button onClick={handleToggle} className="flex justify-center items-center mt-2 space-x-4 w-full text-sm font-semibold">
      <div className={` ${optimisticToggleState && isDisabled ? 'text-gray-400' : ''}`}>
        <span className="hidden lg:inline">Billed </span>Monthly
      </div>
      <PricingToggleButton enabled={optimisticToggleState} handleToggle={handleToggle} isDisabled={isDisabled} />
      <div className={` ${!optimisticToggleState && isDisabled ? 'text-gray-400' : ''}`}>
        <span className="hidden lg:inline">Billed </span>Annually
      </div>
    </button>
  );
}

function PricingToggleButton({ enabled, handleToggle, isDisabled }) {
  return (
    <button
      type="button"
      className={twMerge('flex items-center w-10 h-6 bg-gray-200 rounded-full border-xs border-gray-400', isDisabled && 'cursor-not-allowed')}
      onClick={handleToggle}
      disabled={isDisabled}
    >
      <div
        className={twMerge(
          'mx-auto h-5 w-5 transform rounded-full bg-cyb-pink-500 duration-300 ease-in-out',
          enabled ? 'translate-x-2' : '-translate-x-2',
          isDisabled && 'bg-gray-500'
        )}
      />
    </button>
  );
}

function PricingTitle({ children, className = '', checkoutLoading }) {
  return (
    <div className={twMerge('mb-8 text-center transition-opacity duration-500', checkoutLoading ? 'opacity-0' : 'opacity-100', className)}>
      <p className="mb-0 text-lg font-bold capitalize">{children}</p>
    </div>
  );
}

function PricingContainer({ children }) {
  return (
    <Segment className="pt-4 mt-0 mb-4 lg:pt-8">
      <Fade triggerOnce>{children}</Fade>
    </Segment>
  );
}

const checkoutPackagePriceBoxDiscountTitles = {
  [PADDLE_DISCOUNT_ID_CIP_THREE_FOR_TWO]: (
    <div className="flex justify-center">
      <Header as="h2" className="mb-0 ml-4">
        Buy <span className="text-2xl font-bold text-cyb-pink-500">2</span>{' '}
        <span className="underline">
          Get <span className="text-2xl font-bold text-cyb-pink-500">1</span> FREE
        </span>
      </Header>
      <Tooltip
        content="Get 3 months of full access to Cybrary for the price of 2!"
        triggerContent={<Icon name="question-circle" className="ml-1 w-3 h-3 text-gray-600 hover:scale-105" />}
      />
    </div>
  ),
};

function Pricing({ checkoutData, checkoutLoading, swapCheckoutItems }) {
  const { checkoutPackageId } = useParams();
  const { items, currency_code, discount, totals } = checkoutData || {};
  const item = items ? items[0] : {};
  const { billing_cycle } = item || {}; // For now, just support the first index. We'll get fancy later if we want to support multiple items in the cart.
  const isTeams = item?.price_id === PADDLE_PRICE_ID_TEAM_ANNUAL;
  const isCIPAnnual = item?.price_id === PADDLE_PRICE_ID_CIP_ANNUAL;
  const isCIPMonthly = item?.price_id === PADDLE_PRICE_ID_CIP_MONTHLY;
  const monthsPerCycle = CHECKOUT_MONTHS_PER_CYCLE[item?.price_id] || billing_cycle;
  const totalMonthly = totals ? `${totals.balance / monthsPerCycle}` : '';
  const priceClassNames = twMerge(`mb-3 transition-opacity duration-500`, checkoutLoading ? 'opacity-0' : 'opacity-100');
  const [optimisticToggleState, setOptimisticToggleState] = useState(isCIPAnnual);
  // calculate the additional discount being offered today on top of the recurring price
  const additionalDiscount = discount ? ((100 * totals.discount) / totals.subtotal).toFixed() : '0';

  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  // Disable the toggle if we have a query param for "lockPlan"
  const isToggleLocked = queryParams?.lockPlan;

  // Hide the toggle if we have a query param for "hidePlanToggle"
  const isToggleShown = useMemo(() => {
    // allow toggle if we are showing CIP annual or monthly
    return (isCIPAnnual || isCIPMonthly) && !queryParams?.hidePlanToggle;
  }, [isCIPAnnual, isCIPMonthly, queryParams?.hidePlanToggle]);

  // toggle between monthly and annual billing for CIP
  const handleToggle = () => {
    // teams is only annual
    if (!isToggleShown || isToggleLocked) return;
    const currentPlan = PADDLE_SUBSCRIPTIONS.cip[isCIPAnnual ? 'annual' : 'monthly'];
    const newPlan = PADDLE_SUBSCRIPTIONS.cip[isCIPAnnual ? 'monthly' : 'annual'];
    swapCheckoutItems(currentPlan, newPlan);
    setOptimisticToggleState(!optimisticToggleState);
  };

  const title = useMemo(() => {
    // Check for a discount id title first
    const discountId = queryParams?.discountId;
    const discountTitle = checkoutPackagePriceBoxDiscountTitles?.[discountId];
    if (discountTitle) {
      return discountTitle;
    }

    // Check for a package id title
    if (checkoutPackageId) {
      return CHECKOUT_PRICE_BOX_TITLES?.[checkoutPackageId] || CHECKOUT_PRICE_BOX_TITLES.default;
    }

    // Default to the plan name
    if (isCIPAnnual) {
      return CHECKOUT_PRICE_BOX_TITLES.annual;
    }
    if (isCIPMonthly) {
      return CHECKOUT_PRICE_BOX_TITLES.monthly;
    }
    return CHECKOUT_PRICE_BOX_TITLES.default;
  }, [checkoutPackageId, isCIPAnnual, isCIPMonthly, queryParams]);

  /** Pricing for Teams
   * We can probably refactor this to be more DRY, but for now, it's easier to maintain this way.
   */
  if (isTeams) {
    return (
      <PricingContainer>
        <PricingTitle>Monthly Fee</PricingTitle>
        <div className="flex flex-col justify-between items-center">
          <Price className={priceClassNames} price={totalMonthly} currencyCode={currency_code} />
          <p className="m-0">Billed Annually</p>
        </div>
      </PricingContainer>
    );
  }

  return (
    <PricingContainer>
      <PricingTitle checkoutLoading={checkoutLoading}>{title}</PricingTitle>
      <div className="flex flex-col justify-between items-center">
        <Price className={priceClassNames} price={totalMonthly} currencyCode={currency_code} />
        <DiscountBubble percentOff={additionalDiscount} sizeClasses={discount ? 'w-24 h-8' : 'w-24 h-2'} />
        <If condition={isToggleShown}>
          <PricingBillingCycleToggle optimisticToggleState={optimisticToggleState} handleToggle={handleToggle} isDisabled={isToggleLocked} />
        </If>
      </div>
    </PricingContainer>
  );
}

export default Pricing;
