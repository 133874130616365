import React from 'react';
import AddLink from '../AddLink/AddLink';
import Icon from '../Icon/Icon';
import './search-promo.css';

function SearchCIPPromo({ data, cardView, className }) {
  if (!data) {
    return null;
  }
  if (cardView) {
    return (
      <div className={`group w-60 rounded-sm border-xs border-gray-400 overflow-hidden bg-white ${className || ''}`}>
        <AddLink to={data.app_href || data.href}>
          <div className="flex flex-row justify-center items-center p-4 h-full group-hover:text-black md:flex-col">
            {data.icon ? (
              <div className="my-4 mr-4 md:mr-0 md:text-center">
                <Icon name={data.icon} className="w-8 h-8 text-black hover:text-black" />
              </div>
            ) : null}
            <div className="my-4">
              <div className="pb-4 text-lg font-bold text-black md:text-center">{data.headline}</div>
              <div className="text-xs text-gray-800 md:text-sm md:text-center">{data.description}</div>
              {data.linkText ? (
                <div className="flex items-center my-4 text-cyb-pink-500 group-hover:text-black md:justify-center">
                  {data.linkText} <Icon name="chevron-double-right" className="ml-1 w-4 h-4 group-hover:text-black" />
                </div>
              ) : null}
            </div>
          </div>
        </AddLink>
      </div>
    );
  }
  return (
    <div className="group w-full bg-white">
      <AddLink to={data.app_href || data.href} className="block w-full">
        <div className="flex gap-x-6 items-center py-8 w-full">
          <div className="">
            <Icon name={data.icon} className="w-8 h-8 text-black hover:text-black" />
          </div>
          <div>
            <p className="pb-1.5 mb-0 text-lg font-bold text-black">{data.headline}</p>
            <p className="mb-0 text-xs text-gray-800 md:text-sm">{data.description}</p>
            {data.linkText ? <p className="mt-1 text-cyb-pink-500">{data.linkText}</p> : null}
          </div>
        </div>
      </AddLink>
    </div>
  );
}

export default SearchCIPPromo;
