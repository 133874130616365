import React, { useEffect, useState } from 'react';
import { observer, inject } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import Bugsnag from '@bugsnag/js';
import Button from '../Button/Button';
import Loading from '../Loading/Loading';
import Modal from '../Modal/Modal';
import TeamSelectionButtons from '../TeamSelection/TeamSelectionButtons';
import SelectBoxes from '../SelectBoxes/SelectBoxes';
import Tabs from '../Tabs/Tabs';
import agents from '../../agents/agents';

// Note: Component to be removed when Beta is over
function UserGroupsOptions({ scope, loading, setSearchQuery, searchQuery, options, submitText, teamId, triggerToast, submitCallback }) {
  const [selections, setSelections] = useState([]);

  useEffect(() => {
    // If the scope changes, reset selections -- Prevents selections from previous scope from persisting
    setSelections([]);
  }, [scope]);

  if (loading) {
    return <Loading message="Loading..." />;
  }

  const handleInvites = async () => {
    if (selections && selections.length) {
      if (submitCallback) {
        submitCallback();
      }
      try {
        const payload = {};
        payload[scope] = selections;
        await agents.enterprise.postClabBetaInvites(teamId, payload);
        triggerToast('success', {
          content: `Invites sent to selected ${scope === 'users' ? 'team members' : 'groups'}.`,
        });
      } catch (e) {
        Bugsnag.notify(e);
        triggerToast('error', {
          content: `Something went wrong. Unable to send invites to selected ${scope === 'users' ? 'team members' : 'groups'} at this time.`,
        });
      }
    }
  };

  return (
    <>
      <div className="overflow-auto max-h-[24rem]">
        <SelectBoxes
          containerClasses="overflow-y-auto"
          multiSelect
          showSelectAll
          returnFullSelections
          handleSearch={(query) => setSearchQuery(query)}
          selections={selections}
          handleSelect={(vals) => setSelections(vals)}
          options={options}
          searchVal={searchQuery}
        />
      </div>
      <div className="pt-4 text-right">
        <Button disabled={!selections.length} onClick={() => handleInvites()}>
          {submitText}
        </Button>
      </div>
    </>
  );
}

const UserGroupsSelector = inject(
  'commonStore',
  'teamHierarchyStore'
)(
  observer(({ commonStore, teamHierarchyStore, team, submitCallback }) => {
    const [scope, setScope] = useState('users');
    const [usersLoading, setUsersLoading] = useState(true);
    const [memberOptions, setMemberOptions] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    // Users fetching
    useEffect(() => {
      const getMembers = async () => {
        const currentInvites = await agents.enterprise.getClabBetaInvites(team.id);
        try {
          const members = await agents.enterprise.getMembersList(team.id);
          const membersData = members.data || [];
          // If we have members already invited to beta, remove them from the list of members to invite
          if (currentInvites && currentInvites.length) {
            const currentInvitesIds = currentInvites.map((invite) => invite.id);
            setMemberOptions(membersData.filter((member) => !currentInvitesIds.includes(member.value)));
          } else {
            setMemberOptions(membersData);
          }
        } catch (e) {
          Bugsnag.notify(e);
        } finally {
          setUsersLoading(false);
        }
      };
      // Get users list for this team
      getMembers();
    }, []);

    // Groups fetching
    /*
     * NOTE: Search query filtering in groups scope is handled in store due to the select options being more complex (nested options that disable based on level select)
     * Therefore, group filtering is more manually triggered as seen in useEffects below whereas for user scope it happens within selectBoxes comp automatically
     */
    const { trees, teams, getTeamHierarchy, setHierarchySelectOptions } = teamHierarchyStore;
    const getGroupSelectOptions = () => {
      setHierarchySelectOptions(team.id, null, searchQuery, null, true);
    };

    useEffect(() => {
      const getHierarchy = async () => {
        await getTeamHierarchy(team);
        getGroupSelectOptions();
      };
      getHierarchy();
    }, []);

    useEffect(() => {
      if (scope === 'groups') {
        getGroupSelectOptions();
      }
    }, [searchQuery]);

    useEffect(() => {
      setSearchQuery('');
      if (scope === 'groups') {
        getGroupSelectOptions();
      }
    }, [scope]);

    const teamHierarchy = trees[team.id];

    return (
      <>
        <h2 className="mb-6 text-2xl font-black">Select {scope === 'users' ? 'Users' : 'Groups'}</h2>
        <Tabs
          containerClasses="mb-4"
          tabs={[
            { id: 'users', text: 'Users' },
            { id: 'groups', text: 'Groups' },
          ]}
          selected={scope}
          onChange={(tab) => setScope(tab.id)}
        />
        {scope === 'users' ? (
          <UserGroupsOptions
            scope={scope}
            loading={usersLoading}
            options={memberOptions.filter((opt) => opt.text.toLowerCase().includes(searchQuery.toLowerCase()))}
            setSearchQuery={setSearchQuery}
            searchQuery={searchQuery}
            submitText="Invite Team Members"
            teamId={team.id}
            triggerToast={commonStore.triggerToast}
            submitCallback={submitCallback}
          />
        ) : (
          <UserGroupsOptions
            scope={scope}
            loading={teams[team.id].loading || !teamHierarchy}
            options={teamHierarchy.selectOptions}
            setSearchQuery={setSearchQuery}
            searchQuery={searchQuery}
            submitText="Invite Groups"
            teamId={team.id}
            triggerToast={commonStore.triggerToast}
            submitCallback={submitCallback}
          />
        )}
      </>
    );
  })
);

// Note: Component to be removed when Beta is over
const BetaInvite = inject(
  'commonStore',
  'userStore',
  'authStore'
)(
  observer(({ commonStore, userStore, authStore }) => {
    const { adminTeams } = userStore;
    const navigate = useNavigate();
    const [inviteModalOpen, setInviteModalOpen] = useState(false);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [betaTeams, setBetaTeams] = useState([]);

    const getTeamsWithAccess = () => {
      const adminTeamsIds = Object.keys(adminTeams);
      if (!adminTeamsIds.length) {
        return [];
      }
      // Get teams with beta access
      return adminTeamsIds.filter((id) => adminTeams[id].clab_access);
    };

    useEffect(() => {
      commonStore.setPageTitle(`Assessments (Beta) | Cybrary`);
      authStore.fireAttributionEvent();

      // NOTE: Access checks to be removed when Beta is over
      const { user } = userStore;
      // Get teams with beta access
      const teamsWithClabBeta = getTeamsWithAccess();
      // If no teams with Clab beta access or user has no clab access (not invited)or, redirect out
      if (!teamsWithClabBeta.length && !user.clab_access) {
        navigate('/');
      }
      setBetaTeams(teamsWithClabBeta);
    }, []);

    useEffect(() => {
      // If user is only on one team signed up to the beta, just use that team
      if (betaTeams.length === 1) {
        setSelectedTeam(adminTeams[betaTeams[0]]);
      }
    }, [betaTeams]);

    if (!betaTeams.length) {
      return null;
    }

    const closeModal = () => {
      setInviteModalOpen(false);
      if (betaTeams.length > 1) {
        setSelectedTeam(null);
      }
    };

    const teams = betaTeams.map((team) => adminTeams[team]);
    return (
      <>
        <Button onClick={() => setInviteModalOpen(true)}>Invite Team Members</Button>
        <Modal size="md" open={inviteModalOpen} toggle={closeModal}>
          <div className="px-4 pt-12">
            {selectedTeam ? (
              <UserGroupsSelector submitCallback={closeModal} team={selectedTeam} />
            ) : (
              <>
                <h2 className="mb-6 text-2xl font-black">Choose a Team</h2>
                <TeamSelectionButtons teams={teams} handleSelection={(team) => setSelectedTeam(team)} ctaText="Continue" />
              </>
            )}
          </div>
        </Modal>
      </>
    );
  })
);

export default BetaInvite;
