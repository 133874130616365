import React from 'react';
import { arrayMove, SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import Modal from '../Modal/Modal';
import Icon from '../Icon/Icon';
import FormatUtil from '../../utils/formatUtil';
import Tooltip from '../Tooltip/Tooltip';

/**
 * Draggable list handle
 * @type {React.ComponentClass<any>}
 */
const DragHandle = SortableHandle(() => (
  <Tooltip
    triggerContent={
      <div
        style={{
          width: '28px',
          marginRight: '1em',
          textAlign: 'center',
          cursor: 'pointer',
        }}
      >
        <Icon name="selector" aria-label="Drag and drop selected item" />{' '}
      </div>
    }
    content="Drag up or down"
    position="left"
  />
)); // This can be any component you want

/**
 * Sortable list item
 * @type {React.ComponentClass<{value?: *} & SortableElementProps>}
 */
const SortableItem = SortableElement(({ value, ...props }) => {
  const disableUp = props.disableClickItem(props.idx, 'up');
  const disableDown = props.disableClickItem(props.idx, 'down');
  return (
    <div className="flex items-start m-4">
      <div className="flex">
        <button
          className={disableUp ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}
          onClick={(e) => props.shiftListItem(e, props.idx, props.idx - 1)}
          aria-label="Move selected item up"
          disabled={disableUp}
        >
          <Icon name="arrow-narrow-up" className="w-4 h-4" />
        </button>
        <button
          className={disableDown ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}
          onClick={(e) => props.shiftListItem(e, props.idx, props.idx + 1)}
          aria-label="Move selected item down"
          disabled={disableDown}
        >
          <Icon name="arrow-narrow-down" className="w-4 h-4" />
        </button>
        <DragHandle />
      </div>
      {props.customRenderer(value)}
      <div style={{ flex: '1' }} />
      <Tooltip
        triggerContent={
          <button className="py-1" onClick={(e) => props.removeItemFromList(props.idx, e)} aria-label="Remove this content item from path">
            <Icon className="w-4 h-4 text-gray-600" name="x" />
          </button>
        }
        content="Remove from path"
        position="right"
        omitTabIndex
      />
    </div>
  );
});

/**
 * Sortable list
 * @type {React.ComponentClass<{items?: *} & SortableContainerProps>}
 */
const SortableList = SortableContainer(({ items, ...props }) => {
  return (
    <ul>
      {items.map((value, index) => (
        <li key={value.id}>
          <SortableItem
            index={index}
            value={value}
            idx={index}
            shiftListItem={props.shiftListItem}
            disableClickItem={props.disableClickItem}
            removeItemFromList={props.removeItemFromList}
            customRenderer={props.customRenderer}
          />
        </li>
      ))}
    </ul>
  );
});

class OrderedList extends React.Component {
  state = {
    value: [],
    modalOpen: false,
    totalTime: null,
  };

  componentDidMount() {
    if (this.props.defaultValue && this.props.defaultValue.length) {
      const newState = { ...this.state };
      this.props.defaultValue.forEach((val) => {
        newState.totalTime += val.duration_seconds;
        newState.value.push(val);
        this.setState(newState);
        this.props.onChange(undefined, { name: this.props.name, value: val });
      });
    }
  }

  /**
   * Callback that is invoked when sorting ends
   * @param oldIndex
   * @param newIndex
   */
  onSortEnd = ({ oldIndex, newIndex }) => {
    const newState = {
      ...this.state,
      value: arrayMove(this.state.value, oldIndex, newIndex),
    };
    this.setState(newState, () => {
      this.props.onChange(undefined, {
        name: this.props.name,
        value: this.state.value,
      });
    });
  };

  /**
   * Remove from list
   * @param index
   * @param e
   */
  removeItemFromList = (index, e) => {
    e.preventDefault();
    e.stopPropagation();
    const newState = { ...this.state };
    newState.totalTime -= newState.value[index].durationSeconds;
    newState.value.splice(index, 1);
    this.setState(newState);
    this.props.onChange(undefined, {
      name: this.props.name,
      value: newState.value,
    });
  };

  /**
   * Handle the actual add event!
   */
  handleAdd = (items) => {
    const newState = { ...this.state, modalOpen: false };
    let { totalTime } = this.state;
    items.forEach((item) => {
      newState.value.push(item);
      totalTime += 1 * item.durationSeconds;
    });
    newState.totalTime = totalTime;
    this.setState(newState);
    this.props.onChange(undefined, {
      name: this.props.name,
      value: newState.value,
    });
  };

  /**
   * Add to list
   * @param e
   */
  addItemToList = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const newState = {
      ...this.state,
      modalOpen: true,
    };
    this.setState(newState);
  };

  // Handle modal close
  handleClose = () => {
    const newState = {
      ...this.state,
      modalOpen: false,
    };
    this.setState(newState);
  };

  shiftListItem = (e, fromIndex, toIndex) => {
    e.preventDefault();
    const newState = { ...this.state };
    const element = newState.value[fromIndex];
    newState.value.splice(fromIndex, 1);
    newState.value.splice(toIndex, 0, element);
    this.props.onChange(undefined, {
      name: this.props.name,
      value: newState.value,
    });
  };

  disableClickItem = (idx, direction) => {
    const beginningList = idx === 0;
    const endList = idx === this.state.value.length - 1;
    return (beginningList && direction === 'up') || (endList && direction === 'down');
  };

  render() {
    return (
      <div className={this.props.labelClass || 'mb-4'}>
        <div className="py-4 px-5 mb-1 rounded-sm border-xs border-gray-400">
          <SortableList
            items={this.state.value}
            onSortEnd={this.onSortEnd}
            useDragHandle
            lockAxis="y"
            removeItemFromList={this.removeItemFromList}
            shiftListItem={this.shiftListItem}
            disableClickItem={this.disableClickItem}
            customRenderer={this.props.customRenderer}
          />
          {!this.state.value || !this.state.value.length ? <p>You haven&apos;t added any items yet.</p> : null}
          {this.state.totalTime ? (
            <span>
              <span style={{ fontWeight: 700 }}>Total Time: </span>
              {FormatUtil.formatTime(this.state.totalTime, 'hm')}
            </span>
          ) : null}
          <div className="py-4 px-5">
            <button
              onClick={this.addItemToList}
              aria-describedby={this.props.ariaDescribedBy}
              className="py-2.5 px-6 text-sm font-bold leading-5 text-center text-black bg-gray-200 rounded-sm"
            >
              Add Item
            </button>
          </div>
          <Modal position="center" size="lg" open={this.state.modalOpen} toggle={this.handleClose} ariaLabel="Add Items to Path" modalOverflow="overflow-visible">
            <div className="px-7 pt-14">{this.props.addProvider(this.state.value, this.handleAdd)}</div>
          </Modal>
        </div>
      </div>
    );
  }
}

export default OrderedList;
