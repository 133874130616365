import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import Cookies from 'js-cookie';
import Icon from '../Icon/Icon';
import Modal from '../Modal/Modal';
import Divider from '../Divider/Divider';
import './email-reminder.css';
import { DEFAULT_COOKIE_SETTINGS } from '../../constants';

const EmailReminder = inject('userStore')(
  observer(({ userStore }) => {
    const navigate = useNavigate();
    const [hideReminder, setHideReminder] = useState(true);

    useEffect(() => {
      const { user } = userStore;
      const promptDismissed = Cookies.get('backup_email_reminder');

      // First check if user is eligible to see this backup reminder
      setHideReminder(
        userStore.isUserWithinWeekOfRegistration ||
          promptDismissed ||
          user.total_accounts > 1 ||
          window.location.pathname.includes('/onboarding') ||
          window.location.pathname.includes('/upgrade') ||
          window.location.pathname.includes('/link-account') ||
          window.location.pathname.includes('/settings/account') ||
          window.location.pathname.includes('/team-join') ||
          /cybrary.(com|it)$/.test(user.email)
      );

      // If eligible, delay 3 secs then check if we are showing a pendo guide. If so, not eligible this time
      if (!hideReminder) {
        setTimeout(() => {
          if (window.pendo && window.pendo.isGuideShown) {
            setHideReminder(window.pendo.isGuideShown());
          }
        }, 3000);
      }
    }, []);

    const hideBackupEmailModal = useCallback(() => {
      setHideReminder(true);

      // set cookie to expire in 30 days, upon expiring prompt user again to add recovery email
      Cookies.set('backup_email_reminder', true, {
        ...DEFAULT_COOKIE_SETTINGS,
        path: '/',
        expires: 30,
      });
    }, []);

    if (hideReminder) {
      return null;
    }
    return (
      <Modal open toggle={() => hideBackupEmailModal()} ariaLabelledBy="add-secondary-email">
        <h2 id="add-secondary-email" className="pt-10 pl-8 text-xl font-bold text-black">
          Add a Secondary Email
        </h2>
        <Divider marginTop="mt-4" marginBottom="mb-4" />
        <div className="mx-8">
          <div className="flex items-center mb-4">
            <div className="hidden align-bottom md:block">
              <Icon className="w-12 h-12" name="unlock" />
            </div>
            <div className="md:ml-8">
              <div className="text-xl font-bold text-black">Don&apos;t get locked out.</div>
              <p className="text-sm font-normal text-gray-600">Add another email to make sure you&apos;ll never lose access to Cybrary</p>
            </div>
          </div>
          <p className="text-xs text-gray-600">If you lose access to your primary email, you will lose access to your Cybrary account.</p>
          <div className="flex flex-col w-full text-right md:flex-row md:justify-end">
            <button className="py-2.5 px-6 mb-4 text-sm font-bold leading-5 text-center text-black bg-gray-200 rounded-sm md:mb-0" onClick={() => hideBackupEmailModal()}>
              Do this later
            </button>
            <button
              onClick={() => {
                navigate('/settings/account?settings=email');
                hideBackupEmailModal();
              }}
              className="py-2.5 px-6 text-sm font-bold leading-5 text-center text-white hover:text-white bg-cyb-pink-500 hover:bg-pink-600 rounded-sm md:ml-4"
            >
              Add an Email
            </button>
          </div>
        </div>
      </Modal>
    );
  })
);

export default EmailReminder;
