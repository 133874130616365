import React from 'react';
import AddLink from '../../AddLink/AddLink';

export default function BaselineEmptyText({ orgId }) {
  return (
    <p>
      <span>Click</span>
      <AddLink className="text-cyb-pink-500 hover:underline" to={`/enterprise/${orgId}/paths/assessments`}>
        {' '}
        here{' '}
      </AddLink>
      <span>to start assessing your team today!</span>
    </p>
  );
}
