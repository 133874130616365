import React from 'react';
import { observer, inject } from 'mobx-react';
import withRouter from '../../components/Router/withRouter';
import Title from '../../components/Title/Title';
import Container from '../../components/Container/Container';
import Divider from '../../components/Divider/Divider';
import OrganizationLogo from '../../components/Enterprise/OrganizationLogo';
import ManageSso from '../../components/TeamsSso/ManageSso';
import './enterprise.css';
import ConfiguredDomains from '../../components/TeamsSso/ConfiguredDomains';
import ActionUtil from '../../utils/actionsUtil';

const TeamSettings = inject(
  'enterpriseStore',
  'authStore',
  'userStore',
  'commonStore'
)(
  observer(
    class TeamSettings extends React.Component {
      componentDidMount() {
        const isDemo = this.props.match.params.orgId === 'demo';
        if (isDemo) {
          this.props.userStore.enterDemo();
        } else {
          this.props.userStore.setPreferredTeamInit('', this.props.match.params.orgId);
        }
        this.props.commonStore.setPageTitle(`Settings${isDemo ? ' Demo' : ''} | Cybrary`);
        this.props.authStore.fireAttributionEvent();
        // scroll to top
        ActionUtil.scrollToTop();
      }

      componentDidUpdate(prevProps) {
        this.props.userStore.setPreferredTeamInit(prevProps.match.params.orgId, this.props.match.params.orgId);
      }

      componentWillUnmount() {
        // reset demo on unmount
        if (this.props.match.params.orgId === 'demo') {
          this.props.userStore.exitDemo();
        }
      }

      render() {
        const { team } = this.props.userStore;
        const isDemo = this.props.match.params.orgId === 'demo';

        // Do not want to try and get settings before current team is set
        if (!team) {
          return null;
        }

        return (
          <Container size="md">
            <Title title="Settings" />
            <div className="grid grid-cols-2">
              <div>
                <h2 className="mb-4 text-2xl font-black">Team Logo</h2>
                <div className="w-48 h-48">
                  <OrganizationLogo className="flex justify-center items-center w-full h-full" teamId={team.id} logoUrl={team.logo_url} restrictLogoEdit={isDemo} />
                </div>
              </div>
              <div>
                <h2 className="mb-4 text-2xl font-black">Team Email Domains</h2>
                <ConfiguredDomains domains={this.props.userStore.teamData.domains} />
              </div>
            </div>
            <Divider />

            <h2 className="mb-4 text-2xl font-black">Team SSO</h2>
            <ManageSso team={this.props.userStore.team} commonStore={this.props.commonStore} />
          </Container>
        );
      }
    }
  )
);

export default withRouter(TeamSettings);
