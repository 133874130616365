import React from 'react';

export default function Calendar({ classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';

  return (
    <svg className={className} viewBox="0 0 35 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...accessibilityProps}>
      <path
        d="M9.69696 15.5151H7.54208C6.98424 15.5151 6.53198 15.9803 6.53198 16.5541C6.53198 17.1279 6.98424 17.5931 7.54208 17.5931H9.69696C10.2548 17.5931 10.7071 
        17.1279 10.7071 16.5541C10.7071 15.9803 10.2548 15.5151 9.69696 15.5151Z"
        fill="currentColor"
      />
      <path
        d="M9.69696 19.948H7.54208C6.98424 19.948 6.53198 20.4132 6.53198 20.987C6.53198 21.5607 6.98424 22.0259 7.54208 22.0259H9.69696C10.2548 22.0259 10.7071 
        21.5607 10.7071 20.987C10.7071 20.4132 10.2548 19.948 9.69696 19.948Z"
        fill="currentColor"
      />
      <path
        d="M9.69696 24.381H7.54208C6.98424 24.381 6.53198 24.8462 6.53198 25.4199C6.53198 25.9937 6.98424 26.4589 7.54208 26.4589H9.69696C10.2548 26.4589 10.7071 
        25.9937 10.7071 25.4199C10.7071 24.8462 10.2548 24.381 9.69696 24.381Z"
        fill="currentColor"
      />
      <path
        d="M18.3165 15.5151H16.1616C15.6037 15.5151 15.1515 15.9803 15.1515 16.5541C15.1515 17.1279 15.6037 17.5931 16.1616 17.5931H18.3165C18.8743 17.5931 19.3266 
        17.1279 19.3266 16.5541C19.3266 15.9803 18.8743 15.5151 18.3165 15.5151Z"
        fill="currentColor"
      />
      <path
        d="M18.3165 19.948H16.1616C15.6037 19.948 15.1515 20.4132 15.1515 20.987C15.1515 21.5607 15.6037 22.0259 16.1616 22.0259H18.3165C18.8743 22.0259 19.3266 21.5607 
        19.3266 20.987C19.3266 20.4132 18.8743 19.948 18.3165 19.948Z"
        fill="currentColor"
      />
      <path
        d="M18.3165 24.381H16.1616C15.6037 24.381 15.1515 24.8462 15.1515 25.4199C15.1515 25.9937 15.6037 26.4589 16.1616 26.4589H18.3165C18.8743 26.4589 19.3266 25.9937 
        19.3266 25.4199C19.3266 24.8462 18.8743 24.381 18.3165 24.381Z"
        fill="currentColor"
      />
      <path
        d="M26.936 15.5151H24.7811C24.2233 15.5151 23.771 15.9803 23.771 16.5541C23.771 17.1279 24.2233 17.5931 24.7811 17.5931H26.936C27.4938 17.5931 27.9461 17.1279 
        27.9461 16.5541C27.9461 15.9803 27.4938 15.5151 26.936 15.5151Z"
        fill="currentColor"
      />
      <path
        d="M26.936 19.948H24.7811C24.2233 19.948 23.771 20.4132 23.771 20.987C23.771 21.5607 24.2233 22.0259 24.7811 22.0259H26.936C27.4938 22.0259 27.9461 21.5607 
        27.9461 20.987C27.9461 20.4132 27.4938 19.948 26.936 19.948Z"
        fill="currentColor"
      />
      <path
        d="M26.936 24.381H24.7811C24.2233 24.381 23.771 24.8462 23.771 25.4199C23.771 25.9937 24.2233 26.4589 24.7811 26.4589H26.936C27.4938 26.4589 27.9461 25.9937 
        27.9461 25.4199C27.9461 24.8462 27.4938 24.381 26.936 24.381Z"
        fill="currentColor"
      />
      <path
        d="M31.4478 2.77056H29.0236V1.03896C29.0236 0.465177 28.5713 0 28.0135 0C27.4556 0 27.0034 0.465177 27.0034 1.03896V2.77056H18.2492V1.03896C18.2492 0.465177 
        17.7969 0 17.2391 0C16.6812 0 16.229 0.465177 16.229 1.03896V2.77056H7.47475V1.03896C7.47475 0.465177 7.02249 0 6.46465 0C5.9068 0 5.45455 0.465177 5.45455 
        1.03896V2.77056H3.0303C1.35939 2.77056 0 4.1688 0 5.88745V28.8831C0 30.6018 1.35939 32 3.0303 32H31.4478C33.1187 32 34.4781 30.6018 34.4781 28.8831C34.4781 
        28.2125 34.4781 6.471 34.4781 5.88745C34.4781 4.1688 33.1187 2.77056 31.4478 2.77056ZM2.0202 5.88745C2.0202 5.31456 2.47333 4.84848 3.0303 
        4.84848H5.45455V6.58009C5.45455 7.15387 5.9068 7.61905 6.46465 7.61905C7.02249 7.61905 7.47475 7.15387 7.47475 6.58009V4.84848H16.229V6.58009C16.229 
        7.15387 16.6812 7.61905 17.2391 7.61905C17.7969 7.61905 18.2492 7.15387 18.2492 6.58009V4.84848H27.0034V6.58009C27.0034 7.15387 27.4556 7.61905 28.0135 
        7.61905C28.5713 7.61905 29.0236 7.15387 29.0236 6.58009V4.84848H31.4478C32.0048 4.84848 32.4579 5.31456 32.4579 5.88745V9.97403H2.0202V5.88745ZM31.4478 
        29.9221H3.0303C2.47333 29.9221 2.0202 29.456 2.0202 28.8831V12.0519H32.4579V28.8831C32.4579 29.456 32.0048 29.9221 31.4478 29.9221Z"
        fill="currentColor"
      />
    </svg>
  );
}
