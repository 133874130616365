import React from 'react';

export default function HandWave({ classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';
  return (
    <svg className={className} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...accessibilityProps}>
      <path
        d="M30.039 17.3089C30.6303 16.7187 30.9563 15.9338 30.9567 15.0983C30.9571 14.2629 30.632 13.4775 30.0413 12.8867C29.7405 12.5859 29.3924 12.3596 
        29.0205 12.2075L30.3037 10.9291C30.8964 10.3392 31.2234 9.5539 31.2243 8.7177C31.2253 7.8815 30.9003 7.09536 30.309 6.50409L30.2758 6.47086C29.8195 
        6.0146 29.2555 5.72927 28.6645 5.61494C28.7004 5.42493 28.7194 5.23038 28.7196 5.03276C28.7205 4.19668 28.3954 3.4106 27.8042 2.81946L27.7634 
        2.77868C27.1731 2.18837 26.3883 1.86329 25.5535 1.86329C24.9899 1.86329 24.4494 2.012 23.9758 2.28997C23.8216 1.87244 23.5782 1.48955 23.2545 
        1.16588L23.2276 1.13886C22.0091 -0.0794043 20.0264 -0.0795963 18.8078 1.13892L10.4564 9.49053L11.4937 5.20663C11.9036 3.51399 10.9787 1.788 9.34238 
        1.19174C8.42155 0.856156 7.39969 0.932593 6.5389 1.40114C5.67812 1.86969 5.05932 2.68643 4.84114 3.64197L4.6989 4.26525C4.01851 7.24561 2.89384 
        10.2406 1.35613 13.1667C0.203869 15.3593 -0.234975 17.9236 0.120453 20.3871C0.488173 22.936 1.6476 25.2485 3.47346 27.0743C5.71474 29.3156 8.69362 
        30.5496 11.863 30.5495H11.871C15.0433 30.5474 18.024 29.3092 20.2641 27.0632L22.6551 24.6658L30.039 17.3089ZM18.9043 25.7069C17.0268 27.5894 
        14.5285 28.6271 11.8698 28.6289H11.863C9.20686 28.6289 6.7099 27.5947 4.83148 25.7163C3.30106 24.1859 2.32933 22.2482 2.02128 20.1129C1.72321 
        18.0469 2.0908 15.8972 3.05626 14.0601C4.67131 10.9868 5.85397 7.83515 6.5713 4.69263L6.71354 4.06941C6.81047 3.64478 7.07454 3.29627 7.45711 
        3.08802C7.83969 2.87977 8.27584 2.84725 8.68498 2.99628C9.4016 3.25741 9.80671 4.01333 9.6272 4.75466L7.7854 12.3602C7.56691 13.1442 8.57692 
        14.0174 9.39769 13.2652L20.1659 2.497C20.6356 2.02717 21.3999 2.0273 21.8689 2.49611L21.8957 2.52306C22.1233 2.75064 22.2486 3.05306 22.2486 
        3.37488C22.2486 3.6967 22.1232 3.99919 21.8957 4.22677C20.143 5.97946 16.9992 9.12326 15.2787 10.8437C14.9037 11.2187 14.9037 11.8267 15.2787 
        12.2018C15.6537 12.5768 16.2618 12.5768 16.6367 12.2018C17.6997 11.1387 23.8069 5.03161 24.7018 4.13676C24.9294 3.90918 25.2319 3.78389 25.5537 
        3.78389C25.8755 3.78389 26.178 3.90918 26.4056 4.13676L26.4463 4.17754C26.6742 4.40544 26.7995 4.70844 26.7992 5.03071C26.7988 5.35298 26.6728 
        5.65572 26.4443 5.88324C24.0365 8.28142 20.7435 11.5602 18.36 13.9356C17.9842 14.3099 17.9831 14.9179 18.3573 15.2937C18.545 15.482 18.7913 15.5763 
        19.0377 15.5763C19.2828 15.5763 19.528 15.483 19.7153 15.2964C21.3175 13.7004 25.6829 9.35186 27.2162 7.82689C27.6859 7.35949 28.4492 7.36039 
        28.9178 7.82887L28.951 7.8621C29.179 8.09007 29.3042 8.39307 29.3039 8.7154C29.3035 9.03773 29.1774 9.34047 28.9487 9.56812C26.6131 11.8945 
        22.9142 15.5783 20.5992 17.8862C20.2235 18.2606 20.2223 18.8686 20.5966 19.2443C20.7843 19.4326 21.0305 19.5268 21.2769 19.5268C21.522 19.5268 
        21.7673 19.4335 21.9546 19.2469L26.3579 14.8601L26.984 14.2404C27.4537 13.7755 28.2161 13.7776 28.6833 14.2448C28.911 14.4725 29.0363 14.7752 
        29.0361 15.0972C29.036 15.4192 28.9103 15.7219 28.6829 15.9489L21.2974 23.3074L18.9043 25.7069Z"
        fill="black"
      />
      <path
        d="M28.8803 23.9752C29.089 23.4877 28.863 22.9231 28.3755 22.7145C27.8879 22.5057 27.3235 22.7318 27.1148 23.2194C26.7926 23.9718 26.3328 24.6498 
        25.7482 25.2344C25.034 25.9486 24.19 26.4715 23.2397 26.7884C22.7366 26.9562 22.4648 27.5 22.6326 28.0032C22.7668 28.4055 23.1414 28.6599 23.5434 
        28.6599C23.6441 28.6599 23.7466 28.644 23.8474 28.6104C25.083 28.1982 26.1795 27.5194 27.1063 26.5925C27.8646 25.834 28.4615 24.9535 28.8803 
        23.9752Z"
        fill="black"
      />
      <path
        d="M31.4182 24.504C30.9307 24.295 30.3662 24.5207 30.1572 25.0082C29.7085 26.0548 29.0687 26.9978 28.2556 27.8109C27.3099 28.7566 26.1976 29.463 
        24.9495 29.9104C24.4503 30.0894 24.1906 30.6392 24.3696 31.1385C24.5102 31.5307 24.8796 31.7749 25.2736 31.7749C25.3811 31.7749 25.4905 31.7567 
        25.5977 31.7183C27.1148 31.1744 28.4659 30.3166 29.6136 29.1689C30.6003 28.1823 31.377 27.037 31.9224 25.7649C32.1313 25.2774 31.9056 24.7129 
        31.4182 24.504Z"
        fill="black"
      />
    </svg>
  );
}
