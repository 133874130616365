import React from 'react';
import Icon from '../Icon/Icon';
import Tooltip from '../Tooltip/Tooltip';

function StatusIcon({ passed, statusIconSize = 16 }) {
  const iconClasses = `w-${statusIconSize} h-${statusIconSize} text-gray-600 mx-auto`;
  if (passed) {
    return <Icon name="check" className={iconClasses} />;
  }
  return <Icon name="minus" className={iconClasses} />;
}

function PracticeTestDisplayLabel({ attempts, statusMessage, passed, highScore, omitText, statusIconSize = 16 }) {
  if (omitText) {
    return <StatusIcon passed={passed} statusIconSize={statusIconSize} />;
  }
  return (
    <div className="text-center text-gray-600" style={{ maxWidth: '7.5rem' }}>
      <StatusIcon passed={passed} statusIconSize={statusIconSize} />
      <div className="text-xs font-bold">
        {statusMessage}
        <Tooltip content="A score of 80 or higher is required to complete this goal" triggerContent={<Icon name="question-circle" className="inline-block ml-1 w-4 h-4" />} />
      </div>
      {highScore || attempts ? <p className="text-xs">High score: {highScore || 0}</p> : null}
    </div>
  );
}

export default PracticeTestDisplayLabel;
