import React, { useEffect, useRef } from 'react';
import Tooltip from '../Tooltip/Tooltip';
import If from '../If/If';
import AddLink from '../AddLink/AddLink';
import './analytics.css';

function PercentageBar({ width, maxHeight, popupContent, speed, color, value, toHref }) {
  const placementBarRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      if (placementBarRef.current) {
        placementBarRef.current.classList.remove('progress-hidden');
      }
    }, 400);
  }, [placementBarRef]);

  return (
    <div
      className="percent-slider-container progress-hidden"
      ref={placementBarRef}
      style={{
        width: `${width}`,
        maxHeight: `${maxHeight || '1em'}`,
      }}
    >
      <Tooltip
        disabled={!popupContent}
        content={popupContent}
        omitTabIndex
        triggerContent={
          <div
            className={`percent-slider ${speed || ''}`}
            style={{
              width: '100%',
              background: `${color}`,
              transition: `width 300 linear`,
              maxHeight: `${maxHeight || '1em'}`,
            }}
          >
            <If condition={Boolean(value)}>
              {toHref ? (
                <AddLink className="text-cyb-pink-500 underline" to={toHref}>
                  <span className="value">{value}</span>
                </AddLink>
              ) : (
                <span className="value">{value}</span>
              )}
            </If>
          </div>
        }
      />
    </div>
  );
}

export default PercentageBar;
