import React, { Component } from 'react';
import { inject } from 'mobx-react';
import Collapsible from 'react-collapsible';
import Bugsnag from '@bugsnag/js';
import withRouter from '../Router/withRouter';
import Icon from '../Icon/Icon';
import ProgressBarDisplayLabel from './ProgressBarDisplayLabel';
import PercentageBar from '../Analytics/PercentageBar';
import Table from '../Table/Table';
import Loading from '../Loading/Loading';
import StyledError from '../Error/StyledError';
import AvatarsList from '../Avatar/AvatarsList';
import Agents from '../../agents/agents';
import FormatUtil from '../../utils/formatUtil';
import StyleUtil from '../../utils/styleUtil';
import TeamsIcon from '../../Icons/TeamsIcon';
import ChevronDown from '../../Icons/ChevronDown';

function GoalReportLink({ progressMetric, goalId, goalName, teamId, practiceTest, triggerConfirm, resetConfirmState, navigate, parentId }) {
  const isGroup = 1 * parentId !== 1 * teamId;
  const reportsConfig = {
    certification: {
      reportName: 'content',
      params: `&contentDescriptionId=${practiceTest && practiceTest.id}&contentName=${practiceTest && practiceTest.title}`,
    },
    ceus: { reportName: 'ceu' },
    learning_hours: { reportName: 'learning-hours' },
    activities: { reportName: 'learning-activities' },
  };
  const { reportName, params } = reportsConfig[progressMetric];
  let queryString = `?goalId=${goalId}&goalName=Goal:%20${goalName}${params || ''}`;
  if (isGroup) {
    queryString += `&groupId=${parentId}`;
  }
  const confirmObj = {
    content: 'You are about to leave this page to view a report. Do you want to continue?',
    cancel: () => resetConfirmState(),
    cancelBtn: 'Cancel',
    confirmBtn: 'Continue',
    doNotShowAgainId: 'leaveToReports',
    continue: () => {
      resetConfirmState();
      navigate(`/enterprise/${teamId}/reporting/report/${reportName}/${queryString}`);
    },
  };

  return (
    <button className="ml-2 text-sm text-cyb-pink-500 hover:text-black cursor-pointer" aria-label="Open Goal's Report" onClick={() => triggerConfirm(confirmObj)}>
      <Icon name="line-graph" className="inline-block ml-1 w-4 h-4 fill-current" />
    </button>
  );
}

function PracticeTestProgress({ data }) {
  if (!data || !data.length) {
    return null;
  }
  return (
    <div className="overflow-x-auto mb-4">
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell scope="col" className="text-xs">
              Member
            </Table.HeaderCell>
            <Table.HeaderCell scope="col" className="text-xs">
              Attempts
            </Table.HeaderCell>
            <Table.HeaderCell scope="col" className="text-xs">
              High Score
            </Table.HeaderCell>
            <Table.HeaderCell scope="col" className="text-xs">
              Passed
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data.map((child) => {
            const passedTest = Math.floor(1 * child.passed);
            return (
              <Table.Row key={child.id}>
                <Table.Cell className="text-xs">
                  <div className="flex items-center">
                    <AvatarsList avatarSizes="w-6 h-6" disablePopup data={[child]} />
                    <span className="ml-2">{child.name}</span>
                  </div>
                </Table.Cell>
                <Table.Cell className="text-xs">{Math.floor(1 * child.attempts)}</Table.Cell>
                <Table.Cell className="text-xs">{child.pending_results && 1 * child.pending_results ? 'Pending' : Math.floor(1 * child.score)}</Table.Cell>
                <Table.Cell className={`text-xs ${passedTest ? 'text-green-500' : 'text-red-500'}`}>{passedTest ? 'Yes' : 'No'}</Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
}

function GoalProgressExpanderChildren({ data, goalId, groupScopeQuery, progressMetric, totals, navigate, practiceTest, teamId, goalName, outcomeType, isProgramType }) {
  return data.map((child) => {
    return (
      <GoalProgressExpander
        teamGroupId={child.participant_id}
        goalId={goalId}
        key={child.participant_id}
        participantType={child.participant_type}
        participants={[child]}
        progress={child}
        totals={totals}
        groupScopeQuery={groupScopeQuery}
        progressMetric={progressMetric}
        navigate={navigate}
        practiceTest={practiceTest}
        teamId={teamId}
        goalName={goalName}
        outcomeType={outcomeType}
        isProgramType={isProgramType}
        nested
      />
    );
  });
}

function ParticipantChildrenProgress({
  data,
  loading,
  error,
  goalId,
  groupScopeQuery,
  progressMetric,
  totals,
  showPracticeTestBreakdown,
  navigate,
  practiceTest,
  teamId,
  goalName,
  outcomeType,
  isProgramType,
}) {
  if (loading) {
    return (
      <div>
        <Loading message="Loading..." />
      </div>
    );
  }
  if (error) {
    return (
      <div>
        <StyledError error={error} />
      </div>
    );
  }
  if (!data || !data.length) {
    return null;
  }

  // If showPracticeTestBreakdown, Separate out children that are users vs groups
  // Send the user children to display breakdown and the groups to the expander
  if (showPracticeTestBreakdown) {
    const userProgressChildren = [];
    const teamProgessChildren = [];
    data.forEach((child) => {
      if (child.participant_type === 'user') {
        userProgressChildren.push(child);
      } else {
        teamProgessChildren.push(child);
      }
    });
    return (
      <div className="mt-6">
        <GoalProgressExpanderChildren
          data={teamProgessChildren}
          goalId={goalId}
          totals={totals}
          groupScopeQuery={groupScopeQuery}
          progressMetric={progressMetric}
          navigate={navigate}
          practiceTest={practiceTest}
          teamId={teamId}
          goalName={goalName}
          outcomeType={outcomeType}
          isProgramType={isProgramType}
        />
        <PracticeTestProgress data={userProgressChildren} />
      </div>
    );
  }

  return (
    <div className="mt-6">
      <GoalProgressExpanderChildren
        data={data}
        goalId={goalId}
        totals={totals}
        groupScopeQuery={groupScopeQuery}
        progressMetric={progressMetric}
        navigate={navigate}
        practiceTest={practiceTest}
        teamId={teamId}
        goalName={goalName}
        outcomeType={outcomeType}
        isProgramType={isProgramType}
      />
    </div>
  );
}

@inject('goalsStore', 'commonStore')
class GoalProgressExpander extends Component {
  state = {
    collapseOpen: false,
    childDataLoading: true,
    childDataError: false,
    childData: null,
  };

  // Sum up all of the users not within a group already to put into an "Other Users" group
  createUserGroupProgress = (users) => {
    if (!users || !users.length) {
      return [];
    }
    const userGroup = {
      participant_avatar_url: null,
      participant_count: users.length,
      participant_id: null,
      participant_name: 'Other Members',
      participant_type: 'user-group',
      children: [...users],
    };
    const progressTotals = {
      activities_completed: 0,
      percent_completed: 0,
      learning_seconds_completed: 0,
      learning_hours_completed: 0,
      ceus_completed: 0,
      passed: 0,
      score: 0,
    };
    // Need to accumulate the progress totals of all users
    users.forEach((user) => {
      Object.keys(progressTotals).forEach((key) => {
        if (key in user) {
          progressTotals[key] += 1 * user[key];
        }
      });
    });

    // Now get the average of all the totals between the users
    Object.keys(progressTotals).forEach((key) => {
      if (key === 'passed') {
        userGroup[key] = progressTotals[key];
      } else {
        userGroup[key] = progressTotals[key] / users.length;
      }
    });

    return [userGroup];
  };

  // Loop through the graph primary colors and assign a color to each participant group/user
  assignParticipantColors = (participants) => {
    if (!participants || !participants.length) {
      return [];
    }
    const colorPalette = StyleUtil.getGraphColorDefaults().primaryColors;
    // Track which color from primary colors to assign to child
    let colorIdx = 0;
    const revisedParticipants = [];
    participants.forEach((item) => {
      const participant = {
        ...item,
        progressColor: colorPalette[colorIdx],
      };
      if (participant.children && participant.children.length) {
        const revisedChildren = this.assignParticipantColors(participant.children);
        participant.children = revisedChildren;
      }
      revisedParticipants.push(participant);
      // If we've reached the end of the color list, start over
      colorIdx = colorIdx + 1 === colorPalette.length ? 0 : colorIdx + 1;
    });
    return revisedParticipants;
  };

  showProgressChildren = (parentId, progress) => {
    // Determine if we already have child data or not so we don't to prevent unnecessary server calls
    let childData = this.state.childData || null;
    if (progress.children) {
      childData = progress.children;
    }
    const newState = {
      ...this.state,
      collapseOpen: !this.state.collapseOpen,
      childData,
      childDataLoading: !childData,
    };
    this.setState(newState, () => {
      if (!childData) {
        this.getParticipantChildren(parentId);
      }
    });
  };

  getParticipantChildren = (parentId) => {
    const { goalId, groupScopeQuery, progressMetric } = this.props;
    // Set loading/open state first, then get children (if open)
    if (this.state.collapseOpen) {
      const childProgressState = { ...this.state };
      const formattedQueryString = groupScopeQuery && groupScopeQuery.length ? `?${groupScopeQuery}` : '';
      Agents.goals
        .getTeamGoalProgress(parentId, goalId, formattedQueryString)
        .then((response) => {
          const childProgress = response && response.length ? response : [];
          // Standardize some field keys to match between what was passed in from goal details vs progress calls
          const cleanParticipants = childProgress.map((item) => {
            const progress = {
              ...item,
              id: item.participant_id,
              name: item.participant_name,
              avatar_url: item.participant_avatar_url,
            };
            // If metric is learning hours, the progress comes in seconds. Convert to hours for displaying
            if (progressMetric === 'learning_hours') {
              progress.learning_hours_total = FormatUtil.convertSecondsToHours(1 * progress.learning_seconds_total, true);
              progress.learning_hours_completed = FormatUtil.convertSecondsToHours(1 * progress.learning_seconds_completed, true);
            }
            return progress;
          });

          // Sort return by putting all user participants first, then groups
          const groupParticipants = cleanParticipants.filter((participant) => participant.participant_type === 'team');
          const users = cleanParticipants.filter((participant) => participant.participant_type === 'user');
          // If we have groups, and stray users not in a group, accumulate them into an "Other Members" pseudo group
          const userParticipants = groupParticipants.length ? this.createUserGroupProgress(users) : users;
          const orderedParticipants = [...groupParticipants, ...userParticipants];
          const finalizedParticipants = this.assignParticipantColors(orderedParticipants);
          childProgressState.childData = finalizedParticipants;
          childProgressState.childDataLoading = false;
          this.setState(childProgressState);
        })
        .catch((e) => {
          Bugsnag.notify(e);
          childProgressState.childDataError = e;
          childProgressState.childDataLoading = false;
          this.setState(childProgressState);
        });
    }
  };

  /* Determine name based on type, length, and whether this is a nested item or not */
  getParticipantName = (participants, participantType, isNested) => {
    let header = participants[0].name || participants[0].participant_name;
    if (!isNested && participants.length > 1) {
      header = `${participants.length} ${participantType === 'team' ? 'group' : participantType}${participants.length > 1 ? 's' : ''}`;
    }
    return FormatUtil.formatLongText(header, 30);
  };

  render() {
    const {
      participants,
      participantType,
      progress,
      progressMetric,
      nested,
      goalId,
      goalName,
      groupScopeQuery,
      totals,
      teamGroupId,
      teamId,
      practiceTest,
      navigate,
      outcomeType,
      isProgramType,
    } = this.props;
    const { triggerConfirm, resetConfirmState } = this.props.commonStore;
    const { collapseOpen, childDataLoading, childDataError, childData } = this.state;
    if (!participants || !participants.length || !progress) {
      return null;
    }
    const isSingleUser = participantType === 'user' && participants.length === 1;
    const preppedTotals = isSingleUser ? this.props.goalsStore.prepGoalTotals(progress, totals, outcomeType) : totals;
    const goalProgressPercentage = this.props.goalsStore.getProgressPercentage(progress, preppedTotals, progressMetric, isProgramType);
    const goalProgressDisplay = this.props.goalsStore.getProgressDisplay(progress, preppedTotals, progressMetric);
    const goalProgressSuffix = this.props.goalsStore.getProgressSuffix(goalProgressDisplay, preppedTotals, progressMetric, progress.participant_count, true);

    const name = this.getParticipantName(participants, participantType, nested);

    const isUserGroup = participantType === 'user-group';
    // If a group admin, teamGroupId might just be whatever the 'preferred group' in user store is which is fine to get progress for groups of users
    // But if participants is a single team/group, want to make sure we use that ID
    const parentId = !nested && participants.length === 1 && participantType === 'team' ? participants[0].id : teamGroupId;
    const showPracticeTestBreakdown = progressMetric === 'certification' && 'score' in progress;
    const avatars =
      participantType === 'user' ? (
        <AvatarsList avatarSizes="w-6 h-6" disablePopup data={participants} limit={2} />
      ) : (
        <div className="p-1 text-gray-600 rounded-full border border-gray-600 group-avatar">
          <TeamsIcon classes="w-4 h-4" />
        </div>
      );
    const chevronClasses = `w-3 h-3 transition-transform ${collapseOpen ? 'transform rotate-180' : ''}`;

    return (
      <div className={`max-w-xl w-full flex ${nested ? 'mt-6' : ''}`}>
        {nested || (!nested && isSingleUser) ? <div className="mr-2">{avatars}</div> : null}
        <div className="w-full">
          <div className="flex justify-between items-center mb-2 text-xs">
            <div className="flex items-center">
              {!isSingleUser ? (
                <button
                  onClick={() => this.showProgressChildren(parentId, progress)}
                  className="inline-block p-1 mr-2 bg-gray-200 rounded-full cursor-pointer"
                  aria-label="Toggle additional goal progress"
                >
                  <ChevronDown classes={chevronClasses} />
                </button>
              ) : null}
              {name}
              {!isSingleUser && !isUserGroup ? (
                <GoalReportLink
                  progressMetric={progressMetric}
                  goalId={goalId}
                  goalName={goalName}
                  parentId={parentId}
                  teamId={teamId}
                  practiceTest={practiceTest}
                  triggerConfirm={triggerConfirm}
                  resetConfirmState={resetConfirmState}
                  navigate={navigate}
                />
              ) : null}
            </div>
            <ProgressBarDisplayLabel
              isAggregateView={!isSingleUser && !showPracticeTestBreakdown}
              goalProgressPercentage={goalProgressPercentage}
              goalProgressDisplay={goalProgressDisplay}
              goalProgressSuffix={goalProgressSuffix}
              percentageOnly={isProgramType}
              percentageClasses="ml-2"
            />
          </div>
          <div className={`bg-gray-200 rounded overflow-hidden ${nested ? 'h-1' : 'h-2'}`}>
            <PercentageBar color={progress.progressColor || '#E2037A'} width={`${goalProgressPercentage}%`} />
          </div>
          <Collapsible open={collapseOpen} transitionTime={200}>
            <ParticipantChildrenProgress
              goalId={goalId}
              data={childData}
              loading={childDataLoading}
              error={childDataError}
              progressMetric={progressMetric}
              groupScopeQuery={groupScopeQuery}
              totals={preppedTotals}
              showPracticeTestBreakdown={showPracticeTestBreakdown}
              navigate={navigate}
              teamId={teamId}
              practiceTest={practiceTest}
              goalName={goalName}
              outcomeType={outcomeType}
              isProgramType={isProgramType}
            />
          </Collapsible>
        </div>
      </div>
    );
  }
}

export default withRouter(GoalProgressExpander);
