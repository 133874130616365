import { v4 as uuidv4 } from 'uuid';
import { isEnterpriseLead } from '../utils/navUtil';

/*
  Nav items here are available only for NON-enterprise users 
  based on specific display logic located inside of each object
*/
export const TEAMS_DEMO_NAV = {
  label: 'Teams Demo',
  icon: 'teams',
  href: '/enterprise/demo/organization/dashboard',
  display: isEnterpriseLead,
  startsWith: true,
  id: uuidv4(),
  children: [
    {
      label: 'Welcome',
      href: '/enterprise/demo/organization/welcome',
      display: () => false, // Disabled for now while we wait for new video
      id: uuidv4(),
    },
    {
      label: 'Dashboard',
      href: '/enterprise/demo/organization/dashboard',
      id: uuidv4(),
    },
    {
      label: 'People',
      href: '/enterprise/demo/organization/members',
      id: uuidv4(),
      children: [
        {
          label: 'Members',
          href: '/enterprise/demo/organization/members',
          id: uuidv4(),
        },
        {
          label: 'Groups',
          href: '/enterprise/demo/organization/groups',
          disabled: true,
          startsWith: true,
          id: uuidv4(),
        },
      ],
    },
    {
      label: 'Paths',
      href: '/enterprise/demo/paths',
      startsWith: true,
      id: uuidv4(),
      children: [
        {
          label: 'My Paths',
          href: '/enterprise/demo/paths',
          id: uuidv4(),
        },
        {
          label: 'Assessment Paths',
          href: '/enterprise/demo/paths/assessments',
          disabled: true,
          startsWith: true,
          id: uuidv4(),
        },
        {
          label: 'Assessment Path',
          href: '/enterprise/demo/paths/assessments/:id',
          display: () => false,
          startsWith: true,
          id: uuidv4(),
        },
        {
          label: 'Team Paths',
          href: '/enterprise/demo/paths/team',
          disabled: true,
          startsWith: true,
          id: uuidv4(),
        },
        {
          label: 'Cybrary Paths',
          href: '/enterprise/demo/paths/cybrary',
          startsWith: true,
          id: uuidv4(),
        },
      ],
    },
    {
      label: 'Goals',
      href: '/enterprise/demo/goals',
      id: uuidv4(),
      startsWith: true,
      children: [
        {
          label: 'Active',
          href: '/enterprise/demo/goals',
          id: uuidv4(),
        },
        {
          label: 'Upcoming',
          href: '/enterprise/demo/goals/upcoming',
          disabled: true,
          id: uuidv4(),
        },
        {
          label: 'History',
          href: '/enterprise/demo/goals/completed',
          disabled: true,
          id: uuidv4(),
        },
      ],
    },
    {
      label: 'Analytics',
      href: '/enterprise/demo/reporting',
      id: uuidv4(),
      startsWith: true,
      children: [
        {
          label: 'User Analytics',
          href: '/enterprise/demo/reporting',
          id: uuidv4(),
        },
        {
          label: 'Content Analytics',
          href: '/enterprise/demo/reporting/content',
          id: uuidv4(),
        },
        {
          label: 'Tools',
          href: '/enterprise/demo/reporting/tools',
          id: uuidv4(),
        },
      ],
    },
    {
      label: 'Settings',
      href: '/enterprise/demo/settings',
      id: uuidv4(),
    },
    {
      label: 'Audit Log',
      href: '/enterprise/demo/logs',
      id: uuidv4(),
    },
  ],
  group: 'main',
};
