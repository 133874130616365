import React, { useState, useEffect, useRef, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import queryString from 'query-string';
import { twMerge } from 'tailwind-merge';
import Slider from 'react-slick';
import GoalsUtil from '../../utils/goalsUtil';
import ContentOverlay from '../ContentOverlay/ContentOverlay';
import Title from '../Title/Title';
import GoalDetails from './GoalDetails';
import UserGoalsWizard from './UserGoalsWizard';
import Loading from '../Loading/Loading';
import If from '../If/If';
import GoalTile from './GoalTile';
import BasicTabs from '../Tabs/BasicTabs';
import TeamsNoGoalsBanner from './DashboardGoals/TeamsNoGoalsBanner';
import NoGoalsBanner from './DashboardGoals/NoGoalsBanner';
import Button from '../Button/Button';

function AddGoalButton({ toggleOverlay }) {
  return (
    <Button color="medium-gray" onClick={() => toggleOverlay('add')} className="z-10 py-2 px-4 lg:text-xs">
      + Add Goal
    </Button>
  );
}

const DashboardGoalsSlider = inject(
  'goalsStore',
  'userStore',
  'commonStore'
)(
  // eslint-disable-next-line no-unused-vars
  observer(({ goalsStore, userStore, noItemsJsx, view, toggleOverlay, continueLearning, getMenuOptions, loadingMessage = 'Loading goals...' }) => {
    const { trackingGoal, userGoalsList, userGoals } = goalsStore;
    const isLoading = userGoals?.loading || userGoalsList?.loading;
    const { userTeams, isEnterprise, goal: userGoal } = userStore;

    const goals = useMemo(() => GoalsUtil.getNonBaselineGoals(userGoals?.data), [userGoals?.data]);

    const inProgressGoals = goals?.filter((goal) => goal.active && !goal.completed_at);
    const completedGoals = goals?.filter((goal) => goal.completed_at);
    const expiredGoals = goals?.filter((goal) => !goal.active && !goal.completed_at);

    const goalsForView = useMemo(() => {
      if (view === 'in_progress') {
        return inProgressGoals;
      }
      if (view === 'completed') {
        return completedGoals;
      }
      if (view === 'expired') {
        return expiredGoals;
      }
      return goals;
    }, [view, inProgressGoals, completedGoals, expiredGoals, goals]);

    return (
      <div className="flex flex-col mx-auto mb-12 w-full min-h-[300px] lg:max-w-[675px]">
        {/** Loading State */}
        <If condition={isLoading}>
          <Loading message={loadingMessage} wrapperClassName="w-full h-4/5 py-12" />
        </If>
        <If condition={!isLoading && goalsForView?.length > 0}>
          <Slider
            infinite={false}
            dots={goalsForView?.length > 3}
            speed={500}
            slidesToShow={Math.min(3, goalsForView?.length)}
            slidesToScroll={Math.min(3, goalsForView?.length)}
            arrows={false}
            swipeToSlide={goalsForView?.length > 3}
            vertical
            verticalSwiping={goalsForView?.length > 3}
            className="pb-2.5 mt-2"
          >
            {goalsForView?.slice(0, 18).map((goal, index) => {
              const trackedGoal = trackingGoal?.id && trackingGoal.id === goal.id;
              const canTrackGoal = !trackedGoal && goal.status === 'in_progress';
              const menuOptions = getMenuOptions(goal, canTrackGoal);
              let menuClasses = '';
              // Move the last index in scroll view up to avoid getting cut off by the bottom of the screen
              // Include the last index in the calculation to account for partial slides
              if (index % 3 === 2 || index + 1 === goalsForView.length) {
                menuClasses = 'bottom-4';
              }

              // Do the same for the second index and the second to last index in the scroll view
              else if (index % 3 === 1 || index + 2 === goalsForView.length) {
                menuClasses = '-bottom-12';
              }
              return (
                <GoalTile
                  key={goal.id}
                  goal={goal}
                  teamData={goal.team_id ? userTeams[goal.team_id] : null}
                  menuOptions={menuOptions}
                  menuClasses={menuClasses}
                  view={view}
                  viewDetails={() => toggleOverlay('view', goal.id)}
                  continueLearning={continueLearning}
                  preventAnimation
                  buttonClassName="ml-1 md:ml-2"
                />
              );
            })}
          </Slider>
          {/**
           * Stick button in bottom right of slider to add a new goal
           */}
          <If condition={!isEnterprise}>
            <div className="absolute top-3 right-2 lg:top-auto lg:bottom-2">
              <AddGoalButton toggleOverlay={toggleOverlay} />
            </div>
          </If>
        </If>
        <If condition={!isLoading && (!goalsForView || goalsForView?.length === 0)}>
          {/* <div className="flex justify-center items-center text-center text-gray-500 text-md">{noItemsJsx}</div> */}
          <If condition={isEnterprise}>
            <TeamsNoGoalsBanner view={view} hasGoalsInProgress={!!goalsForView?.length} noItemsJsx={noItemsJsx} />
          </If>
          <If condition={!isEnterprise}>
            <NoGoalsBanner userGoal={userGoal} toggleOverlay={toggleOverlay} noItemsJsx={noItemsJsx} />
          </If>
        </If>
      </div>
    );
  })
);

const getTabs = ({ toggleOverlay, continueLearning, getMenuOptions }) => [
  {
    key: 'in_progress',
    id: 'in_progress',
    title: 'In Progress',
    content: (
      <DashboardGoalsSlider
        noItemsJsx="You have no in progress goals."
        loadingMessage="Loading in progress goals..."
        view="in_progress"
        toggleOverlay={toggleOverlay}
        continueLearning={continueLearning}
        getMenuOptions={getMenuOptions}
      />
    ),
  },
  {
    key: 'completed',
    id: 'completed',
    title: 'Completed',
    content: (
      <DashboardGoalsSlider
        noItemsJsx="You have no recently completed goals."
        loadingMessage="Loading completed goals..."
        view="completed"
        toggleOverlay={toggleOverlay}
        continueLearning={continueLearning}
        getMenuOptions={getMenuOptions}
      />
    ),
  },
  {
    key: 'expired',
    id: 'expired',
    title: 'Expired',
    content: (
      <DashboardGoalsSlider
        noItemsJsx="You have no expired goals."
        loadingMessage="Loading expired goals..."
        view="expired"
        toggleOverlay={toggleOverlay}
        continueLearning={continueLearning}
        getMenuOptions={getMenuOptions}
      />
    ),
  },
];

const DashboardGoals = inject(
  'goalsStore',
  'userStore',
  'commonStore'
)(
  observer(({ goalsStore, commonStore, hideIfNoGoals, navigate, className }) => {
    const [overlayOpen, setOverlayOpen] = useState(false);
    const [overlayAction, setOverlayAction] = useState(null);
    const [view, setView] = useState('in_progress');
    const [goalId, setGoalId] = useState(null);
    const [goalPreselection, setGoalPreselection] = useState(null);
    const goalsRef = useRef();
    // Grab queryParams off the window object every render, not from the hook.
    // This prevents an infinite loop due to cached queryParams and mutations directly to the window object
    const queryParams = queryString.parse(window.location.search);
    const { userGoalsList, userGoals, getUserGoals, hasTrackingGoal, lookForNewTrackingGoal, startTrackingGoalPolling, trackingGoalInit } = goalsStore;

    const goals = useMemo(() => GoalsUtil.getNonBaselineGoals(userGoals?.data), [userGoals?.data]);

    const containerClasses = useMemo(() => twMerge('mx-auto', className), [className]);

    const fetchUserGoals = () => {
      // const statusQueryParams = `?status=${view}`;
      getUserGoals().then(() => {
        // If we are looking at incomplete goals, and we aren't currently tracking a goal, but have goals, set one as default tracking
        // trackingGoalInit prevents this from conflicting with the search for a tracking goal that occurs in app load (if this page is refreshed)
        if (view === 'in_progress' && !hasTrackingGoal && trackingGoalInit && userGoals?.data?.length) {
          lookForNewTrackingGoal(userGoals.data);
          startTrackingGoalPolling();
        }
      });
    };

    const checkQueryParams = () => {
      // If we have a query param specifying a goal, open that goal details
      if (queryParams?.goal) {
        setOverlayOpen(true);
        setOverlayAction('view');
        setGoalId(queryParams.goal);
      } else if (queryParams?.['add-goal'] || queryParams?.action === 'add') {
        setOverlayOpen(true);
        setOverlayAction('add');
      }
    };

    const toggleOverlay = (action, overlayGoalId, isOpen, preselection) => {
      setOverlayAction(action);
      if (isOpen) {
        // isOpen is to manually open or close the overlay,
        setOverlayOpen(true);
      } else {
        // if not passed, toggle the overlay
        setOverlayOpen(!overlayOpen);
      }

      setGoalPreselection(preselection);
      setGoalId(overlayGoalId);
    };

    const deleteGoal = (itemGoalId) => {
      goalsStore
        .deleteGoal(itemGoalId)
        .then(() => {
          commonStore.triggerToast('success', { content: 'Goal deleted.' });
          fetchUserGoals();
        })
        .catch(() => {
          commonStore.triggerToast('error', { content: 'Something went wrong. Unable to delete this goal at this time.' });
        });
    };

    const deleteGoalConfirm = (itemGoalId) => {
      commonStore.triggerConfirm({
        content: 'Are you sure you want to delete this goal? This cannot be undone and all goal progress will be lost',
        cancel: () => commonStore.resetConfirmState(),
        confirmBtn: 'Continue',
        continue: () => {
          commonStore.resetConfirmState();
          deleteGoal(itemGoalId);
        },
      });
    };

    const getMenuOptions = (currentGoal, canTrackGoal) => {
      const isTeamGoal = currentGoal?.team_id;
      const canEditOrDelete = !isTeamGoal && view === 'in_progress';
      const { updateGoalTracking } = goalsStore;
      if (!currentGoal) {
        return [];
      }
      const options = [
        {
          text: 'View info',
          action: () => toggleOverlay('view', currentGoal.id),
        },
      ];
      if (canEditOrDelete) {
        options.push({
          text: 'Edit goal',
          action: () => toggleOverlay('edit', currentGoal.id),
        });
      }
      if (canTrackGoal) {
        options.push({
          text: 'Set as achievement tracker',
          action: () => updateGoalTracking(currentGoal),
        });
      }
      if (canEditOrDelete) {
        options.push({
          dividerLine: true,
        });
        options.push({
          text: 'Delete goal',
          action: () => deleteGoalConfirm(currentGoal.id),
        });
      }
      return options;
    };

    const cancelConfirm = () => {
      if (!goalsStore.addEditGoalPrestine) {
        commonStore.triggerConfirm({
          content: 'Are you sure you want to cancel? Your goal will not be saved',
          cancel: () => commonStore.resetConfirmState(),
          confirmBtn: 'Continue',
          continue: () => {
            // Clear query params to prevent auto opening overlay on page load
            navigate({
              search: '',
            });
            toggleOverlay();
            commonStore.resetConfirmState();
            goalsStore.resetAddEditGoalPrestine();
          },
        });
      } else {
        toggleOverlay();
        commonStore.resetConfirmState();
      }
    };

    const continueLearning = (currentGoal) => {
      GoalsUtil.continueLearning(currentGoal, goalsStore, navigate);
    };

    useEffect(() => {
      fetchUserGoals();

      if (queryParams?.goals && goalsRef?.current) {
        // Slight delay for rendering - Then scroll to section
        setTimeout(() => {
          goalsRef.current.scrollIntoView({
            behavior: 'smooth',
          });
        }, 700);
      }
      // reset the users goals to default on unmount
      return () => {
        goalsStore.setDefaultUserGoals();
      };
    }, []);

    useEffect(() => {
      checkQueryParams();
    }, [queryParams]);

    if (hideIfNoGoals) {
      // We fetch all goals on app init to show in goal widget

      if ((!goals?.length || userGoalsList?.error) && !userGoalsList?.loading) {
        return null;
      }
    }

    const tabs = useMemo(() => getTabs({ toggleOverlay, continueLearning, getMenuOptions }), [toggleOverlay, continueLearning, getMenuOptions]);

    return (
      <div className={containerClasses} ref={goalsRef}>
        <BasicTabs
          tabs={tabs}
          headerTitle="Goals"
          headerTitleLink="/goals"
          omitContainerBorder
          navContainerClassName="pt-1 lg:px-4 lg:pt-3 mb-1"
          headerTitleClassName="pl-2 lg:px-0"
          onChange={(index) => setView(tabs[index].id)}
        />
        <If condition={!userGoalsList?.loading}>
          <ContentOverlay
            omitPadding
            open={overlayOpen}
            dismiss={cancelConfirm}
            width={overlayAction === 'add' ? 'w-full sm:w-[90vw] lg:w-[70vw]' : 'w-full sm:w-[90vw] lg:w-[40vw]'}
            maxWidth={overlayAction !== 'add' ? 'max-w-xl' : 'max-w-5xl'}
            ariaLabelledBy="dashboard-goal-wizard"
          >
            <If condition={overlayAction === 'add'}>
              <div className="py-1 px-2 lg:py-4 lg:px-8">
                <Title title="Create Goal" titleId="create-goal" omitPadding wrapperClasses="mb-2 lg:mb-4" />
                <p className="hidden mb-9 text-sm text-gray-600 md:block">
                  Follow the guide below to set your professional development goal and we&apos;ll get you started in the right direction.
                </p>
                <UserGoalsWizard
                  goalPreselection={goalPreselection}
                  onSubmit={() => {
                    toggleOverlay();
                    fetchUserGoals();
                  }}
                />
              </div>
            </If>
            <If condition={overlayAction !== 'add'}>
              <GoalDetails
                goalId={goalId}
                continueLearning={continueLearning}
                editView={overlayAction === 'edit'}
                editCancel={cancelConfirm}
                onEdit={() => {
                  toggleOverlay('view', goalId, true);
                  fetchUserGoals();
                }}
              />
            </If>
          </ContentOverlay>
        </If>
      </div>
    );
  })
);

export default DashboardGoals;
