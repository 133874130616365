import React, { useRef, useState } from 'react';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, EmailShareButton } from 'react-share';
import { twMerge } from 'tailwind-merge';
import Facebook from '../../Icons/Facebook';
import Twitter from '../../Icons/Twitter';
import Linkedin from '../../Icons/Linkedin';
import Email from '../../Icons/Email';
import FormatUtil from '../../utils/formatUtil';
import GaUtil from '../../utils/gaUtil';
import Button from '../../components/Button/Button';

function ShareCertificate({ omitSocial, certificate, downloadUrl }) {
  const downloadUrlToShare = downloadUrl ?? FormatUtil.formatCertificateDownloadUrl(certificate.certificate_id);
  const socialMediaText = `Check out my certificate of completion for ${certificate.title} from Cybrary.
Sign up for FREE and access this and tons of other cybersecurity and I.T. career resources!`;
  const [isCopied, setIsCopied] = useState(false); // flag for if the invite link has been copied
  const inputRef = useRef(null);

  const gaEvent = () =>
    new Promise((resolve) => {
      GaUtil.fireEvent(`Share ${certificate.title}`, 'Product Interaction', certificate.title);
      resolve();
    });

  const copyToClipboard = (e) => {
    e.preventDefault();

    inputRef.current.focus();
    inputRef.current.select();

    document.execCommand('copy');

    // set copied state to true for 5 seconds
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 5000);
  };
  return (
    <div>
      <h3 className="pt-5 pb-6 text-xl font-black text-center">Share</h3>
      {!omitSocial ? (
        <div className="m-auto w-full lg:w-1/2">
          <div className="flex justify-around">
            <FacebookShareButton url={downloadUrlToShare} quote={socialMediaText} beforeOnClick={gaEvent} className="cursor-pointer">
              <Facebook classes="w-10 h-10 text-white" withRectangle />
            </FacebookShareButton>
            <TwitterShareButton url={downloadUrlToShare} title={socialMediaText} beforeOnClick={gaEvent} className="cursor-pointer">
              <Twitter classes="w-10 h-10 text-white" withRectangle />
            </TwitterShareButton>
            <LinkedinShareButton url={downloadUrlToShare} beforeOnClick={gaEvent} className="cursor-pointer">
              <Linkedin classes="w-10 h-10 text-white" withRectangle />
            </LinkedinShareButton>
            <EmailShareButton url={downloadUrlToShare} body={socialMediaText} beforeOnClick={gaEvent} subject="Recommendation for you on Cybrary" className="cursor-pointer">
              <Email classes="w-10 h-10 text-white" withRectangle />
            </EmailShareButton>
          </div>
        </div>
      ) : null}

      <div className="flex mt-5 mb-3 md:mb-10">
        <input
          id="copyToClipboard"
          aria-label="certificate URL"
          ref={inputRef}
          type="text"
          defaultValue={downloadUrlToShare}
          style={{ borderRadius: '5px' }}
          className={twMerge('border-2 p-3 mr-3 w-full border-gray-400 focus:outline-none', isCopied ? 'border-cyb-pink-500 ' : '')}
        />
        <Button onClick={copyToClipboard} className="whitespace-nowrap" color="gray">
          {isCopied ? 'Copied!' : 'Copy link'}
        </Button>
      </div>
    </div>
  );
}

export default ShareCertificate;
