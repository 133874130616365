import React, { useState, useEffect } from 'react';
import { Slide } from 'react-awesome-reveal';
import AddLink from '../../AddLink/AddLink';
import Chevron from '../Icons/Chevron';
import ProfileMenu from '../ProfileMenu';
import UserIcon from '../../../Icons/UserIcon';
import TeamsDropdown from '../TeamsDropdown';
import Footer from './Footer';
import ExploreMenu from '../ExploreMenu';
import Icon from '../../Icon/Icon';

function shouldDisplay(item, userStore) {
  const { display } = item;
  if (display && !display(userStore)) {
    return false;
  }
  return true;
}
function provideHref(item, userStore) {
  const { href, hrefReplace } = item;
  if (!hrefReplace) {
    return href;
  }
  return hrefReplace(href, userStore);
}

function MenuItems({ item, activeSidebar, toggleDropDown, toggleMobileSidebar, activeDropdown, userStore }) {
  const isActiveMenuItem = activeDropdown === item.id;
  const activeItemClass = isActiveMenuItem ? 'text-black font-semibold' : 'text-gray-600';
  const activeChevron = isActiveMenuItem ? 'rotate-180' : '';
  const activeDropDownMenu = isActiveMenuItem ? 'block lg:hidden' : 'hidden';

  if (!shouldDisplay(item, userStore) || (activeSidebar.icon === 'teams' && (item.teams || item.divider))) {
    return null;
  }
  const actualHref = provideHref(item, userStore);

  if (item.children) {
    return (
      <li>
        <button aria-label="Toggle In Progress Dropdown" className="group flex items-center py-5 px-6 cursor-pointer" onClick={() => toggleDropDown(item.id)}>
          <p className={`text-lg group-hover:text-black group-hover:font-semibold ${activeItemClass} mb-0`}>{item.label}</p>
          <Chevron direction="down" classes={`h-6 w-6 ml-auto transform group-hover:text-black group-hover:font-semibold ${activeItemClass} ${activeChevron}`} />
        </button>
        <ul className={`pl-5 ${activeDropDownMenu}`}>
          {item.children.map((subItem) => {
            if (!subItem.label || !shouldDisplay(subItem, userStore)) {
              return null;
            }
            const subItemHref = provideHref(subItem, userStore);
            return (
              <li key={subItem.label}>
                <AddLink onClick={() => toggleMobileSidebar(false)} to={subItemHref} className="flex items-center py-5 pr-4 pl-6 cursor-pointer">
                  <p className="w-full text-lg text-gray-600 hover:text-black">{subItem.label}</p>
                </AddLink>
              </li>
            );
          })}
        </ul>
      </li>
    );
  }

  return (
    <li>
      <AddLink onClick={() => toggleMobileSidebar(false)} to={actualHref} className="group flex items-center py-4 px-6 cursor-pointer">
        <p className="text-lg text-gray-600 group-hover:text-black">{item.label}</p>
      </AddLink>
    </li>
  );
}

function SecondarySidebar({ activeSidebar, setActiveSidebar, toggleMobileSidebar, isDefaultUserAvatar, userStore, commonStore }) {
  const [activeDropdown, setActiveDropDown] = useState(null);
  const activeSidebarProfile = activeSidebar === 'profile';

  // Check if this secondary nav has any children that should be open by default. If so, set as activeDropdown (picks first found)
  useEffect(() => {
    const defaultActiveChild = activeSidebar && activeSidebar.children && activeSidebar.children.length && activeSidebar.children.filter((child) => child.defaultMobileOpen);
    const defaultActiveChildId = defaultActiveChild && defaultActiveChild[0] && defaultActiveChild[0].id;
    if (defaultActiveChildId) {
      setActiveDropDown(defaultActiveChildId);
    }
  }, [activeSidebar]);

  function toggleDropDown(id) {
    if (activeDropdown === id) {
      setActiveDropDown(null);
      return;
    }
    setActiveDropDown(id);
  }

  /* second side nav */
  if (activeSidebar && !activeSidebarProfile) {
    return (
      <>
        <div className="flex items-center py-7 px-6 mx-auto mb-6 bg-gray-200" style={{ height: '4.5rem' }}>
          <button type="button" className="focus:outline-none" onClick={() => setActiveSidebar(null)}>
            <Chevron direction="left" classes="h-5 w-5 mr-4 text-black" />
          </button>
          <div className="flex items-center w-full">
            <Icon name={activeSidebar.icon} id="-secondary-nav" className="mr-3 w-8 h-8 text-gray-500" active />
            <p className="text-lg font-semibold text-black">{activeSidebar.label}</p>
          </div>
        </div>
        <Slide direction="right" duration={300} triggerOnce>
          {activeSidebar.label === 'Teams' ? (
            <div className="mb-4">
              <TeamsDropdown userStore={userStore} mobileNav />
            </div>
          ) : null}
          {activeSidebar.label === 'Explore' && activeSidebar.dropdown && (
            <>
              <ExploreMenu onClick={() => toggleMobileSidebar(false)} mobile />
              <div className="flex absolute bottom-0 justify-between items-center py-2 pr-4 pl-6 w-full bg-white border border-t border-gray-300">
                <AddLink
                  to="/browse/refined"
                  onClick={() => toggleMobileSidebar(false)}
                  className="flex justify-center items-center py-3 px-6 w-full text-sm font-bold text-black hover:text-black bg-gray-200 hover:bg-cyb-gray-500 rounded-sm"
                >
                  Explore All Content
                </AddLink>
              </div>
            </>
          )}
          {activeSidebar.label !== 'Explore' && !!activeSidebar.children && (
            <ul>
              {activeSidebar.children.map((item) => {
                return (
                  <MenuItems
                    key={item.id}
                    item={item}
                    activeSidebar={activeSidebar}
                    // eslint-disable-next-line react/jsx-no-bind
                    toggleDropDown={toggleDropDown}
                    toggleMobileSidebar={toggleMobileSidebar}
                    activeDropdown={activeDropdown}
                    userStore={userStore}
                  />
                );
              })}
            </ul>
          )}
        </Slide>
      </>
    );
  }

  /*  profile side nav */
  if (activeSidebarProfile) {
    return (
      <>
        <div className="flex items-center py-5 px-6 mx-auto mb-6 bg-gray-200">
          <button type="button" className="focus:outline-none" onClick={() => setActiveSidebar('')}>
            <Chevron direction="left" classes="h-6 w-6 mr-4 text-black" />
          </button>
          <div className="flex items-center w-full">
            {isDefaultUserAvatar ? (
              <UserIcon classes="h-8 w-8 mr-3" active={activeSidebarProfile} id="-mobile-secondary" />
            ) : (
              <div className="mr-3 w-8 h-8 cursor-pointer">
                <img src={userStore.avatarUrl} className="w-full rounded-full" alt="Avatar" />
              </div>
            )}
            <p className="text-lg font-semibold text-black">{userStore.user.real_name || userStore.user.name}</p>
          </div>
        </div>
        <ProfileMenu userStore={userStore} onClick={() => toggleMobileSidebar(false)} mobile />
        <Footer commonStore={commonStore} userStore={userStore} />
      </>
    );
  }
  return null;
}

export default SecondarySidebar;
