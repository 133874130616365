import React from 'react';
import Modal from '../Modal/modal';

export default function ExitModal({ open, close, handleExit, simple = false }) {
  const title = simple ? 'Exit lab?' : 'Exit assessment?';
  const body = simple
    ? 'If you exit the lab, your lab session will end and you will lose any progress.'
    : 'If you exit the assessment, your lab session will end and you will lose any assessment results.';
  return (
    <Modal open={open} onClose={close} className="max-w-sm">
      <h3 className="mb-8 text-2xl font-bold text-gray-200">{title}</h3>
      <p className="mb-6 text-gray-200">{body}</p>
      <div className="flex justify-between">
        <button className="px-8 h-10 text-xs font-semibold bg-white rounded" type="button" onClick={close}>
          Nevermind
        </button>
        <button className="px-8 h-10 text-xs font-semibold text-white bg-pink-700 rounded" type="button" onClick={handleExit}>
          Continue
        </button>
      </div>
    </Modal>
  );
}
