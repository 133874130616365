import Bugsnag from '@bugsnag/js';
import moment from 'moment';
import { observable, makeObservable, computed, action } from 'mobx';
import { isEmpty, has } from 'lodash';
import Agents from '../agents/agents';
import AuthUtil from '../utils/authUtil';

const initialProfileSections = {
  personal: null,
  work: null,
  education: null,
  skills: null,
  certifications: null,
};

class ProfileStore {
  constructor() {
    makeObservable(this, {
      profileData: observable,
      setDefaultProfileData: action,
      setProfileData: action,
      setProfileDataLoading: action,
      setProfileDataError: action,
      setProfileSubmitError: action,
      newEmailInput: observable,
      setNewEmailInput: action,
      currentEmail: observable,
      setUserCurrentEmail: action,
      putProfileEmail: action,
      putPasswordReset: action,
      reset: action,
      addFieldsForCheckboxes: action,
      formatDate: action,
      settingsTabs: observable,
      getSubscriptions: action,
      initiateDataExport: action,
      completeDataExport: action,
      deleteAccount: action,
      profile: observable,
      loading: observable,
      error: observable,
      progress: observable,
      getAllProfileData: action,
      updateProfile: action,
      setProgress: action,
      setIsLoading: action,
      clearErrors: action,
      sortProfile: action,
      updateErrors: action,
      progressPercentage: computed,
      sectionLoading: observable,
      setIsSectionLoading: action,
      createTerm: action,
      updateAvatar: action,
      isEduGovMilUser: computed,
      isPublicEmailOnlyUser: computed,
      totalVerifiedEmailAccounts: computed,
    });
  }

  profileData = {
    settings: {
      loading: true,
      error: false,
      submitError: false,
      data: {},
    },
    email: {
      loading: true,
      error: false,
      submitError: false,
      data: {},
    },
    password: {
      loading: true,
      error: false,
      submitError: false,
      data: {},
    },
    membership: {
      loading: true,
      error: false,
      submitError: false,
      data: {},
    },
    accounts: {
      loading: true,
      error: false,
      submitError: false,
      data: {},
    },
  };

  get isEduGovMilUser() {
    if (!this?.profileData?.accounts?.data) {
      return false;
    }
    return AuthUtil.isEduGovMilUser(this.profileData.accounts.data);
  }

  get isPublicEmailOnlyUser() {
    if (!this?.profileData?.accounts?.data) {
      return false;
    }
    return AuthUtil.isPublicEmailOnlyUser(this.profileData.accounts.data);
  }

  get totalVerifiedEmailAccounts() {
    if (!this?.profileData?.accounts?.data) {
      return 0;
    }
    const otherAccounts = this.profileData.accounts.data.other || {};
    const verifiedOtherAccounts = Object.values(otherAccounts).filter((account) => account.verified);
    return verifiedOtherAccounts.length + 1; // Add 1 for the primary account
  }

  setDefaultProfileData(section) {
    this.profileData[section] = {
      loading: true,
      error: false,
      submitError: false,
      data: {},
    };
  }

  setProfileData(section, data) {
    this.profileData[section].data = data && data.data ? data.data : data;
  }

  setProfileDataLoading(section, loading) {
    this.profileData[section].loading = loading;
  }

  setProfileDataError(section, error) {
    this.profileData[section].error = error;
  }

  setProfileSubmitError(section, error) {
    this.profileData[section].submitError = error;
  }

  getProfileData(section) {
    this.setProfileDataLoading(section, true);
    return Agents.profile
      .getProfileData(section)
      .then(
        action('fetchSuccess', (response) => {
          this.setProfileData(section, response);
          this.setProfileDataLoading(section, false);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.profileData[section].error = error.response || error;
          this.setProfileDataLoading(section, false);
        })
      );
  }

  getUserAccounts() {
    this.setProfileDataLoading('accounts', true);
    return Agents.profile
      .getUserAccounts()
      .then(
        action('fetchSuccess', (response) => {
          this.setProfileData('accounts', response);
          this.setProfileDataLoading('accounts', false);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.profileData.accounts.error = error.response || error;
          this.setProfileDataLoading('accounts', false);
        })
      );
  }

  putProfileData(section, data) {
    return Agents.profile.putProfileData(section, data);
  }

  postProfileData(section, data) {
    return Agents.profile.postProfileData(section, data);
  }

  deleteBackupEmail(data) {
    return Agents.profile.deleteBackupEmail(data);
  }

  resetPassword(data) {
    return Agents.auth.resetPassword(data);
  }

  changePrimaryEmail(data) {
    return Agents.profile.changePrimaryEmail(data);
  }

  newEmailInput = '';

  setNewEmailInput = (email) => {
    this.newEmailInput = email;
  };

  currentEmail = '';

  setUserCurrentEmail(email) {
    this.currentEmail = email;
  }

  putProfileEmail(data) {
    return Agents.profile
      .putProfileEmail(data)
      .then(
        action('submitSuccess', () => {
          this.setUserCurrentEmail(data.email);
        })
      )
      .catch(
        action('submitError', (error) => {
          Bugsnag.notify(error);
          this.setProfileSubmitError('email', error);
        })
      );
  }

  putPasswordReset(data) {
    return Agents.auth.putPassword(data);
  }

  reset() {
    this.currentEmail = '';
  }

  addFieldsForCheckboxes(obj) {
    obj.tableHeadings.unshift({ key: 'id', display: 'ID', showCol: false });
    for (let i = 0; i < obj.tableData.length; i++) {
      obj.tableData[i].unshift({ type: 'int', value: i + 1 });
    }
  }

  formatDate(date) {
    if (!date || !date.length || date === '') return null;
    return moment(new Date(date)).format('YYYY-MM-DD HH:mm:ss');
  }

  setActiveTab(label) {
    const tabs = this.settingsTabs;
    const tab = tabs.find((object) => object.label === label);
    tab.active = true;
  }

  setInactiveTab(label) {
    const tabs = this.settingsTabs;
    const tab = tabs.find((object) => object.label === label);
    tab.active = false;
  }

  settingsTabs = [
    {
      label: 'Account',
      url: '/settings/account',
    },
    {
      label: 'Membership',
      url: '/settings/membership',
    },
  ];

  getSubscriptions() {
    return Agents.auth.getSubscriptions();
  }

  initiateDataExport() {
    return Agents.auth.initiateDataExport();
  }

  completeDataExport(urlId) {
    return Agents.auth.completeDataExport(urlId);
  }

  deleteAccount() {
    return Agents.auth.deleteAccount();
  }

  profile = initialProfileSections;

  loading = true;

  sectionLoading = initialProfileSections;

  error = null;

  progress = {
    order: ['personal', 'certifications', 'skills', 'work', 'education'],
    state: {
      personal: { complete: false, name: 'Personal' },
      work: { complete: false, name: 'Work Experience' },
      education: { complete: false, name: 'Education' },
      skills: { complete: false, name: 'Skills' },
      certifications: { complete: false, name: 'Certifications' },
    },
  };

  getProfileSections = () => {
    return initialProfileSections;
  };

  getAllProfileData = async () => {
    try {
      const profile = await Agents.profile.getProfile();
      this.profile = profile.data;
      this.profile.personal.email = this.profile.email;
      this.sortProfile();
    } catch (error) {
      this.error = error;
      this.updateErrors(error);
    }
    this.setIsLoading(false);
  };

  /**
   * Update profile
   *
   * @param {string} section
   * @param {object} data
   */
  updateProfile = async (section, data) => {
    const requestData = data[section] ? data : { [section]: data };
    try {
      const updatedData = await Agents.profile.updateProfile(requestData);
      if (updatedData) {
        this.profile[section] = requestData[section];
        this.sortProfile();
        this.setProgress();
      }
      this.setIsSectionLoading(section, false);
    } catch (error) {
      this.updateErrors(error);
      this.setIsSectionLoading(section, false);
    }
  };

  updateAvatar = (avatarUrl) => {
    this.profile.personal.avatar_url = avatarUrl;
  };

  updateErrors = (error) => {
    if (has(error, 'response.data.errors')) {
      this.profile.errors = error.response.data.errors;
    }
    throw error;
  };

  /**
   *  Set profile progress based on completion
   */
  setProgress = () => {
    this.progress.order.forEach((section) => {
      this.progress.state[section].complete = !isEmpty(this.profile[section]);
    });
  };

  /**
   * Get progress percentage (computed)
   */
  get progressPercentage() {
    const total = this.progress.order.length;
    let completedCount = 0;
    let upNext = '';

    for (let i = 0; i < this.progress.order.length; i++) {
      const progressId = this.progress.order[i];
      const step = this.progress.state[progressId];
      if (step.complete) {
        completedCount++;
      } else if (!step.complete && !upNext) {
        upNext = {
          id: progressId,
          data: step,
        };
      }
    }
    return { upNext, percent: ((completedCount / total) * 100).toFixed(0) };
  }

  setIsLoading = (status) => {
    this.loading = status;
  };

  setIsSectionLoading = (section, isLoading) => {
    this.sectionLoading[section] = isLoading;
  };

  clearErrors = (errorKey) => {
    if (this.profile.errors) {
      this.profile.errors[errorKey] = null;
    }
  };

  sortProfile = () => {
    // sort a section of arrays
    if (!this.profile) {
      return;
    }

    if (this.profile.work) {
      this.profile.work = this.profile.work.slice().sort((a, b) => (b.endDate ? new Date(b.endDate) : new Date()) - (a.endDate ? new Date(a.endDate) : new Date()));
    }

    if (this.profile.education) {
      this.profile.education = this.profile.education.slice().sort((a, b) => b.gradYear - a.gradYear);
    }

    if (this.profile.certifications) {
      this.profile.certifications = this.profile.certifications.slice().sort((a, b) => b.comapny - a.company);
    }
  };

  createTerm = (endpoint, input) => {
    const data = {
      terms: [
        {
          group: 'Custom',
          term: input,
        },
      ],
    };
    return Agents.profile.createTerm(endpoint, data);
  };
}

export default new ProfileStore();
