import React from 'react';
import Label from '../Label/Label';
import Icon from '../Icon/Icon';
import PercentageBar from '../Analytics/PercentageBar';

function ProgressLabel({ progressNum, completionDate, showProgressBar, progressBarHeight, progressBarSuffix }) {
  const progressNumInt = progressNum * 1;
  let returnLabel = `${progressNumInt}%`;
  let labelColor = 'yellow';
  let icon = null;

  if (showProgressBar && progressNumInt !== 100) {
    return (
      <div>
        <div className={`bg-gray-300 w-24 mx-auto overflow-hidden text-left rounded-lg inline-block align-middle ${progressBarHeight || 'h-2'}`}>
          <PercentageBar color="#df057b" maxHeight="0.5rem" width={`${progressNumInt}%`} />
        </div>
        <span className="ml-2 text-xs">
          {progressNumInt || 0}
          {progressBarSuffix || '%'}
        </span>
      </div>
    );
  }

  if (progressNumInt === 0 || progressNumInt === null) {
    if (completionDate) {
      returnLabel = 'STARTED';
    } else {
      return '-';
    }
  } else if (progressNumInt >= 100 || !!completionDate) {
    labelColor = 'green';
    returnLabel = 'COMPLETED';
    icon = 'check';
  }

  return (
    <>
      <span className="hidden print:block">{progressNumInt}%</span>
      <Label color={labelColor} className="print:hidden progress-label">
        {icon && <Icon name={icon} className="inline-block mr-1 w-4 h-4 text-white" />} {returnLabel}
      </Label>
    </>
  );
}

export default ProgressLabel;
