import { useMemo, useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import Bugsnag from '@bugsnag/js';
import Agents from '../agents/agents';

function getCertContent(certData, progressData = []) {
  const content = {
    courses: [],
    labBundle: [],
    other: [],
    practiceExam: null,
  };
  if (certData && certData.content_item && certData.content_item.children && certData.content_item.children.length) {
    // Create a progress map for easy reference
    const childrenProgress = {};
    progressData.forEach((child) => {
      childrenProgress[child.content_description_id] = child;
    });
    certData.content_item.children.forEach((item) => {
      if (item.status === 'Active') {
        const itemType = item.content_type.nice_name;
        const itemData = { ...childrenProgress[item.id], ...item };
        switch (itemType) {
          case 'Course':
            content.courses.push(itemData);
            break;
          case 'CyberVista Practice Test':
            content.practiceExam = itemData;
            break;
          case 'Practice Labs Lab':
            content.labBundle.push(itemData);
            break;
          default:
            content.other.push(itemData);
        }
      }
    });
  }
  return content;
}

const getPrepSteps = (certContentMap = {}, certData = {}, practiceExamScores = []) => {
  const hasTakenPretest = !!practiceExamScores && !!practiceExamScores.length;
  const unstartedCourses = [];
  const incompleteCourses = [];
  const completeCourses = [];
  certContentMap.courses.forEach((item) => {
    if (!item.progress || 1 * item.progress === 0) {
      unstartedCourses.push(item);
    } else if (!item.completed_at) {
      incompleteCourses.push(item);
    } else if (item.completed_at) {
      completeCourses.push(item);
    }
  });
  const hasStartedLearning = !!incompleteCourses.length || !!completeCourses.length;
  // Get the course completion date. If there's more than one, than get the last course's completion date
  let coursesCompletionDate = '';
  completeCourses.forEach((course) => {
    if (!coursesCompletionDate || moment(coursesCompletionDate).isBefore(course.completed_at)) {
      coursesCompletionDate = course.completed_at;
    }
  });

  // Get exam attempts after course completion
  const examAttemptsAfterCourse = practiceExamScores.filter((attempt) => moment(attempt.created_at).isAfter(coursesCompletionDate));
  // Get passing exam attempts after course completion
  const passingPracticeExams = examAttemptsAfterCourse.filter((attempt) => attempt.score >= 85);
  const examLink = certData.content_item && certData.content_item.meta && certData.content_item.meta.examLink ? certData.content_item.meta.examLink : null;

  const steps = {
    1: {
      ctaText: 'Take Quick Quiz',
      contentData: certContentMap.practiceExam,
      active: false,
    },
    2: {
      ctaText: 'Start Learning',
      active: false,
    },
    3: {
      ctaText: 'Take Practice Exam',
      contentData: certContentMap.practiceExam,
      active: false,
    },
    4: {
      ctaText: 'Visit Official Exam Page',
      href: examLink,
    },
  };

  // STEP 1 - User has not taken practice test
  if (!hasTakenPretest) {
    steps[1].active = true;
    return steps;
  }

  // STEP 2 - User has taken the practice test (step 1), but hasn't started the courses
  if (!hasStartedLearning) {
    steps[2].active = true;
    const [unstartedCourse] = unstartedCourses;
    steps[2].contentData = unstartedCourse;
    return steps;
  }
  // STEP 2B - User has started a course but not completed it yet
  if (incompleteCourses.length) {
    steps[2].active = true;
    steps[2].ctaText = 'Continue Learning';
    const [incompleteCourse] = incompleteCourses;
    steps[2].contentData = incompleteCourse;
    return steps;
  }

  // STEP 3 - User has completed the course (step 2 & 2B), but not yet passed an exam after course completion
  if (!passingPracticeExams.length) {
    steps[3].active = true;
    return steps;
  }

  // STEP 4 - User has completed the practice exam AFTER previously completing quick quiz (would've defaulted to step 1) and after completing the course
  if (passingPracticeExams.length) {
    steps[4].active = true;
    return steps;
  }

  // If still here, which shouldn't happen, default to step 1
  steps[1].active = true;
  return steps;
};

export default function useCertificationProgress({ certificationItem, enabled = true }) {
  const [certContentProgress, setCertContentProgress] = useState([]);
  const [practiceExamScores, setPracticeExamScores] = useState([]);
  const [certContentMap, setCertContentMap] = useState({});
  const [prepSteps, setPrepSteps] = useState({});
  const [loading, setLoading] = useState(enabled);
  const [error, setError] = useState(null);

  const fetchCertificationProgress = useCallback(async () => {
    try {
      setLoading(true);

      // Get progress for this certification's children
      const certificationProgress = await Agents.enrollments.getChildProgress(certificationItem?.content_item?.content_description_id, '?unenrolledProgress=1');

      const contentMap = getCertContent(certificationItem, certificationProgress);

      // Get scores for the practice exam set for this certification
      let scores = [];
      if (contentMap.practiceExam && contentMap.practiceExam.content_description_id) {
        scores = await Agents.enrollments.getAllScores(contentMap.practiceExam.content_description_id, '?sortCol=created_at&sortDirection=desc');
      }

      setCertContentProgress(certificationProgress);
      setCertContentMap(contentMap);
      setPracticeExamScores(scores);
      setPrepSteps(getPrepSteps(contentMap, certificationItem, scores));
    } catch (e) {
      Bugsnag.notify(e);
      setError(e);
    } finally {
      setLoading(false);
    }
  }, [certificationItem]);

  useEffect(() => {
    if (enabled && certificationItem) {
      fetchCertificationProgress();
    }
  }, [enabled, certificationItem]);

  return useMemo(
    () => ({
      certificationItem,
      certContentProgress,
      practiceExamScores,
      certContentMap,
      prepSteps,
      loading,
      error,
    }),
    [certificationItem, certContentProgress, practiceExamScores, certContentMap, prepSteps, loading, error]
  );
}
