import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Icon from '../Icon/Icon';
import DynamicForm from '../DynamicForm/DynamicForm';
import Loading from '../Loading/Loading';
import StyledError from '../Error/StyledError';
import FormatUtil from '../../utils/formatUtil';
import LabBasedReviewForm from '../../forms/reviews/LabBasedReviewForm';
import AssessmentBasedReviewForm from '../../forms/reviews/AssessmentBasedReviewForm';
import AudioVideoBasedReviewForm from '../../forms/reviews/AudioVideoBasedReviewForm';
import Modal from '../Modal/Modal';
import Container from '../Container/Container';
import Segment from '../Segment/Segment';
import Header from '../Header/Header';
import CourseBasedReviewForm from '../../forms/reviews/CourseBasedReviewForm';
import CollectionBasedReviewForm from '../../forms/reviews/CollectionBasedReviewForm';

/**
 * Thank you message component
 */
function ThankYouMessage() {
  return (
    <Segment className="flex flex-col justify-center">
      <div className="mx-4 mt-4 text-center">
        <Icon name="thumbs-up" className="mx-auto w-16 h-16 text-green-600" />
      </div>
      <div className="mx-4 mb-4 text-center">
        <Header as="h3" className="m-4 text-xl font-bold">
          Thank you! We appreciate your feedback.
        </Header>
      </div>
    </Segment>
  );
}

/**
 * Reviews modal for live session
 * @TODO make universal
 */
const FeedbackModal = inject('feedbackStore')(
  observer(
    class FeedbackModal extends Component {
      state = {
        contentDescriptionId: null,
      };

      componentDidMount() {
        this.checkGetFeedback();
      }

      componentDidUpdate() {
        this.checkGetFeedback();
      }

      checkGetFeedback = () => {
        if (this.props.feedbackStore.contentDescriptionId && this.state.contentDescriptionId !== this.props.feedbackStore.contentDescriptionId) {
          this.setState(
            {
              contentDescriptionId: this.props.feedbackStore.contentDescriptionId,
            },
            () => this.props.feedbackStore.getFeedback(this.props.feedbackStore.contentDescriptionId)
          );
        }
      };

      /**
       * update form with user's new answers
       */
      updateForm = () => {
        const { originalReview, review, originalRatings, ratings } = this.props.feedbackStore;

        // check for updates to ratings
        const formattedOriginalRatings = FormatUtil.getKeyFromArray(originalRatings, 'rating');
        const formattedNewRatings = FormatUtil.getKeyFromArray(ratings, 'rating');
        const areArraysSame = FormatUtil.areArraysSame(formattedOriginalRatings, formattedNewRatings);

        // check for update to review
        const areStringsSame = !!originalReview && !!originalReview.review ? originalReview.review === review : true;

        // if there have been changes, set original values to the new values
        if (!areArraysSame) {
          this.props.feedbackStore.setOriginalRatings(ratings);
        }
        if (!areStringsSame) {
          this.props.feedbackStore.setOriginalReview({ review });
        }

        // fetch the feedback again
        this.props.feedbackStore.getFeedback(this.props.feedbackStore.contentDescriptionId);
      };

      /**
       * Display thank you message for 3 seconds
       */
      triggerSuccess = () => {
        this.props.feedbackStore.displayThankYouMessage(true);
        setTimeout(() => {
          this.props.feedbackStore.closeFeedbackModal();
          this.updateForm();
          this.props.feedbackStore.reset();
        }, 3000);
      };

      /**
       * Display error component for 3 seconds
       */
      triggerError = () => {
        this.props.feedbackStore.displayError(true);
        setTimeout(() => {
          this.props.feedbackStore.closeFeedbackModal();
          this.props.feedbackStore.reset();
        }, 3000);
      };

      /**
       *  Submit logic for the feedback form.
       */
      submitFeedbackForm = (data) => {
        // transform the form data into an object the back is expecting
        const submitData = {};
        const transformRatings = [];
        Object.keys(data).forEach((key) => {
          if (key === 'review') {
            submitData.review = data[key];
          } else {
            transformRatings.push({ question_type: key, rating: data[key] });
          }
        });
        submitData.ratings = transformRatings;
        this.props.feedbackStore.setReview({ review: submitData.review });
        this.props.feedbackStore.setRatings(submitData.ratings);
        this.props.feedbackStore.updateReviewsAndRatings(submitData).then(() => {
          const { reviewsAndRatingsError } = this.props.feedbackStore;
          if (reviewsAndRatingsError) {
            this.triggerError();
          } else {
            this.triggerSuccess();
          }
        });
      };

      /**
       *  Clear out a server error for a given field
       */
      clearErrorField = (key) => {
        this.props.feedbackStore.clearFeedbackErrorsForKey(key);
      };

      /**
       * get the default value of the form field
       */
      getDefaultValue = (question_type) => {
        const { ratings } = this.props.feedbackStore;
        let foundItem = {};
        ratings.forEach((item) => {
          if (item.question_type === question_type) {
            foundItem = item;
          }
          return null;
        });
        return foundItem.rating;
      };

      getFormDisplay = () => {
        const { review, ratings, isThankYouMessageDisplayed, isErrorDisplayed, reviewsAndRatingsError, reviewsAndRatingsLoading, feedbackForm } = this.props.feedbackStore;
        let form = null;
        switch (feedbackForm) {
          case 'course':
            // Cybrary Live Sessions, Courses, MicroCourses
            form = CourseBasedReviewForm;
            break;

          case 'assessment':
            form = AssessmentBasedReviewForm;
            break;

          case 'lab':
            form = LabBasedReviewForm;
            break;

          case 'collection':
            form = CollectionBasedReviewForm;
            break;

          case 'video':
          default:
            // default to audio video review form
            form = AudioVideoBasedReviewForm;
            break;
        }

        if (form && form.fields && form.fields.cancelBtn) {
          form.fields.cancelBtn.onClick = () => this.props.feedbackStore.closeFeedbackModal();
        }

        // null state of form on render
        Object.keys(form.fields).forEach((field) => {
          if (field !== 'submit') {
            form.fields[field].defaultValue = '';
          }
        });
        // format default values on render
        if (!!ratings && ratings.length) {
          Object.keys(form.fields).forEach((field) => {
            if (field !== 'review' && field !== 'submit') {
              form.fields[field].defaultValue = this.getDefaultValue(field);
            }
          });
        }
        if (!!review && review.review) {
          if (form.fields.review) {
            form.fields.review.defaultValue = review.review;
          }
        }
        if (reviewsAndRatingsLoading) {
          return <Loading message="Submitting..." />;
        }
        if (isErrorDisplayed) {
          return <StyledError error={reviewsAndRatingsError} />;
        }
        if (isThankYouMessageDisplayed) {
          return <ThankYouMessage />;
        }
        return <DynamicForm form={form} formName={form.name} serverErrors={reviewsAndRatingsError} clearErrorForField={this.clearErrorField} onSubmit={this.submitFeedbackForm} />;
      };

      render() {
        const store = this.props.feedbackStore;
        if (store.feedbackLoading) {
          return (
            <Container>
              <Loading message="Loading..." />
            </Container>
          );
        }
        if (store.feedbackError) {
          return (
            <Container>
              <StyledError error={store.feedbackError} />
            </Container>
          );
        }
        const { isFeedbackModalOpen } = this.props.feedbackStore;

        return (
          <Modal open={isFeedbackModalOpen} className="reviews-modal" position="center" toggle={() => this.props.feedbackStore.closeFeedbackModal()} ariaLabel="Review Content">
            <div className="pt-8 mx-8">{this.getFormDisplay()}</div>
          </Modal>
        );
      }
    }
  )
);

export default FeedbackModal;
