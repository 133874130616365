import React from 'react';

function CloudLock({ classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';

  return (
    <svg className={className} {...accessibilityProps} viewBox="0 0 40 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28.6974 5.40666C28.0106 5.40666 27.3275 5.46496 26.6572 5.58039C24.8266 2.46232 21.351 0.5 17.5145 
        0.5C12.8951 0.5 8.90922 3.2964 7.54552 7.39219C3.12611 8.60067 0 12.472 0 16.8843C0 21.3137 3.07714 
        25.0661 7.39637 26.3156V23.6299C4.6169 22.5069 2.68708 19.908 2.68708 16.8845C2.68708 13.406 5.30002 
        10.3802 8.90049 9.68968C9.41737 9.59036 9.82483 9.21507 9.94295 8.7299C10.7578 5.37917 13.8708 3.03892 
        17.5139 3.03892C20.6517 3.03892 23.4644 4.80526 24.6801 7.53866C24.9446 8.13252 25.6348 8.44774 26.2927 
        8.2664C27.0707 8.05341 27.8797 7.9456 28.6973 7.9456C33.4479 7.9456 37.3129 11.5969 37.3129 16.0854C37.3129 
        19.2606 35.3973 22.0093 32.6036 23.3499V26.1074C36.9406 24.6062 40 20.6836 40 16.085C40.0003 10.1972 34.9304 
        5.40645 28.6975 5.40645L28.6974 5.40666ZM27.4156 18.6893V15.6342C27.4156 11.7708 24.0886 8.62749 20 8.62749C15.9115 
        8.62749 12.5845 11.7705 12.5845 15.6342V18.6893C10.7324 19.3599 9.41183 21.053 9.41183 23.0361V30.2866C9.41183 
        32.3083 11.1523 33.9531 13.2923 33.9531H14.5587C16.1195 34.9287 17.9886 35.5 20 35.5C22.0115 35.5 23.8806 
        34.9287 25.4411 33.9531H26.7074C28.8475 33.9531 30.5879 32.3083 30.5879 30.2866L30.5882 23.0361C30.5882 
        21.053 29.2676 19.3599 27.4156 18.6893H27.4156ZM20 11.1667C22.6071 11.1667 24.7282 13.171 24.7282 
        15.6342V17.9762C23.3233 17.2497 21.7147 16.8316 20 16.8316C18.2854 16.8316 16.6768 17.2497 15.2719 
        17.9762V15.6342C15.2719 13.1709 17.3931 11.1667 20 11.1667ZM21.3436 25.9853V29.3519C21.3436 30.053 
        20.7421 30.6214 20 30.6214C19.258 30.6214 18.6565 30.053 18.6565 29.3519V25.9853C17.9833 25.5699 
        17.5366 24.8542 17.5366 24.038C17.5366 22.7524 18.6395 21.7103 20 21.7103C21.3606 21.7103 22.4635 
        22.7524 22.4635 24.038C22.4635 24.8545 22.0167 25.5699 21.3436 25.9853Z"
      />
    </svg>
  );
}
export default CloudLock;
