import React from 'react';
import ChevronDown from '../../Icons/ChevronDown';
import FormatUtil from '../../utils/formatUtil';

function CollapsibleTriggerIcon({ name, open, toggleOpen }) {
  const chevronClasses = `w-5 h-5 mx-auto md:mx-0 hover:text-gray-900 text-gray-600 transition-transform cursor-pointer ${open ? 'transform rotate-180' : ''}`;
  const collapsibleId = FormatUtil.lowerCaseHyphenText(name);
  const srText = `${open ? 'Collapse' : 'Expand'} ${name} breakdown`;

  const onClick = () => {
    if (toggleOpen) {
      return toggleOpen();
    }
    return null;
  };
  return (
    <button aria-controls={collapsibleId} id={`${collapsibleId}-collapsible`} aria-expanded={!!open} onClick={onClick}>
      <span className="sr-only">{srText}</span>
      <ChevronDown classes={chevronClasses} />
    </button>
  );
}

export default CollapsibleTriggerIcon;
