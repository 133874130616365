import React from 'react';
import Collapsible from 'react-collapsible';
import { observer } from 'mobx-react';
import Icon from '../Icon/Icon';
import Input from '../FormFields/Input';
import TeamHierarchyGroup from './TeamHierarchyGroup';

function GroupsHeader({ handleColSort, parentId, sortConfig, groupColumns }) {
  return (
    <div className="grid grid-cols-12 text-sm">
      {Object.keys(groupColumns).map((col) => {
        let sortIcon = 'arrows-up-down';
        let isActive = false;
        if (sortConfig && sortConfig.col === col) {
          isActive = true;
          sortIcon = `arrow-narrow-${sortConfig.dir === 'desc' ? 'down' : 'up'}`;
        }
        const displayArr = groupColumns[col].display.split(' ');
        const lastWord = displayArr[displayArr.length - 1];
        displayArr.pop();
        return (
          <div
            className={`p-4 pt-0 text-${groupColumns[col].alignment || 'left'} col-span-${groupColumns[col].width} ${groupColumns[col].canSort ? 'sortable' : ''} ${
              isActive ? 'active' : ''
            }`}
            key={col}
          >
            <button onClick={() => handleColSort(parentId, col)}>
              {displayArr.join(' ')}{' '}
              <span className="whitespace-nowrap">
                {lastWord}
                {groupColumns[col].canSort ? <Icon name={sortIcon} className="inline ml-1 w-4 h-4" /> : null}
              </span>
            </button>
          </div>
        );
      })}
    </div>
  );
}

export default observer(
  class TeamHierarchyGroups extends React.Component {
    state = {
      openStates: {},
      newGroupName: '',
    };

    componentDidMount() {
      this.setOpenStates();
    }

    componentDidUpdate(prevProps) {
      if (prevProps.data !== this.props.data) {
        this.setOpenStates();
      }
    }

    setOpenStates = () => {
      const newState = {
        ...this.state,
      };
      const { data, searchVal } = this.props;
      if (data && data.length) {
        // Set up the collapsible open/close states for the groups
        data.forEach((group) => {
          let openState = newState.openStates[group.id] || false;
          openState = searchVal && group.defaultOpen ? group.defaultOpen : openState;
          newState.openStates[group.id] = openState;
        });
      }
      this.setState(newState);
    };

    /**
     * Toggle collapsible state per specified group
     */
    toggleCollapsible = (groupId) => {
      const newState = {
        ...this.state,
      };
      newState.openStates[groupId] = !newState.openStates[groupId];
      this.setState(newState);
    };

    /**
     * Handle when a user hits enter key when adding a new group name (create the group)
     */
    handleNewGroupEnter = (e) => {
      if (e.key === 'Enter' && this.state.newGroupName.length) {
        const { parentId, newGroupInline } = this.props;
        newGroupInline(parentId, this.state.newGroupName);
        this.setNewGroupInputVal(null, { value: '' }); // Reset the input value
      }
    };

    /**
     * Set the value for the group when user is typing to add inline
     */
    setNewGroupInputVal = (e, data) => {
      const newState = {
        ...this.state,
        newGroupName: data.value,
      };
      this.setState(newState);
    };

    /**
     * Return sorted array data based on current sort config
     */
    handleSorting = (data, sortConfig) => {
      return data.slice().sort((a, b) => this.colSortHandler(a, b, sortConfig));
    };

    /**
     * Compares two objects based on selected sort column and direction
     */
    colSortHandler = (a, b, sortConfig) => {
      if (!sortConfig) {
        return 0;
      }
      const { col, dir } = sortConfig;
      if (a[col] > b[col]) {
        return dir === 'asc' ? -1 : 1;
      }
      if (a[col] < b[col]) {
        return dir === 'asc' ? 1 : -1;
      }
      return 0;
    };

    getGroupColumns = (isSubgroup) => {
      const groupColumns = {
        name: {
          display: 'Group Name',
          width: 3,
          alignment: 'left',
          canSort: true,
        },
        children: {
          display: 'Sub-Groups',
          width: 2,
          alignment: 'center',
          canSort: true,
        },
        admins: {
          display: 'Admins',
          width: 2,
          alignment: 'center',
          canSort: true,
        },
        member_count: {
          display: 'Members',
          width: 2,
          alignment: 'center',
          canSort: true,
        },
        created_at: {
          display: 'Date Created',
          width: 2,
          alignment: 'center',
          canSort: true,
        },
        actions: {
          display: '',
          width: 1,
          alignment: 'right',
          canSort: false,
        },
      };

      if (isSubgroup) {
        delete groupColumns.children;
        groupColumns.name.width = 5;
      }
      return groupColumns;
    };

    render() {
      // Team ID prop represents the scope of this tree group. Would be either top level org ID, or a nested group ID depending on where we are in hierarchy
      const { data, refreshData, isSubgroup, newGroupInline, deleteGroup, getTeamInfo, handleColSort, parentId, getSortMap, searchVal, canManageGroup, canManageTeam } = this.props;
      // If no data and not a subgroup, show empty message
      if (!data || !data.length) {
        if (isSubgroup) {
          return null;
        }
        return (
          <div className="empty-data-message">
            <p>There are no groups to display! Edit your search query or click Add Group to create your first group.</p>
          </div>
        );
      }
      const sortConfig = getSortMap(parentId);
      // Sort group by current config
      const sortedChildren = this.handleSorting(data, sortConfig);
      const groupColumns = this.getGroupColumns(isSubgroup);
      return (
        <div className={`groups-container ${isSubgroup ? 'pl-8 py-4' : ''}`}>
          <GroupsHeader handleColSort={handleColSort} parentId={parentId} sortConfig={sortConfig} groupColumns={groupColumns} />
          {sortedChildren.map((group) => {
            if (!group.permissions || !group.permissions.manage) {
              return null;
            }
            const canManageCurrentGroup = group.permissions.manage === 'edit';
            return (
              <React.Fragment key={group.id}>
                <TeamHierarchyGroup
                  group={group}
                  toggleCollapsible={this.toggleCollapsible}
                  columnsConfig={groupColumns}
                  refreshData={refreshData}
                  isSubgroup={!!isSubgroup}
                  deleteGroup={deleteGroup}
                  getTeamInfo={getTeamInfo}
                  canManageGroup={canManageCurrentGroup}
                />
                <Collapsible className="sub-groups-container" openedClassName="sub-groups-container open" open={this.state.openStates[group.id]} transitionTime={200}>
                  <TeamHierarchyGroups
                    data={group.children}
                    refreshData={refreshData}
                    isSubgroup
                    newGroupInline={newGroupInline}
                    deleteGroup={deleteGroup}
                    getTeamInfo={getTeamInfo}
                    parentId={group.id}
                    handleColSort={handleColSort}
                    getSortMap={getSortMap}
                    searchVal={searchVal}
                    canManageGroup={canManageCurrentGroup}
                    canManageTeam={canManageTeam}
                  />
                </Collapsible>
              </React.Fragment>
            );
          })}
          {canManageGroup ? (
            <div className={`groups-row add-group-row text-${groupColumns.name.alignment || 'left'}`}>
              <Input
                className="py-4 px-6 w-full text-sm text-black border border-gray-400"
                placeholder="+ Add Group"
                onChange={this.setNewGroupInputVal}
                value={this.state.newGroupName}
                onKeyDown={this.handleNewGroupEnter}
              />
            </div>
          ) : null}
        </div>
      );
    }
  }
);
