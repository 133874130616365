import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Bugsnag from '@bugsnag/js';
import moment from 'moment';
import queryString from 'query-string';
import Collapsible from 'react-collapsible';
import Loading from '../Loading/Loading';
import Button from '../Button/Button';
import DynamicForm from '../DynamicForm/DynamicForm';
import Agents from '../../agents/agents';
import { useAuth } from '../../contexts/UseAuth';
import AttributeMap from './AttributeMap';
import ChevronDown from '../../Icons/ChevronDown';

const sections = {
  idp: { name: 'Identity Provider Configuration' },
  sp: { name: 'Service Provider Configuration' },
  attributeMap: { name: 'Attribute Mapping' },
};

const spRef = React.createRef();

const copyToClipboard = (id) => {
  const input = document.querySelector(`#${id}`);
  input.focus();
  input.select();

  try {
    document.execCommand('copy');
  } catch (err) {
    Bugsnag.notify(err);
  }
};

function SsoCertificates({ data, classes, headingClasses }) {
  if (!data || !data.spCertificates || !data.spCertificates.length) {
    return null;
  }

  return (
    <>
      <p className={headingClasses}>Service Provider Certificates</p>
      <div className={`${classes} mb-0`}>
        {data.spCertificates.map((cert, idx) => {
          const id = `copyToClipboard-x509Certificate-${idx}`;
          return (
            <div key={cert.x509Certificate}>
              <p className="mb-2 text-xs">Expires: {moment(cert.expiresAt).format('MM-DD-YYYY')}</p>
              <div className="flex mb-4">
                <input
                  id={id}
                  aria-label="Audience URI"
                  type="text"
                  defaultValue={cert.x509Certificate}
                  style={{ width: '89%', borderRadius: '5px' }}
                  className="p-3 mr-3 w-full rounded-sm border border-gray-500"
                />
                <Button color="gray" onClick={() => copyToClipboard(id)}>
                  Copy
                </Button>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

function MetaXmlDownload({ samlHandler, spConfig }) {
  if (samlHandler !== 'cybrary' || !spConfig?.metadataUrl) {
    return null;
  }

  return (
    <Button onClick={() => window.open(spConfig.metadataUrl, '_blank')} className="ml-4">
      Download Metadata XML
    </Button>
  );
}

function SpConfig({ spConfig, teamId, signout, samlHandler }) {
  if (!spConfig) {
    return null;
  }

  const headingClasses = 'text-black mb-2 font-semibold';
  const dataClasses = 'col-span-3 mb-2';
  const loginLink = `/login/?provider=saml.t_${teamId}`;
  const loginLinkFQDN = `${process.env.REACT_APP_BASE_URL}${loginLink}`;
  return (
    <div className="p-4 mb-8 rounded" ref={spRef}>
      <div className="text-sm">
        <p className={headingClasses}>Audience URI (Service Provider Entity ID)</p>
        <div className="flex mb-4">
          <input
            id="copyToClipboard-spEntityId"
            aria-label="Audience URI"
            type="text"
            defaultValue={spConfig.spEntityId}
            style={{ width: '89%', borderRadius: '5px' }}
            className="p-3 mr-3 w-full rounded-sm border border-gray-500"
          />
          <Button color="gray" onClick={() => copyToClipboard('copyToClipboard-spEntityId')}>
            Copy
          </Button>
        </div>
        <p className={headingClasses}>Single Sign-On URL (ACS Url)</p>
        <div className="flex mb-4">
          <input
            id="copyToClipboard-callbackUri"
            aria-label="Audience URI"
            type="text"
            defaultValue={spConfig.callbackUri || spConfig.callbackUrl}
            style={{ width: '89%', borderRadius: '5px' }}
            className="p-3 mr-3 w-full rounded-sm border border-gray-500"
          />
          <Button color="gray" onClick={() => copyToClipboard('copyToClipboard-callbackUri')}>
            Copy
          </Button>
        </div>
        <SsoCertificates data={spConfig} classes={dataClasses} headingClasses={headingClasses} />
      </div>
      <div className="mt-8 text-sm font-normal">
        <div className="p-2 mb-2 bg-gray-200">
          <p>
            <span className={`${headingClasses} mr-2`}>Name ID format:</span> EmailAddress
          </p>
          <p className="mb-0">Identifies the SAML processing rules and constraints for the assertion&apos;s subject statement.</p>
          <p>No other attribute mapping is required</p>
        </div>
      </div>
      <h4 className={`${headingClasses} mt-4`}>Login Url</h4>
      <p className="text-sm">Cybrary does not support IdP Initiated SSO, however we provide a convenient link that can be used as a bookmark.</p>
      <div className="flex mb-4">
        <input
          id="copyToClipboard-loginLink"
          aria-label="Login Link"
          type="text"
          defaultValue={loginLinkFQDN}
          style={{ width: '89%', borderRadius: '5px' }}
          className="p-3 mr-3 w-full rounded-sm border border-gray-500"
        />
        <Button color="gray" onClick={() => copyToClipboard('copyToClipboard-loginLink')}>
          Copy
        </Button>
      </div>
      <h4 className={`${headingClasses} mt-4`}>Test Configuration</h4>
      <p className="text-sm">
        To test your configuration, please click the button below. This will sign you out of Cybrary and attempt to sign you in using your SSO configuration. When complete, please
        contact your customer success manager to enable SSO across your domain.
      </p>
      <Button
        onClick={() => signout(false, loginLink)}
        className="flex-1 py-2.5 px-6 text-sm font-bold leading-5 text-center text-black bg-gray-200 hover:bg-gray-300 rounded-sm border-0 cursor-pointer"
      >
        Test
      </Button>
      <MetaXmlDownload samlHandler={samlHandler} spConfig={spConfig} />
    </div>
  );
}

function ManageSso({ team, commonStore }) {
  const auth = useAuth();
  const params = useParams();
  const isDemo = params?.orgId === 'demo';
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [provider, setProvider] = useState({});
  const [serverErrors, setServerErrors] = useState(null);
  const [isSectionOpen, setIsSectionOpen] = useState({ idp: false, sp: false, attributeMap: false });

  const providerHasIdpConfig = (data) => !!(data && data.sso && data.sso.idpConfig);

  const hasIdpConfig = () => {
    return providerHasIdpConfig(provider);
  };

  const getIdpCert = (data) => {
    if (data?.x509Certificate) {
      return data.x509Certificate;
    }
    if (typeof data === 'string') {
      return data;
    }
    return null;
  };

  const toggleSectionCollapsible = (section) => {
    const newIsSectionOpen = { ...isSectionOpen };
    newIsSectionOpen[section] = !newIsSectionOpen[section];
    setIsSectionOpen(newIsSectionOpen);
  };

  const setSectionCollapseStateToDefault = (data) => {
    const currentlyHasIdpConfig = providerHasIdpConfig(data);

    return setIsSectionOpen({ idp: !currentlyHasIdpConfig, sp: currentlyHasIdpConfig, attributeMap: currentlyHasIdpConfig });
  };

  const onSubmit = async (data) => {
    const payload = { ...data };
    setSubmitting(true);
    setServerErrors(null);
    const queryParams = queryString.parse(window.location.search);
    // If we have a cybrary-saml query param, or the team has cybrary saml enabled,
    // or the provider is not configured for saml, set the saml handler to cybrary
    if (queryParams['cybrary-saml'] || team.cybrary_saml || !payload.samlHandler) {
      payload.samlHandler = 'cybrary';
    }
    try {
      const response = provider.id ? await Agents.enterprise.putSso(team.id, provider.id, payload) : await Agents.enterprise.postSSO(team.id, payload);

      setSubmitting(false);
      commonStore.triggerToast('success', {
        content: 'Your single sign-on Identity Provider information has been successfully updated.',
      });
      setProvider(response.provider);
      setSectionCollapseStateToDefault(response.provider);
      setTimeout(() => {
        spRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 200);
    } catch (e) {
      setSubmitting(false);
      // If we get a 422 back, set in state and send to dynamic form to display on field
      if (e && e.response && e.response.status === 422 && e.response.data && e.response.data.errors) {
        setServerErrors(e.response.data.errors);
      } else {
        Bugsnag.notify(e);
        commonStore.triggerToast('error', {
          content: 'Something went wrong. Unable to add SSO config for your team',
        });
      }
    }
  };

  // Reset the server errors when user changes field so they can try and submit again
  const resetServerErrors = () => {
    if (serverErrors) {
      setServerErrors(null);
    }
  };

  const deleteSso = async () => {
    try {
      setLoading(true);
      await Agents.enterprise.deleteSso(team.id, provider.id);
      commonStore.triggerToast('success', {
        content: 'Single sign-on configuration has been successfully deleted.',
      });
      setProvider({});
      setSectionCollapseStateToDefault({});
      setLoading(false);
    } catch (e) {
      setLoading(false);
      Bugsnag.notify(e);
      commonStore.triggerToast('error', {
        content: "Something went wrong. Unable to delete this team's SSO config",
      });
    }
  };

  const deleteSsoConfirm = () => {
    commonStore.triggerConfirm({
      content: 'Are you sure you want to remove the single sign-on configuration for this team?',
      cancel: () => {
        commonStore.resetConfirmState();
      },
      continue: () => {
        commonStore.resetConfirmState();
        deleteSso();
      },
    });
  };

  const checkValidUrl = (data) => {
    return data.ssoUrl && /^https/.test(data.ssoUrl) ? null : 'URL must use https';
  };

  useEffect(() => {
    const getTeamSso = async () => {
      try {
        const data = await Agents.enterprise.getSso(team.id);

        if (data && data.provider) {
          setProvider(data.provider);
          setSectionCollapseStateToDefault(data.provider);
        }
      } catch (e) {
        Bugsnag.notify(e);
      } finally {
        setLoading(false);
      }
    };
    // skip getting team sso data when in a demo and toggle loading state
    // we do not want to send any requests to the backend in the demo view
    if (isDemo) {
      setLoading(false);
    } else {
      getTeamSso();
    }
  }, []);

  const getSsoIdpForm = () => {
    return {
      name: 'Login Email Form',
      order: ['idpEntityId', 'ssoUrl', 'x509Certificate', 'signRequest', 'submit', 'delete'],
      fields: {
        idpEntityId: {
          type: 'text',
          label: 'Identity Provider (IdP) Issuer URI',
          validations: ['required'],
          defaultValue: !!hasIdpConfig() && provider.sso.idpConfig.idpEntityId,
          disabled: submitting,
          description: 'Issuer URI of the Identity Provider. This value is usually the SAML Metadata EntityID of the IdP EntityDescriptor.',
        },
        ssoUrl: {
          type: 'text',
          label: 'Identity Provider (IdP) Single Sign-On URL',
          validations: ['required', checkValidUrl],
          defaultValue: !!hasIdpConfig() && provider.sso.idpConfig.ssoUrl,
          disabled: submitting,
          description: 'The binding-specific IdP Authentication Request Protocol endpoint that receives SAML AuthnRequest messages.',
        },
        x509Certificate: {
          type: 'textarea',
          label: 'Identity Provider Signature Certificate',
          validations: ['required'],
          defaultValue: !!hasIdpConfig() && getIdpCert(provider.sso.idpConfig.idpCertificates[0]),
          disabled: submitting,
          description: 'The PEM encoded public key certificate of the Identity Provider used to verify SAML message and assertion signatures.',
        },
        signRequest: {
          type: 'boolean',
          label: 'Sign AuthN Requests',
          validations: [],
          defaultValue: !!hasIdpConfig() && !!provider.sso.idpConfig.signRequest,
          disabled: submitting,
          description: 'Generates SP Certificate and signs AuthnRequest requests. AuthnRequest request signature is verified in the returned assertion',
        },
        submit: {
          type: 'button',
          color: 'pink',
          loading: submitting,
          label: hasIdpConfig() ? 'Update' : 'Submit',
          disabled: submitting || isDemo,
          className: hasIdpConfig() ? 'float-left mr-4' : '',
        },
        delete: {
          type: 'insert',
          insertComponent: (
            <Button color="gray" onClick={deleteSsoConfirm}>
              Delete
            </Button>
          ),
          hidden: !hasIdpConfig(),
        },
      },
    };
  };

  if (!team || !team.permissions || !team.permissions.canManageAdmins) {
    return (
      <div className="mb-16 text-gray-600">
        <p>Only team owners can edit single sign-on configuration details. Please ask an owner if you want to edit these settings</p>
      </div>
    );
  }

  if (loading) {
    return <Loading message="Loading..." />;
  }

  const collapsibleSection = (section, content) => {
    return (
      <div className="px-4 pt-4 mb-4 rounded border-xs border-gray-400">
        <Collapsible
          open={isSectionOpen[section]}
          transitionTime={200}
          handleTriggerClick={() => toggleSectionCollapsible(section)}
          trigger={
            <div
              className="mb-4"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                cursor: 'pointer',
              }}
            >
              <h3 className="mb-2 text-lg font-black">{sections[section].name}</h3>
              <ChevronDown classes={`w-4 h-4 transition-transform ${isSectionOpen[section] && 'transform rotate-180'}`} />
            </div>
          }
        >
          {content}
        </Collapsible>
      </div>
    );
  };

  const spSection =
    hasIdpConfig() && collapsibleSection('sp', <SpConfig spConfig={provider?.sso?.spConfig} samlHandler={provider?.saml_handler} teamId={team.id} signout={auth.signout} />);
  const idpSection = collapsibleSection(
    'idp',
    <DynamicForm
      customClassName="font-bold text-base"
      form={getSsoIdpForm()}
      onSubmit={isDemo ? () => {} : onSubmit}
      serverErrors={serverErrors}
      handleOnChange={resetServerErrors}
    />
  );
  const attributeMapSection = hasIdpConfig() && collapsibleSection('attributeMap', <AttributeMap org={team} provider={provider} commonStore={commonStore} />);

  return (
    <div className="mb-4">
      <p className="mb-6 text-gray-600">Don&apos;t know where to get started with Single-Sign On? Talk to your IT team.</p>
      {idpSection}
      {spSection}
      {attributeMapSection}
    </div>
  );
}

export default ManageSso;
