import React from 'react';
import { Fade } from 'react-awesome-reveal';
import { twMerge } from 'tailwind-merge';

/**
 * CheckoutStep container. Adds a fade-in animation to the children and hides the container if isShown is false. 🚶...
 * @param {JSX} children Content to show in the container
 * @param {string} className Tailwind classes to apply to the container
 * @param {string} childClassName Tailwind classes to apply to the children
 * @param {boolean} isShown Whether or not to show the container
 * @returns <div className={className}><div className={childClassName}>{children}</div></div> with added fade
 */
function PaddleStep({ children, className, childClassName, isShown }) {
  const classes = twMerge('flex flex-col w-full mx-auto  max-w-screen-sm ease-in-out duration-300', isShown ? '' : 'hidden', className);
  const childClasses = twMerge('p-2 lg:p-4 mx-auto w-full lg:min-w-[550px] rounded-sm border-gray-400', childClassName);
  return (
    <div className={classes}>
      <Fade triggerOnce duration={300}>
        <div className={childClasses}>{children}</div>
      </Fade>
    </div>
  );
}

export default PaddleStep;
