import React from 'react';
import CountUpWidget from '../Card/CountUpCard/CountUpWidget';
import LockedContainer from '../Container/LockedContainer';
import Card from '../Card/Card';
import LoadingText from '../Loading/LoadingText';
import If from '../If/If';
import Container from '../Container/Container';
import WidgetContainer from '../Container/WidgetContainer';
import { useABM } from '../../providers/ABMProvider';

/**
 * Loading Card for Metric Widget
 * @returns <LoadingCard />
 */
function LoadingCard() {
  return (
    <WidgetContainer className="flex grow">
      <Card className="grow" stackDisplay>
        <LoadingText wrapperClassName="w-2/5 h-3 mb-7" className="h-3" />
        <LoadingText wrapperClassName="w-3/5 h-3" className="h-3" />
      </Card>
    </WidgetContainer>
  );
}

/**
 * Metric Widget for ABM Page
 * @returns {JSX} - The Trending Courses Widget
 */
function MetricWidget({ title, value, lock }) {
  const { isABMTeamLoading, isVerified } = useABM();

  return (
    <LockedContainer id={lock.id} msg={lock.msg} isLocked={!isVerified && !isABMTeamLoading} isFullWidth>
      <Container className="flex m-0 min-h-[130px]" omitPadding>
        {/* Loading State */}
        <If condition={isABMTeamLoading}>
          <LoadingCard />
        </If>
        {/* CountUp Widget */}
        <If condition={!isABMTeamLoading}>
          <CountUpWidget title={title} value={value} />
        </If>
      </Container>
    </LockedContainer>
  );
}

export default MetricWidget;
