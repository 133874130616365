import React from 'react';

export default function Star({ active, classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';
  if (active) {
    return (
      <svg className={className} fill="none" viewBox="0 0 17 16" xmlns="http://www.w3.org/2000/svg" {...accessibilityProps}>
        <path
          d="M9.40871 0.618861C9.14082 -0.206287 7.97343 -0.206287 7.70465 0.618861L6.34374 4.80642C6.28513 4.98619 6.17114 5.1428 6.01809 5.25384C5.86505 5.36489 5.6808 
          5.42466 5.49172 5.42461H1.08913C0.221876 5.42461 -0.140078 6.53556 0.562328 7.04624L4.12453 9.63367C4.27755 9.74491 4.39143 9.90173 4.44985 10.0817C4.50827 10.2616 
          4.50824 10.4554 4.44975 10.6353L3.08974 14.8229C2.82096 15.648 3.76616 16.3352 4.46767 15.8245L8.02987 13.2371C8.18298 13.1258 8.3674 13.0658 8.55668 13.0658C8.74596 
          13.0658 8.93038 13.1258 9.08348 13.2371L12.6457 15.8245C13.3472 16.3352 14.2924 15.6489 14.0236 14.8229L12.6636 10.6353C12.6051 10.4554 12.6051 10.2616 12.6635 
          10.0817C12.7219 9.90173 12.8358 9.74491 12.9888 9.63367L16.551 7.04624C17.2525 6.53556 16.8924 5.42461 16.0242 5.42461H11.6207C11.4318 5.42447 11.2478 5.36461 11.0949 
          5.25358C10.942 5.14255 10.8282 4.98604 10.7696 4.80642L9.40871 0.618861Z"
          fill="currentColor"
        />
      </svg>
    );
  }
  return (
    <svg className={className} fill="none" stroke="currentColor" viewBox="0 0 18 16" xmlns="http://www.w3.org/2000/svg" {...accessibilityProps}>
      <path
        d="M10.1392 0.618861C9.87129 -0.206287 8.7039 -0.206287 8.43512 0.618861L7.07421 4.80642C7.01559 4.98619 6.90161 5.1428 6.74856 5.25384C6.59552 5.36489 
        6.41127 5.42466 6.22218 5.42461H1.8196C0.952345 5.42461 0.590391 6.53556 1.2928 7.04624L4.855 9.63367C5.00802 9.74491 5.1219 9.90173 5.18032 10.0817C5.23874 
        10.2616 5.23871 10.4554 5.18022 10.6353L3.82021 14.8229C3.55143 15.648 4.49663 16.3352 5.19814 15.8245L8.76034 13.2371C8.91345 13.1258 9.09787 13.0658 9.28715 
        13.0658C9.47643 13.0658 9.66085 13.1258 9.81395 13.2371L13.3762 15.8245C14.0777 16.3352 15.0229 15.6489 14.7541 14.8229L13.3941 10.6353C13.3356 10.4554 13.3356 
        10.2616 13.394 10.0817C13.4524 9.90173 13.5663 9.74491 13.7193 9.63367L17.2815 7.04624C17.983 6.53556 17.6228 5.42461 16.7547 5.42461H12.3512C12.1623 5.42447 
        11.9782 5.36461 11.8254 5.25358C11.6725 5.14255 11.5587 4.98604 11.5001 4.80642L10.1392 0.618861Z"
        fill="currentColor"
      />
    </svg>
  );
}
