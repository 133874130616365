import React from 'react';
import { observer, inject } from 'mobx-react';
import Bugsnag from '@bugsnag/js';
import DynamicForm from '../DynamicForm/DynamicForm';
import Loading from '../Loading/Loading';
import StyledError from '../Error/StyledError';
import Container from '../Container/Container';

const CreateGroup = inject(
  'enterpriseStore',
  'commonStore'
)(
  observer(
    class CreateGroup extends React.Component {
      componentDidMount() {
        const { teamId, groupId, view, modalView } = this.props;
        if (!!groupId && view === 'edit') {
          // If this is used in a modal, we already have data, don't need to get again
          if (!modalView) {
            this.props.enterpriseStore.getGroupProfileData(groupId);
          }
        } else {
          this.props.enterpriseStore.getUserListData(teamId);
        }
      }

      componentWillUnmount() {
        // If this is used in a modal, we already have data for elsewhere, don't need to reset it
        if (!this.props.modalView) {
          this.props.enterpriseStore.setDefaultGroupProfileData();
        }
        this.props.enterpriseStore.setDefaultUserListData();
      }

      handleNewGroupSuccess = (group, admins) => {
        const successMsg = 'New group has been added';
        // If successful, now need to add specified admins separately
        if (group && group.id && admins && admins.length) {
          this.props.enterpriseStore
            .addUsersToGroup(group.id, admins, null, 'team-admin')
            .then(() => {
              this.props.commonStore.triggerToast('success', {
                content: successMsg,
              });
              if (this.props.callback) {
                this.props.callback();
              }
            })
            .catch((e) => {
              Bugsnag.notify(e);
              this.props.commonStore.triggerToast('success', {
                content: 'New group has been added, but we were unable to add the selected admin(s)',
              });
              if (this.props.callback) {
                this.props.callback();
              }
            });
        } else {
          this.props.commonStore.triggerToast('success', {
            content: successMsg,
          });
          if (this.props.callback) {
            this.props.callback();
          }
        }
      };

      handleSubmit = (data) => {
        const { teamId, view, groupId } = this.props;
        if (!!teamId && !!groupId && view === 'edit') {
          this.props.enterpriseStore
            .editGroup(teamId, groupId, data)
            .then(() => {
              if (this.props.callback) {
                this.props.callback();
              }
              this.props.commonStore.triggerToast('success', {
                content: 'Group info has been updated',
              });
            })
            .catch((error) => {
              this.props.commonStore.triggerToast('error', {
                errorCode: error.response && error.response.status ? error.response.status : null,
              });
            });
        } else {
          this.props.enterpriseStore
            .addNewGroup(teamId, data)
            .then((response) => {
              this.handleNewGroupSuccess(response, data.admins);
            })
            .catch((e) => {
              Bugsnag.notify(e);
              this.props.commonStore.triggerToast('error', {
                content: 'Something went wrong. Unable to create this group at this time.',
              });
            });
          this.props.enterpriseStore.setDefaultUserListData();
          this.props.enterpriseStore.getUserListData(teamId);
        }
      };

      addDefaultValues = (form, storeData) => {
        if (!storeData) return form; // There is no store data (user is adding a new team), so just return the form as-is
        const newForm = { ...form };
        Object.keys(storeData).forEach((item) => {
          if (!!newForm.fields[item] && !!storeData[item] && storeData[item] !== newForm.fields[item].defaultValue) {
            newForm.fields[item].defaultValue = storeData[item];
          }
        });
        return newForm;
      };

      getCreateGroupForm = (users) => {
        const { view } = this.props;
        if (users.loading && view === 'add') {
          return (
            <Container>
              <Loading message="Loading..." />
            </Container>
          );
        }
        if (users.error && view === 'add') {
          return (
            <Container>
              <StyledError error={users.error} />
            </Container>
          );
        }
        const CreateGroupForm = {
          order: ['name', 'submit'],
          fields: {
            name: {
              type: 'text',
              label: 'Group Name',
              placeholder: 'Type Name Here',
              validations: ['required'],
            },
            admins: {
              type: 'multi-select',
              label: 'Group Admin(s)',
              // slice casts this to a true array - otherwise throws a console warning - due to downgrade from mobx5 to mobx4
              options: view === 'edit' ? null : this.props.enterpriseStore.userListData.data.slice(),
              placeholder: 'Select a User(s)',
            },
            submit: {
              type: 'button',
              color: 'pink',
              label: view === 'edit' ? 'Edit Group' : 'Create Group',
            },
          },
        };

        // If creating a new group, add dropdown to select group admin(s)
        if (view === 'edit') {
          this.addDefaultValues(CreateGroupForm, this.props.enterpriseStore.groupProfileData.profileData);
        } else {
          CreateGroupForm.order.splice(1, 0, 'admins');
        }

        return <DynamicForm form={CreateGroupForm} formName="createGroup" serverErrors={null} clearErrorForField={null} onSubmit={this.handleSubmit} />;
      };

      render() {
        return <div className="text-black">{this.getCreateGroupForm(this.props.enterpriseStore.userListData)}</div>;
      }
    }
  )
);

export default CreateGroup;
