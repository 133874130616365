import React from 'react';
import { useNavigate } from 'react-router-dom';

import ContentTabs from './ContentTabs';
import Icon from '../Icon/Icon';
import Image from '../Image/Image';
import OptionsMenu from '../OptionsMenu/OptionsMenu';

import FormatUtil from '../../utils/formatUtil';

import useScrollDirection from '../../hooks/useScrollDirection';

function DesktopStickyContentTabs({ buttons, circularProgress, backButtonUrl, goToPreviousPath, type, typeText, image, title, menuOptions, tabs, activeTab, setActiveTab }) {
  return (
    <div id="desktop-sticky-content-tabs" className="hidden sticky top-0 z-30 -mt-px bg-white lg:block">
      <div className="flex justify-between items-center py-3 px-8 w-full h-16">
        <div id="left-side" className="flex items-center">
          {backButtonUrl ? (
            <button type="button" onClick={goToPreviousPath} className="mr-4" aria-label="Click to go back to previous page">
              <Icon name="chevron-left" className="w-4 h-4 text-gray-600" />
            </button>
          ) : null}
          {image && <Image marginBottom="mb-0" size="w-[3.75rem] h-10" className="rounded-sm" src={image} alt="" omitMarginAuto />}
          <div id="sticky-toggle-text" className="ml-2.5">
            <p className="mb-0 text-2xs font-semibold text-gray-600 uppercase">{typeText || type}</p>
            <p className="hidden text-lg font-bold text-black md:block">{title}</p>
          </div>
          {circularProgress}
        </div>
        <div id="right-side" className="flex">
          {buttons || null}
          <OptionsMenu options={menuOptions} wrapperClassName="ml-4" iconContainerClassName="border hover:bg-gray-200 border-gray-400 px-2" buttonAriaLabel={`Share ${title}`} />
        </div>
      </div>
      <ContentTabs wrapperClasses="hidden md:flex pl-16 w-full top-40 mb-8 border-t-1 border-b-1 border-gray-400" tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
    </div>
  );
}

function MobileStickyContentTabs({ buttons, circularProgress, backButtonUrl, goToPreviousPath, type, typeText, title, menuOptions, mobileSubBar }) {
  const scrollDirection = useScrollDirection({ initialDirection: 'up', thresholdPixels: 2 });

  // controls the animations if the mobile sub bar should display based on scroll direction
  const mobileSubBarDisplay = scrollDirection === 'down' ? 'translate-y-[-100%]' : 'py-2 translate-y-0';
  return (
    <>
      <div id="mobile-sticky-content-tabs" className="block sticky top-0 z-30 bg-white border-b-xs border-gray-400 lg:hidden">
        <div className="flex flex-col items-center px-4">
          <div id="left-side" className="flex items-center my-3 w-full">
            {backButtonUrl ? (
              <button type="button" onClick={goToPreviousPath} className="mr-4" aria-label="Click to go back to previous page">
                <Icon name="chevron-left" className="w-4 h-4 text-gray-600" />
              </button>
            ) : null}
            <div id="sticky-toggle-text" className="ml-2.5">
              <p className="mb-0 text-2xs font-semibold text-gray-600 uppercase">{typeText || type}</p>
              <p className="text-lg font-bold text-black">{FormatUtil.formatLongText(title, 30)}</p>
            </div>
          </div>
          <div className="flex gap-x-2 items-center mb-4 w-full">
            <div>{circularProgress}</div>
            {buttons}
            <OptionsMenu options={menuOptions} iconContainerClassName="border hover:bg-gray-200 border-gray-400 px-2" buttonAriaLabel={`Share ${title}`} />
          </div>
        </div>
      </div>
      {mobileSubBar ? (
        <div className={`sticky transition-all top-[123.5px] ${mobileSubBarDisplay} z-20  pr-4 pl-[23px] w-full h-16 bg-white border-b-1 border-gray-400 sm:hidden`}>
          {mobileSubBar}
        </div>
      ) : null}
    </>
  );
}

export default function StickyContentTabs({
  type,
  typeText,
  image,
  title,
  commonStore,
  backButtonUrl,
  tabs,
  activeTab,
  setActiveTab,
  circularProgress = null,
  permalink,
  buttons,
  mobileSubBar = null,
}) {
  const navigate = useNavigate();

  const goToPreviousPath = () => {
    navigate(`${backButtonUrl}`);
  };
  const menuOptions = [
    {
      text: 'Share',
      action: () =>
        commonStore.triggerShareComponent({
          permalink,
          type,
          title,
        }),
    },
  ];
  return (
    <>
      <DesktopStickyContentTabs
        buttons={buttons}
        circularProgress={circularProgress}
        backButtonUrl={backButtonUrl}
        goToPreviousPath={goToPreviousPath}
        type={type}
        typeText={typeText}
        image={image}
        title={title}
        menuOptions={menuOptions}
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <MobileStickyContentTabs
        buttons={buttons}
        circularProgress={circularProgress}
        backButtonUrl={backButtonUrl}
        goToPreviousPath={goToPreviousPath}
        type={type}
        typeText={typeText}
        title={title}
        menuOptions={menuOptions}
        mobileSubBar={mobileSubBar}
      />
    </>
  );
}
