import React from 'react';
import DateRangeFilter from '../Enterprise/DateRangeFilter';
import CheckboxDisplay from '../Mitre/CheckboxDisplay';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import FormatUtil from '../../utils/formatUtil';

function ClearAll({ onClick, activeFilters }) {
  if (!onClick || !activeFilters || !Object.keys(activeFilters).length) {
    return null;
  }

  const noun = FormatUtil.pluralize(Object.keys(activeFilters).length, 'filter');
  return (
    <Button color="gray" className="flex justify-between items-center" onClick={onClick}>
      <Icon name="x" className="mr-1 w-4 h-4" />
      Clear {Object.keys(activeFilters).length} {noun}
    </Button>
  );
}

function ActivityFilters({ onFilterChange, onDateChange, filters, activeFilters, clearAll, defaultStartDate, defaultEndDate }) {
  const today = new Date();

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-black">Filters</h2>
        <ClearAll onClick={clearAll} activeFilters={activeFilters} />
      </div>
      <div>
        <h3 className="mb-2 font-bold">Date Range</h3>
        <DateRangeFilter
          dateChange={onDateChange}
          dateSelectionWrapperClasses="flex flex-col justify-center mb-4"
          omitDateRange
          startDate={activeFilters.startDate || defaultStartDate || today}
          endDate={activeFilters.endDate || defaultEndDate || today}
        />
        {filters.map((section) => {
          return (
            <div key={section.sectionKey} className="mb-4">
              <h3 className="mb-2 font-bold">{section.sectionLabel}</h3>
              {section.filters.map((filter) => {
                const isActive = activeFilters && activeFilters[section.sectionKey] && activeFilters[section.sectionKey] === filter.value;
                return (
                  <button onClick={() => onFilterChange(section.sectionKey, filter.value)} className="flex items-center mb-2 text-sm text-left" key={filter.value}>
                    <CheckboxDisplay className="mr-2" isSelected={isActive} />
                    {filter.label}
                  </button>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ActivityFilters;
