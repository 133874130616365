import React from 'react';

export default function GoalFlag({ classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';
  return (
    <svg className={className} width="91" height="100" viewBox="0 0 91 100" fill="none" xmlns="http://www.w3.org/2000/svg" {...accessibilityProps}>
      <path
        d="M89.8526 13.443L85.5794 9.68016L89.8526 5.91811C90.5172 5.33217 90.7445 4.41894 90.4325 3.59039C90.1189 2.76107 89.3445 2.22549 88.4588 
        2.22549H70.7754V1.4656C70.7754 0.656127 70.1193 0 69.3098 0C68.5003 0 67.8442 0.656127 67.8442 1.4656V21.2318L55.8073 27.7679C55.7409 27.2384 
        55.5822 26.7181 55.3312 26.2283L53.6352 22.9171C52.471 20.6436 49.674 19.7425 47.402 20.906L46.0905 21.5774C44.9888 22.142 44.1733 23.1002 43.7933 
        24.2782C43.4134 25.4554 43.5148 26.7105 44.0794 27.8114L45.7754 31.1233C46.1714 31.8961 46.7566 32.508 47.4447 32.9368C46.5445 34.1491 46.1172 
        35.6399 45.6778 37.1886L45.3619 38.3071C45.1414 39.0861 45.5946 39.8963 46.3736 40.116C46.5063 40.1542 46.6406 40.1717 46.7726 40.1717C47.4119 
        40.1717 47.9994 39.7506 48.1825 39.1044L48.4983 37.9882C49.1407 35.723 49.5352 34.51 50.791 33.8279L53.0424 32.6049L53.3201 32.463C53.4124 32.4157 
        53.4986 32.3623 53.5864 32.3097L69.6325 23.5961C69.531 25.6019 68.4515 27.497 66.6738 28.5766L56.2391 34.9166C56.2269 34.9235 56.2147 34.9304 
        56.2033 34.938L52.9554 36.9109C52.2642 37.3313 52.0437 38.2331 52.4641 38.9251C52.8601 39.5774 53.684 39.8093 54.3562 39.482L51.8919 46.9314L50.0906 
        47.6699L45.1925 49.6772L46.604 44.6876C46.8237 43.9086 46.3713 43.0984 45.5923 42.8779C44.8126 42.6566 44.0031 43.1106 43.7826 43.8895L41.5526 
        51.776C41.5236 51.8775 41.5045 51.9889 41.4999 52.0949L40.9163 62.8157C40.8987 63.2247 40.9857 63.9975 40.7965 64.3965L34.3146 78.0333H29.6042C26.8446 
        78.0333 24.6001 80.2779 24.6001 83.0366V87.2671C23.9684 86.978 23.2672 86.8162 22.5279 86.8162H10.5658C7.80705 86.8162 5.56249 89.06 5.56249 
        91.8196V97.0686H2.04382C1.23434 97.0686 0.578213 97.7247 0.578213 98.5342C0.578213 99.3437 1.23434 99.9998 2.04382 99.9998H88.4572C89.2667 99.9998 
        89.9228 99.3437 89.9228 98.5342C89.9228 97.7247 89.2667 97.0686 88.4572 97.0686H84.6456V59.2917C84.6456 56.5329 82.401 54.2884 79.6422 
        54.2884H70.7754V28.72C72.4004 26.5174 73.0024 23.6305 72.2478 20.887C72.1296 20.4559 71.8206 20.1019 71.4086 19.9272C71.2064 19.841 70.9898 19.8028 
        70.7754 19.8112V17.1356H88.458C89.3445 17.1356 90.1189 16.6 90.4317 15.7715C90.7445 14.9429 90.5172 14.0289 89.8526 13.443ZM46.5834 25.1785C46.723 
        24.7459 47.0221 24.3934 47.4264 24.1867L48.7379 23.5153C48.9851 23.3886 49.2491 23.3284 49.51 23.3284C50.128 23.3284 50.7254 23.6663 51.026 
        24.253L52.7227 27.5649C53.1187 28.3386 52.8624 29.2762 52.1582 29.75L51.6806 30.0086L50.6727 30.5251C49.8388 30.9524 48.8119 30.6213 48.3847 
        29.7866L46.6879 26.4755C46.4811 26.0711 46.4438 25.6103 46.5834 25.1785ZM43.6384 78.4842C43.1402 78.2568 42.5985 78.1081 42.0294 78.0554L43.6384 
        75.9184V78.4842ZM50.5972 66.6747C51.3021 65.8301 51.4967 64.8436 51.6539 64.0486C51.6935 63.8594 52.4168 59.9089 52.4168 59.9089C52.4168 59.9089 
        53.1012 60.3354 53.1729 60.3659C53.2225 60.3881 53.2576 60.4369 53.2629 60.4941L54.0602 68.5988H49.1484L50.5972 66.6747ZM59.2642 58.5852L58.0267 
        68.5919H57.0051L56.1804 60.2072C56.0751 59.133 55.4174 58.1954 54.4538 57.7292L52.9798 56.7946L53.9617 51.3526C53.9617 51.3526 58.0984 56.2667 
        58.1945 56.3918C59.0505 57.5049 59.3435 57.9505 59.2642 58.5852ZM43.4332 65.6783C43.7292 64.7872 43.8666 63.88 43.8437 62.9576L44.3762 53.1798L51.1549 
        50.402L49.9228 57.2272C49.9189 57.2455 49.9151 57.2646 49.9128 57.2829L48.8936 62.9264C48.7608 63.5108 48.6448 64.3973 48.2801 64.8787L38.3764 
        78.0333H37.5601L43.4332 65.6783ZM8.49369 91.8188C8.49369 90.6767 9.42295 89.7474 10.5658 89.7474H22.5279C23.6708 89.7474 24.6008 90.6767 24.6008 
        91.8188V97.0686H8.49369V91.8188ZM27.5313 91.8188V83.0366C27.5313 81.8938 28.4613 80.9645 29.6042 80.9645H41.5663C42.7092 80.9645 43.6384 81.8938 
        43.6384 83.0366V97.0686H27.532V91.8188H27.5313ZM81.7144 59.2917V97.0686H65.608V88.2811C65.608 87.4716 64.9519 86.8155 64.1424 86.8155C63.3322 
        86.8155 62.676 87.4716 62.676 88.2811V97.0686H46.5696V73.6014C46.5696 72.4593 47.4989 71.53 48.6418 71.53H60.6039C61.7468 71.53 62.676 72.4593 
        62.676 73.6014V80.9485C62.676 81.758 63.3322 82.4141 64.1416 82.4141C64.9519 82.4141 65.6072 81.758 65.6072 80.9485V59.2917C65.6072 58.1488 66.5373 
        57.2196 67.6801 57.2196H69.2945C69.2991 57.2196 69.3044 57.2203 69.3098 57.2203C69.3144 57.2203 69.3197 57.2196 69.3243 57.2196H79.6415C80.7844 
        57.2196 81.7144 58.1496 81.7144 59.2917ZM67.6801 54.2884C64.9214 54.2884 62.6768 56.5329 62.6768 59.2917V69.0497C62.1519 68.8101 61.5797 68.6583 60.9777 
        68.6141L62.1732 58.9461C62.4082 57.0617 61.4469 55.8127 60.5177 54.6042C60.4193 54.4761 54.7224 47.7118 54.7224 47.7118L58.2174 37.1452L67.8442 
        31.2965V54.2884H67.6801ZM70.7754 14.2036V5.15746H86.2806L82.9412 8.09782C82.4865 8.49837 82.2256 9.07515 82.2256 9.68092C82.2256 10.2867 82.4865 
        10.8635 82.9412 11.264L86.2806 14.2044H70.7754V14.2036Z"
        fill="#707070"
      />
    </svg>
  );
}
