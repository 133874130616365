import React, { useContext, useEffect, useState, useRef } from 'react';
import { observer, inject } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import FormatUtil from '../../utils/formatUtil';
import MitreContext from '../../contexts/MitreContext';
import TechniqueCard from './TechniqueCard';
import ContentCard from '../Card/ContentCard';
import Collapsible from '../Collapsible/Collapsible';
import { StatusIcon } from '../ContentStatus/StatusLegend';
import ContentTransformer from '../../transformers/contentTransformer';
import TechniquePathTable from './TechniquePathTable';
import { getOptions, handleEnroll } from '../../utils/pathsUtil';

const CodeComponent = {
  // Add generic gray background for inline code blocks
  code({ children }) {
    return <code className="p-1 text-xs bg-gray-200">{children}</code>;
  },
};

function SubTechniqueCards({ technique, data }) {
  const { setTechniqueData } = useContext(MitreContext);
  if (!data || !data.length) {
    return null;
  }

  return (
    <div className="mb-8">
      <h3 className="mb-4 text-2xl font-black">Sub-Techniques</h3>
      <div className="flex flex-wrap gap-x-4 gap-y-2">
        {data.map((subTechnique) => {
          const buttonId = `${FormatUtil.lowerCaseHyphenText(technique.name)}_${FormatUtil.lowerCaseHyphenText(subTechnique.name)}`;
          return (
            <TechniqueCard
              key={subTechnique.id}
              data={subTechnique}
              wrapperClasses="w-full sm:w-60 shrink-0"
              buttonId={buttonId}
              handleClick={() => setTechniqueData(subTechnique)}
              isButtonDisabled={false}
            />
          );
        })}
      </div>
    </div>
  );
}

function TechniqueDescription({ data }) {
  const [showDescription, setShowDescription] = useState(false);
  if (!data || !data.description || !data.description.length) {
    return null;
  }
  useEffect(() => {
    // If the data changes (going in/out of subtechniques), collapse description
    setShowDescription(false);
  }, [data]);

  // Should only show first paragraph of description
  const paragraphs = data.description.split('\n\n');
  const p1 = paragraphs[0];
  const otherPs = paragraphs.slice(1, paragraphs.length);
  // Replace <code></code> to use back ticks for markup
  const paragraph1 = p1.replace(/<\/?code>/g, '`');

  return (
    <div className="mb-8">
      <ReactMarkdown className="mb-4 text-sm font-normal has-markdown-inside secondary-links no-p-margin" linkTarget="_blank" components={CodeComponent}>
        {paragraph1}
      </ReactMarkdown>
      {!!otherPs && !!otherPs.length && (
        <>
          <Collapsible open={showDescription}>
            <ReactMarkdown className="mb-4 text-sm font-normal has-markdown-inside secondary-links" linkTarget="_blank" components={CodeComponent}>
              {otherPs.join('\n\n')}
            </ReactMarkdown>
          </Collapsible>

          <button onClick={() => setShowDescription(!showDescription)} className="text-sm hover:text-cyb-pink-500 underline">
            View {showDescription ? 'less' : 'more'}
            <span className="sr-only"> info on {data.name}</span>
          </button>
        </>
      )}
    </div>
  );
}

function SubTechniqueLabel({ title }) {
  return (
    title && (
      <div className="px-2 mt-4">
        <span className="p-1 text-xs text-gray-600 rounded border-xs">{title}</span>
      </div>
    )
  );
}

function TechniqueContent({ data }) {
  if (!data || (data.courses && !data.courses.length && data.comingSoonCourses && !data.comingSoonCourses.length)) {
    return null;
  }

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-2xl font-black">Content</h3>
        <span className="flex grow-0 items-center py-1 px-2 w-max text-2xs uppercase rounded-sm border-xs border-gray-400">
          Coming Soon <StatusIcon status="comingSoon" />
        </span>
      </div>
      <div className="flex flex-wrap gap-4">
        {!!data.courses.length &&
          data.courses.map((course) => {
            const showSubTechniqueLabel = !data.x_mitre_is_subtechnique && course.subTechnique;
            return (
              <ContentCard key={course.id} className="w-full sm:w-60" cardProps={{ menuAbove: true }} data={ContentTransformer.formatDataSources(course)}>
                {showSubTechniqueLabel && <SubTechniqueLabel title={course.subTechnique} />}
              </ContentCard>
            );
          })}
        {!!data.comingSoonCourses.length &&
          data.comingSoonCourses.map((comingSoonCourse) => {
            // If this is a technique and the content is for one of the child subtechniques, show a label with that subtechnique name
            const showSubTechniqueLabel = !data.x_mitre_is_subtechnique && comingSoonCourse.subTechnique;
            return (
              <ContentCard
                key={comingSoonCourse.id}
                className="w-full sm:w-60"
                isComingSoon
                cardProps={{ menuAbove: true }}
                data={ContentTransformer.formatDataSources(comingSoonCourse)}
              >
                {showSubTechniqueLabel && <SubTechniqueLabel title={comingSoonCourse.subTechnique} />}
              </ContentCard>
            );
          })}
      </div>
    </div>
  );
}

const MitreTechniqueContent = inject(
  'userStore',
  'commonStore',
  'enrollmentStore'
)(
  observer(({ data, focusOnDataChange, userStore, commonStore, enrollmentStore }) => {
    const navigate = useNavigate();
    const headingRef = useRef();
    useEffect(() => {
      if (focusOnDataChange && data && headingRef && headingRef.current) {
        // Set focus on the header
        setTimeout(() => {
          headingRef?.current?.focus();
        }, 300);
      }
    }, [data]);

    if (!data) {
      return null;
    }

    const isSubTechnique = data.x_mitre_is_subtechnique;
    return (
      <div>
        <p className="mb-2 text-2xs font-semibold text-gray-600 uppercase">
          {`${isSubTechnique ? 'Sub-' : ''}Technique`} | {data.tId}
        </p>
        <h2 ref={headingRef} tabIndex="-1" className="mb-4 text-2xl font-black">
          {data.name}
        </h2>
        <TechniqueDescription data={data} />
        <SubTechniqueCards technique={data} data={data.sub_techniques} />
        <TechniqueContent data={data} />
        <TechniquePathTable
          technique={data}
          userStore={userStore}
          commonStore={commonStore}
          enrollmentStore={enrollmentStore}
          navigate={navigate}
          getOptions={getOptions}
          handleEnroll={handleEnroll}
          useMiniRow
        />
      </div>
    );
  })
);

export default MitreTechniqueContent;
