import React from 'react';
import Loading from './Loading';
import StyledError from '../Error/StyledError';

export default function PageLoadingState({ loading, error, children }) {
  if (loading) {
    return (
      <div className="py-20">
        <Loading message="Loading..." messageClassName="pt-2 text-2xl" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="py-20 text-center">
        <StyledError error={error} />
      </div>
    );
  }
  return children || null;
}
