import React, { useRef, useState } from 'react';
import Tooltip from '../Tooltip/Tooltip';
import { copyToClipboard } from '../../utils/actionsUtil';
import gaUtil from '../../utils/gaUtil';

/**
 * Share Link component with a copy button and a popup message/focus on click
 * @param {string} shareUrl - the url to share
 * @param {string} successText - the text to display in the popup message
 * @returns
 */
function ShareLink({ shareUrl, successText = 'Copied Link!' }) {
  const [popUpOpen, setPopUpOpen] = useState(false);
  const inputField = useRef(null);

  // Displays a 5 second Popup message once Copied button is clicked.
  const queuePopupDisplayCleanup = () => {
    setTimeout(() => {
      setPopUpOpen(false);
    }, 5000);
  };

  // Copy to clipboard handler
  const copy = () => {
    setPopUpOpen(true);
    copyToClipboard(shareUrl);
    // Google Analytics Event. Title = shareUrl
    gaUtil.fireEvent(`Share link`, 'Product Interaction', shareUrl);
    // Focus inputField and select all text
    inputField.current.focus();
    inputField.current.select();
  };

  return (
    <div className="flex mx-4 mt-2">
      <input id="copyToClipboard" type="text" defaultValue={shareUrl} className="px-4" style={{ width: '100%', borderRadius: '5px' }} ref={inputField} />
      <Tooltip
        size="small"
        omitTabIndex
        triggerContent={
          <input
            type="button"
            value="Copy"
            onClick={copy}
            className="flex-1 py-2.5 px-6 ml-2 text-sm font-bold leading-5
                  text-center text-black hover:text-black bg-gray-200 hover:bg-gray-300 rounded-sm border-0 cursor-pointer"
          />
        }
        content={successText}
        trigger="click"
        open={popUpOpen}
        onShown={() => queuePopupDisplayCleanup()}
        position="top"
      />
    </div>
  );
}

export default ShareLink;
