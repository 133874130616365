import { useState, useEffect } from 'react';
import Bugsnag from '@bugsnag/js';
import Agents from '../agents/agents';
import FormatUtil from '../utils/formatUtil';

let pollingRef = null;
const defaultCsvName = 'import_template';

const useAdminBulkImport = (routesObject, importType) => {
  const [imports, setTeamImports] = useState(null);

  const getTemplateCSV = (templateName, teamId) => {
    return Agents[routesObject].getImportTemplate(importType, teamId).then((response) => {
      FormatUtil.downloadCSV(response, `${templateName || defaultCsvName}.csv`);
    });
  };

  const getTeamImports = (team) => {
    return Agents[routesObject].getImports(team, importType);
  };

  const checkActiveImports = (data) => {
    if (data.length) {
      const activeImports = data.filter((item) => {
        return ['pending', 'in_progress', 'validated'].indexOf(item.status) > -1;
      });
      return !!activeImports.length;
    }
    return false;
  };

  const watchImports = async (team) => {
    try {
      const data = await getTeamImports(team);
      setTeamImports(data);
      const isActiveImport = checkActiveImports(data);
      if (isActiveImport) {
        if (!pollingRef) {
          pollingRef = setInterval(() => {
            watchImports(team);
          }, 5000); // 5 secs
        }
      } else {
        // No active imports -- end polling if any exists
        clearInterval(pollingRef);
        pollingRef = null;
      }
    } catch (e) {
      Bugsnag.notify(e);
    }
  };

  const startImport = (team, data, dataKey) => {
    const formData = new FormData(); // Back end is expecting form data
    formData.append(dataKey, data.csv);
    return Agents[routesObject].postImport(team, formData, importType);
  };

  const getPreviousImportCSV = (team, id, date, templateName) => {
    return Agents[routesObject].getImportCSV(team, id, importType).then((response) => {
      FormatUtil.downloadCSV(response, `${templateName || defaultCsvName}_${date}.csv`);
    });
  };

  useEffect(() => {
    return () => {
      clearInterval(pollingRef);
      pollingRef = null;
    };
  }, []);

  return { getTemplateCSV, getTeamImports, imports, startImport, watchImports, getPreviousImportCSV };
};

export default useAdminBulkImport;
