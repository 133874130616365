import React from 'react';
import Modal from '../Modal/Modal';
import DynamicForm from '../DynamicForm/DynamicForm';

function FirstStep({ setStep }) {
  const customYesNoRenderer = ({ label, checked }) => {
    if (!['Yes', 'No'].includes(label)) return null;
    const buttonClasses = `rounded-sm px-4 py-2 border-1 border-cyb-pink-500 font-bold text-xs ${checked ? 'bg-cyb-pink-500 text-white' : 'bg-white text-cyb-pink-500'}`;
    return (
      <div role="button" tabIndex={0} className={buttonClasses} aria-selected={checked}>
        {label}
      </div>
    );
  };

  const feedbackForm = {
    id: 'platform-feedback-form',
    name: 'platform-feedback',
    order: ['satisfactionRating', 'whereImprove', 'whatImprove', 'contactUser', 'submit'],
    fields: {
      satisfactionRating: {
        id: 'platform-feedback-satisfaction-rating',
        type: 'ratingStars',
        label: 'How satisfied are you with Cybrary?',
        labelClass: 'font-bold',
        validations: ['required'],
        size: 'lg',
      },
      whereImprove: {
        id: 'platform-feedback-where-improve',
        label: 'Where can we improve?',
        type: 'select',
        defaultValue: '',
        placeholder: 'Select an option',
        wrapperClasses: 'w-1/2',
        options: [
          {
            text: 'Product Features/Capabilities',
            value: 'Product Features/Capabilities',
          },
          {
            text: 'Learning Experience/Content',
            value: 'Learning Experience/Content',
          },
          {
            text: 'Other',
            value: 'Other',
          },
        ],
        validations: ['required'],
      },
      whatImprove: {
        id: 'platform-feedback-what-improve',
        type: 'textarea',
        label: 'What can we improve? Please be as detailed as possible',
        validations: ['required'],
        maxLength: 500,
      },
      contactUser: {
        id: 'platform-feedback-contact-user',
        type: 'radio',
        label: 'Can a member of our Product or Content teams contact you for more information?',
        validations: ['required'],
        wrapperClassName: 'flex flex-row gap-4',
        options: [
          {
            label: 'Yes',
            value: 'yes',
          },
          {
            label: 'No',
            value: 'no',
          },
        ],
      },
      submit: {
        type: 'button',
        color: 'pink',
        label: 'Submit',
        className: 'mx-auto',
      },
    },
  };

  const handleSubmit = () => {
    /**
     * Ik... I get it... listen, we don't wanna store this anywhere in our backend,
     * we just wanna pass this along to snowplow. Snow plow will pick up the event in DynamicForm on submit
     * */
    setStep('step2');
  };
  return (
    <div className="pt-4 mx-8 mt-12">
      <h1 id="platform-feedback-modal-title" className="mb-4 text-4xl font-black text-center">
        Share your feedback and help use make your learning experience better!
      </h1>
      <div className="p-6 rounded-2xl border-1 border-black">
        <DynamicForm
          onSubmit={handleSubmit}
          form={feedbackForm}
          customRenderers={{
            contactUser: customYesNoRenderer,
          }}
        />
      </div>
    </div>
  );
}

function SecondStep({ handleClose }) {
  return (
    <div className="flex flex-col justify-center items-center pt-4 mt-7">
      <h1 id="platform-feedback-modal-title" className="mb-7 text-4xl font-black text-center">
        Thank you for your feedback!
      </h1>
      <button className="py-3 px-7 text-sm font-extrabold text-white bg-cyb-pink-500 rounded-sm" onClick={() => handleClose()}>
        Close
      </button>
    </div>
  );
}

function PlatformFeedBackModal({ isOpen, handleClose, step, setStep }) {
  return (
    <Modal open={isOpen} toggle={() => handleClose()} ariaLabelledBy="platform-feedback-modal-title" position="center">
      {step === 'step1' && <FirstStep setStep={setStep} />}
      {step === 'step2' && <SecondStep handleClose={handleClose} />}
    </Modal>
  );
}
export default PlatformFeedBackModal;
