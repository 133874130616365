import React from 'react';
import { observer } from 'mobx-react';
import Title from '../Title/Title';
import Loading from '../Loading/Loading';
import GraphTableToggle from '../Analytics/GraphTableToggle';
import PrintToPdfButton from '../PrintToPdfButton/PrintToPdfButton';
import StyledError from '../Error/StyledError';
import Label from '../Label/Label';
import FormatUtil from '../../utils/formatUtil';
import Container from '../Container/Container';

const labelClasses = 'text-xs rounded-sm p-1';

export default observer(
  class OrganizationDashboardHeading extends React.Component {
    getContentOverview = (shortDesc, longDesc) => {
      if (!shortDesc && !longDesc) {
        return null;
      }
      const desc = shortDesc || FormatUtil.formatLongText(longDesc);
      return (
        <p className="pb-4 text-gray-600">
          <span className="font-semibold text-black">Overview:</span> {desc}
        </p>
      );
    };

    getSkillGroups = (groups) => {
      return groups.map((group) => {
        if (/^Skillset\|/.test(group)) {
          const skillLabel = group.split('|')[1];
          if (skillLabel.length && skillLabel !== '') {
            return (
              <Label color="gray" className={labelClasses} key={skillLabel}>
                {skillLabel}
              </Label>
            );
          }
        }
        return null;
      });
    };

    render() {
      const { data, loading, error, setGraphView, graphView } = this.props;
      if (loading) {
        return (
          <Container>
            <Loading message="Loading..." />
          </Container>
        );
      }
      if (error) {
        return (
          <Container>
            <StyledError error={error} />
          </Container>
        );
      }
      const modalityType = data.content_type && data.content_type.nice_name ? FormatUtil.getModalityMeta(data.content_type.nice_name) : null;
      return (
        <div className="header-container" style={{ borderBottom: '1px solid #CCCCCC', paddingBottom: '3rem' }}>
          <Title title={data.title} />
          {this.getContentOverview(data.short_description, data.long_description)}
          <div className="flex gap-x-2 content-labels-container">
            {data.level && data.level.name ? (
              <Label color="gray" className={labelClasses}>
                {data.level.name}
              </Label>
            ) : null}
            {data.vendor && data.vendor.name ? (
              <Label color="gray" className={labelClasses}>
                {data.vendor.name}
              </Label>
            ) : null}
            {modalityType.type ? (
              <Label color="gray" className={labelClasses}>
                {modalityType.type}
              </Label>
            ) : null}
            {data.groups_info && data.groups_info.length ? this.getSkillGroups(data.groups_info) : null}
          </div>
          <div className="flex gap-x-4 items-center mt-4">
            <PrintToPdfButton
              color="gray"
              container=".dashboard"
              className="shrink"
              margin={[10, 40, 10, 40]}
              filename={`${FormatUtil.slugify(data.title)}_dashboard.pdf`}
              html2canvas={{ scale: 1, dpi: 300 }}
              jsPDF={{ unit: 'px', format: [1920, 2380], orientation: 'portrait' }}
            />
            <GraphTableToggle onChange={setGraphView} checked={graphView} />
          </div>
        </div>
      );
    }
  }
);
