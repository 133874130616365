import React from 'react';
import Inbox from '../../Icons/Inbox';

function EmptyContent({ header, children }) {
  return (
    <div className="mb-40 text-center">
      <Inbox classes="mx-auto w-48 h-48 mb-8 text-gray-500 fill-current" />
      <h2 className="mb-8 text-2xl font-black">{header || 'Nothing Here Yet...'}</h2>
      {children}
    </div>
  );
}

export default EmptyContent;
