import React from 'react';
import { Fade } from 'react-awesome-reveal';
import Collapsible from 'react-collapsible';
import Badge from './Badge';
import If from '../../../If/If';
import { useBadgeModal } from '../../../../providers/BadgeModalProvider';
import Button from '../../../Button/Button';

/**
 * Profile page badges tab
 * @param {array} badges - array of objects with the users badges (earned and unearned)
 * @returns <BadgesTab /> the new profile page tab
 */
function BadgesTab({ badges = [] }) {
  // Open/Close badge accordion by type isAccordionOpen[type] = true/false
  const [isAccordionOpen, setIsAccordionOpen] = React.useState({});
  const openAccordion = (type) => setIsAccordionOpen({ ...isAccordionOpen, [type]: true });
  const closeAccordion = (type) => setIsAccordionOpen({ ...isAccordionOpen, [type]: false });
  // Get unique types for badges to create each section
  const badgeTypes = badges.map(({ type }) => type);
  const uniqueBadgeTypes = [...new Set(badgeTypes)];
  const { open } = useBadgeModal();
  return (
    <Fade duration={500} damping={0.1} triggerOnce cascade>
      <div className="mb-24 w-full">
        {/** Empty State */}
        <If condition={badges.length === 0}>
          <div className="flex flex-col justify-center items-center w-full h-32">
            <h2 className="mb-4 text-xl font-bold">No Badges Earned</h2>
          </div>
        </If>
        {/** Categories / Types Sections */}
        {uniqueBadgeTypes.map((type) => {
          // Get all badges for this type
          const badgesForType = badges.filter((badge) => badge.type === type);
          // Sort by creation date
          badgesForType.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
          const numBadgesForType = badgesForType.length;
          const numBadgesEarnedForType = badgesForType.filter((badge) => badge.completed_at).length;
          // Get the first n badges to show in the header
          const BADGES_IN_HEADER = 4;
          const badgesForHeader = badgesForType.slice(0, BADGES_IN_HEADER);
          const badgesForAccordion = badgesForType.slice(BADGES_IN_HEADER);
          // Define shared props for the badges in the header and the badges in the accordion
          const toggleAccordionHandler = isAccordionOpen[type] ? closeAccordion : openAccordion;
          const toggleAccordionText = isAccordionOpen[type] ? 'View Less' : `View ${badgesForAccordion?.length} More`;
          const toggleAccordionIcon = isAccordionOpen[type] ? 'chevron-up' : 'chevron-down';
          const badgeWrapperClasses = 'sm:w-1/2 lg:w-1/4 px-2 pb-2';
          const badgeProgressWrapperClasses = 'bottom-6 px-6';
          const badgeClasses = 'pb-8';
          return (
            <div key={`badge-${type}`} className="mb-8">
              {/** Category / Type Header */}
              <h2 className="flex items-center mb-4 text-2xl font-bold">
                {type}
                <span className="ml-2 text-sm text-gray-600">
                  ({numBadgesEarnedForType}/{numBadgesForType})
                </span>
              </h2>
              {/** Badges Container */}
              <div className="flex flex-wrap w-full badge-container" id={`badge-container-${type}`}>
                {/** Header Badge section */}
                {badgesForHeader.map((badge) => {
                  return (
                    <Badge
                      key={`badge-${badge.id}`}
                      badge={badge}
                      onClick={() => open(badge)}
                      wrapperClassName={badgeWrapperClasses}
                      className={badgeClasses}
                      progressWrapperClassName={badgeProgressWrapperClasses}
                      showProgress
                    />
                  );
                })}
                {/** Collapsible Badge section */}
                <Collapsible
                  transitionTime={200}
                  open={isAccordionOpen[type]}
                  onOpening={() => openAccordion(type)}
                  onClosing={() => closeAccordion(type)}
                  className="w-full"
                  openedClassName="w-full"
                >
                  {badgesForAccordion.map((badge) => {
                    /** IMPORTANT - Only allow tabbing if this accordion is open */
                    const tabIndex = !isAccordionOpen[type] ? '-1' : '';
                    return (
                      <Badge
                        key={`badge-${badge.id}`}
                        badge={badge}
                        onClick={() => open(badge)}
                        tabIndex={tabIndex}
                        showProgress
                        wrapperClassName={badgeWrapperClasses}
                        className={badgeClasses}
                        progressWrapperClassName={badgeProgressWrapperClasses}
                      />
                    );
                  })}
                </Collapsible>
                {/** Toggle Accordion Button */}
                <If condition={badgesForAccordion.length > 0}>
                  <div className="flex justify-center items-center w-full">
                    <Button
                      color="transparent-gray"
                      icon={{ name: toggleAccordionIcon, position: 'left' }}
                      onClick={() => toggleAccordionHandler(type)}
                      className="justify-center py-1 mx-2 w-full font-semibold"
                    >
                      {toggleAccordionText}
                    </Button>
                  </div>
                </If>
              </div>
            </div>
          );
        })}
      </div>
    </Fade>
  );
}

export default BadgesTab;
