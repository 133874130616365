import React from 'react';
import { PADDLE_DISCOUNT_ID_CIP_NOV_PROMO } from '../../../constants';
import Icon from '../../Icon/Icon';

const NOV20_OFF_PROMO = {
  // Enable or disable the promo
  isEnabled: true,

  // Set the priority of the promo. Higher number = shown first.
  priority: 1,

  // Enable or disable the ability to dismiss the promo and delay the ability to re-show it
  isDismissible: false,
  delayOnDismiss: 60, // Time in minutes before the promo can be shown again after being dismissed
  dismissedKey: 'nov20OffDismissed', // Unique key to identify the promo in the cookie when dismissed

  // Set the start and expiration date of the promo
  startDate: '2024-11-01T00:00:00-05:00',
  endDate: '2024-12-01T00:00:00-05:00',

  // Show the promo on specific pages. Uses regex matching on the current pathname
  isShownOnPages: ['/'],

  // Show the promo based on the user's role/state
  isShownTeamLearner: false,
  isShownTeamAdmin: false,
  isShownTeamOwner: false,
  isShownCipFree: true,
  isShownCipMonthly: false,
  isShownCipAnnual: false,
  isShownCipBiennial: false,
  isShownCipEduGovMilFree: false,
  isShownCipEduGovMilMonthly: false,
  isShownCipEduGovMilAnnual: false,
  isShownCipEduGovMilBiennial: false,

  // Set Snowplow tracking labels
  spLabel: 'upgradeButtonNov20Off',
  spCategory: 'CIPUpgradeBanner',

  // Configure CTA Link
  targetUrl: `/upgrade/checkout/annual?discountId=${PADDLE_DISCOUNT_ID_CIP_NOV_PROMO}`,

  // Configure the attributes of the banner
  title: (
    <>
      <span className="text-xl group-hover:border-b-2">Save 20%</span> on a CIP subscription today!
    </>
  ),
  body: <>Turbocharge your learning with our virtual labs and hands-on assessments</>,
  footer: (
    <>
      Valid until November 30. <Icon name="growth" className="mx-2 w-4 h-4 fill-white" /> Prove you’re ready for the next level today!
    </>
  ),
  iconName: 'rocket',
  bannerClassName: 'from-cyb-purple-500 to-cyb-purple-500',
};

export default NOV20_OFF_PROMO;
