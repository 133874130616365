import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import withRouter from '../../components/Router/withRouter';

import Loading from '../../components/Loading/Loading';
import Message from '../../components/Message/Message';
import PaddleStep from './PaddleStep';
import AddLink from '../../components/AddLink/AddLink';
import { PaddleUpdateSubscriptionProvider, UPDATE_STEPS, usePaddleUpdateSubscription } from '../../providers/PaddleUpdateSubscriptionProvider';
import FormatUtil from '../../utils/formatUtil';
import { BASE_BUTTON_STYLES, PADDLE_PRICE_ID_CIP_ANNUAL, SECONDARY_BUTTON_STYLES } from '../../constants';
import useQueryParams from '../../hooks/useQueryParams';
import OrderSummaryUpgrade from './OrderSummary/OrderSummaryUpgrade';
import If from '../../components/If/If';
import Icon from '../../components/Icon/Icon';
import PaddleCheckoutHeader from './PaddleCheckoutHeader';
import Button from '../../components/Button/Button';
import WidgetContainer from '../../components/Container/WidgetContainer';

/**
 * Gets page title based on update step
 * @param {string} updateStep - Current update step
 * @returns {string} - Page title
 */
function getPageTitle(updateStep) {
  switch (updateStep) {
    case UPDATE_STEPS.CONFIRMATION:
      return 'Confirm Upgrade | Cybrary';
    case UPDATE_STEPS.SUCCESS:
      return 'Success | Cybrary';
    case UPDATE_STEPS.ALREADY_ANNUAL:
      return 'Annual Subscription | Cybrary';
    default:
      return 'Update Subscription | Cybrary';
  }
}

/**
 * Gets page header based on update step
 * @param {string} updateStep - Current update step
 * @returns {string} - Page header
 */
function getPageHeader(updateStep) {
  switch (updateStep) {
    case UPDATE_STEPS.ALREADY_ANNUAL:
      return 'You already have an Annual subscription';
    case UPDATE_STEPS.SUCCESS:
      return 'Congratulations!';
    default:
      return 'Upgrade Your Cybrary Subscription';
  }
}

/**
 * This page is for updating an existing subscription in Paddle.
 * 1. Find subscription id from URL
 * 2. Get subscription details from Paddle
 * 3. (Optional) Get changes from user
 * 4. Get preview of changes from Paddle
 * 5. Display preview to user and confirm
 * 6. Update subscription in Paddle
 */
/**
 * PaddleUpgradeExistingSubscription Page to modify an existing subscription
 * Uses include adding discounts, changing billing cycle (monthly/annual), etc.
 */
const PaddleUpgradeExistingSubscription = withRouter(
  inject(
    'authStore',
    'userStore',
    'commonStore'
  )(
    observer(({ userStore, commonStore, authStore }) => {
      const { updateStep, currentSubscription, previewOfUpdatedSubscription, confirmChanges, isLoading, error, init } = usePaddleUpdateSubscription();

      const navigate = useNavigate();

      /** Initial handler */
      useEffect(() => {
        commonStore.setPageTitle(`Update Subscription | Cybrary`);
        authStore.fireAttributionEvent();
        commonStore.hidePrimaryNav();
        return () => {
          commonStore.showPrimaryNav();
        };
      }, []);

      /** Page title handler */
      useEffect(() => {
        commonStore.setPageTitle(getPageTitle(updateStep));
      }, [updateStep]);

      /** Reload Paddle Subscription when we have a user change */
      useEffect(() => {
        if (userStore?.user) {
          init();
        }
      }, [userStore?.user]);

      const billingPeriodStart = moment(currentSubscription?.current_billing_period?.starts_at).format('DD MMMM');
      const billingPeriodEnd = moment(currentSubscription?.current_billing_period?.ends_at).format('DD MMMM');
      const billingPeriodEndMDY = moment(currentSubscription?.current_billing_period?.ends_at).format('MMMM DD, YYYY');

      const currentMonthlyPrice = currentSubscription?.recurring_transaction_details?.totals?.total;
      const currentMonthlyPriceCurrencyCode = currentSubscription?.recurring_transaction_details?.totals?.currency_code;
      const currentMonthlyPriceFormatted = FormatUtil.convertCentsToDollars(currentMonthlyPrice, currentMonthlyPriceCurrencyCode);

      let previewAsMonthlyPrice = previewOfUpdatedSubscription?.recurring_transaction_details?.totals?.total;
      const previewAsMonthlyPriceCurrencyCode = previewOfUpdatedSubscription?.recurring_transaction_details?.totals?.currency_code;
      previewAsMonthlyPrice = previewAsMonthlyPrice ? previewAsMonthlyPrice / 12 : 0;

      const previewAsMonthlySavings = currentMonthlyPrice - previewAsMonthlyPrice;

      const previewAnnualSavings = previewAsMonthlySavings * 12;
      const previewAnnualSavingsFormatted = FormatUtil.convertCentsToDollars(previewAnnualSavings, previewAsMonthlyPriceCurrencyCode);

      const creditAmount = previewOfUpdatedSubscription?.update_summary?.credit?.amount;
      const creditAmountCurrencyCode = previewOfUpdatedSubscription?.update_summary?.credit?.currency_code;
      const creditAmountFormatted = FormatUtil.convertCentsToDollars(creditAmount, creditAmountCurrencyCode);

      const discountIdAnnualKey = `discountId.${PADDLE_PRICE_ID_CIP_ANNUAL}`;
      const { discountId, [`${discountIdAnnualKey}`]: discountIdAnnual } = useQueryParams();
      const hasDiscount = discountId || discountIdAnnual;

      const updatePaymentInfoHref = currentSubscription?.management_urls?.update_payment_method;

      return (
        <>
          {/** Title */}
          <PaddleCheckoutHeader>{getPageHeader(updateStep)}</PaddleCheckoutHeader>
          <div className="flex flex-col-reverse grow justify-center px-2 mx-auto max-w-screen-lg duration-300 ease-in-out md:flex-row">
            <div className="shrink-0 mx-auto w-full max-w-[550px]">
              {/** Loading Display */}
              {isLoading && (
                <div className="flex flex-col justify-center items-center w-full h-full">
                  <Loading />
                </div>
              )}
              {/** Error Display */}
              {error && (
                <Message
                  status="error"
                  className="mt-0"
                  msgBody={error.message}
                  msgHeader={`Error ${error.status || ''}`}
                  isDismissible
                  // onDismiss={() => clearCheckoutError()}
                />
              )}
              {/** Confirmation Step */}
              <PaddleStep isShown={updateStep === UPDATE_STEPS.CONFIRMATION}>
                <div className="flex flex-col space-y-6 font-bold align-middle">
                  <p className="mb-0">You are currently paying {currentMonthlyPriceFormatted}/month</p>
                  <If condition={previewAnnualSavings > 0}>
                    <p>By upgrading to a Cybrary Insider Pro Annual subscription {hasDiscount && 'with the current promo code,'} you will save:</p>
                    <p className="flex justify-center text-xl align-middle">{previewAnnualSavingsFormatted}/year</p>
                  </If>
                  <p>Act now to claim your savings and get credit for your current subscription!</p>
                  <div>
                    <div className="flex justify-between mb-4 w-full text-sm">
                      <div>{billingPeriodStart}</div>
                      <div>Today</div>
                      <div>{billingPeriodEnd}</div>
                    </div>
                    <div className="relative">
                      <div className="flex absolute justify-between w-full">
                        <div className="-mt-2 w-4 h-4 bg-gray-300 rounded-full" />
                        <div className="-mt-2 w-4 h-4 bg-[#0db350] rounded-full" />
                        <div className="-mt-2 w-4 h-4 bg-[#0db350] rounded-full" />
                      </div>
                      <div className="w-1/2 border-b-2 border-gray-300" />
                      <div className="mt-[-3px] ml-[50%] w-1/2 border-b-2 border-[#0db350]" />
                    </div>
                    <div className="flex justify-evenly w-full">
                      <div className="w-1/2 text-center text-gray-400">Used</div>
                      <div className="w-1/2 text-center text-[#0db350]">
                        Credit
                        <br />
                        {creditAmountFormatted}
                      </div>
                    </div>
                  </div>
                  <button className={BASE_BUTTON_STYLES} onClick={confirmChanges}>
                    Confirm and Upgrade
                  </button>
                  <If condition={updatePaymentInfoHref}>
                    <a className={SECONDARY_BUTTON_STYLES} href={updatePaymentInfoHref} target="blank">
                      Update Payment Method
                    </a>
                  </If>
                </div>
              </PaddleStep>
              {/** Success Step */}
              <PaddleStep isShown={updateStep === UPDATE_STEPS.SUCCESS}>
                <p className="text-xl text-black">You’re subscription has successfully been updated!</p>
                <div className="flex justify-center">
                  <Icon name="checkmark-circle" className="mb-4 w-16 h-16 text-cyb-pink-500" active />
                </div>
                <p className="text-xl text-black">You’re on your way to accelerating your career in cybersecurity!</p>
                <button className={BASE_BUTTON_STYLES} onClick={() => navigate('/')}>
                  Return to the Dashboard
                </button>
              </PaddleStep>

              {/** Already Annual Sub Step */}
              <PaddleStep isShown={updateStep === UPDATE_STEPS.ALREADY_ANNUAL}>
                <WidgetContainer>
                  <p className="text-xl text-black">You already have a Cybrary Insider Pro Annual subscription until:</p>
                  <p className="flex items-center text-xl">
                    <Icon name="checkmark-circle" className="mr-2 w-8 h-8 text-cyb-pink-500" active /> {billingPeriodEndMDY}
                  </p>
                  <p className="text-sm">We cannot process your upgrade at this time. Please check back 30 days before your renewal date.</p>
                </WidgetContainer>
                <div className="flex justify-center mt-4">
                  <Button onClick={() => navigate('/')} icon={{ name: 'chevron-left', className: 'mr-1 w-4 h-4 text-white' }}>
                    Return to the Dashboard
                  </Button>
                </div>
              </PaddleStep>
            </div>
            {/** Price Info Display */}
            <If condition={!isLoading && [UPDATE_STEPS.CONFIRMATION, UPDATE_STEPS.SUCCESS].includes(updateStep)}>
              <div className="m-12 mb-0 md:mb-12 md:w-full">
                <OrderSummaryUpgrade
                  checkoutData={previewOfUpdatedSubscription}
                  checkoutLoading={isLoading}
                  isTeams={false}
                  isSuccessScreenShown={updateStep === UPDATE_STEPS.SUCCESS}
                />
                <p className="py-4 text-xs font-normal text-center text-black">
                  If you have any issues with your transaction,
                  <br />
                  please visit{' '}
                  <AddLink to="https://paddle.net/" target="_blank" className="font-bold text-blue-500">
                    Paddle.net
                  </AddLink>{' '}
                  for assistance.
                </p>
              </div>
            </If>
          </div>
        </>
      );
    })
  )
);

/**
 * Container for Paddle Update Subscription
 * Main purposes is to init the PaddleUpdateSubscriptionProvider and mobX stores
 * @returns {ReactNode} - React component
 */

const PaddleUpgradeExistingSubscriptionPage = inject(
  'userStore',
  'commonStore'
)(
  observer(({ userStore, commonStore }) => {
    return (
      <PaddleUpdateSubscriptionProvider userStore={userStore} commonStore={commonStore}>
        <PaddleUpgradeExistingSubscription />
      </PaddleUpdateSubscriptionProvider>
    );
  })
);

export default PaddleUpgradeExistingSubscriptionPage;
