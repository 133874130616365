import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useQueryParams from '../../hooks/useQueryParams';
import agents from '../../agents/agents';
import Loading from '../../components/Loading/Loading';
import BugsnagUtil from '../../utils/bugsnagUtil';

/**
 * When a user logs into Discourse, they are redirected to this page.
 * This page will then call our backend to get the redirect URL for Discourse.
 * This is to get our JWT token into Discourse.
 * @returns <Loading /> - a loading spinner
 */
function DiscourseAuthRedirect() {
  const { sso, sig } = useQueryParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (sso && sig) {
      agents.discourse
        .auth({ sso, sig })
        .then(({ redirect }) => {
          // this request should redirect us to the discourse forum
          window.location.href = redirect;
        })
        .catch((err) => {
          navigate('/?discourseLoginError=failed');
          BugsnagUtil.notify(err);
        });
    } else {
      navigate('/?discourseLoginError=missingParams');
      BugsnagUtil.notify(new Error('discourseLoginMissingParams'));
    }
  }, [sso, sig]);

  return <Loading message="Logging Into Discourse..." wrapperClassName="pt-12" />;
}

export default DiscourseAuthRedirect;
