import React from 'react';
import { inject, observer } from 'mobx-react';
import Cookies from 'js-cookie';
import Header from '../Header/Header';
import Button from '../Button/Button';
import './survey-banner.css';
import Message from '../Message/Message';
import { DEFAULT_COOKIE_SETTINGS } from '../../constants';

const cookieParams = {
  ...DEFAULT_COOKIE_SETTINGS,
  path: '/',
  expires: 365,
};

const PostAssessmentSurvey = inject('userStore')(
  observer(
    class PostAssessmentSurvey extends React.Component {
      state = {
        visible: false,
        contentDescriptionId: null,
        completedSurveys: [],
      };

      componentDidMount() {
        const userId = this.props.userStore.user.id;
        const postAssessmentCookie = Cookies.get(`post-assessment-survey_${userId}`);
        const completedSurveysCookie = Cookies.get(`post-assessment-surveys-completed_${userId}`);
        const completedSurveysArr = completedSurveysCookie ? completedSurveysCookie.split(',') : [];
        if (postAssessmentCookie) {
          // If they haven't already completed a survey for this content ID, show the banner. Otherwise, clear the cookie
          if (completedSurveysArr.indexOf(postAssessmentCookie) === -1) {
            const newState = {
              ...this.state,
              visible: true,
              contentDescriptionId: postAssessmentCookie,
              completedSurveys: completedSurveysArr,
            };
            this.setState(newState);
          } else {
            this.handleDismiss();
          }
        }
      }

      launchSurvey = () => {
        const { contentDescriptionId, completedSurveys } = this.state;
        const userId = this.props.userStore.user.id;
        window.open(`https://www.surveymonkey.com/r/VSSSVPP?UserId=${userId}&ContentDescriptionId=${contentDescriptionId}`, '_blank');

        // Need to add this contentId to a cookie tracking that they already did a survey so not prompted again.
        completedSurveys.push(contentDescriptionId);
        const newState = {
          ...this.state,
          completedSurveys,
        };
        this.setState(newState, () => {
          this.handleDismiss();
        });
        Cookies.set(`post-assessment-surveys-completed_${userId}`, completedSurveys.join(','), cookieParams);
      };

      handleDismiss = () => {
        const userId = this.props.userStore.user.id;
        Cookies.remove(`post-assessment-survey_${userId}`, cookieParams);
        const newState = {
          ...this.state,
          visible: false,
        };
        this.setState(newState);
      };

      render() {
        if (!this.state.visible) {
          return null;
        }

        return (
          <Message onDismiss={() => this.handleDismiss()} icon="clipboard-list" className="post-assessment-survey-banner">
            <Header as="h3">Post-Assessment Survey</Header>
            <p>
              Thank you for completing our assessment. Please take a moment to tell us about your experience. Your feedback will help to ensure the quality and consistency of skill
              development on the Cybrary platform.
            </p>
            <Button color="gray" style={{ marginRight: '1em' }} onClick={() => this.handleDismiss()}>
              No Thanks
            </Button>
            <Button onClick={() => this.launchSurvey()}>Take Survey</Button>
          </Message>
        );
      }
    }
  )
);

export default PostAssessmentSurvey;
