import React from 'react';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, EmailShareButton } from 'react-share';
import { inject, observer } from 'mobx-react';
import Facebook from '../../Icons/Facebook';
import Twitter from '../../Icons/Twitter';
import Linkedin from '../../Icons/Linkedin';
import Email from '../../Icons/Email';
import Modal from '../Modal/Modal';
import { getFacebookText, getTwitterText, getEmailText, fetchUrl, handleGaEvent, getEmailSubject } from '../../utils/shareUtil';
import ShareProfileLink from './ShareProfileLink';
import If from '../If/If';
import ShareLink from './ShareLink';

const ShareModal = inject('commonStore')(
  observer(({ commonStore }) => {
    const { shareState } = commonStore;
    const { type, title, permalink, prefix, openShare, username } = shareState;
    const toggleShareDisplay = () => {
      commonStore.resetShareComponent();
      handleGaEvent(type, title);
    };

    const facebookText = getFacebookText(type, title);
    const twitterText = getTwitterText(type, title);
    const emailText = getEmailText(type, title);
    const emailSubject = getEmailSubject(type);
    const shareUrl = fetchUrl(prefix, type, permalink);
    const beforeOnClick = () => handleGaEvent(type, title);

    return (
      <Modal open={openShare} toggle={() => toggleShareDisplay()} ariaLabelledBy="share-modal" size="sm" position="center" className="share-container">
        <div className="pt-4">
          <h2 id="share-modal" className="my-2 text-2xl font-black text-center">
            Share
          </h2>
        </div>
        <div className="flex justify-evenly py-4">
          <FacebookShareButton url={shareUrl} quote={facebookText} beforeOnClick={beforeOnClick}>
            <Facebook classes="w-10 h-10 text-white" withRectangle />
          </FacebookShareButton>
          <TwitterShareButton url={shareUrl} title={twitterText} beforeOnClick={beforeOnClick}>
            <Twitter classes="w-10 h-10 text-white" withRectangle />
          </TwitterShareButton>
          <LinkedinShareButton url={shareUrl} beforeOnClick={beforeOnClick}>
            <Linkedin classes="w-10 h-10 text-white" withRectangle />
          </LinkedinShareButton>
          <EmailShareButton url={shareUrl} body={emailText} beforeOnClick={beforeOnClick} subject={emailSubject}>
            <Email classes="w-10 h-10 text-white" withRectangle />
          </EmailShareButton>
        </div>
        <If condition={username}>
          <div>
            <ShareProfileLink username={title} />
          </div>
        </If>
        <If condition={!username && shareUrl}>
          <div>
            <ShareLink shareUrl={shareUrl} />
          </div>
        </If>
      </Modal>
    );
  })
);

export default ShareModal;
