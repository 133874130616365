import React, { useEffect, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import AddLink from '../AddLink/AddLink';
import ProfileStreaksAndSocials from '../Profile/ProfileStreaksAndSocials/ProfileStreaksAndSocials';
import { useProfileContext } from '../../providers/ProfileProvider';
import If from '../If/If';
import Loading from '../Loading/Loading';
import DashboardWidgetTitle from './DashboardWidgetTitle';

function ProfileTitle() {
  return <DashboardWidgetTitle title="Profile" link="/profile" className="hidden mr-0 sm:mr-4 lg:flex lg:mr-0" />;
}

/**
 * Profile widget to show user profile image, name, and some stats about their profile
 * @param {string} className - additional classes to add to the component
 * @returns <ProfileWidget />
 */
const ProfileWidget = inject('userStore')(
  observer(({ userStore, className }) => {
    const { userProfileStats, user } = userStore;
    const { personalProfileData, isLoading } = useProfileContext();
    const { username } = personalProfileData || {};
    const { daily_activity_streak = 0, level, experience_points } = userProfileStats || {};

    useEffect(() => {
      (async () => {
        // reload userProfileStats whenever the component mounts
        await userStore.loadUserProfileStats();
      })();
    }, []);

    const { isEnterprise, isFree, isCip, isEnterpriseAdmin } = userStore || {};
    const licenseType = useMemo(() => {
      if (isEnterprise && !isFree) {
        return 'Enterprise';
      }
      if (isEnterprise && !isFree) {
        return 'Enterprise (Free)';
      }
      if (isCip) {
        return 'Insider Pro';
      }
      return 'Free';
    }, [isEnterprise, isFree, isCip, isEnterpriseAdmin]);
    const registeredAt = useMemo(() => moment(new Date(user?.registered_at)).format('MMMM Do, YYYY'), [user?.registered_at]);

    return (
      <div className={twMerge('p-2 lg:p-4 flex flex-col justify-evenly', className)}>
        {/** Loading State */}
        <If condition={isLoading && !personalProfileData}>
          <ProfileTitle />
          <Loading message="Loading profile..." wrapperClassName="w-full h-36 my-1" />
        </If>
        <If condition={personalProfileData}>
          <div className="flex items-center pb-0 w-full border-0 border-slate-200 lg:block lg:min-h-[80px] lg:border-b-2">
            <ProfileTitle />
            <div className="flex justify-start items-center w-full lg:mx-2 lg:mt-2 lg:mb-6">
              <AddLink to="/profile" title="View profile">
                <img
                  src={user?.avatar_url}
                  alt="avatar"
                  className="grow-0 mr-8 ml-4 w-16 h-16 rounded-full border border-cyb-pink-500
                  transition-transform duration-300 scale-125 hover:scale-105 lg:mr-4 lg:ml-0 lg:scale-100"
                />
              </AddLink>
              <div className="flex flex-col space-y-2">
                <If condition={username}>
                  <AddLink to="/profile" title="View profile">
                    <span className="font-bold text-cyb-pink-500 lg:text-xl text-md">@{username}</span>
                  </AddLink>
                </If>
                <div className="font-bold">{user?.real_name || 'No Name Set'}</div>
                <div className="flex space-x-4 text-xs lg:text-sm xs:space-x-1">
                  <span className="flex flex-col xs:flex-row xs:space-x-1 ">
                    <span className="font-semibold">License:</span>
                    <span className="font-normal whitespace-nowrap">{licenseType}</span>
                  </span>
                  <span className="hidden xs:block">|</span>
                  <span className="flex flex-col xs:flex-row xs:space-x-1 ">
                    <span className="font-semibold">Joined:</span>
                    <span className="font-normal whitespace-nowrap">{registeredAt}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center pt-4 h-12">
            <ProfileStreaksAndSocials currentStreak={daily_activity_streak} currentLevel={level} currentXp={experience_points || '0'} />
          </div>
        </If>
      </div>
    );
  })
);
export default ProfileWidget;
