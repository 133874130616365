import React, { useMemo } from 'react';
import ImageSection from './ImageSection';
import LabSection from './LabSection';
import If from '../../If/If';
import VimeoPlayer from '../../VimeoPlayer/VimeoPlayer';

// list of special characters that should not have a whitespace added before it,
// when rendering text via <RenderText /> component and `withTrailingWhiteSpace` is true
const SPECIAL_CHARACTERS = [',', '!', ')', '}', ']', ';', ':', '.'];

// Turn newlines into line breaks
function Nl2br({ str }) {
  if (!str) {
    return null;
  }
  if (typeof str !== 'string') {
    return str;
  }

  return (
    <>
      {str.split('\n').map((item, key) => {
        const spanKey = `${item}_${key}`;

        // Output a break if this is not the first element
        return (
          <span key={spanKey}>
            {key > 0 && <br />}
            {item}
          </span>
        );
      })}
    </>
  );
}

function makeSureHttps(url) {
  if (!url) {
    return url;
  }
  if (url.indexOf('http') !== 0) {
    return `https:${url}`;
  }
  return url.replace('http:', 'https:');
}

function textClassesFromMarks(marks) {
  let classes = '';
  for (let i = 0; i < marks.length; i++) {
    if (marks[i].type === 'bold') {
      classes = `${classes} font-bold`;
    }
    if (marks[i].type === 'italic') {
      classes = `${classes} italic`;
    }
    if (marks[i].type === 'underline') {
      classes = `${classes} underline`;
    }
    if (marks[i].type === 'code') {
      classes = `${classes} text-gray-100 text-sm my-4 bg-blue-900 py-2 px-4 leading-6 font-mono rounded-lg block whitespace-pre-wrap`;
    }
  }
  return classes;
}

// this is the text that goes within paragraphs, lists, h2's, etc.
function RenderText({ item, withTrailingWhiteSpace }) {
  const { value, marks } = item;
  if (value === null) {
    return null;
  }
  const classes = textClassesFromMarks(marks);
  return (
    <span className={classes}>
      <Nl2br str={value} />
      <If condition={withTrailingWhiteSpace}>
        <span> </span>
      </If>
    </span>
  );
}

// Links should only contain text
function LinkElement({ item }) {
  const { data, content } = item;
  const text = content.length ? content[0].value : null;
  if (!text) {
    return null;
  }
  return (
    <a rel="noopener noreferrer" target="_blank" className="leading-6 text-cyb-pink-500 hover:text-black underline cursor-pointer" href={data.uri}>
      {text}
    </a>
  );
}

// block quote block
function BlockquoteElement({ item, references, userStore }) {
  const { content } = item;
  return (
    <div className="pl-4 mb-4 min-h-[20px] text-gray-700 border-l-4 border-l-gray-300">
      <RenderLeaves items={content} references={references} userStore={userStore} />
    </div>
  );
}

// H1 - H4 components.  If we need others, we can add them easily.  Here you can match the styles as necessary to the existing UI.
function Header1Element({ item, references, userStore }) {
  const { content } = item;
  return (
    <h1 className="mt-6 mb-3 text-4xl font-bold">
      <RenderLeaves items={content} references={references} userStore={userStore} />
    </h1>
  );
}
function Header2Element({ item, references, userStore }) {
  const { content } = item;
  return (
    <h1 className="mb-4 text-3xl font-bold">
      <RenderLeaves items={content} references={references} userStore={userStore} />
    </h1>
  );
}
function Header3Element({ item, references, userStore }) {
  const { content } = item;
  return (
    <h1 className="mb-4 text-2xl font-bold">
      <RenderLeaves items={content} references={references} userStore={userStore} />
    </h1>
  );
}
function Header4Element({ item, references, userStore }) {
  const { content } = item;
  return (
    <h1 className="mb-4 text-xl font-bold">
      <RenderLeaves items={content} references={references} userStore={userStore} />
    </h1>
  );
}
function Header5Element({ item, references, userStore }) {
  const { content } = item;
  return (
    <h1 className="mb-4 text-lg font-bold">
      <RenderLeaves items={content} references={references} userStore={userStore} />
    </h1>
  );
}
function Header6Element({ item, references, userStore }) {
  const { content } = item;
  return (
    <h1 className="mb-4 text-base font-bold">
      <RenderLeaves items={content} references={references} userStore={userStore} />
    </h1>
  );
}

// Inner List Content
function InnerListItem({ li, references, userStore }) {
  // Handle nested ordered and unordered lists
  if (li?.nodeType === 'unordered-list') {
    return <UnorderedListElement item={li} userStore={userStore} references={references} />;
  }
  if (li?.nodeType === 'ordered-list') {
    return <OrderedListElement item={li} userStore={userStore} references={references} />;
  }
  if (!li.content?.length) {
    return (
      // eslint-disable-next-line react/no-array-index-key
      <RenderLeaves items={[li]} references={references} userStore={userStore} />
    );
  }
  return (
    // eslint-disable-next-line react/no-array-index-key
    <RenderLeaves items={li.content} references={references} userStore={userStore} />
  );
}

// Lists, should be simple to match styles to the existing standard
function OrderedListElement({ item, references, userStore }) {
  const { content } = item;
  if (!content || !content.length) {
    return null;
  }
  return (
    <ol className="pl-5 my-4 list-decimal list-outside">
      {content.map((p, i) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <li key={i} className="mb-4 text-black">
            {p.content.map((li, j) => (
              // eslint-disable-next-line react/no-array-index-key
              <InnerListItem li={li} key={`${i}_${j}`} references={references} userStore={userStore} />
            ))}
          </li>
        );
      })}
    </ol>
  );
}
function UnorderedListElement({ item, references, userStore }) {
  const { content } = item;
  if (!content || !content.length) {
    return null;
  }
  return (
    <ul className="pl-5 my-4 list-disc list-outside">
      {content.map((p, i) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <li key={i} className="mb-4 text-black">
            {p.content.map((li, j) => (
              // eslint-disable-next-line react/no-array-index-key
              <InnerListItem li={li} key={`${i}_${j}`} references={references} userStore={userStore} />
            ))}
          </li>
        );
      })}
    </ul>
  );
}

// The default component, a paragraph, match text color, size, margin, etc.
function Paragraph({ item, references, userStore }) {
  const { content } = item;
  return (
    <p className="mb-4 text-black">
      <RenderLeaves items={content} references={references} userStore={userStore} />
    </p>
  );
}

function getVideoId(url) {
  if (!url) {
    return null;
  }
  const parts = url.split('video/');
  if (parts.length === 2) {
    return parts[1];
  }
  return null;
}

// Displays the video
function VideoActivityBlock({ reference }) {
  const { content_item: contentItem } = reference;
  const { url } = contentItem || {};

  const videoId = getVideoId(url);
  if (!videoId) {
    return null;
  }

  return (
    <div className="my-4">
      <VimeoPlayer id="video-player-wrapper" responsive speed videoId={videoId} />
    </div>
  );
}

function Hr() {
  return <div className="my-8 w-full h-[1px] bg-gray-300" />;
}

// Renders embedded entries using the provided references
function EmbeddedEntryBlock({ item, references, userStore }) {
  const { data } = item;
  if (!data || !references) {
    return null;
  }
  const { target } = data;
  const { sys } = target || {};
  const { id } = sys || {};
  if (!id) {
    return null;
  }
  const reference = references[id];

  if (!reference) {
    return null;
  }

  const { thumbnail_url: thumbnailUrl, content_type: contentType, permalink } = reference;
  if (!contentType && thumbnailUrl) {
    // This is an image asset, render it
    return <ImageSection src={makeSureHttps(thumbnailUrl)} alt="Embedded image" smallBorder noX />;
  }

  // Render a video
  if (contentType?.name === 'video_activity') {
    return <VideoActivityBlock reference={reference} />;
  }

  // Render a lab
  if (contentType && permalink) {
    return <LabSection permalink={permalink} userStore={userStore} />;
  }

  return null;
}

// Passed content of other elements, loops over them, rendering leaf nodes (RenderText)
// or full nodes (ItemSwitch) depending on the value of the nodeType property
function RenderLeaves({ items, references, userStore, textProps = null }) {
  return (
    <>
      {items.map((child, i) => {
        const { nodeType } = child;
        if (nodeType !== 'text') {
          // eslint-disable-next-line react/no-array-index-key
          return <ItemSwitch key={i} item={child} references={references} userStore={userStore} />;
        }
        const withTrailingWhiteSpace = useMemo(() => {
          // check if child node is last in array
          const isLastChild = i === items.length - 1;
          // check if the next child node starts with a special character in which
          // a whitespace should not be added before it"
          const nextChild = items[i + 1];
          let startsWithSpecialChar = false;
          if (nextChild) {
            startsWithSpecialChar = SPECIAL_CHARACTERS.includes(nextChild.value?.charAt(0));
          }

          return !isLastChild && !startsWithSpecialChar;
        }, [i]);
        // eslint-disable-next-line react/no-array-index-key
        return <RenderText key={i} item={child} textProps={textProps} withTrailingWhiteSpace={withTrailingWhiteSpace} />;
      })}
    </>
  );
}

// Switch for rendering an element based on type, which is present on nodes that are NOT leaves, aka they
// can wrap other nodes (an h1 could have text in it that is in italics, for instance)
function ItemSwitch({ item, references, userStore }) {
  const { nodeType } = item;

  switch (nodeType) {
    case 'embedded-entry-block':
      return <EmbeddedEntryBlock item={item} references={references} userStore={userStore} />;

    case 'embedded-asset-block':
      return <EmbeddedEntryBlock item={item} references={references} userStore={userStore} />;

    case 'heading-1':
      return <Header1Element item={item} references={references} userStore={userStore} />;

    case 'heading-2':
      return <Header2Element item={item} references={references} userStore={userStore} />;

    case 'heading-3':
      return <Header3Element item={item} references={references} userStore={userStore} />;
    case 'heading-4':
      return <Header4Element item={item} references={references} userStore={userStore} />;
    case 'heading-5':
      return <Header5Element item={item} references={references} userStore={userStore} />;
    case 'heading-6':
      return <Header6Element item={item} references={references} userStore={userStore} />;

    case 'hyperlink':
      return <LinkElement item={item} />;

    case 'ordered-list':
      return <OrderedListElement item={item} references={references} userStore={userStore} />;

    case 'unordered-list':
      return <UnorderedListElement item={item} references={references} userStore={userStore} />;

    case 'blockquote':
      return <BlockquoteElement item={item} references={references} userStore={userStore} />;

    case 'paragraph':
      return <Paragraph item={item} references={references} userStore={userStore} />;

    case 'hr':
      return <Hr />;

    default:
      return null;
  }
}

export default function RichTextRenderer({ content, references, userStore }) {
  if (!content || !content.length) {
    return null;
  }

  return (
    <div className="">
      {content.map((item, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <ItemSwitch item={item} key={i} references={references} userStore={userStore} />
      ))}
    </div>
  );
}
