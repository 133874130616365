import React, { useState, useEffect } from 'react';
import CountUp from 'react-countup';
import SearchUtil from '../../utils/searchUtil';
import FormatUtil from '../../utils/formatUtil';
import MetricsSettings from './MetricsSettings';
import AddLink from '../AddLink/AddLink';
import DoubleChevronRight from '../../Icons/DoubleChevronRight';
import PercentageBar from './PercentageBar';
import Tooltip from '../Tooltip/Tooltip';
import Icon from '../Icon/Icon';
import Header from '../Header/Header';

function getSkillAvgScore(skill) {
  let attemptCount = 0;
  let averageSum = 0;
  skill.forEach((grade) => {
    if (grade.average_score !== null) {
      attemptCount += grade.count;
      averageSum += grade.average_score * grade.count;
    }
  });
  const averageScore = averageSum / attemptCount;
  const averageProficiency = FormatUtil.getProficiencyLevel(averageScore);
  const averageColor = MetricsSettings.competencyLevels[averageProficiency].color;
  return {
    totalAttempts: attemptCount,
    score: averageScore,
    color: averageColor,
  };
}

function getProficiencyLevels(skill, totalAttempts) {
  const levels = [];
  const { competencyLevels } = MetricsSettings;
  skill.forEach((item) => {
    if (item.grade) {
      // Percentage of attempts at this level
      const percentage = 1 * (item.count / totalAttempts) * 100;
      levels.push({
        level: item.grade,
        color: competencyLevels[item.grade].color,
        percentage: Math.floor(percentage),
      });
    }
  });
  return levels;
}

function getAlgoliaFilter(skill) {
  return `skillset|${skill.toLowerCase().split(' ').join('-')}`;
}

function SkillsFilterLink(skillName, omitSkillNameInText) {
  if (!skillName) {
    return null;
  }
  // format for search filters -- "Attack and Exploitation" --> "skillset|attack-and-exploitation"
  const skillFilter = getAlgoliaFilter(skillName);
  const linkText = omitSkillNameInText ? 'related' : skillName;
  return (
    <AddLink className="print:hidden text-cyb-pink-500 skill-search-link" target="_blank" to={`/browse/refined/?terms_info=${skillFilter}`}>
      See all {linkText} content <DoubleChevronRight classes="inline-block w-3 h-3 ml-1" />
    </AddLink>
  );
}

function SkillsBreakdown({ data, showSkillBreakdown }) {
  const [skillsSearchResults, setSkillsSearchResults] = useState({});

  useEffect(() => {
    // Need to check Algolia to see if the skill has search results to show link or not
    if (data) {
      const promises = [];
      const skillsArr = Object.keys(data);
      skillsArr.forEach((skill) => {
        if (skill) {
          const filterName = getAlgoliaFilter(skill);
          let filter = SearchUtil.buildFilters('terms_info', [`'${filterName}'`]);
          filter += ` AND display_in_catalog:true`;
          const searchIndex = process.env.REACT_APP_INSTANTSEARCH_CATALOG_INDEX;

          const options = {
            hitsPerPage: 10,
            filters: filter,
          };
          promises.push(SearchUtil.fetchFromAlgolia(searchIndex, options));
        }
      });
      Promise.all(promises).then((skillResults) => {
        // Get rid of any blank skills, if any
        const skillsArrClean = [...skillsArr].filter((skill) => skill !== '');
        // Check each skill result to see if any hits came back. If so, set bool
        skillResults.forEach((result, idx) => {
          skillsSearchResults[skillsArrClean[idx]] = result.nbHits && result.nbHits > 0;
        });
        const newState = { skillsSearchResults };
        setSkillsSearchResults(newState);
      });
    }
  }, []);

  const skills = data;
  const skillsKeys = Object.keys(skills);
  if (!skillsKeys?.length) {
    return null;
  }
  // Make sure Overall (empty key ""), if exists in array, is first
  if (skillsKeys.indexOf('') !== -1) {
    skillsKeys.splice(skillsKeys.indexOf(''), 1);
    skillsKeys.unshift('');
  }
  return (
    <div className="skill-breakdown">
      {skillsKeys.map((skillName) => {
        const skillArr = skills[skillName];
        if (!skillArr?.length) {
          return null;
        }
        // Each index will always have the same description - so just grab the first one
        const skillDesc = skillArr[0].skill_description;
        // Get the total number of attempts and the overall average score for this skill
        const skillAverage = getSkillAvgScore(skillArr);
        // Get the breakdown of proficiency levels for this skill. Includes the percentage of users at each level
        const proficiencyLevels = getProficiencyLevels(skillArr, skillAverage.totalAttempts);
        // Popup content on hover to show % of attempts at each level
        if (!proficiencyLevels.length) {
          return (
            <div key="empty" className="empty-message">
              There are no scored assessments at this time
            </div>
          );
        }
        const popupContent = proficiencyLevels.map((level) => {
          return (
            <div className="competency-breakdown-item" key={level.level}>
              <span className="color" style={{ backgroundColor: `${level.color}` }} />
              <span className="percent">{level.percentage}%</span>
              <span>{level.level}</span>
            </div>
          );
        });
        const proficientBadge = skillAverage.score > 75 ? <div className="proficient-badge" /> : null;
        return (
          <div className="block mb-4 md:grid md:grid-cols-12" key={skillName}>
            <div className="md:col-span-4">
              {skillName === '' ? <span className="skill-name">Overall</span> : <span className="skill-name">{skillName}</span>}
              {skillDesc && (
                <Tooltip
                  content={
                    <div className="grid grid-cols-12">
                      <div className="col-span-2">
                        <div className="proficient-badge" />
                      </div>
                      <div className="col-span-10">
                        <Header as="h4">{skillName}</Header>
                        <p style={{ fontSize: '0.9375em' }}>{skillDesc}</p>
                      </div>
                    </div>
                  }
                  triggerContent={<Icon style={{ marginLeft: '5px' }} name="question-circle" className="inline-block ml-2 w-5 h-5 text-gray-600 hover:text-black" />}
                />
              )}
              <div>{skillsSearchResults[skillName] ? SkillsFilterLink(skillName, true) : <span>&nbsp;</span>}</div>
            </div>
            <div className="md:col-span-8">
              {showSkillBreakdown ? (
                <Tooltip
                  content={popupContent}
                  triggerContent={
                    <div style={{ width: 'calc(100% - 6em)' }}>
                      <div className="skill-bar-container" style={{ width: `${skillAverage.score}%` }}>
                        {proficiencyLevels.map((level) => {
                          return <PercentageBar key={level.level} color={level.color} maxHeight="2em" width={`${level.percentage}%`} />;
                        })}
                        <span className="avg-label">
                          <CountUp separator="," end={skillAverage.score} suffix="%" duration={3} />
                          {proficientBadge}
                        </span>
                      </div>
                    </div>
                  }
                />
              ) : (
                <>
                  <div className="hidden print:block">{Math.floor(skillAverage.score)}%</div>
                  <div className="print:hidden pdf-print" style={{ width: 'calc(100% - 6em)' }}>
                    <div className="skill-bar-container" style={{ width: '100%' }}>
                      <PercentageBar key={skillName} color={skillAverage.color} maxHeight="2em" width={`${skillAverage.score}%`} />
                      <span className="avg-label">
                        <CountUp separator="," end={skillAverage.score} suffix="%" duration={3} />
                        {proficientBadge}
                      </span>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default SkillsBreakdown;
