import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { keys, isEmpty } from 'lodash';
import moment from 'moment';
import Container from '../../components/Container/Container';
import Loading from '../../components/Loading/Loading';
import Title from '../../components/Title/Title';
import Modal from '../../components/Modal/Modal';
import CertificateModalContent from './CertificateModalContent';
import EmptyContent from '../../components/EmptyContent/EmptyContent';
import AddLink from '../../components/AddLink/AddLink';

const Certificates = inject(
  'certificateStore',
  'commonStore'
)(
  observer(({ commonStore, certificateStore }) => {
    const [open, setOpen] = useState(false);
    const [selectedCert, setSelectedCert] = useState(null);

    useEffect(() => {
      certificateStore.getCertificates();
      commonStore.setPageTitle('Certificates | Cybrary');
      return () => {
        certificateStore.reset();
      };
    }, []);

    const toggleModal = (isOpen, cert = null) => {
      setOpen(isOpen);
      setSelectedCert(cert);
    };

    const certs = certificateStore.certificates;
    const contentTypes = keys(certs);
    const loading = certificateStore.isCertificateLoading;

    return (
      <Container size="lg">
        <Title title="Certificates" />
        {loading && <Loading message="Loading..." />}
        {!isEmpty(certs) && !loading && (
          <>
            <Modal open={open} toggle={() => toggleModal(!open)} ariaLabelledBy="cert-title">
              <CertificateModalContent cert={selectedCert} />
            </Modal>
            <div className="flex flex-row flex-wrap mb-24 w-full">
              {contentTypes.map((type) => {
                return (
                  <React.Fragment key={type}>
                    {type !== 'MicroCourse' && <h2 className="mb-6 w-full text-2xl font-black">{type}s</h2>}
                    {certs[type].map((item) => (
                      <CertCard onCertClick={toggleModal} key={item.id} cert={item} />
                    ))}
                  </React.Fragment>
                );
              })}
            </div>
          </>
        )}
        {isEmpty(certs) && !loading && (
          <EmptyContent>
            <p className="text-gray-600">You currently have no earned certificates.</p>
            <p className="mb-8 text-gray-600">Go ahead and browse our catalog to get started!</p>
            <AddLink to="/browse/refined">
              <button className="py-2.5 px-6 text-sm font-bold leading-5 text-center text-white hover:text-white bg-cyb-pink-500 hover:bg-pink-600 rounded-sm">
                Browse Catalog
              </button>
            </AddLink>
          </EmptyContent>
        )}
      </Container>
    );
  })
);

function Cert({ certImage, cert, completedDate }) {
  return (
    <>
      <img src={certImage} className="mb-0" alt="" />
      <h3 className="mt-2 mb-1 font-semibold text-black">{cert.title}</h3>
      <p className="text-sm text-gray-600">Completed {completedDate}</p>
    </>
  );
}

export function CertCard({ cert, onCertClick }) {
  const certImage = 'https://images.ctfassets.net/kvf8rpi09wgk/7a9Hi5dmSSnzuNJqMTcqUC/5264b28124e7ec8087d82c771a3f84bb/Cybrary-Cert.png?h=200';

  const certDate = cert.issued_at || cert.completed_at;
  const completedDate = certDate ? moment(certDate.substring(0, 10)).format('MM/DD/YYYY') : null;
  const updatedCert = { ...cert, completedDate };

  return (
    <div key={cert.id} className="pr-2 mb-12 flex-basis-1/2 md:flex-basis-1/3">
      {onCertClick ? (
        <button className="cursor-pointer" onClick={() => onCertClick(true, updatedCert)}>
          <Cert certImage={certImage} cert={cert} completedDate={completedDate} />
        </button>
      ) : (
        <Cert certImage={certImage} cert={cert} completedDate={completedDate} />
      )}
    </div>
  );
}

export default Certificates;
