import moment from 'moment';
import FormatUtil from '../utils/formatUtil';
import StyleUtil from '../utils/styleUtil';
import enrollmentTransformer from './enrollmentTransformer';

class CurrentEnrollmentTransformer {
  // Find the content item in collection with the most recent progress by evaluating progress/completion dates of modules and activities
  static getCurrentContentItem = (enrollmentData, descendant_enrollments) => {
    const descendantEnrollments = descendant_enrollments ? this.getDescendantEnrollmentsMap(descendant_enrollments) : null;
    // The current content item with the most recent activity
    const currentContentItem = {
      data: {},
      currentActivityDate: null,
    };
    if (enrollmentData.curriculumItems && enrollmentData.curriculumItems.length) {
      // Loop through curriculumItems
      enrollmentData.curriculumItems.forEach((item) => {
        const itemContentId = item.contentDescription.id;
        // Need to see if the current item is in the descendantEnrollments map and not complete to see if it has recent progress in any module or activities
        if (!!descendantEnrollments && descendantEnrollments[itemContentId] && !descendantEnrollments[itemContentId].completed_at) {
          this.evaluateEnrollmentDescendantsActivity(item, itemContentId, item.curriculumItem.learning_modules, descendantEnrollments, currentContentItem);
        }
        // If there's no item set yet and this item isn't complete, set current item to this
        if (!Object.keys(currentContentItem.data).length && item.progress < 100) {
          currentContentItem.data = item;
        }
      });
    }
    return currentContentItem.data;
  };

  // Evaluate this collection item to determine if it has any progress/completions within for determining which item was most recently interacted with
  static evaluateEnrollmentDescendantsActivity = (item, itemContentId, modules, descendantEnrollments, currentItem) => {
    let currentContentItem = currentItem;
    // Check if this item has learning modules. Need to look through those to find most recently started or completed
    if (!!modules && modules.length) {
      currentContentItem = this.compareModuleAndActivityTimes(item, modules, descendantEnrollments, currentContentItem);
    }
    // There are no learning modules, so this is an activity
    // If we don't have a currentContentItem set already, we will set to this
    if (!Object.keys(currentContentItem.data).length) {
      currentContentItem.data = item;
    } else {
      // We already have a content item set, need to compare the current most recent progress with this item
      const itemCreatedDate = descendantEnrollments[itemContentId].created_at;
      // If no currentActivityDate, we don't have a baseline for most recent started item
      // OR if the currently set item is older than this one, use this one
      if (!currentContentItem.currentActivityDate || moment(itemCreatedDate).isAfter(currentContentItem.currentActivityDate)) {
        currentContentItem.data = item;
        currentContentItem.currentActivityDate = itemCreatedDate;
      }
    }

    return currentContentItem;
  };

  // Loop through all modules to compare activity/completion dates
  // Saves recent-most module progress/completion date for comparison with other content items
  static compareModuleAndActivityTimes = (item, itemModules, descendantEnrollments, currentItem) => {
    const currentContentItem = currentItem;
    const moduleEval = {
      hasModuleIncomplete: null,
      lastCompletedModuleDate: null,
    };
    // Loop over modules
    itemModules.forEach((module) => {
      // If this module has progress/completion, go deeper in evaluating dates and looking at activities
      if (descendantEnrollments[module.id]) {
        const moduleCompletedAt = descendantEnrollments[module.id].completed_at;
        if (!moduleCompletedAt && module.activities && module.activities.length) {
          // If module isn't complete, there HAS to be an activity not complete, look at activity dates
          moduleEval.hasModuleIncomplete = true;
          this.compareActivityTimes(item, currentItem, module, descendantEnrollments);
        } else if (moduleCompletedAt && (!moduleEval.lastCompletedModuleDate || moment(moduleCompletedAt).isAfter(moduleEval.lastCompletedModuleDate))) {
          moduleEval.lastCompletedModuleDate = moduleCompletedAt;
        }
      } else {
        moduleEval.hasModuleIncomplete = true;
      }
    });
    // After looping, if we have any modules incomplete, and the last completed module is after whatever the current most content item, this is the new content item
    if (moduleEval.hasModuleIncomplete && moment(moduleEval.lastCompletedModuleDate).isAfter(currentContentItem.currentActivityDate)) {
      currentContentItem.data = item;
      currentContentItem.currentActivityDate = moduleEval.lastCompletedModuleDate;
    }
    return currentContentItem;
  };

  // Loop through all activities to compare activity/completion dates
  // Saves recent-most activity progress/completion date for comparison with other content items
  static compareActivityTimes = (item, currentItem, module, descendantEnrollments) => {
    const currentContentItem = currentItem;
    const activityEval = {
      hasActivityIncomplete: null,
      lastCompletedActivityDate: null,
    };
    // Loop over activities
    module.activities.forEach((activity) => {
      if (descendantEnrollments[activity.id]) {
        // Activity is complete or has progress - Track dates for comparison and finding most recent progressed/complete activity
        const activityCreatedAt = descendantEnrollments[activity.id].created_at;
        const activityCompletedAt = descendantEnrollments[activity.id].completed_at;

        if (!activityCompletedAt) {
          activityEval.hasActivityIncomplete = true;
          if (!currentContentItem.currentActivityDate || moment(activityCreatedAt).isAfter(currentContentItem.currentActivityDate)) {
            currentContentItem.data = item;
            currentContentItem.currentActivityDate = activityCreatedAt;
          }
        } else if (!!activityCompletedAt && (!activityEval.lastCompletedActivityDate || moment(activityCompletedAt).isAfter(activityEval.lastCompletedActivityDate))) {
          activityEval.lastCompletedActivityDate = activityCompletedAt;
        }
      } else {
        activityEval.hasActivityIncomplete = true;
      }
    });
    // After looping, if we have any activities incomplete, and the last completed activity is after whatever the current most content item, this is the new content item
    if (
      activityEval.hasActivityIncomplete &&
      activityEval.lastCompletedActivityDate &&
      (!currentContentItem.currentActivityDate || moment(activityEval.lastCompletedActivityDate).isAfter(currentContentItem.currentActivityDate))
    ) {
      currentContentItem.data = item;
      currentContentItem.currentActivityDate = activityEval.lastCompletedActivityDate;
    }
    return currentContentItem;
  };

  // Get all descendent enrollments that are not complete in a mapping format - {contentDescriptionId: {created_at, completed_at}
  static getDescendantEnrollmentsMap = (descendants) => {
    const descendantsMap = {};
    if (!!descendants && descendants.length) {
      descendants.forEach((item) => {
        descendantsMap[item.content_description_id] = {
          ...item,
        };
      });
    }
    return descendantsMap;
  };

  // Find the first module in a course that is not complete
  static getCurrentModule = (modules, completeContentIds) => {
    if (!modules) {
      return null;
    }
    let currModule = null;
    // If we weren't given an array of completedContentIds, grab the first module
    if (!completeContentIds) {
      const [firstModule] = modules;
      currModule = firstModule;
    } else {
      // Get the first module that's incomplete
      for (let i = 0; i < modules.length; i++) {
        const module = modules[i];
        if (completeContentIds.indexOf(module.id) === -1) {
          currModule = module;
          break;
        }
      }
    }
    return currModule;
  };

  // Find the first activity in a module that is not complete and calculate the module progress and time remaining
  static getCurrentActivityData = (currentModule) => {
    if (!currentModule || !currentModule.activities) {
      return {};
    }
    let modTimeRemaining = currentModule.duration;
    let activitiesComplete = 0;
    // Get the first activity that's incomplete
    let currentActivity = null;
    for (let i = 0; i < currentModule.activities.length; i++) {
      const activity = currentModule.activities[i];
      if (activity.progress !== 100) {
        if (!currentActivity) {
          currentActivity = activity;
        }
      } else {
        modTimeRemaining -= activity.duration;
        activitiesComplete++;
      }
    }

    // If we don't have a current activity, see if we've completed all activities in the module
    if (!currentActivity) {
      const allActivitiesCompleted = currentModule.activities.every((activity) => activity.progress >= 100);
      if (allActivitiesCompleted) {
        // If all activities are completed, set current activity to the last activity in the module
        // This is a fallback to ensure we always return a current activity that is relevant to the user
        // If all activities are completed, the last activity is the most recent
        currentActivity = currentModule.activities[currentModule.activities.length - 1];
      }
    }

    const moduleTimeRemaining = FormatUtil.formatTime(modTimeRemaining, 'hm').trim();
    const moduleProgress = Math.floor((activitiesComplete / currentModule.activities.length) * 100);
    return {
      currentActivity,
      moduleTimeRemaining,
      moduleProgress,
    };
  };

  static addAvtivityDuration = (secondsTotal, { duration }) => secondsTotal + (duration || 0);

  /**
   * Get array of all activities in a course for use in calculating progress in line with the backend.
   * @param {Object} course Contentful course object
   * @param {Boolean} requiredOnly If true, exclude optional activities. If false, count all activities.
   * @returns {Array} Array of activities
   */
  static getActivitiesInCourse(course, requiredOnly = true) {
    const { learning_modules: learningModules } = course || {};
    // use reduce to count all activities in all learning modules
    return learningModules?.reduce((allActivities, learningModule) => {
      const { activities } = learningModule || {};
      if (requiredOnly) {
        const modulesRequiredActivities = activities?.filter(({ optional }) => !optional);
        allActivities.push(...modulesRequiredActivities);
        return allActivities;
      }
      allActivities.push(...activities);
      return allActivities;
    }, []);
  }

  /**
   * Get array of all completed activities in a course for use in calculating progress in line with the backend.
   * @param {Object} course Contentful course object
   * @param {Array} completedContentDescriptionIds Array of content description ids for completed activities
   * @param {Boolean} requiredOnly If true, exclude optional activities. If false, count all activities.
   * @returns {Array} Array of completed activities
   */
  static getCompletedActivitiesInCourse(course, completedContentDescriptionIds, requiredOnly = true) {
    const activities = this.getActivitiesInCourse(course, requiredOnly);
    return activities?.filter(({ id }) => completedContentDescriptionIds?.includes(id));
  }

  // Transform an enrollment into a slim format
  static transformEnrollmentFormat = (data) => {
    const completeContentIds = data.completed_content_description_ids;
    const enrollmentData = enrollmentTransformer.transformSingleEnrollment(data);
    const isCollection = ['Career Path', 'Curriculum', 'Assignment', 'Threat Actor Path', 'Cybrary Select'].indexOf(enrollmentData?.type) > -1;
    // We want to highlight the current content item, not collection item
    const currentContentItem = isCollection ? this.getCurrentContentItem(enrollmentData, data.descendant_enrollments) : enrollmentData;
    const learningModules = isCollection && currentContentItem.curriculumItem ? currentContentItem.curriculumItem.learning_modules : enrollmentData.learning_modules;
    const currentModule = this.getCurrentModule(learningModules, completeContentIds);
    const typeStyle = StyleUtil.getTypeStyle(currentContentItem.type, currentContentItem.thumbnail);
    // If current item is an activity, it won't have modules, so check first
    const moduleActivityData = this.getCurrentActivityData(currentModule);
    const { currentActivity, moduleTimeRemaining, moduleProgress } = moduleActivityData;
    const activities = this.getActivitiesInCourse({ learning_modules: learningModules }) || [];
    const completedActivities = this.getCompletedActivitiesInCourse({ learning_modules: learningModules }, completeContentIds) || [];
    const termsInfo = isCollection ? currentContentItem?.contentDescription?.terms_info : data?.content?.content_description?.terms_info;
    const domains = termsInfo?.filter((term) => term.includes('Domains|'))?.map((term) => term.replace('Domains|', '')) || [];
    const topics = termsInfo?.filter((term) => term.includes('Topics|'))?.map((term) => term.replace('Topics|', '')) || [];

    // build link to immersive
    let linkToImmersive = '';
    const item = currentContentItem?.curriculumItem;
    // set links for content collection enrollments
    if (item) {
      // check to see if the current curriculum item is an activity
      const type = item?.content_description?.content_type;
      const currentContentDescriptionId = item?.content_description?.id || 0;

      // set links for descendent activity enrollments
      if (type.is_activity) {
        // link to this descendant activity enrollment directly
        // example immersive URL for a stand alone activity: /immersive/enrollment/activity/${activityEnrollmentID}
        const descendantEnrollments = data?.descendant_enrollments || [];
        const activityEnrollment = descendantEnrollments.find((enrollment) => enrollment?.content_description_id === currentContentDescriptionId);
        linkToImmersive = `/immersive/enrollment/activity/${activityEnrollment?.id || 0}`;
      } else if (currentContentDescriptionId && currentActivity?.id) {
        // build link as normal, to the parent-most enrollment with a path to the current activity through the curriculum item
        // example immersive URL: /immersive/${enrollmentID}/item/${courseContentDescriptionID}/activity/${activityContentDescriptionID}
        linkToImmersive = `/immersive/${data?.id}/item/${currentContentDescriptionId}/activity/${currentActivity?.id}`;
      } else {
        // Something went wrong, we don't have a current activity
        // Drop the user into /immersive/${data?.id} and hope the router there can figure it out
        linkToImmersive = `/immersive/${data?.id}`;
      }
    }
    // set links for course enrollments
    if (!item) {
      // build link as normal, to the parent-most enrollment with a path to the current activity
      // example immersive URL: /immersive/${enrollmentID}/activity/${activityContentDescriptionID}
      linkToImmersive = `/immersive/${data?.id}/activity/${currentActivity?.id}`;
    }

    return {
      enrollment: enrollmentData,
      enrollmentId: data.id,
      currentContentItem: isCollection ? currentContentItem?.contentDescription : data?.content?.content_description,
      contentDescription: data?.content?.content_description,
      backgroundImage: data?.content?.meta?.backgroundImage ? `${data?.content?.meta?.backgroundImage}?w=800&q=55` : typeStyle?.backgroundImage,
      thumbnail: currentContentItem?.thumbnail ? `${currentContentItem.thumbnail}?w=800&q=55` : typeStyle?.backgroundImage,
      shortDescription: isCollection ? currentContentItem?.contentDescription?.short_description : currentContentItem?.shortDescription,
      title: currentContentItem?.title,
      contentProgress: currentContentItem?.progress,
      contentTimeRemaining: currentContentItem?.estimatedTimeRemaining?.trim(),
      currentActivity,
      moduleTitle: currentActivity?._formatted_title,
      moduleTimeRemaining,
      moduleProgress,
      // Add total / completed required activities count to course object
      activitiesTotal: activities?.length,
      activitiesCompleted: completedActivities?.length,
      // Add total / completed required learning seconds to course object for progress bars
      secondsTotal: activities?.reduce(this.addAvtivityDuration, 0) || currentContentItem?.contentDescription?.duration_seconds,
      secondsCompleted: completedActivities?.reduce(this.addAvtivityDuration, 0),
      termsInfo,
      domains,
      topics,
      linkToImmersive,
      isCollection,
    };
  };

  // Transform a catalog item into a slim format
  static transformCatalogFormat = (data) => {
    const type = data.content_type.nice_name;
    const thumbnail = data.thumbnail_url;
    const typeStyle = StyleUtil.getTypeStyle(type, thumbnail);
    const currentModule = data.content_item.learning_modules ? data.content_item.learning_modules[0] : null;
    const currentActivity = currentModule && currentModule.activities ? currentModule.activities[0] : null;
    let moduleTitle = currentModule ? currentModule.title : '';
    if (!!currentActivity && currentActivity.title) {
      moduleTitle += ` - ${currentActivity.title}`;
    }
    return {
      contentDescription: data,
      currentContentItem: data.id,
      backgroundImage: thumbnail ? `${thumbnail}?w=800&q=55` : typeStyle.backgroundImage,
      shortDescription: data.short_description,
      title: data.title,
      contentProgress: 0,
      contentTimeRemaining: FormatUtil.formatTime(data.duration_seconds, 'hm').trim(),
      moduleTitle,
      moduleTimeRemaining: currentModule ? FormatUtil.formatTime(currentModule.duration, 'hm').trim() : null,
      moduleProgress: 0,
    };
  };

  // Routes data into specific helper functions based on incoming format
  static transformCurrentEnrollmentData = (data, isEnrollmentFormat) => {
    if (!data || !Object.keys(data).length) {
      return null;
    }
    // The data can be in either an enrollment format, or content description format
    if (isEnrollmentFormat) {
      return this.transformEnrollmentFormat(data);
    }
    if (data.content_type) {
      return this.transformCatalogFormat(data);
    }
    return null;
  };
}

export default CurrentEnrollmentTransformer;
