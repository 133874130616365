import React from 'react';
import Label from './Label';

function ChargebeeLabel({ className, key, basic }) {
  const classes = 'border-xs rounded-sm text-xs p-1';
  return (
    <Label color="orange" basic={!!basic} className={`${classes} ${className}`} key={key || 'chargebee'}>
      Chargebee
    </Label>
  );
}

export default ChargebeeLabel;
