import React from 'react';
import Checkbox from '../FormFields/Checkbox';

class CheckboxBoolean extends React.Component {
  state = { value: this.props.defaultValue || false };

  componentDidMount() {
    if (typeof this.props.defaultValue === 'boolean') {
      this.handleChange(undefined, {
        checked: this.props.defaultValue,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.defaultValue !== this.props.defaultValue) {
      this.handleChange(undefined, {
        checked: this.props.defaultValue,
      });
    }
  }

  handleChange = (e, { checked }) => {
    const newState = { value: checked };
    this.setState(newState);
    this.props.onChange(e, { name: this.props.name, value: newState.value });
  };

  render() {
    const { id, name, disabled, label, ariaDescribedBy, required, wrapperClass, labelClass, inputClasses, innerWrapperClass } = this.props;
    const { value } = this.state;
    return (
      <div className={wrapperClass || 'flex py-2'}>
        <Checkbox
          id={`${id}`}
          label={label}
          name={name}
          value={value}
          required={required}
          ariaDescribedBy={ariaDescribedBy}
          checked={this.state.value}
          disabled={disabled}
          onChange={this.handleChange}
          className={innerWrapperClass || 'mb-0 flex items-start'}
          labelClasses={labelClass || 'text-black'}
          inputClasses={inputClasses}
        />
      </div>
    );
  }
}

export default CheckboxBoolean;
