import React, { useEffect, useState } from 'react';
import Collapsible from 'react-collapsible';

import Icon from '../Icon/Icon';
import Tooltip from '../Tooltip/Tooltip';

function RequirementsListItem({ isValid, children }) {
  return (
    <li className={`${isValid ? 'valid' : 'red-text'} block font-normal`}>
      <Icon name={isValid ? 'check' : 'minus'} className={`w-4 h-4 mr-2 inline-block ${isValid ? 'text-cyb-green-500' : ''}`} />
      {children}
    </li>
  );
}

function TooltipContent({ isValid, isOpen }) {
  return (
    <span className={`text-black mb-4 cursor-pointer text-sm ${isValid ? 'font-semibold' : 'font-light'}`}>
      <Icon className={`inline-block w-4 h-4 mr-1 mb-1 ${isValid ? 'text-cyb-green-500' : ''}`} name={`${isValid ? 'checkmark-circle' : 'question-circle'}`} />
      {isValid ? 'Strong Password!' : 'See Password Requirements'}
      {isOpen ? <Icon className="inline-block mb-1 ml-1 w-4 h-4" name="chevron-up" /> : <Icon className="inline-block mb-1 ml-1 w-4 h-4" name="chevron-down" />}
    </span>
  );
}

function PasswordRequirements({ openPwRequirements, passwordValidations = {} }) {
  const [isOpen, setIsOpen] = useState(false);
  const isAllValid = passwordValidations.length && passwordValidations.number && passwordValidations.lowercase && passwordValidations.uppercase;

  useEffect(() => {
    if (openPwRequirements || !isAllValid) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [openPwRequirements, passwordValidations.length, passwordValidations.number, passwordValidations.lowercase, passwordValidations.uppercase]);

  return (
    <Collapsible
      className="mb-3"
      onOpening={() => {
        setIsOpen(true);
      }}
      onClosing={() => {
        setIsOpen(false);
      }}
      openedClassName=""
      triggerTagName="div"
      trigger={
        <Tooltip
          triggerContent={<TooltipContent isOpen={isOpen} isValid={isAllValid} />}
          position="top"
          className="w-full text-sm text-black bg-white shadow-lg"
          content={isAllValid ? 'This password meets the requirements' : 'This password does not meet the strength requirements'}
        />
      }
      open={isOpen}
      transitionTime={200}
    >
      <ul className="block pb-4 text-sm text-black">
        <RequirementsListItem isValid={passwordValidations.length}>At least 8 character(s)</RequirementsListItem>
        <RequirementsListItem isValid={passwordValidations.number}>At least 1 number(s)</RequirementsListItem>
        <RequirementsListItem isValid={passwordValidations.lowercase}>At least 1 lowercase letter(s)</RequirementsListItem>
        <RequirementsListItem isValid={passwordValidations.uppercase}>At least 1 uppercase letter(s)</RequirementsListItem>
      </ul>
    </Collapsible>
  );
}

export default PasswordRequirements;
