import React from 'react';
import AddLink from '../../components/AddLink/AddLink';

function CancellationSteps({ subscriptionId, plan, gateway }) {
  return (
    <div className="pt-12 pb-8">
      <h2 className="mb-4 text-2xl	text-black">Insider Pro Cancellation Steps</h2>
      <div>
        <p className="text-sm">Before we cancel your membership, please complete a short survey on your experience.</p>
        <p className="text-sm">
          We are asking for your help to answer a few questions on how we can improve the Insider Pro experience to better empower Insiders to get that next job, pay raise,
          certification, or knowledge to reach their goals.
        </p>
        <p className="text-sm">After you submit your answers you will be prompted to complete your membership cancellation.</p>
      </div>
      <div className="mt-8">
        <AddLink
          to={`/cybrary-insider-pro/cancel?subscriptionId=${subscriptionId}&plan=${plan}&gateway=${gateway}`}
          className="py-2.5 px-6 text-sm font-bold leading-5 text-center text-white hover:text-white bg-cyb-pink-500 hover:bg-pink-600 rounded-sm"
        >
          Back
        </AddLink>
        <AddLink
          to={`/cybrary-insider-pro/survey?subscriptionId=${subscriptionId}&plan=${plan}&gateway=${gateway}`}
          className="py-2.5 px-6 mx-4 text-sm font-bold leading-5 text-center text-black bg-gray-200 rounded-sm"
        >
          Start Survey
        </AddLink>
      </div>
    </div>
  );
}

export default CancellationSteps;
