import React from 'react';

export default function Instagram({ classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6 bg-white rounded-md';
  return (
    <svg className={className} viewBox="0 0 16 16" fill="currentColor" {...accessibilityProps}>
      <path
        d="M7.99785 0.615891C4.97202 0.615891 2.09869 0.346725 1.00119 3.16339C0.547853 4.32673 0.613686
         5.83756 0.613686 8.00089C0.613686 9.89923 0.552853 11.6834 1.00119 12.8376C2.09619 15.6559 4.99285
          15.3859 7.99619 15.3859C10.8937 15.3859 13.8812 15.6876 14.992 12.8376C15.4462 11.6626 15.3795
           10.1742 15.3795 8.00089C15.3795 5.11589 15.5387 3.25339 14.1395 1.85506C12.7229 0.438391 10.807
            0.615891 7.99452 0.615891H7.99785ZM7.33619 1.94673C13.6479 1.93673 14.4512 1.23506 14.0079
             10.9826C13.8504 14.4301 11.2254 14.0517 7.99869 14.0517C2.11535 14.0517 1.94619 13.8834 1.94619
              7.99756C1.94619 2.04339 2.41285 1.95006 7.33619 1.94506V1.94673ZM11.9395 3.17256C11.4504 3.17256
               11.0537 3.56923 11.0537 4.05839C11.0537 4.54756 11.4504 4.94423 11.9395 4.94423C12.4287 4.94423
                12.8254 4.54756 12.8254 4.05839C12.8254 3.56923 12.4287 3.17256 11.9395 3.17256ZM7.99785 4.20839C5.90369
                 4.20839 4.20619 5.90673 4.20619 8.00089C4.20619 10.0951 5.90369 11.7926 7.99785 11.7926C10.092 11.7926
                  11.7887 10.0951 11.7887 8.00089C11.7887 5.90673 10.092 4.20839 7.99785 4.20839ZM7.99785 5.53923C11.252
                   5.53923 11.2562 10.4626 7.99785 10.4626C4.74452 10.4626 4.73952 5.53923 7.99785 5.53923Z"
      />
    </svg>
  );
}
