import React from 'react';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, EmailShareButton } from 'react-share';
import FocusLock from 'react-focus-lock';
import Bugsnag from '@bugsnag/js';
import Tooltip from '../Tooltip/Tooltip';
import Facebook from '../../Icons/Facebook';
import Twitter from '../../Icons/Twitter';
import Linkedin from '../../Icons/Linkedin';
import Email from '../../Icons/Email';
import GaUtil from '../../utils/gaUtil';
import { getFacebookText, getTwitterText, getEmailText, getEmailSubject, fetchUrl, handleGaEvent } from '../../utils/shareUtil';

function ScreenClose({ isOpen, handleClose }) {
  return !isOpen ? null : (
    <button onClick={handleClose} className="fixed inset-0 z-10 w-full h-full cursor-default">
      &nbsp;
    </button>
  );
}

function SharePane({ id, title, type, prefix, toggleShareDisplay, isSidebar, isOpen, permalink }) {
  const copyToClipboard = (event) => {
    event.preventDefault();
    const target = event.target.value;
    const elementID = id ? `#${id}-copyToClipboard` : `#copyToClipboard`;
    const input = target ? document.querySelector(elementID) : null;
    input.select();
    input.focus();
    try {
      GaUtil.fireEvent(`Share ${type}`, 'Product Interaction', title);
      document.execCommand('copy');
    } catch (err) {
      Bugsnag.notify(err);
    }
  };

  const facebookText = getFacebookText(type, title);
  const twitterText = getTwitterText(type, title);
  const emailText = getEmailText(type, title);
  const emailSubject = getEmailSubject(type);

  const displayClass = isOpen ? 'block' : 'hidden';
  const containerClass = !isSidebar
    ? `share-dropdown absolute top-16 bg-white rounded-lg shadow-xl border-xs border-gray-400 w-80 z-30 ${displayClass}`
    : 'share-sidebar max-w-md mx-auto';
  const inputId = id ? `${id}-copyToClipboard` : `copyToClipboard`;
  const copyButtonClassName = 'cursor-pointer rounded-sm bg-gray-200 border-0 text-black font-bold text-center hover:text-black hover:bg-gray-300 leading-5 py-2.5 px-6 text-sm';
  const ariaProps = {
    role: 'dialog',
    tabIndex: '-1',
    'aria-modal': 'true',
    'aria-label': `Share ${type} On Social Media Modal`,
  };
  if (!isSidebar && !isOpen) {
    return null;
  }
  const shareUrl = fetchUrl(prefix, type, permalink);
  const beforeOnClick = () => handleGaEvent(type, title);
  return (
    <>
      {!isSidebar ? <ScreenClose isOpen={isOpen} handleClose={toggleShareDisplay} /> : null}
      <FocusLock autoFocus={false} className={containerClass} returnFocus>
        <div {...ariaProps}>
          <div className="px-4 pt-10 pb-8">
            <div className="flex justify-between items-center mb-4 share-container">
              <FacebookShareButton
                url={shareUrl}
                quote={facebookText}
                beforeOnClick={beforeOnClick}
                resetButtonStyle={false}
                className="w-8 h-10 rounded-sm"
                style={{ background: '#3b5998' }}
              >
                <Facebook classes="w-8 h-10 text-white" />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                title={twitterText}
                beforeOnClick={beforeOnClick}
                resetButtonStyle={false}
                className="w-8 h-10 rounded-sm"
                style={{ background: '#00aced' }}
              >
                <Twitter classes="w-8 h-10 text-white" />
              </TwitterShareButton>
              <LinkedinShareButton url={shareUrl} beforeOnClick={beforeOnClick} resetButtonStyle={false} className="w-8 h-10 rounded-sm" style={{ background: '#007fb1' }}>
                <Linkedin classes="w-8 h-10 text-white" />
              </LinkedinShareButton>
              <EmailShareButton
                url={shareUrl}
                body={emailText}
                beforeOnClick={beforeOnClick}
                subject={emailSubject}
                resetButtonStyle={false}
                className="w-8 h-10 rounded-sm"
                style={{ background: '#7f7f7f' }}
              >
                <Email classes="w-8 h-10 text-white" />
              </EmailShareButton>
            </div>
            <div>
              <div className="flex flex-col">
                <input id={inputId} type="text" defaultValue={shareUrl} className="flex-1 py-9px px-4 mb-4 w-full h-10 rounded-sm border border-gray-400" />
                <div className="flex justify-end">
                  <Tooltip
                    size="small"
                    omitTabIndex
                    triggerContent={<input type="button" value="Copy" onClick={copyToClipboard} className={copyButtonClassName} style={{ width: '5.25rem' }} />}
                    content="Copied"
                    trigger="click"
                    position="top"
                    theme="dark"
                    className="inline-block"
                    useOnShow
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </FocusLock>
    </>
  );
}

export default SharePane;
