/**
 * Uses fetch to support ajax requests.
 */
import axios from 'axios';
import queryString from 'query-string';
import authStore from '../stores/authStore';
import { isCookieEnabled } from '../hooks/cookies/useCookieMode';

// Services
const authApiBaseURL = `${process.env.REACT_APP_AUTH_API_URL}`;
const courseApiBaseURL = `${process.env.REACT_APP_COURSE_API_URL}`;
const mitreApiBaseURL = `${process.env.REACT_APP_MITRE_BASE_URL}`;
const termsService = `${process.env.REACT_APP_AUTH_API_URL}/talent/terms`;
// CASS
const CASS_URL = `${process.env.REACT_APP_CYB_ASSESSMENT_API_URL}`;

/**
 * Build CASS request headers.
 * CASS requests require a client key and secret as well as a valid user JWT.
 */
const getCASSHeaders = async () => {
  const token = await authStore.getToken();
  return {
    'X-Api-Key': process.env.REACT_APP_CYB_ASSESSMENT_API_KEY,
    'X-Api-Secret': process.env.REACT_APP_CYB_ASSESSMENT_API_SECRET,
    Authorization: token,
  };
};

// We will send along these headers with each request
const getHeaders = (additionalHeaders, token) => {
  let globalHeaders = {};

  if (token) {
    globalHeaders.Authorization = `Bearer ${token}`;
  }

  if (additionalHeaders) {
    globalHeaders = {
      ...globalHeaders,
      ...additionalHeaders,
    };
  }

  return globalHeaders;
};

// Make a request, but first, check to see if the jwt token will expire soon.
const makeRequest = async (method, url, data, responseType, additionalHeaders = null, authToken = null) => {
  const token = authToken || (await authStore.getToken());
  if (method === 'get' || method === 'delete') {
    return axios({
      method,
      url,
      responseType,
      headers: getHeaders(additionalHeaders, token),
      /**
       * GET requests use params to pass data as filters,
       * This is the same as appending to the url with ?key=value
       * This file uses both methods, but this is the preferred method
       */
      params: data,
    });
  }
  return axios({
    method,
    url,
    data,
    headers: getHeaders(additionalHeaders, token),
  });
};

const requests = {
  get: (url, responseType, additionalHeaders, token, params) => makeRequest('get', url, params, responseType, additionalHeaders, token),
  post: (url, data) => makeRequest('post', url, data),
  put: (url, data) => makeRequest('put', url, data),
  patch: (url, data) => makeRequest('patch', url, data),
  delete: (url, responseType) => makeRequest('delete', url, undefined, responseType),
  oktaPost: (url, data) =>
    axios({
      method: 'post',
      url,
      data: queryString.stringify(data),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json',
      },
    }),
};

const authGoogleBaseUrl = `${authApiBaseURL}/google`;
const authGoogle = {
  getIdp: (data) => requests.post(`${authGoogleBaseUrl}/idp`, data),
  getProvider: (data) => requests.post(`${authGoogleBaseUrl}/idp-domain`, data),
  login: (userJwt) =>
    axios({
      method: 'post',
      url: `${authApiBaseURL}/google/callback`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userJwt}`,
      },
    }),
  logout: (data) => requests.post(`${authApiBaseURL}/google/logout`, data),
  passwordResetEmail: (data) => requests.post(`${authGoogleBaseUrl}/password-reset/email`, data),
  passwordReset: (data) => requests.post(`${authGoogleBaseUrl}/password-reset`, data),
  verifyEmail: (data) => requests.post(`${authGoogleBaseUrl}/verification`, data),
  verifyCode: (data) => requests.post(`${authGoogleBaseUrl}/verify-code`, data),
  sendMagicEmail: (data) => requests.post(`${authGoogleBaseUrl}/magic-link`, data),
  magicAction: (data) => requests.post(`${authGoogleBaseUrl}/user/accounts/magic-action`, data),
  accountExists: (token) =>
    axios({
      method: 'post',
      url: `${authGoogleBaseUrl}/user/account/exists`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      data: { token },
    }),
  linkAccount: (userJwt, data) =>
    axios({
      method: 'post',
      url: `${authApiBaseURL}/google/link-account`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${userJwt}`,
      },
      data,
    }),
};

const auth = {
  getLegacyUserById: (legacyUserId) => requests.get(`${authApiBaseURL}/legacyuser/${legacyUserId}`),
  getUserMeta: () => requests.get(`${authApiBaseURL}/user-meta`),
  getUserTeams: () => requests.get(`${authApiBaseURL}/user-teams`),
  getTeamData: (teamId) => requests.get(`${authApiBaseURL}/team/${teamId}/meta`),
  getUserProfile: () => requests.get(`${authApiBaseURL}/user-profile`),
  getUserLicenses: () => requests.get(`${authApiBaseURL}/user/licenses`),
  getFormData: () => requests.get(`${courseApiBaseURL}/form/create-profile/submission`),
  submitVerticalForm: (data) => requests.put(`${courseApiBaseURL}/user-preference/categoryterm`, data),
  submitSkillForm: (data) => requests.put(`${courseApiBaseURL}/user-preference/level`, data),
  getCategoryPreferences: () => requests.get(`${courseApiBaseURL}/user-preference/categoryterm`),
  getLevelPreferences: () => requests.get(`${courseApiBaseURL}/user-preference/level`),
  getUserSecurityQuestion: () => requests.get(`${authApiBaseURL}/okta/user/security`),
  initiateDataExport: () => requests.get(`${courseApiBaseURL}/gdpr/export`),
  completeDataExport: (urlId) => requests.get(`${courseApiBaseURL}/gdpr/${urlId}/xlsx/signed`),
  deleteAccount: () => requests.delete(`${authApiBaseURL}/user/accounts/primary`),
  getSubscriptions: () => requests.get(`${authApiBaseURL}/subscriptions`),
  cancelSubscription: (data) => requests.post(`${authApiBaseURL}/chargebee/subscriptions/cancel`, data),
  applyCoupon: (data) => requests.post(`${authApiBaseURL}/chargebee/subscriptions/coupon`, data),
  createSubscription: (data) => requests.post(`${authApiBaseURL}/chargebee/subscription/new`, data),
  getBraintreeAuthToken: () => requests.get(`${authApiBaseURL}/chargebee/get-client-token`),
  createSubscriptionEstimate: (data) => requests.post(`${authApiBaseURL}/chargebee/subscription/estimate`, data),
  putPassword: (data) => requests.put(`${authApiBaseURL}/profile/password`, data),
  resetPassword: (data) => requests.post(`${authApiBaseURL}/user/accounts/reset-password`, data),
  getZendeskJWT: () => requests.get(`${authApiBaseURL}/user-zendesk-jwt`),
  getDriftJWT: () => requests.get(`${authApiBaseURL}/user-drift-jwt`),
  /**
   * Registers a user in firebase
   * @param {Object} data - The registration data
   * @returns {Promise} - The registration response from the server
   */
  googleCloudRegister: (data) => requests.post(`${authApiBaseURL}/google/register`, data),
  /**
   * Previews a registration attempt
   * Used to check if a user is already registered before taking their money at checkout
   * @param {Object} data - The registration data
   * @returns {Promise} - The preview of a registration response from the server
   * */
  previewGoogleCloudRegister: (data) => requests.post(`${authApiBaseURL}/google/register-preview`, data),
  /**
   * Invites user(s) to join Cybrary through a referral link
   * @param {*} data - The data to send to the server eq. { invites: [{},{},...] }
   * @returns {Promise} - The response from the server
   */
  sendReferralInvites: (data) => requests.post(`${authApiBaseURL}/invite`, data),
};

const catalog = {
  getContentDescriptionById: (id) => requests.get(`${courseApiBaseURL}/catalog/${id}`),
  getContentDescriptionByPermalink: (permalink) => requests.get(`${courseApiBaseURL}/catalog/browse/${permalink}`),
  getCourse: (id) => requests.get(`${courseApiBaseURL}/course/${id}`),
  listContent: (query) => requests.get(`${courseApiBaseURL}/catalog${query}`),
  launch: (id, query = '') => {
    // Nope, don't love this. Not one bit. But it's just for testing purposes.
    // Add the use_internal_cass flag to the query string if the user has the beta feature enabled
    const isCASSBetaEnabled = isCookieEnabled({ name: 'cass', mode: 'cyb_beta_features' });
    if (isCASSBetaEnabled) {
      // Add use_internal_cass flag to query
      // eslint-disable-next-line no-param-reassign
      query += query ? `&use_internal_cass=1` : `?use_internal_cass=1`;
    }
    return requests.get(`${courseApiBaseURL}/catalog/${id}/launch${query}`);
  },
  answers: (id, data) => requests.post(`${courseApiBaseURL}/catalog/${id}/check-answers`, data),
  getAnswers: (id) => requests.get(`${courseApiBaseURL}/catalog/${id}/answers`),
  completeSession: (sessionId) => requests.put(`${courseApiBaseURL}/activity/${sessionId}/complete`, {}),
  saveAssessmentScore: (id, data) => requests.post(`${courseApiBaseURL}/assessment/${id}/practice`, data),
  getCourseProgress: (id) => requests.get(`${courseApiBaseURL}/catalog/${id}/progress`),
  getBookmarks: () => requests.get(`${courseApiBaseURL}/catalog/bookmarks`),
  bookmark: (id) => requests.put(`${courseApiBaseURL}/catalog/${id}/bookmark`, {}),
  // accepts an array of content ids to bookmark
  batchBookmarkContent: (ids) => requests.put(`${courseApiBaseURL}/catalog/bookmarks`, { contents: ids }),
  unbookmark: (id) => requests.put(`${courseApiBaseURL}/catalog/${id}/unbookmark`, {}),
  subscribeToContentType: (id) => requests.post(`${courseApiBaseURL}/user-preference/content-type`, { contentTypeId: id }),
  unsubscribeToContentType: (id) => requests.post(`${courseApiBaseURL}/user-preference/content-type/delete`, { contentTypeId: id }),
  contentTypeSubscription: () => requests.get(`${courseApiBaseURL}/user-preference/content-type`),
  setTagSubscription: (data) => requests.put(`${courseApiBaseURL}/user-preference/tag`, data),
  getTagSubscription: () => requests.get(`${courseApiBaseURL}/user-preference/tag`),
  categoryTerms: (filter) => requests.get(`${courseApiBaseURL}/category-terms${filter}`),
  getComprehensiveLearningCourses: (permalink) => requests.get(`${courseApiBaseURL}/catalog/${permalink}/courses`),
  getComprehensiveLearningCareerPaths: (permalink) => requests.get(`${courseApiBaseURL}/catalog/${permalink}/collections`),
  getVendorDetails: (contentDescriptionId) => requests.get(`${courseApiBaseURL}/vendor/details/${contentDescriptionId}`),
  getCoursesByCategory: (category) => requests.get(`${courseApiBaseURL}/catalog/terms/${category}`),
  getRecommendations: () => requests.get(`${courseApiBaseURL}/catalog/recommendations`),
};

const assessment = {
  getAssessment: (id) => requests.get(`${courseApiBaseURL}/catalog/${id}`),
  startAssessment: (id) => requests.get(`${courseApiBaseURL}/assessment/${id}/start`),
  storeAssessment: (id, assessmentData) => {
    const data = assessmentData || {};
    const result = Object.keys(data).map((key) => {
      return { questionId: Number(key), answerIndex: data[key] };
    });
    const submittedData = {
      answers: result,
    };
    return requests.post(`${courseApiBaseURL}/assessment/${id}/store`, submittedData);
  },
  submitAssessment: (id, assessmentData) => {
    const data = assessmentData || {};

    const result = Object.keys(data).map((key) => {
      return { questionId: Number(key), answerIndex: data[key] };
    });

    const submittedData = {
      answers: result,
    };

    return requests.post(`${courseApiBaseURL}/assessment/${id}/submit`, submittedData);
  },
};

const cybAssessmentNew = {
  /**
   * Get an assessment by ID
   * @param {number} assessmentId - Assessment ID
   * @returns {Promise} - Promise that resolves to an assessment object
   */
  getAssessment: async (assessmentId) => requests.get(`${courseApiBaseURL}/cass/activity-attempt/${assessmentId}`),
  /**
   * Loads all tracks for a user showing best results by default
   * @param {Boolean} params.recent - (optional) params object { recent: boolean }
   * @returns {Promise} - Promise that resolves to an array of tracks
   */
  getAllTracks: async ({ recent }) => makeRequest('get', `${courseApiBaseURL}/cass/track-scores`, { recent }),
  /**
   * Start an assessment by ID
   * @param {number} assessmentId - Assessment ID
   * @returns {Promise} - Promise that resolves to a start response
   */
  startAssessment: async (assessmentId) => requests.post(`${courseApiBaseURL}/cass/activity-attempt/${assessmentId}/start`, {}), // Post body can't be empty
  /**
   * Answer a question in an assessment
   * @param {number} assessmentId - Assessment ID
   * @param {number} questionId - Question ID
   * @param {string|number} answer - Answer to the question
   * @returns {Promise} - Promise that resolves to a response
   */
  answerQuestion: async (assessmentId, questionId, answer) => requests.post(`${courseApiBaseURL}/cass/activity-attempt/${assessmentId}/${questionId}/answer`, { value: answer }),
  /**
   * Submit an assessment
   * @param {number} assessmentId - Assessment ID
   * @returns {Promise} - Promise that resolves to a submit response
   */
  submitAssessment: async (assessmentId) => requests.post(`${courseApiBaseURL}/cass/activity-attempt/${assessmentId}/submit`, {}), // Post body can't be empty
};

const cybAssessment = {
  getAssessment: async (id) => {
    const headers = await getCASSHeaders();
    return requests.get(`${CASS_URL}/cass/${id}`, undefined, headers);
  },
  /**
   * Loads all tracks for a user showing best results by default
   * @param {*} userId - User ID
   * @param {*} params.recent - (optional) params object { recent: boolean }
   * @returns {Promise} - Promise that resolves to an array of tracks
   */
  getAllTracks: async (userId, params) => {
    const headers = await getCASSHeaders();
    return requests.get(`${CASS_URL}/cass/user/${userId}/track-scores`, undefined, headers, undefined, params);
  },
  /**
   * Get attempt history for an assessment by ID
   * @param {number} assessmentId - Assessment ID
   * @returns {Promise} - Promise that resolves to an assessment attempt history object
   */
  getOwnAssessmentAttempts: async (assessmentId) => requests.get(`${courseApiBaseURL}/cass/attempts-history/${assessmentId}`),
  getMemberAssessmentAttempts: async (teamId, userId, assessmentId) => requests.get(`${courseApiBaseURL}/team/${teamId}/cass/${userId}/attempts-history/${assessmentId}`),
  startAssessment: async (id) => {
    const headers = await getCASSHeaders();
    return makeRequest('post', `${CASS_URL}/cass/${id}/start`, {}, undefined, headers);
  },
  answerQuestion: async (assessmentId, questionId, answer) => {
    const headers = await getCASSHeaders();
    const payload = { value: answer };
    return makeRequest('put', `${CASS_URL}/cass/${assessmentId}/question/${questionId}/answer`, payload, undefined, headers);
  },
  submitAssessment: async (id) => {
    const headers = await getCASSHeaders();
    return makeRequest('post', `${CASS_URL}/cass/${id}/submit`, {}, undefined, headers);
  },
};

const checklist = {
  getAllChecklists: () => requests.get(`${authApiBaseURL}/checklists`),

  postChecklistItem: (listName, item) => requests.put(`${authApiBaseURL}/checklist/${listName}/item/${item}/complete`),

  hideChecklist: (data) => requests.put(`${authApiBaseURL}/checklist/${data}/hide`),
};

const teams = {};

const learningSchedule = {
  getLearningSchedule: () => requests.get(`${authApiBaseURL}/learning-schedules`),
  setLearningSchedule: (data) => requests.put(`${authApiBaseURL}/learning-schedules`, data),
};

const notifications = {
  checkUnread: () => requests.get(`${authApiBaseURL}/notifications/unread`),
  markRead: (ids) => requests.post(`${authApiBaseURL}/notifications/read`, { ids }),
  getPreferences: () => requests.get(`${authApiBaseURL}/notification-preference`),
  updatePreferences: (data) => requests.post(`${authApiBaseURL}/notification-preference`, data),
  getNotifications: (params) => requests.get(`${authApiBaseURL}/notifications${params ? `?${params}` : ''}`),
};

const careerPaths = {
  getCareerPathData: (id) => requests.get(`${courseApiBaseURL}/career-path/${id}/contents`),
  showAllActivityItems: (permalink) => requests.get(`${courseApiBaseURL}/catalog/${permalink}/activities`),
};

const threatActors = {
  getPaths: (query) => requests.get(`${courseApiBaseURL}/catalog/threat-actors${query ? `?${query}` : ''}`),
  getByPermalink: (permalink) => requests.get(`${courseApiBaseURL}/catalog/threat-actors/${permalink}`),
};

const cybrarySelect = {
  getPaths: (query) => requests.get(`${courseApiBaseURL}/catalog/cybrary-select${query ? `?${query}` : ''}`),
  getByPermalink: (permalink) => requests.get(`${courseApiBaseURL}/catalog/cybrary-select/${permalink}`),
};

const collections = {
  getCollectionData: (id) => requests.get(`${courseApiBaseURL}/catalog/${id}/collections`),
};

const podcasts = {
  // by default gets latest podcasts by released at date
  // query options include recordsPerPage
  getPodcasts: (query) => requests.get(`${courseApiBaseURL}/podcasts${query ? `?${query}` : ''}`),
};

const feedback = {
  getOverallRatings: (id) => requests.get(`${courseApiBaseURL}/catalog/${id}/rating`),
  updateOrCreate: (id, data) => requests.put(`${courseApiBaseURL}/catalog/${id}/review`, data),
  getFeedback: (id) => requests.get(`${courseApiBaseURL}/catalog/${id}/review`),
};

const reports = {
  downloadScheduledReport: (teamId, downloadId) => requests.get(`${courseApiBaseURL}/team/${teamId}/report/result/${downloadId}/csv/signed`),

  getReportData: (teamId, reportId, query) => requests.get(`${courseApiBaseURL}/team/${teamId}/report/${reportId}${query}`),

  getFilterData: (teamId, aud) => {
    if (aud === 'group') {
      return requests.get(`${authApiBaseURL}/team/${teamId}/groups/data-list`);
    }
    return requests.get(`${authApiBaseURL}/team/${teamId}/members/data-list`);
  },

  /* Scheduled Reports */

  getScheduledReports: (teamId, query) => {
    return requests.get(`${courseApiBaseURL}/team/${teamId}/report/schedule${query}`);
  },

  getScheduledReport: (teamId, scheduleId) => {
    return requests.get(`${courseApiBaseURL}/team/${teamId}/report/schedule/report/${scheduleId}`);
  },

  addEditScheduledReport: (teamId, data) => {
    return requests.post(`${courseApiBaseURL}/team/${teamId}/report/schedule`, data);
  },

  deleteScheduledReport: (teamId, scheduleId) => {
    return requests.post(`${courseApiBaseURL}/team/${teamId}/report/schedule/delete/${scheduleId}`);
  },
};

const alerts = {
  getAlerts: () =>
    new Promise((resolve) => {
      resolve([
        {
          type: 'info',
          content: {
            title: '',
            message:
              'We are working to ensure the reporting data from our vendors is as accurate as possible. Use this button to perform a manual check for updates to your progress.',
            button: { text: 'Update', clickAction: 'request report' },
            divider: false,
          },
        },
      ]);
    }),

  updateLabProgress: () => requests.post(`${courseApiBaseURL}/reporting/practice-labs`),
};

const enrollments = {
  ensureSync: () => requests.post(`${courseApiBaseURL}/enrollments/sync`, {}),
  getEnrollments: () => requests.get(`${courseApiBaseURL}/enrollments/active?abridged=1`),
  getEnrollment: (contentDescriptionId) => requests.get(`${courseApiBaseURL}/catalog/${contentDescriptionId}/enrollment`),

  getCompletedEnrollments: () => requests.get(`${courseApiBaseURL}/enrollments/completed?abridged=1`),

  startEnrollment: (contentDescriptionId, data = {}, query = '') => requests.post(`${courseApiBaseURL}/catalog/${contentDescriptionId}/enroll${query}`, data),
  pauseEnrollment: (enrollmentId) => requests.put(`${courseApiBaseURL}/enrollment/${enrollmentId}/pause`, {}),
  resumeEnrollment: (enrollmentId) => requests.put(`${courseApiBaseURL}/enrollment/${enrollmentId}/resume`, {}),
  stopEnrollment: (contentId) => requests.post(`${courseApiBaseURL}/catalog/${contentId}/unenroll`, {}),
  saveEnrollmentSettings: (enrollmentId, settings) =>
    requests.put(`${courseApiBaseURL}/enrollment/${enrollmentId}/settings`, {
      settings,
    }),

  getEnrollmentById: (id) => requests.get(`${courseApiBaseURL}/enrollment/${id}`),

  getEnrollmentsList: (query) => requests.get(`${courseApiBaseURL}/enrollments/progress${query || ''}`),

  getAssignmentsList: (query) => requests.get(`${courseApiBaseURL}/assignments/progress${query || ''}`),

  getEnrollmentDetails: (enrollmentId, query) => {
    return requests.get(`${courseApiBaseURL}/enrollment/${enrollmentId}/progress${query || ''}`);
  },

  getEnrollmentActivity: (enrollmentId, query) => requests.get(`${courseApiBaseURL}/enrollment-activity/${enrollmentId}${query || ''}`),

  getAllScores: (contentDescriptionId, query) => requests.get(`${courseApiBaseURL}/catalog/${contentDescriptionId}/scores${query || ''}`),

  getScoreById: (scoreId) => requests.get(`${courseApiBaseURL}/score/${scoreId}`),

  getChildProgress: (contentDescriptionId, query) => requests.get(`${courseApiBaseURL}/catalog/${contentDescriptionId}/children/progress${query || ''}`),

  getWorkroleData: (enrollId) => requests.get(`${courseApiBaseURL}/enrollment-activity/${enrollId}/score`),

  getCurrentEnrollment: () => requests.get(`${courseApiBaseURL}/enrollment/current`),

  saveSessionVideoData: (sessionId, data) => requests.put(`${courseApiBaseURL}/video/${sessionId}/progress`, data),

  enroll: (id, data) => requests.post(`${courseApiBaseURL}/catalog/${id}/enroll`, data || {}),
};

const goals = {
  getUserGoals: (query) => requests.get(`${courseApiBaseURL}/goals${query || ''}`),

  getUserGoalsProgress: (query) => requests.get(`${courseApiBaseURL}/goals/progress${query || ''}`),

  getUserGoalDetails: (goalId, query) => requests.get(`${courseApiBaseURL}/goal/${goalId}/progress${query || ''}`),

  setGoalTracking: (goalId, data) => requests.put(`${courseApiBaseURL}/goal/${goalId}/track`, data),

  postUserGoal: (data) => requests.post(`${courseApiBaseURL}/goals`, data),

  putUserGoal: (goalId, data) => requests.put(`${courseApiBaseURL}/goal/${goalId}`, data),

  deleteUserGoal: (goalId) => requests.delete(`${courseApiBaseURL}/goal/${goalId}`),

  /* Team Goals */

  getTeamGoals: (teamId, query) => requests.get(`${courseApiBaseURL}/team/${teamId}/goals${query}`),

  postTeamGoal: (data, teamId) => requests.post(`${courseApiBaseURL}/team/${teamId}/goal`, data),

  getTeamGoal: (goalId, teamId) => requests.get(`${courseApiBaseURL}/team/${teamId}/goal/${goalId}`),

  putTeamGoal: (goalId, data, teamId) => requests.put(`${courseApiBaseURL}/team/${teamId}/goal/${goalId}`, data),

  getTeamGoalProgress: (teamId, goalId, query) => requests.get(`${courseApiBaseURL}/team/${teamId}/goal/${goalId}/progress${query || ''}`),

  deleteTeamGoal: (goalId, teamId) => requests.delete(`${courseApiBaseURL}/team/${teamId}/goal/${goalId}`),
};

const live = {
  getSeries: (id) => requests.get(`${courseApiBaseURL}/cybrary-live/series/${id}`),

  getSession: (id) => requests.get(`${courseApiBaseURL}/cybrary-live/session/${id}`),
};

const certificate = {
  getByContentDescriptionId: (id) => requests.get(`${courseApiBaseURL}/certificate-claims/${id}`),

  getCertificates: () => requests.get(`${courseApiBaseURL}/certificate-claims`),

  getPublicCertificates: (username) => requests.get(`${courseApiBaseURL}/username/${username}/certificate-claims`),

  retrieveCert: (certificateNumber) => requests.get(`${courseApiBaseURL}/certificate/${certificateNumber}/view`, 'blob'),
};

const enterprise = {
  // live training
  requestLiveTrainingSlackNotification: (teamId, contentDescriptionId, data) => requests.post(`${courseApiBaseURL}/team/${teamId}/live-training/${contentDescriptionId}`, data),

  // team/group member routes
  joinTeam: (data) => requests.post(`${authApiBaseURL}/team/invite`, data),
  getTeamMeta: (team) => requests.get(`${authApiBaseURL}/team/${team}/meta`),

  checkIfTeamMember: (team) => requests.get(`${authApiBaseURL}/team/${team}/isMember`),

  getMembersTable: (team, query) => requests.get(`${authApiBaseURL}/team/${team}/members/table${query || ''}`),

  getMemberProfile: (team, user) => requests.get(`${authApiBaseURL}/team/${team}/member/${user}`),

  getMembersList: (team, query) => requests.get(`${authApiBaseURL}/team/${team}/members/data-list${query || ''}`),

  getMemberGroupsTable: (team, user, query) => requests.get(`${authApiBaseURL}/team/${team}/member/${user}/groups/table${query || ''}`),

  getGroupsExcludingMemberList: (team, user) => requests.get(`${authApiBaseURL}/team/${team}/member/${user}/groups/inverse/data-list`),

  getGroupsTable: (team, query) => requests.get(`${authApiBaseURL}/team/${team}/groups/table${query || ''}`),

  getGroupsList: (team) => requests.get(`${authApiBaseURL}/team/${team}/groups/data-list`),

  getMembersNotInTeamTable: (group, query) => requests.get(`${authApiBaseURL}/team/${group}/members/inverse/table${query || ''}`),

  requestLicense: (team, data) => requests.post(`${authApiBaseURL}/org/${team}/request-license`, data),

  // TO-DO: Update Add On license request URL
  requestAddOnLicense: (team, data) => requests.post(`${authApiBaseURL}/org/${team}/request-license`, data),

  // team/group admin routes
  getTeamHierarchy: (team, query) => requests.get(`${authApiBaseURL}/team/${team}/groups/deep${query || ''}`),

  getInviteUrl: (team) => requests.get(`${authApiBaseURL}/team/${team}/invite-url`),

  getPendingInvites: (team, query) => requests.get(`${authApiBaseURL}/team/${team}/invites${query || ''}`),

  resendInvite: (team, id) => requests.post(`${authApiBaseURL}/team/${team}/invite/${id}/send`),

  deleteInvite: (team, id) => requests.delete(`${authApiBaseURL}/team/${team}/invite/${id}`),

  sendTeamInviteByEmail: (team, data) => requests.post(`${authApiBaseURL}/team/${team}/invites`, data),

  isAdmin: (team) => requests.get(`${authApiBaseURL}/team/${team}/isAdmin`),

  getMemberCerts: (orgId, userId) => requests.get(`${courseApiBaseURL}/team/${orgId}/user/${userId}/certificate-claims`),

  uploadTeamLogo: (team, data) => requests.post(`${authApiBaseURL}/team/${team}/logo-upload`, data),

  getDashboardData: (team) => requests.get(`${authApiBaseURL}/team/${team}/dashboard`),

  regenerateInviteToken: (team) => requests.put(`${authApiBaseURL}/team/${team}/regenerateInvite`),

  removeTeamMembers: (team, data) => requests.post(`${authApiBaseURL}/team/${team}/members/remove`, data),

  addTeamMember: (team, data) => requests.post(`${authApiBaseURL}/team/${team}/members/add`, data),

  setTeamMemberRoles: (team, data) => requests.put(`${authApiBaseURL}/team/${team}/members/roles`, data),

  addGroup: (team, data) => requests.post(`${authApiBaseURL}/team/${team}/group`, data),

  editGroup: (team, group, data) => requests.put(`${authApiBaseURL}/team/${team}/group/${group}`, data),

  deleteGroups: (team, data) => requests.put(`${authApiBaseURL}/team/${team}/groups/delete`, data),

  getCurricula: (teamId, query) => requests.get(`${courseApiBaseURL}/org/${teamId}/curricula${query || ''}`),

  getCurriculaForAdmin: (teamId) => requests.get(`${courseApiBaseURL}/admin/org/${teamId}/curricula`),

  cloneCurriculaForAdmin: (teamId, ids) => requests.post(`${courseApiBaseURL}/admin/org/${teamId}/curricula/clone`, ids),

  getCybraryCurriculaUpdates: () => requests.get(`${courseApiBaseURL}/curricula/cybrary/updated`),

  updateCurriculaVisibility: (teamId, payload) => requests.put(`${courseApiBaseURL}/team/${teamId}/curricula/visibility`, payload),

  getCurriculum: (teamId, id) => requests.get(`${courseApiBaseURL}/org/${teamId}/curriculum/${id}`),

  postCurriculum: (teamId, data) => requests.post(`${courseApiBaseURL}/team/${teamId}/curriculum`, data),

  postCurriculumImg: (teamId, contentDescriptionId, data) => requests.post(`${courseApiBaseURL}/team/${teamId}/curriculum/${contentDescriptionId}/image`, data),

  putCurriculum: (teamId, id, data) => requests.put(`${courseApiBaseURL}/team/${teamId}/curriculum/${id}`, data),

  deleteCurriculum: (teamId, id) => requests.post(`${courseApiBaseURL}/team/${teamId}/curriculum/${id}/delete`),

  manageLicenses: (team, data) => requests.put(`${authApiBaseURL}/org/${team}/members/licenses`, data),

  editOrgSettings: (team, data) => requests.put(`${authApiBaseURL}/org/${team}/settings`, data),

  addAssignment: (teamId, data) => requests.post(`${courseApiBaseURL}/team/${teamId}/assignment`, data),

  editAssignment: (teamId, data, assignmentId) => requests.put(`${courseApiBaseURL}/team/${teamId}/assignment/${assignmentId}`, data),

  deleteAssignment: (teamId, assignmentId) => requests.post(`${courseApiBaseURL}/team/${teamId}/assignments/${assignmentId}/delete`),

  getTeamAssignments: (teamId, query) => requests.get(`${courseApiBaseURL}/team/${teamId}/assignments/active${query || ''}`),

  getAssignment: (teamId, assignmentId) => requests.get(`${courseApiBaseURL}/team/${teamId}/assignment/${assignmentId}`),

  getTeamPastAssignments: (teamId, query) => requests.get(`${courseApiBaseURL}/team/${teamId}/assignments/past${query || ''}`),

  getAssignmentUsers: (assignId, teamId) => requests.get(`${courseApiBaseURL}/team/${teamId}/assignment/${assignId}/users`),

  putAssignment: (assignId, teamId, userId, data) => requests.put(`${courseApiBaseURL}/team/${teamId}/assignment/${assignId}/user/${userId}`, data),

  /* Analytics */
  getUnassignedLearning: (teamId, userId, query) => requests.get(`${courseApiBaseURL}/team/${teamId}/user/${userId}/enrollments/progress${query || ''}`),

  getDashboardAssignments: (teamId, userId, query) => requests.get(`${courseApiBaseURL}/team/${teamId}/user/${userId}/assignments/progress${query || ''}`),

  getDashboardAssignmentDetails: (teamId, enrollId, query) => requests.get(`${courseApiBaseURL}/team/${teamId}/enrollment/${enrollId}/progress/${query || ''}`),

  getDashboardGoals: (teamId, userId, query) => requests.get(`${courseApiBaseURL}/team/${teamId}/user/${userId}/goals/progress${query || ''}`),

  getAssignmentDashboardMembers: (teamId, assignmentId, query) => requests.get(`${courseApiBaseURL}/team/${teamId}/assignment/${assignmentId}/progress${query || ''}`),

  getLeaderboard: (teamId, metric, query) => requests.get(`${courseApiBaseURL}/team/${teamId}/report/leaderboard-${metric}${query || ''}`),

  getWorkroleData: (teamId, enrollId) => requests.get(`${courseApiBaseURL}/team/${teamId}/enrollment-activity/${enrollId}/score`),

  getTeamMemberAllScores: (teamId, userId, contentDescriptionId, query) =>
    requests.get(`${courseApiBaseURL}/team/${teamId}/user/${userId}/catalog/${contentDescriptionId}/scores${query || ''}`),

  /** Onboarding */
  /**
   *
   * @param {int} teamId  The team id
   * @param {string} data.role The teams role (l&d, security, etc.)
   * @param {string} data.role_other The custom team role if other is selected
   * @param {string[]} data.goals The teams goals
   * @param {string} data.learning_approach The teams learning approach
   * @returns {Promise} - The response from the server
   */
  putTeamOnboardingData: (
    teamId,
    data = {
      role: '',
      role_other: '',
      goals: [''],
      learning_approach: '',
    }
  ) => requests.put(`${authApiBaseURL}/org/${teamId}/onboarding`, data),

  /* Content Preferences */
  getContentPreferences: (teamId, query) => requests.get(`${courseApiBaseURL}/team/${teamId}/preferences/category-terms${query}`),

  setContentPreferences: (teamId, data) => requests.post(`${courseApiBaseURL}/team/${teamId}/preferences/category-terms`, data),

  deleteContentPreferences: (teamId, data) => requests.post(`${courseApiBaseURL}/team/${teamId}/preferences/category-terms/delete`, data),

  /* CLAB Beta */
  getClabBetaInvites: (teamId) => requests.get(`${authApiBaseURL}/team/${teamId}/clab-invites`),

  postClabBetaInvites: (teamId, data) => requests.post(`${authApiBaseURL}/team/${teamId}/clab-invites`, data),

  /* Bulk Team Invite/Group Import */
  getImportTemplate: (type, team) => requests.get(`${authApiBaseURL}/org/${team}/csv-import/${type}/template`),

  getImportCSV: (team, id, type) => requests.get(`${authApiBaseURL}/org/${team}/csv-import/${type}/${id}/csv`),

  getImports: (team, type) => requests.get(`${authApiBaseURL}/org/${team}/csv-import/${type}`),

  postImport: (team, data, type) => requests.post(`${authApiBaseURL}/org/${team}/csv-import/${type}`, data),

  /* Team Activity Logs */
  getActivityLogs: (team, query) => requests.get(`${authApiBaseURL}/org/${team}/activity-logs${query || ''}`),

  getActivityLogsEvents: (team) => requests.get(`${authApiBaseURL}/org/${team}/activity-logs/events`),

  /* SSO */
  getSso: (orgId) => requests.get(`${authApiBaseURL}/org/${orgId}/sso`),

  postSSO: (orgId, data) => requests.post(`${authApiBaseURL}/org/${orgId}/sso`, data),

  putSso: (orgId, id, data) => requests.put(`${authApiBaseURL}/org/${orgId}/sso/${id}`, data),

  deleteSso: (orgId, id) => requests.delete(`${authApiBaseURL}/org/${orgId}/sso/${id}`),

  getSsoAttributeMap: (orgId, providerId) => requests.get(`${authApiBaseURL}/org/${orgId}/sso/${providerId}/attributes`),

  updateSsoAttributeMap: (orgId, providerId, data) => requests.put(`${authApiBaseURL}/org/${orgId}/sso/${providerId}/attributes`, { attributes: data }),

  deleteSsoAttributeMap: (orgId, providerId, fieldId) => requests.delete(`${authApiBaseURL}/org/${orgId}/sso/${providerId}/attributes/${fieldId}`),
};

const admin = {
  checkAccess: () => requests.get(`${courseApiBaseURL}/admin/`),

  getTeamHierarchy: (team, query) => requests.get(`${authApiBaseURL}/admin/team/${team}/groups/deep${query || ''}`),

  getTeamListing: (query) => requests.get(`${authApiBaseURL}/admin/teams/table${query || ''}`),

  getScoreById: (scoreId) => requests.get(`${courseApiBaseURL}/score/${scoreId}`),

  getAllScores: (contentDescriptionId, query) => requests.get(`${courseApiBaseURL}/catalog/${contentDescriptionId}/scores${query || ''}`),

  getWorkroleData: (enrollId) => requests.get(`${courseApiBaseURL}/enrollment-activity/${enrollId}/score`),

  getEnrollmentDetails: (enrollmentId, query) => {
    return requests.get(`${courseApiBaseURL}/enrollment/${enrollmentId}/progress${query || ''}`);
  },
  createTeam: (data) => requests.post(`${authApiBaseURL}/admin/team`, data),

  editTeam: (data, team) => requests.put(`${authApiBaseURL}/admin/team/${team}`, data),

  deleteTeam: (team) => requests.post(`${authApiBaseURL}/admin/team/${team}/delete`),

  setSsoStatus: (data, team) => requests.put(`${authApiBaseURL}/admin/team/${team}/sso`, data),

  getTeamData: (team) => requests.get(`${authApiBaseURL}/admin/team/${team}`),

  getTeamPrograms: (team, query) => requests.get(`${courseApiBaseURL}/admin/org/${team}/programs${query || ''}`),

  assignTeamProgram: (team, data) => requests.post(`${courseApiBaseURL}/admin/org/${team}/programs`, data),

  getUserData: (user) => requests.get(`${authApiBaseURL}/admin/user/${user}`),

  disableUser: (user, data) => requests.post(`${authApiBaseURL}/admin/user/${user}/disabled`, data),

  getUnverifiedUserData: (id) => requests.get(`${authApiBaseURL}/admin/user/unverified/${id}`),

  getTeamMembersData: (team, query) => requests.get(`${authApiBaseURL}/admin/team/${team}/members${query || ''}`),

  getUserLookupTableData: (query) => requests.get(`${authApiBaseURL}/admin/user/search${query || ''}`),

  getVendorResultsTableData: (userId, query) => requests.get(`${courseApiBaseURL}/admin/vendor-results/user/${userId}?format=table&${query}`),

  syncVendorResults: (userId, data) => requests.post(`${courseApiBaseURL}/admin/vendor-results/user/${userId}/sync`, data),

  getChallengeCourses: (query) => requests.get(`${courseApiBaseURL}/admin/challenge/courses${query}`),

  getChallengeData: (contentDescriptionId) => requests.get(`${courseApiBaseURL}/admin/challenge/${contentDescriptionId}/activities`),

  getCurrentEnrollment: () => requests.get(`${courseApiBaseURL}/enrollment/current`),

  getAssignmentsList: () => requests.get(`${courseApiBaseURL}/assignments/progress`),

  getEnrollmentsList: (query) => requests.get(`${courseApiBaseURL}/enrollments/progress${query || ''}`),

  getCertificates: () => requests.get(`${courseApiBaseURL}/certificate-claims`),

  addMembers: (team, data) => requests.post(`${authApiBaseURL}/admin/team/${team}/members/add`, data),

  removeMembers: (team, data) => requests.post(`${authApiBaseURL}/admin/team/${team}/members/remove`, data),

  changeRole: (team, data) => requests.put(`${authApiBaseURL}/admin/team/${team}/members/roles`, data),

  expireUserContentLicense: (userId, licenseId) => requests.put(`${authApiBaseURL}/admin/user/${userId}/license/${licenseId}/expire`, {}),

  uploadTeamLogo: (team, data) => requests.post(`${authApiBaseURL}/admin/team/${team}/logo-upload`, data),

  getTeamMembersAdminSearch: (team, query) => requests.get(`${authApiBaseURL}/admin/team/${team}/members/inverse/table${query || ''}`),

  downloadCourseCatalog: () => requests.get(`${courseApiBaseURL}/admin/catalog/export`),

  jwt: (search) => requests.post(`${authApiBaseURL}/admin/jwt`, { search }),

  /* Bulk Team Invite/Group Import */
  getImportTemplate: (type) => requests.get(`${authApiBaseURL}/admin/csv-import/${type}/template`),

  getImportCSV: (team, id, type) => requests.get(`${authApiBaseURL}/admin/org/${team}/csv-import/${type}/${id}/csv`),

  getImports: (team, type) => requests.get(`${authApiBaseURL}/admin/org/${team}/csv-import/${type}`),

  postImport: (team, data, type) => requests.post(`${authApiBaseURL}/admin/org/${team}/csv-import/${type}`, data),

  /* Team Activity Logs */
  getActivityLogs: (team, query) => requests.get(`${authApiBaseURL}/admin/org/${team}/activity-logs${query || ''}`),

  getActivityLogsEvents: (team) => requests.get(`${authApiBaseURL}/admin/org/${team}/activity-logs/events`),
};

const profile = {
  getProfileData: (section) => requests.get(`${authApiBaseURL}/profile/${section}`),

  getProfileStats: () => requests.get(`${courseApiBaseURL}/profile-stats`),

  /**
   * Fetches public profile stats for a given username
   * @param {string} username
   * @returns {Promise}
   */
  getPublicProfileStats: (username) => requests.get(`${courseApiBaseURL}/username/${username}/profile-stats`),

  getUserAccounts: () => requests.get(`${authApiBaseURL}/user/accounts`),

  putProfileData: (section, data) => requests.put(`${authApiBaseURL}/profile/${section}`, data),
  updateProfilePrivacy: (data) => requests.put(`${authApiBaseURL}/talent/profile/public`, data),
  postProfileData: (section, data) => requests.post(`${authApiBaseURL}/user/${section}`, data),

  deleteBackupEmail: (data) => requests.post(`${authApiBaseURL}/user/accounts/delete`, data),

  changePrimaryEmail: (data) => requests.put(`${authApiBaseURL}/user/accounts/primary`, data),

  getProfileTable: (section) => requests.get(`${authApiBaseURL}/profile/${section}/table`),

  putProfileEmail: (data) => requests.put(`${authApiBaseURL}/profile/email`, data),

  putTosOptIn: (data) => requests.put(`${authApiBaseURL}/profile/opt-in`, data),

  uploadAvatarImage: (data) => requests.post(`${authApiBaseURL}/talent/profile/image`, data),

  talentOptIn: (data) => requests.put(`${authApiBaseURL}/profile/set-talent-profile`, data),

  /* Content Preferences */
  getContentPreferences: (query) => requests.get(`${courseApiBaseURL}/preferences/category-terms${query}`),

  setContentPreferences: (data) => requests.post(`${courseApiBaseURL}/preferences/category-terms`, data),

  deleteContentPreferences: (data) => requests.post(`${courseApiBaseURL}/preferences/category-terms/delete`, data),

  /* User Profile  */
  getTerms: (input, endpoint) => requests.get(`${termsService}/${endpoint}?q=${input}`),

  createTerm: (endpoint, data) => requests.post(`${termsService}/${endpoint}`, data),

  getProfile: () => requests.get(`${authApiBaseURL}/talent/profile`),

  /**
   * Fetches public profile data for a given username
   * @param {string} username
   * @returns {Promise} Promise object representing the public profile data
   * @example { "personal": [ "username": "AbleAnt1234", ... ], "certifications": [ //standard certifications info ] }
   */
  getPublicProfile: (username) => requests.get(`${authApiBaseURL}/talent/username/${username}/profile`),

  updateProfile: (data) => requests.patch(`${authApiBaseURL}/talent/profile`, data),

  updateLanguagePreference: (data) => requests.put(`${authApiBaseURL}/talent/profile/language`, data),

  /**
   * Route to get user level, and experience points - current and to next level
   * @returns {Promise} { level: 2, experience_points: 300, next_level_experience_points: 200 }
   */
  getExperiencePoints: () => requests.get(`${courseApiBaseURL}/experience-points`),

  /**
   * Load the Learning Progress stats for a given user based on username
   * @param {string} username - The username of the user
   * @param {string} query - Additional query string, specifying framework and unit of measure
   * @returns Request result, an array of learning progress data
   */
  getProfileLearningProgress: (username, query = '') => requests.get(`${courseApiBaseURL}/username/${username}/progress-taxonomy-interval${query}`),
};

const location = {
  mapBoxGeocoding: (data) => requests.post(`${authApiBaseURL}/talent/geocode?query=${data}`),
};

const forms = {
  submitPqlForm: (data) => requests.post(`${courseApiBaseURL}/form/pql`, data),

  submitBusinessForm: (data) => requests.post(`${courseApiBaseURL}/form/business`, data),

  submitCatForm: (data) => requests.post(`${authApiBaseURL}/chargebee/checkout`, data),

  getForm: (permalink) => requests.get(`${courseApiBaseURL}/form/${permalink}`),
  submitForm: (permalink, data) => requests.post(`${courseApiBaseURL}/form/${permalink}/submit`, data),

  postUserSecurityQuestion: (data) => requests.post(`${authApiBaseURL}/okta/user/security`, data),
};

const contentVersioning = {
  getContentReplacement: (id) => requests.get(`${courseApiBaseURL}/content-replacement/${id}`),
  getEnrollmentsReplaced: () => requests.get(`${courseApiBaseURL}/enrollments/replaced`),
  getContentReplacementProgress: (id) => requests.get(`${courseApiBaseURL}/catalog/${id}/progress?unenrolledProgress=1`),
  getReplacedContent: (id) => requests.get(`${courseApiBaseURL}/catalog/${id}/replaced-content`),
};

const subscriptions = {
  createTeam: (subscriptionId) => requests.post(`${authApiBaseURL}/chargebee/subscription/team`, subscriptionId),
  chargebeePortal: () => requests.get(`${authApiBaseURL}/chargebee/portal`),
};

const mitre = {
  getMitreTactics: (query) => requests.get(`${mitreApiBaseURL}/tactics${query || ''}`),
  getSubscriptions: () => requests.get(`${courseApiBaseURL}/preferences/category-terms`),
  addSubscription: (data) => requests.post(`${courseApiBaseURL}/preferences/category-terms/other`, data),
  syncSubscriptions: (data) => requests.put(`${courseApiBaseURL}/preferences/category-terms/other`, data),
  removeSubscription: (data) => requests.post(`${courseApiBaseURL}/preferences/category-terms/other/delete`, data),
};

const paddle = {
  /**
   * Gets all available prices from the backend
   * @returns {Promise} All available prices from the backend's cache
   */
  getPrices: () => requests.get(`${authApiBaseURL}/paddle/prices`),
  /**
   * Sends transaction id to backend to verify payment and upgrade user account accordingly
   * Used to sync the most recent purchase with our backend
   * @param {string} transaction_id Paddle transaction ID
   * @returns {Promise} Promise object representing the successful payment response from the backend
   */
  syncSubscription: (transaction_id) => requests.post(`${authApiBaseURL}/paddle/subscription/sync`, { transaction_id }),
  /**
   * Get transaction preview for an array of products with a given discount
   * @param {Array} items (Required) Array of products ex: [{ price_id: 'pri_123', quantity: 1 }]
   * @param {string} postalCode (Required) Postal code of the user
   * @param {string} countryCode (Required) Country code of the user
   * @param {string} discountId Paddle discount ID ex: 'dis_123'
   * @returns {Promise} Promise object representing the checkout preview response from paddle
   * @see https://developer.paddle.com/v1/api-reference/transactions/preview-transaction  Transaction Preview API Docs
   */
  getTransactionPreview: ({ items, discountId = null, postalCode = '12345', countryCode = 'US' }) =>
    requests.post(`${authApiBaseURL}/paddle/estimate`, { discount_id: discountId, items, address: { postal_code: postalCode, country_code: countryCode } }),
  /**
   * Cancels a subscription
   * @param {string} subscription_id Paddle subscription ID
   * @param {boolean} immediatly If true, the subscription will be cancelled immediately. If false, the subscription will be cancelled at the end of the current billing period.
   * @returns {Promise} Promise object representing the cancelled subscription response from paddle
   * @see https://developer.paddle.com/v1/api-reference/subscriptions/cancel-subscription  Cancel Subscription API Docs
   */
  cancelSubscription: ({ subscription_id, immediatly = false }) => requests.post(`${authApiBaseURL}/paddle/subscription/${subscription_id}/cancel`, { immediatly }),
  /**
   * Gets a preview of a subscription update
   * @param {string} subscription_id (Required) Paddle subscription ID
   * @param {Array} items Array of products ex: [{ price_id: 'pri_123', quantity: 1 }]
   * @param {string} discount_id Paddle discount ID ex: 'dis_123'
   * @returns {Promise} Promise object representing the subscription update preview response from paddle
   * @see https://developer.paddle.com/v1/api-reference/subscriptions/preview-subscription  Preview Subscription Update API Docs
   */
  getSubscriptionUpdatePreview: ({ subscription_id, items, discount_id }) =>
    requests.post(`${authApiBaseURL}/paddle/subscription/${subscription_id}/previewUpdate`, { items, discount_id }),
  /**
   * Updates the subscription of the given id with the given updates
   * @param {string} subscription_id (Required) Paddle subscription ID
   * @param {Array} items Array of items to be updated ex: [{ price_id: 'pri_123', quantity: 1 }]
   * @param {string} discount_id Paddle discount ID ex: 'dis_123'
   * @returns {Promise} Promise object representing the successful subscription update response from the backend
   * @see https://developer.paddle.com/v1/api-reference/subscriptions/update-subscription  Update Subscription API Docs
   */
  updateSubscription: ({ subscription_id, items, discount_id }) => requests.post(`${authApiBaseURL}/paddle/subscription/${subscription_id}/update`, { items, discount_id }),
  /**
   * Get a subscription from paddle by subscription id
   * @param {string} subscription_id (Required) Paddle subscription ID
   * @returns {Promise} Promise object representing the subscription portal link response from the backend
   */
  getSubscription: (subscription_id) => requests.get(`${authApiBaseURL}/paddle/subscription/${subscription_id}`),
};

/**
 * Discourse API uses route:
 * api/discourse/api/{discoursePath}
 *
 * any query params will be passed along.
 * It makes the request on behalf of the logged in user.
 */
const discourse = {
  /**
   * Auths user with Discourse and redirects to the Discourse forum after successful authentication
   * @param {*} data { sso, sig } data from discourse to authenticate user
   * @returns {Promise} Promise object representing the successful authentication response from the backend
   */
  auth: ({ sso, sig }) => requests.post(`${authApiBaseURL}/discourse/auth`, { sso, sig }),
  /**
   * Fetches top topics from discourse
   * @param {object} options (Optional) Options object to pass to the request
   * @returns {Promise} Promise object representing the top topics response from the backend
   * @see https://docs.discourse.org/#tag/Topics/operation/listTopTopics
   */
  getTopTopics: (options = {}) => requests.get(`${authApiBaseURL}/discourse/api/top?${new URLSearchParams(options).toString()}`),
  /**
   * Fetches all categories from discourse
   * @param {object} options (Optional) Options object to pass to the request
   * @returns {Promise} Promise object representing the categories response from the backend
   * @see https://docs.discourse.org/#tag/Categories/operation/listCategories
   */
  getCategories: (options = {}) => requests.get(`${authApiBaseURL}/discourse/api/categories?${new URLSearchParams(options).toString()}`),
  /**
   * Fetches topics for a given category from discourse
   * @param {string} categorySlug (Required) Slug of the category to fetch topics for i.e. "cert-prep/cissp"
   * @param {string} categoryId (Required) Category ID i.e. 8
   * @param {string} filter (Optional) Filter to apply to the topics i.e. latest, unread, new, unseen, top, read, posted, bookmarks
   * @param {object} options (Optional) Options object to pass to the request
   * @returns {Promise} Promise object representing the topics for the given category response from the backend
   * @see https://meta.discourse.org/t/how-can-i-filter-paginate-the-list-categorytopics-route/290560/2
   */
  getTopicsByCategory: (categorySlug, categoryId, filter = 'top', options = {}) =>
    requests.get(`${authApiBaseURL}/discourse/api/c/${categorySlug}/${categoryId}/l/${filter}?${new URLSearchParams(options).toString()}`),
  /**
   * Performs a search on discourse with the given query
   * @param {string} query (Required) Search query
   * @returns {Promise} Promise object representing the search results response from the backend
   * @see https://docs.discourse.org/#tag/Search
   * @see https://meta.discourse.org/t/how-to-retrieve-all-topics-of-a-category/287557
   */
  getSearchResults: (query) => requests.get(`${authApiBaseURL}/discourse/api/search?q=${query}`),
};

/**
 * The abm object contains API functions related to company management.
 * @namespace abm
 */
const abm = {
  /**
   * Retrieves the company information. Entry point for company management/ABM Page data.
   * @function getCompany
   * @returns {Promise} A promise that resolves to the company data.
   */
  getCompany: () => requests.get(`${authApiBaseURL}/company`),

  /**
   * Sets the company information. Used to add company email to the user's profile.
   * @function setCompany
   * @param {Object} data - The company data to be set.
   * @returns {Promise} A promise that resolves when the company data is successfully set.
   */
  setCompany: (data) => requests.post(`${authApiBaseURL}/company`, data),

  /**
   * Verifies the company information. Used to verify the company email.
   * @function verifyCompany
   * @param {Object} data - The company data to be verified.
   * @returns {Promise} A promise that resolves when the company data is successfully verified.
   */
  verifyCompany: (data) => requests.post(`${authApiBaseURL}/company/verify`, data),

  /**
   * Resend the company verification email.
   * @function resendCompanyVerification
   * @returns {Promise} A promise that resolves when the company verification email is successfully resent.
   */
  resendCompanyVerification: () => requests.post(`${authApiBaseURL}/company/verify/send`),

  /**
   * Deletes the company information. Used to change email or remove company email from the user's profile.
   * @function deleteCompany
   * @returns {Promise} A promise that resolves when the company data is successfully deleted.
   */
  deleteCompany: () => requests.delete(`${authApiBaseURL}/company`),

  /**
   * Retrieves the dashboard data for a specific team including enrollments, progress, etc.
   * @function getDashboardData
   * @param {string} teamId - The ID of the team.
   * @returns {Promise} A promise that resolves to the dashboard data.
   */
  getDashboardData: (teamId) => requests.get(`${courseApiBaseURL}/team/${teamId}/company-dashboard`),
};

/**
 * The teamsLicenseCheck object contains additional API functions related to team management and joining.
 * @namespace teamsLicenseCheck
 */
const teamsLicenseCheck = {
  /**
   * Initiates the team join process for a user.
   * @function initiateTeamJoin
   * @param {Object} data - The data object containing the email.
   * @param {string} data.email - The email of the user initiating the join process.
   * @returns {Promise} A promise that resolves when the join process is initiated.
   */
  initiateTeamJoin: (data) => requests.post(`${authApiBaseURL}/user/accounts/team-join`, data),

  /**
   * Verifies the email code for the team join process for a user.
   * @function verifyTeamJoin
   * @param {Object} data - The data object containing the email and verification code.
   * @param {string} data.email - The email of the user verifying the join process.
   * @param {string} data.code - The verification code.
   * @returns {Promise} {"result": <code>}  where codes are team_not_found, added_to_team, team_join_requested
   */
  verifyTeamJoin: (data) => requests.post(`${authApiBaseURL}/user/accounts/team-join/verify`, data),

  /**
   * Used by a team admin to retrieve the list of join requests for a specific team.
   * @function getTeamJoinRequests
   * @param {string} teamId - The ID of the team.
   * @param {string} query - The query string to pass to the request.
   * @returns {Promise} A promise that resolves to the list of join requests.
   */
  getTeamJoinRequests: (teamId, query) => requests.get(`${authApiBaseURL}/team/${teamId}/join-requests${query || ''}`),

  /**
   * Used by a team admin to approve or deny a team join request.
   * @function respondToTeamJoinRequest
   * @param {string} teamId - The ID of the team.
   * @param {string} teamJoinRequestId - The ID of the join request.
   * @param {Object} data - The data object containing the approval status.
   * @param {number} data.approved - 1 for approval, 0 for denial.
   * @returns {Promise} A promise that resolves when the join request is processed.
   */
  respondToTeamJoinRequest: (teamId, teamJoinRequestId, data) => requests.post(`${authApiBaseURL}/team/${teamId}/join-requests/${teamJoinRequestId}`, data),
};

/**
 * Career Program / Career Quest / Career Journey / Career Adventure API functions
 */
const careerPrograms = {
  /**
   * Retrieves all career programs.
   * @param {string|number} contentTypeId - (Optional) The content type ID of the career programs to retrieve.
   * @returns {Promise} A promise that resolves to the career program data.
   */
  getCareerPrograms: ({ contentTypeId } = {}) => requests.get(`${courseApiBaseURL}/programs${contentTypeId ? `?contentTypeId=${contentTypeId}` : ''}`),
  /**
   * Retrieves the currently enrolled career program data for the current user.
   * @returns {Promise} A promise that resolves to the career program data.
   */
  getCareerProgram: () => requests.get(`${courseApiBaseURL}/program`),
  /**
   * Retrieves the specified career program by contentDescriptionId.
   * The object returned will be the most recent enrollment in that program, or
   * the unenrolled progress if the user has not enrolled in that program.
   * @param {number} contentDescriptionId - The ID of the content description to retrieve.
   * @returns {Promise} A promise that resolves to the career program data.
   */
  getCareerProgramById: (contentDescriptionId) => requests.get(`${courseApiBaseURL}/program/${contentDescriptionId}`),
  /**
   * Updates the current user's career program.
   * @param {number} content_description_id - The ID of the content description to set as the user's career program.
   * @returns {Promise} A promise that resolves when the career program data is successfully updated.
   */
  setCareerProgram: (content_description_id) => requests.put(`${courseApiBaseURL}/program`, { content_description_id }),
  /**
   * Deletes the current user's career program.
   * @returns {Promise} A promise that resolves when the career program data is successfully deleted.
   */
  deleteCareerProgram: () => requests.delete(`${courseApiBaseURL}/program`),
  /**
   * Claims the credly badge for the current user's career program.
   */
  claimProgramBadge: () => requests.post(`${courseApiBaseURL}/program/claim-cert`),
};

/**
 * Measure API client
 */
const measure = {
  /**
   * Retrieves collection of top-level data of baseline goals for team learner (auth user).
   * @returns {Promise} A promise that resolves to an array of learner baseline goals.
   */
  getLeanerBaselineGoals: () => requests.get(`${courseApiBaseURL}/baselining-goals`),
  /**
   * Retrieves detailed data of a baseline goal for a team learner (auth user).
   * @returns {Promise} A promise that resolves to detail data of a learner baseline goal.
   */
  getLeanerBaselineGoal: (goalId) => requests.get(`${courseApiBaseURL}/baselining-goal/${goalId}`),
  /**
   * Retrieves collection of top-level data of baseline goals and topic scores for a team.
   * @returns {Promise} A promise that resolves to an array of team baseline goals and topic scores.
   */
  getTeamBaselineGoals: (orgId, contentDescriptionId) => requests.get(`${courseApiBaseURL}/team/${orgId}/baselining-goals/path/${contentDescriptionId}`),
  /**
   * Retrieves detailed data of a baseline goal for a team.
   * @returns {Promise} A promise that resolves to detail data of a team baseline goal.
   */
  getTeamBaselineGoal: (orgId, goalId) => requests.get(`${courseApiBaseURL}/team/${orgId}/baselining-goal/${goalId}`),
  /**
   * Retrieves progress data for team pre-assessment baseline goal.
   * @returns {Promise} A promise that resolves to progress data for team pre-assessment baseline goal.
   */
  getPreAssessmentGoalProgress: (orgId, goalId, query) => requests.get(`${courseApiBaseURL}/team/${orgId}/baselining-goal/${goalId}/pre-assessment-progress${query}`),
  /**
   * Retrieves progress data for team remediation baseline goal.
   * @returns {Promise} A promise that resolves to progress data for team remediation baseline goal.
   */
  getRemediationGoalProgress: (orgId, goalId, query) => requests.get(`${courseApiBaseURL}/team/${orgId}/baselining-goal/${goalId}/remediation-progress${query}`),
  /**
   * Retrieves progress data for team post-assessment baseline goal.
   * @returns {Promise} A promise that resolves to progress data for team post-assessment baseline goal.
   */
  getPostAssessmentGoalProgress: (orgId, goalId, query) => requests.get(`${courseApiBaseURL}/team/${orgId}/baselining-goal/${goalId}/post-assessment-progress${query}`),
  /**
   * Creates a baseline goal.
   * @returns {Promise} A promise that resolves when the baseline goal is successfully created.
   */
  createBaselineGoal: (orgId, data) => requests.post(`${courseApiBaseURL}/team/${orgId}/baselining-goal`, data),
  /**
   * Updates a baseline goal.
   * @returns {Promise} A promise that resolves when the baseline goal is successfully updated.
   */
  updateBaselineGoal: (orgId, goalId, data) => requests.put(`${courseApiBaseURL}/team/${orgId}/baselining-goal/${goalId}`, data),
  /**
   * Deletes a baseline goal.
   * @returns {Promise} A promise that resolves when the baseline goal is successfully deleted.
   */
  deleteBaselineGoal: (orgId, goalId) => requests.delete(`${courseApiBaseURL}/team/${orgId}/baselining-goal/${goalId}`),
};

/**
 * Proxied API services
 */
const proxy = {
  /**
   * Perform an algolia search.
   * @returns {Promise} A promise that resolves to an algolia REST API search response
   */
  fetchFromAlgolia: (params, index = '*') => requests.post(`${courseApiBaseURL}/algolia/${index}/search`, params),
};

export default {
  abm,
  admin,
  alerts,
  assessment,
  cybAssessment,
  cybAssessmentNew,
  auth,
  authGoogle,
  careerPaths,
  careerPrograms,
  catalog,
  certificate,
  checklist,
  collections,
  contentVersioning,
  cybrarySelect,
  enterprise,
  enrollments,
  feedback,
  forms,
  goals,
  learningSchedule,
  live,
  location,
  measure,
  mitre,
  notifications,
  podcasts,
  profile,
  reports,
  subscriptions,
  teams,
  teamsLicenseCheck,
  threatActors,
  paddle,
  discourse,
  proxy,
};
