import { observable, action, makeObservable } from 'mobx';
import moment from 'moment';
import Bugsnag from '@bugsnag/js';
import FormatUtil from '../utils/formatUtil';
import Agents from '../agents/agents';

class UserSettingsStore {
  /* Learning Scheduler */
  learningSchedule = {
    loading: true,
    error: false,
    data: {},
  };

  setDefaultLearningSchedule = () => {
    this.learningSchedule = {
      loading: true,
      error: false,
      data: {},
    };
  };

  learningScheduleDayMap = {
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
    Sunday: 7,
  };

  // Transform backend format which uses numbers for days to use a map by day text, and convert UTC times to EST/EDT
  transformLearningSchedules = (data) => {
    const formattedData = {};
    if (data && data.length) {
      const daysMap = FormatUtil.objectFlip(this.learningScheduleDayMap);
      data.forEach((day) => {
        const convertedSchedule = this.convertTimes('est', { start_time: day.start_time, end_time: day.end_time }, day.weekday);
        const dayString = daysMap[convertedSchedule.weekday];
        formattedData[dayString] = {
          start_time: convertedSchedule.start_time,
          end_time: convertedSchedule.end_time,
        };
      });
    }
    return formattedData;
  };

  getLearningSchedule = () => {
    return Agents.learningSchedule
      .getLearningSchedule()
      .then(
        action('fetchSuccess', (response) => {
          this.learningSchedule.loading = false;
          if (response && response.schedules && response.schedules.length) {
            this.learningSchedule.data = this.transformLearningSchedules(response.schedules);
          }
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.learningSchedule.loading = false;
          this.learningSchedule.error = error;
        })
      );
  };

  setLearningScheduleDays = (day, selected) => {
    if (day) {
      const schedule = { ...this.learningSchedule.data };
      if (selected) {
        schedule[day] = {
          start_time: '12:00',
          end_time: '12:30',
        };
      } else {
        delete schedule[day];
      }
      this.learningSchedule.data = schedule;
    }
  };

  setLearningScheduleTime = (day, timeType, time) => {
    if (this.learningSchedule.data[day]) {
      const dataCopy = { ...this.learningSchedule.data };
      dataCopy[day][timeType] = time;
      this.learningSchedule.data = dataCopy;
    }
  };

  /* 
    Backend stores in UTC. Frontend displays in EST/EDT. Need to convert times
    Backend only cares about start_time to send notification reminder - end_time is just for front display and ics export
    If start_time goes over/under the 24 hour clock it rolls into next/previous day (end_time effectively does too then).
    If ONLY end_time rolls over 24 hour clock, it just rolls the time but not the day, since value isn't used for anything on backend
  */
  convertTimes = (tzTo, times, day) => {
    const convertedTimes = {
      weekday: day,
    };
    // Is daylight savings?
    const isDST = moment().tz('America/New_York').isDST();
    const tzOffset = isDST ? 4 : 5;
    Object.keys(times).forEach((timeType) => {
      const time = times[timeType];
      let hour = 1 * time.split(':')[0];
      const mins = 1 * time.split(':')[1];
      if (tzTo === 'utc') {
        hour += tzOffset;
        // If the start date is over 24 after conversion, move it to next day and correct time after day rollover
        if (timeType === 'start_time' && hour >= 24) {
          convertedTimes.weekday = convertedTimes.weekday === 7 ? 1 : convertedTimes.weekday + 1;
          hour -= 24;
        } else if (timeType === 'end_time' && hour >= 24) {
          // If end time is over 24 after conversion, roll the time over (value not used for anything on backend, just for storing)
          hour -= 24;
        }
      } else {
        hour -= tzOffset;
        // If the start date is negative after conversion, move it to previous day and correct time after day rollover
        if (timeType === 'start_time' && hour < 0) {
          convertedTimes.weekday = convertedTimes.weekday === 1 ? 7 : convertedTimes.weekday - 1;
          hour += 24;
        } else if (timeType === 'end_time' && hour < 0) {
          // If end time is negative after conversion, roll the time back over (value not used for anything on backend, just for storing)
          hour += 24;
        }
      }
      convertedTimes[timeType] = `${hour}:${mins.toString().padStart(2, '0')}`;
    });

    return convertedTimes;
  };

  setLearningSchedule = () => {
    const formattedData = {};
    formattedData.schedules = Object.keys(this.learningSchedule.data).map((day) => {
      const convertedTimes = this.convertTimes('utc', this.learningSchedule.data[day], this.learningScheduleDayMap[day]);
      return {
        ...convertedTimes,
      };
    });
    return Agents.learningSchedule.setLearningSchedule(formattedData);
  };

  constructor() {
    makeObservable(this, {
      learningSchedule: observable,
      getLearningSchedule: action,
      setLearningScheduleDays: action,
      setLearningScheduleTime: action,
    });
  }
}

export default new UserSettingsStore();
