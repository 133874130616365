import React from 'react';
import Header from '../Header/Header';
import Icon from '../Icon/Icon';
import Button from '../Button/Button';
import Segment from '../Segment/Segment';
import SeriesDisplayHeader from './SeriesDisplayHeader';
import InstructorCard from '../Item/InstructorCard';
import Tooltip from '../Tooltip/Tooltip';
import Container from '../Container/Container';
import Label from '../Label/Label';

import './series-display.css';

function SessionsTable({ sessions, handleClickSessionLink }) {
  const sessionData = {};
  sessionData.active = [];
  sessionData.upcoming = [];
  sessionData.archived = [];

  sessions.forEach((session) => {
    if (session.status === 'active') {
      sessionData.active.push(session);
    } else if (session.status === 'upcoming') {
      sessionData.upcoming.push(session);
    } else {
      sessionData.archived.push(session);
    }
  });
  // Ensure we are sorted by start_time for each set of sessions.
  sessionData.active.sort((a, b) => {
    return a.start_time_timestamp - b.start_time_timestamp;
  });
  sessionData.upcoming.sort((a, b) => {
    return a.start_time_timestamp - b.start_time_timestamp;
  });
  sessionData.archived.sort((a, b) => {
    return a.start_time_timestamp - b.start_time_timestamp;
  });
  return (
    <>
      {!!sessionData && sessionData.active && sessionData.active.length ? (
        <>
          <Header as="h3" className="border-b border-gray-400">
            Live Now
          </Header>
          <Segment>
            {sessionData.active.map((session) => (
              <SessionsTableRow key={session.id} session={session} handleClickSessionLink={handleClickSessionLink} />
            ))}
          </Segment>
        </>
      ) : null}
      {!!sessionData && sessionData.upcoming && sessionData.upcoming.length ? (
        <>
          <Header as="h3" className="border-b border-gray-400">
            Upcoming Sessions
          </Header>
          <Segment>
            {sessionData.upcoming.map((session) => (
              <SessionsTableRow key={session.id} session={session} handleClickSessionLink={handleClickSessionLink} />
            ))}
          </Segment>
        </>
      ) : null}
      {!!sessionData && sessionData.archived && sessionData.archived.length ? (
        <>
          <Header as="h3" className="border-b border-gray-400">
            Past Sessions
          </Header>
          <Segment>
            {sessionData.archived.map((session) => (
              <SessionsTableRow key={session.id} session={session} handleClickSessionLink={handleClickSessionLink} />
            ))}
          </Segment>
        </>
      ) : null}
    </>
  );
}

function SessionsTableRow({ session, handleClickSessionLink }) {
  const rowStyle = session.status === 'active' ? 'font-bold' : '';
  const sessionDateTime = session.localStartDate.format('M/DD/YY');
  const contentDescription = session.content_description;
  const isFree = contentDescription && contentDescription.is_free;
  return (
    <div className="grid grid-cols-12 gap-4 items-center py-6 border-b last:border-b-0 border-gray-400">
      <div className={`col-span-12 lg:col-span-6 ${rowStyle}`}>
        {session.status === 'active' ? (
          <>
            <div className="live-circle-blink live-circle" style={{ float: 'left', marginTop: '6px' }} />

            {session.title}
          </>
        ) : (
          session.title
        )}
        <span className="ml-4" />
        {isFree ? <Label color="grey">FREE</Label> : null}
      </div>
      <div className={`col-span-6 lg:col-span-3 ${rowStyle}`}>
        {sessionDateTime} {session.localTimeRange}
      </div>
      <div className={`col-span-6 lg:col-span-3 ${rowStyle}`}>
        <SessionStatusButton session={session} handleClickSessionLink={handleClickSessionLink} />
      </div>
    </div>
  );
}

function SessionStatusButton({ session, handleClickSessionLink }) {
  let button;
  switch (session.status) {
    case 'active':
      button = {
        popupText: `Watch ${session.title} live!`,
        buttonText: 'Watch live',
        color: 'red',
        icon: 'play',
      };
      break;
    case 'upcoming':
      button = {
        popupText: 'Learn more about this upcoming session',
        buttonText: 'Upcoming',
        color: 'grey',
        icon: 'information-circle',
      };
      break;
    case 'archived':
    default:
      button = {
        popupText: 'Watch',
        buttonText: 'Watch',
        icon: 'play',
      };
  }
  return (
    <Tooltip
      omitTabIndex
      triggerContent={
        <Button onClick={() => handleClickSessionLink(session)} className="flex gap-x-1 items-center" color={!!button && button.color ? button.color : null}>
          {button.buttonText} <Icon name={button.icon} className="inline-block w-5 h-5" />
        </Button>
      }
      content={button.popupText}
      theme="dark"
    />
  );
}

const prepareInstructorCard = (instructor) => {
  const propsToSend = {
    name: instructor.name,
    thumbnail: instructor.thumbnail,
    title: instructor.title,
    instructor_level: instructor.instructorLevel,
    permalink: instructor.permalink,
    short_bio: '',
    type: 'Instructor',
  };
  return <InstructorCard key={instructor.id} {...propsToSend} fluid />;
};

const getInstructors = (instructors) => {
  return (
    <>
      <div className="hidden lg:block">{instructors.map((instructor) => prepareInstructorCard(instructor))}</div>
      <div className="lg:hidden">
        <div className="grid grid-cols-1 gap-2 mb-4 md:grid-cols-2">{instructors.map((instructor) => prepareInstructorCard(instructor))}</div>
      </div>
    </>
  );
};

function SeriesDisplay(props) {
  const hasInstructors = !!props.instructors && props.instructors.length;
  return (
    <>
      <SeriesDisplayHeader title={props.title} series={props.series} description={props.description} />

      <Container fluid>
        <Segment className="border-none">
          <div className="lg:grid lg:grid-cols-12 lg:gap-x-8">
            <div className={hasInstructors ? 'col-span-9' : 'col-span-12'}>
              <SessionsTable sessions={props.sessions} handleClickSessionLink={props.handleClickSessionLink} />
            </div>
            {hasInstructors && (
              <div className={hasInstructors ? 'col-span-3' : 'col-span-12'}>
                <Header as="h3" className="border-b border-gray-400">
                  Instructed By
                </Header>
                {getInstructors(props.instructors)}
              </div>
            )}
          </div>
        </Segment>
      </Container>
    </>
  );
}

export default SeriesDisplay;
