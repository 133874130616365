import React, { useState, useEffect } from 'react';
import Bugsnag from '@bugsnag/js';
import { cloneDeep } from 'lodash';
import Agents from '../../agents/agents';
import FormatUtil from '../../utils/formatUtil';
import StatusLegend from '../ContentStatus/StatusLegend';
import CatalogMatrix from './CatalogMatrix';

const mitreMatrixId = 'x-mitre-matrix--eafc1b4c-5e56-4965-bd4e-66a6a89c88cc';

/* Data fetches */
const fetchMitreCatalog = async (setData, filterTechniqueIds) => {
  try {
    let data = await Agents.mitre.getMitreTactics('?attack_patterns');
    data = filterTechniqueIds(data, 'technique');
    setData(data);
  } catch (e) {
    Bugsnag.notify(e);
  }
};

const fetchSubscriptions = async (setTactics, setTechniques, setSavedSubscriptionsType) => {
  try {
    const data = await Agents.mitre.getSubscriptions();
    const mitreData = data.filter((item) => item.category === 'Mitre Attack');
    const tactics = [];
    const techniques = [];
    if (mitreData.length) {
      if (mitreData.length === 1 && mitreData[0].name === mitreMatrixId) {
        // If you are subscribed to the matrix ID, you're subscribed to all
        setSavedSubscriptionsType('all');
      } else {
        mitreData.forEach((term) => {
          setSavedSubscriptionsType('some');
          if (/^x-mitre-tactic./.test(term.name)) {
            // If this term starts with 'x-mitre-tactic', it's a tactic, so push to separate array
            tactics.push(term.name);
          } else {
            techniques.push(term.name);
          }
        });
      }
    } else {
      setSavedSubscriptionsType('none');
    }
    setTactics(tactics);
    setTechniques(techniques);
  } catch (e) {
    Bugsnag.notify(e);
  }
};

const fetchMitreCourses = async (setMitreCourses, filterCourseIds) => {
  try {
    let mitreCourses = await Agents.catalog.getCoursesByCategory('Mitre%20Attack');
    mitreCourses = filterCourseIds(mitreCourses, 'course');
    setMitreCourses(mitreCourses || []);
  } catch (e) {
    Bugsnag.notify(e);
    setMitreCourses([]);
  }
};

/* Filter Data */
const filterCourses = (mitreCourses, courseIds) => {
  if (!courseIds || !mitreCourses) {
    return mitreCourses;
  }
  const courses = mitreCourses.filter((course) => courseIds.indexOf(course.id) !== -1);
  return courses.length ? courses : [];
};

const filterTechniques = (rawMitreData, techniqueIds) => {
  const filteredMitreData = cloneDeep(rawMitreData);
  if (!techniqueIds || !filteredMitreData) {
    return filteredMitreData;
  }
  const mitreKeys = Object.keys(rawMitreData);
  mitreKeys.forEach((key) => {
    const attackPatterns = filteredMitreData[key].attack_patterns.filter((technique) => techniqueIds.indexOf(technique.id) !== -1);
    filteredMitreData[key].attack_patterns = attackPatterns;
  });
  return filteredMitreData;
};

/* Component Functions */
const getChildrenIds = (data) => {
  if (!data || !data.attack_patterns || !data.attack_patterns.length) {
    return [];
  }
  return data.attack_patterns.map((technique) => technique.id);
};

const getTechniqueParents = (technique, mitreHierarchy) => {
  return Object.keys(mitreHierarchy).filter((tacticId) => {
    const tactic = mitreHierarchy[tacticId];
    return tactic.includes(technique);
  });
};

const unsubscribe = async (data, isTactic, mitreHierarchy, setters, subscriptions) => {
  const { setTechniqueSubscriptions, setTacticSubscriptions } = setters;
  const { techniqueSubscriptions, tacticSubscriptions } = subscriptions;
  const { id } = data;
  const tacticSubs = [...tacticSubscriptions];
  const techniqueSubs = [...techniqueSubscriptions];
  const techniquesToRemove = [];
  const tacticsToRemove = [];

  // Remove the tactic ID and any techniques within it
  if (isTactic) {
    tacticsToRemove.push(id);
    const childIds = getChildrenIds(data);
    if (childIds && childIds.length) {
      childIds.forEach((childId) => {
        techniquesToRemove.push(childId);
      });
    }
  } else {
    techniquesToRemove.push(id);
    // Get any tactics that contain this technique so we can unsubscribe
    const parentTactics = getTechniqueParents(id, mitreHierarchy);
    if (parentTactics && parentTactics.length) {
      parentTactics.forEach((tactic) => {
        if (tacticSubs.includes(tactic)) {
          tacticsToRemove.push(tactic);
        }
      });
    }
  }
  const newTactics = tacticSubs.filter((tactic) => tacticsToRemove.indexOf(tactic) === -1);
  const newTechniques = techniqueSubs.filter((technique) => techniquesToRemove.indexOf(technique) === -1);
  setTacticSubscriptions(newTactics);
  setTechniqueSubscriptions(newTechniques);
};

const subscribe = async (data, isTactic, mitreHierarchy, setters, subscriptions) => {
  const { setTechniqueSubscriptions, setTacticSubscriptions } = setters;
  const { techniqueSubscriptions, tacticSubscriptions } = subscriptions;
  const { id } = data;

  const newTechniquesToAdd = [];
  const newTacticsToAdd = [];
  // If it's a tactic, we need to add the tactic and all of the techniques within it
  if (isTactic) {
    newTacticsToAdd.push(id);
    const childIds = getChildrenIds(data);
    if (childIds && childIds.length) {
      childIds.forEach((childId) => {
        newTechniquesToAdd.push(childId);
      });
    }
  } else {
    newTechniquesToAdd.push(id);
    // Get parents of this technique. Loop through them and see if all techniques beneath them are selected. If so, select the tactic too
    const parentTactics = getTechniqueParents(id, mitreHierarchy);
    if (parentTactics && parentTactics.length) {
      const allTechniqueSubs = FormatUtil.dedupArray([...techniqueSubscriptions, ...newTechniquesToAdd]);
      parentTactics.forEach((tactic) => {
        const tacticTechniques = mitreHierarchy[tactic];
        const unselectedTacticTechniques = tacticTechniques.filter((technique) => !allTechniqueSubs.includes(technique));
        if (!unselectedTacticTechniques.length) {
          newTacticsToAdd.push(tactic);
        }
      });
    }
  }
  setTacticSubscriptions([...tacticSubscriptions, ...newTacticsToAdd]);
  setTechniqueSubscriptions(FormatUtil.dedupArray([...techniqueSubscriptions, ...newTechniquesToAdd]));
};

const getSourceInfo = (data) => {
  if (!data || !data.external_references || !data.external_references.length) {
    return {};
  }
  const extRef = data.external_references.filter((ref) => ref.source_name === 'mitre-attack');
  return extRef.length ? extRef[0] : {};
};

const saveSubscriptions = async (subType, savedSubscriptionsType, savedSubscriptionsData, subscriptionsData, setters, callback, triggerToast) => {
  const { setSavedTacticSubscriptions, setSavedTechniqueSubscriptions, setSavedSubscriptionsType } = setters;
  const { tacticSubscriptions, techniqueSubscriptions } = subscriptionsData;
  const pendingSubscriptions = [...tacticSubscriptions, ...techniqueSubscriptions];

  const subscriptionsHasChanged =
    subType !== savedSubscriptionsType || savedSubscriptionsData.length !== pendingSubscriptions.length || !FormatUtil.areArraysSame(savedSubscriptionsData, pendingSubscriptions);
  if (subscriptionsHasChanged) {
    const postData = { category: 'Mitre Attack', terms: [] };

    if (subType === 'all') {
      postData.terms = [mitreMatrixId];
    } else if (subType === 'some') {
      postData.terms = pendingSubscriptions;
    }
    try {
      await Agents.mitre.syncSubscriptions(postData);
      const isAllOrNone = ['all', 'none'].indexOf(subType) > -1;
      // Successfully saves, so set saved state vals
      setSavedTacticSubscriptions(isAllOrNone ? [] : tacticSubscriptions);
      setSavedTechniqueSubscriptions(isAllOrNone ? [] : techniqueSubscriptions);
      setSavedSubscriptionsType(subType);
      callback();
    } catch (e) {
      Bugsnag.notify(e);
      triggerToast('error', {
        content: `Something went wrong. We were unable to update your subscriptions. Please try again.`,
      });
    }
  } else {
    callback();
  }
};

const getItemProgress = (total, completed) => {
  if (!total || !completed) {
    return 0;
  }

  return Math.floor((completed / total) * 100);
};

const transformTechnique = (data, courses, isSubtechnique) => {
  const techniqueCopy = { ...data }; // Copy current technique to modify in checking for content
  const techniqueSourceInfo = getSourceInfo(techniqueCopy);
  techniqueCopy.tId = techniqueSourceInfo.external_id;
  techniqueCopy.courses = [];
  techniqueCopy.comingSoonCourses = [];
  techniqueCopy.activities_completed = 0;
  techniqueCopy.activities_total = 0;
  courses.forEach((course) => {
    const courseCopy = cloneDeep(course);
    courseCopy.progress = 0;
    if (courseCopy.attack_pattern_id === techniqueCopy.id) {
      if (courseCopy.status === 'Active') {
        // Accumulate a sum of all activities in courses for the technique, to calculate an overall progress %
        techniqueCopy.activities_total += courseCopy.activities_total || 0;
        techniqueCopy.activities_completed += courseCopy.activities_completed || 0;
        // Get the progress for this specific course
        courseCopy.progress = getItemProgress(courseCopy.activities_total, courseCopy.activities_completed);
        // If this is a subtechnique, include the subtechnique name with the course to be shown as a label at technique level
        techniqueCopy.courses.push({ ...courseCopy, subTechnique: isSubtechnique ? techniqueCopy.name : null });
      } else if (courseCopy.status === 'Coming Soon') {
        techniqueCopy.comingSoonCourses.push({ ...courseCopy, subTechnique: isSubtechnique ? techniqueCopy.name : null });
      }
    }
  });
  return techniqueCopy;
};

/* Main Component */
function MitreCatalog({ commonStore, disableSubscription, filters, selectedTechnique, useMobileBlock }) {
  const [savedSubscriptionsType, setSavedSubscriptionsType] = useState(null);
  // Tactics/Technique subscriptions saved on server
  const [savedTacticSubscriptions, setSavedTacticSubscriptions] = useState([]);
  const [savedTechniqueSubscriptions, setSavedTechniqueSubscriptions] = useState([]);
  // Tactics/Technique subscriptions saved locally (pending to send to server)
  const [tacticSubscriptions, setTacticSubscriptions] = useState([]);
  const [techniqueSubscriptions, setTechniqueSubscriptions] = useState([]);

  const [rawMitreData, setRawMitreData] = useState(null);
  const [mitreData, setMitreData] = useState(null);
  const [mitreCourses, setMitreCourses] = useState(null);
  const [mitreHierarchy, setMitreHierarchy] = useState(null);
  const [techniquesCount, setTechniquesCount] = useState([]);
  const courseIds = filters && filters.courseIds ? filters.courseIds : null;
  const techniqueIds = filters && filters.techniqueIds ? filters.techniqueIds : null;

  const filterData = (data, type) => {
    const filterTypes = {
      technique: () => filterTechniques(data, techniqueIds),
      course: () => filterCourses(data, courseIds),
    };
    return filterTypes[type]();
  };

  // Fetch Mitre data and user subscriptions
  useEffect(() => {
    fetchMitreCatalog(setRawMitreData, filterData);
    fetchMitreCourses(setMitreCourses, filterData);
    if (!disableSubscription) {
      fetchSubscriptions(setSavedTacticSubscriptions, setSavedTechniqueSubscriptions, setSavedSubscriptionsType);
    }
  }, []);

  // After the data has been fetched and set, set the UI dependent subscriptions (a copy that will change on user clicks before save)
  useEffect(() => {
    setTechniqueSubscriptions(savedTechniqueSubscriptions);
    setTacticSubscriptions(savedTacticSubscriptions);
  }, [savedTacticSubscriptions, savedTechniqueSubscriptions]);

  // Create a tactic/technique Hierarchy and flat list for subscription ease (between tactics and techniques) AND transform Mitre data to include course data
  useEffect(() => {
    if (rawMitreData && Object.keys(rawMitreData).length && mitreCourses) {
      const hierarchy = {};
      const mitreDataCopy = cloneDeep(rawMitreData);
      const allTechniques = [];
      Object.keys(mitreDataCopy).forEach((tacticId) => {
        const tactic = mitreDataCopy[tacticId];
        if (tactic.attack_patterns && tactic.attack_patterns.length) {
          const longId = tactic.id;
          hierarchy[longId] = [];
          tactic.attack_patterns.forEach((technique, i) => {
            const techniqueCopy = transformTechnique(technique, mitreCourses);
            if (techniqueCopy.sub_techniques && techniqueCopy.sub_techniques.length) {
              const subTechniques = [];
              techniqueCopy.sub_techniques.forEach((subTechnique) => {
                // Omit any that are just null
                if (subTechnique) {
                  const subTechniqueCopy = transformTechnique(subTechnique, mitreCourses, true);
                  // Add in all subtechnique activities counts to the parent technique for overall progress calc
                  techniqueCopy.activities_completed += subTechniqueCopy.activities_completed;
                  techniqueCopy.activities_total += subTechniqueCopy.activities_total;
                  subTechniqueCopy.progress = getItemProgress(subTechniqueCopy.activities_total, subTechniqueCopy.activities_completed);
                  // Save the subtechnique courses to the parent array too
                  techniqueCopy.comingSoonCourses.push(...subTechniqueCopy.comingSoonCourses);
                  techniqueCopy.courses.push(...subTechniqueCopy.courses);

                  subTechniques.push(subTechniqueCopy);
                }
              });
              // Order the subtechniques by tId and replace original array
              techniqueCopy.sub_techniques = FormatUtil.sortArrayObjects(subTechniques, 'tId');
            }
            techniqueCopy.progress = getItemProgress(techniqueCopy.activities_total, techniqueCopy.activities_completed);
            tactic.attack_patterns[i] = techniqueCopy; // Replace original tactic with modified (containing content data)
            if (allTechniques.indexOf(techniqueCopy.id) === -1) {
              allTechniques.push(techniqueCopy.id);
            }
            hierarchy[longId].push(techniqueCopy.id);
          });
        }
      });

      setMitreData(mitreDataCopy);
      setMitreHierarchy(hierarchy);
      setTechniquesCount(allTechniques.length);
    }
  }, [rawMitreData, mitreCourses]);

  return (
    <div className="mb-12">
      <div className="justify-between items-center mb-6 sm:flex sm:flex-wrap">
        {!disableSubscription && (
          <p className="max-w-150 text-sm text-gray-600 lg:mb-0">
            Subscribe to the tactics and techniques you&apos;re most interested in to help prioritize content development, and receive notifications when new courses are ready.
          </p>
        )}
        <StatusLegend />
      </div>
      <CatalogMatrix
        mitreData={mitreData}
        mitreCourses={mitreCourses}
        disableSubscription={disableSubscription}
        subscribe={(data, isTactic) =>
          subscribe(data, isTactic, mitreHierarchy, { setTechniqueSubscriptions, setTacticSubscriptions }, { techniqueSubscriptions, tacticSubscriptions })
        }
        unsubscribe={(data, isTactic) =>
          unsubscribe(data, isTactic, mitreHierarchy, { setTechniqueSubscriptions, setTacticSubscriptions }, { techniqueSubscriptions, tacticSubscriptions })
        }
        tacticSubscriptions={tacticSubscriptions}
        techniqueSubscriptions={techniqueSubscriptions}
        savedTechniqueSubscriptions={savedTechniqueSubscriptions}
        savedSubscriptionsType={savedSubscriptionsType}
        saveSubscriptions={(subType, successCallback) =>
          saveSubscriptions(
            subType,
            savedSubscriptionsType,
            [...savedTacticSubscriptions, ...savedTechniqueSubscriptions],
            { tacticSubscriptions, techniqueSubscriptions },
            { setSavedTacticSubscriptions, setSavedTechniqueSubscriptions, setSavedSubscriptionsType },
            successCallback,
            commonStore.triggerToast
          )
        }
        techniquesCount={techniquesCount}
        selectedTechnique={selectedTechnique}
        useMobileBlock={useMobileBlock}
      />
    </div>
  );
}

export default MitreCatalog;
