import React, { useState, useEffect, useRef } from 'react';
import { useWindowWidth } from '@react-hook/window-size';
import { debounce } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import Icon from '../Icon/Icon';
import Divider from '../Divider/Divider';
import useUniqueKey from '../../hooks/useUniqueKey';

const mobileBreakpoint = 576;
const defaultBreakPoints = {
  'break-1664': {
    6: 4,
    3: 2,
  },
  'break-1120': {
    6: 2,
    3: 1,
  },
};

function CarouselActions({ actions, displayNavButtons, scrollCarousel, activeItem, rightDisabled, actionsWrapperClasses }) {
  return (
    <div className={actionsWrapperClasses || 'text-right'}>
      {actions || null}
      {displayNavButtons ? (
        <div className="flex">
          <button aria-label="See previous items" className="text-gray-600 disabled:opacity-50" disabled={activeItem === 1} onClick={debounce(() => scrollCarousel('left'), 250)}>
            <Icon name="chevron-left" />
          </button>
          <button aria-label="See next items" className="text-gray-600 disabled:opacity-50" disabled={rightDisabled} onClick={debounce(() => scrollCarousel('right'), 250)}>
            <Icon name="chevron-right" />
          </button>
        </div>
      ) : null}
    </div>
  );
}

function ItemsContainer({ visibleContainerWidth, itemsWrapperStyles, cardContainerRef, items }) {
  return (
    <div className="overflow-hidden relative whitespace-nowrap" role="region" aria-live="polite" style={{ width: `${visibleContainerWidth / 16}rem` }}>
      <div className="flex transition-all duration-700" style={itemsWrapperStyles} ref={cardContainerRef}>
        {items}
      </div>
    </div>
  );
}

function DividerContainer({ omitDivider, dividerProps = {} }) {
  const { marginTop, marginBottom } = dividerProps;
  return omitDivider ? null : <Divider marginTop={marginTop || 'mt-8'} marginBottom={marginBottom || 'mb-8'} />;
}

function getCurrentBreakpoint(windowWidth, breakpointMap) {
  let currentBreakpoint = null;
  Object.keys(breakpointMap).forEach((breakpoint) => {
    const breakPt = breakpoint.split('-')[1];
    if (windowWidth < 1 * breakPt) {
      currentBreakpoint = breakpoint;
    }
  });
  return currentBreakpoint;
}

function getvisibleCols(currentBreakpoint, actualCols, breakpointMap) {
  // Haven't hit a breakpoint, return cols
  if (!currentBreakpoint) {
    return actualCols;
  }
  return breakpointMap[currentBreakpoint][`${actualCols}`];
}

function getPanelVisibleCount(windowWidth, visibleColumns, firstColSpan, activeItem, breakpointMap) {
  const currBreakpoint = getCurrentBreakpoint(windowWidth, breakpointMap);
  let visibleCount = visibleColumns;
  if (!currBreakpoint && firstColSpan && activeItem === 1) {
    visibleCount -= firstColSpan - 1;
  }
  return visibleCount;
}

// Get copy of children with styles applied, refs, count of visible columns, and the wrapper width
function getChildren(children, firstColSpan, colWidth, isMobile, availSpace, gapOffset) {
  const refs = [];
  const childrenCopy = [];
  let visibleColumnCount = 0;
  let wrapperWidth = gapOffset; // Offset the gap from the first & last item
  [...children].forEach((child, idx) => {
    const colClasses = 'sm-576:mx-4 shrink-0 whitespace-normal rounded-sm overflow-hidden bg-white';
    let baseColWidth = colWidth;
    if (idx === 0) {
      const firstColSpanGapDifference = 4 * (firstColSpan - 1) * 2; // 4 represents 1rem (col gap)
      baseColWidth = colWidth * firstColSpan + firstColSpanGapDifference;
    }
    const thisColWidth = isMobile ? availSpace : (baseColWidth + 8) * 4; // 8 represents 2 rem (for gap between cols - 1 rem each side)
    const colStyles = { width: `${isMobile ? availSpace / 16 : baseColWidth / 4}rem` };
    const childRef = React.createRef();
    refs.push(childRef);
    const potentialNewWrapperWidth = wrapperWidth + thisColWidth;
    if (potentialNewWrapperWidth <= availSpace) {
      wrapperWidth = potentialNewWrapperWidth;
      visibleColumnCount += idx === 0 ? 1 * firstColSpan : 1;
    }
    childrenCopy.push(
      <div key={uuidv4()} ref={childRef} className={colClasses} style={colStyles}>
        {child}
      </div>
    );
  });
  return { refs, childrenCopy, visibleColumnCount, wrapperWidth };
}

const handleSwipe = (start, end, scrollCarousel, setTouchPos, setMouseDownStart) => {
  const xSwipeDiff = end - start;
  if (xSwipeDiff <= -25) {
    scrollCarousel('right');
  }

  if (xSwipeDiff >= 25) {
    scrollCarousel('left');
  }
  setTouchPos({});
  setMouseDownStart(null);
};

const handleTouchEvent = (event, end, touchPos, setTouchPos) => {
  if (!end) {
    setTouchPos({
      ...touchPos,
      xStart: event.changedTouches[0].screenX,
    });
  } else {
    setTouchPos({
      ...touchPos,
      xEnd: event.changedTouches[0].screenX,
    });
  }
};

function OverflowCarousel({
  heading,
  id,
  children,
  cols = 6,
  firstColSpan = 1,
  actions,
  wrapperClasses,
  actionsWrapperClasses,
  headingWrapperClasses,
  omitDivider,
  dividerProps,
  appendage,
  breakpoints,
  isDefaultColWidth,
  autoScroll = false,
}) {
  const initialWidth = window.innerWidth;
  const breakpointMap = breakpoints || defaultBreakPoints;
  const [init, setInit] = useState(false);
  const [items, setItems] = useState([]);
  const [activeItem, setActiveItem] = useState(1);
  const [visibleColumns, setVisibleColumns] = useState(0);
  const [adjustedCols, setAdjustedCols] = useState(cols);
  const [childRefs, setChildRefs] = useState([]);
  const [windowWidth, setWindowWidth] = useState(initialWidth);
  const [containerWidth, setContainerWidth] = useState(0);
  const [visibleContainerWidth, setVisibleContainerWidth] = useState(0);
  const [isMobile, setIsMobile] = useState(initialWidth < mobileBreakpoint);
  const [gapOffset, setGapOffset] = useState(initialWidth < mobileBreakpoint ? 0 : 0 - 32); // 32 = 32px colgap (combined both sides)
  const [containerOffsetAmount, setContainerOffsetAmount] = useState(gapOffset); // Offset the gap from the first & last item
  const [rightDisabled, setRightDisabled] = useState(false);
  const [touchPos, setTouchPos] = useState({});
  const [mouseDownStart, setMouseDownStart] = useState(null);
  const [isAutoScrolling, setIsAutoScrolling] = useState(autoScroll);
  const containerRef = useRef();
  const cardContainerRef = useRef();
  const carouselId = id || useUniqueKey();

  const setHiddenItems = () => {
    const visibleCount = getPanelVisibleCount(windowWidth, visibleColumns, firstColSpan, activeItem, breakpointMap);
    // Set hidden/Visible items so hidden items can't be tabbed to by keyboard
    const lastActiveItem = activeItem + visibleCount - 1;
    childRefs.forEach((child, idx) => {
      const currChild = idx + 1;
      if (child.current) {
        if (currChild < activeItem || currChild > lastActiveItem) {
          // eslint-disable-next-line no-param-reassign
          child.current.style.visibility = 'hidden';
        } else {
          // eslint-disable-next-line no-param-reassign
          child.current.style.visibility = 'visible';
        }
      }
    });
  };

  const setActiveItemOffset = () => {
    if (childRefs && activeItem && childRefs[activeItem - 1]?.current?.offsetLeft) {
      // Set offset amount for transition
      setContainerOffsetAmount(-1 * childRefs[activeItem - 1].current.offsetLeft);
    }
  };

  const transitionItems = () => {
    // Loop through refs, set all to visible (for transition animation)
    childRefs.forEach((child) => {
      if (child.current) {
        // eslint-disable-next-line no-param-reassign
        child.current.style.visibility = 'visible';
      }
    });
    setActiveItemOffset();
    // Set which children are not visible (to prevent keyboard tab focus on hidden items) - Delay to ensure transition animation has finished
    setTimeout(() => {
      setHiddenItems();
    }, 700);
  };

  const scrollCarousel = (direction) => {
    // We are just adjusting the scroll position (window size changed maybe)
    // Note: Offsets are negative values
    const numOfItems = children.length;
    let newActiveItem = activeItem;
    const visibleCount = getPanelVisibleCount(windowWidth, visibleColumns, firstColSpan, activeItem, breakpointMap);
    const offsetToAbsoluteRight = numOfItems - visibleCount + 1;
    setRightDisabled(false);
    if (direction) {
      if (direction === 'right') {
        const potentialNewActive = newActiveItem + visibleCount;
        if (potentialNewActive >= numOfItems || potentialNewActive > offsetToAbsoluteRight) {
          setRightDisabled(true);
          newActiveItem = offsetToAbsoluteRight;
        } else {
          newActiveItem = potentialNewActive;
        }
      } else {
        const potentialNewActive = newActiveItem - visibleCount;
        newActiveItem = potentialNewActive <= 0 ? 1 : potentialNewActive;
      }
    } else if (newActiveItem > numOfItems - visibleCount) {
      newActiveItem = offsetToAbsoluteRight;
    }
    if (newActiveItem !== activeItem) {
      setActiveItem(newActiveItem > 0 ? newActiveItem : 1);
    } else {
      // Not moving, just adjusting (browser resize)
      setActiveItemOffset();
      setHiddenItems();
    }
  };

  const getCarouselItems = (forceRefresh) => {
    if (children && children.length) {
      // Default for 6 card grid 60 * 4 = 240px
      const defaultColWidth = 60;
      const currentBreakpoint = getCurrentBreakpoint(windowWidth, breakpointMap);
      // Actual number of visible columns after a resize at breakpoint, if any
      // Number of visible columns at the current breakpoint
      const visibleCols = isMobile ? 1 : getvisibleCols(currentBreakpoint, cols, breakpointMap);
      // Column width based on actual columns calculated from number of cols provided, with gap consumption included in width calc
      const colWidth = isDefaultColWidth ? defaultColWidth : defaultColWidth * (6 / cols) + (6 / cols - 1) * 8;
      // 4 represents the visible gap accounted for between items
      const availSpace = isMobile ? containerRef.current.clientWidth : (colWidth * visibleCols + (visibleCols - 1) * 8) * 4;
      setContainerWidth(availSpace);
      const newFirstColSpan = currentBreakpoint ? 1 : firstColSpan; // If we've hit a breakpoint, 1st colspans drop down to 1
      const childrenData = getChildren(children, newFirstColSpan, colWidth, isMobile, availSpace, gapOffset);
      // Only set the children/refs if there's a change that should have them rerender (size change, visible column count change)
      if (forceRefresh || adjustedCols !== cols || childrenData.visibleColumnCount !== visibleColumns || childrenData.visibleColumnCount === 1) {
        setAdjustedCols(cols);
        setChildRefs(childrenData.refs);
        setItems(childrenData.childrenCopy);
        setVisibleColumns(childrenData.visibleColumnCount || 1);
      }
      setVisibleContainerWidth(childrenData.wrapperWidth);
    }
  };

  useEffect(() => {
    const isNowMobile = initialWidth <= mobileBreakpoint;
    setIsMobile(isNowMobile);
    setGapOffset(isNowMobile ? 0 : 0 - 32); // 32 = gap offset of 2rem
    setWindowWidth(initialWidth);
  }, [useWindowWidth({ wait: 200 })]);

  // If children change, re-evaluate carousel items
  useEffect(() => {
    if (children && children.length) {
      getCarouselItems(true);
    }
  }, [children]);

  // After initialization, watch for window width changes as more/less items may have space to show
  useEffect(() => {
    if (init) {
      getCarouselItems();
    }
  }, [windowWidth]);

  // When visible children count changes, set scroll to adjust to different offsets
  useEffect(() => {
    if (init) {
      scrollCarousel();
    }
  }, [visibleColumns]);

  useEffect(() => {
    if (touchPos.xEnd) {
      handleSwipe(touchPos.xStart, touchPos.xEnd, scrollCarousel, setTouchPos, setMouseDownStart);
    }
  }, [touchPos.xEnd]);

  const handleMouseMoveEvent = (event) => {
    if (mouseDownStart && event.clientX) {
      handleSwipe(mouseDownStart, event.clientX, scrollCarousel, setTouchPos, setMouseDownStart);
    }
  };

  // After initialization, set items hidden so they can't be tabbed to
  useEffect(() => {
    if (init) {
      setHiddenItems();
      setActiveItemOffset();
    }
  }, [init]);

  // When the active item changes, or we switch to mobile breakpoint (scroll)
  useEffect(() => {
    if (init) {
      transitionItems();
    }
  }, [activeItem, isMobile]);

  useEffect(() => {
    if (!init && childRefs && childRefs[0] && childRefs[0].current) {
      setInit(true);
    }
  }, [childRefs]);

  const itemsWrapperStyles = { transform: `translateX(0%) translateX(${containerOffsetAmount / 16}rem)` };
  const visibleCount = getPanelVisibleCount(windowWidth, visibleColumns, firstColSpan, activeItem, breakpointMap);
  const displayNavButtons = visibleCount < children.length || activeItem > 1;
  const swipeEvents = {
    onTouchStart: (e) => handleTouchEvent(e, false, touchPos, setTouchPos),
    onTouchEnd: (e) => handleTouchEvent(e, true, touchPos, setTouchPos),
    onMouseDown: (e) => setMouseDownStart(e.clientX),
    onMouseUp: (e) => handleMouseMoveEvent(e),
    onMouseLeave: () => setMouseDownStart(null),
  };

  // handles auto scroll if the prop "autoscroll" is true.
  // We use the state variable "isAutoScrolling" b/c it can change if the user hovers over the carousel
  useEffect(() => {
    let timerId;
    if (isAutoScrolling) {
      const autoScrollMilliSeconds = 3000;
      const handleAutoScroll = () => {
        if (activeItem === items.length) {
          setActiveItem(1);
        } else {
          setActiveItem((currentActiveItem) => currentActiveItem + 1);
        }
      };
      timerId = setTimeout(handleAutoScroll, autoScrollMilliSeconds);
    }
    return () => {
      clearTimeout(timerId);
    };
  }, [activeItem, isAutoScrolling]);

  /* pause autoScroll if hovering over the carousel */
  useEffect(() => {
    const targetElement = document.getElementById(carouselId);
    // if the target element exists, and the prop "autoscroll" is true... if its false we don't want to do any auto scrolling
    if (targetElement && autoScroll) {
      const toggleIsAutoScrolling = () => {
        setIsAutoScrolling((currentIsAutoScrolling) => !currentIsAutoScrolling);
      };
      // if we are on this section, stop autoscrolling
      targetElement.addEventListener('mouseenter', toggleIsAutoScrolling);
      // if we leave this section, resume autoscrolling
      targetElement.addEventListener('mouseleave', toggleIsAutoScrolling);

      return () => {
        // clean up the event listener when component is unmounted
        targetElement.removeEventListener('mouseenter', toggleIsAutoScrolling);
        targetElement.removeEventListener('mouseleave', toggleIsAutoScrolling);
      };
    }
    return () => {};
  }, []);

  return (
    <div id={carouselId} className={wrapperClasses || 'my-8'} ref={containerRef} {...swipeEvents}>
      <div className="mx-auto" style={{ width: `${containerWidth / 16}rem` }}>
        <div className={headingWrapperClasses || 'flex items-center justify-between mb-6'}>
          <div className="w-full">{heading}</div>
          {appendage || null}
          {actions || displayNavButtons ? (
            <CarouselActions
              actions={actions}
              displayNavButtons={displayNavButtons}
              scrollCarousel={scrollCarousel}
              activeItem={activeItem}
              rightDisabled={rightDisabled}
              actionsWrapperClasses={actionsWrapperClasses}
            />
          ) : null}
        </div>
        <ItemsContainer visibleContainerWidth={visibleContainerWidth} itemsWrapperStyles={itemsWrapperStyles} cardContainerRef={cardContainerRef} items={items} />
        <DividerContainer omitDivider={omitDivider} dividerProps={dividerProps} />
      </div>
    </div>
  );
}

export default OverflowCarousel;
