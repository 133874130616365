import React, { useState, useEffect } from 'react';
import InterstitialTemplate from '../../components/InterstitialTemplate/InterstitialTemplate';
import Button from '../../components/Button/Button';
import DynamicForm from '../../components/DynamicForm/DynamicForm';
import Icon from '../../components/Icon/Icon';
import Message from '../../components/Message/Message';
import Agents from '../../agents/agents';
import { useAuth } from '../../contexts/UseAuth';

function LinkAccount() {
  const [submitting, setSubmitting] = useState(false);
  const [complete, setComplete] = useState(false);
  const [tempJwt, setTempJwt] = useState(null);
  const [linkError, setLinkError] = useState(null);
  const auth = useAuth();

  useEffect(() => {
    // Load up the temporary JWT that was saved before redirecting to this page...
    const jwt = window.localStorage.getItem('temporaryJwt');
    setTempJwt(jwt);
    // Now remove it
    window.localStorage.removeItem('temporaryJwt');
  }, []);

  const onSubmit = async (data) => {
    setSubmitting(true);
    setLinkError(null);
    try {
      await Agents.authGoogle.linkAccount(tempJwt, data);
      // Signout from firebase
      auth.signout(true);
      // Display success message with a message saying to check your email and click a link
      setComplete(true);
    } catch (err) {
      setLinkError('There was a problem with the email you provided.');
    }
    setSubmitting(false);
  };

  if (complete) {
    return (
      <div className="flex justify-center items-start h-screen">
        <div className="flex items-center p-6 my-16 w-9/12 border-xs border-gray-400 border-rounded" style={{ maxWidth: '1000px' }}>
          <div className="mr-10 ml-6">
            <Icon className="w-16 h-16 text-green-700" name="thumbs-up" />
          </div>
          <div>
            <h2 className="mb-4 text-2xl font-black">Email Sent</h2>
            <div className="mb-0">
              <p>Please check your email and follow the instructions to complete linking your account.</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const linkAccountForm = {
    name: 'Link Account',
    order: ['email', 'submit', 'cancel'],
    fields: {
      email: {
        type: 'text',
        label: 'Email for account to link',
        validations: ['required', 'email'],
        disabled: submitting,
      },
      submit: {
        type: 'button',
        color: 'pink',
        loading: submitting,
        label: 'Yes, link my accounts',
        disabled: submitting,
        className: 'float-left mr-2',
      },
      cancel: {
        type: 'insert',
        hidden: submitting,
        insertComponent: (
          <Button
            color="gray"
            className=""
            onClick={() => {
              // Ensure that we don't land right back here and that the account creation process happens as it normally would
              window.sessionStorage.setItem('skipLinkAccount', 'true');
              // Just perform a hard redirect which should trigger the rest of the login process to run naturally
              window.location.href = '/';
            }}
          >
            No, Create a new profile
          </Button>
        ),
      },
    },
  };
  return (
    <InterstitialTemplate>
      <div className="flex justify-center items-start w-full">
        <div className="p-6 my-16 w-9/12 rounded-sm border-xs border-gray-400 lg:flex lg:items-center" style={{ maxWidth: '1000px' }}>
          <div className="pr-4 mb-8 lg:mb-0 lg:w-1/2">
            <h2 className="mb-4 text-2xl font-black text-center">Have you been here before?</h2>
            <p className="text-sm text-center">
              If you&apos;ve ever used Cybrary with a different email, you can link your accounts to ensure your profile information and learning activity is carried over.
            </p>
          </div>
          <div className="lg:ml-12 lg:w-1/2">
            {linkError && <Message className="flex mb-8" msgBody={linkError} status="error" icon="exclamation-circle" />}
            <DynamicForm form={linkAccountForm} onSubmit={onSubmit} />
          </div>
        </div>
      </div>
    </InterstitialTemplate>
  );
}

export default LinkAccount;
