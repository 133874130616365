import React, { useState, useMemo, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useToggle, useUpdateEffect } from 'usehooks-ts';
import { twMerge } from 'tailwind-merge';
import { inject, observer } from 'mobx-react';
import nav, { isActive } from '../../navigation/nav';
import { isFreeUser, isCipUser, isUserOnActiveTeam } from '../../utils/navUtil';
import SearchCatalog from './SearchCatalog';
import SecondaryNavbar from './SecondaryNavbar';
import MobileNav from './Mobile/MobileNav';
import DropdownContainer from './DropdownContainer';
import ProfileMenu from './ProfileMenu';
import ExploreMenu from './ExploreMenu';
import DropdownMenu from './DropdownMenu';

import HelpIcon from '../../Icons/HelpIcon';
import BellIcon from './Icons/BellIcon';
import DiscordIcon from './Icons/DiscordIcon';
import UserIcon from '../../Icons/UserIcon';
import ChevronDown from '../../Icons/ChevronDown';
import AddLink from '../AddLink/AddLink';
import FormatUtil from '../../utils/formatUtil';
import useBetaCookie from '../../hooks/cookies/useBetaCookie';

import { trackSnowplowEvent } from '../../utils/snowplowUtil';
import { HELP_DESK_LINKS } from '../../constants';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import If from '../If/If';
import Icon from '../Icon/Icon';

function NavbarItemComponent({ item, location, userStore, wrapperClasses, iconClasses, linkClasses, isSearchDropdownOpen }) {
  const betacookie = useBetaCookie();
  const { type, id, href, label, icon, hrefReplace, display, dropdown, trackClickEvent, redirectOn, beta, target, disabled } = item;
  const { isEnterprise } = userStore;
  const [open, toggle, setOpen] = useToggle();

  const linkHref = useMemo(() => {
    // If the item is disabled, don't return a link
    if (disabled) {
      return '';
    }

    if (redirectOn) {
      const redirectHref = redirectOn(userStore);
      if (redirectHref) {
        return redirectHref;
      }
    }

    // Some URLs require dynamic replacement values, send along the href and the user store to the provided function
    if (hrefReplace) {
      return hrefReplace(href, userStore);
    }

    return href;
  }, [href, hrefReplace, redirectOn, userStore, disabled, userStore?.teamData?.id]);

  const active = isActive(item, 0, userStore, location);

  const uniqueClass = `primary-nav-${FormatUtil.slugify(label)}`;
  const textClasses = `text-xs font-semibold whitespace-nowrap ${active ? 'text-black' : ''}`;
  const btnClasses = twMerge(wrapperClasses, uniqueClass, open ? 'text-black' : '');
  const mergedLinkClasses = twMerge(linkClasses, disabled ? 'cursor-not-allowed' : '');

  const onClick = useCallback(() => {
    if (trackClickEvent) {
      trackSnowplowEvent({ category: 'Navigation', action: 'click', label, property: { label, href } });
    }
  }, [href, label, trackClickEvent]);

  useUpdateEffect(() => {
    if (open && isSearchDropdownOpen) {
      setOpen(false);
    }
  }, [isSearchDropdownOpen]);

  // Check to see if we should even display this item
  if (display && !display(userStore)) {
    return null;
  }

  // Check if this item is a beta feature and if the user has it enabled
  // i.e. set beta key in nav object { beta: "isForumsBetaEnabled" }
  if (beta && betacookie[beta] !== true) {
    return null;
  }

  if (type === 'dropdown') {
    return (
      <div className={`${uniqueClass} relative`} key={id}>
        <AddLink className={btnClasses} key={id} onClick={disabled ? () => {} : toggle}>
          <Icon name={icon} className={iconClasses} active={active} id="-primary-nav" />
          <div className={`${textClasses} flex items-center`}>
            {label} <ChevronDown classes="w-2.5 h-2.5" />
          </div>
        </AddLink>
        <DropdownContainer classes="left-0" omitWidth visible={open} onClose={disabled ? () => {} : toggle}>
          <If condition={label === 'Explore'}>
            <ExploreMenu isEnterprise={isEnterprise} onClick={() => setOpen(false)} />
          </If>
          <If condition={label !== 'Explore'}>
            <DropdownMenu items={dropdown} isEnterprise={isEnterprise} onClick={() => setOpen(false)} />
          </If>
        </DropdownContainer>
      </div>
    );
  }

  return (
    <div className={`${wrapperClasses} ${uniqueClass}`} key={id}>
      <AddLink to={linkHref} className={mergedLinkClasses} onClick={disabled ? () => {} : onClick} target={target} active={active}>
        <Icon name={icon} className={iconClasses} id="-primary-nav" />
        <div className={textClasses}>{label}</div>
      </AddLink>
    </div>
  );
}

const NavbarItem = inject('userStore')(observer(NavbarItemComponent));

function PrimaryNavbarComponent({ userStore, commonStore, authStore, hasUnread }) {
  const [userDropDown, setUserDropDown] = useState(false);
  const [isSearchDropdownOpen, setIsSearchDropdownOpen] = useState(false);
  const location = useLocation() || window.location;
  const { pathname } = location;
  const { primary } = nav;

  const primaryMain = primary.items.filter((item) => item.group === 'main');
  const primarySecondary = primary.items.filter((item) => item.group !== 'main');

  const iconClasses = 'w-8 h-8 mt-2 mx-auto text-center';
  const wrapperClasses = 'mb-2 text-center hover:text-black text-gray-600 px-3 xl:px-4';
  const linkClasses = 'hover:text-black cursor-pointer';
  const rightWrapperClasses = 'text-center hover:text-black text-gray-600 px-3';
  const rightIconClasses = 'w-8 h-8 mx-auto text-center';
  const isNotificationsActive = useMemo(() => (pathname ? /notification-center/.test(pathname) : false), [pathname]);
  const upgradeLink = useMemo(() => commonStore?.getUpgradeLink('?appnav=btn'), [commonStore?.getUpgradeLink]);
  const hasCybraryAdminAccess = useMemo(() => authStore.hasCybraryAdminAccess(), [userStore.user]);

  const renderNavbarItem = useCallback(
    (item) => (
      <ErrorBoundary FallbackComponent={ErrorBoundary.FallbackComponents.FallbackDisplayExtraSmall} key={item.id}>
        <NavbarItem
          item={item}
          location={location}
          wrapperClasses={wrapperClasses}
          iconClasses={iconClasses}
          linkClasses={linkClasses}
          isSearchDropdownOpen={isSearchDropdownOpen}
          key={item.id}
        />
      </ErrorBoundary>
    ),
    [location, isSearchDropdownOpen]
  );

  if (pathname === null || !commonStore.primaryNavDisplay) {
    return null;
  }

  return (
    <div className="w-full primary-navbar">
      <div className="hidden justify-between items-center px-4 mx-auto max-w-screen-2xl bg-white lg:flex xl:px-8">
        <div className="flex items-center">
          <div className="flex shrink-0 items-center">
            <div className="block mr-4 lg:hidden xl:block " style={{ width: '7.5rem' }}>
              <AddLink to={hasCybraryAdminAccess ? '/admin' : '/'} aria-label="Cybrary - Return to Home page" className="block primary-nav-logo">
                <img
                  src="https://images.ctfassets.net/kvf8rpi09wgk/6HtXDpiYT7GMU8yp0DS4Dy/5b42c213353afb410ac8e52e7db7c77f/cybrary_logo_black.svg"
                  alt="Cybrary"
                  width="120"
                  height="31"
                />
              </AddLink>
            </div>
            <div className="flex flex-row justify-start items-center">
              <ErrorBoundary FallbackComponent={ErrorBoundary.FallbackComponents.FallbackDisplaySmall}>
                <div className="flex flex-row items-center">{primaryMain.map(renderNavbarItem)}</div>
                <div className="mx-1 w-px h-6 bg-gray-500" />
                <div className="flex flex-row items-center">{primarySecondary.map(renderNavbarItem)}</div>
              </ErrorBoundary>
            </div>
          </div>
        </div>

        <div className="grow xl:px-4" id="sitewide-search">
          <ErrorBoundary FallbackComponent={ErrorBoundary.FallbackComponents.FallbackDisplaySmall}>
            <SearchCatalog inputClasses="w-full" setOpen={setIsSearchDropdownOpen} />
          </ErrorBoundary>
        </div>
        <div className="flex items-center">
          <ErrorBoundary FallbackComponent={ErrorBoundary.FallbackComponents.FallbackDisplaySmall}>
            <div className="shrink-0">
              <div className="flex items-center">
                {isCipUser(userStore) && (
                  <div className={`${rightWrapperClasses} primary-nav-slack`}>
                    <AddLink to="https://discord.gg/dmFFGfQJHz" className={linkClasses}>
                      <DiscordIcon classes={`${rightIconClasses} fill-white stroke-gray-600 hover:stroke-black`} id="-primary-nav" />
                    </AddLink>
                  </div>
                )}
                <div className={`${rightWrapperClasses} primary-nav-help`}>
                  <AddLink target="_blank" to={HELP_DESK_LINKS.HOMEPAGE} className={linkClasses} aria-label="Help Center opens in new window">
                    <HelpIcon classes={rightIconClasses} id="-primary-nav" />
                  </AddLink>
                </div>
                <div className={`${rightWrapperClasses} relative primary-nav-notifications`}>
                  <AddLink to="/notification-center" className={linkClasses} aria-label="Notification center">
                    <BellIcon classes={rightIconClasses} active={isNotificationsActive} id="-primary-nav" />
                    {hasUnread && <div className="absolute top-0 right-2 z-10 w-2 h-2 bg-cyb-blue-500 rounded" />}
                  </AddLink>
                </div>
              </div>
            </div>
            <div className="shrink ml-4">
              <div className="relative" style={{}}>
                <button
                  onClick={() => setUserDropDown(!userDropDown)}
                  className={`text-center cursor-pointer primary-nav-user align-middle ${userDropDown ? 'text-black' : 'hover:text-black text-gray-600'}`}
                  aria-label={`${userDropDown ? 'Close User Menu' : 'Open User Menu'}`}
                >
                  {userStore.avatarUrl && userStore.avatarUrl === 'https://assets.cybrary.it/uploads/user-avatars/profile.jpg' ? (
                    <UserIcon classes={rightIconClasses} active={userDropDown} />
                  ) : (
                    <div className="flex justify-center items-center m-auto w-8 h-8 text-center bg-gray-300 rounded-full cursor-pointer">
                      <img src={userStore.avatarUrl} className="w-full rounded-full" alt="Avatar" />
                    </div>
                  )}
                </button>
                <DropdownContainer classes="right-0 mt-2 pb-3 pt-8" width="14rem" visible={userDropDown} onClose={() => setUserDropDown(!userDropDown)}>
                  <ProfileMenu onClick={() => setUserDropDown(false)} userStore={userStore} />
                </DropdownContainer>
              </div>
            </div>
          </ErrorBoundary>
          <ErrorBoundary FallbackComponent={ErrorBoundary.FallbackComponents.FallbackDisplaySmall}>
            {!isUserOnActiveTeam(userStore) && isFreeUser(userStore) && (
              <div className="shrink ml-8">
                <div className="flex items-center">
                  <div className="text-center primary-nav-upgrade-btn">
                    <AddLink
                      to={upgradeLink}
                      className="py-2.5 px-6 text-sm font-bold leading-5 text-center text-white hover:text-white bg-cyb-pink-500 hover:bg-pink-600 rounded-sm"
                    >
                      Upgrade
                    </AddLink>
                  </div>
                </div>
              </div>
            )}
          </ErrorBoundary>
        </div>
      </div>
      <ErrorBoundary FallbackComponent={ErrorBoundary.FallbackComponents.FallbackDisplaySmall}>
        <MobileNav />
        <SecondaryNavbar />
      </ErrorBoundary>
    </div>
  );
}
const PrimaryNavbar = inject('userStore', 'commonStore', 'authStore')(observer(PrimaryNavbarComponent));

export default PrimaryNavbar;
