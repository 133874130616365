import React from 'react';
import { inject, observer } from 'mobx-react';
import queryString from 'query-string';
import withRouter from '../Router/withRouter';
import Container from '../Container/Container';
import GoalsTiles from './GoalsTiles';
import ContentOverlay from '../ContentOverlay/ContentOverlay';
import GoalDetails from './GoalDetails';
import GoalsFilters from './GoalsFilters';
import Title from '../Title/Title';
import GoalsUtil from '../../utils/goalsUtil';

const MyGoals = inject(
  'enrollmentStore',
  'goalsStore'
)(
  observer(
    class MyGoals extends React.Component {
      state = {
        detailsOpen: false,
        goalId: null,
      };

      componentDidMount() {
        this.getUserGoals(true);
        this.props.goalsStore.setDefaultGoalData();
        const queryParams = queryString.parse(window.location.search);
        // If we have a query param specifying a goal, open that goal details
        if (queryParams.goal) {
          const newState = {
            ...this.state,
            detailsOpen: true,
            goalId: queryParams.goal,
          };
          this.setState(newState);
        }
      }

      componentDidUpdate(prevProps) {
        if (this.props.view !== prevProps.view) {
          // Reset owners filter query params
          this.props.goalsStore.setQueryParams('userGoals', 'ownerIds', []);
          this.getUserGoals(true);
        }
      }

      componentWillUnmount() {
        this.props.goalsStore.setDefaultUserGoals();
      }

      getGoalStatusParam = (view) => {
        if (view === 'incomplete') {
          return 'in_progress';
        }
        if (view === 'upcoming') {
          return 'scheduled';
        }
        return view;
      };

      getUserGoals = (refreshOwnerFilterList) => {
        const { view } = this.props;
        const { getUserGoals, hasTrackingGoal, userGoals, lookForNewTrackingGoal, startTrackingGoalPolling, trackingGoalInit, setGoalOwnersList } = this.props.goalsStore;
        const queryParams = `?status=${this.getGoalStatusParam(view)}`;
        getUserGoals(queryParams).then((goals) => {
          if (refreshOwnerFilterList) {
            setGoalOwnersList('userGoals', goals || []);
          }
          // If we are looking at incomplete goals, and we aren't currently tracking a goal, but have goals, set one as default tracking
          // trackingGoalInit prevents this from conflicting with the search for a tracking goal that occurs in app load (if this page is refreshed)
          if (view === 'incomplete' && !hasTrackingGoal && trackingGoalInit && userGoals.data && userGoals.data.length) {
            lookForNewTrackingGoal(userGoals.data);
            startTrackingGoalPolling();
          }
        });
      };

      toggleDetails = (goalId) => {
        const newState = {
          ...this.state,
          goalId: goalId || null,
          detailsOpen: !this.state.detailsOpen,
        };
        this.setState(newState);

        // Check if there is a goal query param that auto opens goal on page load, if so remove it
        const queryParams = queryString.parse(window.location.search);
        if (queryParams.goal) {
          delete queryParams.goal;
          this.props.navigate({
            search: queryString.stringify(queryParams),
          });
        }
      };

      continueLearning = (goal) => {
        GoalsUtil.continueLearning(goal, this.props.goalsStore, this.props.navigate);
      };

      handleFilterChange = (key, val) => {
        this.props.goalsStore.setQueryParams('userGoals', key, val);
        this.getUserGoals();
      };

      render() {
        const { updateGoalTracking, trackingGoal } = this.props.goalsStore;
        const { loading, error, data, ownersList, queryParams } = this.props.goalsStore.userGoals;
        const { detailsOpen, goalId } = this.state;

        return (
          <Container size="lg">
            <Title title="Goals" classes="inline-block" />
            <GoalsFilters className="pb-4 lg:pb-8 lg:mb-8" onFilterChange={this.handleFilterChange} options={ownersList.slice()} values={queryParams.ownerIds.slice()} />
            <GoalsTiles
              data={GoalsUtil.getNonBaselineGoals(data)}
              viewGoal={this.toggleDetails}
              continueLearning={this.continueLearning}
              setTrackingGoal={updateGoalTracking}
              trackingGoal={trackingGoal}
              getPrimaryGoalMetric={GoalsUtil.getPrimaryGoalMetric}
              getPracticeTestStatus={GoalsUtil.getPracticeTestStatus}
              loading={loading}
              error={error}
            />
            <ContentOverlay open={detailsOpen} dismiss={this.toggleDetails} width="w-[50vw]" ariaLabelledBy="goal-title">
              <GoalDetails goalId={goalId} continueLearning={this.continueLearning} />
            </ContentOverlay>
          </Container>
        );
      }
    }
  )
);

export default withRouter(MyGoals);
