import React, { useCallback, useEffect, useState, useMemo, memo } from 'react';
import CountUp from 'react-countup';
import { useToggle } from 'usehooks-ts';
import Container from '../Container/Container';
import Loading from '../Loading/Loading';
import StyledError from '../Error/StyledError';
import PercentageBar from '../Analytics/PercentageBar';
import RadarChart from '../Charts/RadarChart/RadarChart';
import StatSection from '../Analytics/StatSection';
import Modal from '../Modal/Modal';
import If from '../If/If';
import StyleUtil from '../../utils/styleUtil';
import AddLink from '../AddLink/AddLink';
import { useCybAssessmentPath } from '../../providers/CybAssessmentPathProvider';
import { useCybAssessmentPathContent } from '../../providers/CybAssessmentPathContentProvider';

const graphColorDefaults = StyleUtil.getGraphColorDefaults();

const chartOptionsOverrides = {
  autoWrapLabels: true,
  autoWrapThreshold: 0,
  scale: { pointLabels: { fontSize: 14 } },
};

/**
 * Topic Breakdown Label
 * @param {TopicBreakdown} breakdown
 * @returns JSX.Element
 */
function TopicBreakdownLabel({ breakdown }) {
  const toHref = `${window.location.pathname}/${breakdown.course_id}`;

  return (
    <div className="flex flex-row items-center mb-8 min-h-[20px]">
      <AddLink className="mr-4 text-sm font-medium text-cyb-pink-500 underline" to={toHref}>
        {breakdown.name}
      </AddLink>
    </div>
  );
}

/**
 * Topic Breakdown ProgressBar
 * @param {TopicBreakdown} breakdown
 * @returns JSX.Element
 */
function ProgressBar({ breakdown }) {
  const progress = breakdown.score ? Math.round(parseFloat(breakdown.score)) : 0;

  return (
    <div className="flex flex-row items-center mb-8 w-full min-h-[20px]">
      <div className="overflow-hidden flex-1 max-h-[10px] bg-[#f4f6f8] rounded-[6px]">
        <PercentageBar color="#DF057B" maxHeight="10px" width={`${progress || 0}%`} />
      </div>
      <CountUp className="ml-2 w-8 text-xs" end={progress || 0} suffix="%" duration={1} />
    </div>
  );
}

function ScoreRangeModal({ scoreDistributions, selectedScoreRange, isModalOpen, toggleModal, asAssessment }) {
  const scoreDistribution = scoreDistributions?.default?.find((distribution) => distribution.score_range === selectedScoreRange);
  const scoreColumnTitle = asAssessment ? 'Assessment Score' : 'Assessment Path Score';

  return (
    <Modal size="md" position="center" className="overflow-none" ariaLabel="Score Percentile Modal" open={isModalOpen} toggle={toggleModal}>
      <div className="flex flex-col justify-center items-center px-10 pt-15 pb-5 text-center">
        <h3 className="mb-4 text-2xl">
          <span className="font-black">Score Range: </span>
          <span>{selectedScoreRange}</span>
        </h3>
        <If condition={!!scoreDistribution}>
          <div className="flex flex-col self-stretch mt-4 rounded-lg border border-stone-300">
            <div className="flex justify-between items-start py-2 px-3 font-bold bg-gray-200 border-b-1 border-stone-300">
              <span>Learner</span>
              <span>{scoreColumnTitle}</span>
            </div>
            {scoreDistribution?.assessment_attempts?.map((attempt, index) => {
              const isLastItem = index === scoreDistribution.assessment_attempts.length - 1;
              const score = Math.floor(parseFloat(attempt.score));

              return (
                <div className={`flex items-start justify-between py-2 px-3 border-stone-300 ${!isLastItem ? 'border-b-1' : ''}`} key={attempt.id}>
                  <span>{attempt.real_name}</span>
                  {asAssessment ? (
                    <AddLink className="text-cyb-pink-500 underline" to={`${window.location.pathname}/summary?learnerId=${attempt.user_id}&learnerName=${attempt.real_name}`}>
                      {score}%
                    </AddLink>
                  ) : (
                    <span>{score}%</span>
                  )}
                </div>
              );
            })}
          </div>
        </If>
      </div>
    </Modal>
  );
}

/**
 * `AssessmentPathOverview` - content for `overview` tab in `AssessmentPathResults` abd `AssessmentResults` pages.
 * Used in `AssessmentPathTabs`.
 * @param {string} orgId - team/organization id
 * @param {boolean} asAssessment - component to be used for `AssessmentResults` page
 * @returns JSX.Element
 */
function AssessmentPathOverview({ orgId, asAssessment }) {
  const { currentTopicBreakdown, currentScoreDistributions, isLoadingTopicBreakdown, isLoadingScoreDistributions, topicBreakdownError, scoreDistributionsError, actions } =
    useCybAssessmentPath();

  const {
    currentAssessment,
    currentAssessmentScoreDistributions,
    isLoadingAssessmentScoreDistributions,
    assessmentScoreDistributionsError,
    actions: assessmentPathContentActions,
  } = useCybAssessmentPathContent();

  const scoreDistributions = useMemo(
    () => (asAssessment ? currentAssessmentScoreDistributions : currentScoreDistributions),
    [asAssessment, currentAssessmentScoreDistributions, currentScoreDistributions]
  );

  const displayData = useMemo(() => {
    return scoreDistributions?.default?.reduce((result, item) => {
      return {
        ...result,
        [item.score_range]: {
          count: item.count,
        },
      };
    }, {});
  }, [scoreDistributions]);

  const radarChartData = useMemo(() => {
    const data = {};

    currentTopicBreakdown?.default?.forEach((breakdown) => {
      const score = breakdown.score ? Math.floor(parseFloat(breakdown.score)) : 0;

      data[breakdown.name] = score;
    });

    return data;
  }, [currentTopicBreakdown]);

  const renderLoadingView = useMemo(() => {
    if (asAssessment) {
      return isLoadingAssessmentScoreDistributions;
    }

    return isLoadingTopicBreakdown || isLoadingScoreDistributions;
  }, [asAssessment, isLoadingAssessmentScoreDistributions, isLoadingScoreDistributions, isLoadingTopicBreakdown]);

  const renderErrorView = useMemo(() => {
    if (asAssessment) {
      return assessmentScoreDistributionsError;
    }

    if (scoreDistributionsError) {
      return scoreDistributionsError;
    }

    return topicBreakdownError;
  }, [asAssessment, assessmentScoreDistributionsError, topicBreakdownError, scoreDistributionsError]);

  const returnEmpty = useMemo(() => {
    return (!asAssessment && !currentTopicBreakdown) || !scoreDistributions;
  }, [asAssessment, currentTopicBreakdown, scoreDistributions]);

  const [isModalOpen, toggleModal] = useToggle(false);

  const [selectedScoreRange, setSelectedScoreRange] = useState(null);

  const onClickBarGraph = useCallback((event, activeElements) => {
    if (activeElements.length > 0) {
      setSelectedScoreRange(activeElements[0]._model.label);
      toggleModal();
    }
  }, []);

  useEffect(() => {
    if (asAssessment) {
      assessmentPathContentActions.getAssessmentScoreDistributions(orgId);
    } else {
      actions.getTopicBreakdown(orgId);

      actions.getScoreDistributions(orgId);
    }
  }, [orgId, currentAssessment]);

  if (renderLoadingView) {
    return (
      <Container>
        <Loading message="Loading..." />
      </Container>
    );
  }

  if (renderErrorView) {
    return (
      <Container>
        <StyledError error={renderErrorView} />
      </Container>
    );
  }

  if (returnEmpty) {
    return null;
  }

  return (
    <Container size="fluid" className="flex flex-col flex-1 pt-0 w-full" omitPadding>
      <If condition={!asAssessment}>
        <>
          <h2 className="mt-5 text-lg font-bold">Topic Breakdown</h2>
          <p className="m-0 mt-3 text-gray-600 text-md">What is the average of my team&apos;s best scores for each topic in the assessment path?</p>
          <div className="flex flex-row flex-1 justify-between items-center -mt-16 w-full">
            <div className="flex flex-1 mt-12 mr-10 max-w-[700px] h-full">
              <div className="flex flex-col items-start">
                {currentTopicBreakdown?.default.map((breakdown) => (
                  <TopicBreakdownLabel breakdown={breakdown} key={breakdown.topic_id} />
                ))}
              </div>
              <div className="flex flex-col flex-1 justify-evenly items-start">
                {currentTopicBreakdown?.default.map((breakdown) => (
                  <ProgressBar breakdown={breakdown} key={breakdown.topic_id} />
                ))}
              </div>
            </div>
            <div className="flex h-full">
              <RadarChart data={radarChartData} containerClasses="h-[425px] w-[425px]" chartOptionsOverrides={chartOptionsOverrides} />
            </div>
          </div>
        </>
      </If>
      <If condition={asAssessment}>
        <div className={asAssessment ? 'mt-5' : ''}>
          <h2 className="mb-3 text-lg font-bold">Average Score Distribution</h2>
          <p className="m-0 mb-3 text-gray-600 text-md">What is the distribution of my team&apos;s best scores for this assessment{asAssessment ? '' : ' path'}?</p>
          <StatSection
            type="bar"
            header="Average Score Distribution"
            orgId={orgId}
            yAxisLabel="Count"
            showLegend={false}
            loading={false}
            error={null}
            displayData={displayData}
            emptyText="No score distribution data to display."
            href={`/enterprise/${orgId}/reporting/report/status-per-member`}
            primaryColors={graphColorDefaults.primaryColors}
            onClick={onClickBarGraph}
          />
          <ScoreRangeModal
            scoreDistributions={scoreDistributions}
            selectedScoreRange={selectedScoreRange}
            isModalOpen={isModalOpen}
            toggleModal={toggleModal}
            asAssessment={asAssessment}
          />
        </div>
      </If>
    </Container>
  );
}

export default memo(AssessmentPathOverview);
