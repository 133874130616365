import queryString from 'query-string';
import html2pdf from 'html2pdf.js';

const oktaBaseUrl = `${process.env.REACT_APP_OKTA_BASE_URL}`;
const oktaLogoutUrl = `${oktaBaseUrl}/oauth2/default/v1/logout`;

class ActionUtil {
  /**
   * Copies input field text to clipboard
   */
  static copyToClipboard = (ref) => {
    ref.select();
    document.execCommand('copy');
    ref.blur();
  };

  /**
   * Handle the logic to redirect a user if they are not logged in.
   */
  static handleLoggedOutRedirect(idToken) {
    // See if this is an SSO link, if it is, just allow the natural redirect to take place
    const queryParams = queryString.parse(window.location.search);
    let isSSO = false;
    let logoutOkta = false;
    if (queryParams && queryParams.sso && queryParams.login_hint) {
      isSSO = true;
    }
    let redirectUri = process.env.REACT_APP_V2_SITE_URL;
    // If user is on /link-account, need to ensure they are logged out completely and sent to the reauthn login page (hides register links)
    if (/link-account/.test(window.location.pathname)) {
      redirectUri = `${process.env.REACT_APP_V2_SITE_URL}/login-reauthn/`;
      logoutOkta = true;
    } else if (
      // Check to see if we are looking at a course page
      !isSSO &&
      /browse\/(course|skill-certification-course)\//.test(window.location.pathname)
    ) {
      // Redirect to the same url, minus /browse, and on www
      redirectUri = process.env.REACT_APP_V2_SITE_URL + window.location.pathname.replace('/browse/refined/', '/');
    } else if (isSSO) {
      redirectUri = `${process.env.REACT_APP_V2_SITE_URL}/login/?sso=1&login_hint=${queryParams.login_hint}&actual_redirect=${encodeURIComponent(window.location.href)}`;
    } else if (/logout/.test(window.location.pathname)) {
      // We are on the logout page
      redirectUri = process.env.REACT_APP_V2_SITE_URL + process.env.REACT_APP_LOGIN_PATH;
      logoutOkta = true;
    } else {
      const redirectUrl = encodeURIComponent(window.location.href);

      redirectUri = `${process.env.REACT_APP_V2_SITE_URL + process.env.REACT_APP_LOGIN_PATH}?redirect_to=${redirectUrl}`;
    }

    if (idToken && logoutOkta) {
      window.location.href = `${oktaLogoutUrl}?id_token_hint=${idToken}&post_logout_redirect_uri=${encodeURIComponent(redirectUri)}`;
    } else {
      window.location.href = redirectUri;
    }
  }

  static printToPdf(container, margin, filename, html2canvas, jsPDF, callback, enableLinks = false) {
    const canvasOpts = {
      ...html2canvas,
      logging: false,
      ignoreElements: (e) => {
        // Ignore any elements that are hidden from print, only for screen readers, or contain specific classes
        return (e.classList.contains('print:hidden') || e.classList.contains('sr-only') || e.classList.contains('pdf-print-hidden')) && !e.classList.contains('pdf-print');
      },
    };

    // Update line heights -- fixes PDF alignment
    const body = document.querySelector('body');
    body.style.lineHeight = 0.75;

    const competencyLevels = document.querySelector('.competency-levels');
    if (competencyLevels) {
      competencyLevels.style.lineHeight = '1.5rem';
    }

    // Reset line heights
    const resetStyles = () => {
      body.style.lineHeight = '';
      if (competencyLevels) {
        competencyLevels.style.lineHeight = '';
      }
    };

    const opt = {
      margin: margin || [10, 10, 10, 10],
      filename,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: canvasOpts,
      jsPDF,
      enableLinks,
    };
    setTimeout(() => {
      html2pdf()
        .from(container)
        .set(opt)
        .save()
        .then(() => {
          resetStyles();
          if (callback) {
            callback();
          }
        })
        .catch(() => {
          resetStyles();
        });
    }, 100);
  }

  static sleep(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  /**
   * Scrolls to the top of the page
   */
  static scrollToTop(behavior = 'smooth') {
    // Scroll cyb-router to top (our main content area that scrolls)
    document?.getElementsByClassName('cyb-content')?.[0].scrollTo({
      top: 0,
      behavior,
    });
  }
}

export async function copyToClipboard(text) {
  try {
    await navigator.clipboard.writeText(text);
    return true;
  } catch (err) {
    return false;
  }
}

export default ActionUtil;
