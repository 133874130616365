/* eslint-disable no-console */
import React, { useEffect } from 'react';
import { twMerge } from 'tailwind-merge';
import { throttle } from 'lodash';
import NewLearnerView from '../../Clab/components/NewLearner/new-learner-view';
import { Warning } from '../Mobile/Warnings';
import { useClab } from '../../../providers/ClabProvider';

function ClabContainer({ clabId, generalFrame, isMobile }) {
  const { launched, launch, reset, loading, labInstanceId, token, session } = useClab();
  // Make sure that we can't double click launch by throttling it (will immediately fire)
  const throttledLaunch = throttle(() => launch(clabId), 2500, { leading: true, trailing: false });

  useEffect(() => {
    // Reset state of provider when exiting
    return () => reset();
  }, [clabId]);

  if (isMobile) {
    // Can't render clab on mobile!
    return (
      <div className={`flex justify-center px-4 items-center bg-black immersive-2023-lesson-frame ${generalFrame}`}>
        <Warning warning="This virtual lab has been optimized for a desktop environment." hasALab hiddenSizeClass="lg:hidden" />
      </div>
    );
  }

  const btnLoadingClasses = loading ? 'cursor-not-allowed bg-pink-600' : 'cursor-pointer bg-cyb-pink-500';
  const btnClasses = twMerge(btnLoadingClasses, `rounded-md font-semibold py-2 hover:bg-pink-600 text-white text-center w-32 text-base`);
  const btnText = loading ? 'Loading...' : 'Start Lab';

  if (!launched) {
    return (
      <div className={`flex justify-center items-center bg-black immersive-2023-lesson-frame ${generalFrame}`}>
        <button type="button" onClick={throttledLaunch} disabled={loading} className={btnClasses}>
          {btnText}
        </button>
      </div>
    );
  }

  return (
    <div className={`immersive-2023-lesson-frame ${generalFrame}`}>
      <NewLearnerView labInstanceIdentifier={labInstanceId} token={token} session={session} />
    </div>
  );
}

export default ClabContainer;
