/**
 * This is a temporary page used to house collections of links to V2 pages.
 */

import React, { Component } from 'react';
import { observer } from 'mobx-react';
import AddLink from '../AddLink/AddLink';
import Segment from '../Segment/Segment';
import Icon from '../Icon/Icon';
import './conversationlinks.css';

export default observer(
  class ConversationLinks extends Component {
    formatConvoLink = (item) => {
      const retArr = [];
      const retArrContent = [];
      retArrContent.push(
        <div key={item.href} className="link-container">
          <div className="link-icon">
            <Icon name={item.icon} className="w-7 h-7" />
          </div>
          <div className="link-content">
            <p className="content-heading">{item.heading}</p>
            <p className="content-text">{item.text}</p>
          </div>
          <div className="link-icon right">
            <Icon name="chevron-right" />
          </div>
        </div>
      );

      if (item.href) {
        retArr.push(
          <Segment key={item.href}>
            <AddLink to={item.href} target={item.target}>
              {retArrContent}
            </AddLink>
          </Segment>
        );
      } else {
        // For when a function is passed instead of a link
        retArr.push(
          <Segment key={item.href} onClick={() => item.action()}>
            {retArrContent}
          </Segment>
        );
      }

      return retArr;
    };

    render() {
      return (
        <div className="conversation-links">
          {this.props.data.map((item) => {
            return <React.Fragment key={item.href}>{this.formatConvoLink(item)}</React.Fragment>;
          })}
        </div>
      );
    }
  }
);
