import React from 'react';
import { useProfileContext } from '../../providers/ProfileProvider';
import { useBadgeModal } from '../../providers/BadgeModalProvider';
import Badge from '../Profile/ProfileTabs/BadgesTab/Badge';

/**
 * Content badge for displaying a badge on the /browse/ pages
 * @param {int} badgeId - the id of the badge to display
 * @param {string} className - the class name to apply to the badge
 * @returns <ContentBadge /> the content badge
 */
function ContentBadge({ badgeId, className }) {
  const { badges } = useProfileContext();
  const { open } = useBadgeModal();
  const badge = badges?.find((b) => b.id === badgeId);

  if (!badge) return null;
  return (
    <div className={`mb-8 ${className}`}>
      <h3 className="pb-4 text-2xl font-black">Badges</h3>
      <Badge badge={badge} onClick={() => open(badge)} />
    </div>
  );
}

export default ContentBadge;
