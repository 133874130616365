import React from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { Navigate } from 'react-router-dom';
import withRouter from '../../components/Router/withRouter';
import Dropdown from '../../components/Dropdown/Dropdown';
import Input from '../../components/FormFields/Input';
import Title from '../../components/Title/Title';
import Loading from '../../components/Loading/Loading';
import StyledError from '../../components/Error/StyledError';
import Container from '../../components/Container/Container';
import DatePicker from '../../components/DatePicker/DatePicker';
import './assignments.css';

const CreateAssignment = inject(
  'commonStore',
  'enterpriseStore',
  'userStore',
  'authStore'
)(
  observer(
    class CreateAssignment extends React.Component {
      state = {
        orgId: null,
        view: null,
        assignId: null,
        groupSelection: null,
        submittingAssignment: false,
      };

      componentDidMount() {
        this.props.authStore.fireAttributionEvent();
        this.props.commonStore.setPageTitle('Create Assignment | Cybrary');
        this.props.userStore.setPreferredTeamInit('', this.props.match.params.orgId, this.init);
      }

      componentDidUpdate(prevProps) {
        this.props.userStore.setPreferredTeamInit(prevProps.match.params.orgId, this.props.match.params.orgId, this.init);
      }

      componentWillUnmount() {
        this.props.enterpriseStore.setDefaultUserListData();
        this.props.enterpriseStore.setDefaultGroupList();
        this.props.enterpriseStore.setDefaultAssignmentData();
      }

      getBreadCrumbs() {
        const crumbs = [];
        crumbs.push({
          href: `/enterprise/${this.state.orgId}/assignments`,
          label: 'Assignments',
        });
        crumbs.push({
          href: `/enterprise/${this.state.orgId}/assignment/edit/${this.state.assignId}`,
          label: `Edit Assignment`,
        });
        return crumbs;
      }

      init = (orgId) => {
        const { team, user } = this.props.userStore;
        const view = this.props.match.params.assignid ? 'edit' : 'add';
        const newState = {
          ...this.state,
          orgId,
          view,
          assignId: this.props.match.params.assignid ? this.props.match.params.assignid : null,
        };
        this.setState(newState, () => {
          // If this user can't manage all assignments, set their group filter automatically to the current assignmentTeamGroupId
          if (team && team.permissions && team.permissions.canManageAssignments !== 'all') {
            this.setGroupSelection(team.assignmentsTeamGroupId);
          }

          this.props.enterpriseStore.loadOwnerListData(team.id, user);

          if (this.props.match.params.assignid) {
            // Edit existing assignment
            this.props.enterpriseStore.getAssignment(team.assignmentsTeamGroupId, this.props.match.params.assignid);
          } else {
            const curriculaId = this.props.match.params.id;
            // Create new assignment
            this.props.enterpriseStore.setDefaultAssignmentData();
            this.props.enterpriseStore.getUserListData(team.assignmentsTeamGroupId);
            this.props.enterpriseStore.getGroupListData(team.assignmentsTeamGroupId);
            // Load in curricula
            this.props.enterpriseStore.loadCurricula(orgId, null, team.package_types).then(() => {
              // If we have a curricula ID passed in, lets find that curricula and get the name and set the assignment name
              if (curriculaId) {
                this.setCurriculaName(curriculaId);
              }
            });

            if (curriculaId) {
              this.setAssignmentParams('curriculum_id', curriculaId * 1);
            }
          }
        });
      };

      setGroupSelection = (id) => {
        const newState = {
          ...this.state,
          groupSelection: id,
        };
        this.setState(newState);
      };

      handleGroupSelect = (groupId) => {
        const { team } = this.props.userStore;
        // If this is a group level admin, set this to preferred group
        if (team && team.permissions && team.permissions.canManageAssignments !== 'all') {
          this.props.userStore.setPreferredGroup(this.state.orgId, groupId);
        }
        // Refresh user list scoped to group selected and reset the user assigned to
        this.props.enterpriseStore.getUserListData(groupId || team.assignmentsTeamGroupId);
        this.props.enterpriseStore.setAssignmentParams('assignee_id', '');
        this.props.enterpriseStore.setAssignmentParams('assignee_type', 'all');
        this.setGroupSelection(groupId);
      };

      setCurriculaName = (id) => {
        const curriculaTypes = ['team', 'cybrary']; // team or cybrary for now
        curriculaTypes.forEach((type) => {
          if (this.props.enterpriseStore.curricula[type] && this.props.enterpriseStore.curricula[type].length) {
            this.props.enterpriseStore.curricula[type].forEach((item) => {
              if (1 * item.id === 1 * id) {
                this.props.enterpriseStore.setAssignmentParams('name', item.name);
              }
            });
          }
        });
      };

      setAssignmentParams = (param, val) => {
        this.props.enterpriseStore.setAssignmentParams(param, val);
      };

      formatDropdownData = (data) => {
        const returnData = [];
        // There are two arrays of data - Cybrary curricula and Custom (team) curricula
        if (data && data.team && data.team.length) {
          data.team.forEach((item) => {
            returnData.push({
              key: item.id,
              value: item.id,
              text: `${item.name}`,
            });
          });
        }
        if (data && data.cybrary && data.cybrary.length) {
          data.cybrary.forEach((item) => {
            returnData.push({
              key: item.id,
              value: item.id,
              text: `${item.name} [Cybrary Paths]`,
            });
          });
        }
        return returnData;
      };

      formatOwnerList = (owners) => {
        if (!owners) return null;
        return owners.map((owner) => {
          return {
            key: owner.text,
            text: owner.text,
            value: owner.value,
          };
        });
      };

      /* When setting a user for assignment, need to set assignee id and type */
      setUserParms = (val) => {
        if (val) {
          this.setAssignmentParams('assignee_type', 'user');
          this.setAssignmentParams('assignee_id', val);
        } else {
          this.setAssignmentParams('assignee_type', 'all');
          this.setAssignmentParams('assignee_id', null);
        }
      };

      submitAssignment = () => {
        const { team } = this.props.userStore;
        const { assignmentData } = this.props.enterpriseStore;
        // Confirm needed fields are populated
        if (assignmentData.data.name && assignmentData.data.curriculum_id) {
          const newState = {
            ...this.state,
            submittingAssignment: true,
          };
          this.setState(newState, () => {
            const teamGroupId = this.state.groupSelection || team.assignmentsTeamGroupId;
            if (this.state.view === 'add') {
              // If the audience selection is "all", no specific user/group assignment needed. Otherwise, required
              if (assignmentData.data.assignee_type === 'all' || (assignmentData.data.assignee_type !== 'all' && assignmentData.data.assignee_id)) {
                this.props.enterpriseStore
                  .submitAssignment(teamGroupId, assignmentData.data)
                  .then(() => {
                    this.props.authStore.fireAttributionEvent('assignment:created');
                    this.props.commonStore.triggerToast('success', {
                      content: 'New assignment added',
                    });
                    // Reset field selections
                    this.props.enterpriseStore.setDefaultAssignmentData();
                    const resetState = {
                      ...this.state,
                      groupSelection: null,
                      submittingAssignment: false,
                    };
                    this.setState(resetState);
                  })
                  .catch((e) => {
                    this.props.commonStore.triggerToast('error', {
                      errorCode: e && e.response && e.response.status,
                    });
                  });
              }
            } else {
              this.props.enterpriseStore
                .editAssignment(teamGroupId, assignmentData.data, this.state.assignId)
                .then(() => {
                  this.props.commonStore.triggerToast('success', {
                    content: 'Assignment has been edited',
                  });
                  const resetState = {
                    ...this.state,
                    submittingAssignment: false,
                  };
                  this.setState(resetState);
                })
                .catch((e) => {
                  this.props.commonStore.triggerToast('error', {
                    errorCode: e.response.status,
                  });
                });
            }
          });
        }
      };

      addAssignmentForm = (curricula, loading, error) => {
        if (loading) {
          return (
            <Container>
              <Loading message="Loading..." />
            </Container>
          );
        }
        if (error) {
          return (
            <Container>
              <StyledError error={error} />
            </Container>
          );
        }
        return (
          <>
            <div className="grid grid-cols-2 gap-8">
              <div>
                <p>Assignment Name</p>
                <Input
                  ref={this.name}
                  placeholder="Enter an assignment name"
                  value={this.props.enterpriseStore.assignmentData.data.name}
                  onChange={(data, value) => this.setAssignmentParams('name', value.value)}
                />
              </div>
              <div>
                <p>
                  Due Date <span style={{ fontSize: '.75em', verticalAlign: 'bottom' }}>(optional)</span>
                </p>
                <DatePicker
                  disabled
                  onChange={(selDate) => {
                    const date = selDate ? new Date(moment(selDate)) : null;
                    this.setAssignmentParams('due_date', date);
                  }}
                  value={this.props.enterpriseStore.assignmentData.data.due_date ? new Date(moment(this.props.enterpriseStore.assignmentData.data.due_date)) : null}
                  className="w-44"
                  style={{ paddingTop: '5px', paddingBottom: '5px' }}
                  label="Due Date"
                />
              </div>
              <div>
                <p>Send Progress Notification to</p>
                <Dropdown
                  id="owner"
                  isSearchable
                  isClearable
                  isMulti
                  options={this.formatOwnerList(this.props.enterpriseStore.ownerListData.data)}
                  name="owner"
                  value={this.props.enterpriseStore.assignmentData.data.owner_ids}
                  defaultValue={this.props.enterpriseStore.assignmentData.data.owner_ids ? this.props.enterpriseStore.assignmentData.data.owner_ids.slice() : []}
                  onChange={(selections) => {
                    if (selections?.length) {
                      this.setAssignmentParams(
                        'owner_ids',
                        selections.map((selection) => selection.value)
                      );
                    } else {
                      this.setAssignmentParams('owner_ids', []);
                    }
                  }}
                />
              </div>
            </div>
            <button
              className="py-2.5 px-6 mt-8 text-sm font-bold leading-5 text-center text-white hover:text-white bg-cyb-pink-500 hover:bg-pink-600 rounded-sm"
              onClick={() => this.submitAssignment()}
            >
              {this.state.view === 'add' ? 'Create Assignment' : 'Edit Assignment'}
            </button>
          </>
        );
      };

      render() {
        const { curricula, assignmentData } = this.props.enterpriseStore;
        const { loading, error } = curricula;
        const { view, orgId } = this.state;
        if (view === 'add') {
          return <Navigate replace to={`/enterprise/${orgId}/assignments`} />;
        }
        return (
          <Container size="md" className="create-assignments">
            <Title title={this.state.view === 'add' ? 'Create Assignment' : 'Edit Assignment'} breadcrumbs={this.getBreadCrumbs()} />
            {this.state.view === 'add' ? this.addAssignmentForm(curricula, loading, error) : this.addAssignmentForm(null, assignmentData.loading, assignmentData.error)}
          </Container>
        );
      }
    }
  )
);

export default withRouter(CreateAssignment);
