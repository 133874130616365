import React from 'react';
import Icon from '../Icon/Icon';
import FormatUtil from '../../utils/formatUtil';
import Label from '../Label/Label';
import Tooltip from '../Tooltip/Tooltip';
import './main-table.css';

function isActivityCompleted(data, enrollmentData) {
  const { content } = data;
  const contentId = content && content.id ? content.id : null;
  const completedContentDescIds = enrollmentData && enrollmentData.completed_content_description_ids;
  return contentId && completedContentDescIds ? completedContentDescIds.includes(contentId) : false;
}

//  Quick functional component render a single row, allows us to target the individual row with handlers
function Row({ data, moduleNum, idx, isFreeUser, courseIsFree, enrollmentData, isLast }) {
  const { content, icon, experience_points_total, optional } = data;
  let iconColor = 'text-gray-600';
  const isFreeContent = content && content.is_free;

  if ((isFreeUser && !courseIsFree) || (!isFreeContent && isFreeUser)) {
    iconColor = 'text-cyb-pink-500';
  } else if (isActivityCompleted(data, enrollmentData)) {
    iconColor = 'text-green-600';
  }

  const showLockIcon = isFreeUser && !isFreeContent;
  const borderClass = isLast ? 'border-transparent' : 'border-gray-400';
  const labelClass = 'ml-1 py-1 px-2 text-xs whitespace-nowrap border uppercase rounded-sm';
  return (
    <li className={`py-4 flex font-normal text-base text-gray-600 border-b-xs ${borderClass}`}>
      <div className="flex flex-1 items-center">
        <Icon className={`${iconColor || ''} w-4 h-4 mr-2 stroke-2`} name={icon} />
        {moduleNum ? (
          <div style={{ display: 'inline-block' }}>
            <span style={{ paddingRight: '0.5em' }}>
              {moduleNum}.{idx + 1}
            </span>
            <span>{FormatUtil.stripNumberFromActivityTitle(content.title)}</span>
          </div>
        ) : (
          <div>{content.title}</div>
        )}
      </div>

      <div className="flex items-center grow-1">
        {content.type === 'Video Activity' || content.type === 'Lesson Activity' || content.type === 'Assessment Activity' ? null : (
          <Label className={`course-outline-label hidden sm:inline-block ${labelClass}`} color="black" basic>
            {FormatUtil.formatContentType(content.type)}
          </Label>
        )}
        {optional && (
          <Label className={`course-outline-label hidden sm:inline-block ${labelClass}`} color="black" basic>
            Optional
          </Label>
        )}
        {courseIsFree && isFreeContent ? (
          <Label className={`course-outline-label hidden sm:inline-block ${labelClass}`} color="blue" basic>
            Free
          </Label>
        ) : null}
        {showLockIcon ? (
          <div className="inline-block relative">
            <Tooltip
              content="To access upgrade to Cybrary Insider Pro or Cybrary for Teams."
              triggerContent={<Icon name="lock" className="ml-4 w-5 h-5 text-gray-600" />}
              size="small"
              wide
            />
          </div>
        ) : null}
      </div>

      <div className="ml-3 text-left">
        {experience_points_total ?? 0} XP | {content.duration}
      </div>
    </li>
  );
}

function HeaderRow({ headerRow }) {
  return headerRow.map((header) => {
    return (
      <li className="font-bold" key={header.content}>
        {header.content}
      </li>
    );
  });
}

function BodyRow({ content, moduleKey, moduleNum, isFreeUser, handleClick, courseIsFree, enrollmentData }) {
  if (!content || !content.length) {
    return null;
  }
  return content.map((data, idx) => {
    const key = `${moduleKey}_${data.content.title}`;
    const isLast = idx === content.length - 1;
    return (
      <Row
        key={key}
        idx={idx}
        data={data}
        isLast={isLast}
        moduleNum={moduleNum}
        handleClick={handleClick}
        isFreeUser={isFreeUser}
        courseIsFree={courseIsFree}
        enrollmentData={enrollmentData}
      />
    );
  });
}

function MainTable({ headerRow, content, isFreeUser, courseIsFree, enrollmentData, handleClick, moduleNum, moduleKey }) {
  return (
    <ul>
      {headerRow ? <HeaderRow headerRow={headerRow} /> : null}
      <BodyRow
        content={content}
        handleClick={handleClick}
        moduleNum={moduleNum}
        moduleKey={moduleKey}
        isFreeUser={isFreeUser}
        courseIsFree={courseIsFree}
        enrollmentData={enrollmentData}
      />
    </ul>
  );
}

export default MainTable;
