import React, { useState } from 'react';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import Loading from '../Loading/Loading';
import Tooltip from '../Tooltip/Tooltip';
import ActionsUtil from '../../utils/actionsUtil';

const getElement = (container) => {
  return document.querySelectorAll(container)[0];
};

function PrintToPdfButton({ className, color, ariaLabel, container, margin = [10, 10, 10, 10], filename = 'page.pdf', html2canvas, jsPDF, enableLinks = false }) {
  const [loading, setLoading] = useState(false);
  if (!container) {
    return null;
  }
  return (
    <>
      <Tooltip
        content="Download PDF"
        omitTabIndex
        triggerContent={
          <Button
            color={color}
            disabled={loading}
            ariaLabel={ariaLabel || 'Download page PDF'}
            className={`${className || ''} print:hidden px-3`}
            onClick={() => {
              setLoading(true);
              // Slight pause to let loading state set
              setTimeout(() => {
                const element = getElement(container);
                if (element) {
                  ActionsUtil.printToPdf(element, margin, filename, html2canvas, jsPDF, () => setLoading(false), enableLinks);
                }
              }, 100);
            }}
          >
            <Icon name="download" className="w-5 h-5" />
          </Button>
        }
      />
      {loading && (
        <div className="print:hidden fixed top-0 left-0 z-50 w-full h-full" style={{ backgroundColor: 'rgba(255, 255, 255, 0.85)', zIndex: '2000' }}>
          <Loading className="mt-48 mb-4 w-10 h-10 border-4" messageClassName="font-semibold" message="Generating PDF..." />
        </div>
      )}
    </>
  );
}

export default PrintToPdfButton;
