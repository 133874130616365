import React from 'react';
import LoadingFacts from '../../components/Loading/LoadingFacts';

function LoadingPage({ message }) {
  return (
    <div className="overflow-hidden pt-8 h-screen bg-gray-200">
      <LoadingFacts message={message} />
    </div>
  );
}

export default LoadingPage;
