import React, { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import Container from '../../components/Container/Container';
import CreateAccount from '../../components/Checkout/CreateAccount';
import useQueryParams from '../../hooks/useQueryParams';
import agents from '../../agents/agents';
import BugsnagUtil from '../../utils/bugsnagUtil';
import FormatUtil from '../../utils/formatUtil';
import { APP_FORM_IDS } from '../../constants';
import CybraryLogo from '../../components/CybraryLogo/CybraryLogo';
import AddLink from '../../components/AddLink/AddLink';

function ErrorMessage({ title = '', message = '' }) {
  return (
    <p className="p-2 my-4 text-sm rounded border-2 border-cyb-pink-500">
      <span className="pr-2 font-bold">{title}:</span>
      {message}
    </p>
  );
}

function RegistrationErrors({ registrationError }) {
  if (!registrationError) return null; // No errors
  const errors = registrationError?.response?.data?.errors; // Laravel errors
  if (!errors) {
    // Not laravel errors, this is a generic error
    return <ErrorMessage title="Error" message={registrationError?.message} />;
  }
  const errorKeys = Object.keys(errors);
  return errorKeys?.map((errorKey) => {
    return <ErrorMessage key={`${errorKey} errors`} title={`${FormatUtil.ucFirstLetter(errorKey)} Error`} message={errors[errorKey]?.join('. ')} />;
  });
}

function RegisterPageComponent({ userStore }) {
  const navigate = useNavigate();
  const { googleMarketplaceId } = useQueryParams();
  const [error, setError] = useState(null);
  const handleRegister = async (data) => {
    try {
      const { email, password, subscribe, tos, pp, captchav3, referral, language } = data || {};
      const registrationPayload = {
        email,
        password,
        // password_verify is set to password b/c we removed confirm password field, but backend still expects password verify
        password_verify: password,
        subscribe: !!subscribe,
        tos: !!tos,
        pp: pp?.length,
        captchav3,
        verificationMethod: 'code',
        // If we have a google marketplace id in the query params, pass it along
        googleMarketplaceId,
        // Our internal referral code (if provided)
        referral,
        // Preferred language
        language: language || 'en',
      };
      // attempt to register account
      await agents.auth.googleCloudRegister(registrationPayload);
      const currentParams = new URLSearchParams(window.location.search);
      navigate(`/verification-code?email=${email}&${currentParams.toString()}`);
      return true;
    } catch (err) {
      // Send bugsnag event
      BugsnagUtil.notify(err);
      setError(err);
      return false;
    }
  };

  // If the user is already logged in, redirect to the dashboard
  if (userStore?.user?.id) {
    return <Navigate replace to="/" />;
  }

  return (
    <Container className="flex flex-col justify-center items-center mt-4">
      <AddLink to="/login">
        <CybraryLogo />
      </AddLink>
      <h1 className="text-4xl font-bold">Register</h1>
      {error && <RegistrationErrors registrationError={error} />}
      <CreateAccount formId={APP_FORM_IDS.AUTH.CREATE_ACCOUNT} handleSubmit={(data) => handleRegister(data)} />
    </Container>
  );
}

const RegisterPage = inject('userStore')(observer(RegisterPageComponent));

export default RegisterPage;
