import React from 'react';

export default function GoalFlag({ classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';
  return (
    <svg className={className} width="28" height="32" viewBox="0 0 28 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...accessibilityProps}>
      <path
        d="M22.6688 7.61788L27.0078 2.1942C25.9566 2.06733 17.6212 -0.150149 9.29896 1.63495V0H7.4166V22.7073C5.79464 23.1263 4.59308 24.6019 4.59308 
        26.3529V26.4476C2.44776 26.8849 0.828369 28.7863 0.828369 31.0588V32H15.8872V31.0588C15.8872 28.7862 14.2678 26.8849 12.1225 26.4476V26.3529C12.1225 
        24.602 10.9209 23.1263 9.29896 22.7073V14.8809C18.92 12.9365 25.8719 15.3253 27.1717 15.498L22.6688 7.61788ZM13.8435 30.1176H2.87198C3.26037 
        29.0221 4.30715 28.2353 5.53425 28.2353H11.1813C12.4084 28.2353 13.4551 29.0221 13.8435 30.1176ZM10.2401 26.3529H6.47543C6.47543 25.315 7.31985 
        24.4706 8.35778 24.4706C9.39571 24.4706 10.2401 25.315 10.2401 26.3529ZM9.29896 12.9614V3.5621C13.4608 2.60405 18.1522 2.57349 23.5758 
        3.47081L20.3997 7.44094L23.4617 12.7994C18.7528 12.0024 14.0023 12.0564 9.29896 12.9614Z"
        fill="black"
      />
    </svg>
  );
}
