import React from 'react';
import Checkbox from '../FormFields/Checkbox';
import { getCN } from '../../utils/formatUtil';

class CheckboxGroup extends React.Component {
  state = { value: this.props.defaultValue && this.props.defaultValue.length ? this.props.defaultValue : [] };

  handleChange = (e, data) => {
    // Manage LOCAL state of the radio group, while calling the parents onChange function.
    const existingIndex = this.state.value.indexOf(data.value);
    const newState = { ...this.state };
    if (existingIndex === -1) {
      // We are checking! Add the value to the array
      newState.value.push(data.value);
    } else {
      // We are UN-checking! Remove the value from the array
      newState.value.splice(existingIndex, 1);
    }
    this.setState(newState);
    this.props.onChange(e, { name: data.name, value: newState.value });
  };

  render() {
    const { id, options, name, fieldsPerRow, ariaDescribedBy, required, wrapperClassName = '', optionClassName = '', labelClasses = '' } = this.props;
    // Generate our radio fields from the provided options.
    if (!options) {
      return null;
    }

    const checkContainer = getCN(`grid
      ${fieldsPerRow === 1 && 'grid-cols-1'}
      ${fieldsPerRow === 2 && 'grid-cols-2'}
      ${fieldsPerRow === 3 && 'grid-cols-3'}
      ${fieldsPerRow === 4 && 'grid-cols-4'}
    `);

    const checkboxesOuput = options.map((option) => {
      const labelText = option.text ? option.text : option.value;
      const optionId = `${id}-${option.value}`;
      return (
        <div key={optionId} className={wrapperClassName}>
          <Checkbox
            id={optionId}
            label={labelText}
            ariaDescribedBy={ariaDescribedBy}
            value={option.value}
            name={name}
            checked={this.state.value.indexOf(option.value) !== -1}
            required={required}
            onChange={this.handleChange}
            className={optionClassName}
            labelClasses={labelClasses}
          />
        </div>
      );
    });

    return <div className={checkContainer}>{checkboxesOuput}</div>;
  }
}

export default CheckboxGroup;
