import React, { useState } from 'react';
import Icon from '../Icon/Icon';
import Modal from '../Modal/Modal';
import Table from '../Table/Table';
import Tooltip from '../Tooltip/Tooltip';
import WorkroleReport from './WorkroleReport';

function WorkroleModal({ getWorkroleData, enrollmentId, scoreId, buttonFormat, loading, error, data, showLevelUpMsg, adminMemberDashboard }) {
  const [workroleModal, setWorkroleModal] = useState(false);

  const openWorkroleModal = (e) => {
    e.stopPropagation();
    setWorkroleModal(true);
    getWorkroleData(enrollmentId, scoreId);
  };

  const closeWorkroleModal = () => {
    setWorkroleModal(false);
  };

  return (
    <>
      {buttonFormat ? (
        <button onClick={(e) => openWorkroleModal(e)} className="py-2.5 px-6 text-sm font-bold leading-5 text-center text-black bg-gray-200 rounded-sm">
          View Results
        </button>
      ) : (
        <Table.Cell collapsing textAlign="right">
          <Tooltip
            content="View your skill assessment results"
            position="top-end"
            omitTabIndex
            triggerContent={
              <button onClick={(e) => openWorkroleModal(e)}>
                <Icon className="w-4 h-4 text-cyb-pink-500 fill-current" name="line-graph" />
              </button>
            }
          />
        </Table.Cell>
      )}
      <Modal size="xl" position="center" ariaLabelledBy="workrole-title" open={workroleModal} toggle={closeWorkroleModal}>
        <WorkroleReport loading={loading} error={error} data={data} showLevelUpMsg={showLevelUpMsg} adminMemberDashboard={adminMemberDashboard} />
      </Modal>
    </>
  );
}

export default WorkroleModal;
