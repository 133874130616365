import React from 'react';

export default function Alert({ classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';
  return (
    <svg className={className} viewBox="0 0 179 161" fill="none" xmlns="http://www.w3.org/2000/svg" {...accessibilityProps}>
      <path
        d="M175.489 128.769L107.326 10.7073C103.536 4.14246 96.7468 0.222656 89.1657 0.222656C81.5849 0.222656 74.7957 4.14246 71.0055 10.7073L2.84294 128.768C-0.947646 135.334 
        -0.947646 143.173 2.84294 149.738C6.63352 156.303 13.4219 160.223 21.0031 160.223H157.328C164.909 160.223 171.698 156.303 175.489 149.738C179.279 143.173 179.279 135.334 
        175.489 128.769ZM166.441 144.515C164.538 147.809 161.132 149.775 157.328 149.775H21.0031C17.1989 149.775 13.7925 147.809 11.8908 144.515C9.98907 141.22 9.98907 137.287 
        11.8908 133.993L80.0541 15.9315C81.9558 12.6372 85.3622 10.6707 89.166 10.6707C92.9695 10.6707 96.3763 12.6372 98.278 15.9315L166.441 133.993C168.342 137.287 168.342 
        141.22 166.441 144.515Z"
        fill="currentColor"
      />
      <path d="M93.9362 56H84.5625V102.869H93.9362V56Z" fill="currentColor" />
      <path
        d="M89.2494 112.242C85.8034 112.242 83 115.046 83 118.492C83 121.937 85.8034 124.741 89.2494 124.741C92.695 124.741 95.4987 121.937 95.4987 118.492C95.4987 115.046 92.6953 
        112.242 89.2494 112.242Z"
        fill="currentColor"
      />
    </svg>
  );
}
