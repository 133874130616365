import React, { useMemo } from 'react';
import TeamOnboardingStep from '../../TeamOnboardingStep';
import { TEAM_ONBOARDING_STEPS, TEAM_ONBOARDING_OPTIONS, useTeamOnboarding } from '../../../../providers/TeamOnboardingProvider';
import MultiSelect from '../../../FormFields/MultiSelect';

function SelectGoalsStep() {
  const { setTeamGoals, teamGoals, setStep } = useTeamOnboarding();

  const options = useMemo(() => {
    return Object.keys(TEAM_ONBOARDING_OPTIONS.teamGoals).map((key) => {
      const goal = TEAM_ONBOARDING_OPTIONS.teamGoals[key];
      return {
        key: goal?.key,
        text: goal?.text,
        value: goal?.key,
      };
    });
  }, []);

  const handleChange = (e, { value }) => {
    setTeamGoals(value);
    setStep(TEAM_ONBOARDING_STEPS.SELECT_GOALS);
  };

  const canAdvance = teamGoals?.length > 0;

  return (
    <TeamOnboardingStep
      title="Question 2/3:"
      subtitle="What are your Team's goals for utilizing Cybrary? Choose all that apply:"
      prevStep={TEAM_ONBOARDING_STEPS.SELECT_ROLE}
      nextStep={TEAM_ONBOARDING_STEPS.SELECT_LEARNING_APPROACH}
      isNextStepDisabled={!canAdvance}
    >
      <MultiSelect options={options} onChange={handleChange} defaultValue={teamGoals} classes="my-6" />
    </TeamOnboardingStep>
  );
}

export default SelectGoalsStep;
