import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import WidgetContainer from '../Container/WidgetContainer';
import { useCareerPrograms } from '../../providers/CareerProgramsProvider';
import Header from '../Header/Header';
import SolidChip from '../Chip/SolidChip';
import Button from '../Button/Button';
import ProgressBar from '../ProgressBar/ProgressBar';
import Image from '../Image/Image';
import If from '../If/If';
import Icon from '../Icon/Icon';
import LoadingText from '../Loading/LoadingText';
import FormatUtil from '../../utils/formatUtil';
import AddLink from '../AddLink/AddLink';
import PermalinkUtil from '../../utils/permalinkUtil';
import StyledError from '../Error/StyledError';
import { useProfileContext } from '../../providers/ProfileProvider';

function SectionTitle({ children }) {
  return <div className="text-xs font-bold text-slate-600 uppercase">{children}</div>;
}

/**
 * Career Program Continue Learning Widget
 * @returns {JSX.Element} - the continue learning widget
 */
function CareerProgramContinueLearningWidget() {
  const navigate = useNavigate();
  const { badges } = useProfileContext();
  const {
    currentGlobalProgramEnrollment,
    isLoadingAllCareerPrograms,
    isLoadingCurrentProgram,
    currentProgramError,
    allCareerProgramsError,
    currentPageProgram: program,
  } = useCareerPrograms();
  // Get the first incomplete enrollment from the enrollments array in currentProgramEnrollment
  const currentProgramChildEnrollment = useMemo(() =>
    currentGlobalProgramEnrollment?.enrollments?.find((enrollment) => !!enrollment && !enrollment.completed_at, [currentGlobalProgramEnrollment])
  );
  const currentProgramChildEnrollmentData = currentProgramChildEnrollment?.transformedData;

  const { currentActivity, currentContentItem, activitiesCompleted, activitiesTotal, secondsCompleted, secondsTotal, domains, topics, linkToImmersive } =
    currentProgramChildEnrollmentData || {};
  // format current lesson title to remove any leading (and possibly wrong) activity numbers
  const currentLessonTitle = useMemo(() => FormatUtil.formatActivityTitle(currentActivity?.title || 'Start next activity'), [currentProgramChildEnrollmentData]);
  const currentContentTitle = useMemo(() => FormatUtil.formatActivityTitle(currentContentItem?.title || 'Start next content item'), [currentProgramChildEnrollmentData]);
  const completedPercent = useMemo(() => Math.floor((activitiesCompleted / activitiesTotal) * 100), [activitiesCompleted, activitiesTotal]);
  const remainingTime = useMemo(() => FormatUtil.formatTime(secondsTotal - secondsCompleted, 'hma')?.trim(), [secondsCompleted, secondsTotal]);

  const coursePermalink = currentContentItem?.permalink ? PermalinkUtil.formatInApp(currentContentItem?.permalink) : '';
  const { badge_ids, content_item } = program || {};
  const { heroGraphic } = content_item?.meta || {};
  const isProgramComplete = Boolean(currentGlobalProgramEnrollment?.completed_at);
  const heroGraphicSaturation = isProgramComplete ? 100 : 0;
  const heroGraphicClassName = `-mt-4 w-12 h-12 saturate-${heroGraphicSaturation}`;
  const isLoading = isLoadingAllCareerPrograms || isLoadingCurrentProgram;

  let continueLearningLink = linkToImmersive;
  if (!continueLearningLink && currentProgramChildEnrollment) {
    const baseLink = `/immersive/${currentProgramChildEnrollment.id}`;
    const itemLink = currentContentItem?.id ? `/item/${currentContentItem?.id}` : '';
    const activityLink = currentActivity?.id ? `/activity/${currentActivity?.id}` : '';
    continueLearningLink = baseLink + itemLink + activityLink;
  }

  const continueLearning = useCallback(() => navigate(continueLearningLink), [navigate, continueLearningLink, currentGlobalProgramEnrollment?.content_description_id]);

  // If we have no current content item, we should not render the widget
  if (!isLoading && !currentContentItem) {
    return null;
  }

  // If our current global program enrollment is not the same as the current page program, we should not render the widget
  if (!isLoading && currentGlobalProgramEnrollment?.content_description_id !== program?.id) {
    return null;
  }

  if (isLoading) {
    return null;
  }

  return (
    <WidgetContainer className="py-2 pt-3 mb-8 rounded-md border-2 border-gray-300">
      {/** Errors */}
      <If condition={!!allCareerProgramsError}>
        <StyledError error={allCareerProgramsError} />
      </If>
      <If condition={!!currentProgramError}>
        <StyledError error={currentProgramError} />
      </If>
      {/** Main Display */}
      <If condition={!allCareerProgramsError && !currentProgramError}>
        {/** Top Half */}
        <div className="flex-col space-y-2">
          <div id="career-program-current-module" className="justify-between pb-2 space-y-2 md:flex md:space-x-2">
            <div className="flex-col mt-auto">
              <SectionTitle>Current {currentContentItem?.content_type?.nice_name || 'Item'}</SectionTitle>
              <AddLink to={coursePermalink} title={`View ${currentContentItem?.content_type?.nice_name || 'Item'}`}>
                <Header as="h3" className="pb-0 mb-0">
                  <LoadingText isLoading={isLoading} className="w-64 h-4">
                    {currentContentTitle}
                  </LoadingText>
                </Header>
              </AddLink>

              <AddLink to={continueLearningLink} title="Continue Learning">
                <Header as="h5" className="flex items-center pb-0 mb-0">
                  <LoadingText isLoading={isLoading} className="w-48 h-2">
                    {currentLessonTitle}
                  </LoadingText>
                </Header>
              </AddLink>
            </div>
            <div className="block lg:flex lg:space-x-16">
              {/** Tags */}
              <div className="flex mt-auto space-x-2">
                <LoadingText isLoading={isLoading} className="w-24 h-4">
                  {domains?.map((domain) => (
                    <AddLink
                      to={`/browse/refined?terms_info=domains%7C${encodeURIComponent(String(domain).toLowerCase().replaceAll(' ', '-'))}`}
                      key={domain}
                      title={`View more ${domain} content`}
                    >
                      <SolidChip key={domain}>{domain}</SolidChip>
                    </AddLink>
                  ))}
                </LoadingText>
                <LoadingText isLoading={isLoading} className="w-24 h-4">
                  {topics?.map((topic) => (
                    <AddLink
                      to={`/browse/refined?terms_info=topics%7C${encodeURIComponent(String(topic).toLowerCase().replaceAll(' ', '-'))}`}
                      key={topic}
                      title={`View more ${topic} content`}
                    >
                      <SolidChip key={topic}>{topic}</SolidChip>
                    </AddLink>
                  ))}
                </LoadingText>
                <LoadingText isLoading={isLoading} className="w-16 h-4">
                  <If condition={currentContentItem?.experience_points_total}>
                    <SolidChip className="flex items-center align-middle">
                      {currentContentItem?.experience_points_total || 0} <Icon name="xp" className="ml-1 w-3 h-3 text-black" />
                    </SolidChip>
                  </If>
                </LoadingText>
              </div>
              {/** Progress */}
              <div className="flex-col mt-auto space-y-1">
                <LoadingText isLoading={isLoading} className="w-24">
                  <span className="text-xs text-gray-700">
                    {completedPercent || '0'}% Complete ({remainingTime || 'Some time'} remaining)
                  </span>
                </LoadingText>
                <LoadingText isLoading={isLoading} className="w-48">
                  <ProgressBar progress={completedPercent || 0} animate />
                </LoadingText>
              </div>
            </div>

            {/** Continue Learning Button */}
            <div className="mt-auto lg:px-8">
              <Button onClick={continueLearning} disabled={isLoading} color={isLoading ? 'gray' : null} className="w-full md:w-auto">
                {isLoading ? 'Loading...' : 'Continue Learning'}
              </Button>
            </div>
          </div>
        </div>
        {/** Divider */}
        <div className="my-2 border-t border-gray-400" />
        {/** Bottom Half */}
        <div id="career-program-current-progress" className="flex-col space-y-2">
          <SectionTitle>Program Progress</SectionTitle>
          <div className="flex space-x-4">
            {/** Badge requirements as progress bars */}
            <If condition={isLoading}>
              <LoadingText wrapperClassName="mb-3 w-full" className="h-3" />
              <LoadingText wrapperClassName="mb-3 w-full" className="h-3" />
              <LoadingText wrapperClassName="mb-3 w-full" className="h-3" />
              <LoadingText wrapperClassName="mb-3 mx-4" className="p-6 mx-auto -mt-2" />
            </If>
            <If condition={!isLoading}>
              <If condition={badge_ids && badge_ids.length > 0}>
                {/** Loop requirement_badge_ids because its guaranteed to be in order from the backend */}
                {badge_ids?.map((badgeId, index) => {
                  const badge = badges?.find((b) => b.id === badgeId);
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={index} className="w-full">
                      <div className="flex justify-between">
                        <div className="w-full">
                          <ProgressBar progress={parseInt(badge?.progress, 10) || 0} animate wrapperClassName="w-full" />
                          <span className="text-xs text-gray-600">{badge?.study_guide_title || badge?.name || 'Continue'}</span>
                        </div>
                        <If condition={badge_ids && index === badge_ids.length - 1}>
                          <div className="shrink-0 mx-4">
                            <Image src={heroGraphic} className={heroGraphicClassName} />
                          </div>
                        </If>
                      </div>
                    </div>
                  );
                })}
              </If>
              <If condition={!badge_ids?.length}>
                <p className="text-gray-600">No Required Badge IDs have been set yet!</p>
              </If>
            </If>
          </div>
        </div>
      </If>
    </WidgetContainer>
  );
}

export default CareerProgramContinueLearningWidget;
