import React from 'react';
import moment from 'moment';
import Icon from '../Icon/Icon';
import AddLink from '../AddLink/AddLink';

/**
 * Button component
 * @param popup
 * @returns {*}
 */
function CTAButton({ button }) {
  const { link, text } = button;
  return (
    <AddLink to={link} target="_blank">
      <button className="px-6 h-10 text-sm font-bold leading-5 text-center text-white bg-cyb-pink-500 hover:bg-pink-600 rounded-sm">{text}</button>
    </AddLink>
  );
}

/**
 * The container for the user type component
 */
function UserTypeId({ userStore, commonStore }) {
  const { user } = userStore;
  const upgradeLink = commonStore.getUpgradeLink('?membership=btn');

  let userContent = {};
  if (user.is_cip) {
    userContent = {
      icon: 'diamond',
      color: 'yellow',
      text: 'Cybrary Insider Pro',
    };
  } else if (user.is_enterprise) {
    userContent = {
      icon: 'globe',
      color: 'grey',
      text: 'Enterprise',
    };
  } else {
    userContent = {
      icon: 'user',
      color: 'pink',
      text: 'Free',
      button: {
        link: upgradeLink,
        text: 'Upgrade',
      },
    };
  }

  return (
    <div className="p-4 w-80 rounded-sm border-xs border-gray-400">
      <div className="text-center">
        {userContent.icon ? <Icon name={userContent.icon} className="mx-auto w-4 h-4" /> : null}
        {userContent.text ? <h3 className="text-lg font-bold">{userContent.text}</h3> : null}
        {user.registered_at ? (
          <p className="text-sm text-gray-600">
            <span className="date">Cybrarian since {moment(user.registered_at).format('MMM Do, YYYY')}</span>
          </p>
        ) : null}
      </div>
      {userContent.button ? (
        <div className="pt-4 mt-4 border-t-xs border-gray-400">
          <CTAButton button={userContent.button} />
        </div>
      ) : null}
    </div>
  );
}

export default UserTypeId;
