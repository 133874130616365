import React, { useMemo } from 'react';
import { Radar } from 'react-chartjs-2';
import { twMerge } from 'tailwind-merge';
import { LabelTransformer } from '../../../utils/chartUtil';

/** @important these are the default options that you can override */
const chartOptionsDefaults = {
  max: 100,
  min: 0,
  stepSize: 20,
  borderColor: 'rgba(223, 0, 115, 1)',
  pointBackgroundColor: '#ff1390',
  rayLinesColor: '#cfd8dd',
  gridLinesColor: '#64656a',
  radarBackgroundColor: 'rgba(226,4,122, 0.2)',
  pointBorderColor: 'white',
  autoWrapLabels: false,
  autoWrapThreshold: 1,
};
/**
 * @param {object} data - array of numbers
 * @param {object} chartOptionsOverrides - overrides for the chart options
 * @param {string} containerClasses - container classes for the radar chart
 * @returns Radar Chart
 */
function RadarChart({ data = {}, chartOptionsOverrides = {}, containerClasses = '' }) {
  if (Object.keys(data).length < 3) return <p>Not enough data</p>;

  const chartOptions = {
    ...chartOptionsDefaults,
    ...chartOptionsOverrides,
  };
  const labels = Object.keys(data);
  const formattedLabels = chartOptions.autoWrapLabels ? LabelTransformer(labels, chartOptions.autoWrapThreshold) : labels;
  const dataForGraph = {
    labels: formattedLabels,
    backgroundColor: 'blue',
    datasets: [
      {
        label: 'Score',
        data: Object.values(data),
        backgroundColor: chartOptions.radarBackgroundColor,
        borderColor: chartOptions.borderColor,
        pointBackgroundColor: chartOptions.pointBackgroundColor,
        pointBorderColor: chartOptions.pointBorderColor,
        borderWidth: 1,
        tension: 0,
      },
    ],
  };

  // @see https://www.chartjs.org/docs/2.9.4/charts/radar.html#point-styling
  const options = {
    legend: {
      display: false,
    },
    responsive: true, // this will make the chart responsive
    maintainAspectRatio: false, // you may want to add this if you want to define custom dimensions
    tooltips: {
      callbacks: {
        title: () => {
          return ``;
        },
        label: (tooltipItem, tooltipData) => {
          if (!tooltipData || !tooltipItem) {
            return '';
          }
          const { index } = tooltipItem;
          const { datasets } = tooltipData;
          const value = datasets[0]?.data[index];
          const label = tooltipData.labels[index].join(' ');
          return ` ${label} - ${value}%`;
        },
      },
    },
    scale: {
      pointLabels: {
        fontSize: chartOptions?.scale?.pointLabels?.fontSize || 10, // changes the font size of the labels surrounding the graph
      },
      beginAtZero: true,
      max: 100,
      ticks: {
        // Hides the labels on the graph ticks (i.e. 20, 40, 60, 80)
        display: false,
        stepSize: chartOptions.stepSize,
        suggestedMin: chartOptions.min,
        suggestedMax: chartOptions.max,
      },
      angleLines: {
        // changes the colors of the ray lines
        color: chartOptions.rayLinesColor,
      },
      gridLines: {
        color: chartOptions.gridLinesColor,
      },
    },
  };
  const chartContainerClasses = useMemo(() => twMerge('items-center w-full h-full', containerClasses), [containerClasses]);
  return (
    <div id="radar-chart-container" className={chartContainerClasses}>
      <Radar data={dataForGraph} options={options} />
    </div>
  );
}
export default RadarChart;
