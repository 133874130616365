import React from 'react';
import Step from '../Steps/Step';
import ContentCard from '../Card/ContentCard';
import ContentTransformer from '../../transformers/contentTransformer';
import FormatUtil from '../../utils/formatUtil';
import CertificationCtaButton from './CertificationCtaButton';

export default function CertificationPrepSteps({ className = '', learningContent, scrollToAdditionalLearning, steps }) {
  if (!steps || !Object.keys(steps).length) {
    return null;
  }

  const pClasses = 'text-gray-600 text-sm';
  return (
    <div className={className}>
      <Step stepNumber={1} step="Quiz Your Knowledge with a Pre-Test">
        <p className={`${pClasses} mb-8`}>Complete the diagnostics test to identify your key focus areas.</p>
        <CertificationCtaButton data={steps[1]} />
      </Step>
      <Step stepNumber={2} step="Learn at Your Own Pace">
        <p className={`${pClasses} mb-4`}>Find resources prepared by subject matter experts and aligned to the certification exam’s key learning objectives.</p>
        <div className="-ml-12 sm:ml-0">
          {learningContent && learningContent.length ? (
            <div className="flex overflow-x-auto relative flex-col gap-y-8 mb-6 sm:flex-row md:flex-wrap md:gap-8">
              {learningContent.map((item) => {
                const formattedAlgoliaObj = ContentTransformer.formatDataSources(item);
                const contentType = FormatUtil.formatContentType(formattedAlgoliaObj.content_type);
                formattedAlgoliaObj.badge = contentType === 'Virtual Lab' ? 'OPTIONAL' : null;
                return (
                  <div className="flex" key={item.id}>
                    <ContentCard
                      data={formattedAlgoliaObj}
                      className="sm:w-60"
                      cardProps={{ menuAbove: true }}
                      badgeClassName="absolute top-2 right-2 p-1 text-2xs text-cyb-blue-500 font-semibold bg-white rounded-sm border border-cyb-blue-500"
                    />
                  </div>
                );
              })}
            </div>
          ) : null}
          <button aria-label="Scroll down to additional content" onClick={scrollToAdditionalLearning} className="text-cyb-pink-500 hover:text-black underline" to="/browse">
            Dig deeper for additional content
          </button>
        </div>
      </Step>
      <Step stepNumber={3} step="Take the Practice Exam">
        <p className={`${pClasses} mb-8`}>Feel confident you’re ready to pass the exam by validate and measuring readiness.</p>
        <CertificationCtaButton data={steps[3]} />
      </Step>
      <Step stepNumber={4} step="Take the Official Exam" omitDivider>
        <p className={`${pClasses} mb-8`}>Head to the official certification website and schedule your exam.</p>
        <CertificationCtaButton data={steps[4]} />
      </Step>
    </div>
  );
}
