import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { Navigate } from 'react-router-dom';

import AddLink from '../../components/AddLink/AddLink';
import FormatUtil from '../../utils/formatUtil';
import Button from '../../components/Button/Button';
import Table from '../../components/Table/Table';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import DynamicForm from '../../components/DynamicForm/DynamicForm';
import AdminTable from '../../components/AdminTable/AdminTable';
import Loading from '../../components/Loading/Loading';
import useTableData from '../../hooks/useTableData';
import CybraryAdminLogo from './CybraryAdminLogo';

const CybraryAdminBrowseChallengeCourses = inject(
  'commonStore',
  'adminStore',
  'authStore'
)(
  observer(({ commonStore, authStore, adminStore }) => {
    useEffect(() => {
      adminStore.checkAccess(authStore);
      commonStore.hidePrimaryNav();
    }, []);

    const { tableData, loading, error, queryParams, setQueryParams, sort, changePage } = useTableData(
      { query: { format: 'table', activePg: 1, recordsPerPage: 25, sortCol: 'title', sortDirection: 'asc', searchQuery: '' }, table: {} },
      'getChallengeCourses'
    );

    const getBreadCrumbs = () => {
      const crumbs = [];
      crumbs.push({
        href: '/admin',
        label: 'Admin',
      });
      crumbs.push({
        label: 'Browse Courses',
      });
      return crumbs;
    };

    const checkFieldLength = (data) => {
      return data.search && data.search.length > 3 ? null : 'Search must include at least 4 characters';
    };

    const searchCourseForm = () => {
      const order = ['search', 'submit'];
      return {
        order,
        fields: {
          search: {
            type: 'text',
            placeholder: 'Search by course title',
            description: 'Minimum of 4 characters required for search',
            validations: [checkFieldLength],
          },
          submit: {
            type: 'button',
            color: 'pink',
            icon: 'search',
            label: 'Search',
          },
        },
      };
    };

    const handleSubmit = (data) => {
      if (!!data && !!data.search && data.search.length) {
        setQueryParams({ ...queryParams, searchQuery: data.search });
      }
    };

    const formatContentId = (row, headings) => {
      const contentId = row[FormatUtil.getColIndex(headings, 'id')].value;
      const viewLink = `/admin/challenge/courses/${contentId}`;

      return (
        <Table.Cell key={contentId}>
          <AddLink to={viewLink}>
            <Button color="gray">View Challenges</Button>
          </AddLink>
        </Table.Cell>
      );
    };

    const { loading: adminLoading, denyAccess } = adminStore;
    if (denyAccess) {
      return <Navigate to="/" />;
    }

    if (adminLoading || adminLoading === null) {
      return (
        <div className="pt-10">
          <Loading message="Loading..." />
        </div>
      );
    }

    return (
      <div className="p-5 pt-0">
        <CybraryAdminLogo />

        <Breadcrumbs crumbs={getBreadCrumbs()} />
        <div className="p-4 rounded border border-gray-400">
          <h1 className="text-4xl font-semibold">Search For Courses with Challenges</h1>
          <DynamicForm form={searchCourseForm()} formName="searchCourseForm" onSubmit={handleSubmit} />

          <AdminTable
            /* Table Data */
            headings={tableData.tableHeadings}
            data={tableData.data}
            tableLoading={loading}
            tableError={error}
            displayCheckBox={false}
            headerClickFunc={sort}
            sortCol={queryParams.sortCol}
            sortDirection={queryParams.sortDirection}
            addCustomCol={[
              {
                method: formatContentId,
                position: 4,
                header: 'Action',
              },
            ]}
            className="four column"
            /* Pagination options */
            pagNumPgs={tableData.pagPagesCount}
            pagChangePg={changePage}
            pagActivePg={queryParams.activePg}
          />
        </div>
      </div>
    );
  })
);

export default CybraryAdminBrowseChallengeCourses;
