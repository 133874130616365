import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

import Container from '../../components/Container/Container';
import Title from '../../components/Title/Title';
import ContentCard from '../../components/Card/ContentCard';
import ContentTransformer from '../../transformers/contentTransformer';
import Pagination from '../../components/Pagination/Pagination';

const BrowseCertificationPrep = inject(
  'commonStore',
  'userStore',
  'authStore',
  'searchStore'
)(
  observer(({ searchStore, commonStore }) => {
    const { searchItems } = searchStore || {};
    const currentPage = searchStore.searchFilters.page;
    const navigate = useNavigate();

    useEffect(() => {
      commonStore.setPageTitle('Certification Prep | Cybrary');
    }, []);

    useEffect(() => {
      searchStore.loadSearchItems();
    }, [currentPage]);

    const pageChange = (page) => {
      searchStore.pageChange(page);
      const newQueryParams = `page=${page}`;
      navigate({
        search: newQueryParams,
      });
    };
    const { certificationPrep } = searchItems?.data || {};

    // The actual certification prep items (algolia hits)
    const certificationPrepItems = certificationPrep?.hits || [];

    return (
      <Container size="card-container-v2">
        <Title title="Certification Prep" omitPadding wrapperClasses="pt-8 md:pt-12 mb-4" />
        <p className="mb-4 max-w-150 text-sm text-gray-600">Assess, learn, and validate your knowledge to prepare for a certification exam.</p>
        <div className="mb-4">
          {certificationPrepItems.map((item) => {
            const preppedData = ContentTransformer.formatDataSources(item);
            return <ContentCard key={item.id} data={preppedData} className="first:pt-4 pb-4 my-4 first:border-t-xs border-b-xs border-gray-400 last:border-none" isList />;
          })}
        </div>
        <div className="flex justify-center w-full">
          <Pagination totalPages={certificationPrep?.nbPages} activePage={currentPage} onPageChange={pageChange} siblingRange={1} />
        </div>
      </Container>
    );
  })
);
export default BrowseCertificationPrep;
