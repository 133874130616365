import React from 'react';

import AdditionalSkills from '../../components/CareerPaths/AdditionalSkills';
import ShortDescription from '../../components/CareerPaths/ShortDescription';
import GoalSection from '../../components/BrowsePromo/GoalSection';
import ContentBadge from '../../components/BrowsePromo/ContentBadge';
import If from '../../components/If/If';

export default function CareerPathOverview({ data, shortDescription, contentfulCareerPathData, userType }) {
  if (!data || !contentfulCareerPathData) {
    return null;
  }

  const { topics } = contentfulCareerPathData;
  const wrapperStyles = 'mb-12';
  const contentDescriptionId = (data && data.content_item && data.content_item.content_description_id) || null;
  return (
    <div className="flex flex-col lg:flex-row lg:gap-x-8 xl:gap-x-20">
      {/* Start of left side column */}
      <div>
        <ShortDescription className={`${wrapperStyles}`} shortDescription={shortDescription} />
      </div>

      {/* Start of Right side column */}
      <div className="lg:shrink-0 lg:w-106">
        <ContentBadge badgeId={data.badge_id} />
        <If condition={!data.badge_id}>
          <GoalSection type={data.content_type?.human_readable_name || 'Career Path'} userType={userType} permalink={data.permalink} contentDescriptionId={contentDescriptionId} />
        </If>
        <AdditionalSkills skills={topics} className={wrapperStyles} />
      </div>
    </div>
  );
}
