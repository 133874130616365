import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { observer, inject } from 'mobx-react';
import { throttle, startCase } from 'lodash';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import Validator from 'validatorjs';
import Bugsnag from '@bugsnag/js';
import { fireFormSnowplowSubmission } from '../../utils/snowplowUtil';
import Form, { FormGroup } from '../FormFields/Form';
import DynamicField from './DynamicField';
import ValidationUtil from '../../utils/validationUtil';
import FormatUtil from '../../utils/formatUtil';
import './dynamic-form.css';

/**
 * Dynamic form component
 * @param props
 * @returns {*}
 * @constructor
 */

// Prevent id collisions between forms with the same field names
const formPrefix = uuidv4();

const DynamicForm = inject(
  'authStore',
  'userStore'
)(
  observer(
    class DynamicForm extends React.Component {
      state = {
        _pristine: true, // whether or not the form has been submitted
        _buttons_enabled: true,
        _inferred_country: 'US', // country from geocookie
        _isValid: false, // whether or not the form is valid and passing all required field validations
        formId: this.props.formId || uuidv4(),
      };

      componentDidMount() {
        this.props.authStore
          .getGeoInfo()
          .then((geo) => {
            if (geo && geo.countryCode) {
              const newState = {
                ...this.state,
                _inferred_country: geo.countryCode,
                _isValid: this.validateAllFields(), // validate all fields on mount
              };
              this.setState(newState);
            }
          })
          .catch((err) => {
            Bugsnag.notify(err);
            this.setState({ _isValid: this.validateAllFields() }); // validate all fields on mount
          });
      }

      // Generic on submit handler
      onSubmit = throttle(() => {
        const { onSubmit, onInvalidSubmit, discardSnowplowEvent } = this.props;
        const { formId } = this.state;

        this.setState({ _pristine: false });
        const valid = this.validateAllFields();

        if (valid) {
          const data = { ...this.state };
          // Clean up state values before submitting
          delete data._pristine;
          delete data._buttons_enabled;

          onSubmit(data);
          // stop event propagation to discard snowplow event when validation fails, or discardSnowplowEvent prop is set
          if (!discardSnowplowEvent) {
            // handle formatting of phone number before firing snowplow form submission
            const { phone = '' } = data;
            const additionalData = {
              phone,
            };

            fireFormSnowplowSubmission(formId, additionalData);
          }
        } else if (onInvalidSubmit) {
          onInvalidSubmit();
        } else if (this.props.scrollTo) {
          this.props.scrollTo();
        }
      }, 750);

      onInit = (name, value) => {
        this.onChange(undefined, { name, value });
      };

      // Generic on change handler, updates state
      onChange = (e, { name, value }, callback) => {
        // update state value
        this.setState({ [name]: value }, () => {
          if (this.props.handleOnChange) {
            const data = { ...this.state };
            // Clean up state values before submitting
            delete data._pristine;
            delete data._buttons_enabled;
            this.props.handleOnChange(data);
            // update validation state
            // IMPORTANT: This _isValid is used in v3 recaptcha to determine if the form is submittable
            this.setState({ _isValid: this.validateAllFields(false) }, () => {
              // run callback if provided
              // IMPORTANT: This callback is used in v3 recaptcha button to submit the form
              if (callback && typeof callback === 'function') {
                callback();
              }
            });
          }
        });

        if (this.props.clearErrorForField) {
          const { formName } = this.props;
          // Laravel returns errors keyed by fornName.fieldName, construct that prefix here if this form has a name
          const errorKey = formName ? `${formName}.${name}` : name;
          this.props.clearErrorForField(errorKey);
        }
      };

      // Generic on change handler, updates state
      onRate = (e, { name, rating }) => {
        this.setState({ [name]: rating });
        if (this.props.clearErrorForField) {
          const { formName } = this.props;
          // Laravel returns errors keyed by fornName.fieldName, construct that prefix here if this form has a name
          const errorKey = formName ? `${formName}.${name}` : name;
          this.props.clearErrorForField(errorKey);
        }
      };

      cleanup = (whichField) => {
        // Remove this fields data from state.
        if (this.state[whichField]) {
          const newState = { ...this.state };
          newState[whichField] = undefined;
          this.setState(newState);
        }
      };

      errorFieldFocus = (fieldName) => {
        const errorElement = document.getElementById(fieldName);
        if (errorElement) {
          errorElement.focus();
        }
      };

      validateAllFields = (focusErrors = true) => {
        const { form, id } = this.props;
        // There's nothing to validate... shouldn't ever occur.
        if (!form || !form.order || !form.fields) {
          return true;
        }
        let valid = true;
        // An optional function that the caller can pass to run anything at the start of validation (good for setting loading state)
        if (this.props.startValidation) {
          this.props.startValidation();
        }
        form.order.forEach((fieldId) => {
          if (valid === true) {
            // we stop validating once we find the first invalid field.
            if (typeof fieldId === 'object') {
              // We have an array of fields, we are going to be creating a field group
              fieldId.forEach((subFieldId) => {
                if (valid === true) {
                  // don't do any more work than we have to
                  const field = form.fields[subFieldId];
                  if (this.validateField(subFieldId, field, true) !== null) {
                    // We got an error state, the form is invalid!
                    valid = false;
                    if (focusErrors) {
                      this.errorFieldFocus(`${id || formPrefix}_${subFieldId}`);
                    }
                  }
                }
              });
            } else {
              const field = form.fields[fieldId];
              if (this.validateField(fieldId, field, true) !== null) {
                // We got an error state, the form is invalid!
                valid = false;
                if (focusErrors) {
                  this.errorFieldFocus(`${id || formPrefix}_${fieldId}`);
                }
              }
            }
          }
        });
        // An optional function that the caller can pass to run anything at the end of validation (good for setting loading state on invalid)
        if (this.props.endValidation) {
          this.props.endValidation(valid);
        }
        return valid;
      };

      getFormattedParams = (parts) => {
        /* if we get a param with an escaped string with a comma inside of it...
        replace the comma with $ so it wont be incorrectly separated on the next line */
        const paramWithReplacedCommas = parts[1].replace(/"[^"]+"/g, (match) => match.replace(/,/g, '$'));
        // separate each string into its own param by comma
        const params = paramWithReplacedCommas.split(',');
        /* now that we have each param, ensure if any params that have the $ used earlier to prevent incorrect separating,
        has the comma added back so the value matches the display text that was given to use from the BE...
        and additional quotes removed */
        return params.map((param) => param?.replaceAll('"', '').replaceAll('$', ','));
      };

      /**
       * Validate required if checks a field to see if it is required based on the value of another field
       * @important Wrap values in double quotes to escape strings in values containing commas
       * @param {*} field - The field to validate
       * @param {*} validation - The validation string to parse and check
       * @returns {boolean} - Whether or not the field is required
       * @example validateRequiredIf(field, 'required_if:fieldToCheck,value1,value2,...')
       * @example validateRequiredIf(field, 'required_if:fieldToCheck,"value1","value2",...')
       */
      validateRequiredIf = (field, validation) => {
        let validateField = false;
        const fieldKey = field.name ? field.name : field.id;
        const value = this.state[fieldKey];
        const parts = validation.split(':');
        const formattedParams = this.getFormattedParams(parts);
        const fieldToCheck = formattedParams.shift();
        const valueToCheck = this.state[fieldToCheck];
        if (formattedParams.indexOf(valueToCheck) !== -1 && value !== 0) {
          validateField = true;
        }
        return validateField;
      };

      validateField = (id, field, forceValidation) => {
        let error = null; // assume a true field
        // We have validations, let's confirm that they all return true.
        const fieldKey = field.name ? field.name : id;
        if ((forceValidation || !this.state._pristine) && field.validations) {
          // check to see if we should be displaying the field
          const hasField = this.fieldShouldDisplay(field);
          if (hasField) {
            // we have the field, let's validate it
            const value = this.state[fieldKey];

            // const phoneReg = /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[-. \\/]?)?((?:\(?\d{1,}\)?[-. \\/]?){0,})(?:[-. \\/]?(?:#|ext\.?|extension|x)[-. \\/]?(\d+))?$/i; // replaced the above regex
            // We will stop on our first validation error
            if (field.fieldFrom === 'profile') {
              error = this.profileValidation(field);
            } else {
              const nullable = field.validations.indexOf('nullable') !== -1;
              field.validations.forEach((validation) => {
                const errorMessage = field.label ? `"${FormatUtil.truncateString(field.label, 6)}" is required` : 'This field is required';
                if (!error) {
                  // only check if we haven't reached an error state
                  if (typeof validation === 'function') {
                    // We have a functional validation to call
                    error = validation(this.state);
                  } else if (validation.indexOf('required_if') === 0) {
                    error = this.validateRequiredIf(field, validation) && !value ? errorMessage : null;
                  } else {
                    // We have one of our predefined types!
                    switch (validation) {
                      case 'number':
                        // If nullable, and empty value, skip this validation
                        if (nullable && !value && value !== 0) {
                          error = null;
                        } else {
                          error = Number.isNaN(Number(value)) ? 'Must be a number' : null;
                        }
                        break;

                      case 'alphanumeric':
                        // If nullable, and empty value, skip this validation
                        if (nullable && !value && value !== 0) {
                          error = null;
                        } else {
                          error = !ValidationUtil.checkIsAlphanumeric(value) ? 'This field can only contain letters and numbers' : null;
                        }
                        break;

                      case 'phone':
                        // If nullable, and empty value, skip this validation
                        if (nullable && !value && value !== 0) {
                          error = null;
                        } else {
                          const phone = value ? parsePhoneNumberFromString(value, 'US') : undefined;
                          error = !phone || !phone.isValid() ? 'Must be a valid phone number' : null;
                        }
                        break;

                      case 'email':
                        // If nullable, and empty value, skip this validation
                        if (nullable && !value && value !== 0) {
                          error = null;
                        } else {
                          error = !ValidationUtil.checkValidEmail(value) ? 'Must be a valid email address' : null;
                        }
                        break;

                      case 'email_match':
                        // If nullable, and empty value, skip this validation
                        if (nullable && !value && value !== 0) {
                          error = null;
                        } else {
                          error = this.state.email !== value ? 'This does not match' : null;
                        }
                        break;

                      case 'required':
                        if (field.type === 'typeAheadObject') {
                          error = this.typeAheadValidation(field);
                        }
                        if (['singleFileUpload', 'singleImageUpload'].indexOf(field.type) > -1) {
                          error = !value || (!!value && !value.path) ? errorMessage : null;
                        } else if (typeof value === 'object' && field.type !== 'typeAheadObject') {
                          error = !value || (!!value && !value.length) ? errorMessage : null;
                        } else {
                          error = !value && value !== 0 ? errorMessage : null;
                        }
                        break;

                      // Default case, nothing to do here.
                      default:
                        break;
                    }
                  }
                }
              });
            }
          }
        }
        if (!error) {
          error = this.getServerErrorsForField(fieldKey);
        }
        return error;
      };

      fieldShouldDisplay = (field, keepIfNoValue) => {
        let fieldShouldDisplay = true;
        if (field.conditions && typeof field.conditions === 'function') {
          return field.conditions(this.state);
        }
        // Our new conditions can be expressed as an array of objects
        if (field.conditions && Array.isArray(field.conditions)) {
          // The default conditions array will be treated as an AND, so all elements must evaluate to TRUE
          for (let i = 0; i < field.conditions.length; i++) {
            const condition = field.conditions[i];
            const fieldName = condition.field;
            const fieldValue = condition.value;
            const currentValue = this.state[fieldName];

            if (keepIfNoValue && !currentValue) {
              return true;
            }

            const fieldValues = [];
            if (Array.isArray(fieldValue)) {
              fieldValue.forEach((value) => {
                if (value === 'undefined') {
                  fieldValues.push(undefined);
                }
                fieldValues.push(value);
              });
            }
            fieldShouldDisplay = this.getShouldFieldDisplay(fieldShouldDisplay, condition, fieldValue, currentValue, fieldValues);
          }
        }
        return fieldShouldDisplay;
      };

      filterFieldOptions = (field) => {
        // if no options, nothing to filter
        if (!field.options) {
          return field;
        }

        // options have conditions the exact same as fields... using existing conditional fields logic to filter options
        const filteredOptions = field.options.filter((option) => {
          return this.fieldShouldDisplay(option, true);
        });
        const fieldCopy = { ...field };
        fieldCopy.options = filteredOptions;
        return fieldCopy;
      };

      getShouldFieldDisplay = (fieldShouldDisplay, condition, fieldValue, currentValue, fieldValues) => {
        let shouldFieldDisplay = fieldShouldDisplay;
        // We will return the first time we reach a FALSE value, otherwise continue the loop
        switch (condition.op) {
          case 'eq':
            if (currentValue !== fieldValue) {
              shouldFieldDisplay = false;
            }
            break;

          case 'neq':
            if (currentValue === fieldValue) {
              shouldFieldDisplay = false;
            }
            break;

          case 'in':
            if (fieldValue.indexOf(currentValue) === -1) {
              shouldFieldDisplay = false;
            }
            break;

          case 'nin':
            if (fieldValues.indexOf(currentValue) !== -1) {
              shouldFieldDisplay = false;
            }
            break;

          case 'rin':
            // custom reverse in
            if (currentValue === undefined || (currentValue && currentValue.indexOf(fieldValue) === -1)) {
              shouldFieldDisplay = false;
            }
            break;
          default:
            // Nothing to do here...
            break;
        }
        return shouldFieldDisplay;
      };

      // Get server errors for a field
      getServerErrorsForField = (fieldKey) => {
        const { serverErrors } = this.props;
        if (serverErrors) {
          const { formName } = this.props;
          // Laravel returns errors keyed by formName.fieldName, construct that prefix here if this form has a name
          const errorKey = formName ? `${formName}.${fieldKey}` : fieldKey;
          if (serverErrors[errorKey]) {
            // It will be an array, so just grab the first element and we have our error.
            return serverErrors[errorKey][0];
          }
        }

        return null;
      };

      generateFieldOutput = (form) => {
        if (!form || !form.order || !form.fields) {
          return null;
        }
        let fields = [];
        const customRenderers = this.props.customRenderers ? this.props.customRenderers : {};
        form.order.forEach((fieldId, index) => {
          if (typeof fieldId === 'object') {
            // We have an array of fields, we are going to be creating a field group
            const subFields = this.getSubFields(fieldId, form, customRenderers);
            if (subFields.length) {
              const columnClass = form.columns && index <= form.columns.length ? form.columns[index] : '';
              const formGroupFields = this.addFormGroup(subFields, fieldId, columnClass);
              fields = fields.concat(formGroupFields);
            }
          } else {
            const mainFields = this.getMainFields(fieldId, form, customRenderers);
            fields = fields.concat(mainFields);
          }
        });
        return fields;
      };

      getSubFields = (fieldId, form, customRenderers) => {
        const { id } = this.props;
        const subFields = [];
        fieldId.forEach((subFieldId) => {
          const field = form.fields[subFieldId];
          if (field) {
            if (!field.name) {
              field.name = subFieldId;
            }
            const displayField = this.fieldShouldDisplay(field);
            if (displayField) {
              subFields.push(
                <DynamicField
                  id={`${id || formPrefix}_${subFieldId}`}
                  key={subFieldId}
                  {...field}
                  onChange={this.onChange}
                  onInit={this.onInit}
                  value={this.state[field.name]}
                  cleanup={this.cleanup}
                  required={this.displayRequired(field)}
                  error={this.validateField(fieldId, field)}
                  customRenderer={customRenderers[subFieldId]}
                  onRate={this.onRate}
                  formState={this.state}
                  toggleButtons={this.toggleButtons}
                  onSubmit={this.onSubmit}
                />
              );
            }
          }
        });
        return subFields;
      };

      addFormGroup = (subFields, fieldId, columnClass) => {
        const fields = [];
        const backFields = subFields.filter((field) => field.props.name === 'back' || field.props.name === 'cancel' || field.props.flex === true);
        const inline = backFields && backFields.length;

        fields.push(
          <FormGroup key={`group${fieldId}`} inline={inline} columnClass={columnClass}>
            {subFields}
          </FormGroup>
        );
        return fields;
      };

      getMainFields = (fieldId, form, customRenderers) => {
        const { id } = this.props;
        const fields = [];
        const field = form.fields[fieldId];
        if (field) {
          // Set a default field name from the ID if none exists
          if (!field.name) {
            field.name = fieldId;
          }
          const displayField = this.fieldShouldDisplay(field);
          if (displayField) {
            // new field with filtered options based on conditions
            const newField = this.filterFieldOptions(field);
            fields.push(
              <DynamicField
                id={`${id || formPrefix}_${fieldId}`}
                key={newField.name}
                {...newField}
                onChange={this.onChange}
                onInit={this.onInit}
                value={this.state[newField.name]}
                cleanup={this.cleanup}
                formId={this.state.formId}
                required={this.displayRequired(newField)}
                error={this.validateField(fieldId, newField)}
                customRenderer={customRenderers[fieldId]}
                onRate={this.onRate}
                formState={this.state}
                toggleButtons={this.toggleButtons}
                onSubmit={this.onSubmit}
              />
            );
          }
        }
        return fields;
      };

      // Returns a promise after buttons are toggled
      toggleButtons = (val) => {
        if (this._buttons_enabled === val) {
          return new Promise((resolve) => {
            resolve(val);
          });
        }
        return new Promise((resolve) => {
          const newState = {
            ...this.state,
            _buttons_enabled: val,
          };
          this.setState(newState, () => resolve(val));
        });
      };

      displayRequired(field) {
        if (field.required) {
          return true;
        }
        if (field.validations) {
          for (let i = 0; i < field.validations.length; i++) {
            if (typeof field.validations[i] !== 'function') {
              if (field.validations[i].indexOf('required_if') === 0) {
                return this.validateRequiredIf(field, field.validations[i]);
              }
              if (field.validations[i].indexOf('required') === 0) {
                return true;
              }
            }
          }
        }
        return false;
      }

      profileValidation(field) {
        let error = null;
        const { validations } = field;

        if (typeof validations === 'function') {
          // We have a functional validation to call
          error = validations(this.state);
        } else {
          if (Array.isArray(validations) && validations.indexOf('phone')) {
            Validator.register(
              'phone',
              (val) => {
                const phone = val ? parsePhoneNumberFromString(val, 'US') : undefined;

                return !(!phone || !phone.isValid());
              },
              'Must be a valid phone number'
            );
          }

          const input = {
            ...this.state,
          };
          const rules = !Array.isArray(field.validations)
            ? field.validations
            : {
                [field.name]: validations,
              };

          const fieldErrorMessage = field.errorMessage || {};
          const validator = new Validator(input, rules, fieldErrorMessage);
          if (validator.fails()) {
            error = validator.errors.get([field.name]);

            if (!error.length) {
              const errors = validator.errors.all();
              if (errors) {
                const key = Object.keys(errors)[0];
                const errorFieldName = key.split('.')[0];
                error = [errors[key][0]];
                error[0] = error[0].replace(key, startCase(errorFieldName));
              }
            }
            // show better error message
            error = error.map((e) => {
              // camelCase to words with space
              const errorMessage = e.replace(/([a-z\xE0-\xFF])([A-Z\xC0\xDF])/g, '$1 $2');
              // uppercase first char of sentence
              return errorMessage.toLowerCase().replace(/(^\w)|\.\s+(\w)/gm, (match) => match.toUpperCase());
            });
          }
        }
        return error;
      }

      render() {
        const { form } = this.props;
        const { formId } = this.state;
        // Generate our fields output from the form object
        const fieldsOutput = this.generateFieldOutput(form);
        // const fieldsOutput = "This is fields output here.";
        const requiredFields = fieldsOutput.filter((field) => field.props.validations && Array.isArray(field.props.validations) && field.props.validations.includes('required'));
        const showRequiredMsg = !!requiredFields.length;
        return (
          <>
            {showRequiredMsg ? (
              <p className="flex mb-6 text-xs">
                Required fields are marked with an <span className="ml-1 text-red-700">*</span>
              </p>
            ) : null}
            <Form onSubmit={this.onSubmit} formId={formId} className={`dynamic-form ${this.props.customClassName ? this.props.customClassName : ''}`}>
              {fieldsOutput}
            </Form>
          </>
        );
      }
    }
  )
);

export default DynamicForm;
