import Bugsnag from '@bugsnag/js';
import GaUtil from './gaUtil';
import { trackSnowplowEvent } from './snowplowUtil';

const ERROR_STATUS_EVENT = {
  404: '404_Error',
};

/**
 * Util for all things Errors
 */
export default class ErrorUtil {
  static parseBraintreeErrorMessage = (error) => {
    let message = '';
    const errorCode = error || 0;
    switch (errorCode.toString()) {
      case '2005':
        message = 'Bad credit card number.';
        break;
      case '2015':
        message = 'Transaction not allowed.';
        break;
      case '2046':
        message = 'Declined.';
        break;
      case '81801':
        message = 'Addresses must have at least one field filled in.';
        break;
      case '81805':
        message = 'First name is too long,';
        break;
      case '81806':
        message = 'Last name is too long.';
        break;
      case '81813':
        message = 'Postal code can only contain letters, numbers, spaces, and hyphens.';
        break;
      case '81808':
        message = 'Postal code is required.';
        break;
      case '81809':
        message = 'Postal code may contain no more than 9 letter or number characters.';
        break;
      case '81828':
        message = 'Postal code is required for the card type and processor.';
        break;
      case '1000':
        message = 'Invalid billing address.';
        break;
      default:
        message = 'Something went wrong. Your payment has not been processed.';
    }
    return message;
  };
}

/**
 * Send `GA4`, `Snowplow` and `Bugsnag` error tracking events
 * @param {Error} error
 * @param {CybraryUser} user
 * @param {string} href
 */
export const sendErrorTrackingEvents = (error, user, href) => {
  const errorStatus = error.response?.status || 404;

  GaUtil.pushEventToDataLayer({
    event: ERROR_STATUS_EVENT[errorStatus],
    userId: user?.id,
  });

  trackSnowplowEvent({ category: ERROR_STATUS_EVENT[errorStatus], action: 'shown', property: href || error.message });

  Bugsnag.notify(new Error(`${ERROR_STATUS_EVENT[errorStatus]}: ${href || error.message}`));
};
