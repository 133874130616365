import React from 'react';
import ContinueLearningProvider from '../../providers/ContinueLearningProvider';
import ContinueLearningTabs from './ContinueLearningTabs';

/**
 * Container component of the Continue Learning block for the dashboard.
 * Used to display the current course, in progress courses, and bookmarked courses in a tabbed interface
 * Wraps the ContinueLearningTabs component in a context provider specific to this component
 * @param {string} className tailwind class names to add to the component
 * @returns <ContinueLearningWidget />
 */
function ContinueLearningWidget({ className = '' }) {
  return (
    <div className={className}>
      <ContinueLearningProvider>
        <ContinueLearningTabs />
      </ContinueLearningProvider>
    </div>
  );
}

export default ContinueLearningWidget;
