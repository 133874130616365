/* eslint-disable no-unused-vars */
import React, { memo } from 'react';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import AddLink from '../../AddLink/AddLink';
import { useCybAssessmentAnalytics } from '../../../providers/CybAssessmentAnalyticsProvider';
import If from '../../If/If';
import ContinueLearningFeatures from '../../ContinueLearning/ContinueLearningFeatures';
import Icon from '../../Icon/Icon';
import { BASE_BUTTON_STYLES } from '../../../constants';
import useQueryParams from '../../../hooks/useQueryParams';

const AssessmentSummaryRecommendedLearning = inject(
  'goalsStore',
  'userStore'
)(
  observer(({ goalsStore, userStore }) => {
    const navigate = useNavigate();
    const { learnerId } = useQueryParams();
    const { currentAssessment } = useCybAssessmentAnalytics();
    const { recommendations } = currentAssessment || {};

    const initRecommendationsAsGoal = () => {
      const contentIds = recommendations?.map(({ id }) => id)?.join(',');
      // If we have a learnerID query param this means we are an admin viewing a learner's assessment
      if (learnerId) {
        const recommendedGoalParams = {
          outcome_type: 'recommended_learning',
          participant_ids: learnerId,
          content_ids: contentIds,
          content_type: 'activity',
          description: `Recommended Learning from ${currentAssessment?.['content-topic'] || 'Assessment'}`,
          name: `${currentAssessment?.['content-topic'] || 'Assessment'} Recommended Learning`,
          action: 'add',
          owner_ids: userStore?.user?.id,
        };
        navigate(`/enterprise/${userStore?.team?.id}/goals?${new URLSearchParams(recommendedGoalParams).toString()}`);
      } else {
        // Otherwise, we are a learner viewing our own assessment
        const recommendedGoalParams = {
          outcome_type: 'recommended_learning',
          participant_ids: userStore?.user?.id,
          content_ids: contentIds,
          content_type: 'activity',
          description: `Recommended Learning from ${currentAssessment?.['content-topic'] || 'Assessment'}`,
          name: `${currentAssessment?.['content-topic'] || 'Assessment'} Recommended Learning`,
          action: 'add',
        };
        navigate(`/?${new URLSearchParams(recommendedGoalParams).toString()}`);
      }
    };

    return (
      <div className="flex flex-col grow p-0 w-full max-h-[341px] rounded-sm border border-neutral-600 md:w-1/2">
        <div className="flex justify-between">
          <h2 className="pt-3 pl-3 mb-4 text-lg font-bold">Recommended Learning</h2>
          <If condition={!!recommendations?.length}>
            <div className="shrink-0 py-2 pr-2">
              <button onClick={() => initRecommendationsAsGoal()} className={BASE_BUTTON_STYLES} title="Assign Recommendations as a Goal">
                {learnerId ? 'Assign As Goal' : 'Create As Goal'}
              </button>
            </div>
          </If>
        </div>
        <div className="flex flex-col grow">
          <p className="overflow-hidden px-6 mb-4 max-h-[3rem]">Want to improve your score? Keep honing your skills with the recommended resources!</p>

          <If condition={!!recommendations?.length}>
            <div className="flex grow-0 mx-auto mb-1 w-5/6 h-[1px] bg-neutral-500" />
            <div className="flex overflow-x-hidden overflow-y-auto flex-col w-full max-h-[212px]">
              {recommendations?.map(({ id, title, 'perma-link': link, 'short-description': shortDescription, 'duration-hours': hours, type }) => (
                <div className="flex items-center" key={id}>
                  <AddLink key={id} className="group mr-4 w-full text-sm font-semibold text-cyb-pink-500" to={`/browse/${link}`} title={shortDescription}>
                    <div className="flex justify-between items-center pl-2 mx-2 hover:!bg-neutral-200 rounded">
                      <span className="flex overflow-hidden items-center group-hover:underline whitespace-nowrap">
                        <Icon name="book" className="mr-3 w-4 h-4 text-black" />
                        {title}
                      </span>
                      <span className="flex">
                        <ContinueLearningFeatures className="flex-nowrap" type={type['nice-name']} learningSecondsTotal={hours * 60 * 60} difficulty={null} />
                      </span>
                    </div>
                  </AddLink>
                </div>
              ))}
            </div>
          </If>
          <If condition={!recommendations?.length}>
            <div className="flex flex-col items-center mt-4 space-y-8">
              <Icon name="search" className="p-2 w-16 h-16 text-white bg-[#7644B7] rounded-full" />
              <p className="w-4/5 text-center">Either you aced the assessment, or we don&apos;t have any recommended content available at this time.</p>
            </div>
          </If>
        </div>
      </div>
    );
  })
);

export default memo(AssessmentSummaryRecommendedLearning);
