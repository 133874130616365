import React from 'react';
import WidgetContainer from '../../Container/WidgetContainer';
import CountUpCard from './CountUpCard';

/**
 * CountUpWidget is a wrapper for the CountUpCard component
 * It forwards all props to the CountUpCard component
 * @param {*} props - props for the CountUpCard component
 * @returns {ReactElement} - the CountUpWidget component
 */
function CountUpWidget(props) {
  return (
    <WidgetContainer>
      <CountUpCard {...props} />
    </WidgetContainer>
  );
}

export default CountUpWidget;
