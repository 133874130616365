import React from 'react';
import ExampleSection from './ExampleSection';
import Header from '../../../components/Header/Header';
import Code from '../../../components/Code/Code';
import BasicTabs from '../../../components/Tabs/BasicTabs';

function BasicTabsExampleSection() {
  const simpleTabs = [
    { id: 1, title: 'Tab 1', content: <div>Content for Tab 1</div> },
    { id: 2, title: 'Tab 2', content: <div>Content for Tab 2</div> },
    { id: 3, title: 'Tab 3', content: <div>Content for Tab 3</div> },
  ];

  const tabsWithHeader = [
    { id: 1, title: 'Tab 1', content: <div>Content for Tab 1</div>, headerTitle: 'Header 1' },
    { id: 2, title: 'Tab 2', content: <div>Content for Tab 2</div>, headerTitle: 'Header 2' },
    { id: 3, title: 'Tab 3', content: <div>Content for Tab 3</div>, headerTitle: 'Header 3' },
  ];

  const customStyleTabs = [
    { id: 1, title: 'Tab 1', content: <div>Content for Tab 1</div> },
    { id: 2, title: 'Tab 2', content: <div>Content for Tab 2</div> },
    { id: 3, title: 'Tab 3', content: <div>Content for Tab 3</div> },
  ];

  const allFeaturesTabs = [
    { id: 1, title: 'Tab 1', content: <div>Content for Tab 1</div>, headerTitle: 'Header 1', headerTitleLink: '/tab-1' },
    { id: 2, title: 'Tab 2', content: <div>Content for Tab 2</div>, headerTitle: 'Header 2', headerTitleLink: '/tab-2' },
    { id: 3, title: 'Tab 3', content: <div>Content for Tab 3</div>, headerTitle: 'Header 3', headerTitleLink: '/tab-3' },
  ];

  return (
    <ExampleSection title="Tabs" description="A component for displaying content in tabs.">
      {/** Default */}
      <Header as="h2">Default</Header>
      <BasicTabs tabs={simpleTabs} />
      <Code>
        {`
const tabs = [
  { id: 1, title: 'Tab 1', content: <div>Content for Tab 1</div> },
  { id: 2, title: 'Tab 2', content: <div>Content for Tab 2</div> },
  { id: 3, title: 'Tab 3', content: <div>Content for Tab 3</div> },
];

<BasicTabs tabs={tabs} />
        `}
      </Code>

      {/** With Header Title */}
      <Header as="h2">With Header Title</Header>
      <BasicTabs tabs={tabsWithHeader} headerTitle="Default Header" />
      <Code>
        {`
const tabs = [
  { id: 1, title: 'Tab 1', content: <div>Content for Tab 1</div>, headerTitle: 'Header 1' },
  { id: 2, title: 'Tab 2', content: <div>Content for Tab 2</div>, headerTitle: 'Header 2' },
  { id: 3, title: 'Tab 3', content: <div>Content for Tab 3</div>, headerTitle: 'Header 3' },
];

<BasicTabs tabs={tabs} headerTitle="Default Header" />
        `}
      </Code>

      {/** Custom Styles */}
      <Header as="h2">Custom Styles</Header>
      <BasicTabs tabs={customStyleTabs} buttonClassName="p-0" navClassName="p-0" navContainerClassName="p-8" headerTitleClassName="text-xl text-green-500" omitContainerBorder />
      <Code>
        {`
<BasicTabs
  tabs={tabs}
  buttonClassName="p-0"
  navClassName="p-0"
  headerTitleClassName="text-xl text-green-500"
/>
        `}
      </Code>

      {/** All Features */}
      <Header as="h2">All Features</Header>
      <BasicTabs
        tabs={allFeaturesTabs}
        headerTitle="All Features Example"
        headerTitleLink="/all-features"
        headerTitleClassName="text-xl text-purple-500"
        omitContainerBorder
        buttonClassName="text-blue-500 hover:text-blue-700"
        navClassName="bg-gray-100 p-2 rounded"
        navContainerClassName="flex-col lg:flex-row"
        initTabIndex={1}
        onChange={() => {}}
      />
      <Code>
        {`
const tabs = [
  { id: 1, title: 'Tab 1', content: <div>Content for Tab 1</div>, headerTitle: 'Header 1', headerTitleLink: '/tab-1' },
  { id: 2, title: 'Tab 2', content: <div>Content for Tab 2</div>, headerTitle: 'Header 2', headerTitleLink: '/tab-2' },
  { id: 3, title: 'Tab 3', content: <div>Content for Tab 3</div>, headerTitle: 'Header 3', headerTitleLink: '/tab-3' },
];

<BasicTabs
  tabs={tabs}
  headerTitle="All Features Example"
  headerTitleLink="/all-features"
  headerTitleClassName="text-xl text-purple-500"
  omitContainerBorder={true}
  buttonClassName="text-blue-500 hover:text-blue-700"
  navClassName="bg-gray-100 p-2 rounded"
  navContainerClassName="flex-col lg:flex-row"
  initTabIndex={1}
  onChange={() => {}}
/>
        `}
      </Code>
    </ExampleSection>
  );
}

export default BasicTabsExampleSection;
