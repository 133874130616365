import React, { useEffect } from 'react';
import withRouter from '../../components/Router/withRouter';
import Container from '../../components/Container/Container';
import Loading from '../../components/Loading/Loading';
import StyledError from '../../components/Error/StyledError';
import { useCybAssessmentPathContent } from '../../providers/CybAssessmentPathContentProvider';
import AssessmentPathTabs from '../../components/AssessmentPath/AssessmentPathTabs';
import AssessmentPathHeader from '../../components/AssessmentPath/AssessmentPathHeader';

function AssessmentResults({ match: { params } }) {
  const { contentId, orgId, pathId } = params;

  const { currentAssessment, isLoadingAssessment, assessmentError, actions } = useCybAssessmentPathContent();

  useEffect(() => {
    actions.getAssessment(orgId, contentId);
  }, [orgId, pathId]);

  if (isLoadingAssessment) {
    return (
      <Container>
        <Loading message="Loading..." />
      </Container>
    );
  }

  if (assessmentError) {
    return (
      <Container>
        <StyledError error={assessmentError} />
      </Container>
    );
  }

  if (!currentAssessment) {
    return null;
  }

  return (
    <Container className="pt-0 sm-576:px-8" lg omitPadding>
      <AssessmentPathHeader orgId={orgId} asAssessment />
      <AssessmentPathTabs orgId={orgId} asAssessment />
    </Container>
  );
}

export default withRouter(AssessmentResults);
