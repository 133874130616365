import React from 'react';
import { inject, observer } from 'mobx-react';
import DynamicForm from '../../components/DynamicForm/DynamicForm';
import Modal from '../../components/Modal/Modal';
import Divider from '../../components/Divider/Divider';
import { APP_FORM_IDS } from '../../constants';

const getDeleteForm = () => ({
  order: ['delete', ['cancel', 'submit']],
  fields: {
    delete: {
      type: 'text',
      label: "If you would like to delete your account, type 'DELETE' in all caps below",
      placeholder: "Type 'DELETE' in all caps",
      validations: ['required', (val) => (val.delete === 'DELETE' ? null : "Please type 'DELETE' in all caps)")],
    },
    cancel: {
      type: 'button',
      color: 'gray',
      label: 'Cancel',
      className: 'mr-4',
    },
    submit: {
      type: 'button',
      color: 'pink',
      label: 'Delete',
    },
  },
});

const DeleteAccount = inject('authStore')(
  observer(
    class DeleteAccount extends React.Component {
      componentDidMount() {
        this.props.authStore.fireAttributionEvent();
      }

      render() {
        const { modalOpen, toggleConfirmationModal, handleDeleteAccount } = this.props;
        const form = getDeleteForm();
        form.fields.cancel.onClick = toggleConfirmationModal;
        return (
          <div>
            <p>If you wish to delete your account permanently we can do so for you.</p>
            <p>
              Deleting your account <strong>removes all your personal information</strong> from our database. You will <strong>not be able to reactivate</strong> your account and
              all your progress will be <strong>permanently lost</strong>.
            </p>
            <p>If your Cybrary profile is public, then it may have been indexed by search engines; Cybrary cannot control these third-party services.</p>
            <p>
              <strong>Clearing all your personal information may take up to 30 days.</strong>
            </p>
            <button onClick={() => toggleConfirmationModal()} className="py-2.5 px-6 mt-4 ml-8 text-sm font-bold leading-5 text-center text-black bg-gray-200 rounded-sm">
              Delete Account
            </button>

            <Modal open={modalOpen} toggle={toggleConfirmationModal} size="md" position="center" ariaDescribedBy="delete-account-modal" omitCloseX>
              <h2 id="delete-account-modal" className="m-6 text-xl font-bold text-black">
                Delete Your Account
              </h2>
              <Divider marginBottom="mb-6" marginTop="mt-0" />
              <div className="mx-6">
                <DynamicForm formId={APP_FORM_IDS.AUTH.DELETE_ACCOUNT} form={form} formName="delete-account" onSubmit={handleDeleteAccount} />
              </div>
            </Modal>
          </div>
        );
      }
    }
  )
);

export default DeleteAccount;
