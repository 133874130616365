import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Userpilot } from 'userpilot';
import { twMerge } from 'tailwind-merge';
import Icon, { ICON_NAMES } from '../Icon/Icon';
import AddLink from '../AddLink/AddLink';
import { trackSnowplowEvent } from '../../utils/snowplowUtil';
import { USERPILOT_EVENTS } from '../../constants';
import GradientBanner from './GradientBanner';
import If from '../If/If';
import Button from '../Button/Button';
import { usePromo } from '../../providers/PromoProvider';

/**
 * Gradient banner that is used to promote the upgrade to CIP as a sticky banner below the primary navbar.
 * @param {string} className - Additional classes to apply to the banner.
 * @param {string} iconClassName - Additional classes to apply to the icon.
 * @param {string} ctaButtonClassName - Additional classes to apply to the CTA button.
 * @param {string} ctaButtonText - Text to display on the CTA button.
 * @param {string} targetUrl - URL to navigate to when the CTA button is clicked.
 * @param {function} onClick - Callback function to execute when the CTA button is clicked.
 * @param {ReactNode} children - Content to display inside the banner.
 * @param {boolean} isDismissible - Whether the banner can be dismissed.
 * @param {boolean} isDismissed - Whether the banner should be dismissed. Overrides local state if true.
 * @param {string} dismissedKey - Unique key to identify the promo in the cookie when dismissed.
 * @param {number} delayOnDismiss - Time in minutes before the promo can be shown again after being dismissed.
 * @param {string} iconName - Name of the icon to display on the banner.
 * @param {string} spLabel - Label for the Snowplow event.
 * @param {string} spCategory - Category for the Snowplow event.
 * @returns {JSX.Element} - The UpgradePromoBanner component.
 */
function UpgradePromoBanner({
  className,
  iconClassName,
  ctaButtonClassName,
  ctaButtonText = 'Upgrade',
  targetUrl,
  onClick,
  children,
  isDismissible = false,
  isDismissed = false,
  dismissedKey,
  delayOnDismiss = 60, // In minutes
  iconName = ICON_NAMES.unlock,
  spLabel = 'upgradeButton',
  spCategory = 'CIPUpgradeBanner',
}) {
  const navigate = useNavigate();
  const { isHeaderBannerDismissed, setIsHeaderBannerDismissed, dismissPromo } = usePromo();

  const handleUpgradeClick = () => {
    trackSnowplowEvent({ category: spCategory, action: 'click', label: spLabel, property: targetUrl });
    if (onClick) {
      onClick();
    } else if (targetUrl) {
      // Open external link in new tab
      if (targetUrl.startsWith('http')) {
        window.open(targetUrl, '_blank');
      } else {
        navigate(targetUrl);
      }
    }
    Userpilot.track(USERPILOT_EVENTS.CIP_BANNER_CLICKED_UPGRADE);
  };

  const handleDismiss = (e) => {
    e.preventDefault();
    e.stopPropagation();
    trackSnowplowEvent({ category: spCategory, action: 'dismiss', label: spLabel });
    setIsHeaderBannerDismissed(true);
    // If we have a dismissedKey, set the cookie to expire at a specific time
    if (dismissedKey) {
      dismissPromo(dismissedKey, delayOnDismiss);
    }
  };

  const gradientBannerClasses = useMemo(() => {
    return twMerge('relative overflow-hidden group px-0 md:px-6', className);
  }, [className]);

  const iconClasses = useMemo(() => {
    return twMerge('hidden left-4 z-10 w-16 h-16 transition-all duration-300 group-hover:scale-105 group-hover:-rotate-12 fill-white lg:block', iconClassName);
  }, [iconClassName]);

  const ctaButtonClasses = useMemo(() => {
    return twMerge('flex z-20 items-center p-1 px-28 mx-auto mt-2 text-black bg-slate-100 group-hover:bg-white rounded-sm lg:p-2 lg:px-6 lg:mt-0', ctaButtonClassName);
  }, [ctaButtonClassName]);

  // Allow local state and props to control dismissal
  if (isHeaderBannerDismissed || isDismissed) {
    return null;
  }

  return (
    <div className="inline-block w-full">
      {isDismissible && (
        <Button onClick={handleDismiss} color="transparent-gray" className="absolute right-1 z-20 py-0.5 px-2 mt-1 text-xs !text-white rounded-full">
          x
        </Button>
      )}
      <GradientBanner className={gradientBannerClasses}>
        <div className="flex flex-col justify-between items-center w-full align-middle lg:flex-row">
          <If condition={iconName}>
            <Icon name={iconName} className={iconClasses} />
          </If>
          <div className="z-20 grow px-0 text-sm md:px-6 md:text-lg">
            <div className="flex flex-col">{children}</div>
          </div>
          <AddLink onClick={handleUpgradeClick} className={ctaButtonClasses}>
            {ctaButtonText}
          </AddLink>
        </div>
      </GradientBanner>
    </div>
  );
}

export default UpgradePromoBanner;
