import React, { useState, useEffect } from 'react';
import fieldClasses from './fieldClasses';

function TextArea(props) {
  const { id, name, placeholder, size, defaultValue, error, required, ariaDescribedBy, onChange, disabled, maxLength } = props;

  const [input, setInput] = useState(defaultValue || '');

  useEffect(() => {
    if (defaultValue) {
      setInput(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    onChange(undefined, { name, value: input });
  }, [onChange, name, input]);

  return (
    <textarea
      id={id || name}
      rows={size ? size.rows : 5}
      cols={size ? size.cols : 5}
      className={fieldClasses(error, disabled)}
      type="text"
      onChange={(e) => setInput(e.target.value)}
      maxLength={maxLength || null}
      disabled={disabled}
      value={input}
      aria-describedby={ariaDescribedBy}
      aria-required={required}
      placeholder={placeholder}
    />
  );
}

export default TextArea;
