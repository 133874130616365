import React from 'react';
import { twMerge } from 'tailwind-merge';
import Icon from '../Icon/Icon';

function StepIcon({ activeStep, index, icon, onClick, color, error, disabled = false }) {
  let cursor = onClick && !disabled ? 'cursor-pointer hover:bg-gray-300' : '';
  cursor = disabled ? 'cursor-not-allowed hover:bg-gray-300' : '';
  const formattedClassName = `${color} ${cursor}`;
  const stepNumber = index + 1;
  let display = icon ? <Icon name={icon} /> : stepNumber;
  if (activeStep === index && error) {
    display = <Icon name="x" />;
  }
  let textColor = 'text-black';
  if (activeStep === index || index < activeStep) {
    textColor = 'text-white';
  }
  return (
    <button onClick={onClick || null} disabled={disabled} className={twMerge(`w-10 h-10 rounded-full text-lg font-black flex items-center`, textColor, formattedClassName, cursor)}>
      <div className={`flex items-center justify-center text-center w-full ${textColor}`}>{display}</div>
    </button>
  );
}

function Step({ step, activeStep, activeColor, completeColor, index, error, length }) {
  let color = 'bg-gray-200';
  if (activeStep === index) {
    color = activeColor;
  } else if (activeStep !== index && index < activeStep) {
    color = completeColor;
  }
  let margin = 'mx-auto';
  if (index === 0) {
    margin = 'mr-auto';
  }
  if (index === length) {
    margin = 'ml-auto';
  }
  return (
    <div className={`w-auto flex flex-col justify-center ${margin}`}>
      <div className="relative z-10 px-4 mx-auto mb-2 bg-white">
        <StepIcon step={step} icon={step.icon} onClick={step.onClick} activeStep={activeStep} index={index} error={error} color={color} disabled={step.disabled} />
      </div>
      <div className="text-xs text-center md:text-base">{step.text}</div>
    </div>
  );
}

/**
 * Progress Stepper used in onboarding
 * Zero based index and progress
 * @param {*} param0
 */
function ProgressStepper({ steps, activeStep, error, className }) {
  if (!steps || !steps.length) {
    return null;
  }
  const activeColor = error ? 'bg-red-500' : 'bg-cyb-pink-500 hover:bg-cyb-pink-600';
  const completeColor = 'bg-cyb-green-500';
  return (
    <div className={`relative w-full ${className || ''}`}>
      <div className="flex justify-evenly">
        {steps.map((step, index) => (
          <Step step={step} activeStep={activeStep} activeColor={activeColor} completeColor={completeColor} error={error} index={index} key={step.text} length={steps.length - 1} />
        ))}
      </div>
      {/** Progress bar */}
      <div className="absolute border-b border-gray-300" style={{ width: 'calc(100% - 2.5rem - 1rem)', top: '25%' }} />
    </div>
  );
}

export default ProgressStepper;
