import authStore from './authStore';
import commonStore from './commonStore';
import userStore from './userStore';
import searchStore from './searchStore';
import catalogStore from './catalogStore';
import notificationsStore from './notificationsStore';
import dashboardStore from './dashboardStore';
import reportsStore from './reportsStore';
import enrollmentStore from './enrollmentStore';
import certificateStore from './certificateStore';
import enterpriseStore from './enterpriseStore';
import goalsStore from './goalsStore';
import adminStore from './adminStore';
import profileStore from './profileStore';
import feedbackStore from './feedbackStore';
import awardsStore from './awardsStore';
import userNotificationStore from './userNotificationStore';
import userSettingsStore from './userSettingsStore';
import teamHierarchyStore from './enterprise/teamHierarchyStore';

const stores = {
  authStore,
  commonStore,
  userStore,
  searchStore,
  catalogStore,
  notificationsStore,
  dashboardStore,
  reportsStore,
  enrollmentStore,
  certificateStore,
  enterpriseStore,
  goalsStore,
  adminStore,
  profileStore,
  feedbackStore,
  awardsStore,
  userNotificationStore,
  userSettingsStore,
  teamHierarchyStore,
};

export default stores;
