import React from 'react';
import ExampleSection from './ExampleSection';
import Header from '../../../components/Header/Header';
import Button from '../../../components/Button/Button';
import Code from '../../../components/Code/Code';

function ButtonsExampleSection() {
  return (
    <ExampleSection title="Buttons">
      <Header as="h2">Colors</Header>
      <div className="flex gap-2 mb-2">
        <Button>Pink (Default)</Button>
        <Button color="gray">Gray</Button>
        <Button color="green">Green</Button>
        <Button color="red">Red</Button>
      </div>
      <Code>
        {`
        <div className="flex gap-2 mb-2">
          <Button>Pink (Default)</Button>
          <Button color="gray">Gray</Button>
          <Button color="green">Green</Button>
          <Button color="red">Red</Button>
        </div>
      `}
      </Code>
      <Header as="h2">Disabled</Header>
      <div className="flex gap-2 mb-2">
        <Button disabled>Pink (Default)</Button>
        <Button color="gray" disabled>
          Gray
        </Button>
        <Button color="green" disabled>
          Green
        </Button>
        <Button color="red" disabled>
          Red
        </Button>
      </div>
      <Code>
        {`
        <div className="flex gap-2 mb-2">
          <Button disabled>Pink (Default)</Button>
          <Button color="gray" disabled>
            Gray
          </Button>
          <Button color="green" disabled>
            Green
          </Button>
          <Button color="red" disabled>
            Red
          </Button>
        </div>
      `}
      </Code>
      <Header as="h2">Loading</Header>
      <div className="flex gap-2 mb-2">
        <Button loading>Pink (Default)</Button>
        <Button color="gray" loading>
          Gray
        </Button>
        <Button color="green" loading>
          Green
        </Button>
        <Button color="red" loading>
          Red
        </Button>
      </div>
      <Code>
        {`
        <div className="flex gap-2 mb-2">
          <Button loading>Pink (Default)</Button>
          <Button color="gray" loading>
            Gray
          </Button>
          <Button color="green" loading>
            Green
          </Button>
          <Button color="red" loading>
            Red
          </Button>
        </div>
      `}
      </Code>
      <Header as="h2">Icons</Header>
      <div className="flex gap-2 mb-2">
        <Button icon={{ name: 'camera', position: 'left' }}>Pink (Default)</Button>
        <Button color="gray" icon={{ name: 'edit', position: 'left' }}>
          Gray
        </Button>
        <Button color="green" icon={{ name: 'lightning-bolt', position: 'right' }}>
          Green
        </Button>
        <Button color="red" icon={{ name: 'fire', position: 'right' }}>
          Red
        </Button>
      </div>
      <Code>
        {`
        <div className="flex gap-2 mb-2">
          <Button icon={{ name: 'camera', position: 'left' }}>Pink (Default)</Button>
          <Button color="gray" icon={{ name: 'edit', position: 'left' }}>
            Gray
          </Button>
          <Button color="green" icon={{ name: 'lightning-bolt', position: 'right' }}>
            Green
          </Button>
          <Button color="red" icon={{ name: 'fire', position: 'right' }}>
            Red
          </Button>
        </div>
      `}
      </Code>
      <div className="flex gap-2 mb-2">
        <Button icon={{ name: 'camera' }} />
        <Button color="gray" icon={{ name: 'edit' }} />
        <Button color="green" icon={{ name: 'lightning-bolt' }} />
        <Button color="red" icon={{ name: 'fire' }} />
      </div>
      <Code>
        {`
        <div className="flex gap-2 mb-2">
          <Button icon={{ name: 'camera' }} />
          <Button color="gray" icon={{ name: 'edit' }} />
          <Button color="green" icon={{ name: 'lightning-bolt' }} />
          <Button color="red" icon={{ name: 'fire' }} />
        </div>
      `}
      </Code>
    </ExampleSection>
  );
}

export default ButtonsExampleSection;
