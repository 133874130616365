import React from 'react';
import ContentBadge from '../BrowsePromo/ContentBadge';
import If from '../If/If';

function OverviewSection({ title, list }) {
  if (!list?.length) {
    return null;
  }

  return (
    <div className="mr-4 mb-4 min-w-[25%]">
      <h3 className="text-2xs font-semibold text-gray-600 uppercase">{title}</h3>
      <div className="py-2">
        <ul className="space-y-1 text-sm list-none">
          {list.map((data) => (
            <li key={data}>{data}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default function Overview({ path }) {
  const { long_description, short_description } = path;
  const isThreatActorCampaigns = path?.content_type?.name === 'threat_actor_path';

  const actors = path?.meta?.actors;
  const mitreGroups = path?.meta?.mitreGroups;
  return (
    <div id="Overview" className="mb-5 lg:flex lg:space-x-0">
      <div className="pr-8 mb-8 lg:mb-0 lg:w-1/2">
        <h2 className="mb-4 text-2xl font-black">Overview</h2>
        <p className="w-full text-sm leading-6">{long_description || short_description}</p>
      </div>
      <div className="justify-between md:flex lg:w-1/2">
        <If condition={isThreatActorCampaigns}>
          <OverviewSection key="actors" title="Actors" list={actors} />
          <OverviewSection key="mitreGroups" title="Associated Groups" list={mitreGroups} />
        </If>
        <ContentBadge badgeId={path?.badge_id} className="mt-8 ml-auto w-full max-w-[300px] md:mt-0" />
      </div>
    </div>
  );
}
