import { Switch } from '@headlessui/react';
import React from 'react';

/**
 * Badges toggle switch for the badges tab to toggle between
 * showing all badges or just the earned badges
 * @param {boolean} showAllBadges - whether or not to show all badges
 * @param {function} setShowAllBadges - function to set whether or not to show all badges
 * @returns <BadgesToggle /> the toggle switch for the badges tab
 */
function BadgesToggle({ showAllBadges, setShowAllBadges }) {
  return (
    <div className="flex justify-end items-center mb-4 w-full">
      <span className="pr-2 text-sm">Show Earned Badges</span>
      <Switch
        checked={showAllBadges}
        onChange={() => setShowAllBadges(!showAllBadges)}
        className="inline-flex relative items-center w-11 h-4 bg-white rounded-full border-1 border-gray-600"
      >
        <span className="sr-only">Use setting</span>
        <span
          aria-hidden="true"
          className={`bg-cyb-pink-500 ${showAllBadges ? 'translate-x-7' : 'translate-x-[.1rem]'}
            pointer-events-none inline-block h-3 w-3 transform rounded-full shadow-lg ring-0 transition duration-200 ease-in-out`}
        />
      </Switch>
      <span className="pl-2 text-sm">Show All Badges</span>
    </div>
  );
}

export default BadgesToggle;
