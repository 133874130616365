import React from 'react';
import ExampleSection from './ExampleSection';
import Header from '../../../components/Header/Header';
import Card from '../../../components/Card/Card';
import Code from '../../../components/Code/Code';
import WidgetContainer from '../../../components/Container/WidgetContainer';
import Image from '../../../components/Image/Image';
import Button from '../../../components/Button/Button';

function CardExampleSection() {
  return (
    <ExampleSection title="Card" description="A Card is simply a container with a thin light gray border and padding.">
      {/** Default */}
      <Header as="h2">Default</Header>
      <Card>First Card</Card>
      <Card className="italic" id="last-card-example">
        Last Card
      </Card>
      <Code>
        {`
      <Card>First Card</Card>
      <Card className="italic" id="last-card-example">
        Last Card
      </Card>
        `}
      </Code>
      {/** Stacking Cards */}
      <Header as="h2">Stacking Cards</Header>
      <p>When stacking cards use the stackDisplay prop to handle borders on the bottom of the card and Y margins.</p>
      <p>Cards need to be grouped (in a div/container) so the last: directive is properly applied.</p>
      <div>
        <Card stackDisplay>First Card</Card>
        <Card stackDisplay className="italic" id="last-card-example">
          Last Card
        </Card>
      </div>
      <Code>
        {`
    <div>
      <Card stackDisplay>First Card</Card>
      <Card stackDisplay className="italic" id="last-card-example">
        Last Card
      </Card>
    </div>
        `}
      </Code>
      {/** Inline Display */}
      <Header as="h2">Inline Display</Header>
      <p>inlineDisplay cards have their first: and last: mt/mb removed. For use in a row.</p>
      <div className="flex gap-x-2">
        <Card inlineDisplay>Card 1</Card>
        <Card inlineDisplay>Card 2</Card>
      </div>
      <Code>
        {`
    <div className="flex gap-x-2">
      <Card inlineDisplay>Card 1</Card>
      <Card inlineDisplay>Card 2</Card>
    </div>
        `}
      </Code>
      <p>Without inlineDisplay Cards do not behave properly in a row</p>
      <div className="flex gap-x-2">
        <Card>Card 1</Card>
        <Card>Card 2</Card>
        <Card>Card 3</Card>
      </div>
      <Code>
        {`
    <div className="flex gap-x-2">
      <Card>Card 1</Card>
      <Card>Card 2</Card>
      <Card>Card 3</Card>
    </div>
            `}
      </Code>
      {/** Inline Content */}
      <Header as="h2">Inline Content</Header>
      <p>inlineContent cards have their children shown in a flex row.</p>
      <Card inlineContent>
        <Header as="h3" className="mb-0">
          inlineContent Header
        </Header>
        <div>Subtitle</div>
      </Card>
      <Card>
        <Header as="h3" className="mb-0">
          Default Header
        </Header>
        <div>Subtitle</div>
      </Card>
      <Code>
        {`
    <Card inlineContent>
      <Header as="h3" className="mb-0">
        inlineContent Header
      </Header>
      <div>Subtitle</div>
    </Card>
    <Card>
      <Header as="h3" className="mb-0">
        Default Header
      </Header>
      <div>Subtitle</div>
    </Card>
        `}
      </Code>
      {/** Card Sections */}
      <Header as="h2">Card Sections</Header>
      <p>Card Sections are used to separate content within a Card with consistent padding/layout rules</p>
      <p>They are useful for creating columns of content within a card, or grouping components into a container to grow a section.</p>
      <Card inlineContent>
        <Image src="https://via.placeholder.com/80" alt="placeholder" className="m-0 w-16 h-16 rounded-full" />
        <Card.Section>
          <Header as="h3" className="mb-0">
            Header
          </Header>
          <div>Subtitle</div>
        </Card.Section>
        <Button color="gray" icon={{ name: 'chevron-right', className: 'w-4 h-4' }} />
      </Card>
      <Code>
        {`
    <Card inlineContent>
      <Image src="https://via.placeholder.com/80" alt="placeholder" className="m-0 w-16 h-16 rounded-full" />
      <Card.Section>
        <Header as="h3" className="mb-0">
          Header
        </Header>
        <div>Subtitle</div>
      </Card.Section>
      <Button color="gray" icon={{ name: 'chevron-right', className: 'w-4 h-4' }} />
    </Card>
            `}
      </Code>
      <p>You can grow card sections as well to easily fill remaining space</p>
      <Card inlineContent>
        <Image src="https://via.placeholder.com/80" alt="placeholder" className="mb-0 w-16 h-16 rounded-full" />
        <Card.Section grow inlineContent>
          <Header as="h3" className="mb-0">
            Header
          </Header>
          <div>Subtitle</div>
        </Card.Section>
        <Button color="gray" icon={{ name: 'chevron-right', className: 'w-4 h-4' }} />
      </Card>
      <Code>
        {`
<Card inlineContent>
<Image src="https://via.placeholder.com/80" alt="placeholder" className="mb-0 w-16 h-16 rounded-full" />
<Card.Section grow inlineContent>
  <Header as="h3" className="mb-0">
    Header
  </Header>
  <div>Subtitle</div>
</Card.Section>
<Button color="gray" icon={{ name: 'chevron-right', className: 'w-4 h-4' }} />
</Card>
            `}
      </Code>
      {/** Widget Container */}
      <Header as="h2">WidgetContainer with Cards</Header>
      <WidgetContainer className="flex gap-x-2">
        <Card inlineContent inlineDisplay>
          <Header as="h3" className="mb-0">
            Header
          </Header>
          <div>Subtitle</div>
        </Card>
        <Card inlineContent inlineDisplay>
          <Image src="https://via.placeholder.com/80" alt="placeholder" className="mb-0 w-16 h-16 rounded-full" />
          <Card.Section>
            <Header as="h3" className="mb-0">
              Header
            </Header>
            <div>Subtitle</div>
          </Card.Section>
          <Button className="px-2.5" color="gray" icon={{ name: 'chevron-right', className: 'w-4 h-4' }} />
        </Card>
        <Card inlineContent inlineDisplay>
          <Card.Section>
            <Header as="h3" className="mb-0">
              Header
            </Header>
            <div>Subtitle</div>
          </Card.Section>
          <Button className="px-2.5" color="gray" icon={{ name: 'chevron-right', className: 'w-4 h-4' }} />
        </Card>
        <Card inlineContent inlineDisplay>
          <Image src="https://via.placeholder.com/80" alt="placeholder" className="mb-0 w-16 h-16 rounded-full" />
          <Card.Section inlineContent>
            <Header as="h3" className="mb-0">
              Header
            </Header>
            <div>Subtitle</div>
          </Card.Section>
          <Button className="px-2.5" color="gray" icon={{ name: 'chevron-right', className: 'w-4 h-4' }} />
        </Card>
      </WidgetContainer>
      <Code>
        {`
          <WidgetContainer className="flex gap-x-2">
            <Card inlineContent inlineDisplay>
              <Header as="h3" className="mb-0">
                Header
              </Header>
              <div>Subtitle</div>
            </Card>
            <Card inlineContent inlineDisplay>
              <Image src="https://via.placeholder.com/80" alt="placeholder" className="mb-0 w-16 h-16 rounded-full" />
              <Card.Section>
                <Header as="h3" className="mb-0">
                  Header
                </Header>
                <div>Subtitle</div>
              </Card.Section>
              <Button className="px-2.5" color="gray" icon={{ name: 'chevron-right', className: 'w-4 h-4' }} />
            </Card>
            <Card inlineContent inlineDisplay>
              <Card.Section>
                <Header as="h3" className="mb-0">
                  Header
                </Header>
                <div>Subtitle</div>
              </Card.Section>
              <Button className="px-2.5" color="gray" icon={{ name: 'chevron-right', className: 'w-4 h-4' }} />
            </Card>
            <Card inlineContent inlineDisplay>
              <Image src="https://via.placeholder.com/80" alt="placeholder" className="mb-0 w-16 h-16 rounded-full" />
              <Card.Section inlineContent>
                <Header as="h3" className="mb-0">
                  Header
                </Header>
                <div>Subtitle</div>
              </Card.Section>
              <Button className="px-2.5" color="gray" icon={{ name: 'chevron-right', className: 'w-4 h-4' }} />
            </Card>
          </WidgetContainer>
            `}
      </Code>
    </ExampleSection>
  );
}

export default CardExampleSection;
