import Bugsnag from '@bugsnag/js';
import { cacheEvent, flushCache } from '../../../utils/eventCacheUtil';

// Provide some duplicate functionality for video tracking events.

// Consolidate some of the common event saving logic
const saveVideoEvent = (event, params, props, state, updateCallback) => {
  const { videoId, activity } = props;
  const { id: contentDescriptionId } = activity;
  if (videoId && contentDescriptionId) {
    try {
      cacheEvent(`video_${event}`, videoId, contentDescriptionId, params);
    } catch (err) {
      Bugsnag.notify(err);
    }
    // If we haven't yet saved our true duration to state, do it now
    const { duration } = params;
    const { trueDuration } = state;
    if (duration && trueDuration === null) {
      const newState = {
        ...state,
        trueDuration: duration,
      };
      updateCallback(newState);
    }
  }
};

export const onPlay = (event, props, state, updateCallback) => {
  // This is either a START event, or just PLAY
  const { seconds, duration } = event;
  if (seconds === 0) {
    // START EVENT!
    saveVideoEvent('start', { videotime: Math.round(seconds), duration: Math.round(duration) }, props, state, updateCallback);
  } else {
    // PLAY EVENT!
    saveVideoEvent('play', { videotime: Math.round(seconds), duration: Math.round(duration) }, props, state, updateCallback);
  }
  flushCache();
};

export const onEnd = (event, props, state, updateCallback) => {
  const { totalSecondsWatched } = state;
  const { seconds, duration } = event;
  saveVideoEvent('end', { videotime: Math.round(seconds), duration: Math.round(duration), total: totalSecondsWatched }, props, state, updateCallback);
  flushCache();
};

export const onTextTrackChange = (event, props, state, updateCallback) => {
  const { lastSecond } = state;
  const eventParams = { ...event };
  eventParams.videotime = lastSecond;
  saveVideoEvent('caption_change', eventParams, props, state, updateCallback);
  flushCache();
};

export const onPause = (event, props, state, updateCallback) => {
  const { seconds, duration } = event;
  saveVideoEvent('pause', { videotime: Math.round(seconds), duration: Math.round(duration) }, props, state, updateCallback);
  const newState = {
    ...state,
    playing: false,
  };
  updateCallback(newState);
};

// eslint-disable-next-line import/prefer-default-export
export const onTimeUpdate = (event, props, state, updateCallback) => {
  if (event) {
    const { seconds, duration } = event;
    const secondsInt = Math.round(seconds);
    const { lastSecond, furthestSecond, playing } = state;
    if (lastSecond !== secondsInt) {
      const diff = lastSecond - secondsInt;
      const absDiff = Math.abs(diff);
      // We've actually progressed
      const newState = {
        ...state,
        lastSecond: secondsInt,
        trueDuration: Math.round(duration),
      };
      if (absDiff > 1) {
        // This is the best place to fire the seek event, as we know the time we seeked FROM
        saveVideoEvent('seek', { videotime: lastSecond, to: secondsInt, playing, duration: Math.round(duration) }, props, state, updateCallback);
      } else {
        // Increment our watched seconds
        newState.totalSecondsWatched++;
        newState.playing = true;
      }
      if (secondsInt > furthestSecond) {
        newState.furthestSecond = secondsInt;
      }
      updateCallback(newState);
    }
  }
};
