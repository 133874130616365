import React, { useEffect, useState } from 'react';
import Bugsnag from '@bugsnag/js';
import { useParams } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { twMerge } from 'tailwind-merge';
import Modal from '../../components/Modal/Modal';
import Icon from '../../components/Icon/Icon';
import List, { ListItem } from '../../components/List/List';
import Loading from '../../components/Loading/Loading';
import Container from '../../components/Container/Container';
import FormatUtil from '../../utils/formatUtil';
import Pagination from '../../components/Pagination/Pagination';
import './enterprise.css';
import Tooltip from '../../components/Tooltip/Tooltip';
import Header from '../../components/Header/Header';
import useQueryParams from '../../hooks/useQueryParams';
import useBetaCookie from '../../hooks/cookies/useBetaCookie';

const acceptRequest = (requestId, orgId, enterpriseStore, commonStore) => {
  enterpriseStore
    .acceptJoinRequest(orgId, requestId)
    .then(() => {
      enterpriseStore.setJoinRequestsQueryParams('activePg', 1);
      enterpriseStore.getJoinRequests(orgId);
      commonStore.triggerToast('success', {
        content: 'Successfully accepted join request',
      });
    })
    .catch((error) => {
      Bugsnag.notify(error);
      commonStore.triggerToast('error', {
        content: 'Something went wrong. Unable to accept join request',
      });
    });
};

const rejectRequest = (requestId, orgId, enterpriseStore, commonStore, email) => {
  enterpriseStore
    .deleteJoinRequest(orgId, requestId)
    .then(() => {
      enterpriseStore.setJoinRequestsQueryParams('activePg', 1);
      enterpriseStore.getJoinRequests(orgId);
      commonStore.triggerToast('success', {
        content: `Successfully rejected join request for ${email}`,
      });
    })
    .catch((error) => {
      Bugsnag.notify(error);
      commonStore.triggerToast('error', {
        content: 'Something went wrong. Unable to reject join request',
      });
    });
};

const TeamJoinRequestsModal = inject(
  'enterpriseStore',
  'commonStore'
)(
  observer(({ enterpriseStore, commonStore }) => {
    const { joinRequests } = enterpriseStore || {};
    const { loading, data, pageCount } = joinRequests || {};
    const { activePg } = joinRequests?.queryParams || {};
    const [joinRequestsOpen, setJoinRequestsOpen] = useState(false);
    const { orgId } = useParams();
    const queryParams = useQueryParams();
    const { isTeamsLicenseCheckPromoBetaEnabled } = useBetaCookie();

    const changePage = (pg) => {
      enterpriseStore.setJoinRequestsQueryParams('activePg', pg);
      enterpriseStore.getJoinRequests(orgId);
    };

    useEffect(() => {
      if (isTeamsLicenseCheckPromoBetaEnabled) {
        changePage(1);
      }
    }, [orgId]);

    // Open modal if query param is set
    useEffect(() => {
      if (queryParams.joinRequestsOpen && data?.totalRecords) {
        setJoinRequestsOpen(true);
      } else if (joinRequestsOpen) {
        setJoinRequestsOpen(false);
      }
    }, [queryParams.joinRequestsOpen, data?.totalRecords]);

    if (!data || !data.totalRecords) {
      return null;
    }
    const listData = FormatUtil.convertTableDataToList(data.columns, data.tableData);
    const labelText = `${data.totalRecords} Pending Join Request${data.totalRecords > 1 ? 's' : ''}`;
    const liClasses = twMerge('flex overflow-hidden justify-between items-center p-2 hover:bg-gray-50 border-t last:border-b border-gray-300', loading && 'opacity-40');
    return (
      <>
        <button onClick={() => setJoinRequestsOpen(true)} className="mb-2 text-xs text-blue-700 rounded-sm border border-blue-700 sm:ml-0.5" style={{ padding: '.4em .8em' }}>
          {labelText}
        </button>
        <Modal open={joinRequestsOpen} toggle={() => setJoinRequestsOpen(false)} paddingBottom="p-4" position="center" size="lg" ariaLabelledBy="join-requests">
          <div className="pt-8 bg-white lg:p-4 invited-members-list">
            <Header as="h2" className="mb-2">
              Join Requests
            </Header>
            <p>These users have requested to join your team by adding and verifying an email address associated with your team.</p>
            {loading && !listData.length ? (
              <Container>
                <Loading message="Loading..." />
              </Container>
            ) : (
              <div className="flex flex-col">
                <List className="overflow-y-auto mt-4 max-h-[60vh] border-gray-300">
                  {listData.map((item) => {
                    const acceptMessage = `Accept join request for ${item.email.slice(0, 47)}${item.email.length > 47 ? '...' : ''}`;
                    const rejectMessage = `Reject join request for ${item.email.slice(0, 47)}${item.email.length > 47 ? '...' : ''}`;
                    return (
                      <ListItem active={!loading} key={item.id} className={liClasses}>
                        <div className="list-content">
                          <div className="flex flex-col">
                            <div>{item.name || 'Member'}</div>
                            <div className="text-sm font-semibold text-cyb-blue-500">{item.email}</div>
                          </div>
                          <p className="invite-date">Requested {FormatUtil.convertUtcToLocal(item.created_at).format('M/D/YYYY')}</p>
                        </div>
                        <div className="float-right pr-4">
                          <Tooltip
                            omitTabIndex
                            triggerContent={
                              <button
                                disabled={loading}
                                aria-label={acceptMessage}
                                onClick={() => acceptRequest(item.id, orgId, enterpriseStore, commonStore)}
                                className="mr-4 text-green-400 hover:text-green-600 disabled:text-gray-400 disabled:animate-pulse"
                              >
                                <Icon name="check" className="w-6 h-6 " />
                              </button>
                            }
                            content={acceptMessage}
                          />
                          <Tooltip
                            omitTabIndex
                            triggerContent={
                              <button
                                disabled={loading}
                                aria-label={rejectMessage}
                                onClick={() => rejectRequest(item.id, orgId, enterpriseStore, commonStore, item.email)}
                                className=" text-red-400 hover:text-red-600 disabled:text-gray-400 disabled:animate-pulse"
                              >
                                <Icon name="trash" className="w-6 h-6" />
                              </button>
                            }
                            content={rejectMessage}
                          />
                        </div>
                      </ListItem>
                    );
                  })}
                </List>
                <div className="grow-0 mx-auto mt-3">
                  <Pagination totalPages={pageCount || 0} onPageChange={changePage} activePage={activePg} />
                </div>
              </div>
            )}
          </div>
        </Modal>
      </>
    );
  })
);

export default TeamJoinRequestsModal;
