export default class ValidationUtil {
  /**
   * Test if provided value is a valid email address
   * @param val string
   * returns bool
   */
  static checkValidEmail = (val) => {
    const emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailReg.test(val);
  };

  /**
   * Test if provided value is a alpha numneric only (no special chars)
   * @param val string
   * returns bool
   */
  static checkIsAlphanumeric = (val) => {
    const alphanumericReg = /^[a-zA-Z0-9]*$/;
    return alphanumericReg.test(val);
  };

  /**
   * Check if provided value is a is a valid integer or float
   * @param val any
   * returns bool
   */
  static isValidNumber = (value) => !Number.isNaN(Number.parseFloat(value)) || Number.isFinite(value);
}
