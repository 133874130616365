/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import useLearnerLab from '../../hooks/useLearnerLab';
import LoadingOrError from '../Loading/LoadingOrError';
import TerminalList from '../Terminal';
import Report from './report';
import GlobalTimer from './global-timer';
import FlagNotification from './flag-notification';
import AuditModal from './audit-modal';
import ExitModal from './exit-modal';
import ConnectionError from './connection-error';
import useNetwork from '../../hooks/useNetwork';
import BuildingLab from './building-lab';
import { useClab } from '../../../../providers/ClabProvider';

function NewLearnerViewContainer({ accountId, labInstanceIdentifier, session, token }) {
  // Load query string params
  const [showAuditLogs, setShowAuditLogs] = useState(false);
  const [simpleView, setSimpleView] = useState(true);
  const [connectionError, setConnectionError] = useState('');
  const [showConnectionErrorModal, setShowConnectionErrorModal] = useState(false);
  const query = queryString.parse(window.location.search);
  const { s } = query || {};

  const { loadLab, lab, loading, error, connect, flagNotification, playbook, resources, expires, labComplete, auditLogs, summary, endLab, loadUserSession, userSession } =
    useLearnerLab();
  const [init, setInit] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [showExitModal, setShowExitModal] = useState(false);
  const [leftEarly, setLeftEarly] = useState(false);
  const { isOnline } = useNetwork();

  const handleFlagClick = () => {};

  const closeSidebar = () => {};

  const hideSidebar = () => {
    closeSidebar();
  };

  const { status } = lab || {};
  const labRunning = status === 'running';

  // If a flag message received and set, show notification
  useEffect(() => {
    if (flagNotification) {
      FlagNotification({ ...flagNotification, onClick: handleFlagClick });
    }
  }, [flagNotification]);

  useEffect(() => {
    if (s === 'true') {
      setSimpleView(true);
    }
  }, [s]);

  useEffect(() => {
    if (!session || !token || !labInstanceIdentifier || !accountId) {
      return;
    }
    // Load the lab info
    loadLab(`${labInstanceIdentifier}`, `${session}`, true);
    loadUserSession(accountId, `${labInstanceIdentifier}`);
  }, [labInstanceIdentifier, token, session, accountId]);

  useEffect(() => {
    // We only do this on initial load, we attempt to connect to the first resource
    if (!resources.length || init || !labRunning) {
      return;
    }

    setInit(true);
    for (let i = 0; i < resources.length; i++) {
      if (resources[i].connectable) {
        connect(resources[i]);
        break;
      }
    }
  }, [resources, labRunning]);

  // If the expires time has cleared and lab is marked complete, show the report
  useEffect(() => {
    if (labComplete && !expires) {
      closeSidebar();
      setShowReport(true);
    }
  }, [labComplete, expires]);

  useEffect(() => {
    if ((!isOnline || connectionError) && !showConnectionErrorModal) {
      hideSidebar();
      setShowConnectionErrorModal(true);
    }
  }, [connectionError, isOnline]);

  // Handle exiting a session
  const handleExit = async () => {
    setShowExitModal(false);
    setLeftEarly(true);
    if (!lab || !lab.labInstanceIdentifier) {
      return;
    }
    try {
      await endLab(lab.labInstanceIdentifier);
      hideSidebar();
    } catch (e) {
      setLeftEarly(false);
      console.log(e);
    }
  };

  const handleLabEnd = async () => {
    setShowExitModal(false);
    hideSidebar();
    try {
      if (lab && lab.labInstanceIdentifier) {
        await endLab(lab.labInstanceIdentifier);
      }
    } catch (e) {
      setLeftEarly(false);
      console.log(e);
    }
  };

  const guacConnectionErrorHandler = (msg = '') => {
    setConnectionError(msg);
  };

  const { ttl, expires: labExpires } = lab || {};
  const { expires: sessionExpires } = userSession || {};

  const preferredExpires = expires || labExpires || sessionExpires;

  if (loading) {
    return (
      <div className="items-center w-full h-screen text-gray-100 bg-neutral-900">
        <LoadingOrError loading={loading} error={!!error} errorText={error || ''} spinnerClass="h-14 w-14 text-gray-100" textClass="text-center" />
      </div>
    );
  }

  // const flagsCount = Object.keys(flags).length;
  const connectableResources = resources.filter((res) => res.connectable && res['connect-string']);
  return (
    <>
      <div className="w-full">
        <div className="hidden relative flex-col w-full md:flex md:fixed md:flex-row">
          <div className="grid relative flex-1 grid-cols-1 gap-1 h-screen bg-neutral-900">
            {labRunning && !error && !showConnectionErrorModal && connectableResources.length && (
              <TerminalList activeLab={lab} resources={resources} errorHandler={guacConnectionErrorHandler} />
            )}
            {!labRunning && !error && !showConnectionErrorModal && !leftEarly && <BuildingLab />}
            {!labRunning && !error && !showConnectionErrorModal && !!leftEarly && (
              <LoadingOrError
                loading
                loadingText="Connecting..."
                error={false}
                className="flex items-center pt-10 w-full h-full text-gray-100"
                spinnerClass="h-14 w-14 text-gray-100"
                textClass="text-center text-gray-100"
              />
            )}
            {!!error && !showConnectionErrorModal && <LoadingOrError loading={false} error={!!error} errorText={error || ''} textClass="text-center text-gray-100" />}
            {showConnectionErrorModal && <ConnectionError msg={connectionError} />}
            <Report title={playbook?.name} subtitle={playbook?.module?.name} open={showReport && !leftEarly && !simpleView} close={() => setShowReport(false)} summary={summary} />
          </div>
        </div>
      </div>
      {!!preferredExpires && !!lab && <GlobalTimer expires={preferredExpires} ttl={ttl} onEnd={handleLabEnd} />}
      <AuditModal open={showAuditLogs} close={() => setShowAuditLogs(false)} auditLogs={auditLogs} />
      <ExitModal simple={simpleView} open={showExitModal} close={() => setShowExitModal(false)} handleExit={handleExit} />
    </>
  );
}

export default function NewLearnerView({ labInstanceIdentifier, token, session }) {
  const { account, getAccount } = useClab();

  useEffect(() => {
    if (!session || !token || !labInstanceIdentifier) {
      return;
    }
    // Set the token so that way it is used by the agents file
    window.localStorage.setItem('clabjwt', `${token}`);
    // Load the account
    getAccount();
  }, [labInstanceIdentifier, token, session]);

  if (!account) {
    return (
      <div className="items-center w-full h-screen text-gray-100 bg-neutral-900">
        <LoadingOrError loading error={false} spinnerClass="h-14 w-14 text-gray-100" textClass="text-center" />
      </div>
    );
  }

  return <NewLearnerViewContainer accountId={account.id} labInstanceIdentifier={labInstanceIdentifier} session={session} token={token} />;
}
