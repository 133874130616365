import React from 'react';
import Spinner from '../Icons/Spinner';

export default function LoadSpinner({ text = '', spinnerClass = 'h-8 w-8 text-gray-500' }) {
  return (
    <div className="flex justify-center items-center w-full h-full">
      <div className="flex flex-col justify-center items-center space-x-1">
        <Spinner className={`animate-spin ${spinnerClass}`} active={false} />
        {text ? <div>{text}</div> : null}
      </div>
    </div>
  );
}
