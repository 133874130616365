import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import Confetti from 'react-confetti';
import { useWindowSize } from '@react-hook/window-size';
import { EmailShareButton, FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import { useNavigate } from 'react-router-dom';
import Modal from '../Modal/Modal';
import { CONFETTI_COLORS } from '../../constants';
import If from '../If/If';
import { useProfileContext } from '../../providers/ProfileProvider';
import Badge from '../Profile/ProfileTabs/BadgesTab/Badge';
import { useBadgeModal } from '../../providers/BadgeModalProvider';
import ShareProfileLink from '../Share/ShareProfileLink';
import Email from '../../Icons/Email';
import Linkedin from '../../Icons/Linkedin';
import Twitter from '../../Icons/Twitter';
import Facebook from '../../Icons/Facebook';
import { fetchUrl, getEmailSubject, getEmailText, getFacebookText, getTwitterText, handleGaEvent } from '../../utils/shareUtil';
import Loading from '../Loading/Loading';
import PermalinkUtil from '../../utils/permalinkUtil';

/**
 * Header for newly earned badges in the modal
 */
function NewlyEarnedBadgesHeader() {
  return (
    <>
      <h2 className="my-2 text-3xl font-black text-center lg:text-5xl">Congratulations!</h2>
      <p className="mx-8 mt-4 mb-0 text-xl font-bold text-center">
        You have earned a new badge!
        <br />
        Use the link below to share your achievement with the world.
      </p>
    </>
  );
}

/**
 * Share buttons for earned badges in the modal
 */
function BadgeModalShareButtons() {
  const { personalProfileData, isLoading } = useProfileContext();

  // Show loading spinner while we wait for the profile data
  if (isLoading) {
    return <Loading />;
  }

  const { username } = personalProfileData || {};

  // If we don't have a username, don't show the share buttons
  if (!username) {
    return null;
  }

  // Get the share URL for the profile page with the badges tab open
  const shareUrl = `${fetchUrl('profile', null, username)}?tab=badges`;

  // Get the share text for each social media platform
  const facebookText = getFacebookText('profile', username);
  const twitterText = getTwitterText('profile', username);
  const emailText = getEmailText('profile', username);
  const emailSubject = getEmailSubject('profile');

  // Google Analytics Event. Title = Profile name
  const beforeOnClick = () => handleGaEvent('profile', username);

  return (
    <div className="flex flex-col justify-center items-center p-4 space-x-2 w-full md:flex-row">
      <div className="flex justify-center items-center space-x-2">
        <FacebookShareButton url={shareUrl} quote={facebookText} beforeOnClick={beforeOnClick} hashtag="#Cybrary">
          <Facebook classes="w-6 h-6 text-white" withRectangle />
        </FacebookShareButton>
        <TwitterShareButton url={shareUrl} title={twitterText} beforeOnClick={beforeOnClick} hashtags={['#Cybrary']}>
          <Twitter classes="w-6 h-6 text-white" withRectangle />
        </TwitterShareButton>
        <LinkedinShareButton url={shareUrl} beforeOnClick={beforeOnClick}>
          <Linkedin classes="w-6 h-6 text-white" withRectangle />
        </LinkedinShareButton>
        <EmailShareButton url={shareUrl} body={emailText} beforeOnClick={beforeOnClick} subject={emailSubject}>
          <Email classes="w-6 h-6 text-white" withRectangle />
        </EmailShareButton>
      </div>
      <ShareProfileLink username={username} tab="badges" successText="Copied Badge Link!" />
    </div>
  );
}

/**
 * Wrapper to Badge component to show in the modal.
 * Includes the date the badge was earned, if applicable.
 * @param {Badge} badge Badge object to show in the modal item from the backend.
 * @param {boolean} isNewlyEarnedBadge Flag to show the date the badge was earned.
 * @returns
 */
function BadgesModalItem({ badge, isNewlyEarnedBadge }) {
  // Get the badge content from the badge modal context (Algolia data)
  const { badgeContentMap, close } = useBadgeModal();
  const badgeContent = badgeContentMap?.[badge.id];

  // If we have badge content, add an onclick to the badge to navigate to the content page
  let onClick = null;
  const navigate = useNavigate();
  if (badgeContent?.length) {
    // Get the permalink from the badge content (for now, just use the first content item)
    const { permalink, content_type } = badgeContent[0];
    // Add any slug prefixes to the permalink (For Threat Actor Path, etc.)
    const link = PermalinkUtil.formatInApp(permalink, content_type?.nice_name);
    onClick = () => {
      navigate(link);
      close();
    };
  }

  // show completed_at: "2023-10-19T14:33:58.000000Z" below badge only if badge is completed
  let completedAtDate = badge?.completed_at ? new Date(badge?.completed_at) : null;
  // for newly earned badges, if we don't have a completed_at date we use the current date
  if (!completedAtDate && isNewlyEarnedBadge) {
    completedAtDate = new Date();
  }

  // reformat date to be more readable "10/19/2023"
  const completedAt = completedAtDate ? `${completedAtDate.getMonth() + 1}/${completedAtDate.getDate()}/${completedAtDate.getFullYear()}` : '';

  return (
    <div className="flex flex-col justify-center items-center px-2 pt-4 w-56 text-center">
      {/** Badge */}
      <Badge badge={badge} noBorder onClick={onClick} showProgress progressWrapperClassName="bottom-2" isNewlyEarnedBadge={isNewlyEarnedBadge} />
      {/** Date Earned  */}
      <If condition={completedAt}>
        <p className="pt-1 text-xs italic font-normal text-black">Date Earned: {completedAt}</p>
      </If>
    </div>
  );
}

/**
 * Global Badge Modal
 * Should only be used via the `useBadgeModal` hook as it is a singleton
 * Supports multiple badges, newly earned badges, and sharing of profile
 */
const BadgesModal = inject('userNotificationStore')(
  observer(({ userNotificationStore }) => {
    const { isOpen, badges, close, open, options } = useBadgeModal();
    const { isNewlyEarnedBadge } = options || {};
    const [width, height] = useWindowSize();
    const { unread, lastUnreadCheckTimestamp, notifications } = userNotificationStore;
    const hasCompletedBadges = badges?.filter(({ completed_at }) => !!completed_at)?.length;
    const { fetchProfileData } = useProfileContext();

    // Watch the userNotificationStore for unread notifications that are for earned badges
    // If we have any, open the modal and mark those notification ID(s) as read.
    useEffect(() => {
      // If we don't have any unread notifications, don't do anything
      if (!unread) return;
      // Get our unread notifications to see if any are for earned badges
      userNotificationStore.getNotifications('page=1', { autoRead: false });
    }, [isOpen, unread, lastUnreadCheckTimestamp]);

    useEffect(() => {
      // If the modal is already open for some reason, don't open it again
      if (isOpen) return;

      // Check the type for each notification to see if it is for an earned badge and is unread
      const unreadBadgeNotifications = notifications?.data?.filter(({ type, read_at }) => type === 'App\\Notifications\\UserBadgeEarned' && !read_at);

      // If we don't have any unread notifications, don't do anything
      if (!unreadBadgeNotifications?.length) return;

      // Build array of badge objects to set in the modal from all unread badge notifications
      const newlyEarnedBadges = unreadBadgeNotifications.map(({ data }) => data?.meta);
      // Open the modal with the badges
      open(newlyEarnedBadges, { isNewlyEarnedBadge: true });

      // Build array of notification ID's to mark as read
      const notificationIds = unreadBadgeNotifications.map(({ id }) => id);
      // Mark the notifications as read
      userNotificationStore.markRead(notificationIds);

      // Reload user badges to show the newly earned badge(s) in the profile page
      fetchProfileData();
    }, [notifications?.data, isOpen, open]);

    return (
      <>
        <Modal
          open={isOpen}
          ariaLabelledBy="badge-modal"
          position="center"
          toggle={close}
          paddingBottom="pb-0"
          sizeClasses="p-4"
          className="overflow-y-auto pt-2 w-auto min-w-[300px] max-h-screen"
        >
          <If condition={isNewlyEarnedBadge}>
            <NewlyEarnedBadgesHeader />
          </If>
          <If condition={badges.length >= 1}>
            <div className="flex flex-wrap justify-center items-center pb-2 space-x-2 w-full lg:flex">
              {badges.map((badge) => (
                <BadgesModalItem badge={badge} key={badge.id} isNewlyEarnedBadge={isNewlyEarnedBadge} />
              ))}
            </div>
            <If condition={hasCompletedBadges || isNewlyEarnedBadge}>
              <BadgeModalShareButtons />
            </If>
          </If>
        </Modal>
        {/** We pass width and height so the confetti animation works if the screen is resized */}
        <If condition={isOpen && isNewlyEarnedBadge}>
          <Confetti width={width} height={height} colors={CONFETTI_COLORS} style={{ zIndex: 51 }} initialVelocityY={20} friction={0.9959} />
        </If>
      </>
    );
  })
);

export default BadgesModal;
