import React, { useMemo } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import WidgetContainer from '../../Container/WidgetContainer';
import Header from '../../Header/Header';
import { useCybMeasure } from '../../../providers/CybMeasureProvider';
import If from '../../If/If';
import CustomBarChartTooltip from './shared/CustomBarChartTooltip';
import Loading from '../../Loading/Loading';
import CustomBarChartTick, { CustomBarChartBottomTick } from './shared/CustomBarChartTick';

function ResultsGraphWidget({ orgId, selectedContentDescription }) {
  const { isTeamLearner, isTeamAdmin, learnerBaselineGoalDetails, isLoadingBaselineGoalDetails, teamBaselineGoals, isLoadingTeamBaselineGoals } = useCybMeasure();

  const baselineGoalDetails = useMemo(() => {
    const contentDescriptionId = selectedContentDescription?.['content-id'] ?? selectedContentDescription?.id;

    if (isTeamLearner) {
      return learnerBaselineGoalDetails[`${orgId}_${contentDescriptionId}`];
    }

    return teamBaselineGoals[`${orgId}_${contentDescriptionId}`];
  }, [orgId, selectedContentDescription, isTeamLearner, learnerBaselineGoalDetails, teamBaselineGoals]);

  const isLoading = useMemo(() => {
    const contentDescriptionId = selectedContentDescription?.['content-id'] ?? selectedContentDescription?.id;

    return isLoadingBaselineGoalDetails || isLoadingTeamBaselineGoals[`${orgId}_${contentDescriptionId}`];
  }, [isLoadingTeamBaselineGoals, isLoadingBaselineGoalDetails, selectedContentDescription]);

  const barChartData = useMemo(() => {
    if (isLoading) {
      return [];
    }

    const topicOrderColumnIndex = baselineGoalDetails?.topic_scores.columns.findIndex((column) => column.key === 'topic_position');

    return (
      baselineGoalDetails?.topic_scores.tableData
        ?.map((data) => ({
          topic: data[0].value,
          order: data[topicOrderColumnIndex]?.value,
          avgPreAssessmentScore: Math.round(parseFloat(data[3].value)),
          avgPreAssessmentScoreColor: '#ff00ff',
          avgPostAssessmentScore: Math.round(parseFloat(data[4].value)),
          avgPostAssessmentScoreColor: '#25136A',
        }))
        ?.sort((a, b) => a.order - b.order) || []
    );
  }, [baselineGoalDetails, isLoading]);

  return (
    <WidgetContainer className="pb-0" omitBorder>
      <Header as="h3" className="mb-3">
        Results
      </Header>
      <div className="flex flex-row items-center mb-4">
        <p className="mb-0">{`What are my ${isTeamAdmin ? "team's" : ''} average pre- and post-assessment scores?`}</p>
        <If condition={isLoading}>
          <Loading wrapperClassName="py-0 ml-3" className="w-4 h-4 border-2" />
        </If>
      </div>
      <div className="mb-5 h-120">
        <ResponsiveBar
          data={barChartData}
          keys={['avgPreAssessmentScore', 'avgPostAssessmentScore']}
          indexBy="topic"
          margin={{ top: 20, right: 70, bottom: 75, left: 80 }}
          padding={0.3}
          groupMode="grouped"
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          colors={({ id, data }) => String(data[`${id}Color`])}
          borderColor={{
            from: 'color',
            modifiers: [['darker', 1.6]],
          }}
          maxValue={100}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Topic',
            legendPosition: 'middle',
            legendOffset: 65,
            format: isLoading ? () => '' : undefined,
            renderTick: isLoading ? undefined : CustomBarChartBottomTick,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Score',
            legendPosition: 'middle',
            legendOffset: -60,
            truncateTickAt: 0,
            renderTick: CustomBarChartTick,
          }}
          label={(d) => `${d.value}%`}
          labelSkipWidth={10}
          labelSkipHeight={10}
          labelTextColor={(datum) => {
            if (datum.data.id === 'avgPostAssessmentScore') {
              return '#ffffff';
            }
            return '#000000';
          }}
          role="application"
          ariaLabel="Results graph chart"
          barAriaLabel={(e) => `${e.id}: ${e.formattedValue} in topic: ${e.indexValue}`}
          tooltip={CustomBarChartTooltip}
        />
      </div>
    </WidgetContainer>
  );
}

export default ResultsGraphWidget;
