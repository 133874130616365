import React, { useState } from 'react';
import { observer, inject } from 'mobx-react';
import { useDropzone } from 'react-dropzone';
import Icon from '../Icon/Icon';
import Tooltip from '../Tooltip/Tooltip';

function getErrorToastMessage(isMoreThanOneFile, isTooLarge, isNonApprovedFileType) {
  switch (true) {
    case isMoreThanOneFile:
      return 'Uploaded more than one file... Please make sure you only upload one file';
    case isTooLarge:
      return 'Uploaded file was too big... Please make sure it is less than 99 KB';
    case isNonApprovedFileType:
      return 'Uploaded file was not of approved file types... Please make sure file is of type jpeg, png, gif, or bmp';
    default:
      return 'Something went wrong trying to upload your file';
  }
}

const SingleImageUpload = inject('commonStore')(
  observer(
    ({
      commonStore,
      acceptedTypes,
      onChange,
      name,
      fieldId,
      dropWidth,
      dropHeight,
      popupText,
      preview,
      defaultValue,
      dropZoneText,
      iconName,
      ariaDescribedBy,
      required,
      maxFileSize,
    }) => {
      const [files, setFiles] = useState([]);
      const { getRootProps, getInputProps } = useDropzone({
        accept: acceptedTypes,
        onDrop: (acceptedFiles) => {
          // remove commas and whitespace from acceptedTypes i.e. 'image/png, image/jpg, image/gif' --> ['image/png', 'image/jpg', 'image/gif']
          const approvedFileTypes = acceptedTypes.split(',').map((item) => item.trim());

          // the types of all the attempted uploaded files. Will look like 'image/svg+xml', 'image/png' etc
          const uploadedFilesTypes = acceptedFiles.map((file) => file.type);
          // sizes of all the attempted uploaded files
          const uploadedFileSizes = acceptedFiles.map((file) => file.size);

          /**
           * conditions for upload failure
           */
          const numOfFiles = acceptedFiles.length;
          const isMoreThanOneFile = numOfFiles > 1;
          // if any of the uploaded files is greater then the limit, throw an error and exit upload
          const isTooLarge = uploadedFileSizes.some((fileSize) => fileSize > maxFileSize);
          // checks every uploaded file to make sure it is of the approved file types
          const isNonApprovedFileType = !uploadedFilesTypes.every((fileType) => approvedFileTypes.includes(fileType));

          const shouldError = isTooLarge || isMoreThanOneFile || isNonApprovedFileType;
          const errorToastMessage = getErrorToastMessage(isMoreThanOneFile, isTooLarge, isNonApprovedFileType);

          if (shouldError) {
            commonStore.triggerToast('error', {
              content: errorToastMessage,
            });
            setFiles([]);
            return;
          }
          // save accepted file in state to be previewed
          setFiles(
            acceptedFiles.map((file) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
              })
            )
          );
          onChange(undefined, { name, value: acceptedFiles[0] });
        },
      });

      const getPreviewImg = () => {
        if (!files.length) {
          if (defaultValue) {
            return defaultValue;
          }
          return '';
        }
        return files[0].preview;
      };

      const inputProps = { ...getInputProps() };
      inputProps.style = {};
      inputProps.className = 'sr-only';
      inputProps.tabIndex = null;
      const dropzoneProps = { ...getRootProps() };
      dropzoneProps.tabIndex = null;
      return (
        <div className="mb-1 focus-within:border focus-within:border-blue">
          <Tooltip
            transitionFlip={false}
            position="top"
            disabled={!popupText}
            content={popupText}
            action="click"
            triggerContent={
              <div
                {...dropzoneProps}
                style={{
                  width: dropWidth || 'auto',
                  height: dropHeight || 'auto',
                  backgroundImage: preview ? `url(${getPreviewImg()})` : 'none',
                }}
                className="flex items-center mx-auto bg-center bg-no-repeat bg-contain border-4 border-gray-600 focus-within:border-blue-500 border-dashed cursor-pointer"
              >
                <div className="mx-auto text-center">
                  <input id={fieldId || name} {...inputProps} aria-describedby={ariaDescribedBy} aria-required={required} />
                  {iconName && !getPreviewImg() ? (
                    <>
                      <Icon name={iconName} className="w-12 h-12 text-gray-600" />
                      {dropZoneText && <p>{dropZoneText}</p>}
                    </>
                  ) : null}
                </div>
              </div>
            }
          />
          {!preview ? (
            <div className="file-list">
              <ul className="list-none">
                <FileList files={files} />
              </ul>
            </div>
          ) : null}
        </div>
      );
    }
  )
);

function FileList({ files }) {
  if (!files) {
    return null;
  }
  return files.map((file) => {
    return (
      <li key={file.path}>
        {file.path} - {file.size} bytes
      </li>
    );
  });
}

export default SingleImageUpload;
