import React, { useMemo } from 'react';
import WidgetContainer from '../Container/WidgetContainer';
import Card from '../Card/Card';
import Header from '../Header/Header';
import If from '../If/If';
import LoadingText from '../Loading/LoadingText';
import { useABM } from '../../providers/ABMProvider';
import LockedContainer from '../Container/LockedContainer';
import BrowseUtil from '../../utils/browseUtil';
import AddLink from '../AddLink/AddLink';

/**
 * Cert Body Loading Card
 * @returns <CertBodyLoadingCard />
 */
function CertBodyLoadingCard() {
  return (
    <Card inlineContent stackDisplay>
      <LoadingText wrapperClassName="w-1/5 h-12 flex flex-col items-center justify-center" className="h-3" />
      <LoadingText wrapperClassName="w-4/5 h-12 flex flex-col items-center justify-center" className="h-3" />
    </Card>
  );
}

const ALL_CERT_BODIES = BrowseUtil.getCertifications();

/**
 * Top Certification Bodies Widget for ABM Page
 * @returns {JSX} - The Top Certification Bodies Widget
 */
function TopCertBodiesWidget() {
  const { isABMTeamLoading, isVerified, dashboardData } = useABM();

  const certBodies = useMemo(() => {
    return dashboardData?.top_certification_bodies?.map((topCertBody) => {
      const matchedCertBody = ALL_CERT_BODIES?.find((cert) => cert.name === topCertBody.certification_body);
      return {
        title: topCertBody?.certification_body,
        id: topCertBody?.certification_body_id,
        learner_count: topCertBody?.users_enrolled,
        image: matchedCertBody?.imgPath,
        link: matchedCertBody ? `/browse/refined?${matchedCertBody?.filter}=${matchedCertBody?.queryParam}` : null,
      };
    });
  }, [dashboardData?.top_certification_bodies, ALL_CERT_BODIES]);

  return (
    <LockedContainer msg="Verify your company email to unlock" isLocked={!isVerified && !isABMTeamLoading} isFullWidth>
      <WidgetContainer>
        <Header as="h2" className="mb-4">
          Top Certification Bodies
        </Header>
        {/* Loading State */}
        <If condition={isABMTeamLoading}>
          <CertBodyLoadingCard />
          <CertBodyLoadingCard />
          <CertBodyLoadingCard />
        </If>
        {/* Empty State */}
        <If condition={!certBodies?.length && !isABMTeamLoading}>
          <Card>
            <p>No certification bodies yet</p>
          </Card>
        </If>
        {/* Default State */}
        <If condition={certBodies?.length && !isABMTeamLoading}>
          {certBodies?.map(({ id, title, learner_count, image, link }) => (
            <AddLink to={link} key={id}>
              <Card inlineContent inlineDisplay stackDisplay>
                <If condition={!!image}>
                  <Card.Section className="mr-3 w-12">
                    <img alt={image?.title || 'Cert Prep Image'} src={image} />
                  </Card.Section>
                </If>
                <Card.Section className="flex flex-col grow">
                  <Header as="h4" className="grow mb-0">
                    {title}
                  </Header>
                  <span className="text-xs text-gray-600">
                    {learner_count} Learner{learner_count > 1 ? 's' : ''}
                  </span>
                </Card.Section>
              </Card>
            </AddLink>
          ))}
        </If>
        {/* Error State? */}
      </WidgetContainer>
    </LockedContainer>
  );
}

export default TopCertBodiesWidget;
