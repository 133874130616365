import React from 'react';
import { inject, observer } from 'mobx-react';
import { saveAs } from 'file-saver';
import moment from 'moment';
import Checkbox from '../FormFields/Checkbox';
import Loading from '../Loading/Loading';
import StyledError from '../Error/StyledError';
import Select from '../FormFields/Select';
import AddLink from '../AddLink/AddLink';
import FormatUtil from '../../utils/formatUtil';
import { HELP_DESK_LINKS } from '../../constants';

const daysOfTheWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

function getTimeOptions(increment = 30) {
  // Create time selection options - need to span from 12AM to 12PM in 30min intervals
  const timeOptions = [];
  for (let i = 0; i < 24; i++) {
    for (let y = 0; y < 60; y += increment) {
      const isPm = i > 11;
      // Need to convert the 24 hour time to 12 hour time for display
      let hourDisplay = isPm ? i - 12 : i;
      hourDisplay = hourDisplay === 0 ? 12 : hourDisplay;
      timeOptions.push({
        text: `${hourDisplay}:${y.toString().padStart(2, '0')}${isPm ? 'pm' : 'am'}`,
        value: `${i}:${y.toString().padStart(2, '0')}`,
      });
    }
  }
  return timeOptions;
}

function getDayOptions() {
  return daysOfTheWeek.map((day) => {
    return {
      text: day,
      value: day,
    };
  });
}

function DaySelector({ options, selectedDays, onChange, errorDays }) {
  return options.map((day) => {
    const isErrorDay = errorDays.indexOf(day.text) > -1;
    const isChecked = selectedDays.indexOf(day.value) !== -1;
    return (
      <div className={`py-2 text-sm ${isErrorDay ? 'mb-10' : 'mb-2'} border-xs border-white`} key={day.text}>
        <Checkbox
          id={day.text}
          label={day.text}
          labelClasses={isChecked ? 'text-black font-semibold' : 'text-gray-600'}
          value={day.value}
          name={day.text}
          checked={isChecked}
          onChange={onChange}
          className="flex items-center mb-0"
          inputClasses="mt-0"
        />
      </div>
    );
  });
}

function TimeRangeSelector({ onChange, data, errorDays }) {
  const timeOptions = getTimeOptions();
  // Loop over days of the week
  return daysOfTheWeek.map((day) => {
    const dayHasError = errorDays.indexOf(day) > -1;
    return (
      <div key={day}>
        <div className="flex items-center mb-[10px] text-sm">
          <div className="w-28">
            <Select
              disabled={!data[day]}
              defaultValue={(data[day] && data[day].start_time) || '12:00'}
              name="start_time"
              onChange={(e, val) => onChange(val, day)}
              options={timeOptions}
              ariaLabel={`${day} start time`}
              error={dayHasError}
            />
          </div>
          <span className="mx-2">-</span>
          <div className="w-28">
            <Select
              disabled={!data[day]}
              defaultValue={(data[day] && data[day].end_time) || '12:30'}
              name="end_time"
              onChange={(e, val) => onChange(val, day)}
              options={timeOptions}
              ariaLabel={`${day} end time`}
            />
          </div>
        </div>
        {dayHasError ? <div className="mb-3 text-xs italic text-red-500">End time cannot be before start time</div> : null}
      </div>
    );
  });
}

const LearningScheduler = inject(
  'userSettingsStore',
  'commonStore'
)(
  observer(
    class LearningScheduler extends React.Component {
      state = {
        icsDisabled: true,
      };

      componentDidMount() {
        const { getLearningSchedule, learningSchedule } = this.props.userSettingsStore;
        getLearningSchedule().then(() => {
          this.toggleIcsButton(false);
          if (learningSchedule.data && Object.keys(learningSchedule.data).length) {
            this.toggleIcsButton(false);
          }
        });
      }

      toggleIcsButton = (isDisabled) => {
        const newState = {
          ...this.state,
          icsDisabled: isDisabled,
        };
        this.setState(newState);
      };

      handleDayChange = (e, val) => {
        this.props.userSettingsStore.setLearningScheduleDays(val.value, val.checked);
        this.toggleIcsButton(true);
      };

      handleTimeChange = (val, day) => {
        this.props.userSettingsStore.setLearningScheduleTime(day, val.name, val.value);
        this.toggleIcsButton(true);
      };

      handleSubmit = () => {
        const { setLearningSchedule, learningSchedule } = this.props.userSettingsStore;
        setLearningSchedule()
          .then(() => {
            if (learningSchedule.data && Object.keys(learningSchedule.data).length) {
              this.toggleIcsButton(false);
            }
            this.props.commonStore.triggerToast('success', {
              content: 'Learning schedule has been saved!',
            });
          })
          .catch(() => {
            this.toggleIcsButton(true);
            this.props.commonStore.triggerToast('error', {
              content: 'Something when wrong. Unable to save this learning schedule. Please try again.',
            });
          });
      };

      setError = (error) => {
        const newState = {
          ...this.state,
          error,
        };
        this.setState(newState);
      };

      getErrorDays = (data) => {
        const errorDays = [];
        if (data && Object.keys(data).length) {
          Object.keys(data).forEach((day) => {
            const startInt = 1 * data[day].start_time.replace(':', '');
            const endInt = 1 * data[day].end_time.replace(':', '');
            if (startInt > endInt) {
              errorDays.push(day);
            }
          });
        }

        return errorDays;
      };

      createEvents = () => {
        const { learningSchedule, learningScheduleDayMap } = this.props.userSettingsStore;
        if (!learningSchedule.data || !Object.keys(learningSchedule.data).length) {
          return '';
        }
        /* 
        ** Sample ICS format
          BEGIN:VEVENT
          UID:cybrary-learning-schedule-${dayText} // Unique ID - Will update previous instances with the same ID (if schedule on a day changes)
          DTSTAMP:20210511T144111Z // date/time of file creation
          SUMMARY:Continue learning on Cybrary
          DTSTART:${startMomentFormatted} // Start time - Defaulted to EST/EDT
          DTEND:${endMomentFormatted} // End time - Defaulted to EST/EDT
          DESCRIPTION:Use this time to keep working towards your cybersecurity career goals. Continue  your learning on Cybrary: app.cybrary.it/my-learning
          STATUS:CONFIRMED
          RRULE:FREQ=WEEKLY;UNTIL=20240101T000000Z // Frequency of event
          END:VEVENT
        */
        const currentEasternTime = moment().tz('America/New_York');
        // Map of the next 7 days of the week, to determine start dates for each selected day
        const daysOfWeekDates = {};
        const learningDayMap = { ...learningScheduleDayMap };
        const daysMap = FormatUtil.objectFlip(learningDayMap);
        const events = [];
        const todayStamp = moment.utc().format('YYYYMMDDTHHmmss');
        const yearFromToday = moment.utc().add(1, 'year').endOf('day').format('YYYYMMDDTHHmmss');
        const eventDesc = 'Use this time to keep working towards your cybersecurity career goals. Continue  your learning on Cybrary: app.cybrary.it/my-learning';
        for (let i = 1; i <= 7; i++) {
          const day = currentEasternTime.clone().add(i, 'days');
          const dayOfWeek = day.isoWeekday();
          const dayText = daysMap[dayOfWeek];
          if (learningSchedule.data[dayText]) {
            const dayStartTimeArr = learningSchedule.data[dayText].start_time.split(':');
            const dayEndTimeArr = learningSchedule.data[dayText].end_time.split(':');
            const startMoment = day.clone().set({ hour: parseInt(dayStartTimeArr[0], 10), minute: parseInt(dayStartTimeArr[1], 10) });
            const endMoment = day.clone().set({ hour: parseInt(dayEndTimeArr[0], 10), minute: parseInt(dayEndTimeArr[1], 10) });
            const startMomentFormatted = moment.utc(startMoment).format('YYYYMMDDTHHmmss'); // Setting to UTC so calendar client handles timezone offset
            const endMomentFormatted = moment.utc(endMoment).format('YYYYMMDDTHHmmss'); // Setting to UTC so calendar client handles timezone offset
            const uid = `cybrary-learning-schedule-${dayText}`;
            // .ics contents split in half for sonarcloud - Broke in half because putting a return was tabbing contents on creation
            // Note: Z at end of datetimes is signify utc time
            const half1 = `BEGIN:VEVENT\r\nUID:${uid}\r\nDTSTAMP:${todayStamp}Z\r\nSUMMARY:Continue learning on Cybrary\r\nDTSTART:${startMomentFormatted}Z\r\nDTEND:`;
            const half2 = `${endMomentFormatted}Z\r\nDESCRIPTION:${eventDesc}\r\nSTATUS:CONFIRMED\r\nRRULE:FREQ=WEEKLY;UNTIL=${yearFromToday}Z\r\nEND:VEVENT`;
            events.push(`${half1}${half2}`);
          }
          daysOfWeekDates[dayText] = day;
        }
        return `${events.join('\r\n')}`;
      };

      // Create .ics file for calendar import
      createIcs = () => {
        const blobConstruct = ['BEGIN:VCALENDAR\r\nVERSION:2.0\r\nCALSCALE:GREGORIAN\r\nPRODID:-letconst//calendar-snippet\r\nMETHOD:PUBLISH', 'END:VCALENDAR'];

        const blobEvents = this.createEvents();
        blobConstruct.splice(1, 0, blobEvents);

        const blob = new Blob([blobConstruct.join('\r\n')], { type: 'text/calendar;charset=utf-8' });
        saveAs(blob, 'cybrary-learning-schedule.ics');
      };

      render() {
        const { learningSchedule } = this.props.userSettingsStore;
        const { loading, error, data } = learningSchedule;
        if (loading) {
          return <Loading message="Loading..." />;
        }
        if (error) {
          return <StyledError error={error} />;
        }
        const selectedDays = Object.keys(data);
        const dayOptions = getDayOptions();
        const fieldsHeadingClasses = 'text-2xs font-semi-bold mb-0 uppercase font-semibold';
        const fieldsSubHeadingClasses = 'text-2xs text-gray-600';
        const errorDays = this.getErrorDays(data);
        return (
          <div>
            <p className="mb-4 text-sm text-gray-600">
              You can change your notification preference at any time{' '}
              <button className="text-cyb-pink-500 underline cursor-pointer" onClick={(e) => this.props.expandAndScroll(e, 2)}>
                here
              </button>
              .
            </p>
            <div className="grid-cols-3 mt-8 md:grid">
              <div className="mb-8 md:col-span-2 md:mb-0">
                <div className="grid grid-cols-3 mb-6">
                  <div>
                    <fieldset>
                      <legend className={fieldsHeadingClasses}>Days</legend>
                      <p className={fieldsSubHeadingClasses}>Repeats Weekly</p>
                      <DaySelector options={dayOptions} selectedDays={selectedDays} onChange={this.handleDayChange} errorDays={errorDays} />
                    </fieldset>
                  </div>
                  <div className="col-span-2">
                    <p className={fieldsHeadingClasses}>Time</p>
                    <p className={fieldsSubHeadingClasses}>All times EST</p>
                    <TimeRangeSelector onChange={this.handleTimeChange} data={{ ...data }} errorDays={errorDays} />
                  </div>
                </div>
                <button
                  className="py-2.5 px-6 text-sm font-bold leading-5 text-center text-black bg-gray-200 rounded-sm disabled:opacity-50"
                  disabled={errorDays.length}
                  onClick={this.handleSubmit}
                  id="submit-learning-schedule"
                >
                  Save Changes
                </button>
              </div>
              <div>
                <div className="p-4 rounded-sm border-xs border-gray-400">
                  <h3 className="mb-4 text-sm font-bold">Add to Your Calendar</h3>
                  <div className="mb-8 text-sm">
                    <p className="mb-0 text-gray-600">Add your learning schedule to your calendar by downloading the iCal file and import it into your calendar client. </p>
                    <AddLink
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-black hover:text-cyb-pink-500 underline hover:underline"
                      to={HELP_DESK_LINKS.ADD_LEARNING_SCHEDULE_TO_CALENDAR}
                    >
                      Learn how
                    </AddLink>
                  </div>
                  <button
                    disabled={this.state.icsDisabled}
                    className="py-2.5 px-6 text-sm font-bold leading-5 text-center text-black bg-gray-200 rounded-sm disabled:opacity-50"
                    onClick={this.createIcs}
                  >
                    Download iCal File
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  )
);

export default LearningScheduler;
