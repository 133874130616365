import React, { Component } from 'react';
import { entries } from 'mobx';
import moment from 'moment';
import { observer } from 'mobx-react';
import Container from '../Container/Container';
import If from '../If/If';
import Icon from '../Icon/Icon';
import Table from '../Table/Table';
import Checkbox from '../FormFields/Checkbox';
import Pagination from '../Pagination/Pagination';
import Loading from '../Loading/Loading';
import StyledError from '../Error/StyledError';
import FormatUtil from '../../utils/formatUtil';
import AccessibilityUtil from '../../utils/accessibilityUtil';
import TableRowExpansion from './TableRowExpansion';
import OptionsMenu from '../OptionsMenu/OptionsMenu';
import './admin-table.css';
import Tooltip from '../Tooltip/Tooltip';
import ResultsSummary from './ResultsSummary';
import NoResultsMessage from '../NoResultsMessage/NoResultsMessage';
import Button from '../Button/Button';

export default observer(
  class AdminTable extends Component {
    state = {
      expandableRows: {},
      ignoredCols: [],
      isAccordionOpen: false,
    };

    componentDidMount() {
      this.getIgnoredCols();
    }

    componentDidUpdate(prevProps) {
      if (this.props.ignoredCols !== prevProps.ignoredCols || this.props.headings !== prevProps.headings) {
        this.getIgnoredCols();
      }
    }

    getIgnoredCols = () => {
      const ignoredCols = this.props.ignoredCols || [];
      if (this.props.headings) {
        this.props.headings.forEach((heading, idx) => {
          if (heading.showCol === false) {
            // If explicitely set to false, ignore. Otherwise if missing, or true, don't ignore
            ignoredCols.push(idx); // Push to array tracking which col idx to ignore
          }
        });
      }
      const newState = {
        ...this.state,
        ignoredCols,
      };
      this.setState(newState);
    };

    // Toggle all checkboxes in table rows
    toggleAllCkBox = (e, { checked }, callback) => {
      if (callback) callback(e, { checked }); // Pass back whether all are selected or not
    };

    // Toggle individual checkboxes in table rows
    toggleCkBox = (e, { checked }, idx, callback) => {
      if (callback) callback(e, { checked }, idx); // Pass back whether checkbox is checked, and which one
    };

    getSortIcon = (sortKey, sortCol, sortDirection) => {
      const iconClasses = 'w-4 h-4';
      const buttonClasses = 'align-text-top';
      if (sortCol && sortDirection && sortKey && sortKey === sortCol) {
        return sortDirection === 'asc' ? (
          <button aria-label="Sort column descending" className={buttonClasses}>
            <Icon name="arrow-narrow-up" className={iconClasses} />
          </button>
        ) : (
          <button aria-label="Sort column ascending" className={buttonClasses}>
            <Icon name="arrow-narrow-down" className={iconClasses} />
          </button>
        );
      }
      return (
        <button aria-label="Sort" className={buttonClasses}>
          <Icon name="arrows-up-down" className={iconClasses} />
        </button>
      );
    };

    generateHeadingColumns = (
      headings,
      headerClickFunc,
      sortCol,
      sortDirection,
      customColAlign,
      addCustomCol,
      omitHeadings,
      tableKey,
      omitSortColumns,
      clickToExpand,
      rowOptions,
      headerMappings,
      colWidths
    ) => {
      const colArr = [];
      headings.forEach((heading, idx) => {
        // If table option to hide headings is passed, or this column is in list to be ignored, do not return a headerCell
        const colWidth = colWidths?.find((item) => item.idx === idx);
        const style = colWidth ? { width: colWidth?.width } : {};
        if (this.state.ignoredCols.indexOf(idx) === -1) {
          const headingDisplay = headerMappings && headerMappings[heading.display] ? headerMappings[heading.display] : heading.display;
          const canSortCol = headerClickFunc && heading.sortKey && (!omitSortColumns || omitSortColumns.indexOf(idx) === -1);
          const textAlign = customColAlign ? `justify-${this.getColAlignment(customColAlign, idx, true)}` : '';
          colArr.push(
            <Table.HeaderCell
              scope="col"
              style={style}
              // eslint-disable-next-line react/no-array-index-key
              key={`${heading.display}-${idx}`}
              ariaSort={AccessibilityUtil.showAriaSortProp(heading.sortKey, sortCol, canSortCol, sortDirection)}
              className={`${canSortCol ? 'header-sortable' : ''} ${omitHeadings ? 'sr-only' : ''} print:text-sm`}
              onClick={canSortCol ? () => headerClickFunc(heading.sortKey, tableKey) : null}
            >
              <div className={`flex items-center ${textAlign}`}>
                {headingDisplay}{' '}
                <If condition={Boolean(heading.tooltip)}>
                  <Tooltip content={<span className="group font-semibold">{heading.tooltip}</span>}>
                    <div className="flex justify-center items-center ml-2 h-full">
                      <Icon name="question-circle" className="w-4 h-4 text-stone-400 hover:text-black" />
                    </div>
                  </Tooltip>
                </If>
                <If condition={canSortCol}>{this.getSortIcon(heading.sortKey, sortCol, sortDirection)}</If>
              </div>
            </Table.HeaderCell>
          );
        }
      });
      const headerClasses = `${omitHeadings ? 'sr-only' : ''} print:text-sm`;
      // Splice in any custom columns
      if (addCustomCol) {
        addCustomCol.forEach((item, idx) => {
          if (item.method) {
            colArr.splice(
              item.position,
              0,
              // eslint-disable-next-line react/no-array-index-key
              <Table.HeaderCell scope="col" className={headerClasses} key={`custom-col-${idx}`}>
                <span className={item.omitHeading ? 'sr-only' : ''}>{item.header}</span>
              </Table.HeaderCell>
            );
          }
        });
      }

      if (rowOptions) {
        colArr.push(
          <Table.HeaderCell scope="col" className={headerClasses} key="row-options">
            <span className="sr-only">Actions</span>
          </Table.HeaderCell>
        );
      }

      if (clickToExpand) {
        colArr.push(
          <Table.HeaderCell scope="col" className={headerClasses} key="expandColumn">
            <span className="sr-only">Expand Row</span>
          </Table.HeaderCell>
        );
      }

      return colArr;
    };

    // Set up table headings, if available
    displayTableHeadings = (
      headings,
      displayCheckbox,
      headingCheckboxFunc,
      headerClickFunc,
      omitHeadings,
      headerCheckBoxState,
      sortCol,
      sortDirection,
      customColAlign,
      addCustomCol,
      tableKey,
      omitSortColumns,
      clickToExpand,
      rowOptions,
      headerMappings,
      colWidths
    ) => {
      if (!!headings && headings.length) {
        return (
          <Table.Header>
            <Table.Row className="print:text-sm">
              {!!displayCheckbox && headingCheckboxFunc ? (
                <Table.HeaderCell scope="col" className="print:text-sm">
                  <Checkbox
                    id="select-all"
                    className="pb-0"
                    label="Select all"
                    labelClasses="sr-only"
                    onChange={(event, { checked }) => {
                      this.toggleAllCkBox(event, { checked }, headingCheckboxFunc);
                    }}
                    checked={headerCheckBoxState}
                  />
                </Table.HeaderCell>
              ) : null}
              {this.generateHeadingColumns(
                headings,
                headerClickFunc,
                sortCol,
                sortDirection,
                customColAlign,
                addCustomCol,
                omitHeadings,
                tableKey,
                omitSortColumns,
                clickToExpand,
                rowOptions,
                headerMappings,
                colWidths
              )}
            </Table.Row>
          </Table.Header>
        );
      }
      return null;
    };

    // Determine display format
    colDisplay = (headings, col, row, fillEmptyCols, formatColumns, currIdx, tableKey) => {
      if (formatColumns) {
        for (let i = 0; i < formatColumns.length; i++) {
          if (formatColumns[i].method && formatColumns[i].colIdx >= 0 && formatColumns[i].colIdx === currIdx) {
            return formatColumns[i].method(col, row, headings, tableKey);
          }
        }
      }

      if (!!col.value && !!col.value.type && col.value.type === 'img') {
        return <img className="org-member-avatar" src={col.value.value} alt="Avatar" />;
      }
      if (!!col.value && !!col.type && (col.type === 'date' || col.type === 'datetime')) {
        return moment(col.value).format('M/D/YYYY');
      }

      if (!!col.value && !!col.type && col.type === 'role') {
        return FormatUtil.convertRoleName(col.value);
      }
      // If type is object, it's either an unhandled object/array or null
      return typeof col.value === 'object' ? fillEmptyCols : col.value;
    };

    getColAlignment = (colObj, idx, isFlexJustifyDisplay = false) => {
      if (colObj.right && colObj.right.indexOf(idx) > -1) {
        return isFlexJustifyDisplay ? 'end' : 'right';
      }
      if (colObj.center && colObj.center.indexOf(idx) > -1) {
        return 'center';
      }
      return isFlexJustifyDisplay ? 'start' : 'left';
    };

    getRowOptions = (options, row, headings) => {
      if (!options) {
        return null;
      }
      const optionItems = [];
      options.forEach((option) => {
        if (!option.displayCondition || option.displayCondition(row, headings)) {
          const optionItem = { ...option };
          if (option.action) {
            optionItem.action = () => option.action(row, headings);
          }
          optionItems.push(optionItem);
        }
      });
      return optionItems;
    };

    getClickToExpandCol = (colArr, clickToExpand, shouldExpand, isRowOpen) => {
      const newColArr = [...colArr];
      if (clickToExpand) {
        const ariaLabelText = `${isRowOpen ? 'Click to collapse activity details' : 'Click to expand more details for this activity'}`;
        newColArr.push(
          <Table.Cell key="expandCol" className="text-right">
            {shouldExpand ? (
              <Tooltip content="Click to view more details for this activity" omitTabIndex>
                <button aria-label={ariaLabelText} aria-expanded={!!isRowOpen}>
                  <Icon className="w-4 h-4" name={isRowOpen ? 'chevron-up' : 'chevron-down'} />
                </button>
              </Tooltip>
            ) : null}
          </Table.Cell>
        );
      }
      return newColArr;
    };

    getColOptions = (colArr, rowOptions, row, headings, isLastRow) => {
      const newColArr = [...colArr];
      if (!!rowOptions && rowOptions.length) {
        const optionItems = this.getRowOptions(rowOptions, row, headings);
        newColArr.push(
          <Table.Cell key="row-options" className="text-right">
            {optionItems?.length ? (
              <OptionsMenu menuClasses="right-12 bottom-0" optionClasses="sm:py-1" options={optionItems} menuAbove={isLastRow} wrapperClassName="text-xs" />
            ) : null}
          </Table.Cell>
        );
      }
      return newColArr;
    };

    getSpliceCustomCol = (colArr, addCustomCol, row, headings, rowIdx, tableKey) => {
      const newColArr = [...colArr];
      // Splice in any custom columns - Loops backwards to not mess up the index positions
      if (addCustomCol) {
        addCustomCol.forEach((item) => {
          if (item.method) {
            newColArr.splice(item.position, 0, item.method(row, headings, rowIdx, tableKey));
          }
        });
      }
      return newColArr;
    };

    generateBodyColumns = ({
      row,
      headings,
      fillEmptyCols,
      formatColumns,
      customColAlign,
      addCustomCol,
      rowIdx,
      tableKey,
      clickToExpand,
      shouldExpand,
      rowOptions,
      colWidths,
      colClasses,
      isRowOpen,
      isLastRow,
    }) => {
      let colArr = [];
      row.forEach((col, idx2) => {
        if (this.state.ignoredCols.indexOf(idx2) === -1) {
          const colWidth = colWidths?.find((item) => item.idx === idx2);
          const style = colWidth ? { width: colWidth } : {};
          const textAlign = customColAlign ? `text-${this.getColAlignment(customColAlign, idx2)}` : '';
          // Ignore column if idx exists in array
          colArr.push(
            <Table.Cell
              // eslint-disable-next-line react/no-array-index-key
              key={`col-${idx2}-${col.value}`}
              style={style}
              className={`text-clip ${colClasses || ''} ${textAlign}`}
            >
              <>{this.colDisplay(headings, col, row, fillEmptyCols, formatColumns, idx2, tableKey)}</>
            </Table.Cell>
          );
        }
      });
      colArr = this.getSpliceCustomCol(colArr, addCustomCol, row, headings, rowIdx, tableKey);
      colArr = this.getColOptions(colArr, rowOptions, row, headings, isLastRow);
      colArr = this.getClickToExpandCol(colArr, clickToExpand, shouldExpand, isRowOpen);

      return colArr;
    };

    displayTableCells = ({
      headings,
      displayCheckbox,
      checkboxFunc,
      checkBoxesState,
      fillEmptyCols,
      formatColumns,
      customColAlign,
      addCustomCol,
      tableKey,
      row,
      idx,
      clickToExpand,
      shouldExpand,
      rowOptions,
      colWidths,
      checkboxLabel,
      colClasses,
      isRowOpen,
      isLastRow,
    }) => {
      return (
        <>
          {displayCheckbox ? (
            <Table.Cell>
              <Checkbox
                id={`checkbox-${idx}`}
                className="pb-0"
                label={checkboxLabel || 'Select row'}
                labelClasses="sr-only"
                checked={checkBoxesState[idx]}
                onChange={(event, { checked }) => this.toggleCkBox(event, { checked }, idx, checkboxFunc)}
              />
            </Table.Cell>
          ) : null}
          {this.generateBodyColumns({
            row,
            headings,
            fillEmptyCols,
            formatColumns,
            customColAlign,
            addCustomCol,
            idx,
            tableKey,
            clickToExpand,
            shouldExpand,
            rowOptions,
            colWidths,
            colClasses,
            isRowOpen,
            isLastRow,
          })}
        </>
      );
    };

    expandRow = (rowId) => {
      const currExpandableRows = { ...this.state.expandableRows };
      currExpandableRows[rowId] = {
        open: !!(!currExpandableRows[rowId] || !currExpandableRows[rowId].open),
      };
      const newState = {
        ...this.state,
        expandableRows: currExpandableRows,
      };
      this.setState(newState);
    };

    toggleAccordion = () => {
      this.setState((prevState) => ({ isAccordionOpen: !prevState.isAccordionOpen }));
    };

    // Set up table rows, if available
    displayTableRows = (
      rows,
      headings,
      displayCheckbox,
      checkboxFunc,
      checkBoxesState,
      fillEmptyCols,
      formatColumns,
      customColAlign,
      addCustomCol,
      tableKey,
      checkboxLabelCol,
      clickToExpand,
      rowOptions,
      rowClick,
      colWidths,
      colClasses
    ) => {
      if (!!rows && rows.length) {
        const { accordionMinRows } = this.props; // minimum number of rows to show before adding accordion to view more
        let shownData = rows;
        if (accordionMinRows && shownData.length > accordionMinRows && this.state.isAccordionOpen === false) {
          shownData = shownData.slice(0, accordionMinRows);
        }
        return shownData.map((row, idx) => {
          const shouldExpand = !!clickToExpand && clickToExpand.condition(row, headings);
          const rowExpandState = this.state.expandableRows[`table-row-${idx}`];
          const isRowOpen = rowExpandState ? rowExpandState.open : false;
          const isLastRow = idx === shownData.length - 1;
          const isHighlightedRow = row.find((item) => item.highlighted_row);
          let rowClass = 'print:text-sm';
          rowClass = shouldExpand ? `${rowClass} expandable` : rowClass;
          rowClass = isRowOpen ? `${rowClass} open` : rowClass;
          rowClass = isHighlightedRow ? `${rowClass} font-bold bg-gray-200` : rowClass;
          rowClass = rowClick ? `${rowClass} clickable` : rowClass;
          rowClass = idx === 1 * rows.length - 1 ? `${rowClass} last-row` : rowClass;

          const checkboxLabel = `Select  ${
            checkboxLabelCol && FormatUtil.getColIndex(headings, checkboxLabelCol) > -1 ? row[FormatUtil.getColIndex(headings, checkboxLabelCol)].value : 'row'
          } to edit`;

          return (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={`table-row-${idx}`}>
              <Table.Row
                className={rowClass}
                onClick={() => {
                  if (shouldExpand) {
                    this.expandRow(`table-row-${idx}`);
                  } else if (rowClick) {
                    rowClick(row, headings);
                  }
                }}
              >
                {this.displayTableCells({
                  headings,
                  displayCheckbox,
                  checkboxFunc,
                  checkBoxesState,
                  fillEmptyCols,
                  formatColumns,
                  customColAlign,
                  addCustomCol,
                  tableKey,
                  row,
                  idx,
                  clickToExpand,
                  shouldExpand,
                  rowOptions,
                  colWidths,
                  checkboxLabel,
                  colClasses,
                  isRowOpen,
                  isLastRow,
                })}
              </Table.Row>
              {shouldExpand ? (
                // eslint-disable-next-line react/no-array-index-key
                <Table.Row className="p-0 print:text-sm expanded-row" key={`table-row-${idx}-expanded-row`}>
                  <Table.Cell colSpan={headings.length} className="p-0">
                    <TableRowExpansion open={isRowOpen} openMethod={clickToExpand.method} row={row} headings={headings} />
                  </Table.Cell>
                </Table.Row>
              ) : null}
            </React.Fragment>
          );
        });
      }
      return null;
    };

    // Set up pagination, if available
    displayPagination = (pagNumPgs, pagChangePg, pagActivePg, headings, displayCheckBox) => {
      if (!!pagNumPgs && !!pagChangePg && !!pagActivePg) {
        let colSpan = headings.length;
        if (displayCheckBox) {
          colSpan += 1;
        }
        return (
          <Table.Row>
            <Table.Cell colSpan={colSpan}>
              <Pagination totalPages={pagNumPgs} onPageChange={pagChangePg} activePage={pagActivePg} siblingRange={1} />
            </Table.Cell>
          </Table.Row>
        );
      }
      return null;
    };

    render() {
      const {
        headings,
        displayCheckBox,
        omitHeadings, // Hides headings from view (but keeps them for screen reader use)
        headerCheckBoxFunc,
        data,
        rowCheckBoxFunc,
        pagNumPgs,
        pagChangePg,
        pagActivePg,
        tableError,
        tableLoading,
        headerClickFunc, // Function to call when clicking on a TH
        addCustomCol, // Add a custom column at the position specified - params = {position, method, header}
        headerCheckBoxState,
        checkBoxesState,
        noResultsMsg,
        sortCol,
        sortDirection,
        checkboxLabel, // Checkbox hidden label - For accessibility
        headerMappings, // Provide mappings for altering header text -- {"old name": "new name"}
        footerRow, // Provide an entire table row to display in the footer
        fillEmptyCols, // Provide a specific char or string that can be used to fill in empty columns
        formatColumns, // Provide custom method(s) that can be called on specified column(s) to provide custom formatting [{ method: this.linkToMemberDashboard, colIdx: 1}, ...]
        customColAlign, // Set which columns should have text align customColAlign={{right: [4], center: [2,3]}}
        tableKey, // A key that can be passed in and then returned back with custom columns - For when multiple tables exist and you need to know which one needs to be mutated as a result of an action
        omitSortColumns, // Array of columns to manually omit from calling sort functions on
        clickToExpand, // When exists, allows for user to click on specified column to slide the row down for more content - params = {condition, method}
        rowOptions, // Additional options (array) that roll up into a dropdown shown on click of an ellipses icon
        listView, // Adds styling to make table look more like a columned list (verticle padding, no vertical borders)
        rowClick, // Calls provided method on row click
        colWidths, // Specify widths for columns based on their index
        numOfResults,
        showResultsSummary, // shows heading and description of number of results found
        wrapperClassName,
        checkboxLabelCol,
        colClasses, // used in admin add-members to decrease text size
      } = this.props;

      if (checkBoxesState) {
        entries(checkBoxesState); // Forces a re-render (I think) to ensure checkboxes show as checked
      }
      if (tableLoading) {
        return (
          <Container>
            <Loading message="Loading..." />
          </Container>
        );
      }
      if (tableError) {
        return (
          <Container>
            <StyledError error={tableError} />
          </Container>
        );
      }
      if (!!data && data.length) {
        return (
          /* below comment is for tabIndex which was recommended by Level Access to be added here as an accessibility fix */
          <div
            className={`${this.props.omitResponsive ? '' : 'responsive-table-container'} ${listView ? 'list-view' : ''} ${wrapperClassName || 'my-8'}`}
            /* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */
            tabIndex="0"
            aria-label="scrollable content"
            role="region"
          >
            <ResultsSummary numOfResults={numOfResults} srOnly={!showResultsSummary} />

            <Table className={`admin-table responsive-table ${this.props.className ? this.props.className : ''}`} style={{ borderRadius: '2px' }}>
              {this.displayTableHeadings(
                headings,
                displayCheckBox,
                headerCheckBoxFunc,
                headerClickFunc,
                omitHeadings,
                headerCheckBoxState,
                sortCol,
                sortDirection,
                customColAlign,
                addCustomCol,
                tableKey,
                omitSortColumns,
                clickToExpand,
                rowOptions,
                headerMappings,
                colWidths
              )}
              <Table.Body>
                {this.displayTableRows(
                  data,
                  headings,
                  displayCheckBox,
                  rowCheckBoxFunc,
                  checkBoxesState,
                  fillEmptyCols || '',
                  formatColumns,
                  customColAlign,
                  addCustomCol,
                  tableKey,
                  checkboxLabelCol,
                  clickToExpand,
                  rowOptions,
                  rowClick,
                  colWidths,
                  colClasses,
                  checkboxLabel
                )}
              </Table.Body>
              {!!footerRow || (!!pagNumPgs && !!pagChangePg && !!pagActivePg) ? (
                <Table.Footer>
                  {footerRow ? footerRow() : null}
                  {this.displayPagination(pagNumPgs, pagChangePg, pagActivePg, headings, displayCheckBox)}
                </Table.Footer>
              ) : null}
              {/** Accordion Toggle */}
              <If condition={this.props.accordionMinRows && data.length > this.props.accordionMinRows}>
                <Table.Row>
                  <Table.Cell colSpan={headings.length - (this.state.ignoredCols?.length || 0)} className="p-0 border-b-0">
                    <Button
                      color="light-gray"
                      className="justify-center py-1 w-full font-semibold"
                      onClick={this.toggleAccordion}
                      icon={{ name: this.state.isAccordionOpen ? 'chevron-up' : 'chevron-down', position: 'right' }}
                    >
                      Show {this.state.isAccordionOpen ? 'less' : `${data.length - this.props.accordionMinRows} more`}
                    </Button>
                  </Table.Cell>
                </Table.Row>
              </If>
            </Table>
          </div>
        );
      }
      return (
        <div>
          <ResultsSummary numOfResults={0} srOnly={!showResultsSummary} />
          <NoResultsMessage message={noResultsMsg || 'No results to display'} />
        </div>
      );
    }
  }
);
