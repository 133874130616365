import { makeAutoObservable, action } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import Bugsnag from '@bugsnag/js';
import Agents from '../../agents/agents';

class TeamHierarchyStore {
  teams = {};

  sortOrders = {};

  trees = {};

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, { name: 'TeamHierarchyStore', properties: ['teams'], storage: window.sessionStorage, debugMode: false });
  }

  /* Team Hierarchy */

  // Takes an array of teams and builds a hierarchy based on each item's ID in relation to parent_id's - Will recursively call self until reaching end of tree
  createTeamHierachyTree = (parentId, parentName, data, parentPermissions, adminGroupsList, reportAdminGroupsList, permissionsMap = {}) => {
    if (!data || !data.length) {
      return { teamHierarchy: [], permissionsMap: {} };
    }
    let permissionsMapping = { ...permissionsMap };
    const teamList = [...data];
    // Get all of the groups to this layer
    const groups = teamList.filter((item) => {
      return item.parent_id * 1 === parentId * 1;
    });

    this.setHierarchySorting(parentId);
    // Loop through any groups found
    for (let i = 0; i < groups.length; i++) {
      const group = groups[i];
      const permissions = this.getGroupPermission(parentPermissions, group, adminGroupsList, reportAdminGroupsList);
      // Get all of the decendents of this child as well
      const childrenTree = this.createTeamHierachyTree(group.id, group.name, teamList, permissions, adminGroupsList, reportAdminGroupsList, permissionsMapping);
      const children = childrenTree.teamHierarchy;
      group.children = children;
      group.parentName = parentName;
      if (!permissions.manage) {
        const childrenWithPerms = children.filter((child) => {
          return !!child.permissions.manage;
        });
        // If any child groups are admins, set the parent to view access
        if (childrenWithPerms && childrenWithPerms.length) {
          permissions.manage = 'view';
        }
      }
      permissionsMapping = {
        ...permissionsMapping,
        ...childrenTree.permissionsMap,
      };
      permissionsMapping[group.id] = permissions;
      group.permissions = permissions;
    }
    return { teamHierarchy: groups, permissionsMap: permissionsMapping };
  };

  setHierarchySorting = (id, col) => {
    if (!id) {
      return;
    }

    const sortOrdersCopy = { ...this.sortOrders };

    if (!sortOrdersCopy[id]) {
      sortOrdersCopy[id] = this.getDefaultSortOrder();
    } else if (col) {
      // If sorting on same column, just change direction
      const currConfig = { ...sortOrdersCopy[id] };
      if (currConfig.col === col) {
        currConfig.dir = currConfig.dir === 'desc' ? 'asc' : 'desc';
      } else {
        // Otherwise, set column and direction to descending by default
        currConfig.col = col;
        currConfig.dir = 'desc';
      }
      sortOrdersCopy[id] = currConfig;
    }

    this.sortOrders = sortOrdersCopy;
  };

  getDefaultTeam = () => {
    return {
      loading: true,
      error: null,
      data: null,
    };
  };

  getDefaultTeamHierarchy = () => {
    return {
      loading: true,
      error: false,
      listData: null,
      selectOptions: null,
      data: null,
    };
  };

  getDefaultSortOrder = () => {
    return {
      col: 'created_at',
      dir: 'desc',
    };
  };

  getDefaultTree = () => {
    return {
      data: null,
      selectOptions: null,
      permissionsMap: {},
    };
  };

  createHierarchySelectOptions = (data, groupFilter, query, defaultOption, nestChildOptions, level) => {
    const selectOptions = [];
    if (defaultOption) {
      selectOptions.push({
        ...defaultOption,
        key: 'defaultGroup',
      });
    }

    if (!data || !data.length) {
      return selectOptions;
    }
    // Loop through the hierarchy data to filter out options based on provided filter or search query
    data.forEach((group) => {
      const childOptions = group.children && group.children.length ? this.createHierarchySelectOptions(group.children, groupFilter, query, null, nestChildOptions, level + 1) : [];
      const selectOption = {
        indented: level,
        key: group.id,
        text: group.name,
        value: group.id,
      };
      // Check if this group is valid based on provided filter (if provided)
      const validGroup = (!groupFilter || groupFilter(group)) && (!query || selectOption.text.toLowerCase().indexOf(query.toLowerCase()) > -1);

      // if the group passes supplied group filter, and name matches provided query, OR if the group has any valid children, keep this group option
      if (validGroup || childOptions.length) {
        selectOption.disabled = !validGroup;
        if (nestChildOptions) {
          selectOption.childOptions = [...childOptions];
          selectOptions.push(selectOption);
        } else {
          selectOptions.push(selectOption);
          selectOptions.push(...childOptions);
        }
      }
    });
    return selectOptions;
  };

  getSortOrders = (id) => {
    return this.sortOrders[id];
  };

  getError = (id) => {
    return this.teams[id].error;
  };

  getSelectOptions = (id) => {
    if (!this.trees[id] || !this.trees[id].selectOptions) {
      return [];
    }
    return this.trees[id].selectOptions;
  };

  isLoading = (id) => {
    return !this.teams[id] || this.teams[id].loading;
  };

  setHierarchySelectOptions = (id, filter, query, defaultOption, nestChildOptions) => {
    if (this.trees[id] && this.trees[id].data && this.trees[id].data.length) {
      this.trees[id].selectOptions = this.createHierarchySelectOptions([...this.trees[id].data], filter, query, defaultOption, nestChildOptions, 1);
    }
  };

  getGroupPermission = (parentPermissions, data, adminGroupsList, reportAdminGroupsList) => {
    const permissions = {
      manage: false, // Can manage the group - edit (add/remove users, change role) or view (view group info)
      reports: false, // Can view reports
    };
    if (parentPermissions.manage === 'edit' || adminGroupsList.includes(data.id)) {
      permissions.manage = 'edit';
    } else if (parentPermissions.manage) {
      permissions.manage = 'view';
    }
    if (parentPermissions.manage === 'edit' || parentPermissions.reports || adminGroupsList.includes(data.id) || reportAdminGroupsList.includes(data.id)) {
      permissions.reports = true;
    }
    return permissions;
  };

  /**
   * Retrieve and set team hierarchy data
   */
  getTeamHierarchy = async (team, forceRefresh, preserveState) => {
    const { id, name } = team;
    if (!this.teams[id] || !this.teams[id].data || !this.trees[id] || forceRefresh) {
      await this.fetchTeamHierarchy(team.id, preserveState);
    }

    if (!preserveState || !this.trees[id]) {
      this.trees[id] = this.getDefaultTree();
    }

    // Group permissions from team level permissions
    const parentGroupPermissions = {
      manage: team.permissions.canManageTeam ? 'edit' : false,
      reports: team.permissions.canManageTeam || team.permissions.canViewReports === 'all',
    };
    const { teamHierarchy, permissionsMap } = this.createTeamHierachyTree(id, name, this.teams[id].data, parentGroupPermissions, team.adminGroupsList, team.reportAdminGroupsList);
    this.trees[id].data = teamHierarchy;
    this.trees[id].permissionsMap = permissionsMap;
    return this.trees[id].data;
  };

  fetchTeamHierarchy(id, preserveState) {
    if (!preserveState || !this.teams[id]) {
      this.teams[id] = this.getDefaultTeam();
    }

    return Agents.enterprise
      .getTeamHierarchy(id)
      .then(
        action('fetchSuccess', (response) => {
          this.teams[id].data = response;
          this.teams[id].loading = false;
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.teams[id].error = error.response || error;
          this.teams[id].loading = false;
        })
      );
  }
}

export default new TeamHierarchyStore();
