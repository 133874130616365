import React from 'react';
import Segment from '../Segment/Segment';
import Header from '../Header/Header';

function CurriculumConfirm({ type }) {
  if (!!type && type === 'copy') {
    return (
      <Segment className="border-none curricula-confirm">
        <Header key="confirm-header" as="h3">
          You are Copying a Cybrary Path
        </Header>
        <div className="confirm-copy">
          <p key="main-copy">Copying this Cybrary Path means it becomes a customized path and will no longer be eligible to automatically receive updates.</p>
          <p key="sub-copy" style={{ fontWeight: 600, padding: '0.5em 1em' }}>
            Are you sure you want to copy this path?
          </p>
        </div>
      </Segment>
    );
  }
  if (!!type && type === 'goal') {
    return (
      <Segment className="border-none curricula-confirm">
        <Header key="confirm-header" as="h3">
          You are adding a Cybrary Path to a goal
        </Header>
        <div className="confirm-copy">
          <p key="main-copy">
            Users will automatically receive updates when a new version is released. If you would like to opt out of auto updates, please copy the path before assigning it to your
            members.
          </p>
        </div>
      </Segment>
    );
  }

  return null;
}

export default CurriculumConfirm;
