const CurriculumBuildForm = {
  order: ['name', 'description', 'contents', 'submit'],
  fields: {
    name: {
      type: 'text',
      label: 'Title',
      validations: ['required'],
    },
    description: {
      type: 'textarea',
      label: 'Brief Description',
      validations: ['required'],
    },
    contents: {
      type: 'orderedlist',
      label: 'Path Content',
      validations: ['required'],
    },
    submit: {
      type: 'button',
      color: 'green',
      label: 'Save Path',
      style: { background: '#DF057B', fontWeight: 700, fontSize: '0.9375rem', color: '#fff', lineHeight: '1.25rem', padding: '0.625rem 1.5rem', borderRadius: '0.125rem' },
    },
  },
};

export default CurriculumBuildForm;
