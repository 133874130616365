import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import queryString from 'query-string';
import moment from 'moment';
import Bugsnag from '@bugsnag/js';
import withRouter from '../../components/Router/withRouter';
import Title from '../../components/Title/Title';
import Loading from '../../components/Loading/Loading';
import DynamicForm from '../../components/DynamicForm/DynamicForm';
import StyledError from '../../components/Error/StyledError';
import Container from '../../components/Container/Container';
import './reports.css';

const ManageScheduledReports = inject(
  'commonStore',
  'userStore',
  'reportsStore',
  'authStore'
)(
  observer(
    class ManageScheduledReports extends Component {
      state = {
        orgId: null,
        view: null,
      };

      componentDidMount() {
        this.props.authStore.fireAttributionEvent();
        this.props.userStore.setPreferredTeamInit('', this.props.match.params.orgId, this.init);
      }

      componentDidUpdate(prevProps) {
        this.props.userStore.setPreferredTeamInit(prevProps.match.params.orgId, this.props.match.params.orgId, this.init);
      }

      componentWillUnmount() {
        this.props.reportsStore.setDefaultScheduledReportData();
      }

      getBreadCrumbs() {
        const crumbs = [];
        crumbs.push({
          href: `/enterprise/${this.state.orgId}/reporting/tools`,
          label: 'Reporting Tools',
        });
        crumbs.push({
          href: `/enterprise/${this.state.orgId}/reporting/scheduled-reports`,
          label: 'Scheduled Reports',
        });
        crumbs.push({
          href: `${this.props.location.pathname}${this.props.location.search}`,
          label: 'Edit Scheduled Report',
        });
        return crumbs;
      }

      init = (orgId) => {
        const params = window.location.search;
        const newState = {
          ...this.state,
          orgId,
          view: this.props.match.params.id ? 'edit' : 'add',
        };
        this.setState(newState);

        if (!this.props.match.params.id) {
          this.props.reportsStore.setScheduledReportParams(queryString.parse(params));
        } else {
          this.props.reportsStore.getScheduledReportData(this.props.userStore.team.reportsTeamGroupId, this.props.match.params.id);
        }
        this.props.reportsStore.getUserListData(this.props.userStore.team.reportsTeamGroupId);
      };

      handleSubmit = (data) => {
        const { scheduledReportData } = this.props.reportsStore;
        // If report is filtered by a group, use that group ID for submitting - Ensures it's returned in lists with group filters
        const teamGroupId =
          scheduledReportData.data.report_parameters.filterAud && scheduledReportData.data.report_parameters.filterAud === 'group'
            ? scheduledReportData.data.report_parameters.filterAudId
            : this.props.userStore.team.reportsTeamGroupId;
        this.props.reportsStore
          .postScheduledReport(teamGroupId, data)
          .then(() => {
            this.props.commonStore.triggerToast('success', {
              heading: 'Success!',
              content: 'Report has been scheduled. Redirecting to your list of scheduled reports.',
            });
            setTimeout(() => {
              // Send the user to the team details page
              this.props.navigate(`/enterprise/${this.state.orgId}/reporting/scheduled-reports`);
            }, 6000);
          })
          .catch((e) => {
            Bugsnag.notify(e);
            this.props.commonStore.triggerToast('error', {
              content: 'Something went wrong. Please try again.',
            });
          });
      };

      addDefaultValues = (scheduleReportsForm, storeData) => {
        const form = { ...scheduleReportsForm };
        if (!storeData) return form; // There is no store data (user is adding a new team), so just return the form as-is  .format("YYYY-MM-DD h:mm")
        Object.keys(form.fields).forEach((item) => {
          if (!!form.fields[item] && !!storeData[item] && storeData[item] !== form.fields[item].defaultValue) {
            form.fields[item].defaultValue = storeData[item];
          }
        });
        return form;
      };

      getUsers = (data) => {
        return data.map((item) => {
          return {
            key: item.value,
            text: item.text,
            value: item.value,
          };
        });
      };

      getForm = () => {
        const form = {
          order: ['start_date', 'end_date', 'interval', 'frequency', 'recipients', 'submit'],
          fields: {
            start_date: {
              type: 'datePicker',
              label: 'Start Date',
              defaultValue: moment().format('YYYY-MM-DD 00:00:00'),
              validations: ['required'],
            },
            end_date: {
              type: 'datePicker',
              label: 'End Date',
              defaultValue: moment().add(3, 'months').format('YYYY-MM-DD 00:00:00'),
              validations: ['required'],
            },
            interval: {
              type: 'select',
              label: 'Date Range',
              defaultValue: '',
              placeholder: 'Select date range for the report',
              validations: ['required'],
              options: [
                {
                  key: 'P1W',
                  text: 'Last 7 Days',
                  value: 'P1W',
                },
                {
                  key: 'P30D',
                  text: 'Last 30 Days',
                  value: 'P30D',
                },
                {
                  key: 'P3M',
                  text: 'Last 3 Months',
                  value: 'P3M',
                },
                {
                  key: 'P6M',
                  text: 'Last 6 Months',
                  value: 'P6M',
                },
                {
                  key: 'MTD',
                  text: 'Month to Date',
                  value: 'MTD',
                },
                {
                  key: 'YTD',
                  text: 'Year to Date',
                  value: 'YTD',
                },
                {
                  key: 'ALL',
                  text: 'All-Time',
                  value: 'ALL',
                },
              ],
            },
            frequency: {
              type: 'radio',
              label: 'Frequency',
              defaultValue: '',
              validations: ['required'],
              fieldsPerRow: 3,
              options: [
                {
                  key: 'P1D',
                  text: 'Daily',
                  value: 'P1D',
                },
                {
                  key: 'P1W',
                  text: 'Weekly',
                  value: 'P1W',
                },
                {
                  key: 'P1M',
                  text: 'Monthly',
                  value: 'P1M',
                },
              ],
            },
            recipients: {
              type: 'multi-select',
              label: 'Recipients',
              validations: ['required'],
              placeholder: 'Select users',
              options: this.getUsers(this.props.reportsStore.userListData.data),
              defaultValue: '',
            },
            submit: {
              type: 'button',
              color: 'pink',
              icon: 'save outline',
              label: 'Save Report Schedule',
            },
          },
        };

        if (this.state.view === 'edit') {
          return this.addDefaultValues(form, this.props.reportsStore.scheduledReportData.data);
        }
        return form;
      };

      displayReportInfo = (data) => {
        return (
          <div className="grid grid-cols-2 gap-x-4 p-6 bg-gray-100 rounded-sm">
            <p className="mb-0">Report Title:</p>
            <p>{data.reportTitle}</p>
            {data.searchQuery ? (
              <>
                <p className="mb-0">Search Query:</p>
                <p>&quot;{data.searchQuery}&quot;</p>
              </>
            ) : null}
            {data.groupName ? (
              <>
                <p className="mb-0">Group:</p>
                <p>{data.groupName}</p>
              </>
            ) : null}
          </div>
        );
      };

      displayFormAndReportInfo = () => {
        if (this.props.reportsStore.userListData.loading || (this.state.view === 'edit' && this.props.reportsStore.scheduledReportData.loading)) {
          return (
            <Container>
              <Loading message="Loading..." />
            </Container>
          );
        }
        if (this.props.reportsStore.userListData.error) {
          return (
            <Container>
              <StyledError error={this.props.reportsStore.userListData.error} />
            </Container>
          );
        }
        if (this.state.view === 'edit' && !!this.props.reportsStore.scheduledReportData.error) {
          return (
            <Container>
              <StyledError error={this.props.reportsStore.scheduledReportData.error} />
            </Container>
          );
        }
        if (!!this.props.reportsStore.scheduledReportData.data && !!this.props.reportsStore.scheduledReportData.data.report_parameters) {
          return (
            <div className="grid lg:grid-cols-2">
              <div className="order-2 lg:order-1">
                <DynamicForm form={this.getForm()} formName="addScheduledReport" onSubmit={this.handleSubmit} />
              </div>
              <div className="order-1 mb-8 lg:order-2 lg:mb-0">{this.displayReportInfo(this.props.reportsStore.scheduledReportData.data.report_parameters)}</div>
            </div>
          );
        }
        // If a user gets to this page directly, without any report params. Redirect them to the scheduled reports list page
        setTimeout(() => {
          this.props.navigate(`/enterprise/${this.state.orgId}/reporting/scheduled-reports`);
        }, 2000);

        return (
          <div>
            <p>No report data provided. Redirecting to your scheduled reports listing.</p>
          </div>
        );
      };

      render() {
        return (
          <Container size="md" className="scheduled-reports-form">
            <Title title={this.state.view === 'edit' ? 'Edit Scheduled Report' : 'Add Scheduled Report'} breadcrumbs={this.getBreadCrumbs()} />
            {this.displayFormAndReportInfo()}
          </Container>
        );
      }
    }
  )
);

export default withRouter(ManageScheduledReports);
