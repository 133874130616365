import React, { useEffect } from 'react';

function DropdownContainer({ classes, ariaLabel, omitWidth, width, customStyles, visible, onClose, children }) {
  // allow dropdown to be closed on press of esc key (accessibility reasons)
  const close = (e) => {
    if (e && e.key === 'Escape' && visible) {
      onClose();
    }
  };
  useEffect(() => {
    // if open add eventListener, else remove it
    window.addEventListener('keydown', close);
    return () => {
      window.removeEventListener('keydown', close);
    };
  }, [visible]);
  let dropdownStyles = { zIndex: '801' };
  if (!omitWidth) {
    dropdownStyles.width = width || '18rem';
  }
  if (customStyles && Object.keys(customStyles).length) {
    dropdownStyles = { ...dropdownStyles, ...customStyles };
  }
  return (
    <div className={`${visible ? 'block' : 'hidden'}`}>
      <button aria-label={ariaLabel || 'Close menu'} onClick={onClose} tabIndex="-1" className="fixed inset-0 z-10 w-full h-full focus:outline-none cursor-default">
        &nbsp;
      </button>
      <div className={`absolute rounded-lg border shadow-2xl bg-white mr-3 ${classes}`} style={dropdownStyles}>
        {children}
      </div>
    </div>
  );
}

export default DropdownContainer;
