import React, { useMemo } from 'react';
import { observer, inject } from 'mobx-react';
import { useParams, useNavigate } from 'react-router-dom';
import Container from '../../components/Container/Container';
import Title from '../../components/Title/Title';
import If from '../../components/If/If';
import useQueryParams from '../../hooks/useQueryParams';
import { useCybAssessmentAnalytics } from '../../providers/CybAssessmentAnalyticsProvider';
import BaselineTeamsPathOverview from '../../components/Baseline/BaselineTeamsPathOverview';
import BaselineEmptyText from '../../components/Baseline/modules/BaselineEmptyText';

const BaselineTeamsPath = inject('userStore')(
  observer(({ userStore }) => {
    const navigate = useNavigate();

    const orgId = userStore?.adminTeam?.id;

    const userAccessDenied = useMemo(() => !userStore?.user?.is_paid || userStore?.user?.is_cip, [userStore?.user]);

    if (userAccessDenied) {
      navigate('/baseline/teams');
    }

    const { pathId } = useParams();

    const { memberId, memberName } = useQueryParams();

    const { tracks } = useCybAssessmentAnalytics();

    const path = useMemo(() => {
      const paths = tracks[userStore?.user?.id];

      if (!paths) {
        return null;
      }

      return paths.find((item) => item['content-id'] === Number(pathId));
    }, [tracks, pathId, userStore?.user]);

    const title = useMemo(() => {
      let base = 'Path Overview';

      if (memberId && memberName) {
        base += ` - ${memberName}`;
      }

      return base;
    }, [path, memberId, memberName]);

    return (
      <Container className="pt-0 sm-576:px-4" md omitPadding>
        <Title title={title} omitPadding wrapperClasses="pt-8 md:pt-12 mb-4" classes="text-2xl lg:text-3xl" />
        <If condition={!path}>
          <Container>
            <BaselineEmptyText orgId={orgId} />
          </Container>
        </If>
        <If condition={Boolean(path)}>
          <BaselineTeamsPathOverview pathId={pathId} />
        </If>
      </Container>
    );
  })
);

export default BaselineTeamsPath;
