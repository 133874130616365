import React from 'react';
import Icon from '../Icon/Icon';
import Divider from '../Divider/Divider';

const config = {
  comingSoon: {
    icon: 'megaphone',
    text: 'Content is coming soon',
    classes: 'w-4 h-4 inline-block ml-1 shrink-0 fill-current text-cyb-blue-500',
    solid: true,
  },
  complete: {
    icon: 'checkmark-circle',
    text: 'Content is complete',
    classes: 'w-5 h-5 text-cyb-green-700 inline-block ml-1 shrink-0',
    active: true,
  },
};

export function StatusIcon({ className, status, omitAccessibilityText }) {
  if (!status || !config[status]) {
    return null;
  }
  const iconConfig = config[status];
  return (
    <>
      <Icon className={className || iconConfig.classes} name={iconConfig.icon} solid={!!iconConfig.solid} active={!!iconConfig.active} />
      {!omitAccessibilityText && <span className="sr-only">{iconConfig.text}</span>}
    </>
  );
}

function StatusLegend({ hideNoContent = false }) {
  return (
    <div className="flex justify-around items-center py-1 px-2 mt-4 rounded-sm border border-gray-400 sm:gap-x-2 sm:mt-0">
      <span className="flex items-center w-max text-2xs uppercase rounded-sm">
        Coming Soon <StatusIcon status="comingSoon" omitAccessibilityText />
      </span>

      <Divider className="inline-block h-4 border-gray-400" vertical />
      <span className="flex items-center w-max text-2xs uppercase rounded-sm">
        Completed Content <StatusIcon status="complete" omitAccessibilityText />
      </span>

      {!hideNoContent && (
        <>
          <Divider className="inline-block h-4 border-gray-400" vertical />
          <span className="flex items-center py-1 px-2 w-max text-2xs leading-3 uppercase bg-gray-400 rounded-sm border-xs">No Content Yet</span>
        </>
      )}
    </div>
  );
}

export default StatusLegend;
