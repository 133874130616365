import React from 'react';

export function Warning({ hasALab, warning, className = '', hiddenSizeClass = 'md:hidden' }) {
  if (!hasALab) {
    return null;
  }
  return (
    <div className={`block ${hiddenSizeClass} bg-cyb-blue-500 text-white text-sm rounded-sm p-4 ${className}`}>
      {warning || 'This content is intended to be accessed on a desktop device. Please log in to Cybrary from a desktop browser to complete this activity.'}
    </div>
  );
}

export function WarningWithWrapper({ vendor, type, title, className, hasALab }) {
  const gradientClass = 'bg-gradient-to-br lg:bg-gradient-to-r from-cyb-pink-600 to-cyb-purple-500';
  return (
    <div className={`block md:hidden rounded-sm py-4 ${gradientClass} ${className}`}>
      <p className="px-4 pb-4 text-lg font-black border-b border-white">Launch Virtual Machine</p>
      <p className="mx-4 mt-6 mb-2 text-lg font-bold">{title}</p>
      <p className="mx-4 mb-5 text-sm">
        By <span className="font-bold">{vendor}</span>
      </p>
      <Warning hasALab={hasALab} warning={`This ${vendor} ${type} has been optimized for a desktop environment.`} className="mx-4" />
    </div>
  );
}
