import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useNavigate } from 'react-router-dom';

import Modal from '../Modal/Modal';

export default function NewTeamPurchaseMessage({ clearSubscriptionStatus }) {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = queryString.parse(window.location.search);
    const isNewSubscription = !!queryParams.newSubscription;
    // Clear the storage subscription status so the user doesn't end up here again
    clearSubscriptionStatus();
    if (isNewSubscription) {
      setShowModal(isNewSubscription);
      delete queryParams.newSubscription;
      navigate({
        search: queryString.stringify(queryParams),
      });
    }
  }, []);

  return (
    <Modal position="center" size="lg" open={showModal} toggle={() => setShowModal(!showModal)} ariaLabelledBy="new-team-subscription">
      <p className="px-7 pt-14 font-semibold" id="new-team-subscription">
        Congrats on starting your Cybrary for Teams account! One of our customer support team members will contact you within the next business day to help finish setting up your
        team.
      </p>
    </Modal>
  );
}
