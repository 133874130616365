import React, { useState, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import Label from './Label';

/**
 * Google ReCAPTCHA V2 Component
 * @param {string} labelClass - Classname for label
 * @param {string} label - Label text
 * @param {string} id - ID for label
 * @param {boolean} required - Is field required?
 * @param {string} name - Name of field
 * @param {function} toggleButtons - Function to toggle buttons on parent form
 * @param {string} ariaDescribedBy - ID of element to describe field
 * @param {function} onChange - Function to call on change
 * @param {object} style - Style object
 * @returns
 */
function Captcha({ labelClass, label, id, required, name, toggleButtons, ariaDescribedBy, onChange, style }) {
  const isCaptchaEnabled = process.env.REACT_APP_CAPTCHA_ENABLED === 'true';
  const [value, setValue] = useState(false);
  if (!isCaptchaEnabled) {
    useEffect(() => {
      onChange(undefined, { name, value });
    }, [value]);
  }

  const labelText = !isCaptchaEnabled ? 'Captcha not enabled. Click checkbox to proceed.' : label;
  const labelOutput = <Label id={id} label={labelText} required={required} style={style} className={labelClass} />;

  // Disabled captcha checkbox
  if (!isCaptchaEnabled) {
    return (
      <>
        {labelOutput}
        <input
          type="checkbox"
          checked={!!value}
          id={name}
          name={name}
          onChange={(e) => {
            setValue(e.target.checked);
            toggleButtons(e.target.checked);
          }}
          aria-describedby={ariaDescribedBy}
        />
      </>
    );
  }

  return (
    <>
      {labelOutput}
      <ReCAPTCHA
        id={id}
        sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
        onChange={(val) => {
          toggleButtons(true).then(() => {
            onChange(null, { name, value: val });
          });
        }}
        aria-describedby={ariaDescribedBy}
      />
    </>
  );
}

export default Captcha;
