import React, { useMemo } from 'react';
import AdminTable from '../../AdminTable/AdminTable';
import { useCybMeasure } from '../../../providers/CybMeasureProvider';
import { formatTableColumns } from '../../Baseline/shared';
import WidgetContainer from '../../Container/WidgetContainer';
import Header from '../../Header/Header';
import Loading from '../../Loading/Loading';
import If from '../../If/If';

const COLUMN_HEADING_MAPPINGS = {
  pre_assessment_score: 'Average Pre-Assessment Score',
  post_assessment_score: 'Average Post-Assessment Score',
  difference: 'Difference',
};

const renderColumn = (row, _fullRow, _headings, column) => {
  if (row.value !== undefined && column.showCol) {
    const percentage = Math.round(parseFloat(row.value));

    if (row.value === null) {
      return <i>incomplete</i>;
    }

    if (column.key === 'difference') {
      const sign = percentage < 0 ? '-' : '+';
      return `${sign}${Math.abs(percentage)}%`;
    }

    return `${percentage}%`;
  }

  return '';
};

function DetailTableWidget({ orgId, selectedContentDescription }) {
  const { isTeamLearner, isTeamAdmin, learnerBaselineGoalDetails, isLoadingBaselineGoalDetails, teamBaselineGoals, isLoadingTeamBaselineGoals } = useCybMeasure();

  const isLoading = useMemo(() => {
    const contentDescriptionId = selectedContentDescription?.['content-id'] ?? selectedContentDescription?.id;

    return isLoadingBaselineGoalDetails || isLoadingTeamBaselineGoals[`${orgId}_${contentDescriptionId}`];
  }, [isLoadingBaselineGoalDetails, isLoadingTeamBaselineGoals, selectedContentDescription]);

  const baselineGoalDetails = useMemo(() => {
    if (!selectedContentDescription) {
      return null;
    }

    const contentDescriptionId = selectedContentDescription?.['content-id'] ?? selectedContentDescription?.id;

    if (isTeamLearner) {
      return learnerBaselineGoalDetails[`${orgId}_${contentDescriptionId}`];
    }

    return teamBaselineGoals[`${orgId}_${contentDescriptionId}`];
  }, [orgId, selectedContentDescription, learnerBaselineGoalDetails, teamBaselineGoals]);

  const updatedColumnHeadings = useMemo(
    () =>
      baselineGoalDetails?.topic_scores?.columns?.map((col) => ({
        ...col,
        display: COLUMN_HEADING_MAPPINGS[col.key] ?? col.display,
      })),
    [baselineGoalDetails]
  );

  const formatColumns = useMemo(() => {
    let targetKeys = ['avg_pre_assessment_score', 'avg_post_assessment_score', 'difference', 'community_score'];

    if (isTeamLearner) {
      targetKeys = ['pre_assessment_score', 'post_assessment_score', 'difference'];
    }
    return formatTableColumns(baselineGoalDetails?.topic_scores?.columns, targetKeys, renderColumn);
  }, [renderColumn, baselineGoalDetails, isTeamLearner]);

  const tableData = useMemo(() => {
    const topicOrderColumnIndex = baselineGoalDetails?.topic_scores.columns.findIndex((column) => column.key === 'topic_position');

    return baselineGoalDetails?.topic_scores?.tableData.sort((a, b) => a[topicOrderColumnIndex].value - b[topicOrderColumnIndex].value);
  }, [baselineGoalDetails]);

  const ignoredCols = useMemo(() => {
    const value = [];

    if (tableData) {
      // get the totalsRow, which is the last row
      const totalsRow = tableData[tableData.length - 1];
      // find the index of the `highlighted_row` entry for that row
      const highlightedRowEntryIndex = totalsRow?.findIndex((item) => item?.highlighted_row);

      if (highlightedRowEntryIndex >= 0) {
        value.push(highlightedRowEntryIndex);
      }
    }
    return value;
  }, [tableData]);

  return (
    <WidgetContainer className="flex flex-col pt-0" omitBorder>
      <Header as="h3" className="mb-3">
        Detail
      </Header>
      <div className="flex flex-row items-center mb-6">
        <p className="mb-0">{`What are the detailed breakdowns of my ${isTeamAdmin ? "team's" : ''} baselining scores?`}</p>
        <If condition={isLoading}>
          <Loading wrapperClassName="py-0 ml-3" className="w-4 h-4 border-2" />
        </If>
      </div>
      <AdminTable
        wrapperClassName="m-0 w-full"
        colClasses="whitespace-nowrap"
        headings={updatedColumnHeadings}
        data={tableData}
        formatColumns={formatColumns}
        ignoredCols={ignoredCols}
      />
    </WidgetContainer>
  );
}

export default DetailTableWidget;
