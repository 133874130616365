import React from 'react';
import BaselineTeamsOverviewTable from './modules/BaselineTeamsOverviewTable';
import BaselineTeamsOverviewMemberTable from './modules/BaselineTeamsOverviewMemberTable';
import BaselineTeamsOverviewChart from './modules/BaselineTeamsOverviewChart';

export default function BaselineTeamsOverview() {
  return (
    <div className="flex flex-col mt-5">
      <BaselineTeamsOverviewChart />
      <BaselineTeamsOverviewTable />
      <BaselineTeamsOverviewMemberTable />
    </div>
  );
}
