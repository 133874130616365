import React from 'react';
import { twMerge } from 'tailwind-merge';
import CountUp from 'react-countup';
import Icon from '../Icon/Icon';
import Loading from '../Loading/Loading';

/**
 * Statcard to show numerical value, icon, and title
 * @param {string} title - title of the stat card
 * @param {string} value - value of the stat card
 * @param {string} iconName - name of the icon to use from the Icon component
 * @param {string} color - color of the icon and border i.e. 'cyb-pink-500', 'red-500', etc.
 * @param {boolean} isLoading - whether or not to show the loading component in place of the value
 * @param {string} className - additional classes to add to the component
 * @returns <StatCard />
 */
export default function ProfileStatCard({ className, title, value = 0, iconName, isLoading, color = 'cyb-pink-500' }) {
  return (
    <div className={twMerge('flex flex-col items-center border-1 rounded-md border-gray-600 py-2 w-full mx-1 bg-white', className)}>
      <div className="flex items-center mb-4">
        <div className={`flex items-center border-1 border-${color} rounded-full`}>
          <Icon name={iconName} className={`m-1 w-6 h-6 text-${color} `} />
        </div>
      </div>
      {isLoading ? <Loading wrapperClassName="w-full" className="w-4 h-4 border-2" /> : <CountUp className="mb-4 text-2xl font-black" separator="," end={value} duration={1} />}
      <p className="items-end px-1 h-full text-[10px] font-medium text-center">{title}</p>
    </div>
  );
}
