import { useState, useMemo } from 'react';

const useMitreCatalog = () => {
  const [techniqueSideSectionData, setTechniqueSideSectionData] = useState(null);
  const [subTechniqueSideSectionData, setSubTechniqueSideSectionData] = useState(null);

  // Set technique/subtechnique data to display in side section slide out
  const setTechniqueData = (data) => {
    const isSubTechnique = data.x_mitre_is_subtechnique;
    const config = isSubTechnique
      ? {
          data: subTechniqueSideSectionData,
          setter: setSubTechniqueSideSectionData,
        }
      : {
          data: techniqueSideSectionData,
          setter: setTechniqueSideSectionData,
        };
    // If there's no data passed through, or the user clicked on the technique already opened, go ahead and close
    if (!data || (config.data && data.id === config.data.id)) {
      config.setter(null);
    } else {
      if (!isSubTechnique) {
        // If a technique was clicked on, clear opened subtechnique data
        setSubTechniqueSideSectionData(null);
      }
      config.setter(data);
    }
  };

  const resetTechniqueData = () => {
    setTechniqueSideSectionData(null);
    setSubTechniqueSideSectionData(null);
  };

  const techniqueDataValues = useMemo(() => ({ setTechniqueData, openTechniqueId: techniqueSideSectionData ? techniqueSideSectionData.id : null }), [techniqueSideSectionData]);

  return {
    setTechniqueData,
    techniqueSideSectionData,
    setTechniqueSideSectionData,
    subTechniqueSideSectionData,
    setSubTechniqueSideSectionData,
    resetTechniqueData,
    techniqueDataValues,
  };
};

export default useMitreCatalog;
