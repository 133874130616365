import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import queryString from 'query-string';
import Icon from '../../components/Icon/Icon';
import Header from '../../components/Header/Header';
import Segment from '../../components/Segment/Segment';
import withRouter from '../../components/Router/withRouter';
import Loading from '../../components/Loading/Loading';
import Container from '../../components/Container/Container';
import './reports.css';

const ReportDownload = inject(
  'commonStore',
  'reportsStore',
  'authStore'
)(
  observer(
    class ReportDownload extends Component {
      state = {
        downloaded: false,
        error: false,
      };

      componentDidMount() {
        this.props.authStore.fireAttributionEvent();
        const queryParams = queryString.parse(this.props.location.search);
        const downloadId = queryParams.urlid;
        const { orgId } = this.props.match.params;
        this.props.reportsStore
          .downloadScheduledReport(orgId, downloadId)
          .then((response) => {
            window.open(response, '_blank');
            this.props.commonStore.triggerToast('success', {
              content: 'Report has successfully been downloaded!',
            });
            const newState = {
              ...this.state,
              downloaded: true,
            };
            this.setState(newState);
          })
          .catch((e) => {
            this.props.commonStore.triggerToast('error', {
              errorCode: e.response.status,
            });
            const newState = {
              ...this.state,
              downloaded: false,
              error: true,
            };
            this.setState(newState);
          });
      }

      getStatusMessage = (downloaded, error) => {
        if (downloaded) {
          return (
            <Segment className="p-12">
              <Header as="h2" className="flex gap-x-1 items-center">
                <Icon name="checkmark-circle" className="w-6 h-6 text-green-600" />
                Report Downloaded!
              </Header>
              <p style={{ color: '#637381' }}>The download may open in a new window. Please ensure that popups are enabled on this page if you do not see your report download</p>
            </Segment>
          );
        }
        if (error) {
          return (
            <Segment className="p-12">
              <Header as="h2" className="flex gap-x-1 items-center">
                <Icon name="x-circle" className="w-6 h-6 text-red-600" />
                Something went wrong
              </Header>
            </Segment>
          );
        }
        return <Loading message="Retrieving your report..." />;
      };

      render() {
        return (
          <Container>
            <Segment className="border-none">{this.getStatusMessage(this.state.downloaded, this.state.error)}</Segment>
          </Container>
        );
      }
    }
  )
);

export default withRouter(ReportDownload);
