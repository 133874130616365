import React from 'react';
import { inject, observer } from 'mobx-react';

const ExportAccount = inject(
  'authStore',
  'userStore',
  'profileStore'
)(
  observer(
    class ExportAccount extends React.Component {
      componentDidMount() {
        this.props.authStore.fireAttributionEvent();
      }

      render() {
        return (
          <div>
            <p>This request emails an export of the information we store and collect in the platform to you as a csv. This has no impact on your account or user status.</p>

            <button
              onClick={() => this.props.handleInitiateDataExport()}
              className="py-2.5 px-6 mt-4 ml-8 text-sm font-bold leading-5 text-center text-black bg-gray-200 rounded-sm"
            >
              Start export
            </button>
          </div>
        );
      }
    }
  )
);

export default ExportAccount;
