import React, { useMemo } from 'react';
import { ResponsiveLine } from '@nivo/line';
import { ResponsiveRadar } from '@nivo/radar';
import moment from 'moment';
import ExampleSection from './ExampleSection';
import Header from '../../../components/Header/Header';
import Code from '../../../components/Code/Code';
import StyleUtil from '../../../utils/styleUtil';
import { ChartSliceTooltip } from '../../../components/Baseline/shared';
import BarChart from '../../../components/Charts/BarChart';

const graphColorDefaults = StyleUtil.getGraphColorDefaults();

const BAR_CHART_DATA = [
  {
    country: 'AD',
    'hot dog': 91,
    burger: 67,
    sandwich: 8,
    kebab: 148,
    fries: 104,
    donut: 16,
  },
  {
    country: 'AE',
    'hot dog': 192,
    burger: 177,
    sandwich: 187,
    kebab: 1,
    fries: 129,
    donut: 43,
  },
  {
    country: 'AF',
    'hot dog': 136,
    burger: 178,
    sandwich: 154,
    kebab: 191,
    fries: 23,
    donut: 189,
  },
  {
    country: 'AG',
    'hot dog': 88,
    burger: 95,
    sandwich: 152,
    kebab: 142,
    fries: 187,
    donut: 93,
  },
  {
    country: 'AI',
    'hot dog': 161,
    burger: 134,
    sandwich: 43,
    kebab: 179,
    fries: 135,
    donut: 197,
  },
  {
    country: 'AL',
    'hot dog': 83,
    burger: 154,
    sandwich: 5,
    kebab: 157,
    fries: 150,
    donut: 44,
  },
  {
    country: 'AM',
    'hot dog': 34,
    burger: 64,
    sandwich: 62,
    kebab: 120,
    fries: 117,
    donut: 113,
  },
];

const LINE_CHART_DATA = [
  {
    id: 'Average User Score',
    data: [
      {
        x: '2023-10-01',
        y: 50,
      },
      {
        x: '2023-11-01',
        y: 43,
      },
      {
        x: '2023-12-01',
        y: 65,
      },
      {
        x: '2024-01-01',
        y: 88,
      },
    ],
    color: graphColorDefaults.primaryColors[0],
  },
  {
    id: 'Community Score',
    data: [
      {
        x: '2023-10-01',
        y: 25,
      },
      {
        x: '2023-11-01',
        y: 55,
      },
      {
        x: '2023-12-01',
        y: 73,
      },
      {
        x: '2024-01-01',
        y: 90,
      },
    ],
    color: graphColorDefaults.primaryColors[1],
  },
  {
    id: 'Company Score',
    data: [
      {
        x: '2023-10-01',
        y: 35,
      },
      {
        x: '2023-11-01',
        y: 58,
      },
      {
        x: '2023-12-01',
        y: 79,
      },
      {
        x: '2024-01-01',
        y: 100,
      },
    ],
    color: graphColorDefaults.primaryColors[2],
  },
];

const RADAR_CHART_DATA = [
  {
    taste: 'fruity',
    chardonay: 36,
    carmenere: 21,
    syrah: 28,
  },
  {
    taste: 'bitter',
    chardonay: 31,
    carmenere: 94,
    syrah: 114,
  },
  {
    taste: 'heavy',
    chardonay: 41,
    carmenere: 88,
    syrah: 106,
  },
  {
    taste: 'strong',
    chardonay: 29,
    carmenere: 76,
    syrah: 34,
  },
  {
    taste: 'sunny',
    chardonay: 54,
    carmenere: 99,
    syrah: 72,
  },
];

function GridLabel({ id, x, y, anchor }) {
  const translateValue = useMemo(() => {
    if (anchor === 'end') {
      return -50;
    }

    if (anchor === 'middle') {
      return -20;
    }

    return 0;
  }, []);
  return (
    <g transform={`translate(${x}, ${y})`}>
      <g transform={`translate(${translateValue}, -10)`}>
        <text>{id}</text>
      </g>
    </g>
  );
}

function ChartExampleSection() {
  const gridXValues = useMemo(() => LINE_CHART_DATA[0].data.map((struct) => moment(struct.x).toDate()), []);

  return (
    <ExampleSection title="Charts">
      {/** Line Chart */}
      <Header as="h2" className="mb-0">
        Line Chart
      </Header>
      <div>
        <div className="relative w-2/3 h-[400px]">
          <ResponsiveLine
            animate
            margin={{ top: 30, right: 40, bottom: 50, left: 60 }}
            data={LINE_CHART_DATA}
            curve="monotoneX"
            xFormat="time:%Y-%m-%d"
            xScale={{
              format: '%Y-%m-%d',
              precision: 'month',
              type: 'time',
              useUTC: false,
            }}
            yFormat=" >-.2f"
            yScale={{
              type: 'linear',
              min: 0,
              max: 100,
            }}
            axisBottom={{
              tickSize: 10,
              tickPadding: 10,
              tickRotation: 0,
              format: '%b %d %Y',
              tickValues: 'every month',
            }}
            axisLeft={{
              tickSize: 0,
              tickPadding: 10,
              tickRotation: 0,
              tickValues: [0, 20, 40, 60, 80, 100],
              format: (value) => `${value}%`,
            }}
            sliceTooltip={ChartSliceTooltip}
            colors={{ datum: 'color' }}
            pointSize={10}
            pointColor="#FFFFFF"
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            gridXValues={gridXValues}
            crosshairType="cross"
            enableSlices="x"
            useMesh
          />
        </div>
        <div className="flex flex-row items-start ml-12">
          {LINE_CHART_DATA.map((entry, index) => (
            <div className="flex flex-row items-center py-1 px-2 mr-3 hover:bg-black/[0.03] rounded-sm cursor-default" key={entry.id + index.toString()}>
              <div className="mr-2 w-2 h-2 rounded-full" style={{ backgroundColor: entry.color }} />
              <span className="text-[11px] font-semibold text-gray-600 whitespace-nowrap">{entry.id}</span>
            </div>
          ))}
        </div>
      </div>
      <Code>
        {`
      const LINE_CHART_DATA = [{"id":"Average User Score","data":[{"x":"2023-10-01","y":50},{"x":"2023-11-01","y":43},{"x":"2023-12-01","y":65},{"x":"2024-01-01","y":88}],"color":""},{"id":"Community Score","data":[{"x":"2023-10-01","y":25},{"x":"2023-11-01","y":55},{"x":"2023-12-01","y":73},{"x":"2024-01-01","y":90}],"color":""},{"id":"Company Score","data":[{"x":"2023-10-01","y":35},{"x":"2023-11-01","y":58},{"x":"2023-12-01","y":79},{"x":"2024-01-01","y":100}],"color":""}];

      <div className="relative w-2/3 h-[400px]">
        <ResponsiveLine
          animate
          margin={{ top: 30, right: 40, bottom: 50, left: 60 }}
          data={LINE_CHART_DATA}
          curve="monotoneX"
          xFormat="time:%Y-%m-%d"
          xScale={{
            format: '%Y-%m-%d',
            precision: 'month',
            type: 'time',
            useUTC: false,
          }}
          yFormat=" >-.2f"
          yScale={{
            type: 'linear',
            min: 0,
            max: 100,
          }}
          axisBottom={{
            tickSize: 10,
            tickPadding: 10,
            tickRotation: 0,
            format: '%b %d %Y',
            tickValues: 'every month',
          }}
          axisLeft={{
            tickSize: 0,
            tickPadding: 10,
            tickRotation: 0,
            tickValues: [0, 20, 40, 60, 80, 100],
            format: (value) => \`\${value}%\`,
          }}
          sliceTooltip={ChartSliceTooltip}
          colors={{ datum: 'color' }}
          pointSize={10}
          pointColor="#FFFFFF"
          pointBorderWidth={2}
          pointBorderColor={{ from: 'serieColor' }}
          gridXValues={gridXValues}
          crosshairType="cross"
          enableSlices="x"
          useMesh
        />
      </div>
        `}
      </Code>
      {/** Radar Chart */}
      <Header as="h2" className="mb-0">
        Radar Chart
      </Header>
      <div className="relative w-[500px] h-[500px]">
        <ResponsiveRadar
          animate
          data={RADAR_CHART_DATA}
          keys={['chardonay', 'carmenere', 'syrah']}
          indexBy="taste"
          valueFormat=">-.2f"
          margin={{ top: 70, right: 80, bottom: 40, left: 80 }}
          borderColor={{ from: 'color' }}
          gridLabelOffset={36}
          gridLabel={GridLabel}
          dotSize={10}
          dotColor={{ theme: 'background' }}
          dotBorderWidth={2}
          colors={{ scheme: 'set1' }}
          blendMode="multiply"
          motionConfig="wobbly"
          legends={[
            {
              anchor: 'top-left',
              direction: 'column',
              translateX: -50,
              translateY: -40,
              itemWidth: 80,
              itemHeight: 25,
              symbolSize: 12,
              symbolShape: 'circle',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemTextColor: '#000',
                  },
                },
              ],
            },
          ]}
        />
      </div>
      <Code>
        {`
      const RADAR_CHART_DATA = [{"taste":"fruity","chardonay":36,"carmenere":21,"syrah":28},{"taste":"bitter","chardonay":31,"carmenere":94,"syrah":114},{"taste":"heavy","chardonay":41,"carmenere":88,"syrah":106},{"taste":"strong","chardonay":29,"carmenere":76,"syrah":34},{"taste":"sunny","chardonay":54,"carmenere":99,"syrah":72}];

      <div className="relative w-[500px] h-[500px]">
        <ResponsiveRadar
          animate
          data={RADAR_CHART_DATA}
          keys={['chardonay', 'carmenere', 'syrah']}
          indexBy="taste"
          valueFormat=">-.2f"
          margin={{ top: 70, right: 80, bottom: 40, left: 80 }}
          borderColor={{ from: 'color' }}
          gridLabelOffset={36}
          dotSize={10}
          dotColor={{ theme: 'background' }}
          dotBorderWidth={2}
          colors={{ scheme: 'set1' }}
          blendMode="multiply"
          motionConfig="wobbly"
          legends={[
            {
              anchor: 'top-left',
              direction: 'column',
              translateX: -50,
              translateY: -40,
              itemWidth: 80,
              itemHeight: 20,
              itemTextColor: '#999',
              symbolSize: 12,
              symbolShape: 'circle',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemTextColor: '#000',
                  },
                },
              ],
            },
          ]}
        />
      </div>
        `}
      </Code>

      {/* BAR CHART */}
      <Header as="h2" className="mb-0">
        Bar Chart
      </Header>
      <div>
        <div className="relative h-[600px]">
          <BarChart
            data={BAR_CHART_DATA}
            keys={['hot dog', 'burger', 'sandwich', 'kebab', 'fries', 'donut']}
            indexBy="country"
            colorScheme="paired"
            ariaLabel="Example bar chart"
          />
        </div>
      </div>
      <Code>
        {`
      
      <div className="relative h-[600px]">
        <BarChart
          data={BAR_CHART_DATA}
          keys={['hot dog', 'burger', 'sandwich', 'kebab', 'fries', 'donut']}
          indexBy="country"
          colorScheme="paired"
          ariaLabel="Example bar chart"
        />
      </div>
        `}
      </Code>
    </ExampleSection>
  );
}

export default ChartExampleSection;
