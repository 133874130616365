import React, { useState, useEffect } from 'react';
import Bugsnag from '@bugsnag/js';
import Section from './Section';
import agents from '../../../agents/agents';
import Loading from '../../Loading/Loading';
import FormatUtil from '../../../utils/formatUtil';
import AddLink from '../../AddLink/AddLink';
import UpgradeButton from '../../UpgradeButton/UpgradeButton';
import { WarningWithWrapper } from '../Mobile/Warnings';
import { HELP_DESK_LINKS } from '../../../constants';

function openInNewTab(url, id) {
  const w = window.open(url, `launched_${id}`);
  // Set the opener to null (helps with cyberscore)
  w.opener = null;
}

function LabSection({ permalink, userStore, noX, noY }) {
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState(null);
  const [vendorDetails, setVendorDetails] = useState({});
  const [launching, setLaunching] = useState(false);
  const [authorized, setAuthorized] = useState(false);

  // Load up the lab
  useEffect(() => {
    async function init() {
      setLoading(true);
      try {
        const result = await agents.catalog.getContentDescriptionByPermalink(permalink);
        setContent(result);
        const isAuthorized = userStore.checkPermissions(result);
        setAuthorized(isAuthorized);
        // Check to see if we have an activity
      } catch (err) {
        setContent(null);
      }
      setLoading(false);
    }
    init();
  }, [permalink]);

  // get vendor details
  useEffect(() => {
    const hasContentId = content && !!content.id;
    if (hasContentId) {
      agents.catalog
        .getVendorDetails(content.id)
        .then((response) => {
          setVendorDetails(response);
        })
        .catch((error) => setVendorDetails({ error: error.response || error }));
    }
  }, [content]);

  async function launch(e) {
    if (e) e.preventDefault();
    // Launch the activity!
    if (!launching && content) {
      setLaunching(true);
      const { id } = content;
      try {
        const launchResult = await agents.catalog.launch(id);
        const { url } = launchResult;
        openInNewTab(url, id);
        setLaunching(false);
      } catch (err) {
        Bugsnag.notify(err);
      }
    }
  }

  if (loading) {
    return (
      <Section noX={noX}>
        <Loading />
      </Section>
    );
  }
  // The lab could not be loaded for some reason, return null for now
  if (!content) {
    return null;
  }

  const { title, thumbnail_url: thumbnail, duration_seconds: duration, vendor, content_type: contentType } = content;
  const vendorName = vendor ? vendor.name : null;
  const { nice_name: typeName } = contentType || { nice_name: 'Lab' };
  const labType = FormatUtil.formatContentType(typeName);
  const btnText = launching ? 'Launching...' : `Launch ${labType}`;
  const btnClasses = launching ? 'cursor-not-allowed bg-pink-600' : 'cursor-pointer bg-cyb-pink-500';
  const lowercaseType = labType.toLowerCase();
  const { launch_limit: launchLimit, user_attempts: userAttempts, vendor_details: vendorDetailsContent } = vendorDetails;
  const remainingLaunches = launchLimit ? launchLimit - (userAttempts || 0) : null;
  return (
    <Section noX={noX} noY={noY}>
      <WarningWithWrapper vendor={vendorName} type={lowercaseType} className="text-white" title={title} hasALab />
      <div className="hidden flex-wrap p-8 w-full bg-black rounded-sm md:flex">
        <div className="flex flex-col flex-1 justify-between items-center md:flex-row">
          <div className="mx-auto mb-4 w-56 text-white sm:w-96 md:mb-0 md:w-full">
            <img src={`${thumbnail}?w=450`} alt={title} className="w-full" />
          </div>
          <div className="px-4 w-full text-white md:px-6">
            <div className="mb-1 text-xs uppercase">{labType}</div>
            <h3 className="mb-2 text-2xl font-extrabold">{title}</h3>
            {!!vendorName && (
              <h4>
                By
                <span className="font-bold"> {vendorName}</span>
              </h4>
            )}
            <div className="flex flex-col mt-1 text-xs">
              <div className="flex w-full">{!!duration && <p className="mr-4 mb-0">{FormatUtil.hm(duration)}</p>}</div>
              {vendorDetailsContent && <p className="overflow-visible mt-4 mb-0 w-full">{vendorDetailsContent}</p>}
            </div>
            {!authorized ? (
              <>
                <p className="my-4 text-xs italic">
                  This activity requires a Cybrary subscription. Purchase an Individual or Team subscription today to gain immediate access to more than 1,900 self-paced learning
                  activities at just a fraction of the price of a single in-person course or bootcamp.
                </p>
                <UpgradeButton upgradeText="Upgrade Now" />
              </>
            ) : (
              <>
                <p className="my-4 text-xs italic">
                  This {lowercaseType} is provided by a third-party partner and will launch in a new browser tab. It may not meet all accessibility standards. Please see our{' '}
                  <AddLink
                    className="text-cyb-pink-500 hover:text-white underline hover:underline"
                    target="_blank"
                    rel="noopener noreferrer"
                    to={HELP_DESK_LINKS.COMMITMENT_TO_WEB_ACCESSIBILITY}
                  >
                    help center
                  </AddLink>{' '}
                  for details.
                </p>
                <div className="flex flex-col items-center">
                  <button type="button" onClick={launch} className={`${btnClasses} w-full hover:bg-pink-600 text-center px-4 py-2 rounded-sm text-sm font-bold`}>
                    {btnText}
                  </button>
                  {remainingLaunches !== null && (
                    <p className="mt-4 text-sm text-center">
                      {remainingLaunches} / {launchLimit} Launches Remaining
                    </p>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </Section>
  );
}

export default LabSection;
