import React from 'react';

function WizardOutcomeStepperDetails({ outcomeDisplay, practiceTestDisplay }) {
  const classes = practiceTestDisplay ? 'list-disc' : '';
  return (
    <ul>
      <li className={classes}>{outcomeDisplay}</li>
      <li className={classes}>{!!practiceTestDisplay && practiceTestDisplay}</li>
    </ul>
  );
}

export default WizardOutcomeStepperDetails;
