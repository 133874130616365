import { useEffect, useState } from 'react';
import { PADDLE_COMPONENT_ID } from '../../constants';

/**
 * IF YOUR TRYING TO WORK WITH PADDLE, YOU PROBABLY DON'T WANT THIS HOOK.
 * usePaddleJS is simply to mount the Paddle.js library into the DOM in a <script> tag and perform initial setup
 * @see `usePaddleCheckout` For creating new checkouts
 * @see `usePaddlePricing` For getting pricing estimates
 * @see `usePaddleSubscription` For managing exsisting subscription items
 * @param {string} environment The environment to use, either 'sandbox' or 'production'
 * @param {function} eventCallback Callback function for every Paddle.js checkout event.
 * @param {object} settings Settings passed to Paddle.Setup() are default settings, applied to all checkouts opened on a page.
 * @see https://developer.paddle.com/v1/paddlejs/methods/paddle-checkout-open
 */
const usePaddleJS = ({ environment, eventCallback = () => {}, settings = {} }) => {
  const [paddle, setPaddle] = useState(null);
  useEffect(() => {
    if (paddle || typeof window === 'undefined') return;
    // Check if Paddle is already loaded. If so, set it and return
    if (document.getElementById('paddle')) {
      setPaddle(global.Paddle);
      return;
    }
    // No paddle found, so load it by mounting a script tag to the DOM
    const script = document.createElement('script');
    script.src = 'https://cdn.paddle.com/paddle/v2/paddle.js';
    document.body.appendChild(script);
    script.addEventListener('load', () => {
      if (environment) {
        global.Paddle.Environment.set(environment);
      }
      // Settings passed to Paddle.Setup() are default settings, applied to all checkouts opened on a page.
      global.Paddle.Setup({
        seller: parseInt(process.env.REACT_APP_PADDLE_SELLER_ID, 10), // 12345
        pwAuth: process.env.REACT_APP_PADDLE_RETAIN_KEY,
        eventCallback, // event handler
        checkout: {
          settings: {
            displayMode: 'inline',
            theme: 'light',
            locale: 'en',
            frameTarget: PADDLE_COMPONENT_ID,
            frameInitialHeight: 500,
            // Keep these styles here to prevent Paddle from adding their own styles
            frameStyle: 'background-color: transparent; border: none;',
            // Override default settings with any passed in
            ...settings,
          },
        },
      });
      setPaddle(global.Paddle || null);
    });
  }, [environment, paddle]);
  return { paddle };
};

export default usePaddleJS;
