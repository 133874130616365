import React, { useState } from 'react';

export default function ClipboardForm() {
  const [value, setValue] = useState('');

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const sendClipboard = () => {
    const event = new CustomEvent('copyToRemote', { detail: { str: value } });
    window.dispatchEvent(event);
  };

  return (
    <div className="w-full">
      <label htmlFor="clipboardtext" className="block mb-2 w-full text-base font-bold text-gray-800">
        Clipboard
      </label>
      <textarea id="clipboardtext" name="clipboardtext" rows={5} cols={5} className="p-2 w-full bg-blue-50 rounded-xl border border-blue-200" onChange={onChange} value={value} />
      <button className="block py-2 mt-2 w-full text-base font-semibold text-white bg-cyb-pink-600 hover:bg-cyb-pink-500 rounded" type="button" onClick={sendClipboard}>
        Send to System
      </button>
    </div>
  );
}
