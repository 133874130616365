import React, { useState } from 'react';
import { observer, inject } from 'mobx-react';
import moment from 'moment';
import { Fade } from 'react-awesome-reveal';
import useUniqueKey from '../../../../hooks/useUniqueKey';
import DynamicForm from '../../../DynamicForm/DynamicForm';
import { useProfileContext } from '../../../../providers/ProfileProvider';

const defaultImgSrc = `https://images.ctfassets.net/kvf8rpi09wgk/6Z2QVhDP33A5d7EwQgRsfn/8c0d9c5d84e74c3919b2c4817db7d7bc/cert_with_cybrary_logo.png?h=250`;

/**
 * @param {string} certTitle - the title of the certification
 * @param {string} issuedDate - the date the certification was issued
 * @param {string} expiresDate - the date the certification expires
 * @param {string} company - the company that issued the certification (not entirely accurate... we should just deprecate tbh)
 * @param {string} permalink - the permalink to the certification in cert prep collection
 * @param {string} thumbnailUrl - the url to the thumbnail image of the certification
 * @param {array} certOptions - the options for the certification dropdown
 * @param {boolean} isEditable - boolean indicating whether the profile is editable
 * @returns <CertificationCard /> component which contains the edit and delete buttons for the certification
 */

const CertificationCard = inject('commonStore')(
  // we intend to use permalink in a future iteration
  observer(
    ({ commonStore, certTitle, issuedDate, expiresDate, company, /* permalink = '/browse/certification-prep', */ thumbnailUrl = defaultImgSrc, certOptions, isEditable }) => {
      const [isEditing, setIsEditing] = useState(false);
      const { certificationsProfileData, updateProfile } = isEditable ? useProfileContext() : {}; // if isEditable is false, then we don't need the context

      const uniqueId = useUniqueKey();
      const originalIssuedDate = moment(issuedDate, 'YYYY-MM');
      const formattedIssuedDate = originalIssuedDate.format('MMMM YYYY');

      const originalExpiresDate = moment(expiresDate, 'YYYY-MM');
      const formattedExpiresDate = originalExpiresDate.format('MMMM YYYY');

      const handleSubmit = (formValues) => {
        const cleanedFormValues = {
          certId: certOptions.find((cert) => cert.text === formValues.name)?.id,
          company: company || certOptions.find((cert) => cert.text === formValues.name)?.text,
          thumbnailUrl: certOptions.find((cert) => cert.text === formValues.name)?.thumbnailUrl,
          permalink: certOptions.find((cert) => cert.text === formValues.name)?.url,
          name: formValues.name,
          issuedAt: formValues.issuedAt,
          expiresAt: formValues.expiresAt,
        };

        const updatedCertifications = certificationsProfileData.map((cert) => {
          if (cert.name === certTitle) {
            return cleanedFormValues;
          }
          return cert;
        });

        updateProfile('certifications', { certifications: updatedCertifications })
          .then((response) => {
            if (response) {
              commonStore.triggerToast('success', {
                content: `Certifications updated successfully`,
              });
              setIsEditing(false);
            } else {
              commonStore.triggerToast('error', {
                content: `Error updating certifications`,
              });
            }
          })
          .catch((error) => {
            commonStore.triggerToast('error', {
              content: `Error deleting certifications: ${error.message}`,
            });
          });
      };

      const handleDeleteCert = () => {
        try {
          // remove current cert from certifications, then make an api request with the updated array of objects... then update state with the same array of objects
          const updatedCertifications = certificationsProfileData.filter((cert) => cert.name !== certTitle);

          updateProfile('certifications', { certifications: updatedCertifications });
        } catch (error) {
          commonStore.triggerToast('error', {
            content: `Error deleting certifications: ${error.message}`,
          });
        } finally {
          setIsEditing(false);
        }
      };

      const EditCertificationForm = {
        id: 'edit-certification-form',
        name: 'Edit Certification',
        order: ['name', ['issuedAt', 'expiresAt'], ['delete', 'cancel', 'submit']],
        fields: {
          name: {
            type: 'select',
            label: 'Certification Name',
            placeholder: certTitle,
            options: certOptions,
            validations: ['required'],
            defaultValue: certTitle,
          },
          issuedAt: {
            type: 'datePicker',
            label: 'Issued At',
            dateFormat: 'YYYY-MM',
            className: 'w-1/2',
            defaultValue: issuedDate,
            validations: ['required'],
          },
          expiresAt: {
            type: 'datePicker',
            label: 'Expired At',
            dateFormat: 'YYYY-MM',
            defaultValue: expiresDate,
            validations: ['required'],
          },
          delete: {
            type: 'button',
            className: 'mr-2',
            icon: { 'aria-label': 'Delete', name: 'trash', className: 'w-6 h-6 text-gray-600 group-hover:text-red-500' },
            onClick: handleDeleteCert,
          },
          cancel: {
            type: 'button',
            label: 'Cancel',
            className: 'py-3 px-6 ml-auto text-sm font-bold text-cyb-pink-500 rounded-lg border-1 border-cyb-pink-500 mr-4',
            onClick: () => setIsEditing(false),
          },
          submit: {
            type: 'button',
            label: 'Save Changes',
            color: 'pink',
            className: 'py-3 px-6 ml-auto text-sm font-bold rounded-lg',
            buttonClassName: 'bg-green-200',
          },
        },
      };

      return (
        <div className="p-4 mt-8 bg-gray-200 rounded-lg border-1 border-gray-600">
          {!isEditing && (
            <Fade duration={500} damping={0.1} triggerOnce cascade>
              <div id={uniqueId} className="flex flex-col justify-center items-center md:flex-row">
                <img src={thumbnailUrl} alt={certTitle} className="relative mb-8 w-full bg-cyb-pink-500 md:mr-6 md:mb-0 md:w-[240px] md:h-[184px]" />
                <div id="cert-title-and-info" className="flex flex-col justify-center mx-4 mb-8 w-full md:mb-0">
                  <h2 className="text-2xl font-black">{certTitle}</h2>
                  <p className="mt-4 mb-0 text-base">Issued: {formattedIssuedDate}</p>
                  <p className="mt-1 mb-0 text-base">Expires: {formattedExpiresDate}</p>
                </div>
                {!!isEditable && (
                  <div className="md:ml-auto">
                    <button
                      type="button"
                      className="py-3 px-6 mr-4 ml-auto text-sm font-bold text-cyb-pink-500 rounded-lg border-1 border-cyb-pink-500"
                      onClick={() => setIsEditing(true)}
                    >
                      Edit
                    </button>
                  </div>
                )}
              </div>
            </Fade>
          )}
          {!!isEditing && !!isEditable && <DynamicForm id={`edit-certification-form-${uniqueId}`} form={EditCertificationForm} onSubmit={handleSubmit} />}
        </div>
      );
    }
  )
);
export default CertificationCard;
