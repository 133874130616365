import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import ValidationUtil from '../../utils/validationUtil';
import Input from '../../components/FormFields/Input';

const ManagerInviteForm = inject(
  'enterpriseStore',
  'commonStore'
)(
  observer(function ManagerInviteForm({ enterpriseStore, commonStore, orgId }) {
    if (!orgId) {
      return null;
    }
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState(`Don't see the right person? Invite your manager to join the team so they can get you setup.`);

    function sendInvite() {
      return enterpriseStore
        .requestLicense(orgId, null, email)
        .then(() => {
          const content = `Invitation has been sent to ${email}`;
          commonStore.triggerToast('success', { content });
          setMessage(content);
          setEmail('');
        })
        .catch(({ response }) => {
          const content = response?.data?.message || `Something went wrong. Unable to invite ${email}.`;
          commonStore.triggerToast('error', { content });
          setMessage(content);
        });
    }

    return (
      <div className="invite-manager">
        <p>{message}</p>
        <Input
          className="py-2 px-4 mr-4 w-80 rounded border border-gray-400"
          value={email}
          placeholder="name@example.com"
          onChange={(e, input) => {
            // <Input />'s onChange passes { name, value } as the second param, NOT just the value
            setEmail(input.value);
          }}
        />
        <button
          className="py-2.5 px-6 text-sm font-bold leading-5 text-center text-white hover:text-white bg-cyb-pink-500 hover:bg-pink-600 rounded-sm disabled:opacity-50"
          onClick={sendInvite}
          disabled={!ValidationUtil.checkValidEmail(email)}
        >
          Send Invitation
        </button>
      </div>
    );
  })
);

export default ManagerInviteForm;
