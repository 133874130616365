import React, { useMemo } from 'react';
import { Fade } from 'react-awesome-reveal';
import { twMerge } from 'tailwind-merge';

/**
 * Pulsing bar to be used in place of text while loading
 * Acts as a suspense boundary for its children if any are provided. Showing the children when loading is complete.
 * @param {string} className - Tailwind classes to be applied to the bar
 * @param {string} wrapperClassName - Tailwind classes to be applied to the wrapper
 * @param {boolean} isLoading - Whether the text is loading
 * @param {boolean} animateOnReveal - Whether to animate the children when loading is complete. Defaults to true
 * @param {React.ReactNode} children - The children to show when loading is complete
 * @returns {JSX.Element} - the animating loading bar or the children if loading is complete
 */
function LoadingText({ wrapperClassName, className, children, isLoading = true, animateOnReveal = true }) {
  const classes = useMemo(() => twMerge('w-full h-2 bg-slate-300 rounded-full opacity-50', className), [className]);
  const wrapperClasses = useMemo(() => twMerge('flex py-1 animate-pulse', wrapperClassName), [wrapperClassName]);

  if (!isLoading && !!children) {
    if (animateOnReveal) {
      return <Fade triggerOnce>{children}</Fade>;
    }
    return children;
  }

  return (
    <div className={wrapperClasses}>
      <div className={classes} />
    </div>
  );
}

export default LoadingText;
