import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../contexts/UseAuth';
import Container from '../../components/Container/Container';
import Forgot from '../../components/Login/Forgot';
import StatusMessage from '../../components/Login/StatusMessage';
import { bootPendo } from '../../utils/pendoUtil';
import CybraryLogo from '../../components/CybraryLogo/CybraryLogo';

const ForgotPassword = inject('commonStore')(
  observer(({ commonStore }) => {
    const [submitting, setSubmitting] = useState(false);
    const [status, setStatus] = useState(null);
    const { submitForgotPassword, user } = useAuth();

    useEffect(() => {
      commonStore.setPageTitle('Forgot Password | Cybrary');
      // Pendo - Boot anonymously
      bootPendo();
    }, []);

    // Define our logic for submitting email
    const onSubmitForgotPassword = async (data) => {
      setSubmitting(true);
      setStatus(null);
      const { email: providedEmail } = data;
      try {
        await submitForgotPassword({ email: providedEmail });
        setSubmitting(false);
        setStatus({ type: 'success', code: 'forgotPwSuccess' });
      } catch (e) {
        // If the code returned is 406, show 406 error. Otherwise default forgotPwSuccess
        if ((e.response && e.response.status === 406) || e.code === 406) {
          setStatus({ type: 'error', code: 406 });
        } else {
          setStatus({ type: 'success', code: 'forgotPwSuccess' });
        }
        setSubmitting(false);
      }
    };

    // Redirect back to LIHP if already logged in
    if (user) {
      return <Navigate replace to="/" />;
    }

    return (
      <Container className="py-4 mb-8 max-w-screen-xl">
        <div className="mx-auto w-full md:w-120">
          <CybraryLogo />
          {status && <StatusMessage status={status} type={status.type} />}
          <Forgot onSubmit={onSubmitForgotPassword} submitting={submitting} />
        </div>
      </Container>
    );
  })
);

export default ForgotPassword;
