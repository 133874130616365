import React from 'react';

function Maestro({ classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';
  return (
    <svg className={className} {...accessibilityProps} version="1.1" viewBox="0 0 780 500" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M53.41,15h672.18C746.804,15,764,31.834,764,52.6v394.8   
        C764,468.166,746.804,485,725.59,485H53.41C32.197,485,15,468.166,15,447.399V52.6C15,31.834,32.197,15,53.41,15z"
        fill="none"
        stroke="currentColor"
        strokeWidth="30"
      />
      <path
        d="m704.82 250.48c0 105.68-87.876 191.66-195.94 191.66-45.036 0-86.586-14.925-119.72-40.036-33.143 25.111-74.672 40.036-119.73 
        40.036-108.02 0-195.9-85.98-195.9-191.66 0-105.65 87.874-191.62 195.9-191.62 45.054 0 86.583 14.962 119.73 40.049 33.138-25.087 
        74.689-40.049 119.72-40.049 108.06 0 195.94 85.969 195.94 191.62zm-196.2-174.7c-39.736 0-76.417 12.737-106.09 34.225 28.973 
        26.353 49.743 61.17 58.275 100.34h-17.956c-8.374-35.046-27.584-66.039-53.954-89.402-26.352 23.363-45.553 54.355-53.936 
        89.402h-17.947c8.521-39.171 29.302-73.989 58.253-100.34-29.635-21.488-66.351-34.225-106.1-34.225-98.487 0-178.35 78.095-178.35 
        174.46 0 96.363 79.865 174.51 178.35 174.51 39.746 0 76.461-12.745 106.1-34.234-25.3-23.058-44.365-52.511-54.475-85.79h18.357c9.763 
        29.085 27.127 54.769 49.748 74.859 22.674-20.091 40.004-45.774 49.767-74.859h18.351c-10.073 33.279-29.17 62.731-54.482 85.79 
        29.674 21.489 66.354 34.234 106.09 34.234 98.515 0 178.37-78.151 178.37-174.51-1e-3 -96.362-79.855-174.46-178.37-174.46z"
        fill="currentColor"
      />
      <path
        d="m385.59 291.14c-7.247 1.822-14.265 2.719-21.665 2.719-23.595 0-35.917-10.525-35.917-30.674 0-23.481 15.65-40.77 36.929-40.77 
        17.374 0 28.479 9.708 28.479 24.877 0 5.012-0.745 9.915-2.596 16.886h-41.98c-1.518 9.819 5.821 14.142 18.322 14.142 7.482 0 
        14.229-1.302 21.716-4.267l-3.288 17.087zm-11.249-40.452c0-1.519 2.327-12.053-9.807-12.293-6.703 0-11.521 4.381-13.48 
        12.293h23.287zm29.973-4.995c0 8.686 5.002 14.707 16.369 19.142 8.672 3.488 10.04 4.49 10.04 7.609 0 4.348-3.814 6.298-12.341 
        6.262-6.4-0.038-12.248-0.809-19.119-2.681l-3.076 15.805c6.124 1.4 14.733 1.876 22.371 2.028 22.651 0 33.094-7.266 33.094-22.963 
        0-9.423-4.344-14.984-15.138-19.128-8.983-3.5-10.017-4.295-10.017-7.47 0-3.742 3.56-5.638 10.525-5.638 4.208 0 9.973 0.384 15.453 
        1.044l3.052-15.952c-5.556-0.724-14.03-1.336-18.918-1.336-24.044 0-32.37 10.57-32.295 23.278zm-90.461 47.06h-17.634l0.432-7.238c-5.371 
        5.662-12.511 8.344-22.244 8.344-11.484 0-19.378-7.694-19.378-18.734 0-16.802 13.705-26.494 37.239-26.494 2.406 0 5.51 0.145 8.657 
        0.498 0.646-2.27 0.82-3.224 0.82-4.449 0-4.602-3.686-6.289-13.614-6.289-9.753 0.041-16.335 1.456-22.44 3.062l3.003-15.415c10.574-2.61 
        17.5-3.623 25.315-3.623 18.222 0 27.867 6.963 27.867 20.111 0.144 3.493-1.105 10.555-1.733 13.607-0.723 4.497-5.76 30.458-6.29 
        36.62zm-14.86-30.55c-2.219-0.242-3.206-0.31-4.744-0.31-12.031 0-18.095 3.478-18.095 10.402 0 4.386 2.964 7.066 7.602 7.066 8.615 
        1e-3 14.867-7.065 15.237-17.158zm195.27 15.044c-1.464 0.492-3.306 0.687-5.713 0.687-5.011 0-7.201-1.534-7.201-5.093 0-2.219 
        5.931-31.345 5.931-31.345h10.818l2.894-16.752h-10.759l3.205-16.429h-20.972s-9.392 49.652-9.976 53.076c-0.585 3.404-3.251 15.625-2.893 
        18.226 0 9.608 5.888 15.003 16.707 15.003 4.846 0 9.369-0.628 15.139-2.183l2.82-15.19zm93.525 16.611c-23.626 0-33.156-14.046-33.156-31.21 
        0-23.989 16.136-40.234 41.117-40.234 21.699 0 33.221 13.376 33.221 30.54 0 28.468-17.294 40.904-41.182 40.904zm7.128-54.524c-10.624 
        0-17.238 12.306-17.238 23.193 0 9.196 4.502 15.318 11.994 15.223 10.272 0 16.769-9.201 16.769-24.691 
        0-7.044-2.939-13.725-11.525-13.725zm-80.162-15.261c-2.66 21.282-7.399 42.857-11.073 64.143l-0.903 4.676h21.204c7.634-41.174 10.18-52.907 
        26.177-48.763 0.825-2.21 7.708-19.462 7.708-19.462-11.149-4.016-18.348 1.733-24.845 9.835 0.583-3.683 1.688-7.205 
        1.412-10.429h-19.68zm-256.85-14.819h-34.304l-23.2 52.023-1.742-52.023h-32.952l-16.307 84.071h19.144l12.577-64.242 3.501 
        64.242h19.238l28.866-64.636-12.538 64.636h21.089l16.628-84.071z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Maestro;
