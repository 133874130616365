// import Store from 'react-notifications-component';
// import FlagBox from './flag-box';
// import NotificationConfig from './notifications-config';

// TODO: WE CANNOT USE REACT NOTIFICATIONS COMPONENT!!
// It's incompatible with react 17 and the docs for the old version are just straight up wrong.

const FlagNotification = (flag) => {
  // eslint-disable-next-line no-console
  console.log(flag, 'FIX NOTIFICATIONS');
  // Store.addNotification({
  //   ...NotificationConfig,
  //   content: () => <FlagBox {...flag} className="w-full bg-neutral-900 text-gray-200" />,
  // });
};

export default FlagNotification;
