import Bugsnag from '@bugsnag/js';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import withRouter from '../Router/withRouter';
import SearchUtil from '../../utils/searchUtil';
import OverflowCarousel from '../Carousel/OverflowCarousel';
import ContentTransformer from '../../transformers/contentTransformer';
import ContentCard from '../Card/ContentCard';
import AddLink from '../AddLink/AddLink';
import Loading from '../Loading/Loading';

/**
 * Handles loading, management, and display of search results
 * based on content tags and the provided operand
 * @param title Title to use for display
 * @param description Short description about this section's content
 * @param tags Array of tags to filter by
 * @param tagOperand 'AND' | 'OR'
 * @param contentTypes Array of contentTypes to filter by
 * @param contentTypeOperand 'AND' | 'OR'
 * @param searchIndex Algolia index to search. Default: REACT_APP_INSTANTSEARCH_POPULAR_INDEX
 * @param maxCards Limit on how many cards to load in the query
 * @param highlightFirstCard Highlights first card in carousel
 */
const SearchCarousel = inject()(
  observer(
    ({
      navigate,
      title,
      description,
      tags = [],
      tagOperand = 'AND',
      contentTypes = [],
      contentTypeOperand = 'AND',
      searchIndex = process.env.REACT_APP_INSTANTSEARCH_POPULAR_INDEX,
      maxCards = 20,
      highlightFirstCard,
    }) => {
      const [searchItems, setSearchItems] = useState({ data: null, error: null, loading: true });

      const buildCardsFromResults = (results) => {
        return results.hits.map((hit, index) => {
          const data = ContentTransformer.formatDataSources(hit);
          const { id, comingSoon } = data;
          return (
            <div key={data.id} className="flex h-full">
              <ContentCard
                cardProps={{ menuAbove: true }}
                key={id}
                data={data}
                objectID={id}
                queryID={results.queryID}
                positionIdx={index}
                highlightedView={highlightFirstCard && index === 0}
                className="w-full h-auto bg-white sm:w-60"
                isComingSoon={comingSoon}
              />
            </div>
          );
        });
      };

      function Header() {
        return (
          <div className="=md-991:flex-1">
            <h2 className="text-2xl font-black">{title}</h2>
            {description && <div className="my-0 text-sm font-normal text-gray-600">{description}</div>}
          </div>
        );
      }

      function ViewAll() {
        const tagsQueryParams = tags?.map((tag) => `tags_info=${encodeURIComponent(tag)}`).join('&') || '';
        const changeCategoryView = () => navigate(`/browse/refined?${tagsQueryParams}&resultsView=grid&view=${contentTypes[0]?.toLowerCase() || ''}`);
        return (
          <div className="flex justify-end items-end self-end ml-auto w-15 md-991:ml-0">
            <AddLink className="inline-block text-sm text-cyb-pink-500 hover:text-black underline cursor-pointer" onClick={changeCategoryView}>
              View all
            </AddLink>
          </div>
        );
      }

      function NoResultsMessage() {
        return (
          <>
            <Header />
            <div className="px-[31xp] my-8 text-sm">
              <p>{`Sorry, we couldn't find any results related to this search.`}</p>
              <p>Please refresh the page, or try again later.</p>
            </div>
          </>
        );
      }

      useEffect(() => {
        (async () => {
          try {
            let filters = SearchUtil.getDefaultFiltersByCategory();

            // Add tag filters
            if (tags?.length) {
              filters += ` AND ${SearchUtil.buildFilters(
                'tags_info',
                tags.map((tag) => `'${tag}'`), // algolia expects tag to be wrapped in ''
                tagOperand
              )}`;
            }

            // Add content type filters
            if (contentTypes?.length) {
              filters += ` AND ${SearchUtil.buildFilters(
                'content_group',
                contentTypes.map((contentType) => `'${contentType}'`), // algolia expects content_group to be wrapped in ''
                contentTypeOperand
              )}`;
            }
            const results = await SearchUtil.fetchFromAlgolia(searchIndex, { filters, hitsPerPage: maxCards });
            setSearchItems({ loading: false, data: { results } });
          } catch (error) {
            Bugsnag.notify(error);
            setSearchItems({ loading: false, error });
          }
        })();
      }, []);

      if (searchItems.loading) {
        return <Loading message="Loading..." wrapperClassName="py-6" />;
      }

      if (!searchItems.data?.results?.hits?.length) {
        return <NoResultsMessage />;
      }

      // Setting <OverflowCarousel />'s firstColSpan to 3,
      // and cols to 6 allows the first card to be
      // highlighted and behave properly on x-large screens
      return (
        <OverflowCarousel firstColSpan={3} cols={6} wrapperClasses={!description ? 'mb-8 mx-8' : 'mx-8'} omitDivider appendage={<ViewAll />} heading={<Header />}>
          {buildCardsFromResults(searchItems.data.results)}
        </OverflowCarousel>
      );
    }
  )
);

export default withRouter(SearchCarousel);
