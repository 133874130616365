import React, { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

/**
 * A chip component to display small pieces of information
 * @param {string} className - additional classes to add to the component
 * @param {string} color - color of the chip, i.e. 'black', 'red', 'green', etc.
 * @returns <Chip />
 */
function Chip({ children, className, color = 'black' }) {
  const classes = useMemo(() => {
    const classesToUse = `py-1 px-2 text-[8px] font-medium uppercase bg-white rounded border border-${color} text-${color} inline-block items-center justify-center flex`;
    return twMerge(classesToUse, className);
  }, [className, color]);
  return <div className={classes}>{children}</div>;
}

export default Chip;
