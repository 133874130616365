/* eslint-disable no-console */
import React, { useEffect } from 'react';
import IntroScreen from './Assessment/IntroScreen';
import LoadSpinner from '../../Clab/components/Loading/LoadSpinner';
import AssessmentQuestion from './Assessment/AssessmentQuestion';
import { useCybAssessment } from '../../../providers/CybAssessmentProvider';
import IncompleteScreen from './Assessment/IncompleteScreen';

function AssessmentWrapper({ children, generalFrame, bg = 'bg-gray-800' }) {
  return <div className={`flex justify-center items-center ${bg} immersive-2023-lesson-frame ${generalFrame}`}>{children}</div>;
}

function CybAssessment({ generalFrame, setIsPrimarySidebarOpen, id, activity, setDisableSidebar, completeActivity }) {
  const {
    loading,
    starting,
    launched,
    assessment,
    currentIndex,
    currentQuestion,
    loadAssessment,
    startAssessment,
    next,
    previous,
    onUpdate,
    getQuestionName,
    getQuestionValue,
    incompleteSubmission,
    submitting,
    submitted,
    submitAssessment,
  } = useCybAssessment();

  const init = () => {
    setIsPrimarySidebarOpen(false);
    setDisableSidebar(true);
    loadAssessment(id);
  };

  const start = async () => {
    await startAssessment(id);
    setDisableSidebar(false);
    setIsPrimarySidebarOpen(true);
  };

  useEffect(() => {
    init();

    return () => setDisableSidebar(false);
  }, [id]);

  useEffect(() => {
    if (submitting) {
      setIsPrimarySidebarOpen(false);
    }
  }, [submitting]);

  const questions = assessment?.questions;

  if (loading || starting || submitting || submitted) {
    let text = 'Loading';
    if (starting) {
      text = 'Starting...';
    } else if (submitting || submitted) {
      text = 'Submitting...';
    }
    return (
      <div className={`${generalFrame} flex h-full w-full items-center bg-gray-800 text-gray-100`}>
        <LoadSpinner text={text} spinnerClass="h-14 w-14 text-gray-100 text-center" />
      </div>
    );
  }

  if (!launched) {
    return (
      <AssessmentWrapper generalFrame={generalFrame}>
        <IntroScreen start={start} lessonContent={activity?.lesson_content} />
      </AssessmentWrapper>
    );
  }

  if (incompleteSubmission) {
    return (
      <AssessmentWrapper generalFrame={generalFrame}>
        <IncompleteScreen completeActivity={completeActivity} />
      </AssessmentWrapper>
    );
  }

  const name = getQuestionName();
  const currentValue = getQuestionValue();
  const total = questions?.length;
  const isLast = currentIndex === total - 1;
  const nextShouldSubmit = !!questions && isLast;

  // Handle the next as submit button press
  const nextSubmitAction = async () => {
    if (!nextShouldSubmit) {
      return;
    }
    await submitAssessment(() => completeActivity());
  };

  return (
    <AssessmentWrapper generalFrame={generalFrame}>
      <AssessmentQuestion
        question={currentQuestion}
        name={name}
        value={currentValue}
        onUpdate={onUpdate}
        num={currentIndex + 1}
        total={total}
        next={next}
        previous={previous}
        nextShouldSubmit={nextShouldSubmit}
        nextSubmitAction={nextSubmitAction}
      />
    </AssessmentWrapper>
  );
}

export default CybAssessment;
