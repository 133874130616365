import React, { useEffect, useMemo, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Fade } from 'react-awesome-reveal';
import queryString from 'query-string';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Bugsnag from '@bugsnag/js';
import Cookies from 'js-cookie';
import usePaddleCheckout from '../../hooks/checkout/usePaddleCheckout';
import withRouter from '../../components/Router/withRouter';

import {
  CIP_CHECKOUT_DEFAULT_ANNUAL_ITEMS,
  CIP_CHECKOUT_DEFAULT_MONTHLY_ITEMS,
  DEFAULT_COOKIE_SETTINGS,
  PADDLE_COMPONENT_ID,
  TEAMS_CHECKOUT_DEFAULT_ITEMS,
  PADDLE_PRICE_ID_CIP_MONTHLY,
  CHECKOUT_PACKAGE_MAP,
  APP_FORM_IDS,
} from '../../constants';

import OrderSummaryCheckout from './OrderSummary/OrderSummaryCheckout';
import PaymentBadges from './PaymentBadges';
import CreateTeam from '../../components/Checkout/CreateTeam';
import Pricing from './Pricing';
import Loading from '../../components/Loading/Loading';
import Message from '../../components/Message/Message';
import CreateAccount from '../../components/Checkout/CreateAccount';
import PaddleStep from './PaddleStep';
import FailedUpgrade from './FailedUpgrade';
import AddLink from '../../components/AddLink/AddLink';
import agents from '../../agents/agents';

import './Paddle.css';
import PaddleCheckoutHeader from './PaddleCheckoutHeader';

export const CHECKOUT_STEPS = {
  LOADING: 'loading',
  REGISTRATION_DATA: 'registrationData',
  TEAMS_DATA: 'teamsData',
  PAYMENT: 'payment',
  CONFIRMATION: 'confirmation',
  FAILED_UPGRADE: 'failedUpgrade',
};

/**
 * PaddleCheckout iFrame (Paddle's checkout component)
 * @see https://developer.paddle.com/v1/build/checkout/build-branded-inline-checkout
 * @returns <div className={PADDLE_COMPONENT_ID} />
 */
function PaddleCheckoutIFrame() {
  return <div className={PADDLE_COMPONENT_ID} data-coupon="10%OFF" />;
}

/**
 * PaddleCheckout Page
 * @param {boolean} isTeams Whether or not the checkout is for teams. This uses a different checkout flow.
 */
const PaddleCheckout = inject(
  'authStore',
  'userStore',
  'commonStore'
)(
  observer(({ commonStore, authStore, userStore, isTeams = false }) => {
    const {
      paddle,
      registrationData,
      setRegistrationData,
      registrationError,
      checkoutData,
      checkoutLoading,
      checkoutError,
      setCheckoutError,
      setCheckoutLoading,
      startNewCheckout,
      updateCheckoutItems,
      swapCheckoutItems,
      clearCheckoutError,
      isUpdatingExistingTx,
      previewRegisterNewUser,
    } = usePaddleCheckout();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = queryString.parse(location.search);
    const { real_name, name, first_name, last_name, onboarding_data } = userStore?.user || {};

    // Get the checkoutPackage
    const { checkoutPackageId } = useParams();
    const checkoutPackage = CHECKOUT_PACKAGE_MAP[checkoutPackageId];

    // try to get company and admin name from user object
    const adminName = real_name || name || first_name ? `${first_name} ${last_name}` : '';
    const [customData, setCustomData] = useState({ seatCount: 5, adminName, teamName: onboarding_data?.company || '' });
    const [checkoutStep, setCheckoutStep] = useState(null);
    const isPublic = !userStore?.user;

    // @queryParam _ptxn is the transactionId from an email link
    // @queryParam txid is the transactionId from a redirect we created
    const existingTransactionId = queryParams?._ptxn || queryParams?.txid;

    // LEGACY Plan/Package Routing
    // This used query params to determine the plan/package
    // We are keeping it for now for backwards compatibility
    // In the future we will remove this and only use the checkoutPackageId
    const legacyPlanItems = useMemo(() => {
      const isAnnualPlan = ['annual', 'annually'].includes(queryParams?.plan);
      const isMonthlyPlan = ['month', 'monthly'].includes(queryParams?.plan);
      if (isMonthlyPlan) {
        return CIP_CHECKOUT_DEFAULT_MONTHLY_ITEMS;
      }
      if (isAnnualPlan) {
        return CIP_CHECKOUT_DEFAULT_ANNUAL_ITEMS;
      }
      return CIP_CHECKOUT_DEFAULT_MONTHLY_ITEMS;
    }, [queryParams?.plan]);

    /** Initial handler */
    useEffect(() => {
      // ik we could combine these... but sonar made me do it
      const title = `Checkout | Cybrary`;
      const teamsTitle = `Teams Checkout | Cybrary`;
      commonStore.setPageTitle(!isTeams ? title : teamsTitle);
      authStore.fireAttributionEvent();
      commonStore.hidePrimaryNav();

      return () => {
        commonStore.showPrimaryNav();
      };
    }, []);

    /**
     * Handles initial checkout setup
     * If we have an existing transactionId, we need to load the checkout to edit the billing info
     * If we don't have an existing transactionId, we need to start a new checkout
     * If we're not logged in, we need to:
     * - Collect registration data
     * - (Teams Checkouts) Collect team data
     * - Start a new checkout for the anonymous user for CIP or Teams
     * - Complete checkout
     * - Register the user including the transaction_id
     * - Redirect to /verification-code with a redirect to the correct post-purchase checkout page
     * - User verifies and is logged in to the correct post-purchase checkout page
     * The above assumes the backend will provision the user before the user will complete account verification
     * If we're logged in, we need to:
     * - (Teams Checkouts) Collect team data
     * - Start a new checkout for the user for CIP or Teams
     * - Complete checkout
     * - Call /paddle/sync to sync the user's subscription
     * - Refresh the user's data
     * - Redirect to the correct post-purchase checkout page
     */
    const init = () => {
      if (!paddle) return;
      // Logged in ONLY - Edit an Existing Subscription's Billing Info
      if (existingTransactionId) {
        if (!isPublic) {
          startNewCheckout({ transactionId: existingTransactionId });
          setCheckoutStep(CHECKOUT_STEPS.PAYMENT);
          return;
        }
        // Not Logged in trying to edit a subscription. GET 'EM OUTTA HERE!
        try {
          // some browsers have issues with this, so we try/catch it and navigate to login if it fails
          window.location.href = '/login';
        } catch (e) {
          Bugsnag.notify(e);
          navigate('/login');
        }
        return;
      }
      // Logged in - Start New Teams Subscription
      if (!isPublic && isTeams) {
        setCheckoutStep(CHECKOUT_STEPS.TEAMS_DATA);
        return;
      }
      // Logged in - Start New CIP Checkout
      if (!isPublic && !isTeams) {
        // Check if the user has an active subscription
        (async () => {
          // Provide a loading state while we check for an active subscription
          setCheckoutLoading(true);
          const allSubscriptions = await agents.auth.getSubscriptions();

          // Handle existing monthly subscriptions
          const activeMonthlySubscription = allSubscriptions?.filter((sub) => sub?.status === 'active')?.items?.find((item) => item?.price_id === PADDLE_PRICE_ID_CIP_MONTHLY);
          const isCIPCheckout = ['monthly', 'annual'].includes(checkoutPackageId) || !!legacyPlanItems?.priceId;
          if (isCIPCheckout && activeMonthlySubscription) {
            // If the user has an active monthly subscription and is here for a CIP checkout, we need to update the existing subscription instead of starting a new one
            // Bounce them to the update page "/upgrade/update-subscription" preserving any query params and adding an additional one for activeSubscription.id
            const queryParamsString = queryString.stringify({ ...queryParams, subscriptionId: activeMonthlySubscription.subscription_id });
            navigate(`/upgrade/update-subscription?${queryParamsString}`);
            return;
          }

          // Start the checkout with the selected plan/package
          // If a custom package is selected, use the custom package items
          // Otherwise, use the default items for the selected plan
          startNewCheckout({ items: checkoutPackage || legacyPlanItems });
          setCheckoutStep(CHECKOUT_STEPS.PAYMENT);
        })();
        return;
      }
      // Not Logged in - Start New Teams Subscription via Registration
      if (isPublic && isTeams) {
        setCheckoutStep(CHECKOUT_STEPS.REGISTRATION_DATA);
        return;
      }
      // Not Logged in - Start CIP Checkout via Registration
      if (isPublic && !isTeams) {
        setCheckoutStep(CHECKOUT_STEPS.REGISTRATION_DATA);
        return;
      }
      throw new Error('Failed to init checkout - Unhandled Checkout Flow');
    };

    /** Handler to start the checkout process once paddle loads */
    useEffect(() => {
      init();
    }, [paddle]);

    /** If we get a registration error, update the current step */
    useEffect(() => {
      if (!registrationError) return;
      setCheckoutStep(CHECKOUT_STEPS.FAILED_UPGRADE);
    }, [registrationError]);

    /** Handler for starting/updating checkout whenever our external customData changes */
    useEffect(() => {
      // Only start/update checkout here if we are on the Payment Step for Teams Checkouts
      if (checkoutStep !== CHECKOUT_STEPS.PAYMENT || !isTeams) return;

      // For every item in the cart, update the quantity to the new seat qty
      const quantity = customData.seatCount || 1;
      const items = TEAMS_CHECKOUT_DEFAULT_ITEMS.map((item) => ({ ...item, quantity }));

      // If we already have an active checkout, lets update that.
      if (checkoutData?.items?.length > 0) {
        updateCheckoutItems(items);
        return;
      }
      // Otherwise, start a new Teams Checkout once we are done collecting data passing along the customData
      startNewCheckout({ items, customData, email: registrationData.email });
    }, [customData]);

    /** Handler for starting checkout whenever our registerData changes */
    useEffect(() => {
      // Only start/update checkout here if we are on the Payment Step and not a Teams Checkout
      if (checkoutStep !== CHECKOUT_STEPS.PAYMENT || isTeams) return;
      // Otherwise, start a new Teams Checkout once we are done collecting data passing along the customData
      startNewCheckout({ items: checkoutPackage || legacyPlanItems, email: registrationData.email });
    }, [registrationData]);

    /** Handler for the Teams Data form submission */
    const handleCreateTeamFormSubmit = (newCustomData) => {
      // Merge the new data with the existing data
      // This will allow us to chain multiple forms together if we need to collect more data later
      const parsedTeamsData = { ...customData, ...newCustomData };
      // Stash adminName in a cookie for 1 day to use in onboarding
      Cookies.set('cyb_an', parsedTeamsData.adminName, { ...DEFAULT_COOKIE_SETTINGS, expires: 1 });
      delete parsedTeamsData.form_id; // remove form_id from data
      setCustomData(parsedTeamsData); // Updating customData will start/update the paddle checkout
      setCheckoutStep(CHECKOUT_STEPS.PAYMENT);
    };
    /** Handler for the Registration form submission */
    const handleRegistrationFormSubmit = async (newRegistrationData) => {
      // Clear any existing errors
      setCheckoutError(null);
      setCheckoutLoading(true);

      // Merge the new data with the existing data
      // This will allow us to chain multiple forms together if we need to collect more data later
      const parsedRegistrationData = { ...registrationData, ...newRegistrationData };
      delete parsedRegistrationData.form_id; // remove form_id from data

      try {
        // send preview registration data to the backend to validate without actually registering the user
        await previewRegisterNewUser(parsedRegistrationData);
        // If no errors we can continue with the registration
        if (isTeams) {
          setCheckoutStep(CHECKOUT_STEPS.TEAMS_DATA);
        } else {
          setCheckoutStep(CHECKOUT_STEPS.PAYMENT);
        }
        setRegistrationData(parsedRegistrationData);
        setCustomData({ ...customData, anonCheckout: true }); // Updating customData will start/update the paddle checkout
        setCheckoutLoading(false);
      } catch (e) {
        setCheckoutError(e);
        setCheckoutLoading(false);
      }
    };

    /** Handler for the Teams Data form seat qty updates */
    const setPlanQuantity = (val) => setCustomData({ ...customData, seatCount: val });

    const isComplete = checkoutData?.status === 'completed';
    const showPrices = [CHECKOUT_STEPS.PAYMENT, CHECKOUT_STEPS.TEAMS_DATA].includes(checkoutStep) && !isComplete && !isUpdatingExistingTx && checkoutData?.items?.length;
    const planTitle = isTeams ? 'For Teams' : 'Insider Pro';
    const checkoutTitle = `Upgrade To Cybrary ${planTitle}`;
    const updateTitle = `Update Your Cybrary Subscription`;
    // abstract error messages from the response
    const serverErrors = checkoutError?.response?.data?.errors;
    const displayErrorMessages = Object.keys(serverErrors || {})
      .map((key) => `${serverErrors[key]}`)
      .join('<br />');
    return (
      <>
        {/** Title */}
        <PaddleCheckoutHeader>{isUpdatingExistingTx ? updateTitle : checkoutTitle}</PaddleCheckoutHeader>
        <div className="flex flex-col-reverse grow justify-center mx-auto max-w-screen-lg duration-300 ease-in-out md:flex-row">
          <div className="shrink-0 mx-auto w-full max-w-full md:max-w-[400px] lg:max-w-[550px]">
            {/** Loading Display */}
            {checkoutLoading && (
              <div className="flex flex-col justify-center items-center w-full h-full">
                <Loading />
              </div>
            )}
            {/** Error Display */}
            {checkoutError?.message && (
              <Message
                status="error"
                className="mt-0"
                msgBody={displayErrorMessages || checkoutError.message}
                msgHeader={`Error ${checkoutError.status || ''}`}
                isDismissible
                onDismiss={() => clearCheckoutError()}
              />
            )}
            {/** Register Step */}
            <PaddleStep isShown={checkoutStep === CHECKOUT_STEPS.REGISTRATION_DATA && !checkoutLoading} childClasses="m-12">
              <CreateAccount
                formId={APP_FORM_IDS.CHECKOUT.CREATE_ACCOUNT}
                registrationData={registrationData}
                handleSubmit={handleRegistrationFormSubmit}
                serverErrors={serverErrors}
                clearErrorForField={(key) => {
                  const updatedServerErrors = { ...serverErrors };
                  delete updatedServerErrors[key];
                  setCheckoutError(updatedServerErrors);
                }}
                loading={checkoutLoading}
                isCheckout
              />
            </PaddleStep>
            {/** Teams Data Step */}
            {isTeams && (
              <PaddleStep isShown={checkoutStep === CHECKOUT_STEPS.TEAMS_DATA && !checkoutLoading} childClasses="m-12">
                <CreateTeam teamData={customData} handleSubmit={handleCreateTeamFormSubmit} setPlanQuantity={setPlanQuantity} />
              </PaddleStep>
            )}
            {/** Payment Step */}
            <PaddleStep isShown={checkoutStep === CHECKOUT_STEPS.PAYMENT}>
              <div className={`ease-in-out duration-300 ${checkoutLoading ? 'hidden' : ''}`}>
                {/** Paddle checkout frame has a very janky loading animation so we wrap it in an additional fade to smooth it out */}
                <Fade delay={200} triggerOnce>
                  <PaddleCheckoutIFrame />
                  {isTeams && !isComplete && (
                    <button className="mt-2 ml-8 text-cyb-pink-500" onClick={() => setCheckoutStep(CHECKOUT_STEPS.TEAMS_DATA)}>
                      Back
                    </button>
                  )}
                </Fade>
              </div>
            </PaddleStep>
            {/** Failed Upgrade Step */}
            <PaddleStep isShown={checkoutStep === CHECKOUT_STEPS.FAILED_UPGRADE}>
              <FailedUpgrade isTeams={isTeams} />
            </PaddleStep>
          </div>
          {/** Price Info Display */}
          {showPrices && (
            <div className="flex flex-col justify-center px-2 mx-auto mb-0 w-full sm:max-w-screen-sm md:justify-start md:mb-12 lg:m-12">
              <Pricing checkoutData={checkoutData} checkoutLoading={checkoutLoading} swapCheckoutItems={swapCheckoutItems} isTeams={isTeams} />
              <OrderSummaryCheckout
                checkoutData={checkoutData}
                checkoutLoading={checkoutLoading}
                isTeams={isTeams}
                onEditTeamsQty={() => setCheckoutStep(CHECKOUT_STEPS.TEAMS_DATA)}
              />
              <PaymentBadges className="hidden md:block" />
              <p className="py-4 text-xs font-normal text-center text-black">
                If you have any issues with your transaction,
                <br />
                please visit{' '}
                <AddLink to="https://paddle.net/" target="_blank" className="font-bold text-blue-500">
                  Paddle.net
                </AddLink>{' '}
                for assistance.
              </p>
            </div>
          )}
        </div>
      </>
    );
  })
);

export default withRouter(PaddleCheckout);
