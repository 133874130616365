import Bugsnag from '@bugsnag/js';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import Agents from '../../agents/agents';
import Button from '../../components/Button/Button';
import Container from '../../components/Container/Container';
import StatusLegend from '../../components/ContentStatus/StatusLegend';
import withRouter from '../../components/Router/withRouter';
import SearchCarousel from '../../components/SearchCarousel/SearchCarousel';
import SearchGrid from '../../components/SearchGrid/SearchGrid';
import Title from '../../components/Title/Title';

const CVE_TAG = `Cve Series`;
const FWPL_TAG = `Free With Paid Labs`;
const CVESeries = inject(
  'searchStore',
  'userStore'
)(
  observer(({ userStore }) => {
    const [subscription, setSubscription] = useState({ tags: [], loading: true, error: null });
    const hasCveTag = subscription.tags.indexOf(CVE_TAG) !== -1;
    const isFreeUser = !userStore?.user?.is_paid;

    useEffect(() => {
      const getSubscription = async () => {
        const subscriptionData = { ...subscription };
        try {
          const tagSubscription = await Agents.catalog.getTagSubscription();
          subscriptionData.tags = tagSubscription.map((tag) => tag.name);
          subscriptionData.loading = false;
          subscriptionData.error = null;
        } catch (err) {
          Bugsnag.notify(err);
          subscriptionData.tags = [];
          subscriptionData.loading = false;
          subscriptionData.error = err;
        }
        setSubscription(subscriptionData);
      };
      getSubscription();
    }, []);

    const toggleSubscription = async () => {
      setSubscription({ ...subscription, loading: true });

      const tagsWithoutCve = subscription.tags.filter((tag) => tag !== CVE_TAG);
      const data = hasCveTag ? tagsWithoutCve : [...tagsWithoutCve, CVE_TAG];
      const subscriptionData = { ...subscription };

      try {
        await Agents.catalog.setTagSubscription({ tags: data });
        subscriptionData.tags = data;
        subscriptionData.loading = false;
        subscriptionData.error = null;
      } catch (err) {
        Bugsnag.notify(err);
        subscriptionData.loading = false;
        subscriptionData.error = err;
      }
      setSubscription(subscriptionData);
    };

    const subscribeText = hasCveTag ? 'Unsubscribe' : 'Subscribe';
    return (
      <Container size="card-container-v2">
        <Title title="CVE Series" omitPadding wrapperClasses="pt-8 md:pt-12 mb-4" />
        <p className="mb-0 max-w-150 text-sm text-gray-600">
          Reduce risk and keep adversaries out of your environment with hands-on training built around the latest vulnerabilities and exploits.
        </p>
        <div className="justify-between items-center mb-6 sm:flex sm:flex-wrap">
          <div>
            {!subscription.error && (
              <div className="my-4">
                <Button type="button" color="gray" className="mb-3" onClick={toggleSubscription} loading={subscription.loading}>
                  {subscribeText}
                </Button>
                <p className="max-w-150 text-sm text-gray-600 lg:mb-0">Subscribe to receive notifications when CVE content is updated or new content is published.</p>
              </div>
            )}
          </div>
          <StatusLegend hideNoContent />
        </div>
        {isFreeUser && <SearchCarousel tags={[FWPL_TAG, CVE_TAG]} title="Free Courses" />}
        <SearchGrid tags={[CVE_TAG]} />
      </Container>
    );
  })
);

export default withRouter(CVESeries);
