import React, { useState, useEffect } from 'react';
import DropdownContainer from './DropdownContainer';

import TeamsIcon from '../../Icons/TeamsIcon';
import ChevronDown from '../../Icons/ChevronDown';
import FormatUtil from '../../utils/formatUtil';

function TeamsSelections({ teams, switchTeam, logoClasses, mobileNav }) {
  return (
    <>
      {Object.keys(teams).map((teamId, idx) => {
        const currTeam = teams[teamId];
        return (
          <button key={teamId} onClick={() => switchTeam(currTeam, teamId)} className={`py-4 cursor-pointer w-full text-left ${idx > 0 ? 'border-t-1 border-gray-300' : ''}`}>
            <div className="py-1 px-8 hover:bg-gray-50">
              <TeamLogo logo={currTeam.logo_url} classes={logoClasses} />
              <p className={`${!mobileNav ? 'text-sm' : 'text-lg'} align-middle ml-2 mb-0 inline-block hover:text-gray-900 text-gray-600`}>{currTeam.name}</p>
            </div>
          </button>
        );
      })}
    </>
  );
}

function TeamLogo({ logo, classes }) {
  if (!logo) {
    return <TeamsIcon filled inverted classes={`p-1 bg-black ${classes}`} id="-team-logo" />;
  }

  return <img className={classes} src={logo} alt="Team Logo" />;
}

function TeamsDropdown({ userStore, mobileNav }) {
  if (!userStore) {
    return null;
  }

  const [teams, setTeams] = useState(null);
  const [team, setTeam] = useState(null);
  const [teamDropdown, setTeamDropdown] = useState(false);

  useEffect(() => {
    const { teamsWithAdminAccess, adminTeam } = userStore;
    if (!teamsWithAdminAccess || !Object.keys(teamsWithAdminAccess).length) {
      return;
    }
    setTeams(teamsWithAdminAccess);
    setTeam(adminTeam);
  }, []);

  if (!team || !teams) {
    return null;
  }

  const selectedTeam = team;
  const hasMultipleTeams = Object.keys(teams).length > 1;
  const selectorClasses = hasMultipleTeams ? 'cursor-pointer' : '';
  const selectorClickProp = hasMultipleTeams ? { onClick: () => setTeamDropdown(!teamDropdown) } : {};
  const logoClasses = 'w-8 mr-2 border border-gray-300 rounded inline-block';
  const chevronTransformClass = teamDropdown ? 'transform rotate-180' : '';

  // Update to the current team
  const switchTeam = (newTeam, newTeamId) => {
    if (newTeamId !== selectedTeam.id) {
      // We are actually switching
      userStore.setTeam(newTeam);
      userStore.setTeamPermissions(newTeamId);
      userStore.setMetaPreferenceTeam(newTeamId);
      window.location = `/enterprise/${newTeamId}`;
    } else {
      setTeamDropdown(false);
    }
  };

  if (!mobileNav) {
    return (
      <div className="flex relative items-center px-2 secondary-nav-teams">
        <div className={`mr-2 flex items-center ${selectorClasses}`} {...selectorClickProp}>
          <TeamLogo logo={selectedTeam.logo_url} classes={logoClasses} />
          <span className="text-sm font-semibold text-black">{FormatUtil.formatLongText(selectedTeam.name, 35)}</span>
          {hasMultipleTeams && (
            <button className="leading-4" aria-label="Toggle view teams">
              <ChevronDown classes={`inline-block ml-2 w-5 h-5 hover:text-gray-900 text-gray-600 ${chevronTransformClass}`} />
            </button>
          )}
        </div>
        {hasMultipleTeams ? (
          <DropdownContainer classes="left-0 top-12 pt-8 pb-4" width="25rem" visible={teamDropdown} onClose={() => setTeamDropdown(!teamDropdown)}>
            <p className="px-8 mb-4 text-xs uppercase">Teams</p>
            <TeamsSelections switchTeam={switchTeam} teams={teams} logoClasses={logoClasses} />
          </DropdownContainer>
        ) : null}
      </div>
    );
  }

  return (
    <div className="border-b border-gray-300">
      <div className={`pb-6 px-6 ${selectorClasses}`} {...selectorClickProp}>
        <div className="flex relative items-center space-between">
          <div className="flex-1">
            <TeamLogo logo={selectedTeam.logo_url} classes={logoClasses} />
            <span className="text-lg font-semibold text-black align-middle">{FormatUtil.formatLongText(selectedTeam.name, 35)}</span>
          </div>
          {hasMultipleTeams ? (
            <>
              <button aria-label="Toggle view teams">
                <ChevronDown classes={`inline-block w-6 h-6 hover:text-gray-900 text-gray-600 ${chevronTransformClass}`} />
              </button>
              <DropdownContainer classes="left-0 top-10" width="100%" visible={teamDropdown} onClose={() => setTeamDropdown(!teamDropdown)}>
                <TeamsSelections switchTeam={switchTeam} teams={teams} logoClasses={logoClasses} mobileNav />
              </DropdownContainer>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default TeamsDropdown;
