import React, { createContext, useContext, useState, useMemo, useCallback, useEffect } from 'react';
import Bugsnag from '@bugsnag/js';
import agents from '../agents/agents';
import BugsnagUtil from '../utils/bugsnagUtil';
import useSandboxCookie from '../hooks/cookies/useSandboxCookie';
import DEMO_TRACKS from '../pages/Baseline/Demo/Reports/track-scores.json';

const CybAssessmentAnalyticsContext = createContext();

/**
 * Cybrary Assessment Analytics Provider
 * @param {*} children - React children
 * @param {*} userStore - Mobx user store
 * @returns <CybAssessmentAnalyticsProvider.Provider />
 */
function CybAssessmentAnalyticsProvider({ children, userStore }) {
  const { isBaselineSandboxEnabled } = useSandboxCookie();
  const user = useMemo(() => userStore.user, [userStore.user]);
  // index of tracks keyed by user.id
  const [tracks, setTracks] = useState({});
  // index of track assessment attempts history, keyed by `trackId_assessmentId`
  const [assessmentAttempts, setAssessmentAttempts] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null); // Error object from api request
  const [isLoadingAssessmentAttempts, setIsLoadingAssessmentAttempts] = useState(false);
  const [assessmentAttemptsError, setAssessmentAttemptsError] = useState(null);
  // Current track and assessment - stored here to avoid computing this data in components since its shared
  const [currentTrack, setCurrentTrack] = useState(null);
  const [currentAssessment, setCurrentAssessment] = useState(null);

  /**
   * Gets a track by id from the tracks data
   * @param {number} trackId - Track ID
   * @param {number?} userId - user ID
   * @returns {object} - Track and Assessment data { track: {...}, assessment: {...} }
   */
  const getTrackById = useCallback(
    (trackId, userId) => {
      return tracks[userId ?? user?.id]?.find((track) => track['content-id'] === parseInt(trackId, 10));
    },
    [tracks, user]
  );

  /**
   * Gets an assessment by id from the tracks data
   * @param {number} trackId - Track ID
   * @param {number} assessmentId - Assessment ID
   * @param {number?} userId - user ID
   * @returns {object} - Track and Assessment data { track: {...}, assessment: {...} }
   */
  const getAssessmentById = useCallback(
    (trackId, assessmentId, userId) => {
      const track = getTrackById(trackId, userId);
      const assessments = track?.children;
      return assessments?.find((assessment) => assessment['content-id'] === parseInt(assessmentId, 10));
    },
    [tracks, getTrackById]
  );

  /**
   * Helper function to set the current track by id
   * @param {number} trackId - Track ID
   */
  const setCurrentTrackById = useCallback(
    (trackId) => {
      const track = getTrackById(trackId);
      setCurrentTrack(track);
    },
    [getTrackById]
  );

  /**
   * Helper function to set the current track & assessment by id
   * @param {number} trackId - Track ID
   * @param {number?} userId - user ID
   * @param {number} assessmentId - Assessment ID
   */
  const setCurrentAssessmentById = useCallback(
    (trackId, assessmentId, userId) => {
      const track = getTrackById(trackId, userId);
      setCurrentTrack(track);
      const assessment = getAssessmentById(trackId, assessmentId, userId);
      setCurrentAssessment(assessment);
    },
    [getTrackById, getAssessmentById]
  );

  /**
   * set `currentAssessment` to a given assessment attempt
   * @param {number} trackId - Track ID
   * @param {number} userId - User ID
   * @param {object} assessmentAttempt - Assessment attempt payload to insert into `currentAssessment`
   */
  const setCurrentAssessmentAttempt = useCallback(
    (trackId, userId, assessmentAttempt) => {
      const assessment = getAssessmentById(trackId, assessmentAttempt['content-id'], userId);

      if (assessment) {
        setCurrentAssessment(assessmentAttempt);
      }
    },
    [getTrackById, getAssessmentById]
  );

  /**
   * Loads tracks from CASS api for a specific user
   * @param {boolean} params.recent - If true, loads the most recent track results. Otherwise, it loads the best track results
   * @param {number?} params.userId - If present, fetches tracks for specific user ID
   */
  const loadTracks = useCallback(
    async (params) => {
      const userId = params?.userId ?? user?.id;

      if (userId) {
        try {
          if (isBaselineSandboxEnabled) {
            const demoTracks = DEMO_TRACKS.reverse();
            setTracks((prevState) => ({
              ...prevState,
              [userId]: demoTracks,
            }));

            setCurrentTrack(demoTracks[0]); // init current track to first track
          } else {
            setIsLoading(true);
            setError(null);

            const trackResults = await agents.cybAssessmentNew.getAllTracks({ recent: params?.recent });

            const trackResultsOrdered = trackResults.reverse();

            setTracks((prevState) => ({
              ...prevState,
              [userId]: trackResultsOrdered,
            }));

            setCurrentTrack(trackResultsOrdered[0]); // init current track to first track
          }
        } catch (loadTracksError) {
          setError(loadTracksError);
          Bugsnag.notify(loadTracksError);
        } finally {
          setIsLoading(false);
        }
      } else {
        // Debugging - user ID not available argument or found in user store user object?? Not sure why this would happen
        BugsnagUtil.notifyWithNamedMetadata(
          new Error('User ID not available argument or found in CybAssessmentAnalyticsProvider while loading tracks!'),
          'CybAssessmentAnalyticsProvider',
          { user }
        );
      }
    },
    [user, isBaselineSandboxEnabled]
  );

  /**
   * Helper function to get a trackId from a given assessmentId
   * Used in My Learning to create a link to the track results from the assessment course enrollment
   * @param {number} assessmentId - Assessment Course ID
   * @param {number?} userId - user ID
   * @returns {number} - Track ID
   */
  const getTrackIdByAssessmentId = useCallback(
    (assessmentId, userId) => {
      const track = tracks[userId ?? user?.id]?.find((t) => t?.children?.find((child) => child?.['content-id'] === parseInt(assessmentId, 10)));
      return track?.['content-id'];
    },
    [tracks, user]
  );

  /**
   * get or fetch attempts history for a given assessment of a path
   * @param {number} trackId - Track ID
   * @param {number} assessmentId - Assessmemnt ID
   * @returns {object} - Assessment attempts
   */
  const getOwnAssessmentAttempts = useCallback(
    async (trackId, assessmentId, forceReload = false) => {
      try {
        const key = `${trackId}_${assessmentId}`;

        let attempts = assessmentAttempts[key];

        if (!attempts || forceReload) {
          setIsLoadingAssessmentAttempts(true);
          setAssessmentAttemptsError(null);

          attempts = await agents.cybAssessment.getOwnAssessmentAttempts(assessmentId);

          setAssessmentAttempts((prevState) => ({
            ...prevState,
            [key]: attempts,
          }));
        }

        return attempts;
      } catch (getAssessmentAttemptsError) {
        setAssessmentAttemptsError(getAssessmentAttemptsError);
        BugsnagUtil.notify(getAssessmentAttemptsError);
      } finally {
        setIsLoadingAssessmentAttempts(false);
      }

      return null;
    },
    [assessmentAttempts]
  );

  /**
   * get or fetch attempts history for a given assessment of a path for a given user
   * @param {number} trackId - Track ID
   * @param {number} assessmentId - Assessmemnt ID
   * @param {number} userId - User ID
   * @param {number} orgId - Org ID
   * @returns {object} - Assessment attempts
   */
  const getMemberAssessmentAttempts = useCallback(
    async (trackId, assessmentId, userId, orgId) => {
      try {
        const key = `${orgId}_${userId}_${trackId}_${assessmentId}`;

        let attempts = assessmentAttempts[key];

        if (!attempts) {
          setIsLoadingAssessmentAttempts(true);
          setAssessmentAttemptsError(null);

          attempts = await agents.cybAssessment.getMemberAssessmentAttempts(orgId, userId, assessmentId);

          setAssessmentAttempts((prevState) => ({
            ...prevState,
            [key]: attempts,
          }));
        }

        return attempts;
      } catch (getAssessmentAttemptsError) {
        setAssessmentAttemptsError(getAssessmentAttemptsError);
        BugsnagUtil.notify(getAssessmentAttemptsError);
      } finally {
        setIsLoadingAssessmentAttempts(false);
      }

      return null;
    },
    [assessmentAttempts]
  );

  const actions = useMemo(
    () => ({
      getAssessmentById,
      getTrackById,
      setCurrentTrackById,
      setCurrentAssessmentById,
      loadTracks,
      getTrackIdByAssessmentId,
      getOwnAssessmentAttempts,
      getMemberAssessmentAttempts,
      setCurrentAssessmentAttempt,
    }),
    [
      getAssessmentById,
      getTrackById,
      setCurrentTrackById,
      setCurrentAssessmentById,
      loadTracks,
      getTrackIdByAssessmentId,
      getOwnAssessmentAttempts,
      getMemberAssessmentAttempts,
      setCurrentAssessmentAttempt,
    ]
  );

  const value = useMemo(
    () => ({
      // State
      tracks,
      currentTrack,
      currentAssessment,
      error,
      isLoading,
      assessmentAttempts,
      isLoadingAssessmentAttempts,
      assessmentAttemptsError,
      // Actions
      actions,
    }),
    [tracks, currentTrack, currentAssessment, error, isLoading, assessmentAttempts, isLoadingAssessmentAttempts, assessmentAttemptsError, actions]
  );

  // Init tracks on mount
  useEffect(() => {
    // Only load when we have user ID
    if (user?.id) {
      loadTracks();
    }
  }, [user, loadTracks]);

  return <CybAssessmentAnalyticsContext.Provider value={value}>{children}</CybAssessmentAnalyticsContext.Provider>;
}

export const useCybAssessmentAnalytics = () => useContext(CybAssessmentAnalyticsContext);

export default CybAssessmentAnalyticsProvider;
