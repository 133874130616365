import React from 'react';
import FormatUtil from '../../../utils/formatUtil';
import Loading from '../../../components/Loading/Loading';
import OrderSummaryRow from './Sections/OrderSummaryRow';
import OrderSummaryContainer from './Sections/OrderSummaryContainer';
import OrderSummaryDiscountRow from './Sections/OrderSummaryDiscountRow';
import { getAmountDueRowTitle } from './orderSummaryUtil';
import If from '../../../components/If/If';

/**
 * Shows amount due rows for new checkout items
 * Includes edit qty button for teams and any discounts
 * @param {object} item - Item from checkout
 * @param {string} rowClass - Class to apply to row
 * @param {string} code - Discount code
 * @param {string} currency - Currency code
 * @returns {React.ReactNode} - Amount due rows
 */
function AmountDueRows({ item, rowClass, code, currency }) {
  const { totals: priceTotals } = item;
  const formattedDiscount = priceTotals?.discount ? FormatUtil.convertCentsToDollars(priceTotals.discount, currency) : null;
  const hasDiscount = !!parseInt(priceTotals?.discount, 10);

  /** CIP product rows */
  const formattedPrice = FormatUtil.convertCentsToDollars(priceTotals?.subtotal, currency);
  return (
    <>
      <OrderSummaryRow title={getAmountDueRowTitle(item)} value={formattedPrice} className={rowClass} />
      <If condition={hasDiscount}>
        <OrderSummaryDiscountRow discountAmount={formattedDiscount} rowClass={rowClass} code={code} />
      </If>
    </>
  );
}

/**
 * Order summary for New Checkouts
 * @param {boolean} basic - If true, use basic styles
 * @param {object} checkoutData - Data from checkout
 * @param {boolean} checkoutLoading - If true, show loading rows
 * @param {boolean} isSuccessScreenShown - If true, show "total paid today" instead of "total due today"
 * @returns {React.ReactNode} - Order summary
 */
function OrderSummaryUpgrade({ basic, checkoutData, checkoutLoading, isSuccessScreenShown }) {
  // If we have an immediate_transaction we are updating an existing subscription.
  const { immediate_transaction } = checkoutData || {};

  // Return null if we don't have checkoutData or immediate_transaction
  if (!checkoutData || !immediate_transaction) {
    return null;
  }

  const { totals, discount, line_items: items } = immediate_transaction?.details || {};
  const { currency_code } = totals || {};

  const { code } = discount || {};
  const rowClass = basic ? 'border-b bg-none' : 'border bg-white';
  const priceRowClass = basic ? 'font-normal' : 'font-bold';
  const totalPrice = !checkoutLoading ? FormatUtil.convertCentsToDollars(totals?.grand_total || '0.00', currency_code) : <Loading />;
  const totalTax = totals?.tax ? FormatUtil.convertCentsToDollars(totals?.tax, currency_code) : null;
  const creditAmount = checkoutData?.update_summary?.credit?.amount;
  const creditAmountCurrencyCode = checkoutData?.update_summary?.credit?.currency_code;
  const creditAmountFormatted = FormatUtil.convertCentsToDollars(creditAmount, creditAmountCurrencyCode);
  const totalRowTitle = isSuccessScreenShown ? 'Total Paid Today' : 'Total Due Today';

  return (
    <OrderSummaryContainer basic={basic} isLoading={checkoutLoading}>
      {/** Add AmountDueRows for every item on the checkout */}
      {items?.map((item) => (
        <AmountDueRows key={item.price_id} item={item} code={code} rowClass={rowClass} currency={currency_code} checkoutLoading={checkoutLoading} />
      ))}
      {/** Add credit row */}
      {totalTax && <OrderSummaryRow className={`${rowClass}`} title="Prorated Monthly Credit" value={`-${creditAmountFormatted}`} />}
      {/** Add tax row */}
      {totalTax && <OrderSummaryRow className={`${rowClass}`} title="Tax" value={totalTax} />}
      {/** Add total row */}
      <OrderSummaryRow className={`${rowClass} ${priceRowClass}`} title={totalRowTitle} value={totalPrice} />
    </OrderSummaryContainer>
  );
}

export default OrderSummaryUpgrade;
