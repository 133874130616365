import React, { useState } from 'react';
import ActivityLogWrapper from './ActivityLogWrapper';

const getActivityFilters = (events = []) => {
  return events.map((event) => ({ label: event, value: event }));
};

const formatEventName = (data) => {
  return <span className="py-1 px-2 text-cyb-pink-500 rounded border border-cyb-pink-500">{data.value}</span>;
};

const boldColumnValue = (data) => {
  return <span className="font-bold">{data.value}</span>;
};

function TeamOwnerActivityLog({ team }) {
  const [filterOptions, setFilterOptions] = useState([]);

  return (
    <ActivityLogWrapper
      team={team}
      agentObj="enterprise"
      filters={getActivityFilters(filterOptions)}
      setFilterOptions={setFilterOptions}
      formatColumns={[
        {
          method: formatEventName,
          column: 'event',
        },
        {
          method: boldColumnValue,
          column: 'user_name',
        },
        {
          method: boldColumnValue,
          column: 'member_name',
        },
      ]}
      headerMappings={{ User: 'Name', Message: 'Details' }}
      activityLogClasses="mt-8"
      filterType="horizontal"
    />
  );
}

export default TeamOwnerActivityLog;
