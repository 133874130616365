// Provide common functionality related to the catalog, specifically the Algolia catalog.
import algoliasearch from 'algoliasearch';
import algoliasearchHelper from 'algoliasearch-helper';

class CatalogUtil {
  constructor() {
    this.catalogIndex = process.env.REACT_APP_INSTANTSEARCH_CATALOG_INDEX;
    this.courseIndex = process.env.REACT_APP_INSTANTSEARCH_COURSES_INDEX;
    this.curriculumIndex = process.env.REACT_APP_INSTANTSEARCH_CURRICULUM_INDEX;
    this.client = algoliasearch(process.env.REACT_APP_INSTANTSEARCH_APP_ID, process.env.REACT_APP_INSTANTSEARCH_API_KEY);
    this.contentHelper = algoliasearchHelper(this.client, this.catalogIndex);
    this.courseHelper = algoliasearchHelper(this.client, this.courseIndex);
  }

  client = null;

  catalogIndex = null;

  courseIndex = null;

  curriculumIndex = null;

  courseHelper = null;

  contentHelper = null;

  lessonsIdMap = {};

  coursesIdMap = {};

  /**
   * Store an item in the lessons ID map.
   * @param {string} param - the parameter we want to use to key the item
   * @param {any} item - the item we are storing in the map
   */
  setLessonMap(param, item) {
    this.lessonsIdMap[`id_${param}`] = item;
  }

  /**
   * Grab an item from the lessons map and return it.
   * @param {string|integer} id - The id of the item we are retrieving from the map.
   * @returns {any} - the item from the map, undefined if not found.
   */
  getLessonMapItem(id) {
    return this.lessonsIdMap[`id_${id}`];
  }

  /**
   * Store an item in the courses ID map.
   * @param {string} param - the parameter we want to use to key the item
   * @param {any} item - the item we are storing in the map
   */
  setCourseMap(param, item) {
    this.coursesIdMap[`id_${param}`] = item;
  }

  /**
   * Grab an item from the courses map and return it.
   * @param {string|integer} id - The id of the item we are retrieving from the map.
   * @returns {any} - the item from the map, undefined if not found.
   */
  getCourseMapItem(id) {
    return this.coursesIdMap[`id_${id}`];
  }

  /**
   * Build filters string from a key and an array of values
   *
   * @param {string} key - The key that we will use to filter on.
   * @param {Array} values - Array of values we will turn into filter values
   * @returns {string} - The filters string. Looks like "<key>:<value1> OR <key>:<value2>" etc
   */
  buildFilters(key, values) {
    const filters = [];
    for (let i = 0; i < values.length; i++) {
      filters.push(`${key}:${values[i]}`);
    }
    return filters.join(' OR ');
  }

  /**
   * Check if a content type is a 'course' type
   * We have some vendor content types that are treated like courses (contain modules, activities)
   *
   * @param {string} type - Content type
   * @returns {bool}
   */
  checkIfCourseType(type) {
    const courseTypes = ['Practice Labs Lab', 'Next Tech Course', 'Avatao Course'];
    return courseTypes.indexOf(type) > -1;
  }
}

export default new CatalogUtil();
