import React, { useState, useMemo, useCallback, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import AssessmentPathOverview from './AssessmentPathOverview';
import AssessmentPathLearners from './AssessmentPathLearners';
import useQueryParams from '../../hooks/useQueryParams';
import { useCybAssessmentPath } from '../../providers/CybAssessmentPathProvider';
import { useCybAssessmentPathContent } from '../../providers/CybAssessmentPathContentProvider';

const ACTIVE_CLASSES = 'border-cyb-pink-500 font-semibold text-black';
const INACTIVE_CLASSES = 'border-slate-200 cursor-pointer';

/**
 * `AssessmentPathTabs` - content for `overview` tab in `AssessmentPathResults` and `AssessmentResults` pages.
 * Used in `AssessmentPathTabs`.
 * @param {string} orgId - team/organization id
 * @param {boolean} asAssessment - tabs to be used for `AssessmentResults` page
 * @returns JSX.Element
 */
function AssessmentPathTabs({ orgId, asAssessment }) {
  const navigate = useNavigate();

  const { tab: tabQueryParam } = useQueryParams();

  const { isLoadingTopicBreakdown, isLoadingLearners, topicBreakdownError, learnersError } = useCybAssessmentPath();

  const { isLoadingAssessmentLearners, assessmentLearnersError } = useCybAssessmentPathContent();

  const tabs = useMemo(
    () => [
      {
        id: 1,
        title: 'Overview',
        content: <AssessmentPathOverview orgId={orgId} asAssessment={asAssessment} />,
      },
      {
        id: 2,
        title: 'Learners',
        content: <AssessmentPathLearners orgId={orgId} asAssessment={asAssessment} />,
      },
    ],
    [orgId, asAssessment]
  );

  const DEFAULT_ACTIVE_TAB = useMemo(() => {
    if (tabQueryParam === 'learners') {
      return tabs[1].id;
    }
    return tabs[0].id;
  }, [tabs, tabQueryParam]);

  const [activeTab, setActiveTab] = useState(DEFAULT_ACTIVE_TAB);

  const content = useMemo(() => tabs[activeTab - 1].content, [activeTab]);

  const minHeight = useMemo(() => {
    const busyCondition = activeTab === 1 && (isLoadingTopicBreakdown || topicBreakdownError);
    const busyCondition2 = activeTab === 2 && (isLoadingLearners || isLoadingAssessmentLearners || learnersError || assessmentLearnersError);

    if (busyCondition || busyCondition2) {
      return ' min-h-[200px]';
    }

    return '';
  }, [activeTab, isLoadingTopicBreakdown, isLoadingLearners, isLoadingAssessmentLearners, topicBreakdownError, learnersError, assessmentLearnersError]);

  const marginTop = useMemo(() => (asAssessment ? 'mt-6' : 'mt-3'), [asAssessment]);

  const onClickTab = useCallback(
    (id) => {
      if (tabQueryParam) {
        // remove `tab` query param
        navigate(window.location.pathname, { replace: true });
      }

      setActiveTab(id);
    },
    [tabQueryParam]
  );

  return (
    <div className={`flex flex-col items-start ${marginTop} w-full${minHeight}`}>
      {/** Tab Navigation */}
      <div className="relative self-start pt-4 w-full lg:flex">
        <nav className="flex z-10 w-full">
          {tabs.map(({ id, title }) => (
            <button
              key={id}
              aria-pressed={activeTab === id}
              aria-label={`View ${title} content`}
              className={`inline-block text-sm px-4 py-2 border-b-2 ${activeTab === id ? ACTIVE_CLASSES : INACTIVE_CLASSES}`}
              onClick={() => onClickTab(id)}
            >
              {title}
            </button>
          ))}
        </nav>
        <div className="absolute bottom-0 w-full h-[2px] bg-slate-200 z-9" />
      </div>
      {/** Tab Content */}
      {content}
    </div>
  );
}

export default memo(AssessmentPathTabs);
