import React, { useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { inject, observer } from 'mobx-react';
import DynamicForm from '../DynamicForm/DynamicForm';
import { copyToClipboard } from '../../utils/actionsUtil';

/**
 * Builds the form object for the invite link form
 * @param {string} code - referral code
 * @param {boolean} isCopied - flag for if the invite link has been copied
 * @returns {Object} - form object for the invite link form
 */
const getInviteLinkForm = ({ code, isCopied }) => {
  const referralURL = `${process.env.REACT_APP_V2_SITE_URL}?referral=${code}&source=link`;
  return {
    name: 'Get Invite Link',
    order: ['link', 'copy'],
    fields: {
      link: {
        type: 'text',
        defaultValue: referralURL,
        value: referralURL,
        disabled: true,
        className: twMerge('w-full px-6 py-3 mt-0 text-md font-medium mx-auto rounded-md border-4 min-w-3/4', isCopied ? 'border border-cyb-pink-500 border-4' : ''),
      },
      copy: {
        type: 'button',
        color: 'pink',
        label: isCopied ? 'Link Copied!' : 'Copy link',
        className: 'flex justify-center w-full px-6 py-3 text-sm md-991:w-auto mx-auto',
      },
    },
  };
};

const CopyInviteLinkForm = inject('userStore')(
  observer(({ userStore }) => {
    const [isCopied, setIsCopied] = useState(false); // flag for if the invite link has been copied
    const { user } = userStore;
    const { invite_token } = user;

    /**
     * Handle copy invite link form submission
     * @param {FormData} data - form data from the dynamic form submission
     */
    const handleCopyInviteLink = async (data) => {
      await copyToClipboard(data.link);
      // set copied state to true for 5 seconds
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 5000);
    };

    return <DynamicForm form={getInviteLinkForm({ code: invite_token, isCopied })} onSubmit={(data) => handleCopyInviteLink(data)} customClassName="mt-1" />;
  })
);

export default CopyInviteLinkForm;
