import React from 'react';
import WidgetContainer from '../Container/WidgetContainer';
import Header from '../Header/Header';
import If from '../If/If';
import Card from '../Card/Card';
import Image from '../Image/Image';
import LoadingText from '../Loading/LoadingText';
import Chip from '../Chip/Chip';
import AddLink from '../AddLink/AddLink';
import { useABM } from '../../providers/ABMProvider';
import LockedContainer from '../Container/LockedContainer';
import PermalinkUtil from '../../utils/permalinkUtil';
import Button from '../Button/Button';

/**
 * Loading Card for Top Enrollments Widget
 * @returns {JSX} - The Loading Card
 */
function LoadingCard() {
  return (
    <Card inlineContent stackDisplay>
      <LoadingText wrapperClassName="w-1/5" />
      <LoadingText wrapperClassName="w-4/5" />
    </Card>
  );
}

function TopEnrollmentsWidget() {
  const { isABMTeamLoading, isVerified, abmTeam } = useABM();
  const { dashboardData } = abmTeam || {};
  const { top_enrollments } = dashboardData || {};

  return (
    <LockedContainer msg="Verify your company email to unlock" isLocked={!isVerified && !isABMTeamLoading} isFullWidth>
      <WidgetContainer>
        <Header as="h2" className="mb-4">
          Top Enrollments at Your Organization
        </Header>
        {/* Loading State */}
        <If condition={isABMTeamLoading}>
          <LoadingCard />
          <LoadingCard />
          <LoadingCard />
        </If>
        {/* Empty State */}
        <If condition={!top_enrollments?.length && !isABMTeamLoading}>
          <Card>
            <p>No Enrollments Yet</p>
          </Card>
        </If>
        {/* Default State */}
        <If condition={top_enrollments?.length && !isABMTeamLoading}>
          {top_enrollments?.map(
            ({
              content_description_id,
              content_description_title,
              content_description_type,
              content_description_type_nice_name,
              content_description_permalink,
              content_description_image,
            }) => (
              <AddLink to={PermalinkUtil.formatInApp(content_description_permalink, content_description_type)} key={content_description_id}>
                <Card inlineContent inlineDisplay stackDisplay>
                  <Card.Section className="mr-2 w-24 ">
                    <Image src={content_description_image} alt={content_description_title} className="my-0 w-auto max-h-16" />
                  </Card.Section>
                  <Card.Section className="grow">
                    <div>
                      <Chip>{content_description_type_nice_name}</Chip>
                    </div>
                    <Header as="h3" className="grow mb-0">
                      {content_description_title}
                    </Header>
                  </Card.Section>
                  <Button color="gray" icon={{ name: 'chevron-right', className: 'w-4 h-4' }} className="px-3" />
                </Card>
              </AddLink>
            )
          )}
        </If>
        {/* Error State? */}
      </WidgetContainer>
    </LockedContainer>
  );
}

export default TopEnrollmentsWidget;
