import React from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import Icon from '../Icon/Icon';
import Loading from '../Loading/Loading';
import StyledError from '../Error/StyledError';
// eslint-disable-next-line import/no-cycle
import NestedContentTables from '../AdminTable/NestedContentTables';
import AddLink from '../AddLink/AddLink';
import FormatUtil from '../../utils/formatUtil';
import CatalogUtil from '../../utils/catalogUtil';
import Container from '../Container/Container';

export default observer(
  class ExpandingContentTableDetails extends React.Component {
    checkIsCompleteAssessment = (contentType, progress) => {
      const modalityType = FormatUtil.formatContentType(contentType);
      return ['Assessment', 'Practice Test'].indexOf(modalityType) > -1 && 1 * progress === 100;
    };

    checkExpandTable = (row, headings, parentContentType) => {
      const typeIdx = FormatUtil.getColIndex(headings, 'content_description_type');
      const contentType = typeIdx > -1 ? row[typeIdx].value : null;
      const progressIdx = FormatUtil.getColIndex(headings, 'progress');
      const progress = progressIdx > -1 ? row[progressIdx].value : null;
      const isCompleteAssessment = this.checkIsCompleteAssessment(contentType, progress);
      if (
        ((contentType === 'Course Module' || contentType === 'Course' || CatalogUtil.checkIfCourseType(contentType) || contentType === 'Career Path') &&
          this.props.expandFunction) ||
        // We only want to show the score breakdown/expansion when inside a collection
        ((parentContentType === 'Career Path' || parentContentType === 'Curriculum') && isCompleteAssessment && this.props.getAllScores)
      ) {
        return true;
      }
      return false;
    };

    showRowExpansion = (row, headings, parentContentType) => {
      if (this.checkExpandTable(row, headings, parentContentType)) {
        const typeIdx = FormatUtil.getColIndex(headings, 'content_description_type');
        const contentType = typeIdx ? row[typeIdx].value : null;
        const progressIdx = FormatUtil.getColIndex(headings, 'progress');
        const progress = progressIdx ? row[progressIdx].value : null;
        const isCompleteAssessment = this.checkIsCompleteAssessment(contentType, progress);
        const isParentCollection = parentContentType === 'Career Path' || parentContentType === 'Curriculum';
        const positionIdx = FormatUtil.getColIndex(headings, 'position'); // Get's the parent level idx when getting expanded children rows.
        const positionValue = row[positionIdx] ? row[positionIdx].value : null;
        // We only want to show the score breakdown/expansion when inside a collection
        if (isParentCollection && isCompleteAssessment) {
          const tableProps = {
            getMethod: (data) => this.props.getAllScores(data.content_description_id),
          };
          return this.getScoreBreakdownTable(contentType, tableProps, row, headings);
        }
        const tableProps = {
          getMethod: this.props.expandFunction,
          omitHeadings: true,
        };
        return this.getNestedTable(this.props.enrollment, null, row, headings, tableProps, positionValue);
      }
      return null;
    };

    getScoreBreakdownTable = (contentType, tableProps, row, headings) => {
      const contentDescriptionIdIdx = FormatUtil.getColIndex(headings, 'content_description_id');
      const contentDescriptionId = contentDescriptionIdIdx > -1 ? row[contentDescriptionIdIdx].value : null;
      return (
        <NestedContentTables
          {...tableProps}
          tableKey={this.props.tableKey}
          displayCheckBox={false}
          contentDescriptionId={contentDescriptionId}
          getWorkroleIcons={this.props.getWorkroleIcons}
          parentContentType={contentType}
          formatDate={this.props.formatDate}
        />
      );
    };

    getNestedTable = (enrollment, contentId, row, headings, tableProps, positionValue) => {
      const { topLevelEnrollment } = this.props;
      let contentDescriptionId = contentId || null; // Used for expanding tables and getting contents within
      let enrollmentId = enrollment ? enrollment.id : null; // Also used for expanding tables and getting contents within
      const contentTypeIdx = headings ? FormatUtil.getColIndex(headings, 'content_description_type') : -1;
      let contentType = enrollment ? enrollment.content_description_type : null;
      // If this is a child nested table, the row obj will be present - Use that enrollmentId and content_descriptionID
      if (row) {
        // Value could be null - That's OK - It will just get the child items without any progress (aren't enrolled in the module yet)
        enrollmentId = row[FormatUtil.getColIndex(headings, 'id')] ? row[FormatUtil.getColIndex(headings, 'id')].value : enrollmentId;
        contentDescriptionId = row[FormatUtil.getColIndex(headings, 'content_description_id')]
          ? row[FormatUtil.getColIndex(headings, 'content_description_id')].value
          : contentDescriptionId;
        contentType = !!contentTypeIdx && contentTypeIdx > -1 ? row[contentTypeIdx].value : contentType;
      } else if (!!enrollment && 'enrollment_id' in enrollment) {
        // For assignments, the enrollment ID is in enrollment.enrollment_id. It's possible for the value to be null (Assignments assigned to user that hasn't been started).
        // We want to know that and pass the null value up though so it hits a different endpoint to just get the collection contents without progress
        enrollmentId = enrollment.enrollment_id; // Will be an ID or null
      }
      // Used for launching - passed up for the enroll/launch route
      // Top level so immersive is scope to whole collection
      let parentEnrollmentId = topLevelEnrollment ? topLevelEnrollment.id : null;
      // This is in own if because this should occur if the obj.key exists regardless of if row is defined or not
      // If an assignment, the enrollment id is in 'enrollment_id' whereas other content it is in 'id'
      if (topLevelEnrollment && 'enrollment_id' in topLevelEnrollment) {
        parentEnrollmentId = topLevelEnrollment.enrollment_id; // Will either be an ID or null
      }
      return (
        <NestedContentTables
          {...this.props}
          {...tableProps}
          // If this iaView prop is initially passed, and this is a collection, iaView is true in NestedContentTables
          // Tells component to display the iaView rather than standard table
          iaView={this.props.iaView && (contentType === 'Career Path' || contentType === 'Curriculum')}
          tableKey={this.props.tableKey}
          displayCheckBox={false}
          enrollmentId={enrollmentId}
          parentEnrollmentId={parentEnrollmentId}
          parentContentType={contentType}
          getWorkroleIcons={this.props.getWorkroleIcons}
          topLevelEnrollment={topLevelEnrollment}
          enrollmentCompletedAt={!!enrollment && enrollment.completed_at ? enrollment.completed_at : null}
          contentDescriptionId={contentDescriptionId}
          fillEmptyCols="-"
          parentIdx={positionValue}
          checkCerts={this.props.checkCerts}
          formatDate={this.props.formatDate}
          learningHoursCalc={this.props.learningHoursCalc}
          progressFormat={this.props.progressFormat}
          titleTagFormat={this.props.titleTagFormat}
          clickToExpand={{
            condition: (tableRow, columns) => this.checkExpandTable(tableRow, columns, contentType),
            method: (tableRow, columns) => this.showRowExpansion(tableRow, columns, contentType),
          }}
        />
      );
    };

    render() {
      const { enrollment, loading, error, contentDescriptionId, isArchived, iaView } = this.props;
      const isComplete = !!enrollment && !!enrollment.completed_at;
      if (loading) {
        return (
          <Container>
            <Loading message="Loading..." />
          </Container>
        );
      }
      if (error) {
        return (
          <Container>
            <StyledError error={error} />
          </Container>
        );
      }
      if (!enrollment) {
        return null;
      }
      const curriculumLink = this.props.omitCurriculaLink ? '' : `/enterprise/${this.props.orgId}/paths/show/${enrollment.content_description_id}`;
      if (iaView) {
        // If this is new IA View - Don't need the details section, just display the nested tables
        return (
          <>
            {this.getNestedTable(enrollment, contentDescriptionId, null, null, {
              getMethod: this.props.expandFunction,
              showSummary: this.props.showSummary,
            })}
          </>
        );
      }
      const iconClasses = 'inline-block mr-1 w-4 h-4';
      return (
        <>
          <div className="p-2 text-sm expanded-assignments-heading">
            {!this.props.hideCurriculaDetails && enrollment.content_description_title && (
              <div className="mb-2 ml-2 w-full">
                <AddLink className="text-cyb-pink-500 hover:text-black underline" to={curriculumLink}>
                  <Icon name="newspaper" className={iconClasses} />
                  {enrollment.content_description_title}
                </AddLink>
              </div>
            )}
            <div className="flex mb-2 ml-2 ">
              <div className="w-1/2">
                {enrollment.item_count} Item
                {enrollment.item_count > 1 ? 's' : ''}
              </div>
              <div className="w-1/2">{enrollment.due_date ? `Due: ${moment(enrollment.due_date).format('M/D/YY')}` : null}</div>
            </div>
            <div className="flex mb-2 ml-2 ">
              {enrollment.learning_seconds_total && enrollment.learning_seconds_completed && (
                <div className="w-1/2">{`Estimated time remaining: ${this.props.learningHoursCalc(
                  enrollment.learning_seconds_total - enrollment.learning_seconds_completed,
                  false,
                  1
                )} hours`}</div>
              )}
              {enrollment.learning_seconds_total && <div className="w-1/2">{`Total time: ${this.props.learningHoursCalc(enrollment.learning_seconds_total, false, 1)} hours`}</div>}
            </div>
            <div>
              <div>
                {this.props.unenrollFunction && !isComplete && (
                  <button
                    className="inline-block"
                    style={{ color: '#db2828' }}
                    onClick={() => this.props.unenrollFunction(enrollment.content_description_id, enrollment.content_description_type, this.props.tableKey)}
                  >
                    <Icon name="x" className={iconClasses} />
                    Unenroll
                  </button>
                )}
                {this.props.retakeFunction && isComplete && !isArchived && (
                  <button
                    className="inline-block"
                    style={{ color: '#0db350' }}
                    onClick={() => this.props.retakeFunction(contentDescriptionId, enrollment.content_description_type)}
                  >
                    <Icon name="refresh" className={iconClasses} />
                    Retake
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="expanded-assignments-table">
            {this.getNestedTable(enrollment, contentDescriptionId, null, null, {
              getMethod: this.props.expandFunction,
              showSummary: this.props.showSummary,
            })}
          </div>
        </>
      );
    }
  }
);
