import React from 'react';
import Label from './Label';

function BetaLabel({ className, labelKey }) {
  const classes = 'border-xs border-black rounded-sm text-xs p-1';
  return (
    <Label color="black" className={`${classes} ${className}`} key={labelKey || 'beta'}>
      Beta
    </Label>
  );
}

export default BetaLabel;
