import React from 'react';

export default function Fire({ classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';
  return (
    <svg className={className} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...accessibilityProps}>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M1.5641 2.75498H1.71343L2.78294 4.15876C3.18797 2.86186 3.13599 1.45535 2.63645 0.195312C3.56763 0.244527 4.47115 0.544131 5.26036 1.06539C6.04958 1.58666 6.69801 2.31209 7.14347 3.17213L8.11343 2.18002C8.68014 2.78749 9.0705 3.55176 9.2389 4.38354C9.4073 5.21531 9.34678 6.08023 9.06441 6.87723C8.78205 7.67423 8.2895 8.37039 7.64432 8.88436C6.99915 9.39833 6.22799 9.70889 5.42097 9.77973C4.61394 9.85058 3.80438 9.67879 3.08689 9.28445C2.36939 8.89011 1.77359 8.28949 1.36911 7.55279C0.964635 6.81609 0.768181 5.97373 0.802709 5.12414C0.837236 4.27455 1.10132 3.45282 1.5641 2.75498Z"
        fill="currentColor"
      />
    </svg>
  );
}
