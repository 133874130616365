import React from 'react';
import ExampleSection from './ExampleSection';
import Header from '../../../components/Header/Header';
import Code from '../../../components/Code/Code';

function HeadersExampleSection() {
  return (
    <ExampleSection title="Headers" description="Header component for page titles">
      <Header as="h1">Header 1</Header>
      <Header as="h2">Header 2</Header>
      <Header as="h3">Header 3</Header>
      <Header as="h4">Header 4</Header>
      <Header as="h5">Header 5</Header>
      <Header as="h6">Header 6</Header>
      <Code>
        {`
    <Header as="h1">Header 1</Header>
    <Header as="h2">Header 2</Header>
    <Header as="h3">Header 3</Header>
    <Header as="h4">Header 4</Header>
    <Header as="h5">Header 5</Header>
    <Header as="h6">Header 6</Header>
      `}
      </Code>
    </ExampleSection>
  );
}

export default HeadersExampleSection;
