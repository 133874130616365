import React from 'react';
import AddLink from '../../components/AddLink/AddLink';

function CybraryAdminLogo() {
  return (
    <AddLink to="/admin" aria-label="Home" className="block">
      <img
        className="mx-auto"
        src="https://images.ctfassets.net/kvf8rpi09wgk/6HtXDpiYT7GMU8yp0DS4Dy/5b42c213353afb410ac8e52e7db7c77f/cybrary_logo_black.svg"
        alt="Cybrary"
        width="240"
        height="62"
      />
    </AddLink>
  );
}

export default CybraryAdminLogo;
