import Bugsnag from '@bugsnag/js';
import { action, observable, makeObservable } from 'mobx';
import Agents from '../agents/agents';

class AwardsStore {
  checklists = {
    loading: true,
    data: null,
    error: false,
  };

  isChecklistDismissed = false;

  setChecklistLoading = (loading) => {
    this.checklists.loading = loading;
  };

  setChecklistError = (error) => {
    this.checklists.error = error;
  };

  dismissChecklist = (checklist) => {
    return Agents.checklist
      .hideChecklist(checklist)
      .then(
        action('fetchSuccess', (response) => {
          this.isChecklistDismissed = response;
        })
      )
      .catch((error) => {
        Bugsnag.notify(error);
      });
  };

  postChecklistItem = (listName, item) => {
    if (!this.getChecklistByName(listName)) {
      return null;
    }
    return Agents.checklist
      .postChecklistItem(listName, item)
      .then(
        action('fetchSuccess', (response) => {
          return response;
        })
      )
      .catch((error) => {
        Bugsnag.notify(error);
      });
  };

  getAllChecklists = () => {
    this.setChecklistLoading(true);
    return Agents.checklist
      .getAllChecklists()
      .then(
        action('fetchSuccess', (response) => {
          // Success. Set checklist data
          this.checklists.data = response;
          this.setChecklistLoading(false);
        })
      )
      .catch((error) => {
        Bugsnag.notify(error);
        this.setChecklistError(error);
        this.setChecklistLoading(false);
      });
  };

  getChecklistByName = (name) => {
    if (this.checklists.data && this.checklists.data.length && name) {
      const filteredChecklists = this.checklists.data.filter((list) => list.name === name);
      return filteredChecklists[0];
    }
    return null;
  };

  checklistItemIconMap = {
    complete_orientation_course: 'hand-wave',
    visit_community_page: 'community',
    enroll_in_first_course: 'rocket',
    schedule_learning: 'calendar',
    add_learner_goal: 'goal-flag',
  };

  getIconForChecklistItem = (item) => {
    return this.checklistItemIconMap[item] || 'circle';
  };

  constructor() {
    makeObservable(this, {
      checklists: observable,
      isChecklistDismissed: observable,
      setChecklistLoading: action,
      dismissChecklist: action,
      postChecklistItem: action,
      getAllChecklists: action,
    });
  }
}

export default new AwardsStore();
