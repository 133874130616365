/* eslint-disable no-console */
import React from 'react';
import { useCybAssessment } from '../../../../providers/CybAssessmentProvider';

function IncompleteScreen({ completeActivity }) {
  const { setIncompleteSubmission, submitAssessment } = useCybAssessment();

  const handleSubmit = async () => {
    await submitAssessment(() => completeActivity(), true);
  };

  const submitBtnClasses = 'cursor-pointer bg-gray-300 rounded-md font-semibold py-3 px-10 hover:bg-gray-400 text-gray-800 text-center text-base';
  const resumeBtnClasses = 'cursor-pointer bg-cyb-pink-500 rounded-md font-semibold py-3 px-10 hover:bg-pink-600 min-w-[200px] text-white text-center text-base';
  return (
    <div className="mx-auto w-full max-w-2xl text-gray-900 bg-white">
      <div className="py-10 px-8">
        <p className="mb-8 text-5xl font-black text-center">You&apos;re Not Done Yet</p>
        <p className="mb-6 text-lg font-bold text-center">
          It looks like you still have some
          <br />
          incomplete questions.
        </p>
        <p className="mb-6 text-lg text-center">Would you like to submit your Assessment anyway?</p>
        <div className="flex justify-between mt-10">
          <button type="button" onClick={handleSubmit} className={submitBtnClasses}>
            Submit Assessment
          </button>
          <button type="button" onClick={() => setIncompleteSubmission(false)} className={resumeBtnClasses}>
            Resume Assessment
          </button>
        </div>
      </div>
    </div>
  );
}

export default IncompleteScreen;
