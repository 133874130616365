import React from 'react';
import Icon from '../Icon/Icon';

function RecurringGoalIcon() {
  return (
    <>
      <span className="sr-only">This goal is set to recur</span>
      <Icon name="refresh" className="ml-2 w-4 h-4 text-cyb-blue-500" />
    </>
  );
}

export default RecurringGoalIcon;
