import React from 'react';

function PaddleCheckoutHeader({ title, children }) {
  return (
    <div className="px-2 pb-2 text-center">
      <h1 className="mb-2 text-3xl font-black lg:mb-6 lg:text-4xl">
        {title}
        {children}
      </h1>
    </div>
  );
}

export default PaddleCheckoutHeader;
