import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import Container from '../../components/Container/Container';
import Loading from '../../components/Loading/Loading';
import Title from '../../components/Title/Title';
import ContentCard from '../../components/Card/ContentCard';
import ContentTransformer from '../../transformers/contentTransformer';

import { useBackendRecommendedContent } from '../../hooks/useRecommendedContent';
import If from '../../components/If/If';
import StyledError from '../../components/Error/StyledError';
import { trackSnowplowEvent } from '../../utils/snowplowUtil';
import PermalinkUtil from '../../utils/permalinkUtil';

const Recommended = inject(
  'commonStore',
  'authStore'
)(
  observer(({ commonStore, authStore }) => {
    const [content, isLoading, error] = useBackendRecommendedContent();
    useEffect(() => {
      commonStore.setPageTitle('Recommendations | Cybrary');
      authStore.fireAttributionEvent();
    }, []);

    const navigate = useNavigate();

    return (
      <div>
        <Container size="cards-container">
          <div className="items-center sm:flex">
            <Title title="Recommended For You" />
          </div>
          {/** Loading */}
          <If condition={isLoading}>
            <Loading message="Loading recommended content..." wrapperClassName="h-64" />
          </If>
          {/** Error */}
          <If condition={!isLoading && error}>
            <StyledError error={error} />
          </If>
          {/** No Content */}
          <If condition={!isLoading && !error && content?.length === 0}>
            <div className="text-center">There are no recommended items to display.</div>
          </If>
          {/** Content */}
          <If condition={!isLoading && !error && content?.length > 0}>
            <div className="flex flex-wrap gap-8 my-4">
              {content?.map((item, i) => {
                const prepped = ContentTransformer.formatDataSources(item);
                const link = PermalinkUtil.formatInApp(item.permalink, item.content_type);

                /**
                 * category: 'recommended_content',
                 * action: 'clicked',
                 * value: content_id,
                 * property: 0-based index in results
                 * label: 'recommendations_page' (In case we want to track recommendations on different pages in the future)
                 */
                const handleClick = () => {
                  trackSnowplowEvent({ category: 'recommended_content', action: 'clicked', value: prepped.id, property: i, label: 'recommendations_page' });
                  navigate(link);
                };
                return (
                  <ContentCard
                    handleClick={handleClick}
                    cardProps={{ menuAbove: true }}
                    key={prepped.id}
                    data={prepped}
                    objectID={prepped.id}
                    className="w-full sm:w-60"
                    isComingSoon={prepped.comingSoon}
                  />
                );
              })}
            </div>
          </If>
        </Container>
      </div>
    );
  })
);

export default Recommended;
