import React from 'react';
import Tooltip from '../Tooltip/Tooltip';
import Table from '../Table/Table';
import HelpIcon from '../../Icons/HelpIcon';
import AccessibilityUtil from '../../utils/accessibilityUtil';

function getHeadingDisplay(display, reportId) {
  if ((reportId === 'learning-hours' && display === 'Assigned') || (reportId === 'ceu' && display === 'Assigned')) {
    return (
      <span>
        {display}{' '}
        <Tooltip
          content={`Assigned ${reportId === 'learning-hours' ? 'hours' : 'CEUs'} includes content assigned as part of a Goal, or Assignments created before April 1st, 2021`}
          triggerContent={<HelpIcon classes="w-5 h-5 inline-block" />}
        />
      </span>
    );
  }
  if (reportId === 'status-per-member' && display === 'Status') {
    return (
      <span>
        {display}{' '}
        <Tooltip
          content={
            'The activity status of your members based on login recency. Active members have login within the last 7 days. ' +
            'Passive members have a login between 8-30 days. Inactive members have had no login activity within the last 30 days.'
          }
          triggerContent={<HelpIcon classes="w-5 h-5 inline-block" />}
        />
      </span>
    );
  }
  return display;
}

function TableHeadings({ headings, sortFunc, reportsStore, getSortIcon, sortCol, sortDirection, reportId }) {
  return headings.map((heading, idx) => {
    reportsStore.omitHeading(heading.showCol === false); // Pass in true if this column should be omitted from displaying
    if (heading.showCol !== false) {
      return (
        <Table.HeaderCell
          scope="col"
          // eslint-disable-next-line react/no-array-index-key
          key={idx}
          onClick={() => sortFunc(heading.sortKey)}
          className={heading.sortKey ? 'header-sortable' : null}
          ariaSort={AccessibilityUtil.showAriaSortProp(heading.sortKey, sortCol, true, sortDirection)}
        >
          {getHeadingDisplay(heading.display, reportId)}
          <button aria-label="sort" className="align-text-top">
            {getSortIcon(heading.sortKey, sortCol, sortDirection)}
          </button>
        </Table.HeaderCell>
      );
    }
    return null;
  });
}

export default TableHeadings;
