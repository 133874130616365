import React, { useState, useEffect } from 'react';
import RatingStar from '../RatingStar/RatingStar';

function RatingStarsField({ id, name, defaultRating, onRate, ariaDescribedBy, required, formId, size }) {
  const [rating, setRating] = useState(defaultRating || '');
  useEffect(() => {
    onRate(undefined, { name, rating });
  }, [rating]);
  return <RatingStar id={id} defaultRating={rating} onChange={(value) => setRating(value)} ariaDescribedBy={ariaDescribedBy} required={required} formId={formId} size={size} />;
}
export default RatingStarsField;
