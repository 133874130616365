import React from 'react';
import MetricWidget from './MetricWidget';
import { useABM } from '../../providers/ABMProvider';

function ABMTeamMetrics() {
  const { abmTeam } = useABM() || {};
  const { team, dashboardData } = abmTeam || {};
  const { total_members } = team || {};
  const total_learning_hours = dashboardData?.learning_hours?.[0]?.total ?? 0;
  return (
    <>
      <MetricWidget title="Verified Learners on Cybrary" value={total_members ?? 1} lock={{ id: 'verified-learners', msg: 'Verify your company email to unlock' }} />
      <MetricWidget title="Learning Hours (last 90d)" value={total_learning_hours} lock={{ id: 'learning-hours', msg: 'Verify your company email to unlock' }} />
    </>
  );
}

export default ABMTeamMetrics;
