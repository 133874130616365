import { useEffect } from 'react';
import { useTimer } from 'react-timer-hook';
import TimeNotification from './time-notification';

export default function GlobalTimer({ expires, onEnd, ttl = 60 }) {
  const handleExpire = () => {
    if (onEnd) {
      onEnd();
    }
  };

  const { isRunning, minutes, hours } = useTimer({ expiryTimestamp: new Date(expires), autoStart: true, onExpire: handleExpire });

  useEffect(() => {
    if (!isRunning) {
      return;
    }
    const halfway = ttl ? Math.round(ttl / 2) : 30;
    // When we hit one of our minutes values, show a notification
    const showOn = [halfway, 15, 10, 5, 1];
    const actualMinutes = 60 * hours + minutes;
    if (showOn.includes(actualMinutes)) {
      TimeNotification(`${actualMinutes} minute${minutes === 1 ? '' : 's'} remaining`);
    }
  }, [minutes, hours]);

  return null;
}
