import React from 'react';
import { observer, inject } from 'mobx-react';
import withRouter from '../../components/Router/withRouter';
import AddLink from '../../components/AddLink/AddLink';
import Icon from '../../components/Icon/Icon';
import Table from '../../components/Table/Table';
import ContentDashboardHeading from '../../components/Enterprise/ContentDashboardHeading';
import StatSectionContainer from '../../components/Analytics/StatSectionContainer';
import CompetencyLevels from '../../components/Analytics/CompetencyLevels';
import PercentageBar from '../../components/Analytics/PercentageBar';
import DateRangeFilter from '../../components/Enterprise/DateRangeFilter';
import AdminTable from '../../components/AdminTable/AdminTable';
import WorkroleModal from '../../components/Analytics/WorkroleModal';
import FormatUtil from '../../utils/formatUtil';
import MetricsSettings from '../../components/Analytics/MetricsSettings';
import ProgressLabel from '../../components/Enterprise/ProgressLabel';
import Container from '../../components/Container/Container';
import GroupSelect from '../../components/Enterprise/GroupSelect';
import Segment from '../../components/Segment/Segment';
import Sticky from '../../components/Sticky/Sticky';
import './enterprise.css';
import Header from '../../components/Header/Header';
import Tooltip from '../../components/Tooltip/Tooltip';
import Card from '../../components/Card/Card';

const OrganizationDashboard = inject(
  'enterpriseStore',
  'commonStore',
  'userStore',
  'authStore'
)(
  observer(
    class OrganizationDashboard extends React.Component {
      state = {
        orgId: null,
        teamGroupId: null,
        view: 'team',
        contentDescriptionId: null,
        contentType: null,
        tabularView: false,
      };

      componentDidMount() {
        this.props.commonStore.setPageTitle('Content Dashboard | Cybrary');
        this.props.authStore.fireAttributionEvent();
        this.props.userStore.setPreferredTeamInit('', this.props.match.params.orgId, this.init);
      }

      componentDidUpdate(prevProps) {
        this.props.userStore.setPreferredTeamInit(prevProps.match.params.orgId, this.props.match.params.orgId, this.init);
      }

      componentWillUnmount() {
        this.props.enterpriseStore.setDefaultContentDashboardData();
      }

      init = () => {
        const contentId = this.props.match.params.id;
        const { team } = this.props.userStore;
        const groupScope = team.permissions && !team.permissions.canManageTeam && team.permissions.canViewReports !== 'all';
        const teamGroupId = groupScope ? team.reportsTeamGroupId : this.props.match.params.orgId;
        this.props.enterpriseStore.setFilterItem('contentDashboardData', 'contentDescriptionId', contentId);
        // This dashboard is shared between org and group views. teamGroupId is used mostly and will be either group ID or org ID depending on view
        // Org ID is saved in store too because some links may need to use org ID regardless of view (depending on permissions)
        const newState = {
          ...this.state,
          orgId: this.props.match.params.orgId,
          teamGroupId,
          contentDescriptionId: contentId,
        };
        this.props.enterpriseStore.setStartDate('contentDashboardData', this.props.userStore.team.created_at);
        this.setState(newState, () => {
          this.props.enterpriseStore.getContentDashboardHeading(contentId).then((contentDesc) => {
            const newerState = {
              ...this.state,
            };
            newerState.contentType = !!contentDesc && contentDesc.content_type && contentDesc.content_type.nice_name ? contentDesc.content_type.nice_name : null;
            this.setState(newerState);
          });
          this.getFilterableContent();
        });
      };

      applyGroupFilter = (groupId) => {
        const { team } = this.props.userStore;
        const newState = {
          ...this.state,
          teamGroupId: groupId || this.state.orgId,
        };
        this.setState(newState, () => {
          this.getFilterableContent();
          // If this is a group admin and the group filter was changed, set that to the preferred group for the user
          if (team && team.permissions && !team.permissions.canManageTeam && team.permissions.canViewReports !== 'all') {
            this.props.userStore.setPreferredGroup(this.state.orgId, groupId);
          }
        });
      };

      getStatSections = (tabularViewChange) => {
        // Stats/Metrics
        const statsConfig = this.props.enterpriseStore.contentDashboardData.config.stats;

        Object.keys(statsConfig).forEach((section) => {
          statsConfig[section].forEach((item) => {
            this.props.enterpriseStore.getDashboardMetrics(
              'contentDashboardData',
              this.state.teamGroupId,
              null,
              item,
              this.state.contentDescriptionId,
              this.state.tabularView,
              !!tabularViewChange
            );
          });
        });
      };

      getFilterableContent = () => {
        this.getStatSections();
        this.props.enterpriseStore.getCompetencyLevels(this.state.teamGroupId, this.state.contentDescriptionId);
        this.props.enterpriseStore.getContentTranscript(this.state.teamGroupId, this.state.contentDescriptionId);
      };

      setGraphView = (e, { checked }) => {
        const newState = {
          ...this.state,
          tabularView: checked,
        };
        this.setState(newState, () => {
          this.getStatSections(true);
        });
      };

      getDateRangeFilter = (team) => {
        if (!team) {
          return null;
        }
        const createdAt = team.data ? team.data.created_at : team.created_at;
        return (
          <DateRangeFilter
            dateChange={this.applyDateFilter}
            dateRange={this.props.enterpriseStore.contentDashboardData.filters.range}
            allTimeStart={createdAt}
            startDate={this.props.enterpriseStore.contentDashboardData.filters.startDate}
            endDate={this.props.enterpriseStore.contentDashboardData.filters.endDate}
            rangeSelectionClasses="w-full mb-6 md:mb-0 md:w-48 lg:w-52 md:mr-4"
          />
        );
      };

      applyDateFilter = (startDate, endDate, value) => {
        if (startDate) {
          this.props.enterpriseStore.setDashboardDateFilter('contentDashboardData', 'startDate', startDate);
        }
        if (endDate) {
          this.props.enterpriseStore.setDashboardDateFilter('contentDashboardData', 'endDate', endDate);
        }
        this.props.enterpriseStore.setDashboardDateFilter('contentDashboardData', 'range', value || null);

        this.getFilterableContent();
      };

      // /**
      //  * Sets sort column and direction vars in store for service call query params
      //  * @param heading
      //  */
      tableSort = (heading) => {
        const { contentTranscript } = this.props.enterpriseStore.contentDashboardData;
        if (heading) {
          if (heading === contentTranscript.queryParams.sortCol) {
            contentTranscript.queryParams.sortDirection = contentTranscript.queryParams.sortDirection === 'desc' ? 'asc' : 'desc';
          } else {
            contentTranscript.queryParams.sortCol = heading;
            contentTranscript.queryParams.sortDirection = 'desc';
          }
          contentTranscript.queryParams.activePg = 1;
          this.props.enterpriseStore.getContentTranscript(this.state.teamGroupId, this.state.contentDescriptionId);
        }
      };

      // /**
      //  * Sets page number from pagination in store for service call query params
      //  */
      pagChangePg = (pg) => {
        this.props.enterpriseStore.contentDashboardData.contentTranscript.queryParams.activePg = pg;
        this.props.enterpriseStore.getContentTranscript(this.state.teamGroupId, this.state.contentDescriptionId);
      };

      getWorkroleIcons = (row, headings, rowIdx) => {
        const storeData = this.props.enterpriseStore.contentDashboardData;
        const enrollmentId =
          FormatUtil.getColIndex(headings, 'enrollment_id') > -1 && !!row[FormatUtil.getColIndex(headings, 'enrollment_id')]
            ? row[FormatUtil.getColIndex(headings, 'enrollment_id')].value
            : null;
        if (!enrollmentId || (FormatUtil.getColIndex(headings, 'enrollment_id') > -1 && !row[FormatUtil.getColIndex(headings, 'completed_at')].value)) {
          return <Table.Cell key={`workrole-${rowIdx}`} />;
        }

        return (
          <WorkroleModal
            key={`workrole-${rowIdx}`}
            enrollmentId={enrollmentId}
            loading={storeData.workroleData.loading}
            error={storeData.workroleData.error}
            data={storeData.workroleData.data}
            getWorkroleData={this.getWorkroleData}
            showLevelUpMsg={1 * this.state.userId === 1 * this.props.userStore.user.id}
          />
        );
      };

      getWorkroleData = (enrollmentId) => {
        this.props.enterpriseStore.getWorkroleData(this.state.teamGroupId, 'contentDashboardData', enrollmentId);
      };

      formatScore = (col) => {
        if (col.value === null) {
          return '-';
        }
        const proficiencyLevel = FormatUtil.getProficiencyLevel(col.value);
        const { color } = MetricsSettings.competencyLevels[proficiencyLevel];
        return (
          <span style={{ position: 'relative' }}>
            {proficiencyLevel === 'Proficient' ? <div className="print:hidden pdf-print proficient-badge" /> : null}
            <div className="hidden print:block">{col.value}%</div>
            <div className="print:hidden pdf-print">
              <PercentageBar popupContent={proficiencyLevel} value={`${col.value}%`} key={col.value} color={color} maxHeight="2em" width={`${col.value}%`} />
            </div>
          </span>
        );
      };

      formatName = (col, row) => {
        const uId = row[FormatUtil.getColIndex(this.props.enterpriseStore.contentDashboardData.contentTranscript.tableHeadings, 'id')].value;
        if (uId) {
          return (
            <AddLink
              className="hover:font-semibold text-black hover:text-black underline hover:underline"
              to={`/enterprise/${this.state.orgId}/organization/member/${uId}/dashboard`}
            >
              {col.value}
            </AddLink>
          );
        }
        return col.value;
      };

      getProgressLabel = (col, row, headings) => {
        return <ProgressLabel progressNum={col.value} completionDate={row[FormatUtil.getColIndex(headings, 'completed_at')].value} />;
      };

      getStatCards = (storeData) => {
        const statData = { ...storeData };
        // If this isn't a course or microcourse, don't show the CEU stat
        if (this.state.contentType !== 'Course' || this.state.contentType !== 'MicroCourse') {
          if (statData.learningHours && statData.learningHours.data && statData.learningHours.data.completeCeus) {
            delete statData.learningHours.data.completeCeus;
          }
        }
        return (
          <div className="grid grid-cols-1 gap-6 mb-6 md:grid-cols-3 lg:grid-cols-5 stat-cards-group">
            <Card className="pt-0 my-0 stat-card summary" style={{ borderRadius: 2 }}>
              <StatSectionContainer
                type="summary"
                orgId={this.state.orgId}
                loading={storeData.learningHoursSummary.loading}
                error={storeData.learningHoursSummary.error}
                displayData={storeData.learningHoursSummary.data}
                emptyText="There are no activities during this time period"
                tooltip="The total hours earned on this item"
                tabularView={this.state.tabularView && storeData.learningHoursSummary.tableView}
              />
            </Card>
            <Card className="pt-0 my-0 stat-card summary" style={{ borderRadius: 2 }}>
              <StatSectionContainer
                type="summary"
                orgId={this.state.orgId}
                loading={storeData.enrolledActivities.loading}
                error={storeData.enrolledActivities.error}
                displayData={storeData.enrolledActivities.data}
                emptyText="There are no activities during this time period"
                tooltip="The number of unique users who have enrolled in this item and the total number of enrollments in this item across all users"
                tabularView={this.state.tabularView && storeData.enrolledActivities.tableView}
              />
            </Card>
            <Card className="pt-0 my-0 stat-card summary" style={{ borderRadius: 2 }}>
              <StatSectionContainer
                type="summary"
                orgId={this.state.orgId}
                loading={storeData.activityCompletions.loading}
                error={storeData.activityCompletions.error}
                displayData={storeData.activityCompletions.data}
                emptyText="There are no activities during this time period"
                tooltip="The total number of times the assessment has been completed."
                tabularView={this.state.tabularView && storeData.activityCompletions.tableView}
              />
            </Card>
            <Card className="pt-0 my-0 stat-card summary" style={{ borderRadius: 2 }}>
              <StatSectionContainer
                type="summary"
                orgId={this.state.orgId}
                loading={storeData.averageScore.loading}
                error={storeData.averageScore.error}
                displayData={storeData.averageScore.data}
                emptyText="There are no activities during this time period"
                topStatSuffix="%"
                tooltip="The average score across all users that have completed this item"
                tabularView={this.state.tabularView && storeData.averageScore.tableView}
              />
            </Card>
            <Card className="pt-0 my-0 stat-card summary proficient-count" style={{ borderRadius: 2 }}>
              <StatSectionContainer
                type="summary"
                orgId={this.state.orgId}
                loading={storeData.proficientActivities.loading}
                error={storeData.proficientActivities.error}
                displayData={storeData.proficientActivities.data}
                emptyText="There are no activities during this time period"
                showWhenEmpty
                tooltip="The number of attempts where a member scored 76% or higher"
                tabularView={this.state.tabularView && storeData.proficientActivities.tableView}
              />
            </Card>
          </div>
        );
      };

      formatGroups = (col) => {
        const groups = col.value;
        if (!groups) {
          return null;
        }
        const groupsArr = groups.split('|');
        if (groupsArr.length === 1) {
          return groups;
        }
        return (
          <Tooltip
            triggerContent={
              <div className="flex gap-x-2 items-center">
                <Icon name="user-group" className="w-4 h-4" /> {groupsArr.length} Groups
              </div>
            }
            content={groupsArr.join(', ')}
          />
        );
      };

      render() {
        const storeData = this.props.enterpriseStore.contentDashboardData;

        const { contentDashboardData } = this.props.enterpriseStore;
        const { heading, competencyLevels } = contentDashboardData;
        const { team } = this.props.userStore;
        const reportLink = `/enterprise/${this.state.teamGroupId}/reporting/report/learning-activities?contentDescriptionId=${this.state.contentDescriptionId}`;
        return (
          <div className="organization">
            <div className="content-dashboard dashboard">
              <Container>
                <ContentDashboardHeading loading={heading.loading} error={heading.error} data={heading.data} setGraphView={this.setGraphView} graphView={this.state.tabularView} />
              </Container>
              <div className="filterable-content">
                <Sticky stickyClasses="static lg:sticky lg:border-b lg:border-gray-400">
                  <Container>
                    <div className="py-9 md:flex md:justify-end md:items-center filter-container">
                      <p className="mr-2 mb-0">Filters:</p>
                      <span className="mr-4 w-56">
                        <GroupSelect
                          team={team}
                          handleGroupSelect={this.applyGroupFilter}
                          selectedGroup={this.state.teamGroupId}
                          groupFilter={(group) => group.permissions.reports}
                          placeholder="All Groups"
                          defaultOption={
                            team && team.permissions && (team.permissions.canManageTeam || team.permissions.canViewReports === 'all')
                              ? {
                                  text: `All Groups`,
                                  value: null,
                                }
                              : null
                          }
                        />
                      </span>
                      {this.getDateRangeFilter(this.props.userStore.team)}
                    </div>
                  </Container>
                </Sticky>
                <Container>
                  {!!storeData.stats && Object.keys(storeData.stats).length ? (
                    <div className="mb-8">
                      {this.getStatCards(storeData.stats)}
                      <div className="mb-6 learning-hours">
                        <Card className="p-0 stat-card line" style={{ borderRadius: 2 }}>
                          <StatSectionContainer
                            type="line"
                            loading={storeData.stats.contentAttempts.loading}
                            error={storeData.stats.contentAttempts.error}
                            displayData={storeData.stats.contentAttempts.data}
                            header="Attempts Over Time"
                            interval={storeData.filters.interval}
                            borderWidth={2}
                            emptyText="There are no attempts to display during this time period."
                            borderColor="#00a4ed"
                            backgroundColor="#00a4ed"
                            showLegend={false}
                            yAxisLabel="Users Attempted"
                            tooltip="The number of completed attempts that occurred over the specified time period"
                            href={reportLink}
                            tabularView={this.state.tabularView && storeData.stats.contentAttempts.tableView}
                          />
                        </Card>
                      </div>
                      <div className="mb-6">
                        <Card className="p-0 stat-card bar">
                          <StatSectionContainer
                            type="bar"
                            loading={storeData.stats.contentScoreDistribution.loading}
                            error={storeData.stats.contentScoreDistribution.error}
                            displayData={storeData.stats.contentScoreDistribution.data}
                            header="Score Distribution"
                            yAxisLabel="Count"
                            interval={storeData.filters.interval}
                            emptyText="There are no scores to display during this time period"
                            borderColor="#FF6384"
                            backgroundColor="#FF6384"
                            showLegend={false}
                            tooltip="The score distribution across all users that completed this item"
                            href={reportLink}
                            tabularView={this.state.tabularView && storeData.stats.contentScoreDistribution.tableView}
                          />
                        </Card>
                      </div>
                    </div>
                  ) : null}
                  <CompetencyLevels loading={competencyLevels.loading} error={competencyLevels.error} data={competencyLevels.data} reportLink={reportLink} />
                  <Segment className="px-0 border-none">
                    <div className="flex justify-between items-center">
                      <Header as="h2" className="mb-0">
                        All Attempts
                      </Header>
                      {storeData.contentTranscript.tableData && storeData.contentTranscript.tableData.length && (
                        <AddLink className="text-cyb-pink-500 hover:text-black" to={reportLink}>
                          <Icon name="line-graph" className="w-4 h-4 fill-current" />
                        </AddLink>
                      )}
                    </div>
                    <AdminTable
                      className="assessment-table"
                      headings={storeData.contentTranscript.tableHeadings}
                      data={storeData.contentTranscript.tableData}
                      tableLoading={storeData.contentTranscript.loading}
                      tableError={storeData.contentTranscript.error}
                      headerClickFunc={this.tableSort}
                      sortCol={storeData.contentTranscript.queryParams.sortCol}
                      sortDirection={storeData.contentTranscript.queryParams.sortDirection}
                      addCustomCol={[
                        {
                          method: this.getWorkroleIcons,
                          position: 7,
                          header: 'Assessment Results',
                          omitHeading: true,
                        },
                      ]}
                      formatColumns={[
                        {
                          method: this.formatName,
                          colIdx: FormatUtil.getColIndex(storeData.contentTranscript.tableHeadings, 'name'),
                        },
                        {
                          method: this.formatScore,
                          colIdx: FormatUtil.getColIndex(storeData.contentTranscript.tableHeadings, 'score'),
                        },
                        {
                          method: this.getProgressLabel,
                          colIdx: FormatUtil.getColIndex(storeData.contentTranscript.tableHeadings, 'progress'),
                        },
                        {
                          method: this.formatGroups,
                          colIdx: FormatUtil.getColIndex(storeData.contentTranscript.tableHeadings, 'groups'),
                        },
                      ]}
                      /* Pagination options */
                      pagNumPgs={storeData.contentTranscript.pagPagesCount}
                      pagChangePg={this.pagChangePg}
                      pagActivePg={storeData.contentTranscript.queryParams.activePg}
                    />
                  </Segment>
                </Container>
              </div>
            </div>
          </div>
        );
      }
    }
  )
);

export default withRouter(OrganizationDashboard);
