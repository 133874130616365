import { inject, observer } from 'mobx-react';
import React from 'react';
import { twMerge } from 'tailwind-merge';
import Icon from '../Icon/Icon';
import { trackSnowplowEvent } from '../../utils/snowplowUtil';
import If from '../If/If';

const InviteReferralButton = inject('commonStore')(
  observer(({ commonStore, iconClasses = 'w-8 h-8', buttonClasses, label }) => {
    const classes = twMerge('group flex items-center p-1 text-gray-600 hover:text-black cursor-pointer', buttonClasses);
    const handleClickReferralButton = () => {
      commonStore.setIsReferralModalOpen(true);
      trackSnowplowEvent({ category: 'Referral', action: 'click', label: 'Triggered referral modal' });
    };
    return (
      <button onClick={handleClickReferralButton} className={classes}>
        <Icon name="user-plus" className={iconClasses} />
        <If condition={label}>
          <p className="pl-4 mb-0 text-lg text-gray-600 group-hover:text-black">{label}</p>
        </If>
      </button>
    );
  })
);
export default InviteReferralButton;
