import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import Agents from '../../agents/agents';
import FormatUtil from '../../utils/formatUtil';
import Loading from '../Loading/Loading';
import Button from '../Button/Button';
import AddLink from '../AddLink/AddLink';
import ShareCertificate from '../../pages/Certificates/ShareCertificate';
import Modal from '../Modal/Modal';
import UpgradeButton from '../UpgradeButton/UpgradeButton';
import Icon from '../Icon/Icon';
import CopyInviteLinkForm from '../ReferralModal/CopyInviteLinkForm';
import { useBadgeModal } from '../../providers/BadgeModalProvider';

function Recommendations({ ctaButtonColor }) {
  return (
    <div className="mt-8">
      <p>Want to hone your skills? Here&apos;s what we recommend next.</p>
      <Button href="/recommendations" color={ctaButtonColor} className="mt-2 mb-8">
        Explore Recommended Content
      </Button>
    </div>
  );
}

function MainContent({ hasIncompleteItems, gotoFirstIncomplete, gotoNextActivity, hasCurriculumItems, isFreeUser, title, certificate, canClaimCertificate, hasCertificate }) {
  const [open, setOpen] = useState(false);

  if (hasIncompleteItems) {
    return <IncompleteCourse gotoFirstIncomplete={gotoFirstIncomplete} gotoNextActivity={gotoNextActivity} hasCurriculumItems={hasCurriculumItems} isFreeUser={isFreeUser} />;
  }
  if (!hasCertificate) {
    return (
      <>
        <h1 className="mb-8 text-4xl font-black text-black sm:text-6xl">Congratulations</h1>
        <h2 className="mb-8 text-2xl font-black text-center text-black">Now you&apos;re ready to learn!</h2>
        <Recommendations />
      </>
    );
  }

  return (
    <>
      <CompleteCertificate
        title={title}
        certificate={certificate}
        hasCurriculumItems={hasCurriculumItems}
        gotoNextActivity={gotoNextActivity}
        openShareModal={() => setOpen(!open)}
        canClaimCertificate={canClaimCertificate}
        isFreeUser={isFreeUser}
      />
      <Modal open={open} toggle={() => setOpen(!open)} ariaLabelledBy="share-certificate-modal">
        <div className="p-8">
          <ShareCertificate certificate={certificate.data} />
        </div>
      </Modal>
    </>
  );
}

const CompleteView = inject('userStore')(
  observer(function CompleteView({
    generalFrame,
    title,
    contentDescriptionId,
    type,
    hasIncompleteItems,
    hasCurriculumItems,
    gotoNextActivity,
    gotoFirstIncomplete,
    userStore,
    hasCertificate,
  }) {
    const [certificate, setCertificate] = useState({ data: null, error: null, loading: !hasIncompleteItems });
    const canClaimCertificate = ['Course', 'Career Path', 'MicroCourse', 'Virtual Lab'].indexOf(type) !== -1;
    const isFreeUser = !userStore?.user?.is_paid;

    useEffect(() => {
      async function claimCertificate() {
        // wait for 2s, give some time for the certificate to be created
        await new Promise((resolve) => {
          setTimeout(resolve, 2000);
        });
        try {
          const certificateClaim = await Agents.certificate.getByContentDescriptionId(contentDescriptionId);
          setCertificate({ data: certificateClaim, error: null, loading: false });
        } catch (err) {
          setCertificate({ data: null, error: err, loading: false });
        }
      }

      // If this course is complete AND it's in a path with other content AND the cert of completion is turned off (via contentful), just go to next activity
      if (!hasIncompleteItems && hasCurriculumItems && !hasCertificate) {
        gotoNextActivity();
      } else if (!hasIncompleteItems && canClaimCertificate) {
        claimCertificate();
      } else {
        setCertificate({ data: null, error: null, loading: false });
      }
    }, []);

    if (certificate.loading) {
      return (
        <div className="flex justify-center items-center w-full">
          <Loading className="mt-24 mb-4 w-10 h-10 border-4" message="Loading..." />
        </div>
      );
    }

    return (
      <>
        <div className={`immersive-complete-frame ${generalFrame}`}>
          <div className="overflow-y-scroll w-full h-full scrollbar">
            <div className={`flex flex-col items-center pt-16 pb-24 px-5 ${hasIncompleteItems ? 'max-w-5xl' : 'max-w-2xl'} mx-auto text-center`}>
              <MainContent
                hasIncompleteItems={hasIncompleteItems}
                gotoFirstIncomplete={gotoFirstIncomplete}
                gotoNextActivity={gotoNextActivity}
                hasCurriculumItems={hasCurriculumItems}
                isFreeUser={isFreeUser}
                title={title}
                certificate={certificate}
                canClaimCertificate={canClaimCertificate}
                hasCertificate={hasCertificate}
              />
            </div>
          </div>
        </div>
        {isFreeUser && <UpgradeBanner />}
      </>
    );
  })
);

function IncompleteCourse({ gotoFirstIncomplete, gotoNextActivity, hasCurriculumItems, isFreeUser }) {
  const ctaButtonColor = isFreeUser ? 'gray' : 'pink';

  return (
    <>
      <h1 className="mb-8 text-4xl font-black text-black sm:text-6xl">You&apos;re Not Done Yet</h1>
      <h2 className="mb-8 text-2xl font-black text-center text-black">
        You&apos;ve reached the end of this course, <br /> but still have some open activities.
      </h2>
      <p className="text-lg font-bold text-black">You must complete all activities to unlock your Certificate of Completion.</p>
      <Button onClick={() => gotoFirstIncomplete()} color={ctaButtonColor} className="my-8">
        Complete Activities
      </Button>
      {hasCurriculumItems ? (
        <div>
          <p>Ready to keep going? Let&apos;s hone your skills.</p>
          <AddLink type="button" onClick={() => gotoNextActivity()} className="mt-2 underline">
            Continue Learning
          </AddLink>
        </div>
      ) : (
        <div>
          <p>Want to hone your skills? Here&apos;s what we recommend next.</p>
          <AddLink type="button" to="/recommendations" className="mt-2 underline">
            Explore Recommended Content
          </AddLink>
        </div>
      )}
    </>
  );
}

function UpgradeBanner() {
  // animate in/out by
  // 1) adding animationClasses to parent component
  const animationClasses = `transition-all duration-1000 ease-in-out`;

  // 2) change height class to animate
  const [cssHeight, setCSSHeight] = useState('h-0');
  const showBanner = (show) => setCSSHeight(show ? 'h-1/3 sm:h-1/4' : 'h-0');

  // 3) start hidden, and set to full height on mount to animate on load
  useEffect(() => showBanner(true), []);

  return (
    <div className={`flex fixed bottom-0 z-50 w-full ${cssHeight} text-white bg-neutral-800 ${animationClasses}`}>
      <div className="p-4 sm:w-1/4">
        <img
          className="ml-auto max-h-36"
          alt="A programmer learning through Cybrary"
          src="https://images.ctfassets.net/kvf8rpi09wgk/3Ls37Ke5KPtU7N5x9DAdE1/dd1889d8e40806c8b4708de2fd503721/Programming_Cybrary.svg"
        />
      </div>
      <div className="w-full sm:w-3/4">
        <div className="my-2 w-full text-2xl font-bold text-center">Keep Skilling Up</div>
        <div className="overflow-hidden m-auto w-full max-w-4xl text-base sm:text-sm">
          Upgrade to Cybrary Insider Pro for unlimited access to professional development courses, certification preparation, hands-on virtual labs, and assessment -- PLUS access
          to guided career paths and a global cybersecurity community.
        </div>
        <div className="mt-2 w-full text-center">
          <UpgradeButton color="pink" upgradeText="Upgrade" />
        </div>
      </div>
      <button className="absolute top-2 right-4 p-2 cursor-pointer" title="Dismiss Upgrade Banner" onClick={() => showBanner(false)}>
        <Icon className="w-6 h-6 text-white" name="x" />
      </button>
    </div>
  );
}

const CompleteCertificate = inject('userNotificationStore')(
  observer(function CompleteCertificate({ userNotificationStore, title, certificate, hasCurriculumItems, gotoNextActivity, openShareModal, canClaimCertificate, isFreeUser }) {
    const certDate = certificate.data ? certificate.data.issued_date || certificate.data.issued_at || certificate.data.completed_at : null;
    const completedDate = certDate ? moment(certDate.substring(0, 10)).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY');
    const certificateNotFound = !(certificate.data && certificate.data.title);
    const downloadUrl = certificate.data ? FormatUtil.formatCertificateDownloadUrl(certificate.data.certificate_id) : null;
    const ctaButtonColor = isFreeUser ? 'gray' : 'pink';

    const { isOpen } = useBadgeModal();

    useEffect(() => {
      // Check for unread notifications on activity load
      // This allows us to show badges as we earn them in immersive
      // IMPORTANT Stop checking for unread notifications when the badge modal is open.
      userNotificationStore.checkForUnreadOnInterval(isOpen, { maxAttempts: 10, delay: 5000 });
    }, []);

    return (
      <>
        <h1 className="mb-8 text-4xl font-black text-black sm:text-6xl">Congratulations!</h1>
        <h2 className="mb-8 text-2xl font-black text-center text-black">
          You&apos;ve successfully completed this course! <br /> You put in the work, now share your achievement with the world.
        </h2>
        {canClaimCertificate && (
          <img
            src="https://images.ctfassets.net/kvf8rpi09wgk/7a9Hi5dmSSnzuNJqMTcqUC/5264b28124e7ec8087d82c771a3f84bb/Cybrary-Cert.png?h=400"
            alt=""
            className="inline-block mx-auto mb-3 w-auto max-h-64"
          />
        )}
        <p className="mb-1 text-lg font-bold text-black">{certificate.data ? certificate.data.title : title}</p>
        {certificateNotFound && <p className="mb-1 text-center text-gray-600">Completed {completedDate}</p>}
        {!certificateNotFound && (
          <>
            <div className="text-center text-gray-600">
              <p className="mb-1">Certificate ID: {certificate.data.certificate_id}</p>
              <p className="mb-1">Completed {completedDate}</p>
            </div>

            <div className="flex pb-6 mt-10 border-b-xs border-gray-400">
              <Button color="gray" onClick={openShareModal} className="mr-5 mb-2">
                Share Certificate
              </Button>
              <Button color="gray" target="_blank" href={downloadUrl} className="mb-2">
                Download Certificate
              </Button>
            </div>
          </>
        )}
        {hasCurriculumItems ? (
          <div className="mt-8">
            <p>Ready to keep going? Let&apos;s hone your skills.</p>
            <Button color={ctaButtonColor} onClick={() => gotoNextActivity()} className="my-2">
              Continue Learning
            </Button>
          </div>
        ) : (
          <Recommendations ctaButtonColor={ctaButtonColor} />
        )}

        {certificateNotFound && <p className="mt-6 mb-1 text-center text-cyb-blue-500">We will send you a notification with the link to your certificate shortly.</p>}

        <div className="w-full">
          <p className="mt-4 mb-0 font-bold text-left text-black text-md">Invite your friends and share the fun!</p>
          <CopyInviteLinkForm />
        </div>
      </>
    );
  })
);

export default CompleteView;
