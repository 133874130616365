import moment from 'moment';
import FormatUtil from '../utils/formatUtil';
import CatalogUtil from '../utils/catalogUtil';

class EnrollmentTransformer {
  // Return a random assignment image
  static getRandomAssignmentImage() {
    const max = 18;
    const rand = Math.floor(Math.random() * (max + 1));
    return `/images/assignment_${rand}.jpg`;
  }

  // Convenience function for retrieving an element keyed by ID from a map.
  static getContentDescription(id, contentDescriptionMap) {
    return contentDescriptionMap[id];
  }

  // Helper to get header row
  static getHeaderRow() {
    return [{ content: 'LAUNCH' }, { content: 'TITLE' }, { content: 'STATUS' }, { content: 'ESTIMATED TIME' }, { content: 'TYPE' }];
  }

  /**
   * get module status
   * @param progress
   * @returns {string}
   */
  static getModuleStatus(progress) {
    let status = '';
    if (progress === 100) {
      status = 'Done';
    } else if (progress > 0 && progress < 100) {
      status = 'In Progress';
    } else {
      status = 'To Do';
    }
    return status;
  }

  // Generate accordion contents, as well as progress bar data.
  static generateAccordionData(buckets, accordionContents) {
    const headerRow = this.getHeaderRow();

    buckets.forEach((bucket) => {
      const title = bucket.label;
      const duration_seconds = bucket.remaining_duration;
      const duration = FormatUtil.formatTime(duration_seconds, 'hm');
      const checkpointDate = 'Sep 30, 2018'; // not sure what this really means righ tnow
      const bucketContent = [];
      const progress = bucket.total_items ? Math.round((bucket.total_completed_items / bucket.total_items) * 100) : 0;
      bucket.items.forEach((item) => {
        bucketContent.push({
          // date: "Dec 31, 2018", // what is this?
          icon: 'play', // should this be toggled based on status?
          content: {
            title: item._formatted_title,
            // description: FormatUtil.formatLongText(
            //   item.content_description.short_description ||
            //     item.content_description.long_description,
            //   150
            // ),
            status: item._is_completed ? 'Completed' : 'Pending',
            duration: FormatUtil.formatTime(item.duration, 'hm'),
            // type: item.content_description.content_type.nice_name
          },
        });
      });

      accordionContents.push({
        title,
        progress,
        checkpointDate,
        duration,
        headerRow,
        headerColumns: [
          {
            title: 'Progress',
            value: `${progress}%`,
          },
          // {
          //   title: "Deadline",
          //   value: "Sep 30, 2018"
          // },
          {
            title: 'Estimated Time Remaining',
            value: duration || '0 mins',
          },
        ],
        content: bucketContent,
      });
    });

    // No need to return anything, as accordionContents were array so they were passed by reference.
  }

  // Helper function to transform enrollments data into something the enrollment cards will like.
  static transformEnrollmentData(enrollments) {
    const transformed = [];

    // Loop over enrollments
    enrollments.forEach((enrollment) => {
      const transformedEnrollment = this.transformSingleEnrollment(enrollment);
      if (transformedEnrollment) {
        transformed.push(transformedEnrollment);
      }
    });

    return transformed;
  }

  // Transform a SINGLE enrollment item!
  static transformSingleEnrollment(enrollment) {
    let transformed = null;
    const type = enrollment?.content?.content_description?.content_type?.nice_name || enrollment?.content_description_type;
    switch (type) {
      /**
       * I don't know why we use the assignment transformer for collections, but we do.
       * Following suit for Assessment Path
       */
      case 'Assignment':
      case 'Curriculum':
      case 'Career Path':
      case 'Threat Actor Path':
      case 'Cybrary Select':
      case 'Collection':
      case 'Assessment Path':
        transformed = this.transformAssignment(enrollment);
        break; // end assignment

      case 'MicroCourse':
      case 'Assessment':
      case 'Course':
        transformed = this.transformCourse(enrollment);
        break; // end course

      // case "m_course":
      default:
        transformed = this.transformMicroCourse(enrollment);
        break; // end m_course and default
    } // end switching based on legacy type

    return transformed;
  }

  //
  //
  // transform an assignment/Curriculum and Career Path
  //
  //
  static transformAssignment(assignment) {
    const flattenedLessons = [];
    let totalCurriculumItems = 0;
    let totalCompletedCurriculumItems = 0;
    let totalStartedCurriculumItems = 0;
    let completedDuration = 0;
    let totalDuration = 0;
    let totalLessons = 0;
    let totalCompletedLessons = 0;
    let estimatedCompletionDate = null;
    const programDeadlineMoment = moment(assignment.due_date, 'YYYY-MM-DD');
    const moduleActivities = [];
    const completedActivityIds = assignment.completed_content_description_ids || [];
    const curriculumItems = [];
    const enrollmentType = ['Curriculum', 'Assignment'].indexOf(assignment.type) !== -1 ? 'Curriculum' : 'Career Path';

    if (assignment.content && assignment.content.curriculum_items) {
      assignment.content.curriculum_items.forEach((item) => {
        const curriculumItem = { ...item };
        // grab the curriculum item from our dictionary

        const contentDescription = curriculumItem.content_description;
        if (contentDescription) {
          totalCurriculumItems++;
          // in the rare event that we don't have a content description, skip this item.
          const { content_type } = contentDescription;
          const type = content_type.nice_name;
          if (type !== 'Course' && type !== 'MicroCourse' && !CatalogUtil.checkIfCourseType(type)) {
            let totalCurriculumDuration = 0;
            let totalCompletedCurriculumDuration = 0;

            // We are dealing with a non-course, just add this to the flattened array!
            const duration = contentDescription.duration_seconds;
            totalLessons++;
            totalCurriculumDuration += duration;
            totalDuration += duration;
            if (completedActivityIds.indexOf(contentDescription.id) !== -1) {
              curriculumItem._is_completed = true;
              totalCompletedCurriculumItems++; // increment completed curriculum items
              totalCompletedLessons++;
              totalCompletedCurriculumDuration += curriculumItem.duration_seconds;
              completedDuration += curriculumItem.duration_seconds ? curriculumItem.duration_seconds : 0;
            } else {
              curriculumItem._is_completed = false;
            }
            curriculumItem._formatted_title = contentDescription.title; // nothing to format for one of these
            curriculumItem.duration = FormatUtil.formatTime(contentDescription.duration_seconds, 'hm');

            const remainingCurriculumSeconds = totalCurriculumDuration - totalCompletedCurriculumDuration;
            const estimatedCurriculumTimeRemaining = FormatUtil.formatTime(remainingCurriculumSeconds, 'hm');
            flattenedLessons.push(curriculumItem);
            const progress = curriculumItem._is_completed ? 100 : 0;
            // enrollmentTableData.push({
            //   title: curriculumItem._formatted_title,
            //   progress,
            //   estimatedTimeRemaining: estimatedCurriculumTimeRemaining,
            //   status: curriculumItem._is_completed ? "Done" : "To Do"
            // });

            curriculumItems.push({
              title: contentDescription.title,
              type,
              duration: curriculumItem.duration,
              remainingDuration: estimatedCurriculumTimeRemaining,
              estimatedTimeRemaining: estimatedCurriculumTimeRemaining,
              progress,
              status: this.getModuleStatus(progress),
              thumbnail: contentDescription.thumbnail_url,
              contentDescription,
              curriculumItem,
            });
          } else {
            // We are dealing with a course!  Flatten it's lessons!
            const courseId = curriculumItem.id ? curriculumItem.id : undefined;

            let curriculumItemLessons = 0;
            let curriculumItemCompletedLessons = 0;
            let curriculumItemDuration = 0;
            let curriculumItemCompletedDuration = 0;

            const learningModulesInfo = this.transformLearningModules(completedActivityIds, curriculumItem.learning_modules, flattenedLessons, courseId);
            // Increment our talleys of lessons and duration
            totalLessons += learningModulesInfo.lessons;
            curriculumItemLessons += learningModulesInfo.lessons;
            curriculumItemDuration += learningModulesInfo.duration;
            totalDuration += learningModulesInfo.duration;
            totalCompletedLessons += learningModulesInfo.completedLessons;
            curriculumItemCompletedLessons += learningModulesInfo.completedLessons;
            completedDuration += learningModulesInfo.completedDuration;
            curriculumItemCompletedDuration += learningModulesInfo.completedDuration;

            // Increment total completed/started count if this curriculum item is either completed or started
            if (curriculumItemLessons === curriculumItemCompletedLessons) {
              totalCompletedCurriculumItems++;
            } else if (curriculumItemCompletedLessons > 0) {
              totalStartedCurriculumItems++;
            }

            const remainingCurriculumItemSeconds = curriculumItemDuration - curriculumItemCompletedDuration;
            const curriculumItemRemainingDuration = FormatUtil.formatTime(remainingCurriculumItemSeconds, 'hm');
            const curriculumItemDurationTotal = FormatUtil.formatTime(curriculumItemDuration, 'hm');

            const curriculumItemProgress = !curriculumItemLessons ? 0 : Math.round((curriculumItemCompletedLessons / curriculumItemLessons) * 100);

            curriculumItems.push({
              title: contentDescription.title,
              type,
              duration: curriculumItemDurationTotal,
              remainingDuration: curriculumItemRemainingDuration,
              estimatedTimeRemaining: curriculumItemRemainingDuration,
              progress: curriculumItemProgress,
              status: this.getModuleStatus(curriculumItemProgress),
              thumbnail: contentDescription.thumbnail_url,
              contentDescription,
              curriculumItem,
            });
          } // end if we have a content description
        }
      }); // end of looping curriculum items
    } // end if we have curriculum items

    const progress = Math.round((totalCompletedLessons / totalLessons) * 100);
    const remainingSeconds = totalDuration - completedDuration;
    const estimatedTimeRemaining = FormatUtil.formatTime(remainingSeconds, 'hm');

    const enrollment_id = assignment.id;
    const timePerWeek = assignment.settings && assignment.settings.timePerWeek ? assignment.settings.timePerWeek : 30;

    const buckets = this.sortIntoBuckets(flattenedLessons, timePerWeek, 'Session');

    // Get the estimated completion date based on remaining time and time per week.
    const sessionsPerWeek = 3; // assuming 3 sessions per week for now
    const numWeeksLeft = Math.round(remainingSeconds / (timePerWeek * 60 * sessionsPerWeek));
    const dateToComplete = moment().add(numWeeksLeft, 'weeks');
    const dateOutputFormat = 'MMM D, YYYY';
    estimatedCompletionDate = dateToComplete.format(dateOutputFormat);
    const programDeadline = programDeadlineMoment.format(dateOutputFormat);

    const isLate = dateToComplete.isAfter(programDeadlineMoment);

    // Now transform buckets to accordionContents!
    const realAccordionContents = [];
    this.generateAccordionData(buckets, realAccordionContents);

    buckets.forEach((bucket) => {
      moduleActivities.push(bucket.items);
    });

    // const thumbnail_url =
    //   "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcROKq-LLHbHNeGvCyDTfL8Pmz780sZ_bARs6C4buFUJJPnJGyUChA";
    const thumbnail_url = this.getRandomAssignmentImage();
    return {
      id: enrollment_id,
      exclude_progress_before: assignment.exclude_progress_before,
      title: assignment?.content?.content_description?.title || assignment?.content_description_title,
      estimatedCompletionDate,
      programDeadline,
      estimatedTimeRemaining,
      overallProgress: progress,
      thumbnail: assignment?.content_description_thumbnail_url || thumbnail_url,
      shortDescription: assignment.short_description,
      type: enrollmentType,
      isLate,
      progressData: realAccordionContents,
      buckets,
      _raw: assignment,
      settings: assignment.settings,
      status: assignment.status,
      // enrollmentTableData: enrollmentTableData,
      enrollmentTableData: curriculumItems, // assignments will just use curriculum item data in my enrollments table
      moduleActivities,
      totalCurriculumItems,
      totalCompletedCurriculumItems,
      totalStartedCurriculumItems,
      curriculumItems,
    };
  }

  //
  //
  // transform a course
  //
  //
  static transformCourse(item) {
    const course = { ...item };
    const contentDescription = course.content?.content_description || {};
    const completedActivityIds = course.completed_content_description_ids || [];

    const courseId = course.content?.id;

    const { content_type, short_description } = contentDescription;
    const type = content_type?.nice_name || course.content_description_type;
    const title = contentDescription?.title || course.content_description_title;
    const thumbnail_url = contentDescription?.thumbnail_url || course.content_description_thumbnail_url;
    let estimatedCompletionDate = null;
    const programDeadline = null;
    const enrollmentTableData = [];
    let completedLessons = 0;
    let totalLessons = 0;
    const moduleActivities = [];

    let completedDuration = 0;
    let totalDuration = 0;
    const flattenedLessons = [];

    const learningModuesInfo = this.transformLearningModules(completedActivityIds, course.content.learning_modules, flattenedLessons, courseId, enrollmentTableData);
    // Increment our talleys of lessons and duration
    totalLessons += learningModuesInfo.lessons;
    totalDuration += learningModuesInfo.duration;
    completedLessons += learningModuesInfo.completedLessons;
    completedDuration += learningModuesInfo.completedDuration;

    const remainingSeconds = totalDuration - completedDuration;
    const estimatedTimeRemaining = FormatUtil.formatTime(remainingSeconds, 'hm');

    const enrollment_id = course.id;
    course.totalActivities = totalLessons;
    course.completedActivities = completedLessons;
    const timePerWeek = course.settings && course.settings.timePerWeek ? course.settings.timePerWeek : 30;

    const buckets = this.sortIntoBuckets(flattenedLessons, timePerWeek, 'Session');
    buckets.forEach((bucket) => {
      moduleActivities.push(bucket.items);
    });
    // Now transform buckets to accordionContents!
    const realAccordionContents = [];
    this.generateAccordionData(buckets, realAccordionContents);

    const progress = Math.round((completedLessons / totalLessons) * 100);

    // Get the estimated completion date based on remaining time and time per week.
    const sessionsPerWeek = 3; // assuming 3 sessions per week for now
    const numWeeksLeft = Math.round(remainingSeconds / (timePerWeek * 60 * sessionsPerWeek));
    const dateToComplete = moment().add(numWeeksLeft, 'weeks');
    const dateOutputFormat = 'MMM D, YYYY';
    estimatedCompletionDate = dateToComplete.format(dateOutputFormat);
    const is_free = course.content && course.content.content_description ? course.content.content_description.is_free : false;
    const has_paid_content = course.content && course.content.content_description ? course.content.content_description.has_paid_content : false;
    return {
      id: enrollment_id,
      exclude_progress_before: course.exclude_progress_before,
      created_at: course.created_at,
      is_free,
      title,
      programDeadline,
      estimatedTimeRemaining,
      estimatedCompletionDate,
      overallProgress: progress,
      thumbnail: thumbnail_url,
      shortDescription: short_description,
      learning_modules: course.content && course.content.learning_modules ? course.content.learning_modules : [],
      type,
      // progressData: accordionContents,
      progressData: realAccordionContents,
      progress,
      buckets,
      _raw: course,
      settings: course.settings,
      status: course.status,
      enrollmentTableData,
      moduleActivities,
      has_paid_content,
    };
  }

  //
  //
  // transform a microcourse
  //
  //
  static transformMicroCourse(item) {
    const microcourse = { ...item };
    // If this microcourse hasn't already been included in a curriculum, present it to the user as a top-level enrollment

    const contentDescription = microcourse.content?.content_description || {};

    const courseId = microcourse.content?.id;

    if (contentDescription) {
      const { content_type, short_description } = contentDescription;
      const title = contentDescription.title || microcourse.content_description_title;
      const type = content_type?.nice_name || microcourse.content_description_type;
      const thumbnail_url = contentDescription.thumbnail_url || microcourse.content_description_thumbnail_url;
      const upcomingDeadline = null;
      const programDeadline = null;

      // const completedLessons = microcourse.completed_count;
      const { progress } = microcourse;
      // const totalLessons = microcourse.incomplete_count + completedLessons;
      const moduleActivities = [];

      const duration = contentDescription.duration_seconds;
      const remainingSeconds = duration - (progress / 100) * duration; // figure out remaining time based on percentage complete
      const estimatedTimeRemaining = FormatUtil.formatTime(remainingSeconds, 'hm');

      // Simulate flattened lessons, using just this microcourse
      microcourse._is_completed = false;
      microcourse.content_description = contentDescription;
      microcourse._formatted_title = title;
      microcourse._course_id = courseId;
      const flattenedLessons = [microcourse];

      const enrollment_id = microcourse.id;
      const timePerWeek = microcourse.settings && microcourse.settings.timePerWeek ? microcourse.settings.timePerWeek : 30;

      const buckets = this.sortIntoBuckets(flattenedLessons, timePerWeek, 'Session');

      const realAccordionContents = [];
      this.generateAccordionData(buckets, realAccordionContents);

      buckets.forEach((bucket) => {
        moduleActivities.push(bucket.items);
      });
      const is_free = microcourse.content && microcourse.content.content_description ? microcourse.content.content_description.is_free : false;
      return {
        id: enrollment_id,
        is_free,
        exclude_progress_before: microcourse.exclude_progress_before,
        created_at: microcourse.created_at,
        title,
        upcomingDeadline,
        programDeadline,
        estimatedTimeRemaining,
        overallProgress: progress,
        thumbnail: thumbnail_url,
        shortDescription: short_description,
        learning_modules: microcourse?.content?.learning_modules || [],
        type,
        progressData: realAccordionContents,
        buckets,
        _raw: microcourse,
        settings: microcourse.settings,
        status: microcourse.status,
        moduleActivities,
      };
    } // end if we have a content description
    return null;
  }

  /**
   * Sort an array of items into buckets, based on their duration and a target maximum number of minutes per bucket.
   */
  static sortIntoBuckets(items, minutesPerBucket, bucketLabel) {
    const buckets = []; // our buckets of items we will return

    // Sort program into buckets based on numeric value
    let bucketCount = 0;

    let totalBucketMinutes = 0;
    let totalBucketItems = 0;
    let totalBucketCompletedItems = 0;
    let remainingDuration = 0;

    items.forEach((item) => {
      const durationInMinutes = item.duration / 60; // convert duration to minutes

      if (!buckets[bucketCount]) {
        totalBucketItems = 1;
        totalBucketCompletedItems = item._is_completed ? 1 : 0;
        remainingDuration = item._is_completed ? 0 : item.duration;
        buckets[bucketCount] = {
          label: `${bucketLabel} ${bucketCount + 1}`,
          items: [item],
          duration_seconds: item.duration,
          remaining_duration: remainingDuration,
          total_items: totalBucketItems,
          total_completed_items: totalBucketCompletedItems,
        };
        totalBucketMinutes = durationInMinutes;
      } else if (totalBucketMinutes + durationInMinutes > minutesPerBucket) {
        bucketCount++;
        totalBucketMinutes = durationInMinutes;
        totalBucketItems = 1;
        totalBucketCompletedItems = item._is_completed ? 1 : 0;
        remainingDuration = item._is_completed ? 0 : item.duration;
        buckets[bucketCount] = {
          label: `${bucketLabel} ${bucketCount + 1}`,
          items: [item],
          duration_seconds: item.duration,
          remaining_duration: remainingDuration,
          total_items: totalBucketItems,
          total_completed_items: totalBucketCompletedItems,
        };
      } else {
        buckets[bucketCount].items.push(item);
        buckets[bucketCount].duration_seconds += item.duration;
        buckets[bucketCount].total_items++;
        buckets[bucketCount].total_completed_items += item._is_completed ? 1 : 0;
        buckets[bucketCount].remaining_duration += item._is_completed ? 0 : item.duration;
        totalBucketMinutes += durationInMinutes;
      }
    });

    return buckets;
  }

  // Transform a set of learning modules and return relevant data
  static transformLearningModules(completedActivityIds, modules, flattenedLessons, courseId, enrollmentTableData) {
    const ret = {
      lessons: 0,
      duration: 0,
      completedLessons: 0,
      completedDuration: 0,
    };

    for (let idx = 0; idx < modules.length; idx++) {
      const module = modules[idx];
      const lessons = module.activities;
      const moduleTitle = module.title;
      const moduleContentDescriptionId = module.id;
      let totalModuleDuration = 0;
      let completedModuleDuration = 0;
      let totalModuleCompleteLessons = 0;
      let totalModuleLessons = 0;
      for (let i = 0; i < lessons.length; i++) {
        const lesson = lessons[i];
        const lessonDuration = lesson.duration;
        const lessonTitle = lesson.title;
        ret.lessons++;
        ret.duration += lessonDuration;
        totalModuleDuration += lessonDuration;
        totalModuleLessons++;
        if (completedActivityIds.indexOf(lesson.id) !== -1) {
          lesson._is_completed = true;
          lesson.progress = 100;
          ret.completedLessons++;
          ret.completedDuration += lessonDuration;
          completedModuleDuration += lessonDuration;
          totalModuleCompleteLessons++;
        } else {
          lesson._is_completed = false;
          lesson.progress = 0;
        }
        // Setup a title based on module title - lesson title
        lesson._formatted_title = `${moduleTitle} - ${lessonTitle}`;
        lesson._course_id = courseId;
        flattenedLessons.push(lesson);
      }

      // If we provided an enrollmentTableData array, update it.
      if (typeof enrollmentTableData !== 'undefined') {
        const remainingModuleSeconds = totalModuleDuration - completedModuleDuration;
        const estimatedModuleTimeRemaining = FormatUtil.formatTime(remainingModuleSeconds, 'hm');

        const moduleProgress = totalModuleLessons === 0 ? 0 : Math.round((totalModuleCompleteLessons / totalModuleLessons) * 100);
        const moduleStatus = EnrollmentTransformer.getModuleStatus(moduleProgress);

        enrollmentTableData.push({
          title: moduleTitle,
          progress: moduleProgress,
          estimatedTimeRemaining: estimatedModuleTimeRemaining,
          status: moduleStatus,
          moduleContentDescriptionId,
        });
      }
    }

    return ret;
  }
}

export default EnrollmentTransformer;
