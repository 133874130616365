import React from 'react';
import { observer, inject } from 'mobx-react';
import { Navigate } from 'react-router-dom';
import moment from 'moment';
import withRouter from '../../components/Router/withRouter';
import Loading from '../../components/Loading/Loading';
import Segment from '../../components/Segment/Segment';
import Agents from '../../agents/agents';
import AdminSendgridEvents from '../../components/CybraryAdmin/CybraryAdminSendgridEvents';
import StyledError from '../../components/Error/StyledError';
import AdminEmailButtons from '../../components/CybraryAdmin/CybraryAdminEmailButtons';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import Container from '../../components/Container/Container';

const CybraryAdminUnverifiedDashboard = inject(
  'adminStore',
  'authStore',
  'commonStore'
)(
  observer(
    class CybraryAdminUnverifiedDashboard extends React.Component {
      state = {
        loadingUser: true,
        unverifiedUser: null,
        error: null,
      };

      componentDidMount() {
        // Confirm that we are allowed on the admin page
        this.props.adminStore.checkAccess(this.props.authStore);
        const { id } = this.props.match.params;
        this.props.commonStore.hidePrimaryNav();
        // get unverified user data
        Agents.admin
          .getUnverifiedUserData(id)
          .then((response) => {
            const newState = { ...this.state, unverifiedUser: response, loadingUser: false };
            this.setState(newState);
          })
          .catch((error) => {
            const newState = { ...this.state, error, loadingUser: false };
            this.setState(newState);
          });
      }

      componentWillUnmount() {
        this.props.commonStore.showPrimaryNav();
      }

      getBreadCrumbs = () => {
        const crumbs = [];
        crumbs.push({
          href: '/admin',
          label: 'Admin',
        });
        crumbs.push({
          href: '/admin/user',
          label: 'Browse User',
        });
        return crumbs;
      };

      render() {
        const { loading, denyAccess } = this.props.adminStore;
        const { loadingUser, unverifiedUser, error } = this.state;
        // If we should deny access, just get out now
        if (denyAccess) {
          return <Navigate to="/" />;
        }
        if (loading || loadingUser) {
          return (
            <Container>
              <Loading message="Loading..." />
            </Container>
          );
        }

        if (error) {
          return (
            <Container>
              <StyledError error={error} />
            </Container>
          );
        }

        return (
          <Container className="organization" fluid>
            <Segment className="border-none organization-member-dashboard dashboard">
              <Segment className="header-container">
                <Breadcrumbs crumbs={this.getBreadCrumbs()} />
                <div className="flex justify-between items-center pb-5">
                  <h1 className="text-4xl font-bold">Unverified User</h1>
                  <div>
                    <AdminEmailButtons text="Verify Email" email={unverifiedUser.email} commonStore={this.props.commonStore} sendEmail={Agents.authGoogle.verifyEmail} />
                    <AdminEmailButtons text="Password Reset" email={unverifiedUser.email} commonStore={this.props.commonStore} sendEmail={Agents.authGoogle.passwordResetEmail} />
                  </div>
                </div>
                <p className="pb-3">
                  <span className="user-detail">Email:</span> {unverifiedUser.email}
                </p>
                <p>
                  <span className="user-detail">Created At:</span>
                  {moment(unverifiedUser.created_at).format('M/D/YYYY')}
                </p>
              </Segment>

              {unverifiedUser && unverifiedUser.sendgridEvents && unverifiedUser.sendgridEvents.length ? <AdminSendgridEvents events={unverifiedUser.sendgridEvents} /> : null}
            </Segment>
          </Container>
        );
      }
    }
  )
);

export default withRouter(CybraryAdminUnverifiedDashboard);
