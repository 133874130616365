/* eslint-disable no-console */
// import Store from 'react-notifications-component';
// import { ClockIcon } from '@heroicons/react/outline';
// import Box from './box';
// import NotificationConfig from './notifications-config';

// TODO: WE CANNOT USE REACT NOTIFICATIONS COMPONENT!!
// It's incompatible with react 17 and the docs for the old version are just straight up wrong.

const TimeNotification = (timeRemaining) => {
  console.log(timeRemaining, 'FIX NOTIFICATIONS');
  // Store.addNotification({
  //   ...NotificationConfig,
  //   content: () => (
  //     <Box className="flex items-center gap-x-4 w-full bg-neutral-900 text-gray-200 text-xl">
  //       <ClockIcon className="inline w-7 h-7 -mt-1 mr-2" />
  //       {timeRemaining}
  //     </Box>
  //   ),
  // });
};

export default TimeNotification;
