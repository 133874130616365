import { observable, action, makeObservable } from 'mobx';
import Bugsnag from '@bugsnag/js';
import SearchUtil from '../utils/searchUtil';
import FilterUtil from '../utils/filterUtil';

class SearchStore {
  userId = '';

  certCount = 0;

  exploreCertCount = 0;

  searchFilters = {
    filters: {},
    searchVal: '',
    q: '',
    page: 1,
    sort: '',
    license: '',
  };

  setDefaultSearchFilters = () => {
    this.searchFilters = {
      filters: {},
      searchVal: '',
      q: '',
      page: 1,
      sort: '',
      license: '',
    };
  };

  exploreSearchFilters = {};

  setDefaultExploreSearchFilters = () => {
    this.exploreSearchFilters = {};
  };

  setSearchQuery = (val) => {
    this.searchFilters.q = val;
  };

  setSearchVal = (val) => {
    this.searchFilters.searchVal = val;
  };

  setSort = (val) => {
    this.searchFilters.sort = val;
  };

  pageChange = (page) => {
    this.searchFilters.page = page || 1;
  };

  addFilter = (facet, filter) => {
    if (!this.searchFilters.filters[facet]) {
      this.searchFilters.filters[facet] = [];
    }
    if (this.searchFilters.filters[facet].indexOf(filter) === -1) {
      this.searchFilters.filters[facet].push(filter);
    }
  };

  addLicense = (value) => {
    this.searchFilters.license = value;
  };

  removeFilter = (facet, filter) => {
    return this.searchFilters.filters[facet].splice(this.searchFilters.filters[facet].indexOf(filter), 1);
  };

  searchItems = {
    loading: true,
    error: null,
    data: null,
    facets: [],
  };

  setDefaultSearchItems = () => {
    this.searchItems = {
      loading: true,
      error: null,
      data: null,
      facets: [],
    };
  };

  exploreSearchItems = {
    loading: true,
    data: null,
    facets: [],
  };

  setDefaultExploreSearchItems = () => {
    this.exploreSearchItems = {
      loading: true,
      data: null,
      facets: [],
    };
  };

  setSearchItemsLoading = (loading) => {
    this.searchItems.loading = loading;
  };

  setSearchItemsError = (error) => {
    this.searchItems.error = error;
  };

  constructor() {
    makeObservable(this, {
      userId: observable,
      certCount: observable,
      exploreCertCount: observable,
      searchFilters: observable,
      setDefaultSearchFilters: action,
      exploreSearchFilters: observable,
      setDefaultExploreSearchFilters: action,
      setSearchQuery: action,
      setSearchVal: action,
      setSort: action,
      pageChange: action,
      addFilter: action,
      addLicense: action,
      removeFilter: action,
      searchItems: observable,
      exploreSearchItems: observable,
      setDefaultSearchItems: action,
      setDefaultExploreSearchItems: action,
      setSearchItemsLoading: action,
      setSearchItemsError: action,
      setUserIdForSearchInsights: action,
      loadSearchItems: action,
      getExploreSearchItems: action,
      cipPromoData: observable,
      setCIPPromoData: action,
      getCIPPromoData: action,
    });
  }

  setUserIdForSearchInsights(userId) {
    this.userId = userId;
  }

  // Transform filter arrays to Algolia query clauses
  // All queries are treated as AND's between filters currently
  transformFilters = (filters) => {
    let query = '';
    Object.keys(filters).forEach((facet) => {
      const filtersArr = [];
      if (filters[facet].length) {
        filters[facet].forEach((filter) => {
          filtersArr.push(`'${filter}'`);
        });
        query += ` AND (${SearchUtil.buildFilters(facet, filtersArr, 'AND')})`;
      }
    });
    if (/groups_info:'certifications\|all'/.test(query)) {
      query = query.replace(/groups_info:'certifications\|all'/, "categories_info:'certifications'");
    }
    if (/new:'true'/.test(query)) {
      query = query.replace(/new:'true'/, `released_at_timestamp:${SearchUtil.getNewReleasesDateRangeQuery()}`);
    }
    return query;
  };

  formatSearchRequest = (params, category) => {
    return new Promise((resolve) => {
      SearchUtil.fetchFromAlgolia(...params)
        .then((res) => {
          const retObj = {};
          retObj[category] = res;
          resolve(retObj);
        })
        .catch((err) => {
          const retObj = {};
          retObj[category] = { err };
          resolve(retObj);
        });
    });
  };

  loadSearchItems = ({ hitsPerPage, categories } = {}) => {
    let catalogIndex = process.env.REACT_APP_INSTANTSEARCH_CATALOG_INDEX;

    this.setSearchItemsLoading(true);

    // use popular index (default) if left blank
    if (!this.searchFilters.sort) {
      catalogIndex += `_popular`;
    } else if (this.searchFilters.sort === 'Newest') {
      catalogIndex += `_newest`;
    }

    const promises = [];
    /* SearchUtil.getSearchCategories() is a function of common search categories that we use
       in getExploreSearchItems and getSearchItems. We add 'certificationPrep' to the array b/c for this
       query we also want certification prep content to access on the /browse/certification-prep page
    */
    const searchCategories = categories || [...SearchUtil.getSearchCategories(), 'certificationPrep'];

    const facets = FilterUtil.getFacets();
    let translatedLicense = null;

    if (this.searchFilters.license && 1 * this.searchFilters.license === 1) {
      translatedLicense = {
        has_paid_content: false,
        is_free: [1, true],
      };
    } else if (this.searchFilters.license && 1 * this.searchFilters.license === 0) {
      translatedLicense = {
        has_paid_content: true,
        is_free: [0, false],
      };
    }

    // Set up promises to retreive the categories of search results
    searchCategories.forEach((category) => {
      let filters = SearchUtil.getDefaultFiltersByCategory(category);
      filters += Object.keys(this.searchFilters.filters).length ? this.transformFilters(this.searchFilters.filters) : '';
      if (translatedLicense) {
        filters += ` AND (${SearchUtil.buildFilters('is_free', translatedLicense.is_free, 'OR')})`;
        filters += ` AND has_paid_content:${translatedLicense.has_paid_content}`;
      }
      const searchOptions = {
        filters,
        optionalFilters: [`tags_info:Featured`],
        hitsPerPage: hitsPerPage || 20,
      };
      const params = [
        catalogIndex,
        searchOptions,
        this.searchFilters.q,
        {
          providedPage: this.searchFilters.page - 1, // 0-based indexed
          facets,
          userId: this.userId,
        },
      ];
      promises.push(this.formatSearchRequest(params, category));
    });
    // Get all of the search queries at once
    Promise.all(promises).then((values) => {
      const srchRes = {}; // Flatten array of search results into one obj with key value being view
      if (!!values && values.length) {
        searchCategories.forEach((view, idx) => {
          srchRes[view] = values[idx][view];
        });
      }
      // Initialize searchItems.data if it doesn't exist
      this.searchItems.data = this.searchItems.data || {};
      // Update searchItems with the search results by merging newly loaded categories with existing categories
      Object.keys(srchRes).forEach((key) => {
        this.searchItems.data[key] = srchRes[key];
      });
      // The facets for all categories are using what's returned from the "ALL" query
      if (srchRes?.all?.facets) {
        this.searchItems.facets = FilterUtil.formattedSearchItemFacets(srchRes.all.facets);
        this.searchItems.facets.map((fct) => {
          if (fct.name === 'categories_info') {
            this.certCount = fct.data.certifications;
          }
          return true;
        });
      }
      this.setSearchItemsLoading(false);
    });
  };

  getExploreSearchItems = () => {
    const catalogIndex = process.env.REACT_APP_INSTANTSEARCH_CATALOG_INDEX;

    const promises = [];
    /* SearchUtil.getSearchCategories() is a function of common search categories that we use
       in getExploreSearchItems and loadSearchItems. We add 'certificationPrep' to the array b/c for this
       query we also want certification prep content to access on the /browse/certification-prep page
    */
    const searchCategories = SearchUtil.getSearchCategories();
    const facets = FilterUtil.getFacets();

    // Set up promises to retrieve the categories of search results
    searchCategories.forEach((category) => {
      let filters = SearchUtil.getDefaultFiltersByCategory(category);
      filters += Object.keys(this.exploreSearchFilters).length ? this.transformFilters(this.exploreSearchFilters) : '';
      const options = {
        filters,
      };
      const params = [
        catalogIndex,
        options,
        '',
        {
          providedPage: 0, // 0-based indexed
          facets,
          userId: this.userId,
        },
      ];
      promises.push(this.formatSearchRequest(params, category));
    });
    // Get all of the search queries at once
    Promise.all(promises).then((values) => {
      const srchRes = {}; // Flatten array of search results into one obj with key value being view
      if (!!values && values.length) {
        searchCategories.forEach((view, idx) => {
          srchRes[view] = values[idx][view];
        });
      }
      this.exploreSearchItems.data = srchRes;
      // The facets for all categories are using what's returned from the "ALL" query
      this.exploreSearchItems.facets = FilterUtil.formattedSearchItemFacets(srchRes.all.facets);
      this.exploreSearchItems.facets.forEach((fct) => {
        if (fct.name === 'categories_info') {
          this.exploreCertCount = fct.data.certifications;
        }
      });
      this.exploreSearchItems.loading = false;
    });
  };

  cipPromoData = null;

  setCIPPromoData(data) {
    this.cipPromoData = data;
  }

  getCIPPromoData(user) {
    SearchUtil.getPromoData('search_cip', user)
      .then((response) => {
        if (!!response && response.hits.length !== 0 && !!response.hits[0] && response.hits[0].content) {
          this.setCIPPromoData(response.hits[0].content);
        }
      })
      .catch((error) => {
        Bugsnag.notify(error);
      });
  }
}

export default new SearchStore();
