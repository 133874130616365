import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import queryString from 'query-string';
import InterstitialTemplate from '../../components/InterstitialTemplate/InterstitialTemplate';
import Divider from '../../components/Divider/Divider';
import Button from '../../components/Button/Button';

function TeamInviteInterstitial() {
  const params = useParams();
  const { uuid, key } = params;
  useEffect(() => {
    // If no UUID and key provided, just redirect user to dashboard (Shouldn't be possible as user should see 404 page)
    if (!uuid || !key) {
      window.location.href = `/`;
    }
  }, []);

  const getRedirectUrl = (destination) => {
    const queryParams = queryString.parse(window.location.search);
    const baseRedirectTo = `/team-join/${uuid}/key/${key}`;
    // Default redirect url to /register
    let url = `${process.env.REACT_APP_V2_SITE_URL}/register/`;
    // If sending to login, redirect to login and add query param to direct them to account settings after joining team
    if (destination === 'login') {
      queryParams.destination = 'account-settings';
      queryParams.action = 'add-team-email';
      url = process.env.REACT_APP_LOGIN_PATH;
    }
    const redirectToQueryString = Object.keys(queryParams).length ? `?${queryString.stringify(queryParams)}` : '';
    const formattedRedirect = `${url}?redirect_to=${encodeURIComponent(`${baseRedirectTo}${redirectToQueryString}`)}`;
    window.location.href = formattedRedirect;
  };

  return (
    <InterstitialTemplate>
      <div className="flex relative flex-col gap-x-36 items-center p-6 py-24 w-full lg:flex-row xl:w-9/12 xl:max-w-291">
        <div className="text-center lg:mb-0 lg:w-1/2">
          <h2 className="mb-4 text-2xl font-black text-center">Link Existing Account</h2>
          <p className="mb-6 text-sm text-center">To link your account, login with your existing credentials.</p>
          <Button
            onClick={() => {
              getRedirectUrl('login');
            }}
          >
            Login
          </Button>
        </div>
        <Divider vertical className="hidden absolute inset-y-0 my-3 lg:flex" style={{ left: 'calc(50% - 0.75rem)' }}>
          <span className="text-gray-600 uppercase">or</span>
        </Divider>
        <Divider horizontal className="py-16 w-4/5 lg:hidden">
          <span className="text-gray-600 uppercase">or</span>
        </Divider>
        <div className="text-center lg:w-1/2">
          <h2 className="mb-4 text-2xl font-black text-center">Create New Account</h2>
          <p className="mb-6 text-sm text-center">Proceed to set up your new account.</p>
          <Button
            onClick={() => {
              getRedirectUrl('register');
            }}
          >
            Create New Account
          </Button>
        </div>
      </div>
    </InterstitialTemplate>
  );
}

export default TeamInviteInterstitial;
