import React from 'react';

export default function Email({ classes, withRectangle, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';
  return (
    <svg className={className} viewBox="0 0 64 64" fill="currentColor" {...accessibilityProps}>
      {withRectangle ? <rect width="64" height="64" rx="0" ry="0" fill="#7f7f7f" /> : null}{' '}
      <path d="M17,22v20h30V22H17z M41.1,25L32,32.1L22.9,25H41.1z M20,39V26.6l12,9.3l12-9.3V39H20z" />
    </svg>
  );
}
