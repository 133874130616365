/**
 * Static Helper functions
 */

/**
 * Deconstructs a category slug into its parent and child slugs
 * discourseCategorySlug looks like "cert-prep/cissp/8".
 * We need to split the last part off to get the category ID and slug seperated
 * @param {string} slug
 * @returns {object} - { categoryId, categorySlug }
 */
export const deconstructCategorySlug = (slug = '') => {
  // If we don't have a slug, return an empty object
  if (!slug) {
    return {};
  }
  const categorySlugParts = slug.split('/'); // ["cert-prep", "cissp", "8"]
  const categoryId = categorySlugParts.pop(); // "8"
  const categorySlug = categorySlugParts.join('/'); // "cert-prep/cissp"
  return { categoryId, categorySlug }; // { categoryId: "8", categorySlug: "cert-prep/cissp" }
};
