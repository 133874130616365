import React, { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import Section from './Section';

// Local variable to ensure that the timeout doesn't get run if the user is flipping through different activities.
let mounted = false;

/**
 * Legacy article component
 */

export default function LegacyArticleSection({ isComplete, completeActivity, content }) {
  useEffect(() => {
    mounted = true;
    // Auto-complete articles after 10 seconds, if not already complete
    if (!isComplete) {
      setTimeout(() => {
        if (mounted) {
          completeActivity();
        }
      }, 10000);
    }

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <Section>
      <ReactMarkdown className="has-markdown-inside">{content}</ReactMarkdown>
    </Section>
  );
}
