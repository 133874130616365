import React from 'react';

export const COMPACT_GRID_Y_VALUES = [0, 20, 40, 60, 80, 100];

// Generate <tspan /> groups to properly wrap text in svg <text /> element
export const getTspanGroups = (value, maxLineLength = 18, maxLines = 3, padded = false) => {
  const words = value.split(' ');

  // reduces the words into lines of maxLineLength
  const assembleLines = words.reduce(
    (acc, word) => {
      // if the current line isn't empty and the word + current line is larger than the allowed line size, create a new line and update current line
      if (`${word} ${acc.currLine}`.length > maxLineLength && acc.currLine !== '') {
        return {
          lines: acc.lines.concat([acc.currLine]),
          currLine: word,
        };
      }
      // otherwise add the word to the current line
      return {
        ...acc,
        currLine: acc.currLine ? `${acc.currLine} ${word}` : word,
      };
    },
    { lines: [], currLine: '' }
  );

  // add the ending state of current line (the last line) to lines
  const allLines = assembleLines.lines.concat([assembleLines.currLine]);

  const lines = allLines.slice(0, maxLines);
  const children = [];

  lines.forEach((lineText, i) => {
    const dy = padded && i === 0 ? 0 : 18;

    children.push(
      <tspan x={0} dy={dy} key={`${lineText + i}`}>
        {lineText}
      </tspan>
    );
  });

  return children;
};

export function CustomBarChartBottomTick(tick) {
  return (
    <g transform={`translate(${tick.x},${tick.y})`}>
      <line x1={0} x2={tick.lineX} y1={0} y2={tick.lineY} style={{ stroke: 'rgb(119, 119, 119)', strokeWidth: 1 }} />
      <text
        dominantBaseline={tick.textBaseline}
        textAnchor={tick.textAnchor}
        transform={`translate(${tick.textX},${tick.textY}) rotate(0)`}
        className="fill-gray-700"
        style={{ fontSize: 12 }}
      >
        {getTspanGroups(tick.value, 20, 2, true)}
      </text>
    </g>
  );
}

export function CustomBarChartBottomTickCompact(tick, tickCount) {
  const maxLineLength = tickCount >= 5 ? 10 : 20;
  const maxLines = tickCount >= 5 ? 3 : 2;
  return (
    <g transform={`translate(${tick.x},${tick.y})`}>
      <line x1={0} x2={tick.lineX} y1={0} y2={tick.lineY} style={{ stroke: 'rgb(119, 119, 119)', strokeWidth: 1 }} />
      <text
        dominantBaseline={tick.textBaseline}
        textAnchor={tick.textAnchor}
        transform={`translate(${tick.textX},${tick.textY}) rotate(0)`}
        className="fill-gray-700"
        style={{ fontSize: 10 }}
      >
        {getTspanGroups(tick.value, maxLineLength, maxLines, true)}
      </text>
    </g>
  );
}

export function CustomBarChartTickCompact(tick) {
  if (!COMPACT_GRID_Y_VALUES.includes(tick.value)) {
    return null;
  }

  return (
    <g transform={`translate(${tick.x},${tick.y})`}>
      <line x1={0} x2={tick.lineX} y1={0} y2={0} style={{ stroke: 'rgb(119, 119, 119)', strokeWidth: 1 }} />
      <text dominantBaseline={tick.textBaseline} textAnchor={tick.textAnchor} transform={`translate(${tick.textX},${tick.textY}) rotate(0)`} className="text-xs fill-gray-700">
        {tick.value}%
      </text>
    </g>
  );
}

function CustomBarChartTick(tick) {
  return (
    <g transform={`translate(${tick.x},${tick.y})`}>
      <line x1={0} x2={tick.lineX} y1={0} y2={0} style={{ stroke: 'rgb(119, 119, 119)', strokeWidth: 1 }} />
      <text dominantBaseline={tick.textBaseline} textAnchor={tick.textAnchor} transform={`translate(${tick.textX},${tick.textY}) rotate(0)`} className="text-xs fill-gray-700">
        {tick.value}%
      </text>
    </g>
  );
}

export default CustomBarChartTick;
