import { getCN } from '../../utils/formatUtil';

const fieldClasses = (error, disabled, width = 'w-full') =>
  getCN(`
    appearance-none border bg-white rounded py-2 px-2 mb-1 focus:outline-none focus:border-blue-400 text-gray-700 
    ${width} 
    ${error ? 'border-red-500 bg-red-50 placeholder-gray-400' : 'border-gray-300 placeholder-gray-600'} 
    ${disabled && 'bg-gray-300 opacity-50 cursor-not-allowed'}
  `);

export default fieldClasses;
