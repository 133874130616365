import React, { useMemo, useCallback } from 'react';
import { observer, inject } from 'mobx-react';
import Container from '../../components/Container/Container';
import DynamicForm from '../../components/DynamicForm/DynamicForm';
import { LANGUAGES } from '../../utils/countries';

const DEFAULT_VALUE_ENG = LANGUAGES.find((lang) => lang.value === 'en-US');

const LanguagePreferencesForm = inject(
  'userStore',
  'commonStore'
)(
  observer(({ userStore, commonStore }) => {
    const currentLanguage = userStore?.user?.language;

    const formData = useMemo(
      () => ({
        order: ['locale', 'submit'],
        fields: {
          locale: {
            type: 'select',
            label: 'Preferred Language',
            defaultValue: LANGUAGES.find((lang) => lang.value === currentLanguage)?.value || DEFAULT_VALUE_ENG.value,
            options: LANGUAGES,
          },
          submit: {
            type: 'button',
            id: 'submit-language-settings',
            color: 'gray',
            className: 'grey-submit-button',
            label: 'Save Changes',
          },
        },
      }),
      [currentLanguage]
    );

    const onSubmit = useCallback(async (data) => {
      await userStore?.updateLanguagePreference(data.locale);

      commonStore.triggerToast('success', {
        content: 'Language preference has been saved!',
      });
    }, []);

    return (
      <Container>
        <DynamicForm form={formData} formName="language-preferences" onSubmit={onSubmit} />
      </Container>
    );
  })
);

export default LanguagePreferencesForm;
