import moment from 'moment';
import Bugsnag from '@bugsnag/js';
import FormatUtil from './formatUtil';
import agents from '../agents/agents';
import { CHARGEBEE_CIP_ANNUAL, CHARGEBEE_REGIONS } from '../constants';

const usCurrencyCode = 'USD';

const chargebee = {
  getOffer: (subscription, estimate) => {
    const offer = chargebee.determineOffer(subscription, estimate);

    const {
      actual_unit_price, // currentPrice user is paying for their plan including discounts
      plan_unit_price, // currentBasePrice of the users plan without discounts
      expiration_date, // nextPaymentDate
    } = subscription;
    const { total, subTotal, couponAmount, couponId } = estimate;

    offer.currentPrice = FormatUtil.convertCentsToDollars(actual_unit_price, usCurrencyCode);
    offer.newPrice = FormatUtil.convertCentsToDollars(total, usCurrencyCode);
    offer.currentBasePrice = FormatUtil.convertCentsToDollars(plan_unit_price, usCurrencyCode);
    offer.newBasePrice = FormatUtil.convertCentsToDollars(subTotal, usCurrencyCode);
    offer.dollarAmountOffPrice = FormatUtil.convertCentsToDollars(couponAmount, usCurrencyCode); // coupon dollar amount off
    offer.couponId = couponId;
    offer.nextPaymentDate = moment.unix(expiration_date).format('DD MMMM YYYY');

    return offer;
  },
  determineOffer: (subscription, estimate) => {
    const offer = {
      applyCoupon: false,
      adjustPricing: false,
    };
    const { actual_unit_price, plan_unit_price, scheduled_changes } = subscription;
    const { total, subTotal } = estimate;

    const isCurrentCheapestPrice = actual_unit_price <= total;
    const isNewBasePriceCheaper = plan_unit_price > subTotal && actual_unit_price > subTotal;
    const isNewBasePriceWithCancellationCheaper = plan_unit_price > subTotal && actual_unit_price > total;
    const isCancellationPriceCheaper = plan_unit_price === subTotal && actual_unit_price > total;
    const isNewBasePriceMoreExpensive = plan_unit_price < subTotal && actual_unit_price > total;
    const hasScheduledChanges = scheduled_changes !== false;
    if (isCurrentCheapestPrice || isNewBasePriceMoreExpensive || hasScheduledChanges) {
      // do nothing
      return offer;
    }
    offer.adjustPricing = isNewBasePriceCheaper || isNewBasePriceWithCancellationCheaper;
    offer.applyCoupon = (isNewBasePriceWithCancellationCheaper || isCancellationPriceCheaper) && !isNewBasePriceCheaper;

    return offer;
  },

  /**
   * Cover the estimates fetched from Chargebee
   */
  convertEstimate: (estimate) => {
    const { amount_due, total, line_items, line_item_discounts } = estimate;
    const sub_total = line_items[0].amount;
    let couponAmount = '';
    let couponId = '';
    if (line_item_discounts && line_item_discounts.length) {
      const { discount_amount, coupon_id } = line_item_discounts[0];
      couponAmount = discount_amount;
      couponId = coupon_id;
    }
    return {
      total,
      amountDue: amount_due,
      subTotal: sub_total,
      couponAmount,
      couponId,
    };
  },

  /**
   * Fetch the estimate of the product
   */
  fetchEstimate: async (targetSubscription, planRegion) => {
    if (!targetSubscription?.plan_id) return null;
    const { plan_id } = targetSubscription;
    const payload = {
      subscription: {
        plan_id,
        quantity: 1,
      },
    };
    try {
      // if the user is not in the IN region, add the coupon id
      if (planRegion !== 'IN') {
        const regionalAnnualPlanId = CHARGEBEE_REGIONS[planRegion]?.cip?.annual || CHARGEBEE_CIP_ANNUAL; // get the regional annual plan id
        const isCurrentPlanAnnual = plan_id === regionalAnnualPlanId; // check if the current plan is annual
        const couponId = isCurrentPlanAnnual ? process.env.REACT_APP_ANNUAL_CIP_CANCEL_COUPON : process.env.REACT_APP_MONTHLY_CIP_CANCEL_COUPON; // get the coupon id
        payload.coupon_ids = [couponId]; // add the coupon id to the payload
      }
      // fetch the estimate
      const estimates = await agents.auth.createSubscriptionEstimate(payload);
      if (estimates?.invoice_estimate) {
        // convert the estimate to a more readable format
        return chargebee.convertEstimate(estimates.invoice_estimate);
      }
    } catch (err) {
      Bugsnag.notify(err);
      // if we get a 422 error, try again without the coupon id
      if (err?.response?.status === 422 && payload.coupon_ids.length) {
        return chargebee.fetchEstimate({
          subscription: {
            plan_id,
            quantity: 1,
          },
          coupon_ids: [],
        });
      }
    }
    return null;
  },
};

const paddle = {
  /**
   * Compare the current subscription with the subscription preview
   * @param {object} currentSubscription Current subscription object from Paddle
   * @param {object} subscriptionPreview Subscription preview object from Paddle
   * @returns {object} Offer object
   */
  getOffer: (currentSubscription, subscriptionPreview) => {
    // Get current and new prices
    const subscriptionPreviewTotals = subscriptionPreview?.recurring_transaction_details?.totals || {};
    const currentSubscriptionTotals = currentSubscription?.recurring_transaction_details?.totals || {};
    // convert the prices to cents / integers
    const currentTotalPrice = parseInt(currentSubscriptionTotals?.total, 10);
    const newTotalPrice = parseInt(subscriptionPreviewTotals?.total, 10);
    const currentBasePrice = parseInt(currentSubscriptionTotals?.subtotal, 10);
    const newBasePrice = parseInt(subscriptionPreviewTotals?.subtotal, 10);
    const currencyCode = currentSubscriptionTotals?.currency_code || 'USD'; // use original currency code, default to USD

    const offer = {
      applyCoupon: false,
      adjustPricing: false,
      couponId: subscriptionPreview?.discount?.id, // set the discount id from the subscription preview for reuse later if needed
      currencyCode,
      items: subscriptionPreview?.recurring_transaction_details?.items || [],
    };
    // set the items from the subscription preview
    const newLineItems = subscriptionPreview?.recurring_transaction_details?.line_items || [];
    offer.items = newLineItems.map(({ price_id, quantity }) => {
      return { price_id, quantity };
    });
    // set the next payment date
    offer.nextPaymentDate = moment(currentSubscription?.next_billed_at).format('DD MMMM YYYY');

    // convert the prices to dollars / formatted strings
    offer.currentBasePrice = FormatUtil.convertCentsToDollars(currentBasePrice, currencyCode);
    offer.currentPrice = FormatUtil.convertCentsToDollars(currentTotalPrice, currencyCode);
    offer.newBasePrice = FormatUtil.convertCentsToDollars(newBasePrice, currencyCode);
    offer.newPrice = FormatUtil.convertCentsToDollars(newTotalPrice, currencyCode);

    // set the discount amount
    offer.dollarAmountOffPrice = FormatUtil.convertCentsToDollars(currentTotalPrice - newTotalPrice, currencyCode); // coupon dollar amount off

    // set price checks for decision tree.
    // @see: https://docs.google.com/spreadsheets/d/1sj2t2LBA3Dmuq7gHB7Rh5ca_bK59za9XPxs243-uXl0/edit#gid=2109869191
    offer.isCurrentCheapestPrice = currentTotalPrice <= newTotalPrice;
    offer.isNewBasePriceCheaper = currentBasePrice > newBasePrice && currentTotalPrice > newBasePrice;
    offer.isNewBasePriceWithCancellationCheaper = currentBasePrice > newBasePrice && currentTotalPrice > newTotalPrice;
    offer.isCancellationPriceCheaper = currentBasePrice === newBasePrice && currentTotalPrice > newTotalPrice;
    offer.isNewBasePriceMoreExpensive = currentBasePrice < newBasePrice && currentTotalPrice > newTotalPrice;

    // if the current subscription is the cheapest price, or we already have a scheduled change, return the offer with no adjustments or coupons
    if (offer.isCurrentCheapestPrice || currentSubscription.scheduled_change) {
      return offer;
    }
    offer.adjustPricing = offer.isNewBasePriceCheaper || offer.isNewBasePriceWithCancellationCheaper;
    offer.applyCoupon = (offer.isNewBasePriceWithCancellationCheaper || offer.isCancellationPriceCheaper) && !offer.isNewBasePriceCheaper;
    return offer;
  },
};

const CancellationUtil = {
  chargebee,
  paddle,
  /**
   * Fetch the subscription from the backend
   * @param {string} subscriptionId paddle/chargebee subscription id
   * @param {int} attempt number of attempts to fetch the subscription
   * @returns {object} subscription object
   */
  fetchTargetSubscription: async (subscriptionId, attempt = 1) => {
    // get all user's subscription info
    const allSubscriptions = await agents.auth.getSubscriptions();
    // if there are no subscriptions, try again a few time otherwise, return null
    if (!allSubscriptions) return attempt < 2 ? CancellationUtil.fetchTargetSubscription(subscriptionId, attempt + 1) : null;
    // filter to only the subscription we want
    const targetSubscription = allSubscriptions.filter((subscription) => subscription.id === subscriptionId);
    return targetSubscription[0];
  },
};
export default CancellationUtil;
