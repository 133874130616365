import { observable, action, set, makeObservable } from 'mobx';
import Bugsnag from '@bugsnag/js';
import SearchUtil from '../utils/searchUtil';
import PermalinkUtil from '../utils/permalinkUtil';
import Agents from '../agents/agents';
import currentEnrollmentTransformer from '../transformers/currentEnrollmentTransformer';

class DashboardStore {
  bannerContentLoading = true;

  bannerContentError = false;

  bannerContentData = null;

  dashboardSections = [];

  dashboardSectionsData = {};

  getUserType = (isCip, isEnterprise) => {
    let userType = 'open_users';
    if (isCip) {
      userType = 'cip_users';
    } else if (isEnterprise) {
      userType = 'enterprise_users';
    }
    return userType;
  };

  constructor() {
    makeObservable(this, {
      bannerContentLoading: observable,
      bannerContentError: observable,
      bannerContentData: observable,
      dashboardSections: observable,
      dashboardSectionsData: observable,
      setBannerContentLoading: action,
      setBannerContentError: action,
      setBannerContentData: action,
      setSectionContentError: action,
      setDashboardSectionData: action,
      loadBannerContentData: action,
      communitySection: observable,
      getCommunitySection: action,
      setDashboardSectionsObj: action,
      setDashboardSectionsData: action,
      getDashboardSections: action,
      recommendedCourses: observable,
      setRecommendedCoursesLoading: action,
      setRecommendedCoursesError: action,
      setRecommendedCoursesData: action,
      currentEnrollment: observable,
      resetCurrentEnrollment: action,
      setCurrentEnrollment: action,
      getCurrentEnrollment: action,
    });
  }

  setBannerContentLoading(loading) {
    this.bannerContentLoading = loading;
  }

  setBannerContentError(error) {
    this.bannerContentError = error;
  }

  setBannerContentData(bannerContentData) {
    set(this, 'bannerContentData', bannerContentData);
  }

  setSectionContentError(section, error) {
    set(this.dashboardSectionsData, section, error);
  }

  /**
   * Shuffles array in place. ES6 version
   * @param {Array} a items An array containing the items.
   */
  shuffle(a) {
    const b = [...a];
    for (let i = b.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [b[i], b[j]] = [b[j], b[i]];
    }
    return b;
  }

  setDashboardSectionData(section, data) {
    const sectionObj = this.dashboardSectionsData[section];
    const newSection = {
      ...sectionObj,
      items: sectionObj.options.random === true ? this.shuffle(data.hits).slice(0, sectionObj.options.displayAmt) : data.hits,
      loading: false,
    };
    set(this.dashboardSectionsData, section, newSection);
  }

  loadBannerContentData(userType, registeredThisWeekFreeUser) {
    this.setBannerContentLoading(true);
    let filters = SearchUtil.buildFilters('section', ['banner']);
    filters += ` AND ${SearchUtil.buildFilters('hidden', ['false'])}`;
    const registeredThisWeekFreeUserClause = registeredThisWeekFreeUser ? ' AND ' : ' AND NOT ';
    filters += registeredThisWeekFreeUserClause + SearchUtil.buildFilters('registered_this_week_free_user', ['true']);
    filters += ` AND ${SearchUtil.buildFilters(userType, ['true'])}`;
    const dashboardIndex = process.env.REACT_APP_INSTANTSEARCH_DASHBOARD_INDEX;
    const options = {
      hitsPerPage: 1,
      filters,
    };
    return SearchUtil.fetchFromAlgolia(dashboardIndex, options)
      .then(
        action('fetchSuccess', (response) => {
          this.setBannerContentData(response.hits[0]);
          this.setBannerContentLoading(false);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.setBannerContentError(error.response || error);
          this.setBannerContentLoading(false);
        })
      );
  }

  communitySection = {
    data: null,
    loading: true,
    error: false,
  };

  getCommunitySection = (userType) => {
    this.communitySection.loading = true;
    let filters = SearchUtil.buildFilters('pageSection', ['community']);
    filters += ` AND ${SearchUtil.buildFilters('hidden', ['false'])}`;
    filters += ` AND ${SearchUtil.buildFilters(userType, ['true'])}`;
    const dashboardIndex = process.env.REACT_APP_INSTANTSEARCH_DASHBOARD_INDEX;
    const options = {
      hitsPerPage: 1,
      filters,
    };
    return SearchUtil.fetchFromAlgolia(dashboardIndex, options)
      .then(
        action('fetchSuccess', (response) => {
          // eslint-disable-next-line prefer-destructuring
          this.communitySection.data = response.hits[0];
          this.communitySection.loading = false;
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.communitySection.error = error.response || error;
          this.communitySection.loading = false;
        })
      );
  };

  setDashboardSectionsObj = (data, userType, userGoal) => {
    const sectionsArr = [];
    data.forEach((section) => {
      if (section[userType] && !section.hidden && (!section.goal || section.goal.indexOf(userGoal) > -1)) {
        sectionsArr.push(section.section);
        const newSection = {
          title: section.title || section.section.split('_').join(' '),
          subtitle: section.subtitle || '',
          href: section.href,
          loading: true,
          error: false,
          items: null,
          options: {
            displayAmt: section.displayAmt,
            random: section.random,
            format: section.format,
            itemsPerRow: section.items_per_row,
          },
        };
        set(this.dashboardSectionsData, section.section, newSection);
        this.setDashboardSectionsData(section, userType);
      }
    });
    set(this, 'dashboardSections', sectionsArr);
  };

  setDashboardSectionsData(section, userType) {
    const { section: sectionKey, displayAmt, random, filters, index_postfix } = section;

    // Sections that have defined Algolia filters (not hardcoded individual content items)
    if (filters?.length) {
      let sectionFilters = `${SearchUtil.buildFilters('display_in_catalog', ['true'])}`;
      filters.forEach((filter) => {
        sectionFilters += ` AND (${SearchUtil.buildFilters(filter.filterKey, filter.filterValue)})`;
      });
      const catalogIndex = `${process.env.REACT_APP_INSTANTSEARCH_CATALOG_INDEX}${index_postfix || ''}`;
      const hitsPerPage = displayAmt || 5;
      const options = {
        hitsPerPage,
        filters: sectionFilters,
      };
      return SearchUtil.fetchFromAlgolia(catalogIndex, options)
        .then(
          action('fetchSuccess', (response) => {
            // if we have hits for this section, transform data, otherwise set to empty array to be ignored later
            const formattedHits =
              response && response.hits && response.hits.length
                ? response.hits.map((hit) => {
                    return {
                      ...hit,
                      description: {
                        title: hit.title,
                      },
                      image: hit.thumbnail_url,
                      href: PermalinkUtil.formatInApp(hit.permalink),
                    };
                  })
                : [];
            response.hits = formattedHits;

            this.setDashboardSectionData(sectionKey, response);
          })
        )
        .catch(
          action('fetchError', (error) => {
            Bugsnag.notify(error);
          })
        );
    }

    // Sections that have hardcoded individual content items, not just filters to retrieve content accordingly
    let sectionFilters = SearchUtil.buildFilters('pageSection', [sectionKey]);
    sectionFilters += ` AND ${SearchUtil.buildFilters('hidden', ['false'])}`;
    sectionFilters += ` AND ${SearchUtil.buildFilters(userType, ['true'])}`;
    const dashboardIndex = process.env.REACT_APP_INSTANTSEARCH_DASHBOARD_INDEX;
    const hitsPerPage = random ? 100 : displayAmt;
    const options = {
      hitsPerPage,
      filters: sectionFilters,
    };
    return SearchUtil.fetchFromAlgolia(dashboardIndex, options)
      .then(
        action('fetchSuccess', (response) => {
          this.setDashboardSectionData(sectionKey, response);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
        })
      );
  }

  getDashboardSections(userType, userGoal) {
    const filters = SearchUtil.buildFilters('dashboardSections', ['true']);
    const dashboardIndex = process.env.REACT_APP_INSTANTSEARCH_DASHBOARD_INDEX;
    const options = {
      hitsPerPage: 1,
      filters,
    };
    return SearchUtil.fetchFromAlgolia(dashboardIndex, options)
      .then(
        action('fetchSuccess', (response) => {
          if (response.hits[0]) {
            this.setDashboardSectionsObj(response.hits[0].sections, userType, userGoal);
          }
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
        })
      );
  }

  recommendedCourses = {
    'Recommended Courses': {
      error: false,
      href: '',
      items: null,
      loading: true,
      options: {
        displayAmt: 5,
        random: true,
      },
      title: 'Recommended For You',
    },
  };

  setRecommendedCoursesLoading(loading) {
    this.recommendedCourses['Recommended Courses'].loading = loading;
  }

  setRecommendedCoursesError(error) {
    this.recommendedCourses['Recommended Courses'].error = error;
  }

  // Loads and transforms recommended courses for dashboard cards display
  setRecommendedCoursesData(data) {
    this.setRecommendedCoursesLoading(true);
    if (data) {
      this.setRecommendedCoursesLoading(false);
      const transformedArr = data.map((course) => {
        return {
          image: course.thumbnail_url,
          description: {
            title: course.title,
            body: course.short_description,
          },
          playBtn: true,
        };
      });
      // Apply randomness and trim to the display amount
      this.recommendedCourses['Recommended Courses'].items = this.shuffle(transformedArr).slice(0, this.recommendedCourses['Recommended Courses'].options.displayAmt);
    }
  }

  currentEnrollment = {
    data: null,
    loading: true,
    error: false,
  };

  resetCurrentEnrollment = () => {
    this.currentEnrollment = {
      data: null,
      loading: true,
      error: false,
    };
  };

  setCurrentEnrollment = (key, val) => {
    this.currentEnrollment[key] = val;
  };

  getCurrentEnrollment = () => {
    return Agents.enrollments
      .getCurrentEnrollment()
      .then(
        action('fetchSuccess', (response) => {
          // If we have current enrollment data, lets transform it and we're finished here
          if (!!response && Object.keys(response).length) {
            const transformedData = currentEnrollmentTransformer.transformCurrentEnrollmentData(response, true);
            this.setCurrentEnrollment('data', transformedData);
            this.setCurrentEnrollment('loading', false);
            return response;
          }
          // If there is no current enrollment, we are going to show the Intro to IT course, if not already completed
          return Agents.catalog
            .getContentDescriptionByPermalink('course/introduction-to-it-and-cybersecurity')
            .then(
              action('fetchSuccess', (data) => {
                // Check to make sure this isn't already complete. If so, we don't want to show
                return Agents.catalog
                  .getCourseProgress(data.id)
                  .then(
                    action('fetchSuccess', (progress) => {
                      if (!!progress && progress.content_progress && !progress.content_progress.percent_completed) {
                        const transformedData = currentEnrollmentTransformer.transformCurrentEnrollmentData(data, false);
                        this.setCurrentEnrollment('data', transformedData);
                      }
                      this.setCurrentEnrollment('loading', false);
                    })
                  )
                  .catch(
                    action('fetchError', (error) => {
                      Bugsnag.notify(error);
                      this.setCurrentEnrollment('error', error);
                      this.setCurrentEnrollment('loading', false);
                    })
                  );
              })
            )
            .catch(
              action('fetchError', (error) => {
                Bugsnag.notify(error);
                this.setCurrentEnrollment('error', error);
                this.setCurrentEnrollment('loading', false);
              })
            );
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.setCurrentEnrollment('error', error);
          this.setCurrentEnrollment('loading', false);
        })
      );
  };
}

export default new DashboardStore();
