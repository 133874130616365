import React from 'react';
import X from '../../Icons/X';
import BrowseUtil from '../../utils/browseUtil';

/**
 * Section banner for each content type
 */

export default function SectionBanner({ isViewAll, sectionNiceName, sectionBanners, handleSectionBannerDismiss }) {
  const showBanner = sectionBanners.indexOf(sectionNiceName) === -1;
  if (isViewAll || !showBanner) {
    return null;
  }
  const sectionLongDescription = BrowseUtil.getSectionDescription(sectionNiceName).long;
  return (
    <div className="flex overflow-hidden relative flex-col justify-center items-start p-4 my-4 w-full h-60 bg-black rounded-sm lg:p-0 lg:pl-12">
      <p className="mb-6 text-2xl font-black text-white lg:w-96">{sectionNiceName}</p>
      <p className="text-sm text-white lg:w-96">{sectionLongDescription}</p>
      <button aria-label={`Dismiss ${sectionNiceName} banner`} onClick={() => handleSectionBannerDismiss(sectionNiceName)} className="group absolute top-4 right-4 cursor-pointer">
        <X classes="h-6 w-6 text-white group-hover:text-cyb-pink-500" />
      </button>
    </div>
  );
}
