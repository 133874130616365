import React from 'react';
import AddLink from '../AddLink/AddLink';

function SupplementalMaterials({ items }) {
  if (!items || !items.length) {
    return null;
  }
  return (
    <ul className="list-none">
      {items.map((item) => (
        <li className="pb-6" key={item.title}>
          <AddLink target="_blank" className="hover:font-semibold text-black hover:text-black underline hover:underline" to={item.url}>
            {item.title}
          </AddLink>
          {item.description && <div className="font-normal">{item.description}</div>}
        </li>
      ))}
    </ul>
  );
}

export default SupplementalMaterials;
