import React, { useCallback } from 'react';
import { USER_ONBOARDING_STEPS, useUserOnboarding } from '../../../providers/UserOnboardingProvider';

import CIPFormStep from '../OnboardingSteps/User/CIPFormStep';
import TeamFormStep from '../OnboardingSteps/User/TeamFormStep';
import RecommendationsStep from '../OnboardingSteps/User/RecommendationsStep';
import MembershipForStep from '../OnboardingSteps/User/MembershipForStep';
import SelectPackageStep from '../OnboardingSteps/User/SelectPackageStep';
import FinishedStep from '../OnboardingSteps/User/FinishedStep.js';

function UserOnboardingRouter() {
  const { step } = useUserOnboarding();

  const UserOnboardingStepJsx = useCallback(() => {
    switch (step) {
      case USER_ONBOARDING_STEPS.CIP_FORM:
        return <CIPFormStep />;
      case USER_ONBOARDING_STEPS.TEAM_FORM:
        return <TeamFormStep />;
      case USER_ONBOARDING_STEPS.CONTENT_RECOMMENDATIONS:
        return <RecommendationsStep />;
      case USER_ONBOARDING_STEPS.SELECT_PACKAGE:
        return <SelectPackageStep />;
      case USER_ONBOARDING_STEPS.FINISHED:
        return <FinishedStep />;
      case USER_ONBOARDING_STEPS.INITIAL:
      default:
        return <MembershipForStep />;
    }
  }, [step]);

  return <UserOnboardingStepJsx />;
}

export default UserOnboardingRouter;
