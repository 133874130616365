import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { observer, inject } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { useCybMeasure } from '../../providers/CybMeasureProvider';
import { useCybAssessmentAnalytics } from '../../providers/CybAssessmentAnalyticsProvider';
import Container from '../../components/Container/Container';
import Title from '../../components/Title/Title';
import If from '../../components/If/If';
import Loading from '../../components/Loading/Loading';
import AddLink from '../../components/AddLink/AddLink';
import Checkbox from '../../components/FormFields/Checkbox';
import LearnerBaselineGoalsWidget from '../../components/Measure/Baseline/LearnerBaselineGoalsWidget';
import BaselineOverviewWidget from '../../components/Measure/Baseline/BaselineOverviewWidget';
import UserAccessDeniedPrompt from '../../components/Measure/Baseline/shared/UserAccessDeniedPrompt';
import { isFreeOrCipUser } from '../../utils/navUtil';

const Baseline = inject(
  'userStore',
  'goalsStore',
  'authStore',
  'commonStore'
)(
  observer(({ userStore, goalsStore, authStore, commonStore }) => {
    const navigate = useNavigate();

    const { tracks, isLoading: isLoadingTracks } = useCybAssessmentAnalytics();

    const { isTeamLearner, isTeamAdmin, isLoadingBaselineGoals, learnerBaselineGoals, getLearnerBaselineGoalDetails, getTeamBaselineGoals, topics, contextMode, setContextMode } =
      useCybMeasure();

    const orgId = userStore?.teamData?.id;

    const isLoading = isLoadingTracks || isLoadingBaselineGoals;

    const isNonTeamUser = isFreeOrCipUser(userStore);

    const isTeamLearnerWithoutGoal = useMemo(() => {
      const baselineGoals = learnerBaselineGoals[orgId];

      if (!baselineGoals) {
        return null;
      }

      return isTeamLearner && Boolean(baselineGoals?.length === 0);
    }, [isTeamLearner, learnerBaselineGoals, orgId]);

    const teamTopics = useMemo(() => topics[orgId], [topics, orgId]);

    const roles = useMemo(() => {
      const userTracks = tracks[userStore.user?.id]?.filter((track) => track.status !== 'Unlisted').concat();

      if (!userTracks) {
        return null;
      }

      // for team learners (non-admin), only show roles that have an assigned goal,
      // this should usually only be one at a time - but can possibly be concurrent
      if (isTeamLearner) {
        const baselineGoals = learnerBaselineGoals[orgId];

        // get a list of the track (assessment path / role) ids represented by the assigned baseline goals
        const assignedGoalsTrackIds = baselineGoals?.map((goal) => goal.content_description_id);

        return userTracks.filter((track) => assignedGoalsTrackIds?.includes(track['content-id']));
      }

      return userTracks;
    }, [tracks, isTeamLearner, learnerBaselineGoals, orgId, userStore.user?.id]);

    const [selectedRole, setSelectedRole] = useState(roles?.[0] ?? null);

    const [selectedTopic, setSelectedTopic] = useState(teamTopics?.[0] ?? null);

    const selectedContentDescription = useMemo(() => (contextMode === 'topic' ? selectedTopic : selectedRole), [contextMode, selectedRole, selectedTopic]);

    const onChangeContentDescription = useCallback(
      (contentDescriptionId) => {
        if (contextMode === 'topic') {
          const topic = teamTopics?.find((item) => item.id === contentDescriptionId);

          if (topic) {
            setSelectedTopic(topic);
          }
        } else {
          const role = roles?.find((item) => item['content-id'] === contentDescriptionId);

          if (role) {
            setSelectedRole(role);
          }
        }
      },
      [roles, teamTopics, contextMode]
    );

    const handleSelectedContentDescriptionUpdate = useCallback(
      (contentDescription) => {
        const contentDescriptionId = contentDescription['content-id'] ?? contentDescription.id;

        if (isTeamLearner) {
          const baselineGoals = learnerBaselineGoals[orgId];

          const targetedGoal = baselineGoals?.find((goal) => goal.content_description_id === contentDescriptionId);

          if (targetedGoal) {
            getLearnerBaselineGoalDetails(orgId, targetedGoal.id, contentDescriptionId, true);
          }
        } else {
          getTeamBaselineGoals(orgId, contentDescriptionId, true);
        }
      },
      [orgId, isTeamLearner, learnerBaselineGoals, getLearnerBaselineGoalDetails, getTeamBaselineGoals]
    );

    const handleContextModeChange = useCallback(() => {
      setContextMode((prevState) => (prevState === 'role' ? 'topic' : 'role'));
    }, []);

    useEffect(() => {
      commonStore.setPageTitle('Measure - Baselining | Cybrary');
      authStore.fireAttributionEvent();
    }, []);

    useEffect(() => {
      // onMount-ish (whenever the `roles` or `teamTopics` value is initialized),
      // set the initial value for `selectedRole` or `selectedTopic` if it doesn't already exist
      if (contextMode === 'topic' && teamTopics?.length > 0 && !selectedTopic && !isNonTeamUser) {
        setSelectedTopic(teamTopics[0]);
      } else if (roles?.length > 0 && !selectedRole && !isNonTeamUser) {
        setSelectedRole(roles[0]);
      }
    }, [roles, teamTopics, contextMode, isNonTeamUser]);

    useEffect(() => {
      if (selectedContentDescription && !isNonTeamUser) {
        handleSelectedContentDescriptionUpdate(selectedContentDescription);
      }
    }, [selectedContentDescription, isNonTeamUser]);

    useEffect(() => {
      if (!isLoading && isTeamLearnerWithoutGoal !== null && isTeamLearnerWithoutGoal) {
        navigate('/measure/assessments');
      }
    }, [isLoading, isTeamLearnerWithoutGoal]);

    return (
      <Container className="pt-0 sm-576:px-4" md={!isNonTeamUser} fluid={isNonTeamUser} omitPadding>
        <If condition={isNonTeamUser}>
          <UserAccessDeniedPrompt userId={userStore?.user?.id} />
        </If>
        <If condition={!isNonTeamUser}>
          <>
            <div className="flex flex-row justify-between items-center">
              <Title title="Baselining" wrapperClasses="my-8" classes="text-2xl lg:text-2xl" omitPadding />
              <div className="flex print:hidden justify-end items-center text-sm">
                <p className="pr-2 pb-2 mb-0 font-bold">By Role</p>
                <Checkbox toggle onChange={handleContextModeChange} checked={contextMode === 'topic'} ariaLabelledBy="toggle-context-mode" />
                <p className="pb-2 pl-2 mb-0 font-bold">By Topic</p>
              </div>
            </div>
            <div className="flex flex-col">
              <div className="flex flex-col py-8 px-14 mb-8 text-base bg-gray-100 border border-gray-300">
                <If condition={isTeamLearner}>
                  <p>
                    Measure your strengths and weaknesses in key topic areas. Get custom learning recommendations from Cybrary’s content catalog to improve your scores. Re-assess
                    to measure improvement.
                  </p>
                </If>
                <If condition={isTeamAdmin}>
                  <p>
                    Assign Cybrary’s role-based assessments to measure your team’s strengths and weaknesses in key topic areas. Get custom learning recommendations from Cybrary’s
                    content catalog to improve your team’s scores. Re-assess your team to measure improvement.
                  </p>
                </If>
              </div>
            </div>
            <If condition={isLoading}>
              <Loading message="Loading..." />
            </If>
            <If condition={!isLoading}>
              <If condition={isTeamLearnerWithoutGoal}>
                <p>
                  <span>No baselining data avaialble. Click</span>
                  <AddLink className="text-cyb-pink-500 hover:underline" to="/measure/assessments">
                    {' '}
                    here{' '}
                  </AddLink>
                  <span>to view your assessments.</span>
                </p>
              </If>
              <If condition={!isTeamLearnerWithoutGoal}>
                <If condition={isTeamLearner}>
                  <LearnerBaselineGoalsWidget orgId={orgId} selectedRole={selectedRole} goalsStore={goalsStore} />
                </If>
                <BaselineOverviewWidget
                  orgId={orgId}
                  roles={roles}
                  selectedContentDescription={selectedContentDescription}
                  onChangeContentDescription={onChangeContentDescription}
                />
              </If>
            </If>
          </>
        </If>
      </Container>
    );
  })
);

export default Baseline;
