import React, { useState } from 'react';
import { twMerge } from 'tailwind-merge';
import Icon from '../Icon/Icon';
import PlatformFeedBackModal from '../Feedback/PlatformFeedbackModal';

function PlatformFeedback({ iconClasses, buttonClasses }) {
  const [isOpen, setIsOpen] = useState(false);
  const [step, setStep] = useState('step1');

  const buttonClassesMerged = twMerge('group flex items-center px-4 text-gray-600 hover:text-black cursor-pointer', buttonClasses);
  const iconClassesMerged = twMerge('mx-auto w-7 h-7 text-center', iconClasses);

  const handleClose = () => {
    setIsOpen(false);
    setStep('step1');
  };

  return (
    <>
      <button onClick={() => setIsOpen(true)} className={buttonClassesMerged}>
        <Icon name="chat" className={iconClassesMerged} />
        <p className="pl-4 mb-0 text-lg text-gray-600 group-hover:text-black">Feedback</p>
      </button>
      <PlatformFeedBackModal isOpen={isOpen} handleClose={handleClose} step={step} setStep={setStep} />
    </>
  );
}
export default PlatformFeedback;
