import React, { useState, useEffect } from 'react';
import { getCN } from '../../utils/formatUtil';

function RadioButtons({
  name,
  id,
  customRenderer,
  options,
  block,
  onChange,
  value,
  defaultValue,
  fieldsPerRow,
  labelClassName,
  inputClassName,
  wrapperClassName,
  omitLabelMarginRight,
}) {
  const labelTextClasses = getCN(`items-center cursor-pointer
    ${labelClassName || 'text-gray-600'}
    ${block ? 'mb-1 block' : ''}
    ${!block && !omitLabelMarginRight ? 'mr-10' : ''}
    ${customRenderer ? 'sr-only' : ''}
  `);

  const labelWrapperClasses = getCN(block ? '' : 'flex items-start');

  const radioContainer = getCN(`grid 
    ${fieldsPerRow === 1 && 'grid-cols-1'}
    ${fieldsPerRow === 2 && 'grid-cols-2'}
    ${fieldsPerRow === 3 && 'grid-cols-3'}
    ${fieldsPerRow === 4 && 'grid-cols-4'}
  `);

  const inputClasses = getCN(`
    w-4 h-4 mr-2 
    ${block ? '' : 'mt-1 shrink-0'}
    ${inputClassName}
    ${customRenderer ? 'sr-only peer' : ''}
  `);

  const [input, setInput] = useState(defaultValue || null);

  useEffect(() => {
    if (defaultValue) {
      setInput(defaultValue);
    }
  }, [defaultValue]);

  const handleChange = (changedValue) => {
    if (changedValue !== input) {
      setInput(changedValue);
      onChange(undefined, { name, value: changedValue });
    }
    return changedValue;
  };

  const val = typeof value !== 'undefined' ? value : input;
  return (
    <div className={`${wrapperClassName || radioContainer}`}>
      {options.map((radio, index) => {
        // Props to pass to input or custom renderer
        const fieldProps = {
          name,
          id: `${name}-${index}`,
          value: radio.value,
          checked: val === radio.value,
        };
        const label = radio.label || radio.text || radio.value;

        return (
          <React.Fragment key={`${name}-${radio.value}`}>
            <label htmlFor={`${name}-${index}`} className={labelWrapperClasses}>
              <input type="radio" onChange={(e) => handleChange(e.target.value)} {...fieldProps} className={inputClasses} />
              <span className={labelTextClasses}>{label}</span>
              {customRenderer && customRenderer({ ...fieldProps, label })}
            </label>
          </React.Fragment>
        );
      })}
      {/* @important The input field below is for tracking purposes with snowplow and form submissions.  */}
      <input className="sr-only" type="text" id={id} name={id} value={input || ''} readOnly />
    </div>
  );
}

export default RadioButtons;
