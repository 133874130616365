import React, { useState, useRef, useEffect } from 'react';
import ChevronRight from '../../Icons/ChevronRight';
import ChevronLeft from '../../Icons/ChevronLeft';
import Icon from '../Icon/Icon';

function checkThenNavigate(fromStep, toStep, setActiveStep, stepHeadingRef, toStepIdx, visibleSteps) {
  if (fromStep.onStepSwitch) {
    fromStep.onStepSwitch(fromStep, toStep);
  }
  if (toStep.shouldSkipNavigation) {
    const stepAfterNext = visibleSteps[toStepIdx + 1];
    if (stepAfterNext) {
      stepHeadingRef.current.scrollIntoView({ block: 'start' });
      setActiveStep(stepAfterNext.id);
    }
  } else if (!toStep.preventNavigation) {
    stepHeadingRef.current.scrollIntoView({ block: 'start' });
    setActiveStep(toStep.id);
  }
}

function NextStepButton({ nextStepIdx, visibleSteps, setActiveStep, currStep, submitText, isValid, onSubmit, stepHeadingRef, submitId }) {
  const nextStep = visibleSteps[nextStepIdx];
  const nextStepClasses = 'rounded-sm bg-cyb-pink-500 text-white font-bold text-center hover:text-white hover:bg-pink-600 leading-5 py-2.5 px-6 text-sm';
  const isDisabled = !nextStep?.shouldSkipNavigation && Boolean(nextStep?.preventNavigation);

  if (visibleSteps && visibleSteps.length && nextStepIdx < visibleSteps.length) {
    let buttonText = currStep.nextText || nextStep.content.heading;

    if (nextStep?.shouldSkipNavigation) {
      const stepAfterNext = visibleSteps[nextStepIdx + 1];
      buttonText = stepAfterNext?.content?.heading || nextStep.content.heading;
    }

    return (
      <button
        onClick={() => {
          checkThenNavigate(currStep, nextStep, setActiveStep, stepHeadingRef, nextStepIdx, visibleSteps);
        }}
        aria-disabled={isDisabled}
        className={`${nextStepClasses} ${isDisabled ? 'opacity-50' : ''}`}
      >
        {buttonText}
        <ChevronRight classes="ml-2 w-4 h-4 inline-block" />
      </button>
    );
  }
  if (visibleSteps && visibleSteps.length && nextStepIdx === visibleSteps.length && onSubmit) {
    return (
      <button id={submitId} onClick={() => onSubmit(currStep)} className={`${nextStepClasses} ${!isValid ? 'opacity-50' : ''}`} aria-disabled={!isValid}>
        {submitText || 'Submit'}
      </button>
    );
  }

  return <div />;
}

function PrevNextButtons({ currStep, currStepIdx, setActiveStep, visibleSteps, submitText, isValid, onSubmit, stepHeadingRef, submitId }) {
  const nextStepIdx = currStepIdx + 1;
  const prevStepIdx = currStepIdx - 1;
  const prevStep = visibleSteps[prevStepIdx];

  return (
    <div className="flex justify-evenly items-center mt-4 sm:justify-between sm:px-8">
      {prevStepIdx >= 0 ? (
        <button
          onClick={() => {
            checkThenNavigate(currStep, prevStep, setActiveStep, stepHeadingRef);
          }}
          aria-label={`Go back to ${prevStep.content.heading}`}
          className="text-sm underline cursor-pointer"
        >
          <ChevronLeft classes="w-4 h-4 mr-2 inline-block" />
          {prevStep.content.heading}
        </button>
      ) : (
        <div />
      )}
      <NextStepButton
        nextStepIdx={nextStepIdx}
        visibleSteps={visibleSteps}
        setActiveStep={setActiveStep}
        currStep={currStep}
        submitText={submitText}
        isValid={isValid}
        onSubmit={onSubmit}
        stepHeadingRef={stepHeadingRef}
        submitId={submitId}
      />
    </div>
  );
}

function StepperIcon({ idx, isValid, isActive }) {
  const classes = 'w-6 h-6 font-bold rounded-full text-white flex items-center justify-center text-sm';
  let bgColor = 'bg-gray-600';
  if (isValid) {
    bgColor = 'bg-green-500';
  }
  if (isActive) {
    bgColor = 'bg-cyb-pink-500';
  }
  return <div className={`${classes} ${bgColor}`}>{isValid ? <Icon name="check" className="w-6 h-4" /> : idx}</div>;
}

function Wizard({ steps, startStep, omitStepper, disableStepperNav, errorMessage, scrollToError, submitText, isValid, onSubmit, contentContainerStyles, submitId }) {
  const stepHeadingRef = useRef();
  const errorRef = useRef();
  const [activeStep, setActiveStep] = useState(startStep || steps[0].id);
  useEffect(() => {
    if (scrollToError && errorMessage) {
      errorRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [errorMessage]);

  const visibleSteps = steps.filter((step) => !step.hidden);
  const currStepIdx = visibleSteps.findIndex((step) => step.id === activeStep);
  const currStep = visibleSteps[currStepIdx];
  const contentWrapperStyles = contentContainerStyles || { height: 'calc(100vh - 21rem)' };
  return (
    <div className="md:flex">
      {!omitStepper && (
        <div className="hidden px-8 pb-4 min-w-full border-b-xs border-gray-400 md:block md:pb-0 md:min-w-0 md:border-r-xs md:border-b-0" style={{ width: '20vw' }}>
          {visibleSteps.map((step, idx) => {
            const isActive = idx === currStepIdx;
            if (step.hidden) {
              return null;
            }
            return (
              <button
                className={`flex text-left ${step.preventNavigation ? 'cursor-default' : 'cursor-pointer'} ${step.content.stepperDetails ? 'mb-2' : 'mb-7'}`}
                key={step.id}
                onClick={() => step.id !== activeStep && !disableStepperNav && !step.preventNavigation && checkThenNavigate(currStep, step, setActiveStep, stepHeadingRef)}
              >
                <div className="">
                  <StepperIcon isValid={step.isValid} idx={idx + 1} isActive={isActive} />
                </div>
                <div className="ml-3">
                  <div className="font-bold">{step.content.stepperHeading || step.content.heading}</div>
                  {step.content.stepperDetails ? <div className="text-sm text-gray-600">{step.content.stepperDetails}</div> : null}
                </div>
              </button>
            );
          })}
        </div>
      )}
      <div className="flex-1 pt-4 md:pt-0">
        <h2 className="pl-2 mb-2 text-2xl font-black text-black sm:px-8">{currStep.content.heading}</h2>
        <div className="overflow-y-auto relative pl-2 sm:px-8" style={contentWrapperStyles}>
          <div>
            {currStep.content.description && <div className="mb-4 text-sm text-gray-600">{currStep.content.description}</div>}
            <div ref={stepHeadingRef} />
            {currStep.additionalContent}
          </div>
          <div ref={errorRef}>
            {errorMessage && (
              <div className="flex items-center my-4 text-sm text-cyb-red-700">
                <Icon name="exclamation-circle" className="shrink-0 mr-2 w-6 h-6" /> {errorMessage}
              </div>
            )}
          </div>
        </div>
        <PrevNextButtons
          currStepIdx={currStepIdx}
          currStep={currStep}
          setActiveStep={setActiveStep}
          checkThenNavigate={checkThenNavigate}
          stepHeadingRef={stepHeadingRef}
          visibleSteps={visibleSteps}
          submitText={submitText}
          isValid={!!isValid}
          onSubmit={onSubmit}
          submitId={submitId}
        />
      </div>
    </div>
  );
}

export default Wizard;
