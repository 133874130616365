import Bugsnag from '@bugsnag/js';
import { useCallback, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import agents from '../../agents/agents';
import usePaddleSubscription from './usePaddleSubscription';
import { DEFAULT_COOKIE_SETTINGS } from '../../constants';

const COOKIE_KEY = 'cyb_paddle_ctm';

/**
 * Hook to load the Paddle customer ID from the backend and cache it in cookies
 * We use cookies to share the customer ID between the app and the marketing website (if needed)
 * @param {object} userStore The user store from the MobX Provider
 * @returns {object} Object containing the customer ID, loading state, and error state
 */
const usePaddleCustomerId = (userStore) => {
  const [customerId, setCustomerId] = useState(null);
  const { getCustomerId } = usePaddleSubscription();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getPaddleCustomerId = useCallback(async () => {
    try {
      // Check cookie for existing cookies first
      const customerIdFromCookie = Cookies.get(COOKIE_KEY);
      if (customerIdFromCookie) {
        setCustomerId(customerIdFromCookie);
        setLoading(false);
        return;
      }
      // no customer ID in the cookie, fetch it from the backend
      const subscriptions = await agents.auth.getSubscriptions();
      // no subscriptions, no fetch
      if (!subscriptions?.length) return;
      // Otherwise, get the customer ID from the first paddle subscription
      const subscription = subscriptions.find((sub) => sub.source === 'paddle');
      // no paddle subs, no fetch
      if (!subscription) return;
      // featch customer ID using the usePaddleSubscription hook
      const response = await getCustomerId(subscription.id);
      setCustomerId(response);
      // Cache the customer ID in a cookie for a week
      Cookies.set(COOKIE_KEY, response, { ...DEFAULT_COOKIE_SETTINGS, expires: 7 });
    } catch (err) {
      Bugsnag.notify(err);
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [customerId, getCustomerId]);

  const userId = userStore?.user?.id;

  useEffect(() => {
    // If we don't have a user, reset state
    if (!userId) {
      setCustomerId(null);
    } else {
      // Otherwise we can fetch the customer ID whenever the user changes
      getPaddleCustomerId();
    }
  }, [userId]);

  return { customerId, loading, error };
};
export default usePaddleCustomerId;
