import { observable, action, makeObservable } from 'mobx';
import Bugsnag from '@bugsnag/js';
import FormatUtil from '../utils/formatUtil';
import NotificationUtil from '../utils/notificationUtil';
import Agents from '../agents/agents';

class NotificationsStore {
  cybraryCurriculaUpdates = null;

  preferencesData = {
    loading: true,
    error: false,
    submitError: false,
    data: {},
  };

  contentNotificationsInit = false;

  contentNotifications = {
    'My Enrollments': 0,
    Paths: {},
  };

  setContentNotifications = (name, val) => {
    this.contentNotifications[name] = val;
    this.contentNotificationsInit = true;
  };

  setCybraryCurriculaUpdates = (data) => {
    this.cybraryCurriculaUpdates = data;
  };

  getCybraryCurriculaUpdates = () => {
    return Agents.enterprise
      .getCybraryCurriculaUpdates()
      .then(
        action('fetchSuccess', (response) => {
          this.setCybraryCurriculaUpdates(response);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
        })
      );
  };

  userAssignments = null;

  setUserAssignments = (data) => {
    this.userAssignments = data;
  };

  // Get all of user's assignments - For checking if they use a Cybrary curricula that was updated (for notification)
  getUserAssignments = () => {
    return Agents.enrollments
      .getAssignmentsList('?isActivity=0')
      .then(
        action('fetchSuccess', (response) => {
          this.setUserAssignments(response);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
        })
      );
  };

  // Get only the needed children details for a content description based on an array of provided ID's
  getChangedChildDetails = (changedIds, contentChildren) => {
    const changedChildDetails = {};
    // If we have changedId's and we have content children, loop over the children and break them into arrays by type, if the child is found in the changedId's array
    if (!!changedIds && changedIds.length && !!contentChildren) {
      contentChildren.forEach((child) => {
        if (changedIds.indexOf(child.id) > -1) {
          const contentType = child.content_type && child.content_type.nice_name ? child.content_type.nice_name : child.type;
          const type = FormatUtil.formatContentType(contentType);
          if (!changedChildDetails[type]) {
            changedChildDetails[type] = [];
          }
          const childObj = {
            title: child.title,
            id: child.id,
          };
          changedChildDetails[type].push(childObj);
        }
      });
    }
    return changedChildDetails;
  };

  getActivitiesList = (modules) => {
    const activitiesArr = [];
    if (!modules) {
      return activitiesArr;
    }

    modules.forEach((module) => {
      if (module.activities && module.activities.length) {
        activitiesArr.push(...module.activities);
      }
    });
    return activitiesArr;
  };

  // Transform content updates. Gets only the data actually used and breaks down what is new (added/updated) vs removed by type
  transformContentUpdates = (updateDetails, oldDescription, newDescription) => {
    let transformedUpdates = {};
    // Need different data back for courses vs other content types
    let oldChildren = null;
    let newChildren = null;
    // If this is a collection, the children are in a different place than courses and other content
    // Need to find children at different places
    if (oldDescription.content_item) {
      if (oldDescription.content_item.learning_modules) {
        oldChildren = this.getActivitiesList(oldDescription.content_item.learning_modules);
      } else if (oldDescription.content_item.children) {
        oldChildren = oldDescription.content_item.children;
      }
    }
    // New children
    if (newDescription.content_item) {
      if (newDescription.content_item.learning_modules) {
        newChildren = this.getActivitiesList(newDescription.content_item.learning_modules);
      } else if (newDescription.content_item.children) {
        newChildren = newDescription.content_item.children;
      }
    }

    transformedUpdates = {
      title: newDescription.title,
      scheduled_at: updateDetails.scheduled_at,
      archive_at: updateDetails.archive_at,
      description: updateDetails.description,
      new_content_description_id: updateDetails.new_content_description_id,
      newContentType: newDescription.content_type.nice_name,
      old_content_description_id: updateDetails.old_content_description_id,
      'Removed Content': this.getChangedChildDetails(updateDetails.removed_content_ids, oldChildren),
      'New Content': this.getChangedChildDetails(updateDetails.added_content_ids, newChildren),
      'Updated Content': this.getChangedChildDetails(updateDetails.updated_content_ids, newChildren),
      new_content_status: updateDetails.settings && updateDetails.settings.new_content_status ? updateDetails.settings.new_content_status : 'Active',
    };
    return transformedUpdates;
  };

  contentReplacement = {
    data: null,
    loading: true,
    error: false,
  };

  getContentReplacement = (replacementId, hasUpdated) => {
    this.contentReplacement.loading = true;
    this.contentReplacement.error = false;

    return Agents.contentVersioning
      .getContentReplacement(replacementId)
      .then((response) => {
        const contentReplacementDetails = response;
        // After getting the updates data, we need to get the expanded content description for the old and new content
        // First we will get the old content description
        Agents.catalog
          .getContentDescriptionById(contentReplacementDetails.old_content_description_id)
          .then((oldResponse) => {
            contentReplacementDetails.oldContentDescription = oldResponse;
            // Then we will get the new content description
            Agents.catalog
              .getContentDescriptionById(contentReplacementDetails.new_content_description_id)
              .then((newResponse) => {
                // If update has already happened - We don't show before/after progress for updates
                if (!hasUpdated) {
                  this.getContentReplacementProgress(contentReplacementDetails.new_content_description_id);
                }
                contentReplacementDetails.newContentDescription = newResponse;
                this.contentReplacement.data = this.transformContentUpdates(contentReplacementDetails, oldResponse, newResponse);
                this.contentReplacement.loading = false;
              })
              .catch((error) => {
                this.contentReplacement.error = error;
              });
          })
          .catch((error) => {
            this.contentReplacement.error = error;
            this.contentReplacement.loading = false;
          });
      })
      .catch((error) => {
        this.contentReplacement.error = error;
      });
  };

  contentReplacementProgress = null;

  setContentReplacementProgress = (data) => {
    this.contentReplacementProgress = data;
  };

  getContentReplacementProgress = (id) => {
    return Agents.contentVersioning
      .getContentReplacementProgress(id)
      .then(
        action('fetchSuccess', (response) => {
          if (response.content_progress && response.content_progress.percent_completed !== null) {
            this.setContentReplacementProgress(response.content_progress.percent_completed);
          }
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
        })
      );
  };

  enrollmentsReplaced = null;

  setEnrollmentsReplaced = (data) => {
    this.enrollmentsReplaced = data;
  };

  getEnrollmentsReplaced = () => {
    return Agents.contentVersioning
      .getEnrollmentsReplaced()
      .then(
        action('fetchSuccess', (response) => {
          this.setEnrollmentsReplaced(response);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
        })
      );
  };

  setCurriculaNotifications = (userTeams) => {
    const curriculaNotifications = NotificationUtil.getCurriculaNotifications(this.cybraryCurriculaUpdates, this.teamCurriculaUpdates, this.userCurriculaUpdates, userTeams);
    this.setContentNotifications('Paths', curriculaNotifications);
  };

  // Team curricula updates per team
  teamCurriculaUpdates = null;

  setTeamCurriculaWithUpdates = (teamId, updates) => {
    if (!this.teamCurriculaUpdates) {
      this.teamCurriculaUpdates = {};
    }
    this.teamCurriculaUpdates[teamId] = updates;
  };

  // User curricula updates per team
  userCurriculaUpdates = null;

  setUserCurriculaWithUpdates = (teamId, updates) => {
    if (!this.userCurriculaUpdates) {
      this.userCurriculaUpdates = {};
    }
    this.userCurriculaUpdates[teamId] = updates;
  };

  filterUpdatedCurricula = (teams, teamCurricula, userId) => {
    // Loop over teams
    Object.keys(teams || {}).forEach((team, idx) => {
      const teamData = teamCurricula[idx];
      // Filter out all curricula that doesn't have a team ID (Cybrary curriculum) or a `content_replaced_at` date
      if (teamData) {
        const filteredData = teamData.filter((curriculum) => {
          if (curriculum.content_replaced_at && curriculum.team_id === 1 * team) {
            return true;
          }
          return false;
        });
        // If the curriculum's author_id matches the user's ID, it's a user curricula. Separate them out
        const filteredTeamCurricula = [];
        const filteredUserCurricula = [];
        filteredData.forEach((item) => {
          if (item.author_id === userId) {
            filteredUserCurricula.push(item);
          } else {
            filteredTeamCurricula.push(item);
          }
        });
        this.setUserCurriculaWithUpdates(team, filteredUserCurricula);
        this.setTeamCurriculaWithUpdates(team, filteredTeamCurricula);
      }
    });
  };

  // Content items (children) replaced in parent
  replacedContent = null;

  resetReplacedContent = () => {
    this.replacedContent = null;
  };

  setReplacedContent = (data) => {
    this.replacedContent = data;
  };

  getReplacedContent = (id) => {
    return Agents.contentVersioning
      .getReplacedContent(id)
      .then(
        action('fetchSuccess', (response) => {
          this.setReplacedContent(response);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
        })
      );
  };

  getTeamCurricula = (teams, userId) => {
    const curriculaPromises = [];
    Object.keys(teams || {}).forEach((teamId) => {
      // Team discovery gives user a team record even when they aren't on the team, but no role. Only get curricula for teams user is actually on
      if (teams[teamId] && teams[teamId].role) {
        curriculaPromises.push(Agents.enterprise.getCurricula(teamId));
      }
    });
    return Promise.all(curriculaPromises).then((values) => {
      this.filterUpdatedCurricula(teams, values, userId);
      return values;
    });
  };

  getUserNotifications = (userStore) => {
    const { isEnterprise, userTeams, user } = userStore;
    if (!this.contentNotificationsInit) {
      // Get team curricula - Need to show notification if Cybrary curricula have updated
      if (isEnterprise) {
        // Get team curricula - Need to show notification if Cybrary curricula have updated
        this.getCybraryCurriculaUpdates().then(() => {
          if (this.cybraryCurriculaUpdates && this.cybraryCurriculaUpdates.length) {
            // Get user assignments - Need to show notification if assignment containing a Cybrary curriculum has been updated
            this.getUserAssignments().then(() => {
              const assignmentNotifications = NotificationUtil.getAssignmentNotificationsCount(this.cybraryCurriculaUpdates, this.userAssignments);
              // Get enrollment notifications, add count of notifications with the count for assignment updates
              this.getEnrollmentsNotifications().then((response) => {
                this.setContentNotifications('My Enrollments', assignmentNotifications + response);
              });
            });
          } else {
            // There aren't any cybrary curricula updates - Still check for stand-alone enrollment notifications
            this.getEnrollmentsNotifications().then((response) => {
              this.setContentNotifications('My Enrollments', response);
            });
          }
          // Get All curricula for each team, to see if any team curricula contain content that has been updated
          /**   Note - If field `content_replaced_at` contains a date, there is content in the curriculum that's been updated
                Cybrary curricula (curriculum with no team ID) also have this field, but we don't use it to base notifications
                off of for those because the user does not have a choice on the update - It's already done.
                The notifications from getCybraryCurriculaUpdates (for Cybrary curricula) will stop after they are 2 weeks old
          */
          this.getTeamCurricula(userTeams, user.id).then(() => {
            this.setCurriculaNotifications(userTeams);
          });
        });
      } else {
        this.getEnrollmentsNotifications().then((response) => {
          this.setContentNotifications('My Enrollments', response);
        });
      }
    }
  };

  getEnrollmentsNotifications = () => {
    return this.getEnrollmentsReplaced().then(() => {
      // If anything comes back, we can show a notification on My Enrollments
      if (!!this.enrollmentsReplaced && this.enrollmentsReplaced.length) {
        return NotificationUtil.getEnrollmentsReplacedNotificationsCount(this.enrollmentsReplaced);
      }
      return 0;
    });
  };

  setNotificationPreferences = (data) => {
    this.preferencesData.data = data;
  };

  setNotificationPreferenceLoading(loading) {
    this.preferencesData.loading = loading;
  }

  getPreferences = () => {
    this.setNotificationPreferenceLoading(true);
    return Agents.notifications
      .getPreferences()
      .then(
        action('fetchSuccess', (response) => {
          this.setNotificationPreferences(response);
          this.setNotificationPreferenceLoading(false);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.preferencesData.error = error.response || error;
          this.setNotificationPreferenceLoading(false);
        })
      );
  };

  updatePreferences = (preferences) => {
    return Agents.notifications.updatePreferences(preferences);
  };

  constructor() {
    makeObservable(this, {
      cybraryCurriculaUpdates: observable,
      preferencesData: observable,
      setCybraryCurriculaUpdates: action,
      getCybraryCurriculaUpdates: action,
      userAssignments: observable,
      setUserAssignments: action,
      getUserAssignments: action,
      contentReplacement: observable,
      getContentReplacement: action,
      contentReplacementProgress: observable,
      setContentReplacementProgress: action,
      getContentReplacementProgress: action,
      enrollmentsReplaced: observable,
      setEnrollmentsReplaced: action,
      getEnrollmentsReplaced: action,
      teamCurriculaUpdates: observable,
      setTeamCurriculaWithUpdates: action,
      userCurriculaUpdates: observable,
      setUserCurriculaWithUpdates: action,
      filterUpdatedCurricula: action,
      replacedContent: observable,
      resetReplacedContent: action,
      setReplacedContent: action,
      setNotificationPreferences: action,
      setNotificationPreferenceLoading: action,
      getPreferences: action,
      updatePreferences: action,
      contentNotificationsInit: observable,
      contentNotifications: observable,
      setContentNotifications: action,
    });
  }
}

export default new NotificationsStore();
