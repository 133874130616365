/* eslint-disable no-unused-vars */
import { useCallback, useState } from 'react';
import agents from '../agents/agents';
import BugsnagUtil from '../utils/bugsnagUtil';

/**
 * This hook is used to fetch the discourse data from the backend
 * It is READ ONLY and should not be used to update the discourse data
 */
function useDiscourse() {
  /**
   * Top Topics
   */
  const [topTopics, setTopTopics] = useState();
  const [isTopTopicsLoading, setIsTopTopicsLoading] = useState(false);
  const [topTopicsError, setTopTopicsError] = useState(null);

  /**
   * Fetch the top topics by views from the discourse backend and sets the topTopics state
   * @param {object} options - (Optional) options to pass to the discourse api
   */
  const fetchTopTopics = useCallback(async (options) => {
    setIsTopTopicsLoading(true);
    try {
      const response = await agents.discourse.getTopTopics(options);
      setTopTopics(response?.topic_list?.topics);
      return response;
    } catch (error) {
      BugsnagUtil.notify(error);
      setTopTopicsError(error);
    } finally {
      setIsTopTopicsLoading(false);
    }
    return null;
  }, []);

  /** Topics For Category */
  const [topicsForCategory, setTopicsForCategory] = useState();
  const [isTopicsForCategoryLoading, setIsTopicsForCategoryLoading] = useState(false);
  const [topicsForCategoryError, setTopicsForCategoryError] = useState(null);

  /**
   * Fetch the topics for a category from the discourse backend and sets the categoryTopics state
   * @param {string} categorySlug - the slug of the category to fetch topics for. i.e. "cert-prep/cissp"
   * @param {number} categoryId - the id of the category to fetch topics for i.e. 8
   * @param {string} filter - (Optional) filter to pass to the discourse api - [latest, unread, new, unseen, top, read, posted, bookmarks]
   * @param {object} options - (Optional) options to pass to the discourse api
   * @see https://meta.discourse.org/t/how-can-i-filter-paginate-the-list-categorytopics-route/290560/2
   */
  const fetchTopicsForCategory = useCallback(async (categorySlug, categoryId, filter, options) => {
    // Require categorySlug and/or categoryId
    if (!categorySlug || !categoryId) {
      return null;
    }
    setIsTopicsForCategoryLoading(true);
    try {
      const response = await agents.discourse.getTopicsByCategory(categorySlug, categoryId, filter, options);
      setTopicsForCategory(response);
      return response;
    } catch (error) {
      BugsnagUtil.notify(error);
      setTopicsForCategoryError(error);
    } finally {
      setIsTopicsForCategoryLoading(false);
    }
    return null;
  }, []);

  return {
    topTopics,
    isTopTopicsLoading,
    topTopicsError,
    fetchTopTopics,

    topicsForCategory,
    isTopicsForCategoryLoading,
    topicsForCategoryError,
    fetchTopicsForCategory,
  };
}

export default useDiscourse;
