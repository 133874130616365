import React from 'react';
import { Fade } from 'react-awesome-reveal';
import FormatUtil from '../../utils/formatUtil';

function Price({ price = '0.00', className, currencyCode = 'USD' }) {
  const currencySymbol = FormatUtil.convertCurrencyCodeToSymbol(currencyCode);
  const [dollars, cents] = price.split('.');
  return (
    <div className={`${className} flex items-start justify-start justify-items-start`}>
      <Fade>
        <p className="mb-0 text-lg font-bold leading-4">{currencySymbol}</p>
        <p className="mb-0 text-5xl font-bold leading-8">{dollars}</p>
      </Fade>
      <div className="inline-flex flex-col justify-between ml-1">
        <Fade delay={500}>
          <p className="mb-0 leading-4">
            {cents?.substring(0, 2)}
            {/** Sometimes we get floats back with no hundreths place, append 0 if it happens */}
            {cents?.length === 1 ? '0' : ''}
          </p>
        </Fade>
        <Fade delay={1000}>
          <p className="mb-0 leading-4">/mo</p>
        </Fade>
      </div>
    </div>
  );
}

export default Price;
