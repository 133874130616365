/**
 * Util for all things Google Analytics.
 * This file contains code for both universal analytics using the react-ga package
 * and Ga4 thats being pulled in directly.
 */
import { debounce } from 'lodash';
import ReactGA from 'react-ga';
import { trackPageView } from '@snowplow/browser-tracker';
import AuthUtil from './authUtil';

class GaUtil {
  fireEvent = (action, category, label) => {
    ReactGA.event({
      category,
      action,
      label,
    });
  };

  fireGaPageView = debounce((pagePath) => {
    const pageNameVal = pagePath || window.location.pathname + window.location.search;
    ReactGA.pageview(pageNameVal);

    // Send pageview to Snowplow
    // Will break out or abstract this method depending on whether we move forward with Snowplow
    if (process.env.REACT_APP_SNOWPLOW_COLLECTOR && process.env.REACT_APP_SNOWPLOW_ID) {
      trackPageView();
    }
  }, 1000);

  fireCustomDimension = (dimensionIndex, dimensionValue) => {
    ReactGA.set({
      [dimensionIndex]: dimensionValue,
    });
  };

  // push event object to data layer for tracking
  pushEventToDataLayer = (event = {}) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(event);
  };

  pushLoginEventsToDataLayer = (user) => {
    this.pushEventToDataLayer({
      event: 'login',
      userId: user.id,
    });

    if (AuthUtil.isNewUser(user)) {
      this.pushEventToDataLayer({
        event: 'nru_signup',
        userId: user.id,
      });
    }
  };
}

export default new GaUtil();
