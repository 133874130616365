import React, { useCallback } from 'react';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Bugsnag from '@bugsnag/js';
import Button from './Button';
import Label from '../FormFields/Label';

/**
 * Google's ReCaptchaV3 button
 * @param {string} color - Button color
 * @param {string} label - Button label
 * @param {string} className - Button class name
 * @param {function} onChange - Form onChange function
 * @param {function} onSubmit - Form onSubmit function
 * @param {boolean} loading - Button loading state
 * @param {boolean} disabled - Button disabled state
 * @returns {JSX.Element} - Google's ReCaptchaV3 button
 */
function GoogleReCaptchaV3ButtonElement({ color, label, className, onChange, onSubmit, loading, disabled }) {
  const isCaptchaEnabled = process.env.REACT_APP_CAPTCHA_ENABLED === 'true';
  const { executeRecaptcha } = useGoogleReCaptcha();

  /**
   * Handle form submit by calling form onChange and onSubmit as needed
   * @param {string} token - V3 Captcha token
   */
  const handleFormSubmit = useCallback((token) => {
    if (token) {
      // call form onChange to set captchav3 value
      // call form onSubmit to submit form as a callback
      onChange(undefined, { name: 'captchav3', value: token }, onSubmit);
    } else {
      // call form onSubmit if we don't have a token to submit
      onSubmit();
    }
  }, []);

  /**
   * Handle ReCaptcha verify on button click
   */
  const handleReCaptchaVerify = useCallback(() => {
    if (!executeRecaptcha) {
      // Execute recaptcha not yet available
      return;
    }
    (async () => {
      try {
        const token = await executeRecaptcha('formSubmit');
        // Set captcha token and then handle form submit
        handleFormSubmit(token);
      } catch (error) {
        Bugsnag.notify(error);
      }
    })();
  }, [executeRecaptcha]);

  // Disabled captcha checkbox for automated testing on BWB
  if (!isCaptchaEnabled) {
    return (
      <div className="relative">
        <Label label="*Captcha Disabled*" className="absolute top-0 left-1 z-10 mx-auto text-2xs" />
        <Button onClick={() => handleFormSubmit()} text={label} disabled={disabled} type="button" color={color} loading={loading} className={className} />
      </div>
    );
  }

  return <Button onClick={handleReCaptchaVerify} text={label} disabled={disabled} type="button" color={color} loading={loading} className={className} />;
}

/**
 * Google's ReCaptchaV3 button wrapped in a provider
 * @param {string} color - Button color
 * @param {string} label - Button label
 * @param {string} className - Button class name
 * @param {function} onChange - Form onChange function
 * @param {function} onSubmit - Form onSubmit function
 * @param {boolean} loading - Button loading state
 * @param {boolean} disabled - Button disabled state
 * @returns {JSX.Element} - Google's ReCaptchaV3 button wrapped in a provider
 */
function GoogleReCaptchaV3Button({ color, label, className, onChange, onSubmit, loading, disabled }) {
  // Initialize Google V3 Recaptcha Provider
  return (
    <GoogleReCaptchaProvider scriptProps={{ async: true }} reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY_V3}>
      <GoogleReCaptchaV3ButtonElement {...{ color, label, className, onChange, onSubmit, loading, disabled }} />
    </GoogleReCaptchaProvider>
  );
}

export default GoogleReCaptchaV3Button;
