import React from 'react';
import moment from 'moment';
import Bugsnag from '@bugsnag/js';
import { observer } from 'mobx-react';
import Container from '../Container/Container';
import AdminTable from './AdminTable';
import Table from '../Table/Table';
import Loading from '../Loading/Loading';
import StyledError from '../Error/StyledError';
import FormatUtil from '../../utils/formatUtil';
// eslint-disable-next-line import/no-cycle
import ExpandingContentTable from '../Enterprise/ExpandingContentTable';

export default observer(
  class NestedContentTables extends React.Component {
    state = {
      loading: true,
      error: null,
      data: null,
    };

    componentDidMount() {
      const { data, enrollmentId, contentDescriptionId, iaView, content } = this.props;
      if (data) {
        const newState = {
          ...this.state,
          data: this.props.data,
          loading: false,
        };
        this.setState(newState);
      } else if (!!enrollmentId || !!contentDescriptionId) {
        // If this is new IA view and this is a collection, we want the request to return in list format, not table
        const listView = iaView;
        this.props
          .getMethod(
            {
              enrollment_id: enrollmentId,
              content_description_id: contentDescriptionId,
              // For goals - The first level of activities/progress already comes with top level data. Send it back up to imitate API call/existing functionality
              contents: content && content.contents,
              // For program goals, the first level of content does not include all top level data. Send it back with payload.
              topLevelContent: content,
            },
            this.props.tableKey,
            listView
          )
          .then((response) => {
            const newState = {
              ...this.state,
              loading: false,
              data: response,
            };
            this.setState(newState);
          })
          .catch((error) => {
            Bugsnag.notify(error);
            const newState = {
              ...this.state,
              loading: false,
              error,
            };
            this.setState(newState);
          });
      }
    }

    getSummaryRow = () => {
      if (!this.state.data) {
        return null;
      }

      return (
        <Table.Row className="summary-row">
          <Table.Cell>Summary:</Table.Cell>
          <Table.Cell>{this.state.data.totals.progress}%</Table.Cell>
          <Table.Cell>{this.props.learningHoursCalc(this.state.data.totals.learning_seconds_completed)}</Table.Cell>
          <Table.Cell />
          <Table.Cell>{this.props.enrollmentCompletedAt ? moment(this.props.enrollmentCompletedAt).format('MM/DD/YYYY') : null}</Table.Cell>
          <Table.Cell />
        </Table.Row>
      );
    };

    rowValue = (data, rowIdx) => {
      return data[rowIdx] && data[rowIdx].value ? data[rowIdx].value : null;
    };

    getEnrollmentTitleNumber = (headings, tableData) => {
      if (!tableData) {
        return null;
      }

      //  Make a copy of tableData, loop through the copy and modify the titles
      const copyData = [...tableData];
      copyData.forEach((rowData, idx) => {
        const data = [...rowData];
        const typeIdx = FormatUtil.getColIndex(headings, 'content_description_type');
        const type = this.rowValue(data, typeIdx) ? FormatUtil.formatContentType(this.rowValue(data, typeIdx)) : null;
        const titleIdx = FormatUtil.getColIndex(headings, 'content_description_title');
        const title = this.rowValue(data, titleIdx);
        if (!!title && (type === 'Course Module' || type === 'Learning Module')) {
          data[titleIdx].value = FormatUtil.formatModuleTitle(title, idx + 1);
        } else if (
          !!title &&
          (type === 'On Demand Video' ||
            type === 'Reading Activity' ||
            type === 'Virtual Lab' ||
            type === 'Practice Test' ||
            type === 'Assessment' ||
            type === 'Practice Labs Lab Module')
        ) {
          data[titleIdx].value =
            this.props.parentIdx >= 0 ? FormatUtil.formatActivityTitle(FormatUtil.stripNumberFromActivityTitle(title), this.props.parentIdx + 1, idx + 1) : data[titleIdx].value;
        }
      });

      return copyData;
    };

    render() {
      const {
        tableKey,
        parentEnrollmentId,
        parentContentType,
        topLevelEnrollment,
        iaView,
        showSummary,
        showUnenrollIcon,
        checkCerts,
        getWorkroleIcons,
        formatDate,
        learningHoursCalc,
        progressFormat,
        titleTagFormat,
      } = this.props;

      const { loading, error, data } = this.state;
      if (loading) {
        return (
          <div className="mx-auto w-24">
            <Loading message="Loading..." />
          </div>
        );
      }
      if (error) {
        return (
          <Container>
            <StyledError error={error} />
          </Container>
        );
      }
      // If IA view, calling in the top level ExpandingContentTable component rather than the regular nested tables
      // ExpandingContentTable will loop back down to here for decendent level content
      if (iaView) {
        return (
          <ExpandingContentTable
            {...this.props}
            isNested
            hideFilters
            data={{
              data,
              loading,
              error,
            }}
          />
        );
      }

      const headings = data.columns;
      const tableDataCopy = this.getEnrollmentTitleNumber(data.columns, data.tableData);

      return (
        <AdminTable
          {...this.props}
          className={tableKey}
          headings={headings}
          data={tableDataCopy}
          ignoredCols={[
            FormatUtil.getColIndex(headings, 'content_description_type'),
            FormatUtil.getColIndex(headings, 'vendor'),
            FormatUtil.getColIndex(headings, 'content_description_archived_at'),
          ]}
          colWidths={[
            {
              idx: FormatUtil.getColIndex(headings, 'content_description_title'),
              width: '40%',
            },
            {
              idx: FormatUtil.getColIndex(headings, 'progress'),
              width: '15%',
            },
            {
              idx: FormatUtil.getColIndex(headings, 'learning_seconds_completed'),
              width: '15%',
            },
            {
              idx: FormatUtil.getColIndex(headings, 'created_at'),
              width: '10%',
            },
            {
              idx: FormatUtil.getColIndex(headings, 'completed_at'),
              width: '15%',
            },
          ]}
          formatColumns={[
            {
              method: (col, row, tableHeadings) => titleTagFormat(parentEnrollmentId, col, row, tableHeadings, topLevelEnrollment),
              colIdx: FormatUtil.getColIndex(headings, 'content_description_title'),
            },
            {
              method: progressFormat,
              colIdx: FormatUtil.getColIndex(headings, 'progress'),
            },
            {
              method: (col) => learningHoursCalc(col && col.value ? col.value : 0),
              colIdx: FormatUtil.getColIndex(headings, 'learning_seconds_completed'),
            },
            {
              method: formatDate,
              colIdx: FormatUtil.getColIndex(headings, 'created_at'),
            },
            {
              method: formatDate,
              colIdx: FormatUtil.getColIndex(headings, 'completed_at'),
            },
          ]}
          addCustomCol={[
            {
              method: checkCerts,
              position: 8,
              header: 'Certificates Earned',
              omitHeading: true,
            },
            {
              method: (row, columns, rowIdx) => getWorkroleIcons(row, columns, rowIdx, parentContentType),
              position: 9,
              header: 'Assessment Results',
              omitHeading: true,
            },
            {
              method: showUnenrollIcon,
              position: 10,
              header: 'Unenroll',
              omitHeading: true,
            },
          ]}
          footerRow={showSummary ? this.getSummaryRow : null}
        />
      );
    }
  }
);
