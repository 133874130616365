import React, { useEffect } from 'react';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import InterstitialTemplate from '../../components/InterstitialTemplate/InterstitialTemplate';
import Icon from '../../components/Icon/Icon';
import AddLink from '../../components/AddLink/AddLink';

const getAction = (action) => {
  switch (action) {
    case 'addAccount':
      return 'add an additional email to your account';
    case 'removeAccount':
      return 'remove a backup account';
    case 'setPrimary':
      return 'switch your primary email';
    case 'deleteUser':
      return 'delete your account';
    case 'linkAccount':
      return 'link your account';
    default:
      return null;
  }
};

function AdditionalInstructions({ action, success }) {
  if (!success) {
    return <div>Please try again later. If this issue persists, please contact support@cybrary.it.</div>;
  }
  if (action === 'addAccount') {
    return (
      <div>
        <p>For security reasons, you will be required to reset your password. You will receive an email containing a password reset link at your alternate email address.</p>
        <p>
          Please click the link to verify your alternate email and complete the process. <b>Link expires in 24 hours</b>. When complete, please return to your previous window and
          click the button to refresh.
        </p>
      </div>
    );
  }
  if (action === 'linkAccount') {
    return (
      <div>
        <p>
          Your account has been linked,{' '}
          <AddLink className="text-cyb-pink-500 hover:text-black underline" to="/login">
            login to access it.
          </AddLink>
        </p>
      </div>
    );
  }

  return <div>Please return to your previous window and click the button to refresh.</div>;
}

function AccountChange() {
  const location = useLocation() || window.location;
  const queryParams = queryString.parse(location.search);
  const navigate = useNavigate();

  useEffect(() => {
    if (!queryParams.action || !queryParams.status) {
      navigate('/');
    }
  }, []);

  const success = queryParams.status === 'success';

  return (
    <InterstitialTemplate>
      <div className="flex justify-center items-start w-full">
        <div className="flex items-center p-6 my-16 w-9/12 rounded-sm border border-gray-400" style={{ maxWidth: '1000px' }}>
          <div className="mr-10 ml-6">
            <Icon className={`w-16 h-16 ${success ? 'text-green-700' : 'text-red-700'}`} name={success ? 'thumbs-up' : 'exclamation-circle'} />
          </div>
          <div>
            <h2 className="mb-4 text-2xl font-black">Account Change</h2>
            <div className="mb-0">
              <p>
                Your request to {getAction(queryParams.action)} was {success ? `successful.` : 'unsuccessful.'}
              </p>
              <AdditionalInstructions success={success} action={queryParams.action} />
            </div>
          </div>
        </div>
      </div>
    </InterstitialTemplate>
  );
}

export default AccountChange;
