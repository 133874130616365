import React from 'react';

const LABEL_MAPPINGS = {
  avgPreAssessmentScore: 'Avg Pre-Assessment Score',
  avgPostAssessmentScore: 'Avg Post-Assessment Score',
};

function CustomBarChartTooltip({ id, label, data, color, value }) {
  const displayLabel = LABEL_MAPPINGS[id] ?? id;

  return (
    <div
      key={label}
      className="py-2 px-3 text-inherit bg-white rounded-sm"
      style={{
        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 2px',
      }}
    >
      <div className="flex items-center whitespace-pre">
        <span>
          <strong>{data.topic}</strong>
        </span>
      </div>
      <div className="flex items-center mt-1 whitespace-pre">
        <span className="block mr-2 w-3 h-3 rounded-full" style={{ backgroundColor: color }} />
        <span className="mr-3">
          {displayLabel}: <strong>{value}%</strong>
        </span>
      </div>
    </div>
  );
}

export default CustomBarChartTooltip;
