import React from 'react';
import ReactMarkdown from 'react-markdown';
import moment from 'moment';
import Segment from '../Segment/Segment';
import Loading from '../Loading/Loading';
import Header from '../Header/Header';
import Container from '../Container/Container';
import List, { ListItem } from '../List/List';
import StyledError from '../Error/StyledError';
import FormatUtil from '../../utils/formatUtil';
import './content-updates.css';

function ArchiveMessage({ archived, archiveAt }) {
  const messageClasses = 'mt-2 text-sm text-cyb-red-700 semi-bold';
  if (archived) {
    return <p className={messageClasses}>The course you were enrolled in has been archived, but we hope you&apos;ll enroll in our new and improved version of the course!</p>;
  }
  if (archiveAt && !archived) {
    const formattedDate = moment(archiveAt).format('M/D/YYYY');
    return <p className={messageClasses}>You have until {formattedDate} to remain in this course before it will be archived</p>;
  }

  return null;
}

function ContentChangeLog({ data }) {
  if (!data) {
    return null;
  }

  const changeTypes = ['New Content', 'Updated Content', 'Removed Content'];
  return (
    <div className="content-changelog">
      <Header className="pt-4 text-base font-semibold" as="h4">
        Changelog:
      </Header>
      <Segment className="m-4 changelog-details">
        {changeTypes.map((changeType) => {
          const changes = data[changeType];
          if (!changes || !Object.keys(changes).length) {
            return null;
          }
          return (
            <div className="m-4 change-type" key={changeType}>
              <p>{changeType}</p>
              {Object.keys(changes).map((contentType) => {
                const contentTypeChanges = changes[contentType];
                if (!contentTypeChanges) {
                  return null;
                }
                return (
                  <div className="content-types" key={contentType}>
                    <p>
                      {contentType}s ({contentTypeChanges.length})
                    </p>
                    <List>
                      {contentTypeChanges.map((content) => {
                        return (
                          <ListItem key={content.title}>
                            <span className="mx-2">&bull;</span> {FormatUtil.stripNumberFromActivityTitle(content.title)}
                          </ListItem>
                        );
                      })}
                    </List>
                  </div>
                );
              })}
            </div>
          );
        })}
      </Segment>
    </div>
  );
}

function ContentUpdateSummary({ data, error, loading, summaryTitle, contentTitle, progress, contentReplacementProgress, isNewContentComingSoon, archived }) {
  if (loading) {
    return (
      <Container>
        <Loading message="Loading..." />
      </Container>
    );
  }
  if (error) {
    return (
      <Container>
        <StyledError error={error} />
      </Container>
    );
  }
  const defaultHeader = `A new version ${isNewContentComingSoon ? 'is coming soon!' : 'has been released!'}`;
  return (
    <Segment className="pt-4 m-4 border-none content-update-summary">
      <Header as="h3" id="content-update" className="text-xl font-bold summary-topic">
        {summaryTitle || defaultHeader}
      </Header>
      <div className="summary-headers">
        <div className="block md:flex">
          <div className="w-full md:w-2/3">
            <Header as="h4">{contentTitle || data.title}</Header>
            {!isNewContentComingSoon && <Header as="h5">New Version Released: {moment(data.scheduled_at).format('M/D/YYYY')}</Header>}
            <ArchiveMessage archived={archived} archiveAt={data && data.archive_at} />
          </div>
          <div className="w-full md:w-1/3 progress-container">
            {!!progress && contentReplacementProgress !== null && (
              <div>
                <p>Current Progress: {progress}%</p>
                <p>Progress with Update: {contentReplacementProgress}%</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="summary-content">
        {data.description ? (
          <div className="details">
            <span style={{ fontWeight: 600 }}>Release Details: </span>
            <ReactMarkdown className="has-markdown-inside">{data.description}</ReactMarkdown>
          </div>
        ) : null}
        {!isNewContentComingSoon && <ContentChangeLog data={data} />}
      </div>
    </Segment>
  );
}

export default ContentUpdateSummary;
