import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { colorSchemes } from '@nivo/colors';
import If from '../If/If';
import FlexLegends from './FlexLegends';

// Data is expected to be an array of objects, each object contains a member that matches the indexBy value
// as well as any other values to display

function BarChart({ data, keys, indexBy = 'id', colorScheme = 'paired', ariaLabel = 'Bar Chart', onClick = null, legends = false, customColors = null, tooltip = null }) {
  const colorsFromOurScheme = colorSchemes[colorScheme];
  const legendData = legends ? keys.map((item, index) => ({ id: item, color: customColors?.[item] || colorsFromOurScheme[index] })) : [];
  const tooltipProps = tooltip ? { tooltip } : {};
  const colorProps = customColors
    ? {
        colors: ({ id, i }) => {
          return customColors?.[id] || colorsFromOurScheme[i];
        },
      }
    : { colors: { scheme: colorScheme } };
  return (
    <>
      <div className="relative h-[400px]">
        <ResponsiveBar
          data={data}
          keys={keys}
          indexBy={indexBy}
          margin={{ top: 20, right: 20, bottom: 50, left: 50 }}
          padding={0.15}
          groupMode="grouped"
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          {...colorProps}
          borderColor={{
            from: 'color',
            modifiers: [['darker', 1.6]],
          }}
          onClick={onClick}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            truncateTickAt: 0,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            truncateTickAt: 0,
          }}
          enableLabel={false}
          role="application"
          ariaLabel={ariaLabel}
          barAriaLabel={(e) => `${e.id}: ${e.formattedValue}: ${e.indexValue}`}
          {...tooltipProps}
        />
      </div>
      <If condition={!!legends}>
        <FlexLegends legendData={legendData} />
      </If>
    </>
  );
}

export default BarChart;
