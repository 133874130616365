import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import Bugsnag from '@bugsnag/js';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import queryString from 'query-string';
import { useCareerPrograms } from '../../providers/CareerProgramsProvider';
import Agents from '../../agents/agents';
import ActionUtil from '../../utils/actionsUtil';
import Loading from '../../components/Loading/Loading';
import TeamHeader from '../../components/AdminTeamActivityLogs/TeamHeader';
import Container from '../../components/Container/Container';
import Divider from '../../components/Divider/Divider';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import CybraryAdminLogo from './CybraryAdminLogo';
import Dropdown from '../../components/Dropdown/Dropdown';
import AdminTable from '../../components/AdminTable/AdminTable';
import StyledError from '../../components/Error/StyledError';
import If from '../../components/If/If';
import SearchInput from '../../components/Search/SearchInput';

function ManageProgramsTableOptions({ programs, selectedProgram, onChangeProgram, searchValue, setSearchValue }) {
  const dropdownOptions = useMemo(
    () =>
      programs?.map((program) => ({
        key: program.id,
        text: program.title,
        value: program.id,
      })) || [],
    [programs]
  );

  const onInputChange = useCallback((e) => {
    const input = e.target.value;

    setSearchValue(input);
  }, []);

  const onClear = useCallback(() => {
    setSearchValue('');
  }, []);

  return (
    <div className="grid grid-cols-12 view-team-options">
      <div className="col-span-8">
        <div className="flex gap-x-2 items-center">
          <Dropdown classes="w-64 inline-block" placeholder="Assign Program" options={dropdownOptions} value={selectedProgram} onChange={onChangeProgram} />
        </div>
      </div>
      <div className="col-span-4">
        <SearchInput inputClasses="w-5/6" placeholder="Search Members" value={searchValue} onChange={onInputChange} onClose={onClear} ariaLabel="Search members" isClearable />
      </div>
    </div>
  );
}

const ManageProgramsTable = React.memo(function ManageProgramsTable({ teamId, onUpdateSelectedTableRows, refreshTableTrigger, isAssigningProgram, searchValue }) {
  const [teamProgramData, setTeamProgramData] = useState(null);
  const [teamProgramDataLoading, setTeamProgramDataLoading] = useState(true);
  const [teamProgramDataError, setTeamProgramDataError] = useState(null);
  const [checkboxState, setCheckboxState] = useState({});
  const [isHeaderSelected, setIsHeaderSelected] = useState(false);
  const [tableQueryParams, setTableQueryParams] = useState({
    activePg: 1,
    resultsPerPage: 25,
    sortCol: 'name',
    sortDirection: 'asc',
  });

  const filteredTableData = useMemo(() => {
    if (searchValue !== '') {
      return teamProgramData?.tableData?.filter((entry) => {
        const name = entry[1].value.toLowerCase();
        const email = entry[2].value.toLowerCase();
        const value = searchValue.trim().toLowerCase();

        return name.includes(value) || email.includes(value);
      });
    }
    return teamProgramData?.tableData;
  }, [searchValue, teamProgramData?.tableData]);

  const pagPagesCount = useMemo(() => {
    if (!teamProgramData) {
      return null;
    }

    return Math.ceil(teamProgramData.totalRecords / tableQueryParams.resultsPerPage);
  }, [teamProgramData, tableQueryParams.resultsPerPage]);

  const fetchTeamProgramData = useCallback(
    async (queryParams) => {
      try {
        setTeamProgramDataLoading(true);
        setTeamProgramDataError(null);

        const query = queryString.stringify(queryParams);
        const response = await Agents.admin.getTeamPrograms(teamId, `?${query}`);

        setTeamProgramData(response);
        setTeamProgramDataLoading(false);
      } catch (e) {
        Bugsnag.notify(e);
        setTeamProgramDataError(e);
      }
    },
    [teamId]
  );

  const resetCheckboxState = useCallback((tableData) => {
    if (!tableData) {
      return;
    }

    const defaultCheckboxState = {};

    tableData?.forEach((_, index) => {
      defaultCheckboxState[index] = false;
    });

    setCheckboxState(defaultCheckboxState);
    setIsHeaderSelected(false);
    onUpdateSelectedTableRows([]);
  }, []);

  const onClickRowCheckbox = useCallback((_event, { checked }, index) => {
    setCheckboxState((prevState) => ({
      ...prevState,
      [index]: checked,
    }));
  }, []);

  const onClickHeaderCheckbox = useCallback(
    (_event, { checked }) => {
      const updatedCheckboxState = {};

      filteredTableData?.forEach((_entry, index) => {
        updatedCheckboxState[index] = checked;
      });

      setCheckboxState(updatedCheckboxState);
      setIsHeaderSelected(checked);
    },
    [filteredTableData]
  );

  const onClickHeaderSort = useCallback(
    (heading) => {
      if (!heading) {
        return;
      }

      let sortDirection = 'desc';

      if (heading === tableQueryParams.sortCol) {
        sortDirection = tableQueryParams.sortDirection === 'desc' ? 'asc' : 'desc';
      }

      const updatedTableQueryParams = {
        ...tableQueryParams,
        activePg: 1,
        sortDirection,
        sortCol: heading,
      };

      setTableQueryParams(updatedTableQueryParams);
    },
    [tableQueryParams]
  );

  const onClickPageChange = useCallback((page) => {
    setTableQueryParams((prevState) => ({
      ...prevState,
      activePg: page,
    }));

    ActionUtil.scrollToTop();
  }, []);

  const handleUpdatedSelectedRowsEvent = useCallback(
    (currentCheckboxState) => {
      if (!filteredTableData) {
        return;
      }

      const selectedRowIds = [];

      Object.entries(currentCheckboxState).forEach(([index, checked]) => {
        if (checked) {
          const rowId = filteredTableData[index][0].value;
          selectedRowIds.push(rowId);
        }
      });

      onUpdateSelectedTableRows(selectedRowIds);
    },
    [filteredTableData]
  );

  useEffect(() => {
    fetchTeamProgramData(tableQueryParams);
  }, [tableQueryParams, refreshTableTrigger]);

  useEffect(() => {
    if (filteredTableData) {
      resetCheckboxState(filteredTableData);
    }
  }, [filteredTableData]);

  useEffect(() => {
    handleUpdatedSelectedRowsEvent(checkboxState);
  }, [checkboxState]);

  if (teamProgramDataError) {
    return (
      <div>
        <StyledError error={teamProgramDataError} />
      </div>
    );
  }

  return (
    <div className="relative">
      <If condition={teamProgramDataLoading || isAssigningProgram}>
        <div className={`flex absolute inset-0 z-50 flex-col justify-center items-center ${teamProgramData?.tableData ? 'bg-white/75' : 'bg-white'}`}>
          <Loading message="Loading..." />
        </div>
      </If>
      <AdminTable
        headings={teamProgramData?.columns}
        data={filteredTableData}
        sortCol={tableQueryParams.sortCol}
        sortDirection={tableQueryParams.sortDirection}
        pagActivePg={tableQueryParams.activePg}
        pagNumPgs={pagPagesCount}
        pagChangePg={onClickPageChange}
        headerCheckBoxFunc={onClickHeaderCheckbox}
        headerClickFunc={onClickHeaderSort}
        rowCheckBoxFunc={onClickRowCheckbox}
        headerCheckBoxState={isHeaderSelected}
        checkBoxesState={observable(checkboxState)}
        displayCheckBox
      />
    </div>
  );
});

function ManageProgramsController({ teamId, programs, commonStore }) {
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [selectedTableRows, setSelectedTableRows] = useState([]);
  const [isAssigningProgram, setIsAssigningProgram] = useState(false);
  const [refreshTableTrigger, setRefreshTableTrigger] = useState(0);
  const [searchValue, setSearchValue] = useState('');

  const assignTeamProgram = useCallback(
    async (users, program) => {
      try {
        setIsAssigningProgram(true);

        await Agents.admin.assignTeamProgram(teamId, {
          users,
          program,
        });

        setRefreshTableTrigger((prevState) => prevState + 1);
      } catch (e) {
        Bugsnag.notify(e);
      } finally {
        setIsAssigningProgram(false);
      }
    },
    [teamId]
  );

  const onChangeProgram = useCallback(
    (e) => {
      if (!selectedTableRows.length) {
        return;
      }

      commonStore.triggerConfirm({
        content: `Are you sure you want to assign ${e.label} to the selected users?`,
        cancel: () => {
          commonStore.resetConfirmState();
        },
        continue: () => {
          commonStore.resetConfirmState();
          setSelectedProgram(e.value);
          assignTeamProgram(selectedTableRows, e.value);
        },
      });
    },
    [selectedTableRows]
  );

  const onUpdateSelectedTableRows = useCallback((value) => {
    setSelectedTableRows(value);

    if (!value.length) {
      setSelectedProgram(null);
    }
  }, []);

  return (
    <>
      <ManageProgramsTableOptions
        programs={programs}
        selectedProgram={selectedProgram}
        onChangeProgram={onChangeProgram}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
      <ManageProgramsTable
        teamId={teamId}
        onUpdateSelectedTableRows={onUpdateSelectedTableRows}
        refreshTableTrigger={refreshTableTrigger}
        isAssigningProgram={isAssigningProgram}
        searchValue={searchValue}
      />
    </>
  );
}

function CybraryAdminTeamManageProgramsPage({ commonStore }) {
  const params = useParams();
  const [teamData, setTeamData] = useState({});
  const [teamLoading, setTeamLoading] = useState(true);
  const { allCareerPrograms, getAllCareerPrograms } = useCareerPrograms();

  const breadcrumbs = [{ label: 'Browse Teams', href: '/admin/teams' }, { label: 'View Team', href: `/admin/view-team/${params.id}` }, { label: 'Manage Programs' }];

  const fetchTeamData = useCallback(async () => {
    try {
      const response = await Agents.admin.getTeamData(params.id);
      setTeamData(response);
      setTeamLoading(false);
    } catch (e) {
      Bugsnag.notify(e);
    }
  }, [params.id]);

  useEffect(() => {
    fetchTeamData();
    getAllCareerPrograms();
    commonStore.hidePrimaryNav();
  }, []);

  if (teamLoading) {
    return (
      <div>
        <CybraryAdminLogo />
        <Loading message="Loading..." />
      </div>
    );
  }

  return (
    <Container size="xl">
      <CybraryAdminLogo />
      <Breadcrumbs crumbs={breadcrumbs} />
      <TeamHeader data={teamData} />
      <Divider />
      <ManageProgramsController teamId={params.id} programs={allCareerPrograms} commonStore={commonStore} />
    </Container>
  );
}

const CybraryAdminTeamManagePrograms = inject('commonStore')(observer(CybraryAdminTeamManageProgramsPage));

export default CybraryAdminTeamManagePrograms;
