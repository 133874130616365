class ContentTransformer {
  /**
   * Transform data from Algolia and backend services for consistency
   * @param data
   * @returns formatted data
   */
  static formatDataSources = (data) => {
    let formattedData = {};
    const base = {
      id: null,
      thumbnail_url: '',
      content_type: '',
      title: '',
      average_rating: null,
      rating_count: null,
      duration_seconds: null,
      ceu_count: null,
      permalink: '',
      wwwPermalink: '',
      prefix: null,
      enrollment_id: null,
      progress: 0,
      description: '',
      instructors_info: '',
      author: '',
      level: '',
      tags_info: [],
      experience_points_total: 0,
    };
    if (data) {
      /* 
        content collections with type certification prep does not exist on www... only individual certification courses
        so we want to redirect to the catalog/certifications page instead of following the permalink strictly
        since there is no 1:1 mapping between the two like other content types...
        this is for the share button that is apart of the content card
    */
      if (data?.permalink && data?.permalink?.includes('certification-prep/')) {
        formattedData.wwwPermalink = 'certifications/';
      }
      Object.keys(data).forEach((key) => {
        if (base[key] !== undefined) {
          formattedData[key] = data[key];
        }
      });
      const details = ContentTransformer.getContentDetails(data);
      const instructor = ContentTransformer.getInstructorsInfo(data);
      formattedData = {
        ...formattedData,
        ...details,
        ...instructor,
      };
    }
    return formattedData;
  };

  static getInstructorsInfo(data) {
    const formattedData = {};
    if (data.instructors_info && data.instructors_info.length && data.instructors_info[0].name) {
      formattedData.instructors_info = data.instructors_info;
      formattedData.author = data.instructors_info[0].name;
    }
    if (data.author && data.author.name) {
      formattedData.author = data.author.name;
    }
    if (data.vendor && data.vendor.name) {
      formattedData.author = data.vendor.name;
    }
    return formattedData;
  }

  static getContentDetails(data) {
    const formattedData = {};
    if (data.content_description_id) {
      formattedData.id = data.content_description_id;
    }
    if (data.content_description_thumbnail_url) {
      formattedData.thumbnail_url = data.content_description_thumbnail_url;
    }
    if (data.content_description_type || (data.content_type && data.content_type.nice_name)) {
      formattedData.content_type = data.content_description_type || data.content_type.nice_name;
    }
    if (data.content_description_title) {
      formattedData.title = data.content_description_title;
    }
    if (data.content_description_permalink) {
      formattedData.permalink = data.content_description_permalink;
    }
    if (data.short_description) {
      formattedData.description = data.short_description;
    }
    if (data.learning_seconds_total) {
      formattedData.duration_seconds = data.learning_seconds_total;
    }
    if (data.content_description_ceu_count) {
      formattedData.ceu_count = data.content_description_ceu_count;
    }
    if (data.level && data.level.name) {
      formattedData.level = data.level.name;
    }
    if (data.status) {
      formattedData.status = data.status;
      formattedData.comingSoon = data.status === 'Coming Soon';
    }
    return formattedData;
  }
}

export default ContentTransformer;
