import React, { useState } from 'react';
import Collapsible from '../Collapsible/Collapsible';
import Icon from '../Icon/Icon';

export function Faq({ question, answer }) {
  const [open, setOpen] = useState(false);
  const chevronDisplayClasses = open ? 'transform rotate-180' : '';
  return (
    <Collapsible
      trigger={
        <div className="flex justify-between bg-gray-100 rounded">
          <div>
            <h3 className="py-5 px-6 text-lg font-bold">{question}</h3>
          </div>
          <div className="flex items-center mr-7">
            <Icon name="chevron-down" className={`text-black w-4 h-4 ${chevronDisplayClasses}`} />
          </div>
        </div>
      }
      onOpening={() => setOpen(true)}
      onClosing={() => setOpen(false)}
      open={open}
      ariaLabel={`FAQ Collapsible for ${question}`}
    >
      <div className="bg-gray-100 rounded">
        <p className="py-5 px-6 text-sm font-normal">{answer}</p>
      </div>
    </Collapsible>
  );
}

export default function Faqs({ careerPathFaqs, className }) {
  if (!careerPathFaqs || !careerPathFaqs.faqs || !careerPathFaqs.faqs.length) {
    return null;
  }

  const { faqs } = careerPathFaqs;
  return (
    <div className={className || ''}>
      <h2 className="mb-6 text-2xl	font-black">Frequently Asked Questions</h2>
      {faqs.map((faq) => {
        const { question, answer } = faq;

        return (
          <div className="my-4" key={question}>
            <Faq question={question} answer={answer} />
          </div>
        );
      })}
    </div>
  );
}
