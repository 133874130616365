import React, { useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import Icon from '../Icon/Icon';
import Collapsible from '../Collapsible/Collapsible';
// eslint-disable-next-line import/no-cycle
import ExpandingContentTableDetails from './ExpandingContentTableDetails';
// eslint-disable-next-line import/no-cycle
import NestedContentTables from '../AdminTable/NestedContentTables';
import ProgressLabel from './ProgressLabel';
import OptionsMenu from '../OptionsMenu/OptionsMenu';
import NotificationUtil from '../../utils/notificationUtil';
import CatalogUtil from '../../utils/catalogUtil';
import FormatUtil from '../../utils/formatUtil';
import ContentUpdateFlag from '../ContentUpdates/ContentUpdateFlag';
import ContentUpdateLabel from '../ContentUpdates/ContentUpdateLabel';
import WorkroleModal from '../Analytics/WorkroleModal';
import ArchivedLabel from '../ContentUpdates/ArchivedLabel';
import ChevronDown from '../../Icons/ChevronDown';
import Label from '../Label/Label';
import BetaLabel from '../Label/BetaLabel';
import AddLink from '../AddLink/AddLink';
import { useCybAssessmentAnalytics } from '../../providers/CybAssessmentAnalyticsProvider';

function ImgWrapper({ isNested, content }) {
  const thumbWidth = isNested ? 156 : 260;
  const imgSrc = `${
    content.content_description_thumbnail_url || 'https://images.ctfassets.net/kvf8rpi09wgk/2bcb1ThKRYPgD8mgIPLRJF/812cb7a77584ac0477c4a729a0bae83e/cybrary_background.jpg'
  }?w=${thumbWidth}`;
  const imgWrapperStyles = !isNested ? { width: '260px', height: '161px' } : {};

  return (
    <div className="hidden overflow-hidden md:block" style={imgWrapperStyles}>
      <img className="m-auto w-auto h-full" src={imgSrc} alt="" />
    </div>
  );
}

function ProgressLabelWrapper({ content, status, conditionalClass, defaultClass }) {
  const className = status === 'completed' ? conditionalClass : defaultClass;
  return (
    <div className={className}>
      <ProgressLabel progressNum={1 * content.progress} completionDate={content.completed_at} showProgressBar progressBarSuffix="% Complete" />
    </div>
  );
}

function OptionsMenuWrapper({ isNested, menuOptions }) {
  return !isNested && menuOptions && menuOptions.length ? (
    <div className="ml-4 md:ml-8">
      <OptionsMenu options={menuOptions} noIcon />
    </div>
  ) : (
    <div className="hidden ml-4 md:block md:ml-8 md:w-10" />
  );
}

function ChevronIconWrapper({ content, showScoreBreakdown, open, toggleRow }) {
  // Hide chevron if this is a cybrary assessment (CASS)
  const isCybraryAssessment = content?.content_description_type === 'Assessment';
  if (isCybraryAssessment) {
    return <ChevronDown classes="w-5 h-5 invisible" />;
  }
  const chevronClasses = `w-5 h-5 mx-auto md:mx-0 hover:text-gray-900 text-gray-600 transition-transform cursor-pointer ${open ? 'transform rotate-180' : ''}`;
  const contentType = content.type || content.content_description_type;
  const collapsibleId = FormatUtil.lowerCaseHyphenText(content.name || content.content_description_title);
  const srText = `${open ? 'Collapse' : 'Expand'} ${content.name || content.content_description_title} ${contentType} breakdown`;
  return content.item_count || showScoreBreakdown ? (
    <button aria-controls={collapsibleId} id={`${collapsibleId}-collapsible`} aria-expanded={!!open} onClick={() => toggleRow()}>
      <span className="sr-only">{srText}</span>
      <ChevronDown classes={chevronClasses} />
    </button>
  ) : (
    <ChevronDown classes="w-5 h-5 invisible" />
  );
}

function ContentUpdates({ isArchived, contentUpdates, contentUpdateSummaryTitle, content, switchToNewContentVersion, isCybraryCollection }) {
  const contentArchiveDate = contentUpdates.archive_at ? moment(contentUpdates.archive_at).format('MM/DD/YYYY') : null;
  // 1. Content is scheduled to be ARCHIVED with no replacement
  if (contentArchiveDate && !contentUpdates.new_content_description_id) {
    // If it's already archived at this point, no need to show an update (archive label will already be shown
    if (isArchived) {
      return null;
    }
    return <ContentUpdateLabel labelText={`Archiving on ${contentArchiveDate}`} />;
  }
  // 2. Content has been replaced with new content (old content UNLISTED) OR
  // 3. Content has been replaced with new content (old content ARCHIVED) - Archive scheduled further out OR
  // 4. Content has been replaced with new content (old content ARCHIVED) - Archive has already happened
  return (
    <ContentUpdateFlag
      archived={isArchived}
      data={contentUpdates}
      summaryTitle={contentUpdateSummaryTitle}
      contentTitle={<ContentName content={content} />}
      progress={content.progress}
      switchToNewContentVersion={switchToNewContentVersion}
      hasUpdated={isCybraryCollection}
    />
  );
}

function IAViewRow({
  content,
  iaView,
  isNested,
  launchItem,
  topLevelEnrollment,
  checkCerts,
  getAllScores,
  workroleData,
  getWorkroleData,
  contentUpdates,
  contentType,
  isCybraryCollection,
  contentUpdateSummaryTitle,
  isArchived,
  toggleRow,
  getMenuOptions,
  checkIsCompleteAssessment,
  switchToNewContentVersion,
  onOpening,
  open,
  hasOpened,
  rowProps,
  status,
}) {
  const outerWrapperClassName = isNested ? 'border-b-xs border-gray-400' : 'mb-4';
  const innerWrapperClassName = isNested ? 'p-4' : 'border-xs border-gray-400';
  let contentWrapperClassName = isNested ? 'pt-4 px-8 flex-1' : 'pt-8 px-8 pb-4 md:pt-4 flex-1';
  if (contentUpdates && !isNested) {
    contentWrapperClassName = 'pt-8 px-8 pb-4 md:pt-4 flex-1';
  }
  const menuOptions = getMenuOptions();
  const topLevelEnrollmentId = 'enrollment_id' in topLevelEnrollment ? topLevelEnrollment.enrollment_id : topLevelEnrollment.id;
  // Is this an assessment that needs to show a score breakdown table?
  const showScoreBreakdown = getAllScores && checkIsCompleteAssessment(contentType, content.progress);
  let tableProps = {};

  if (showScoreBreakdown) {
    tableProps = {
      getMethod: (data) => getAllScores(data.content_description_id),
    };
  }
  const learningActionWrapper = status !== 'completed' ? 'md:w-32' : 'md:w-52';
  return (
    <div className={`rounded-sm ${outerWrapperClassName}`}>
      <div className={`rounded-sm flex ${innerWrapperClassName}`}>
        <ImgWrapper isNested={isNested} content={content} />
        <div className={`flex items-center ${contentWrapperClassName}`}>
          <div className="flex flex-col justify-between w-full md:flex-row md:items-center">
            <div className="flex justify-between items-center mb-6 md:mb-0 md:w-1/3">
              <div className="flex flex-col w-3/5 md:w-full">
                <div className="mb-6 font-bold md:mb-2" style={{ maxWidth: '40ch' }}>
                  <ContentName content={content} isArchived={isArchived} omitType />
                </div>
                <ContentDetails content={content} />
                <div className="mt-2">
                  {contentUpdates ? (
                    <ContentUpdates
                      content={content}
                      contentUpdates={contentUpdates}
                      isArchived={isArchived}
                      contentUpdateSummaryTitle={contentUpdateSummaryTitle}
                      switchToNewContentVersion={switchToNewContentVersion}
                      isCybraryCollection={isCybraryCollection}
                    />
                  ) : null}
                </div>
              </div>
              <ProgressLabelWrapper content={content} status={status} conditionalClass="flex justify-end w-2/5 md:w-full md:hidden" defaultClass="hidden" />
            </div>

            <div className="flex justify-between items-center mb-6 md:justify-end md:mb-0 md:w-2/3">
              <ProgressLabelWrapper content={content} status={status} conditionalClass="hidden md:block" defaultClass="flex flex-start md:w-48" />

              <div className={`ml-4 md:ml-8 ${learningActionWrapper}`}>
                <LearningAction
                  launchItem={launchItem}
                  topLevelEnrollment={topLevelEnrollment}
                  content={content}
                  checkCerts={checkCerts}
                  iaView={iaView}
                  isArchived={isArchived}
                  workroleData={{ ...workroleData }}
                  getWorkroleData={getWorkroleData}
                  isNested={isNested}
                />
              </div>

              <OptionsMenuWrapper isNested={isNested} menuOptions={menuOptions} />

              <div className="hidden ml-4 w-5 md:block md:ml-8">
                <ChevronIconWrapper content={content} showScoreBreakdown={showScoreBreakdown} toggleRow={toggleRow} open={open} />
              </div>
            </div>

            <div className="block mx-auto w-5 md:hidden md:ml-8">
              <ChevronIconWrapper content={content} showScoreBreakdown={showScoreBreakdown} toggleRow={toggleRow} open={open} />
            </div>
          </div>
        </div>
      </div>
      <Collapsible
        triggerTagName="div"
        open={open}
        className="cursor-default"
        openedClassName="cursor-default border-l-xs border-r-xs border-b-xs border-gray-400"
        transitionTime={200}
        ariaLabel={`${content.name || content.content_description_title}`}
        onOpening={onOpening}
        collapsibleId={FormatUtil.lowerCaseHyphenText(content.name || content.content_description_title)}
      >
        {hasOpened && showScoreBreakdown ? (
          <NestedContentTables
            {...tableProps}
            tableKey={rowProps.tableKey}
            displayCheckBox={false}
            contentDescriptionId={content.content_description_id}
            getWorkroleIcons={rowProps.getWorkroleIcons}
            parentContentType={contentType}
            formatDate={rowProps.formatDate}
          />
        ) : null}
        {hasOpened && !showScoreBreakdown ? (
          <ExpandingContentTableDetails
            {...rowProps}
            iaView
            parentEnrollmentId={topLevelEnrollmentId}
            tableKey={rowProps.tableKey}
            contentDescriptionId={content.content_description_id}
            enrollment={content}
            topLevelEnrollment={rowProps.topLevelEnrollment}
            orgId={rowProps.orgId}
            userId={rowProps.userId}
            checkCerts={rowProps.checkCerts}
            getWorkroleIcons={rowProps.getWorkroleIcons}
            progressFormat={rowProps.progressFormat}
            titleTagFormat={rowProps.titleTagFormat}
            formatCertificateLink={rowProps.formatCertificateLink}
            learningHoursCalc={rowProps.learningHoursCalc}
            formatDate={rowProps.formatDate}
            showSummary={rowProps.showSummary}
            hideCurriculaDetails={rowProps.hideCurriculaDetails}
            omitCurriculaLink={rowProps.omitCurriculaLink}
            unenrollFunction={rowProps.unenrollFunction}
            retakeFunction={rowProps.retakeFunction}
            noResultsMsg={rowProps.noResultsMsg}
            queryParams={rowProps.queryParams}
            getAllScores={rowProps.getAllScores}
            expandFunction={rowProps.expandFunction}
            isArchived={!!isArchived}
          />
        ) : null}
      </Collapsible>
    </div>
  );
}

function LearningAction({ launchItem, topLevelEnrollment, content, checkCerts, iaView, isArchived, isNested, workroleData, getWorkroleData }) {
  const showWorkRoleButton =
    !isNested && ['iMocha Test', 'Interview Mocha Test'].indexOf(content.content_description_type) !== -1 && content.completed_at && content.id && getWorkroleData;
  const { actions } = useCybAssessmentAnalytics();
  // Show workrole modal button
  if (showWorkRoleButton) {
    return (
      <WorkroleModal
        enrollmentId={content.id}
        loading={workroleData.loading}
        error={workroleData.error}
        data={workroleData.data}
        getWorkroleData={getWorkroleData}
        showLevelUpMsg
        buttonFormat
      />
    );
  }

  // Show See Score link for completed Cybrary assessments
  const isCybraryAssessment = content?.content_description_type === 'Assessment';
  if (isCybraryAssessment && content?.content_description_id && content?.completed_at) {
    const assessmentId = content?.content_description_id;
    const trackId = useMemo(() => actions?.getTrackIdByAssessmentId(assessmentId), [assessmentId, actions?.getTrackIdByAssessmentId]);
    return (
      <AddLink className="text-sm text-cyb-pink-500 hover:text-black underline cursor-pointer" to={`/my-learning/assessments/results/${trackId}/${assessmentId}`}>
        See Score
      </AddLink>
    );
  }

  // Show Download Cert button
  if (1 * content.progress === 100 || content.completed_at) {
    return <div className="w-full md:w-auto">{checkCerts(null, null, content, iaView)}</div>;
  }

  // If archived, show nothing (no action can be taken)
  if (isArchived) {
    return <div />;
  }

  // Show launch button
  // Get enrollment ID. If this is an assignment, it's in 'enrollment_id'. Otherwise it's in 'id' (ID is assignment ID for assignments).
  // Need to use whatever is in that key, even if null (when launching it will enroll if not yet enrolled)
  const topLevelEnrollmentId = 'enrollment_id' in topLevelEnrollment ? topLevelEnrollment.enrollment_id : topLevelEnrollment.id;
  const contentType = content.type || content.content_description_type;
  return (
    <button
      onClick={(e) => launchItem(e, topLevelEnrollmentId, content.content_description_id, topLevelEnrollment, topLevelEnrollment.is_activity, contentType)}
      className="text-sm text-cyb-pink-500 hover:text-black underline cursor-pointer"
    >
      {1 * content.progress ? 'Continue' : 'Start'} Learning
    </button>
  );
}

const labelClasses = 'p-1 text-xs rounded no-underline';

function AssignmentGoalStatus({ dueDate, progress, status }) {
  const today = moment();
  const isExpired = status && status.toLowerCase() === 'expired';
  const iconClasses = 'w-4 h-4 inline-block mr-1';
  if ((status && status.toLowerCase() === 'completed') || progress * 1 === 100) {
    return (
      <Label color="green" className={labelClasses}>
        <Icon name="checkmark-circle" className={iconClasses} /> Completed
      </Label>
    );
  }
  if (today > moment(dueDate) || isExpired) {
    return (
      <Label color="red" className={labelClasses}>
        <Icon name="exclamation-triangle" className={iconClasses} /> {isExpired ? 'Expired' : 'Past Due'}
      </Label>
    );
  }
  if (1 * progress === 0 && status && status.toLowerCase() !== 'in_progress') {
    return (
      <Label basic className={labelClasses}>
        <Icon name="calendar" className={iconClasses} /> Not Started
      </Label>
    );
  }
  return (
    <Label color="yellow" className={labelClasses}>
      <span>
        <Icon name="circle" className={iconClasses} /> In Progress
      </span>
    </Label>
  );
}

function ContentName({ content, isArchived, omitType }) {
  const name = content.name || content.content_description_title;
  const type = content.content_description_type;
  const retName = [<span key={name}>{name}</span>];

  if (CatalogUtil.checkIfCourseType(type) && !omitType) {
    retName.push(
      <Label className={`ml-2 ${labelClasses}`} key={type} basic>
        {type}
      </Label>
    );
  }

  if (content.tags && content.tags.indexOf('Beta') !== -1) {
    retName.push(<BetaLabel className="ml-2" key={`beta-${name}`} />);
  }

  if (isArchived) {
    retName.push(<ArchivedLabel className="ml-2" key="archived" />);
  }

  return <span className="content-name">{retName}</span>;
}

function ContentDetails({ content }) {
  return (
    <div className="md:w-64">
      <p className="mb-0 text-sm text-gray-600">
        <span>{FormatUtil.formatContentType(content.content_description_type)}</span>
        {content.item_count ? (
          <span>
            {' '}
            | {content.item_count} Item{content.item_count > 1 && 's'}
          </span>
        ) : null}
      </p>
      {content.score !== null && content.content_description_type !== 'Career Path' && content.content_description_type !== 'Curriculum' ? (
        <p className="mb-0 text-sm text-gray-600">Score: {content.score}</p>
      ) : null}
      <p className="mb-0 text-sm text-gray-600">
        Estimated time remaining: {FormatUtil.formatTime(content.learning_seconds_total - content.learning_seconds_completed, 'hma') || '0hrs'}
      </p>
      <p className="mb-0 text-sm text-gray-600">Total time: {FormatUtil.formatTime(content.learning_seconds_total, 'hma')}</p>
    </div>
  );
}

const ExpandingContentTableRow = inject(
  'enrollmentStore',
  'notificationsStore'
)(
  observer(
    class ExpandingContentTableRow extends React.Component {
      state = {
        hasOpened: false,
      };

      getContentUpdates = (tableKey, contentDescriptionId) => {
        let updates = null;
        // If this is the assignments table, these updates are for Cybrary curricula. The updates have already occurred, so we look at the new content id
        if (tableKey === 'assignments') {
          updates = NotificationUtil.transformContentNotifications(this.props.notificationsStore.cybraryCurriculaUpdates, 'new_content_description_id');
        } else {
          updates = NotificationUtil.transformContentNotifications(this.props.notificationsStore.enrollmentsReplaced, 'old_content_description_id');
        }

        const contentUpdateData = updates[contentDescriptionId];
        /*
          Return updates if the content has a replacement that has been processed, or is scheduled to be archived
          - 4 Scenarios for content updates messaging --
          1. Content has been replaced with new content (old content UNLISTED)
          2. Content has been replaced with new content (old content ARCHIVED) - Archive scheduled further out
          3. Content has been replaced with new content (old content ARCHIVED) - Archive has already happened
          4. Content is scheduled to be ARCHIVED with no replacement
        */
        return !!updates && contentUpdateData && (contentUpdateData.processed || (contentUpdateData.archive_at && !contentUpdateData.archived)) ? contentUpdateData : null;
      };

      toggleRow = () => {
        const newState = {
          ...this.state,
          open: !this.state.open,
        };
        this.setState(newState);
      };

      getMenuOptions = () => {
        const { content, retakeFunction, openFeedbackModal, unenrollFunction, tableKey, isNested } = this.props;
        const isComplete = !!content && content.completed_at;
        const isArchived = content.content_description_archived_at;
        const contentDescriptionId = content.content_description_id;
        const contentType = content.content_description_type;
        const contentTitle = content.content_description_title;
        const options = [];
        if (!isNested && unenrollFunction && !isComplete) {
          options.push({
            text: 'Unenroll',
            action: () => unenrollFunction(contentDescriptionId, contentType, tableKey),
            icon: 'close',
          });
        }
        if (!isNested && openFeedbackModal) {
          options.push({
            text: 'Review',
            action: () => openFeedbackModal(contentDescriptionId, contentType, contentTitle),
          });
        }

        if (!isNested && retakeFunction && isComplete && !isArchived) {
          options.push({
            text: 'Retake',
            action: () => retakeFunction(contentDescriptionId, contentType),
            icon: 'redo',
          });
        }
        return options;
      };

      checkIsCompleteAssessment = (contentType, progress) => {
        const type = FormatUtil.formatContentType(contentType);
        return ['Assessment', 'Practice Test'].indexOf(type) > -1 && 1 * progress === 100;
      };

      onOpening = () => {
        const newState = {
          ...this.state,
          hasOpened: true,
        };
        this.setState(newState);
      };

      render() {
        const { content, iaView, isNested, launchItem, topLevelEnrollment, checkCerts, getAllScores, status, checkShouldExpand } = this.props;
        const { workroleData, getWorkroleData } = this.props.enrollmentStore;
        const contentUpdates = this.props.showContentUpdates ? this.getContentUpdates(this.props.tableKey, content.content_description_id) : null;
        const contentType = content.content_description_type;
        const isCybraryCollection = this.props.tableKey === 'assignments' && contentType === 'Career Path';
        const contentUpdateSummaryTitle = isCybraryCollection ? 'Your Assignment has been Updated!' : null;
        const isArchived = !!content.content_description_archived_at;
        const omitExpanding = checkShouldExpand ? !checkShouldExpand(content) : false;
        if (iaView) {
          return (
            <IAViewRow
              rowProps={this.props}
              content={content}
              iaView={iaView}
              isNested={isNested}
              launchItem={launchItem}
              topLevelEnrollment={topLevelEnrollment}
              checkCerts={checkCerts}
              getAllScores={getAllScores}
              workroleData={{ ...workroleData }}
              getWorkroleData={getWorkroleData}
              contentUpdates={contentUpdates}
              contentType={contentType}
              isCybraryCollection={isCybraryCollection}
              contentUpdateSummaryTitle={contentUpdateSummaryTitle}
              isArchived={isArchived}
              toggleRow={this.toggleRow}
              getMenuOptions={this.getMenuOptions}
              checkIsCompleteAssessment={this.checkIsCompleteAssessment}
              switchToNewContentVersion={this.props.switchToNewContentVersion}
              onOpening={this.onOpening}
              open={this.state.open}
              hasOpened={this.state.hasOpened}
              status={status}
            />
          );
        }
        return (
          <Collapsible
            triggerTagName="div"
            triggerDisabled={omitExpanding}
            className="assignments-show-hide"
            openedClassName="assignments-show-hide"
            transitionTime={200}
            onOpening={this.onOpening}
            trigger={
              <div className={`flex flex-wrap p-2 assignment-row ${contentUpdates ? 'has-updates' : ''}`}>
                <div className="w-full md:w-6/12">
                  {contentUpdates && (
                    <ContentUpdates
                      content={content}
                      contentUpdates={contentUpdates}
                      isArchived={isArchived}
                      contentUpdateSummaryTitle={contentUpdateSummaryTitle}
                      switchToNewContentVersion={this.props.switchToNewContentVersion}
                      isCybraryCollection={isCybraryCollection}
                    />
                  )}
                  <ContentName content={content} isArchived={isArchived} />
                  {!omitExpanding && <Icon name="chevron-down" className="inline-block ml-4 w-4 h-4" />}
                </div>
                <div className="w-1/2 md:w-2/12 progress-text-container">{content.progress ? `${content.progress}% Complete` : null}</div>
                <div className="m-auto w-1/12 certs-container">{this.props.checkCerts(null, null, content)}</div>
                <div className="w-1/2 text-right md:w-2/12 progress-label-container">
                  <AssignmentGoalStatus dueDate={content.due_date} progress={content.progress} status={content.status} />
                </div>
              </div>
            }
          >
            {
              // We don't want this to render and make the call for data until the section has been expanded for the first time
              this.state.hasOpened ? (
                <ExpandingContentTableDetails
                  {...this.props}
                  tableKey={this.props.tableKey}
                  contentDescriptionId={content.content_description_id}
                  enrollment={content}
                  orgId={this.props.orgId}
                  userId={this.props.userId}
                  checkCerts={this.props.checkCerts}
                  getWorkroleIcons={this.props.getWorkroleIcons}
                  progressFormat={this.props.progressFormat}
                  titleTagFormat={this.props.titleTagFormat}
                  formatCertificateLink={this.props.formatCertificateLink}
                  learningHoursCalc={this.props.learningHoursCalc}
                  formatDate={this.props.formatDate}
                  showSummary={this.props.showSummary}
                  hideCurriculaDetails={this.props.hideCurriculaDetails}
                  omitCurriculaLink={this.props.omitCurriculaLink}
                  unenrollFunction={this.props.unenrollFunction}
                  retakeFunction={this.props.retakeFunction}
                  noResultsMsg={this.props.noResultsMsg}
                  queryParams={this.props.queryParams}
                  getAllScores={this.props.getAllScores}
                  expandFunction={this.props.expandFunction}
                  isArchived={!!isArchived}
                />
              ) : null
            }
          </Collapsible>
        );
      }
    }
  )
);

export default ExpandingContentTableRow;
