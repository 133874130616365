import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import Bugsnag from '@bugsnag/js';
import DynamicForm from './DynamicForm';
import Loading from '../Loading/Loading';
import Agents from '../../agents/agents';

const RemoteDynamicForm = inject(
  'commonStore',
  'authStore'
)(
  observer(
    class RemoteDynamicForm extends Component {
      state = {
        serverErrors: {},
        form: null,
        loading: false,
      };

      componentDidMount() {
        // Fetch our form
        Agents.forms.getForm(this.props.formPermalink).then((response) => {
          if (response) {
            // apply transformations to the form
            const form = {
              ...response,
            };
            if (form.fields) {
              // If this is the business form and in_app_captcha is 0 (user is logged in - captcha not enforced) - remove captcha field
              if (this.props.formPermalink === 'enterprise_demo_request' && form.in_app_captcha === 0) {
                delete form.fields.captcha;
                const newFormOrder = form.order.filter((item) => item !== 'captcha');
                form.order = newFormOrder;
              }
              // Set form field validations
              const newForm = this.setValidations(form);

              // Add a hook to allow parent components to update the form before it's set
              const finalForm = this.props.modifyForm ? this.props.modifyForm(newForm) : newForm;
              const newState = {
                ...this.state,
                form: finalForm,
              };
              this.setState(newState);
            }
          }
        });
      }

      setValidations = (form) => {
        const clientValidations = ['number', 'phone', 'email', 'required', 'nullable'];
        const copyOfForm = form;
        Object.keys(copyOfForm.fields).forEach((fieldName) => {
          if (Object.prototype.hasOwnProperty.call(form.fields, fieldName)) {
            const field = copyOfForm.fields[fieldName];
            if (field && field.validations) {
              const validations = [];
              // Check to see if we have a special type that should add validation
              if (['phone', 'email'].indexOf(field.type) !== -1) {
                validations.push(field.type);
              }
              // Explode our validations
              const serverValidations = field.validations.split('|');
              serverValidations.forEach((serverValidation) => {
                // Check to see if the validation is a client validation and not already in our array
                if (validations.indexOf(serverValidation) === -1 && clientValidations.indexOf(serverValidation) !== -1) {
                  validations.push(serverValidation);
                }
                // Required If is a special case, in that it provides parameters, so we are looking for STARTS WITH
                if (serverValidation.indexOf('required_if') === 0) {
                  validations.push(serverValidation);
                  // Required if needs to also push nullable onto the validations stack
                  if (validations.indexOf('nullable') === -1) {
                    validations.push('nullable');
                  }
                }
              });
              field.validations = validations;
            }
          }
        });
        return copyOfForm;
      };

      // Submit logic for the enrollment form.
      submitForm = (data) => {
        const { modifySubmitPayload } = this.props;
        let submitData = {
          ...data,
          _location_hostname: window.location.hostname,
          _location_pathname: window.location.pathname,
          _location_search: window.location.search,
        };
        if (modifySubmitPayload) {
          submitData = modifySubmitPayload(submitData);
        }
        Agents.forms
          .submitForm(this.props.formPermalink, submitData)
          .then((response) => {
            this.props.authStore.fireAttributionEvent(`form:${this.props.formPermalink}`);
            this.props.onSubmit(this.props.formPermalink, response, data);
            this.setLoading(false);
          })
          .catch((error) => {
            const hideToast = !!this.props.hideToast;
            if (error.response && error.response.data && error.response.status === 422) {
              if (!hideToast) {
                this.props.commonStore.triggerToast('error', {
                  content: 'There was a problem, please check the form for errors.',
                });
              }
              Bugsnag.notify(error);
            } else if (!hideToast) {
              this.props.commonStore.triggerToast('error', {
                content: 'An unknown error occurred.  Please try again.',
              });
            }
            this.setLoading(false);
          });
      };

      clearErrorForField = (fieldName) => {
        if (this.state.serverErrors && this.state.serverErrors[fieldName]) {
          const serverErrors = {
            ...this.state.serverErrors,
            [fieldName]: undefined,
          };
          const newState = {
            ...this.state,
            serverErrors,
          };
          this.setState(newState);
        }
      };

      setLoading = (loading) => {
        const newState = {
          ...this.state,
          loading,
        };
        this.setState(newState);
      };

      // Set loading at the start of validation
      startValidation = () => {
        this.setLoading(true);
      };

      // Set loading to false at the end of validation, if it failed
      endValidation = (valid) => {
        if (!valid) {
          this.setLoading(false);
        }
      };

      render() {
        const { serverErrors, form, loading } = this.state;
        const { defaults, hiddenFields, id, formId, customRenderers } = this.props;

        if (!form) {
          return <Loading message="Loading..." />;
        }

        // If we have a generically named submit button, send it the loading property
        if (form.fields && form.fields.submit) {
          form.fields.submit.loading = loading;
        }
        // If we have custom CTA configuration from the front, set them
        if (this.props.cta) {
          if (this.props.cta.text) {
            form.fields.submit.label = this.props.cta.text;
          }
          if (this.props.cta.color) {
            form.fields.submit.color = this.props.cta.color;
          }
          if (this.props.cta.size) {
            form.fields.submit.size = this.props.cta.size;
          }
        }

        // If defaults were provided, set them on the form
        if (defaults) {
          Object.keys(defaults).forEach((fieldName) => {
            if (form.fields[fieldName]) {
              form.fields[fieldName].defaultValue = defaults[fieldName];
            }
          });
        }

        if (hiddenFields) {
          Object.keys(hiddenFields).forEach((fieldName) => {
            if (form.fields[fieldName]) {
              form.fields[fieldName].hidden = true;
            }
          });
        }

        return (
          <DynamicForm
            form={form}
            formId={formId}
            id={id}
            startValidation={this.startValidation}
            endValidation={this.endValidation}
            serverErrors={serverErrors}
            clearErrorForField={this.clearErrorForField}
            onSubmit={this.submitForm}
            customRenderers={customRenderers}
          />
        );
      }
    }
  )
);

export default RemoteDynamicForm;
