import React from 'react';
import ReactDOM from 'react-dom';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { Provider } from 'mobx-react';
import App from './components/App/App';
import stores from './stores/stores';
import './css/tailwind.css';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import BugsnagUtil from './utils/bugsnagUtil';

const releaseStage = process.env.REACT_APP_BUGSNAG_RELEASE_STAGE || 'development';
const appVersion = process.env.REACT_APP_VERSION || '0.0.1';

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_KEY,
  enabledReleaseStages: ['production', 'staging', 'blackwaterbay', 'blackwaterbay'],
  releaseStage,
  plugins: [new BugsnagPluginReact(React)],
  collectUserIp: false,
  maxBreadcrumbs: 50,
  appVersion,
});
Bugsnag.addOnError(BugsnagUtil.discardUselessErrors);
Bugsnag.addOnError(BugsnagUtil.retitleImmersiveErrors);
Bugsnag.addOnError(BugsnagUtil.retitleEnterpriseErrors);

ReactDOM.render(
  <ErrorBoundary>
    <Provider {...stores}>
      <App />
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root')
);
