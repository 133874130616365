const EXAMPLE_PROMO = {
  // Enable or disable the promo
  isEnabled: false,

  // Set the priority of the promo. Higher number = shown first.
  priority: 1,

  // Enable or disable the ability to dismiss the promo and delay the ability to re-show it
  isDismissible: true,
  delayOnDismiss: 1440, // Time in minutes before the promo can be shown again after being dismissed
  dismissedKey: 'exampleDismissed', // Unique key to identify the promo in the cookie when dismissed

  // Set the start and expiration date of the promo
  startDate: '2024-11-01T00:00:00-05:00',
  endDate: '2024-12-01T00:00:00-05:00',

  // Show the promo on specific pages. Uses regex matching on the current pathname
  isShownOnPages: [/\/upgrade/],

  // Show the promo based on the user's role/state
  isShownTeamLearner: false,
  isShownTeamAdmin: false,
  isShownTeamOwner: false,
  isShownCipFree: true,
  isShownCipMonthly: false,
  isShownCipAnnual: false,
  isShownCipBiennial: false,
  isShownCipEduGovMilFree: false,
  isShownCipEduGovMilMonthly: false,
  isShownCipEduGovMilAnnual: false,
  isShownCipEduGovMilBiennial: false,

  // Set Snowplow tracking labels
  spLabel: 'examplePromo',
  spCategory: 'CIPUpgradeBanner',

  // Configure CTA Link
  targetUrl: '/upgrade/checkout?discountId=dsc_1234567890&plan=annual&lockPlan=1',

  // Configure the attributes of the banner
  title: 'title',
  titleClassName: '',
  body: 'body',
  bodyClassName: '',
  footer: 'footerMessage',
  footerClassName: '',
  iconName: 'unlock', // @see https://app.blackwaterbay.cybrary.it/icons for available icons
  iconClassName: '',
  ctaButtonText: 'ctaButtonText',
  ctaButtonClassName: '',
  bannerClassName: 'from-cyb-purple-500 to-cyb-purple-500',

  // OR pass in an UpgradePromoBanner component. This will override the title, body, iconName, and targetUrl
  component: null,
};

export default EXAMPLE_PROMO;
