import React from 'react';

export default function Trophy({ classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';
  return (
    <svg className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...accessibilityProps}>
      <path
        d="M23.9992 4.57847C23.9753 2.54152 22.9329 1.41966 21.0639 1.41966C20.3405 1.41966
           19.6435 1.58803 19.1878 1.72852C19.2332 0.895922 19.2339 0.381984 19.2339 
           0.356438L19.234 0H4.76641V0.356391C4.76641 0.368578 4.76763 0.890625 4.81352 
           1.72894C4.3579 1.58836 3.66021 1.41966 2.93613 1.41966C1.06722 1.41966 0.0247706 
           2.54147 0.000864362 4.57847C-0.0226669 6.58237 0.434364 8.36616 1.35916 
           9.88036C2.26713 11.367 3.42766 12.2628 4.24136 12.7524C5.54463 13.5367 6.84715 
           13.7014 7.7104 13.7014C7.92321 13.7014 8.12824 13.6913 8.32001 13.6716C8.33271 
           13.6702 8.34447 13.6687 8.3569 13.6674C8.77427 14.1169 9.22422 14.4769 9.70502 
           14.7445C10.0594 14.9417 10.306 15.2856 10.3904 15.6809C9.91254 15.8296 9.56458 
           16.2761 9.56458 16.8023C9.56458 17.3403 9.92847 17.7946 10.4229 
           17.9329V19.4287H8.49433V20.9897H6.59121V23.9975H17.4091V20.9897H15.5059V19.4287H13.5774V17.9329C14.0718 
           17.7946 14.4357 17.3403 14.4357 16.8023C14.4357 16.2761 14.0878 15.8296 13.6099 15.6809C13.6944 
           15.2856 13.9408 14.9417 14.2953 14.7445C14.7749 14.4776 15.228 14.1156 15.6443 13.6676C15.7918 13.6839 
           16.013 13.7013 16.2896 13.7013C16.2897 13.7013 16.2896 13.7013 16.2898 13.7013C17.1529 13.7013 18.4554 13.5366 
           19.7586 12.7524C20.5722 12.2627 21.7328 11.367 22.6408 9.88031C23.5657 8.36616 24.0227 6.58233 23.9992 
           4.57847ZM18.5153 0.712781C18.5121 0.843141 18.507 1.00702 18.4992 1.19883H5.50254C5.49447 1.00706 5.48908 
           0.842953 5.48561 0.712781H18.5153ZM19.0001 3.98527C19.483 3.82453 20.1088 3.66244 20.6695 3.66244C21.1396 3.66244 
           21.4781 3.77728 21.6756 4.00378C21.9025 4.26412 21.9757 4.70147 21.8929 5.30381C21.5483 7.81284 20.4603 9.77138 
           18.8295 10.8185C18.2614 11.1833 17.6496 11.334 17.1369 11.3875C18.2214 9.10795 18.7463 6.24783 19.0001 
           3.98527ZM5.36697 6.46791C5.71755 8.3483 6.20158 9.96305 6.80571 11.2673C6.82441 11.3078 6.84344 11.3476 6.86238 
           11.3875C6.34985 11.3339 5.73841 11.1832 5.17057 10.8186C3.53974 9.77142 2.45177 7.81294 2.1071 5.30391C2.02436 
           4.70152 2.09749 4.26412 2.3245 4.00387C2.52199 3.77737 2.86052 3.66258 3.33058 3.66258C3.89172 3.66258 4.51816 3.82491 
           5.00126 3.98573C5.08755 4.75739 5.20554 5.60203 5.36697 6.46791ZM7.7104 12.9886C6.93752 12.9886 5.77197 12.8416 4.60896 
           12.1417C3.41951 11.426 0.658802 9.25688 0.713646 4.58686C0.733052 2.93531 1.46004 2.13248 2.93613 2.13248C3.72555 
           2.13248 4.50522 2.37047 4.86161 2.496C4.87938 2.73511 4.8993 2.97492 4.92133 3.21375C4.43688 3.07144 3.86608 2.94966 
           3.33058 2.94966C2.64536 2.94966 2.12613 3.14667 1.78722 3.53527C1.41883 3.95775 1.28885 4.58541 1.40088 5.4008C1.59279 
           6.79772 1.99385 8.03241 2.59296 9.0705C3.16488 10.0615 3.90255 10.8515 4.7854 11.4183C5.64771 11.972 6.58038 12.1117 
           7.24568 12.125C7.24793 12.129 7.25022 12.1327 7.25247 12.1366C7.28894 12.2005 7.32579 12.2633 7.363 12.3253C7.37458 
           12.3447 7.38626 12.3639 7.39793 12.3831C7.43341 12.4414 7.46918 12.4991 7.50532 12.5557C7.51891 12.577 7.53269 12.5977 
           7.54638 12.6188C7.57099 12.6567 7.59574 12.6942 7.62068 12.7313C7.64477 12.7671 7.66896 12.8026 7.69329 12.8377C7.71715 
           12.8721 7.74105 12.9062 7.76515 12.9399C7.77644 12.9558 7.78755 12.9722 7.79885 12.9879C7.77016 12.9884 7.74068 
           12.9886 7.7104 12.9886ZM16.6963 21.7026V23.2847H7.30408V21.7026H8.49438H15.506H16.6963ZM13.5775 
           20.1416H14.7932V20.9897H9.20721V20.1416H10.4229H13.5775ZM11.1357 19.4287V17.9765H12.8647V19.4287H11.1357ZM13.723 
           16.8023C13.723 17.0567 13.516 
           17.2636 13.2617 17.2636H10.7388C10.4844 17.2636 10.2774 17.0567 10.2774 16.8023C10.2774 16.5479 
           10.4844 16.341 10.7388 16.341H13.2617C13.5161 16.341 13.723 16.5479 13.723 16.8023ZM15.5058 12.7277C15.5019 12.7328 15.498 12.738 15.494 
           12.7431C15.459 12.7884 15.4236 12.8327 15.3881 12.8763C15.3794 12.887 15.3708 12.8978 15.362 12.9084C15.323 12.9558 
           15.2836 13.0022 15.2441 13.0475C15.2421 13.0497 15.2403 13.0519 15.2384 13.0541C14.841 13.5075 14.4071 13.8666 13.9488 
           14.1216C13.3839 14.4359 12.9998 14.994 12.8942 15.6281H11.1063C11.0006 14.994 10.6165 14.4359 10.0516 14.1216C9.60925 
           13.8756 9.20979 13.5466 8.8489 13.1536L8.76171 13.0541C8.40733 12.65 8.0733 12.1603 7.76894 11.5989L7.67571 
           11.4268C6.14304 8.46539 5.67944 4.18519 5.53966 1.91161H18.4633C18.4574 2.01052 18.4509 2.11266 18.4436 
           2.21873C18.4433 2.22366 18.4429 2.22872 18.4426 2.23364C18.4347 2.34858 18.4263 2.46384 18.4173 2.5792C18.4171 
           2.58258 18.4168 2.58595 18.4166 2.58938C18.3881 2.955 18.3543 3.32198 18.3159 3.68461L18.2926 3.90389C18.0336 6.22552 
           17.4866 9.18319 16.3331 11.4111L16.2313 11.5988C16.0046 12.0168 15.7616 12.3949 15.5058 12.7277ZM19.3911 12.1417C18.228 
           12.8417 17.0627 12.9886 16.2899 12.9886C16.2898 12.9886 16.2897 12.9886 16.2897 12.9886C16.2594 12.9886 16.2299 12.9884 
           16.2011 12.9879C16.2016 12.9872 16.2021 12.9865 16.2026 12.9859C16.2488 12.922 16.2944 12.8572 16.3392 12.7916C16.3447 
           12.7836 16.3501 12.7754 16.3556 12.7673C16.3935 12.7115 16.4309 12.6548 16.4678 12.5977C16.4773 12.583 16.4868 12.5683 
           16.4963 12.5535C16.5371 12.4893 16.5776 12.4247 16.6173 12.359C16.6235 12.3488 16.6295 12.3382 16.6357 12.3278C16.6682 
           12.2736 16.7003 12.2188 16.732 12.1635C16.7394 12.1505 16.7471 12.1381 16.7545 12.1251C17.4198 12.1118 18.3525 11.9721 
           19.2148 11.4184C20.0976 10.8515 20.8353 10.0615 21.4072 9.07059C22.0063 8.0325 22.4073 6.79781 22.5993 5.40089C22.7112 
           4.58545 22.5813 3.9578 22.213 3.53536C21.8741 3.14672 21.3548 2.9497 20.6696 2.9497C20.1341 2.9497 19.5633 3.07139 
           19.0788 3.21375C19.1013 2.97023 19.1206 2.73666 19.1371 2.51466C19.1375 2.50847 19.1381 2.50223 19.1385 2.49609C19.495 
           2.37052 20.2746 2.13258 21.064 2.13258C22.5401 2.13258 23.2671 2.93541 23.2865 4.58686C23.3412 9.25688 20.5806 11.4259 
           19.3911 12.1417Z"
        fill="currentColor"
      />
      <path
        d="M9.01719 11.5391L8.42188 11.9313C8.62512 12.2398 8.84356 12.5202 9.07114 12.7645L9.59276 
          12.2786C9.39228 12.0634 9.19864 11.8145 9.01719 11.5391Z"
        fill="currentColor"
      />
      <path
        d="M7.48794 7.59375L6.79297 7.75228C7.13478 9.25064 7.59111 10.5085 8.1492 11.4908L8.76898 
          11.1387C8.2453 10.2169 7.81428 9.02423 7.48794 7.59375Z"
        fill="currentColor"
      />
      <path
        d="M20.7977 15.6345L20.7972 14.9217L20.1954 14.922L20.195 14.3203L19.4822 14.3208L19.4826 
          14.9226L18.8809 14.923L18.8813 15.6358L19.4831 15.6354L19.4835 16.2371L20.1963 16.2366L20.1959 
          15.6349L20.7977 15.6345Z"
        fill="currentColor"
      />
      <path
        d="M5.288 19.3345L5.28725 18.6217L4.78231 18.6222L4.7818 18.1172L4.06897 18.1179L4.06948 
          18.6229L3.56445 18.6234L3.56525 19.3362L4.07019 19.3357L4.0707 19.8407L4.78353 19.84L4.78302 
          19.335L5.288 19.3345Z"
        fill="currentColor"
      />
      <path d="M2.96148 13.5078H2.28906V14.2206H2.96148V13.5078Z" fill="currentColor" />
      <path d="M7.28375 15.6172H6.61133V16.33H7.28375V15.6172Z" fill="currentColor" />
      <path d="M18.1216 17.7578H17.4492V18.4706H18.1216V17.7578Z" fill="currentColor" />
      <defs>
        <clipPath id="clip0_124_27141">
          <rect width="100%" height="100%" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
