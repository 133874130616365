import React, { useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { useCybMeasure } from '../../providers/CybMeasureProvider';
import { useCybAssessmentAnalytics } from '../../providers/CybAssessmentAnalyticsProvider';
import Container from '../../components/Container/Container';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import LoadingText from '../../components/Loading/LoadingText';
import If from '../../components/If/If';
import Header from '../../components/Header/Header';
import TeamBaselineGoalWidget from '../../components/Measure/Baseline/TeamBaselineGoalWidget';
import TeamBaselineGoalTabs from '../../components/Measure/Baseline/TeamBaselineGoalTabs';

const BaselineGoal = inject(
  'userStore',
  'authStore',
  'commonStore'
)(
  observer(({ userStore, authStore, commonStore }) => {
    const { goalId } = useParams();

    const { tracks, isLoading: isLoadingTracks } = useCybAssessmentAnalytics();

    const { teamBaselineGoalDetails, isLoadingTeamBaselineGoalDetails, getTeamBaselineGoalDetails } = useCybMeasure();

    const orgId = userStore?.teamData?.id;

    const isLoading = isLoadingTracks || isLoadingTeamBaselineGoalDetails;

    const goalDetails = useMemo(() => teamBaselineGoalDetails[`${orgId}_${goalId}`], [orgId, goalId, teamBaselineGoalDetails]);

    const role = useMemo(() => {
      const userTracks = tracks[userStore.user?.id]?.concat();

      if (!userTracks || !goalDetails) {
        return null;
      }

      return userTracks.find((track) => track['content-id'] === parseInt(goalDetails.content_description_id, 10));
    }, [tracks, goalDetails, userStore.user?.id]);

    const crumbs = useMemo(() => {
      const crumbies = [
        {
          href: `/measure/baseline`,
          label: 'Overview',
        },
      ];

      if (goalDetails) {
        crumbies.push({
          href: window.location.pathname,
          label: goalDetails.name,
        });
      }

      return crumbies;
    }, [goalDetails]);

    useEffect(() => {
      commonStore.setPageTitle('Measure - Baselining Goal | Cybrary');
      authStore.fireAttributionEvent();
    }, []);

    useEffect(() => {
      if (!goalDetails && !isLoading) {
        getTeamBaselineGoalDetails(orgId, goalId);
      }
    }, [goalDetails, isLoading]);

    return (
      <Container className="pt-0 sm-576:px-4" size="md-1164" omitPadding>
        <div className="flex flex-col my-8 w-full">
          <div className="flex flex-row items-center pb-6">
            <Breadcrumbs crumbs={crumbs} wrapperClasses="pb-0" />
            <If condition={isLoading}>
              <LoadingText wrapperClassName="w-20 h-4 p-0" className="h-4 rounded-none" />
            </If>
          </div>
          <div className="flex flex-row justify-between items-center">
            <If condition={isLoading}>
              <>
                <LoadingText wrapperClassName="w-60 h-8 p-0" className="h-8 rounded-none" />
                <LoadingText wrapperClassName="w-72 h-8 p-0" className="h-8 rounded-none" />
              </>
            </If>
            <If condition={!isLoading}>
              <>
                <Header as="h1" className="flex-1 m-0 text-2xl font-black lg:text-2xl">
                  {goalDetails?.name}
                </Header>
                <span className="flex-1 text-sm text-end">
                  <span>
                    <b>Role: </b>
                    {role?.['content-name']}
                  </span>
                  <span className="mx-2">|</span>
                  <span>
                    <b>Owners: </b>
                    {goalDetails?.owners?.map((owner, index) => {
                      const isLast = index === goalDetails.owners.length - 1;

                      return (
                        <span key={owner.id}>
                          {owner.name}
                          <If condition={!isLast}>{', '}</If>
                        </span>
                      );
                    })}
                  </span>
                </span>
              </>
            </If>
          </div>
        </div>
        <TeamBaselineGoalWidget isLoading={isLoading} goalDetails={goalDetails} />
        <div className="pt-5 pb-20">
          <TeamBaselineGoalTabs orgId={orgId} goalId={goalId} />
        </div>
      </Container>
    );
  })
);

export default BaselineGoal;
