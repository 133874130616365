import React, { useMemo } from 'react';
import Slider from 'react-slick';
import { useProfileContext } from '../../providers/ProfileProvider';
import Loading from '../Loading/Loading';
import Badge from '../Profile/ProfileTabs/BadgesTab/Badge';
import If from '../If/If';
import { useBadgeModal } from '../../providers/BadgeModalProvider';
import BasicTabs from '../Tabs/BasicTabs';

// Sort function for badges - Newest first
const recentlyCreatedBadgesFirstSort = (a, b) => {
  const aDate = new Date(a.created_at);
  const bDate = new Date(b.created_at);
  return bDate - aDate;
};

// Sort function for badges - Recently earned first
const recentlyEarnedBadgesFirstSort = (a, b) => {
  const aDate = new Date(a.completed_at);
  const bDate = new Date(b.completed_at);
  return bDate - aDate;
};

// Filter function for recently earned badges - earned within the last 30 days
const recentlyEarnedBadgesFilter = (badge) => {
  if (!badge.completed_at) return false;
  const badgeDate = new Date(badge.completed_at);
  const today = new Date();
  const thirtyDaysAgo = new Date(today.setDate(today.getDate() - 30));
  return badgeDate > thirtyDaysAgo;
};

// Filter function for in progress badges
const inProgressFilter = (badge) => !badge.completed_at && badge.progress > 0;

function BadgesSlider({ view, noItemsJsx }) {
  const { open } = useBadgeModal();
  const { isLoading, badges } = useProfileContext();

  const displayBadges = useMemo(() => {
    switch (view) {
      case 'in_progress':
        return badges.filter(inProgressFilter);
      case 'recentlyEarned':
        return badges.filter(recentlyEarnedBadgesFilter).sort(recentlyEarnedBadgesFirstSort);
      case 'new':
        return badges.sort(recentlyCreatedBadgesFirstSort);
      default:
        return badges;
    }
  }, [badges, view]);

  return (
    <div className="flex flex-col pb-2 mx-auto w-full lg:max-w-[675px] lg:min-h-[360px]">
      {/** Loading State */}
      <If condition={isLoading}>
        <Loading message="Loading badges..." wrapperClassName="w-full h-4/5 my-12" />
      </If>
      <If condition={!isLoading && displayBadges?.length > 0}>
        <Slider
          infinite={false}
          dots
          speed={500}
          slidesToShow={3}
          slidesToScroll={3}
          arrows={false}
          className="pb-2"
          responsive={[
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
              },
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              },
            },
          ]}
        >
          {displayBadges?.slice(0, 20).map((badge) => (
            <Badge key={badge.id} badge={badge} className="bg-white rounded-md" wrapperClassName="px-1" onClick={() => open(badge)} showProgress noDescription alwaysUnlocked />
          ))}
        </Slider>
      </If>
      <If condition={!isLoading && (!displayBadges || displayBadges?.length === 0)}>
        <p className="flex justify-center items-center min-h-[200px] text-center text-gray-500 text-md">{noItemsJsx}</p>
      </If>
    </div>
  );
}

/**
 * Badges widget for Dashboard
 * @returns <BadgesWidget /> the badges widget
 */
function BadgesWidget() {
  const { isLoading, badges } = useProfileContext();
  // Try to put the user on their most relevant tab:
  // 1. If they have in progress badges, show them first
  // 2. If they have recently earned badges, show them second
  // 3. If there are new badges, show them last
  const initTabIndex = useMemo(() => {
    if (badges.some(inProgressFilter)) {
      return 0;
    }
    if (badges.some(recentlyEarnedBadgesFilter)) {
      return 1;
    }
    return 2;
  }, [badges]);

  // Mount content to each tab
  const transformedTabs = useMemo(() => {
    // Tabs to display in the continue learning block
    return [
      {
        key: 'inProgress',
        id: 'inProgress',
        title: 'In Progress',
        content: <BadgesSlider view="in_progress" noItemsJsx="You have no in progress badges yet." />,
      },
      {
        key: 'recentlyEarned',
        id: 'recentlyEarned',
        title: 'Recently Earned',
        content: <BadgesSlider view="recentlyEarned" noItemsJsx="You have no recently earned badges." />,
      },
      {
        key: 'new',
        id: 'new',
        title: 'Newly Added',
        content: <BadgesSlider view="new" noItemsJsx="There are no new badges at this time. Check back later!" />,
      },
    ];
  }, [isLoading, badges]);

  return (
    <div className="w-full h-full lg:min-h-[255px]">
      {/** Tab Content */}
      <BasicTabs
        tabs={transformedTabs}
        initTabIndex={initTabIndex}
        headerTitle="Badges"
        headerTitleLink="/profile?tab=badges"
        omitContainerBorder
        navContainerClassName="pt-1 lg:px-4 lg:pt-3"
        headerTitleClassName="pl-2 lg:px-0"
      />
    </div>
  );
}

export default BadgesWidget;
