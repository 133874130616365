export default class StyleUtil {
  /**
   * get the background style for the app page header and label style
   * @params type
   */
  static getTypeStyle = (type, metaImage) => {
    let ret = null;
    switch (type) {
      case 'Practice Labs':
      case 'Practice Labs Lab':
        ret = {
          labelBackgroundColor: '#1e55b4',
          labelTextColor: '#fff',
          bgColor: 'purple',
          backgroundImage: 'https://images.ctfassets.net/kvf8rpi09wgk/2eV2ixbrZQEjMxI8dfy1qA/b2107e1b180061de20e7a4dd22241662/Lab_default.png?w=600',
        };
        break;
      case 'Practice Labs Exam':
        ret = {
          labelBackgroundColor: '#1e55b4',
          labelTextColor: '#fff',
          bgColor: 'purple',
          backgroundImage: 'https://images.ctfassets.net/kvf8rpi09wgk/3miac35Ih3zEKnRj5pd3pe/d3d783aa3105d32cc303998482412cb7/Exam_default.png?w=600',
        };
        break;
      case 'Cyberscore':
      case 'CyberScore Lab':
        ret = {
          labelBackgroundColor: '#fb791a',
          labelTextColor: '#fff',
          bgColor: 'purple',
          backgroundImage: 'https://images.ctfassets.net/kvf8rpi09wgk/2eV2ixbrZQEjMxI8dfy1qA/b2107e1b180061de20e7a4dd22241662/Lab_default.png?w=600',
        };
        break;
      case 'Cydefe':
      case 'CyDefe Assessment':
        ret = {
          labelBackgroundColor: '#505c6d',
          labelTextColor: '#ff3e01',
          bgColor: 'purple',
          backgroundImage: 'https://images.ctfassets.net/kvf8rpi09wgk/wE3STi7SZJvSzTQzb7gMi/68b991734ac190938b55209bb0d2e145/Assessment_default.png?w=600',
        };
        break;
      case 'Kaplan Practice Test':
      case 'CyberVista Practice Test':
        ret = {
          labelBackgroundColor: '#4804b4',
          labelTextColor: '#fff',
          bgColor: 'purple',
          backgroundImage: 'https://images.ctfassets.net/kvf8rpi09wgk/7gwm2RzAAJBH0RqcWYfeNj/a708498b9f9abc7ddffc95a0766c92f8/Kaplan_backdrop.jpg?w=600',
        };
        break;
      case 'Interview Mocha Test':
      case 'iMocha Test':
        ret = {
          labelBackgroundColor: '#4804b4',
          labelTextColor: '#fff',
          bgColor: 'purple',
          backgroundImage: 'https://images.ctfassets.net/kvf8rpi09wgk/wE3STi7SZJvSzTQzb7gMi/68b991734ac190938b55209bb0d2e145/Assessment_default.png?w=600',
        };
        break;
      case 'Infosec Lab':
        ret = {
          labelBackgroundColor: '#4804b4',
          labelTextColor: '#fff',
          bgColor: 'purple',
          backgroundImage: 'https://images.ctfassets.net/kvf8rpi09wgk/6ySeZpzSwpiVxgWIN8sO1b/9400e49a925e60f6845aa4b5bad032f5/infosec-learning-backdrop.jpg?w=600',
        };
        break;
      case 'Rangeforce Lab':
        ret = {
          labelBackgroundColor: '#9f1e1d',
          labelTextColor: '#171717',
          bgColor: 'purple',
          backgroundImage: 'https://images.ctfassets.net/kvf8rpi09wgk/2eV2ixbrZQEjMxI8dfy1qA/b2107e1b180061de20e7a4dd22241662/Lab_default.png?w=600',
        };
        break;
      case 'Rangeforce Assessment':
      case 'Rangeforce':
        ret = {
          labelBackgroundColor: '#9f1e1d',
          labelTextColor: '#171717',
          bgColor: 'purple',
          backgroundImage: 'https://images.ctfassets.net/kvf8rpi09wgk/wE3STi7SZJvSzTQzb7gMi/68b991734ac190938b55209bb0d2e145/Assessment_default.png?w=600',
        };
        break;
      case 'Learn On Demand IT Pro Challenges':
      case 'Learn On Demand Assessment':
        ret = {
          labelBackgroundColor: '#3399ff',
          labelTextColor: '#000',
          bgColor: 'purple',
          backgroundImage: 'https://images.ctfassets.net/kvf8rpi09wgk/wE3STi7SZJvSzTQzb7gMi/68b991734ac190938b55209bb0d2e145/Assessment_default.png?w=600',
        };
        break;
      case 'Next Tech Course':
        ret = {
          labelBackgroundColor: '#c1426b',
          labelTextColor: '#fff',
          bgColor: 'purple',
          backgroundImage: 'https://images.ctfassets.net/kvf8rpi09wgk/5BWDcBFUXVOcoy3yGmE5df/182fcadb46464dce63415c4ac02096cd/Course_default.png?w=600',
        };
        break;
      case 'Avatao Course':
        ret = {
          labelBackgroundColor: '#1e55b4',
          bgColor: 'purple',
          labelTextColor: '#fff',
          backgroundImage: 'https://images.ctfassets.net/kvf8rpi09wgk/5BWDcBFUXVOcoy3yGmE5df/182fcadb46464dce63415c4ac02096cd/Course_default.png?w=600',
        };
        break;
      case 'Video Activity':
      case 'Cybrary Live':
      case 'Cybrary Live Series':
      case 'Cybrary Live Session':
        ret = {
          bgColor: 'blue',
          backgroundImage: 'https://images.ctfassets.net/kvf8rpi09wgk/5BWDcBFUXVOcoy3yGmE5df/182fcadb46464dce63415c4ac02096cd/Course_default.png?w=600',
        };
        break;
      case 'Course':
      case 'MicroCourse':
        ret = {
          labelBackgroundColor: '#fff',
          labelTextColor: '#000',
          bgColor: 'orange',
          backgroundImage: metaImage || 'https://images.ctfassets.net/kvf8rpi09wgk/5BWDcBFUXVOcoy3yGmE5df/182fcadb46464dce63415c4ac02096cd/Course_default.png?h=600',
        };
        break;
      case 'Career Path':
      default:
        ret = {
          labelBackgroundColor: '#fff',
          labelTextColor: '#000',
          bgColor: 'orange',
          backgroundImage: metaImage || 'https://images.ctfassets.net/kvf8rpi09wgk/7mDkcCWlKDDrWxjSP5JzgK/f07e873635fe62b973d1f4dff1ad0e34/Career_Path_default.png?h=600',
        };
        break;
    }
    return ret;
  };

  /**
   * get the teaser image for upgrade paywall
   * @params type
   */
  static getTeaserImage = (type) => {
    let ret = {};
    switch (type) {
      case 'Practice Labs':
      case 'Practice Labs Lab':
      case 'Practice Labs Exam':
        ret = {
          teaserImage: 'https://images.ctfassets.net/kvf8rpi09wgk/54rTvcObSygLLeNLmJiC4w/082dcb6385f96a51a690231c3575492f/practice_labs_upgrade.png?w=500',
        };
        break;
      case 'Cyberscore':
      case 'CyberScore Lab':
        ret = {
          teaserImage: 'https://images.ctfassets.net/kvf8rpi09wgk/XDAAmmf56j1PaeCkYVN0N/e4baff1877a50daaf4c2c9ee24ef0f23/cybrscore.png?w=500',
        };
        break;
      case 'Cydefe':
      case 'CyDefe Assessment':
        ret = {
          teaserImage: 'https://images.ctfassets.net/kvf8rpi09wgk/tAJWkyPyD2y48PpJGsYvs/5e3d15fe51d42d02b07d63cf209a9bfe/cydefe.png?w=500',
        };
        break;
      case 'Kaplan':
      case 'Kaplan Practice Test':
      case 'CyberVista':
      case 'CyberVista Practice Test':
        ret = {
          teaserImage: 'https://images.ctfassets.net/kvf8rpi09wgk/2Jvys7NrxGM1F5iaPzZ1pu/30dbf3e7cd217db5e229744b23935303/Kaplan.png?w=500',
        };
        break;
      case 'Rangeforce':
      case 'Rangeforce Lab':
      case 'Rangeforce Assessment':
        ret = {
          teaserImage: 'https://images.ctfassets.net/kvf8rpi09wgk/7LidKHbL02UECibvhn9vVF/3bc16697852c0a3b8daf21ce5c68e7b6/rangeforce_upgrade.png?w=500',
        };
        break;
      case 'Infosec Lab':
        ret = {
          teaserImage: 'https://images.ctfassets.net/kvf8rpi09wgk/FYjteHZE9tH3RnIyegB3z/8c3fa877d7c0e237cf48805985dbfb70/infosec-learning-teaser.png?w=500',
        };
        break;
      case 'Learn on Demand':
      case 'Learn on Demand IT Pro Challenges':
      case 'Learn on Demand Assessment':
      case 'Learn On Demand Systems':
        ret = {
          teaserImage: 'https://images.ctfassets.net/kvf8rpi09wgk/6sAVHJCpUK9s4xahKPMnk6/cc4434a74cd652322e328ee616cea13f/LODS_upgrade.png?w=500',
        };
        break;
      case 'Interview Mocha':
      case 'Interview Mocha Test':
      case 'iMocha Test':
      case 'Course':
      case 'MicroCourse':
      default:
        ret = {
          teaserImage: 'https://images.ctfassets.net/kvf8rpi09wgk/7ZzzVKBdF0inhY86QyhEu/bbc064bd793f403b09e5ee0eeb9661df/interview_mocha.png?w=500',
        };
    }
    return ret.teaserImage;
  };

  static getGraphColorDefaults = () => {
    const graphColorDefaults = {
      primaryColors: [
        '#FC5C65',
        '#4B7BEC',
        '#FD9644',
        '#2BCBBA',
        '#FED330',
        '#A55EEA',
        '#BD454C',
        '#385CB1',
        '#BE7133',
        '#20988C',
        '#BF9E24',
        '#7C47B0',
        '#FEAEB2',
        '#A5BDF6',
        '#FECBA2',
        '#95E5DD',
        '#FFE998',
        '#D2AFF5',
      ],
      primaryColorsAlternate: ['#4B7BEC', '#FC5C65', '#FD9644'],
      progressColors: ['#DF057B'],
      d3SchemaCategory10: ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22', '#17becf'],
    };

    return graphColorDefaults;
  };

  static getGoalsGraphColorDefaults = () => {
    return ['#4B7BEC', '#FD9644', '#FC5C65'];
  };

  /**
   * Get color scheme for promo banner
   */
  static getColorsScheme = (data) => {
    const { textColor, backgroundColor } = data;

    const pink = 'bg-cyb-pink-500';
    const white = 'bg-white';
    const black = 'bg-black';
    const green = 'bg-cyb-green-500';
    const scheme = { background: green, color: white }; // default colors if none are provided

    if (textColor === 'Default (White)') {
      scheme.color = `text-white`;
    } else if (textColor === 'Black') {
      scheme.color = `text-black`;
    }

    if (backgroundColor === 'Default (Green)') {
      scheme.background = green;
    } else if (backgroundColor === 'Pink') {
      scheme.background = pink;
    } else if (backgroundColor === 'Black') {
      scheme.background = black;
    } else if (backgroundColor === 'White') {
      scheme.background = white;
    }

    return scheme;
  };

  static formatIconName(iconName) {
    const iconNameMap = {
      video: 'video-camera',
      'edit outline': 'pencil-alt',
      flask: 'beaker',
      'clipboard list': 'clipboard-list',
      book: 'book-open',
      star: 'star',
      'clock outline': 'clock',
      'dollar sign': 'currency-dollar',
      'calendar alternate outline': 'calendar',
      'grid layout': 'view-grid',
      shield: 'shield-check',
      computer: 'desktop-computer',
      'chart bar': 'chart-bar',
      road: 'paper-airplane',
      linkify: 'link',
      'shipping fast': 'lightning-bolt',
    };

    return iconNameMap[iconName] || iconName;
  }

  static formatColorName(color) {
    switch (color) {
      case 'pink':
        return `text-cyb-pink-500`;
      case 'green':
        return `text-cyb-green-500`;
      case 'blue':
        return `text-cyb-blue-500`;
      case 'dark-gray':
        return `text-gray-700`;
      case 'white':
        return `text-white`;
      case 'black':
        return `text-black`;
      case 'red':
        return `text-red-500`;
      case 'yellow':
        return `text-yellow-300`;
      case 'orange':
        return `text-orange-500`;
      case 'teal':
        return `text-teal-500`;
      case 'indigo':
        return `text-indigo-500`;
      case 'purple':
        return `text-purple-500`;
      case 'gray':
      default:
        return `text-cyb-gray-500`;
    }
  }

  static getFontSize(fontSize) {
    switch (fontSize) {
      case 'small':
        return 'text-2xl';
      case 'medium':
        return 'text-3xl';
      case 'huge':
        return 'text-5xl';
      case 'large':
      default:
        return 'text-4xl';
    }
  }

  static getFontWeight = (fontWeight) => {
    switch (fontWeight) {
      case '100':
        return 'font-hairline';
      case '200':
        return 'font-thin';
      case '300':
        return 'font-light';
      case '400':
        return 'font-normal';
      case '500':
        return 'font-medium';
      case '700':
        return 'font-bold';
      case '800':
        return 'font-extrabold';
      case '900':
        return 'font-black';
      case '600':
      default:
        return 'font-semibold';
    }
  };

  static getHeroOverlay = (heroOverlay) => {
    switch (heroOverlay) {
      case 'dark-overlay':
        return 'bg-black/60';
      case 'light-overlay':
        return 'bg-white/60';
      case '':
      default:
        return '';
    }
  };

  static getTextAlign = (textAlign) => {
    switch (textAlign) {
      case 'Left':
        return 'text-left';
      case 'Right':
        return 'text-right';
      case 'Center':
      default:
        return 'text-center';
    }
  };

  static getBackgroundColor = (color) => {
    switch (color) {
      case 'gray':
        return 'bg-cyb-gray-500';
      case 'pink':
        return 'bg-cyb-pink-500';
      case 'green':
        return 'bg-cyb-green-500';
      case 'blue':
        return 'bg-cyb-blue-500';
      case 'dark-gray':
        return 'bg-gray-700';
      case 'white':
        return 'bg-white';
      case 'red':
        return 'bg-red-500';
      case 'yellow':
        return 'bg-yellow-500';
      case 'orange':
        return 'bg-orange-500';
      case 'teal':
        return 'bg-teal-500';
      case 'indigo':
        return 'bg-indigo-500';
      case 'purple':
        return 'bg-purple-500';
      case 'cyb-black':
      case 'black':
      default:
        return 'bg-black';
    }
  };

  static getTextColor = (textColor) => {
    switch (textColor) {
      case 'light-gray':
        return 'text-gray-200';
      case 'dark-gray':
        return 'text-gray-700';
      case 'gray':
        return 'text-cyb-gray-500';
      case 'green':
        return 'text-cyb-green-500';
      case 'cyb-black':
      case 'black':
        return 'text-black';
      case 'white':
      case '#f9f9f9':
      default:
        return 'text-white';
    }
  };

  static getPaddingBottom = (paddingBottom) => {
    switch (paddingBottom) {
      case 'Small':
        return 'pb-4';
      case 'Medium':
        return 'pb-16';
      case 'None':
        return 'pb-0';
      case 'Large':
      default:
        return 'pb-32';
    }
  };

  static getPaddingTop = (paddingTop) => {
    switch (paddingTop) {
      case 'Small':
        return 'pt-4';
      case 'Medium':
        return 'pt-16';
      case 'None':
        return 'pt-0';
      case 'Large':
      default:
        return 'pt-32';
    }
  };

  static getReactSelectCustomStyles = (showOptionsCheckboxes, isCompact) => {
    return {
      control: (provided) => ({
        ...provided,
        '&:hover': { borderColor: 'transparent' },
        border: 'transparent',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        ...(isCompact && {
          minHeight: '30px',
        }),
      }),
      menu: (styles) => {
        return {
          ...styles,
          zIndex: 10,
        };
      },
      multiValue: (styles) => {
        return {
          ...styles,
          backgroundColor: '#000',
          color: '#fff',
        };
      },
      option: (styles, { data, isSelected, isFocused, isDisabled }) => {
        let bgColor = isFocused ? '#f5f5f5' : '#fff';
        bgColor = isSelected && !showOptionsCheckboxes ? '#EBEBEB' : bgColor;
        return {
          ...styles,
          '&:hover': isDisabled ? { cursor: 'not-allowed' } : { backgroundColor: '#f5f5f5' },
          '&:focus': { backgroundColor: '#f5f5f5' },
          backgroundColor: bgColor,
          padding: '8px 12px',
          lineHeight: '24px',
          color: isDisabled ? '#707070' : '#000',
          paddingLeft: data.indented ? `${data.indented}rem` : '1rem',
        };
      },
      multiValueLabel: (styles) => ({
        ...styles,
        color: '#fff',
        backgroundColor: '#000',
      }),
      multiValueRemove: (styles, state) => ({
        ...styles,
        backgroundColor: state.isFocused && '#fff',
        border: state.isFocused && '1px solid #000',
        color: state.isFocused && '#000',
        '&:hover': { color: '#000', backgroundColor: '#fff', borderColor: '#000', borderWidth: '1px' },
      }),
      singleValue: (styles) => {
        return {
          ...styles,
          fontSize: isCompact ? 14 : 'inherit',
        };
      },
      placeholder: (styles) => {
        return {
          ...styles,
          color: '#707070',
          fontSize: isCompact ? 14 : 'inherit',
        };
      },
    };
  };

  static getExamProgressColor = (score) => {
    if (score >= 85) {
      return 'text-cyb-green-600';
    }
    if (score >= 65) {
      return 'text-cyb-yellow-500';
    }
    if (score >= 1) {
      return 'text-cyb-red-700';
    }
    return 'text-gray-400';
  };
}
