import React from 'react';
import ExampleSection from './ExampleSection';
import Header from '../../../components/Header/Header';
import WidgetContainer from '../../../components/Container/WidgetContainer';
import Code from '../../../components/Code/Code';

function WidgetContainerExampleSection() {
  return (
    <ExampleSection title="WidgetContainer" description="Wrapper to container widget to add consistent styling and padding to widgets">
      <Header as="h2">Default</Header>
      <WidgetContainer primaryWidget>
        <p>Primary Widget</p>
      </WidgetContainer>
      <Code>
        {`      
    <WidgetContainer primaryWidget>
      <p>Primary Widget</p>
    </WidgetContainer>
      `}
      </Code>
      <Header as="h2">Sizes</Header>
      <div className="flex">
        <WidgetContainer primaryWidget size="3/5">
          <p>Primary Widget 3/5</p>
        </WidgetContainer>
        <WidgetContainer secondaryWidget size="2/5">
          <p>Secondary Widget 2/5</p>
        </WidgetContainer>
      </div>
      <Code>
        {`    
  <div className="flex">  
    <WidgetContainer primaryWidget size="3/5">
      <p>Primary Widget 3/5</p>
    </WidgetContainer>
    <WidgetContainer secondaryWidget size="2/5">
      <p>Secondary Widget 2/5</p>
    </WidgetContainer>
  </div>
      `}
      </Code>
      <Header as="h2">Omit</Header>
      <div className="flex">
        <WidgetContainer primaryWidget omitBackground omitPadding size="1/5">
          <p>Primary Widget 1/5 omitBackground omitPadding</p>
        </WidgetContainer>
        <WidgetContainer secondaryWidget omitBorder size="4/5">
          <p>Secondary Widget 4/5 omitBorder</p>
        </WidgetContainer>
      </div>
      <Code>
        {`    
    <div className="flex">
      <WidgetContainer primaryWidget omitBackground omitPadding size="1/5">
        <p>Primary Widget 1/5 omitBackground omitPadding</p>
      </WidgetContainer>
      <WidgetContainer secondaryWidget omitBorder size="4/5">
        <p>Secondary Widget 4/5 omitBorder</p>
      </WidgetContainer>
    </div>
      `}
      </Code>
    </ExampleSection>
  );
}

export default WidgetContainerExampleSection;
