import React from 'react';
import Section from './Content/Section';
import InterstitialEmbed from '../Embed/InterstitialEmbed';

function Unauthorized({ contentType }) {
  return (
    <Section>
      <InterstitialEmbed dismiss={() => {}} hideNext contentType={contentType} />
    </Section>
  );
}

export default Unauthorized;
