import React from 'react';
import ChevronLeft from '../../Icons/ChevronLeft';
import AddLink from '../AddLink/AddLink';
import Image from '../Image/Image';
import ShareButton from '../Share/ShareButton';
import Title from '../Title/Title';
import FeedbackButton from '../Feedback/FeedbackButton';

function ContentHeaderCTAs({ buttons }) {
  if (!buttons) return null;
  return <div className="flex">{buttons}</div>;
}

function ContentHeaderBackLink({ backLink, backLinkText }) {
  if (!backLink) return null;
  return (
    <AddLink to={backLink} className="text-sm hover:text-black underline hover:underline">
      <div className="flex items-center">
        <ChevronLeft classes="w-4 h-4 mr-1 inline-block" />
        <p>{backLinkText}</p>
      </div>
    </AddLink>
  );
}
function ContentHeaderRatingAndShare({ showReviews, showShareButton, commonStore, title, type, permalink = '', contentDescriptionId, averageRating, ratingCount, hasProgress }) {
  if (!showReviews && !showShareButton) return null;
  const tooltipText = hasProgress ? null : "Let us know what you think once you've started learning.";
  return (
    <div className="flex">
      {showReviews ? (
        <div className="mr-4">
          <FeedbackButton
            contentDescriptionId={contentDescriptionId}
            type={type}
            title={title}
            average={averageRating}
            count={ratingCount}
            hasProgress={hasProgress}
            textColor="text-white"
            tooltipText={tooltipText}
          />
        </div>
      ) : null}
      {showShareButton ? (
        <div>
          <ShareButton
            onClick={() =>
              commonStore.triggerShareComponent({
                permalink,
                type,
                title,
              })
            }
            textColor="text-white"
          />
        </div>
      ) : null}
    </div>
  );
}

export default function ContentHeader({
  contentType,
  contentTitle,
  contentImageUrl,
  backLink = '',
  backLinkText = 'Back',
  buttons = null,
  showReviews = false,
  showShareButton = false,
  commonStore,
  permalink,
  hasProgress,
  contentDescriptionId,
  averageRating,
  ratingCount,
  contentDescription,
}) {
  return (
    <div className="hidden pt-8 md:pt-12 lg:block">
      <div className="flex justify-between items-center">
        <ContentHeaderBackLink backLink={backLink} backLinkText={backLinkText} />
        <ContentHeaderRatingAndShare
          showReviews={showReviews}
          showShareButton={showShareButton}
          commonStore={commonStore}
          title={contentTitle}
          type={contentType}
          permalink={permalink}
          hasProgress={hasProgress}
          contentDescriptionId={contentDescriptionId}
          averageRating={averageRating}
          ratingCount={ratingCount}
        />
      </div>
      <div className="flex pt-8">
        <div className="flex">
          <Image src={contentImageUrl} className="mr-6 rounded-sm" size="w-[320px] h-[214px]" marginBottom="mb-0" omitMarginAuto />
          <div className="flex flex-col justify-between min-h-min">
            <div>
              <p className="mb-2 text-2xs font-semibold text-gray-600 uppercase">{contentType}</p>
              <Title title={contentTitle} omitPadding wrapperClasses="pb-5 max-w-lg" />
              {contentDescription && <p className="pb-4">{contentDescription}</p>}
            </div>
            <ContentHeaderCTAs buttons={buttons} />
          </div>
        </div>
      </div>
    </div>
  );
}
