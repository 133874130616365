import React from 'react';
import { observer } from 'mobx-react';
import Header from '../Header/Header';
import CurriculumItem from './CurriculumItem';
import ArchivedLabel from '../ContentUpdates/ArchivedLabel';

export default observer(function CurriculumItems({ collection, handleCurriculumItemLaunch, title, collectionArchived }) {
  // Avoid typerror in bugsnag by nullchecking collection, then checking that it has a map function
  const itemsOutput = collection?.map
    ? collection.map((curriculumItem) => {
        return (
          <CurriculumItem
            key={curriculumItem.id || curriculumItem.content_description_id}
            curriculumItem={curriculumItem}
            handleCurriculumItemLaunch={handleCurriculumItemLaunch}
          />
        );
      })
    : [];

  return (
    <div>
      <Header as="h2">
        {title}
        {collectionArchived && <ArchivedLabel />}
      </Header>
      {itemsOutput}
    </div>
  );
});
