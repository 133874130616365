import React from 'react';
import { twMerge } from 'tailwind-merge';

function getColors(color, basic) {
  const colors = {
    labelBgColor: `bg-gray-600`,
    labelTextColor: `text-white`,
  };
  if (basic) {
    colors.labelBgColor = `bg-white`;
    switch (color) {
      case 'gold':
        colors.labelTextColor = `text-gold`;
        colors.labelBorderColor = `border-gold`;
        break;
      case 'pink':
        colors.labelTextColor = `text-cyb-pink-500`;
        colors.labelBorderColor = `border-cyb-pink-500`;
        break;
      case 'indigo':
        colors.labelBorderColor = `border-indigo-600`;
        colors.labelTextColor = `text-indigo-600`;
        break;
      case 'white':
      case 'black':
        colors.labelBorderColor = `border-gray-600`;
        colors.labelTextColor = `text-black`;
        break;
      case 'yellow':
        colors.labelBorderColor = `border-yellow-400`;
        colors.labelTextColor = `text-yellow-400`;
        break;
      case 'green':
        colors.labelTextColor = `text-cyb-green-700`;
        colors.labelBorderColor = `border-cyb-green-700`;
        break;
      case 'red':
        colors.labelTextColor = `text-red-600`;
        colors.labelBorderColor = `border-red-600`;
        break;
      case 'light-gray':
        colors.labelBorderColor = `border-gray-400`;
        colors.labelTextColor = `text-gray-400`;
        break;
      case 'orange':
        colors.labelBorderColor = `border-orange-500`;
        colors.labelTextColor = `text-orange-500`;
        break;
      case 'teal':
        colors.labelTextColor = `text-teal-600`;
        colors.labelBorderColor = `border-teal-600`;
        break;
      case 'blue':
        colors.labelBorderColor = `border-cyb-blue-500`;
        colors.labelTextColor = `text-cyb-blue-500`;
        break;
      case 'light-blue':
        colors.labelBorderColor = `border-blue-200`;
        colors.labelTextColor = `text-blue-200`;
        break;
      case 'purple':
        colors.labelTextColor = `text-purple-600`;
        colors.labelBorderColor = `border-purple-600`;
        break;

      case 'gray':
      default:
        colors.labelTextColor = `text-black`;
        colors.labelBorderColor = `border-gray-400`;
        break;
    }
  } else {
    switch (color) {
      case 'white':
        colors.labelBgColor = `bg-white`;
        colors.labelTextColor = `text-black`;
        colors.labelBorderColor = `border-gray-100`;
        break;
      case 'teal':
        colors.labelBgColor = `bg-teal-600`;
        colors.labelBorderColor = `border-teal-600`;
        break;

      case 'pink':
        colors.labelBgColor = `bg-cyb-pink-500`;
        colors.labelBorderColor = `border-cyb-pink-500`;
        break;
      case 'black':
        colors.labelBgColor = `bg-black`;
        colors.labelBorderColor = `border-gray-400`;
        break;
      case 'gold':
        colors.labelBorderColor = `border-gold`;
        colors.labelBgColor = `bg-gold`;
        break;

      case 'green':
        colors.labelBorderColor = `border-cyb-green-700`;
        colors.labelBgColor = `bg-cyb-green-700`;
        break;
      case 'red':
        colors.labelBgColor = `bg-red-600`;
        colors.labelBorderColor = `border-red-600`;
        break;
      case 'yellow':
        colors.labelBorderColor = `border-yellow-400`;
        colors.labelBgColor = `bg-yellow-400`;
        break;
      case 'blue':
        colors.labelBorderColor = `border-cyb-blue-500`;
        colors.labelBgColor = `bg-cyb-blue-500`;
        break;
      case 'light-blue':
        colors.labelBorderColor = `border-blue-200`;
        colors.labelBgColor = `bg-blue-200`;
        break;
      case 'orange':
        colors.labelBgColor = `bg-orange-500`;
        colors.labelBorderColor = `border-orange-500`;
        break;

      case 'indigo':
        colors.labelBorderColor = `border-indigo-600`;
        colors.labelBgColor = `bg-indigo-600`;
        break;
      case 'light-gray':
        colors.labelTextColor = `text-black`;
        colors.labelBgColor = `bg-gray-200`;
        colors.labelBorderColor = `border-gray-200`;
        break;
      case 'purple':
        colors.labelBgColor = `bg-purple-600`;
        colors.labelBorderColor = `border-purple-600`;
        break;
      case 'gray':
      default:
        colors.labelTextColor = `text-white`;
        colors.labelBgColor = `bg-gray-600`;
        colors.labelBorderColor = `border-gray-600`;
        break;
    }
  }
  return colors;
}

function Label({ color, basic, className = '', children, id = '', wrapperClasses = 'inline-block', labelClasses = '' }) {
  const { labelBgColor = '', labelTextColor = '', labelBorderColor = '' } = getColors(color, basic);
  const baseClasses = `inline-block border text-2xs rounded overflow-hidden mr-1 mb-0 
  ${labelBgColor} ${labelTextColor} print:border-gray-400 print:text-gray-600`;
  const defaultLabelClasses = 'inline-block py-1 px-3 font-normal leading-4';
  return (
    <div className={wrapperClasses}>
      <div className="flex">
        <div id={id} className={twMerge(labelBorderColor, baseClasses, className)}>
          <span className={twMerge(defaultLabelClasses, labelClasses)}>{children}</span>
        </div>
      </div>
    </div>
  );
}

export default Label;
