import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import HamburgerMenu from '../Icons/HamburgerMenu';
import Search from '../../../Icons/SearchIcon';
import SearchCatalog from '../SearchCatalog';
import PrimarySidebar from './PrimarySidebar';
import AddLink from '../../AddLink/AddLink';

function MobileNavComponent({ userStore, commonStore }) {
  const [mobileSidebar, toggleMobileSidebar] = useState(false);
  const [searchBar, toggleSearchBar] = useState(false);

  return (
    <div className="block w-full bg-white lg:hidden">
      <div className="border-b-xs border-gray-400" style={{ height: '4.5rem' }}>
        <div className={`${searchBar ? 'hidden' : 'block'} flex items-center px-4 py-5`}>
          <div>
            <HamburgerMenu classes="h-8 w-8 cursor-pointer stroke-current text-gray-600 hover:text-black" onClick={() => toggleMobileSidebar(!mobileSidebar)} />
          </div>
          <div className="px-2 mx-auto">
            <AddLink to="/" aria-label="Home" className="block cursor-pointer">
              <img
                src="https://images.ctfassets.net/kvf8rpi09wgk/6HtXDpiYT7GMU8yp0DS4Dy/5b42c213353afb410ac8e52e7db7c77f/cybrary_logo_black.svg"
                alt="Cybrary"
                width="120"
                height="31"
              />
            </AddLink>
          </div>
          <button onClick={() => toggleSearchBar(!searchBar)} aria-label="Toggle search input field">
            <Search classes="w-8 h-8 cursor-pointer stroke-current text-gray-600 hover:text-black" />
          </button>
        </div>

        <div className={`${searchBar ? 'block' : 'hidden'} lg:hidden flex items-center px-4 py-4 justify-center`}>
          <div id="mobile-sitewide-search" className="w-4/5">
            <SearchCatalog toggleSearchBar={toggleSearchBar} />
          </div>
          <button onClick={() => toggleSearchBar(false)} className="py-2 px-3 ml-4 text-lg text-gray-500 focus:outline-none">
            Cancel
          </button>
        </div>
      </div>
      <PrimarySidebar userStore={userStore} commonStore={commonStore} mobileSidebar={mobileSidebar} toggleMobileSidebar={toggleMobileSidebar} />
    </div>
  );
}

const MobileNav = inject('userStore', 'commonStore')(observer(MobileNavComponent));

export default MobileNav;
