import React from 'react';

export default function ConnectionError({ msg = '' }) {
  const reload = () => {
    window.location.reload();
  };
  return (
    <div className="pt-48 w-full h-full">
      <p className="my-8 text-center text-gray-100">An error was detected with your lab connection: {msg || 'Network connection lost.'}</p>
      {/* <p className="text-center my-2 text-gray-100">Most errors can be resolved by reloading your lab environment.</p> */}
      <p className="flex justify-center items-center my-8">
        <button className="py-2 px-4 text-right text-white bg-pink-700 rounded" onClick={reload} type="button">
          Reconnect
        </button>
      </p>
    </div>
  );
}
