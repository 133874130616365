import React from 'react';
import ExampleSection from './ExampleSection';
import Header from '../../../components/Header/Header';
import Icon from '../../../components/Icon/Icon';
import Code from '../../../components/Code/Code';
import AddLink from '../../../components/AddLink/AddLink';

function IconsExampleSection() {
  return (
    <ExampleSection
      title="Icons"
      description={
        <AddLink to="/icons" className="flex items-center">
          See /icons for a full example <Icon name="chevron-right" className="w-4 h-4" />
        </AddLink>
      }
    >
      <Header as="h2">Default</Header>
      <Icon name="camera" />
      <Code>
        {`
    <Icon name="camera" />
      `}
      </Code>
      <Header as="h2">Sizes / Colors</Header>
      <div className="flex gap-2">
        <Icon name="camera" className="w-12 h-12" />
        <Icon name="camera" className="w-12 h-12 text-cyb-pink-500" />
        <Icon name="camera" className="w-12 h-12 text-cyb-green-500" />
        <Icon name="camera" className="w-12 h-12 text-red-600" />
      </div>
      <Code>
        {`
        <Icon name="camera" className="w-12 h-12" />
        <Icon name="camera" className="w-12 h-12 text-cyb-pink-500" />
        <Icon name="camera" className="w-12 h-12 text-cyb-green-500" />
        <Icon name="camera" className="w-12 h-12 text-red-600" />
      `}
      </Code>
      <Header as="h2">Animations</Header>
      <div className="flex gap-2">
        <Icon name="camera" className="w-12 h-12 animate-spin" />
        <span className="flex">
          <Icon name="camera" className="inline-flex absolute w-12 h-12 animate-ping" />
          <Icon name="camera" className="w-12 h-12" />
        </span>
        <Icon name="camera" className="w-12 h-12 animate-pulse" />
        <Icon name="camera" className="w-12 h-12 animate-bounce" />
      </div>
      <Code>
        {`
    <div className="flex gap-2">
      <Icon name="camera" className="w-12 h-12 animate-spin" />
      <span className="flex">
        <Icon name="camera" className="inline-flex absolute w-12 h-12 animate-ping" />
        <Icon name="camera" className="w-12 h-12" />
      </span>
      <Icon name="camera" className="w-12 h-12 animate-pulse" />
      <Icon name="camera" className="w-12 h-12 animate-bounce" />
    </div>
      `}
      </Code>
    </ExampleSection>
  );
}

export default IconsExampleSection;
