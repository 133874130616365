import React, { useState } from 'react';

const RatingStar = ({ id, onChange, defaultRating, formId, size = 'base' }) => {
  const [hover, setOnHover] = useState(null);
  const [rating, setRating] = useState(defaultRating || '');
  const sizeClass = {
    sm: 'text-lg',
    base: 'text-2xl',
    lg: 'text-4xl',
    xl: 'text-5xl',
  };
  const handleChange = (changedValue) => {
    if (changedValue !== rating) {
      setRating(changedValue);
      onChange(changedValue);
    }
  };

  const displayRatingStar = () => {
    const ratingsArray = [];
    for (let i = 1; i <= 5; i++) {
      const starKey = formId ? `${formId}_${id}_${i}_star` : `${id}_${i}_star`;
      ratingsArray.push(
        <label
          key={starKey}
          htmlFor={starKey}
          className={`cursor-pointer ${sizeClass[size]} ${hover >= i || rating >= i ? 'text-yellow-300' : 'text-gray-400'}`}
          aria-label={`${i} star`}
          onMouseEnter={() => setOnHover(i)}
          onMouseLeave={() => setOnHover(null)}
          onClick={() => handleChange(i)}
        >
          <input
            className="sr-only"
            type="radio"
            checked={rating >= i}
            id={starKey}
            name={id}
            value={i}
            onChange={() => handleChange(i)}
            onFocus={() => setOnHover(i)}
            onBlur={() => setOnHover(null)}
          />
          <span>&#9733;</span>
        </label>
      );
    }
    return (
      <>
        {ratingsArray}
        {/* @important The input field below is for tracking purposes with snowplow and form submissions.  */}
        <input className="sr-only" type="number" id={id} name={id} value={rating} readOnly />
      </>
    );
  };
  return displayRatingStar();
};

export default RatingStar;
