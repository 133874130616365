import React from 'react';

export default function List({ classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';
  return (
    <svg className={className} viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...accessibilityProps}>
      <path
        d="M17.6673 1.33268H5.50065C5.40898 1.33268 5.33398 1.40768 5.33398 1.49935V2.66602C5.33398 2.75768 5.40898 2.83268 5.50065 
        2.83268H17.6673C17.759 2.83268 17.834 2.75768 17.834 2.66602V1.49935C17.834 1.40768 17.759 1.33268 17.6673 1.33268ZM17.6673 
        7.24935H5.50065C5.40898 7.24935 5.33398 7.32435 5.33398 7.41602V8.58268C5.33398 8.67435 5.40898 8.74935 5.50065 8.74935H17.6673C17.759 
        8.74935 17.834 8.67435 17.834 8.58268V7.41602C17.834 7.32435 17.759 7.24935 17.6673 7.24935ZM17.6673 13.166H5.50065C5.40898 13.166 5.33398 
        13.241 5.33398 13.3327V14.4993C5.33398 14.591 5.40898 14.666 5.50065 14.666H17.6673C17.759 14.666 17.834 14.591 17.834 14.4993V13.3327C17.834 
        13.241 17.759 13.166 17.6673 13.166ZM0.833984 2.08268C0.833984 2.23589 0.864161 2.3876 0.922792 2.52915C0.981422 2.67069 1.06736 2.79931 
        1.17569 2.90764C1.28403 3.01598 1.41264 3.10191 1.55419 3.16054C1.69573 3.21917 1.84744 3.24935 2.00065 3.24935C2.15386 3.24935 2.30557 3.21917 
        2.44711 3.16054C2.58866 3.10191 2.71727 3.01598 2.82561 2.90764C2.93394 2.79931 3.01988 2.67069 3.07851 2.52915C3.13714 2.3876 3.16732 2.23589 
        3.16732 2.08268C3.16732 1.92947 3.13714 1.77776 3.07851 1.63622C3.01988 1.49467 2.93394 1.36606 2.82561 1.25772C2.71727 1.14939 2.58866 1.06345 
        2.44711 1.00482C2.30557 0.946192 2.15386 0.916016 2.00065 0.916016C1.84744 0.916016 1.69573 0.946192 1.55419 1.00482C1.41264 1.06345 1.28403 1.14939 
        1.17569 1.25772C1.06736 1.36606 0.981422 1.49467 0.922792 1.63622C0.864161 1.77776 0.833984 1.92947 0.833984 2.08268ZM0.833984 7.99935C0.833984 
        8.15256 0.864161 8.30427 0.922792 8.44581C0.981422 8.58736 1.06736 8.71597 1.17569 8.82431C1.28403 8.93264 1.41264 9.01858 1.55419 9.07721C1.69573 
        9.13584 1.84744 9.16601 2.00065 9.16601C2.15386 9.16601 2.30557 9.13584 2.44711 9.07721C2.58866 9.01858 2.71727 8.93264 2.82561 8.82431C2.93394 
        8.71597 3.01988 8.58736 3.07851 8.44581C3.13714 8.30427 3.16732 8.15256 3.16732 7.99935C3.16732 7.84614 3.13714 7.69443 3.07851 7.55288C3.01988 
        7.41134 2.93394 7.28273 2.82561 7.17439C2.71727 7.06606 2.58866 6.98012 2.44711 6.92149C2.30557 6.86286 2.15386 6.83268 2.00065 6.83268C1.84744 
        6.83268 1.69573 6.86286 1.55419 6.92149C1.41264 6.98012 1.28403 7.06606 1.17569 7.17439C1.06736 7.28273 0.981422 7.41134 0.922792 7.55288C0.864161 
        7.69443 0.833984 7.84614 0.833984 7.99935ZM0.833984 13.916C0.833984 14.0692 0.864161 14.2209 0.922792 14.3625C0.981422 14.504 1.06736 14.6326 
        1.17569 14.741C1.28403 14.8493 1.41264 14.9352 1.55419 14.9939C1.69573 15.0525 1.84744 15.0827 2.00065 15.0827C2.15386 15.0827 2.30557 15.0525 
        2.44711 14.9939C2.58866 14.9352 2.71727 14.8493 2.82561 14.741C2.93394 14.6326 3.01988 14.504 3.07851 14.3625C3.13714 14.2209 3.16732 14.0692 3.16732 
        13.916C3.16732 13.7628 3.13714 13.6111 3.07851 13.4696C3.01988 13.328 2.93394 13.1994 2.82561 13.0911C2.71727 12.9827 2.58866 12.8968 2.44711 
        12.8382C2.30557 12.7795 2.15386 12.7493 2.00065 12.7493C1.84744 12.7493 1.69573 12.7795 1.55419 12.8382C1.41264 12.8968 1.28403 12.9827 1.17569 
        13.0911C1.06736 13.1994 0.981422 13.328 0.922792 13.4696C0.864161 13.6111 0.833984 13.7628 0.833984 13.916Z"
        fill="currentColor"
      />
    </svg>
  );
}
