/* eslint-disable no-unused-vars */
import React, { useMemo } from 'react';
import { useCybMeasure } from '../../../providers/CybMeasureProvider';
import WidgetContainer from '../../Container/WidgetContainer';
import RoleDropdown from '../../Dashboard/CybraryAssessmentsWidget/RoleDropdown';
import ResultsGraphWidget from './ResultsGraphWidget';
import DetailTableWidget from './DetailTableWidget';
import GoalsTableWidget from './GoalsTableWidget';
import LoadingSkeleton from '../../Loading/LoadingText';
import If from '../../If/If';

function BaselineOverviewWidget({ orgId, roles, selectedContentDescription, onChangeContentDescription }) {
  const { isTeamAdmin, topics, isLoadingTopics, contextMode } = useCybMeasure();

  const mappedTeamTopics = useMemo(
    () =>
      topics[orgId]?.map((topic) => ({
        'content-id': topic.id,
        'content-name': topic.title,
      })) || [],
    [topics, orgId]
  );

  const optionData = useMemo(() => {
    if (contextMode === 'topic') {
      return mappedTeamTopics;
    }
    return roles;
  }, [mappedTeamTopics, roles, contextMode]);

  return (
    <WidgetContainer omitPadding>
      <div className="flex absolute top-0 right-0 z-20 flex-row items-center mt-4 mr-4">
        <span className="mr-2 font-bold capitalize">{contextMode}:</span>
        <div className="relative">
          <RoleDropdown
            optionData={optionData}
            controlledValue={selectedContentDescription}
            onChange={onChangeContentDescription}
            wrapperClasses={isLoadingTopics ? 'opacity-0' : 'opacity-100'}
            isCompact
          />
          <If condition={isLoadingTopics}>
            <LoadingSkeleton wrapperClassName="absolute inset-0 p-0 z-50" className="w-full h-full rounded" />
          </If>
        </div>
      </div>
      <ResultsGraphWidget orgId={orgId} selectedContentDescription={selectedContentDescription} />
      <div className="mx-auto mt-7 mb-5 w-3/4 border-b-1 border-b-gray-300" />
      <DetailTableWidget orgId={orgId} selectedContentDescription={selectedContentDescription} />
      <If condition={isTeamAdmin}>
        <>
          <div className="mx-auto mt-7 mb-5 w-3/4 border-b-1 border-b-gray-300" />
          <GoalsTableWidget selectedContentDescription={selectedContentDescription} />
        </>
      </If>
    </WidgetContainer>
  );
}

export default BaselineOverviewWidget;
