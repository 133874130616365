export function getRoleOptions(isOrgOwner, groupScope) {
  const roleOptions = [
    {
      key: 'team-admin',
      text: `${groupScope ? 'Group ' : ''}Admin`,
      value: 'team-admin',
    },
    {
      key: 'team-member',
      text: `${groupScope ? 'Group ' : ''}Member`,
      value: 'team-member',
    },
    {
      key: 'team-reporting-admin',
      text: `${groupScope ? 'Group ' : ''}Reporting Admin`,
      value: 'team-reporting-admin',
    },
  ];

  if (isOrgOwner) {
    roleOptions.splice(0, 0, {
      key: 'org-owner',
      text: 'Owner',
      value: 'org-owner',
    });
  }

  return roleOptions;
}

export default { getRoleOptions };
