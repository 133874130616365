import React from 'react';
import moment from 'moment';
import AddLink from '../../AddLink/AddLink';
import Icon from '../../Icon/Icon';

/**
 * Single row for Top Topics Widget
 * @param {object} topic - topic object from discourse
 * @param {string} className - Tailwind classes to apply to the container
 * @returns <TopForumsTopicRow />
 */
function TopForumsTopicRow({ topic, className }) {
  const { title, created_at } = topic || {};
  // format data using moment() to "X days ago"
  const formattedCreatedAtDate = moment(created_at).fromNow();
  const link = `${process.env.REACT_APP_FORUMS_URL}/t/${topic?.slug}/${topic?.id}`;
  return (
    <div className={className}>
      <AddLink to={link} target="_blank">
        <div className="group flex flex-col p-2">
          <div className="mr-2 mb-2 text-sm font-semibold group-hover:underline">{title || 'Unknown topic title'}</div>
          <div className="flex flex-row items-center mb-2">
            <div className="p-1 pl-0 mr-2 text-xs rounded-md border-1 border-gray-200">Created {formattedCreatedAtDate}</div>
            <Icon name="external-link" className="w-4 h-4 text-gray-600" />
          </div>
        </div>
      </AddLink>
    </div>
  );
}

export default TopForumsTopicRow;
