import React from 'react';
import AddLink from '../AddLink/AddLink';
/**
 * Footer row for the continue learning block inprogress and bookmarked content tabs
 * @param {string} title title shown above the button
 * @param {string} cta Button text
 * @param {string} to URL to link to
 * @returns <ContinueLearningFooterRow />
 */
export default function ContinueLearningFooterRow({ title = 'Want to view more content?', cta = 'Click here', to = '/browse/refined' }) {
  return (
    <div className="flex flex-col justify-center items-center py-6 mb-2 w-full text-center">
      <p className="mb-4 text-sm text-gray-600">{title}</p>
      <AddLink to={to} className="py-2 px-4 text-sm text-white bg-cyb-pink-500 rounded">
        {cta}
      </AddLink>
    </div>
  );
}
