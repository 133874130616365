import React from 'react';

export default function UserGroup({ classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';
  return (
    <svg viewBox="-91 -21 682 682.66669" className={className} fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...accessibilityProps}>
      <path
        d="m369.722656 314.1875c-34.074218 27.535156-77.410156 44.054688-124.53125 44.054688s-90.457031-16.519532-124.539062-44.058594c-74.648438 43.175781-124.984375 
        123.898437-124.984375 216.160156v90.300781c0 10.6875 8.664062 19.355469 19.355469 19.355469h460.332031c10.6875 0 19.347656-8.667969 
        19.347656-19.355469v-90.300781c0-92.257812-50.332031-172.980469-124.980469-216.15625zm0 0"
      />
      <path
        d="m404.957031 159.765625c0-88.09375-71.667969-159.765625-159.765625-159.765625-88.09375 0-159.765625 71.671875-159.765625 159.765625s71.671875 
        159.765625 159.765625 159.765625c88.097656 0 159.765625-71.671875 159.765625-159.765625zm0 0"
      />
    </svg>
  );
}
