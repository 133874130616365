import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import SearchInput from '../Search/SearchInput';
import { trackSnowplowEvent } from '../../utils/snowplowUtil';

/**
 * Search input field for the Explore page (Browse Refined)
 */
const SearchInputField = inject('searchStore')(
  observer(({ searchStore, setQueryParams }) => {
    const [isFocused, setIsFocused] = useState(false);

    const applySearch = () => {
      searchStore.pageChange(1);
      searchStore.setSearchQuery(searchStore.searchFilters.searchVal);
      setQueryParams();
      // Fire Snowplow event for Explore Page Searchs
      trackSnowplowEvent({
        category: 'ExplorePageSearch',
        action: 'search',
        label: searchStore.searchFilters.searchVal, // What the user searched for (e.g. 'Python')
        property: 'Searched using Explore Page search box', // Description of the action
      });
      searchStore.loadSearchItems();
    };

    const handleSearchEnter = (e) => {
      if (e.key === 'Enter') {
        applySearch();
      }
    };

    const setSearchVal = (data) => {
      searchStore.setSearchVal(data.target.value);
    };

    const onSearchFocus = () => {
      setIsFocused(true);
    };

    const onSearchBlur = () => {
      setIsFocused(false);
    };

    return (
      <SearchInput
        className={isFocused ? 'focused' : ''}
        value={searchStore.searchFilters.searchVal}
        placeholder="Filter results"
        onChange={setSearchVal}
        onFocus={onSearchFocus}
        onBlur={onSearchBlur}
        onKeyPress={handleSearchEnter}
        inputClasses="w-full"
        width="w-100"
      />
    );
  })
);

export default SearchInputField;
