import React, { useMemo } from 'react';
import useBetaCookie from '../../hooks/cookies/useBetaCookie';
import useSandboxCookie from '../../hooks/cookies/useSandboxCookie';
import If from '../If/If';

const COOKIE_BUTTON_CLASSES = 'mb-5 py-3 px-4 text-white bg-cyb-pink-500 hover:bg-cyb-pink-600 rounded';

/**
 * Buttons to disable cookie modes and their contexts
 *
 * @returns {JSX.Element} - Buttons
 */
function CookieModeButtons() {
  const { disableAllFeatures, enabledFeatures } = useBetaCookie();
  const { disableAllSandboxes, enabledSandboxes } = useSandboxCookie();

  const showButtonGroup = useMemo(() => enabledFeatures?.length > 0 || enabledSandboxes?.length > 0, [enabledFeatures, enabledSandboxes]);

  return (
    <If condition={showButtonGroup}>
      <div className="flex fixed right-10 bottom-10 z-220 flex-col items-center">
        <If condition={enabledFeatures?.length}>
          <button onClick={() => disableAllFeatures()} className={COOKIE_BUTTON_CLASSES}>
            Exit Beta
          </button>
        </If>
        <If condition={enabledSandboxes?.length}>
          <button onClick={() => disableAllSandboxes()} className={COOKIE_BUTTON_CLASSES}>
            Exit Sandbox
          </button>
        </If>
      </div>
    </If>
  );
}

export default CookieModeButtons;
