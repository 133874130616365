// Some utilities to help with dealing with contentful data
import { createClient } from 'contentful';
import Bugsnag from '@bugsnag/js';

class ContentfulUtil {
  // Will hold the contentful client
  client = null;

  // cache
  cache = {};

  constructor() {
    // Initialize our contentful client
    this.client = createClient({
      space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
      host: process.env.REACT_APP_CONTENTFUL_HOST,
      accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
      environment: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT,
    });
  }

  /**
   * Fetch an item from contentful.  Returns a promise that will resolve to the result(s).
   *
   * @param {string} permalink The permalink of the item we are searching for
   * @param {string} type The type (in contentful) of the item we want to retrieve, defaults to contentCollection
   * @returns {Promise} The results of the query.
   */
  getByPermalink(permalink, providedType) {
    const type = providedType || 'contentCollection';

    const query = {
      limit: 1,
      include: 2,
      content_type: type,
      'fields.permalink': permalink,
    };

    return new Promise((resolve) => {
      if (this.cache[permalink] && this.cache[permalink][type]) {
        resolve(this.cache[permalink][type]);
      } else {
        this.client
          .getEntries(query)
          .then((response) => {
            if (!this.cache[permalink]) {
              this.cache[permalink] = {};
            }
            this.cache[permalink][type] = response;
            resolve(response);
          })
          .catch((err) => {
            Bugsnag.notify(err);
            return resolve({});
          });
      }
    });
  }

  /**
   * Extract marketing content from contentful.
   * @param {object} data - content from contentful
   */
  getMarketingContent(data, providedFieldName) {
    const ret = [];
    const fieldName = providedFieldName || 'marketingContent';
    if (data && data.items && data.items[0]) {
      const item = data.items[0];
      if (item && item.fields && item.fields[fieldName]) {
        const marketingContent = item.fields[fieldName];
        if (marketingContent && marketingContent.length) {
          marketingContent.forEach((section) => {
            const formattedSection = this.formatContentfulSection(section);
            if (formattedSection) {
              ret.push(formattedSection);
            }
          });
        }
      }
    }
    return ret;
  }

  formatContentfulSection(section) {
    if (section.fields) {
      const formattedSection = { ...section.fields };
      if (section.fields.imageAndText) {
        formattedSection.imageAndText = section.fields.imageAndText.map((img) => this.formatContentfulImage(img));
      }
      if (section.fields.imagesGrid) {
        formattedSection.imagesGrid = section.fields.imagesGrid.map((img) => {
          return {
            ...img.fields,
          };
        });
      }
      if (section.fields.heroBackgroundImage) {
        formattedSection.heroBackgroundImage = {
          ...section.fields.heroBackgroundImage.fields,
        };
      }
      return formattedSection;
    }
    return null;
  }

  formatContentfulImage(img) {
    return {
      ...img.fields,
      image: img.fields && img.fields.image && img.fields.image.fields ? img.fields.image.fields : null,
    };
  }

  resolveResponse(query) {
    return new Promise((resolve) => {
      this.client
        .getEntries(query)
        .then((response) => resolve(response))
        .catch((err) => {
          Bugsnag.notify(err);
          return resolve({});
        });
    });
  }

  getSiteBanner() {
    const type = `siteBanner`;

    const query = {
      limit: 10,
      content_type: type,
      'fields.loggedIntoApp': true,
    };

    return this.resolveResponse(query);
  }

  getPromotionBanners() {
    const type = `promotions`;

    const query = {
      content_type: type,
      'fields.isInAppSiteWide': true,
    };

    return this.resolveResponse(query);
  }
}

export default new ContentfulUtil();
