import React, { useState, useEffect } from 'react';
import Bugsnag from '@bugsnag/js';
import Agents from '../../agents/agents';
import useTeamPaths from '../../hooks/useTeamPaths';
import Icon from '../Icon/Icon';
import SelectBoxes from '../SelectBoxes/SelectBoxes';
import Loading from '../Loading/Loading';
import Button from '../Button/Button';
import TeamSelectionButtons from '../TeamSelection/TeamSelectionButtons';
import CurriculumBuilder from '../Curriculum/CurriculumBuilder';
import SearchInput from '../Search/SearchInput';

function PathsFilters({ handleSearch, handleTabClick, activeTab, team = {} }) {
  // If user can not manage all curricula (group admin), do not show tabs so they can only see 'My Paths' - Same functionality for editingpaths on that admin page
  const canManageTeamPaths = team.permissions && team.permissions.canManageCurricula === 'all';
  return (
    <div className="flex justify-between">
      <div className="mb-6 w-3/5">
        <SearchInput placeholder="Type to search..." onChange={(e) => handleSearch(e.target.value)} />
      </div>
      {canManageTeamPaths && (
        <div className="flex justify-end items-center mb-6 w-2/6">
          <button className={`cursor-pointer mr-4 ${activeTab === 'user' ? 'font-bold' : 'text-gray-600'}`} onClick={() => handleTabClick('user')}>
            My Paths
          </button>
          <button className={`cursor-pointer mr-4 ${activeTab === 'team' ? 'font-bold' : 'text-gray-600'}`} onClick={() => handleTabClick('team')}>
            Team Paths
          </button>
        </div>
      )}
    </div>
  );
}

function PathSelectCreateWrapper({ userStore, commonStore, successCallback, disabled, content }) {
  const { paths, fetchPaths, loading } = useTeamPaths();

  const [teams, setTeams] = useState([]);
  const [team, setTeam] = useState(null);
  const [pathsData, setPathsData] = useState([]);
  const [query, setQuery] = useState('');
  const [activeTab, setActiveTab] = useState('user');
  const [showPathBuilder, setShowPathBuilder] = useState(false);
  const [selectedPath, setSelectedPath] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const { teamsWithPathsAdminAccess } = userStore;
    const adminTeamsIds = Object.keys(teamsWithPathsAdminAccess);
    // Convert the object of teams to an array of teams
    const teamsArr = adminTeamsIds.map((id) => teamsWithPathsAdminAccess[id]);
    setTeams(teamsArr);
    // If there's only one admin team, just use that team ID
    if (adminTeamsIds.length === 1) {
      setTeam(teamsWithPathsAdminAccess[adminTeamsIds[0]]);
    }
  }, []);

  useEffect(() => {
    if (team) {
      fetchPaths(team);
    }
  }, [team]);

  useEffect(() => {
    let updatedPaths = [...paths];
    if (updatedPaths.length) {
      const filteredPaths = updatedPaths.filter((item) => {
        const { name, author_id } = item || {};
        // First find all paths that match the current search query, if a query exists
        const queryMatch = !query || (name && name.toLowerCase().includes(query.toLowerCase()));
        // Find the paths that match the selected tab --
        // If tab is My Paths, find the paths that have an author_id matching the user's ID
        // If tab is Team Paths, find paths that do not have the same author_id as user's ID
        const tabMatch =
          (activeTab === 'user' && userStore.user && author_id === userStore.user.id) || (activeTab === 'team' && (!userStore.user || author_id !== userStore.user.id));
        return queryMatch && tabMatch;
      });
      updatedPaths = filteredPaths;
    }
    setPathsData(updatedPaths);
  }, [paths, query, activeTab]);

  const onSelect = (selection) => {
    if (selection === selectedPath) {
      setSelectedPath(null);
    } else {
      setSelectedPath(selection);
    }
  };

  const transformOptions = (data = []) => {
    return data.map((path) => {
      return {
        text: path.name,
        value: path.id,
      };
    });
  };

  const addContentToPath = async () => {
    // Find the full path in the array of paths
    const pathSelection = pathsData.filter((path) => path.id === selectedPath);
    if (pathSelection && pathSelection.length) {
      const pathSelectionId = pathSelection[0].id;
      setSubmitting(true);
      // Fetch the full path details for the path selected
      try {
        const teamId = team.curriculaTeamGroupId || team.id;
        const path = await Agents.enterprise.getCurriculum(teamId, pathSelectionId);
        // Format the path contents to only contain IDs
        const pathContents = path.contents.map((item) => item.id);
        // Check if this content is already in the path. If so, just show an error
        if (pathContents.includes(content.id)) {
          commonStore.triggerToast('error', { content: 'This content already exists in the selected path.' });
        } else {
          // Add in the new content item
          pathContents.push(content.id);
          path.contents = pathContents;
          await Agents.enterprise.putCurriculum(teamId, pathSelectionId, path);
          commonStore.triggerToast('success', { content: 'Success! The path was successfully saved.' });
          if (successCallback) {
            successCallback();
          }
        }
      } catch (err) {
        Bugsnag.notify(err);
        commonStore.triggerToast('error', { content: 'Something went wrong. Unable to add this content to the selected path.' });
      } finally {
        setSubmitting(false);
      }
    }
  };

  // If no teams length, just show loading (should never see this as user needs to be on a team for paths. So rather than show nothing, just showing loading)
  if (!teams.length) {
    return (
      <div className="py-6 px-4">
        <Loading message="Loading..." />
      </div>
    );
  }

  // If we don't have a team ID yet, show a component for user to select the team
  if (!team && teams.length) {
    return (
      <div className="py-6 px-4">
        <h2 className="text-2xl font-black">Choose a Team</h2>
        <p className="mb-8 font-semibold">Select which team&apos;s paths you would like to add to</p>
        <TeamSelectionButtons teams={teams} handleSelection={(selectedTeam) => setTeam(selectedTeam)} ctaText="Continue" />
      </div>
    );
  }

  // If showPathBuilder, user clicked to add a new path. Show CurriculumBuilder
  if (showPathBuilder) {
    return (
      <div className="py-6 px-4">
        <button type="button" className="flex items-center text-sm" onClick={() => setShowPathBuilder(false)}>
          <Icon name="chevron-left" className="w-4 h-4 text-gray-600" /> Back to existing paths
        </button>
        <CurriculumBuilder scopedTeam={team} defaultContent={[content]} />
      </div>
    );
  }

  // Show path selector
  return (
    <div className="relative px-4 pt-6">
      <p className="text-sm text-gray-600">
        Select a path, or{' '}
        <button type="button" className="text-cyb-pink-500 hover:text-black cursor-pointer" onClick={() => setShowPathBuilder(true)}>
          click here
        </button>{' '}
        to add a new one
      </p>
      <PathsFilters handleSearch={setQuery} handleTabClick={setActiveTab} activeTab={activeTab} team={team} />
      <div className="overflow-auto max-h-[24rem]">
        <SelectBoxes
          containerClasses="overflow-y-auto"
          disabled={disabled}
          returnFullSelections
          selections={selectedPath}
          handleSelect={onSelect}
          options={transformOptions(pathsData)}
          loading={loading}
        />
      </div>
      <div className="pt-4 text-right">
        <Button loading={submitting} onClick={() => addContentToPath()} disabled={!selectedPath}>
          Add to Path
        </Button>
      </div>
    </div>
  );
}

export default PathSelectCreateWrapper;
