import React, { useCallback } from 'react';
import { TEAM_ONBOARDING_STEPS, useTeamOnboarding } from '../../../providers/TeamOnboardingProvider';
import SelectRoleStep from '../OnboardingSteps/Team/SelectRoleStep';
import SelectGoalsStep from '../OnboardingSteps/Team/SelectGoalsStep';
import SelectLearningApproach from '../OnboardingSteps/Team/SelectLearningApproach';
import FinishedStep from '../OnboardingSteps/Team/FinishedStep.js';

function TeamOnboardingRouter() {
  const { step } = useTeamOnboarding();

  const TeamOnboardingStepJsx = useCallback(() => {
    switch (step) {
      case TEAM_ONBOARDING_STEPS.FINISHED:
        return <FinishedStep />;
      case TEAM_ONBOARDING_STEPS.SELECT_GOALS:
        return <SelectGoalsStep />;
      case TEAM_ONBOARDING_STEPS.SELECT_LEARNING_APPROACH:
        return <SelectLearningApproach />;
      case TEAM_ONBOARDING_STEPS.SELECT_ROLE:
      default:
        return <SelectRoleStep />;
    }
  }, [step]);

  return <TeamOnboardingStepJsx />;
}

export default TeamOnboardingRouter;
