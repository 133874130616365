import React from 'react';

export function FormGroup({ children, inline, columnClass }) {
  const formGroupClass = inline ? 'flex' : 'grid grid-cols-1 md:gap-4 md:grid-flow-col md:auto-cols-fr';
  const groupClassName = columnClass ? `flex ${columnClass}` : formGroupClass;
  return <div className={groupClassName}>{children}</div>;
}

function Form(props) {
  const { formId, children, onSubmit, className } = props;

  const submit = (e) => {
    e.preventDefault();
    onSubmit(e);
  };

  return (
    <form id={formId} onSubmit={submit} className={` mt-4 mb-4 ${className || ''}`}>
      {children}
    </form>
  );
}

export default Form;
