import React from 'react';

/**
 * Helper component to conditionally render children
 * @param {Boolean} condition Condition to check
 * @param {Boolean} isAlwaysMounted (Optional) Whether or not to always render the children, and just hide them if the condition is false
 * @returns {JSX} children or null
 */
function If({ condition, children, isAlwaysMounted = false }) {
  // If isAlwaysMounted is true, we will always render the children, and just hide them if the condition is false
  // This keeps the children mounted, which is useful for animations
  if (isAlwaysMounted) {
    return <div className={condition ? '' : 'hidden'}>{children}</div>;
  }
  // If isAlwaysMounted is false, we will only render the children if the condition is true
  if (condition) {
    return children;
  }
  return null;
}

export default If;
