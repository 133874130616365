import React from 'react';
import { observer } from 'mobx-react';
import Header from '../Header/Header';
import Icon from '../Icon/Icon';
import Loading from '../Loading/Loading';
import StyledError from '../Error/StyledError';
import MetricsSettings from './MetricsSettings';
import SkillsBreakdown from './SkillsBreakdown';
import AddLink from '../AddLink/AddLink';
import Segment from '../Segment/Segment';
import Tooltip from '../Tooltip/Tooltip';
import Container from '../Container/Container';

function CompetencyLevelLegend() {
  const { competencyLevels } = MetricsSettings;
  return (
    <div className="legend-container">
      {Object.keys(competencyLevels).map((level) => {
        // Not currently showing No Assessed
        if (level === 'Not Assessed') {
          return null;
        }
        const levelData = competencyLevels[level];
        return (
          <div key={level} className="legend-key-container">
            <div>
              <span className="key-icon" style={{ background: `${levelData.color}` }} />
              <span className="key-title">{level}</span>
            </div>
            <span className="key-title subtitle">{levelData.range}</span>
          </div>
        );
      })}
    </div>
  );
}

export default observer(function CompetencyLevels({ data, loading, error, showSkillBreakdown, reportLink }) {
  if (loading) {
    return (
      <Container>
        <Loading message="Loading..." />
      </Container>
    );
  }
  if (error) {
    return (
      <Container>
        <StyledError error={error} />
      </Container>
    );
  }
  if (!data || !data.userGradesBySkill) {
    return null;
  }
  return (
    <Segment className="px-0 border-none competency-levels">
      <div className="flex justify-between items-center">
        <Header as="h2">Competency Level</Header>
        <div className="flex gap-x-2 items-center text-right">
          {reportLink ? (
            <AddLink className="text-cyb-pink-500 hover:text-black" to={reportLink}>
              <Icon name="line-graph" className="w-5 h-5 text-cyb-pink-500 fill-current" />
            </AddLink>
          ) : null}
          <Tooltip
            content="The average score across all users who completed the assessment for each individual skill that was assessed."
            triggerContent={<Icon name="question-circle" className="w-5 h-5 text-gray-600 hover:text-black" />}
          />
        </div>
      </div>
      <CompetencyLevelLegend />
      <SkillsBreakdown data={data.userGradesBySkill} showSkillBreakdown={!!showSkillBreakdown} />
    </Segment>
  );
});
