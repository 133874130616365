import React, { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import Header from '../Header/Header';
import WidgetContainer from '../Container/WidgetContainer';
import { useTeamOnboarding } from '../../providers/TeamOnboardingProvider';
import OnboardingHeader from './OnboardingHeader';
import If from '../If/If';
import Button from '../Button/Button';

/**
 * Header component for use in onboarding steps
 * @param title the text to display
 */
function TeamOnboardingStepTitle() {
  return (
    <div className="max-w-sm">
      <div className="mb-8 text-6xl font-light tracking-wide leading-tight">
        <span className="text-pink-500">Help us to help</span>
        <span className="ml-3 font-normal text-black">You.</span>
      </div>
      <div>
        We want to supercharge your Cybrary experience! Please answer these three questions so we can learn more about you and your team and further anticipate your success.
      </div>
    </div>
  );
}
function TeamOnboardingStepDescription({ description, className }) {
  return (
    <Header as="h3" className={twMerge('px-8 pb-4 m-auto text-xl md:text-2xl font-black text-center', className)}>
      {description}
    </Header>
  );
}
function TeamOnboardingNavButtons({
  nextStep,
  prevStep,
  isNextStepDisabled,
  isPrevStepDisabled,
  isNextStepShown = true,
  isPrevStepShown = true,
  nextButtonText = 'Next',
  prevButtonText = 'Back',
}) {
  const { setStep } = useTeamOnboarding();
  return (
    <div className="flex justify-between mt-4">
      <If condition={isPrevStepShown}>
        <Button disabled={isPrevStepDisabled || !prevStep} color="gray" onClick={() => setStep(prevStep)}>
          {prevButtonText}
        </Button>
      </If>
      <If condition={isNextStepShown}>
        <Button disabled={isNextStepDisabled || !nextStep} onClick={() => setStep(nextStep)} className="ml-auto">
          {nextButtonText}
        </Button>
      </If>
    </div>
  );
}
/**
 * Container for a single step in the onboarding flow
 * For use in the onboarding modal
 * @param title text shown at top of step
 * @param subtitle text shown below title
 * @param nextStep the next step to navigate to
 * @param prevStep the previous step to navigate to
 * @param isNextStepDisabled whether the next step button should be disabled
 * @param isPrevStepDisabled whether the previous step button should be disabled
 * @param isNextStepShown whether the next step button should be shown
 * @param isPrevStepShown whether the previous step button should be shown
 * @param children the content of the step
 * @param containerClassName additional classes for the container
 * @param contentClassName additional classes for the content
 */
function TeamOnboardingStep({
  title,
  subtitle,
  nextStep,
  prevStep,
  isNextStepDisabled,
  isPrevStepDisabled,
  isNextStepShown,
  isPrevStepShown,
  nextButtonText,
  prevButtonText,
  children,
  containerClassName,
  contentClassName,
}) {
  const containerClasses = useMemo(() => {
    return twMerge('flex flex-col p-6 justify-center items-center h-[80vh] max-w-6xl w-[100vw] mx-auto space-x-12', containerClassName);
  }, [containerClassName]);
  const contentClasses = useMemo(() => {
    return twMerge('p-12 rounded-2xl', contentClassName);
  }, [contentClassName]);
  return (
    <div className={containerClasses}>
      <OnboardingHeader className="ml-4" />
      <div className="flex w-[100vw] max-w-6xl">
        <WidgetContainer primaryWidget size="1/3" omitBackground omitBorder>
          <TeamOnboardingStepTitle />
        </WidgetContainer>

        <WidgetContainer secondaryWidget size="2/3" omitBackground className={contentClasses}>
          {/** title / subtitle */}
          <Header as="h2" className="mb-2">
            {title}
          </Header>
          <Header as="h4">{subtitle}</Header>
          {children}
          <TeamOnboardingNavButtons
            nextStep={nextStep}
            prevStep={prevStep}
            isNextStepDisabled={isNextStepDisabled}
            isPrevStepDisabled={isPrevStepDisabled}
            isNextStepShown={isNextStepShown}
            isPrevStepShown={isPrevStepShown}
            nextButtonText={nextButtonText}
            prevButtonText={prevButtonText}
          />
        </WidgetContainer>
      </div>
    </div>
  );
}
/** Export child components for reuse in custom onboarding displays */
TeamOnboardingStep.Title = TeamOnboardingStepTitle;
TeamOnboardingStep.Description = TeamOnboardingStepDescription;

export default TeamOnboardingStep;
