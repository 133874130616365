import React, { useState } from 'react';
import jwtDecode from 'jwt-decode';
import Agents from '../../agents/agents';
import Button from '../../components/Button/Button';
import Container from '../../components/Container/Container';
import Divider from '../../components/Divider/Divider';
import AddLink from '../../components/AddLink/AddLink';
import { useAuth } from '../../contexts/UseAuth';
import AuthUtil from '../../utils/authUtil';
import '../CybraryAdmin/admin.css';

const ENTER_KEY = 13;

function JwtSegment({ jwt }) {
  if (!jwt) {
    return null;
  }

  try {
    const decodedJwt = jwtDecode(jwt);
    return (
      <div className="mt-4">
        <Divider />
        <h3 className="text-2xl font-black">User Info</h3>
        <div>
          <label htmlFor="user_id">
            ID: <span id="user_id">{decodedJwt.claims.uid}</span>
          </label>
        </div>
        <div>
          <label htmlFor="user_email">
            Email: <span id="user_email">{decodedJwt.claims.email}</span>
          </label>
        </div>
        <div>
          <label htmlFor="user_claims">
            Claims: <span id="user_claims">{decodedJwt.claims.claims.join(', ')}</span>
          </label>
        </div>
      </div>
    );
  } catch (e) {
    return (
      <div className="mt-4">
        <Divider />
        <h3 className="text-2xl font-black">User Info</h3>
        <p>Invalid JWT</p>
      </div>
    );
  }
}

function AdminJwt() {
  const [jwt, setJwt] = useState('');
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('');
  const auth = useAuth();

  const handleChangeSearch = (e) => {
    setSearch(e.target.value);
  };

  const submitSearch = (query) => {
    setStatus('');
    Agents.admin
      .jwt(query)
      .then((response) => {
        setJwt(response.jwt);
      })
      .catch(() => {
        setStatus('Not Found');
      });
  };

  const handleSubmitSearch = (e) => {
    submitSearch(search);
    e.preventDefault();
    return false;
  };

  const handleChangeJwt = (e) => {
    setJwt(e.target.value);
  };

  const handleSubmitJwt = async () => {
    setStatus('');
    try {
      await auth.loginWithCustomToken(jwt);
      window.location.href = '/';
    } catch (e) {
      setStatus('Unable to login');
    }
  };

  const keyPress = (event) => {
    if (event.keyCode === ENTER_KEY) {
      submitSearch(search);
    }
  };

  if (!AuthUtil.isDevEnvironment()) {
    window.location.href = '/';
    return null;
  }

  return (
    <Container size="md" className="mt-16">
      <p className="font-bold text-red-500">{status}</p>
      <div>
        <h3 className="text-2xl font-black">Enter Your User ID or Email</h3>
        <input
          className="block p-2 mb-3 w-full border border-gray-400"
          type="text"
          name="jwt"
          value={search}
          onKeyDown={keyPress}
          onChange={handleChangeSearch}
          style={{ marginBottom: '10px' }}
        />
        <Button onClick={handleSubmitSearch} primary>
          Search
        </Button>
        <JwtSegment jwt={jwt} />
      </div>

      <div className="mt-4">
        <Divider />
        <h3 className="text-2xl font-black">Enter Your JWT</h3>
        <input className="block p-2 mb-3 w-full border border-gray-400" type="text" autoComplete="off" name="jwt" value={jwt} onChange={handleChangeJwt} />
        <Button value="Submit" onClick={handleSubmitJwt}>
          Login
        </Button>
      </div>
      <Divider />
      <div className="flex mt-8 space-x-8 text-cyb-pink-500">
        <AddLink className=" hover:text-black underline" to="/login">
          Login Page &raquo;
        </AddLink>
        <AddLink className="hover:text-black underline" to="/local-register">
          Register Page &raquo;
        </AddLink>
        <AddLink className="hover:text-black underline" to="/upgrade/checkout">
          CIP Checkout &raquo;
        </AddLink>
        <AddLink className="hover:text-black underline" to="/upgrade/teams-checkout">
          Teams Checkout &raquo;
        </AddLink>
        <AddLink className="hover:text-black underline" to="/components">
          Components &raquo;
        </AddLink>
      </div>
    </Container>
  );
}

export { AdminJwt, ENTER_KEY };
