import React, { useEffect, useState } from 'react';
import { observer, inject } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import DynamicForm from '../DynamicForm/DynamicForm';

import AuthUtil from '../../utils/authUtil';
import BugsnagUtil from '../../utils/bugsnagUtil';
import Loading from '../Loading/Loading';
import PasswordRequirements from '../Login/PasswordRequirements';
import Divider from '../Divider/Divider';
import AddLink from '../AddLink/AddLink';
import useQueryParams from '../../hooks/useQueryParams';

function LoginWithDivider({ isTeams }) {
  // preserve queryParams from url
  const { plan, discountId } = useQueryParams();
  const location = useLocation();
  let signInUrl = '/login';
  // Redirect to checkout after login if user is on upgrade page
  if (location?.pathname?.startsWith('/upgrade/')) {
    const planQueryParam = plan ? `plan=${plan}&` : '';
    const discountIdQueryParam = discountId ? `discountId=${discountId}&` : '';
    signInUrl = `/login?redirect_to=/upgrade/${isTeams ? 'teams-' : ''}checkout?${planQueryParam}${discountIdQueryParam}`;
  }
  // if we're in local dev, redirect to the local dev login page
  else if (process.env.NODE_ENV === 'development') {
    signInUrl = '/local-login';
  }
  return (
    <p className="mt-4 text-center">
      <span className="text-black">Already have an account? </span>
      <AddLink to={signInUrl}>Sign In &raquo;</AddLink>
    </p>
  );
}

function ToSAndPPLinks() {
  return (
    <>
      By creating an account, you agree to the{' '}
      <AddLink to={`${process.env.REACT_APP_V2_SITE_URL}/terms-service/`} target="_blank" rel="noreferrer">
        Terms of Service
      </AddLink>{' '}
      and{' '}
      <AddLink to={`${process.env.REACT_APP_V2_SITE_URL}/privacy-policy/`} target="_blank" rel="noreferrer">
        Privacy Policy
      </AddLink>
    </>
  );
}

const CreateAccount = inject('authStore')(
  observer(
    ({ formId, className, authStore, handleSubmit, isTeams, hideSignIn, serverErrors, clearErrorForField, isReCAPTCHARetry, initEmail, initPassword, initSubscribe, initToS }) => {
      const [isLoading, setIsLoading] = useState(false);
      const [isInEU, setIsInEU] = useState(null); // null means that we haven't checked the user's IP, otherwise this will be a boolean
      const [failedValidation, setFailedValidation] = useState(false);
      const [passwordValidations, setPasswordValidations] = useState({
        length: false,
        number: false,
        lowercase: false,
        uppercase: false,
      });
      const [openPwRequirements, setOpenPwRequirements] = useState(false);
      const { referral } = useQueryParams();

      const RegisterForm = {
        name: 'Register Form',
        order: ['email', 'password', 'password_requirements', 'referral', 'subscribe', 'tos', 'language', 'submit'],
        fields: {
          email: {
            type: 'email',
            label: 'Email',
            validations: ['required', 'email'],
            disabled: isReCAPTCHARetry,
            defaultValue: initEmail,
          },
          password: {
            type: 'password',
            label: 'Password',
            validations: ['required', AuthUtil.validatePassword],
            onFocus: () => setOpenPwRequirements(true),
            onBlur: () => setOpenPwRequirements(false),
            disabled: isReCAPTCHARetry,
            defaultValue: initPassword,
          },
          password_requirements: {
            type: 'insert',
            insertComponent: <PasswordRequirements openPwRequirements={openPwRequirements} passwordValidations={passwordValidations} />,
            hidden: isReCAPTCHARetry,
          },
          referral: {
            type: 'text',
            label: 'Referral Code',
            value: referral,
            defaultValue: referral,
            disabled: true,
            hidden: !referral, // Only show if we have a referral from query params
          },
          subscribe: {
            type: 'checkbox',
            options: [
              {
                value: 'Y',
                text: 'I want to receive new courses, feature updates, news, and discounts from Cybrary',
              },
            ],
            disabled: isReCAPTCHARetry,
            defaultValue: initSubscribe ? ['Y'] : ['N'],
          },
          tos: {
            type: 'checkbox',
            options: [
              {
                value: 'Y',
                text: <ToSAndPPLinks />,
              },
            ],
            style: {
              display: 'none',
            },
            defaultValue: initToS ? ['Y'] : ['N'],
            requiredHidden: true,
            validations: ['required'],
            disabled: isReCAPTCHARetry,
          },
          // hidden preferred language field
          language: {
            type: 'hidden',
            defaultValue: navigator.language,
          },
          submit: {
            type: 'submit-captcha-v3',
            color: 'pink',
            label: 'Create Account',
            className: 'w-full px-6 py-3 text-sm md-991:w-auto',
            loading: isLoading,
            disabled: failedValidation,
          },
        },
      };

      // markDone == setCompleted
      // setLoading == setIsLoading
      // startValidation == setIsLoading(true)

      // Set loading to false at the end of validation, if it failed
      const endValidation = (valid) => {
        setFailedValidation(!valid);
        setIsLoading(false);
      };

      const checkUserLocation = async () => {
        try {
          const euCountries = AuthUtil.getEuCountries();
          const geo = await authStore.getGeoInfo();
          setIsInEU(euCountries.includes(geo?.countryCode));
        } catch (e) {
          setIsInEU(true);
          BugsnagUtil.notify(e);
        }
      };
      useEffect(() => {
        checkUserLocation();
      }, []);

      if (isInEU === null) {
        // Still waiting for the EU info, just show the loading state until we get it...
        return <Loading className="w-8 h-8" />;
      }

      RegisterForm.fields.subscribe.defaultValue = !isInEU ? ['Y'] : ['N'];

      return (
        <div className={className}>
          {!hideSignIn && (
            <>
              <LoginWithDivider isTeamProduct={isTeams} />
              <Divider horizontal className="my-8">
                OR
              </Divider>
            </>
          )}
          <DynamicForm
            formId={formId}
            form={RegisterForm}
            startValidation={() => setIsLoading(true)}
            endValidation={(valid) => endValidation(valid)}
            handleOnChange={(data) => setPasswordValidations(AuthUtil.checkPassword(data?.password))}
            onSubmit={handleSubmit}
            serverErrors={serverErrors}
            clearErrorForField={clearErrorForField}
          />
        </div>
      );
    }
  )
);

export default CreateAccount;
