import React from 'react';
import Button from '../Button/Button';

function TeamSelectionButtons({ teams = [], ctaText = 'Continue', handleSelection }) {
  return (
    <div className="flex flex-col gap-y-8">
      {teams.map((team) => {
        return (
          <div key={team.id} className="flex justify-between items-center">
            <p>{team.name}</p>
            <Button onClick={() => handleSelection(team)}>{ctaText}</Button>
          </div>
        );
      })}
    </div>
  );
}

export default TeamSelectionButtons;
