import React, { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import FocusLock from 'react-focus-lock';
import { createPortal } from 'react-dom';

function CloseX({ toggle, omitCloseX, color }) {
  if (omitCloseX) {
    return null;
  }

  return (
    <button aria-label="Close" className={`cursor-pointer text-3xl ${color || ''}`} onClick={toggle}>
      &times;
    </button>
  );
}

function manageFocus(focusLocked) {
  if (focusLocked) {
    const modalContainer = document.getElementById('modal-container');
    if (modalContainer) {
      modalContainer.focus();
    }
  }
}

function getBackgroundColor(transparentBg, bgBlack) {
  if (transparentBg) {
    return '';
  }
  if (bgBlack) {
    return 'bg-black';
  }
  return 'bg-white';
}

function getSizeStyles(size, sizeStyles) {
  if (sizeStyles) {
    return sizeStyles;
  }
  switch (size) {
    case 'sm':
      return 'md:w-1/3';
    case 'md':
      return 'md:w-2/3 xl:w-1/2';
    case 'lg':
      return 'md:w-2/3';
    case 'xl':
      return 'md:w-5/6';
    default:
      return 'sm:w-3/4 lg:w-2/3 xl:w-1/2';
  }
}

function getPositionStyles(position) {
  let positionStyles = '';

  if (position === 'center') {
    positionStyles = 'flex items-center';
  }

  if (position === 'top right') {
    positionStyles = 'flex items-start justify-end';
  }

  if (position === 'bottom') {
    positionStyles = 'flex items-end';
  }

  return positionStyles;
}

function Modal({
  open,
  trigger,
  toggle,
  header,
  size,
  children,
  transparentBg,
  position,
  ariaLabel,
  paddingBottom,
  omitCloseX,
  ariaLabelledBy,
  focusLockDelay = 0,
  closeIconColor,
  sizeClasses,
  bgBlack,
  className = '',
  closeXClassName,
  modalOverflow,
  omitMargin,
  omitRounded,
  backdropColor = 'rgba(0, 0, 0, 0.3)',
}) {
  const [focusLocked, setFocusLocked] = useState(false);
  const positionStyles = getPositionStyles(position);

  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : '';
    // Control when focus lock begins (in case we need to delay for reasons (toast has focus))
    if (open) {
      setTimeout(() => {
        setFocusLocked(true);
      }, focusLockDelay);
    }
    // allow modal to be closed on press of esc key (accessibility reasons)
    const close = (e) => {
      if (e && e.key === 'Escape' && open && toggle) {
        toggle();
      }
    };
    window.addEventListener('keydown', close);
    return () => {
      window.removeEventListener('keydown', close);
      setFocusLocked(false);
    };
  }, [open]);

  // Set focus on modal when focuslocked
  // There's a prop to go on modal, 'autofocus' that will auto focus on a tabble element inside, but this defaults to the close button when active
  // 'autofocus' also doesn't seem to work when delayed from focusLockDelay
  useEffect(() => {
    manageFocus(focusLocked);
  }, [focusLocked]);

  const paddingB = paddingBottom || 'pb-10';

  // if we have aria label by, apply aria labeled by
  // else use the passed in aria label or default aria label
  const ariaLabelProps = {
    role: 'dialog',
    tabIndex: '-1',
    'aria-modal': 'true',
  };
  if (ariaLabelledBy) {
    ariaLabelProps['aria-labelledby'] = ariaLabelledBy;
  } else {
    ariaLabelProps['aria-label'] = ariaLabel || 'Modal Dialog';
  }

  const bgColor = getBackgroundColor(transparentBg, bgBlack);
  const sizeStyles = getSizeStyles(size, sizeClasses);
  const margin = omitMargin ? '' : 'mb-16 mx-auto';
  const rounded = omitRounded ? '' : 'rounded-lg';
  const defaultWithoutPosition = !positionStyles ? 'md:mt-10 mt-5' : '';
  const overflow = modalOverflow || 'overflow-auto';
  const modalContentClassName = twMerge(overflow, bgColor, sizeStyles, defaultWithoutPosition, margin, rounded, 'z-30 cursor-default w-full h-auto', className);

  return (
    <>
      {trigger}
      {open &&
        createPortal(
          // if autoFocus is set to true will set focus on first focusable element in module - Focus being handled above when focusLocked is true
          <FocusLock autoFocus={false} disabled={!focusLocked} returnFocus>
            <div
              onClick={toggle}
              role="button"
              tabIndex={0}
              className={`${open ? '' : 'hidden'} fixed top-0 left-0 w-full`}
              style={{ backgroundColor: backdropColor, zIndex: '2000' }}
              id="modal-container"
            >
              <div className={`z-20 h-screen overflow-auto px-2 sm:px-5 ${positionStyles}`}>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  role="button"
                  tabIndex={0}
                  className={modalContentClassName}
                  style={{ maxHeight: '90vh' }}
                  {...ariaLabelProps}
                >
                  {header && (
                    <div className={`${transparentBg ? '' : 'border-b-xs border-gray-400'} flex items-center justify-between px-6 py-1 md:py-2`}>
                      <h5 className="inline-block text-xl">{header}</h5>
                      <CloseX toggle={toggle} omitCloseX={omitCloseX} color={closeIconColor} />
                    </div>
                  )}
                  <div className={`${bgColor} relative ${paddingB} ${header ? 'pt-5' : 'pt-0'}`}>
                    {!header && (
                      <div className={`absolute z-20 top-0 right-0 block text-center ${closeXClassName || '-mt-1 pr-3'}`}>
                        <CloseX toggle={toggle} omitCloseX={omitCloseX} color={closeIconColor} />
                      </div>
                    )}
                    {children}
                  </div>
                </div>
              </div>
            </div>
          </FocusLock>,
          document.body
        )}
    </>
  );
}

export default Modal;
