import React from 'react';

export default function ContentAvailable({ classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';
  return (
    <svg className={className} viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...accessibilityProps}>
      <path d="M3 10.6L4.58049 13L8.53171 7" stroke="black" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
      <rect x="0.5" y="4.5" width="11" height="11" stroke="black" />
      <line x1="1" y1="2.5" x2="11" y2="2.5" stroke="black" />
      <line x1="2" y1="0.5" x2="10" y2="0.5" stroke="black" />
    </svg>
  );
}
