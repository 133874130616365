import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { find } from 'lodash';
import Bugsnag from '@bugsnag/js';
import nav from '../../navigation/nav';
import AddLink from '../AddLink/AddLink';
import Loading from '../Loading/Loading';
import FilterUtil from '../../utils/filterUtil';
import Chevron from './Icons/Chevron';
import withRouter from '../Router/withRouter';
import PermalinkUtil from '../../utils/permalinkUtil';
import { HeadingWrapper, MobileMenuItems, SelectedMenu } from './DropdownMenu';

function DesktopMenuWrapper({ exploreDropdown, searchFacets, facets, exploreCertCount, active, mobile, handleSelect, redirectToDestination, isEnterprise }) {
  return (
    <>
      <ul className="relative pt-6 pb-4 w-[212px]">
        {exploreDropdown.dropdown.map((item) => {
          // Hide live training for non-enterprise users
          if (item.label === 'Live Training' && !isEnterprise) {
            return null;
          }
          if (item.heading) {
            return <HeadingWrapper key={item.id} item={item} />;
          }
          const isButton = item.type === 'button';
          const wrapperClass = isButton ? 'w-full px-4' : 'flex items-center mb-2';
          const linkClass = `${
            item.additionalLabels ? 'w-auto' : 'w-full'
          } lg:w-auto flex items-center text-gray-600 hover:text-black text-lg lg:text-sm pl-6 lg:pl-8 pr-2 py-3 lg:py-2 cursor-pointer`;
          const buttonClass = 'bg-gray-200 flex items-center justify-center text-black text-sm w-full px-6 py-3 rounded-sm font-bold hover:bg-cyb-gray-500 hover:text-black';
          const labelClass = isButton ? '' : 'pr-2 text-gray-600';
          if (mobile && isButton) {
            return null;
          }
          if (item.type === 'filter') {
            const navSearchFacets = searchFacets.filter((facet) => !['work_role', 'nice_work_role', 'nice_category', 'groups_info', 'level.name'].includes(facet));
            return navSearchFacets.map((facetCategory, index) => {
              // Get the data for this facet
              const facet = FilterUtil.getFacetData(facets, facetCategory);
              const filterHeader = FilterUtil.getFilterHeader(facetCategory);
              const filters = FilterUtil.getFilters(facet, exploreCertCount);
              const activeClass = index === active.index ? 'bg-gray-200' : '';
              if (!facet || filterHeader === 'terms_info') {
                return null;
              }
              return (
                <div key={filterHeader} className={`${linkClass} ${activeClass}`} onClick={() => handleSelect(index, facet.name, filters)} role="button" tabIndex={0}>
                  {filterHeader}
                  <Chevron direction={index === active.index ? 'left' : 'right'} classes="w-4 h-4 ml-auto" />
                </div>
              );
            });
          }
          return (
            <li key={item.id} className={wrapperClass}>
              <AddLink onClick={() => redirectToDestination(item.href)} className={isButton ? buttonClass : linkClass}>
                <span className={labelClass}>{item.label}</span>
              </AddLink>
            </li>
          );
        })}
      </ul>
      {active.isActive && active.filters.length ? (
        <SelectedMenu
          wrapperClass="overflow-x-hidden overflow-y-auto pt-12 pb-4 w-[212px]"
          linkClass="flex items-center py-3 pr-2 pl-6 w-full text-lg text-left text-gray-600 hover:text-black cursor-pointer lg:py-2 lg:pl-4 lg:w-auto lg:text-sm"
          redirectToDestination={redirectToDestination}
          active={active}
          isEnterprise={isEnterprise}
        />
      ) : null}
    </>
  );
}

const ExploreMenu = inject('searchStore')(
  observer(({ onClick, mobile, navigate, searchStore, isEnterprise }) => {
    const [active, setActive] = useState({
      facet: null,
      index: null,
      filters: [],
      isActive: false,
    });

    const { primary } = nav;
    const exploreDropdown = find(primary.items, (item) => {
      return item.label === 'Explore';
    });
    useEffect(() => {
      const getExploreSearchItems = async () => {
        try {
          await searchStore.getExploreSearchItems();
        } catch (err) {
          Bugsnag.notify(err);
        }
      };
      getExploreSearchItems();
      return () => {
        setActive({
          facet: null,
          index: null,
          filters: [],
          isActive: false,
        });
        searchStore.setDefaultExploreSearchItems();
      };
    }, []);

    const handleSelect = (index, facet, filters) => {
      if (active.index === index) {
        setActive({ index: null, facet: null, filters: [], isActive: false });
      } else {
        setActive({ index, facet, filters, isActive: true });
      }
    };

    const redirectToDestination = (destination) => {
      PermalinkUtil.redirectToDestination(destination, navigate, onClick);
    };

    const baseFacets = FilterUtil.getSearchFacets();
    const advancedFacets = FilterUtil.getAdvancedFacets().filter((facet) => facet !== 'vendor.name');
    const searchFacets = [...baseFacets, ...advancedFacets];
    const { exploreSearchItems, exploreCertCount } = searchStore;
    const { facets, loading } = exploreSearchItems;
    const navSearchFacets = searchFacets.filter((facet) => !['work_role', 'groups_info', 'level.name'].includes(facet));

    if (mobile) {
      return loading ? (
        <Loading />
      ) : (
        <ul className="mb-8">
          {exploreDropdown.dropdown.map((item) => {
            if (item.type === 'button') {
              return null;
            }
            // remove live training if not enterprise user
            if (item.label === 'Live Training' && !isEnterprise) {
              return null;
            }
            if (item.heading) {
              return <HeadingWrapper key={item.id} item={item} />;
            }
            return (
              <MobileMenuItems
                key={item.id}
                item={item}
                facets={facets}
                searchFacets={navSearchFacets}
                active={active}
                setActive={setActive}
                certCount={exploreCertCount}
                handleSelect={handleSelect}
                redirectToDestination={redirectToDestination}
                toggleMobileSidebar={onClick}
                isEnterprise={isEnterprise}
              />
            );
          })}
        </ul>
      );
    }

    return (
      <div className="flex justify-between w-full max-h-[80vh] divide-x-1">
        {loading ? (
          <div className="flex justify-center items-center w-[212px]">
            <Loading />
          </div>
        ) : (
          <DesktopMenuWrapper
            exploreDropdown={exploreDropdown}
            searchFacets={searchFacets}
            facets={facets}
            exploreCertCount={exploreCertCount}
            active={active}
            mobile={mobile}
            handleSelect={handleSelect}
            redirectToDestination={redirectToDestination}
            isEnterprise={isEnterprise}
          />
        )}
      </div>
    );
  })
);

export default withRouter(ExploreMenu);
