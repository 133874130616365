import React from 'react';

export default function Keyboard({ classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 59.48" {...accessibilityProps}>
      <g>
        <path
          d="M113.82,0c2.49,0,4.76,1.02,6.4,2.66c1.64,1.64,2.66,3.91,2.66,6.4v41.35c0,2.49-1.02,4.76-2.66,6.4 
          c-1.64,1.64-3.91,2.66-6.4,2.66H9.06c-2.49,0-4.76-1.02-6.4-2.66C1.02,55.18,0,52.91,0,50.42V9.06c0-2.49,1.02-4.76,2.66-6.4 
          C4.3,1.02,6.57,0,9.06,0C69.6,0,96.63,0,113.82,0L113.82,0z M92.24,3.84H9.06c-1.44,0-2.74,0.59-3.69,1.54 
          C4.42,6.32,3.84,7.63,3.84,9.06v41.35c0,1.44,0.59,2.74,1.54,3.69c0.95,0.95,2.25,1.54,3.69,1.54h104.75 
          c1.44,0,2.74-0.59,3.69-1.54c0.95-0.95,1.54-2.25,1.54-3.69V9.06c0-1.44-0.59-2.74-1.54-3.69c-0.95-0.95-2.25-1.54-3.69-1.54 
          h-13.24C98.26,3.84,94.56,3.84,92.24,3.84L92.24,3.84z M12.26,9.73h7.75c1.06,0,1.92,0.86,1.92,1.92v6.54 
          c0,1.06-0.86,1.92-1.92,1.92h-7.75c-1.06,0-1.92-0.86-1.92-1.92v-6.54C10.34,10.59,11.2,9.73,12.26,9.73L12.26,9.73z M27.61,9.73 
          h7.75c1.06,0,1.92,0.86,1.92,1.92v6.54c0,1.06-0.86,1.92-1.92,1.92h-7.75c-1.06,0-1.92-0.86-1.92-1.92v-6.54 
          C25.69,10.59,26.55,9.73,27.61,9.73L27.61,9.73z M42.97,9.73h7.75c1.06,0,1.92,0.86,1.92,1.92v6.54c0,1.06-0.86,1.92-1.92,1.92
          h-7.75c-1.06,0-1.92-0.86-1.92-1.92v-6.54C41.05,10.59,41.91,9.73,42.97,9.73L42.97,9.73z M58.32,9.73h7.75 
          c1.06,0,1.92,0.86,1.92,1.92v6.54c0,1.06-0.86,1.92-1.92,1.92h-7.75c-1.06,0-1.92-0.86-1.92-1.92v-6.54 C56.41,10.59,57.26,9.73,58.32,9.73L58.32,9.73z 
          M73.68,9.73h7.75c1.06,0,1.92,0.86,1.92,1.92v6.54c0,1.06-0.86,1.92-1.92,1.92 
          h-7.75c-1.06,0-1.92-0.86-1.92-1.92v-6.54C71.76,10.59,72.62,9.73,73.68,9.73L73.68,9.73z M89.04,9.73h7.75 
          c1.06,0,1.92,0.86,1.92,1.92v6.54c0,1.06-0.86,1.92-1.92,1.92h-7.75c-1.06,0-1.92-0.86-1.92-1.92v-6.54 C87.12,10.59,87.98,9.73,89.04,9.73L89.04,9.73z 
          M104.39,9.73h7.75c1.06,0,1.92,0.86,1.92,1.92v6.54c0,1.06-0.86,1.92-1.92,1.92 
          h-7.75c-1.06,0-1.92-0.86-1.92-1.92v-6.54C102.47,10.59,103.33,9.73,104.39,9.73L104.39,9.73z M104.39,23.85h7.75 
          c1.06,0,1.92,0.87,1.92,1.94v20.68c0,1.07-0.86,1.94-1.92,1.94h-7.75c-1.06,0-1.92-0.87-1.92-1.94V25.79 
          C102.47,24.72,103.33,23.85,104.39,23.85L104.39,23.85z M12.26,24.02h7.75c1.06,0,1.92,0.86,1.92,1.92v6.54 
          c0,1.06-0.86,1.92-1.92,1.92h-7.75c-1.06,0-1.92-0.86-1.92-1.92v-6.54C10.34,24.88,11.2,24.02,12.26,24.02L12.26,24.02z 
          M27.61,24.02h7.75c1.06,0,1.92,0.86,1.92,1.92v6.54c0,1.06-0.86,1.92-1.92,1.92h-7.75c-1.06,0-1.92-0.86-1.92-1.92v-6.54 
          C25.69,24.88,26.55,24.02,27.61,24.02L27.61,24.02z M42.97,24.02h7.75c1.06,0,1.92,0.86,1.92,1.92v6.54c0,1.06-0.86,1.92-1.92,1.92 
          h-7.75c-1.06,0-1.92-0.86-1.92-1.92v-6.54C41.05,24.88,41.91,24.02,42.97,24.02L42.97,24.02z M58.32,24.02h7.75 
          c1.06,0,1.92,0.86,1.92,1.92v6.54c0,1.06-0.86,1.92-1.92,1.92h-7.75c-1.06,0-1.92-0.86-1.92-1.92v-6.54 C56.41,24.88,57.26,24.02,58.32,24.02L58.32,24.02z 
          M73.68,24.02h7.75c1.06,0,1.92,0.86,1.92,1.92v6.54c0,1.06-0.86,1.92-1.92,1.92 
          h-7.75c-1.06,0-1.92-0.86-1.92-1.92v-6.54C71.76,24.88,72.62,24.02,73.68,24.02L73.68,24.02z M89.04,24.02h7.75 
          c1.06,0,1.92,0.86,1.92,1.92v6.54c0,1.06-0.86,1.92-1.92,1.92h-7.75c-1.06,0-1.92-0.86-1.92-1.92v-6.54 
          C87.12,24.88,87.98,24.02,89.04,24.02L89.04,24.02z M12.26,38.16h7.75c1.06,0,1.92,0.86,1.92,1.92v6.54c0,1.06-0.86,1.92-1.92,1.92 
          h-7.75c-1.06,0-1.92-0.86-1.92-1.92v-6.54C10.34,39.02,11.2,38.16,12.26,38.16L12.26,38.16z M27.61,38.16h38.47 
          c1.05,0,1.9,0.86,1.9,1.92v6.54c0,1.06-0.85,1.92-1.9,1.92H27.61c-1.05,0-1.9-0.86-1.9-1.92v-6.54 C25.71,39.02,26.56,38.16,27.61,38.16L27.61,38.16z 
          M73.68,38.16h7.75c1.06,0,1.92,0.86,1.92,1.92v6.54c0,1.06-0.86,1.92-1.92,1.92 
          h-7.75c-1.06,0-1.92-0.86-1.92-1.92v-6.54C71.76,39.02,72.62,38.16,73.68,38.16L73.68,38.16z M89.04,38.16h7.75 
          c1.06,0,1.92,0.86,1.92,1.92v6.54c0,1.06-0.86,1.92-1.92,1.92h-7.75c-1.06,0-1.92-0.86-1.92-1.92v-6.54 
          C87.12,39.02,87.98,38.16,89.04,38.16L89.04,38.16z"
        />
      </g>
    </svg>
  );
}
