import React from 'react';
import { twMerge } from 'tailwind-merge';

// https://www.figma.com/file/KOZCHCtm08V9L3PhB6Hda0/Product-Design-System?node-id=527%3A157
const defaultClasses = {
  h1: 'mb-8 text-4xl font-bold lg:text-5xl',
  h2: 'mb-6 text-2xl font-bold',
  h3: 'mb-4 text-lg font-bold',
  h4: 'mb-4 font-semibold',
  h5: 'mb-4 text-xs font-semibold',
  h6: 'mb-4 text-2xs font-semibold',
};

export default function Header({ as: HTag = 'h1', className = '', children, id }) {
  const classes = twMerge(defaultClasses[HTag], className);
  return (
    <HTag id={id || ''} className={classes}>
      {children}
    </HTag>
  );
}
