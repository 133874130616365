import React, { useState } from 'react';
import Bugsnag from '@bugsnag/js';
import { useParams } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { twMerge } from 'tailwind-merge';
import Modal from '../../components/Modal/Modal';
import Icon from '../../components/Icon/Icon';
import List, { ListItem } from '../../components/List/List';
import Loading from '../../components/Loading/Loading';
import Container from '../../components/Container/Container';
import FormatUtil from '../../utils/formatUtil';
import Pagination from '../../components/Pagination/Pagination';
import './enterprise.css';
import Tooltip from '../../components/Tooltip/Tooltip';
import Header from '../../components/Header/Header';

const deleteTeamInvitation = (inviteId, orgId, enterpriseStore, commonStore) => {
  enterpriseStore
    .deleteTeamInvitation(orgId, inviteId)
    .then(() => {
      enterpriseStore.getInvitedTeamMembers(orgId);
      commonStore.triggerToast('success', {
        content: 'Successfully deleted invitation',
      });
    })
    .catch((error) => {
      Bugsnag.notify(error);
      commonStore.triggerToast('error', {
        content: 'Something went wrong. Unable to delete invitation',
      });
    });
};

const resendTeamInvitation = (inviteId, orgId, enterpriseStore, commonStore, email) => {
  enterpriseStore
    .resendTeamInvitation(orgId, inviteId)
    .then(() => {
      commonStore.triggerToast('success', {
        content: `Successfully resent invitation to ${email}`,
      });
    })
    .catch((error) => {
      Bugsnag.notify(error);
      commonStore.triggerToast('error', {
        content: 'Something went wrong. Unable to resend invitation',
      });
    });
};

const InvitedTeamMembersModal = inject(
  'enterpriseStore',
  'commonStore'
)(
  observer(({ enterpriseStore, commonStore }) => {
    const { invitedTeamMembers } = enterpriseStore || {};
    const { loading, data, queryParams, pageCount } = invitedTeamMembers || {};
    const { activePg } = queryParams || {};
    const [invitedOpen, setInvitedOpen] = useState(false);
    const { orgId } = useParams();

    const changePage = (pg) => {
      enterpriseStore.setTeamInviteQueryParams('activePg', pg);
      enterpriseStore.getInvitedTeamMembers(orgId);
    };

    if (!data || !data.totalRecords) {
      if (invitedOpen) {
        setInvitedOpen(false);
      }
      return null;
    }
    const listData = FormatUtil.convertTableDataToList(data.columns, data.tableData);
    const labelText = `${data.totalRecords} Pending Invite${data.totalRecords > 1 ? 's' : ''}`;
    const liClasses = twMerge('flex overflow-hidden justify-between items-center p-2 hover:bg-gray-50 border-t last:border-b border-gray-400', loading && 'opacity-40');
    return (
      <>
        <button onClick={() => setInvitedOpen(true)} className="mb-2 text-xs text-red-700 rounded-sm border border-red-700 sm:ml-0.5" style={{ padding: '.4em .8em' }}>
          {labelText}
        </button>
        <Modal open={invitedOpen} toggle={() => setInvitedOpen(false)} paddingBottom="p-4" position="center" size="lg" ariaLabelledBy="invited-users">
          <div className="pt-8 bg-white lg:p-4 invited-members-list">
            <Header as="h2" className="mb-2">
              Invited Users
            </Header>
            <p>These users have been invited to your team, but have not accepted their invite.</p>
            {loading && !listData.length ? (
              <Container>
                <Loading message="Loading..." />
              </Container>
            ) : (
              <div className="flex flex-col">
                <List className="overflow-y-auto mt-4 max-h-[60vh] border-gray-400">
                  {listData.map((item) => {
                    const roleMeta = FormatUtil.getTeamRoleMeta(item.role);
                    const resendMessage = `Resend invitation to ${item.email.slice(0, 47)}${item.email.length > 47 ? '...' : ''}`;
                    const deleteMessage = `Delete invitation for ${item.email.slice(0, 47)}${item.email.length > 47 ? '...' : ''}`;
                    return (
                      <ListItem active={!loading} key={item.id} className={liClasses}>
                        <div className="list-content">
                          <div className="flex items-center space-x-2">
                            <div>{item.email}</div>
                            <div className={`text-${roleMeta.color}-500 text-sm font-semibold`}>{FormatUtil.convertRoleName(item.role)}</div>
                          </div>
                          <p className="invite-date">Invited {FormatUtil.convertUtcToLocal(item.created_at).format('M/D/YYYY')}</p>
                        </div>
                        <div className="float-right pr-4">
                          <Tooltip
                            omitTabIndex
                            triggerContent={
                              <button
                                disabled={loading}
                                aria-label={resendMessage}
                                onClick={() => resendTeamInvitation(item.id, orgId, enterpriseStore, commonStore, item.email)}
                                className="mr-4 text-blue-400 hover:text-blue-600 disabled:text-gray-400 disabled:animate-pulse"
                              >
                                <Icon name="refresh" className="w-6 h-6 " />
                              </button>
                            }
                            content={resendMessage}
                          />
                          <Tooltip
                            omitTabIndex
                            triggerContent={
                              <button
                                disabled={loading}
                                aria-label={deleteMessage}
                                onClick={() => deleteTeamInvitation(item.id, orgId, enterpriseStore, commonStore)}
                                className=" text-red-400 hover:text-red-600 disabled:text-gray-400 disabled:animate-pulse"
                              >
                                <Icon name="trash" className="w-6 h-6" />
                              </button>
                            }
                            content={deleteMessage}
                          />
                        </div>
                      </ListItem>
                    );
                  })}
                </List>
                <div className="grow-0 mx-auto mt-3">
                  <Pagination totalPages={pageCount || 0} onPageChange={changePage} activePage={activePg} />
                </div>
              </div>
            )}
          </div>
        </Modal>
      </>
    );
  })
);

export default InvitedTeamMembersModal;
