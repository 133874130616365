import React from 'react';
import { twMerge } from 'tailwind-merge';
import Icon from '../Icon/Icon';
import Tooltip from '../Tooltip/Tooltip';

/**
 * The button to trigger the share dropdown
 */
function ShareButton({ textColor, useIcon, onClick, theme, iconClasses = '' }) {
  return (
    <Tooltip
      position="top-end"
      size="small"
      theme={theme || 'light'}
      style={{ display: 'flex', alignItems: 'center' }}
      content={<div className="font-semibold text-center">Share</div>}
      omitTabIndex
      triggerContent={
        <button className="text-sm leading-5 bg-none rounded-sm cursor-pointer reviews-button" onClick={onClick} aria-label="Share">
          <div className="flex items-center">
            <Icon className={twMerge('w-6 h-6 text-gray-600 share-icon', iconClasses)} name="share" />
            {useIcon ? null : <div className={`inline text-sm ml-2 ${textColor || 'text-white'}`}>Share</div>}
          </div>
        </button>
      }
    />
  );
}

export default ShareButton;
