import React from 'react';

export default function Filter({ active, classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';
  if (active) {
    return (
      <svg className={className} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...accessibilityProps}>
        <path
          d="M1.371 1.371C1.13345 1.60854 1 1.93073 1 2.26667V5.54227C1.00007 5.87818 1.13357 6.20031 1.37113 6.4378L9.49553 14.5622C9.7331 14.7997 9.8666 15.1218 
          9.86667 15.4577V23.8L14.9333 18.7333V15.4577C14.9334 15.1218 15.0669 14.7997 15.3045 14.5622L23.4289 6.4378C23.6664 6.20031 23.7999 5.87818 23.8 
          5.54227V2.26667C23.8 1.93073 23.6665 1.60854 23.429 1.371C23.1915 1.13345 22.8693 1 22.5333 1H2.26667C1.93073 1 1.60854 1.13345 1.371 1.371Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
  return (
    <svg className={className} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...accessibilityProps}>
      <path
        d="M1.371 1.371C1.13345 1.60854 1 1.93073 1 2.26667V5.54227C1.00007 5.87818 1.13357 6.20031 1.37113 6.4378L9.49553 14.5622C9.7331 14.7997 9.8666 15.1218 9.86667 
        15.4577V23.8L14.9333 18.7333V15.4577C14.9334 15.1218 15.0669 14.7997 15.3045 14.5622L23.4289 6.4378C23.6664 6.20031 23.7999 5.87818 23.8 5.54227V2.26667C23.8 
        1.93073 23.6665 1.60854 23.429 1.371C23.1915 1.13345 22.8693 1 22.5333 1H2.26667C1.93073 1 1.60854 1.13345 1.371 1.371Z"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
