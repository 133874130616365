import React from 'react';
import { observer, inject } from 'mobx-react';
import AddLink from '../AddLink/AddLink';
import PercentageBar from '../Analytics/PercentageBar';
import CircularProgress from '../ProgressBar/CircularProgress';
import { StatusIcon } from '../ContentStatus/StatusLegend';
import FormatUtil from '../../utils/formatUtil';
import PermalinkUtil from '../../utils/permalinkUtil';
import StyleUtil from '../../utils/styleUtil';
import SearchUtil from '../../utils/searchUtil';
import Icon from '../Icon/Icon';
import ContentCardActionsMenu from './ContentCardActionsMenu';
import AddOnContentMessage from '../Message/AddOnMessage';
import { trackSnowplowEvent } from '../../utils/snowplowUtil';
import useSandboxCookie from '../../hooks/cookies/useSandboxCookie';

function isCompleted(progress) {
  return 1 * progress === 100;
}

function ContentBadge({ badge, className }) {
  if (!badge) {
    return null;
  }
  return <div className={className || 'absolute top-0 left-0 py-1 px-2 text-2xs text-black bg-white rounded-br-sm border-white'}>{badge}</div>;
}

function Label({ label }) {
  return <span className="inline-block py-1px px-1 text-2xs font-semibold leading-4 text-gray-600 uppercase rounded-sm border-xs border-gray-600">{label}</span>;
}

function LabelsContainer({ instructors, author, level, type }) {
  const labelsToShow = [];
  if (instructors && instructors.length) {
    labelsToShow.push(type);
    instructors.forEach((instructor) => {
      labelsToShow.push(instructor.name);
    });
  } else if (author) {
    labelsToShow.push(author);
    labelsToShow.push(type);
  }
  labelsToShow.push(level);
  return (
    <div className="flex flex-wrap gap-2 max-w-[600px]">
      {labelsToShow.map((label) => {
        if (!label) {
          return null;
        }
        return <Label key={label} label={FormatUtil.formatContentType(label)} />;
      })}
    </div>
  );
}

/**
 * The details about the activity
 * @param { durationSeconds, ceuCount, isComingSoon, completed, className, xp }
 * @returns jsx div
 */
function Details({ durationSeconds, ceuCount, isComingSoon, completed, className, xp }) {
  const details = [];
  if (xp) {
    details.push(`${xp}xp`);
  }
  if (durationSeconds) {
    const duration = FormatUtil.formatTime(durationSeconds, 'hma');
    details.push(duration);
  }
  if (ceuCount) {
    const ceus = `${ceuCount}CEUs`;
    details.push(ceus);
  }
  const formatDetails = details.join(' | ');
  return (
    <div className={`text-xs flex items-center ${className}`}>
      {formatDetails}
      {completed ? <StatusIcon status="complete" /> : null}
      {isComingSoon ? <StatusIcon status="comingSoon" /> : null}
    </div>
  );
}

function ProgressStatus({ enrollmentProgress, className = '', isList }) {
  const containerSizeClasses = isList && isCompleted(enrollmentProgress) ? 'w-8 h-8' : 'w-12 h-12';
  const iconSizeClasses = isList && isCompleted(enrollmentProgress) ? 'w-4 h-4' : 'w-6 h-6';
  return (
    <div className={`${containerSizeClasses} rounded-full overflow-hidden ${className}`}>
      {isCompleted(enrollmentProgress) ? (
        <div className="flex justify-center items-center w-full h-full bg-green-700">
          <Icon name="completion-check" className={`${iconSizeClasses} mx-auto text-white hover:text-white`} />
        </div>
      ) : (
        <CircularProgress
          classes={`${containerSizeClasses} mx-auto overflow-hidden`}
          progressClasses="bg-white rounded-full flex relative justify-center items-center"
          strokeWidth={4}
          percentageValue={enrollmentProgress}
        >
          <Icon name="trophy" className="mx-auto w-6 h-6 text-black hover:text-black" />
        </CircularProgress>
      )}
    </div>
  );
}

function ProgressStatusWrapper({ enrollmentProgress }) {
  return isCompleted(enrollmentProgress) ? <ProgressStatus enrollmentProgress={enrollmentProgress} className="block absolute right-2 -bottom-6 bg-white sm:hidden" /> : null;
}

function ProgressOrPercentWrapper({ enrollmentProgress, color }) {
  return isCompleted(enrollmentProgress) ? (
    <ProgressStatus enrollmentProgress={enrollmentProgress} className="hidden mb-2 sm:block" isList />
  ) : (
    <div className="overflow-hidden mb-1 ml-auto w-30 h-1 text-left bg-gray-300 rounded-lg sm:ml-0">
      <PercentageBar color={color} maxHeight="0.5rem" width={`${enrollmentProgress}%`} />
    </div>
  );
}

function TitleWrapper({ type, screenReaderHint, title }) {
  return (
    <div>
      <p className="mb-2 text-2xs font-semibold text-left text-gray-600 uppercase">{type}</p>
      {screenReaderHint && <span className="sr-only">{screenReaderHint} </span>}
      <h3 className="mb-2 max-h-15 text-sm font-bold text-left line-clamp-3">{title}</h3>
    </div>
  );
}

function ListView({
  id,
  description,
  permalink,
  wwwPermalink,
  contentType,
  prefix,
  badge,
  badgeClassName,
  img,
  title,
  type,
  durationSeconds,
  ceuCount,
  instructorsInfo,
  author,
  level,
  enrollmentProgress,
  screenReaderHint,
  isComingSoon,
  permalinkProps,
  cardProps,
  handleOnClick,
  userStore,
  children,
  isCollection,
  color,
  cardClassName,
  addToPathOption,
  experience_points_total,
}) {
  return (
    <div className={`flex flex-col w-full sm:flex-row ${cardClassName}`}>
      <div>
        <AddLink {...permalinkProps} onClick={handleOnClick} className="flex flex-col hover:text-cyb-pink-500 sm:flex-row content-card">
          <div className="relative">
            <img className="rounded-sm sm:object-left-top sm:w-60" src={img} alt="" />
            <ContentBadge badge={badge} className={badgeClassName} />
            {isCollection ? (
              <ProgressStatus enrollmentProgress={enrollmentProgress} className="block absolute right-2 -bottom-6 bg-white sm:hidden" />
            ) : (
              <ProgressStatusWrapper enrollmentProgress={enrollmentProgress} />
            )}
          </div>
          <div className="pt-4 max-w-[600px] sm:px-4 sm:pt-0">
            <div className="flex gap-x-4 items-center">
              <TitleWrapper type={type} screenReaderHint={screenReaderHint} title={title} />
              {isCollection ? (
                <ProgressStatus enrollmentProgress={enrollmentProgress} className="hidden mb-2 sm:block" isList />
              ) : (
                <ProgressOrPercentWrapper enrollmentProgress={enrollmentProgress} color={color} />
              )}
            </div>
            <p className="my-3 text-sm text-gray-600">{FormatUtil.formatLongText(description, 200)}</p>
            <LabelsContainer instructors={instructorsInfo} author={author} level={level} type={type} />
            {children}
            <Details durationSeconds={durationSeconds} ceuCount={ceuCount} isComingSoon={isComingSoon} xp={experience_points_total} className="mt-3 text-gray-600" />
          </div>
        </AddLink>
      </div>
      <div className="ml-auto">
        <div className="flex justify-between items-center px-2 pb-2">
          <ContentCardActionsMenu
            isComingSoon={isComingSoon}
            content={{ id, permalink, wwwPermalink, type: contentType, title, duration_seconds: durationSeconds, prefix }}
            userStore={userStore}
            menuAbove={cardProps && cardProps.menuAbove}
            iconContainerClassName="bg-white hover:bg-gray-200"
            addToPathOption={addToPathOption}
          />
        </div>
      </div>
    </div>
  );
}

function GridView({
  id,
  permalink,
  wwwPermalink,
  contentType,
  prefix,
  badge,
  badgeClassName,
  img,
  title,
  description,
  type,
  durationSeconds,
  ceuCount,
  enrollmentProgress,
  screenReaderHint,
  isComingSoon,
  permalinkProps,
  cardProps,
  handleOnClick,
  cardClassName = '',
  userStore,
  children,
  isCollection,
  color,
  gridShowDescription,
  fluid,
  addToPathOption,
  tags_info,
  experience_points_total,
}) {
  const { isContentIdSandboxEnabled } = useSandboxCookie();
  return (
    <div className="flex flex-col">
      <div className={`${cardClassName} border-xs border-gray-400 rounded-sm flex flex-col gap-y-4 justify-between min-h-[18rem] flex-1`}>
        <div>
          <AddLink {...permalinkProps} onClick={handleOnClick} className="block hover:text-cyb-pink-500 content-card">
            <div className="relative bg-gray-400">
              <img className={`rounded-t-sm sm:object-left-top ${fluid ? '' : 'sm:h-40'}`} src={img} alt="" />
              <ContentBadge badge={badge} className={badgeClassName} />
              {isCollection ? <ProgressStatus enrollmentProgress={enrollmentProgress} className="absolute right-2 -bottom-6 bg-white" /> : null}
            </div>
            <div className="px-2 pt-3">
              <p className="mb-2 text-2xs font-semibold text-left text-gray-600 uppercase">{type}</p>
              {screenReaderHint && <span className="sr-only">{screenReaderHint} </span>}
              <h3 className="mb-2 max-h-15 text-sm font-bold text-left line-clamp-3">{title}</h3>
              <AddOnContentMessage message="Red Team Operator Series" tagsToCheck={['Red Team Operator Series']} tags_info={tags_info} type={type} />
              <AddOnContentMessage message="DFIR Operator Series" tagsToCheck={['DFIR Operator Series']} tags_info={tags_info} type={type} />
              {gridShowDescription && description && <p className="my-3 text-xs text-gray-600">{FormatUtil.formatLongText(description, 200)}</p>}
            </div>
          </AddLink>
          {children}
        </div>
        <div>
          {isContentIdSandboxEnabled && (
            <p className="px-2 mb-0 text-xs text-gray-600">
              ID:<span> {id}</span>
            </p>
          )}
          <div className="flex justify-between items-center px-2 pb-2">
            <Details
              durationSeconds={durationSeconds}
              ceuCount={ceuCount}
              isComingSoon={isComingSoon}
              completed={isCompleted(enrollmentProgress)}
              xp={experience_points_total}
              className="text-gray-600"
            />
            <ContentCardActionsMenu
              isComingSoon={isComingSoon}
              content={{ id, permalink, wwwPermalink, type: contentType, title, duration_seconds: durationSeconds, prefix }}
              userStore={userStore}
              menuAbove={cardProps && cardProps.menuAbove}
              iconContainerClassName="bg-white hover:bg-gray-200"
              addToPathOption={addToPathOption}
            />
          </div>
          {isCollection ? null : (
            <div className="overflow-hidden mx-auto w-full h-1 text-left bg-gray-300 rounded-lg">
              <PercentageBar color={color} maxHeight="0.5rem" width={`${enrollmentProgress}%`} />
            </div>
          )}
        </div>
      </div>
      {isCollection ? (
        <>
          <div className="mx-1 h-1 bg-white rounded-b-sm border-xs border-t-0 border-gray-400" />
          <div className="mx-2 h-1 bg-white rounded-b-sm border-xs border-t-0 border-gray-400" />
          <div className="mx-3 h-1 bg-white rounded-b-sm border-xs border-t-0 border-gray-400" />
          <div className="mx-4 h-1 bg-white rounded-b-sm border-xs border-t-0 border-gray-400" />
        </>
      ) : null}
    </div>
  );
}

/**
 * Standard card
 * @param {*}
 * @returns card
 */
function StandardCard({
  id,
  description,
  permalink,
  wwwPermalink,
  contentType,
  prefix,
  badge,
  badgeClassName,
  img,
  title,
  type,
  durationSeconds,
  ceuCount,
  instructorsInfo,
  author,
  level,
  enrollmentProgress,
  screenReaderHint,
  isComingSoon,
  isList,
  permalinkProps,
  cardProps,
  handleOnClick,
  cardClassName,
  userStore,
  children,
  gridShowDescription,
  fluid,
  addToPathOption,
  tags_info,
  experience_points_total,
}) {
  const isCollection = ['Generic Collection', 'Collection', 'Career Path'].includes(type);
  const color = isCompleted(enrollmentProgress) ? '#2F855A' : '#df057b';
  const propsToSend = {
    id,
    description,
    permalink,
    wwwPermalink,
    contentType,
    prefix,
    badge,
    badgeClassName,
    img,
    title,
    type,
    durationSeconds,
    ceuCount,
    instructorsInfo,
    author,
    level,
    enrollmentProgress,
    screenReaderHint,
    isComingSoon,
    isList,
    permalinkProps,
    cardProps,
    handleOnClick,
    cardClassName,
    userStore,
    children,
    isCollection,
    color,
    gridShowDescription,
    fluid,
    addToPathOption,
    tags_info,
    experience_points_total,
  };
  return isList ? <ListView {...propsToSend} /> : <GridView {...propsToSend} />;
}

/**
 * Highlight card, large width on desktop
 * @param {*}
 * @returns card
 */
function HighlightCard({
  userStore,
  permalinkProps,
  cardProps,
  handleOnClick,
  badge,
  badgeClassName,
  id,
  permalink,
  wwwPermalink,
  contentType,
  prefix,
  enrollmentProgress,
  durationSeconds,
  screenReaderHint,
  img,
  title,
  type,
  ceuCount,
  isComingSoon,
  addToPathOption,
}) {
  const color = isCompleted(enrollmentProgress) ? '#2F855A' : '#df057b';
  return (
    <div className="flex flex-col justify-between w-full min-h-[18rem] bg-gray-900 rounded-sm border-xs border-gray-900 xl-1664:flex-row">
      <div className="flex flex-col grow gap-y-6 justify-between xl-1664:flex-row xl-1664:gap-y-0">
        <AddLink {...permalinkProps} onClick={handleOnClick} className="shrink-0 hover:text-cyb-pink-500 content-card">
          <div className="relative">
            <img className="sm:object-left-top sm:h-40 xl-1664:w-auto xl-1664:h-72" src={img} alt="" width="429" height="289" />
            <ContentBadge badge={badge} className={badgeClassName} />
          </div>
          <div className="px-2 pt-3 xl-1664:hidden">
            <p className="mb-2 text-2xs font-semibold text-left text-gray-500 uppercase">{type}</p>
            {screenReaderHint && <span className="sr-only">{screenReaderHint} </span>}
            <h3 className="mb-8 max-h-15 text-sm font-bold text-left text-white line-clamp-3">{title}</h3>
          </div>
        </AddLink>
        <div className="xl-1664:flex xl-1664:flex-col xl-1664:grow xl-1664:justify-between xl-1664:px-4.2 xl-1664:pt-8 xl-1664:pb-2">
          <div className="hidden w-4/5 xl-1664:block">
            <p className="mb-2 text-2xs font-semibold text-left text-gray-500 uppercase">{type}</p>
            <h3 className="mb-8 max-h-15 text-lg font-bold leading-5 text-left text-white line-clamp-3">{title}</h3>
            <div className="overflow-hidden mx-auto w-full h-1 text-left bg-gray-600 rounded-lg">
              <PercentageBar color={color} maxHeight="0.5rem" width={`${enrollmentProgress}%`} />
            </div>
            <div className="hidden pt-12 xl-1664:block">
              <AddLink
                {...permalinkProps}
                onClick={handleOnClick}
                className="inline-block py-2.5 px-6 text-sm font-bold leading-5 text-center text-white hover:text-white bg-cyb-pink-500 hover:bg-pink-600 rounded-sm"
              >
                Continue Learning
              </AddLink>
            </div>
          </div>
          <div className="flex justify-between items-center px-2 pb-2 xl-1664:px-0 xl-1664:pt-8 xl-1664:pb-0">
            <Details durationSeconds={durationSeconds} ceuCount={ceuCount} isComingSoon={isComingSoon} className="text-gray-500" />
            <ContentCardActionsMenu
              isComingSoon={isComingSoon}
              content={{ id, permalink, wwwPermalink, type: contentType, title, duration_seconds: durationSeconds, prefix }}
              userStore={userStore}
              menuAbove={cardProps && cardProps.menuAbove}
              iconContainerClassName="text-white bg-gray-900 hover:bg-gray-700"
              addToPathOption={addToPathOption}
            />
          </div>
          <div className="block overflow-hidden mx-auto w-full h-1 text-left bg-gray-600 rounded-lg xl-1664:hidden">
            <PercentageBar color={color} maxHeight="0.5rem" width={`${enrollmentProgress}%`} />
          </div>
        </div>
      </div>
    </div>
  );
}

const ContentCard = inject(
  'userStore',
  'enrollmentStore'
)(
  observer(
    ({
      userStore,
      enrollmentStore,
      data,
      handleClick,
      screenReaderHint,
      isComingSoon,
      highlightedView,
      queryID,
      objectID,
      positionIdx,
      cardProps,
      className,
      children,
      isList,
      gridShowDescription,
      fluid,
      badgeClassName,
      addToPathOption,
    }) => {
      if (!data) {
        return null;
      }
      const {
        id,
        badge,
        thumbnail_url,
        content_type,
        permalink,
        wwwPermalink,
        title,
        duration_seconds,
        ceu_count,
        progress,
        prefix,
        instructors_info,
        author,
        level,
        description,
        tags_info,
        experience_points_total,
      } = data;
      const formattedPermalink = PermalinkUtil.formatInApp(permalink, content_type);
      const type = FormatUtil.formatContentType(content_type);
      const styles = StyleUtil.getTypeStyle(content_type);
      const typeBackgroundImage = styles.backgroundImage;
      const img = thumbnail_url ? `${thumbnail_url}?w=605&h=378` : typeBackgroundImage;

      const handleOnClick = () => {
        if (handleClick) {
          return handleClick();
        }
        if (objectID && positionIdx) {
          const userId = userStore.user.id;
          SearchUtil.searchInsightEvent(userId, queryID, objectID, positionIdx);
        }
        // fire event when user clicks on a card
        trackSnowplowEvent({
          category: 'ContentCard',
          action: `clicked`,
          value: objectID || 0, // ID of the content from contentful
          property: `Content card clicked`, // Description of the action
        });
        return null;
      };
      let enrollmentProgress = progress || 0;
      if (!progress && objectID) {
        enrollmentProgress = enrollmentStore.getEnrollmentProgressForContentDescriptionId(objectID);
      }

      // If 'handleClick' is passed in, don't want to have link props so that Addlink treats this as a button
      const permalinkProps = !handleClick && permalink ? { to: formattedPermalink } : {};
      const propsToSend = {
        id,
        cardClassName: className,
        permalink,
        wwwPermalink,
        description,
        contentType: content_type,
        prefix,
        userStore,
        permalinkProps,
        cardProps,
        handleOnClick,
        img,
        enrollmentProgress,
        title,
        type,
        screenReaderHint,
        badge,
        badgeClassName,
        durationSeconds: duration_seconds,
        ceuCount: ceu_count,
        instructorsInfo: instructors_info,
        author,
        level,
        isComingSoon,
        children,
        isList,
        gridShowDescription,
        fluid,
        addToPathOption,
        tags_info,
        experience_points_total,
      };
      return highlightedView ? <HighlightCard {...propsToSend} /> : <StandardCard {...propsToSend} />;
    }
  )
);

export default ContentCard;
