import React from 'react';
import { useClab } from '../../../providers/ClabProvider';
import useLearnerLab from '../../Clab/hooks/useLearnerLab';
import ResourcesTab from '../../Clab/components/NewLearner/resources-tab';
import ClipboardForm from '../../Clab/components/NewLearner/clipboard-form';

export default function ClabMachinesTab() {
  const { resources } = useClab();
  const { connect, disconnect, activeResource } = useLearnerLab();

  return (
    <div className="px-6 w-full h-[calc(100vh-140px)]">
      <div className="flex flex-col justify-between h-full">
        <div className="overflow-y-scroll flex-1 pt-6 no-scrollbar">
          <ResourcesTab resources={resources} activeResource={activeResource} connect={connect} disconnect={disconnect} />
        </div>
        <ClipboardForm />
      </div>
    </div>
  );
}
