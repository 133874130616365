import React, { Component, useState } from 'react';
import Header from '../Header/Header';
import Icon from '../Icon/Icon';
import Button from '../Button/Button';
import Segment from '../Segment/Segment';
import FeedbackModal from '../Feedback/FeedbackModal';
import FeedbackButton from '../Feedback/FeedbackButton';
import Card from '../Card/Card';
import VimeoPlayer from '../VimeoPlayer/VimeoPlayer';

function LiveEmbed({ id, data, onPlay }) {
  const [showVideo, setShowVideo] = useState(false);
  const placeholderImage = data.image || data.seriesImage || data.seriesBackgroundImage;

  const startSession = () => {
    setShowVideo(true);
    onPlay();
  };

  if (!showVideo) {
    return (
      <button className="relative" aria-label="Play live session" onClick={startSession}>
        <img src={placeholderImage} alt="" />
        <Icon name="play" className="absolute top-[43%] left-[43%] w-12 h-12 text-white lg:w-18 lg:h-18 xl:w-28 xl:h-28" />
      </button>
    );
  }
  return (
    <div className="min-h-[12rem] md:min-h-[24rem] lg:min-h-[32rem] xl:min-h-[56rem]">
      <VimeoPlayer autoplay id={id} responsive videoId={id} />
    </div>
  );
}

class SessionContent extends Component {
  displayFeedbackBtn = ({ ratingsAverage, ratingsCount, session }) => {
    if (session.status === 'upcoming') {
      return null;
    }
    return (
      <div className="feedback-button-container">
        <FeedbackButton type="Cybrary Live Session" title={session.title} average={ratingsAverage} count={ratingsCount} textColor="text-gray-600" hasProgress />
      </div>
    );
  };

  render() {
    const { session, fireLaunchActivity, registered, registerForSession, ratingsAverage, ratingsCount } = this.props;

    let vimeoId;
    let liveTimeString = '';
    switch (session.status) {
      case 'active':
        liveTimeString = 'live now';
        vimeoId = session.vimeoStreamId;
        break;

      case 'upcoming':
        liveTimeString = `will go live ${session.localStartDate.fromNow()}`;
        vimeoId = session.vimeoStreamId;
        break;

      case 'archived':
      default:
        liveTimeString = `went live ${session.localStartDate.fromNow()}`;
        vimeoId = session.vimeoVideoId || session.vimeoStreamId;
        break;
    }
    return (
      <Card>
        <div>
          {session.status !== 'upcoming' ? (
            <LiveEmbed id={vimeoId} onPlay={fireLaunchActivity} data={session} />
          ) : (
            <Segment className="py-24 text-white bg-black">
              <Header as="h2">
                <Icon name="clock" className="inline-block mr-2 mb-1 w-6 h-6" />
                This session will go live on {session.localStartDate.format('dddd, MMMM Do YYYY [at] h:mm a')}.
              </Header>
              {!registered ? (
                <Button onClick={registerForSession} className="mb-4 w-48">
                  Register Now
                </Button>
              ) : (
                <Button color="gray" disabled className="mb-4 w-48">
                  You Are Registered
                </Button>
              )}
              {session.seriesCalendarUrl ? (
                <Button color="red" className="mb-4 w-64" onClick={() => window.open(session.seriesCalendarUrl, '_blank')}>
                  <Icon name="calendar" className="inline-block mr-2 mb-1 w-4 h-4" />
                  Subscribe to Calendar
                </Button>
              ) : null}
            </Segment>
          )}
        </div>
        <div>
          {session.status !== 'active' ? (
            <div className="title-card-container">
              <div style={{ flex: '1' }}>
                <Header as="h1" className="mb-0">
                  {session.title}
                </Header>
                <p className="mb-0 text-sm text-gray-600">{liveTimeString}</p>
              </div>
              {this.displayFeedbackBtn({ ratingsAverage, ratingsCount, session })}
            </div>
          ) : (
            <Segment className="border-none title-card-container">
              <div style={{ flex: '1' }}>
                <div
                  style={{
                    maxWidth: '550px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <div className="live-circle-blink live-circle" />
                  <div style={{ flex: '1' }}>
                    <Header as="h3" className="m-0">
                      {session.title}
                    </Header>
                  </div>
                </div>

                <div
                  style={{
                    color: 'lightgrey',
                    fontSize: '14px',
                    fontWeight: '400',
                  }}
                >
                  {liveTimeString}
                </div>
              </div>
              {this.displayFeedbackBtn({ ratingsAverage, ratingsCount, session })}
            </Segment>
          )}
        </div>
        <FeedbackModal />
      </Card>
    );
  }
}

export default SessionContent;
