import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useNavigate, useParams } from 'react-router-dom';
import Bugsnag from '@bugsnag/js';
import Loading from '../../components/Loading/Loading';
import CybAssessmentProvider from '../../providers/CybAssessmentProvider';
import ClabProvider from '../../providers/ClabProvider';
import ImmersiveTasksProvider from '../../providers/ImmersiveTasksProvider';
import { useImmersive } from '../../providers/ImmersiveProvider';
import ImmersiveRenderer from '../../components/Immersive/ImmersiveRenderer';

/**
 * This page controls the immersive when we have an enrollment with curriculum items, as well as an Item Activity, aka a stand-alone activity that acts as it's own enrollment,
 * i.e. a Virtual Lab added directly to the curricula.
 * We load the enrollment, then the item activity, followed by the activity, finally displaying the Immersive UI.
 * Example url: /immersive/:enrollmentId/item/enrollment/activity/:itemActivityId
 */
const ImmersiveEnrollmentWithItemActivity = inject(
  'userStore',
  'authStore',
  'commonStore',
  'goalsStore'
)(
  observer(({ userStore, commonStore, authStore, onCompletePage = false }) => {
    const navigate = useNavigate();
    const params = useParams();
    const { enrollment, loadingEnrollment, loadingActivity, loadingItem, loadEnrollment, loadItemActivity, item, activity, loadActivity, outline, menu, reset } = useImmersive();

    const { enrollmentId, itemActivityId } = params;

    useEffect(() => {
      // Remove the app's main navigation by letting the common store know we are in the immersive ux
      commonStore.setIsInImmersive(true);
      return () => {
        reset();
        commonStore.setIsInImmersive(false);
      };
    }, []);

    useEffect(() => {
      if (!enrollmentId) {
        return;
      }

      loadEnrollment(1 * enrollmentId);
    }, [enrollmentId, itemActivityId, enrollment]);

    useEffect(() => {
      if (!enrollment || !itemActivityId || loadingItem) {
        return;
      }

      loadItemActivity(1 * itemActivityId);
    }, [enrollment, itemActivityId, loadingItem]);

    useEffect(() => {
      if (!enrollment || !item || !itemActivityId || loadingItem || loadingActivity) {
        return;
      }

      // Help make debugging easier with some meta data about this enrollment
      Bugsnag.addOnError((event) => {
        event.addMetadata('Immersive Activity', {
          enrollment,
          item,
          itemActivityId,
        });
      });

      loadActivity(1 * itemActivityId);
    }, [itemActivityId, item, enrollment, loadingActivity, loadingItem]);

    if (loadingActivity || loadingEnrollment || loadingItem || !outline || !menu || !activity) {
      return <Loading wrapperClassName="py-16" />;
    }

    return (
      <ClabProvider>
        <CybAssessmentProvider>
          <ImmersiveTasksProvider>
            <ImmersiveRenderer
              userStore={userStore}
              commonStore={commonStore}
              authStore={authStore}
              navigate={navigate}
              enrollment={enrollment}
              item={item}
              activity={activity}
              outline={outline}
              menu={menu}
              onCompletePage={onCompletePage}
            />
          </ImmersiveTasksProvider>
        </CybAssessmentProvider>
      </ClabProvider>
    );
  })
);

export default ImmersiveEnrollmentWithItemActivity;
