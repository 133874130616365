import React, { useState, useEffect } from 'react';
import FormatUtil from '../../utils/formatUtil';
import Icon from '../Icon/Icon';
import Collapsible from '../Collapsible/Collapsible';
import Button from '../Button/Button';
import RadioButtons from '../FormFields/RadioButtons';
import Divider from '../Divider/Divider';

function SettingsRadios({ subscriptionsType, setSubscriptionsType }) {
  const subscriptionOptions = [
    {
      value: 'all',
      text: 'Subscribe to All (notify me whenever new content is added)',
    },
    {
      value: 'some',
      text: 'Subscribe to Specific (notify me on new content for specific techniques)',
    },
    {
      value: 'none',
      text: 'Subscribe to None',
    },
  ];

  return (
    <RadioButtons
      wrapperClassName="grid gap-1 p-4"
      value={subscriptionsType}
      name="subscription-settings"
      labelClassName="text-sm leading-6 font-normal"
      onChange={(e, data) => setSubscriptionsType(data.value)}
      options={subscriptionOptions}
      omitLabelMarginRight
      inputClassName="mt-0"
    />
  );
}

function SubscriptionCounter({ savedSubscriptionsType, techniqueSubsCount }) {
  return (
    <>
      You are subscribed to{' '}
      <span className="flex justify-center items-center">
        <span className="mx-2 text-2xl font-black">
          {savedSubscriptionsType === 'all' ? 'All ' : ''}
          {techniqueSubsCount}
        </span>
        {FormatUtil.pluralize(techniqueSubsCount, 'technique')}
      </span>
    </>
  );
}

function SettingsSaveButton({ settingsOpen, onButtonClick }) {
  return (
    <Button className="self-center w-full" color={settingsOpen ? 'pink' : 'gray'} onClick={onButtonClick}>
      {settingsOpen ? 'Save' : 'Edit'} Subscription Settings
    </Button>
  );
}

function SubscriptionSettings({
  subscriptionsType,
  setSubscriptionsType,
  savedTechniqueSubscriptions,
  settingsOpen,
  setSettingsOpen,
  showSubscribeBoxes,
  saveSubscriptions,
  savedSubscriptionsType,
  techniquesCount,
  windowWidth,
  forceStackSections,
}) {
  const [radiosOpen, setRadiosOpen] = useState(true);

  useEffect(() => {
    if (subscriptionsType === 'some') {
      setRadiosOpen(false);
    }
  }, [subscriptionsType]);

  const onButtonClick = () => {
    // If settings are open, save the settings changes
    if (settingsOpen) {
      saveSubscriptions(subscriptionsType, () => setSettingsOpen(false));
    } else {
      setSettingsOpen(true);
    }
  };
  const shouldStackSections = windowWidth <= 1420;
  const techniqueSubsCount = savedSubscriptionsType === 'all' ? techniquesCount : savedTechniqueSubscriptions.length;

  // Stacked layout -- For less than 1420px or when side details is open and space is compressed
  if (shouldStackSections || forceStackSections) {
    return (
      <div className="gap-4 items-stretch pr-2 w-full text-sm font-semibold bg-white sm:pr-0 sm:w-116 sm:rounded-lg box-shadow">
        {showSubscribeBoxes && (
          <button className="flex justify-center p-3 w-full" onClick={() => setRadiosOpen(!radiosOpen)} aria-label={`${radiosOpen ? 'Collapse' : 'Expand'} subscribe options`}>
            <Icon className="w-4 h-4 text-gray-600" name={radiosOpen ? 'chevron-down' : 'chevron-up'} />
          </button>
        )}
        <div className={`flex items-center p-4 ${showSubscribeBoxes ? 'pt-0' : ''} justify-center shrink-0`}>
          <SubscriptionCounter savedSubscriptionsType={savedSubscriptionsType} techniqueSubsCount={techniqueSubsCount} />
        </div>
        {settingsOpen && (
          <>
            <Divider marginTop="mt-0" marginBottom="mb-4" />
            <Collapsible open={!showSubscribeBoxes || radiosOpen}>
              <SettingsRadios setSubscriptionsType={setSubscriptionsType} subscriptionsType={subscriptionsType} />
            </Collapsible>
          </>
        )}
        <div className="shrink-0 self-center p-4 pt-0">
          <SettingsSaveButton settingsOpen={settingsOpen} onButtonClick={onButtonClick} />
        </div>
      </div>
    );
  }

  return (
    <div className="flex gap-0 items-center pr-2 text-sm font-semibold bg-white sm:pr-0 sm:rounded-lg box-shadow">
      <div className={`flex items-center p-4 ${showSubscribeBoxes ? 'pt-4' : ''} justify-start shrink-0`}>
        <SubscriptionCounter savedSubscriptionsType={savedSubscriptionsType} techniqueSubsCount={techniqueSubsCount} />
      </div>
      {settingsOpen && (
        <>
          <Divider className="self-stretch my-4" vertical />
          <SettingsRadios setSubscriptionsType={setSubscriptionsType} subscriptionsType={subscriptionsType} />
          <Divider className="self-stretch my-4" vertical />
        </>
      )}
      <div className="shrink-0 self-center px-4">
        <SettingsSaveButton settingsOpen={settingsOpen} onButtonClick={onButtonClick} />
      </div>
    </div>
  );
}

export default SubscriptionSettings;
