import React from 'react';
import { twMerge } from 'tailwind-merge';
import { Tab } from '@headlessui/react';

/**
 * @param {string} containerClasses - classes to be applied to the container div
 * @param {string} tabListClasses - classes to be applied to the tab list
 * @param {array} tabs - array of objects with the tab id and text
 * @param {string} selected - the id of the selected tab
 * @param {function} onChange - function to be called when the tab is changed
 * @param {number} defaultIndex - the index of the default tab on load (default is 0) BEST USED FOR QUERY PARAMS
 * @returns Headless UI Tabs component
 */
export default function Tabs({ containerClasses, tabListClasses, tabs, selected, onChange, defaultIndex = 0 }) {
  if (!tabs || !tabs.length) {
    return null;
  }

  return (
    <div className={containerClasses || ''}>
      <Tab.Group
        defaultIndex={defaultIndex}
        onChange={(idx) => {
          onChange(tabs[idx]);
        }}
      >
        <Tab.List id="tab-list" className={twMerge('flex gap-x-8 items-start text-sm border-b border-gray-400', tabListClasses)}>
          {tabs.map((tab) => {
            const isSelected = tab.id === selected;
            return (
              <Tab key={tab.id} className={`py-3 text-sm ${isSelected ? 'text-black font-semibold border-b-2 border-cyb-pink-500' : 'text-gray-600'}`}>
                {tab.text}
              </Tab>
            );
          })}
        </Tab.List>
      </Tab.Group>
    </div>
  );
}
