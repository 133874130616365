/* eslint-disable no-console */
import React, { useEffect, useRef, useState } from 'react';
import { throttle } from 'lodash';
import GuacConnection from '../../services/GuacConnection';
import getConnected from '../../utils/resourceUtil';

// We are going to create some custom events to control the remote copy/paste process
const subscribeToRemoteCopy = (listener) => {
  window.addEventListener('copyToRemote', listener);
};
const unsubscribeFromRemoteCopy = (listener) => {
  window.addEventListener('copyToRemote', listener);
};

const guac = {};

function Terminal({ resource, index, totalIps, errorHandler }) {
  // Hide the terminal on load to resize it
  const [show, setShow] = useState(false);
  const containerRef = useRef(null);
  const terminalRef = useRef(null);
  const keyboardRef = useRef(null);
  const { ip, activeTab } = resource;

  const jwt = window.localStorage.getItem('clabjwt');

  useEffect(() => {
    if (jwt) {
      guac[index] = new GuacConnection(`${process.env.REACT_APP_CLAB_API_URL?.replace('http', 'ws')}/tunnel-ws`, {});
    }
  }, [jwt]);

  useEffect(() => {
    const display = guac[index].client.getDisplay();
    const hasTerminalElement = terminalRef && terminalRef.current;
    const containerElement = containerRef.current;

    if (display && hasTerminalElement && ip && containerElement && jwt) {
      const width = Math.round(containerElement.clientWidth);
      const height = Math.round(containerElement.clientHeight);

      const connectStringWithDimensions = `${resource['connect-string']}&width=${width}&height=${height}&token=${jwt}`;
      guac[index].connect(connectStringWithDimensions, ip, errorHandler);
      terminalRef.current.appendChild(display.getElement());

      guac[index].startKeyboardListener();
      guac[index].startMouseListener();
    }
    return () => {
      console.log(new Date(), 'CALLING DISCONNECT FROM WITHIN THE TERMINAL', index, ip);
      return guac[index].client.disconnect();
    };
  }, [ip, jwt]);

  const remoteCopyListener = (e) => {
    const { detail } = e || {};
    const { str } = detail || {};
    if (index && str && guac && guac[index]) {
      guac[index].sendToClipboard(str);
    }
  };

  /**
   * Resize onload and when more terminals start
   */
  useEffect(() => {
    const containerElement = containerRef.current;
    if (containerElement) {
      setTimeout(() => {
        guac[index].resizeTerminal(containerElement);
        setShow(true);
      }, 500);
    }
  }, []);

  useEffect(() => {
    if (terminalRef.current) {
      window.addEventListener(
        'resize',
        throttle(() => guac[index].resizeTerminal(containerRef.current), 3000, { leading: false, trailing: true })
      );
    }
    return () => window.removeEventListener('resize', guac[index].resizeTerminal);
  }, []);

  // Resize when the actual element changes size, throttle should prevent this from occuring more than once on window resize
  useEffect(() => {
    if (!terminalRef?.current) {
      return () => null;
    }
    const observer = new ResizeObserver(() => {
      throttle(() => guac[index].resizeTerminal(containerRef.current), 500, { leading: false, trailing: true })();
    });

    observer.observe(terminalRef.current);
    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    if (resource.activeTab && resource.connected) {
      subscribeToRemoteCopy(remoteCopyListener);
      return () => unsubscribeFromRemoteCopy(remoteCopyListener);
    }
    return () => null;
  }, []);

  return (
    <div className={`absolute top-0 left-0 h-full w-full ${activeTab || totalIps === 1 ? '' : 'hidden'}`}>
      <div
        className="relative w-full h-full min-h-[500px] md:min-h-[calc(100vh-64px)] md:max-h-[calc(100vh-64px)] clab-container"
        ref={containerRef}
        onClick={() => keyboardRef.current?.focus()}
        role="application"
      >
        <input id={ip} ref={keyboardRef} value="" onChange={(e) => console.log(e)} className="absolute top-10 left-10 bg-gray-800 border-0  outline-none focus:outline-none" />
        <div className={`relative z-50 h-full w-full ${ip && show ? 'visible' : 'invisible'}`} ref={terminalRef} />
      </div>
    </div>
  );
}

export default function TerminalList({ activeLab, resources, errorHandler }) {
  const connectedResources = getConnected(resources || activeLab?.resources);
  const connectedTotal = getConnected(resources || activeLab?.resources).length;
  return (
    <>
      {!connectedTotal ? <p className="flex justify-center items-center h-full text-white">Connect to a resource to get started!</p> : null}

      {connectedResources.map((rscr) => (
        <Terminal key={rscr.ip} resource={rscr} index={rscr.ip} totalIps={connectedTotal} errorHandler={errorHandler} />
      ))}
    </>
  );
}
