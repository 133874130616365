const GOAL_PROMOS = {
  launchMyCareer: {
    isEnabled: true,
    title: 'Enroll In a Career Path',
    body: 'Take the next step in your cybersecurity career and sign up for Cybrary Insider Pro to unlock access to all of our career paths.',
  },
  getCertification: {
    isEnabled: true,
    title: 'Confidently Pass The Exam',
    body: 'Sign up for Cybrary Insider Pro and get unlimited access to practice exams and labs.',
    footer: 'Practice exams can help you improve your test scores by over 25%.',
  },
  upskillPractice: {
    isEnabled: true,
    title: 'Get Your Dream Role',
    body: 'Sign up for Cybrary Insider Pro to master the hands-on skills that security leaders are hiring for.',
  },
};

export default GOAL_PROMOS;
