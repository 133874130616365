import React from 'react';
import CountUp from 'react-countup';

function CircleProgress({
  size = 100,
  strokeWidth = 12,
  strokeColor = 'text-cyb-pink-500',
  percentage,
  children,
  progressClasses,
  accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true },
}) {
  const radius = (size - strokeWidth) / 2;
  const viewBox = `0 0 ${size} ${size}`;
  const circumference = radius * Math.PI * 2;
  const offset = circumference - (circumference * percentage) / 100;
  const halfSize = size / 2;
  const wrapperClasses = progressClasses || 'flex relative justify-center items-center';
  return (
    <div className={wrapperClasses}>
      <svg viewBox={viewBox} width="100%" height="100%" {...accessibilityProps}>
        <circle className="text-gray-400 fill-current stroke-current" cx={halfSize} cy={halfSize} r={radius} strokeWidth={`${strokeWidth}px`} style={{ fill: 'none' }} />
        <circle
          className={`${strokeColor} stroke-current`}
          cx={halfSize}
          cy={halfSize}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
          transform={`rotate(-90 ${halfSize} ${halfSize})`}
          style={{
            strokeDasharray: circumference,
            strokeDashoffset: offset,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            fill: 'none',
          }}
        />
      </svg>
      <div className="absolute">{children}</div>
    </div>
  );
}

function ValueDisplay({ displayValue, percentageValue, progressSuffix, omitValueDisplay, preventAnimation }) {
  if (omitValueDisplay) {
    return null;
  }
  const endVal = displayValue ? 1 * displayValue : 1 * percentageValue;
  return (
    <span className="text-gray-600">
      <CountUp
        className="text-2xl font-bold leading-4 text-center progress"
        style={{ maxWidth: '4rem' }}
        separator=","
        start={preventAnimation ? endVal : 0}
        end={endVal}
        suffix={progressSuffix || '%'}
        duration={1}
      />
    </span>
  );
}

function CircularProgress({ percentageValue, displayValue, progressSuffix, children, strokeWidth, strokeColor, classes, progressClasses, omitValueDisplay, customStyles }) {
  if (Number.isNaN(Number(percentageValue))) {
    return null;
  }

  const wrapperClasses = `${classes || 'w-52'}`;
  let wrapperStyles = { maxWidth: '13rem' };
  if (customStyles) {
    wrapperStyles = { ...wrapperStyles, ...customStyles };
  }
  return (
    <div className={wrapperClasses} style={wrapperStyles}>
      <CircleProgress strokeWidth={strokeWidth} strokeColor={strokeColor} percentage={1 * percentageValue} progressClasses={progressClasses}>
        {(
          <>
            {children}
            <span className="sr-only">{percentageValue}% Complete</span>
          </>
        ) || <ValueDisplay displayValue={displayValue} percentageValue={percentageValue} progressSuffix={progressSuffix} omitValueDisplay={omitValueDisplay} />}
      </CircleProgress>
    </div>
  );
}

export default CircularProgress;
