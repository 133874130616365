import React from 'react';

export default function Road({ classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';
  return (
    <svg className={className} {...accessibilityProps} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 122.56" stroke="currentColor" fill="currentColor">
      <g>
        <path
          className="st0"
          style={{ fillRule: 'evenodd', clipRule: 'evenodd' }}
          d="M0,122.56L38.12,0h46l38.75,122.56H0L0,122.56z M71.1,112.9H51.78l1.57-23.31h16.17L71.1,112.9L71.1,112.9z 
          M69,74.35H53.88l0.52-23.32h14.08L69,74.35L69,74.35z M67.95,35.65H54.92l0.52-23.31h11.46L67.95,35.65L67.95,35.65z"
        />
      </g>
    </svg>
  );
}
