import React from 'react';
import Carousel from '../../components/Carousel/Carousel';
import ContentCard from '../../components/Card/ContentCard';
import ContentTransformer from '../../transformers/contentTransformer';

function FreeAssessment() {
  const dummyObj = [
    {
      id: 1,
      title: 'title 1',
      description: 'description 1',
    },
    {
      id: 2,
      title: 'title 2',
      description: 'description 2',
    },
    {
      id: 3,
      title: 'title 3',
      description: 'description 3',
    },
    {
      id: 4,
      title: 'title 4',
      description: 'description 4',
    },
    {
      id: 5,
      title: 'title 5',
      description: 'description 5',
    },
    {
      id: 6,
      title: 'title 6',
      description: 'description 6',
    },
  ];
  const DisplayCards = dummyObj?.map((newCourse) => {
    const { id } = newCourse;
    const prepped = ContentTransformer.formatDataSources(newCourse);

    return (
      <div key={id} className="flex h-full bg-white">
        <ContentCard key={id} data={prepped} className="w-80 md:w-60" cardProps={{ menuAbove: true }} />
      </div>
    );
  });
  return (
    <div className="mx-16">
      <h1 className="mt-8 mb-18 text-5xl font-black"> Claim Your Assessment</h1>
      <div id="assessments-container">
        <Carousel title="Assessments" items={DisplayCards} />
      </div>
    </div>
  );
}
export default FreeAssessment;
