import React from 'react';
import { DEFAULT_COLOR } from '../../utils/constants/status';

export default function StatusBadge({ text, colors }) {
  const { bg, ball, text: textColor, border } = colors || DEFAULT_COLOR;
  return (
    <div className={`flex rounded-full items-center ${bg} py-2 px-2 ${border}`}>
      <div className={`mr-1 h-3 w-3 rounded-full ${ball}`} />
      <p className={`text-xs font-medium capitalize ${textColor}`}>{text || 'Pending'}</p>
    </div>
  );
}
