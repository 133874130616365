import React from 'react';

export default function TrendingDown({ classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 512 640" x="0px" y="0px" strokeWidth="1.5" stroke="currentColor" {...accessibilityProps}>
      <g>
        <path d="M487.69,213.56a24,24,0,0,0-24,24v73.26L322.62,169.75a24,24,0,0,0-33.94,0l-102,102L41.28,126.26A24,24,0,0,0,7.34,160.2L169.75,322.62a24,24,0,0,0,33.94,0l102-102,124.1,124.11H356.48a24,24,0,0,0,0,48H487.69c.75,0,1.49,0,2.22-.11H490c.73-.07,1.44-.18,2.15-.31l.14,0a21.41,21.41,0,0,0,2.1-.51l.17-.05c.68-.2,1.34-.43,2-.69l.23-.09c.64-.27,1.26-.56,1.87-.87L499,390q.9-.49,1.77-1l.26-.16c.6-.4,1.18-.83,1.74-1.28l.16-.13c.61-.5,1.2-1,1.77-1.6h0c.56-.56,1.09-1.15,1.6-1.77l.12-.16c.45-.56.88-1.14,1.28-1.74.06-.08.11-.17.17-.26.37-.57.72-1.16,1-1.77.05-.09.1-.18.14-.27.32-.61.61-1.23.87-1.87l.09-.24c.26-.65.5-1.31.7-2,0-.05,0-.11.05-.17.2-.69.37-1.38.51-2.09l0-.14c.13-.71.24-1.43.31-2.16a.49.49,0,0,1,0-.12c.06-.73.1-1.46.1-2.21h0V237.56A24,24,0,0,0,487.69,213.56Z" />
      </g>
    </svg>
  );
}
