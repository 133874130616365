import React, { useEffect, useState } from 'react';
import { Fade } from 'react-awesome-reveal';
import { useCustomPathsContext } from '../../providers/CustomPathsProvider';
import Loading from '../../components/Loading/Loading';
import Container from '../../components/Container/Container';
import AddLink from '../../components/AddLink/AddLink';
import SearchInput from '../../components/Search/SearchInput';
import CustomPathsEnrollmentCta from '../../components/CustomPaths/CustomPathsEnrollmentCta';

function PathRows({ paths }) {
  if (paths.length === 0) return <p> No Paths Found</p>;
  return (
    <div id="path-rows-container" className="overflow-scroll max-h-screen border-t-1 border-gray-500">
      {paths.map((path) => {
        return <PathRow key={`${path.id}-path-row`} path={path} />;
      })}
    </div>
  );
}
function PathRow({ path }) {
  const { id, name, description, team_id } = path;
  return (
    <div id={`path-row-${id}-container`} className="flex flex-col justify-between pb-4 border-b-1 border-b-gray-500 md:flex-row">
      <div id={`path-row-${id}-text-container`} className="md:max-w-[75%] lg:max-w-[65%]">
        <h2 className="my-4 text-lg font-bold">{name}</h2>
        <p className="text-sm font-normal text-gray-600">{description}</p>
      </div>
      <div
        id={`path-row-${id}-cta-container`}
        className="flex flex-row gap-[10px] justify-start items-center mt-8 md:flex-col md:justify-center md:items-start md:mt-0 lg:flex-row lg:items-center"
      >
        <AddLink to={`/custom-paths/${team_id}/${id}`}>
          <p className="text-xs text-cyb-pink-500 hover:text-gray-600 underline hover:cursor-pointer">View Details</p>
        </AddLink>
        <CustomPathsEnrollmentCta item={path} />
      </div>
    </div>
  );
}
function CustomPathsViewAll() {
  const { pathsData, isLoading, fetchEnrolledCustomPaths } = useCustomPathsContext();
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchEnrolledCustomPaths();
  }, []);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = pathsData.filter((item) => {
    const itemName = item.name.toLowerCase();
    const searchTermLower = searchTerm.toLowerCase();
    return itemName.includes(searchTermLower);
  });

  return (
    <Container size="md" className="">
      <Fade triggerOnce cascade>
        <div id="sticky-container" className="sticky">
          <h1 className="my-8 text-5xl font-black">Custom Paths</h1>
          <div id="search-input-container" className="mb-8 sm:w-full md:w-1/2 lg:w-2/5">
            <SearchInput placeholder="Filter the list of paths" ariaLabel="Filter your paths by name" onChange={handleSearch} />
          </div>
        </div>
      </Fade>

      {isLoading ? (
        <Loading />
      ) : (
        <Fade triggerOnce>
          <PathRows paths={filteredData} />
        </Fade>
      )}
    </Container>
  );
}
export default CustomPathsViewAll;
