import React, { useCallback, useMemo } from 'react';
import WidgetContainer from '../Container/WidgetContainer';
import Header from '../Header/Header';
import DataTable from '../AdminTable/AdminTable';
import { formatTableColumns } from '../Baseline/shared';
import { useABM } from '../../providers/ABMProvider';
import LockedContainer from '../Container/LockedContainer';
import LoadingText from '../Loading/LoadingText';

const TABLE_DATA = {
  columns: [
    {
      key: 'assessment_path_id',
      type: 'string',
      showCol: false,
      sortKey: 'assessment_path_id',
    },
    {
      key: 'assessment_path_name',
      type: 'string',
      showCol: true,
      display: 'Assessment Path',
      sortKey: 'assessment_path_name',
    },
    {
      key: 'user_score',
      type: 'string',
      showCol: true,
      display: 'Your Score',
      sortKey: 'user_score',
    },
    {
      key: 'company_score',
      type: 'string',
      showCol: true,
      display: 'Company Score',
      sortKey: 'company_score',
    },
    {
      key: 'community_score',
      type: 'string',
      showCol: true,
      display: 'Community Score',
      sortKey: 'community_score',
    },
  ],
  tableData: [
    [
      {
        value: 70852,
        type: 'string',
      },
      {
        value: '-',
        type: 'string',
      },
      {
        value: '0',
        type: 'string',
      },
      {
        value: '0',
        type: 'string',
      },
      {
        value: '0',
        type: 'string',
      },
    ],
  ],
};

/**
 * Company Skills Tracker Widget for ABM Page
 * @returns {JSX} - The Company Skills Tracker Widget
 */
function CompanySkillsTrackerWidget() {
  const { isABMTeamLoading, isVerified, dashboardData } = useABM();
  const { company_skills } = dashboardData || {};

  const tableData = useMemo(() => {
    const data = {
      columns: TABLE_DATA.columns,
      tableData: isABMTeamLoading ? Array(4).fill(TABLE_DATA.tableData[0]) : [],
    };
    if (company_skills) {
      data.tableData = company_skills.map(({ assessment_path_id, assessment_path_name, company_score, community_score, user_score }) => {
        return [
          {
            value: assessment_path_id,
            type: 'string',
          },
          {
            value: assessment_path_name,
            type: 'string',
          },
          {
            value: user_score || 'N/A',
            type: 'string',
          },
          {
            value: company_score,
            type: 'string',
          },
          {
            value: community_score,
            type: 'string',
          },
        ];
      });
    }
    return data;
  }, [company_skills]);

  const renderColumn = useCallback(
    (row, _fullRow, _headings, column) => {
      if (isABMTeamLoading) {
        return <LoadingText wrapperClassName="w-full" />;
      }
      if (row.value !== undefined && column.showCol && ['user_score', 'company_score', 'community_score'].includes(column.key)) {
        const percentage = Math.round(parseFloat(row.value * 100));

        return percentage ? `${percentage}%` : 'N/A';
      }

      return row.value;
    },
    [isABMTeamLoading]
  );

  const formatColumns = useMemo(
    () => formatTableColumns(tableData?.columns, ['user_score', 'assessment_path_name', 'company_score', 'community_score'], renderColumn),
    [renderColumn]
  );

  return (
    <LockedContainer msg="Verify your company email to unlock" isLocked={!isVerified && !isABMTeamLoading} isFullWidth>
      <WidgetContainer>
        <Header as="h2" className={isABMTeamLoading ? 'mb-0' : 'mb-4'}>
          Company Skills Tracker
        </Header>
        <div className="flex flex-col">
          <DataTable
            wrapperClassName="m-0 w-full"
            colClasses="whitespace-nowrap"
            headings={tableData.columns}
            data={tableData.tableData}
            formatColumns={formatColumns}
            noResultsMsg={<p className="p-4">No data to display during this time period</p>}
          />
        </div>
        {/* Error State? */}
      </WidgetContainer>
    </LockedContainer>
  );
}

export default CompanySkillsTrackerWidget;
