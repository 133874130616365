import React from 'react';
import Icon from '../../Icon/Icon';

// Trophy icon with rounded a gradient background
function GradientTrophyIcon() {
  return (
    <div className="flex justify-center mx-auto w-18 bg-gradient-to-br from-[#5E2C90] to-cyb-pink-500 rounded-full ">
      <Icon name="trophy" className="p-2 my-2 w-14 h-14 text-white fill-current" />
    </div>
  );
}

export default GradientTrophyIcon;
