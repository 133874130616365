import React from 'react';
import Divider from '../Divider/Divider';

export default function Step({ step, stepNumber, children, omitDivider }) {
  return (
    <>
      <div className="flex gap-x-4 items-start">
        <div className="flex shrink-0 justify-center items-center w-8 h-8 text-2xl font-black text-white bg-black rounded-full">
          <span className="mt-[1px]">{stepNumber}</span>
        </div>
        <div>
          <h3 className="mb-2 text-lg font-bold">{step}</h3>
          {children}
        </div>
      </div>
      {!omitDivider && <Divider marginTop="mt-8" marginBottom="mb-8" />}
    </>
  );
}
