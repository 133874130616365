import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Userpilot } from 'userpilot';
import AddLink from '../AddLink/AddLink';
import { trackSnowplowEvent } from '../../utils/snowplowUtil';
import If from '../If/If';
import { HELP_DESK_LINKS, USERPILOT_EVENTS } from '../../constants';
import GradientBanner from './GradientBanner';
import Icon from '../Icon/Icon';

function EDUUpgradeBanner({ className, isEduGovMilUser }) {
  const navigate = useNavigate();
  const handleAddEmailClick = () => {
    const targetUrl = `/settings/account?settings=1`;
    trackSnowplowEvent({ category: 'CIPUpgradeBanner', action: 'click', label: 'addEmailButtonEduMilGov', property: targetUrl });
    navigate(targetUrl);
    Userpilot.track(USERPILOT_EVENTS.CIP_BANNER_CLICKED_ADD_EMAIL);
  };

  const handleEduMilGovUpgradeClick = () => {
    const targetUrl = `/upgrade/checkout`;
    trackSnowplowEvent({ category: 'CIPUpgradeBanner', action: 'click', label: 'upgradeButtonEduMilGov', property: targetUrl });
    navigate(targetUrl);
    Userpilot.track(USERPILOT_EVENTS.CIP_BANNER_CLICKED_UPGRADE);
  };

  const ctaTitle = isEduGovMilUser ? 'Upgrade' : 'Add Your Email';
  const ctaOnClick = isEduGovMilUser ? handleEduMilGovUpgradeClick : handleAddEmailClick;

  return (
    <GradientBanner className={className}>
      <Icon name="unlock" className="hidden z-10 w-16 h-16 transition-all duration-300 group-hover:scale-105 group-hover:-rotate-12 fill-white lg:block" />

      <div className="grow px-1 lg:px-6 lg:text-lg text-md">
        <div>
          <div className="hidden md:block">Empower your future with exclusive savings for student, military and government employees!</div>
          <span className="group-hover:border-b-2">Save 50%</span> on your Cybrary Insider Pro subscription today!
        </div>
        <If condition={HELP_DESK_LINKS.EDU_MIL_GOV_UPGRADE}>
          <AddLink to={HELP_DESK_LINKS.EDU_MIL_GOV_UPGRADE} className="py-0 text-xs italic font-normal whitespace-nowrap hover:border-b-1">
            Learn more about our .edu, .mil, and .gov discount program &gt;
          </AddLink>
        </If>
      </div>
      <AddLink onClick={ctaOnClick} className="z-20 shrink-0 p-1 px-28 mx-auto mt-2 mb-1 ml-1 text-black bg-slate-100 group-hover:bg-slate-200 rounded-sm md:p-2 md:px-6 lg:mt-0">
        {ctaTitle}
      </AddLink>
    </GradientBanner>
  );
}

export default EDUUpgradeBanner;
