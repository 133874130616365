import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import CryptoMD5 from 'crypto-js/md5';
import queryString from 'query-string';
import moment from 'moment';
import withRouter from '../Router/withRouter';
import Title from '../Title/Title';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import ReportGraph from './ReportGraph';
import ReportTable from './ReportTable';
import Segment from '../Segment/Segment';
import ReportOptions from './ReportOptions';
import Download from '../../Icons/Download';
import Calendar from '../../Icons/Calendar';
import Container from '../Container/Container';
import GraphTableToggle from '../Analytics/GraphTableToggle';
import Tooltip from '../Tooltip/Tooltip';
import BetaLabel from '../Label/BetaLabel';
import Message from '../Message/Message';

const ReportsContainer = inject(
  'userStore',
  'reportsStore',
  'commonStore'
)(
  observer(
    class ReportsContainer extends Component {
      state = {
        urlHash: '',
        orgId: this.props.orgId,
        tabularGraph: false,
      };

      componentDidMount() {
        this.props.reportsStore.setDates('startDate', this.props.userStore.team.created_at);
        this.props.reportsStore.setQueryParams('startDate', this.props.userStore.team.created_at);
        const newState = {
          ...this.state,
          urlHash: CryptoMD5(window.location.href).toString(),
        };
        this.setState(newState);
        this.getReportData();
        this.props.reportsStore.getUserListData(this.props.reportsStore.reportItem.queryParams.filterAudId || this.props.teamGroupId);
        this.props.commonStore.setPageTitle(`${this.props.reportTitle || 'Analytics'} | Cybrary`);
      }

      componentDidUpdate() {
        // There are a few reports that have a high-level overview and then a more detailed view (content reports and assignment reports)
        // Handle filters accordingly and also reset store data as needed (since component will not unmount to do so)
        if (this.state.urlHash !== CryptoMD5(window.location.href).toString()) {
          const newState = {
            ...this.state,
            urlHash: CryptoMD5(window.location.href).toString(),
          };
          // eslint-disable-next-line react/no-did-update-set-state
          this.setState(newState);
          this.props.reportsStore.setViewChangeParamDefaults(this.props.reportParams);
          this.props.setQueryParams();
          this.getReportData();
          this.props.reportsStore.getUserListData(this.props.reportsStore.reportItem.queryParams.filterAudId || this.props.teamGroupId);
          this.props.commonStore.setPageTitle(`${this.props.reportTitle || 'Analytics'} | Cybrary`);
        }
      }

      componentWillUnmount() {
        this.props.reportsStore.setDefaultReportItem();
      }

      getBreadCrumbs() {
        const crumbs = [];
        const reportType = this.props.reportsStore.getReportType(this.props.reportId);
        const queryParams = queryString.parse(window.location.search);
        if (queryParams.goalId) {
          crumbs.push({
            href: `/enterprise/${this.props.orgId}/goals?goal=${queryParams.goalId}`,
            label: 'Goals',
          });
        } else {
          crumbs.push({
            href: `/enterprise/${this.props.orgId}/reporting/${reportType.toLowerCase() === 'content' ? 'content' : ''}`,
            label: `${reportType} Analytics`,
          });
        }

        if (!!this.props.reportId && this.props.reportId === 'assignment-details') {
          crumbs.push({
            href: `/enterprise/${this.props.orgId}/reporting/report/assignment/?contentDescriptionId=${
              this.props.reportsStore.reportItem.queryParams.contentId
            }&contentName=${encodeURIComponent(this.props.reportsStore.reportItem.queryParams.contentName)}`,
            label: 'Assignments',
          });
        }
        if (
          !!this.props.reportId &&
          this.props.reportId === 'content' &&
          this.props.reportsStore.reportItem.queryParams.contentType &&
          this.props.reportsStore.reportItem.queryParams['contentTypeIds[]']
        ) {
          const contentTypeIdParams = this.props.reportsStore.getContentTypeIdsParam(this.props.reportParams['contentTypeIds[]']);
          crumbs.push({
            href: `/enterprise/${this.props.orgId}/reporting/report/content-type/?contentType=${this.props.reportsStore.reportItem.queryParams.contentType}${contentTypeIdParams}`,
            label: this.props.reportsStore.reportItem.queryParams.contentType,
          });
        }
        if (!!this.props.reportId && this.props.reportId === 'curricula-content') {
          crumbs.push({
            href: `/enterprise/${this.props.orgId}/reporting/report/curricula-overview`,
            label: 'Paths Overview',
          });
        }
        crumbs.push({
          href: `${this.props.location.pathname}${this.props.location.search}`,
          label: this.props.reportTitle,
        });
        return crumbs;
      }

      setGraphView = (e, { value }) => {
        const newState = {
          ...this.state,
          tabularGraph: value,
        };
        this.setState(newState, () => {
          this.props.reportsStore.loadGraphData(this.props.graphId, this.props.teamGroupId, this.state.tabularGraph);
        });
      };

      // /**
      //  * Sets vars in store for service call query params
      //  */
      applyFilters = (bypassApplyCheck) => {
        if (!!bypassApplyCheck || this.props.reportsStore.reportItem.canFilter) {
          this.props.reportsStore.setQueryParams('resultsPg', 1);
          // If when searching by members, the user selected an actual user - use their user ID for filtering
          if (this.props.reportsStore.reportItem.userFilter.userId) {
            // If you are searching for a user, remove url query params for username/id (from drilling into reports)
            this.deleteQueryParamsUpdateURL(['userName', 'userId']);
            this.props.reportsStore.setQueryParams('userId', this.props.reportsStore.reportItem.userFilter.userId);
          } else {
            // no user ID selected
            this.props.reportsStore.deleteQueryParam('userId');
          }
          this.props.reportsStore.setQueryParams('startDate', moment(this.props.reportsStore.reportItem.startDate).format('YYYY-MM-DD'));
          this.props.reportsStore.setQueryParams('endDate', moment(this.props.reportsStore.reportItem.endDate).format('YYYY-MM-DD'));
          // If this is a group admin and the group filter was changed, set that to the preferred group for the user
          if (this.props.canViewReports === 'group' && this.props.reportsStore.reportItem.queryParams.filterAudId) {
            this.props.userStore.setPreferredGroup(this.state.orgId, this.props.reportsStore.reportItem.queryParams.filterAudId);
          }
          this.props.reportsStore.setActionFlag('canFilter', false);
          this.props.reportsStore.setActionFlag('canClear', true);
          this.getReportData();
        }
      };

      deleteQueryParamsUpdateURL = (paramsArr) => {
        const queryParams = queryString.parse(window.location.search);
        if (paramsArr.length) {
          paramsArr.forEach((param) => {
            this.props.reportsStore.deleteQueryParam(param);
            delete queryParams[param];
          });
          this.props.navigate({
            search: queryString.stringify(queryParams),
          });
        }
      };

      getReportData = () => {
        if (!this.checkReportDeprecated()) {
          if (this.props.showGraph) {
            this.props.reportsStore.loadGraphData(this.props.graphId, this.props.teamGroupId, this.state.tabularGraph);
          }
          if (this.props.showTable) {
            this.props.reportsStore.setQueryParams('sortCol', this.props.tableOptions.defaultSortCol || 'name');
            this.props.reportsStore.setQueryParams('sortDirection', this.props.tableOptions.defaultSortDirection || 'asc');
            this.props.reportsStore.loadTableData(this.props.reportId, this.props.teamGroupId);
          }
          this.props.reportsStore.setFilterChips(this.props.reportId, this.props.canViewReports);
        }
      };

      // /**
      //  * Format graph data
      //  * @param graphData, graphKey
      //  * @returns obj
      //  */
      formatGraphData = (graphData, graphKey) => {
        if (!graphData || !graphData.length) {
          return {};
        }
        const data = {};
        data[graphKey] = graphData.map((item) => ({ count: item.total || 0, period: item.period }));
        return data;
      };

      // /**
      //  * Get table data for component
      //  * @param data
      //  * @returns component
      //  */
      getTableData = (data) => {
        return (
          <ReportTable
            data={data.data}
            loading={data.loading}
            error={data.error}
            count={Math.ceil(data.data.recordsCount / this.props.reportsStore.reportItem.queryParams.resultsPerPg)}
            activePage={this.props.reportsStore.reportItem.queryParams.resultsPg}
            reportsStore={this.props.reportsStore}
            tableSort={this.tableSort}
            changePg={this.changePg}
            orgId={this.props.orgId}
            teamGroupId={this.props.teamGroupId}
            reportId={this.props.reportId}
            tableOptions={this.props.tableOptions}
            reportParams={this.props.reportsStore.reportItem.queryParams}
            userName={this.props.reportsStore.reportItem.queryParams.userName}
            filterApplied={!!Object.keys(this.props.reportsStore.reportItem.filterChips).length}
          />
        );
      };

      // /**
      //  * Sets sort column and direction vars in store for service call query params
      //  * @param heading
      //  */
      tableSort = (heading, isGraphTable) => {
        const params = isGraphTable ? this.props.reportsStore.reportItem.graph.tableParams : this.props.reportsStore.reportItem.queryParams;
        // If no sort key provided, don't do anything
        if (heading) {
          if (heading === params.sortCol) {
            this.props.reportsStore.setQueryParams('sortDirection', params.sortDirection === 'desc' ? 'asc' : 'desc', isGraphTable);
          } else {
            this.props.reportsStore.setQueryParams('sortCol', heading, isGraphTable);
            this.props.reportsStore.setQueryParams('sortDirection', 'desc', isGraphTable);
          }
          this.props.reportsStore.setQueryParams('resultsPg', 1, isGraphTable);
          if (isGraphTable) {
            this.props.reportsStore.loadGraphData(this.props.graphId, this.props.teamGroupId, this.state.tabularGraph);
          } else {
            this.props.reportsStore.loadTableData(this.props.reportId, this.props.teamGroupId);
          }
        }
      };

      downloadReport = () => {
        // If null, it will default to content activity type nice_name on backend
        let reportTitle = null;
        if (this.props.reportsStore.reportItem.queryParams && this.props.reportsStore.reportItem.queryParams.contentName) {
          reportTitle = this.props.reportsStore.reportItem.queryParams.contentName;
        } else if (this.props.reportTitle) {
          reportTitle = this.props.reportTitle;
        }
        this.props.reportsStore.downloadReport(this.props.reportId, this.props.teamGroupId, reportTitle, null, true);
      };

      // /**
      //  * Sets page number from pagination in store for service call query params
      //  * @param pg
      //  */
      changePg = (pg) => {
        this.props.reportsStore.setQueryParams('resultsPg', pg);
        this.props.reportsStore.loadTableData(this.props.reportId, this.props.teamGroupId);
      };

      getHeaderText = () => {
        if (this.props.reportId === 'assignment' || this.props.reportId === 'curricula-content') {
          return `${this.props.reportTitle} - ${this.props.reportsStore.reportItem.queryParams.contentName}`;
        }
        if (this.props.reportId === 'assignment-details' || this.props.reportId === 'learning-hours') {
          const subjectName =
            this.props.reportsStore.reportItem.queryParams.userName ||
            this.props.reportsStore.reportItem.queryParams.assignmentName ||
            this.props.reportsStore.reportItem.queryParams.contentName;

          return `${this.props.reportTitle} ${subjectName ? `- ${subjectName}` : ''}`;
        }
        if (this.props.reportId === 'content') {
          return `${this.props.reportTitle} - ${this.props.reportsStore.reportItem.queryParams.contentName}`;
        }
        if (this.props.reportId === 'leaderboard-hands-on' && 1 * this.props.reportParams['contentTypeIds[]'] === 21) {
          return `${this.props.reportTitle} - Assessments`;
        }
        return this.props.reportTitle;
      };

      scheduleReport = () => {
        const query = `?name=${this.props.reportId}&reportTitle=${this.getHeaderText()}&${queryString.stringify(this.props.queryParams)}${queryString.stringify(
          this.props.reportsStore.reportItem.queryParams
        )}`;

        this.props.scheduleReport(`/enterprise/${this.state.orgId}/reporting/scheduled-reports/new/${query}`);
      };

      checkReportDeprecated = () => {
        const deprecatedReports = {};
        return deprecatedReports[this.props.reportId] || null;
      };

      render() {
        const reportDeprecated = this.checkReportDeprecated();
        if (reportDeprecated) {
          return (
            <Container className="organization reports deprecated">
              <Segment className="mt-4">
                <Title title={this.getHeaderText()} breadcrumbs={this.getBreadCrumbs()} />
                <Message icon="exclamation-circle" msgBody={reportDeprecated?.message || 'This is a Deprecated Report'} msgHeader="Deprecated Report" />
              </Segment>
            </Container>
          );
        }
        return (
          <Container className="organization reports">
            <div className="pb-4">
              <div className="pt-12 mb-8 border-b-xs border-gray-400 sm:pt-16 sm:pb-12">
                <div className="items-end sm:flex">
                  <div>
                    <Breadcrumbs crumbs={this.getBreadCrumbs()} />
                    <div className="flex gap-x-4 items-center">
                      <Title title={this.getHeaderText()} omitPadding />
                      {this.props.isBeta && <BetaLabel />}
                    </div>
                  </div>
                  <div className="mt-12 mb-8 sm:flex-1 sm:my-0 sm:text-right">
                    <Tooltip
                      position="top-end"
                      omitTabIndex
                      triggerContent={
                        <button
                          aria-label="Download report"
                          className="p-2 text-sm font-bold text-center text-black bg-gray-200 rounded-sm disabled:opacity-20 disabled:cursor-wait"
                          onClick={() => this.downloadReport()}
                          disabled={this.props.reportsStore.downloadLoading}
                        >
                          <Download classes="w-6 h-6" />
                        </button>
                      }
                      content="Download report"
                    />
                    <Tooltip
                      omitTabIndex
                      triggerContent={
                        <button
                          aria-label="Add scheduled report"
                          className="p-2 ml-2 text-sm font-bold text-center text-black bg-gray-200 rounded-sm"
                          onClick={() => this.scheduleReport()}
                        >
                          <Calendar aria-hidden="true" classes="w-6 h-6" />
                        </button>
                      }
                      position="top-end"
                      content="Schedule recurring emails for this report"
                    />
                  </div>
                </div>
              </div>
              {this.props.showOptions && this.props.team ? (
                <ReportOptions
                  team={this.props.team}
                  loading={this.props.reportsStore.reportItem.filters.loading}
                  error={this.props.reportsStore.reportItem.filters.error}
                  filterChips={{
                    ...this.props.reportsStore.reportItem.filterChips,
                  }}
                  orgId={this.props.orgId}
                  teamGroupId={this.props.teamGroupId}
                  showDateFilter={this.props.showDateFilter}
                  showDateRangeFilter={this.props.showDateRangeFilter}
                  showUserFilter={this.props.showUserFilter}
                  applyFilters={this.applyFilters}
                  getReportData={this.getReportData}
                  deleteQueryParamsUpdateURL={this.deleteQueryParamsUpdateURL}
                  canViewReports={this.props.canViewReports}
                  showGroupFilter={this.props.showGroupFilter}
                />
              ) : null}
            </div>
            {this.props.showGraph ? (
              <>
                <GraphTableToggle onChange={this.setGraphView} checked={this.state.tabularGraph} />
                <ReportGraph
                  className="report-chart"
                  tableSort={this.tableSort}
                  heading={this.getHeaderText()}
                  data={this.formatGraphData(this.props.reportsStore.reportItem.graph.data, this.props.graphKey)}
                  loading={this.props.reportsStore.reportItem.graph.loading}
                  error={this.props.reportsStore.reportItem.graph.error}
                  interval={this.props.reportsStore.reportItem.graph.interval}
                  reportsStore={this.props.reportsStore}
                  graphKey={this.props.graphKey}
                  tabularView={this.state.tabularGraph}
                />
              </>
            ) : null}
            {this.props.showTable ? <div className="table-container">{this.getTableData(this.props.reportsStore.reportItem.table)}</div> : null}
          </Container>
        );
      }
    }
  )
);

export default withRouter(ReportsContainer);
