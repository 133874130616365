import React from 'react';
import { useNavigate } from 'react-router-dom';
import { startCase } from 'lodash';
import Icon from '../Icon/Icon';
import ContentCard from '../Card/ContentCard';
import PermalinkUtil from '../../utils/permalinkUtil';
import StatusLegend from '../ContentStatus/StatusLegend';

function buildAttackPatternsConfig(attackPatterns = []) {
  return attackPatterns.map((technique) => {
    return {
      tactics: {
        name: technique.kill_chain_phases[0].phase_name
          .split('-')
          .map((word) => (word === 'and' ? word : startCase(word)))
          .join(' '),
      },
      technique: {
        id: technique.id,
        name: technique.name,
      },
      subTechnique: technique.sub_technique
        ? {
            id: technique.sub_technique.id,
            name: technique.sub_technique.name,
          }
        : null,
    };
  });
}

function ContentRow({ course, path, handleTechniqueSelected, isFreeUser }) {
  const { short_description, attack_patterns } = course;
  const isThreatActorPath = path.content_type.name === 'threat_actor_path';
  const isFreeWithPaidLabs = course?.tags?.includes('Free With Paid Labs') || false;
  const attackPatterns = buildAttackPatternsConfig(attack_patterns);
  return (
    <div id="overview" className="hidden lg:block">
      <h2 className="pb-2 text-lg font-bold">Overview</h2>
      <p className="mb-0 w-full text-sm leading-6 lg:w-5/6">
        {short_description} {isFreeUser && isFreeWithPaidLabs && <span className="text-xs text-red-500">Free with paid labs.</span>}
      </p>
      {isThreatActorPath && (
        <>
          <h2 className="pt-4 pb-2 text-lg font-bold">
            <span className="uppercase">Mitre Att&amp;ck</span> Framework
          </h2>
          {attackPatterns.map((pattern) => (
            <ul key={pattern.technique.id} className="flex items-center pb-3">
              <li>
                <span className="text-2xs font-semibold text-gray-600 uppercase">Tactics</span>
                <p className="underline">{pattern.tactics.name}</p>
              </li>
              {pattern.technique && (
                <>
                  <li className="px-2">
                    <Icon name="chevron-right" className="w-4 h-4 text-gray-600" />
                  </li>
                  <li>
                    <button type="button" className="w-full text-left" onClick={() => handleTechniqueSelected(pattern.technique.id)}>
                      <span className="text-2xs font-semibold text-gray-600 uppercase">Technique</span>
                      <p className="underline">{pattern.technique.name}</p>
                    </button>
                  </li>
                </>
              )}
              {pattern.subTechnique && (
                <>
                  <li className="px-2">
                    <Icon name="chevron-right" className="w-4 h-4 text-gray-600" />
                  </li>
                  <li>
                    <button type="button" className="w-full text-left" onClick={() => handleTechniqueSelected(pattern.technique.id)}>
                      <span className="text-2xs font-semibold text-gray-600 uppercase">Sub-Technique</span>
                      <p className="underline">{pattern.subTechnique.name}</p>
                    </button>
                  </li>
                </>
              )}
            </ul>
          ))}
        </>
      )}
    </div>
  );
}

function DottedLineBall({ position, active }) {
  const activeBall = active ? 'bg-cyb-green-700 border-cyb-green-700' : 'bg-white border-gray-400';
  let firstDivClass = '';
  let ballWrapper = '';
  switch (position) {
    case 'top':
      firstDivClass = 'bg-white';
      break;
    case 'middle':
      firstDivClass = 'bg-transparent';
      break;
    case 'bottom':
      ballWrapper = 'flex items-end';
      break;
    default:
  }

  return (
    <div className="flex z-20 flex-col -ml-12">
      {position !== 'bottom' && <div className={`${firstDivClass} h-1/2`} />}
      <div className={`${ballWrapper} h-1/2`}>
        <div className={`h-7 w-7 border-2 rounded-full flex items-center justify-center -ml-8-12 ${activeBall}`}>
          {active && <Icon name="check" className="w-4 h-5 text-white" />}
        </div>
      </div>
      {position === 'bottom' && <div className="h-1/2 bg-white" />}
    </div>
  );
}

export default function Outline({ pathEnrollmentId, courses, handleTechniqueSelected, path, isFreeUser }) {
  const navigate = useNavigate();
  const getPosition = (index) => {
    let position = 'top';
    if (index > 0) {
      position = 'middle';
    }
    return position;
  };

  const contentClick = (course) => {
    const permalink = pathEnrollmentId ? `/immersive/${pathEnrollmentId}/item/${course.id}` : PermalinkUtil.formatInApp(course.permalink, course.content_type);
    navigate(permalink);
  };

  return (
    <div id="Outline">
      <div className="flex justify-between">
        <h2 className="pb-6 text-2xl font-black">Outline</h2>
        <div>
          <StatusLegend hideNoContent />
        </div>
      </div>
      <div className="relative px-10 lg:px-0">
        <div
          className="absolute left-1 w-10 h-full lg:-left-9"
          style={{
            backgroundImage: 'repeating-linear-gradient(0deg, #CCCCCC, #CCCCCC 10px, transparent 10px, transparent 19px, #CCCCCC 19px)',
            backgroundSize: '2px 100%, 100% 2px, 2px 100% , 100% 2px',
            backgroundRepeat: 'no-repeat',
          }}
        />

        {courses.map((course, index) => (
          <div key={course.id} id="row" className="flex pb-8 mb-8 border-b border-gray-400">
            <DottedLineBall position={getPosition(index)} active={course.progress === 100} />
            <div className="flex ml-6">
              <div id="card" className="lg:pr-6">
                <ContentCard
                  isComingSoon={course.comingSoon}
                  handleClick={() => contentClick(course)}
                  data={course}
                  key={course.id}
                  objectID={course.id}
                  className="w-full sm:w-60"
                  cardProps={{ menuAbove: true }}
                />
              </div>
              <ContentRow course={course} path={path} handleTechniqueSelected={handleTechniqueSelected} isFreeUser={isFreeUser} />
            </div>
          </div>
        ))}

        <div id="row" className="flex">
          <DottedLineBall position="bottom" />

          <div className="flex ml-6">
            <div id="card" className="p-16 rounded-full border-xs border-gray-400">
              <Icon name="trophy" className="mx-auto w-32 h-32 text-gray-400" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
