import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import { Fade } from 'react-awesome-reveal';
import EmptyContent from '../../EmptyContent/EmptyContent';
import { CertCard } from '../../../pages/Certificates/Certificates';
import Modal from '../../Modal/Modal';
import CertificateModalContent from '../../../pages/Certificates/CertificateModalContent';

/**
 * Content of the CertCompletion Tab showing the Cybrary Certificates of Completion for a user
 * @param {boolean} isLoading - comes from the cert store and tells us if the certs are loading
 * @param {object} certs - contains two keys, Course and MicroCourse, which contain an array of certs
 * @returns <CertCompletionTab />
 * */
function CertCompletionTab({ isLoading, certs = {} }) {
  const [open, setOpen] = useState(false);
  const [selectedCert, setSelectedCert] = useState(null);
  /**
   * if you navigate to this page directly before data is loaded... it causes an error...
   * to prevent that we return null if we are loading
   */
  if (isLoading) return null;

  const contentTypes = Object.keys(certs);
  const displayedCerts = [];
  contentTypes?.map((type) => {
    return certs[type].map((item) => displayedCerts.push(item));
  });

  const toggleModal = (isOpen, cert = null) => {
    setOpen(isOpen);
    setSelectedCert(cert);
  };

  return (
    <div className="pt-4">
      <Fade duration={500} damping={0.1} triggerOnce cascade>
        <h2 className="mb-8 text-2xl font-bold">Certificates of Completion</h2>
        {!isLoading && isEmpty(certs) && <EmptyContent header="No Certificates" />}
        <Modal open={open} toggle={() => toggleModal(!open)} ariaLabelledBy="cert-title">
          <CertificateModalContent cert={selectedCert} />
        </Modal>
        <div className="flex flex-row flex-wrap mb-24 w-full">
          {displayedCerts.map((item, idx) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <CertCard key={`${item.id}-${idx}`} cert={item} onCertClick={() => toggleModal(!open, item)} />
            );
          })}
        </div>
      </Fade>
    </div>
  );
}
export default CertCompletionTab;
