import React from 'react';

function Paths({ active }) {
  if (active) {
    return (
      <path
        d="M17.7084 16.9145L17.7086 16.9144C18.641 16.4843 19.41 15.7447 19.9454 14.9423C20.4782 14.1438 20.8 13.2522 20.8 12.4999C20.8 9.85337 18.6468 7.69994 16 
            7.69994C13.3532 7.69994 11.2 9.85337 11.2 12.4999C11.2 13.2174 11.781 13.8001 12.4999 13.8001C13.2188 13.8001 13.8001 13.2175 13.8001 12.4999C13.8001 
            11.2877 14.7868 10.3 16 10.3C17.2132 10.3 18.1999 11.2877 18.1999 12.4999C18.1999 12.7149 18.0563 13.1173 17.7586 13.5441C17.4677 13.961 17.0669 14.3466 
            16.6196 14.5525L16.6193 14.5527C15.4542 15.0907 14.7001 16.2667 14.7001 17.5511V18.0001C14.7001 18.7174 15.2811 19.3 16 19.3C16.7189 19.3 17.2999 18.7174 
            17.2999 18.0001V17.5511C17.2999 17.2794 17.4606 17.0286 17.7084 16.9145ZM4.3 16C4.3 9.54862 9.54862 4.3 16 4.3C22.4514 4.3 27.7 9.54862 27.7 16C27.7 
            22.4514 22.4514 27.7 16 27.7C9.54862 27.7 4.3 22.4514 4.3 16ZM14.7001 22C14.7001 22.7177 15.2823 23.2999 16 23.2999C16.7177 23.2999 17.2999 22.7177 17.2999
            22C17.2999 21.2823 16.7177 20.7001 16 20.7001C15.2823 20.7001 14.7001 21.2823 14.7001 22Z"
        fill="black"
        stroke="black"
        strokeWidth="0.6"
      />
    );
  }
  return (
    <>
      <path
        d="M16.9999 21.9999C16.9999 22.5522 16.5522 22.9999 15.9999 22.9999C15.4477 22.9999 15 22.5522 15 21.9999C15 21.4477 15.4477 21 15.9999 21C16.5522 21
          16.9999 21.4477 16.9999 21.9999Z"
        fill="currentColor"
        strokeWidth="0.1"
      />
      <path
        d="M16 28C9.38293 28 4 22.6171 4 16C4 9.38293 9.38293 4 16 4C22.6171 4 28 9.38293 28 16C28 22.6171 22.6171 28 16 28ZM16 5.5C10.21 5.5 5.5 10.21 5.5
          16C5.5 21.79 10.21 26.5 16 26.5C21.79 26.5 26.5 21.79 26.5 16C26.5 10.21 21.79 5.5 16 5.5Z"
        fill="currentColor"
        strokeWidth="0.1"
      />
      <path
        d="M16.0001 18.7501C15.5861 18.7501 15.2501 18.4141 15.2501 18.0001V16.9901C15.2501 16.0381 15.8541 15.1851 16.7521 14.8681C17.947 14.4471 18.7501
          13.174 18.7501 12.2501C18.7501 10.733 17.5171 9.5 16.0001 9.5C14.483 9.5 13.25 10.733 13.25 12.2501C13.25 12.6641 12.914 13.0001 12.5 13.0001C12.086
          13.0001 11.75 12.6641 11.75 12.2501C11.75 9.90704 13.6561 8 16.0001 8C18.344 8 20.2501 9.90704 20.2501 12.2501C20.2501 13.9182 18.933 15.6891 17.251
          16.2831C16.9511 16.3881 16.7501 16.673 16.7501 16.991V18.0001C16.7501 18.4141 16.4141 18.7501 16.0001 18.7501Z"
        fill="currentColor"
        strokeWidth="0.1"
      />
    </>
  );
}

export default function HelpIcon({ active, classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';
  const strokeProps = active ? {} : { stroke: 'currentColor' };
  return (
    <svg className={className} fill="none" {...strokeProps} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...accessibilityProps}>
      <Paths active={active} />
    </svg>
  );
}
