import React, { createContext, useContext, useState, useMemo } from 'react';
import SearchUtil from '../utils/searchUtil';

export const EXAMPLE_BADGES = [
  {
    id: 3,
    name: 'Completed Regex',
    description: 'Complete the Regex Course',
    type: 'Courses',
    locked_image_url: 'https://images.ctfassets.net/kvf8rpi09wgk/5GagxK5Lka8pPPGI80tpma/5a99df90ac86efa74345e89f15e4df24/trophy-locked.jpeg',
    unlocked_image_url: 'https://images.ctfassets.net/kvf8rpi09wgk/4H3GGwysHXmjtjfclL8mwg/bcdcb22ff7c7148f75761c7f6d6b0b80/trophy.png',
    badge_group_id: null,
    level: 1,
    active: true,
    created_at: '2023-09-29 20:01:27',
    updated_at: '2023-10-10 14:31:17',
    deleted_at: null,
    group: null,
    completed_at: '2023-10-19T14:33:58.000000Z',
  },
  {
    id: 4,
    name: 'Completed First Assessment',
    description: 'Complete your first Assessment',
    type: 'Courses',
    locked_image_url: 'https://images.ctfassets.net/kvf8rpi09wgk/5GagxK5Lka8pPPGI80tpma/5a99df90ac86efa74345e89f15e4df24/trophy-locked.jpeg',
    unlocked_image_url: 'https://images.ctfassets.net/kvf8rpi09wgk/4H3GGwysHXmjtjfclL8mwg/bcdcb22ff7c7148f75761c7f6d6b0b80/trophy.png',
    badge_group_id: null,
    level: 1,
    active: true,
    created_at: '2023-10-10 14:55:48',
    updated_at: '2023-10-10 17:44:35',
    deleted_at: null,
    group: null,
    completed_at: '2023-10-19T14:33:58.000000Z',
  },
  {
    id: 5,
    name: 'Byte Blocker',
    description: 'Complete Regex course',
    type: 'Courses',
    locked_image_url: null,
    unlocked_image_url: 'https://images.ctfassets.net/kvf8rpi09wgk/2SbYA4ABJlNoEg1JxcFigi/8e069aa45fbc3e9d670e06eabbf5e274/image_40.png',
    badge_group_id: null,
    level: 1,
    active: true,
    created_at: '2023-10-10 17:39:11',
    updated_at: '2023-10-10 18:36:33',
    deleted_at: null,
    group: null,
    completed_at: '2023-10-19T14:33:58.000000Z',
  },

  {
    id: 6,
    name: 'Byte Blocker',
    description: 'Complete Regex course',
    type: 'Courses',
    locked_image_url: null,
    unlocked_image_url: 'https://images.ctfassets.net/kvf8rpi09wgk/2SbYA4ABJlNoEg1JxcFigi/8e069aa45fbc3e9d670e06eabbf5e274/image_40.png',
    badge_group_id: null,
    level: 1,
    active: true,
    created_at: '2023-10-10 17:39:11',
    updated_at: '2023-10-10 18:36:33',
    deleted_at: null,
    group: null,
    completed_at: '2023-10-19T14:33:58.000000Z',
  },

  // {
  //   id: 7,
  //   name: 'Byte Blocker',
  //   description: 'Complete Regex course',
  //   type: 'Courses',
  //   locked_image_url: null,
  //   unlocked_image_url: 'https://images.ctfassets.net/kvf8rpi09wgk/2SbYA4ABJlNoEg1JxcFigi/8e069aa45fbc3e9d670e06eabbf5e274/image_40.png',
  //   badge_group_id: null,
  //   level: 1,
  //   active: true,
  //   created_at: '2023-10-10 17:39:11',
  //   updated_at: '2023-10-10 18:36:33',
  //   deleted_at: null,
  //   group: null,
  //   completed_at: '2023-10-19T14:33:58.000000Z',
  // },
];

const BadgeModalContext = createContext();

/**
 * Provider for the badges modal
 * Allows the badges modal to be opened and configured from anywhere in the app, including public profile pages
 * It has a badges state to store the badge object(s) from the server to show in the modal
 * @param {ReactElement} children - the children to render
 * @returns {ReactElement} - the rendered children
 */
function BadgeModalProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false); // whether or not the modal is open
  const [badges, setBadges] = useState(EXAMPLE_BADGES); // the badge objects from the server to show in the modal
  // Cache response from algolia for badge content by badgeId
  const [badgeContentMap, setBadgeContentMap] = useState({});

  // Checks if badge content is in cache, if not, fetches it from algolia
  const handleBadgeContent = async (badgeIds = []) => {
    // Determine which badge content is not in cache
    const badgeIdsToFetch = badgeIds.filter((badgeId) => !badgeContentMap[badgeId]);

    // If no badge content to fetch, return. We already have all badge content in cache
    if (!badgeIdsToFetch.length) return;

    // Fetch all badge content thats needed for the current modal display that is not in cache
    const options = {
      hitsPerPage: 99,
      filters: SearchUtil.buildFilters('badge_id', badgeIdsToFetch),
    };
    const results = await SearchUtil.fetchFromAlgolia(process.env.REACT_APP_INSTANTSEARCH_CATALOG_INDEX, options);

    // Add badge content to cache as an array of all content for that badge
    const newBadgeContentMap = { ...badgeContentMap };
    badgeIdsToFetch.forEach((badgeId) => {
      const badgeContent = results?.hits?.filter(({ badge_id }) => badge_id === badgeId);
      newBadgeContentMap[badgeId] = badgeContent;
    });
    setBadgeContentMap(newBadgeContentMap);
  };

  /**
   * options for the modal to customize display
   * Supported options:
   * @option isNewlyEarnedBadge = flag indicating whether or not the badge is newly earned (Used for when a user earns a badge)
   */
  const [options, setOptions] = useState({
    isNewlyEarnedBadge: true,
  });

  /**
   * Open the badge modal and sets the given badges to show
   * the first param are the badges to show in the modal
   * the second param are all other options and configs for this modal's display
   * @param {Array} badgesToShow - the badge objects to show in the modal
   * @param {Object} newOptions - (optional) the options to configure the modal's display
   */
  const open = (badgesToShow, newOptions = {}) => {
    // If no badges to show, return
    if (!badgesToShow) return;
    // Transform badgesToShow to an array (if needed)
    const badgesToShowArray = Array.isArray(badgesToShow) ? badgesToShow : [badgesToShow];
    // Set the badges to show array
    setBadges(badgesToShowArray);
    // Apply our new options, if any.
    setOptions(newOptions);
    // Open the modal
    setIsOpen(true);
    // Fetch badge content from algolia (if needed)
    handleBadgeContent(badgesToShowArray.map(({ id }) => id));
  };

  /**
   * Close the badge modal and reset the badge modal
   */
  const close = () => {
    setIsOpen(false);
    setBadges([]);
    setOptions({});
  };

  // values returned to be used in the user's profile page
  const values = useMemo(
    () => ({
      isOpen,
      badges,
      options,
      badgeContentMap,
      open,
      close,
    }),
    [isOpen, badges, options, badgeContentMap, open, close]
  );

  return <BadgeModalContext.Provider value={values}>{children}</BadgeModalContext.Provider>;
}

export const useBadgeModal = () => useContext(BadgeModalContext);
export default BadgeModalProvider;
