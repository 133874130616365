import React, { useEffect, useState } from 'react';
import { observer, inject } from 'mobx-react';
import { useParams, useNavigate } from 'react-router-dom';
import VisibilitySensor from 'react-visibility-sensor';

import MitreContext from '../../contexts/MitreContext';
import MitreCatalog from '../../components/Mitre/MitreCatalog';
import MitreTechniqueContent from '../../components/Mitre/MitreTechniqueContent';
import SideContentSlideout from '../../components/SideContentSlideout/SideContentSlideout';
import Icon from '../../components/Icon/Icon';
import Container from '../../components/Container/Container';
import Divider from '../../components/Divider/Divider';
import ContentTabs from '../../components/ContentTabs/ContentTabs';
import StickyContentTabs from '../../components/ContentTabs/StickyContentTabs';
import Overview from '../../components/Path/Overview';
import Outline from '../../components/Path/Outline';
import useMitreCatalog from '../../hooks/useMitreCatalog';
import EnrollmentButton from '../../components/BrowsePromo/EnrollmentButton';
import OptionsMenu from '../../components/OptionsMenu/OptionsMenu';
import CircularProgress from '../../components/ProgressBar/CircularProgress';
import MitreSlideoutHeader from '../../components/Mitre/MitreSlideoutHeader';
import ContentHeader from '../../components/ContentHeader/ContentHeader';
import useContentCollectionPath from '../../hooks/useContentCollectionPath';
import useSelectTechnique from '../../hooks/useSelectTechnique';
import PageLoadingState from '../../components/Loading/PageLoadingState';
import { setStickyNavActiveTab } from '../../utils/tabsUtil';
import { getOptions, handleEnroll } from '../../utils/pathsUtil';

const tabs = [
  {
    id: 'overview',
    name: 'Overview',
    scrollSelector: '#overview',
    heightSwitch: 0.5,
  },
  {
    id: 'outline',
    name: 'Outline',
    scrollSelector: '#outline',
    heightSwitch: 0.8,
  },
  {
    id: 'ttps',
    name: 'TTPs',
    scrollSelector: '#ttps',
    heightSwitch: 0.75,
  },
];

const ThreatActorPath = inject(
  'userStore',
  'enrollmentStore',
  'commonStore',
  'authStore'
)(
  observer(({ userStore, enrollmentStore, commonStore, authStore }) => {
    const params = useParams();
    const navigate = useNavigate();
    const paramPermalink = params && params.permalink ? params.permalink : null;
    const { loading, error, path, coursesData, courseIds, techniqueIds } = useContentCollectionPath(paramPermalink, 'threatActors');
    const [activeTab, setActiveTab] = useState('overview');
    const [stickyTab, setStickyTab] = useState(false);
    const [showHeader, setShowHeader] = useState(true);
    const {
      setTechniqueData,
      techniqueSideSectionData,
      setTechniqueSideSectionData,
      subTechniqueSideSectionData,
      setSubTechniqueSideSectionData,
      resetTechniqueData,
      techniqueDataValues,
    } = useMitreCatalog();
    const { selectedTechnique, handleTechniqueSelected, getTechniqueData, closeSlideout } = useSelectTechnique({ techniqueSideSectionData, setTechniqueData, resetTechniqueData });

    const { title, thumbnail_url, permalink, progressData, percentCompleted, type, progressEvent, enrollment_id, content_status, license } = path || {};
    const comingSoon = content_status === 'Coming Soon';
    const browseThreatActor = '/browse/threat-actor-campaigns';
    const shareOptions = { permalink, title, type };
    const stores = { userStore, enrollmentStore, commonStore };
    const started = progressEvent && progressEvent === 'started';
    const threatType = 'Threat Actor Campaigns';
    const isFreeUser = !userStore?.user?.is_paid;

    useEffect(() => {
      authStore.fireAttributionEvent();
    }, []);

    useEffect(() => {
      commonStore.setPageTitle(`${title || ''} | Cybrary`);
      if (started) {
        setShowHeader(false);
        setStickyTab(true);
        setActiveTab('outline');
      }
    }, [path]);
    const enroll = () => handleEnroll(path, stores, navigate);
    return (
      <PageLoadingState loading={loading} error={error}>
        <MitreContext.Provider value={techniqueDataValues}>
          <SideContentSlideout open={!!techniqueSideSectionData}>
            <SideContentSlideout.Main scrollEvent={() => setStickyNavActiveTab(tabs, setActiveTab)}>
              {stickyTab && (
                <StickyContentTabs
                  tabs={tabs}
                  setActiveTab={setActiveTab}
                  activeTab={activeTab}
                  backButtonUrl={browseThreatActor}
                  commonStore={commonStore}
                  item={path}
                  progress={progressData}
                  title={title}
                  typeText={threatType}
                  type={type}
                  image={thumbnail_url}
                  circularProgress={
                    <CircularProgress classes={`${started ? 'block' : 'hidden'} ml-4 w-12 h-12 mx-auto`} strokeWidth={4} percentageValue={percentCompleted}>
                      <Icon name="trophy" className="mx-auto w-6 h-6 text-gray-600" />
                    </CircularProgress>
                  }
                  permalink={permalink || ''}
                  buttons={
                    !comingSoon ? (
                      <EnrollmentButton type={type} handleEnroll={enroll} license={license} progress={progressData} item={path} buttonClasses="w-full lg:w-auto" />
                    ) : null
                  }
                />
              )}
              <Container size="cards-container" className="px-4 md:px-16" omitPadding>
                {showHeader && (
                  <ContentHeader
                    contentType="Threat Actor Campaigns"
                    contentTitle={title}
                    contentImageUrl={thumbnail_url}
                    backLink={browseThreatActor}
                    backLinkText="Back To Threat Actor Campaigns"
                    buttons={
                      <>
                        {!comingSoon ? <EnrollmentButton type={type} handleEnroll={enroll} userType={userStore.userType} progress={progressData} item={path} /> : null}
                        <div className="ml-2">
                          <OptionsMenu options={getOptions(commonStore, shareOptions)} iconContainerClassName="border hover:bg-gray-200 border-gray-400 px-2" />
                        </div>
                      </>
                    }
                  />
                )}
                <div className="mt-10">
                  {showHeader && (
                    <VisibilitySensor onChange={(isVisible) => setStickyTab(!isVisible)} partialVisibility offset={{ top: 240 }}>
                      <div className="mb-8 lg:border-b-xs lg:border-gray-400">
                        <ContentTabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} wrapperClasses="mt-12 border-t-xs lg:border-t-0" />
                      </div>
                    </VisibilitySensor>
                  )}
                  {started && (
                    <div className="block mb-8 lg:hidden lg:border-b-xs lg:border-gray-400">
                      <ContentTabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} wrapperClasses="mt-12 border-t-xs lg:border-t-0" />
                    </div>
                  )}
                  <div id="overview" className="scroll-mt-32">
                    <Overview path={path} />
                  </div>

                  <Divider marginTop="mt-8" marginBottom="mb-8" />
                  <div id="outline" className="scroll-mt-36">
                    <Outline pathEnrollmentId={enrollment_id} courses={coursesData} path={path} handleTechniqueSelected={handleTechniqueSelected} isFreeUser={isFreeUser} />
                  </div>
                  <Divider marginTop="mt-8" marginBottom="mb-8" />
                  <div id="ttps" className="mb-10 scroll-mt-36">
                    <h2 className="mb-2 text-2xl font-black">TTPs</h2>
                    <p className="mb-6 text-gray-600">This campaign comprises the following adversary tactics and techniques.</p>
                    <MitreCatalog
                      commonStore={commonStore}
                      disableSubscription
                      filters={{
                        courseIds,
                        techniqueIds,
                      }}
                      selectedTechnique={{ id: selectedTechnique, get: getTechniqueData }}
                      useMobileBlock
                    />
                  </div>
                </div>
              </Container>
            </SideContentSlideout.Main>
            <SideContentSlideout.SideSection width="w-full md:w-132" labelledById={techniqueSideSectionData && techniqueSideSectionData.buttonId}>
              <SideContentSlideout.SideHeader>
                <MitreSlideoutHeader
                  closeSlideout={closeSlideout}
                  subTechniqueSideSectionData={subTechniqueSideSectionData}
                  techniqueSideSectionData={techniqueSideSectionData}
                  setSubTechniqueSideSectionData={setSubTechniqueSideSectionData}
                  setTechniqueSideSectionData={setTechniqueSideSectionData}
                />
              </SideContentSlideout.SideHeader>
              <SideContentSlideout.SideContent className="py-6 px-4 mx-auto max-w-132">
                <MitreTechniqueContent data={subTechniqueSideSectionData || techniqueSideSectionData} focusOnDataChange />
              </SideContentSlideout.SideContent>
            </SideContentSlideout.SideSection>
          </SideContentSlideout>
        </MitreContext.Provider>
      </PageLoadingState>
    );
  })
);

export default ThreatActorPath;
