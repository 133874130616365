import React from 'react';
import ReactMarkdown from 'react-markdown';
import Section from './Section';
import LegacyVideo from './LegacyVideo';
import Lx2Video from './Lx2Video';
import { useImmersive } from '../../../providers/ImmersiveProvider';

function VideoSection({ videoId, highlight, legacy, activity, caption, isComplete, completeActivity, isImmersiveV3 }) {
  const { a11yPaused, setA11yPaused, setIncludeA11yPause } = useImmersive();

  if (!videoId) {
    return null;
  }
  if (legacy) {
    return (
      <div className="text-gray-800 bg-gray-100">
        <LegacyVideo
          videoId={videoId}
          activity={activity}
          isComplete={isComplete}
          completeActivity={completeActivity}
          isNew={isImmersiveV3}
          a11yPaused={a11yPaused}
          setA11yPaused={setA11yPaused}
          setIncludeA11yPause={setIncludeA11yPause}
        />
      </div>
    );
  }
  return (
    <Section highlight={highlight}>
      <Lx2Video videoId={videoId} activity={activity} />
      {!!caption && <ReactMarkdown className="mb-4 text-sm text-center text-gray-700 has-markdown-inside">{caption}</ReactMarkdown>}
    </Section>
  );
}

export default VideoSection;
