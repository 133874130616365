import React from 'react';
import SelectBoxes from '../SelectBoxes/SelectBoxes';
import WizardStepDescription from './WizardStepDescription';
import Divider from '../Divider/Divider';

function WizardOutcomeSelection({
  // setValue,
  selectedOutcomeType,
  selectedOutcomeSettings,
  // certificationOptions,
  // filterOptions,
  isEditView,
  // practiceTests,
  // selectedPracticeTest,
  outcomeOptions,
  inputRef,
  // practiceTestInputRef,
  handleSelection,
  learningHours,
  goalType,
}) {
  return (
    <>
      <SelectBoxes
        disabled={isEditView}
        containerClasses="overflow-y-auto w-11/12"
        selections={selectedOutcomeType}
        handleSelect={(selection) => handleSelection('outcome_type', selection)}
        options={outcomeOptions}
      />
      {selectedOutcomeType === 'learning_hours' ? (
        <>
          <Divider marginTop="mt-6" marginBottom="mb-6" />
          <div>
            <div ref={inputRef} />
            <p className="mb-0 text-sm text-gray-600">How many learning hours?</p>
            <p className="mb-2 text-xs text-gray-500">Please use whole numbers only</p>
            <input
              disabled={isEditView}
              className="p-2 w-80 rounded-sm border border-gray-400"
              value={learningHours}
              onChange={(e) => handleSelection('outcome_settings', e.target.value)}
              placeholder="Type the number of learning hours..."
            />
          </div>
        </>
      ) : null}
      {selectedOutcomeType === 'ceus' ? (
        <>
          <Divider marginTop="mt-6" marginBottom="mb-6" />
          <div>
            <div ref={inputRef} />
            <div className="mb-4 text-sm text-gray-600">
              <WizardStepDescription step="ceus" disabled={isEditView} goalType={goalType} />
            </div>
            <input
              disabled={isEditView}
              className="p-2 w-80 rounded-sm border border-gray-400"
              defaultValue={selectedOutcomeSettings.ceus_total || ''}
              onChange={(e) => handleSelection('outcome_settings', e.target.value)}
              placeholder="Type the number of CEUs..."
            />
          </div>
        </>
      ) : null}
    </>
  );
}

export default WizardOutcomeSelection;
