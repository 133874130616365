import React from 'react';
import AddLink from '../../components/AddLink/AddLink';
import Message from '../../components/Message/Message';

function CancellationConfirm({ hasCalendly }) {
  return (
    <div className="pt-12 pb-8 text-center">
      <h2 className="mb-4 text-2xl	text-black">Your Insider Pro Membership is Cancelled</h2>
      <div>
        <p className="text-sm">Thank you for being a member of Insider Pro! We hope you excel on your educational journey and consider Cybrary as a resource in the future.</p>
        <ul>
          <li className="text-sm">You will receive an email confirming your cancellation.</li>
        </ul>
        <p className="text-sm">Your Cybrary account will always be available, and you can upgrade again at any time to pick up where you left off.</p>
      </div>
      {hasCalendly && (
        <div className="mt-4">
          <Message status="info" isDismissible={false}>
            <p className="text-sm">You indicated you would like to provide feedback to one of our team members. Use the following link to schedule a feedback session:</p>
            <p className="text-sm text-center">
              <AddLink
                className="text-cyb-pink-500 hover:text-black underline cursor-pointer"
                to="https://calendly.com/cybrary-sessions/insider-pro-exit-interview"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://calendly.com/cybrary-sessions/insider-pro-exit-interview
              </AddLink>
            </p>
          </Message>
        </div>
      )}
      <div className="mt-8">
        <AddLink className="py-2.5 px-6 mt-8 text-sm font-bold leading-5 text-center text-white hover:text-white bg-cyb-pink-500 hover:bg-pink-600 rounded-sm" to="/">
          Home
        </AddLink>
      </div>
    </div>
  );
}

export default CancellationConfirm;
