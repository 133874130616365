import Bugsnag from '@bugsnag/js';

/* eslint-disable no-param-reassign */
const BugsnagUtil = {
  // checks if a bugsnag event is a network or
  // other noise that we don't consider useful.
  // returns true if useless
  discardUselessErrors: (event) => {
    const { errorMessage } = event.errors[0];
    let usefulError = true;
    [
      /Request failed with status code (401|403|406|408|500|502|503)/g,
      /There was an error fetching the embed code from Vimeo/g,
      /.https:\/\/vimeo\.com\/[0-9]{8,11}. is not embeddable/g,
      /\(auth\/network-request-failed\)/g,
      /Because of its privacy settings, this video cannot be played here/g,
    ].every((ignoredErrorMessage) => {
      usefulError = !ignoredErrorMessage.test(errorMessage);
      return usefulError;
    });
    // returning false from an onError callback prevents the error from submitting to bugsnag
    return usefulError;
  },
  // update context of errors from a dynamic url to the original error message
  retitleImmersiveErrors: (event) => {
    const immersiveUrlRegex = /(\/immersive\/\d{4,10})/g;
    const { errorMessage } = event.errors[0];
    if (immersiveUrlRegex.test(event.context)) {
      event.context = `Immersive ${errorMessage}`;
    }
  },
  retitleEnterpriseErrors: (event) => {
    const enterpriseUrlRegex = /(\/enterprise\/\d{2,10})/g;
    const { errorMessage } = event.errors[0];
    if (enterpriseUrlRegex.test(event.context)) {
      event.context = `Enterprise ${errorMessage}`;
    }
  },
  /**
   * Utility function to send error to bugnsag with a named metadata object
   * @param {Error} error js Error object of the error event
   * @param {String} name what to name the metadata tab in bugsnag
   * @param {Object} data the object of data to be included in the bugsnag event
   */
  notifyWithNamedMetadata: (error, name, data) => {
    Bugsnag.notify(error, (errorEvent) => {
      errorEvent.addMetadata(name, data);
    });
  },
  /**
   * Utility function to send error to bugnsag with a metadata object
   * @param {Error} error js Error object of the error event
   * @param {Object} data the object of data to be included in the bugsnag event
   */
  notifyWithMetadata: (error, data) => {
    BugsnagUtil.notifyWithNamedMetadata(error, 'metadata', data);
  },
  /**
   * Utility function to send error to bugnsag. Same as Bugsnag.notify.
   * Used to make imports for bug handling consistent.
   * @param {Error} error js Error object of the error event
   */
  notify: Bugsnag.notify,
};

export default BugsnagUtil;
