import { colorSchemes } from '@nivo/colors';

/**
 * @why - this is a helper function to format the labels for the radar chart in the event they get cut off
 * @param {array} labels -  array of strings
 * @param {number} threshold - number of spaces in a string to trigger the label to be split into an array of strings
 * returns array of strings with the first letter capitalized i.e.[["My", "long", "long", "long", "label"], "another label",...],
 * @example
 * "My long long label"...will look like
 * My
 * long
 * long
 * label
 *  ...in the UI
 */
export function LabelTransformer(labels = [], threshold = 1) {
  return labels.map((label) => {
    const splitLabel = label.split(' ');
    const numSpaces = splitLabel.length - 1;
    /** our threshold is 1 space. If more then one space return an array of string, else return the original string */
    if (numSpaces > threshold) {
      return splitLabel;
    }
    return label;
  });
}

/**
 * Return custom colors for our Progress Charts, based on @nivo color scheme and category name.
 * @param {*} name - The category name we are looking to display
 * @param {*} index - The index of the "line" or "bar", in case we can't find the name
 * @param {*} scheme - The @nivo color scheme
 * @returns {string} - The custom color hex code, with a fallback to black just in case none are available
 */
export function getCustomColor(name, index, scheme) {
  const colorsFromScheme = colorSchemes[scheme];
  const customColorConfig = {
    Foundations: '#6BB82F',
    'Defensive Security': '#2FB8B0',
    'Offensive Security': '#B82F2F',
    'Engineering and Operations': '#FF8F27',
    'Management & Leadership': '#F6DC99',
    'Leadership and Management': '#F6DC99',
    'Governance, Risk, and Compliance': '#AD27FF',
    'Threats and Vulnerabilities': '#0B0B0F',
    'Oversight and Governance': '#2FB8B0',
    'Design and Development': '#B82F2F',
    'Implementation and Operation': '#45D3F7',
    'Protection and Defense': '#FF008A',
    Investigation: '#AD27FF',
    'Cyberspace Intelligence': '#FF8F27',
    'Cyberspace Effects': '#6BB82F',
  };
  return customColorConfig[name] || colorsFromScheme[index] || '#000000';
}
