import React from 'react';
import { StatusIcon } from '../ContentStatus/StatusLegend';
import CheckboxDisplay from './CheckboxDisplay';
import PercentageBar from '../Analytics/PercentageBar';

function TechniqueStatusIcons({ isComplete, hasContentAvailable, hasContentComingSoon }) {
  return (
    <>
      {isComplete && <StatusIcon status="complete" />}
      {hasContentComingSoon && <StatusIcon status="comingSoon" />}
      {!hasContentAvailable && <span className="sr-only">No content yet.</span>}
    </>
  );
}

function TechniqueCard({ data, wrapperClasses, handleClick, buttonId, isButtonDisabled, isSelected, isTechniqueOpen, showSubscribeBoxes }) {
  const getTechniqueName = (name) => {
    // If the technique name has a / in it, split and add a word break element after the slash so it doesn't overflow and don't have to force awkward breaks
    if (name.indexOf('/') > -1) {
      const nameArr = name.split('/');
      nameArr[0] = `${nameArr[0]}/`;
      nameArr.splice(1, 0, <wbr key="word-break" />);
      return nameArr;
    }
    return name;
  };

  // flags for color and icons
  const hasContentAvailable = data.courses && data.courses.length;
  const hasContentComingSoon = data.comingSoonCourses && data.comingSoonCourses.length;
  const isComplete = data.progress === 100;
  const backgroundColor = hasContentAvailable ? 'bg-white' : 'bg-gray-400';
  const selectedClasses =
    isTechniqueOpen || (isSelected && showSubscribeBoxes) ? 'border-2 border-black font-bold' : 'border border-gray-300 hover:border-black hover:font-bold hover:border-2';
  const fullButtonId = `${buttonId}${showSubscribeBoxes ? '_subscribe' : ''}`;

  return (
    <button
      id={fullButtonId}
      disabled={isButtonDisabled}
      onClick={() => handleClick(fullButtonId)}
      className={`${wrapperClasses || 'w-full'} flex flex-col justify-between rounded-sm ${selectedClasses} ${backgroundColor} disabled:cursor-default ${
        showSubscribeBoxes ? 'technique-subscribe' : 'technique-view-info'
      }`}
      aria-pressed={isSelected || isTechniqueOpen}
    >
      <div className="flex flex-col grow justify-between p-2 w-full">
        <div className="mb-2 text-xs leading-5 text-left">
          {showSubscribeBoxes && (
            <>
              <CheckboxDisplay className="mt-0.5" isSelected={isSelected} />
              <span className="sr-only">{showSubscribeBoxes ? 'Subscribe to' : 'View more details for'} </span>
            </>
          )}
          {getTechniqueName(data.name)}
        </div>
        <div className="flex justify-between items-center w-full">
          <div className="flex items-center">
            {data.tId && <span className="text-2xs font-semibold">{data.tId}</span>}
            {data.sub_techniques && data.sub_techniques.length ? (
              <span className="inline-block ml-2 w-4 h-4 text-2xs font-semibold rounded-full border-xs border-black">{data.sub_techniques.length}</span>
            ) : null}
          </div>
          <div className="flex gap-1">
            <TechniqueStatusIcons isComplete={isComplete} hasContentAvailable={!!hasContentAvailable} hasContentComingSoon={!!hasContentComingSoon} />
          </div>
        </div>
      </div>
      <div className="overflow-hidden mx-auto w-full h-1 text-left bg-gray-400 rounded-lg">
        <span className="sr-only">Content in this technique is {data.progress}% complete</span>
        <PercentageBar color={isComplete ? '#2F855A' : '#df057b'} maxHeight="0.5rem" width={`${data.progress || 0}%`} />
      </div>
    </button>
  );
}

export default TechniqueCard;
