import React from 'react';
import ExampleSection from './ExampleSection';
import Header from '../../../components/Header/Header';
import LockedContainer from '../../../components/Container/LockedContainer';
import Code from '../../../components/Code/Code';

function LockedContainerExampleSection() {
  return (
    <ExampleSection title="LockedContainer" description="Wrapper that can lock access content">
      <Header as="h2">Basic</Header>
      <LockedContainer id="demo-content" msg="Verify your email to unlock this feature" isLocked>
        <div className="flex justify-center items-center p-6 w-150 h-60 bg-slate-200">
          <p className="pb-20 text-6xl">😍 💥 ⭐️ 🔥</p>
        </div>
      </LockedContainer>
      <Code>
        {`      
      <LockedContainer id="demo-content" msg="Verify your email to unlock this feature" isLocked>
        <div className="w-150 h-60 bg-violet-400">
          <p>HELLER</p>
        </div>
      </LockedContainer>
      `}
      </Code>
      {/* Grouped */}
      <Header as="h2">Grouped</Header>
      <div className="flex flex-row flex-wrap gap-2">
        {Array.from(Array(4).keys()).map((a) => (
          <LockedContainer id={`demo-content-${a}`} msg="Verify your email to unlock this feature" isLocked key={a}>
            <div className="p-6 w-100 h-60 text-center bg-slate-200">
              <p className="text-3xl">😍 💥 ⭐️ 🔥</p>
            </div>
          </LockedContainer>
        ))}
      </div>
      <Code>
        {`      
      <div className="flex flex-row flex-wrap gap-2">
        {Array.from(Array(4).keys()).map((a) => (
          <LockedContainer id={"demo-content-\`\${a}%\`"} msg="Verify your email to unlock this feature" isLocked key={a}>
            <div className="w-100 h-60 bg-violet-400">
              <p>HELLER</p>
            </div>
          </LockedContainer>
        ))}
      </div>
      `}
      </Code>
    </ExampleSection>
  );
}

export default LockedContainerExampleSection;
