import React from 'react';
import Label from '../Label/Label';
import Tooltip from '../Tooltip/Tooltip';

function UpdateLabel({ text, color, basic, className }) {
  return (
    <Label basic={basic} color={color} className={`text-xs px-2 py-1 rounded-sm ${className}`}>
      {text}
    </Label>
  );
}

function ContentUpdateLabel({ labelText, tooltipText, color = 'blue', className, basic }) {
  if (!labelText) {
    return null;
  }

  if (tooltipText) {
    return <Tooltip content={tooltipText} triggerContent={<UpdateLabel text={labelText} color={color} className={className} basic={basic} />} />;
  }

  return <UpdateLabel text={labelText} color={color} className={className} basic={basic} />;
}

export default ContentUpdateLabel;
