import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';

import { useAuth } from '../../contexts/UseAuth';
import agents from '../../agents/agents';
import ActionUtil from '../../utils/actionsUtil';
import LoadingPage from '../LoadingPage/LoadingPage';
/**
 * Intermediary logout page.
 */
const Logout = inject(
  'authStore',
  'commonStore'
)(
  observer(({ authStore, commonStore }) => {
    const auth = useAuth();
    useEffect(() => {
      // Hide navigation bar
      commonStore.hidePrimaryNav();
      ActionUtil.scrollToTop();

      authStore.fireAttributionEvent().then(async () => {
        // Logout through the backend first
        await agents.authGoogle.logout();

        // Then logout through the client
        auth.signout();
      });
    }, []);

    return <LoadingPage message="Logging out..." />;
  })
);

export default Logout;
