import React from 'react';

import ContentHeader from '../ContentHeader/ContentHeader';
import CertificationCtaButton from './CertificationCtaButton';

export default function CertificationHeader({
  contentType,
  contentTitle,
  contentImageUrl,
  commonStore,
  browseCertsPathUrl,
  ctaData,
  contentDescriptionId,
  averageRating,
  ratingCount,
  hasProgress,
  contentDescription,
}) {
  const permalink = 'certifications';
  return (
    <ContentHeader
      backLink={browseCertsPathUrl}
      buttons={<CertificationCtaButton data={ctaData} />}
      commonStore={commonStore}
      contentImageUrl={contentImageUrl}
      contentTitle={contentTitle}
      contentType={contentType}
      permalink={permalink}
      contentDescriptionId={contentDescriptionId}
      contentDescription={contentDescription}
      averageRating={averageRating}
      ratingCount={ratingCount}
      hasProgress={hasProgress}
      showShareButton
      showReviews
    />
  );
}
