import React from 'react';
import { twMerge } from 'tailwind-merge';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';

const sizesConfig = {
  md: 'max-w-240',
  'md-1164': 'max-w-291',
  'md-1280': 'max-w-screen-xl',
  lg: 'max-w-340',
  browse: 'max-w-1318px',
  'browse-refined': 'max-w-[1393px]',
  'cards-container': 'max-w-1056px',
  'card-container-v2': 'max-w-[1120px]',
  dashboard: 'max-w-400',
  xl: 'max-w-404',
  'xl-1648': 'max-w-412',
  'xl-1680': 'max-w-420',
  fluid: 'max-w-none',
  '1/3': 'w-full lg:w-1/3',
  '2/3': 'w-full lg:w-2/3',
  '1/5': 'w-full lg:w-1/5',
  '2/5': 'w-full lg:w-2/5',
  '3/5': 'w-full lg:w-3/5',
  '4/5': 'w-full lg:w-4/5',
  full: 'w-full',
};

function Container({ children, size, className, omitPadding, style, fluid, full, md, lg, xl, browse, dashboard }) {
  const classes = ['mx-auto', 'box-content', 'relative'];
  // props with no value are passed as 'true' booleans which allows us to pass size as a property
  // i.e. <Container fluid> will do the same thing as <Container size="fluid">
  if (fluid) {
    classes.push(sizesConfig.fluid);
  }
  if (full) {
    classes.push(sizesConfig.full);
  }
  if (md) {
    classes.push(sizesConfig.md);
  }
  if (lg) {
    classes.push(sizesConfig.lg);
  }
  if (xl) {
    classes.push(sizesConfig.xl);
  }
  if (browse) {
    classes.push(sizesConfig.browse);
  }
  if (dashboard) {
    classes.push(sizesConfig.dashboard);
  }
  if (className) {
    classes.push(className);
  }
  if (!omitPadding) {
    classes.push('px-4 md:px-8');
  }
  // size prop can be used to pass a string for a size match
  // not safe, prefer to use above props instead
  if (size) {
    const maxWidth = sizesConfig[size] || 'max-w-none';
    classes.push(maxWidth);
  }

  const classesMerged = twMerge(classes);

  return (
    <div className={classesMerged} style={style || {}}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </div>
  );
}

export default Container;
