import React from 'react';
import { PADDLE_DISCOUNT_ID_CIP_THREE_FOR_TWO } from '../../../constants';
import Icon from '../../Icon/Icon';

const THREE_FOR_TWO_PROMO = {
  // Enable or disable the promo
  isEnabled: false,

  // Show the promo based on the user's role/state
  isShownTeamLearner: false,
  isShownTeamAdmin: false,
  isShownTeamOwner: false,
  isShownCipFree: true,
  isShownCipMonthly: false,
  isShownCipAnnual: false,
  isShownCipBiennial: false,
  isShownCipEduGovMilFree: false,
  isShownCipEduGovMilMonthly: false,
  isShownCipEduGovMilAnnual: false,
  isShownCipEduGovMilBiennial: false,
  targetUrl: `/upgrade/checkout/quarterly?discountId=${PADDLE_DISCOUNT_ID_CIP_THREE_FOR_TWO}`,
  title: (
    <>
      October Special: <span className="text-xl group-hover:border-b-2">Get 3 months of access</span> for the price of 2!
    </>
  ),
  body: (
    <>
      Celebrate Cybersecurity Awareness Month with our <span className="text-xl font-bold group-hover:border-b-2">50% OFF</span> offer.
    </>
  ),
  footer: (
    <>
      Valid until October 31.
      <Icon name="growth" className="mx-2 w-4 h-4 fill-white" /> Elevate your skills today!
    </>
  ),
  iconName: 'unlock',
  spLabel: 'upgradeButtonThreeForTwo',
};

export default THREE_FOR_TWO_PROMO;
