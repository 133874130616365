import { useRef, useState, useEffect } from 'react';

function useCarousel({ autoScroll = false, autoScrollInterval = 5000 }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAutoScrolling, setIsAutoScrolling] = useState(autoScroll);
  const carouselItemWrapper = useRef(null);

  // inner width of an element in pixels. It includes padding but excludes borders, margins, and vertical scrollbars
  const [carouselContainerWidth, setCarouselContainerWidth] = useState(carouselItemWrapper?.current?.clientWidth || 0);

  // width of all content, including content not visible on the screen due to overflow.
  const [carouselTotalItemsWidth, setCarouselTotalItemsWidth] = useState(carouselItemWrapper?.current?.scrollWidth || 0);

  const [isPrevDisabled, setIsPrevDisabled] = useState(true);
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const [carouselMaxScrollWidth, setCarouselMaxScrollWidth] = useState(carouselTotalItemsWidth - carouselContainerWidth);

  const refresh = () => {
    const containerWidth = carouselItemWrapper.current?.clientWidth || 0;
    // Update carousel container width
    setCarouselContainerWidth(containerWidth);

    // Update carousel total items width
    const totalItemsWidth = carouselItemWrapper.current?.scrollWidth || 0;
    setCarouselTotalItemsWidth(totalItemsWidth);

    // Update carousel max scroll width
    const maxScrollWidth = totalItemsWidth - containerWidth;
    setCarouselMaxScrollWidth(maxScrollWidth);

    // Update prev / next buttons disabled state
    const disableNext = containerWidth * currentIndex > maxScrollWidth || maxScrollWidth === 0;
    setIsNextDisabled(disableNext);
    const disablePrev = currentIndex <= 0;
    setIsPrevDisabled(disablePrev);
  };

  useEffect(() => {
    refresh();
  }, [currentIndex, carouselItemWrapper?.current?.clientWidth, carouselItemWrapper?.current?.scrollWidth, carouselMaxScrollWidth]);

  const movePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevState) => prevState - 1);
    }
  };

  const moveNext = () => {
    if (carouselItemWrapper.current !== null && carouselContainerWidth * currentIndex <= carouselMaxScrollWidth) {
      setCurrentIndex((prevState) => prevState + 1);
    }
  };

  const toggleIsAutoScrolling = () => {
    setIsAutoScrolling((currentIsAutoScrolling) => !currentIsAutoScrolling);
  };

  useEffect(() => {
    if (carouselItemWrapper?.current !== null) {
      carouselItemWrapper.current.scrollLeft = carouselContainerWidth * currentIndex;
    }
  }, [currentIndex]);

  // handles auto scroll if the prop "auto scroll" is true.
  // We use the state variable "isAutoScrolling" b/c it can change if the user hovers over the carousel
  useEffect(() => {
    let timerId;
    if (isAutoScrolling) {
      const handleAutoScroll = () => {
        if (carouselItemWrapper.current !== null && carouselContainerWidth * currentIndex <= carouselMaxScrollWidth) {
          setCurrentIndex((prevState) => prevState + 1);
        } else {
          setCurrentIndex(0);
        }
      };
      timerId = setTimeout(handleAutoScroll, autoScrollInterval);
    }
    return () => {
      clearTimeout(timerId);
    };
  }, [currentIndex, isAutoScrolling]);

  /* pause autoScroll if hovering over the carousel */
  useEffect(() => {
    // if the target element exists, and the prop "auto scroll" is true... if its false we don't want to do any auto scrolling
    if (autoScroll && carouselItemWrapper?.current !== null) {
      // if we are on this section, stop auto scrolling
      carouselItemWrapper.current.addEventListener('mouseenter', toggleIsAutoScrolling);
      // if we leave this section, resume auto scrolling
      carouselItemWrapper.current.addEventListener('mouseleave', toggleIsAutoScrolling);

      return () => {
        // clean up the event listener when component is unmounted
        carouselItemWrapper.current?.removeEventListener('mouseenter', toggleIsAutoScrolling);
        carouselItemWrapper.current?.removeEventListener('mouseleave', toggleIsAutoScrolling);
      };
    }
    return () => {};
  }, [carouselItemWrapper.current]);

  const resetCurrentIndex = () => {
    setCurrentIndex(0);
  };

  return { movePrev, moveNext, isPrevDisabled, isNextDisabled, carouselItemWrapper, carouselMaxScrollWidth, refresh, resetCurrentIndex };
}

export default useCarousel;
