import React, { useEffect, useState } from 'react';
import Table from '../../components/Table/Table';
import SolidChip from '../../components/Chip/SolidChip';
import PieChart from '../../components/Charts/PieChart';
import If from '../../components/If/If';
import Icon from '../../components/Icon/Icon';

export default function OrganizationTrainingProgressDetails({ details, unit = 'hours', hideChart = false, hideInterval = false, onClose = null }) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (!details) {
      return;
    }
    // Set show to true if we are updating details
    setShow(true);
  }, [details]);

  const hide = () => {
    setShow(false);
    if (onClose) {
      onClose();
    }
  };

  if (!details || !show) {
    return null;
  }

  const unitLabel = unit === 'hours' ? 'Learning Hours' : 'XP';

  const { breakdown, category_term_name: categoryName, formattedPeriod } = details;
  const chartData = breakdown.map((row) => {
    const { topic_measure: value, topic_name: label } = row;
    return {
      id: label,
      label,
      value,
    };
  });

  const gridClasses = !hideChart ? 'grid grid-cols-1 sm:grid-cols-2' : '';

  return (
    <div className="relative mt-3 border-t-2 border-t-gray-100">
      <div className={gridClasses}>
        <div className="p-4">
          <div className="flex mb-4 space-x-4">
            <h3 className="font-bold">Detail</h3>
            <If condition={!hideInterval}>
              <SolidChip>{formattedPeriod}</SolidChip>
            </If>
            <SolidChip>{categoryName}</SolidChip>
          </div>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Topic</Table.HeaderCell>
                <Table.HeaderCell>{unitLabel}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {breakdown.map((row) => {
                const { topic_measure: measure, topic_name: topic } = row;
                return (
                  <Table.Row key={topic}>
                    <Table.Cell>{topic}</Table.Cell>
                    <Table.Cell>{measure}</Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </div>
        <If condition={!hideChart}>
          <div className="p-4">
            <div className="mb-2">
              <h3 className="font-bold">Distribution</h3>
            </div>
            <div className="h-[400px]">
              <PieChart data={chartData} />
            </div>
          </div>
        </If>
      </div>
      <If condition={!!onClose}>
        <div className="absolute top-3 right-2">
          <button aria-label="Dismiss Dialog" onClick={hide}>
            <Icon name="x" className="inline-block w-5 h-5 text-gray-600 cursor-pointer" />
          </button>
        </div>
      </If>
    </div>
  );
}
