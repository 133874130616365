import React from 'react';
import ExampleSection from './ExampleSection';
import Header from '../../../components/Header/Header';
import Code from '../../../components/Code/Code';
import UPTrigger from '../../../components/UPTrigger/UPTrigger';
import { USERPILOT_EVENTS } from '../../../constants';

function UPTriggerExampleSection() {
  return (
    <ExampleSection title="UPTrigger" description="A component that triggers a Userpilot guide when clicked.">
      {/** Default */}
      <Header as="h2">Default</Header>
      <UPTrigger guideId={USERPILOT_EVENTS.FINISHED_ONBOARDING} />
      <Code>{`<UPTrigger guideId={USERPILOT_EVENTS.FINISHED_ONBOARDING} />`}</Code>

      {/** Custom Text */}
      <Header as="h2">Custom Text</Header>
      <UPTrigger guideId={USERPILOT_EVENTS.VIEWED_BASELINE_INDIVIDUAL_REPORT} text="Click for help" />
      <Code>{`<UPTrigger guideId={USERPILOT_EVENTS.VIEWED_BASELINE_INDIVIDUAL_REPORT} text="Click for help" />`}</Code>

      {/** Custom Icon */}
      <Header as="h2">Custom Icon</Header>
      <UPTrigger guideId={USERPILOT_EVENTS.IMMERSIVE_STARTED_ACTIVITY} iconClassName="w-6 h-6 text-blue-500" />
      <Code>{`<UPTrigger guideId={USERPILOT_EVENTS.IMMERSIVE_STARTED_ACTIVITY} iconClassName="w-6 h-6 text-blue-500" />`}</Code>

      {/** With onClick Handler */}
      <Header as="h2">With onClick Handler</Header>
      <UPTrigger guideId={USERPILOT_EVENTS.VIEWED_TEAM_DASHBOARD} onClick={() => {}} />
      <Code>
        {`
<UPTrigger 
  guideId={USERPILOT_EVENTS.VIEWED_TEAM_DASHBOARD} 
  onClick={() => {}}
/>
        `}
      </Code>
    </ExampleSection>
  );
}

export default UPTriggerExampleSection;
