import { useEffect, useState } from 'react';
import queryString from 'query-string';
import Bugsnag from '@bugsnag/js';
import { isEmpty } from 'lodash';
import Agents from '../agents/agents';

/**
 * useTableData hook to manage table data state
 * @param {object} defaultTableData
 * @param {(string|object)} agent - string|{ id: string, name: string }
 * @param {object} runTableEffects - { onMount: boolean, disableEffect: boolean }
 * @returns {object}
 */
const useTableData = (defaultTableData, agent, agentObj = 'admin', runTableEffects = { onMount: false, disableEffect: false }) => {
  const { onMount, disableEffect } = runTableEffects;
  const [init, setInit] = useState(false);
  const [tableData, setTableData] = useState(defaultTableData.table || {});
  const [queryParams, setQueryParams] = useState(defaultTableData.query || {});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [tempQueryString, setTempQueryString] = useState(null);

  /**
   * Make agent request call
   * @param {string} queryData
   * @returns
   */
  const callAgent = (queryData) => {
    if (typeof agent === 'string') {
      return Agents[agentObj][agent](queryData);
    }

    const { id, name } = agent;
    if (!name) Promise.reject();

    return id ? Agents[agentObj][name](id, queryData) : Agents[agentObj][name](queryData);
  };

  /**
   * Get table data
   * @async
   */
  const getTableData = async () => {
    const paramKeys = Object.keys(queryParams);
    // Seperate out queryparams that contain arrays of values vs those that are just simple key value pairs
    const arrayParams = [];
    const params = {};
    paramKeys.forEach((key) => {
      const param = queryParams[key];
      if (Array.isArray(param)) {
        arrayParams.push([key, param]);
      } else {
        params[key] = param;
      }
    });
    // Build the query string - First with the key value pairs
    let queryData = !isEmpty(queryParams) ? `?${queryString.stringify(params)}` : '';
    // Next, If any params containing arrays exist, loop through those and build the query string array in a format of 'key[]=val1&key[]=val2
    if (arrayParams.length) {
      arrayParams.forEach((param) => {
        const [key, val] = param;
        const paramString = val.reduce((string, currVal) => `${string}${string.length ? '&' : ''}${key}[]=${currVal}`, '');
        queryData += `${isEmpty(queryData) ? '?' : '&'}${paramString}`;
      });
    }

    if (tempQueryString === queryData) return;
    setLoading(true);
    try {
      setError(null);
      const response = await callAgent(queryData);
      setTableData({
        data: response.tableData,
        totalRecords: response.totalRecords,
        tableHeadings: response.columns,
        recordsCount: response.totalRecords.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','), // number with commas
        pagPagesCount: Math.ceil(response.totalRecords / queryParams.recordsPerPage),
      });
      if (queryData) {
        setTempQueryString(queryData);
      }
    } catch (err) {
      Bugsnag.notify(err);
      setError(err.response || err);
    }
    setLoading(false);
  };

  /**
   * Reset table data and params
   */
  const resetTable = () => {
    setTableData(null);
    setQueryParams(defaultTableData.query);
    setTempQueryString(null);
    setError(null);
  };

  /**
   * Set sort params on query
   * @param {*} heading
   */
  const sort = (heading) => {
    if (!heading) return;
    if (heading === queryParams.sortCol) {
      setQueryParams({ ...queryParams, activePg: 1, sortDirection: queryParams.sortDirection === 'desc' ? 'asc' : 'desc' });
    } else {
      setQueryParams({ ...queryParams, activePg: 1, sortCol: heading, sortDirection: 'desc' });
    }
  };

  /**
   * Sets page number param from pagination.
   */
  const changePage = (pg) => {
    setQueryParams({ ...queryParams, activePg: pg });
  };

  /**
   * GetTableData effect
   * Get data on query update (sort, activePage, searchQuery).
   */
  useEffect(() => {
    if ((init || onMount) && !disableEffect) {
      getTableData();
    }
  }, [init, queryParams, onMount, disableEffect]);

  /**
   * Set first init for first mount and reset table on component unmount.
   */
  useEffect(() => {
    setInit(true);
    return () => resetTable();
  }, []);

  return { init, tableData, getTableData, resetTable, loading, error, queryParams, setQueryParams, sort, changePage };
};

export default useTableData;
