import React, { useState, useEffect } from 'react';
import { ClockIcon } from '@heroicons/react/outline';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import Box from './box';
import BonusFlag from './bonus-flag';
import { convertSecsToHHMMSS, getColorFromScore } from '../../utils/labUtil';

function FlagData({ items }) {
  const [groupedItems, setGroupedItems] = useState(null);
  useEffect(() => {
    if (!items || !items.length) {
      return;
    }
    const newGroupedItems = [];
    const itemIndices = [];
    items.forEach((item) => {
      const name = item['source-name'];
      // Skip Cybrary
      if (name === 'CYBRARY') {
        return;
      }
      let index = itemIndices.indexOf(name);
      if (index === -1) {
        // Initialize our group
        index = itemIndices.length;
        itemIndices.push(name);
        newGroupedItems.push({
          name,
          data: [],
        });
      }
      newGroupedItems[index].data.push(item);
    });
    setGroupedItems(newGroupedItems);
  }, [items]);

  if (!groupedItems) {
    return null;
  }
  return (
    <div className="my-6">
      {groupedItems.map((item) => (
        <div key={item.name} className="mb-6">
          <p className="inline-block p-2 mb-2 text-blue-700 bg-blue-200 rounded-sm text-md">{item.name}</p>
          {item.data.map((subitem) => (
            <p key={subitem.identifier} className="mb-1">{`${subitem.identifier}: ${subitem.title}`}</p>
          ))}
        </div>
      ))}
    </div>
  );
}

function Flag({ flag }) {
  const [open, setOpen] = useState(false);

  const { name, points, pointsEarned = 0, bonus, time, items } = flag;
  const bgColor = getColorFromScore(points, pointsEarned);
  return (
    <Box key={name}>
      <div className="grid grid-cols-12 justify-center align-middle">
        <div className="col-span-1">
          <div className={`w-7 h-7 rounded-full ${bgColor}`} />
        </div>
        <div className="col-span-6">
          <p className="text-xl font-semibold">
            {name}
            {!!bonus && <BonusFlag className="ml-4" />}
          </p>
        </div>
        <div className="col-span-2">
          <p className="text-xl text-right">
            Score:
            <span className="ml-2 font-bold">
              {pointsEarned}/{points}
            </span>
          </p>
        </div>
        <div className="col-span-2">
          <p className="text-xl text-right">
            <ClockIcon className="inline -mt-1 mr-2 w-7 h-7" />
            {time}
          </p>
        </div>
        <div className="col-span-1 justify-end text-right">
          {!open && <ChevronDownIcon onClick={() => setOpen(true)} className="inline w-7 h-7 cursor-pointer" />}
          {open && <ChevronUpIcon onClick={() => setOpen(false)} className="inline w-7 h-7 cursor-pointer" />}
        </div>
      </div>
      {open && <FlagData items={items} />}
    </Box>
  );
}

export default function FlagsSummary({ flags }) {
  const formattedFlags = flags.map((flag) => ({
    name: flag.title,
    points: flag['points-available'],
    pointsEarned: flag['points-earned'],
    time: convertSecsToHHMMSS(flag['time-seconds-elapsed']),
    bonus: flag.bonus,
    items: flag['ctax-list'],
  }));

  return (
    <div>
      <p className="mb-4 text-lg">Activities Summary</p>
      <div className="space-y-4">
        {formattedFlags.map((flag) => (
          <Flag key={flag.name} flag={flag} />
        ))}
      </div>
    </div>
  );
}
