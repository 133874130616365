import Cookies from 'js-cookie';
import moment from 'moment';
import { DEFAULT_COOKIE_SETTINGS } from '../constants';

export default class NotificationUtil {
  /**
   * Generates an object containing teams and the curricula notifications per team
   */
  static getCurriculaNotifications = (cybraryCurriculaUpdates, teamCurriculaUpdates, userCurriculaUpdates, teams) => {
    const teamNotifications = {};
    // If no recent updates, no notifications to show
    if (
      (!cybraryCurriculaUpdates || !cybraryCurriculaUpdates.length) &&
      (!teamCurriculaUpdates || !Object.keys(teamCurriculaUpdates).length) &&
      (!userCurriculaUpdates || !Object.keys(userCurriculaUpdates).length)
    ) {
      return teamNotifications;
    }

    // Loop over the teams
    Object.keys(teams).forEach((team) => {
      // See if there's a cookie set for this team for previously dismissing a notification
      const cybraryCurriculaCookieName = `notification_cybrary-path-update_${team}`;
      const cybraryCurriculaCookieVal = Cookies.get(cybraryCurriculaCookieName);
      const teamCurriculaCookieName = `notification_team-path-update_${team}`;
      const teamCurriculaCookieVal = Cookies.get(teamCurriculaCookieName);
      const userCurriculaCookieName = `notification_user-path-update_${team}`;
      const userCurriculaCookieVal = Cookies.get(userCurriculaCookieName);
      let cybraryCurriculaUpdateCount = 0;
      let teamCurriculaUpdateCount = 0;
      let userCurriculaUpdateCount = 0;
      // Check for updates for Cybrary Curricula
      if (cybraryCurriculaUpdates && cybraryCurriculaUpdates.length) {
        cybraryCurriculaUpdateCount = NotificationUtil.getCurriculaUpdatesCount(cybraryCurriculaCookieVal, cybraryCurriculaUpdates, 'scheduled_at');
      }
      // Check for updates for Team Curricula
      if (!!teamCurriculaUpdates && teamCurriculaUpdates[team] && teamCurriculaUpdates[team].length) {
        teamCurriculaUpdateCount = NotificationUtil.getCurriculaUpdatesCount(teamCurriculaCookieVal, teamCurriculaUpdates[team], 'content_replaced_at');
      }

      // Check for updates for user Curricula
      if (!!userCurriculaUpdates && userCurriculaUpdates[team] && userCurriculaUpdates[team].length) {
        userCurriculaUpdateCount = NotificationUtil.getCurriculaUpdatesCount(userCurriculaCookieVal, userCurriculaUpdates[team], 'content_replaced_at');
      }

      if (cybraryCurriculaUpdateCount || teamCurriculaUpdateCount) {
        teamNotifications[team] = {
          cybrary: cybraryCurriculaUpdateCount,
          team: teamCurriculaUpdateCount,
          user: userCurriculaUpdateCount,
          updates: cybraryCurriculaUpdateCount + teamCurriculaUpdateCount + userCurriculaUpdateCount,
        };
      }
    });
    return teamNotifications;
  };

  /**
   * Helper function to get the count of curricula updates (custom or Cybrary)
   * Checks cookie value for last dismissal of the notication and compares that to the notification date
   */
  static getCurriculaUpdatesCount = (cookieVal, updates, updateKey) => {
    let count = 0;
    // If no cookie for this team - They get all update notifications
    if (!cookieVal) {
      count = updates.length;
    } else {
      // Loop over updates and see if there's an update since last dismissal
      updates.forEach((update) => {
        // If the update has been processed
        if (update.processed) {
          const updateUnix = moment.utc(update[updateKey]).unix();
          // If the update date is after the last update dismissal, need to push/show this update
          if (updateUnix > 1 * cookieVal) {
            count += 1;
          }
        }
      });
    }
    return count;
  };

  /**
   * Checks to see if there is an assignment notification from a cybrary curriculum update and return count
   * Looks at user's assignments to see if it contains an updated cybrary curriculum and then compares to previous dismissal dates
   */
  static getAssignmentNotificationsCount = (curriculaUpdates, assignments) => {
    let assignmentNotifications = 0;
    // If no recent updates, or no assignments - no notification
    if (!curriculaUpdates || !assignments) {
      return assignmentNotifications;
    }

    const cookieName = `notification_enrollments-updates`;
    const cookieVal = Cookies.get(cookieName);
    // Need to loop over assignments and see if there are any that contain a cybrary curricula that's been updated
    assignments.forEach((assignment) => {
      curriculaUpdates.forEach((update) => {
        if (update.new_content_description_id === assignment.content_description_id && update.processed && update.scheduled_at) {
          const updateUnix = moment.utc(update.scheduled_at).unix();
          // If the update date is after the last update dismissal, need to push/show this update
          if (!cookieVal || updateUnix > 1 * cookieVal) {
            assignmentNotifications++;
          }
        }
      });
    });
    return assignmentNotifications;
  };

  /**
   * Checks to see if there is an enrollment notification from a replaced enrollment and return count
   */
  static getEnrollmentsReplacedNotificationsCount = (enrollmentsReplaced) => {
    // If no records, false
    if (!enrollmentsReplaced) {
      return 0;
    }

    const cookieName = `notification_enrollments-updates`;
    const cookieVal = Cookies.get(cookieName);
    let enrollmentsReplacedNotification = 0;
    enrollmentsReplaced.forEach((enrollment) => {
      // No notification if it hasn't been processed, or the course was archived (currently we don't show archived courses, so nowhere to show the notification)
      if (enrollment.processed && !enrollment.settings.archive) {
        const updateUnix = moment.utc(enrollment.scheduled_at).unix();
        // If the update date is after the last update dismissal, need to push/show this update
        if (!cookieVal || updateUnix > 1 * cookieVal) {
          enrollmentsReplacedNotification++;
        }
      }
    });
    return enrollmentsReplacedNotification;
  };

  /**
   * Sets a cookie with the current datetime for when users dismiss notifications - to prevent them from re-appearing
   */
  static dismissNotification = (id) => {
    const currentUTC = moment().unix();
    Cookies.set(id, currentUTC, {
      ...DEFAULT_COOKIE_SETTINGS,
      path: '/',
      expires: 730,
    });
  };

  /**
   * Transforms an array into an obj
   */
  static transformContentNotifications = (arr, keyName) => {
    if (!arr) {
      return {};
    }
    const notificationObj = {};
    arr.forEach((item) => {
      notificationObj[item[keyName]] = item;
    });
    return notificationObj;
  };

  static checkContentUpdateComingSoon = (data) => {
    return !!data && !!data.settings && !!data.settings.new_content_status && data.settings.new_content_status === 'Coming Soon';
  };
}
