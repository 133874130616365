import { useState } from 'react';

/** Hook to get selected technique from mitre catalog */
const useSelectTechnique = ({ techniqueSideSectionData, setTechniqueData, resetTechniqueData }) => {
  const [selectedTechnique, setSelectedTechnique] = useState(null);

  const getTechniqueData = (data) => {
    if (!techniqueSideSectionData || (techniqueSideSectionData && techniqueSideSectionData.id !== data.id)) {
      setTimeout(() => {
        setTechniqueData(data);
      }, 350);
    }
  };

  const handleTechniqueSelected = (techniqueId) => {
    setSelectedTechnique(techniqueId);
  };

  const closeSlideout = () => {
    resetTechniqueData();
    setSelectedTechnique(null);
  };

  return { selectedTechnique, handleTechniqueSelected, getTechniqueData, closeSlideout };
};

export default useSelectTechnique;
