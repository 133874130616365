import React, { useState } from 'react';
import ExampleSection from './ExampleSection';
import Header from '../../../components/Header/Header';
import Code from '../../../components/Code/Code';
import Modal from '../../../components/Modal/Modal';
import Button from '../../../components/Button/Button';

function ModalExampleSection() {
  const [isBasicModalOpen, setIsBasicModalOpen] = useState(false);
  const [isCustomModalOpen, setIsCustomModalOpen] = useState(false);
  const [isPositionedModalOpen, setIsPositionedModalOpen] = useState(false);

  return (
    <ExampleSection title="Modal" description="A flexible modal component with various customization options.">
      {/* Basic Modal */}
      <Header as="h2">Basic Modal</Header>
      <Button onClick={() => setIsBasicModalOpen(true)}>Open Basic Modal</Button>
      <Modal open={isBasicModalOpen} toggle={() => setIsBasicModalOpen(false)} header="Basic Modal">
        <p>This is a basic modal with a header and default styling.</p>
      </Modal>
      <Code>
        {`
const [isBasicModalOpen, setIsBasicModalOpen] = useState(false);

<Button onClick={() => setIsBasicModalOpen(true)}>Open Basic Modal</Button>
<Modal
  open={isBasicModalOpen}
  toggle={() => setIsBasicModalOpen(false)}
  header="Basic Modal"
>
  <p>This is a basic modal with a header and default styling.</p>
</Modal>
        `}
      </Code>

      {/* Custom Styled Modal */}
      <Header as="h2">Custom Styled Modal</Header>
      <Button onClick={() => setIsCustomModalOpen(true)}>Open Custom Modal</Button>
      <Modal
        open={isCustomModalOpen}
        toggle={() => setIsCustomModalOpen(false)}
        header="Custom Modal"
        size="lg"
        transparentBg
        className="bg-blue-100 border-2 border-blue-500"
        closeIconColor="text-blue-500"
      >
        <p>This modal has custom styling applied to it.</p>
      </Modal>
      <Code>
        {`
const [isCustomModalOpen, setIsCustomModalOpen] = useState(false);

<Button onClick={() => setIsCustomModalOpen(true)}>Open Custom Modal</Button>
<Modal
  open={isCustomModalOpen}
  toggle={() => setIsCustomModalOpen(false)}
  header="Custom Modal"
  size="lg"
  transparentBg
  className="bg-blue-100 border-2 border-blue-500"
  closeIconColor="text-blue-500"
>
  <p>This modal has custom styling applied to it.</p>
</Modal>
        `}
      </Code>

      {/* Positioned Modal */}
      <Header as="h2">Positioned Modal</Header>
      <Button onClick={() => setIsPositionedModalOpen(true)}>Open Positioned Modal</Button>
      <Modal open={isPositionedModalOpen} toggle={() => setIsPositionedModalOpen(false)} position="top right" size="sm" omitCloseX>
        <p>This modal is positioned in the top right corner.</p>
        <Button onClick={() => setIsPositionedModalOpen(false)}>Close</Button>
      </Modal>
      <Code>
        {`
const [isPositionedModalOpen, setIsPositionedModalOpen] = useState(false);

<Button onClick={() => setIsPositionedModalOpen(true)}>Open Positioned Modal</Button>
<Modal
  open={isPositionedModalOpen}
  toggle={() => setIsPositionedModalOpen(false)}
  position="top right"
  size="sm"
  omitCloseX
>
  <p>This modal is positioned in the top right corner.</p>
  <Button onClick={() => setIsPositionedModalOpen(false)}>Close</Button>
</Modal>
        `}
      </Code>
    </ExampleSection>
  );
}

export default ModalExampleSection;
