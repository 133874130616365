import React from 'react';

/* Mock checkbox so we aren't using real checkboxes (inputs) inside of buttons/other possibly conflicting elements */
function CheckboxDisplay({ isSelected, className, isRadio }) {
  const roundedClass = isRadio ? 'rounded-full' : 'rounded-sm';
  return (
    <span
      aria-hidden="true"
      className={`${className} ${roundedClass} w-4 h-4 mr-1 border border-gray-700 float-left flex items-center justify-center ${isSelected ? 'bg-black' : 'bg-white'}`}
    >
      {isSelected ? <span className="text-base font-semibold leading-4 text-white">✓</span> : null}
    </span>
  );
}

export default CheckboxDisplay;
