import React, { useState } from 'react';
import moment from 'moment';
import ChevronLeft from '../../Icons/ChevronLeft';
import Table from '../Table/Table';
import ChevronRight from '../../Icons/ChevronRight';
import Button from '../Button/Button';
import Loading from '../Loading/Loading';
import WidgetContainer from '../Container/WidgetContainer';
import Header from '../Header/Header';

function CybraryAdminSendgridEvents(props) {
  const [page, setPage] = useState(1);

  const pageSize = 10;
  if (!props.events || !props.events.length) {
    return (
      <WidgetContainer className="mt-6" omitBorder omitBackground>
        <Header as="h2">Email Events</Header>
        {!props.events ? <Loading message="Loading..." /> : <div>No email events in the last 30 days</div>}
      </WidgetContainer>
    );
  }

  const maxPages = Math.floor((props.events.length + pageSize - 1) / pageSize);

  const updatePage = (count) => {
    const curPage = page;
    let newPage = curPage + count;
    if (newPage < 1) {
      newPage = 1;
    }
    if (newPage > maxPages) {
      newPage = maxPages;
    }
    setPage(newPage);
  };
  const renderCategories = (event) => {
    if (!event || !event.category || !event.category.length) {
      return null;
    }
    return event.category.map((category) => {
      return (
        <span key={`${event.id}-${category}`} className="p-2 mr-1 text-sm rounded border border-green-500">
          {category}
        </span>
      );
    });
  };

  const renderEventType = (eventType) => {
    let color = '';
    switch (eventType) {
      case 'deferred':
        color = 'border-yellow-500 text-yellow-500';
        break;
      case 'processed':
        color = 'border-gray-500 text-gray-500';
        break;
      case 'delivered':
        color = 'border-green-500 text-green-500';
        break;
      case 'spamreport':
      case 'dropped':
      case 'bounce':
        color = 'border-red-500 text-red-500';
        break;
      default:
        color = 'border-black-500 text-black-500';
        break;
    }
    return <span className={`p-2 rounded text-sm border ${color} mr-1`}>{eventType}</span>;
  };
  const start = (page - 1) * pageSize;
  const end = start + pageSize;
  const curPageEvents = props.events.reverse().slice(start, end);

  return (
    <WidgetContainer className="mt-6">
      <div className="flex justify-between items-center mb-5">
        <h2 className="text-3xl font-bold">Email Events</h2>
      </div>
      <div>
        <Table className="my-4">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>Sent to</Table.HeaderCell>
              <Table.HeaderCell>Event</Table.HeaderCell>
              <Table.HeaderCell>Categories</Table.HeaderCell>
              <Table.HeaderCell>Date Sent</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          {curPageEvents.map((event) => (
            <Table.Body key={event.id}>
              <Table.Row>
                <Table.Cell>{event.id}</Table.Cell>
                <Table.Cell>{event.email}</Table.Cell>
                <Table.Cell>{renderEventType(event.event)}</Table.Cell>
                <Table.Cell>{renderCategories(event)}</Table.Cell>
                <Table.Cell>{moment(event.timestamp).format('M/D/YYYY')}</Table.Cell>
              </Table.Row>
            </Table.Body>
          ))}
        </Table>
        <div className="inline">
          <Button disabled={page === 1} onClick={() => updatePage(-1)}>
            <ChevronLeft />
          </Button>
          <Button disabled={page === maxPages} onClick={() => updatePage(1)}>
            <ChevronRight />
          </Button>
        </div>
      </div>
    </WidgetContainer>
  );
}

export default CybraryAdminSendgridEvents;
