import React from 'react';
import fieldClasses from './fieldClasses';
import Label from './Label';

class TextToggle extends React.Component {
  state = {
    isHidden: true,
    value: '',
    name: '',
  };

  componentDidMount() {
    if (this.props.defaultValue) {
      this.handleChange(this.props.defaultValue);
    }
  }

  handleChange = (value) => {
    const currState = {
      ...this.state,
      name: this.props.name,
      value,
    };
    this.props.onChange(undefined, { name: this.props.name, value: currState.value });
    this.setState(currState);
  };

  toggleIsHidden = () => {
    const newState = { ...this.state, isHidden: !this.state.isHidden };
    if (!this.state.isHidden) {
      newState.value = '';
      document.getElementById(this.props.id).value = '';
      this.handleChange(newState.value);
    }
    this.setState(newState);
  };

  render() {
    const { id, placeholder, name, disabled, error, ariaDescribedBy, className, labelClass, label, required, tooltip, trigger, style } = this.props;
    const isHidden = this.state.isHidden && !this.state.defaultValue && !this.state.value;
    const isDisabled = disabled || false;
    const { value } = this.state;
    return (
      <>
        <button onClick={this.toggleIsHidden} className={className || ''}>
          <Label id={id} label={label} required={required} tooltip={tooltip} trigger={trigger} style={style} className={labelClass} isHidden={isHidden} toggle />
        </button>

        <input
          disabled={isDisabled}
          id={id || name}
          className={`${fieldClasses(error, disabled)} ${isHidden ? 'hidden' : 'block'}`}
          type="text"
          placeholder={placeholder}
          value={value}
          onFocus={null}
          onBlur={null}
          aria-required={required}
          aria-describedby={ariaDescribedBy}
          onChange={(e) => this.handleChange(e.target.value)}
        />
      </>
    );
  }
}

export default TextToggle;
