import React, { useState } from 'react';
import Bugsnag from '@bugsnag/js';
import Modal from '../Modal/Modal';
import Icon from '../Icon/Icon';
import Divider from '../Divider/Divider';
import DynamicForm from '../DynamicForm/DynamicForm';
import AdminBulkImport from '../AdminBulkImport/AdminBulkImport';
import Agents from '../../agents/agents';
import FormatUtil from '../../utils/formatUtil';
import Button from '../Button/Button';
import If from '../If/If';
import { APP_FORM_IDS } from '../../constants';

const teamRoles = [
  {
    key: 'team-member',
    text: 'Member',
    value: 'team-member',
  },
  {
    key: 'team-admin',
    text: 'Admin',
    value: 'team-admin',
  },
  {
    key: 'team-reporting-admin',
    text: 'Reporting Admin',
    value: 'team-reporting-admin',
  },
];

const licenseOptions = [
  {
    key: 'licensed',
    text: 'Licensed',
    value: 'licensed',
  },
  {
    key: 'unlicensed',
    text: 'Unlicensed',
    value: 'unlicensed',
  },
];

const getEmailsPayload = (data, omitSeatGrant) => {
  const emails = [];
  if (data.emailList) {
    const emailsArr = data.emailList.split(',');
    emailsArr.forEach((email) => {
      if (!!email && email.length) {
        emails.push({
          email: email.trim(),
          role: data.role,
          grant_seat: omitSeatGrant ? null : data.license === 'licensed',
        });
      }
    });
  } else {
    for (let i = 1; i <= 3; i++) {
      const email = data[`email${i}`];
      if (!!email && email.length) {
        emails.push({
          email,
          role: data[`role${i}`],
          grant_seat: omitSeatGrant ? null : data[`license${i}`] === 'licensed',
        });
      }
    }
  }
  return emails;
};

const copyToClipboard = (e) => {
  e.preventDefault();

  const target = e.target.value;
  const input = target ? document.querySelector('#copyToClipboard') : null;
  input.focus();
  input.select();

  try {
    document.execCommand('copy');
  } catch (err) {
    Bugsnag.notify(err);
  }
};

const getLicenseVal = (autoGrantSeats) => {
  return autoGrantSeats ? 'licensed' : 'unlicensed';
};

// Adds a comma delimited input field of Email addresses to add more than 3 emails at once
const getEmailListForm = (autoGrantSeats, toggleForm, omitLicenseSelection) => {
  return {
    order: ['emailList', 'role', 'license', 'add', 'submit'],
    fields: {
      emailList: {
        type: 'textarea',
        placeholder: 'Add a comma delimited list of email addresses',
        label: 'Comma separated list of email addresses',
        labelClass: 'sr-only',
      },
      role: {
        type: 'select',
        placeholder: 'Choose role',
        options: teamRoles,
        defaultValue: 'team-member',
        label: 'Role',
        labelClass: 'sr-only',
      },
      license: {
        type: 'select',
        options: licenseOptions,
        defaultValue: getLicenseVal(autoGrantSeats),
        label: 'License',
        labelClass: 'sr-only',
        hidden: omitLicenseSelection,
      },
      add: {
        type: 'insert',
        insertComponent: (
          <button onClick={toggleForm} type="button" className="inline-block py-2.5 px-5 mt-6 text-sm font-bold bg-gray-200 rounded-sm">
            Add individual emails
          </button>
        ),
      },
      submit: {
        type: 'button',
        label: 'Send Invitations',
        color: 'pink',
      },
    },
  };
};

const getInviteForm = (autoGrantSeats, toggleForm, omitLicenseSelection, isDemo) => {
  return {
    order: [
      ['email1', 'role1', 'license1'],
      ['email2', 'role2', 'license2'],
      ['email3', 'role3', 'license3'],
      ['submit', 'add'],
    ],
    fields: {
      email1: {
        type: 'email',
        placeholder: 'name@example.com',
        label: 'Email 1',
        labelClass: 'sr-only',
      },
      role1: {
        type: 'select',
        placeholder: 'Choose role',
        options: teamRoles,
        defaultValue: 'team-member',
        label: 'Role 1',
        labelClass: 'sr-only',
      },
      license1: {
        type: 'select',
        options: licenseOptions,
        defaultValue: getLicenseVal(autoGrantSeats),
        label: 'License 1',
        labelClass: 'sr-only',
        hidden: omitLicenseSelection,
      },
      email2: {
        type: 'email',
        placeholder: 'name@example.com',
        label: 'Email 2',
        labelClass: 'sr-only',
      },
      role2: {
        type: 'select',
        placeholder: 'Choose role',
        options: teamRoles,
        defaultValue: 'team-member',
        label: 'Role 2',
        labelClass: 'sr-only',
      },
      license2: {
        type: 'select',
        options: licenseOptions,
        defaultValue: getLicenseVal(autoGrantSeats),
        label: 'License 2',
        labelClass: 'sr-only',
        hidden: omitLicenseSelection,
      },
      email3: {
        type: 'email',
        placeholder: 'name@example.com',
        label: 'Email 3',
        labelClass: 'sr-only',
      },
      role3: {
        type: 'select',
        placeholder: 'Choose role',
        options: teamRoles,
        defaultValue: 'team-member',
        label: 'Role 3',
        labelClass: 'sr-only',
      },
      license3: {
        type: 'select',
        options: licenseOptions,
        defaultValue: getLicenseVal(autoGrantSeats),
        label: 'License 3',
        labelClass: 'sr-only',
        hidden: omitLicenseSelection,
      },
      add: {
        type: 'insert',
        insertComponent: (
          <>
            <Divider horizontal className="flex my-8 md:hidden">
              <p>OR</p>
            </Divider>
            <button
              onClick={toggleForm}
              disabled={isDemo}
              type="button"
              className={`block w-full md:w-auto ml-auto p-2 py-2.5 px-5 text-sm font-bold bg-gray-200 rounded-sm ${isDemo ? 'cursor-not-allowed' : 'cursor-pointer'}`}
            >
              Bulk upload CSV
            </button>
          </>
        ),
      },
      submit: {
        type: 'button',
        label: 'Send Invitations',
        color: 'pink',
        className: isDemo ? 'cursor-not-allowed' : '',
        disabled: isDemo,
      },
    },
  };
};

function TemplateOption({ children }) {
  return <code className="p-1 text-sm bg-gray-200">{children}</code>;
}

function BulkInviteTools({ orgId, teamName, triggerToast, isGroupScope, handleSendInvite, autoGrantSeats, toggleForm, omitLicenseSelection }) {
  const getGroupsExportCSV = async () => {
    try {
      const teamHierarchy = await Agents.admin.getTeamHierarchy(orgId, '?format=csv');
      FormatUtil.downloadCSV(teamHierarchy, `${FormatUtil.lowerCaseHyphenText(teamName || orgId)}_groups.csv`);
    } catch (e) {
      Bugsnag.notify(e);
      triggerToast('error', { content: 'Something went wrong. Unable to retrieve team groups at this time.' });
    }
  };

  if (isGroupScope) {
    return (
      <>
        <h3 className="text-lg font-bold text-black">Invite via email address</h3>
        <DynamicForm
          formId={APP_FORM_IDS.TEAM.INVITE}
          formName="modInvitationForm"
          form={getEmailListForm(autoGrantSeats, toggleForm, omitLicenseSelection)}
          onSubmit={handleSendInvite}
          customClassName="invite-form-list"
        />
      </>
    );
  }

  return (
    <div>
      <h3 className="text-lg font-bold text-black">Bulk User Invite</h3>
      <p className="text-sm lg:mb-6">
        Download and complete the CSV template to invite multiple Members at once. This template will also allow you to create and assign up to 5 Groups for new Members. To add
        additional Groups for existing Members, use the Add Group function.
      </p>
      <h4 className="text-lg font-bold text-black">Template Requirements</h4>
      <p className="mb-6 text-sm">
        <b>Email</b>, <b>Role</b> (<TemplateOption>Owner</TemplateOption>, <TemplateOption>Admin</TemplateOption>, <TemplateOption>Reporting-Admin</TemplateOption>,{' '}
        <TemplateOption>Member</TemplateOption>), and <b>License</b> (<TemplateOption>Yes</TemplateOption>, <TemplateOption>No</TemplateOption>) must be completed for the Bulk
        Import to process. When adding <b>Groups</b>, <b>Group Role</b> options are <TemplateOption>Admin</TemplateOption>, <TemplateOption>Reporting-Admin</TemplateOption>, or{' '}
        <TemplateOption>Member</TemplateOption>.
      </p>
      <AdminBulkImport
        teamId={orgId}
        importTypeDisplay="invite" // For UI display purposes
        formConfig={{
          formName: 'bulkUserInvite',
          submitText: 'Bulk Invite',
          instructions: (
            <div className="-mt-4 mb-4 text-sm ">
              <p className="mb-0 ">Upload the completed CSV template and click “Bulk Invite” to send many team invitations.</p>
              <p className="my-1 font-semibold">
                To view the Group IDs, download the{' '}
                <button className="text-cyb-pink-500 hover:underline" onClick={() => getGroupsExportCSV()}>
                  Current Groups Report <Icon name="download" className="inline-block w-4 h-4" />
                </button>
                .
              </p>
            </div>
          ),
        }}
        importConfig={{
          templateFileName: 'cybrary_user_invite',
          routesObject: 'enterprise',
          importType: 'team-invite-v2', // For classification of import type in routes
          dataKey: 'import', // Post data key that CSV will be included in
          confirmText: 'Are you sure you want to invite these users? The Bulk Invite function will send Teams invitations to every email in the CSV template.',
          logDescription: 'Review and download past Bulk Invite CSVs.',
        }}
      />
    </div>
  );
}

function FormInviteModal({
  modalOpen,
  modalClose,
  url,
  groupId,
  orgId,
  autoGrantSeats,
  invitedTeamMembers,
  getTeamInviteUrlByEmail,
  triggerToast,
  getInvitedTeamMembers,
  omitLicenseSelection,
  teamName,
}) {
  const [toggleInviteForm, setToggle] = useState(false);
  const isGroupScope = !!groupId;
  // Toggles between a input forms/tools
  const toggleForm = () => {
    setToggle(!toggleInviteForm);
  };
  const isDemo = orgId === 'demo';

  const handleSendInvite = (data) => {
    const emails = getEmailsPayload(data, omitLicenseSelection);
    if (!!emails && emails.length) {
      const id = groupId || orgId;
      getTeamInviteUrlByEmail(id, emails)
        .then(() => {
          // If we have invited team members table data, let's refresh that
          if (invitedTeamMembers && invitedTeamMembers.data) {
            getInvitedTeamMembers(id);
          }
          triggerToast('success', {
            content: 'Team invitations successfully sent!',
          });
        })
        .catch(() => {
          triggerToast('error', {
            content: 'Something went wrong. Unable to send invites at this time. Please confirm the user(s) are not already on this team.',
          });
        });
      modalClose();
    } else {
      triggerToast('error', {
        content: 'Enter at least one email address',
      });
    }
  };

  const inviteUrl = isDemo
    ? 'https://app.cybrary.it/team-invite/a6v45f44s356yuibutfd4s553wesdf?source=link'
    : url.replace('https://app.the-crossing.cybrary.it', 'http://localhost:3000');

  return (
    <Modal sizeClasses="sm:w-2/3 2xl:w-1/2 lg:pt-4 lg:px-4" open={modalOpen} toggle={modalClose} ariaLabelledBy="invite-modal">
      <div className="p-4 text-left">
        <h2 id="invite-modal" className="mb-4 text-2xl font-black text-black">
          {toggleInviteForm && !isGroupScope ? 'Add Members' : 'Invite to Team'}
        </h2>
        {toggleInviteForm && !isDemo ? (
          <BulkInviteTools
            orgId={orgId}
            teamName={teamName}
            triggerToast={triggerToast}
            isGroupScope={isGroupScope}
            handleSendInvite={handleSendInvite}
            autoGrantSeats={autoGrantSeats}
            toggleForm={toggleForm}
            omitLicenseSelection={omitLicenseSelection}
          />
        ) : (
          <>
            <h3 className="text-lg font-bold text-black">Invite via email address</h3>
            <DynamicForm
              formName="invitationForm"
              form={getInviteForm(autoGrantSeats, toggleForm, omitLicenseSelection, isDemo)}
              onSubmit={isDemo ? () => {} : handleSendInvite}
              customClassName="invite-form"
            />
          </>
        )}
        <Divider horizontal className="my-6">
          <p>OR</p>
        </Divider>
        <div>
          <h3 className="text-lg font-bold text-black">Invite via link</h3>
          <p className="lg:whitespace-nowrap">* Click the button to copy the url and send to the person you want to invite.</p>
          <div className="flex">
            <input
              id="copyToClipboard"
              aria-label="Team invite URL"
              type="text"
              defaultValue={inviteUrl}
              readOnly
              style={{ width: '89%', borderRadius: '5px' }}
              className="p-3 mr-3 w-full rounded-sm border border-gray-500"
            />
            <input
              type="button"
              value="Copy"
              onClick={copyToClipboard}
              className="flex-1 py-2.5 px-6 text-sm font-bold leading-5 text-center text-black bg-gray-200 hover:bg-gray-300 rounded-sm border-0 cursor-pointer"
            />
          </div>
        </div>
        <If condition={toggleInviteForm}>
          <Button color="gray" onClick={toggleForm} className="mt-8" icon={{ name: 'chevron-left', position: 'left', className: 'w-4 h-4' }}>
            Back to individual email invites
          </Button>
        </If>
      </div>
    </Modal>
  );
}

export default FormInviteModal;
