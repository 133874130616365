import React from 'react';

export default function SearchIcon({ classes, onClick, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';

  function handleClick() {
    if (onClick) {
      onClick();
    }
  }

  return (
    <svg onClick={() => handleClick()} className={className} fill="none" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...accessibilityProps}>
      <path
        d="M26.6673 26.6663L19.5562 19.5552M21.9266 13.6293C21.9266 14.7188 21.712 15.7976 21.2951 16.8042C20.8781 17.8107 20.267 18.7253 19.4966
          19.4957C18.7263 20.2661 17.8117 20.8772 16.8051 21.2941C15.7986 21.711 14.7198 21.9256 13.6303 21.9256C12.5408 21.9256 11.462 21.711 10.4554
          21.2941C9.44887 20.8772 8.5343 20.2661 7.76391 19.4957C6.99353 18.7253 6.38243 17.8107 5.9655 16.8042C5.54857 15.7976 5.33398 14.7188 5.33398
          13.6293C5.33398 11.429 6.20806 9.31879 7.76391 7.76294C9.31977 6.20708 11.43 5.33301 13.6303 5.33301C15.8306 5.33301 17.9408 6.20708 19.4966
          7.76294C21.0525 9.31879 21.9266 11.429 21.9266 13.6293Z"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
