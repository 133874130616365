import React from 'react';
import { twMerge } from 'tailwind-merge';

function PaymentBadges({ className }) {
  return (
    <div className={twMerge(`flex flex-col justify-center mx-auto w-full max-w-xs`, className)}>
      <div className="pb-2 text-lg font-bold text-center front-black">We Accept International Payments!</div>
      <img
        className="m-auto"
        alt="Payment badges"
        src="https://images.ctfassets.net/kvf8rpi09wgk/2sMI9ltZakOGqFdezu9H2y/27784c612cb9d0ac1ce29e690eaf5332/screenshot_2023-06-07_at_11.44.58_am.png?h=250"
      />
    </div>
  );
}
export default PaymentBadges;
