import React, { useEffect, useState } from 'react';
import Bugsnag from '@bugsnag/js';
import OverflowCarousel from '../Carousel/OverflowCarousel';
import Loading from '../Loading/Loading';
import SearchUtil from '../../utils/searchUtil';
import ContentTransformer from '../../transformers/contentTransformer';
import ContentCard from '../Card/ContentCard';

function AssessmentsContent() {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);

  const breakpoints = {
    'break-1869': {
      4: 4,
      2: 2,
    },
    'break-1369': {
      4: 3,
      2: 2,
    },
    'break-869': {
      4: 2,
      2: 2,
    },
  };

  const fetchContent = async () => {
    const catalogIndex = `${process.env.REACT_APP_INSTANTSEARCH_CATALOG_INDEX}`;
    /*
     * Note: Not checking for 'display_in_catalog' status here, like we do most everywhere else.
     * This is because this content needs to be omitted from main catalog, but pulled in here.
     * Easiest way to do this is to set content to unlisted, but ignore that and pull in any with correct type here
     */
    const filters = `${SearchUtil.buildFilters('content_type.nice_name', ["'Cybrary Lab'"])}`;

    // No current limit on how many to show - 99 is arbitrary number to just pull everything
    const contentOptions = {
      hitsPerPage: 99,
      filters,
    };
    try {
      const data = await SearchUtil.fetchFromAlgolia(catalogIndex, contentOptions);
      if (data && data.hits) {
        setItems(data.hits);
      }
    } catch (e) {
      Bugsnag.notify(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchContent();
  }, []);

  if (loading) {
    return <Loading message="Loading..." />;
  }

  if (!items || !items.length) {
    return <p>There are no assessments to display at this time.</p>;
  }

  return (
    <OverflowCarousel
      breakpoints={breakpoints}
      wrapperClasses="box-content w-full"
      dividerProps={{ marginTop: 'mt-8', marginBottom: 'mb-0' }}
      omitDivider
      cols={4}
      isDefaultColWidth
    >
      {items.map((item) => {
        const formattedAlgoliaObj = ContentTransformer.formatDataSources(item);
        return (
          <div key={formattedAlgoliaObj.id} className="flex h-full">
            <ContentCard data={formattedAlgoliaObj} className="w-full sm:w-60" cardProps={{ menuAbove: true }} />
          </div>
        );
      })}
    </OverflowCarousel>
  );
}

export default AssessmentsContent;
