import React from 'react';
import Icon from '../../../components/Icon/Icon';
import UpgradeButton from '../../../components/UpgradeButton/UpgradeButton';
import CopyInviteLinkForm from '../../../components/ReferralModal/CopyInviteLinkForm';

const cipFeatures = [
  'Over 2,000 courses covering role-based skills, tools, concepts, and more',
  'Practice exams to help you ace your certification exams',
  'Role and skill-based assessments to test your readiness',
  'Virtual lab simulations based on real-world attacks to help you develop and practice the hands-on skills that employers are hiring for',
  'A private community with mentors on standby to help you succeed',
];
export default function UpgradeInterstitial({ smallVersion = false }) {
  // We are adding a new smaller version, I'm leaving the old one behind in case we switch back quickly
  if (smallVersion) {
    return (
      <div className="flex flex-col justify-center h-full text-center">
        <div className="flex justify-center">
          <Icon name="cloud-lock" className="w-32 h-32 text-center text-black fill-current" />
        </div>
        <div className="text-3xl font-black text-black">This content is for Paid Subscribers only.</div>
        <div className="my-4">
          <UpgradeButton color="pink" upgradeText="Upgrade" />
        </div>
      </div>
    );
  }
  return (
    <div className="mt-4 h-full text-center">
      <div className="my-8 text-4xl font-black text-black">Keep the momentum going with Cybrary Insider Pro.</div>
      <div className="my-8">
        <UpgradeButton color="pink" upgradeText="Upgrade" />
      </div>
      <p className="my-8">If you’re here, you’ve either reached our recommended optional content or a course that’s not included as part of Cybrary Free Access</p>
      <div className="flex m-auto mb-12 max-w-240">
        <div className="pr-12 ml-12 w-full md:w-2/3">
          <div className="mb-2 font-bold text-left">Upgrade today to unlock access to:</div>
          {cipFeatures.map((li) => (
            <div className="flex mb-1 ml-4 text-left" key={li}>
              <Icon name="check" className="shrink-0 mr-2 w-6 h-6 text-green-600" />
              <p>{li}</p>
            </div>
          ))}
        </div>
        <div className="w-full md:w-1/3">
          <img className="m-auto max-h-72" alt="" src="https://images.ctfassets.net/kvf8rpi09wgk/3Ls37Ke5KPtU7N5x9DAdE1/dd1889d8e40806c8b4708de2fd503721/Programming_Cybrary.svg" />
        </div>
      </div>
      <div className="mx-auto w-full md:w-1/2">
        <p className="mt-4 mb-0 font-bold text-left text-black text-md">Invite your friends and share the fun!</p>
        <CopyInviteLinkForm />
      </div>
    </div>
  );
}
