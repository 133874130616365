export const setStickyNavActiveTab = (tabs, setActiveTab) => {
  // primary, secondary and sticky nav
  const navsHeight = 230;
  const tabNames = tabs.map((tab) => tab.id);

  let previousTab = {};
  tabNames.forEach((tabName, index) => {
    const el = document.getElementById(tabName);
    if (el) {
      // heightSwitch represents the content height scrolled before switching to the next active tab content. Can be used to increase accuracy
      const heightSwitch = navsHeight - el.offsetHeight * tabs[index].heightSwitch;
      const topOfContent = el.getBoundingClientRect().top;

      const startOfContent = topOfContent > heightSwitch;
      const endOfContent = index > 0 ? previousTab.topOfContent <= previousTab.heightSwitch : true;

      previousTab = {
        topOfContent,
        heightSwitch,
      };

      if (startOfContent && endOfContent) {
        setActiveTab(tabName);
      }
    }
  });
};

export default { setStickyNavActiveTab };
