import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import Bugsnag from '@bugsnag/js';
import moment from 'moment';
import Card from '../../components/Card/Card';
import ComboboxMultiselect from '../../components/Enterprise/ComboboxMultiselect';
import Agents from '../../agents/agents';
import TimeUtil from '../../utils/timeUtil';
import BarChart from '../../components/Charts/BarChart';
import Container from '../../components/Container/Container';
import Loading from '../../components/Loading/Loading';
import StyledError from '../../components/Error/StyledError';
import Segment from '../../components/Segment/Segment';
import NoResultsMessage from '../../components/NoResultsMessage/NoResultsMessage';
import DropdownFilter from '../../components/Dropdown/DropdownFilter';
import Checkbox from '../../components/FormFields/Checkbox';
import { findIndexById } from '../../components/Clab/utils/helpers';
import DEMO_ORG_PROGRESS_DATA from './Demo/DEMO_ORG_PROGRESS_DATA.json';
import LineChart from '../../components/Charts/LineChart';
import If from '../../components/If/If';
import { getCustomColor } from '../../utils/chartUtil';
import OrganizationTrainingProgressDetails from './OrganizationTrainingProgressDetails';

function LineTooltip(props) {
  const {
    point: { id, data, serieId: label },
  } = props;

  const { unitOfMeasure } = data || {};

  if (data.marker) {
    return null;
  }

  return (
    <div
      key={id}
      style={{
        background: 'white',
        color: 'inherit',
        fontSize: 'inherit',
        borderRadius: 2,
        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 2px',
        padding: '10px 10px',
      }}
    >
      <p className="mb-1 text-base text-center">
        {data.y.toFixed(2)} {unitOfMeasure === 'hours' ? 'hours' : 'XP'}
      </p>
      <p className="text-xl font-semibold text-center">{label}</p>
    </div>
  );
}

function TrainingProgressGraph({ interval, filters, domainFilters, orgId, unitOfMeasure = 'hours', taxonomy = 'cybrary', isDemo = false, memberId = null, whichDisplay = 'line' }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);
  const [rawData, setRawData] = useState(null);
  const [query, setQuery] = useState(null);
  const [keys, setKeys] = useState(null);
  const [details, setDetails] = useState(null);
  const [lineData, setLineData] = useState(null);
  const [customLineChartColors, setCustomLineChartColors] = useState(null);
  const [customBarChartColors, setCustomBarChartColors] = useState(null);

  // Define the color scheme we will default to for the charts
  const lineChartColorScheme = 'category10';
  const barChartColorScheme = 'paired';

  useEffect(() => {
    // Build our query string, which will be used when making the request
    const queryParams = filters
      ? {
          startDate: moment(filters.startDate).format('YYYY-MM-DD 00:00:00'),
          endDate: moment(filters.endDate).format('YYYY-MM-DD 23:59:59'),
        }
      : {};
    if (interval) {
      queryParams.interval = interval === 'time' ? TimeUtil.getDateRangeInterval(filters.startDate, filters.endDate) : interval;
    }
    if (memberId) {
      queryParams.userId = memberId;
    }
    queryParams.unit = unitOfMeasure;
    queryParams.taxonomy = taxonomy;
    setQuery(`?${queryString.stringify(queryParams)}`);
  }, [filters, interval, unitOfMeasure, memberId]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(false);
      setDetails(null);
      try {
        let response;
        if (isDemo) {
          response = DEMO_ORG_PROGRESS_DATA;
        } else {
          response = await Agents.reports.getReportData(orgId, 'team-taxonomy-progress', query);
        }
        // Add our data from each response to newData
        const graphData = [];
        const newKeys = [];
        const dataByPeriod = {};
        if (response?.length) {
          const queryParams = query ? queryString.parse(query) : {};
          // Only show the day in our label if we are zoomed into to an hour or day time interval
          const labelDateFormat = ['hour', 'day', 'week'].includes(queryParams.interval) ? 'MMM D, YYYY' : 'MMM YYYY';
          response.forEach((d) => {
            const { category_term_name: name, category_term_id: termId, measure, period } = d;
            // We either filter these on the request (so add to params), OR we handle this client side and don't require a round trip to the server to do so
            if (domainFilters?.length && domainFilters.indexOf('_all') === -1) {
              if (domainFilters.indexOf(termId) === -1) {
                // Skip this if we don't have the term id in our filters array
                return;
              }
            }
            const formattedPeriod = moment(period).format(labelDateFormat);
            const periodTimestamp = moment(period).unix();
            if (!dataByPeriod[formattedPeriod]) {
              const i = graphData.length;
              dataByPeriod[formattedPeriod] = {
                index: i,
                period,
                periodTimestamp,
              };
              graphData.push({
                period: formattedPeriod,
              });
            }
            if (!dataByPeriod[formattedPeriod][name]) {
              // Build our raw data, keyed by period, and then the name of the category term
              dataByPeriod[formattedPeriod][name] = {
                ...d,
                formattedPeriod,
              };
            }
            if (newKeys.indexOf(name) === -1) {
              newKeys.push(name);
            }
            // Grab the index of this period in our graph data
            const indexToUse = dataByPeriod[formattedPeriod].index;
            graphData[indexToUse][name] = parseFloat(measure || 0);
          });
        }
        setKeys(newKeys);
        setData(graphData);
        setRawData(dataByPeriod);
        // Now handle the line data
        const orderedData = [];
        const formattedPeriods = Object.keys(dataByPeriod);
        formattedPeriods.forEach((formattedPeriod) => {
          const dataForThisPeriod = dataByPeriod[formattedPeriod] || {};
          orderedData.push({
            formattedPeriod,
            ...dataForThisPeriod,
          });
        });
        orderedData.sort((a, b) => a.periodTimestamp - b.periodTimestamp);
        const newLineChartData = [];
        const newCustomLineChartColors = [];
        const newBarChartColors = {};
        orderedData.forEach((periodData) => {
          const { formattedPeriod: xValue } = periodData;
          // Loop over each of our categories (aka each of our lines) and get the data
          newKeys.forEach((name, i) => {
            let indexOfData = findIndexById(newLineChartData, name);
            if (indexOfData === -1) {
              const color = getCustomColor(name, i, lineChartColorScheme);
              const barChartColor = getCustomColor(name, i, barChartColorScheme);
              if (newCustomLineChartColors.indexOf(color) === -1) {
                newCustomLineChartColors.push(color);
              }
              if (!newBarChartColors[name]) {
                newBarChartColors[name] = barChartColor;
              }
              newLineChartData.push({
                id: name,
                data: [],
                color,
              });
              indexOfData = newLineChartData.length - 1;
            }
            const dataPoint = parseFloat(periodData?.[name]?.measure || 0);
            newLineChartData[indexOfData].data.push({
              x: xValue,
              y: dataPoint,
              unitOfMeasure,
            });
          });
        });
        setLineData(newLineChartData);
        setCustomLineChartColors(newCustomLineChartColors);
        setCustomBarChartColors(newBarChartColors);
      } catch (err) {
        Bugsnag.notify(err);
        setError(err);
      }
      setLoading(false);
    };
    // If we have an empty query string, just return
    if (!query) {
      return;
    }

    fetchData();
  }, [query, orgId, domainFilters, isDemo]);

  if (error) {
    return (
      <Container>
        <StyledError error={error} />
      </Container>
    );
  }

  if (loading || !data) {
    return (
      <Container>
        <Loading message="Loading..." />
      </Container>
    );
  }

  if (!data?.length) {
    return (
      <Segment className="border-none empty-stats-container">
        <NoResultsMessage message="There is no data to display for this time period." />
      </Segment>
    );
  }

  const chartClickHandler = (clicked) => {
    if (taxonomy !== 'cybrary') {
      return;
    }
    const { id, indexValue } = clicked;
    setDetails(rawData?.[indexValue]?.[id] || null);
  };

  const lineChartClickHandler = (clicked) => {
    if (taxonomy !== 'cybrary') {
      return;
    }
    const {
      serieId: category,
      data: { x: month },
    } = clicked;
    setDetails(rawData?.[month]?.[category] || null);
  };

  const hideDetails = () => {
    setDetails(null);
  };

  return (
    <div>
      <div className="relative">
        <If condition={whichDisplay === 'line'}>
          <LineChart
            data={lineData}
            onClick={lineChartClickHandler}
            legends
            tooltip={LineTooltip}
            customColors={customLineChartColors}
            colorScheme={lineChartColorScheme}
            areaOpacity={1}
          />
        </If>
        <If condition={whichDisplay === 'bar'}>
          <BarChart
            onClick={chartClickHandler}
            data={data}
            keys={keys}
            indexBy="period"
            ariaLabel="Training progress chart"
            colorScheme={barChartColorScheme}
            legends
            customColors={customBarChartColors}
          />
        </If>
      </div>
      <OrganizationTrainingProgressDetails details={details} unit={unitOfMeasure} onClose={hideDetails} />
    </div>
  );
}

function GridToggle({ onClick, leftLabel, rightLabel, isChecked = false, ariaLabel = 'toggle', disabled = false, first = false }) {
  return (
    <div className={`grid print:hidden grid-cols-5 items-center h-[42px] text-sm ${first ? '' : 'mt-4'}`}>
      <p className="col-span-2 mb-0 font-semibold">{leftLabel}</p>
      <p className="mb-0 text-center">
        <Checkbox className="inline-block p-0 pr-4 m-0" toggle onChange={onClick} checked={isChecked} ariaLabelledBy={ariaLabel} disabled={disabled} />
      </p>
      <p className="col-span-2 mb-0 font-semibold">{rightLabel}</p>
    </div>
  );
}

function OrganizationTrainingProgress({ filters, orgId, isDemo = false, memberId = null }) {
  const [localFilters, setLocalFilters] = useState(['_all']);
  const [switching, setSwitching] = useState(false);
  const [taxonomy, setTaxonomy] = useState('cybrary');
  const [unitOfMeasure, setUnitOfMeasure] = useState('hours');
  const [whichDisplay, setWhichDisplay] = useState('line');

  const fetchOptions = () => {
    const categories = taxonomy === 'cybrary' ? 'Domains' : 'NICE Category';
    return Agents.catalog.categoryTerms(`?categories[]=${categories}`);
  };

  const handleDomainChange = (val) => {
    setLocalFilters(val.map((item) => item.value));
  };

  const handleTaxonomyToggle = () => {
    setSwitching(true);
    setTaxonomy(taxonomy === 'cybrary' ? 'nice' : 'cybrary');
    setTimeout(() => {
      setSwitching(false);
    }, 150);
  };

  const handleDisplayToggle = () => {
    setWhichDisplay(whichDisplay === 'bar' ? 'line' : 'bar');
  };

  const unitOfMeasureOptions = [
    {
      id: 'Learning Hours',
      text: 'Learning Hours',
      value: 'hours',
    },
    {
      id: 'XP',
      text: 'XP',
      value: 'xp',
    },
  ];

  const handleUnitChange = (item) => {
    setUnitOfMeasure(item.value);
  };

  const taxonomyTitle = taxonomy === 'cybrary' ? 'Domains' : 'Categories';

  return (
    <div className="mb-8">
      <div className="mb-4 learning-hours">
        <Card className="z-50 p-0 my-0 bg-gray-100">
          <div className="z-50 border-b border-b-gray-100 md:flex">
            <div className="flex-1 p-4">
              <h3 className="mb-3 text-lg font-bold">Training Progress</h3>
              <If condition={!memberId}>
                <p className="mb-3">How is my team progressing against my training framework?</p>
              </If>
            </div>
            <div className="z-50 justify-end p-4 md:flex">
              <div>
                <GridToggle
                  leftLabel="Cybrary Framework"
                  rightLabel="NICE Framework"
                  disabled={switching}
                  ariaLabel="toggle-taxonomy"
                  onClick={handleTaxonomyToggle}
                  isChecked={taxonomy !== 'cybrary'}
                  first
                />
                <GridToggle
                  leftLabel="Stacked Area Chart"
                  rightLabel="Bar Chart"
                  disabled={switching}
                  ariaLabel="toggle-display"
                  onClick={handleDisplayToggle}
                  isChecked={whichDisplay === 'bar'}
                />
              </div>
              <div>
                <div className="flex z-50 items-center h-[42px]">
                  <label className="block pr-5 w-[190px] text-sm font-semibold text-right">{taxonomyTitle}</label>
                  <div className="z-50">
                    {!switching && <ComboboxMultiselect performQuery={fetchOptions} placeholder={`Select ${taxonomyTitle}`} onChange={handleDomainChange} />}
                  </div>
                </div>
                <div className="flex z-50 items-center mt-4 h-[42px]">
                  <label className="block pr-5 w-[190px] text-sm font-semibold text-right">Unit of Measure</label>
                  <div className="w-[220px] text-left">
                    <DropdownFilter
                      name="unit"
                      options={unitOfMeasureOptions}
                      value={unitOfMeasure}
                      onChange={handleUnitChange}
                      placeholder="Unit Of Measure"
                      ariaLabel="Change unit of measure"
                      hideSelectedOptions={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Card className="p-4 my-0 border-0 border-t-1 summary">
            <h4 className="mb-1 font-semibold">Trend</h4>
            {!switching && (
              <TrainingProgressGraph
                isDemo={isDemo}
                orgId={orgId}
                filters={filters}
                taxonomy={taxonomy}
                interval="time"
                domainFilters={localFilters}
                unitOfMeasure={unitOfMeasure}
                memberId={memberId}
                whichDisplay={whichDisplay}
              />
            )}
          </Card>
        </Card>
      </div>
    </div>
  );
}

export default OrganizationTrainingProgress;
