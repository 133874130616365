import React from 'react';
import WidgetContainer from '../Container/WidgetContainer';
import Card from '../Card/Card';
import Header from '../Header/Header';
import If from '../If/If';
import LoadingCard from './LoadingCard';
import Button from '../Button/Button';
import { useABM } from '../../providers/ABMProvider';
import LockedContainer from '../Container/LockedContainer';
import AddLink from '../AddLink/AddLink';
import PermalinkUtil from '../../utils/permalinkUtil';

/**
 * Top Cert Prep Programs Widget for ABM Page
 * @returns {JSX} - The Top Cert Prep Programs Widget
 */
function TopCertPrepProgramsWidget() {
  const { isABMTeamLoading, isVerified, dashboardData } = useABM();
  const programs = dashboardData?.top_certification_prep;
  return (
    <LockedContainer msg="Verify your company email to unlock" isLocked={!isVerified && !isABMTeamLoading} isFullWidth>
      <WidgetContainer>
        <Header as="h2" className="mb-4">
          Top Certification Prep Programs
        </Header>
        {/* Loading State */}
        <If condition={isABMTeamLoading}>
          <LoadingCard />
          <LoadingCard />
          <LoadingCard />
        </If>
        {/* Empty State */}
        <If condition={!programs?.length && !isABMTeamLoading}>
          <Card>
            <p>No cert prep programs yet</p>
          </Card>
        </If>
        {/* Default State */}
        <If condition={programs?.length && !isABMTeamLoading}>
          {programs?.map(({ content_description_id, content_description_title, users_enrolled, content_description_permalink, content_description_type }) => (
            <AddLink to={PermalinkUtil.formatInApp(content_description_permalink, content_description_type)} key={content_description_id}>
              <Card inlineContent inlineDisplay stackDisplay>
                <Card.Section className="flex flex-col grow">
                  <Header as="h4" className="grow mb-0">
                    {content_description_title}
                  </Header>
                  <span className="text-xs text-gray-600">
                    {users_enrolled} Learner{users_enrolled > 1 ? 's' : ''}
                  </span>
                </Card.Section>
                <Button color="gray" icon={{ name: 'chevron-right', className: 'w-4 h-4' }} className="px-3" />
              </Card>
            </AddLink>
          ))}
        </If>
        {/* Error State? */}
      </WidgetContainer>
    </LockedContainer>
  );
}

export default TopCertPrepProgramsWidget;
