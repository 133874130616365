import React, { useState, useEffect } from 'react';
import Bugsnag from '@bugsnag/js';
import moment from 'moment';
import Agents from '../../agents/agents';
import Loading from '../Loading/Loading';
import Button from '../Button/Button';
import CircularProgress from '../ProgressBar/CircularProgress';
import Dropdown from '../Dropdown/Dropdown';
import Collapsible from '../Collapsible/Collapsible';
import StyleUtil from '../../utils/styleUtil';
import Icon from '../Icon/Icon';
import FormatUtil from '../../utils/formatUtil';
import StyledError from '../Error/StyledError';

const subheadingClasses = 'mb-0 text-2xs font-semibold text-gray-600 uppercase';

const getScorePercentage = (data) => {
  return data && data.points_earned && data.points_possible ? Math.floor((data.points_earned / data.points_possible) * 100) : 0;
};

const getQuestionsAnswered = (data) => {
  return data ? data.items_total - data.items_omitted : 0;
};

function ReportRow({ data, isHeading }) {
  const rowClasses = `flex gap-x-8 w-full ${isHeading ? 'pb-4 border-b border-gray-400' : 'pt-4 text-sm'}`;
  const colClasses = isHeading ? subheadingClasses : 'mb-0';
  const colTypeClasses = {
    term_description: 'w-full text-left',
    answered: 'w-48 text-center',
    duration_seconds: 'w-16 text-center',
    score: 'w-16 text-center',
  };

  if (isHeading) {
    return (
      <div className={rowClasses}>
        <p className={`${colClasses} ${colTypeClasses.term_description}`}>Topics</p>
        <p className={`${colClasses} ${colTypeClasses.answered}`}>Answered Questions</p>
        <p className={`${colClasses} ${colTypeClasses.duration_seconds}`}>Time</p>
        <p className={`${colClasses} ${colTypeClasses.score}`}>Score</p>
      </div>
    );
  }

  if (!data) {
    return null;
  }

  const score = getScorePercentage(data);
  const scoreColor = StyleUtil.getExamProgressColor(score);

  return (
    <div className={rowClasses}>
      {/* If no term_description and no parent_id, this is the top level 'totals', so show 'All Questions' */}
      <p className={`${colClasses} ${colTypeClasses.term_description}`}>
        {!data.term_description && !data.parent_id ? 'All Questions' : FormatUtil.formatLongText(data.term_description, 60)}
      </p>
      <p className={`${colClasses} ${colTypeClasses.answered}`}>{getQuestionsAnswered(data)}</p>
      <p className={`${colClasses} ${colTypeClasses.duration_seconds} text-green-700`}>{data.duration_seconds ? FormatUtil.formatTime(data.duration_seconds, 'colon') : '00:00'}</p>
      <p className={`${colClasses} ${colTypeClasses.score} ${scoreColor}`}>{score}%</p>
    </div>
  );
}

function ReportRowWrapper({ data, openRows, toggleRow, isNested }) {
  if (!data || !data.length) {
    return null;
  }
  return (
    <>
      {data.map((row) => {
        const hasChildren = row.children && row.children.length;
        if (!hasChildren) {
          return (
            <div key={row.id} className={isNested ? 'ml-7' : ''}>
              <ReportRow data={row} />
            </div>
          );
        }
        const isOpen = openRows.includes(row.id);
        return (
          <div key={row.id} className={isNested ? 'ml-2' : ''}>
            <button onClick={() => toggleRow(row.id)} className={`flex gap-x-2 items-center w-full ${!isNested ? 'font-semibold' : ''}`}>
              <Icon name="chevron-right" className={`mt-4 w-3 h-3 transition-transform ${isOpen && 'transform rotate-90'}`} />
              <span className="sr-only">Click to expand row details</span>
              <ReportRow data={row} />
            </button>
            <Collapsible key={row.id} open={isOpen} ariaLabel={`${row.question} details`}>
              <div>
                <ReportRowWrapper data={row.children} openRows={openRows} toggleRow={toggleRow} isNested />
              </div>
            </Collapsible>
          </div>
        );
      })}
    </>
  );
}

function ExamReport({ data }) {
  const [openRows, setOpenRows] = useState([]);
  if (!data || !data.length) {
    return null;
  }

  const toggleRow = (id) => {
    const rows = [...openRows];
    const idIdx = rows.indexOf(id);
    if (idIdx === -1) {
      rows.push(id);
      setOpenRows(rows);
    } else {
      rows.splice(idIdx, 1);
      setOpenRows(rows);
    }
  };

  const getAllExpandableRows = (rows) => {
    const expandableRows = [];
    rows.forEach((row) => {
      if (row.children && row.children.length) {
        expandableRows.push(row.id);
        const childIds = getAllExpandableRows(row.children);
        expandableRows.push(...childIds);
      }
    });
    return expandableRows;
  };

  const expandAll = () => {
    const rowIds = getAllExpandableRows(data);
    if (rowIds && rowIds.length) {
      setOpenRows(rowIds);
    }
  };

  return (
    <div className="overflow-x-auto">
      <Button onClick={expandAll} className="mb-6" color="gray">
        Expand All
      </Button>
      <div className="pb-4 min-w-[40rem] md:pb-0">
        <ReportRow isHeading />
        <ReportRowWrapper data={data} openRows={openRows} toggleRow={toggleRow} />
      </div>
    </div>
  );
}

const createReportHierarchy = (data, parentId) => {
  const rows = data.filter((row) => {
    return row.parent_id === parentId;
  });
  for (let i = 0; i < rows.length; i++) {
    const row = rows[i];
    row.children = createReportHierarchy(data, row.id);
  }
  return rows;
};

const generateScoreOptions = (scores = []) => {
  return scores.map((score) => {
    return {
      key: score.id,
      text: moment.utc(score.created_at).format('MMMM D, YYYY'),
      value: score.id,
    };
  });
};

function CertificationExamReport({ data, permalink }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [scoreId, setScoreId] = useState(null);
  const [scoreOptions, setScoreOptions] = useState([]);
  const [scoreData, setScoreData] = useState([]);
  const [reportData, setReportData] = useState({});

  const fetchScoreData = async (id) => {
    setLoading(true);
    // If we don't already have the data for this score, fetch. Otherwise show saved data
    if (!reportData[id]) {
      try {
        const response = await Agents.enrollments.getScoreById(id);
        const scoreHierarchy = createReportHierarchy(response.score, null); // Create question hierarchy and save it on score data
        const reportDataCopy = { ...reportData };
        reportDataCopy[id] = scoreHierarchy;
        setReportData(reportDataCopy);
        setScoreData(scoreHierarchy);
      } catch (e) {
        Bugsnag.notify(e);
        setError(e);
      } finally {
        setLoading(false);
      }
    } else {
      // Small delay just to show loader so user doesn't miss that data on page changed since it doesn't need to be re-fetched
      setTimeout(() => {
        setScoreData(reportData[id]);
        setLoading(false);
      }, 250);
    }
  };

  useEffect(() => {
    if (scoreId) {
      fetchScoreData(scoreId);
    }
  }, [scoreId]);

  useEffect(() => {
    if (data && data.length) {
      // Generate scores dropdown options from scores list provided
      setScoreOptions(generateScoreOptions(data));
      // Grab the first scoreId from the data - Will be in order by most recent
      const { id } = data[0];
      const defaultScoreId = sessionStorage.getItem(`${permalink}_score-selection`);
      setScoreId(defaultScoreId ? 1 * defaultScoreId : id);
    }
  }, [data]);

  const setScoreSelection = (id) => {
    setScoreId(id);
    // Persist dropdown selection for session
    sessionStorage.setItem(`${permalink}_score-selection`, id);
  };

  if (loading) {
    return <Loading message="Loading..." />;
  }

  if (error) {
    return <StyledError error={error} />;
  }

  const totalsData = scoreData.length ? scoreData[0] : {};
  const score = getScorePercentage(totalsData);
  return (
    <div className="relative">
      <div className="flex justify-between">
        <div>
          <p className={`${subheadingClasses} mb-1`}>Select Exam</p>
          <Dropdown
            ariaLabel="Select exam for reporting"
            options={scoreOptions}
            classes="text-xs min-w-20 my-4 sm:my-0 rounded-none z-20"
            placeholder="5"
            value={scoreId}
            onChange={(e) => setScoreSelection(e.value)}
          />
        </div>
      </div>
      <div className="text-center">
        <CircularProgress classes="w-50 h-50 mx-auto my-4 md:my-0" strokeColor={StyleUtil.getExamProgressColor(score)} strokeWidth={4} percentageValue={score}>
          <div className="text-center">
            <p className="mb-0 text-[64px] leading-[72px]">{score}%</p>
            <p className={subheadingClasses}>score</p>
            <p className={`${subheadingClasses} font-bold text-gray-700`}>of {getQuestionsAnswered(totalsData)} questions</p>
          </div>
        </CircularProgress>
      </div>
      <ExamReport data={scoreData} />
    </div>
  );
}

export default CertificationExamReport;
