import React from 'react';
import { twMerge } from 'tailwind-merge';

function Table({ children, className = '' }) {
  const baseClasses = 'w-full text-sm border border-gray-400 rounded-sm';
  return <table className={twMerge(baseClasses, className)}>{children}</table>;
}

function TableBody({ children, className = '' }) {
  return <tbody className={className}>{children}</tbody>;
}

function TableHead({ children, className = '' }) {
  const baseClasses = 'text-left border-b border-gray-400';
  return <thead className={twMerge(baseClasses, className)}>{children}</thead>;
}

function TableHeaderCell({ children, className = '', colSpan, scope, style = {}, onClick, ariaSort }) {
  const baseClasses = 'p-3 bg-gray-200';
  return (
    <th className={twMerge(baseClasses, className)} colSpan={colSpan} scope={scope} style={style} onClick={onClick} aria-sort={ariaSort}>
      {children}
    </th>
  );
}

function TableRow({ children, className = '', onClick }) {
  const baseClasses = 'border-b border-gray-400';
  return (
    <tr className={twMerge(baseClasses, className)} onClick={onClick}>
      {children}
    </tr>
  );
}

function TableCell({ children, className = '', colSpan, style = {} }) {
  const baseClasses = 'py-2 px-3';
  return (
    <td className={twMerge(baseClasses, className)} colSpan={colSpan} style={style}>
      {children}
    </td>
  );
}

function TableFooter({ children, className = '' }) {
  const baseClasses = 'bg-gray-200';
  return <tfoot className={twMerge(baseClasses, className)}>{children}</tfoot>;
}

Table.Body = TableBody;
Table.Header = TableHead;
Table.HeaderCell = TableHeaderCell;
Table.Row = TableRow;
Table.Cell = TableCell;
Table.Footer = TableFooter;

export default Table;
