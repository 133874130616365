import React, { useEffect, useState, createContext, useContext } from 'react';
import { throttle } from 'lodash';
import { observer, inject } from 'mobx-react';
import { useWindowWidth } from '@react-hook/window-size';
import Icon from '../Icon/Icon';
import Footer from '../Footer/Footer';

const SlideoutContext = createContext();
const { Provider } = SlideoutContext;

function MainSection({ children, scrollEvent }) {
  const { isOpen, isFullView } = useContext(SlideoutContext);

  useEffect(() => {
    const el = document.getElementById('sideSlideoutMain');
    if (scrollEvent) {
      el.addEventListener('scroll', throttle(scrollEvent, 1000, { leading: false, trailing: true }));
    }
    return () => el.removeEventListener('scroll', scrollEvent);
  }, []);

  let containerClasses = '';
  // If open, in mobile view it just hides the main section
  if (isOpen && isFullView) {
    containerClasses += ' hidden';
  }

  return (
    <div id="sideSlideoutMain" className={`${containerClasses} overflow-y-scroll no-scrollbar w-full`}>
      <div className="flex flex-col justify-between w-full h-full">
        <div className="w-full">{children}</div>
        <Footer />
      </div>
    </div>
  );
}

function SideSection({ children, width = 'w-full md:w-132', side = 'right', labelledById }) {
  const { isOpen, isChangeComplete, isFullView } = useContext(SlideoutContext);
  const labelledBy = labelledById ? { 'aria-labelledby': labelledById } : {};

  let containerClasses = 'transition-all duration-300';
  if (isOpen) {
    // If open, in mobile view it just hides the main section and shows the side section
    if (isFullView) {
      containerClasses = 'w-full block';
    } else {
      containerClasses += ` block ${isChangeComplete ? `${width} opacity-100` : 'border-none'}`;
    }
  } else if (isFullView) {
    containerClasses = 'hidden';
  } else {
    containerClasses += ` opacity-0 border-none ${isChangeComplete ? 'hidden' : ''}`;
  }

  return (
    <div
      role="region"
      {...labelledBy}
      className={`${containerClasses} w-0 box-content shrink-0 ${side === 'right' ? 'border-l-xs' : 'border-r-xs'} border-gray-400 overflow-y-scroll no-scrollbar relative`}
    >
      {children}
    </div>
  );
}

function SideSectionHeader({ children }) {
  return <div className="py-3 px-4 h-16 border-b-xs border-gray-400">{children}</div>;
}

function SideSectionContent({ children, className = 'px-4 py-6' }) {
  const { isOpen } = useContext(SlideoutContext);
  return <div className={`${className} ${isOpen ? 'block' : 'hidden'}`}>{children}</div>;
}

function CloseButton({ onClick, ariaLabel, className }) {
  return (
    <button type="button" className={className || 'p-2 border-xs border-gray-400 rounded'} aria-label={ariaLabel || 'Close section'} onClick={onClick}>
      <Icon name="close-arrows" />
    </button>
  );
}

const SideContentSlideout = inject('commonStore')(
  observer(({ commonStore, open, children, id, switchInsteadSlideWidth = 1024 }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isChangeComplete, setIsChangeComplete] = useState(false);
    const [isFullView, setIsFullView] = useState(false);

    useEffect(() => {
      commonStore.setHideFooter(true);
      return () => {
        commonStore.setHideFooter(false);
      };
    }, []);

    useEffect(() => {
      const isMobile = window.innerWidth < switchInsteadSlideWidth;
      if (isFullView !== isMobile) {
        setIsFullView(isMobile);
      }
    }, [useWindowWidth({ wait: 200 })]);

    useEffect(() => {
      if (open !== isOpen) {
        setIsOpen(open);
        setIsChangeComplete(false);
        setTimeout(() => {
          setIsChangeComplete(true);
        }, 300);
      }
    }, [open]);

    return (
      <Provider value={{ isOpen, isChangeComplete, isFullView }}>
        <div className="flex slide-content-container" id={id}>
          {children}
        </div>
      </Provider>
    );
  })
);

SideContentSlideout.Main = MainSection;
SideContentSlideout.SideSection = SideSection;
SideContentSlideout.SideHeader = SideSectionHeader;
SideContentSlideout.SideContent = SideSectionContent;
SideContentSlideout.CloseButton = CloseButton;

export default SideContentSlideout;
