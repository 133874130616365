import React from 'react';
import { FooterBottom } from '../Footer/Footer';
import BlankTemplate from '../BlankTemplate/BlankTemplate';

function InterstitialTemplate({ children }) {
  return (
    <>
      <div className="flex flex-col justify-between items-center py-4 h-screen">
        <div className="flex flex-col gap-y-24 items-center w-full">
          <img src="https://images.ctfassets.net/kvf8rpi09wgk/6HtXDpiYT7GMU8yp0DS4Dy/5b42c213353afb410ac8e52e7db7c77f/cybrary_logo_black.svg" alt="" width="240" height="62" />
          {children}
        </div>
        <FooterBottom />
      </div>
      <BlankTemplate />
    </>
  );
}

export default InterstitialTemplate;
