import React from 'react';
import DynamicForm from '../DynamicForm/DynamicForm';

function RegisterCode({ onSubmit, submitting }) {
  const RegiserCodeForm = {
    name: 'Register Code',
    order: ['code', 'submit'],
    fields: {
      code: {
        type: 'text',
        label: 'Enter your code',
        validations: ['required'],
        disabled: submitting,
      },
      submit: {
        type: 'button',
        color: 'pink',
        loading: submitting,
        label: 'Verify Account',
        disabled: submitting,
        className: 'w-full grid justify-items-center',
      },
    },
  };
  return <DynamicForm customClassName="text-base" form={RegiserCodeForm} onSubmit={onSubmit} />;
}

export default RegisterCode;
