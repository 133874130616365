import React from 'react';

export default function Community({ classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';
  return (
    <svg className={className} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...accessibilityProps}>
      <path
        d="M29.5386 14.1972C30.5627 12.4497 29.6801 10.1976 27.75 9.60929V6.65074C27.75 4.05591 25.6472 1.94485 23.0625 1.94485H18.8633C18.3801 0.837967 
        17.2788 0.0625 16 0.0625C14.7212 0.0625 13.6199 0.837967 13.1367 1.94485H8.96875C6.38406 1.94485 4.28125 4.05591 4.28125 6.65074V9.6187C2.38137 
        10.2198 1.51187 12.4389 2.51175 14.1759C1.03887 14.789 0 16.2472 0 17.9449V20.7684C0 21.2882 0.41975 21.7096 0.9375 
        21.7096H4.28125V24.5331C4.28125 27.1279 6.38406 29.239 8.96875 29.239H10.8438V31.1213C10.8438 31.6411 11.2635 32.0625 11.7812 
        32.0625H20.2188C20.7365 32.0625 21.1562 31.6411 21.1562 31.1213V29.239H23.0625C25.6472 29.239 27.75 27.1279 27.75 24.5331V21.7096H31.0625C31.5802 
        21.7096 32 21.2882 32 20.7684V17.9449C32 16.2665 30.9848 14.8222 29.5386 14.1972ZM26.8438 13.8664C26.1545 13.8664 25.5938 13.3035 25.5938 
        12.6115C25.5938 11.9196 26.1545 11.3566 26.8438 11.3566C27.533 11.3566 28.0938 11.9196 28.0938 12.6115C28.0938 13.3035 27.533 13.8664 26.8438 
        13.8664ZM14.9062 6.33701H17.0938C18.2999 6.33701 19.2812 7.32217 19.2812 8.53309V10.4154H12.7188V8.53309C12.7188 7.32217 13.7001 6.33701 
        14.9062 6.33701ZM16 1.94485C16.6893 1.94485 17.25 2.5078 17.25 3.19976C17.25 3.89171 16.6893 4.45466 16 4.45466C15.3108 4.45466 14.75 3.89171 
        14.75 3.19976C14.75 2.5078 15.3108 1.94485 16 1.94485ZM5.21875 11.3566C5.908 11.3566 6.46875 11.9196 6.46875 12.6115C6.46875 13.3035 5.908 
        13.8664 5.21875 13.8664C4.5295 13.8664 3.96875 13.3035 3.96875 12.6115C3.96875 11.9196 4.5295 11.3566 5.21875 11.3566ZM1.875 17.9449C1.875 
        16.7339 2.85631 15.7488 4.0625 15.7488H6.3125C7.51869 15.7488 8.5 16.7339 8.5 17.9449V19.8272H1.875V17.9449ZM19.2812 30.1801H12.7188V28.2978C12.7188 
        27.0869 13.7001 26.1017 14.9062 26.1017H17.0938C18.2999 26.1017 19.2812 27.0869 19.2812 28.2978V30.1801ZM16 24.2194C15.3108 24.2194 14.75 23.6564 
        14.75 22.9645C14.75 22.2725 15.3108 21.7096 16 21.7096C16.6893 21.7096 17.25 22.2725 17.25 22.9645C17.25 23.6564 16.6893 24.2194 16 24.2194ZM25.875 
        24.5331C25.875 26.09 24.6133 27.3566 23.0625 27.3566H21.0459C20.7472 26.0927 19.8619 25.0545 18.6949 24.5501C18.9678 24.0845 19.125 23.5427 19.125 
        22.9645C19.125 21.2346 17.7231 19.8272 16 19.8272C14.2769 19.8272 12.875 21.2346 12.875 22.9645C12.875 23.5427 13.0323 24.0845 13.3051 
        24.5501C12.1381 25.0545 11.2528 26.0927 10.9541 27.3566H8.96875C7.41794 27.3566 6.15625 26.09 6.15625 24.5331V21.7096H9.4375C9.95525 21.7096 
        10.375 21.2882 10.375 20.7684V17.9449C10.375 16.2665 9.35975 14.8222 7.91362 14.1972C8.92787 12.4665 8.07275 10.2251 6.15625 
        9.6187V6.65074C6.15625 5.09384 7.41794 3.82721 8.96875 3.82721H12.9379C13.0075 4.17023 13.1336 4.49274 13.3051 4.78539C11.859 5.41045 10.8438 
        6.85478 10.8438 8.53309V11.3566C10.8438 11.8764 11.2635 12.2978 11.7812 12.2978H20.2188C20.7365 12.2978 21.1562 11.8764 21.1562 
        11.3566V8.53309C21.1562 6.85478 20.141 5.41045 18.6949 4.78539C18.8664 4.49274 18.9925 4.1703 19.0621 3.82721H23.0625C24.6133 3.82721 25.875 5.09384 
        25.875 6.65074V9.62931C23.9893 10.2482 23.1463 12.4553 24.1367 14.1759C22.6639 14.789 21.625 16.2472 21.625 17.9449V20.7684C21.625 21.2882 22.0448 
        21.7096 22.5625 21.7096H25.875V24.5331ZM30.125 19.8272H23.5V17.9449C23.5 16.7339 24.4813 15.7488 25.6875 15.7488H27.9375C29.1437 15.7488 30.125 
        16.7339 30.125 17.9449V19.8272Z"
        fill="black"
      />
    </svg>
  );
}
