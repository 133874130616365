import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import Bugsnag from '@bugsnag/js';
import withRouter from '../../components/Router/withRouter';
import Title from '../../components/Title/Title';
import Loading from '../../components/Loading/Loading';
import AdminTable from '../../components/AdminTable/AdminTable';
import StyledError from '../../components/Error/StyledError';
import GroupSelect from '../../components/Enterprise/GroupSelect';
import Container from '../../components/Container/Container';
import FormatUtil from '../../utils/formatUtil';

const ScheduledReports = inject(
  'commonStore',
  'userStore',
  'reportsStore',
  'authStore'
)(
  observer(
    class ScheduledReports extends Component {
      state = {
        orgId: null,
        groupFilter: null,
      };

      componentDidMount() {
        this.props.authStore.fireAttributionEvent();
        this.props.userStore.setPreferredTeamInit('', this.props.match.params.orgId, this.init);
        this.props.commonStore.setPageTitle('Scheduled Reports | Cybrary');
      }

      componentDidUpdate(prevProps) {
        this.props.userStore.setPreferredTeamInit(prevProps.match.params.orgId, this.props.match.params.orgId, this.init);
      }

      componentWillUnmount() {
        this.props.reportsStore.setDefaultScheduledReportsTableData();
      }

      getBreadCrumbs() {
        const crumbs = [];
        crumbs.push({
          href: `/enterprise/${this.state.orgId}/reporting/tools`,
          label: 'Reporting Tools',
        });
        crumbs.push({
          href: `${this.props.location.pathname}${this.props.location.search}`,
          label: 'Scheduled Reports',
        });
        return crumbs;
      }

      init = (orgId) => {
        const { team } = this.props.userStore;
        const groupSelection = team.permissions && team.permissions.canManageAssignments === 'group' ? team.assignmentsTeamGroupId : null;
        const newState = {
          ...this.state,
          orgId: 1 * orgId,
          groupFilter: groupSelection,
        };
        this.setState(newState);

        this.props.reportsStore.setDefaultScheduledReportsTableData();
        this.props.reportsStore.getScheduledReportsTableData(this.props.userStore.team.reportsTeamGroupId);
      };

      /**
       * Sets sort column and direction vars in store for service call query params
       */
      tableSort = (heading) => {
        if (heading) {
          if (heading === this.props.reportsStore.scheduledReportsTableData.queryParams.sortCol) {
            this.props.reportsStore.scheduledReportsTableData.queryParams.sortDirection =
              this.props.reportsStore.scheduledReportsTableData.queryParams.sortDirection === 'desc' ? 'asc' : 'desc';
          } else {
            this.props.reportsStore.scheduledReportsTableData.queryParams.sortCol = heading;
            this.props.reportsStore.scheduledReportsTableData.queryParams.sortDirection = 'desc';
          }
          this.props.reportsStore.scheduledReportsTableData.queryParams.activePg = 1;
          this.props.reportsStore.getScheduledReportsTableData(this.props.userStore.team.reportsTeamGroupId);
        }
      };

      // /**
      //  * Sets page number from pagination in store for service call query params
      //  */
      pagChangePg = (pg) => {
        this.props.reportsStore.scheduledReportsTableData.queryParams.activePg = pg;
        this.props.reportsStore.getScheduledReportsTableData(this.props.userStore.team.reportsTeamGroupId);
      };

      deleteScheduledReport = (id) => {
        this.props.reportsStore
          .deleteScheduledReport(this.props.userStore.team.reportsTeamGroupId, id)
          .then(() => {
            this.props.reportsStore.getScheduledReportsTableData(this.props.userStore.team.reportsTeamGroupId);
            this.props.commonStore.triggerToast('success', {
              heading: 'Success!',
              content: 'Scheduled report has been deleted',
            });
          })
          .catch((e) => {
            Bugsnag.notify(e);
            this.props.commonStore.triggerToast('error', {
              content: 'Something went wrong. Scheduled report not deleted. Please try again.',
            });
          });
      };

      showDeleteConfirm = (row, headings) => {
        const id = row[FormatUtil.getColIndex(headings, 'id')].value;
        this.props.commonStore.triggerConfirm({
          content: 'Are you sure you want to delete this scheduled report?',
          cancel: () => this.props.commonStore.resetConfirmState(),
          continue: () => {
            this.props.commonStore.resetConfirmState();
            this.deleteScheduledReport(id);
          },
        });
      };

      editScheduledReport = (row, headings) => {
        const id = row[FormatUtil.getColIndex(headings, 'id')].value;
        this.props.navigate(`/enterprise/${this.state.orgId}/reporting/scheduled-reports/edit/${id}`);
      };

      translateFrequency = (col) => {
        let retVal = col.value;
        switch (col.value) {
          case 'P1W':
            retVal = 'Weekly';
            break;
          case 'P1M':
            retVal = 'Monthly';
            break;
          case 'P1D':
            retVal = 'Daily';
            break;
          default:
            retVal = col.value;
        }
        return retVal;
      };

      getScheduledReportsTable = (storeData) => {
        if (storeData.loading) {
          return (
            <Container>
              <Loading message="Loading..." />
            </Container>
          );
        }
        if (storeData.error) {
          return (
            <Container>
              <StyledError error={storeData.error} />
            </Container>
          );
        }
        if (!storeData.data.recordsCount) {
          return <p className="no-results">No scheduled reports to display at this time.</p>;
        }
        return (
          <AdminTable
            /* Table Data */
            headings={storeData.data.tableHeadings}
            data={storeData.data.tableData}
            tableLoading={storeData.loading}
            tableError={storeData.error}
            headerClickFunc={this.tableSort}
            sortCol={storeData.queryParams.sortCol}
            sortDirection={storeData.queryParams.sortDirection}
            formatColumns={[
              {
                method: this.translateFrequency,
                colIdx: FormatUtil.getColIndex(storeData.data.tableHeadings, 'frequency'),
              },
            ]}
            // Temp omission of "user_name" column from sorting. Currently breaks on back end -- Will remove this when fixed in back end query
            omitSortColumns={[FormatUtil.getColIndex(storeData.data.tableHeadings, 'user_name')]}
            /* Pagination options */
            pagNumPgs={storeData.data.pagPagesCount}
            pagChangePg={this.pagChangePg}
            pagActivePg={storeData.queryParams.activePg}
            rowOptions={[
              {
                text: 'Edit',
                icon: 'edit',
                action: this.editScheduledReport,
              },
              {
                text: 'Delete',
                icon: 'times',
                action: this.showDeleteConfirm,
              },
            ]}
          />
        );
      };

      applyGroupFilter = (groupId) => {
        const { team } = this.props.userStore;
        const newState = {
          ...this.state,
          groupFilter: groupId,
        };
        this.setState(newState, () => {
          // If this is a group admin and the group filter was changed, set that to the preferred group for the user
          if (team && team.permissions && !team.permissions.canManageTeam && team.permissions.canViewReports !== 'all') {
            this.props.userStore.setPreferredGroup(this.state.orgId, groupId);
          }
          this.props.reportsStore.getScheduledReportsTableData(groupId || this.props.userStore.team.reportsTeamGroupId);
        });
      };

      render() {
        const { team } = this.props.userStore;
        // Wait for team in userStore to be set
        if (!team || team.id !== this.state.orgId) {
          return null;
        }
        return (
          <Container size="lg" className="schedule-reports">
            <Title title="Scheduled Reports" breadcrumbs={this.getBreadCrumbs()} />
            <div className="flex justify-end items-center">
              <p className="mr-2 mb-0">Filter:</p>
              <div className="w-80">
                <GroupSelect
                  team={team}
                  handleGroupSelect={this.applyGroupFilter}
                  selectedGroup={this.state.groupFilter}
                  groupFilter={(group) => group.permissions.reports}
                  placeholder="All Groups"
                  defaultOption={
                    team.permissions && team.permissions.canManageAssignments === 'all'
                      ? {
                          text: 'All Groups',
                          value: null,
                        }
                      : null
                  }
                />
              </div>
            </div>
            {this.getScheduledReportsTable(this.props.reportsStore.scheduledReportsTableData)}
          </Container>
        );
      }
    }
  )
);

export default withRouter(ScheduledReports);
