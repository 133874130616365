import React, { useState } from 'react';
import FormatUtil from '../../utils/formatUtil';
import AddLink from '../AddLink/AddLink';
import Label from '../Label/Label';
import ActivityLogWrapper from './ActivityLogWrapper';

const getActivityFilters = (events) => {
  return [
    {
      sectionKey: 'cybraryAdmin',
      sectionLabel: 'Admin',
      filters: [
        {
          label: 'Cybrary Admin',
          value: '1',
        },
        {
          label: 'Team Admin',
          value: '0',
        },
      ],
    },
    {
      sectionKey: 'event',
      sectionLabel: 'Event',
      filters: events.map((event) => {
        return { label: event, value: event };
      }),
    },
  ];
};

const formatUserColumn = (data, row, headings) => {
  const userIdIdx = FormatUtil.getColIndex(headings, 'user_id');
  const userId = userIdIdx > -1 ? row[userIdIdx].value : null;
  return <MemberLookupLink id={userId} text={data.value} />;
};

const formatMemberColumn = (data, row, headings) => {
  const memberIdIdx = FormatUtil.getColIndex(headings, 'member_id');
  const memberId = row[memberIdIdx] ? row[memberIdIdx].value : null;
  return <MemberLookupLink id={memberId} text={data.value} />;
};

const formatEventName = (data, row, headings) => {
  const eventName = data.value;
  const adminIdx = FormatUtil.getColIndex(headings, 'cybrary_admin');
  if (adminIdx > -1 && row[adminIdx]) {
    const isCybAdmin = row[adminIdx].value;
    return (
      <span className="flex gap-x-2 justify-between items-center">
        {eventName}
        <Label color={isCybAdmin ? 'black' : 'green'} basic className="p-1 text-xs whitespace-nowrap rounded-sm">
          {isCybAdmin ? 'Cybrary' : 'Team'}
        </Label>
      </span>
    );
  }

  return eventName;
};

function MemberLookupLink({ id, text }) {
  if (!id || !text) {
    return null;
  }
  return (
    <AddLink className="text-cyb-pink-500 hover:text-black underline" to={`/admin/user/${id}/dashboard`}>
      {text}
    </AddLink>
  );
}

function CybraryAdminActivityLog({ team }) {
  const [filterOptions, setFilterOptions] = useState([]);

  return (
    <div className="grid grid-cols-6 gap-x-8">
      <ActivityLogWrapper
        team={team}
        agentObj="admin"
        filters={getActivityFilters(filterOptions)}
        setFilterOptions={setFilterOptions}
        formatColumns={[
          {
            method: formatEventName,
            column: 'event',
          },
          {
            method: formatUserColumn,
            column: 'user_name',
          },
          {
            method: formatMemberColumn,
            column: 'member_name',
          },
        ]}
        activityLogClasses="col-span-5"
      />
    </div>
  );
}

export default CybraryAdminActivityLog;
