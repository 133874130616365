import React, { useMemo } from 'react';
import Vimeo from '@u-wave/react-vimeo';
import Cookies from 'js-cookie';
import { DEFAULT_COOKIE_SETTINGS } from '../../constants';

/**
 * Wrapper for the Vimeo player that handles cookies for text track, playback rate, and volume.
 * @param {int|string} videoId - The ID of the video to play.
 * @param {string} className - The class name of the video player.
 * @param {boolean} autoplay - Whether the video should autoplay.
 * @param {boolean} responsive - Whether the video should be responsive.
 * @param {number} speed - The speed of the video playback.
 * @param {boolean} keyboard - Whether the video should be controlled by the keyboard.
 * @param {boolean} pip - Whether the video should be in picture-in-picture mode.
 * @param {boolean} playsInline - Whether the video should be played inline.
 * @param {string} quality - The quality of the video playback.
 * @param {boolean} transparent - Whether the video should be transparent.
 * @param {number} start - The time to start the video at (in seconds).
 * @param {boolean} paused - Whether the video should be paused.
 * @param {function} onPlay - The function to call when the video is played.
 * @param {function} onEnd - The function to call when the video is ended.
 * @param {function} onTimeUpdate - The function to call when the video is time updated.
 * @param {function} onTextTrackChange - The function to call when the video text track is changed.
 * @param {function} onPlaybackRateChange - The function to call when the video playback rate is changed.
 * @param {function} onVolumeChange - The function to call when the video volume is changed.
 * @param {function} onPause - The function to call when the video is paused.
 * @returns <Vimeo /> player
 */
function VimeoPlayer({
  videoId,
  className,
  autoplay,
  responsive,
  speed,
  keyboard,
  pip,
  playsInline,
  quality,
  transparent,
  start,
  paused,
  onPlay,
  onEnd,
  onTimeUpdate,
  onTextTrackChange,
  onPlaybackRateChange,
  onVolumeChange,
  onPause,
}) {
  const handleTextTrackChange = (event) => {
    onTextTrackChange?.(event);

    // Save selection to cookie
    // The event contains a language code and label
    if (event?.language) {
      Cookies.set('cyb_vimeo_text_track', event.language, { ...DEFAULT_COOKIE_SETTINGS });
    } else {
      Cookies.remove('cyb_vimeo_text_track');
    }
  };

  const handlePlaybackRateChange = (event) => {
    onPlaybackRateChange?.(event);

    const { playbackRate: rate } = event;
    // Save playback rate to cookie
    if (rate) {
      Cookies.set('cyb_vimeo_playback_rate', rate, { ...DEFAULT_COOKIE_SETTINGS });
    } else {
      Cookies.remove('cyb_vimeo_playback_rate');
    }
  };

  const handleVolumeChange = (event) => {
    onVolumeChange?.(event);

    // Save volume to cookie
    if (event?.volume) {
      Cookies.set('cyb_vimeo_volume', event.volume, { ...DEFAULT_COOKIE_SETTINGS });
    } else {
      Cookies.remove('cyb_vimeo_volume');
    }
  };

  const savedTextTrack = useMemo(() => Cookies.get('cyb_vimeo_text_track'), []); // Captions / subtitles
  const savedPlaybackRate = useMemo(() => parseFloat(Cookies.get('cyb_vimeo_playback_rate')) || null, []); // Speed
  const savedVolume = useMemo(() => parseFloat(Cookies.get('cyb_vimeo_volume')) || null, []); // Volume

  return (
    <Vimeo
      id="video-player-wrapper"
      className={className}
      autoplay={autoplay}
      responsive={responsive}
      speed={speed}
      video={videoId}
      keyboard={keyboard}
      pip={pip}
      playsInline={playsInline}
      quality={quality}
      textTrack={savedTextTrack}
      transparent={transparent}
      start={start}
      paused={paused}
      volume={savedVolume}
      playbackRate={savedPlaybackRate}
      color="df057b"
      onPlay={(event) => onPlay(event)}
      onEnd={(event) => onEnd(event)}
      onTimeUpdate={(event) => onTimeUpdate(event)}
      onPause={(event) => onPause(event)}
      onTextTrackChange={handleTextTrackChange}
      onPlaybackRateChange={handlePlaybackRateChange}
      onVolumeChange={handleVolumeChange}
    />
  );
}

export default VimeoPlayer;
