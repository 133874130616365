import React from 'react';
import { twMerge } from 'tailwind-merge';
import FormatUtil from '../../utils/formatUtil';
import Icon from '../Icon/Icon';

/**
 * Shows an icon with some text next to it
 * @param {string} iconName - Name of the icon to show
 * @param {string} text - Text to show next to the icon
 * @returns <ContinueLearningFeature />
 */
export function ContinueLearningFeature({ iconName, text }) {
  return (
    <div className="flex flex-nowrap items-center my-1 mr-4 text-xs font-bold text-black truncate">
      <Icon name={iconName} className="mr-2 w-4 h-4 text-black" /> {text}
    </div>
  );
}

/**
 * Shows the features of the content for the Continue Learning block's various tabs.
 * @param {string} className - Class name to apply to the root element
 * @param {string} type - Type of content (e.g. 'Course', 'TAC', etc.))
 * @param {int} learningSecondsTotal - Total time required to complete the content in seconds
 * @param {string} difficulty - Difficulty of the content (e.g. 'Beginner', 'Intermediate', 'Hard' etc.))
 * @param {int} totalEnrollments - Total users who have enrolled in this content
 * @param {int} xp - Total XP earned by the user for this content
 * @returns <ContinueLearningFeatures />
 */
export default function ContinueLearningFeatures({ className, type, learningSecondsTotal, difficulty = 'TBD', totalEnrollments, xp }) {
  return (
    <div className={twMerge('flex flex-wrap my-1 items-center', className)}>
      {!!type && <ContinueLearningFeature iconName="graduation-cap" text={type} />}
      {!!learningSecondsTotal && <ContinueLearningFeature iconName="clock" text={FormatUtil.formatTime(learningSecondsTotal, 'hma')} />}
      {!!difficulty && <ContinueLearningFeature iconName="speedometer" text={difficulty} />}
      {!!totalEnrollments && <ContinueLearningFeature iconName="user-group" text={FormatUtil.formatNumbers(totalEnrollments)} />}
      {!!xp && <ContinueLearningFeature iconName="xp" text={FormatUtil.formatNumbers(xp)} />}
    </div>
  );
}
