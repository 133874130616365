import { cloneDeep } from 'lodash';
import moment from 'moment';

/** format profile typeahead form fields */
const formatProfileDefaults = (section, profile) => {
  const profileDefaults = cloneDeep(profile);

  if (section === 'skills') {
    profileDefaults[section] = profile[section].map((skill) => {
      return {
        label: skill.term,
        value: skill.term,
        group: skill.group,
      };
    });
  } else if (section === 'work') {
    profile[section].forEach((item, index) => {
      profileDefaults[section][index].position = { label: item.position, value: item.position };
    });
  } else if (section === 'certifications') {
    profile[section].forEach((item, index) => {
      profileDefaults[section][index].name = { label: `${item.company} ${item.name}`, value: item.name };
    });
  }
  return profileDefaults[section];
};

/** Add default values for profile */
const addDefaultValues = (profile, schema, section, index = -1) => {
  const profileData = formatProfileDefaults(section, profile);

  // loop through profile section fields and assign the defaultValues
  const schemaKeys = Object.keys(schema.fields);
  for (let i = 0; i < schemaKeys.length; i++) {
    const fieldName = schemaKeys[i];
    const fieldData = schema.fields[fieldName];

    if (index >= 0) {
      fieldData.defaultValue = (profileData[index] && profileData[index][fieldName]) || fieldData.defaultValue;
    } else if (fieldName) {
      fieldData.defaultValue = section === 'skills' ? profileData : profileData[fieldName] || fieldData.defaultValue;
    }
  }

  return schema;
};

const getPastJobDuration = (pastJob) => {
  const startDate = moment(pastJob.startDate.split('-'));
  const endDate = pastJob.endDate ? moment(pastJob.endDate.split('-')) : moment(moment().format('YYYY-MM').split('-'));
  let pastJobYears = endDate.diff(startDate, 'years', true);
  const pastJobMonths = parseInt((pastJobYears % 1) * 12, 10);
  pastJobYears = parseInt(pastJobYears / 1, 10);
  const pluralYears = pastJobYears > 1 ? 'yrs' : 'yr';
  const pluralMonths = pastJobMonths > 1 ? 'mos' : 'mo';
  const years = pastJobYears >= 1 ? `${pastJobYears}${pluralYears}` : '';
  const months = pastJobMonths >= 1 ? `${pastJobMonths}${pluralMonths}` : '';
  let duration = '';
  if (years && months) {
    duration = `(${years} ${months})`;
  }
  if (years && !months) {
    duration = `(${years})`;
  }
  if (months && !years) {
    duration = `(${months})`;
  }
  return duration;
};

// Profile notifications after section submission
function notifyProfileCompleted(progressPercentage, triggerToast, complete, setComplete) {
  const { percent } = progressPercentage;
  const content = parseInt(percent, 10) === 100 && complete !== 100 ? { content: 'Your profile is 100% completed!' } : { content: 'Your profile has been updated!' };

  triggerToast('success', content);
  setComplete(parseInt(percent, 10));
}

// scroll to profile section
function scrollTo(id) {
  const sectionContainer = document.getElementById(id);
  if (sectionContainer) {
    sectionContainer.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}

const profileUtil = {
  addDefaultValues,
  getPastJobDuration,
  notifyProfileCompleted,
  scrollTo,
};

export default profileUtil;
