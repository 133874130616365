import React from 'react';
import moment from 'moment';
import fieldClasses from '../FormFields/fieldClasses';
import DatePicker from '../DatePicker/DatePicker';

class DatePickerField extends React.Component {
  state = {};

  componentDidMount() {
    this.handleChange(this.props.defaultValue ? this.props.defaultValue : null);
  }

  handleChange = (selDate) => {
    // Manage LOCAL state of the date picker, while calling the parents onChange function.
    const currState = {
      name: this.props.name,
      value: selDate ? new Date(selDate) : null,
    };
    this.setState(currState);
    // Need to pass date as a string to validation onChange, but must remain a JS date in state for datepicker component
    const formattedCurrState = {
      name: this.props.name,
      value: selDate ? moment(selDate).format(this.props.dateFormat || '') : null,
    };
    this.props.onChange(undefined, formattedCurrState);
  };

  render() {
    const { id, error, disabled, ariaDescribedBy, required } = this.props;
    return (
      <DatePicker
        id={id}
        className={`${fieldClasses(error, disabled)} w-44`}
        ariaDescribedBy={ariaDescribedBy}
        required={required}
        value={this.state.value}
        onChange={this.handleChange}
      />
    );
  }
}

export default DatePickerField;
