import React from 'react';
import { components } from 'react-select';

function ReactSelectInput(props) {
  const ariaProps = {
    'aria-describedby': `${props['aria-describedby'] || ''} ${
      props.selectProps && props.selectProps['aria-describedby'] ? props.selectProps && props.selectProps['aria-describedby'] : ''
    }`,
    'aria-required': props.selectProps && props.selectProps['aria-required'],
    'aria-autocomplete': props.selectProps && props.selectProps.isSearchable ? 'list' : 'none',
  };
  return <components.Input {...props} {...ariaProps} />;
}

export default ReactSelectInput;
