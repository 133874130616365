import React from 'react';
import ContentCard from '../../components/Card/ContentCard';
import ContentTransformer from '../../transformers/contentTransformer';

export default function CareerOutline({ contents }) {
  if (!contents || !contents.length) {
    return null;
  }
  return (
    <div>
      <h2 className="mb-4 text-2xl font-black">Outline</h2>
      <div className="grid grid-cols-1 md:flex md:flex-wrap md:gap-8">
        {contents.map((content) => {
          const formattedApiData = ContentTransformer.formatDataSources(content);
          return (
            <div key={formattedApiData.id} className="flex my-4">
              <ContentCard data={formattedApiData} className="w-full md:w-60" isComingSoon={formattedApiData.comingSoon} />
            </div>
          );
        })}
      </div>
    </div>
  );
}
