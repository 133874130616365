import React from 'react';

export default function Bookmark({ active, classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';
  if (active) {
    return (
      <svg className={className} fill="none" viewBox="0 0 21 26" xmlns="http://www.w3.org/2000/svg" {...accessibilityProps}>
        <path
          d="M1.78105 1.78105C1.28095 2.28115 1 2.95942 1 3.66667V25L10.3333 20.3333L19.6667 25V3.66667C19.6667 2.95942 19.3857 2.28115 18.8856 1.78105C18.3855 1.28095 17.7072 1 
          17 1H3.66667C2.95942 1 2.28115 1.28095 1.78105 1.78105Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
  return (
    <svg className={className} fill="none" viewBox="0 0 21 26" xmlns="http://www.w3.org/2000/svg" {...accessibilityProps}>
      <path
        d="M1.78105 1.78105C1.28095 2.28115 1 2.95942 1 3.66667V25L10.3333 20.3333L19.6667 25V3.66667C19.6667 2.95942 19.3857 2.28115 18.8856 1.78105C18.3855 1.28095 17.7072 1 
        17 1H3.66667C2.95942 1 2.28115 1.28095 1.78105 1.78105Z"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
