import moment from 'moment';

export default class AuthUtil {
  /**
   * Return null or invalid message.
   *
   * @returns {string|null}
   */
  static validatePassword(val) {
    const passwordStatus = AuthUtil.checkPassword(val.password);
    let isValid = true;

    Object.keys(passwordStatus).forEach((check) => {
      // If isValid is false, don't need to check anything else - it's not valid
      if (isValid) {
        isValid = passwordStatus[check];
      }
    });

    if (isValid) {
      return null;
    }
    return 'Your password does not meet the minimum security qualifications, please try again.';
  }

  /**
   * Return object of password validation status.
   *
   * @returns {object} - contains validation status for the four requirements
   */
  static checkPassword(password) {
    return {
      length: !!password && password.length > 7,
      number: !!password && /\d/.test(password),
      lowercase: !!password && /[a-z]/.test(password),
      uppercase: !!password && /[A-Z]/.test(password),
    };
  }

  /**
   * Wether or not all password validations passed
   */
  static checkIsPasswordValid(password) {
    const { length, number, lowercase, uppercase } = AuthUtil.checkPassword(password);
    return length && number && lowercase && uppercase;
  }

  /**
   * Returns whether we are in dev environment
   * @returns bool
   */
  static isDevEnvironment() {
    return window.location.hostname.match(/^localhost$/) || window.location.hostname.match(/\.netlify\.app$/);
  }

  /**
   * Returns whether we are in BWB (Staging environment)
   * @returns bool
   */
  static isBWBEnvironment() {
    return Boolean(window.location.hostname.match(/\.blackwaterbay\.cybrary\.it$/));
  }

  /**
   * Returns whether or not the userMeta object provided is a new user
   * based upon the registration and last login time stamps.
   */
  static isNewUser(userMeta, lastLoginThreshold = 10000) {
    if (!userMeta || typeof userMeta !== 'object') {
      return false;
    }
    const { last_login_date, registered_at } = userMeta;
    // check against the registration date first for email logins
    const accountAge = moment(last_login_date) - moment(registered_at); // ms since registration
    // A user is new if they have registered within the given lastLoginThreshold time frame
    if (accountAge <= lastLoginThreshold) {
      return true;
    }
    return false;
  }

  static getEuCountries() {
    return ['AT', 'BE', 'BG', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HR', 'HU', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE', 'GB'];
  }

  /**
   * Takes all user's emails and determines if they have a registered EDU/GOV/MIL email address
   * @param {Object} accounts - accounts.data object from profileStore
   */
  static isEduGovMilUser(accounts) {
    // If there are no accounts or no primary account, return false
    if (!accounts || !accounts.primary) {
      return false;
    }
    // init flat emails array with primary account
    const emails = [accounts.primary];
    // If there are other accounts, add them to the emails array
    if (accounts.other) {
      const otherAccounts = Object.values(accounts.other);
      otherAccounts.forEach((account) => emails.push(account));
    }
    return emails.some(({ email, verified }) => {
      // Required to be verified
      if (!verified) {
        return false;
      }
      // EDU
      if (email.match(/\.edu$|\.edu\.[a-z]{2}$|\.edu\.[a-z]{2}$/)) {
        return true;
      }
      // GOV
      if (email.match(/\.gov$|\.gov\.[a-z]{2}$/)) {
        return true;
      }
      // MIL
      if (email.match(/\.mil$|\.mil\.[a-z]{2}$/)) {
        return true;
      }
      // AC
      if (email.match(/\.ac$|\.ac\.[a-z]{2}$/)) {
        return true;
      }
      // K12
      if (email.match(/\.k12\.[a-z]{2}.us$/)) {
        return true;
      }

      // "hhs.nl"
      if (email.match(/\.hhs\.nl$/)) {
        return true;
      }
      return false;
    });
  }

  /**
   * Checks if the email is a known public email provider
   * @param {string} email
   * @returns {boolean}
   */
  static isPublicEmailDomain(email) {
    // GMAIL
    if (email.match(/gmail\.com$|googlemail\.com$/)) {
      return true;
    }
    // YAHOO
    if (email.match(/yahoo\.(com|co\.uk|fr|de|it|es|com\.au|co\.nz)$/)) {
      return true;
    }
    // HOTMAIL
    if (email.match(/hotmail\.(com|co\.uk|fr|de|it|es|com\.au)$/)) {
      return true;
    }
    // OUTLOOK
    if (email.match(/outlook\.(com|co\.uk|fr|de|it|es|com\.au)$/)) {
      return true;
    }
    // PROTONMAIL
    if (email.match(/protonmail\.(com|ch)$/)) {
      return true;
    }
    // AOL
    if (email.match(/aol\.(com|co\.uk|fr|de|it)$/)) {
      return true;
    }
    // APOLLO
    if (email.match(/apollo\.lv$/)) {
      return true;
    }
    // ZOHO
    if (email.match(/zoho\.(com|eu)$/)) {
      return true;
    }
    // YMAIL
    if (email.match(/ymail\.com$/)) {
      return true;
    }
    // Local Testing - cybrarydev.com
    if (AuthUtil.isDevEnvironment() && email.match(/cybrarydev\.(com|it)$/)) {
      return true;
    }

    return false;
  }

  /**
   * Checks if the user has only public email providers
   * @param {Object} accounts - accounts.data object from profileStore
   * @returns {boolean}
   */
  static isPublicEmailOnlyUser(accounts) {
    // If there are no accounts or no primary account, return false
    if (!accounts || !accounts.primary) {
      return false;
    }
    // init flat emails array with primary account
    const emails = [accounts.primary];
    // If there are other accounts, add them to the emails array
    if (accounts.other) {
      const otherAccounts = Object.values(accounts.other);
      otherAccounts.forEach((account) => emails.push(account));
    }
    // Only consider verified emails
    const verifiedEmails = emails.filter((account) => account.verified);
    return verifiedEmails.every(({ email }) => this.isPublicEmailDomain(email));
  }
}
