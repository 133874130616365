import React from 'react';
import { twMerge } from 'tailwind-merge';

/**
 * Used to show Add On Course tags on course cards
 * @param {array} tags_info Array of Content's tags
 * @param {array} tagsToCheck Array of tags that must exist in tags_info to show this message
 * @param {string} message Text to show on the card
 * @param {string | array} className class overrides
 * @returns
 */
export default function AddOnContentMessage({ tags_info = [], type = '', tagsToCheck = [], message = '', className = '' }) {
  // required props
  if (!tags_info || !tagsToCheck || !message || !type) return null;

  // required type - needs "Add On Course" as type as well as every tagToCheck needs to exist in the tags_info array.
  if (type !== 'Add On Course') return null;

  // required tags - needs every tagToCheck to exist in the tags_info array.
  const isShown = tagsToCheck.every((tag) => tags_info.includes(tag));
  if (!isShown) return null;

  return <div className={twMerge('text-xs text-left', className)}>{message}</div>;
}
