import React from 'react';
import If from '../../../components/If/If';
import Header from '../../../components/Header/Header';
import HorizontalRule from './HorizontalRule';

/**
 * Example section container for components
 * @param {string} title - the title of the section
 * @param {string|JSX} description - the description of the section
 * @param {JSX.Element} children - the children to be displayed
 * @returns {JSX.Element} - the example section
 */
function ExampleSection({ title, description, children }) {
  return (
    <div className="pb-8 mb-8 w-full">
      <If condition={title}>
        <Header as="h1">{title}</Header>
      </If>
      <If condition={description}>
        <p>{description}</p>
      </If>
      {children}
      <HorizontalRule />
    </div>
  );
}

export default ExampleSection;
