import React, { useState, useEffect } from 'react';
import Dropdown from './Dropdown';
import MultiSelect from '../FormFields/MultiSelect';

// Wrapper to the regular Dropdown to handle accessibility issues
// For Keyboard users, the filter changes shouldn't occur until user blurs off of dropdown. Mouse users it happens on selection
function DropdownFilter(props) {
  const [val, setVal] = useState(props.value);
  const [triggerOnChange, setTriggerOnChange] = useState(false);

  useEffect(() => {
    if (val !== props.value) {
      setVal(props.value);
    }
  }, [props.value]);

  useEffect(() => {
    if (triggerOnChange) {
      props.onChange({ value: val });
      setTriggerOnChange(false);
    }
  }, [triggerOnChange]);

  const onBlur = () => {
    if (props.value !== val) {
      props.onChange({ value: val });
    }
  };

  const onChange = (e, { value }, action) => {
    if (props.onSelect) {
      props.onSelect(value);
    } else {
      setVal(value);
      // If clearing, trigger on change regardless of input type
      if (action && action.action === 'clear') {
        setTriggerOnChange(true);
      }
    }
  };

  const onMouseDown = (selectProps, action) => {
    if (selectProps.isDisabled) {
      return null;
    }
    if (action === 'addOption') {
      // Have component trigger regular value setting
      selectProps.selectOption(selectProps.data);
    } else if (action) {
      action();
    }
    return setTriggerOnChange(true);
  };

  if (props.isMulti) {
    return <MultiSelect {...props} onBlur={onBlur} onChange={onChange} onMouseDown={onMouseDown} value={val} />;
  }

  return <Dropdown {...props} onBlur={onBlur} onChange={(data) => onChange(undefined, data)} onMouseDown={onMouseDown} value={val} />;
}

export default DropdownFilter;
