import React from 'react';
import Modal from '../Modal/Modal';
import Icon from '../Icon/Icon';
import Message from '../Message/Message';
import gaUtil from '../../utils/gaUtil';
import AddLink from '../AddLink/AddLink';

function CipFeatureListItem({ title }) {
  return (
    <div className="flex items-center">
      <Icon name="x" className="mr-2 w-4 h-4 text-red-500" /> {title}
    </div>
  );
}
function CipFeatureList() {
  return (
    <div className="block sm:grid sm:grid-cols-2 features-list">
      <div>
        <CipFeatureListItem title="300+ Courses" />
        <CipFeatureListItem title="500+ Virtual Labs" />
        <CipFeatureListItem title="200+ Practice Tests" />
        <CipFeatureListItem title="200+ Assessments" />
      </div>
      <div>
        <CipFeatureListItem title="Career Paths" />
        <CipFeatureListItem title="Community Chat" />
        <CipFeatureListItem title="Mentorship" />
      </div>
    </div>
  );
}

function FirstWeekCancellationHeader() {
  return (
    <>
      <h2 id="cip-cancel-modal" className="my-4 text-2xl font-semibold">
        Are you sure you want to cancel today?
      </h2>
      <p className="mt-6 font-semibold">You will immediately lose access to:</p>
    </>
  );
}

function DefaultCancellationHeader({ cipSubscriptionExpiredDate }) {
  return (
    <>
      <h2 id="cip-cancel-modal" className="font-semibold">
        Are You Sure?
      </h2>
      <div className="cancellation-timeline">
        <div className="expiration-date">
          <p>Today</p>
          <p>{cipSubscriptionExpiredDate}</p>
        </div>
        <div className="line">
          <span className="left-circle" />
          <span className="right-circle" />
        </div>
        <div className="end-subscription">
          <p className="active">Access</p>
          <p>No Access</p>
        </div>
      </div>
      <p className="mt-6 font-semibold">Note that you will lose access to:</p>
    </>
  );
}

function CourseProgressWarning() {
  return (
    <Message icon="exclamation-circle" msgHeader="Cybary no longer provides free video courses" className="mt-4">
      <div className="text-sm">
        <p className="mt-4 mb-1">You will not be able to advance in currently enrolled courses.</p>
        <p>You will not be able to progress past the first activity in newly enrolled courses.</p>
      </div>
    </Message>
  );
}

function CipCancelModal({ showCIPModal, toggleCIPModal, subscriptionId, plan, gateway, cipSubscriptionExpiredDate, isFirstWeekCancellation }) {
  const handleContinueSubscription = () => {
    gaUtil.fireEvent('Abandonment', 'CIP Cancellation', 'Abandon Cancellation');
    toggleCIPModal();
  };
  return (
    <Modal className="cip-cancel-modal" open={showCIPModal} toggle={() => toggleCIPModal()} ariaLabelledBy="cip-cancel-modal">
      <div className="px-7 pt-14">
        <div>
          {isFirstWeekCancellation ? <FirstWeekCancellationHeader /> : <DefaultCancellationHeader cipSubscriptionExpiredDate={cipSubscriptionExpiredDate} />}
          <CipFeatureList />
          <CourseProgressWarning />
          <div className="mt-4 text-right">
            <button className="px-6 h-10 text-lg font-bold leading-5 text-center text-white bg-cyb-pink-500 hover:bg-cyb-pink-600 rounded-sm" onClick={handleContinueSubscription}>
              Continue Insider Pro Access
            </button>
            <div className="mt-4">
              <AddLink to={`/cybrary-insider-pro/cancel?subscriptionId=${subscriptionId}&plan=${plan}&gateway=${gateway}`}>
                <span className="text-sm text-black hover:text-cyb-pink-500">Cancel Subscription</span>
              </AddLink>
              <p className="text-xs text-gray-600">(Your progress and account will not be deleted)</p>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default CipCancelModal;
