import React from 'react';
import Container from '../../components/Container/Container';
import Icon from '../../components/Icon/Icon';

export default function GoogleMarketplaceOnboardingPage() {
  return (
    <Container size="dashboard" omitPadding className="px-6 pt-4 max-w-[50rem]">
      <h1 id="page-top" className="sr-only">
        Google Marketplace Onboarding Page
      </h1>
      <div className="mt-4 h-full font-black text-center text-cyb-pink-500">
        <div className="my-8 text-4xl">Congratulations on completing your Cybrary purchase and registration! </div>
        <Icon name="user-with-stars" className="shrink-0 my-16 mx-auto w-72 h-72" />
        <p className="my-8">Your Customer Success Manager will be in touch with you soon to get started.</p>
      </div>
    </Container>
  );
}
