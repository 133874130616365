import React from 'react';
import CountUp from 'react-countup';
import Card from '../Card';

/**
 * CountUpCard is a card that displays a number that counts up from 0 to the value prop
 * @param {string} title - title of the card
 * @param {number} value - value to count up to
 * @returns
 */
function CountUpCard({ title, value = 0 }) {
  return (
    <Card>
      <Card.Section>
        <CountUp className="mb-1 text-2xl font-black" separator="," end={value} duration={1} />
        <p className="text-sm">{title || ''}</p>
      </Card.Section>
    </Card>
  );
}

export default CountUpCard;
