import React from 'react';
import { inject, observer } from 'mobx-react';
import Header from '../Header/Header';
import StyleUtil from '../../utils/styleUtil';
import UpgradeButton from '../UpgradeButton/UpgradeButton';
import Container from '../Container/Container';
import './embed.css';

function InterstitialContent({ contentType, blockedContent, hideNext, hasPremiumContentLicense, dismiss }) {
  const upgradeLinkParams = blockedContent ? `?appimv=upgradepromo&type=${contentType}&campaign=firstvideofree` : '?appimv=upgradepromo&type=course';
  const interstitialText = hasPremiumContentLicense
    ? 'This learning activity requires a cybersecurity content license. Contact your Customer Success Manager to upgrade your account.'
    : 'Unlock access to the entire library of on-demand videos, virtual labs, and assessments by Upgrading to Cybrary Insider Pro or Cybrary for Teams.';
  return (
    <div className="flex">
      <div className="hidden sm:block sm:w-9/12 md:w-7/12">
        <img src={StyleUtil.getTeaserImage(contentType)} alt="" style={{ margin: 'auto' }} />
      </div>

      <div className="w-full sm:w-9/12 md:w-7/12">
        <div className="p-4">
          {blockedContent ? (
            <>
              <Header as="h3" className="mb-4 text-lg font-bold text-white">
                Upgrade your account to continue
              </Header>
              <div style={{ color: '#fff', fontSize: '16px' }}>{interstitialText}</div>
              <div
                style={{
                  display: 'flex',
                  margin: '1em 0',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <UpgradeButton upgradeText="Upgrade" upgradeLinkParams={upgradeLinkParams} openChatOnly={hasPremiumContentLicense} />
              </div>
            </>
          ) : (
            <>
              <Header as="h3" className="mb-4 text-lg font-bold text-white">
                Elevate your learning
              </Header>
              <div style={{ color: '#fff', fontSize: '16px' }}>
                Unlock Cybrary&apos;s entire library including virtual labs, assessments, and practice tests by Upgrading to Cybrary Insider Pro or Cybrary for Teams.
              </div>

              <div
                style={{
                  display: 'flex',
                  margin: '1em 0',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <UpgradeButton upgradeText="Upgrade" upgradeLinkParams={upgradeLinkParams} />

                {!hideNext && (
                  <a
                    style={{
                      color: '#ffffff',
                      textDecoration: 'underline',
                      marginLeft: '2em',
                      fontSize: '0.9em',
                    }}
                    href="/immersive"
                    onClick={(e) => {
                      e.preventDefault();
                      dismiss();
                    }}
                  >
                    Continue to Next Video
                  </a>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

/**
 * Promotional interstitial embedded item in immersive
 */
const InterstitialEmbed = inject('userStore')(
  observer(
    class InterstitialEmbed extends React.Component {
      dismiss = () => {
        this.props.dismiss();
      };

      render() {
        const { contentType, blockedContent, hideNext } = this.props;
        return (
          <div className="interstitial-embed">
            <div className="flex justify-center items-center text-white bg-black upgrade-paywall">
              <Container>
                <div className="m-auto">
                  <InterstitialContent
                    blockedContent={blockedContent}
                    contentType={contentType}
                    hideNext={hideNext}
                    hasPremiumContentLicense={this.props.userStore.hasPremiumContentLicense}
                    dismiss={this.dismiss}
                  />
                </div>
              </Container>
            </div>
          </div>
        );
      }
    }
  )
);

export default InterstitialEmbed;
