import React, { createContext, useContext, useState, useMemo } from 'react';
import queryString from 'query-string';
import Bugsnag from '@bugsnag/js';
import agents from '../agents/agents';
import { getApiRequest } from '../components/Clab/agents';

const ClabContext = createContext();

function ClabProvider({ children }) {
  const [launched, setLaunched] = useState(false);
  const [loading, setLoading] = useState(false);
  const [labInstanceId, setLabInstanceId] = useState(null);
  const [token, setToken] = useState(null);
  const [session, setSession] = useState(null);
  const [account, setAccount] = useState(null);
  const [resources, setResources] = useState([]);
  const [activeResource, setActiveResource] = useState(null);
  const [lab, setLab] = useState(null);

  const launch = async (clabId) => {
    setLoading(true);
    try {
      const launchResult = await agents.catalog.launch(clabId);
      const { url } = launchResult;
      const parsed = queryString.parseUrl(url);
      setLabInstanceId(parsed.query.labInstanceIdentifier);
      setToken(parsed.query.token);
      setSession(parsed.query.session);
      setLaunched(true);
    } catch (err) {
      Bugsnag.notify(err);
    }
    setLoading(false);
  };

  // Reset to the original state, for when we exit a clab
  const reset = () => {
    setLaunched(false);
    setLoading(false);
    setLabInstanceId(null);
    setToken(null);
    setSession(null);
    setAccount(null);
    setResources([]);
    setLab(null);
  };

  // Load our clab account
  const getAccount = async () => {
    const result = await getApiRequest('/admin/account');
    const { accountId, accountName, email } = result;
    setAccount({
      id: accountId,
      name: accountName,
      email,
    });
  };

  const state = useMemo(
    () => ({
      launched,
      loading,
      labInstanceId,
      token,
      session,
      account,
      resources,
      lab,
      activeResource,
      launch,
      reset,
      setResources,
      setLab,
      getAccount,
      setActiveResource,
    }),
    [launched, loading, labInstanceId, token, session, account, resources, lab, activeResource]
  );

  return <ClabContext.Provider value={state}>{children}</ClabContext.Provider>;
}

export const useClab = () => useContext(ClabContext);
export default ClabProvider;
