import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import CountUp from 'react-countup';
import moment from 'moment';
import Loading from '../Loading/Loading';
import Segment from '../Segment/Segment';
import StyledError from '../Error/StyledError';
import PercentageBar from '../Analytics/PercentageBar';
import AvatarsList from '../Avatar/AvatarsList';
import AdminTable from '../AdminTable/AdminTable';
import FormatUtil from '../../utils/formatUtil';
import RecurringGoalIcon from './RecurringGoalIcon';
import Container from '../Container/Container';
import './goals.css';

const GoalsList = inject('userStore')(
  observer(
    class GoalsList extends Component {
      formatGoalTitle = (col) => {
        if (!col) {
          return null;
        }
        return FormatUtil.formatLongText(col.value, 25);
      };

      formatProgress = (col) => {
        if (!col) {
          return null;
        }
        const progress = Math.floor(col.value);
        return (
          <div className="goal-progress">
            <div className="percentage" style={{ maxWidth: '6rem' }}>
              <PercentageBar color="#DF057B" maxHeight="0.5em" width={`${progress || 0}%`} />
            </div>
            <CountUp className="progress" end={progress || 0} suffix="%" duration={2} />
          </div>
        );
      };

      formatDate = (data, row, headings, col) => {
        if (!data || !data.value) {
          return null;
        }
        const dateFormatted = moment(data.value).format('MM/DD/YYYY');
        if (col === 'due_date') {
          const dueTypeIdx = FormatUtil.getColIndex(headings, 'due_type');
          if (dueTypeIdx && row[dueTypeIdx] && row[dueTypeIdx].value === 'recurring') {
            return (
              <span className="flex items-center">
                {dateFormatted} <RecurringGoalIcon />
              </span>
            );
          }
        }
        return dateFormatted;
      };

      formatOwners = (col) => {
        if (!col || !col.value || !col.value.length) {
          return null;
        }
        return <AvatarsList data={col.value} limit={2} />;
      };

      formatParticipants = (col) => {
        if (!col || !col.value || !col.value.length) {
          return null;
        }
        // Check if there are any avatars anywhere in the array. If so, it's assigned to users and show avatars
        const participantUsers = col.value.filter((item) => {
          return !!item.avatar_url;
        });
        if (participantUsers.length) {
          return <AvatarsList data={col.value} limit={2} />;
        }
        // No avatars, so either a team or group(s). Just display name
        const teamDisplays = col.value.map((team) => {
          return team.name;
        });
        return <div className="goal-participants">{teamDisplays.join(', ')}</div>;
      };

      // Check if this goal can be managed by this user (either user is an owner of the goal, or admin of team)
      checkShowEditOptions = (row, headings) => {
        const { user, team } = this.props.userStore;
        const ownersIdx = FormatUtil.getColIndex(headings, 'owners');
        const owners = ownersIdx > -1 ? row[ownersIdx].value : [];
        const ownersArray = owners.map((owner) => owner.id);
        return ownersArray.indexOf(user.id) > -1 || team.permissions.canManageTeam;
      };

      getDisplayButtons = (viewGoal, editGoal, deleteGoal, isCompleted) => {
        const displayButtons = [
          { text: 'View', icon: 'list alternate outline', action: viewGoal },
          { text: 'Delete', icon: 'trash alternate outline', action: deleteGoal, displayCondition: this.checkShowEditOptions },
        ];
        if (!isCompleted) {
          displayButtons.splice(1, 0, {
            text: 'Edit',
            icon: 'edit outline',
            action: editGoal,
            displayCondition: this.checkShowEditOptions,
          });
        }
        return displayButtons;
      };

      tableSort = (heading) => {
        if (heading) {
          if (heading === this.props.teamGoals.queryParams.sortCol) {
            this.props.setQueryParams('teamGoals', 'sortCol', this.props.teamGoals.queryParams.sortCol);
            this.props.setQueryParams('teamGoals', 'sortDirection', this.props.teamGoals.queryParams.sortDirection === 'desc' ? 'asc' : 'desc');
          } else {
            this.props.setQueryParams('teamGoals', 'sortCol', heading);
            this.props.setQueryParams('teamGoals', 'sortDirection', 'desc');
          }
          this.props.getTeamGoals();
        }
      };

      render() {
        const { teamGoals, viewGoal, editGoal, deleteGoal } = this.props;
        const { data, loading, error, queryParams } = teamGoals;
        if (loading) {
          return (
            <Container>
              <Loading message="Loading..." wrapperClassName="py-8" />
            </Container>
          );
        }
        if (error) {
          return (
            <Container>
              <StyledError error={error} />
            </Container>
          );
        }
        if (!data || !data.tableData || !data.tableData.length) {
          return (
            <Segment className="border-none">
              <p>You have no goals set. Click &quot;Add new&quot; to create your first goal.</p>
            </Segment>
          );
        }

        return (
          <Segment className="px-0 border-none goals-list">
            <AdminTable
              headings={data.columns}
              headerClickFunc={this.tableSort}
              sortCol={queryParams.sortCol}
              sortDirection={queryParams.sortDirection}
              data={data.tableData}
              rowClick={viewGoal}
              formatColumns={[
                {
                  method: this.formatGoalTitle,
                  colIdx: FormatUtil.getColIndex(data.columns, 'name'),
                },
                {
                  method: this.formatProgress,
                  colIdx: FormatUtil.getColIndex(data.columns, 'percent_completed'),
                },
                {
                  method: this.formatParticipants,
                  colIdx: FormatUtil.getColIndex(data.columns, 'participants'),
                },
                {
                  method: this.formatDate,
                  colIdx: FormatUtil.getColIndex(data.columns, 'start_date'),
                },
                {
                  method: (col, row, headings) => this.formatDate(col, row, headings, 'due_date'),
                  colIdx: FormatUtil.getColIndex(data.columns, 'due_date'),
                },
                {
                  method: this.formatOwners,
                  colIdx: FormatUtil.getColIndex(data.columns, 'owners'),
                },
              ]}
              rowOptions={this.getDisplayButtons(viewGoal, editGoal, deleteGoal, this.props.isCompleted)}
            />
          </Segment>
        );
      }
    }
  )
);

export default GoalsList;
