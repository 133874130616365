import React from 'react';
import ExampleSection from './ExampleSection';
import Header from '../../../components/Header/Header';
import Code from '../../../components/Code/Code';
import CountUpCard from '../../../components/Card/CountUpCard/CountUpCard';
import CountUpWidget from '../../../components/Card/CountUpCard/CountUpWidget';

function CountUpCardExampleSection() {
  return (
    <ExampleSection title="CountUp Cards" description="A Card is simply a container with a thin light gray border and padding.">
      {/** Default */}
      <Header as="h2">CountUpCard</Header>
      <CountUpCard title="Learning Hours" value={108} />
      <Code>
        {`
      <CountUpCard title="Learning Hours" value={108} />
        `}
      </Code>
      {/** Widget */}
      <Header as="h2">CountUpWidget</Header>
      <div className="flex gap-4">
        <CountUpWidget title="Learning Hours" value={108} />
        <CountUpWidget title="Learning Minutes" value={108 * 60} />
        <CountUpWidget title="Learning Seconds" value={108 * 60 * 60} />
      </div>
      <Code>
        {`
      <CountUpWidget title="Learning Hours" value={108} />
      `}
      </Code>
    </ExampleSection>
  );
}

export default CountUpCardExampleSection;
