import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import VisibilitySensor from 'react-visibility-sensor';
import { isEmpty } from 'lodash';
import withRouter from '../../components/Router/withRouter';
import Notification from '../../components/Notifications/Notification';
import Container from '../../components/Container/Container';
import Loading from '../../components/Loading/Loading';
import Title from '../../components/Title/Title';

const NotificationsPage = inject(
  'authStore',
  'commonStore',
  'userNotificationStore'
)(
  observer(
    class NotificationsPage extends Component {
      state = {
        activePage: 0,
        lastPage: 1,
        loading: false,
        initialized: false,
        notifications: [],
      };

      componentDidMount() {
        this.props.authStore.fireAttributionEvent();
        this.props.commonStore.setPageTitle('Notification Center | Cybrary');
      }

      fetchNotifications = async (page) => {
        this.props.userNotificationStore.getNotifications(`page=${page}`).then(() => {
          const { notifications } = this.props.userNotificationStore;
          const newState =
            notifications && !isEmpty(notifications)
              ? {
                  ...this.state,
                  activePage: notifications.current_page,
                  lastPage: notifications.last_page,
                  loading: false,
                  initialized: true,
                  notifications: notifications.data,
                }
              : {
                  ...this.state,
                  lastPage: 0,
                  loading: false,
                  initialized: true,
                };
          this.setState(newState);
        });
      };

      loadMore = (visible) => {
        if (visible && !this.state.loading && this.state.activePage < this.state.lastPage) {
          const { activePage } = this.state;
          const newState = {
            ...this.state,
            loading: true,
          };
          this.setState(newState, () => {
            this.fetchNotifications(activePage + 1);
          });
        }
      };

      render() {
        const { notifications, loading, initialized, activePage, lastPage } = this.state;
        const hasNotifications = notifications.length;

        return (
          <Container size="md">
            <Title title="Notifications" />
            <ul className="mb-24">
              {!hasNotifications && initialized && (
                <li className="flex flex-col items-center pb-8 mb-8 w-full border-0 border-b-xs md:flex-row lg:pb-12">
                  <p>There are no notifications to display.</p>
                </li>
              )}
              {hasNotifications && initialized
                ? notifications.map((notification) => {
                    return <Notification key={notification.id} notification={notification} />;
                  })
                : null}

              <div className="text-center">
                <VisibilitySensor onChange={this.loadMore}>
                  <>
                    {loading && <Loading wrapperClassName="py-12" />}
                    {!loading && activePage < lastPage ? <p>Scroll to see more...</p> : null}
                    {!loading && hasNotifications && activePage === lastPage ? <p>There are no more notifications to display.</p> : null}
                  </>
                </VisibilitySensor>
              </div>
            </ul>
          </Container>
        );
      }
    }
  )
);

export default withRouter(NotificationsPage);
