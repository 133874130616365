import React from 'react';

export default function Mountain({ classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';

  return (
    <svg className={className} viewBox="0 0 112 135" fill="none" xmlns="http://www.w3.org/2000/svg" {...accessibilityProps}>
      <path
        d="M40.7752 91.4202C41.475 93.6141 42.805 97.6639 43.68 98.1282C44.4852 97.6639 45.85 93.6141 46.55 91.4202C48.3351 86.0623 50.5051 79.4389 55.9651 79.4389C60.76 79.4389 63.2802 83.3625 64.925 85.9781C66.5001 88.4671 67.095 89.1422 68.2501 89.1422C69.4402 89.1422 70.0702 88.4671 71.645 85.9359C73.1502 83.5734 75.3552 80.114 79.3801 79.5233L64.4002 54.1686C63.175 52.0592 61.4601 50.625 59.5001 49.739V36.7032H80.7802C82.7051 36.7032 84.2802 34.8047 84.2802 32.4844V12.6562C84.2802 10.336 82.7051 8.4375 80.7802 8.4375H56.0001C54.0751 8.4375 52.5001 10.336 52.5001 12.6562V49.739C50.5751 50.625 48.8251 52.0592 47.6 54.1686L32.6201 79.5656C37.1701 80.5358 39.1301 86.5266 40.7752 91.4202Z"
        fill="#E2037A"
      />
      <path
        d="M103.18 119.896L85.4003 89.7322C84.8402 89.6903 84.2803 89.4372 83.7902 89.0151C83.0552 88.4244 82.2502 87.876 80.5703 87.876C79.4152 87.876 78.8203 88.5511 77.2102 91.04C75.5653 93.6559 73.0803 97.5793 68.2502 97.5793C63.4553 97.5793 61.0052 93.6559 59.3603 91.0823C57.7502 88.5511 57.1553 87.876 55.9652 87.876C55.1252 88.3402 53.7952 92.3481 53.0601 94.5417C51.3101 99.8573 49.1053 106.523 43.6451 106.523C38.1851 106.523 36.0152 99.8996 34.2652 94.5417C33.5303 92.3901 32.2003 88.3402 31.3253 87.876C29.9252 87.876 29.0852 88.298 28.2102 88.9729C27.7552 89.3527 27.2303 89.5636 26.6702 89.6058L8.82031 119.896C8.08523 121.204 7.98034 122.892 8.57528 124.284C9.17022 125.676 10.3603 126.562 11.6903 126.562H100.31C101.64 126.562 102.83 125.676 103.425 124.284C104.02 122.892 103.915 121.204 103.18 119.896Z"
        fill="#E2037A"
      />
    </svg>
  );
}
