import React from 'react';
import Checkbox from '../FormFields/Checkbox';

function GraphTableToggle({ onChange, checked, className, disabled }) {
  return (
    <div className={`flex print:hidden items-center text-sm ${className}`}>
      <Checkbox toggle className="mr-8" onChange={onChange} checked={checked} ariaLabelledBy="toggle-table" disabled={disabled} />
      <p id="toggle-table">Display graphs in tabular format</p>
    </div>
  );
}

export default GraphTableToggle;
