import React from 'react';

function Path({ classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';

  return (
    <svg className={className} viewBox="0 0 50 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...accessibilityProps}>
      <path
        d="M43.1305 0.278442C39.3429 0.278442 36.2615 3.36161 36.2615 7.15151C36.2615 7.65555 36.3175 8.14679 36.4209 8.62036L27.6052 13.0313C26.3446 11.5727 24.4838 10.6468 22.4098 10.6468C18.6221 10.6468 15.5406 13.73 15.5406 17.5199C15.5406 21.3098 18.6221 24.3929 22.4098 24.3929C22.948 24.3929 23.4714 24.3286 23.9742 24.2109L28.1934 30.5446C26.7875 31.804 25.9008 33.6321 25.9008 35.6643C25.9008 36.0545 25.9354 36.4369 25.998 36.8094L13.4561 38.9012C12.6146 36.0571 9.98114 33.9754 6.86936 33.9754C3.08165 33.9754 0 37.0584 0 40.8481C0 44.6384 3.08165 47.7215 6.86936 47.7215C10.1574 47.7215 12.912 45.3975 13.5818 42.305L27.4397 39.9936C28.7002 41.5443 30.621 42.5372 32.7699 42.5372C36.5579 42.5372 39.6397 39.454 39.6397 35.6643C39.6397 31.8746 36.5579 28.7914 32.7699 28.7914C32.2317 28.7914 31.7084 28.8559 31.2057 28.9734L26.9865 22.6396C28.3924 21.3801 29.279 19.552 29.279 17.5199C29.279 17.0158 29.2229 16.5247 29.1195 16.0513L37.9354 11.6402C39.1958 13.099 41.0566 14.0249 43.1305 14.0249C46.9184 14.0249 50 10.9416 50 7.15151C50 3.36161 46.9184 0.278442 43.1305 0.278442ZM6.86936 44.3434C4.94428 44.3434 3.37828 42.7756 3.37828 40.8481C3.37828 38.9212 4.94444 37.3534 6.86936 37.3534C8.79428 37.3534 10.3603 38.9212 10.3603 40.8481C10.3603 42.7754 8.79428 44.3434 6.86936 44.3434ZM36.2616 35.6643C36.2616 37.5914 34.6953 39.1591 32.77 39.1591C30.845 39.1591 29.2791 37.5916 29.2791 35.6643C29.2791 33.7374 30.8451 32.1695 32.77 32.1695C34.6953 32.1695 36.2616 33.7374 36.2616 35.6643ZM18.9189 17.5199C18.9189 15.5928 20.4848 14.0249 22.4098 14.0249C24.3347 14.0249 25.9007 15.5928 25.9007 17.5199C25.9007 19.447 24.3347 21.0148 22.4098 21.0148C20.4848 21.0148 18.9189 19.447 18.9189 17.5199ZM43.1305 10.6468C41.2056 10.6468 39.6397 9.07878 39.6397 7.15151C39.6397 5.2244 41.2057 3.65656 43.1305 3.65656C45.0557 3.65656 46.6217 5.22423 46.6217 7.15151C46.6219 9.07861 45.0557 10.6468 43.1305 10.6468Z"
        fill="url(#paint0_linear_3_617)"
      />
      <defs>
        <linearGradient id="paint0_linear_3_617" x1="25" y1="0.278442" x2="25" y2="47.7215" gradientUnits="userSpaceOnUse">
          <stop stopColor="#CE2D79" />
          <stop offset="1" stopColor="#25135F" />
        </linearGradient>
      </defs>
    </svg>
  );
}
export default Path;
