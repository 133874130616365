import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { observer, inject } from 'mobx-react';
import AdminTable from '../../AdminTable/AdminTable';
import SearchInput from '../../Search/SearchInput';
import Loading from '../../Loading/Loading';
import StyledError from '../../Error/StyledError';
import Container from '../../Container/Container';
import { useCybAssessmentPath } from '../../../providers/CybAssessmentPathProvider';
import { formatTableColumns, renderTrendingColumn } from '../shared';
import BaselineEmptyText from './BaselineEmptyText';
import Tooltip from '../../Tooltip/Tooltip';
import Icon from '../../Icon/Icon';

const COLUMN_MODIFICATIONS = {
  real_name: {
    display: 'Name',
  },
  topics_count: {
    display: 'Topics Assessed',
    tooltip: 'The count of Topic Assessments completed, across all Assessment Paths, completed by the user',
  },
  score: {
    tooltip: 'The average score of the user’s best attempts across all completed Topic Assessments',
  },
  trending: {
    display: 'Trending(90d)',
    tooltip: 'Change in the user’s score over the past 90 days',
  },
};

const BaselineTeamsOverviewMemberTable = inject(
  'userStore',
  'reportsStore'
)(
  observer(({ userStore, reportsStore }) => {
    const {
      cumulativeLearners,
      isLoadingCumulativeLearners,
      cumulativeLearnersError,
      actions: { getCumulativeLearners },
    } = useCybAssessmentPath();

    const { downloadReport, downloadLoading } = reportsStore;

    const orgId = userStore?.adminTeam?.id;

    const assessmentPathsCumulativeLearners = cumulativeLearners[orgId];

    const [inputValue, setInputValue] = useState('');

    const [filteredTableUsers, setFilteredTableUsers] = useState(assessmentPathsCumulativeLearners?.default ?? []);

    const modifiedTableColumns = useMemo(
      () =>
        assessmentPathsCumulativeLearners?.table?.columns?.map((column) => ({
          ...column,
          ...COLUMN_MODIFICATIONS[column.key],
        })),
      [assessmentPathsCumulativeLearners?.table?.columns]
    );

    const MEMBER_TABLE = useMemo(() => {
      const tableData = filteredTableUsers.map((user) => assessmentPathsCumulativeLearners?.table?.tableData.find((data) => data[0].value === user.user_id));

      return {
        columns: modifiedTableColumns,
        tableData,
      };
    }, [modifiedTableColumns, filteredTableUsers, assessmentPathsCumulativeLearners?.table?.tableData]);

    const renderColumn = useCallback((row, fullRow, headings, column) => {
      if (row.value !== undefined && column.showCol) {
        const percentage = Math.round(parseFloat(row.value * 100));

        if (column.key === 'trending') {
          return renderTrendingColumn(fullRow, headings, 'trending');
        }

        return `${percentage}%`;
      }

      return '';
    }, []);

    const formatColumns = useMemo(() => formatTableColumns(MEMBER_TABLE?.columns, ['score', 'trending'], renderColumn), [renderColumn, MEMBER_TABLE?.columns]);

    const handleInputChange = useCallback(
      (value) => {
        setInputValue(value);

        setFilteredTableUsers(() => {
          if (!value) {
            return assessmentPathsCumulativeLearners?.default;
          }
          return assessmentPathsCumulativeLearners?.default?.filter((user) => user.real_name.toLowerCase().includes(value.trim().toLowerCase()));
        });
      },
      [assessmentPathsCumulativeLearners?.default]
    );

    const onChange = useCallback(
      (e) => {
        const input = e.target.value;

        handleInputChange(input);
      },
      [handleInputChange]
    );

    const onClear = useCallback(() => {
      handleInputChange('');
    }, []);

    const exportTableData = useCallback(() => downloadReport('cumulative-assessment-learners', orgId, null, 'table', false), [orgId]);

    useEffect(() => {
      if (!assessmentPathsCumulativeLearners) {
        getCumulativeLearners(orgId);
      } else if (!filteredTableUsers.length) {
        setFilteredTableUsers(assessmentPathsCumulativeLearners?.default);
      }
    }, [assessmentPathsCumulativeLearners]);

    if (isLoadingCumulativeLearners) {
      return (
        <Container className="mt-10">
          <Loading message="Loading..." />
        </Container>
      );
    }

    if (cumulativeLearnersError) {
      return (
        <Container className="mt-10">
          <StyledError error={cumulativeLearnersError} />
        </Container>
      );
    }

    if (!assessmentPathsCumulativeLearners) {
      return (
        <Container className="mt-10">
          <BaselineEmptyText orgId={orgId} />
        </Container>
      );
    }

    return (
      <div className="flex flex-col mt-10">
        <div className="flex flex-row justify-between mb-4">
          <div className="w-2/5">
            <SearchInput placeholder="Search Members" value={inputValue} onChange={onChange} ariaLabel="Search members" onClose={onClear} isClearable />
          </div>
          <Tooltip
            position="left"
            omitTabIndex
            triggerContent={
              <button
                aria-label="Export table data"
                className="p-2 text-sm font-bold text-center text-black bg-gray-200 rounded-sm disabled:opacity-20 disabled:cursor-wait"
                onClick={exportTableData}
                disabled={downloadLoading}
              >
                <Icon name="download" />
              </button>
            }
            content="Export table data"
          />
        </div>
        <div className="flex flex-col mt-2">
          <AdminTable
            wrapperClassName="m-0 w-full"
            className="table-fixed"
            colClasses="whitespace-nowrap"
            headings={MEMBER_TABLE.columns}
            data={MEMBER_TABLE.tableData}
            formatColumns={formatColumns}
          />
        </div>
      </div>
    );
  })
);

export default BaselineTeamsOverviewMemberTable;
