import React from 'react';
import { twMerge } from 'tailwind-merge';
import AddLink from '../AddLink/AddLink';
import Header from '../Header/Header';
import Icon from '../Icon/Icon';

function DashboardWidgetTitle({ title, icon = 'chevron-right', link, className }) {
  if (!link) {
    const classes = twMerge('group flex items-center align-middle py-3 mb-0 lg:py-0', className);
    return (
      <Header as="h2" className={classes}>
        {title}
      </Header>
    );
  }

  const classes = twMerge('group flex items-center align-middle py-3 mb-0 lg:py-0 whitespace-nowrap', className);

  return (
    <AddLink to={link} title={`View ${title}`}>
      <Header as="h2" className={classes}>
        <span>{title}</span>
        <Icon name={icon} className="mr-1 w-6 h-6 group-hover:opacity-100 transition-all duration-100 group-hover:translate-x-1 lg:opacity-0 opacity-1000" />
      </Header>
    </AddLink>
  );
}

export default DashboardWidgetTitle;
