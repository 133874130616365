import { twMerge } from 'tailwind-merge';
import React, { useMemo, useEffect, useState } from 'react';
import If from '../If/If';

/**
 * Display progress in bar format.
 * @param {int} progress 0-100 to represent progress of the bar.
 * @param {string} progressClassName Tailwind classes to apply to the progress bar.
 * @param {string} wrapperClassName Tailwind classes to apply to the wrapper.
 * @param {boolean} showProgressLabel Whether or not to show progress bar percent label.
 * @param {boolean} animate Whether or not to animate the progress bar.
 */
export default function ProgressBar({ progress, progressClassName, wrapperClassName, showProgressLabel = false, animate = true }) {
  const [currentProgress, setProgress] = useState(animate ? 0 : progress);

  const showProgressLabelClasses = useMemo(() => {
    if (showProgressLabel) {
      let classes = 'text-xs flex flex-row items-center justify-center';

      if (currentProgress > 0 && currentProgress < 10) {
        classes += ' px-4';
      }

      return classes;
    }

    return '';
  }, [currentProgress, showProgressLabel]);

  const progressClasses = twMerge(
    '-mt-[1px] h-2 bg-cyb-pink-500 rounded-lg transition-all duration-500 ease-in-out',
    !currentProgress ? 'border-0' : '',
    showProgressLabelClasses,
    progressClassName
  );
  const wrapperClasses = twMerge('h-2 bg-gray-200 rounded-lg border border-cyb-pink-500/25', wrapperClassName);

  useEffect(() => {
    if (animate) {
      const timer = setInterval(() => {
        setProgress(progress);
      }, 300);

      return () => {
        clearInterval(timer);
      };
    }
    return () => {};
  }, []);

  return (
    <div className={wrapperClasses}>
      <div className={progressClasses} style={{ width: `${currentProgress}%` }}>
        <If condition={showProgressLabel && currentProgress > 0}>
          <span>{currentProgress}%</span>
        </If>
      </div>
    </div>
  );
}
