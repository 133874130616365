import React from 'react';

export default function Certificate({ classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 122.88" fill="currentColor" {...accessibilityProps}>
      <g>
        <path
          className="st0"
          d="M61.44,0l13.52,10.99l17.2-2.76l6.21,16.28l16.28,6.21l-2.76,17.2l10.99,13.52l-10.99,13.52l2.76,17.2 
          l-16.28,6.21l-6.21,16.28l-17.2-2.76l-13.52,10.99l-13.52-10.99l-17.2,2.76l-6.21-16.28L8.23,92.16l2.76-17.2L0,61.44l10.99-13.52 
          l-2.76-17.2l16.28-6.21l6.21-16.28l17.2,2.76L61.44,0L61.44,0z M61.44,26.71 
          c19.18,0,34.73,15.55,34.73,34.73c0,19.18-15.55,34.73-34.73,34.73S26.71,80.62,26.71,61.44C26.71,42.26,42.26,26.71,61.44,26.71 L61.44,26.71z"
        />
      </g>
    </svg>
  );
}
