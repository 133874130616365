import React, { useState, createContext, useMemo, useContext } from 'react';
import Bugsnag from '@bugsnag/js';
import { useNavigate } from 'react-router-dom';
import { PADDLE_PRICE_ID_CIP_ANNUAL } from '../constants';
import agents from '../agents/agents';
import useQueryParams from '../hooks/useQueryParams';
import CancellationUtil from '../utils/cancellationUtil';
import PaddleToasts from '../pages/Paddle/PaddleToasts';

export const UPDATE_STEPS = {
  LOADING: 'loading',
  ERROR: 'error',
  CONFIRMATION: 'confirmation',
  ALREADY_ANNUAL: 'alreadyAnnual',
  SUCCESS: 'success',
};

const stub = () => {
  throw new Error('You forgot to wrap your component in <PaddleUpdateSubscription>.');
};

const PaddleUpdateSubscriptionContext = createContext({
  updateStep: UPDATE_STEPS.LOADING,
  currentSubscription: null,
  previewOfUpdatedSubscription: null,
  updatedSubscription: null,
  subscriptionChanges: null,
  isLoading: true,
  error: null,
  confirmChanges: stub,
  init: stub,
});

/**
 * Paddle Update Subscription Provider
 * Used to upgrade a user's subscription from monthly to annual, or add discounts to an existing subscription
 * @param {Mobx} userStore - Mobx userStore
 * @param {Mobx} commonStore - Mobx commonStore
 * @param {*} children - React children
 */
function PaddleUpdateSubscriptionProvider({ userStore, commonStore, children }) {
  if (!userStore) throw new Error('Must provide userStore');
  if (!commonStore) throw new Error('Must provide commonStore');
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const { subscriptionId } = queryParams;

  const [updateStep, setUpdateStep] = useState(null);
  const [currentSubscription, setCurrentSubscription] = useState(null);
  const [previewOfUpdatedSubscription, setPreviewOfUpdatedSubscription] = useState(null);
  const [updatedSubscription, setUpdatedSubscription] = useState(null);
  const [subscriptionChanges, setSubscriptionChanges] = useState(); // { price_id: 'pri_12345', quantity: 1, discount_id: 'dsc_12345' }
  const [offer, setOffer] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const isPublic = !userStore?.user;

  /**
   * Discounts are passed in as query params.
   *
   * PRICE_ID SPECIFIC DISCOUNTS
   * ?discountId.{key}={value}
   * ?discountId.pri_12345=dsc_12345
   * ?discountId.pri_12345=dsc_12345&discountId.pri_67890=dsc_67890
   *
   * GENERAL DISCOUNT
   * ?discountId={value}
   *
   * The key is the `price_id` of the item the discount applies to, and the value is the matching `discount_id`.
   * We will try to find a matching discount for the first item in the checkout, then we'll try to use the general discountId as a fallback.
   *
   */
  const getQueryParamDiscountId = (priceId) => {
    return queryParams[`discountId.${priceId}`] || queryParams.discountId; // Fallback to just `discountId` if we don't have a price_id
  };

  const init = async () => {
    /**
     * Check for logged in user
     */
    if (isPublic) {
      navigate('/login');
      return false;
    }

    /**
     * Check for subscriptionId in URL
     */
    if (!subscriptionId) {
      const err = new Error('Failed to init paddle upgrade - No subscriptionId');
      Bugsnag.notify(err);
      setError(err);
      navigate('/?error=updateSubscriptionIdMissing');
      return false;
    }

    /**
     * Find Target Subscription for user from our backend
     * (Makes sure the subscriptionId is valid for the currently logged in user)
     */
    let targetSubscriptionResponse; // Light response with only the subscription id from our backend
    try {
      targetSubscriptionResponse = await CancellationUtil.fetchTargetSubscription(subscriptionId);
      if (!targetSubscriptionResponse) {
        // EMPTY RESPONSE - Not a valid subscription id for this user
        const err = new Error('Failed to init paddle upgrade - No targetSubscription');
        Bugsnag.notify(err);
        setError(err);
        navigate('/?error=updateSubscriptionNotFound');
        return false;
      }
    } catch (err) {
      // ERROR RESPONSE
      Bugsnag.notify(err);
      setError(err);
      navigate('/?error=targetSubscriptionResponse');
      return false;
    }

    /**
     * Fetch Full Current Subscription from paddle with the valid subscription id
     */
    let currentSubscriptionResponse; // Full response from Paddle with line items, cadance, etc.
    try {
      currentSubscriptionResponse = await agents.paddle.getSubscription(subscriptionId);
      if (!currentSubscriptionResponse) {
        // EMPTY RESPONSE
        const err = new Error('Failed to init paddle upgrade - No currentSubscription');
        Bugsnag.notify(err);
        setError(err);
        navigate('/?error=updateSubscriptionNotFoundInPaddle');
        return false;
      }
      // SUCCESS RESPONSE
      setCurrentSubscription(currentSubscriptionResponse);
    } catch (err) {
      // ERROR RESPONSE
      Bugsnag.notify(err);
      setError(err);
      navigate('/?error=currentSubscriptionResponse');
      return false;
    }

    /**
     * Bounce Annual Subscriptions to the ALREADY_ANNUAL step
     */
    const { billing_cycle } = currentSubscriptionResponse;
    if (billing_cycle?.frequency === 1 && billing_cycle?.interval === 'year') {
      setUpdateStep(UPDATE_STEPS.ALREADY_ANNUAL);
      setIsLoading(false);
      return true;
    }

    /**
     * Determine subscription changes
     * (Currently only supports monthly to annual upgrades while adding discounts)
     */
    const discountId = getQueryParamDiscountId(PADDLE_PRICE_ID_CIP_ANNUAL);
    const updatePreviewParams = {
      subscription_id: subscriptionId,
      discount_id: discountId,
      // CAUTION: REMOVING ITEMS HERE REMOVES THEM FROM THE SUBSCRIPTION
      items: [{ price_id: PADDLE_PRICE_ID_CIP_ANNUAL, quantity: 1 }],
    };
    // Save the changes to state for later if the user confirms
    setSubscriptionChanges(updatePreviewParams);

    /**
     * Fetch a preview of the subscription update
     */
    let previewSubscriptionResponse;
    try {
      previewSubscriptionResponse = await agents.paddle.getSubscriptionUpdatePreview(updatePreviewParams);
    } catch (err) {
      // ERROR RESPONSE
      Bugsnag.notify(err);
      setError(err);
      navigate('/?error=previewSubscriptionResponse');
      return false;
    }
    // EMPTY RESPONSE
    if (!previewSubscriptionResponse) {
      const err = new Error('Failed to init paddle upgrade - No previewSubscription returned');
      Bugsnag.notify(err);
      setError(err);
      navigate('/?error=updateSubscriptionPreviewFailed');
      return false;
    }
    // SUCCESS RESPONSE
    setPreviewOfUpdatedSubscription(previewSubscriptionResponse);

    /**
     * Create an offer object from the current and previewed subscriptions
     * showing the user the changes that will be made
     */
    const offerResponse = CancellationUtil.paddle.getOffer(currentSubscriptionResponse, previewSubscriptionResponse);
    setOffer(offerResponse);

    // Set the update step to confirmation
    setUpdateStep(UPDATE_STEPS.CONFIRMATION);
    setIsLoading(false);
    return true;
  };

  /**
   * Accepts the previewed subscription update and updates the subscription in Paddle with the changes
   */
  const confirmChanges = async () => {
    setIsLoading(true);
    setUpdateStep(UPDATE_STEPS.LOADING);
    let updatedSubscriptionResponse;
    try {
      updatedSubscriptionResponse = await agents.paddle.updateSubscription(subscriptionChanges);
    } catch (err) {
      // ERROR RESPONSE
      Bugsnag.notify(err);
      setError(err);
      navigate('/?error=updatedSubscriptionResponse');
      return false;
    }
    // EMPTY RESPONSE
    if (!updatedSubscriptionResponse) {
      const err = new Error('Failed to init paddle upgrade - No updatedSubscription returned');
      Bugsnag.notify(err);
      setError(err);
      navigate('/?error=updateSubscriptionFailed');
      return false;
    }
    // SUCCESS RESPONSE
    setUpdatedSubscription(updatedSubscriptionResponse);
    setUpdateStep(UPDATE_STEPS.SUCCESS);
    PaddleToasts.showUpgradeSuccessToast(commonStore);

    setIsLoading(false);
    return true;
  };

  const values = useMemo(
    () => ({
      updateStep,
      currentSubscription,
      previewOfUpdatedSubscription,
      updatedSubscription,
      offer,
      isLoading,
      error,
      confirmChanges,
      init,
    }),
    [updateStep, currentSubscription, previewOfUpdatedSubscription, updatedSubscription, offer, isLoading, error, confirmChanges]
  );

  return <PaddleUpdateSubscriptionContext.Provider value={values}>{children}</PaddleUpdateSubscriptionContext.Provider>;
}

/**
 * Creates a context hook for the PaddleUpdateSubscriptionContext
 * @returns {Object} - PaddleUpdateSubscriptionContext
 */
const usePaddleUpdateSubscription = () => useContext(PaddleUpdateSubscriptionContext);

export { PaddleUpdateSubscriptionContext, PaddleUpdateSubscriptionProvider, usePaddleUpdateSubscription };
