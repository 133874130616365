import React from 'react';
import { inject, observer } from 'mobx-react';

import { PaddleCheckoutProvider } from '../providers/PaddleCheckoutProvider';
import PaddleCheckout from '../pages/Paddle/PaddleCheckout';
import PrivateRoute from './PrivateRoute';

/**
 * Checkout Router
 * @param {boolean} isTeams Whether or not the checkout is for teams
 * @param {boolean} isPaddle Whether or not the checkout should use Paddle. If false, we will use Chargebee/Braintree instead.
 * @important This is where we will determine which checkout to use based on the users billing provider durring migration.
 */
const CheckoutRoute = inject(
  'authStore',
  'userStore',
  'commonStore',
  'profileStore'
)(
  observer(({ userStore, authStore, commonStore, profileStore, isTeams = false, isPaddle = false }) => {
    if (isPaddle) {
      return (
        <PaddleCheckoutProvider
          environment={process.env.REACT_APP_PADDLE_ENVIRONMENT}
          userStore={userStore}
          authStore={authStore}
          commonStore={commonStore}
          profileStore={profileStore}
        >
          <PaddleCheckout isTeams={isTeams} />
        </PaddleCheckoutProvider>
      );
    }
    return <PrivateRoute />;
  })
);

export default CheckoutRoute;
