import React from 'react';

export default function TrendingConstant({ classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 512 640" x="0px" y="0px" strokeWidth="1.5" stroke="currentColor" {...accessibilityProps}>
      <g>
        <path d="M23.81,280H430L303.71,406.3a24,24,0,1,0,33.94,33.94L504.92,273c.28-.28.55-.56.81-.85l.18-.21c.2-.22.4-.45.59-.68l.15-.19c.2-.25.4-.5.58-.75l.09-.12c.21-.28.41-.56.6-.85l0,0a23.79,23.79,0,0,0,3.89-10.91c0-.06,0-.11,0-.17,0-.33.06-.67.07-1s0-.8,0-1.2,0-.8,0-1.2,0-.68-.07-1c0-.06,0-.11,0-.17a23.79,23.79,0,0,0-3.89-10.9l0,0c-.19-.28-.39-.56-.59-.83a.71.71,0,0,0-.1-.14c-.18-.25-.37-.48-.56-.72l-.17-.22c-.18-.22-.37-.43-.55-.64s-.14-.17-.22-.25c-.22-.25-.45-.49-.69-.73l-.12-.12L337.65,71.76a24,24,0,1,0-33.94,33.94L430,232H23.81a24,24,0,0,0,0,48Z" />
      </g>
    </svg>
  );
}
