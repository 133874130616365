import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { twMerge } from 'tailwind-merge';
import UserOnboardingStep from '../../UserOnboardingStep';
import { ONBOARDING_PACKAGE_OPTIONS, USER_ONBOARDING_STEPS, useUserOnboarding } from '../../../../providers/UserOnboardingProvider';

/** Step shown at the start of the onboarding flow */
function SelectPackageStepComponent({ userStore }) {
  const { setStep, setPackageSelected } = useUserOnboarding();

  const handleClick = async (clickedOption) => {
    setPackageSelected(clickedOption);
    setStep(USER_ONBOARDING_STEPS.FINISHED);
  };

  useEffect(() => {
    // If the user already has a full subscription package, set the package to "skip"
    if (userStore.isCip || userStore.isEnterprise) {
      handleClick('skip');
    }
  }, [userStore.user]);

  return (
    <UserOnboardingStep title="Select Your Package">
      <div className="flex flex-col-reverse justify-center items-center align-middle md:flex-row md:items-stretch md:space-x-4">
        {Object.values(ONBOARDING_PACKAGE_OPTIONS).map(({ key, title, titleClasses, description, featuresTitle, features, icon = 'user', iconClassName, className }) => {
          return (
            <UserOnboardingStep.Button
              key={key}
              title={title}
              titleClassName={titleClasses}
              description={description}
              icon={icon}
              iconClassName={iconClassName}
              onClick={() => handleClick(key)}
              className={twMerge('flex-1 py-6 px-8 max-w-[380px] h-auto md:ml-0 lg:m-0', className)}
              packageHighlights={features}
              featuresTitle={featuresTitle}
            />
          );
        })}
      </div>
    </UserOnboardingStep>
  );
}

const SelectPackageStep = inject('userStore')(observer(SelectPackageStepComponent));

export default SelectPackageStep;
