import React from 'react';

function Paths({ active }) {
  if (active) {
    return (
      <>
        <path d="M14.8 14.8H6.2C5 14.8 4 13.8 4 12.6V6.2C4 5 5 4 6.2 4H12.6C13.8 4 14.8 5 14.8 6.2V14.8Z" fill="black" />
        <path d="M17.1992 14.8V6.2C17.1992 5 18.1992 4 19.3992 4H25.7992C26.9992 4 27.9992 5 27.9992 6.2V12.6C27.9992 13.8 26.9992 14.8 25.7992 14.8H17.1992Z" fill="black" />
        <path
          d="M17.1992 17.2002H25.7992C26.9992 17.2002 27.9992 18.2002 27.9992 19.4002V25.8002C27.9992 27.0002 26.9992 28.0002 25.7992 28.0002H19.3992C18.1992 28.0002 17.1992
            27.0002 17.1992 25.8002V17.2002Z"
          fill="black"
        />
        <path d="M14.8 17.2002V25.8002C14.8 27.0002 13.8 28.0002 12.6 28.0002H6.2C5 28.0002 4 27.0002 4 25.8002V19.4002C4 18.2002 5 17.2002 6.2 17.2002H14.8Z" fill="black" />
      </>
    );
  }
  return (
    <>
      <path d="M14 14H6.2C5.44183 14 4.8 13.3582 4.8 12.6V6.2C4.8 5.44183 5.44183 4.8 6.2 4.8H12.6C13.3582 4.8 14 5.44183 14 6.2V14Z" strokeWidth="1.6" />
      <path
        d="M17.9992 14V6.2C17.9992 5.44183 18.641 4.8 19.3992 4.8H25.7992C26.5574 4.8 27.1992 5.44183 27.1992 6.2V12.6C27.1992 13.3582 26.5574 14 25.7992 14H17.9992Z"
        strokeWidth="1.6"
      />
      <path
        d="M17.9992 18.0002H25.7992C26.5574 18.0002 27.1992 18.642 27.1992 19.4002V25.8002C27.1992 26.5584 26.5574 27.2002 25.7992 27.2002H19.3992C18.641 27.2002 17.9992
          26.5584 17.9992 25.8002V18.0002Z"
        strokeWidth="1.6"
      />
      <path
        d="M14 18.0002V25.8002C14 26.5584 13.3582 27.2002 12.6 27.2002H6.2C5.44183 27.2002 4.8 26.5584 4.8 25.8002V19.4002C4.8 18.642 5.44183 18.0002 6.2 18.0002H14Z"
        strokeWidth="1.6"
      />
    </>
  );
}

export default function BrowseIcon({ active, classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';
  const strokeProps = active ? {} : { stroke: 'currentColor' };
  return (
    <svg className={className} fill="none" {...strokeProps} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...accessibilityProps}>
      <Paths active={active} />
    </svg>
  );
}
