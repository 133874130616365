import React from 'react';

export default function OnlineVideo({ classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';
  return (
    <svg className={className} enableBackground="new 0 0 32 32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...accessibilityProps}>
      <g id="Layer_18">
        <path d="m6.199 24.424h19.602c2.26 0 4.099-1.839 4.099-4.1v-13.583c0-2.261-1.839-4.1-4.099-4.1h-19.602c-2.26 0-4.099 1.839-4.099 4.1v13.583c0 2.261 1.839 4.1 4.099 4.1zm-2.299-17.683c0-1.268 1.032-2.299 2.299-2.299h19.602c1.268 0 2.299 1.031 2.299 2.299v13.583c0 1.268-1.032 2.299-2.299 2.299h-19.602c-1.268 0-2.299-1.031-2.299-2.299z" />
        <path d="m13.367 18.567c.414.214.862.32 1.309.32.576 0 1.148-.176 1.639-.522l3.553-2.512c.753-.532 1.202-1.4 1.202-2.322 0-.921-.45-1.789-1.202-2.32l-3.554-2.511c-.872-.616-2.001-.694-2.948-.202-.947.49-1.536 1.458-1.536 2.524v5.021c.001 1.066.589 2.034 1.537 2.524zm.264-7.545c0-.397.21-.743.563-.926.154-.08.319-.12.482-.12.209 0 .417.065.599.194l3.553 2.511c.28.197.44.508.44.851s-.161.653-.441.852l-3.553 2.511c-.324.229-.727.257-1.081.074-.353-.183-.563-.528-.563-.926v-5.021z" />
        <path d="m30 27.558h-28c-.497 0-.9.403-.9.9s.403.9.9.9h28c.497 0 .9-.403.9-.9s-.403-.9-.9-.9z" />
      </g>
    </svg>
  );
}
