import React from 'react';

export default function UserWithStars({ classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';

  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 417 416" fill="none" stroke="currentColor" {...accessibilityProps}>
      <path
        d="M255.052 244.051L253.328 247.485C253.328 247.485 253.328 247.485 253.328 246.627L252.465 248.343L236.948 
        234.609C235.224 232.893 231.776 232.034 229.19 233.751L213.672 239.76C210.224 241.476 205.914 241.476 201.603 
        239.76L186.086 233.751C183.5 232.893 180.914 232.893 178.328 234.609L162.81 248.343L161.948 244.051V244.91C161.086 
        241.476 157.638 225.167 157.638 220.876C157.638 201.991 177.465 191.691 198.155 191.691C217.983 191.691 227.465 
        195.124 230.914 201.133C248.155 201.991 255.052 214.867 256.776 226.884C258.5 232.893 257.638 238.043 255.052 244.051Z"
        stroke="black"
        strokeWidth="15.36"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M296.431 371.948C274.017 394.266 242.983 408 208.5 408C174.017 408 142.983 394.266 119.707 371.948C122.293 339.33 
        135.224 323.88 159.362 317.013C166.259 315.296 174.017 313.579 182.638 313.579H189.534C189.534 315.296 188.672 317.013 
        188.672 317.013L192.121 330.747C196.431 345.339 217.121 345.339 221.431 330.747L224.879 317.013C224.879 317.013 224.879 
        315.296 224.017 313.579H230.914C239.534 314.438 247.293 315.296 254.19 317.013C281.776 323.88 294.707 339.33 296.431 371.948Z"
        stroke="black"
        strokeWidth="15.36"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M163.672 248.343L179.19 234.609C180.914 232.893 184.362 232.893 186.948 233.751L202.466 239.76C205.914 241.476 210.224 
        241.476 214.534 239.76L230.052 233.751C232.638 232.893 235.224 232.893 237.81 234.609L253.328 248.343"
        stroke="black"
        strokeWidth="15.36"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M253.328 247.485C252.466 268.086 240.397 289.545 223.155 298.129C217.983 300.704 212.81 301.562 207.638 301.562C202.466 
        301.562 197.293 300.704 192.983 298.129C175.741 289.545 162.81 267.227 162.81 245.768"
        stroke="black"
        strokeWidth="15.36"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M162.81 244.052L163.672 248.343L162.81 245.768C162.81 244.91 162.81 244.91 162.81 244.052Z"
        stroke="black"
        strokeWidth="15.36"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M253.328 246.627L252.466 248.343" stroke="black" strokeWidth="15.36" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M223.155 297.27C223.155 297.27 226.603 315.296 226.603 316.154L223.155 329.888C218.845 344.481 198.155 344.481 193.845 
        329.888L190.396 316.154C190.396 315.296 193.845 297.27 193.845 297.27"
        stroke="black"
        strokeWidth="15.36"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M208.5 407.142C278.012 407.142 334.362 351.033 334.362 281.82C334.362 212.606 278.012 156.498 208.5 156.498C138.988 156.498 
        82.6379 212.606 82.6379 281.82C82.6379 351.033 138.988 407.142 208.5 407.142Z"
        stroke="black"
        strokeWidth="15.36"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M208.5 8L227.466 46.6266L269.707 52.6352L239.534 82.6781L246.431 124.738L208.5 104.996L170.569 124.738L177.466 
        82.6781L147.293 52.6352L189.534 46.6266L208.5 8Z"
        stroke="black"
        strokeWidth="15.36"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M94.7069 51.7768L107.638 79.2446L138.672 83.5365L116.259 104.996L121.431 135.039L94.7069 120.446L67.1207 135.039L72.2931 
        104.996L49.8793 83.5365L80.9138 79.2446L94.7069 51.7768Z"
        stroke="black"
        strokeWidth="15.36"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M322.293 51.7768L336.086 79.2446L367.121 83.5365L344.707 104.996L349.879 135.039L322.293 120.446L295.569 135.039L300.741 
        104.996L278.328 83.5365L309.362 79.2446L322.293 51.7768Z"
        stroke="black"
        strokeWidth="15.36"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M375.741 142.764L386.086 163.365L408.5 166.798L392.121 182.249L396.431 205.425L375.741 194.266L355.052 205.425L359.362 
        182.249L342.121 166.798L365.396 163.365L375.741 142.764Z"
        stroke="black"
        strokeWidth="15.36"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.2586 142.764L51.6035 163.365L74.8793 166.798L57.6379 182.249L61.9483 205.425L41.2586 194.266L20.569 205.425L24.8793 
        182.249L8.5 166.798L30.9138 163.365L41.2586 142.764Z"
        stroke="black"
        strokeWidth="15.36"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
