import React, { useMemo } from 'react';
import { fetchUrl } from '../../utils/shareUtil';
import ShareLink from './ShareLink';

/**
 * Share Profile Link is a component that wraps the ShareLink component and builds a share link for a profile given a username and tab
 * @param {string} username Profile username used to build share link
 * @param {string} tab Profile tab used to build share link to open a specific tab
 * @param {string} successText Text to display in the popup message when the link is copied
 * @returns
 */
function ShareProfileLink({ username, tab, successText = 'Copied Profile Link!' }) {
  const shareUrl = useMemo(() => `${fetchUrl('profile', null, username)}${tab ? `?tab=${tab}` : ''}`, [username, tab]);
  return <ShareLink shareUrl={shareUrl} successText={successText} />;
}

export default ShareProfileLink;
