import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useQueryParams from '../useQueryParams';
import useCookieMode from './useCookieMode';

/**
 * Sandbox Cookie Hook
 *
 * @description Utilizes `cyb_sandbox_environments` cookie mode via `useCookieMode` hook that represents sandbox environments that the user has enabled
 *
 * @returns {CookieModeInterface} `cookieModeInterface`
 */
const useSandboxCookie = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = useQueryParams();
  const { enabledContexts: enabledSandboxes, enableContext: enableSandbox, disableAllContexts: disableAllSandboxes } = useCookieMode({ mode: 'cyb_sandbox_environments' });

  /**
   * Sanbox environments are enabled by passing a `sandbox` query param to the url and disabled by clicking the "Exit Sandbox" button
   */
  useEffect(() => {
    const sandboxId = queryParams?.sandbox;

    // If the `sandbox` query param is set, enable the sandboxId
    if (sandboxId) {
      enableSandbox(sandboxId);
      // remove the `sandbox` query string param in the URL using react router so we don't fire this twice on a reload. Be sure to preserve other query params.
      navigate(location.pathname, { replace: true });
    }
  }, [queryParams]);

  /**
   * `SandboxCookieInterface` data returned from the hook
   */
  return useMemo(
    () => ({
      enabledSandboxes,
      enableSandbox,
      disableAllSandboxes,
      isBaselineSandboxEnabled: enabledSandboxes.includes('baseline'),
      isMeasureSandboxEnabled: enabledSandboxes.includes('measure'),
      isContentIdSandboxEnabled: enabledSandboxes.includes('content'),
      isDesignComponentSandboxEnabled: enabledSandboxes.includes('design'),
    }),
    [enabledSandboxes, enableSandbox, disableAllSandboxes]
  );
};

export default useSandboxCookie;
