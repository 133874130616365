import React from 'react';
import Collapsible from 'react-collapsible';
import { observer } from 'mobx-react';
import Icon from '../Icon/Icon';
import withRouter from '../Router/withRouter';
import Loading from '../Loading/Loading';
import Segment from '../Segment/Segment';
import StyledError from '../Error/StyledError';
import FormatUtil from '../../utils/formatUtil';
import Container from '../Container/Container';
import Card from '../Card/Card';
import Label from '../Label/Label';

function RoleTag({ role }) {
  let color = null;
  switch (role) {
    case 'org-owner':
      color = 'orange';
      break;
    case 'team-admin':
      color = 'purple';
      break;
    case 'team-reporting-admin':
      color = 'gray';
      break;
    case 'team-member':
    default:
      color = 'blue';
      break;
  }

  return (
    <div className="mx-auto w-4/5 text-center">
      <Label color={color} className="block w-full" basic>
        {FormatUtil.convertRoleName(role)}
      </Label>
    </div>
  );
}

function GroupCard({ group, navigate, orgId, canClick }) {
  const handleClick = () => {
    if (canClick) {
      navigate(`/enterprise/${orgId}/organization/dashboard?group=${group.id}`);
    }
  };
  return (
    <Card key={group.id} className={`${canClick ? '' : 'disabled'} box-shadow flex flex-col justify-center items-center py-3 my-0`}>
      <button className="w-full" onClick={handleClick}>
        <h2 className="mb-2 text-base font-semibold">{group.name}</h2>
        <span className="sr-only">Click to visit {group.name} admin page.=</span>
        {group.role ? <RoleTag role={group.role} /> : <Label>{`${group.userCount} Members`}</Label>}
      </button>
    </Card>
  );
}

const OrganizationGroupCards = observer(
  class OrganizationGroupCards extends React.Component {
    state = {
      expanded: false,
    };

    getTriggerText = () => {
      return this.state.expanded ? 'Show Less' : 'View More';
    };

    render() {
      const { loading, error, groups } = this.props;
      if (loading) {
        return (
          <Container>
            <Loading message="Loading..." />
          </Container>
        );
      }
      if (error) {
        return (
          <Container>
            <StyledError error={error} />
          </Container>
        );
      }
      if (groups && !groups.length) {
        return null;
      }

      const visibleGroups = groups ? groups.slice(0, 12) : null;
      const hiddenGroups = groups ? groups.slice(12) : null;
      return (
        <Segment className="mb-0 border-none group-cards-container">
          <div className="grid grid-cols-2 gap-4 h-full md:grid-cols-6">
            {visibleGroups
              ? visibleGroups.map((group) => {
                  const canClick = this.props.groupPermissions[group.id] && this.props.groupPermissions[group.id].reports;
                  return <GroupCard key={group.id} canClick={canClick} orgId={this.props.orgId} group={group} urlKey={this.props.urlKey} navigate={this.props.navigate} />;
                })
              : null}
          </div>
          {hiddenGroups && hiddenGroups.length ? (
            <Collapsible
              trigger={
                <div>
                  {this.getTriggerText()} <Icon name="chevron-down" className="mx-auto w-6 h-6" />
                </div>
              }
              className="groups-collapsible"
              openedClassName="groups-collapsible"
              transitionTime={200}
              open={this.state.expanded}
              triggerTagName="div"
              handleTriggerClick={() => {
                const currState = {
                  ...this.state,
                  expanded: !this.state.expanded,
                };
                this.setState(currState);
              }}
            >
              <div className="grid grid-cols-2 gap-4 h-full md:grid-cols-6">
                {hiddenGroups
                  ? hiddenGroups.map((group) => {
                      const canClick = this.props.groupPermissions[group.id] && this.props.groupPermissions[group.id].reports;
                      return <GroupCard key={group.id} canClick={canClick} group={group} orgId={this.props.orgId} navigate={this.props.navigate} urlKey={this.props.urlKey} />;
                    })
                  : null}
              </div>
            </Collapsible>
          ) : null}
        </Segment>
      );
    }
  }
);
export default withRouter(OrganizationGroupCards);
