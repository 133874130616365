import React, { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import LoadingText from '../Loading/LoadingText';

function CareerProgramWidgetLoadingText({ className }) {
  const classes = useMemo(() => twMerge('flex flex-col space-y-4', className), [className]);
  return (
    <div className={classes}>
      <div className="flex space-x-2">
        <LoadingText wrapperClassName="w-3/4" className="h-6" />
        <LoadingText wrapperClassName="w-1/4" className="h-6" />
      </div>
      <div className="flex space-x-2">
        <LoadingText wrapperClassName="w-3/4" className="h-6" />
        <LoadingText wrapperClassName="w-1/4" className="h-6" />
      </div>
      <div className="flex space-x-2">
        <LoadingText wrapperClassName="w-3/4" className="h-6" />
        <LoadingText wrapperClassName="w-1/4" className="h-6" />
      </div>
    </div>
  );
}

export default CareerProgramWidgetLoadingText;
