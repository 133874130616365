/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable tailwindcss/classnames-order */
import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import remarkUnwrapImages from 'remark-unwrap-images';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import Section from './Section';
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary';

function MarkdownText({ item }) {
  return (
    <ReactMarkdown
      className="text-sm text-black has-markdown-inside"
      linkTarget="_blank"
      remarkPlugins={[remarkGfm, remarkUnwrapImages]}
      rehypePlugins={[rehypeRaw]}
      components={{
        pre({ ...props }) {
          return <pre className="p-2 text-gray-100 bg-blue-900 rounded-lg">{props.children}</pre>;
        },
        code({ ...props }) {
          return <code style={{ whiteSpace: 'break-spaces' }}>{props.children}</code>;
        },
        a({ ...props }) {
          return (
            <a
              href={props.href}
              aria-label={`Visit ${props.children} in a new tab.`}
              rel="noopener noreferrer"
              target={props.target || '_blank'}
              className="text-cyb-pink-500 hover:text-black underline cursor-pointer"
            >
              {props.children}
            </a>
          );
        },
        img({ ...props }) {
          const imageClasses = 'mx-auto max-w-full';
          const { src, alt, title } = props;
          return (
            <figure className="flex flex-col items-center justify-center bg-gray-200 rounded-sm p-4 md:p-8">
              <ErrorBoundary FallbackComponent={<img className={imageClasses} src={src} alt={alt} />}>
                <Zoom openText="Click to enlarge image" closeText="Click to reset image" zoomMargin={100}>
                  <img className={imageClasses} src={src} alt={alt} />
                </Zoom>
              </ErrorBoundary>
              {title && <figcaption className="mt-1 -mb-4 text-sm text-center text-gray-700">{props.title}</figcaption>}
            </figure>
          );
        },
      }}
    >
      {item}
    </ReactMarkdown>
  );
}

function OrderedOrUnorderedList({ ordered, children }) {
  if (ordered) {
    return <ol className="pl-5 list-decimal">{children}</ol>;
  }
  return <ul className="pl-5 list-disc">{children}</ul>;
}

function ContentWrapper({ content }) {
  if (!content || !content.length) {
    return null;
  }

  if (Array.isArray(content) && content.length) {
    return content.map((contentItem) => {
      const { items, type, ordered } = contentItem;
      if (type === 'list') {
        return (
          <OrderedOrUnorderedList ordered={ordered} key={contentItem.id}>
            {items.map((item, i) => {
              const key = `${item}_${i}`;
              return (
                <li className="text-sm text-black" key={key}>
                  <MarkdownText item={item} />
                </li>
              );
            })}
          </OrderedOrUnorderedList>
        );
      }
      if (type === 'paragraph') {
        return (
          <div key={contentItem.id}>
            {items.map((item, i) => {
              const marginClass = i === items.length - 1 ? 'mb-0' : 'mb-4';
              const key = `${item}_${i}`;
              return (
                <div className={`text-sm text-black ${marginClass}`} key={key}>
                  <MarkdownText item={item} />
                </div>
              );
            })}
          </div>
        );
      }
      return <div key={contentItem.id} />;
    });
  }

  return <MarkdownText item={content} />;
}

function TextSection({ title, highlight, content }) {
  return (
    <Section highlight={highlight} title={title} noY>
      {!!title && <h4 className="mb-2 font-bold leading-4">{title}</h4>}
      <ContentWrapper content={content} />
    </Section>
  );
}

export default TextSection;
