import React from 'react';
import UpgradeButton from '../../../components/UpgradeButton/UpgradeButton';
import Icon from '../../../components/Icon/Icon';

const features = [
  'Over 2,000 courses covering role-based skills, tools, concepts, and more',
  'Practice exams to help you ace your certification exams',
  'Role and skill-based assessments to test your readiness',
  'Virtual lab simulations based on real-world attacks to help you develop and practice the hands-on skills that employers are hiring for',
  'A private community with mentors on standby to help you succeed',
];

export default function ThreadedUpgradeInterstitial({ handleClose = () => {} }) {
  return (
    <div className="px-4 mx-auto mt-4 max-w-1318px h-full text-center">
      <div className="my-6 text-[48px] font-black text-black">Congratulations</div>
      <div className="my-4 text-[20px] font-black text-black">You have completed this module.</div>
      <div className="my-4 text-[20px] text-black">You’re doing a great job progressing through the course!</div>
      <div className="my-6 text-[20px] text-black">
        While our video and written content is immensively valuable, we’re also big advocates of practicing and getting hands-on with what you’re learning. We really want to give
        you an opportunity to try out this content, so we’d like to offer you a special discount.
      </div>
      <div className="my-6 text-[24px] font-black text-black">Click the button below to get your first month of Cybrary Insider Pro for $1.</div>
      <div className="flex justify-center items-center my-8">
        <UpgradeButton color="pink" upgradeText="Upgrade" upgradeLinkParams="?monthly=CYB99MOFFIN-APP" />
        <div className="my-4 mx-6 text-[12px] text-black">Or</div>
        <button className="py-2.5 px-6 text-sm font-bold leading-5 text-center text-black bg-gray-200 hover:bg-gray-300 rounded-sm border-black border-sm" onClick={handleClose}>
          Continue
        </button>
      </div>
      <div className="flex justify-center m-auto mx-auto max-w-240">
        <div className="ml-12 w-full md:w-1/2">
          <div className="mb-2 font-bold text-left">With Cybrary Insider Pro you’ll unlock access to...</div>
          {features.map((li) => (
            <div className="flex mb-1 text-left" key={li}>
              <Icon name="check" className="shrink-0 mr-2 w-6 h-6 text-green-600" />
              <div>{li}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
