import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Bugsnag from '@bugsnag/js';
import { inject, observer } from 'mobx-react';
import Agents from '../../agents/agents';
import Loading from '../../components/Loading/Loading';
import TeamHeader from '../../components/AdminTeamActivityLogs/TeamHeader';
import CybraryAdminActivityLog from '../../components/AdminTeamActivityLogs/CybraryAdminActivityLog';
import Container from '../../components/Container/Container';
import Divider from '../../components/Divider/Divider';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import CybraryAdminLogo from './CybraryAdminLogo';

function CybraryAdminTeamActivityLogComponent({ commonStore }) {
  const params = useParams();
  const [teamData, setTeamData] = useState({});
  const [teamLoading, setTeamLoading] = useState({});

  const fetchTeamData = async () => {
    try {
      const response = await Agents.admin.getTeamData(params.id);
      setTeamData(response);
      setTeamLoading(false);
    } catch (e) {
      Bugsnag.notify(e);
    }
  };

  useEffect(() => {
    // Fetch team data
    fetchTeamData();
    // Hide primary nav
    commonStore.hidePrimaryNav();
  }, []);

  const breadcrumbs = [{ label: 'Browse Teams', href: '/admin/teams' }, { label: 'View Team', href: `/admin/view-team/${params.id}` }, { label: 'Activity Log' }];

  if (teamLoading) {
    return (
      <div>
        <CybraryAdminLogo />
        <Loading message="Loading..." />
      </div>
    );
  }

  return (
    <Container size="xl">
      <CybraryAdminLogo />
      <Breadcrumbs crumbs={breadcrumbs} />
      <TeamHeader data={teamData} />
      <Divider />
      <CybraryAdminActivityLog team={teamData} />
    </Container>
  );
}

const CybraryAdminTeamActivityLog = inject('commonStore')(observer(CybraryAdminTeamActivityLogComponent));

export default CybraryAdminTeamActivityLog;
