import React from 'react';
import { OAUTH_PROVIDERS } from '../../constants';
/**
 * Loading component that displays a nice loader when mounted.
 */
function Social({ hasColumns, handleClick }) {
  const containerClass = hasColumns ? 'sm:grid-cols-2' : '';
  return (
    <div className={`w-full mx-auto grid gap-2 ${containerClass}`}>
      {OAUTH_PROVIDERS.map((option) => {
        const { id, name, logo } = option;
        // DO NOT Show SAML button, but we need it in the config for SAML support.
        if (id === 'saml') {
          return null;
        }
        return (
          <button key={id} onClick={() => handleClick(option)} className="flex items-center py-3 px-4 bg-white hover:bg-gray-200 rounded-sm border-xs border-gray-400 border-solid">
            <div className="flex justify-center items-center">
              <span className={logo} />
            </div>
            <div className="flex-1">
              <div className="mx-auto w-40 text-sm text-left text-black">Sign in with {name}</div>
            </div>
          </button>
        );
      })}
    </div>
  );
}

export default Social;
