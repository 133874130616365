export default class AccessibilityUtil {
  static showAriaSortProp = (sortKey, sortCol, sortProperty, sortDirection) => {
    if (sortProperty) {
      const ariaSort = sortDirection === 'asc' ? 'ascending' : 'descending';
      return sortKey === sortCol ? ariaSort : 'none';
    }
    return null;
  };

  // Show the aria-described by field for dynamic form fields if error is shown
  static ariaDescribedById = (id, error, helpText) => {
    let describeId = '';
    if ((!error && !helpText) || !id) {
      return null;
    }
    if (error) {
      describeId += `${id}_error`;
    }

    if (helpText) {
      describeId += `${describeId && describeId.length ? ' ' : ''}${id}_text`;
    }
    return describeId;
  };
}
