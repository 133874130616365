import React from 'react';
import { twMerge } from 'tailwind-merge';
import { Userpilot } from 'userpilot';
import AddLink from '../AddLink/AddLink';
import Tooltip from '../Tooltip/Tooltip';
import Icon from '../Icon/Icon';

/**
 * A component that triggers a Userpilot guide when clicked.
 * Wraps a button that triggers the guide when clicked.
 * This button contains a tooltip that displays the text 'Learn more' by default on hover.
 * @param {string} guideId - The ID of the guide to trigger.
 * @param {function} onClick - Optional click handler.
 * @param {string} className - Optional class name for the wrapper.
 * @param {string} iconClassName - Optional class name for the icon.
 * @param {string} text - Optional text for the tooltip.
 * @returns {JSX} <UPTrigger />
 */
function UPTrigger({ guideId, onClick, className, iconClassName, text = 'Click to learn more' }) {
  const iconClasses = twMerge(
    'inline-block ml-1 w-5 h-5 text-gray-600 group-hover:scale-105 group-hover:bg-cyb-blue-500 rounded-full group-hover:text-white transition-all duration-300',
    iconClassName
  );
  const wrapperClasses = twMerge('inline-block group', className);
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    Userpilot.track(guideId);
  };

  return (
    <AddLink onClick={handleClick} aria-label={text} className={wrapperClasses}>
      <Tooltip content={text} triggerContent={<Icon name="question-circle" className={iconClasses} />} />
    </AddLink>
  );
}

export default UPTrigger;
