import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import withRouter from '../Router/withRouter';
import SelectionChip from '../SelectTiles/SelectionChip';
import DateRangeFilter from '../Enterprise/DateRangeFilter';
import DateRangeOptions from '../Enterprise/DateRangeOptions';
import GroupSelect from '../Enterprise/GroupSelect';
import Dropdown from '../Dropdown/Dropdown';

function MemberFilter({ showUserFilter, userList, value, onChange }) {
  if (!showUserFilter) {
    return null;
  }
  return (
    <div className="w-full">
      <Dropdown
        placeholder="Select a Member..."
        loading={userList.loading}
        ariaLabel="Select a member for filter"
        options={userList.data || null}
        value={value}
        onChange={onChange}
        isSearchable
        name="member-select"
      />
    </div>
  );
}

function GroupFilter({ showGroupFilter, team, handleGroupSelect, selectedGroup, handleKeyPress, groupFilter }) {
  if (!showGroupFilter) {
    return null;
  }

  return (
    <GroupSelect className="w-full" team={team} handleGroupSelect={handleGroupSelect} selectedGroup={selectedGroup} handleKeyPress={handleKeyPress} groupFilter={groupFilter} />
  );
}

const ReportOptions = inject(
  'reportsStore',
  'enterpriseStore',
  'userStore'
)(
  observer(
    class ReportOptions extends Component {
      state = {
        orgId: null,
        dateRange: 'allTime',
      };

      componentDidMount() {
        const newState = {
          ...this.state,
          orgId: this.props.orgId,
          teamGroupId: this.props.teamGroupId,
        };
        this.setState(newState);
      }

      componentDidUpdate() {
        // If the orgId has changed, need to reset reporting filters (should only occur when using group switcher)
        if (this.state.teamGroupId !== this.props.teamGroupId) {
          const newState = {
            ...this.state,
            teamGroupId: this.props.teamGroupId,
          };
          // eslint-disable-next-line react/no-did-update-set-state
          this.setState(newState, () => {
            this.resetOptions();
          });
        }
      }

      componentWillUnmount() {
        this.props.reportsStore.setDefaultUserListData();
      }

      // /**
      //  * Sets var in store for use in endpoint composition
      //  * @param value
      //  */
      filterChange = (groupId, groupName) => {
        if (groupId !== this.props.reportsStore.reportItem.queryParams.filterAudId) {
          this.props.reportsStore.setQueryParams('filterAud', 'group');
          this.props.reportsStore.setQueryParams('groupName', groupName);
          this.props.reportsStore.setQueryParams('filterAudId', groupId);
          this.props.reportsStore.setActionFlag('canFilter', true);
        }
      };

      // /**
      //  * Resets options when clicking on reset button
      //  */
      resetOptions = () => {
        const newState = {
          ...this.state,
          dateRange: 'allTime',
        };
        this.setState(newState);
        this.props.reportsStore.setUserFilter(null, '');
        if (this.props.reportsStore.reportItem.canClear || Object.keys(this.props.filterChips).length) {
          this.props.reportsStore.setDefaultQueryParams(this.props.canViewReports);
          this.props.reportsStore.setDates('startDate', this.props.userStore.team.created_at);
          this.props.reportsStore.setQueryParams('startDate', this.props.userStore.team.created_at);
          Object.keys(this.props.filterChips).forEach((item) => {
            if (item === 'userName' || item === 'userId') {
              this.props.deleteQueryParamsUpdateURL(['userName', 'userId']);
            }
            if (item === 'contentName') {
              this.props.deleteQueryParamsUpdateURL(['contentName', 'contentDescriptionId']);
            }
            if (item === 'assignmentName') {
              this.props.deleteQueryParamsUpdateURL(['assignmentName', 'assignment']);
            }
            if (item === 'goalName') {
              this.props.deleteQueryParamsUpdateURL(['goalName', 'goalId']);
            }
          });
          this.props.getReportData();
          this.props.reportsStore.clearFilterChips(this.props.canViewReports);
        }
        this.props.reportsStore.setActionFlag('canFilter', false);
        this.props.reportsStore.setActionFlag('canClear', false);
      };

      // /**
      //  * Sets var in store for change selection on start date picker
      //  * @param start date
      //  */
      startOnChange = (startDate) => {
        this.props.reportsStore.setDates('startDate', startDate);
        this.props.reportsStore.setActionFlag('canFilter', true);
      };

      // /**
      //  * Sets var in store for change selection on end date picker
      //  * @param start date
      //  */
      endOnChange = (endDate) => {
        this.props.reportsStore.setDates('endDate', endDate);
        this.props.reportsStore.setActionFlag('canFilter', true);
      };

      userFilterSelection = ({ value, label }) => {
        this.props.reportsStore.setUserFilter(value, label);
        this.props.reportsStore.setActionFlag('canFilter', true);
      };

      applyDateFilter = (startDate, endDate, value) => {
        this.props.reportsStore.setQueryParams(
          'dateRange',
          value ? DateRangeOptions[value].text : `${moment(startDate).format('M/D/YYYY')} - ${moment(endDate).format('M/D/YYYY')}`
        );

        if (startDate) {
          this.props.reportsStore.setDates('startDate', startDate);
          this.props.reportsStore.setActionFlag('canFilter', true);
        }
        if (endDate) {
          this.props.reportsStore.setDates('endDate', endDate);
          this.props.reportsStore.setActionFlag('canFilter', true);
        }
        const newState = {
          ...this.state,
          dateRange: value || null,
        };
        this.setState(newState);
      };

      removeChip = (item) => {
        // Can't remove the group filter if you can only view group reports
        if (item !== 'groupName' || this.props.canViewReports === 'all') {
          const currState = { ...this.state };
          // If we are clearing the userID chip and have search userId set, clear that and the search (searched and selected a specific user)
          if (item === 'userId' && this.props.reportsStore.reportItem.userFilter.userId) {
            this.props.reportsStore.setUserFilter(null, '');
          }
          if (item === 'groupName') {
            this.props.reportsStore.setQueryParams('filterAud', 'all');
            this.props.reportsStore.setQueryParams('filterAudId', null);
            this.props.deleteQueryParamsUpdateURL(['groupId']);
          }
          if (item === 'dateRange') {
            currState.dateRange = 'allTime';
            this.props.reportsStore.setQueryParams('startDate', new Date(this.props.userStore.team.created_at));
            this.props.reportsStore.setQueryParams('endDate', new Date());
            this.props.reportsStore.setDates('startDate', this.props.userStore.team.created_at);
            this.props.reportsStore.setDates('endDate', new Date());
          }

          if (item === 'userName' || item === 'userId') {
            this.props.deleteQueryParamsUpdateURL(['userName', 'userId']);
          }
          if (item === 'contentName') {
            this.props.deleteQueryParamsUpdateURL(['contentName', 'contentDescriptionId']);
          }
          if (item === 'assignmentName') {
            this.props.deleteQueryParamsUpdateURL(['assignmentName', 'assignment']);
          }
          if (item === 'goalName') {
            this.props.deleteQueryParamsUpdateURL(['goalName', 'goalId']);
          }

          this.setState(currState, () => {
            this.props.reportsStore.removeChip(item);
            this.props.applyFilters(true);
          });
        }
      };

      getChips = (filterChips) => {
        if (!filterChips) {
          return null;
        }
        return (
          <div>
            <span className="sr-only">Applied Filters</span>
            <div className="flex flex-wrap gap-2">
              {Object.keys(filterChips).map((item) => {
                return <SelectionChip key={item.id} handleClose={() => this.removeChip(item)} text={filterChips[item]} />;
              })}
            </div>
          </div>
        );
      };

      /**
       * Filter passed to hierarchy creation to filter out any groups that the user doesn't have permission to view
       */
      groupFilter = (group) => !!group.permissions.reports;

      handleFilterEnter = (e) => {
        if (e.key === 'Enter') {
          this.props.applyFilters();
        }
      };

      render() {
        const omitMemberGroupCol = !this.props.showGroupFilter && !this.props.showUserFilter;
        return (
          <div className="my-4">
            <div className={`grid grid-cols-1 ${this.props.showDateFilter ? 'lg:grid-cols-2' : ''} gap-x-8 w-full`}>
              <div className={`${omitMemberGroupCol ? 'hidden' : 'flex'} flex-col ${this.props.showDateFilter ? '' : 'lg:flex-row w-full gap-x-8'} gap-y-4`}>
                <GroupFilter
                  showGroupFilter={this.props.showGroupFilter}
                  team={this.props.team}
                  handleGroupSelect={this.filterChange}
                  selectedGroup={this.props.reportsStore.reportItem.queryParams.filterAudId}
                  handleKeyPress={this.handleFilterEnter}
                  groupFilter={this.groupFilter}
                />
                <MemberFilter
                  showUserFilter={this.props.showUserFilter}
                  userList={{ ...this.props.reportsStore.userListData }}
                  value={this.props.reportsStore.reportItem.userFilter.userId}
                  onChange={this.userFilterSelection}
                />
              </div>
              {this.props.showDateFilter && (
                <div className="mt-4 lg:mt-0">
                  <DateRangeFilter
                    dateChange={this.applyDateFilter}
                    dateRange={this.state.dateRange}
                    allTimeStart={this.props.userStore.team.created_at}
                    startDate={new Date(this.props.reportsStore.reportItem.startDate)}
                    endDate={new Date(this.props.reportsStore.reportItem.endDate)}
                    rangeSelectionClasses="w-full"
                    omitDateRange={!this.props.showDateRangeFilter}
                    dateSelectionWrapperClasses="flex items-center mt-4"
                  />
                </div>
              )}
            </div>
            <div className="flex justify-between mt-6 w-full">
              <div className="">{this.getChips(this.props.filterChips)}</div>
              <div className="self-end">
                <button className="mr-4 text-cyb-pink-500 underline" onClick={() => this.resetOptions()}>
                  Clear Filters
                </button>
                <button onClick={() => this.props.applyFilters()} className="py-2.5 px-6 text-sm font-bold leading-5 text-center text-black bg-gray-200 rounded-sm">
                  Apply Filters
                </button>
              </div>
            </div>
          </div>
        );
      }
    }
  )
);

export default withRouter(ReportOptions);
