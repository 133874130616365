import React, { useMemo, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import WidgetContainer from '../../Container/WidgetContainer';
import Header from '../../Header/Header';
import Card from '../../Card/Card';
import Button from '../../Button/Button';
import CircularProgress from '../../ProgressBar/CircularProgress';
import { useCybMeasure } from '../../../providers/CybMeasureProvider';
import Icon from '../../Icon/Icon';
import If from '../../If/If';
import ContentOverlay from '../../ContentOverlay/ContentOverlay';
import GoalDetails from '../../Goals/GoalDetails';
import LoadingText from '../../Loading/LoadingText';
import GoalsUtil from '../../../utils/goalsUtil';

const GOAL_LABEL_MAPPING = {
  baselining_pre_assessment: 'Complete the Pre-Assessments',
  baselining_remediation: 'Complete my Recommended Learning',
  baselining_post_assessment: 'Complete the Post-Assessment',
};

function LearnerBaselineGoalsWidget({ orgId, selectedRole, goalsStore }) {
  const navigate = useNavigate();

  const { learnerBaselineGoalDetails, isLoadingBaselineGoalDetails } = useCybMeasure();

  const [selectedGoal, setSelectedGoal] = useState(null);

  const baselineGoalDetails = useMemo(() => learnerBaselineGoalDetails[`${orgId}_${selectedRole?.['content-id']}`], [orgId, selectedRole, learnerBaselineGoalDetails]);

  const isAllCompleted = useMemo(() => baselineGoalDetails?.goals?.every((goal) => goal.progress?.percent_completed === 100), [baselineGoalDetails?.goals]);

  const isLockedSubGoal = useCallback(
    (outcomeType) => {
      if (outcomeType === 'baselining_remediation') {
        const preAssessmentsGoal = baselineGoalDetails?.goals?.find((goal) => goal.outcome_type === 'baselining_pre_assessment');

        return !preAssessmentsGoal?.completed_at;
      }

      if (outcomeType === 'baselining_post_assessment') {
        const recommendedLearningGoal = baselineGoalDetails?.goals?.find((goal) => goal.outcome_type === 'baselining_remediation');

        return !recommendedLearningGoal?.completed_at;
      }

      return false;
    },
    [baselineGoalDetails]
  );

  const continueLearning = useCallback((currentGoal) => GoalsUtil.continueLearning(currentGoal, goalsStore, navigate), [goalsStore, navigate]);

  if (isAllCompleted) {
    return null;
  }

  return (
    <>
      <WidgetContainer className="mb-8">
        <Header as="h3" className="mb-3">
          My Baselining Goals
        </Header>
        <p>
          You’ve been assigned the following goals for the <b>{selectedRole?.['content-name']}</b> role:
        </p>
        <div className="mt-5 mb-3 h-52">
          <If condition={isLoadingBaselineGoalDetails}>
            <Card className="p-0 h-16 border-0" inlineContent>
              <LoadingText wrapperClassName="w-full h-full p-0" className="h-full rounded-none" />
            </Card>
            <Card className="p-0 h-16 border-0" inlineContent>
              <LoadingText wrapperClassName="w-full h-full p-0" className="h-full rounded-none" />
            </Card>
            <Card className="p-0 h-16 border-0" inlineContent>
              <LoadingText wrapperClassName="w-full h-full p-0" className="h-full rounded-none" />
            </Card>
          </If>
          <If condition={!isLoadingBaselineGoalDetails && Boolean(baselineGoalDetails)}>
            {baselineGoalDetails?.goals?.map((goal) => {
              const isLocked = isLockedSubGoal(goal.outcome_type);
              const isCompleted = goal.progress?.percent_completed === 100;

              return (
                <Card className="py-0 px-3 h-16" inlineContent key={goal.id}>
                  <Card.Section className="mr-2 w-8 ">
                    <If condition={isLocked}>
                      <Icon name="lock" className="w-8 h-8 text-gray-400" />
                    </If>
                    <If condition={isCompleted}>
                      <Icon name="check" className="w-8 h-8 text-green-500" />
                    </If>
                    <If condition={!isLocked && !isCompleted}>
                      <CircularProgress percentageValue={goal.progress.percent_completed} strokeWidth={18} classes="w-8 h-8" />
                    </If>
                  </Card.Section>
                  <Header as="h4" className={`grow mb-0 ${isLocked ? 'opacity-40' : ''}`}>
                    {GOAL_LABEL_MAPPING[goal.outcome_type]}
                  </Header>
                  <If condition={!isLocked && !isCompleted}>
                    <Button className="py-2 font-semibold" onClick={() => setSelectedGoal(goal)}>
                      Continue
                    </Button>
                  </If>
                </Card>
              );
            })}
          </If>
        </div>
      </WidgetContainer>
      <ContentOverlay open={Boolean(selectedGoal)} dismiss={() => setSelectedGoal(null)} width="w-[50vw]" ariaLabelledBy="goal-title">
        <GoalDetails goalId={selectedGoal?.id} teamId={orgId} continueLearning={continueLearning} />
      </ContentOverlay>
    </>
  );
}

export default LearnerBaselineGoalsWidget;
