import React, { useEffect, createRef } from 'react';
import Message from '../Message/Message';
import AddLink from '../AddLink/AddLink';
import { HELP_DESK_LINKS } from '../../constants';

const errorDefaultIcon = 'exclamation-circle';
const successDefaultIcon = 'paper-plane';
const infoDefaultIcon = 'information-circle';

const errorSwitch = (code) => {
  switch (code) {
    case 406:
      return {
        header: '',
        msgBody: (
          <div>
            We are experiencing issues. Please try again in a few minutes. If you continue having trouble, view our{' '}
            <AddLink className="text-black hover:text-cyb-pink-500 underline" to={HELP_DESK_LINKS.HOW_TO_REGISTER_AND_LOGIN} target="_blank" rel="noreferrer noopener">
              Help Center
            </AddLink>
            .
          </div>
        ),
        status: 'error',
        icon: errorDefaultIcon,
      };
    case 400:
    case 401:
    case 'auth/user-not-found':
    case 'auth/wrong-password':
      return {
        header: '',
        msgBody: (
          <div>
            <p>Login failed. Please be sure you are using the correct email address and password.</p>
            <p>
              If you have not yet activated your account or logged in recently, an email has been sent to the address you used when registering. Follow the instructions in the
              email to ensure access. You can also use the forgot password feature to activate your account and reset your password using the email address you provided when
              registering.
            </p>
            <p>
              If you continue to have trouble, visit our{' '}
              <AddLink className="text-black hover:text-cyb-pink-500 underline" to={HELP_DESK_LINKS.HOW_TO_REGISTER_AND_LOGIN} target="_blank" rel="noreferrer noopener">
                Help Center
              </AddLink>{' '}
              for further details.
            </p>
          </div>
        ),
        status: 'error',
        icon: errorDefaultIcon,
      };
    case 429:
      return {
        header: '',
        msgBody: (
          <div>
            Our login provider is experiencing unusually high traffic volume and is working to correct the issue. Please wait 5 minutes and try again. If you retry/refresh too
            quickly, you may become blocked for 24 hours.
          </div>
        ),
        status: 'error',
        icon: errorDefaultIcon,
      };
    case 'pwResetInvalidCode':
      return {
        header: '',
        // Message used in Okta for now
        msgBody: (
          <div>Your password reset link has expired or is otherwise invalid. Please click &apos;Forgot your password?&apos; below and resend a new link to your email address.</div>
        ),
        status: 'error',
        icon: errorDefaultIcon,
      };
    case 'pwResetDefault':
      return {
        header: '',
        msgBody: <div>Unable to update password at this time. Please try again later.</div>,
        status: 'error',
        icon: errorDefaultIcon,
      };
    case 'magicEmailInvalidToken':
      return {
        header: '',
        msgBody: <div>Your one-time link has expired or is otherwise invalid. Please return to sign in and try again.</div>,
        status: 'error',
        icon: errorDefaultIcon,
      };
    case 'magicEmail':
      return {
        header: '',
        msgBody: <div>Unable to log you in with the provided email address. Please check the address and try again.</div>,
        status: 'error',
        icon: errorDefaultIcon,
      };
    case 'okta-unmigrated':
      return {
        header: '',
        msgBody: (
          <div>
            <p>We cannot log you in with single sign-on. Please contact your Org Owner to have your single sign-on configuration updated.</p>
            <p>
              In the meantime, you can login with your email after{' '}
              <AddLink className="text-black hover:text-cyb-pink-500 underline" to="/forgot-password">
                resetting your password
              </AddLink>
              .
            </p>
          </div>
        ),
        status: 'error',
        icon: errorDefaultIcon,
      };
    case 'socialLogin':
      return {
        header: '',
        msgBody: (
          <div>
            Unable to log you in with this provider. Please try again later. If you require assistance, view our{' '}
            <a className="text-black hover:text-cyb-pink-500 underline" href={HELP_DESK_LINKS.HOW_TO_REGISTER_AND_LOGIN} rel="noreferrer noopener">
              Help Center
            </a>
            .
          </div>
        ),
        status: 'error',
        icon: errorDefaultIcon,
      };
    case 'verifyEmail':
      return {
        header: '',
        msgBody: (
          <div>
            Unable to verify email. Please try again. If the issue persists please contact: <a href="mailto:support@cybrary.it">support@cybrary.it</a>
          </div>
        ),
        status: 'error',
        icon: errorDefaultIcon,
      };
    case 'auth/account-exists-with-different-credential':
      return {
        header: '',
        msgBody: (
          <div>
            Unable to log you in with this provider. If you have an email address and password associated with your account already, please try again using those credentials.
          </div>
        ),
        status: 'error',
        icon: errorDefaultIcon,
      };
    case 'resendRegisterCode':
      return {
        header: '',
        msgBody: (
          <div>
            There was an error sending your verification email, please try again later. If you encounter any issues or require assistance, please contact{' '}
            <a className="text-black hover:text-cyb-pink-500 underline" href="mailto:support@cybrary.it">
              support@cybrary.it
            </a>
            .
          </div>
        ),
        status: 'error',
        icon: errorDefaultIcon,
      };
    case 'registerCodeError':
      return {
        header: '',
        msgBody: (
          <div>
            Error, please try again later. If you encounter any issues or require assistance, please contact{' '}
            <a className="text-black hover:text-cyb-pink-500 underline" href="mailto:support@cybrary.it">
              support@cybrary.it
            </a>
            .
          </div>
        ),
        status: 'error',
        icon: errorDefaultIcon,
      };
    default:
      return {
        header: '',
        msgBody: (
          <div>
            Confirm your login credentials are correct and that you have verified your email address. If you require assistance, view our{' '}
            <a className="text-black hover:text-cyb-pink-500 underline" href={HELP_DESK_LINKS.HOW_TO_REGISTER_AND_LOGIN} rel="noreferrer noopener">
              Help Center
            </a>
            .
          </div>
        ),
        status: 'error',
        icon: errorDefaultIcon,
      };
  }
};

const successSwitch = (code) => {
  switch (code) {
    case 'forgotPwSuccess':
      return {
        header: '',
        msgBody: (
          <div>
            If your email was correct, an email has been sent to you with instructions on how to reset your password. If you continue having trouble, view our{' '}
            <AddLink className="text-black hover:text-cyb-pink-500 underline" to={HELP_DESK_LINKS.HOW_TO_REGISTER_AND_LOGIN} rel="noreferrer noopener" target="_blank">
              Help Center
            </AddLink>
            .{' '}
            <AddLink className="text-cyb-pink-500 hover:text-black underline" to="/login">
              Back to Sign In &raquo;
            </AddLink>
          </div>
        ),
        status: 'success',
        icon: successDefaultIcon,
      };
    case 'reset':
      return {
        header: '',
        msgBody: <div>Your password has been updated successfully.</div>,
        status: 'success',
        icon: successDefaultIcon,
      };
    case 'magicEmail':
      return {
        header: '',
        msgBody: <div>We have sent an email to your registered email addresses. Click the one-time link provided in that email to log in.</div>,
        status: 'success',
        icon: successDefaultIcon,
      };
    case 'verifyEmail':
      return {
        header: '',
        msgBody: <div>Email verified. Please sign in below.</div>,
        status: 'success',
        icon: 'thumbs-up',
      };
    default:
      return null;
  }
};

const infoSwitch = (code) => {
  if (code === 'pwExpired') {
    return {
      header: '',
      msgBody: (
        <div>
          <p>Your password has expired and needs to be reset. Please check your email for instructions on how to proceed.</p>
        </div>
      ),
      status: 'info',
      icon: infoDefaultIcon,
    };
  }
  return null;
};

const getMessageContent = (status, type) => {
  const defaultIconMap = {
    success: successDefaultIcon,
    error: errorDefaultIcon,
    info: infoDefaultIcon,
  };

  if (status.message) {
    return {
      header: '',
      msgBody: status.message,
      status: type,
      icon: defaultIconMap[type] || errorDefaultIcon,
    };
  }
  if (status.code) {
    switch (type) {
      case 'success':
        return successSwitch(status.code);
      case 'error':
        return errorSwitch(status.code);
      case 'info':
        return infoSwitch(status.code);
      default:
        return null;
    }
  }
  return null;
};

function StatusMessage({ status, type }) {
  if (!type) {
    return null;
  }
  const messageRef = createRef();

  useEffect(() => {
    if (type && messageRef && messageRef.current) {
      messageRef.current.focus();
    }
  }, [status, type, messageRef]);

  const messageContent = getMessageContent(status, type);
  return (
    <div ref={messageRef} tabIndex="-1">
      <Message className="flex mb-8" {...messageContent} />
    </div>
  );
}

export default StatusMessage;
