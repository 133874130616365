import React, { createContext, useContext, useState, useMemo, useEffect, useCallback } from 'react';
import Bugsnag from '@bugsnag/js';
import moment from 'moment';
import { debounce } from 'lodash';
import agents from '../agents/agents';
import { trackSnowplowEvent } from '../utils/snowplowUtil';

const CybAssessmentContext = createContext();

function CybAssessmentProvider({ children }) {
  const [launched, setLaunched] = useState(false);
  const [loading, setLoading] = useState(false);
  const [starting, setStarting] = useState(false);
  const [incompleteSubmission, setIncompleteSubmission] = useState(false);
  const [assessment, setAssessment] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [expires, setExpires] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [values, setValues] = useState({});

  useEffect(() => {
    if (!assessment?.questions?.[currentIndex]) {
      return;
    }
    setCurrentQuestion(assessment.questions[currentIndex]);
  }, [assessment, currentIndex]);

  const fireTrackingEvent = (action, label) => {
    trackSnowplowEvent({ category: 'CybAssessment', action, label });
  };

  const reset = () => {
    setLaunched(false);
    setStarting(false);
    setIncompleteSubmission(false);
    setAssessment(null);
    setSubmitting(false);
    setSubmitted(false);
    setExpires(null);
    setCurrentIndex(0);
    setCurrentIndex(null);
    setValues({});
  };

  const loadAssessment = async () => {
    setLoading(true);
    // When loading an assessment, reset back to the initial state
    reset();
    setLoading(false);
  };

  const getQuestionNameForId = (id) => {
    return id ? `question_${id}` : null;
  };

  const getQuestionName = () => {
    const id = currentQuestion ? currentQuestion['question-id'] : null;
    return getQuestionNameForId(id);
  };

  // Options will have option.choice set to true for the chosen answer if they have been answered already
  const getChosenOption = (options) => {
    if (!options?.length) {
      return '';
    }
    for (let i = 0; i < options.length; i++) {
      if (options[i].choice) {
        return options[i].text;
      }
    }
    return '';
  };

  const setInitialValues = (questions) => {
    const newValues = {};
    questions.forEach((question) => {
      // Once we have other types of questions, extracting the value will need to be updated to switch based on type
      const { answer, 'question-id': questionId } = question;
      const { options } = answer || {};
      newValues[getQuestionNameForId(questionId)] = getChosenOption(options);
    });
    setValues(newValues);
  };

  // Launches the assessment, if it's already started, we just fetch it, otherwise we start and fetch
  const startAssessment = async (id) => {
    setStarting(true);
    try {
      const initialResult = await agents.cybAssessmentNew.getAssessment(id);
      const { status } = initialResult;
      let assessmentResult;
      if (status === 'CREATED') {
        // We have to actually start this one up
        await agents.cybAssessmentNew.startAssessment(id);
        const result = await agents.cybAssessmentNew.getAssessment(id);
        setAssessment(result);
        assessmentResult = result;
      } else {
        // Set our initial state, in case there is any
        setInitialValues(initialResult.questions);
        setAssessment(initialResult);
        assessmentResult = initialResult;
      }
      setCurrentIndex(0);
      const { 'time-limit': timeLimit, 'time-elapsed': timeElapsed } = assessmentResult;
      const timeRemaining = parseInt(timeLimit, 10) - parseInt(timeElapsed, 10);
      const expiration = moment.unix(moment().unix() + timeRemaining).toDate();
      setExpires(expiration);
      setLaunched(true);
      fireTrackingEvent('Started', assessmentResult?.uuid);
    } catch (err) {
      Bugsnag.notify(err);
    }
    setStarting(false);
  };

  const next = () => {
    const questions = assessment?.questions;
    if (!questions) {
      return;
    }
    if (currentIndex < questions.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const previous = () => {
    const questions = assessment?.questions;
    if (!questions || currentIndex === 0) {
      return;
    }
    setCurrentIndex(currentIndex - 1);
  };

  const jumpToIndex = (newIndex) => {
    setCurrentIndex(newIndex);
  };

  const updateAsync = (uuid, questionId, value) => {
    agents.cybAssessmentNew.answerQuestion(uuid, questionId, value);
    fireTrackingEvent('AnswerQuestion', uuid);
  };
  // Debounce the actual async update calls to make sure we don't send too many (especially when typing)
  const debouncedUpdate = useCallback(debounce(updateAsync, 300), []);

  const onUpdate = (name, value) => {
    const newValues = {
      ...values,
      [name]: value,
    };
    setValues(newValues);
    // We are going to TEMPORARILY answer every question with the server as we go
    // This will be done optimistically (we won't wait for the response)
    const questionId = name.replace('question_', '');
    debouncedUpdate(assessment.uuid, questionId, value);
  };

  const getQuestionValue = () => {
    const name = getQuestionName();
    return name && typeof values[name] !== 'undefined' ? values[name] : '';
  };

  const getQuestionValueForId = (questionId) => {
    const name = getQuestionNameForId(questionId);
    return name && typeof values[name] !== 'undefined' ? values[name] : '';
  };

  const hasAnswer = (questionId) => {
    const name = getQuestionNameForId(questionId);
    return values && name && typeof values[name] !== 'undefined' && values[name] !== '';
  };

  const getTotalAnsweredQuestions = () => {
    return Object.keys(values).length;
  };

  const sendSubmitRequest = async (afterSubmit) => {
    setSubmitting(true);
    try {
      const assessmentId = assessment.uuid;
      await agents.cybAssessmentNew.submitAssessment(assessmentId);
      // If we passed a callback, fire it (will be used to run completeActivity)
      if (afterSubmit) {
        afterSubmit();
      }
      setSubmitting(false);
      setSubmitted(true);
      fireTrackingEvent('Submit', assessmentId);
      return true;
    } catch (err) {
      setSubmitting(false);
      Bugsnag.notify(err);
    }
    return false;
  };

  const submitAssessment = async (afterSubmit = null, force = false) => {
    const questions = assessment?.questions;
    const total = questions.length;
    const num = getTotalAnsweredQuestions();
    const allAnswered = num === total;
    if (force || allAnswered) {
      // Submit!
      setIncompleteSubmission(false);
      return sendSubmitRequest(afterSubmit);
    }
    setIncompleteSubmission(true);
    return false;
  };

  const state = useMemo(
    () => ({
      launched,
      loading,
      starting,
      assessment,
      currentIndex,
      currentQuestion,
      values,
      expires,
      incompleteSubmission,
      submitting,
      submitted,
      loadAssessment,
      startAssessment,
      next,
      previous,
      onUpdate,
      getQuestionName,
      getQuestionNameForId,
      getQuestionValue,
      getQuestionValueForId,
      hasAnswer,
      getTotalAnsweredQuestions,
      jumpToIndex,
      submitAssessment,
      setIncompleteSubmission,
    }),
    [launched, loading, starting, assessment, currentIndex, currentQuestion, values, expires, incompleteSubmission, submitting, submitted]
  );

  return <CybAssessmentContext.Provider value={state}>{children}</CybAssessmentContext.Provider>;
}

export const useCybAssessment = () => useContext(CybAssessmentContext);
export default CybAssessmentProvider;
