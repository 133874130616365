import React from 'react';
import { Fade } from 'react-awesome-reveal';
import Segment from '../../../../components/Segment/Segment';
import LoadingText from '../../../../components/Loading/LoadingText';
import If from '../../../../components/If/If';
import OrderSummaryRow from './OrderSummaryRow';

/**
 * Order Summary Container for Paddle Checkout/Upgrade pages
 * @param {boolean} basic - If true, use basic styles
 * @param {boolean} isLoading - If true, show loading rows
 * @param {React.ReactNode} children - Child rows
 * @returns {React.ReactNode} - Order summary container
 */
function OrderSummaryContainer({ basic, isLoading, children }) {
  const headerClass = basic ? 'border-none bg-none' : 'border bg-gray-200';
  const rowClass = basic ? 'border-b bg-none' : 'border bg-white';
  const priceRowClass = basic ? 'font-normal' : 'font-bold';
  return (
    <Segment className="overflow-hidden p-0 mt-0">
      <Fade triggerOnce>
        <table className="w-full border-collapse table-auto">
          <thead>
            <tr className={headerClass}>
              <th className="col-span-2 py-2 px-4 text-left">Order Summary</th>
              <th aria-label="empty-headercell" />
            </tr>
          </thead>
          <tbody className="text-sm">
            {/** Loading Display */}
            <If condition={isLoading}>
              {/** Add an extra loading row for teams since we show two rows for one item there */}
              <OrderSummaryRow key="empty1" className={`${rowClass}`} title={<LoadingText />} value={<LoadingText />} />
              <OrderSummaryRow key="empty2" className={`${rowClass} ${priceRowClass}`} title={<LoadingText />} value={<LoadingText />} />
            </If>
            {/** Display children rows */}
            <If condition={!isLoading}>{children}</If>
          </tbody>
        </table>
      </Fade>
    </Segment>
  );
}

export default OrderSummaryContainer;
