import React, { useState, useEffect } from 'react';
import Bugsnag from '@bugsnag/js';
import Agents from '../../../../agents/agents';
import Container from '../../../Container/Container';
import Loading from '../../../Loading/Loading';
import Segment from '../../../Segment/Segment';
import NoResultsMessage from '../../../NoResultsMessage/NoResultsMessage';
import BarChart from '../../../Charts/BarChart';
import DropdownFilter from '../../../Dropdown/DropdownFilter';
import Checkbox from '../../../FormFields/Checkbox';
import { getCustomColor } from '../../../../utils/chartUtil';
import If from '../../../If/If';
import FormatUtil from '../../../../utils/formatUtil';
import OrganizationTrainingProgressDetails from '../../../../pages/Enterprise/OrganizationTrainingProgressDetails';

// Custom tooltip for the bar chart, to hide the empty date (since we are displaying all time values)
function BarTooltip({ id, value, data }) {
  const { unitOfMeasure } = data || {};
  return (
    <div
      key={id}
      style={{
        background: 'white',
        color: 'inherit',
        fontSize: 'inherit',
        borderRadius: 2,
        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 2px',
        padding: '10px 10px',
      }}
    >
      <p className="mb-1 text-base text-center">
        <span className="font-bold">{id}:</span> {FormatUtil.formatNumbers(value)} {unitOfMeasure === 'hours' ? 'hours' : 'XP'}
      </p>
    </div>
  );
}

/**
 * @param {string} username - username for the user's profile we are viewing
 * @returns <LearningProgressChart /> the learning progress chart component
 */
export default function LearningProgressChart({ username }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [details, setDetails] = useState(null);
  const [rawData, setRawData] = useState(null);
  const [keys, setKeys] = useState(null);
  const [unitOfMeasure, setUnitOfMeasure] = useState('hours');
  const [taxonomy, setTaxonomy] = useState('cybrary');
  const [customBarChartColors, setCustomBarChartColors] = useState(null);

  const barChartColorScheme = 'paired';

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setDetails(null);
      try {
        const query = `?taxonomy=${taxonomy}&unit=${unitOfMeasure}`;
        const response = await Agents.profile.getProfileLearningProgress(username, query);
        // Add our data from each response to newData
        const graphData = [];
        const newKeys = [];
        const newBarChartColors = {};
        const newRawData = {};
        if (response?.length) {
          // Initialize graph data
          graphData.push({ period: ' ', unitOfMeasure });
          response.forEach((d, i) => {
            const { category_term_name: name, measure } = d;
            if (newKeys.indexOf(name) === -1) {
              newKeys.push(name);
              const barChartColor = getCustomColor(name, i, barChartColorScheme);
              newBarChartColors[name] = barChartColor;
            }
            // Insert our graph data, there is only one graph period, since we are showing all data
            graphData[0][name] = parseFloat(measure || 0);
            // Save our raw data for the details view
            newRawData[name] = { ...d };
          });
        }
        setKeys(newKeys);
        setData(graphData);
        setCustomBarChartColors(newBarChartColors);
        setRawData(newRawData);
      } catch (err) {
        Bugsnag.notify(err);
      }
      setLoading(false);
    };

    if (!username || username === '') {
      return;
    }

    fetchData();
  }, [username, unitOfMeasure, taxonomy]);

  if (loading || !data) {
    return (
      <Container>
        <Loading message="Loading..." />
      </Container>
    );
  }

  const unitOfMeasureOptions = [
    {
      id: 'Learning Hours',
      text: 'Learning Hours',
      value: 'hours',
    },
    {
      id: 'XP',
      text: 'XP',
      value: 'xp',
    },
  ];

  const handleUnitChange = (item) => {
    setUnitOfMeasure(item.value);
  };
  const handleTaxonomyToggle = () => {
    setTaxonomy(taxonomy === 'cybrary' ? 'nice' : 'cybrary');
  };
  const chartClickHandler = (clicked) => {
    if (taxonomy !== 'cybrary') {
      return;
    }
    const { id } = clicked;
    setDetails(rawData?.[id] || null);
  };
  const hideDetails = () => {
    setDetails(null);
  };

  return (
    <div className="relative py-4 rounded-md border border-gray-600">
      <div className="justify-center p-4 md:flex">
        <div className="grid print:hidden grid-cols-5 items-center h-[42px] text-sm">
          <p className="col-span-2 mb-0 font-semibold">Cybrary Framework</p>
          <p className="mb-0 text-center">
            <Checkbox className="inline-block p-0 pr-4 m-0" toggle onChange={handleTaxonomyToggle} checked={taxonomy !== 'cybrary'} ariaLabelledBy="toggle-taxonomy" />
          </p>
          <p className="col-span-2 mb-0 font-semibold">NICE Framework</p>
        </div>
        <div className="flex z-50 items-center">
          <label className="block pr-5 w-[190px] text-sm font-semibold text-right">Unit of Measure</label>
          <div className="w-[220px] text-left">
            <DropdownFilter
              options={unitOfMeasureOptions}
              value={unitOfMeasure}
              onChange={handleUnitChange}
              placeholder="Unit Of Measure"
              ariaLabel="Change unit of measure"
              hideSelectedOptions={false}
            />
          </div>
        </div>
      </div>
      <If condition={!data?.length}>
        <Segment className="border-none empty-stats-container">
          <NoResultsMessage message="There is no data to display." />
        </Segment>
      </If>

      <If condition={!!data?.length}>
        <BarChart
          data={data}
          onClick={chartClickHandler}
          keys={keys}
          indexBy="period"
          ariaLabel="Training Progress"
          colorScheme={barChartColorScheme}
          legends
          customColors={customBarChartColors}
          tooltip={BarTooltip}
        />
        <OrganizationTrainingProgressDetails details={details} unit={unitOfMeasure} hideInterval onClose={hideDetails} />
      </If>
    </div>
  );
}
