import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import ReactMarkdown from 'react-markdown';
import Header from '../../components/Header/Header';
import withRouter from '../../components/Router/withRouter';
import Loading from '../../components/Loading/Loading';
import StyledError from '../../components/Error/StyledError';
import Segment from '../../components/Segment/Segment';
import SessionContent from '../../components/Live/SessionContent';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import SupplementalMaterials from '../../components/Details/SupplementalMaterials';
import AdditionalResources from '../../components/Details/AdditionalResources';
import InstructorCard from '../../components/Item/InstructorCard';
import Agents from '../../agents/agents';
import GaUtil from '../../utils/gaUtil';
import Container from '../../components/Container/Container';

/**
 * The Cybrary Live Series page view
 * @returns {*}
 * @constructor
 */
const LiveSession = inject(
  'authStore',
  'commonStore',
  'userStore',
  'catalogStore',
  'feedbackStore'
)(
  observer(
    class LiveSession extends Component {
      state = {
        embedActive: false,
        registered: false,
      };

      componentDidMount() {
        this.props.authStore.fireAttributionEvent();
        this.props.commonStore.setPageTitle('Live | Cybrary');
        if (this.props.match.params.permalink) {
          const realPermalink = `cybrary-live/session/${this.props.match.params.permalink}`;
          // Fetch our content description from the server.
          this.props.catalogStore.getContentDescriptionByPermalink(realPermalink).then((item) => {
            this.props.feedbackStore.setContentDescriptionId(item.id);
            Agents.enrollments.getEnrollment(item.id).then((response) => {
              // Update registered on state if we are registered
              if (response && response.id) {
                const newState = {
                  ...this.state,
                  registered: true,
                };
                this.setState(newState);
              }
            });
          });
        }
      }

      componentWillUnmount() {
        this.props.catalogStore.reset();
      }

      getBreadCrumbs = (session) => {
        const crumbs = [];
        if (session.seriesUrl && session.seriesTitle) {
          crumbs.push({
            href: session.seriesUrl,
            label: session.seriesTitle,
          });
        }
        if (session.url && session.title) {
          crumbs.push({
            href: session.url,
            label: session.title,
          });
        }
        return crumbs;
      };

      // const timeFormat = "dddd, MMMM Do YYYY [at] h:mm a";

      fireLaunchActivity = () => {
        // e.preventDefault();
        // e.stopPropagation();
        const session = this.props.catalogStore.item;
        if (session) {
          if (!this.props.userStore.checkPermissions(session)) {
            if (this.props.userStore.isEnterprise) {
              window.location.href = this.props.commonStore.getRequestLicenseLink((this.props.userStore.team && this.props.userStore.team.id) || null);
            } else {
              window.location.href = this.props.commonStore.getUpgradeLink('?appbrowse=upgradebtn');
            }
          } else {
            this.props.catalogStore.fireLaunchActivity(session.id);
            GaUtil.fireEvent('CybraryLive', 'Enrollment', session.title);
            const newState = {
              ...this.state,
              embedActive: true,
            };
            this.setState(newState);
          }
        }
      };

      registerForSession = () => {
        const session = this.props.catalogStore.item;
        if (session) {
          this.props.catalogStore.registerForSession(session.id);
          const newState = {
            ...this.state,
            registered: true,
          };
          this.setState(newState);
        }
      };

      getInstructors = (instructors) => {
        return (
          <>
            <div className="hidden gap-2 lg:grid lg:grid-flow-row">
              {instructors.map((instructor) => {
                const propsToSend = {
                  name: instructor.name,
                  thumbnail: instructor.thumbnail,
                  title: instructor.title,
                  instructor_level: instructor.instructorLevel,
                  permalink: instructor.permalink,
                  short_bio: '',
                  type: 'Instructor',
                };
                return <InstructorCard key={instructor.name} {...propsToSend} fluid />;
              })}
            </div>
            <div className="lg:hidden">
              <div className="grid gap-2 my-3.5 sm:grid-cols-1 md:grid-cols-2">
                {instructors.map((instructor) => {
                  const propsToSend = {
                    name: instructor.name,
                    thumbnail: instructor.thumbnail,
                    title: instructor.title,
                    short_bio: '',
                    instructor_level: instructor.instructorLevel,
                    permalink: instructor.permalink,
                    type: 'Instructor',
                  };
                  return <InstructorCard key={instructor.name} {...propsToSend} fluid />;
                })}
              </div>
            </div>
          </>
        );
      };

      render() {
        const data = this.props.catalogStore.item;
        const type = 'Cybrary Live Session';
        const loading = this.props.catalogStore.loading || !data || !data.content_type || data.content_type.nice_name !== type;

        if (loading) {
          return <Loading message="Loading..." />;
        }
        if (this.props.catalogStore.error) {
          return <StyledError error={this.props.catalogStore.error} />;
        }
        const session = this.props.catalogStore.formatSessionData(data);

        const hasInstructors = !!session.instructors && session.instructors.length;

        const firstGridCols = session.status === 'active' ? 'lg:col-span-3' : 'col-span-4';

        const ratingsAverage = session.average_rating ? session.average_rating : 0;
        const ratingsCount = session.rating_count ? session.rating_count : 0;

        return (
          <div className="cybrary-live-series">
            <Segment className="border-none">
              <Breadcrumbs crumbs={this.getBreadCrumbs(session)} />
              <div className="grid grid-cols-1 mb-4 lg:grid-cols-4 lg:gap-x-4">
                <div className={firstGridCols}>
                  <SessionContent
                    session={session}
                    fireLaunchActivity={this.fireLaunchActivity}
                    embedActive={this.state.embedActive}
                    registerForSession={this.registerForSession}
                    registered={this.state.registered}
                    feedbackStore={this.props.feedbackStore}
                    ratingsAverage={ratingsAverage}
                    ratingsCount={ratingsCount}
                  />
                </div>
                {session.status === 'active' ? (
                  <div className="lg:col-span-1">
                    <Segment>
                      <div style={{ maxHeight: '100vh' }}>
                        <iframe src={session.vimeoChatUrl} width="100%" height="600" title="Live Chat" frameBorder="0" />
                      </div>
                    </Segment>
                  </div>
                ) : null}
              </div>
            </Segment>
            <Container fluid>
              <Segment className="border-none">
                <div className="lg:grid lg:grid-cols-3 lg:gap-x-6">
                  <div className="mb-6 lg:col-span-2 lg:mb-0">
                    {session.description ? (
                      <>
                        <Header as="h3" className="border-b border-gray-400">
                          About this session
                        </Header>
                        <Segment>
                          <ReactMarkdown className="has-markdown-inside">{session.description}</ReactMarkdown>
                        </Segment>
                      </>
                    ) : null}
                    {session.supplementalMaterials ? (
                      <>
                        <Header as="h3" className="border-b border-gray-400">
                          Supplemental Materials
                        </Header>
                        <Segment>
                          <SupplementalMaterials items={session.supplementalMaterials} />
                        </Segment>
                      </>
                    ) : null}
                    {session.additionalResources ? (
                      <>
                        <Header as="h3" className="border-b border-gray-400">
                          Additional Resources
                        </Header>
                        <Segment>
                          <AdditionalResources items={session.additionalResources} />
                        </Segment>
                      </>
                    ) : null}
                  </div>
                  {hasInstructors ? (
                    <div className="lg:col-span-1">
                      <Header as="h3" className="border-b border-gray-400">
                        Instructed By
                      </Header>
                      {this.getInstructors(session.instructors)}
                    </div>
                  ) : null}
                </div>
              </Segment>
            </Container>
          </div>
        );
      }
    }
  )
);

export default withRouter(LiveSession);
