import React, { useState, useEffect, useRef } from 'react';
import DropdownFilter from '../../Dropdown/DropdownFilter';
import ReportHeadingTooltip from '../../Enterprise/ReportHeadingTooltip';
import LeaderboardTable from './LeaderboardTable';
import '../analytics.css';
import If from '../../If/If';
import ComboboxMultiselect from '../../Enterprise/ComboboxMultiselect';
import Header from '../../Header/Header';

function Leaderboard({
  name,
  filters,
  filterValue,
  filterChange,
  formatColumns,
  sorters,
  sorterValueChange,
  orgId,
  loading,
  error,
  heading,
  headingTooltip,
  data,
  ignoredCols,
  href,
}) {
  const [ariaLive, setAriaLive] = useState('off');
  const placementRefs = useRef([]);
  const tableRef = useRef(null);

  const setPlacementRef = (ref) => {
    if (ref) {
      placementRefs.current.push(ref);
    }
  };

  const setLeaderboardFocus = () => {
    setTimeout(() => {
      if (tableRef.current) {
        tableRef.current.focus();
      }
    }, 300);
  };

  const setPlacements = () => {
    if (placementRefs.current) {
      let delay = 100;
      placementRefs.current.forEach((ref) => {
        delay += 100;
        setTimeout(() => {
          ref.classList.remove('hidden');
        }, delay);
      });
    }
  };

  const changeFilter = ({ value }) => {
    filterChange(name, value, setLeaderboardFocus);
    setAriaLive('polite');
  };

  const changeSorterValue = (value, sorter) => {
    const values = value[0]?.value !== '_all' ? value.map((option) => option.value) : [];
    sorterValueChange(name, values, sorter.queryParam, setLeaderboardFocus);
    setAriaLive('polite');
  };

  // handles setting placements on mount and when it updates..
  useEffect(() => {
    setPlacements();
  }, [placementRefs.current]);

  const isDemo = orgId === 'demo';
  return (
    <div className="py-4 leaderboard-container">
      <div className="print:flex print:justify-between print:items-center sm:flex sm:justify-between sm:items-center">
        <div className="flex items-center">
          {heading && (
            <Header as="h3" ref={tableRef.current} tabIndex="-1">
              {heading}
            </Header>
          )}
          {headingTooltip && <ReportHeadingTooltip iconClass="mt-2" />}
        </div>
        <div className="flex flex-row items-center">
          <If condition={Boolean(sorters?.length > 0)}>
            {sorters?.map((sorter) => (
              <div className="flex z-50 items-center mr-7 h-[42px]" key={sorter.id}>
                <label className="block mr-4 font-semibold text-right text-md">{sorter.title}</label>
                <div className="z-50">
                  <ComboboxMultiselect placeholder={`Select ${sorter.title}`} onChange={(value) => changeSorterValue(value, sorter)} options={sorter.options} />
                </div>
              </div>
            ))}
          </If>
          <div className="flex items-center mt-4 sm:justify-end sm:mt-0">
            <label className="block mr-4 font-semibold text-right text-md">Sort</label>
            <div className="inline-block w-60 text-left">
              <DropdownFilter
                name={name}
                options={filters}
                value={filterValue}
                onChange={changeFilter}
                placeholder="Filter"
                ariaLabel="Change leaderboard metric"
                disabled={isDemo}
                hideSelectedOptions={false}
              />
            </div>
          </div>
        </div>
      </div>
      <LeaderboardTable
        data={data}
        name={name}
        formatColumns={formatColumns}
        filter={filterValue}
        loading={loading}
        error={error}
        orgId={orgId}
        heading={heading}
        ignoredCols={ignoredCols}
        ariaLive={ariaLive}
        filterValue={filterValue}
        href={href}
        setPlacementRef={setPlacementRef}
      />
    </div>
  );
}
export default Leaderboard;
