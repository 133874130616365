import React from 'react';

export default function CloseArrows({ classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';
  return (
    <svg className={className} width="24" height="10" viewBox="0 0 24 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...accessibilityProps}>
      <path
        d="M5.80912 1.12727C5.54177 1.39463 5.54177 1.82809 5.80912 2.09544L8.02907 4.31539L0.968182 4.31539C0.59009 4.31539 0.283569 4.62191 0.283569 5C0.283569 5.37809 0.59009 5.68461 0.968182 5.68461L8.02907 5.68461L5.80912 7.90456C5.54177 8.17191 5.54177 8.60537 5.80912 8.87273C6.07648 9.14008 6.50994 9.14008 6.77729 8.87273L10.1659 5.48409C10.1661 5.48393 10.1662 5.48374 10.1664 5.48359C10.1822 5.46777 10.1971 5.45118 10.2113 5.43391C10.2178 5.42599 10.2235 5.41762 10.2295 5.4095C10.2368 5.39976 10.2443 5.39025 10.2511 5.38016C10.2577 5.3704 10.2633 5.36023 10.2693 5.3502C10.2748 5.34106 10.2805 5.33214 10.2856 5.3227C10.291 5.31247 10.2957 5.30196 10.3006 5.29152C10.3052 5.28172 10.3101 5.27205 10.3143 5.26198C10.3185 5.25183 10.3219 5.24151 10.3256 5.23121C10.3294 5.22043 10.3336 5.20976 10.3369 5.19874C10.34 5.18846 10.3423 5.17802 10.3449 5.16765C10.3477 5.15637 10.3509 5.14525 10.3532 5.13377C10.3556 5.12181 10.357 5.10972 10.3587 5.09767C10.3601 5.08761 10.3621 5.07774 10.363 5.06755C10.3675 5.02265 10.3675 4.97738 10.363 4.93245C10.362 4.92228 10.3601 4.91239 10.3587 4.90233C10.357 4.89026 10.3556 4.87819 10.3532 4.86623C10.3509 4.85475 10.3478 4.84361 10.3449 4.83235C10.3423 4.82198 10.34 4.81154 10.3369 4.80126C10.3336 4.79024 10.3294 4.77957 10.3256 4.76879C10.3219 4.75851 10.3185 4.74814 10.3143 4.73802C10.3101 4.72795 10.3052 4.71828 10.3006 4.70848C10.2957 4.69804 10.291 4.68753 10.2856 4.6773C10.2805 4.6679 10.2748 4.65894 10.2693 4.6498C10.2633 4.63977 10.2577 4.62962 10.2511 4.61984C10.2444 4.60972 10.2368 4.60019 10.2295 4.5905C10.2234 4.58236 10.2178 4.57403 10.2113 4.56609C10.197 4.54864 10.1819 4.53187 10.1659 4.51591L6.77729 1.12727C6.50996 0.859896 6.07648 0.859919 5.80912 1.12727Z"
        fill="#707070"
      />
      <path
        d="M22.6818 4.31539L15.6209 4.31539L17.8409 2.09545C18.1082 1.82809 18.1082 1.39464 17.8409 1.12728C17.5735 0.859926 17.1401 0.859926 16.8727 1.12728L13.4841 4.51592C13.4681 4.53187 13.453 4.54864 13.4387 4.56609C13.4322 4.57401 13.4266 4.58238 13.4205 4.5905C13.4132 4.60024 13.4057 4.60975 13.3989 4.61984C13.3924 4.6296 13.3867 4.63977 13.3807 4.6498C13.3752 4.65894 13.3695 4.66786 13.3645 4.6773C13.359 4.68753 13.3543 4.69804 13.3494 4.70848C13.3448 4.71828 13.3399 4.72795 13.3357 4.73802C13.3315 4.74817 13.3281 4.75849 13.3245 4.76879C13.3206 4.77957 13.3165 4.79024 13.3131 4.80127C13.31 4.81154 13.3077 4.82198 13.3051 4.83235C13.3023 4.84363 13.2991 4.85475 13.2968 4.86623C13.2944 4.87819 13.2931 4.89029 13.2913 4.90233C13.2899 4.91239 13.288 4.92226 13.287 4.93245C13.2826 4.97735 13.2825 5.02262 13.287 5.06755C13.288 5.07772 13.2899 5.08761 13.2913 5.09767C13.293 5.10974 13.2944 5.12181 13.2968 5.13377C13.2991 5.14525 13.3023 5.15639 13.3051 5.16765C13.3077 5.17802 13.31 5.18846 13.3131 5.19873C13.3165 5.20976 13.3206 5.22043 13.3245 5.23121C13.3282 5.24149 13.3315 5.25186 13.3357 5.26198C13.3399 5.27205 13.3448 5.28172 13.3494 5.29152C13.3543 5.30196 13.359 5.31247 13.3645 5.3227C13.3695 5.33209 13.3752 5.34106 13.3807 5.3502C13.3867 5.36023 13.3923 5.37038 13.3989 5.38016C13.4057 5.39028 13.4132 5.39981 13.4205 5.4095C13.4266 5.41764 13.4322 5.42597 13.4387 5.43391C13.4529 5.45118 13.4678 5.46779 13.4836 5.48359C13.4838 5.48374 13.4839 5.48392 13.4841 5.48408L16.8727 8.87272C17.1401 9.14007 17.5735 9.14007 17.8409 8.87272C18.1082 8.60536 18.1082 8.17191 17.8409 7.90455L15.6209 5.68461L22.6818 5.68461C23.0599 5.68461 23.3664 5.37809 23.3664 5C23.3664 4.62189 23.0599 4.31539 22.6818 4.31539Z"
        fill="#707070"
      />
    </svg>
  );
}
