import React, { useMemo } from 'react';

function Paths({ active }) {
  if (active) {
    return (
      <>
        <g>
          <g>
            <path
              d="M454.32,75.411H333.816V36.916c0-16.288-13.25-29.538-29.538-29.538h-138.99c-16.288,0-29.538,13.25-29.538,29.538v38.496
			H18.294C8.19,75.411,0,83.603,0,93.706v166.467c0,3.074,0.831,5.927,2.171,8.471h92.646v-21.07h52.677v21.07h177.625v-21.07
			h52.677v21.07h92.648c1.341-2.543,2.171-5.397,2.171-8.471V93.706C472.615,83.603,464.424,75.411,454.32,75.411z M314.124,75.411
			H155.441V36.916c0-5.432,4.418-9.846,9.846-9.846h138.99c5.428,0,9.846,4.414,9.846,9.846V75.411z"
              fill="black"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M377.796,288.336v4.135c0,14.571-11.815,26.387-26.388,26.387c-14.474,0-26.289-11.815-26.289-26.387v-4.135H147.494
			v4.135c0,14.571-11.815,26.387-26.289,26.387c-14.572,0-26.388-11.815-26.388-26.387v-4.135H27.442v157.21
			c0,10.875,8.816,19.692,19.692,19.692h375.297c10.876,0,19.692-8.817,19.692-19.692v-157.21H377.796z"
              fill="black"
            />
          </g>
        </g>
      </>
    );
  }
  return (
    <g>
      <g>
        <path
          d="M464.168,70.488H343.663V41.839c0-16.289-13.25-29.539-29.538-29.539h-138.99c-16.288,0-29.538,13.25-29.538,29.539
			v28.649H28.139C12.625,70.488,0,83.113,0,98.632v166.466c0,15.276,12.257,27.689,27.442,28.069v157.301
			c0,16.288,13.25,29.538,29.538,29.538h375.298c16.288,0,29.538-13.25,29.538-29.538V293.238h2.351
			c15.514,0,28.139-12.625,28.139-28.139V98.632C492.308,83.113,479.683,70.488,464.168,70.488z M165.288,41.839
			c0-5.428,4.418-9.846,9.846-9.846h138.99c5.428,0,9.846,4.418,9.846,9.846v28.649H165.288V41.839z M442.125,450.469
			c0,5.428-4.418,9.846-9.846,9.846H56.981c-5.428,0-9.846-4.418-9.846-9.846V293.238h57.529v4.188
			c0,14.534,11.827,26.361,26.361,26.361c14.534,0,26.356-11.827,26.356-26.361v-4.188h177.548v4.188
			c0,14.534,11.827,26.361,26.361,26.361c14.534,0,26.356-11.827,26.356-26.361v-4.188h54.481V450.469z M124.356,297.426v-25.255
			h13.332v25.255c0,3.678-2.99,6.668-6.663,6.668C127.346,304.094,124.356,301.103,124.356,297.426z M354.62,297.426v-25.255h13.332
			v25.255c0,3.678-2.99,6.668-6.663,6.668C357.611,304.094,354.62,301.103,354.62,297.426z M472.615,265.099
			c0,4.659-3.788,8.447-8.447,8.447h-76.524v-21.067h-52.716v21.067H157.38v-21.067h-52.716v21.067H28.139
			c-4.659,0-8.447-3.788-8.447-8.447V98.632c0-4.659,3.788-8.452,8.447-8.452h117.457h198.067h120.505
			c4.659,0,8.447,3.793,8.447,8.452V265.099z"
          fill="currentColor"
        />
      </g>
    </g>
  );
}

export default function CareerPathIcon({ id, active, classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const strokeProps = active ? {} : { stroke: 'currentColor' };
  const viewBox = active ? '0 0 472.615 472.615' : '0 0 492.308 492.308';

  const className = useMemo(() => {
    let base = classes || 'w-6 h-6';

    if (id === '-primary-nav') {
      base += ' scale-[0.8]';
    }

    return base;
  }, [classes, id]);

  return (
    <svg className={className} fill="none" {...strokeProps} viewBox={viewBox} xmlns="http://www.w3.org/2000/svg" {...accessibilityProps}>
      <Paths active={active} />
    </svg>
  );
}
