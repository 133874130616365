import React from 'react';

export default function Circle({ classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      width="24"
      height="24"
      stroke="currentColor"
      strokeWidth={2}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...accessibilityProps}
    >
      <circle cx="12" cy="12" r="10" />
    </svg>
  );
}
