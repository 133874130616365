import React, { useEffect } from 'react';
import withRouter from '../../components/Router/withRouter';
import Container from '../../components/Container/Container';
import Loading from '../../components/Loading/Loading';
import StyledError from '../../components/Error/StyledError';
import { useCybAssessmentPath } from '../../providers/CybAssessmentPathProvider';
import AssessmentPathTabs from '../../components/AssessmentPath/AssessmentPathTabs';
import AssessmentPathHeader from '../../components/AssessmentPath/AssessmentPathHeader';

function AssessmentPathResults({ match: { params } }) {
  const { orgId, id } = params;

  const { currentAssessmentPath, isLoading, error, actions } = useCybAssessmentPath();

  useEffect(() => {
    actions.getAssessmentPath(orgId, id);
  }, [orgId, id]);

  if (isLoading) {
    return (
      <Container>
        <Loading message="Loading..." />
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <StyledError error={error} />
      </Container>
    );
  }

  if (!currentAssessmentPath) {
    return null;
  }

  return (
    <Container className="pt-0 sm-576:px-8" lg omitPadding>
      <AssessmentPathHeader orgId={orgId} />
      <AssessmentPathTabs orgId={orgId} />
    </Container>
  );
}

export default withRouter(AssessmentPathResults);
