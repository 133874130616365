import React from 'react';
import Section from './Section';

function CodeSection({ code }) {
  if (!code) {
    return null;
  }
  return (
    <Section>
      <pre className="p-2 text-gray-100 bg-blue-900 rounded-lg">
        <code>{code}</code>
      </pre>
    </Section>
  );
}

export default CodeSection;
