import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Icon from '../Icon/Icon';
import Table from '../Table/Table';
import TableHeadings from './TableHeadings';
import TableRows from './TableRows';
import Pagination from '../Pagination/Pagination';
import Loading from '../Loading/Loading';
import StyledError from '../Error/StyledError';
import ResultsSummary from '../AdminTable/ResultsSummary';
import Container from '../Container/Container';

export default observer(
  class ReportTable extends Component {
    getSortIcon = (sortKey, sortCol, sortDirection) => {
      if (!sortKey) {
        return null;
      }
      const iconClasses = 'w-4 h-4 ml-1';
      if (!!sortCol && !!sortDirection && !!sortKey && sortKey === sortCol) {
        return sortDirection === 'asc' ? <Icon name="arrow-narrow-down" className={iconClasses} /> : <Icon name="arrow-narrow-up" className={iconClasses} />;
      }
      return <Icon name="arrows-up-down" className={iconClasses} />;
    };

    render() {
      if (this.props.loading) {
        return (
          <Container>
            <Loading message="Loading..." wrapperClassName="py-8" />
          </Container>
        );
      }
      if (this.props.error) {
        return (
          <Container>
            <StyledError error={this.props.error} />
          </Container>
        );
      }
      const { recordsCount } = this.props.data;

      return (
        /* below comment is for tabIndex which was recommended by Level Access to be added here as an accessibility fix */
        /* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */
        <div tabIndex="0" aria-label="scrollable content" role="region">
          <ResultsSummary numOfResults={recordsCount} srOnly={!this.props.filterApplied} />
          <Table className="activity-table" unstackable>
            <Table.Header>
              <Table.Row>
                <TableHeadings
                  headings={this.props.data.tableHeadings}
                  reportId={this.props.reportId}
                  sortFunc={this.props.tableSort}
                  reportsStore={this.props.reportsStore}
                  getSortIcon={this.getSortIcon}
                  sortCol={this.props.reportsStore.reportItem.queryParams.sortCol}
                  sortDirection={this.props.reportsStore.reportItem.queryParams.sortDirection}
                />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <TableRows
                data={this.props.data.tableData}
                columns={this.props.data.tableHeadings}
                reportId={this.props.reportId}
                reportsStore={this.props.reportsStore}
                reportParams={this.props.reportParams}
                orgId={this.props.orgId}
                teamGroupId={this.props.teamGroupId}
                columnMutations={this.props.tableOptions.columnMutations || {}}
              />
            </Table.Body>
            <Table.Footer className="bg-gray-200">
              <Table.Row>
                <Table.Cell colSpan={this.props.data.tableData[0]?.length ? this.props.data.tableData[0].length : 10}>
                  <Pagination totalPages={this.props.count} onPageChange={this.props.changePg} activePage={this.props.activePage} siblingRange={1} />
                </Table.Cell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </div>
      );
    }
  }
);
