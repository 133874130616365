import { v4 as uuidv4 } from 'uuid';
import { canJoinTeam, canRequestLicense, isFreeUser, isEnterpriseUser, showTeamLogos, teamInviteHrefReplace } from '../utils/navUtil';

export default {
  items: [
    {
      label: 'Join Discord',
      href: '/community/?discord=1',
      display: (userStore) => isFreeUser(userStore) && !isEnterpriseUser(userStore),
      id: uuidv4(),
      discordModal: true,
    },
    {
      label: 'Buy For My Team',
      href: `/upgrade/teams-checkout`,
      newTab: true,
      display: (userStore) => !isEnterpriseUser(userStore),
      id: uuidv4(),
    },
    {
      label: 'Join Team',
      hrefReplace: teamInviteHrefReplace,
      href: '/team/join',
      display: canJoinTeam,
      id: uuidv4(),
    },
    {
      label: 'Request License',
      href: '/request-license',
      display: canRequestLicense,
      id: uuidv4(),
    },
    {
      display: showTeamLogos,
      id: 'team-logos',
    },
  ],
};
