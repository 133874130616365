import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import Container from '../../components/Container/Container';
import Title from '../../components/Title/Title';
import BaselineOverview from '../../components/Baseline/BaselineOverview';

const Assessments = inject(
  'authStore',
  'commonStore'
)(
  observer(({ authStore, commonStore }) => {
    useEffect(() => {
      commonStore.setPageTitle('Measure - Assessments | Cybrary');
      authStore.fireAttributionEvent();
    }, []);

    return (
      <Container className="pt-0 sm-576:px-4" md omitPadding>
        <Title title="Assessments" omitPadding wrapperClasses="my-8" classes="text-2xl lg:text-2xl" />
        <div className="flex flex-col">
          <div className="flex flex-col py-8 px-14 mb-8 text-[15px] bg-gray-100">
            <h3 className="mb-3 font-bold">How Does It Work?</h3>
            <p>
              Each assessment is made up of a collection of topics which you’ll complete one at a time. The assessment is made up of a Q&A knowledge check followed by hands-on
              practical challenges using real virtual machines and real scenarios. Upon submission, you’ll receive an overall score for the topic. At this time, you will not get a
              detailed breakdown of correct and incorrect answers. You are welcome to complete an assessment as many times as you’d like.
            </p>
            <p>
              Select a topic and hit <b>Start</b> to begin your first assessment!
            </p>
          </div>
          <BaselineOverview />
        </div>
      </Container>
    );
  })
);

export default Assessments;
