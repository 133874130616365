import React from 'react';
import ContentTransformer from '../../transformers/contentTransformer';
import BrowseUtil from '../../utils/browseUtil';
import FormatUtil from '../../utils/formatUtil';
import SearchUtil from '../../utils/searchUtil';
import AddLink from '../AddLink/AddLink';
import ContentCard from '../Card/ContentCard';
import OverflowCarousel from '../Carousel/OverflowCarousel';
import Container from '../Container/Container';
import Divider from '../Divider/Divider';
import StyledError from '../Error/StyledError';
import Loading from '../Loading/Loading';
import Pagination from '../Pagination/Pagination';
import SearchCIPPromo from '../Search/SearchCIPPromo';
import SectionBanner from './SectionBanner';

function SectionHeaderWrapper({ isViewAll, sectionNiceName, onClickViewAll, isFeaturedSection }) {
  const sectionShortDescription = BrowseUtil.getSectionDescription(sectionNiceName).short;
  const headerMargin = sectionShortDescription ? '0rem' : '2rem';
  const sectionNameId = FormatUtil.lowerCaseHyphenText(sectionNiceName);
  return (
    <div className="flex flex-col justify-between mb-4 sm:flex-row sm:items-end">
      <div>
        <h2 id={sectionNameId} className="text-2xl font-black" style={{ marginBottom: headerMargin }}>
          {sectionNiceName}
        </h2>
        {sectionShortDescription ? <div className="my-0 text-sm font-normal text-gray-600">{sectionShortDescription}</div> : null}
      </div>
      {isViewAll && !isFeaturedSection && (
        <div className="self-end text-sm font-normal sm:self-auto">
          <AddLink aria-describedby={sectionNameId} className="inline-block text-sm text-cyb-pink-500 hover:text-black underline cursor-pointer" {...onClickViewAll}>
            View all
          </AddLink>
        </div>
      )}
    </div>
  );
}

function ResultsGrid({ results, isViewAll, cipPromoData, sectionNiceName, onClickViewAll, omitDivider, isFeaturedSection, addToPathOption }) {
  return (
    <>
      <SectionHeaderWrapper isViewAll={isViewAll} sectionNiceName={sectionNiceName} onClickViewAll={onClickViewAll} isFeaturedSection={isFeaturedSection} />
      <div className="flex flex-wrap gap-8 my-4">
        {results.hits.map((hit, hitIdx) => {
          const positionIdx = hitIdx + 1;
          const prepped = ContentTransformer.formatDataSources(hit);
          const contentCard = (
            <ContentCard
              cardProps={{ menuAbove: true }}
              key={prepped.id}
              data={prepped}
              objectID={prepped.id}
              queryID={results.queryID}
              positionIdx={positionIdx}
              className="w-full sm:w-60"
              isComingSoon={prepped.comingSoon}
              addToPathOption={addToPathOption}
            />
          );

          if (!!cipPromoData && hitIdx === 2) {
            // Check hitIdx against 2 to insert SearchCIPPromo as the third item
            return (
              <>
                {contentCard}
                <SearchCIPPromo key={`search-promo-${positionIdx}`} data={cipPromoData} className="w-full sm:w-60" cardView />
              </>
            );
          }

          return contentCard;
        })}
      </div>
      {!omitDivider && isViewAll && <Divider marginTop="mt-8" marginBottom="mb-8" />}
    </>
  );
}

function ResultsList({ results, isViewAll, sectionNiceName, onClickViewAll, cipPromoData, omitDivider, isFeaturedSection, addToPathOption }) {
  return (
    <>
      <SectionHeaderWrapper isViewAll={isViewAll} sectionNiceName={sectionNiceName} onClickViewAll={onClickViewAll} isFeaturedSection={isFeaturedSection} />
      <div className="grid grid-cols-1">
        {results.hits.map((hit, hitIdx) => {
          const prepped = ContentTransformer.formatDataSources(hit);
          const positionIdx = hitIdx + 1;
          prepped.hit = hit;
          const contentCard = (
            <ContentCard
              cardProps={{ menuAbove: true }}
              key={prepped.id}
              data={prepped}
              objectID={prepped.id}
              queryID={results.queryID}
              positionIdx={positionIdx}
              className="pb-4 my-4 first:mt-0 border-b-xs border-gray-400 last:border-none"
              hasLabels
              isList
              isComingSoon={prepped.comingSoon}
              addToPathOption={addToPathOption}
            />
          );

          if (!!cipPromoData && hitIdx === 2) {
            // Check hitIdx against 2 to insert SearchCIPPromo as the third item
            return (
              <>
                {contentCard}
                <SearchCIPPromo key={`search-promo-${positionIdx}`} data={cipPromoData} />
              </>
            );
          }

          return contentCard;
        })}
      </div>
      {!omitDivider && isViewAll && <Divider marginTop="mt-0" marginBottom="mb-8" />}
    </>
  );
}

function ResultsGridWrapper({ results, isViewAll, section, cipPromoData, onClickViewAll, sectionNiceName, omitDivider, isFeaturedSection, addToPathOption, title }) {
  return isViewAll ? (
    <OverflowCarouselWrapper
      key={section}
      section={section}
      results={results}
      isViewAll={isViewAll}
      onClickViewAll={onClickViewAll}
      cipPromoData={cipPromoData}
      omitDivider={omitDivider}
      isFeaturedSection={isFeaturedSection}
      addToPathOption={addToPathOption}
      title={title}
    />
  ) : (
    <ResultsGrid
      results={results}
      isViewAll={isViewAll}
      section={section}
      cipPromoData={cipPromoData}
      sectionNiceName={sectionNiceName}
      onClickViewAll={onClickViewAll}
      omitDivider={omitDivider}
      isFeaturedSection={isFeaturedSection}
      addToPathOption={addToPathOption}
    />
  );
}

function ResultsWrapper({
  resultsView,
  results,
  isViewAll,
  section,
  cipPromoData,
  onClickViewAll,
  sectionIndex,
  sectionNiceName,
  omitDivider,
  isFeaturedSection,
  addToPathOption,
  title,
}) {
  return resultsView === 'grid' ? (
    <ResultsGridWrapper
      section={section}
      results={results}
      isViewAll={isViewAll}
      onClickViewAll={onClickViewAll}
      cipPromoData={cipPromoData}
      sectionIndex={sectionIndex}
      sectionNiceName={sectionNiceName}
      omitDivider={omitDivider}
      isFeaturedSection={isFeaturedSection}
      addToPathOption={addToPathOption}
      title={title}
    />
  ) : (
    <ResultsList
      results={results}
      isViewAll={isViewAll}
      onClickViewAll={onClickViewAll}
      section={section}
      sectionNiceName={sectionNiceName}
      cipPromoData={cipPromoData}
      omitDivider={omitDivider}
      isFeaturedSection={isFeaturedSection}
      addToPathOption={addToPathOption}
      title={title}
    />
  );
}

const getSearchCards = (results, cipPromoData, addToPathOption) => {
  return results.hits.map((hit, hitIdx) => {
    const positionIdx = hitIdx + 1;
    if (!!cipPromoData && hitIdx === 3) {
      return <SearchCIPPromo key={`search-promo-${positionIdx}`} data={cipPromoData} className="w-full sm:w-60" cardView />;
    }

    const prepped = ContentTransformer.formatDataSources(hit);
    return (
      <div key={prepped.id} className="flex h-full">
        <ContentCard
          cardProps={{ menuAbove: true }}
          key={prepped.id}
          data={prepped}
          objectID={prepped.id}
          queryID={results.queryID}
          positionIdx={positionIdx}
          className="w-full h-auto bg-white sm:w-60"
          isComingSoon={prepped.comingSoon}
          addToPathOption={addToPathOption}
        />
      </div>
    );
  });
};

function OverflowCarouselWrapper({ section, results, onClickViewAll, cipPromoData, omitDivider, isFeaturedSection, addToPathOption, title }) {
  const sectionNiceName = title || SearchUtil.getSearchSectionNiceNames(section);
  const searchCards = getSearchCards(results, cipPromoData, addToPathOption);
  const sectionShortDescription = BrowseUtil.getSectionDescription(sectionNiceName).short;
  const headerMargin = sectionShortDescription ? '0.5rem' : '2rem';
  const sectionNameId = FormatUtil.lowerCaseHyphenText(sectionNiceName);
  const breakpoints = {
    'break-1869': {
      4: 4,
      2: 2,
    },
    'break-1369': {
      4: 3,
      2: 2,
    },
    'break-869': {
      4: 2,
      2: 2,
    },
  };
  const appendageProp =
    isFeaturedSection || !onClickViewAll
      ? {}
      : {
          appendage: (
            <div className="flex justify-end items-end self-end ml-auto w-15 md-991:ml-0">
              <AddLink aria-describedby={sectionNameId} className="inline-block text-sm text-cyb-pink-500 hover:text-black underline cursor-pointer" {...onClickViewAll}>
                View all
              </AddLink>
            </div>
          ),
        };
  return (
    <OverflowCarousel
      key={sectionNameId}
      breakpoints={breakpoints}
      wrapperClasses="box-content w-full max-w-1056px"
      headingWrapperClasses="flex flex-wrap md-991:flex-nowrap items-end justify-between gap-2 mb-4"
      actionsWrapperClasses={isFeaturedSection ? 'ml-auto' : ''}
      dividerProps={{ marginTop: 'mt-8', marginBottom: 'mb-0' }}
      omitDivider={omitDivider}
      cols={4}
      isDefaultColWidth
      heading={
        <div className="=md-991:flex-1">
          <h2 id={sectionNameId} className="text-2xl font-black" style={{ marginBottom: headerMargin }}>
            {sectionNiceName}
          </h2>
          {sectionShortDescription ? <div className="my-0 text-sm font-normal text-gray-600">{sectionShortDescription}</div> : null}
        </div>
      }
      {...appendageProp}
    >
      {searchCards}
    </OverflowCarousel>
  );
}

function getSectionsToDisplay(searchParams, addToPathOption) {
  const { data, view, resultsView, changeCategoryView, currentPage, pageChange, cipPromoData, sectionBanners, handleSectionBannerDismiss, title } = searchParams;
  const sectionsToDisplay = [];
  const isViewAll = view === 'all';
  let index = 0;
  const sectionLength = Object.keys(data).filter((section) => {
    return section !== 'all' && data[section] && data[section].hits && data[section].hits.length > 0;
  }).length;
  Object.keys(data).forEach((section) => {
    const results = data[section];
    const hasResults = !!results?.hits?.length > 0;
    const isAllSection = section === 'all';
    // Omit "all" results - only used for facets. If not in the "all" view, skip over the other sections, and if no hits, skip
    if ((isViewAll || section === view) && !isAllSection && hasResults) {
      index++;
      results.sectionIndex = index;
      const isLastSection = results.sectionIndex === sectionLength;
      const sectionNiceName = SearchUtil.getSearchSectionNiceNames(section, 'browseRefined');
      const onClickViewAll = changeCategoryView ? { onClick: () => changeCategoryView(section) } : null;
      const isFeaturedSection = ['new', 'coming'].indexOf(section) !== -1;
      const isSectionBeforeFeatured = ['careerPath', 'certificationPrep', 'course', 'assessment'].indexOf(section) !== -1;
      const omitDivider = isSectionBeforeFeatured || isFeaturedSection || isLastSection;
      const wrapperClasses = isViewAll && isFeaturedSection ? 'bg-gray-100 py-8' : 'my-8';
      sectionsToDisplay.push(
        <div key={sectionNiceName} className={`px-[31px] ${wrapperClasses}`}>
          <SectionBanner isViewAll={isViewAll} sectionNiceName={sectionNiceName} sectionBanners={sectionBanners} handleSectionBannerDismiss={handleSectionBannerDismiss} />
          <ResultsWrapper
            isViewAll={isViewAll}
            resultsView={resultsView}
            results={results}
            section={section}
            cipPromoData={cipPromoData}
            onClickViewAll={onClickViewAll}
            sectionNiceName={sectionNiceName}
            omitDivider={omitDivider}
            isFeaturedSection={isFeaturedSection}
            addToPathOption={addToPathOption}
            title={title}
          />
          {isViewAll ? null : (
            <div className="flex justify-center w-full">
              <Pagination totalPages={results.nbPages} activePage={currentPage} onPageChange={pageChange} siblingRange={1} />
            </div>
          )}
        </div>
      );
    }
  });
  return sectionsToDisplay;
}

/**
 * Displays results from an algolia content query
 */
export default function SearchResults({
  loading, // loading boolean
  data, // algolia search result items to display
  error, // error object to show
  cipPromoData,
  resultsView = 'grid', // or 'list'
  changeCategoryView, // function to bind to the 'View All' button in top right of carousel
  currentPage,
  pageChange = () => null,
  view = 'all',
  sectionBanners = [],
  handleSectionBannerDismiss = () => null,
  addToPathOption,
  title,
}) {
  if (loading) {
    return (
      <Container>
        <Loading message="Loading..." wrapperClassName="mt-48" />
      </Container>
    );
  }
  if (error) {
    return (
      <Container>
        <StyledError error={error} />
      </Container>
    );
  }
  // Need to check if there are hits in at least one section
  const searchParams = { data, view, resultsView, changeCategoryView, currentPage, pageChange, cipPromoData, sectionBanners, handleSectionBannerDismiss, title };
  const sectionsToDisplay = getSectionsToDisplay(searchParams, addToPathOption);
  const errorText = view === 'all' ? 'results' : SearchUtil.getSearchSectionNiceNames(view);

  if (sectionsToDisplay?.length) {
    return <div className="w-full">{sectionsToDisplay}</div>;
  }
  return (
    <div className="px-8 my-8 text-sm">
      <p>{`Sorry, we couldn't find any ${errorText} related to that search.`}</p>
      <p>Have you tried:</p>
      <ul className="list-disc list-inside">
        <li>Checking a different tab</li>
        <li>Removing some filters</li>
        <li>A new search term</li>
      </ul>
    </div>
  );
}
