import React from 'react';
import { observer, inject } from 'mobx-react';
import moment from 'moment';
import queryString from 'query-string';
import { Navigate } from 'react-router-dom';
import withRouter from '../../components/Router/withRouter';
import Table from '../../components/Table/Table';
import AdminSendgridEvents from '../../components/CybraryAdmin/CybraryAdminSendgridEvents';
import AdminMemberDashboardHeading from '../../components/CybraryAdmin/CybraryAdminMemberDashboardHeading';
import ExpandingContentTable from '../../components/Enterprise/ExpandingContentTable';
import WorkroleModal from '../../components/Analytics/WorkroleModal';
import ContentActivityTable from '../../components/Enterprise/ContentActivityTable';
import CheckAndDisplayCerts from '../../components/Enterprise/CheckAndDisplayCerts';
import ProgressLabel from '../../components/Enterprise/ProgressLabel';
import ArchivedLabel from '../../components/ContentUpdates/ArchivedLabel';
import AddLink from '../../components/AddLink/AddLink';
import Loading from '../../components/Loading/Loading';
import FormatUtil from '../../utils/formatUtil';
import DisplayCerts from '../../components/Dashboard/DisplayCerts';
import BetaLabel from '../../components/Label/BetaLabel';
import Container from '../../components/Container/Container';
import Label from '../../components/Label/Label';

import '../Enterprise/enterprise.css';
import '../../components/Analytics/analytics.css';
import WidgetContainer from '../../components/Container/WidgetContainer';
import BadgesTab from '../../components/Profile/ProfileTabs/BadgesTab/BadgesTab';
import Header from '../../components/Header/Header';
import AdminFirebase from '../../components/CybraryAdmin/CybraryAdminFirebase';
import If from '../../components/If/If';
import AdminTeamInfo from '../../components/CybraryAdmin/CybraryAdminTeamInfo';
import AdminUserSubscription from '../../components/CybraryAdmin/CybraryAdminUserSubscription';
import StyledError from '../../components/Error/StyledError';
import CybraryAdminLogo from './CybraryAdminLogo';

const CybraryAdminMemberDashboard = inject(
  'enterpriseStore',
  'commonStore',
  'userStore',
  'authStore',
  'adminStore'
)(
  observer(
    class CybraryAdminMemberDashboard extends React.Component {
      state = {
        userId: null,
      };

      componentDidMount() {
        // Confirm that we are allowed on the admin page
        this.props.adminStore.checkAccess(this.props.authStore);

        const { id } = this.props.match.params;
        this.props.adminStore.setUserLarping(id);
        this.props.adminStore.getUserData(id);
        this.props.adminStore.getUserAssignmentData();
        this.props.adminStore.getUserGoalData(this.props.enterpriseStore.transformGoalData);
        this.props.adminStore.getUserCertificates();
        this.props.adminStore.getUserCurrentEnrollmentData();
        this.props.adminStore.getUserEnrollmentData();
        this.props.adminStore.getUserLearningData();
        const newState = {
          ...this.state,
          userId: id,
        };
        this.setState(newState);
        this.props.commonStore.hidePrimaryNav();
      }

      componentWillUnmount() {
        this.props.adminStore.setDefaultAdminMemberDashboardData();
        this.props.commonStore.showPrimaryNav();
      }

      getMemberDashboardLearning = (queryParams, filter) => {
        const params = queryParams;
        params.status = filter;
        this.props.adminStore.setDashboardQueryParams('adminMemberDashboardData', 'learning', params);
        this.props.adminStore.getUserLearningData(this.props.match.params.id);
      };

      formatTitleWithType = (parentEnrollmentId, col, row, headings) => {
        const colArr = col.value.split(' ');
        const lastWord = colArr[colArr.length - 1];
        const type = row[FormatUtil.getColIndex(headings, 'content_description_type')].value;
        colArr.pop();
        const archivedCol = row[FormatUtil.getColIndex(headings, 'content_description_archived_at')];
        const isArchived = archivedCol ? archivedCol.value : false;
        const tags = FormatUtil.getColIndex(headings, 'tags') > -1 ? row[FormatUtil.getColIndex(headings, 'tags')].value : null;

        return (
          <AddLink>
            {colArr.join(' ')}{' '}
            <span className="whitespace-nowrap">
              {lastWord}
              <Label basic className="ml-2">
                {type}
              </Label>
              {isArchived ? <ArchivedLabel className="ml-2" /> : null}
              {tags && tags.indexOf('Beta') !== -1 ? <BetaLabel className="ml-2" /> : null}
            </span>
          </AddLink>
        );
      };

      formatDate = (col) => {
        return col.value ? moment.utc(col.value).format('M/DD/YYYY') : '-';
      };

      getProgressLabel = (col, row, headings) => {
        return <ProgressLabel progressNum={col.value} completionDate={row[FormatUtil.getColIndex(headings, 'completed_at')].value} />;
      };

      checkCerts = (data, headings) => {
        if (!data || !headings) {
          return null;
        }
        const certData = {
          ...this.props.adminStore.adminMemberDashboardData.certificates,
        };
        const activityIdIdx = FormatUtil.getColIndex(headings, 'content_description_id');
        const activityId = !!data[activityIdIdx] && data[activityIdIdx].value ? data[activityIdIdx].value : null;
        const enrollmentIdIdx = FormatUtil.getColIndex(headings, 'id');
        const enrollmentId = !!data[enrollmentIdIdx] && data[enrollmentIdIdx].value ? data[enrollmentIdIdx].value : null;
        const completionDateIdx = FormatUtil.getColIndex(headings, 'completed_at');
        const completionDate = !!data[completionDateIdx] && data[completionDateIdx].value ? data[completionDateIdx].value : null;
        const typeIdx = FormatUtil.getColIndex(headings, 'content_description_type');
        const type = !!data[typeIdx] && data[typeIdx].value ? data[typeIdx].value : null;

        if (!activityId || !completionDate || !type) {
          return <Table.Cell key={activityId} />;
        }
        return (
          <Table.Cell key={activityId} className="text-center">
            <CheckAndDisplayCerts activityId={activityId} enrollmentId={enrollmentId} completionDate={completionDate} type={type} certData={certData.data} />
          </Table.Cell>
        );
      };

      getWorkroleData = (enrollmentId, scoreId) => {
        this.props.adminStore.getWorkroleData(enrollmentId, scoreId);
      };

      getWorkroleIcons = (row, headings, rowIdx, parentContentType) => {
        const { workroleData } = this.props.adminStore.adminMemberDashboardData;
        // Two different table types run through this method, both with an ID column, but different ID types (enrollment ID vs ScoreId)
        // If the parentContentType is Interview Mocha Test, we have a scoreID, NOT an enrollment ID
        const enrollmentIdIdx = ['iMocha Test', 'Interview Mocha Test'].indexOf(parentContentType) !== -1 ? -1 : FormatUtil.getColIndex(headings, 'id');
        const scoreIdIdx = ['iMocha Test', 'Interview Mocha Test'].indexOf(parentContentType) !== -1 ? FormatUtil.getColIndex(headings, 'id') : -1;
        const enrollmentId = enrollmentIdIdx > -1 ? row[enrollmentIdIdx].value : null;
        const scoreId = scoreIdIdx > -1 ? row[scoreIdIdx].value : null;
        const typeIdx = FormatUtil.getColIndex(headings, 'content_description_type');
        const type = typeIdx > -1 ? row[typeIdx].value : null;
        const completedAtIdx = FormatUtil.getColIndex(headings, 'completed_at');
        const completedAt = completedAtIdx > -1 ? row[completedAtIdx].value : null;
        const collections = ['Curriculum', 'Career Path', 'Collection'];
        const isParentCollection = collections.indexOf(parentContentType) > -1;
        if ((['iMocha Test', 'Interview Mocha Test'].indexOf(type) !== -1 && !!completedAt && !isParentCollection && !!enrollmentId) || !!scoreId) {
          return (
            <WorkroleModal
              key={`workrole-${rowIdx}`}
              enrollmentId={enrollmentId}
              scoreId={scoreId}
              loading={workroleData.loading}
              error={workroleData.error}
              data={workroleData.data}
              adminMemberDashoard
              getWorkroleData={this.getWorkroleData}
              showLevelUpMsg={1 * this.state.userId === 1 * this.props.userStore.user.id}
            />
          );
        }
        return <Table.Cell key={`workrole-${rowIdx}`} />;
      };

      assignmentExpand = (content) => {
        return this.props.adminStore.getAdminMemberDashboardAssignmentDetails(content.enrollment_id, content.content_description_id);
      };

      goalExpand = (content) => {
        // If we have a content_description_id, pass it along to get goal children from API
        // Note: For Goals, only time you won't have content_description_id is when expanding a goal top level that has a course/activity assigned and not a path
        // Content ID isn't set at top level - Is manually set for path goals in transformer in store.
        // Goals come with activities/progress for direct children already, so we show those in this case
        if (content && content.content_description_id) {
          return this.props.adminStore.getAdminMemberDashboardAssignmentDetails(content.enrollment_id, content.content_description_id);
        }

        return this.props.enterpriseStore.getDashboardGoalDetailsFromContent(content);
      };

      getAllScores = (contentDescriptionId) => {
        return this.props.adminStore.getAllScores(contentDescriptionId, { format: 'table' });
      };

      checkGoalExpansion = (goal) => {
        // If this goal has a content type, and it isn't 'none', return true. 'none' = learner decides (user picks content they want to take, nothing assigned)
        return goal.content_type && goal.content_type !== 'none';
      };

      render() {
        const { loading, denyAccess } = this.props.adminStore;
        // If we should deny access, just get out now
        if (denyAccess) {
          return <Navigate to="/" />;
        }
        if (loading) {
          return (
            <Container>
              <CybraryAdminLogo />
              <Loading message="Loading..." />
            </Container>
          );
        }

        const storeData = this.props.adminStore.adminMemberDashboardData;
        const { heading } = storeData || {};
        return (
          <Container className="organization">
            <CybraryAdminLogo />
            <AdminMemberDashboardHeading data={heading?.data} loading={heading?.loading} error={heading?.error} />
            <If condition={heading?.data?.userEmails?.length}>
              <AdminFirebase userEmails={heading?.data?.userEmails} commonStore={this.props.commonStore} />
            </If>
            <If condition={heading?.data?.teamsData?.length}>
              <AdminTeamInfo teams={heading?.data?.teamsData} />
            </If>
            <If condition={heading?.data?.licenses?.length}>
              <AdminUserSubscription data={heading?.data} />
            </If>
            <div
              ref={(stickyRef) => {
                this.stickyRef = stickyRef;
              }}
            >
              <AdminSendgridEvents events={storeData.sendgridEvents} />
              <WidgetContainer className="mt-6" omitBorder omitBackground>
                <ExpandingContentTable
                  heading="Goals"
                  data={storeData.goals}
                  hideFilters
                  progressFormat={this.getProgressLabel}
                  titleTagFormat={this.formatTitleWithType}
                  learningHoursCalc={FormatUtil.convertSecondsToHours}
                  formatDate={this.formatDate}
                  expandFunction={this.goalExpand}
                  checkCerts={this.checkCerts}
                  certData={storeData.certificates}
                  getWorkroleIcons={this.getWorkroleIcons}
                  checkShouldExpand={this.checkGoalExpansion}
                  getAllScores={this.getAllScores}
                  className="py-0"
                />
              </WidgetContainer>
              <WidgetContainer className="mt-6" omitBorder omitBackground>
                <ExpandingContentTable
                  heading="Assignments"
                  data={storeData.assignments}
                  hideFilters
                  progressFormat={this.getProgressLabel}
                  titleTagFormat={this.formatTitleWithType}
                  learningHoursCalc={FormatUtil.convertSecondsToHours}
                  formatDate={this.formatDate}
                  expandFunction={this.assignmentExpand}
                  checkCerts={this.checkCerts}
                  certData={storeData.certificates}
                  getWorkroleIcons={this.getWorkroleIcons}
                  getAllScores={this.getAllScores}
                  className="py-0"
                />
              </WidgetContainer>
            </div>
            <WidgetContainer className="mt-6" omitBorder omitBackground>
              <ContentActivityTable
                heading="Enrollments"
                headingTooltip
                loading={storeData.learning.loading}
                error={storeData.learning.error}
                data={storeData.learning.data}
                filterParams={storeData.learning.queryParams}
                progressFormat={this.getProgressLabel}
                expandFunction={this.assignmentExpand}
                titleTagFormat={this.formatTitleWithType}
                formatDate={this.formatDate}
                learningHoursCalc={FormatUtil.convertSecondsToHours}
                checkCerts={this.checkCerts}
                noResultsMsg="There are no activities for this user during this time period"
                certData={{ ...storeData.certificates }}
                getActivityTableData={this.getMemberDashboardLearning}
                linkToLearningActivityReport
                filterValue={storeData.learning.queryParams.status}
                hideFilters
                omitSort
                queryParams={queryString.parse(window.location.search)}
                getWorkroleIcons={this.getWorkroleIcons}
                getAllScores={this.getAllScores}
              />
            </WidgetContainer>
            <WidgetContainer className="mt-6" omitBorder omitBackground>
              <DisplayCerts certificates={storeData.certificates} />
            </WidgetContainer>
            <WidgetContainer className="mt-6" omitBorder omitBackground>
              <Header as="h2">Badges</Header>
              <If condition={heading?.error}>
                <StyledError error={heading?.error} />
              </If>
              <If condition={!heading?.data?.loading && !heading?.error}>
                <WidgetContainer omitBorder omitBackground>
                  {' '}
                  <BadgesTab badges={heading?.data?.badges} />
                </WidgetContainer>
              </If>
            </WidgetContainer>
          </Container>
        );
      }
    }
  )
);

export default withRouter(CybraryAdminMemberDashboard);
