import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

export default function ReportModal({ open, cancel, children }) {
  const container1 = 'inline-block overflow-hidden rounded-lg bg-neutral-800 px-4 pt-5 pb-4 text-left align-bottom';
  const container2 = 'shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-5xl sm:p-6 sm:align-middle border border-neutral-600';

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="overflow-y-auto fixed inset-0 z-10" onClose={() => null}>
        <div className="flex justify-center items-end px-4 pt-4 pb-20 min-h-screen text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-neutral-800/75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className={`${container1} ${container2}`}>
              {children}
              <div className="flex justify-end px-4 pt-4">
                <button className="px-8 h-10 text-xs text-white bg-pink-700 rounded" type="button" onClick={cancel}>
                  Close
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
