import React from 'react';
import Label from '../Label/Label';

/*
 * Show list of email domains configured for this team with a label next to any set for SSO
 */
function ConfiguredDomains({ domains }) {
  if (!domains || !domains.length) {
    return null;
  }
  return (
    <div>
      {domains.map((domain) => {
        return (
          <div key={domain.id}>
            @{domain.domain}
            {domain.is_sso ? (
              <Label className="ml-4 align-top" basic color="green">
                SSO
              </Label>
            ) : null}
            {domain.auto_join ? (
              <Label className="ml-4 align-top" basic color="blue">
                Auto Join
              </Label>
            ) : null}
          </div>
        );
      })}
    </div>
  );
}

export default ConfiguredDomains;
