import React, { useState, useEffect, useRef, useContext } from 'react';
import { useWindowWidth } from '@react-hook/window-size';
import MitreContext from '../../contexts/MitreContext';
import FormatUtil from '../../utils/formatUtil';
import Loading from '../Loading/Loading';
import Icon from '../Icon/Icon';
import Collapsible from '../Collapsible/Collapsible';
import TechniqueCard from './TechniqueCard';
import CheckboxDisplay from './CheckboxDisplay';
import SubscriptionSettings from './SubscriptionSettings';

const tacticsOrderedList = ['TA0043', 'TA0042', 'TA0001', 'TA0002', 'TA0003', 'TA0004', 'TA0005', 'TA0006', 'TA0007', 'TA0008', 'TA0009', 'TA0011', 'TA0010', 'TA0040'];

function TacticSubscribe({ data, isSelected, unsubscribe, subscribe, className }) {
  return (
    <button
      id={`tactic-${FormatUtil.lowerCaseHyphenText(data.name)}`}
      className={`${className} items-center px-2 py-3 tactic-subscribe`}
      onClick={() => (isSelected ? unsubscribe(data, true) : subscribe(data, true))}
      aria-pressed={isSelected}
    >
      <CheckboxDisplay isSelected={isSelected} />
      <span className="text-xs leading-5">
        Select All <span className="sr-only">techniques for topic: {data.name}</span>
      </span>
    </button>
  );
}

function Tactic({ data, isSelected, isFirst, subscribe, unsubscribe, isOpen, isPrevOpen, showSubscribeBoxes, toggleMobileTacticExpand, useMobileBlock }) {
  const hasAttackPatterns = data && data.attack_patterns && data.attack_patterns.length;
  const getMobileChevronClasses = () => {
    let classes = 'tactic-chevron';
    if (!isOpen && !useMobileBlock) {
      if (showSubscribeBoxes) {
        classes += ' tactic-chevron-down-slim';
      } else {
        classes += ' tactic-chevron-down';
      }
      if (!isFirst && !isPrevOpen) {
        classes += ' tactic-chevron-up pt-4';
      }
    }
    return classes;
  };

  return (
    <div className="flex sm:block">
      {showSubscribeBoxes && (
        <TacticSubscribe
          data={data}
          className="flex shrink-0 items-center my-2 mr-2 bg-gray-100 sm:my-0 sm:mr-0 sm:w-auto sm:bg-white"
          isSelected={isSelected}
          unsubscribe={unsubscribe}
          subscribe={subscribe}
        />
      )}
      {/* Mobile */}
      <button
        type="button"
        onClick={toggleMobileTacticExpand}
        className={`px-5 my-2 ${
          useMobileBlock && !hasAttackPatterns ? 'hidden' : 'flex sm:hidden'
        } w-full items-center justify-center rounded-sm text-center ${getMobileChevronClasses()}`}
      >
        <span className="sr-only">{isOpen ? 'Hide' : 'Show'} techniques for</span>
        <p className={`mb-0 text-white text-xs leading-5 font-bold ${!isFirst ? 'ml-2' : ''}`}>{data.name}</p>
        <Icon className="mx-2 w-5 h-5 text-white" name={isOpen ? 'chevron-up' : 'chevron-down'} />
      </button>
      {/* Desktop */}
      <div className={`px-5 hidden sm:flex items-center justify-center rounded-sm text-center tactic-chevron tactic-chevron-right ${!isFirst ? 'tactic-chevron-left' : ''}`}>
        <p className={`mb-0 text-white text-xs leading-5 font-bold ${!isFirst ? 'ml-2' : ''}`}>{data.name}</p>
      </div>
    </div>
  );
}

function Techniques({ tactic, subscribe, unsubscribe, techniqueSubscriptions, showSubscribeBoxes, settingsOpen, selectedTechnique }) {
  const { setTechniqueData, openTechniqueId } = useContext(MitreContext);
  const handleClick = (techniqueData, isSelected) => {
    if (showSubscribeBoxes) {
      if (isSelected) {
        unsubscribe(techniqueData);
      } else {
        subscribe(techniqueData);
      }
    }
    if (!settingsOpen) {
      setTechniqueData(techniqueData);
    }
  };

  if (!tactic.attack_patterns || !tactic.attack_patterns.length) {
    return null;
  }

  const checkSelectedTechnique = (technique) => {
    if (selectedTechnique && selectedTechnique.id && selectedTechnique.id === technique.id) {
      selectedTechnique.get(technique);
    }
  };

  return tactic.attack_patterns.map((technique) => {
    const techniqueIsSelected = techniqueSubscriptions.indexOf(technique.id) > -1;
    const isTechniqueOpen = openTechniqueId && openTechniqueId === technique.id;
    const buttonId = `${FormatUtil.lowerCaseHyphenText(tactic.name)}_${FormatUtil.lowerCaseHyphenText(technique.name)}`;
    const isButtonDisabled = settingsOpen && !showSubscribeBoxes;
    checkSelectedTechnique(technique);
    return (
      <TechniqueCard
        key={technique.id}
        wrapperClasses="my-2 w-full"
        data={technique}
        buttonId={buttonId}
        handleClick={(fullButtonId) => handleClick({ ...technique, buttonId: fullButtonId }, techniqueIsSelected)}
        isSelected={techniqueIsSelected}
        isTechniqueOpen={isTechniqueOpen}
        showSubscribeBoxes={showSubscribeBoxes}
        isButtonDisabled={isButtonDisabled}
      />
    );
  });
}

function TacticTechniqueWrapper({
  tactic,
  subscribe,
  unsubscribe,
  tacticSubscriptions,
  techniqueSubscriptions,
  isFirst,
  toggleMobileTacticExpand,
  mobileTacticsOpen,
  idx,
  showSubscribeBoxes,
  settingsOpen,
  isMobile,
  selectedTechnique,
  useMobileBlock,
}) {
  const isSelected = tacticSubscriptions.indexOf(tactic.id) > -1;
  const isOpen = mobileTacticsOpen.indexOf(idx) > -1;
  const isPrevOpen = mobileTacticsOpen.indexOf(idx - 1) > -1; // When prev tactic is open, this one displays slightly different
  return (
    <>
      <Tactic
        data={tactic}
        subscribe={subscribe}
        unsubscribe={unsubscribe}
        isFirst={isFirst}
        isSelected={isSelected}
        isOpen={isOpen}
        isPrevOpen={isPrevOpen}
        showSubscribeBoxes={showSubscribeBoxes}
        toggleMobileTacticExpand={() => toggleMobileTacticExpand(idx)}
        useMobileBlock={useMobileBlock}
      />
      <Collapsible open={!isMobile || isOpen}>
        <Techniques
          tactic={tactic}
          tacticSelected={isSelected}
          subscribe={subscribe}
          unsubscribe={unsubscribe}
          techniqueSubscriptions={techniqueSubscriptions}
          showSubscribeBoxes={showSubscribeBoxes}
          settingsOpen={settingsOpen}
          selectedTechnique={selectedTechnique}
        />
      </Collapsible>
    </>
  );
}

function ContentFadeBlock({ showLeft, showRight }) {
  const commonClasses = 'hidden sm:block h-full absolute z-10 transition-all duration-300 transform';
  return (
    <>
      <div
        className={`${commonClasses} -left-2 ${showLeft ? 'w-12' : 'w-0'}`}
        style={{ background: `linear-gradient(270deg, rgb(255 255 255 / 0%) 0%, rgb(255 255 255 / 100%) 95%)` }}
      />
      <div
        className={`${commonClasses} right-0 ${showRight ? 'w-12' : 'w-0'}`}
        style={{ background: `linear-gradient(90deg, rgb(255 255 255 / 0%) 0%, rgb(255 255 255 / 100%) 95%)` }}
      />
    </>
  );
}

function CatalogMatrix({
  mitreData,
  mitreCourses,
  disableSubscription,
  subscribe,
  unsubscribe,
  tacticSubscriptions,
  techniqueSubscriptions,
  savedTechniqueSubscriptions,
  saveSubscriptions,
  savedSubscriptionsType,
  techniquesCount,
  selectedTechnique,
  useMobileBlock,
}) {
  const { openTechniqueId } = useContext(MitreContext);
  const [isFullLeft, setIsFullLeft] = useState(true);
  const [isFullRight, setIsFullRight] = useState(false);
  const [mobileTacticsOpen, setMobileTacticsOpen] = useState([]);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [subscriptionsType, setSubscriptionsType] = useState(null);
  const [windowWidth, setWindowWidth] = useState(true);
  const scrollContainer = useRef();
  const showSubscribeBoxes = settingsOpen && subscriptionsType === 'some';

  useEffect(() => {
    if (!subscriptionsType && savedSubscriptionsType) {
      setSubscriptionsType(savedSubscriptionsType);
    }
  }, [savedSubscriptionsType]);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, [useWindowWidth({ wait: 200 })]);

  if (!mitreData || !mitreCourses) {
    return <Loading message="Loading..." />;
  }

  const onScroll = () => {
    // Determine if we are full left or full right on matrix scroll to add fade out styling
    if (scrollContainer && scrollContainer.current) {
      const fullLeft = scrollContainer.current.scrollLeft === 0;
      const fullRight = scrollContainer.current.clientWidth + scrollContainer.current.scrollLeft === scrollContainer.current.scrollWidth;
      if (isFullLeft !== fullLeft) {
        setIsFullLeft(fullLeft);
      }
      if (isFullRight !== fullRight) {
        setIsFullRight(fullRight);
      }
    }
  };

  const scrollContentContainer = (direction) => {
    const scrollOffset = scrollContainer.current.clientWidth;
    if (direction === 'right') {
      scrollContainer.current.scrollLeft += scrollOffset;
    } else {
      scrollContainer.current.scrollLeft -= scrollOffset;
    }
  };

  const toggleMobileTacticExpand = (idx) => {
    const arrCopy = [...mobileTacticsOpen];
    const tacticsIdx = arrCopy.indexOf(idx);
    if (tacticsIdx > -1) {
      arrCopy.splice(tacticsIdx, 1);
    } else {
      arrCopy.push(idx);
    }
    setMobileTacticsOpen(arrCopy);
  };
  return (
    <div className="relative">
      <ContentFadeBlock showLeft={!isFullLeft} showRight={!isFullRight} />
      <div className="relative mb-8 sm:flex sm:overflow-x-auto sm:-ml-2" ref={scrollContainer} onScroll={onScroll} style={{ scrollBehavior: 'smooth' }}>
        {tacticsOrderedList.map((tacticKey, idx) => {
          const tactic = mitreData[tacticKey];
          if (!tactic) {
            return null;
          }
          return (
            <div key={tacticKey} className="sm:shrink-0 sm:px-2 sm:w-34">
              <TacticTechniqueWrapper
                settingsOpen={settingsOpen}
                showSubscribeBoxes={showSubscribeBoxes}
                tactic={tactic}
                subscribe={subscribe}
                unsubscribe={unsubscribe}
                tacticSubscriptions={tacticSubscriptions}
                techniqueSubscriptions={techniqueSubscriptions}
                isFirst={idx === 0}
                mobileTacticsOpen={mobileTacticsOpen}
                toggleMobileTacticExpand={toggleMobileTacticExpand}
                idx={idx}
                isMobile={windowWidth <= 640}
                selectedTechnique={selectedTechnique}
                useMobileBlock={useMobileBlock}
              />
            </div>
          );
        })}
      </div>
      <div className="flex sticky bottom-0 z-20 justify-center items-center -ml-4 w-screen sm:bottom-4 sm:justify-between sm:px-2 sm:w-auto lg:px-8">
        <button
          type="button"
          onClick={() => scrollContentContainer('left')}
          className="hidden left-0 p-2 h-10 bg-white rounded-full disabled:opacity-50 disabled:cursor-not-allowed sm:block box-shadow"
          aria-label="Scroll content left"
          disabled={isFullLeft}
        >
          <Icon name="chevron-left" />
        </button>
        {!disableSubscription && (
          <SubscriptionSettings
            settingsOpen={settingsOpen}
            setSettingsOpen={setSettingsOpen}
            setSubscriptionsType={setSubscriptionsType}
            showSubscribeBoxes={showSubscribeBoxes}
            saveSubscriptions={saveSubscriptions}
            techniquesCount={techniquesCount}
            windowWidth={windowWidth}
            savedSubscriptionsType={savedSubscriptionsType}
            subscriptionsType={subscriptionsType}
            savedTechniqueSubscriptions={savedTechniqueSubscriptions}
            forceStackSections={!!openTechniqueId}
          />
        )}
        <button
          type="button"
          onClick={() => scrollContentContainer('right')}
          className="hidden left-full p-2 mr-20 h-10 bg-white rounded-full disabled:opacity-50 disabled:cursor-not-allowed sm:block xl-1850:mr-0 box-shadow"
          aria-label="Scroll content right"
          disabled={isFullRight}
        >
          <Icon name="chevron-right" />
        </button>
      </div>
    </div>
  );
}

export default CatalogMatrix;
