import React, { useMemo } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import WidgetContainer from '../../Container/WidgetContainer';
import ProgressBar from '../../ProgressBar/ProgressBar';
import Header from '../../Header/Header';
import LoadingText from '../../Loading/LoadingText';
import If from '../../If/If';
import CustomBarChartTooltip from './shared/CustomBarChartTooltip';
import { CustomBarChartTickCompact, CustomBarChartBottomTickCompact, COMPACT_GRID_Y_VALUES } from './shared/CustomBarChartTick';

const PROGRESS_LABEL_MAPPING = {
  baselining_pre_assessment: 'Pre-Assessment Progress',
  baselining_remediation: 'Remediation Progress',
  baselining_post_assessment: 'Post-Assessment Progress',
};

function GoalProgressWidget({ goals, isLoading }) {
  return (
    <WidgetContainer className="flex flex-col px-0 w-3/4" omitBorder omitBackground>
      <If condition={isLoading}>
        {Array.from(Array(3).keys()).map((item) => (
          <div className="mb-6" key={item}>
            <LoadingText wrapperClassName="w-48 h-6 p-0 mb-3" className="h-5 rounded-none" />
            <LoadingText wrapperClassName="w-full h-5 p-0" className="h-5 rounded-full" />
          </div>
        ))}
      </If>
      <If condition={!isLoading && Boolean(goals)}>
        {goals?.map((goal) => {
          const percentage = Math.round(parseFloat(goal.progress.percent_completed || 0));

          return (
            <div className="mb-6" key={goal.id}>
              <Header as="h4" className="mb-3">
                {PROGRESS_LABEL_MAPPING[goal.outcome_type]}
              </Header>
              <ProgressBar
                progress={percentage}
                progressClassName={`${percentage === 0 ? 'bg-cyb-gray-500 border-cyb-gray-500' : 'bg-cyb-blue-500 border-cyb-blue-500'} h-5 rounded-full text-white`}
                wrapperClassName={`${percentage === 0 ? 'bg-gray-200 border-cyb-gray-500' : 'bg-blue-200 border-cyb-blue-500/25'} h-5 rounded-full`}
                showProgressLabel
              />
            </div>
          );
        })}
      </If>
    </WidgetContainer>
  );
}

function GoalResultsGraphWidget({ topicScores }) {
  const barChartData = useMemo(
    () =>
      topicScores
        ?.map((entry) => ({
          topic: entry.topic,
          order: entry.topic_position,
          avgPreAssessmentScore: Math.round(parseFloat(entry.avg_pre_assessment_score)),
          avgPreAssessmentScoreColor: '#ff00ff',
          avgPostAssessmentScore: Math.round(parseFloat(entry.avg_post_assessment_score)),
          avgPostAssessmentScoreColor: '#25136A',
        }))
        ?.sort((a, b) => a.order - b.order) || [],
    [topicScores]
  );

  return (
    <WidgetContainer className="flex flex-col px-0" omitBorder omitBackground>
      <div className="w-full h-80">
        <ResponsiveBar
          data={barChartData}
          keys={['avgPreAssessmentScore', 'avgPostAssessmentScore']}
          indexBy="topic"
          margin={{ top: 10, right: 0, bottom: barChartData?.length >= 5 ? 90 : 80, left: 70 }}
          padding={0.3}
          groupMode="grouped"
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          colors={({ id, data }) => String(data[`${id}Color`])}
          borderColor={{
            from: 'color',
            modifiers: [['darker', 1.6]],
          }}
          maxValue={100}
          gridYValues={COMPACT_GRID_Y_VALUES}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Topic',
            legendPosition: 'middle',
            legendOffset: barChartData?.length >= 5 ? 75 : 65,
            renderTick: (tick) => CustomBarChartBottomTickCompact(tick, barChartData?.length),
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Score',
            legendPosition: 'middle',
            legendOffset: -60,
            truncateTickAt: 0,
            renderTick: CustomBarChartTickCompact,
          }}
          label={(d) => `${d.value}%`}
          labelSkipWidth={10}
          labelSkipHeight={10}
          labelTextColor={(datum) => {
            if (datum.data.id === 'avgPostAssessmentScore') {
              return '#ffffff';
            }
            return '#000000';
          }}
          role="application"
          ariaLabel="Results graph chart"
          barAriaLabel={(e) => `${e.id}: ${e.formattedValue} in topic: ${e.indexValue}`}
          tooltip={CustomBarChartTooltip}
        />
      </div>
    </WidgetContainer>
  );
}

function TeamBaselineGoalWidget({ goalDetails, isLoading }) {
  return (
    <WidgetContainer className="flex flex-row items-center" omitBorder omitPadding omitBackground>
      <GoalProgressWidget isLoading={isLoading} goals={goalDetails?.goals} />
      <div className="mr-4 ml-5 w-1 h-80 bg-gray-300" />
      <GoalResultsGraphWidget topicScores={goalDetails?.topic_scores} />
    </WidgetContainer>
  );
}

export default TeamBaselineGoalWidget;
