import React from 'react';
import ContentOverlay from '../ContentOverlay/ContentOverlay';

function FiltersSidebar({ mobileSidebar, toggleMobileSidebar, children }) {
  return (
    <ContentOverlay open={mobileSidebar} dismiss={() => toggleMobileSidebar(!mobileSidebar)} width="w-[80vw]" ariaLabelledBy="Filters" omitPadding omitDismissX>
      {children}
      <div className="sticky inset-x-0 bottom-0 z-10 p-4 w-full h-[72px] bg-white border-t-xs border-gray-300">
        <button
          type="button"
          onClick={() => toggleMobileSidebar(!mobileSidebar)}
          aria-label="Toggle Sidebar Overlay"
          className="py-2 px-6 w-full text-sm font-semibold text-center text-white bg-cyb-pink-500 rounded-sm"
        >
          Done
        </button>
      </div>
    </ContentOverlay>
  );
}

export default FiltersSidebar;
