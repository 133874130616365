import React from 'react';
import { Navigate } from 'react-router-dom';
import AuthUtil from '../utils/authUtil';

// This is our local dev middleware.  It will check any conditions that need to be satisfied before a local route can be rendered

/**
 * Middleware to check if we are in a dev environment, and if not, redirect to the home page
 * If allowBWB is true, we will allow the route to be rendered on BWB (Staging) as well
 * @param {boolean} allowBWB - (Optional. Default=false) Allow the route to render on BWB (Staging)
 * @param {object} children - The children to be rendered if we are in a dev environment
 * @param {boolean} isSandboxEnabled - (Optional. Default=false) Allow the route to render if the sandbox is enabled
 * @returns
 */
function LocalRoute({ children, allowBWB = false, isSandboxEnabled = false }) {
  // Either returns the desired route (aka children), OR a redirect to the home page!
  if (!AuthUtil.isDevEnvironment()) {
    // We are attempting to load a LocalRoute in a non-dev environment
    // See if we are allowed to be here on BWB (Staging) and if that is the environment we are in
    if (allowBWB && AuthUtil.isBWBEnvironment()) {
      // We are on BWB and we are allowed to be here
      return children;
    }
    // We are not on BWB, check if we have an enabled sandbox flag
    if (isSandboxEnabled) {
      return children;
    }
    // We are not on BWB, and/or we are not allowed to be here on BWB
    return <Navigate to={{ pathname: '/' }} />;
  }
  return children;
}
export default LocalRoute;
