import React from 'react';

const TOSForm = {
  order: ['subscribe', 'tos', 'submit'],
  fields: {
    subscribe: {
      type: 'boolean',
      label: 'I want to receive new courses, feature updates, news, and discounts from Cybrary',
    },
    tos: {
      type: 'boolean',
      label: 'By creating an account, you agree to the Terms of Service and Privacy Policy',
      description: (
        <span>
          View{' '}
          <a href={`${process.env.REACT_APP_V2_SITE_URL}/terms-service/`} target="_blank" rel="noreferrer" className="underline">
            Terms of Service
          </a>{' '}
          and{' '}
          <a href={`${process.env.REACT_APP_V2_SITE_URL}/privacy-policy/`} target="_blank" rel="noreferrer" className="underline">
            Privacy Policy
          </a>
        </span>
      ),
      validations: ['required'],
    },
    submit: {
      type: 'button',
      color: 'green',
      label: 'Continue',
    },
  },
};

export default TOSForm;
