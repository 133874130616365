import React from 'react';
import ReactMarkdown from 'react-markdown';
import './details.css';

function Details({ isBrowseHeader, classes, content, header, headerStyle, markdown }) {
  const headerClass = isBrowseHeader ? 'text-gray-400' : 'text-gray-600';
  const contentClasses = classes || '';
  if (content) {
    return (
      <>
        {header ? (
          <div className={`details-header ${headerClass}`} style={{ ...headerStyle }}>
            {header}
          </div>
        ) : null}
        <div className={`details-content ${contentClasses}`}>{markdown ? <ReactMarkdown className="has-markdown-inside">{content}</ReactMarkdown> : content}</div>
      </>
    );
  }
  return null;
}

export default Details;
