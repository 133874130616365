import React, { useMemo, useState, useCallback } from 'react';
import { observer, inject } from 'mobx-react';
import AdminTable from '../../AdminTable/AdminTable';
import { useCybMeasure } from '../../../providers/CybMeasureProvider';
import { formatTableColumns } from '../../Baseline/shared';
import WidgetContainer from '../../Container/WidgetContainer';
import Header from '../../Header/Header';
import If from '../../If/If';
import Loading from '../../Loading/Loading';
import Button from '../../Button/Button';
import AddLink from '../../AddLink/AddLink';
import ContentOverlay from '../../ContentOverlay/ContentOverlay';
import TeamsGoalsWizard from '../../Goals/TeamsGoalsWizard';
import GoalDetails from '../../Goals/GoalDetails';
import BugsnagUtil from '../../../utils/bugsnagUtil';
import ActionUtil from '../../../utils/actionsUtil';
import { TEAMS_DASHBOARD_ACCESS_ROLES } from '../../../constants';

const CONTENT_ID_KEY = 'content-id';

const GoalsTableWidget = inject(
  'userStore',
  'goalsStore',
  'commonStore',
  'teamHierarchyStore'
)(
  observer(({ selectedContentDescription, userStore, goalsStore, commonStore, teamHierarchyStore }) => {
    const orgId = userStore?.teamData?.id;

    const team = userStore?.team;

    const {
      teamBaselineGoals,
      isLoadingTeamBaselineGoals,
      createBaselineGoal,
      isProcessingGoalCreation,
      updateBaselineGoal,
      isProcessingGoalUpdate,
      deleteBaselineGoal,
      isProcessingGoalDeletion,
    } = useCybMeasure();

    const contentDescriptionId = useMemo(() => selectedContentDescription?.[CONTENT_ID_KEY] ?? selectedContentDescription?.id, [selectedContentDescription]);

    const isLoading = useMemo(() => {
      return isLoadingTeamBaselineGoals[`${orgId}_${contentDescriptionId}`] || isProcessingGoalDeletion;
    }, [isLoadingTeamBaselineGoals, isProcessingGoalDeletion, contentDescriptionId]);

    const baselineGoals = useMemo(() => {
      if (!contentDescriptionId) {
        return null;
      }

      return teamBaselineGoals[`${orgId}_${contentDescriptionId}`];
    }, [orgId, contentDescriptionId, teamBaselineGoals]);

    const renderColumn = useCallback(
      (row, fullRow, _headings, column) => {
        if (row.value !== undefined && column.showCol) {
          const percentage = Math.round(parseFloat(row.value));

          if (row.value === null) {
            return <i>incomplete</i>;
          }

          if (column.key === 'baseline_goal_name') {
            const goalId = fullRow[0].value;
            const href = `${window.location.pathname}/goal/${goalId}`;

            return (
              <AddLink className="text-cyb-pink-500 underline" to={href} onClick={() => ActionUtil.scrollToTop('instant')}>
                {row.value}
              </AddLink>
            );
          }

          return `${percentage}%`;
        }

        return '';
      },
      [window.location.pathname]
    );

    const formatColumns = useMemo(
      () =>
        formatTableColumns(
          baselineGoals?.baselining_goals?.columns,
          ['baseline_goal_name', 'pre_assessment_progress', 'remediation_progress', 'post_assessment_progress'],
          renderColumn
        ),
      [renderColumn, baselineGoals]
    );

    const tableData = useMemo(() => baselineGoals?.baselining_goals?.tableData, [baselineGoals]);

    const ignoredCols = useMemo(() => {
      const value = [];

      if (tableData) {
        // get the totalsRow, which is the last row
        const totalsRow = tableData[tableData.length - 1];
        // find the index of the `highlighted_row` entry for that row
        const highlightedRowEntryIndex = totalsRow?.findIndex((item) => item?.highlighted_row);

        if (highlightedRowEntryIndex >= 0) {
          value.push(highlightedRowEntryIndex);
        }
      }
      return value;
    }, [tableData]);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [modalContext, setModalContext] = useState(null);

    // id of the sub-baseline goal (pre_assessment, post_assessment, etc.), this is used
    // to pass into <GoalDetails /> component as it is not compatible with top-level baseline goal ids -
    // this sub-baseline goal contains the same data as it's parent baseline goal
    const [stagedGoalId, setStagedGoalId] = useState(null);

    // id of the top-level baseline goal, used to pass into the api update call when editing a baseline goal
    const [stagedBaselineGoalId, setStagedBaselineGoalId] = useState(null);

    const getTeamHierarchy = useCallback(async () => {
      try {
        await teamHierarchyStore?.getTeamHierarchy(team);

        // Get admins for all groups user has permission to manage. Add those to the owners list
        goalsStore.getGoalOwnersData(orgId, team?.permissions?.canManageTeam, teamHierarchyStore?.trees[team.id].data);

        teamHierarchyStore?.setHierarchySelectOptions(team?.id, null, null, null, true);
      } catch (error) {
        BugsnagUtil.notify(error);
      }
    }, [teamHierarchyStore?.trees, team?.permissions?.canManageTeam, orgId]);

    const setGoalStoreData = useCallback(() => {
      const contentObject = {
        metaLabels: ['Cybrary'],
        tabGroup: 'Cybrary',
        text: selectedContentDescription?.['content-name'] ?? selectedContentDescription?.title,
        type: selectedContentDescription?.['content-type'] ?? selectedContentDescription?.content_type?.id,
        value: contentDescriptionId,
      };
      goalsStore.setGoalData('team', 'outcome_type', 'team_baseline');
      goalsStore.setGoalData('team', 'due_type', 'none');
      goalsStore.setGoalData('team', 'outcome_type', 'team_baseline');
      goalsStore.setGoalData('team', 'content_ids', [contentDescriptionId]);
      goalsStore.setGoalData('team', 'content_type', 'assessment_path');
      goalsStore.setGoalData('team', 'contents', [contentObject]);
      goalsStore.getGoalOwnersData(orgId, team?.permissions?.canManageTeam, []);
      goalsStore.getGoalParticipantsData(orgId);
      getTeamHierarchy();
    }, [orgId, team?.permissions?.canManageTeam, selectedContentDescription, contentDescriptionId, getTeamHierarchy]);

    const onClickAddBaselineGoal = useCallback(() => {
      setGoalStoreData();
      setModalContext('add');
      setIsModalOpen(true);
    }, [setGoalStoreData]);

    const onCancelConfirm = useCallback(() => {
      commonStore.triggerConfirm({
        content: 'Are you sure you want to cancel? Your baseline goal will not be saved',
        cancel: () => commonStore.resetConfirmState(),
        confirmBtn: 'Continue',
        continue: () => {
          commonStore.resetConfirmState();
          setIsModalOpen(false);
          goalsStore.resetAddEditGoalPrestine();
        },
      });
    }, []);

    const onCreateGoal = useCallback(async () => {
      try {
        const payload = {
          name: goalsStore?.goalData?.data?.name,
          owner_ids: goalsStore?.goalData?.data?.owner_ids,
          participant_ids: goalsStore?.goalData?.data?.participant_ids,
          participant_type: goalsStore?.goalData?.data?.participant_type,
          content_description_id: goalsStore?.goalData?.data?.content_ids[0],
          start_date: goalsStore?.goalData?.data?.start_date,
          due_interval: goalsStore?.goalData?.data?.due_interval,
          description: goalsStore?.goalData?.data?.description,
          metric: goalsStore?.goalData?.data?.metric,
          certification_test_id: goalsStore?.goalData?.data?.certification_test_id,
          owners: goalsStore?.goalData?.data?.owners,
          certification_test: goalsStore?.goalData?.data?.certification_test,
          participants: goalsStore?.goalData?.data?.participants,
          include_admins: goalsStore?.goalData?.data?.include_admins,
          baselining_pre_assesssment: [],
          baselining_post_assessment: [],
          baselining_remmediation: [],
        };

        await createBaselineGoal(orgId, contentDescriptionId, payload, goalsStore?.getUserGoalsList);

        commonStore.resetConfirmState();
        setIsModalOpen(false);
        goalsStore.resetAddEditGoalPrestine();
      } catch (error) {
        BugsnagUtil.notify(error);
      }
    }, [orgId, contentDescriptionId, goalsStore?.goalData?.data, goalsStore?.getUserGoalsList, createBaselineGoal]);

    const onDeleteGoal = useCallback(
      (baselineGoalId) => {
        try {
          deleteBaselineGoal(orgId, contentDescriptionId, baselineGoalId);
        } catch (error) {
          BugsnagUtil.notify(error);
        }
      },
      [orgId, contentDescriptionId, deleteBaselineGoal]
    );

    const onSaveEdit = useCallback(async () => {
      try {
        const payload = {
          id: stagedBaselineGoalId,
          name: goalsStore?.goalData?.data?.name,
          owner_ids: goalsStore?.goalData?.data?.owner_ids,
          participant_ids: goalsStore?.goalData?.data?.participant_ids,
          participant_type: goalsStore?.goalData?.data?.participant_type,
          content_description_id: goalsStore?.goalData?.data?.content_ids[0],
          description: goalsStore?.goalData?.data?.description,
          include_admins: goalsStore?.goalData?.data?.include_admins,
        };

        await updateBaselineGoal(orgId, contentDescriptionId, stagedBaselineGoalId, payload, goalsStore?.getUserGoalsList);

        commonStore.resetConfirmState();
        setIsModalOpen(false);
        setModalContext(null);
        setStagedGoalId(null);
        stagedBaselineGoalId(null);
      } catch (error) {
        BugsnagUtil.notify(error);
      }
    }, [orgId, contentDescriptionId, goalsStore?.goalData?.data, stagedBaselineGoalId, updateBaselineGoal, goalsStore?.getUserGoalsList]);

    const canAddGoal = useMemo(() => TEAMS_DASHBOARD_ACCESS_ROLES.includes(userStore?.teamData?.role), [userStore?.teamData?.role, TEAMS_DASHBOARD_ACCESS_ROLES]);

    const rowOptions = useMemo(() => {
      if (canAddGoal) {
        return [
          {
            text: 'Edit',
            icon: 'edit outline',
            action: (goalData) => {
              const targetGoalName = goalData[2].value;
              const targetGoalPathId = goalData[1].value;
              const targetSubGoal = goalsStore?.userGoalsList?.data.find((goal) => {
                // sub goals have a pattern of `{goal_name} - {sub goal context}` > Pen Testing Goal - Post-Assessment,
                // so we just grab the goal name
                const normalizedGoalName = goal.name.split(' -')[0];

                return normalizedGoalName === targetGoalName && goal.content_ids[0] === targetGoalPathId;
              });

              if (targetSubGoal) {
                setModalContext('edit');
                setStagedGoalId(targetSubGoal.id);
                setStagedBaselineGoalId(goalData[0].value);
                setIsModalOpen(true);
              }
            },
          },
          {
            text: 'Delete',
            icon: 'trash alternate outline',
            action: (goalData) => {
              commonStore.triggerConfirm({
                content: 'Are you sure you want to delete this baseline goal? This action cannot be undone and all goal progress will be lost.',
                cancel: () => commonStore.resetConfirmState(),
                confirmBtn: 'Continue',
                continue: () => {
                  onDeleteGoal(goalData[0].value);

                  commonStore.resetConfirmState();
                },
              });
            },
          },
        ];
      }

      return [];
    }, [canAddGoal, goalsStore?.userGoalsList, onDeleteGoal]);

    return (
      <>
        <WidgetContainer className="flex flex-col pt-0" omitBorder>
          <div className="flex flex-row justify-between items-start">
            <div>
              <Header as="h3" className="mb-3">
                Goals
              </Header>
              <div className="flex flex-row items-center mb-6">
                <p className="mb-0">What baselining goals have I assigned to my team?</p>
                <If condition={isLoading}>
                  <Loading wrapperClassName="py-0 ml-3" className="w-4 h-4 border-2" />
                </If>
              </div>
            </div>
            <If condition={canAddGoal}>
              <Button icon={{ name: 'plus' }} className="py-2 font-semibold" onClick={onClickAddBaselineGoal}>
                Add Baselining Goal
              </Button>
            </If>
          </div>
          <AdminTable
            wrapperClassName="m-0 w-full"
            colClasses="whitespace-nowrap"
            headings={baselineGoals?.baselining_goals?.columns}
            data={tableData}
            formatColumns={formatColumns}
            ignoredCols={ignoredCols}
            rowOptions={rowOptions}
          />
        </WidgetContainer>
        <ContentOverlay
          open={isModalOpen}
          dismiss={onCancelConfirm}
          width={modalContext === 'add' ? 'w-[70vw]' : 'w-[40vw]'}
          maxWidth="max-w-5xl"
          ariaLabelledBy={`${modalContext}-goal`}
          omitPadding
        >
          <If condition={modalContext === 'add'}>
            <div className="py-4">
              <div className="px-8 mb-9">
                <Header as="h1" className="mb-4">
                  Create Baselining Goal
                </Header>
                <p className="hidden text-sm text-gray-600 md:block">
                  Goals help you track your team&apos;s learning and the on-the-job outcomes you care about.
                  <br />
                  Follow this step-by-step guide to create a baselining goal for your team.
                </p>
              </div>
              <If condition={Boolean(team)}>
                <div className="relative">
                  <If condition={isProcessingGoalCreation}>
                    <div className="flex absolute inset-0 z-20 justify-center items-center bg-white/90">
                      <Loading message="Creating Goal..." wrapperClassName="pb-40" />
                    </div>
                  </If>
                  <TeamsGoalsWizard team={team} teamGroupId={team?.goalsTeamGroupId} onSaveGoal={onCreateGoal} />
                </div>
              </If>
            </div>
          </If>
          <If condition={modalContext === 'edit'}>
            <div className="relative">
              <If condition={isProcessingGoalUpdate}>
                <div className="flex absolute inset-0 z-20 justify-center items-center bg-white/90">
                  <Loading message="Updating Goal..." wrapperClassName="pb-40" />
                </div>
              </If>
              <GoalDetails
                goalId={stagedGoalId}
                progressTeamGroupId={orgId}
                editTeamGroupId={orgId}
                teamId={orgId}
                onSaveEdit={onSaveEdit}
                editView
                editCancel={onCancelConfirm}
                filterGroupsByPermissions={(group) => group.permissions.manage === 'edit'}
                teamAdminView
              />
            </div>
          </If>
        </ContentOverlay>
      </>
    );
  })
);

export default GoalsTableWidget;
