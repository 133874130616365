import React, { useMemo } from 'react';
import TeamOnboardingStep from '../../TeamOnboardingStep';
import { TEAM_ONBOARDING_STEPS, TEAM_ONBOARDING_OPTIONS, useTeamOnboarding } from '../../../../providers/TeamOnboardingProvider';
import RadioButtons from '../../../FormFields/RadioButtons';

function SelectLearningApproach() {
  const { teamLearningApproach, setTeamLearningApproach, setStep } = useTeamOnboarding();

  const options = useMemo(() => {
    return Object.keys(TEAM_ONBOARDING_OPTIONS.teamLearningApproach).map((key) => {
      const approach = TEAM_ONBOARDING_OPTIONS.teamLearningApproach[key];
      return {
        key: approach?.key,
        text: approach?.text,
        value: approach?.key,
      };
    });
  }, []);

  const handleChange = (e, { value }) => {
    setTeamLearningApproach(value);
    setStep(TEAM_ONBOARDING_STEPS.FINISHED);
  };

  return (
    <TeamOnboardingStep
      title="Question 3/3:"
      subtitle="What is your Team's approach to learning?"
      nextStep={TEAM_ONBOARDING_STEPS.SELECT_ROLE}
      prevStep={TEAM_ONBOARDING_STEPS.SELECT_GOALS}
      isNextStepDisabled={!teamLearningApproach || teamLearningApproach === ''}
      nextButtonText="Finish"
    >
      <RadioButtons options={options} onChange={handleChange} fieldsPerRow={1} inputClassName="my-6" labelClassName="text-black" value={teamLearningApproach} />
    </TeamOnboardingStep>
  );
}

export default SelectLearningApproach;
