import React from 'react';
import { observer, inject } from 'mobx-react';
import AddLink from '../AddLink/AddLink';

const ComingSoonButton = inject('userStore')(
  observer(({ userStore, contentDescriptionId, title, instructionsTextClass = 'text-sm text-white' }) => {
    const handleClick = () => {
      userStore.toggleBookmark(contentDescriptionId, title);
    };
    const hasBookmark = Array.isArray(userStore.bookmarks) ? userStore.bookmarks.indexOf(contentDescriptionId) !== -1 : false;
    const buttonCN =
      'focus:outline-none cursor-pointer rounded-sm bg-cyb-pink-500 text-white font-bold text-center hover:text-white hover:bg-pink-600 leading-5 py-2.5 px-6 text-sm';
    return (
      <div className="flex-1">
        <div className="mb-4">
          {hasBookmark ? (
            <AddLink
              to="/bookmarks"
              className="inline-block py-2.5 px-6 text-sm font-bold leading-5 text-center text-white hover:text-white bg-cyb-pink-500 hover:bg-pink-600 rounded-sm cursor-pointer"
            >
              Added to Saved Items
            </AddLink>
          ) : (
            <button type="button" onClick={() => handleClick()} className={buttonCN}>
              Send Me Updates
            </button>
          )}
        </div>
        <p className={instructionsTextClass}>
          {!hasBookmark && 'Click the button above to add this content to your Saved Items list.'} When this content goes live, we will send you a reminder notification.
        </p>
      </div>
    );
  })
);

export default ComingSoonButton;
