import React, { Component } from 'react';
import { action } from 'mobx';
import { observer, inject } from 'mobx-react';
import Bugsnag from '@bugsnag/js';
import withRouter from '../../components/Router/withRouter';
import Loading from '../../components/Loading/Loading';
import StyledError from '../../components/Error/StyledError';
import SeriesDisplay from '../../components/Live/SeriesDisplay';
import EnrollmentTransformer from '../../transformers/enrollmentTransformer';
import './live.css';

/**
 * The Cybrary Live Series page view
 * @returns {*}
 * @constructor
 */
const LiveSeries = inject(
  'authStore',
  'commonStore',
  'userStore',
  'catalogStore',
  'enrollmentStore'
)(
  observer(
    class LiveSeries extends Component {
      componentDidMount() {
        this.props.authStore.fireAttributionEvent();
        this.props.commonStore.setPageTitle('Live | Cybrary');
        this.loadSeries();
      }

      componentDidUpdate(prevProps) {
        if (this.props.match.params.permalink !== prevProps.match.params.permalink) {
          this.loadSeries();
        }
      }

      componentWillUnmount() {
        this.props.catalogStore.reset();
      }

      loadSeries = () => {
        if (this.props.match.params.permalink) {
          const realPermalink = `cybrary-live/series/${this.props.match.params.permalink}`;
          // Fetch the series, using it's realPermalink
          this.props.catalogStore.getContentDescriptionByPermalink(realPermalink);
        }
      };

      handleClickSessionLink = () => {
        const seriesId = this.props.catalogStore && this.props.catalogStore.item && this.props.catalogStore.item.id ? this.props.catalogStore.item.id : null;
        const contentTypeName =
          this.props.catalogStore && this.props.catalogStore.item && this.props.catalogStore.item.content_type ? this.props.catalogStore.item.content_type.nice_name : null;
        this.props.enrollmentStore
          .startEnrollment(seriesId, null, contentTypeName)
          .then(
            action('fetchSuccess', (response) => {
              const enrollment = EnrollmentTransformer.transformEnrollmentData([response.enrollment])[0];
              this.props.navigate(`/immersive/${enrollment.id}/item/${this.props.catalogStore.item.id}`, { replace: true });
            })
          )
          .catch(
            action('fetchError', (error) => {
              Bugsnag.notify(error);
            })
          );
      };

      render() {
        const data = this.props.catalogStore.item;
        // Determine if we are loading, based on the item being present, loading in store, and content type of item
        const type = 'Cybrary Live Series';
        const loading = this.props.catalogStore.loading || !data || !data.content_type || data.content_type.nice_name !== type;

        if (loading) {
          return <Loading message="Loading..." />;
        }
        if (this.props.catalogStore.error) {
          return <StyledError error={this.props.catalogStore.error} />;
        }
        const series = this.props.catalogStore.formatSeriesData(data);
        const {
          id,
          title,
          short_description,
          long_description,
          sessions,
          instructors_info,
          // image,
        } = series;

        return (
          <div className="cybrary-live-series">
            <SeriesDisplay
              id={id}
              instructors={instructors_info}
              sessions={sessions}
              title={title}
              series={series}
              description={long_description || short_description}
              handleClickSessionLink={this.handleClickSessionLink}
            />
          </div>
        );
      }
    }
  )
);

export default withRouter(LiveSeries);
