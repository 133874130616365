import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { AttentionSeeker, Fade } from 'react-awesome-reveal';
import { twMerge } from 'tailwind-merge';
import UpgradeButton from '../UpgradeButton/UpgradeButton';
import Icon from '../Icon/Icon';
import ProgressBar from '../ProgressBar/ProgressBar';
import If from '../If/If';

const UserStatsHeader = inject('userStore')(
  observer(({ userStore, wrapperClasses = '', hideXpIfShowingUpgrade = false }) => {
    const { userProfileStats, xpChanges, xpCurrentLevelProgress, isFree, isUserOnActiveTeam } = userStore;
    const { xpProgressPercent, xpNeededToLevelUp } = xpCurrentLevelProgress;

    const { experience_points: xp, level } = userProfileStats || {};

    useEffect(() => {
      // init profile stats loading if needed.
      if (!userProfileStats) {
        userStore.loadUserProfileStats();
      }
    }, []);

    // clear xpChanges after 5 seconds
    useEffect(() => {
      if (xpChanges.xp > 0 || xpChanges.level > 0) {
        setTimeout(() => {
          userStore.resetXpChanges();
        }, 3000);
      }
    }, [xpChanges]);

    if (!userProfileStats) return null;

    const nextLevel = parseInt(level, 10) + 1;
    const showUpgradeButton = isFree && !isUserOnActiveTeam;
    // Show the XP message "n XP to Level y" if we are not showing the upgrade button, or we don't care to hide it based on prop
    const showXpMessage = !showUpgradeButton || !hideXpIfShowingUpgrade;

    return (
      <div className={twMerge('flex justify-center lg:justify-end items-center pr-8 w-full space-x-6', wrapperClasses)}>
        <Fade delay={750}>
          {/** Level */}
          <div className="inline justify-evenly items-center">
            <div className="flex justify-between items-center mr-4 mb-2 font-medium whitespace-nowrap">
              <Icon name="star" className="p-0.5 mr-2 w-5 h-5 text-black rounded-full border-2 border-black" />
              Level {level}
            </div>
            {/** Progress */}
            <ProgressBar progress={xpProgressPercent} wrapperClassName="w-24" />
          </div>

          <If condition={showXpMessage}>
            {/** XP */}
            <div className="min-w-[115px]">
              <div className="flex w-full text-sm font-semibold text-green-600 whitespace-nowrap">
                XP {xp}
                <div className="flex-1">
                  <If condition={xpChanges.xp > 0}>
                    <AttentionSeeker triggerOnce duration={1000}>
                      <Fade duration={300}>
                        <div className="text-right">+{xpChanges.xp}</div>
                      </Fade>
                    </AttentionSeeker>
                  </If>
                </div>
              </div>

              {/** XP To Level */}
              <div className="pt-2 text-sm leading-3 text-slate-500">
                {xpNeededToLevelUp} XP to Level {nextLevel}
              </div>
            </div>
          </If>
        </Fade>
        {/** Upgrade Button */}
        <If condition={showUpgradeButton}>
          <div className="mr-0 ml-4">
            <UpgradeButton upgradeText="Upgrade" upgradeLinkParams="?immersivenav=btn" />
          </div>
        </If>
      </div>
    );
  })
);

export default UserStatsHeader;
