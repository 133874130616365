import React, { useState, useEffect } from 'react';

import Bugsnag from '@bugsnag/js';
import { inject, observer } from 'mobx-react';
import Button from '../Button/Button';
import Agents from '../../agents/agents';

export default inject('commonStore')(
  observer(({ commonStore, contentTypeId }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isSubscribed, setIsSubscribed] = useState(false);
    const subscribeText = isSubscribed ? 'Unsubscribe' : 'Subscribe';

    const getSubscription = async () => {
      setIsLoading(true);
      try {
        const contentTypes = await Agents.catalog.contentTypeSubscription();
        contentTypes.forEach((contentType) => {
          if (contentType.id === contentTypeId) {
            setIsSubscribed(true);
          }
        });
        setError(null);
      } catch (err) {
        setError(err);
      }
      setIsLoading(false);
    };

    useEffect(() => {
      getSubscription();
    }, []);

    useEffect(() => {
      if (error) {
        Bugsnag.notify(error);
        commonStore.triggerToast('error', {
          content: `An error has occured while subscribing, please try again later!`,
        });
      }
    }, [error]);

    const toggleSubscription = async () => {
      setIsLoading(true);
      try {
        if (isSubscribed) {
          await Agents.catalog.unsubscribeToContentType(contentTypeId);
        } else {
          await Agents.catalog.subscribeToContentType(contentTypeId);
        }
        commonStore.triggerToast('success', {
          content: `Successfully ${subscribeText}d!`,
        });
        setIsSubscribed(!isSubscribed);
      } catch (err) {
        setError(err);
      }
      setIsLoading(false);
    };
    return (
      <Button onClick={toggleSubscription} type="button" color="gray" loading={isLoading} disabled={!!error}>
        {error ? 'Error' : subscribeText}
      </Button>
    );
  })
);
