import React, { useCallback } from 'react';
import { observer, inject } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import Container from '../Container/Container';
import AdminTable from '../AdminTable/AdminTable';
import CertificationAdditionalContent from '../Certifications/CertificationAdditionalContent';
import { useCareerPrograms } from '../../providers/CareerProgramsProvider';
import { formatTableColumns } from '../Baseline/shared';
import EnrollmentUtil from '../../utils/enrollmentUtil';
import Button from '../Button/Button';
import If from '../If/If';
import { useBadgeModal } from '../../providers/BadgeModalProvider';
import Badge from '../Profile/ProfileTabs/BadgesTab/Badge';
import { useProfileContext } from '../../providers/ProfileProvider';
import Loading from '../Loading/Loading';
import AddLink from '../AddLink/AddLink';
import PermalinkUtil from '../../utils/permalinkUtil';

/**
 * Computed column headers for career program path table
 * @param {boolean} isAssessmentPaths - denotes that paths are assessment paths
 * @returns TABLE_COLUMN_HEADERS
 */
const TABLE_COLUMN_HEADERS = ({ isAssessmentPaths = false, hasTopics = false }) => [
  {
    key: 'path_id',
    type: 'string',
    showCol: false,
    sortKey: 'path_id',
  },
  {
    key: 'content_id',
    type: 'string',
    showCol: false,
    sortKey: 'content_id',
  },
  {
    key: 'title',
    type: 'string',
    showCol: true,
    display: 'Title',
    sortKey: 'title',
  },
  {
    key: 'topic',
    type: 'string',
    showCol: hasTopics,
    display: 'Topic',
    sortKey: 'topic',
  },
  // We only want to show the domains columns if we don't have topics
  {
    key: 'domain',
    type: 'string',
    showCol: !hasTopics,
    display: 'Domain',
    sortKey: 'domain',
  },
  {
    key: 'activity_type',
    type: 'string',
    showCol: true,
    display: 'Activity Type',
    sortKey: 'activity_type',
  },
  {
    key: 'xp',
    type: 'string',
    showCol: true,
    display: 'XP',
    sortKey: 'xp',
  },
  {
    key: 'score',
    type: 'string',
    showCol: isAssessmentPaths,
    display: 'Score',
    sortKey: 'score',
  },
  {
    key: 'progress',
    type: 'string',
    showCol: !isAssessmentPaths,
    display: 'Progress',
    sortKey: 'progress',
  },
  {
    key: 'action',
    type: 'string',
    showCol: true,
    display: 'Action',
    sortKey: 'action',
  },
  {
    key: 'content_link',
    type: 'string',
    showCol: false,
    sortKey: 'content_link',
  },
];

/**
 * Transform path content data to compliant format for table data
 *
 * @param {CareerProgramPaths} paths
 * @param {CareerProgramEnrollment} currentProgramEnrollment - current career program enrollment
 * @param {boolean} isAssessmentPaths - denotes that paths are assessment paths
 * @param {object} enrollmentProgressMap - enrollment progress map from enrollmentStore
 * @returns {tableData} { columns: [], tableData: [][] }
 */
const generateTableData = ({ mappedPaths, currentProgramEnrollment, isAssessmentPaths, enrollmentProgressMap }) => {
  const [tableData] = mappedPaths.map((path) => {
    const pathEnrollment = currentProgramEnrollment?.enrollments?.find((enrollment) => enrollment.content_description_id === path.id);
    return path.content_item?.children?.map((content) => {
      const contentEnrollment = pathEnrollment?.descendant_enrollments?.find((item) => item.content_description_id === content.id) || enrollmentProgressMap?.[`id_${content.id}`];

      let topic;
      const topicTerm = content?.terms_info?.find((entry) => entry.includes('Topics|'));
      if (topicTerm) {
        const split = topicTerm.split('|');
        const value = split[1];
        topic = value;
      }

      let domain;
      const domainTerm = content?.terms_info?.find((entry) => entry.includes('Domains|'));
      if (domainTerm) {
        const split = domainTerm.split('|');
        const value = split[1];
        domain = value;
      }

      const xpEarned = Math.min(contentEnrollment?.experience_points_earned || 0, content.experience_points_total);
      const xpProgress = Math.round((xpEarned / content.experience_points_total) * 100);
      const xpEarnedTotal = `${xpEarned}/${content.experience_points_total}`;
      // Use progress from enrollment if available, otherwise calculate from xp
      let progressLabel = `${contentEnrollment?.progress || xpProgress}%`;
      if (contentEnrollment?.completed_at) {
        progressLabel = '100%';
      }

      const score = Math.round(contentEnrollment?.score || 0 * 100);
      const scoreLabel = score ? `${score}%` : '-';

      return [
        // path_id
        {
          value: path.id,
          type: 'string',
        },
        // content_id
        {
          value: content.id,
          type: 'string',
        },
        // title
        {
          value: content.title,
          type: 'string',
        },
        // topic
        {
          value: isAssessmentPaths ? content.title : topic,
          type: 'string',
        },
        // domain
        {
          value: domain,
          type: 'string',
        },
        // activity_type
        {
          value: content.content_type?.nice_name,
          type: 'string',
        },
        // xp
        {
          value: xpEarnedTotal,
          type: 'string',
        },
        // score
        {
          value: scoreLabel,
          type: 'string',
        },
        // progress
        {
          value: progressLabel,
          type: 'string',
        },
        // content_link
        {
          value: content.permalink,
          type: 'string',
        },
      ];
    });
  });

  // We only want to show the domains columns if we have topics
  const hasTopics = tableData?.some((row) => row[3].value);

  return {
    columns: TABLE_COLUMN_HEADERS({ isAssessmentPaths, hasTopics }),
    tableData,
  };
};

const OutlineTab = inject(
  'userStore',
  'enrollmentStore',
  'commonStore'
)(
  observer(({ userStore, enrollmentStore, commonStore }) => {
    const navigate = useNavigate();

    const { isLoadingCareerProgramBrowseData, currentProgramEnrollment, currentPageProgram: program, isLoadingCurrentProgram } = useCareerPrograms();
    const { badges, isLoading: isLoadingBadgeData } = useProfileContext();
    const isLoadingPaths = !program?.permalink || isLoadingCareerProgramBrowseData?.[program.permalink];

    const paths = program?.content_item?.children;

    const relatedContent = program?.content_item?.related;

    const { open: openBadgeModal } = useBadgeModal();

    const onClickStart = useCallback(
      async (item, preferredEnrollmentId, excludeProgress = false) => {
        const options = preferredEnrollmentId ? { preferredEnrollmentId } : null;
        const data = excludeProgress ? { exclude_progress_before: 'now' } : null;
        return EnrollmentUtil.handleStartEnroll(userStore, enrollmentStore, commonStore, navigate, item, data, options);
      },
      [navigate]
    );

    const renderUserActionButton = useCallback(
      (pathId, contentId) => {
        const path = paths.find((item) => item.id === pathId);
        const content = path?.content_item?.children?.find((contentItem) => contentItem.id === contentId);
        if (!content) return null;
        const pathEnrollment = currentProgramEnrollment?.enrollments?.find((enrollment) => enrollment.content_description_id === pathId);

        const isComingSoon = content.status === 'Coming Soon';
        const isStarted = pathEnrollment?.started_content_description_ids?.includes(content?.id);
        const isFinished = pathEnrollment?.completed_content_description_ids?.includes(content?.id);
        const isAssessment = content?.content_type?.nice_name === 'Assessment';
        const staticButtonClasses = 'px-1 py-1 text-xs font-normal rounded-md min-w-[80px] pr-2 mx-auto';
        const contentEnrollmentId = pathEnrollment?.descendant_enrollments?.find((item) => item.content_description_id === content?.id)?.id;

        if (isFinished) {
          return (
            <Button
              color="gray"
              className={staticButtonClasses}
              onClick={() => onClickStart(content, contentEnrollmentId, isAssessment)}
              icon={{ name: 'refresh', position: 'left', className: 'w-4 h-4 ml-1 mr-1' }}
            >
              {isAssessment ? 'Re-take' : 'Revisit'}
            </Button>
          );
        }

        if (isStarted) {
          return (
            <Button className={staticButtonClasses} onClick={() => onClickStart(content, contentEnrollmentId)}>
              Continue
            </Button>
          );
        }

        if (isComingSoon) {
          return (
            <Button color="dark-gray" className={staticButtonClasses} disabled icon={{ name: 'clock', position: 'center', className: 'w-4 h-4 mr-1' }}>
              Coming Soon
            </Button>
          );
        }

        if (userStore?.isFree && !content?.is_free) {
          return (
            <Button
              color="dark-gray"
              className={staticButtonClasses}
              icon={{ name: 'lock', position: 'center', className: 'w-4 h-4 mx-0.5' }}
              title="Upgrade to enroll"
              href={`${process.env.REACT_APP_V2_SITE_URL}/upgrade/`}
              target="_blank"
            >
              Upgrade
            </Button>
          );
        }

        if ((userStore?.isFree && content?.is_free) || userStore?.isCip || userStore?.isUserOnActiveTeam) {
          return (
            <Button className={staticButtonClasses} onClick={() => onClickStart(content)}>
              Start
            </Button>
          );
        }

        return null;
      },
      [paths, currentProgramEnrollment, userStore?.is_free, userStore?.isCip, userStore?.isUserOnActiveTeam, onClickStart]
    );

    const renderColumn = useCallback(
      (row, fullRow, _headings, column) => {
        if (row.value !== undefined && column.showCol) {
          const permalink = fullRow?.[9]?.value;
          const type = fullRow?.[5]?.value;
          const contentId = fullRow?.[1]?.value;
          const pathId = fullRow?.[0]?.value;
          switch (column.key) {
            case 'action':
              return renderUserActionButton(pathId, contentId);
            case 'title':
              if (permalink && type) {
                return (
                  <AddLink to={PermalinkUtil.formatInApp(permalink, type)} title={`View ${row.value}`}>
                    {row.value}
                  </AddLink>
                );
              }
              return row.value;
            case 'topic':
              return (
                <AddLink
                  to={`/browse/refined?terms_info=topics%7C${encodeURIComponent(String(row.value).toLowerCase().replaceAll(' ', '-'))}`}
                  key={row.value}
                  title={`Explore more ${row.value} content`}
                >
                  {row.value}
                </AddLink>
              );
            case 'domain':
              return (
                <AddLink
                  to={`/browse/refined?terms_info=domains%7C${encodeURIComponent(String(row.value).toLowerCase().replaceAll(' ', '-'))}`}
                  key={row.value}
                  title={`Explore more ${row.value} content`}
                >
                  {row.value}
                </AddLink>
              );
            default:
              return row.value;
          }
        }
        return '';
      },
      [renderUserActionButton]
    );

    const formatColumns = useCallback(
      (isAssessmentPath) => formatTableColumns(TABLE_COLUMN_HEADERS(isAssessmentPath), ['xp', 'score', 'progress', 'action', 'title', 'topic', 'domain'], renderColumn),
      [renderColumn]
    );

    return (
      <Container size="fluid" className="flex flex-col flex-1 pt-6 w-full" omitPadding>
        <If condition={isLoadingPaths || isLoadingBadgeData || isLoadingCurrentProgram}>
          <div className="flex flex-col justify-center items-center w-full h-96">
            <Loading />
          </div>
        </If>
        <If condition={Boolean(program?.content_item?.requirement_badge_ids?.length && !isLoadingPaths && !isLoadingBadgeData && !isLoadingCurrentProgram)}>
          {/** Loop requirement_badge_ids because its guaranteed to be in order from the backend */}
          {badges?.length &&
            program?.content_item?.requirement_badge_ids?.map((badgeId) => {
              const badge = badges?.find((b) => b.id === badgeId);
              const mappedPaths = paths?.filter((path) => path?.badge_ids?.includes(badge?.id));
              const pathRequiredBadges = mappedPaths?.flatMap((path) => path?.badge_ids);

              // determine if these are assessment paths, we only need to check the first path
              const isAssessmentPaths = mappedPaths?.[0]?.content_type.human_readable_name === 'Assessment Path';
              const tableData = mappedPaths
                ? generateTableData({
                    mappedPaths,
                    currentProgramEnrollment,
                    isAssessmentPaths,
                    enrollmentProgressMap: enrollmentStore.enrollmentProgressMap,
                  })
                : { columns: TABLE_COLUMN_HEADERS(), tableData: [] };

              const columns = formatColumns(isAssessmentPaths);
              return (
                <div className="flex flex-col mb-12" key={badge.id}>
                  <div className="flex justify-between">
                    {/** Title & Description */}
                    <div className="flex flex-col pb-3">
                      <h3 className="text-xl font-black">{badge?.study_guide_title}</h3>
                      <p className="py-2 text-sm text-neutral-500">{badge?.outline_description || badge?.study_guide_description}</p>
                    </div>
                    {/** Path Badges */}
                    <If condition={pathRequiredBadges?.length}>
                      <div className="flex space-x-2">
                        {pathRequiredBadges.map((pathRequiredBadgeId) => {
                          const pathRequiredBadge = badges?.find((b) => b.id === pathRequiredBadgeId);
                          if (!pathRequiredBadge) return null;
                          return (
                            <Badge
                              badge={pathRequiredBadge}
                              key={pathRequiredBadge?.id}
                              className="flex items-center h-auto lg:h-auto"
                              imgClassName="w-12 h-12"
                              onClick={() => openBadgeModal(pathRequiredBadge)}
                              noBorder
                              noTitle
                              noDescription
                            >
                              <img src={pathRequiredBadge.unlocked_image_url} alt={pathRequiredBadge.name} className="mr-2 w-8 h-8" />
                              <span className="text-sm font-semibold sr-only">{pathRequiredBadge.name}</span>
                            </Badge>
                          );
                        })}
                      </div>
                    </If>
                  </div>
                  {/** Table */}
                  <div className="flex">
                    <AdminTable
                      wrapperClassName="m-0 w-full"
                      className="table-fixed"
                      headings={tableData.columns}
                      data={tableData.tableData}
                      tableLoading={isLoadingPaths}
                      formatColumns={columns}
                      colWidths={[
                        { width: '25%', idx: 2 }, // Title
                        { width: '20%', idx: 3 }, // Topic
                        { width: '20%', idx: 4 }, // Domain (Only shown if no Topics)
                        { width: '10%', idx: 5 }, // Activity Type
                        { width: '7%', idx: 6 }, // XP
                        { width: '7%', idx: 7 }, // Score
                        { width: '7%', idx: 8 }, // Progress
                        { width: '10%', idx: 9 }, // Action
                      ]}
                      customColAlign={{ center: [6, 7, 8, 9] }}
                      accordionMinRows={5}
                    />
                  </div>
                </div>
              );
            })}
        </If>
        <If condition={Boolean(relatedContent?.length && !isLoadingPaths && !isLoadingCurrentProgram)}>
          <div className="mt-4">
            <h3 className="text-2xl font-black text-center">Keep advancing your career with related content!</h3>
            <div className="flex flex-row justify-center items-center mt-16">
              <CertificationAdditionalContent content={relatedContent} inline omitHeader />
            </div>
          </div>
        </If>
      </Container>
    );
  })
);

export default OutlineTab;
