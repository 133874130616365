import React from 'react';
import If from '../If/If';
import SearchIcon from '../../Icons/SearchIcon';

export default function SearchInput({
  handleFocus,
  handleBlur,
  onChange,
  onKeyPress,
  placeholder,
  width,
  disabled,
  ariaLabel,
  visible,
  isSearchCatalog,
  onClose,
  value,
  inputClasses = '',
  isClearable,
}) {
  const inputClassNames = `border-0 bg-gray-200 outline-none focus:outline-none focus:border-0 text-gray-600 placeholder-gray-600 p-0 ${inputClasses}`;
  const inputAriaLabel = `${ariaLabel || 'Search Cybrary'}, press enter to submit`;
  const showClearButton = (isSearchCatalog && visible) || (value !== '' && isClearable);
  return (
    <div className={`bg-gray-200 px-4 py-2 rounded-full flex ${width || ''}`}>
      <div className="shrink">
        <SearchIcon classes="w-6 h-6 text-gray-600" />
      </div>
      <div className="grow pl-4">
        <input
          type="text"
          disabled={disabled}
          name="sitesearch"
          placeholder={placeholder || 'Search Cybrary'}
          className={inputClassNames}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={onChange}
          onKeyPress={onKeyPress}
          aria-label={inputAriaLabel}
          autoComplete="off"
          value={value}
        />
      </div>
      <If condition={showClearButton}>
        <button aria-label="Clear search input and collapse search results menu" className="text-gray-600 cursor-pointer" onClick={() => onClose()}>
          &times;
        </button>
      </If>
    </div>
  );
}
