import React, { useState, useEffect } from 'react';
import MenuContent from './MenuContent';

function getFixedClasses(isSidebar, v2Fixed) {
  if (v2Fixed) {
    return 'fixed md:w-[548px] w-full';
  }
  return isSidebar ? 'fixed top-16 w-4/5' : 'fixed top-0 w-full border-r';
}

function TabsMenu({ tabs, outline, isSidebar, v2Fixed = false, noTopMargin = false, centerTabs = false, tasks = null }) {
  const [activeTabIdx, setActiveTabIdx] = useState(0);
  const [activeTab, setActiveTab] = useState({});

  if (!tabs || !tabs.length) {
    return null;
  }

  useEffect(() => {
    setActiveTab(tabs[activeTabIdx]);
  }, []);

  function changeTabsKeyboard(e, tab) {
    const key = e.which || e.keyCode;
    const tabIdx = tabs.indexOf(tab);
    const tempTabsIdx = [];

    // handle entering letter to navigate to tab
    tabs.forEach((value, idx) => {
      if (value.label.toLowerCase().charAt(0) === e.key) {
        tempTabsIdx.push(idx);
        setActiveTabIdx(tempTabsIdx[0]);
        document.getElementById(tabs[tempTabsIdx[0]].id).focus();
      }
    });

    // enter and spacebar key
    if (key === 13 || key === 32) {
      setActiveTab(tabs[activeTabIdx]);
    }

    // end key
    if (key === 35) {
      setActiveTabIdx(tabs.length - 1);
      document.getElementById(tabs[tabs.length - 1].id).focus();
    }

    // home key
    if (key === 36) {
      setActiveTabIdx(0);
      document.getElementById(tabs[0].id).focus();
    }

    // left arrow key
    if (key === 37) {
      if (tabIdx > 0) {
        setActiveTabIdx(tabIdx - 1);
        document.getElementById(tabs[tabIdx - 1].id).focus();
      }
    }

    // right arrow key
    if (key === 39) {
      if (tabIdx < tabs.length - 1) {
        setActiveTabIdx(tabIdx + 1);
        document.getElementById(tabs[tabIdx + 1].id).focus();
      }
    }
  }

  function changeTabsClick(idx) {
    setActiveTabIdx(idx);
    setActiveTab(tabs[idx]);
  }

  const alignmentClasses = centerTabs ? 'justify-center' : 'pl-3 pr-5';
  const tabBorderClasses = centerTabs ? 'px-5' : '';
  const menuContentWrapperClasses = v2Fixed ? 'pt-16' : '';

  return (
    <>
      <ul
        role="tablist"
        aria-label="Additional Resources"
        className={`h-16 flex items-center bg-white flex-row border-b-xs overflow-hidden border-gray-400 z-10 ${alignmentClasses} ${getFixedClasses(isSidebar, v2Fixed)} `}
      >
        {tabs.map((tab, idx) => {
          const isActive = activeTabIdx === idx;
          const tabClass = isActive
            ? 'text-black font-semibold border-cyb-pink-500'
            : 'text-gray-600 border-transparent hover:border-cyb-pink-500 hover:font-semibold hover:text-black';
          return (
            <li key={tab.id} className="px-4 bg-white">
              <button
                role="tab"
                aria-selected={isActive}
                id={tab.id}
                aria-controls={`${tab.id}-tab`}
                className={`border-b-4 pb-4 mt-5 ${tabClass} ${tabBorderClasses}`}
                onClick={() => changeTabsClick(idx)}
                onKeyDown={(e) => changeTabsKeyboard(e, tab)}
                tabIndex={isActive ? '0' : '-1'}
              >
                {tab.label}
              </button>
            </li>
          );
        })}
      </ul>
      <div className={menuContentWrapperClasses}>
        <MenuContent content={activeTab} outline={outline} isSidebar={isSidebar} noTopMargin={noTopMargin} tasks={tasks} />
      </div>
    </>
  );
}

export default TabsMenu;
