import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Navigate } from 'react-router-dom';
import moment from 'moment';
import withRouter from '../../components/Router/withRouter';
import DynamicForm from '../../components/DynamicForm/DynamicForm';
import AdminTable from '../../components/AdminTable/AdminTable';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import Button from '../../components/Button/Button';
import Segment from '../../components/Segment/Segment';
import Agents from '../../agents/agents';
import FormatUtil from '../../utils/formatUtil';
import Container from '../../components/Container/Container';
import './admin.css';
import CybraryAdminLogo from './CybraryAdminLogo';

const CybraryAdminBrowserVendorResults = inject(
  'commonStore',
  'adminStore',
  'authStore'
)(
  observer(
    class CybraryAdminBrowserVendorResults extends Component {
      state = { searchData: { userId: null }, canSync: false, syncing: false, submitted: false, syncError: null };

      componentDidMount() {
        // Confirm that we are allowed on the admin page
        this.props.adminStore.checkAccess(this.props.authStore);
        this.props.commonStore.hidePrimaryNav();
      }

      componentWillUnmount() {
        this.props.adminStore.setDefaultVendorResultsTableData();
      }

      getBreadCrumbs = () => {
        const crumbs = [];
        crumbs.push(
          {
            href: '/admin',
            label: 'Admin',
          },
          {
            href: '/admin/vendor-results',
            label: 'Vendor Results',
          }
        );
        return crumbs;
      };

      formatCompletionStatus = (data) => {
        const completed = data.value;
        return completed ? <span className="font-bold text-green-500">Completed</span> : <span className="font-bold text-red-300-500">Incomplete</span>;
      };

      formatDuration = (data) => {
        const durationSeconds = data.value;
        return FormatUtil.formatTime(durationSeconds, 'hma');
      };

      vendorResultsForm = () => {
        return {
          order: [['userId', 'vendor', 'date'], 'submit'],
          fields: {
            userId: {
              type: 'text',
              label: 'UID',
              placeholder: 'Search by UID',
              defaultValue: this.props.match.params.id || '',
              validations: ['required'],
            },
            vendor: {
              type: 'select',
              label: 'Vendor Type',
              placeholder: 'Search Vendor',
              options: [
                {
                  label: 'Cybrscore',
                  value: '12',
                },
                {
                  label: 'Cybervista',
                  value: '14',
                },
                {
                  label: 'Practice Labs - Labs',
                  value: '11',
                },
                {
                  label: 'Practice Labs - Exam',
                  value: '18',
                },
                {
                  label: 'Skillable',
                  value: '22',
                },
                {
                  label: 'iMocha',
                  value: '21',
                },
              ],
              validations: ['required'],
            },
            date: {
              type: 'datePicker',
              label: 'Date',
              defaultValue: moment().format('YYYY-MM-DD'),
              validations: ['required'],
            },
            submit: {
              type: 'button',
              color: 'pink',
              label: 'Search',
            },
          },
        };
      };

      // if any form field is updated after a submit, disable sync.
      formValueChange = () => {
        if (this.state.canSync) {
          const updatedState = {
            ...this.state,
            canSync: false,
          };
          this.setState(updatedState);
        }
      };

      handleSubmit = (data) => {
        if (data) {
          const formData = {
            userId: data.userId,
            startAt: moment(data.date).subtract(15, 'days').format('YYYY-MM-DD hh:mm:ss').toString(),
            endAt: moment(data.date).add(15, 'days').format('YYYY-MM-DD hh:mm:ss').toString(),
            contentTypeId: data.vendor,
          };

          this.props.adminStore.getVendorResultsTableData(formData);
          const updatedState = {
            ...this.state,
            submitted: true,
            canSync: true,
            searchData: formData,
          };
          this.setState(updatedState);
        }
      };

      syncResults = () => {
        let updatedState = {
          ...this.state,
          syncing: true,
        };
        this.setState(updatedState);
        const syncData = { ...this.state.searchData };
        Agents.admin
          .syncVendorResults(syncData.userId, {
            start_at: syncData.startAt,
            end_at: syncData.endAt,
            content_type_id: syncData.contentTypeId,
          })
          .then(() => {
            updatedState = {
              ...this.state,
              canSync: false,
              syncing: false,
            };
            this.setState(updatedState);
            this.props.commonStore.triggerToast('success', {
              heading: 'Success!',
              content: 'Sync completed successfully',
              color: 'green',
              icon: 'thumbs up outline',
            });
            this.props.adminStore.getVendorResultsTableData(this.state.searchData);
          })
          .catch((error) => {
            updatedState = {
              ...this.state,
              syncing: false,
              error,
            };
            this.setState(updatedState);
            this.props.commonStore.triggerToast('error', {
              content: 'Something went wrong. Please try again.',
            });
          });
      };

      render() {
        const { denyAccess } = this.props.adminStore;

        // If we should deny access, just get out now
        if (denyAccess) {
          return <Navigate to="/" />;
        }
        return (
          <Container className="admin-add-members">
            <CybraryAdminLogo />
            <Segment className="border-none">
              <Breadcrumbs crumbs={this.getBreadCrumbs()} />
              <div className="flex flex-col justify-between pb-4 md:flex-row md:items-center">
                <h3 className="mb-2 text-xl font-bold">Search For Vendor Results</h3>
                <div>
                  <Button
                    loading={this.state.syncing}
                    loadingProps={{ message: 'Syncing...', messageClassName: 'ml-2', wrapperClassName: 'flex-row' }}
                    disabled={!this.state.canSync || this.state.syncing}
                    onClick={this.syncResults}
                  >
                    Sync Results
                  </Button>
                  {this.state.canSync && <p className="text-sm text-gray-700">Syncing results may take 1-2 mins to complete</p>}
                </div>
              </div>
              <DynamicForm handleOnChange={this.formValueChange} form={this.vendorResultsForm()} formName="vendorResuktsForm" onSubmit={this.handleSubmit} />
              <AdminTable
                /* Table Data */
                headings={this.props.adminStore.vendorResultsTableData.tableHeadings}
                data={this.props.adminStore.vendorResultsTableData.tableData}
                tableLoading={this.props.adminStore.vendorResultsTableData.loading}
                tableError={this.props.adminStore.vendorResultsTableData.error}
                displayCheckBox={false}
                sortCol={this.props.adminStore.vendorResultsTableData.queryParams.sortCol}
                sortDirection={this.props.adminStore.vendorResultsTableData.queryParams.sortDirection}
                formatColumns={[
                  {
                    method: this.formatCompletionStatus,
                    colIdx: FormatUtil.getColIndex(this.props.adminStore.vendorResultsTableData.tableHeadings, 'completion_status'),
                  },
                  {
                    method: this.formatDuration,
                    colIdx: FormatUtil.getColIndex(this.props.adminStore.vendorResultsTableData.tableHeadings, 'duration_seconds'),
                  },
                ]}
              />
            </Segment>
          </Container>
        );
      }
    }
  )
);

export default withRouter(CybraryAdminBrowserVendorResults);
