import React from 'react';

export default function WorkingDev({ classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';
  return (
    <svg className={className} viewBox="0 0 183 143" fill="none" xmlns="http://www.w3.org/2000/svg" {...accessibilityProps}>
      <g clipPath="url(#clip0_953_3104)">
        <path
          d="M151.845 61.7393C151.845 61.7393 147.566 64.1199 148.798 66.8305C150.03 69.541 167.52 67.3233 
        167.52 67.3233C167.52 67.3233 166.288 63.6271 164.071 62.8879C161.854 62.1486 151.845 61.7393 151.845 
        61.7393Z"
        />
        <path
          d="M150.177 62.3737C151.037 61.8145 151.993 61.4191 152.997 61.2074C156.345 60.3957 159.85 60.5051 
          163.141 61.5239L163.08 61.4748C164.482 62.215 165.713 63.2416 166.693 64.488L150.177 62.3737Z"
          fill="#CCCCCC"
        />
        <path
          d="M169.126 74.0304C169.067 74.915 169.008 75.7988 168.949 76.6818C168.868 78.5312 168.666 80.3734 
          168.345 82.1965C168.045 83.7218 167.567 85.2348 166.712 86.5334C165.857 87.8295 164.588 88.9014 
          163.086 89.2956C162.163 89.5168 161.204 89.5378 160.273 89.3572C158.516 88.3593 157.326 89.6357 
          159.08 90.9343C159.478 91.2285 159.892 91.5008 160.319 91.7499L161.115 104.038L146.335 100.835L149.219 
          84.4881C148.777 84.0866 148.373 83.6453 148.012 83.1698C146.359 80.9743 145.79 78.1135 145.899 
          75.3684C146.01 72.6209 146.741 69.9424 147.468 67.2935C147.653 66.5748 147.877 65.8666 148.138 
          65.1719C148.461 65.3049 148.788 65.4257 149.121 65.5267C151.247 66.1723 153.506 66.2241 155.728 
          66.2216C159.681 66.2191 163.63 66.0647 167.574 65.7583C168.48 67.2509 169.031 68.9321 169.183 
          70.6718C169.246 71.7912 169.227 72.9138 169.126 74.0304Z"
          fill="#CCCCCC"
        />
        <path
          d="M105.911 107.388L128.665 109.409L128.909 109.431V12.3103C128.828 12.2536 128.746 12.1969 128.665 
          12.1403C123.923 8.82224 118.793 6.09643 113.39 4.02344L112.227 20.0649L112.121 21.5335L110.971 
          37.4419L110.616 42.3356L105.953 106.822L105.911 107.388Z"
          fill="#E6E6E6"
        />
        <path
          d="M49.6397 10.5015C49.4771 10.605 49.3145 10.7085 49.1519 10.8169V109.431L49.6397 109.387L72.1476 
          107.388L72.1058 106.822L67.4426 42.3355L67.0878 37.4417L65.9374 21.5334L65.8315 20.0648L64.6121 
          3.24219C59.3537 5.06548 54.3282 7.50204 49.6397 10.5015Z"
          fill="#E6E6E6"
        />
        <path
          d="M49.1519 10.8174C48.8243 11.0293 48.4967 11.2461 48.174 11.4654V110.777C48.174 113.258 49.6446 
          115.338 51.6104 115.868C51.9316 115.955 52.2629 115.999 52.5957 115.998H125.709C126.041 115.999 
          126.372 115.955 126.692 115.868C127.423 115.665 128.085 115.268 128.608 114.72C129.607 113.652 
          130.153 112.239 130.131 110.777V13.1928C129.727 12.8946 129.32 12.5989 128.909 12.3106C128.828 
          12.2539 128.746 12.1973 128.665 12.1406V110.777C128.666 111.097 128.628 111.415 128.552 111.726C128.204 
          113.192 127.059 114.266 125.709 114.266H52.5957C50.965 114.266 49.6397 112.701 49.6397 110.777V10.502C49.4771 
          10.6054 49.3145 10.7089 49.1519 10.8174Z"
          fill="#E6E6E6"
        />
        <path d="M129.398 20.0664H48.9062V21.5348H129.398V20.0664Z" fill="#E6E6E6" />
        <path d="M104.746 1.33517C104.258 1.21443 103.77 1.10107 103.277 0.995117V40.8671H104.746V1.33517Z" fill="#E6E6E6" />
        <path d="M75.0278 0.5C74.5374 0.591173 74.047 0.684806 73.5591 0.785835V40.8673H75.0278V0.5Z" fill="#E6E6E6" />
        <path
          d="M33.1251 113.429C33.1248 114.271 33.2862 115.106 33.6006 115.888C33.7213 116.191 33.8654 116.484 
          34.0316 116.765C34.2463 117.131 34.4954 117.476 34.7756 117.795C35.3944 118.5 36.1567 119.066 37.0114 
          119.453C37.8662 119.84 38.7937 120.04 39.7319 120.04H136.127C137.879 120.04 139.559 119.344 140.798 
          118.104C142.037 116.865 142.734 115.184 142.734 113.431C142.734 111.678 142.037 109.997 140.798 
          108.758C139.559 107.519 137.879 106.822 136.127 106.822H39.7319C37.9807 106.824 36.3016 107.52 35.063 
          108.759C33.8244 109.997 33.1275 111.677 33.1251 113.429Z"
          fill="#E6E6E6"
        />
        <path d="M48.9062 42.3369L128.909 42.3369V37.4423L48.9062 37.4423V42.3369Z" fill="#E6E6E6" />
        <path
          d="M92.3326 38.665H85.4822C85.4822 38.3115 85.5518 37.9615 85.6871 37.6348C85.8223 37.3082 86.0206 
          37.0115 86.2705 36.7615C86.5204 36.5115 86.817 36.3132 87.1435 36.1779C87.4701 36.0427 87.82 35.973 
          88.1734 35.973H89.6414C90.3551 35.973 91.0396 36.2567 91.5443 36.7615C92.049 37.2664 92.3326 37.9511 
          92.3326 38.665H92.3326Z"
          fill="#E6E6E6"
        />
        <path d="M0.5 117.203C2.64001 119.74 4.95466 122.123 7.42704 124.337H175.573C178.045 122.123 180.36 119.74 182.5 117.203H0.5Z" fill="#3F3D56" />
        <path
          d="M46.1121 93.3477V118.62H63.8115V93.3477C63.8118 93.166 63.7538 92.9889 63.6461 92.8426C63.5383 
          92.6962 63.3865 92.5882 63.2129 92.5346C63.1311 92.5075 63.0453 92.4942 62.9592 92.4952H46.9669C46.8547 
          92.4949 46.7437 92.5168 46.64 92.5596C46.5364 92.6023 46.4422 92.6651 46.3628 92.7442C46.2834 92.8234 
          46.2204 92.9175 46.1774 93.021C46.1344 93.1246 46.1122 93.2356 46.1121 93.3477ZM53.0194 102.031C53.024 
          101.533 53.2251 101.057 53.5789 100.706C53.9328 100.356 54.4107 100.159 54.9088 100.159C55.4069 100.159 
          55.8849 100.356 56.2387 100.706C56.5925 101.057 56.7936 101.533 56.7982 102.031V104.964C56.7957 105.463 
          56.5955 105.941 56.2414 106.294C55.8874 106.646 55.4083 106.844 54.9088 106.844C54.4094 106.844 53.9303 
          106.646 53.5762 106.294C53.2221 105.941 53.022 105.463 53.0194 104.964V102.031Z"
          fill="#2F2E41"
        />
        <path
          d="M46.0579 117.812V120.249C46.0583 120.375 46.1084 120.496 46.1974 120.585C46.2864 120.675 46.4071 120.726 
          46.5333 120.727H63.3903C63.5166 120.726 63.6375 120.675 63.7266 120.586C63.8157 120.496 63.8657 120.375 
          63.8657 120.249V117.812H46.0579Z"
          fill="#3F3D56"
        />
        <path
          d="M18.2684 55.2729V101.416C18.2689 102.004 18.5026 102.568 18.9183 102.984C19.334 103.399 19.8976 103.633 
          20.4855 103.634H88.8445C89.4323 103.633 89.996 103.399 90.4117 102.984C90.8274 102.568 91.0611 102.004 
          91.0615 101.416V55.2729C91.0601 54.6853 90.826 54.1223 90.4105 53.7071C89.9949 53.2919 89.4318 53.0584 
          88.8445 53.0576H20.4855C19.8981 53.0584 19.335 53.2919 18.9195 53.7071C18.5039 54.1223 18.2698 54.6853 
          18.2684 55.2729Z"
          fill="#3F3D56"
        />
        <path
          d="M19.8721 55.8469V100.847C19.8726 101.161 19.9976 101.462 20.2197 101.684C20.4418 101.906 20.7429 
        102.031 21.057 102.032H88.2754C88.5895 102.031 88.8906 101.906 89.1127 101.684C89.3348 101.462 89.4598 
        101.161 89.4603 100.847V55.8469C89.4603 55.5323 89.3355 55.2306 89.1134 55.008C88.8912 54.7853 88.5899 
        54.6599 88.2754 54.6592H21.057C20.7425 54.6599 20.4411 54.7853 20.219 55.008C19.9969 55.2306 19.8721 
        55.5323 19.8721 55.8469Z"
        />
        <path
          d="M33.5784 121.301C33.6468 121.387 33.7337 121.456 33.8327 121.504C33.9317 121.551 34.0401 121.575 34.1499 
          121.575H74.1405C74.2499 121.575 74.3578 121.55 74.4565 121.503C74.5552 121.456 74.6422 121.387 74.7111 
          121.303C74.78 121.218 74.8291 121.118 74.8548 121.012C74.8805 120.906 74.8822 120.795 74.8598 120.688L74.239 
          117.738C74.2133 117.618 74.1579 117.506 74.0779 117.413C73.9979 117.319 73.8957 117.247 73.7808 117.204C73.6977 
          117.171 73.6091 117.154 73.5197 117.154H34.7682C34.6788 117.154 34.5902 117.171 34.5071 117.204C34.3922 117.247 
          34.29 117.319 34.21 117.413C34.13 117.506 34.0746 117.618 34.0489 117.738L33.4281 120.688C33.406 120.795 33.408 
          120.905 33.434 121.012C33.46 121.118 33.5093 121.217 33.5784 121.301Z"
          fill="#2F2E41"
        />
        <path
          d="M72.1239 118.425H73.1154C73.1819 118.425 73.2357 118.371 73.2357 118.305V117.806C73.2357 117.739 73.1819 
          117.686 73.1154 117.686H72.1239C72.0575 117.686 72.0037 117.739 72.0037 117.806V118.305C72.0037 118.371 
          72.0575 118.425 72.1239 118.425Z"
          fill="#3F3D56"
        />
        <path
          d="M69.6606 118.425H70.652C70.7185 118.425 70.7723 118.371 70.7723 118.305V117.806C70.7723 117.739 70.7185 
          117.686 70.652 117.686H69.6606C69.5941 117.686 69.5403 117.739 69.5403 117.806V118.305C69.5403 118.371 
          69.5941 118.425 69.6606 118.425Z"
          fill="#3F3D56"
        />
        <path
          d="M67.1972 118.425H68.1887C68.2551 118.425 68.309 118.371 68.309 118.305V117.806C68.309 117.739 68.2551 
          117.686 68.1887 117.686H67.1972C67.1308 117.686 67.0769 117.739 67.0769 117.806V118.305C67.0769 118.371
          67.1308 118.425 67.1972 118.425Z"
          fill="#3F3D56"
        />
        <path
          d="M64.7338 118.425H65.7252C65.7917 118.425 65.8455 118.371 65.8455 118.305V117.806C65.8455 117.739 65.7917 
          117.686 65.7252 117.686H64.7338C64.6673 117.686 64.6135 117.739 64.6135 117.806V118.305C64.6135 118.371 
          64.6673 118.425 64.7338 118.425Z"
          fill="#3F3D56"
        />
        <path
          d="M62.2704 118.425H63.2618C63.3283 118.425 63.3821 118.371 63.3821 118.305V117.806C63.3821 117.739 63.3283 
          117.686 63.2618 117.686H62.2704C62.2039 117.686 62.1501 117.739 62.1501 117.806V118.305C62.1501 118.371 
          62.2039 118.425 62.2704 118.425Z"
          fill="#3F3D56"
        />
        <path
          d="M59.807 118.425H60.7985C60.8649 118.425 60.9188 118.371 60.9188 118.305V117.806C60.9188 117.739 60.8649 
          117.686 60.7985 117.686H59.807C59.7406 117.686 59.6867 117.739 59.6867 117.806V118.305C59.6867 118.371 59.7406 
          118.425 59.807 118.425Z"
          fill="#3F3D56"
        />
        <path
          d="M57.3436 118.425H58.3351C58.4015 118.425 58.4554 118.371 58.4554 118.305V117.806C58.4554 117.739 58.4015 
          117.686 58.3351 117.686H57.3436C57.2772 117.686 57.2233 117.739 57.2233 117.806V118.305C57.2233 118.371 
          57.2772 118.425 57.3436 118.425Z"
          fill="#3F3D56"
        />
        <path
          d="M54.8802 118.425H55.8717C55.9381 118.425 55.992 118.371 55.992 118.305V117.806C55.992 117.739 55.9381 
          117.686 55.8717 117.686H54.8802C54.8138 117.686 54.7599 117.739 54.7599 117.806V118.305C54.7599 118.371
          54.8138 118.425 54.8802 118.425Z"
          fill="#3F3D56"
        />
        <path
          d="M52.4169 118.425H53.4083C53.4748 118.425 53.5286 118.371 53.5286 118.305V117.806C53.5286 117.739 53.4748 
          117.686 53.4083 117.686H52.4169C52.3504 117.686 52.2966 117.739 52.2966 117.806V118.305C52.2966 118.371 
          52.3504 118.425 52.4169 118.425Z"
          fill="#3F3D56"
        />
        <path
          d="M49.9534 118.425H50.9449C51.0113 118.425 51.0652 118.371 51.0652 118.305V117.806C51.0652 117.739 51.0113 
          117.686 50.9449 117.686H49.9534C49.887 117.686 49.8331 117.739 49.8331 117.806V118.305C49.8331 118.371 
          49.887 118.425 49.9534 118.425Z"
          fill="#3F3D56"
        />
        <path
          d="M47.49 118.425H48.4815C48.5479 118.425 48.6018 118.371 48.6018 118.305V117.806C48.6018 117.739 48.5479 
          117.686 48.4815 117.686H47.49C47.4236 117.686 47.3697 117.739 47.3697 117.806V118.305C47.3697 118.371 
          47.4236 118.425 47.49 118.425Z"
          fill="#3F3D56"
        />
        <path
          d="M45.0267 118.425H46.0181C46.0846 118.425 46.1384 118.371 46.1384 118.305V117.806C46.1384 117.739 46.0846 
          117.686 46.0181 117.686H45.0267C44.9602 117.686 44.9064 117.739 44.9064 117.806V118.305C44.9064 118.371 
          44.9602 118.425 45.0267 118.425Z"
          fill="#3F3D56"
        />
        <path
          d="M42.5633 118.425H43.5548C43.6212 118.425 43.675 118.371 43.675 118.305V117.806C43.675 117.739 43.6212 117.686 
          43.5548 117.686H42.5633C42.4968 117.686 42.443 117.739 42.443 117.806V118.305C42.443 118.371 42.4968 118.425 
          42.5633 118.425Z"
          fill="#3F3D56"
        />
        <path
          d="M40.0999 118.425H41.0914C41.1578 118.425 41.2117 118.371 41.2117 118.305V117.806C41.2117 117.739 41.1578 
          117.686 41.0914 117.686H40.0999C40.0335 117.686 39.9796 117.739 39.9796 117.806V118.305C39.9796 118.371 
          40.0335 118.425 40.0999 118.425Z"
          fill="#3F3D56"
        />
        <path
          d="M37.6365 118.425H38.628C38.6944 118.425 38.7483 118.371 38.7483 118.305V117.806C38.7483 117.739 38.6944 
          117.686 38.628 117.686H37.6365C37.5701 117.686 37.5162 117.739 37.5162 117.806V118.305C37.5162 118.371 
          37.5701 118.425 37.6365 118.425Z"
          fill="#3F3D56"
        />
        <path
          d="M35.1731 118.425H36.1646C36.2311 118.425 36.2849 118.371 36.2849 118.305V117.806C36.2849 117.739 36.2311 
          117.686 36.1646 117.686H35.1731C35.1067 117.686 35.0529 117.739 35.0529 117.806V118.305C35.0529 118.371 
          35.1067 118.425 35.1731 118.425Z"
          fill="#3F3D56"
        />
        <path
          d="M72.1531 119.656H73.1445C73.211 119.656 73.2648 119.602 73.2648 119.536V119.037C73.2648 118.971 73.211 
          118.917 73.1445 118.917H72.1531C72.0866 118.917 72.0328 118.971 72.0328 119.037V119.536C72.0328 119.602 
          72.0866 119.656 72.1531 119.656Z"
          fill="#3F3D56"
        />
        <path
          d="M69.6897 119.656H70.6812C70.7476 119.656 70.8015 119.602 70.8015 119.536V119.037C70.8015 118.971 70.7476 
          118.917 70.6812 118.917H69.6897C69.6232 118.917 69.5694 118.971 69.5694 119.037V119.536C69.5694 119.602 
          69.6232 119.656 69.6897 119.656Z"
          fill="#3F3D56"
        />
        <path
          d="M67.2262 119.656H68.2177C68.2842 119.656 68.338 119.602 68.338 119.536V119.037C68.338 118.971 68.2842 
          118.917 68.2177 118.917H67.2262C67.1598 118.917 67.106 118.971 67.106 119.037V119.536C67.106 119.602 
          67.1598 119.656 67.2262 119.656Z"
          fill="#3F3D56"
        />
        <path
          d="M64.7629 119.656H65.7543C65.8208 119.656 65.8746 119.602 65.8746 119.536V119.037C65.8746 118.971 65.8208 
          118.917 65.7543 118.917H64.7629C64.6964 118.917 64.6426 118.971 64.6426 119.037V119.536C64.6426 119.602 
          64.6964 119.656 64.7629 119.656Z"
          fill="#3F3D56"
        />
        <path
          d="M62.2995 119.656H63.291C63.3574 119.656 63.4113 119.602 63.4113 119.536V119.037C63.4113 118.971 63.3574 
          118.917 63.291 118.917H62.2995C62.2331 118.917 62.1792 118.971 62.1792 119.037V119.536C62.1792 119.602 
          62.2331 119.656 62.2995 119.656Z"
          fill="#3F3D56"
        />
        <path
          d="M59.8361 119.656H60.8276C60.894 119.656 60.9479 119.602 60.9479 119.536V119.037C60.9479 118.971 60.894 
          118.917 60.8276 118.917H59.8361C59.7697 118.917 59.7158 118.971 59.7158 119.037V119.536C59.7158 119.602 
          59.7697 119.656 59.8361 119.656Z"
          fill="#3F3D56"
        />
        <path
          d="M57.3727 119.656H58.3642C58.4306 119.656 58.4845 119.602 58.4845 119.536V119.037C58.4845 118.971 58.4306 
          118.917 58.3642 118.917H57.3727C57.3063 118.917 57.2524 118.971 57.2524 119.037V119.536C57.2524 119.602 
          57.3063 119.656 57.3727 119.656Z"
          fill="#3F3D56"
        />
        <path
          d="M54.9094 119.656H55.9008C55.9673 119.656 56.0211 119.602 56.0211 119.536V119.037C56.0211 118.971 55.9673 
          118.917 55.9008 118.917H54.9094C54.8429 118.917 54.7891 118.971 54.7891 119.037V119.536C54.7891 119.602 
          54.8429 119.656 54.9094 119.656Z"
          fill="#3F3D56"
        />
        <path
          d="M52.446 119.656H53.4374C53.5039 119.656 53.5577 119.602 53.5577 119.536V119.037C53.5577 118.971 53.5039 
          118.917 53.4374 118.917H52.446C52.3795 118.917 52.3257 118.971 52.3257 119.037V119.536C52.3257 119.602 
          52.3795 119.656 52.446 119.656Z"
          fill="#3F3D56"
        />
        <path
          d="M49.9825 119.656H50.974C51.0404 119.656 51.0943 119.602 51.0943 119.536V119.037C51.0943 118.971 51.0404 
          118.917 50.974 118.917H49.9825C49.9161 118.917 49.8622 118.971 49.8622 119.037V119.536C49.8622 119.602 
          49.9161 119.656 49.9825 119.656Z"
          fill="#3F3D56"
        />
        <path
          d="M47.5192 119.656H48.5106C48.5771 119.656 48.6309 119.602 48.6309 119.536V119.037C48.6309 118.971 48.5771 
          118.917 48.5106 118.917H47.5192C47.4527 118.917 47.3989 118.971 47.3989 119.037V119.536C47.3989 119.602 
          47.4527 119.656 47.5192 119.656Z"
          fill="#3F3D56"
        />
        <path
          d="M45.0558 119.656H46.0472C46.1137 119.656 46.1675 119.602 46.1675 119.536V119.037C46.1675 118.971 46.1137 
          118.917 46.0472 118.917H45.0558C44.9893 118.917 44.9355 118.971 44.9355 119.037V119.536C44.9355 119.602 
          44.9893 119.656 45.0558 119.656Z"
          fill="#3F3D56"
        />
        <path
          d="M42.5924 119.656H43.5839C43.6503 119.656 43.7042 119.602 43.7042 119.536V119.037C43.7042 118.971 43.6503 
          118.917 43.5839 118.917H42.5924C42.526 118.917 42.4721 118.971 42.4721 119.037V119.536C42.4721 119.602 
          42.526 119.656 42.5924 119.656Z"
          fill="#3F3D56"
        />
        <path
          d="M40.129 119.656H41.1205C41.1869 119.656 41.2408 119.602 41.2408 119.536V119.037C41.2408 118.971 41.1869 
          118.917 41.1205 118.917H40.129C40.0626 118.917 40.0087 118.971 40.0087 119.037V119.536C40.0087 119.602 
          40.0626 119.656 40.129 119.656Z"
          fill="#3F3D56"
        />
        <path
          d="M37.6656 119.656H38.6571C38.7235 119.656 38.7774 119.602 38.7774 119.536V119.037C38.7774 118.971 38.7235 
          118.917 38.6571 118.917H37.6656C37.5992 118.917 37.5453 118.971 37.5453 119.037V119.536C37.5453 119.602 
          37.5992 119.656 37.6656 119.656Z"
          fill="#3F3D56"
        />
        <path
          d="M35.2023 119.656H36.1937C36.2602 119.656 36.314 119.602 36.314 119.536V119.037C36.314 118.971 36.2602 118.917 
          36.1937 118.917H35.2023C35.1358 118.917 35.082 118.971 35.082 119.037V119.536C35.082 119.602 35.1358 119.656 
          35.2023 119.656Z"
          fill="#3F3D56"
        />
        <path
          d="M51.7071 121.135H61.3202C61.3866 121.135 61.4404 121.081 61.4404 121.014V120.516C61.4404 120.449 61.3866 
          120.396 61.3202 120.396H51.7071C51.6407 120.396 51.5869 120.449 51.5869 120.516V121.014C51.5869 121.081 
          51.6407 121.135 51.7071 121.135Z"
          fill="#3F3D56"
        />
        <path
          d="M24.4347 60.8578H87.3308C87.3962 60.8564 87.4584 60.8294 87.5041 60.7826C87.5499 60.7359 87.5755 60.673 
          87.5755 60.6076C87.5755 60.5422 87.5499 60.4794 87.5041 60.4326C87.4584 60.3858 87.3962 60.3589 87.3308 
          60.3574H24.4347C24.3694 60.3589 24.3071 60.3858 24.2614 60.4326C24.2157 60.4794 24.1901 60.5422 24.1901 
          60.6076C24.1901 60.673 24.2157 60.7359 24.2614 60.7826C24.3071 60.8294 24.3694 60.8564 24.4347 60.8578Z"
          fill="#3F3D56"
        />
        <path
          d="M83.0498 59.0799C83.8596 59.0799 84.5161 58.4088 84.5161 57.581C84.5161 56.7531 83.8596 56.082 83.0498 
          56.082C82.24 56.082 81.5836 56.7531 81.5836 57.581C81.5836 58.4088 82.24 59.0799 83.0498 59.0799Z"
          fill="#3F3D56"
        />
        <path
          d="M77.986 59.0799C78.7958 59.0799 79.4523 58.4088 79.4523 57.581C79.4523 56.7531 78.7958 56.082 77.986 56.082C77.1762 56.082 76.5198 56.7531 
          76.5198 57.581C76.5198 58.4088 77.1762 59.0799 77.986 59.0799Z"
          fill="#3F3D56"
        />
        <path
          d="M72.9223 59.0799C73.7321 59.0799 74.3886 58.4088 74.3886 57.581C74.3886 56.7531 73.7321 56.082 72.9223 56.082C72.1125 56.082 71.4561 
          56.7531 71.4561 57.581C71.4561 58.4088 72.1125 59.0799 72.9223 59.0799Z"
          fill="#3F3D56"
        />
        <path
          d="M27.2155 56.7266C27.2153 56.7621 27.2221 56.7972 27.2355 56.83C27.2489 56.8628 27.2686 56.8926 27.2936 56.9178C27.3185 56.943 
          27.3482 56.963 27.3808 56.9767C27.4135 56.9904 27.4485 56.9975 27.484 56.9977H31.0805C31.1516 56.996 31.2192 56.9666 31.2689 
          56.9157C31.3186 56.8649 31.3464 56.7965 31.3464 56.7254C31.3464 56.6543 31.3186 56.586 31.2689 56.5351C31.2192 56.4842 31.1516 
          56.4548 31.0805 56.4531H27.484C27.4122 56.454 27.3436 56.4833 27.2933 56.5345C27.243 56.5857 27.215 56.6548 27.2155 56.7266Z"
          fill="#3F3D56"
        />
        <path
          d="M27.2155 57.7491C27.2153 57.7845 27.2221 57.8197 27.2355 57.8525C27.2489 57.8853 27.2686 57.9151 27.2936 57.9403C27.3185 
          57.9654 27.3482 57.9855 27.3808 57.9992C27.4135 58.0129 27.4485 58.02 27.484 58.0202H31.0805C31.1517 58.0186 31.2194 57.9892 
          31.2691 57.9383C31.3189 57.8874 31.3468 57.8191 31.3468 57.7479C31.3468 57.6767 31.3189 57.6083 31.2691 57.5574C31.2194 
          57.5065 31.1517 57.4772 31.0805 57.4756H27.484C27.4122 57.4765 27.3436 57.5057 27.2933 57.557C27.243 57.6082 27.215 
          57.6773 27.2155 57.7491Z"
          fill="#3F3D56"
        />
        <path
          d="M27.2155 58.7691C27.215 58.8409 27.243 58.91 27.2933 58.9613C27.3436 59.0125 27.4122 59.0418 27.484 59.0426H31.0805C31.1516 
          59.041 31.2192 59.0115 31.2689 58.9607C31.3186 58.9098 31.3464 58.8415 31.3464 58.7703C31.3464 58.6992 31.3186 58.6309 31.2689 
          58.58C31.2192 58.5291 31.1516 58.4997 31.0805 58.498H27.484C27.4485 58.4982 27.4135 58.5053 27.3808 58.519C27.3482 58.5327 
          27.3185 58.5528 27.2936 58.5779C27.2686 58.6031 27.2489 58.6329 27.2355 58.6657C27.2221 58.6985 27.2153 58.7337 27.2155 
          58.7691Z"
          fill="#3F3D56"
        />
        <path
          d="M28.3275 66.6719H27.0348C26.6429 66.6719 26.3251 66.9896 26.3251 67.3815C26.3251 67.7735 26.6429 68.0912 27.0348 
          68.0912H28.3275C28.7194 68.0912 29.0372 67.7735 29.0372 67.3815C29.0372 66.9896 28.7194 66.6719 28.3275 66.6719Z"
          fill="#E6E6E6"
        />
        <path
          d="M43.1079 66.6719H41.8151C41.4232 66.6719 41.1055 66.9896 41.1055 67.3815C41.1055 67.7735 41.4232 68.0912 41.8151 
          68.0912H43.1079C43.4998 68.0912 43.8175 67.7735 43.8175 67.3815C43.8175 66.9896 43.4998 66.6719 43.1079 66.6719Z"
          fill="#E6E6E6"
        />
        <path
          d="M47.2956 66.6719H46.0029C45.6109 66.6719 45.2932 66.9896 45.2932 67.3815C45.2932 67.7735 45.6109 68.0912 46.0029 
          68.0912H47.2956C47.6875 68.0912 48.0053 67.7735 48.0053 67.3815C48.0053 66.9896 47.6875 66.6719 47.2956 66.6719Z"
          fill="#E6E6E6"
        />
        <path
          d="M39.0872 66.5547H31.0551C30.6633 66.5547 30.3457 66.8724 30.3457 67.2643C30.3457 67.6563 30.6633 67.974 31.0551 
          67.974H39.0872C39.479 67.974 39.7967 67.6563 39.7967 67.2643C39.7967 66.8724 39.479 66.5547 39.0872 66.5547Z"
          fill="#E6E6E6"
        />
        <path
          d="M58.7941 66.5537H50.762C50.3702 66.5537 50.0526 66.8714 50.0526 67.2634C50.0526 67.6553 50.3702 67.973 50.762 
          67.973H58.7941C59.1859 67.973 59.5035 67.6553 59.5035 67.2634C59.5035 66.8714 59.1859 66.5537 58.7941 66.5537Z"
          fill="#E6E6E6"
        />
        <path
          d="M32.269 69.876H30.9763C30.5843 69.876 30.2666 70.1937 30.2666 70.5856C30.2666 70.9776 30.5843 71.2953 30.9763 
          71.2953H32.269C32.6609 71.2953 32.9786 70.9776 32.9786 70.5856C32.9786 70.1937 32.6609 69.876 32.269 69.876Z"
          fill="#E6E6E6"
        />
        <path
          d="M47.0493 69.876H45.7566C45.3647 69.876 45.0469 70.1937 45.0469 70.5856C45.0469 70.9776 45.3647 71.2953 45.7566 
          71.2953H47.0493C47.4413 71.2953 47.759 70.9776 47.759 70.5856C47.759 70.1937 47.4413 69.876 47.0493 69.876Z"
          fill="#E6E6E6"
        />
        <path
          d="M51.2371 69.876H49.9443C49.5524 69.876 49.2347 70.1937 49.2347 70.5856C49.2347 70.9776 49.5524 71.2953 49.9443 
          71.2953H51.2371C51.629 71.2953 51.9467 70.9776 51.9467 70.5856C51.9467 70.1937 51.629 69.876 51.2371 69.876Z"
          fill="#E6E6E6"
        />
        <path
          d="M43.0284 69.7578H34.9963C34.6045 69.7578 34.2869 70.0755 34.2869 70.4675C34.2869 70.8594 34.6045 71.1771 34.9963 
          71.1771H43.0284C43.4202 71.1771 43.7378 70.8594 43.7378 70.4675C43.7378 70.0755 43.4202 69.7578 43.0284 69.7578Z"
          fill="#E6E6E6"
        />
        <path
          d="M62.7355 69.7578H54.7034C54.3116 69.7578 53.994 70.0755 53.994 70.4675C53.994 70.8594 54.3116 71.1771 54.7034 
          71.1771H62.7355C63.1273 71.1771 63.4449 70.8594 63.4449 70.4675C63.4449 70.0755 63.1273 69.7578 62.7355 69.7578Z"
          fill="#E6E6E6"
        />
        <path
          d="M36.2104 73.0791H34.9177C34.5257 73.0791 34.208 73.3968 34.208 73.7888C34.208 74.1807 34.5257 74.4984 34.9177 
          74.4984H36.2104C36.6023 74.4984 36.92 74.1807 36.92 73.7888C36.92 73.3968 36.6023 73.0791 36.2104 73.0791Z"
          fill="#E6E6E6"
        />
        <path
          d="M50.9907 73.0791H49.698C49.3061 73.0791 48.9883 73.3968 48.9883 73.7888C48.9883 74.1807 49.3061 74.4984 49.698 
          74.4984H50.9907C51.3827 74.4984 51.7004 74.1807 51.7004 73.7888C51.7004 73.3968 51.3827 73.0791 50.9907 73.0791Z"
          fill="#E6E6E6"
        />
        <path
          d="M55.1785 73.0791H53.8857C53.4938 73.0791 53.1761 73.3968 53.1761 73.7888C53.1761 74.1807 53.4938 74.4984 53.8857 
          74.4984H55.1785C55.5704 74.4984 55.8881 74.1807 55.8881 73.7888C55.8881 73.3968 55.5704 73.0791 55.1785 73.0791Z"
          fill="#E6E6E6"
        />
        <path
          d="M46.9698 72.9609H38.9377C38.5459 72.9609 38.2283 73.2787 38.2283 73.6706C38.2283 74.0625 38.5459 74.3803 38.9377 
          74.3803H46.9698C47.3616 74.3803 47.6792 74.0625 47.6792 73.6706C47.6792 73.2787 47.3616 72.9609 46.9698 72.9609Z"
          fill="#E6E6E6"
        />
        <path
          d="M66.6769 72.9609H58.6449C58.2531 72.9609 57.9354 73.2787 57.9354 73.6706C57.9354 74.0625 58.2531 74.3803 58.6449 
          74.3803H66.6769C67.0688 74.3803 67.3864 74.0625 67.3864 73.6706C67.3864 73.2787 67.0688 72.9609 66.6769 72.9609Z"
          fill="#E6E6E6"
        />
        <path
          d="M40.1518 76.2822H38.8591C38.4671 76.2822 38.1494 76.5999 38.1494 76.9919C38.1494 77.3838 38.4671 77.7015 38.8591 
          77.7015H40.1518C40.5437 77.7015 40.8615 77.3838 40.8615 76.9919C40.8615 76.5999 40.5437 76.2822 40.1518 76.2822Z"
          fill="#E6E6E6"
        />
        <path
          d="M54.9321 76.2822H53.6394C53.2475 76.2822 52.9297 76.5999 52.9297 76.9919C52.9297 77.3838 53.2475 77.7015 53.6394 
          77.7015H54.9321C55.3241 77.7015 55.6418 77.3838 55.6418 76.9919C55.6418 76.5999 55.3241 76.2822 54.9321 76.2822Z"
          fill="#E6E6E6"
        />
        <path
          d="M59.1199 76.2822H57.8272C57.4352 76.2822 57.1175 76.5999 57.1175 76.9919C57.1175 77.3838 57.4352 77.7015 57.8272 
          77.7015H59.1199C59.5118 77.7015 59.8295 77.3838 59.8295 76.9919C59.8295 76.5999 59.5118 76.2822 59.1199 76.2822Z"
          fill="#E6E6E6"
        />
        <path
          d="M50.9113 76.1641H42.8792C42.4874 76.1641 42.1697 76.4818 42.1697 76.8737C42.1697 77.2657 42.4874 77.5834 42.8792 
          77.5834H50.9113C51.3031 77.5834 51.6207 77.2657 51.6207 76.8737C51.6207 76.4818 51.3031 76.1641 50.9113 76.1641Z"
          fill="#E6E6E6"
        />
        <path
          d="M43.1079 89.0205H41.8151C41.4232 89.0205 41.1055 89.3382 41.1055 89.7302C41.1055 90.1221 41.4232 90.4398 41.8151 
          90.4398H43.1079C43.4998 90.4398 43.8175 90.1221 43.8175 89.7302C43.8175 89.3382 43.4998 89.0205 43.1079 89.0205Z"
          fill="#E6E6E6"
        />
        <path
          d="M47.2956 89.0205H46.0029C45.6109 89.0205 45.2932 89.3382 45.2932 89.7302C45.2932 90.1221 45.6109 90.4398 46.0029 
          90.4398H47.2956C47.6875 90.4398 48.0053 90.1221 48.0053 89.7302C48.0053 89.3382 47.6875 89.0205 47.2956 89.0205Z"
          fill="#E6E6E6"
        />
        <path
          d="M58.7941 89.1387H50.762C50.3702 89.1387 50.0526 89.4564 50.0526 89.8483C50.0526 90.2403 50.3702 90.558 50.762 
          90.558H58.7941C59.1859 90.558 59.5035 90.2403 59.5035 89.8483C59.5035 89.4564 59.1859 89.1387 58.7941 89.1387Z"
          fill="#E6E6E6"
        />
        <path
          d="M28.3275 89.0205H27.0348C26.6429 89.0205 26.3251 89.3382 26.3251 89.7302C26.3251 90.1221 26.6429 90.4398 27.0348 
          90.4398H28.3275C28.7194 90.4398 29.0372 90.1221 29.0372 89.7302C29.0372 89.3382 28.7194 89.0205 28.3275 89.0205Z"
          fill="#E6E6E6"
        />
        <path
          d="M39.0872 89.1387H31.0551C30.6633 89.1387 30.3457 89.4564 30.3457 89.8483C30.3457 90.2403 30.6633 90.558 31.0551 
          90.558H39.0872C39.479 90.558 39.7967 90.2403 39.7967 89.8483C39.7967 89.4564 39.479 89.1387 39.0872 89.1387Z"
          fill="#E6E6E6"
        />
        <path
          d="M32.269 85.8174H30.9763C30.5843 85.8174 30.2666 86.1351 30.2666 86.527C30.2666 86.919 30.5843 87.2367 30.9763 
          87.2367H32.269C32.6609 87.2367 32.9786 86.919 32.9786 86.527C32.9786 86.1351 32.6609 85.8174 32.269 85.8174Z"
          fill="#E6E6E6"
        />
        <path
          d="M47.0493 85.8174H45.7566C45.3647 85.8174 45.0469 86.1351 45.0469 86.527C45.0469 86.919 45.3647 87.2367 45.7566 
          87.2367H47.0493C47.4413 87.2367 47.759 86.919 47.759 86.527C47.759 86.1351 47.4413 85.8174 47.0493 85.8174Z"
          fill="#E6E6E6"
        />
        <path
          d="M51.2371 85.8174H49.9443C49.5524 85.8174 49.2347 86.1351 49.2347 86.527C49.2347 86.919 49.5524 87.2367 49.9443 
          87.2367H51.2371C51.629 87.2367 51.9467 86.919 51.9467 86.527C51.9467 86.1351 51.629 85.8174 51.2371 85.8174Z"
          fill="#E6E6E6"
        />
        <path
          d="M43.0284 85.9355H34.9963C34.6045 85.9355 34.2869 86.2533 34.2869 86.6452C34.2869 87.0371 34.6045 87.3549 34.9963 
          87.3549H43.0284C43.4202 87.3549 43.7378 87.0371 43.7378 86.6452C43.7378 86.2533 43.4202 85.9355 43.0284 85.9355Z"
          fill="#E6E6E6"
        />
        <path
          d="M36.2104 82.6133H34.9177C34.5257 82.6133 34.208 82.931 34.208 83.3229C34.208 83.7149 34.5257 84.0326 34.9177 
          84.0326H36.2104C36.6023 84.0326 36.92 83.7149 36.92 83.3229C36.92 82.931 36.6023 82.6133 36.2104 82.6133Z"
          fill="#E6E6E6"
        />
        <path
          d="M50.9907 82.6133H49.698C49.3061 82.6133 48.9883 82.931 48.9883 83.3229C48.9883 83.7149 49.3061 84.0326 49.698 
          84.0326H50.9907C51.3827 84.0326 51.7004 83.7149 51.7004 83.3229C51.7004 82.931 51.3827 82.6133 50.9907 82.6133Z"
          fill="#E6E6E6"
        />
        <path
          d="M55.1785 82.6133H53.8857C53.4938 82.6133 53.1761 82.931 53.1761 83.3229C53.1761 83.7149 53.4938 84.0326 
          53.8857 84.0326H55.1785C55.5704 84.0326 55.8881 83.7149 55.8881 83.3229C55.8881 82.931 55.5704 82.6133 
          55.1785 82.6133Z"
          fill="#E6E6E6"
        />
        <path
          d="M46.9698 82.7324H38.9377C38.5459 82.7324 38.2283 83.0501 38.2283 83.4421C38.2283 83.834 38.5459 84.1517 
          38.9377 84.1517H46.9698C47.3616 84.1517 47.6792 83.834 47.6792 83.4421C47.6792 83.0501 47.3616 82.7324 
          46.9698 82.7324Z"
          fill="#E6E6E6"
        />
        <path
          d="M40.1518 79.4102H38.8591C38.4671 79.4102 38.1494 79.7279 38.1494 80.1198C38.1494 80.5118 38.4671 80.8295 
          38.8591 80.8295H40.1518C40.5437 80.8295 40.8615 80.5118 40.8615 80.1198C40.8615 79.7279 40.5437 79.4102 
          40.1518 79.4102Z"
          fill="#E6E6E6"
        />
        <path
          d="M54.9321 79.4102H53.6394C53.2475 79.4102 52.9297 79.7279 52.9297 80.1198C52.9297 80.5118 53.2475 80.8295 
          53.6394 80.8295H54.9321C55.3241 80.8295 55.6418 80.5118 55.6418 80.1198C55.6418 79.7279 55.3241 79.4102 
          54.9321 79.4102Z"
          fill="#E6E6E6"
        />
        <path
          d="M59.1199 79.4102H57.8272C57.4352 79.4102 57.1175 79.7279 57.1175 80.1198C57.1175 80.5118 57.4352 80.8295 
          57.8272 80.8295H59.1199C59.5118 80.8295 59.8295 80.5118 59.8295 80.1198C59.8295 79.7279 59.5118 79.4102 
          59.1199 79.4102Z"
          fill="#E6E6E6"
        />
        <path
          d="M50.9113 79.5283H42.8792C42.4874 79.5283 42.1697 79.846 42.1697 80.238C42.1697 80.6299 42.4874 80.9476 
          42.8792 80.9476H50.9113C51.3031 80.9476 51.6207 80.6299 51.6207 80.238C51.6207 79.846 51.3031 79.5283 
          50.9113 79.5283Z"
          fill="#E6E6E6"
        />
        <path
          d="M23.6805 135.298C23.8307 135.374 23.9835 135.45 24.1337 135.524C28.0259 137.471 31.1396 138.577 35.3988 
          139.79C35.7585 135.773 36.1058 128.713 36.4433 124.337C36.532 123.201 36.6207 122.248 36.7069 121.575C36.7286 
          121.217 36.8016 120.865 36.9237 120.528L36.9089 121.575L36.8719 124.337L36.6576 140.135C41.9557 141.537 47.3982 
          142.322 52.8766 142.473C53.1623 142.481 53.4431 142.485 53.7141 142.49C53.8102 142.493 53.9038 142.495 53.9974 
          142.495C54.3053 142.498 54.6108 142.5 54.9187 142.5C60.6919 142.504 66.4442 141.807 72.0491 140.423C71.9284 
          136.448 71.8077 129.467 71.6919 124.337C71.6673 123.334 71.6451 122.4 71.6205 121.575C71.5737 119.811 71.5244 
          118.539 71.4776 118.113C71.5191 118.188 71.5513 118.269 71.5737 118.352C71.8018 119.414 71.9574 120.491 
          72.0392 121.575C72.1255 122.4 72.2117 123.334 72.2979 124.337C72.7561 129.667 73.234 136.914 73.7267 139.987C76.6592 
          139.186 79.5367 138.196 82.3411 137.022C83.8192 136.406 85.2709 135.742 86.6964 135.029C86.4254 133.255 86.1421 131.266 
          85.8515 129.204C85.6248 127.605 85.3908 125.961 85.1519 124.337C84.7823 121.829 79.2915 104.299 78.9441 103.634C78.6461 
          103.064 78.3628 102.527 78.0943 102.032C77.4439 100.829 76.8921 99.8709 76.4832 99.2647C74.7736 96.6707 72.2285 94.7396 
          69.2704 93.7919L69.1965 93.7722L69.1448 93.7944L64.1072 92.2469L63.3706 92.0202L62.8755 91.8674L61.1314 90.5393L60.183 
          89.8173L59.2937 89.1396L57.5545 87.8164L46.7871 88.4793L44.6907 93.5282L39.9955 95.2457L39.9142 95.2088L39.8428 95.1792L39.7689 
          95.1989C36.8113 96.1474 34.2664 98.0784 32.5561 100.672C32.3245 101.019 32.0437 101.478 31.7259 102.032C31.455 102.503 31.1593 
          103.04 30.8391 103.634C30.3834 104.479 29.8858 105.432 29.3586 106.465C29.2428 106.692 29.1246 106.923 29.0063 107.157C28.7871 
          107.591 28.5646 108.034 28.3388 108.485C27.8141 109.533 27.2721 110.627 26.7302 111.738C26.2597 112.702 25.7842 114.683 25.3162 
          117.204C24.9294 119.298 24.5452 121.767 24.1732 124.337C23.6854 127.669 24.2052 131.658 23.7643 134.723C23.7371 134.918 23.7076 
          135.108 23.6805 135.298Z"
          fill="#3F3D56"
        />
        <path opacity="0.2" d="M75.286 117.193L73.1928 109.112L71.3445 116.085L75.286 117.193Z" fill="black" />
        <path
          d="M19.5863 111.928C20.3352 122.247 19.5592 126.791 23.6928 135.3C27.4537 137.141 31.3712 138.644 35.3988 139.789C35.8176 
          139.908 36.2372 140.023 36.6576 140.134C41.9557 141.537 47.3982 142.321 52.8765 142.473C53.1623 142.48 53.4431 142.485 
          53.7141 142.49C53.8101 142.492 53.9038 142.495 53.9974 142.495C54.3053 142.497 54.6108 142.5 54.9187 142.5C60.6918 
          142.504 66.4441 141.807 72.0491 140.423C72.6107 140.285 73.1699 140.139 73.7266 139.986C76.6592 139.186 79.5367 
          138.195 82.3411 137.022C83.8191 136.406 85.2709 135.742 86.6964 135.029C87.0191 134.866 87.3418 134.703 87.6596 
          134.536C89.2928 131.663 90.2215 128.169 90.2412 123.97C89.1105 116.735 22.0842 105.688 19.5863 111.928Z"
          fill="#2F2E41"
        />
        <path
          opacity="0.2"
          d="M19.5883 113.695C19.55 113.056 19.5073 112.396 19.4574 111.709C21.9553 105.47 88.9816 116.516 90.1123 
          123.751C90.1092 124.414 90.0808 125.058 90.0331 125.686C87.2949 118.546 23.619 108.047 19.5883 113.695Z"
          fill="black"
        />
        <path
          d="M4.39939 117.098C4.50792 117.415 4.71379 117.688 4.98745 117.88C5.26111 118.072 5.58847 118.172 5.92253 
          118.166L12.4485 117.998C12.785 117.991 13.1104 117.876 13.3759 117.669C13.6415 117.462 13.833 117.175 
          13.9218 116.85L14.8453 106.972C15.173 107.121 15.5296 107.194 15.8893 107.187C16.52 107.171 17.1184 
          106.904 17.5531 106.447C17.9877 105.989 18.223 105.378 18.2072 104.747C18.1913 104.116 17.9257 103.517 
          17.4687 103.082C17.0116 102.647 16.4006 102.411 15.7698 102.427C15.6261 102.426 15.4827 102.441 15.3421 
          102.471C15.1893 102.306 15.0034 102.174 14.7966 102.085C14.5899 101.996 14.3667 101.951 14.1415 101.953L4.10302 
          102.212C4.01975 102.216 3.9369 102.226 3.85541 102.243C3.63526 102.284 3.42647 102.372 3.24307 102.501C3.05967 
          102.629 2.90593 102.795 2.79218 102.988C2.67844 103.181 2.60735 103.396 2.58367 103.619C2.56 103.842 2.58431 
          104.067 2.65495 104.28L4.39939 117.098ZM15.0347 106.301L15.6918 103.948C15.7666 103.676 15.7683 103.389 
          15.6968 103.116C15.7287 103.116 15.7569 103.101 15.7887 103.101C16.2393 103.091 16.6752 103.261 17.0008 
          103.573C17.3265 103.885 17.5152 104.313 17.5258 104.763C17.5363 105.214 17.3678 105.651 17.0571 105.977C16.7464 
          106.304 16.319 106.494 15.8685 106.505C15.5772 106.515 15.2889 106.444 15.0347 106.301Z"
          fill="#2F2E41"
        />
        <path
          d="M3.85541 102.244C4.06927 103.467 6.47376 104.327 9.36498 104.177C12.0236 104.055 14.1975 103.121 14.5958 
          102.014C14.4484 101.97 14.2951 101.95 14.1415 101.954L4.10302 102.213C4.01975 102.216 3.9369 102.226 
          3.85541 102.244Z"
          fill="#3F3D56"
        />
        <path
          d="M9.89491 111.671C9.90813 111.668 9.9207 111.662 9.9321 111.655L11.3497 110.727C11.3669 110.716 11.381 
        110.7 11.3907 110.682C11.4005 110.664 11.4056 110.644 11.4056 110.624C11.4056 110.603 11.4005 110.583 11.3908 
        110.565C11.381 110.547 11.367 110.532 11.3498 110.521L9.87633 109.556C9.86281 109.547 9.84765 109.54 9.83173 
        109.537C9.81581 109.534 9.79944 109.534 9.78356 109.538C9.76767 109.541 9.75259 109.547 9.73918 109.556C9.72577 109.566 9.71428 109.577 9.70539 109.591C9.6965 109.604 9.69038 109.62 9.68738 109.635C9.68437 109.651 9.68454 109.668 9.68788 109.684C9.69122 109.7 9.69765 109.715 9.70682 109.728C9.71599 109.741 9.72772 109.753 9.74132 109.762L11.0574 110.624L9.79723 111.449C9.77283 111.464 9.75488 111.489 9.74657 111.517C9.73826 111.544 9.74013 111.574 9.75185 111.601C9.76357 111.628 9.78437 111.65 9.81057 111.662C9.83676 111.675 9.86664 111.678 9.8949 111.671H9.89491Z"
        />
        <path
          d="M7.65689 111.774C7.68069 111.768 7.70214 111.755 7.7185 111.737C7.73486 111.719 7.74538 111.696 7.74872 
        111.672C7.75206 111.647 7.74805 111.622 7.73723 111.6C7.7264 111.578 7.70925 111.56 7.68796 111.548L6.38122 
        110.799L7.64424 109.861C7.65721 109.851 7.66816 109.839 7.67646 109.825C7.68476 109.811 7.69025 109.796 7.69261 
        109.78C7.69496 109.764 7.69415 109.747 7.69021 109.732C7.68626 109.716 7.67927 109.701 7.66962 109.688C7.65998 
        109.675 7.64788 109.664 7.634 109.656C7.62013 109.648 7.60475 109.642 7.58876 109.64C7.57276 109.638 7.55646 
        109.638 7.54078 109.642C7.5251 109.646 7.51035 109.653 7.49737 109.663L6.0833 110.713C6.06687 110.726 6.05373 
        110.742 6.04508 110.76C6.03642 110.779 6.03252 110.799 6.03372 110.819C6.03492 110.84 6.0412 110.86 6.05197 
        110.877C6.06274 110.894 6.07768 110.909 6.09542 110.919L7.56534 111.762C7.59307 111.778 7.6259 111.782 7.65689 
        111.774Z"
        />
        <path
          d="M9.7545 112.484C9.76642 112.481 9.77781 112.476 9.78826 112.469C9.8021 112.461 9.81414 112.45 9.82371 
        112.437C9.83327 112.424 9.84017 112.409 9.844 112.393C9.84784 112.378 9.84853 112.361 9.84605 112.345C9.84357 
        112.329 9.83795 112.314 9.82954 112.3L7.81992 108.996C7.80284 108.968 7.77546 108.949 7.74376 108.941C7.71207 
        108.933 7.67865 108.939 7.65081 108.955C7.62297 108.972 7.60298 109 7.59522 109.031C7.58746 109.063 7.59255 
        109.097 7.60939 109.124L9.619 112.428C9.63272 112.451 9.65327 112.468 9.67766 112.478C9.70204 112.488 9.72897 
        112.49 9.7545 112.484Z"
        />
        <path d="M165.795 98.6182H143.871V117.838H165.795V98.6182Z" fill="#3F3D56" />
        <path
          d="M169.045 71.7715C168.639 74.1811 168.996 76.6575 170.067 78.854C170.463 79.6619 170.954 80.6352 
          170.471 81.3947C170.336 81.5846 170.16 81.7417 169.956 81.8548C169.752 81.9678 169.525 82.0338 
          169.292 82.048C168.827 82.0727 168.36 82.0123 167.916 81.8699"
          fill="#CCCCCC"
        />
        <path
          d="M149.784 64.3668C150.192 64.3668 150.523 64.091 150.523 63.7508C150.523 63.4106 150.192 63.1348 
          149.784 63.1348C149.375 63.1348 149.044 63.4106 149.044 63.7508C149.044 64.091 149.375 64.3668 
          149.784 64.3668Z"
          fill="#3F3D56"
        />
        <path
          d="M152.493 64.6129C152.902 64.6129 153.233 64.3371 153.233 63.9969C153.233 63.6567 152.902 63.3809 
          152.493 63.3809C152.085 63.3809 151.754 63.6567 151.754 63.9969C151.754 64.3371 152.085 64.6129 
          152.493 64.6129Z"
          fill="#3F3D56"
        />
        <path
          d="M155.203 65.8453C155.611 65.8453 155.942 65.5695 155.942 65.2293C155.942 64.8891 155.611 64.6133 
          155.203 64.6133C154.795 64.6133 154.464 64.8891 154.464 65.2293C154.464 65.5695 154.795 65.8453 
          155.203 65.8453Z"
          fill="#3F3D56"
        />
        <path
          d="M158.405 65.1051C158.814 65.1051 159.144 64.8293 159.144 64.4891C159.144 64.1489 158.814 63.873 
          158.405 63.873C157.997 63.873 157.666 64.1489 157.666 64.4891C157.666 64.8293 157.997 65.1051 
          158.405 65.1051Z"
          fill="#3F3D56"
        />
        <path
          d="M91.1364 76.7813C94.7257 75.7846 98.7234 76.8528 101.497 79.2995C102.994 80.6288 104.092 82.3476 
          104.67 84.2643C105.378 86.5458 105.495 88.9524 105.569 91.3239C105.651 93.9462 105.722 96.5688 
          105.799 99.1912C105.871 101.692 105.88 104.204 106.047 106.7C106.187 108.795 106.522 110.969 
          107.643 112.786C108.681 114.47 110.323 115.607 112.178 116.234C114.058 116.87 116.085 117.017 
          118.058 117.005C120.353 116.997 122.645 116.816 124.913 116.462C129.22 115.771 133.475 114.011 
          136.106 110.378C137.318 108.729 137.998 106.749 138.058 104.704C138.113 102.32 137.352 100.011 
          136.518 97.8085C135.706 95.6657 134.828 93.5545 134.315 91.3141C133.837 89.2175 133.61 87.0717 
          133.637 84.9216C133.681 80.62 134.712 76.3859 136.65 72.5456C137.631 70.5732 138.875 68.7433 
          140.348 67.106C141.868 65.4521 143.749 64.0868 145.987 63.6392C147.079 63.4129 148.21 63.4584 
          149.28 63.7716C149.736 63.9084 149.932 63.1951 149.476 63.0588C147.26 62.3949 144.852 62.8997 
          142.886 64.052C140.954 65.185 139.427 66.8847 138.131 68.6869C135.641 72.2202 133.983 76.2717 
          133.282 80.5375C132.582 84.8033 132.856 89.1724 134.084 93.3171C135.399 97.6266 138.26 102.112 
          137.031 106.776C135.955 110.859 132.229 113.633 128.38 114.886C126.045 115.646 123.597 115.968 
          121.155 116.134C119.091 116.275 116.999 116.364 114.941 116.099C113.051 115.855 111.107 115.277 
          109.642 114.008C108.035 112.617 107.301 110.618 106.982 108.567C106.62 106.246 106.673 103.852 
          106.605 101.51L106.372 93.5158C106.241 89.0006 106.354 83.9966 103.417 80.2553C102.243 78.7975 
          100.752 77.6268 99.0577 76.8321C97.3632 76.0375 95.5098 75.64 93.6386 75.6697C92.726 75.6906 
          91.8196 75.8246 90.9399 76.0684C90.8485 76.0971 90.7717 76.1602 90.7258 76.2444C90.6799 76.3286 
          90.6685 76.4273 90.694 76.5198C90.7195 76.6122 90.7799 76.6911 90.8624 76.7399C90.9449 76.7887 
          91.0432 76.8035 91.1364 76.7813H91.1364Z"
          fill="#3F3D56"
        />
        <path
          d="M38.6742 72.1052C39.1974 75.8285 41.1768 79.1918 44.1775 81.4561C47.1783 83.7204 50.955 84.7004 54.6777 
          84.1809C58.4005 83.6614 61.7647 81.6848 64.0314 78.6854C66.298 75.686 67.2816 71.9093 66.766 68.1849L66.7379 
          67.9836C66.138 64.3095 64.1183 61.0184 61.1145 58.8205C58.1107 56.6225 54.3635 55.6937 50.6813 56.2345C46.999 
          56.7753 43.6769 58.7423 41.4315 61.7113C39.186 64.6802 38.1973 68.413 38.6786 72.1046L38.6742 72.1052Z"
          fill="#FFB6B6"
        />
        <path
          d="M51.1763 83.8353C50.4431 82.3579 49.6896 80.7716 49.8888 79.1342C50.088 77.4968 51.6874 75.9271 53.2646 
          76.4082C54.2078 76.6959 54.923 77.6242 55.9081 77.6685C57.2634 77.7295 58.0352 76.1755 58.4527 74.8843L60.1529 
          69.6252C63.4325 72.2024 59.2791 69.5006 63.3416 68.5571C64.9354 68.187 66.5444 67.4704 67.4755 66.1246C68.4066 
          64.7788 68.394 62.6962 67.1073 61.6852C66.4764 61.1895 65.6383 61.0031 65.0352 60.4739C64.6611 60.1456 64.4025 
          59.7056 64.2978 59.2189C64.1931 58.7322 64.2479 58.2247 64.4539 57.7716C64.66 57.3184 65.0064 56.9437 65.4419 
          56.7028C65.8774 56.4618 66.3789 56.3676 66.8721 56.4339L62.5449 55.9076L63.8258 53.5717C62.8997 54.3071 61.6076 
          54.4099 60.4431 54.2042C59.2787 53.9985 58.1811 53.5229 57.0416 53.207C51.5711 51.6909 45.2281 54.3802 42.5131 
          59.3669C41.4475 59.0149 40.3002 58.9951 39.2231 59.3101C38.146 59.6252 37.19 60.2601 36.4818 61.1309C35.3288 
          62.6048 35.0204 64.5677 34.9818 66.4389C34.91 70.1283 35.7264 73.7809 37.3621 77.0884C37.8719 78.1163 38.4745 
          79.1247 39.341 79.8766C40.2076 80.6285 50.0005 84.7937 51.1306 84.5973"
          fill="#2F2E41"
        />
        <path
          d="M153.478 74.0993C153.48 74.7389 153.398 75.376 153.235 75.9942V75.9967C153.232 76.009 153.227 76.0213 153.225 
          76.0336C152.727 77.8645 151.54 79.1508 150.153 79.1508C148.773 79.1508 147.591 77.8743 147.086 76.0583C147.051 
          75.9326 147.022 75.8069 146.995 75.6763C146.96 75.531 146.933 75.3806 146.911 75.2254C146.891 75.1022 146.877 
          74.9765 146.864 74.8484C146.847 74.671 146.835 74.4936 146.832 74.3088V74.3063C146.827 74.2373 146.827 74.1683 
          146.827 74.0993V74.0377L146.83 74.0352C146.83 73.7229 146.851 73.411 146.891 73.1013C146.909 72.9707 146.928 
          72.8451 146.953 72.7194C146.983 72.5666 147.015 72.4163 147.054 72.2685C147.086 72.1379 147.123 72.0122 147.162 
          71.8865C147.217 71.719 147.276 71.5563 147.342 71.4011C147.384 71.3001 147.428 71.2015 147.473 71.1054C148.079 
          69.8585 149.054 69.0479 150.153 69.0479C151.673 69.0479 152.954 70.5978 153.35 72.712C153.355 72.744 153.363 
          72.7761 153.365 72.8081V72.8106C153.441 73.2359 153.479 73.6672 153.478 74.0993Z"
          fill="#E6E6E6"
        />
        <path
          d="M147.505 71.2139C147.503 71.2591 147.486 71.3024 147.457 71.3365C147.427 71.3706 147.387 71.3935 147.342 
        71.4012C147.384 71.3001 147.428 71.2016 147.473 71.1055C147.493 71.1379 147.504 71.1755 147.505 71.2139Z"
        />
        <path
          d="M150.418 70.9902H148.25C148.144 70.9902 148.058 71.076 148.058 71.1818C148.058 71.2875 148.144 71.3733 
        148.25 71.3733H150.418C150.524 71.3733 150.609 71.2875 150.609 71.1818C150.609 71.076 150.524 70.9902 150.418 
        70.9902Z"
        />
        <path
          d="M147.438 72.0765C147.438 72.1274 147.418 72.1763 147.382 72.2123C147.346 72.2483 147.297 72.2686 147.246 
        72.2687H147.054C147.086 72.1381 147.123 72.0124 147.162 71.8867H147.246C147.297 71.8867 147.345 71.9065 147.381 
        71.9421C147.417 71.9776 147.438 72.0259 147.438 72.0765Z"
        />
        <path
          d="M148.378 71.8867H148.029C147.923 71.8867 147.837 71.9725 147.837 72.0782C147.837 72.184 147.923 72.2698 
        148.029 72.2698H148.378C148.483 72.2698 148.569 72.184 148.569 72.0782C148.569 71.9725 148.483 71.8867 148.378 
        71.8867Z"
        />
        <path
          d="M151.482 71.8545H149.313C149.207 71.8545 149.122 71.9402 149.122 72.046C149.122 72.1518 149.207 72.2375 
        149.313 72.2375H151.482C151.587 72.2375 151.673 72.1518 151.673 72.046C151.673 71.9402 151.587 71.8545 151.482 
        71.8545Z"
        />
        <path
          d="M148.311 72.751H147.962C147.856 72.751 147.771 72.8367 147.771 72.9425C147.771 73.0483 147.856 
        73.134 147.962 73.134H148.311C148.417 73.134 148.502 73.0483 148.502 72.9425C148.502 72.8367 148.417 
        72.751 148.311 72.751Z"
        />
        <path
          d="M149.441 72.751H149.093C148.987 72.751 148.901 72.8367 148.901 72.9425C148.901 73.0483 148.987 
        73.134 149.093 73.134H149.441C149.547 73.134 149.633 73.0483 149.633 72.9425C149.633 72.8367 149.547 72.751 
        149.441 72.751Z"
        />
        <path
          d="M147.419 72.9095C147.419 72.9604 147.398 73.0093 147.362 73.0453C147.326 73.0813 147.277 73.1016 
        147.226 73.1017H146.891C146.909 72.9711 146.928 72.8454 146.953 72.7197H147.226C147.252 72.7195 147.276 
        72.7242 147.3 72.7336C147.323 72.7431 147.344 72.757 147.362 72.7746C147.38 72.7923 147.394 72.8132 
        147.404 72.8364C147.414 72.8595 147.419 72.8844 147.419 72.9095Z"
        />
        <path
          d="M152.545 72.7188H150.377C150.271 72.7188 150.185 72.8045 150.185 72.9103C150.185 73.0161 
        150.271 73.1018 150.377 73.1018H152.545C152.651 73.1018 152.737 73.0161 152.737 72.9103C152.737 
        72.8045 152.651 72.7188 152.545 72.7188Z"
        />
        <path
          d="M147.505 74.1717C147.505 74.1968 147.5 74.2216 147.49 74.2448C147.48 74.2679 147.466 74.2889 
          147.448 74.3065C147.43 74.3242 147.409 74.3381 147.386 74.3475C147.363 74.357 147.338 74.3617 
          147.313 74.3614H146.965C146.916 74.3616 146.868 74.3431 146.832 74.3097V74.3072C146.827 74.2382 
          146.827 74.1692 146.827 74.1002V74.0386L146.83 74.0362C146.847 74.0182 146.869 74.0039 146.892 
          73.9942C146.915 73.9844 146.94 73.9795 146.965 73.9795H147.313C147.364 73.9796 147.413 73.9998 
          147.449 74.0359C147.485 74.0719 147.505 74.1207 147.505 74.1717Z"
          fill="#CCCCCC"
        />
        <path
          d="M150.418 73.9473H148.25C148.144 73.9473 148.058 74.033 148.058 74.1388C148.058 74.2446 148.144 
          74.3303 148.25 74.3303H150.418C150.524 74.3303 150.609 74.2446 150.609 74.1388C150.609 74.033 
          150.524 73.9473 150.418 73.9473Z"
          fill="#CCCCCC"
        />
        <path
          d="M147.438 75.0337C147.438 75.0846 147.418 75.1335 147.382 75.1695C147.346 75.2055 147.297 75.2258 
          147.246 75.2259H146.911C146.891 75.1027 146.877 74.977 146.864 74.8489C146.875 74.8448 146.887 
          74.8431 146.899 74.8439H147.246C147.297 74.8439 147.345 74.8638 147.381 74.8993C147.417 74.9348 
          147.438 74.9831 147.438 75.0337Z"
          fill="#CCCCCC"
        />
        <path
          d="M148.378 74.8438H148.029C147.923 74.8438 147.837 74.9295 147.837 75.0353C147.837 75.1411 
          147.923 75.2268 148.029 75.2268H148.378C148.483 75.2268 148.569 75.1411 148.569 75.0353C148.569 
          74.9295 148.483 74.8438 148.378 74.8438Z"
          fill="#CCCCCC"
        />
        <path
          d="M151.482 74.8115H149.313C149.207 74.8115 149.122 74.8973 149.122 75.003C149.122 75.1088 149.207 
          75.1946 149.313 75.1946H151.482C151.587 75.1946 151.673 75.1088 151.673 75.003C151.673 74.8973 
          151.587 74.8115 151.482 74.8115Z"
          fill="#CCCCCC"
        />
        <path
          d="M148.311 75.708H147.962C147.856 75.708 147.771 75.7938 147.771 75.8995C147.771 76.0053 147.856 
          76.0911 147.962 76.0911H148.311C148.417 76.0911 148.502 76.0053 148.502 75.8995C148.502 75.7938 
          148.417 75.708 148.311 75.708Z"
          fill="#CCCCCC"
        />
        <path
          d="M149.441 75.708H149.093C148.987 75.708 148.901 75.7938 148.901 75.8995C148.901 76.0053 148.987 
          76.0911 149.093 76.0911H149.441C149.547 76.0911 149.633 76.0053 149.633 75.8995C149.633 75.7938 
          149.547 75.708 149.441 75.708Z"
          fill="#CCCCCC"
        />
        <path
          d="M147.419 75.8665C147.419 75.9175 147.398 75.9663 147.362 76.0023C147.326 76.0384 147.277 76.0586 
          147.227 76.0587H147.086C147.052 75.933 147.022 75.8074 146.995 75.6768H147.227C147.252 75.6765 
          147.276 75.6812 147.3 75.6907C147.323 75.7001 147.344 75.714 147.362 75.7316C147.38 75.7493 147.394 
          75.7703 147.404 75.7934C147.414 75.8166 147.419 75.8414 147.419 75.8665Z"
          fill="#CCCCCC"
        />
        <path
          d="M152.545 75.6758H150.377C150.271 75.6758 150.185 75.7615 150.185 75.8673C150.185 75.9731 150.271 
          76.0588 150.377 76.0588H152.545C152.651 76.0588 152.737 75.9731 152.737 75.8673C152.737 75.7615 
          152.651 75.6758 152.545 75.6758Z"
          fill="#CCCCCC"
        />
      </g>
      <defs>
        <clipPath id="clip0_953_3104">
          <rect width="182" height="142" fill="white" transform="translate(0.5 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
