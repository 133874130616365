import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import useBrowsePaths from '../../hooks/useBrowsePaths';
import Container from '../../components/Container/Container';
import Title from '../../components/Title/Title';
import StatusLegend from '../../components/ContentStatus/StatusLegend';
import PathTable from '../../components/Path/PathTable';
import PageLoadingState from '../../components/Loading/PageLoadingState';
import { getOptions, handleEnroll } from '../../utils/pathsUtil';
import PathSubscribeButton from '../../components/PathSubscribeButton/PathSubscribeButton';

const BrowseThreatActorPaths = inject(
  'commonStore',
  'authStore',
  'userStore',
  'enrollmentStore'
)(
  observer(({ commonStore, authStore, userStore, enrollmentStore }) => {
    const { loading, error, paths, pathType, fetchPaths } = useBrowsePaths();
    const navigate = useNavigate();
    const contentTypeId = paths && paths.length ? paths[0].content_type.id : null;

    useEffect(() => {
      fetchPaths('threatActors');
      commonStore.setPageTitle(`Threat Actor Campaigns | Cybrary`);
      authStore.fireAttributionEvent();
    }, []);

    return (
      <PageLoadingState loading={loading} error={error}>
        <Container size="lg">
          <Title title="Threat Actor Campaigns" />
          <p className="mb-6 max-w-150 text-sm text-gray-600">
            Work through realistic attack scenarios to learn how adversaries will target your environment and develop the skills to defend against these threats. Subscribe to
            receive notifications when new Threat Actor Campaigns are published.
          </p>

          <div className="flex flex-col justify-between items-center w-full lg:flex-row">
            {contentTypeId && <PathSubscribeButton contentTypeId={contentTypeId} />}
            <div className="w-full lg:max-w-min">
              <StatusLegend hideNoContent />
            </div>
          </div>
          <PathTable
            paths={paths}
            pathType={pathType}
            userStore={userStore}
            commonStore={commonStore}
            enrollmentStore={enrollmentStore}
            navigate={navigate}
            getOptions={getOptions}
            handleEnroll={handleEnroll}
          />
        </Container>
      </PageLoadingState>
    );
  })
);

export default BrowseThreatActorPaths;
