import React from 'react';
import Container from '../Container/Container';
import Icon from '../Icon/Icon';

function ScreenClose({ isOpen, handleClose }) {
  return !isOpen ? null : (
    <button onClick={handleClose} className="fixed inset-0 w-full h-full cursor-default">
      &nbsp;
    </button>
  );
}

function CloseX({ handleClose }) {
  return (
    <button className="absolute -top-4 right-4 focus:outline-none lg:right-0" onClick={handleClose}>
      <Icon name="x" className="w-6 h-6 text-white" />
    </button>
  );
}

function SuccessBanner({ isOpen, gotoNextActivity, isLast, isLastIncomplete, gotoCompletePage, toggleSuccessBannerDisplay }) {
  const displayClass = isOpen ? `translate-y-0 visible` : `translate-y-full invisible`;
  const gradientClass = 'bg-gradient-to-br lg:bg-gradient-to-r from-cyb-pink-600 to-cyb-purple-500';
  const content = isLastIncomplete
    ? {
        title: 'Course Complete!',
        button: {
          text: 'Exit',
          action: gotoCompletePage,
        },
      }
    : {
        title: 'Great Job!',
        button: {
          text: 'Continue',
          action: isLast ? gotoCompletePage : gotoNextActivity,
        },
      };

  return (
    <>
      <ScreenClose isOpen={isOpen} handleClose={toggleSuccessBannerDisplay} />
      <div className={`w-full py-8 flex fixed -bottom-0 left-0 right-0 transition-all duration-300 transform z-50 ${displayClass} ${gradientClass}`}>
        <Container size="md" className="flex flex-col justify-between items-center w-full lg:flex-row">
          <CloseX handleClose={toggleSuccessBannerDisplay} />
          <div className="w-full lg:w-2/3">
            <h2 className="mb-3 text-2xl font-black text-white">{content.title}</h2>
          </div>
          <div className="flex justify-center items-center w-full lg:w-1/3">
            <button
              className="py-2.5 px-6 mx-auto w-full h-10 text-sm font-bold text-center text-white bg-cyb-pink-500 hover:bg-pink-600 rounded-sm focus:outline-none lg:w-auto"
              onClick={content.button.action}
            >
              {content.button.text}
            </button>
          </div>
        </Container>
      </div>
    </>
  );
}

export default SuccessBanner;
