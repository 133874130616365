import React from 'react';

export default function Megaphone({ classes, solid, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';
  if (solid) {
    return (
      <svg className={className} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...accessibilityProps}>
        <path
          d="M10.9354 0.752153C10.9814 0.607585 10.9686 0.450703 10.8999 0.315467C10.8311 0.180231 10.7118 0.0775225 10.5679 0.0295746C10.4239 -0.0183733 10.2669 -0.00770242 
          10.1308 0.0592773C9.99462 0.126257 9.89034 0.244156 9.84049 0.387453L9.26344 2.11785C9.2174 2.26242 9.23015 2.4193 9.29893 2.55454C9.3677 2.68977 9.48697 2.79248 
          9.63092 2.84043C9.77487 2.88838 9.9319 2.87771 10.068 2.81073C10.2042 2.74375 10.3085 2.62585 10.3583 2.48255L10.9354 0.752153ZM14.45 0.739073C14.5581 0.847271 
          14.6188 0.993939 14.6188 1.14686C14.6188 1.29978 14.5581 1.44645 14.45 1.55465L12.5265 3.47817C12.4737 3.53486 12.41 3.58034 12.3392 3.61188C12.2684 3.64342 12.192 
          3.66038 12.1145 3.66174C12.037 3.66311 11.9601 3.64886 11.8882 3.61983C11.8163 3.59081 11.7511 3.54761 11.6963 3.49282C11.6415 3.43802 11.5983 3.37275 11.5693 
          3.30089C11.5402 3.22904 11.526 3.15208 11.5274 3.0746C11.5287 2.99711 11.5457 2.9207 11.5772 2.84992C11.6088 2.77913 11.6542 2.71542 11.7109 2.66259L13.6344 
          0.739073C13.7426 0.631009 13.8893 0.570311 14.0422 0.570311C14.1952 0.570311 14.3418 0.631009 14.45 0.739073ZM7.59459 1.90857C7.39607 1.69599 7.14871 1.53502 
          6.87394 1.43961C6.59917 1.3442 6.30527 1.31723 6.01773 1.36104C5.73018 1.40484 5.45765 1.5181 5.22376 1.69101C4.98987 1.86391 4.80166 2.09124 4.67545 2.35329L0.137478 
          11.7709C0.00962694 12.036 -0.0304337 12.3349 0.0231072 12.6244C0.0766481 12.9138 0.221006 13.1786 0.435239 13.3805L1.54242 14.4245C1.74086 14.6116 1.99012 14.736 
          2.25894 14.782C2.52777 14.828 2.80419 14.7936 3.05354 14.6831L4.14379 14.1999C4.48016 14.9371 5.09373 15.5119 5.85132 15.7996C6.6089 16.0872 7.44936 16.0645 8.19027 
          15.7363C8.93118 15.4081 9.51275 14.8009 9.80874 14.0465C10.1047 13.2921 10.0913 12.4515 9.77125 11.707L12.4026 10.5406C12.6634 10.425 12.8928 10.2488 13.0717 
          10.0265C13.2505 9.80425 13.3736 9.54244 13.4307 9.26294C13.4878 8.98343 13.4772 8.69433 13.3998 8.41975C13.3224 8.14518 13.1805 7.89309 12.9858 7.68453L7.59459 
          1.90857ZM8.71562 12.174C8.90246 12.6368 8.90213 13.1541 8.71468 13.6166C8.52724 14.0791 8.16736 14.4507 7.71106 14.6529C7.25476 14.855 6.73775 14.8719 6.26924 
          14.6999C5.80073 14.528 5.41739 14.1806 5.20019 13.7313L8.71562 12.174ZM12.3111 5.37861C12.3111 5.22556 12.3719 5.07879 12.4801 4.97057C12.5883 4.86235 12.7351 
          4.80155 12.8881 4.80155H14.4269C14.58 4.80155 14.7268 4.86235 14.835 4.97057C14.9432 5.07879 15.004 5.22556 15.004 5.37861C15.004 5.53165 14.9432 5.67843 14.835 
          5.78665C14.7268 5.89487 14.58 5.95566 14.4269 5.95566H12.8881C12.7351 5.95566 12.5883 5.89487 12.4801 5.78665C12.3719 5.67843 12.3111 5.53165 12.3111 5.37861Z"
        />
      </svg>
    );
  }
  return (
    <svg className={className} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...accessibilityProps}>
      <path
        d="M10.9356 1.01147C10.9817 0.866892 10.9689 0.710002 10.9001 0.57476C10.8314 0.439517 10.7121 0.336804 10.5681 0.288853C10.4242 0.240903 10.2671 0.251575 10.131 
        0.318557C9.99485 0.38554 9.89056 0.503445 9.84071 0.646749L9.26362 2.37723C9.21759 2.52181 9.23034 2.6787 9.29912 2.81394C9.3679 2.94918 9.48717 3.0519 9.63113 
        3.09985C9.77508 3.1478 9.93212 3.13712 10.0683 3.07014C10.2044 3.00316 10.3087 2.88525 10.3585 2.74195L10.9356 1.01147ZM14.4505 0.998386C14.5585 1.10659 14.6192 
        1.25326 14.6192 1.40619C14.6192 1.55912 14.5585 1.70579 14.4505 1.814L12.5268 3.73761C12.474 3.79431 12.4103 3.83979 12.3395 3.87133C12.2687 3.90287 12.1923 3.91983 
        12.1148 3.9212C12.0373 3.92256 11.9604 3.90831 11.8885 3.87928C11.8167 3.85026 11.7514 3.80706 11.6966 3.75226C11.6418 3.69746 11.5986 3.63219 11.5696 3.56033C11.5405 
        3.48847 11.5263 3.41151 11.5276 3.33402C11.529 3.25654 11.546 3.18012 11.5775 3.10933C11.6091 3.03854 11.6545 2.97483 11.7112 2.922L13.6348 0.998386C13.743 0.890317 
        13.8897 0.829616 14.0427 0.829616C14.1956 0.829616 14.3423 0.890317 14.4505 0.998386ZM4.67542 2.61268C4.80167 2.35052 4.98996 2.12312 5.22397 1.95018C5.45798 1.77725 
        5.73064 1.664 6.0183 1.62026C6.30597 1.57652 6.59997 1.60362 6.8748 1.69919C7.14963 1.79476 7.397 1.95593 7.59547 2.16871L12.9862 7.94417C13.1808 8.15274 13.3228 
        8.40484 13.4002 8.67943C13.4776 8.95402 13.4882 9.24314 13.4311 9.52266C13.374 9.80218 13.2509 10.064 13.0721 10.2863C12.8932 10.5085 12.6638 10.6848 12.403 
        10.8004L9.77146 11.9661C10.0915 12.7106 10.105 13.5513 9.80895 14.3057C9.51295 15.0601 8.93135 15.6673 8.19041 15.9955C7.44946 16.3238 6.60896 16.3465 5.85134 
        16.0589C5.09372 15.7712 4.48012 15.1963 4.14373 14.4591L3.05343 14.9431C2.804 15.0534 2.52752 15.0877 2.25868 15.0416C1.98984 14.9955 1.7406 14.871 1.54224 
        14.6837L0.435005 13.6404C0.220929 13.4386 0.0766689 13.1739 0.0231298 12.8846C-0.0304092 12.5953 0.00955447 12.2965 0.137229 12.0315L4.67542 2.61345V2.61268ZM5.20018 
        13.992C5.4167 14.4426 5.80015 14.7913 6.26929 14.964C6.73843 15.1368 7.2564 15.1201 7.71346 14.9175C8.17052 14.715 8.53076 14.3424 8.71785 13.8788C8.90495 13.4152 
        8.90421 12.897 8.71578 12.4339L5.19941 13.992H5.20018ZM6.75138 2.95586C6.68094 2.88046 6.59318 2.82337 6.4957 2.78953C6.39822 2.7557 6.29397 2.74613 6.19196 
        2.76167C6.08995 2.77721 5.99327 2.81737 5.91029 2.8787C5.8273 2.94002 5.76051 3.02064 5.71571 3.11359L1.17752 12.5324C1.15592 12.5766 1.14905 12.6267 1.15791 
        12.6751C1.16677 12.7236 1.1909 12.7679 1.22676 12.8017L2.334 13.8451C2.36705 13.8762 2.40855 13.8969 2.4533 13.9045C2.49806 13.9122 2.54408 13.9065 2.58561 
        13.8881L11.9351 9.74621C12.0279 9.70532 12.1095 9.64282 12.1731 9.56393C12.2367 9.48505 12.2805 9.39208 12.3009 9.29279C12.3212 9.19351 12.3175 9.0908 12.29 
        8.99325C12.2625 8.8957 12.2121 8.80615 12.1429 8.73209L6.75138 2.95663V2.95586ZM12.3114 5.63814C12.3114 5.48509 12.3722 5.33831 12.4804 5.23008C12.5886 5.12186 
        12.7354 5.06106 12.8885 5.06106H14.4274C14.5804 5.06106 14.7272 5.12186 14.8354 5.23008C14.9437 5.33831 15.0045 5.48509 15.0045 5.63814C15.0045 5.7912 14.9437 
        5.93798 14.8354 6.0462C14.7272 6.15443 14.5804 6.21523 14.4274 6.21523H12.8885C12.7354 6.21523 12.5886 6.15443 12.4804 6.0462C12.3722 5.93798 12.3114 5.7912 12.3114 
        5.63814Z"
        fill="black"
      />
    </svg>
  );
}
