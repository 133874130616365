import React from 'react';
import { inject, observer } from 'mobx-react';
import DynamicForm from '../../components/DynamicForm/DynamicForm';
import Loading from '../../components/Loading/Loading';
import StyledError from '../../components/Error/StyledError';
import Container from '../../components/Container/Container';

const NewslettersForm = inject('authStore')(
  observer(
    class NewslettersForm extends React.Component {
      componentDidMount() {
        this.props.authStore.fireAttributionEvent();
      }

      /**
       * construct the opt in form
       */
      addSettingsForm = (storeData) => {
        const settingsForm = {
          order: ['display_name', 'cybrary_mailing_list', 'email_reminders', 'submit'],
          fields: {
            display_name: {
              type: 'text',
              label: 'Display Name',
              description: 'This is the nickname that will be displayed all around the site',
              hidden: true,
            },
            cybrary_mailing_list: {
              type: 'checkbox',
              label: 'Cybrary Mailing List',
              options: [{ value: 'Opt Out' }],
              description: 'These emails keep you up to date with the latest in cybersecurity and site news.',
            },
            email_reminders: {
              type: 'checkbox',
              label: 'Email Reminders',
              options: [{ value: 'Opt Out' }],
              description: 'Cybrary will periodically send you a reminder email to help with keeping you on track with training.',
              hidden: true,
            },
            submit: {
              type: 'button',
              id: 'submit-settings',
              color: 'gray',
              className: 'grey-submit-button',
              label: 'Save Changes',
            },
          },
        };

        return this.props.addDefaultValues(settingsForm, storeData);
      };

      render() {
        if (this.props.settingsData.loading) {
          return (
            <Container>
              <Loading message="Loading..." />
            </Container>
          );
        }
        if (this.props.settingsData.error) {
          return (
            <Container>
              <StyledError error={this.props.settingsData.error} />
            </Container>
          );
        }
        return <DynamicForm form={this.addSettingsForm(this.props.settingsData.data)} formName="optInForm" onSubmit={this.props.handleOptInSubmit} />;
      }
    }
  )
);

export default NewslettersForm;
