import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

/**
 * Custom hook to get query params from url
 * @returns {object} queryParams - object containing query params from url
 */
function useQueryParams() {
  const location = useLocation();
  return location?.search ? queryString.parse(location.search) : {};
}

export default useQueryParams;
