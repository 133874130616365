import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import withRouter from '../../components/Router/withRouter';
import MitreContext from '../../contexts/MitreContext';
import Container from '../../components/Container/Container';
import Title from '../../components/Title/Title';
import MitreCatalog from '../../components/Mitre/MitreCatalog';
import MitreTechniqueContent from '../../components/Mitre/MitreTechniqueContent';
import SideContentSlideout from '../../components/SideContentSlideout/SideContentSlideout';
import useMitreCatalog from '../../hooks/useMitreCatalog';
import MitreSlideoutHeader from '../../components/Mitre/MitreSlideoutHeader';

const Mitre = inject(
  'commonStore',
  'authStore'
)(
  observer(({ commonStore, authStore }) => {
    const { techniqueSideSectionData, setTechniqueSideSectionData, subTechniqueSideSectionData, setSubTechniqueSideSectionData, resetTechniqueData, techniqueDataValues } =
      useMitreCatalog();
    useEffect(() => {
      commonStore.setPageTitle(`MITRE ATT&CK | Cybrary`);
      authStore.fireAttributionEvent();
    }, []);

    return (
      <MitreContext.Provider value={techniqueDataValues}>
        <SideContentSlideout open={!!techniqueSideSectionData}>
          <SideContentSlideout.Main>
            <div className="px-4">
              <Container size="xl-1680" omitPadding>
                <Title title="MITRE ATT&amp;CK" omitPadding wrapperClasses="pt-8 md:pt-12 mb-4" />
                <p className="pb-2 mb-2 max-w-150 text-sm text-gray-600 border-b-1 border-gray-400">
                  Get hands-on experience in detecting real-world techniques employed by threat actors to become more effective in your role and better protect your organization.
                </p>
                <MitreCatalog commonStore={commonStore} />
              </Container>
            </div>
          </SideContentSlideout.Main>
          <SideContentSlideout.SideSection width="w-full md:w-132" labelledById={techniqueSideSectionData && techniqueSideSectionData.buttonId}>
            <SideContentSlideout.SideHeader>
              <MitreSlideoutHeader
                closeSlideout={resetTechniqueData}
                subTechniqueSideSectionData={subTechniqueSideSectionData}
                techniqueSideSectionData={techniqueSideSectionData}
                setSubTechniqueSideSectionData={setSubTechniqueSideSectionData}
                setTechniqueSideSectionData={setTechniqueSideSectionData}
              />
            </SideContentSlideout.SideHeader>
            <SideContentSlideout.SideContent className="py-6 px-4 mx-auto max-w-132">
              <MitreTechniqueContent data={subTechniqueSideSectionData || techniqueSideSectionData} focusOnDataChange />
            </SideContentSlideout.SideContent>
          </SideContentSlideout.SideSection>
        </SideContentSlideout>
      </MitreContext.Provider>
    );
  })
);

export default withRouter(Mitre);
