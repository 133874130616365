import React from 'react';
import Tooltip from '../Tooltip/Tooltip';
import UserIcon from '../../Icons/UserIcon';

function AvatarImg({ data, avatarSizes }) {
  if (!data) {
    return null;
  }

  const imgClasses = `${avatarSizes || 'w-8 h-8'} rounded-full align-bottom inline-block`;
  return (
    <span>
      {data.avatar_url && data.avatar_url !== 'https://assets.cybrary.it/uploads/user-avatars/profile.jpg' ? (
        <img className={`${imgClasses} border b-gray-600`} alt="" src={data.avatar_url} />
      ) : (
        <UserIcon classes={`${imgClasses} text-gray-600 bg-white`} />
      )}
    </span>
  );
}

function AvatarsList({ data, disablePopup, limit = 3, avatarSizes, stacked }) {
  if (!data || !data.length) {
    return null;
  }

  if (stacked) {
    return data.map((user) => {
      return (
        <div key={user.id} className="flex items-center">
          <AvatarImg data={user} avatarSizes={avatarSizes} />
          <span className="ml-2 text-sm">{user.name}</span>
        </div>
      );
    });
  }

  const avatars = [];
  for (let i = 0; i < limit; i++) {
    if (i >= data.length) {
      break;
    }
    const user = data[i];
    avatars.push(
      <span key={user.id} className={i > 0 ? '-ml-2' : ''}>
        <Tooltip disabled={!!disablePopup} position="right" triggerContent={<AvatarImg data={user} avatarSizes={avatarSizes} />} content={user.name} />
      </span>
    );
  }
  // All other users are shown in list
  const extraAvatars = [];
  if (data.length > limit) {
    for (let i = limit; i < data.length; i++) {
      const user = data[i];
      extraAvatars.push(<div>{user.name}</div>);
    }
    avatars.push(
      <Tooltip
        position="right"
        key="extra-users"
        triggerContent={
          <span className={`${avatarSizes || 'w-8 h-8'} rounded-full align-bottom inline-block -ml-2 border border-gray-600 bg-gray-600/60`}>
            <div className="mt-1 text-sm text-center text-white">{`+${extraAvatars.length}`}</div>
          </span>
        }
        content={extraAvatars}
      />
    );
  }

  return <div className="avatars-list">{avatars}</div>;
}

export default AvatarsList;
