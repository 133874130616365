import React from 'react';

function BlankTemplate() {
  // Returns the CSS needed to create a blank template.
  return (
    <style
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: `
          .primary-navbar,
          .sidebar-navbar,
          .app-footer,
          .primary-sidebar, 
          .app-content > .menu-container
          {
            display: none;
          }
          .cyb-router {
            height: auto !important;
          }
          .cyb-app {
            overflow: auto;
          }
          .app-footer .footer-nav {
            display: none !important;
          }
        `,
      }}
    />
  );
}

export default BlankTemplate;
