import React from 'react';
import DynamicForm from '../DynamicForm/DynamicForm';
import LoginLinks from './LoginLinks';
import { APP_FORM_IDS } from '../../constants';

function Forgot({ startValidation, endValidation, onSubmit, submitting }) {
  const forgotPasswordForm = {
    name: 'Forgot Password Form',
    order: ['email', 'submit'],
    fields: {
      email: {
        type: 'email',
        label: 'Email',
        validations: ['required', 'email'],
        disabled: submitting,
      },
      submit: {
        type: 'button',
        color: 'pink',
        loading: submitting,
        label: 'Request',
        disabled: submitting,
        className: 'w-full grid justify-items-center',
      },
    },
  };
  return (
    <>
      <h1 className="my-8 text-2xl font-black text-center md:text-3xl lg:text-5xl">Forgot Password</h1>
      <DynamicForm
        formId={APP_FORM_IDS.AUTH.FORGOT_PASSWORD}
        customClassName="text-base"
        form={forgotPasswordForm}
        startValidation={startValidation}
        endValidation={endValidation}
        onSubmit={onSubmit}
      />
      <LoginLinks omitForgotPasswordLink />
    </>
  );
}

export default Forgot;
