import { ProviderId } from 'firebase/auth';
import { twMerge } from 'tailwind-merge';
import ALL_COUNTRIES from './utils/countries';

// content groupings in the backend/algolia
const CONTENT_GROUPS = {
  VIRTUAL_LAB: 'Virtual Lab',
  CYBRARY_LAB: 'Cybrary Lab',
  COURSE: 'Course',
  GENERIC_COLLECTION: 'Generic Collection',
  PRACTICE_TEST: 'Practice Test',
  PRACTICE_LABS: 'Practice Labs Lab',
  CHALLENGE: 'Challenge',
  CERTIFICATION_PREP: 'Certification Prep',
  LIVE_TRAINING: 'Live Training',
  LIVE_TRAINING_COURSE: 'Live Training Course',
  ASSESSMENT: 'Assessment',
  ASSESSMENT_PATH: 'Assessment Path',
  ASSESSMENT_ACTIVITY: 'Assessment Activity',
  SKILL_PROGRAM: 'Skill Path',
  CAREER_PATH: 'Career Path',
  THREAT_ACTOR_CAMPAIGN: 'Threat Actor Campaign',
  CURRICULUM: 'Curriculum',
  MICROCOURSE: 'MicroCourse',
};

// Content type id's and groupings in the backend/algolia
export const CONTENT_TYPES = {
  COURSE: {
    id: 1,
    group: CONTENT_GROUPS.COURSE,
  },
  VIDEO_ACTIVITY: {
    id: 2,
  },
  MODULE: {
    id: 3,
  },
  ARTICLE_ACTIVITY: {
    id: 4,
  },
  CURRICULUM: {
    id: 5,
    group: CONTENT_GROUPS.CURRICULUM,
  },
  CAREER_PATH: {
    id: 6,
    group: CONTENT_GROUPS.CAREER_PATH,
  },
  JOB_DESCRIPTION: {
    id: 7,
  },
  ASSESSMENT: {
    id: 8,
    group: CONTENT_GROUPS.ASSESSMENT,
  },
  ASSESSMENT_QUESTION: {
    id: 9,
  },
  ASSESSMENT_CYBERSKYLINE: {
    id: 10,
  },
  PRACTICE_LABS: {
    id: 11,
    group: CONTENT_GROUPS.PRACTICE_LABS,
  },
  LAB_CYBERSCORE: {
    id: 12,
  },
  MICROCOURSE: {
    id: 13,
    group: CONTENT_GROUPS.MICROCOURSE,
  },
  PRACTICE_TEST_CYBERVISTA: {
    id: 14,
  },
  ASSESSMENT_CYDEFE: {
    id: 15,
  },
  LAB_RANGEFORCE: {
    id: 16,
  },
  CAREER_PATH_LEGACY: {
    id: 17,
    group: CONTENT_GROUPS.CAREER_PATH,
  },
  PRACTICE_TEST: {
    id: 18,
    group: CONTENT_GROUPS.PRACTICE_TEST,
  },
  CYBRARY_LIVE_SESSION: {
    id: 19,
  },
  CYBRARY_LIVE_SERIES: {
    id: 20,
  },
  TEST_IMOCHA: {
    id: 21,
  },
  LEARN_ON_DEMAND_PRO: {
    id: 22,
  },
  LEARN_ON_DEMAND_SELF: {
    id: 23,
  },
  MODULE_PRACTICE_LABS: {
    id: 24,
  },
  COURSE_NEXT_TECH: {
    id: 25,
  },
  ACTIVITY_NEXT_TECH: {
    id: 26,
  },
  CHALLENGE_NEXT_TECH: {
    id: 27,
  },
  LESSON_NEXT_TECH: {
    id: 28,
  },
  PROJECT_NEXT_TECH: {
    id: 29,
  },
  QUIZ_NEXT_TECH: {
    id: 30,
  },
  READING_NEXT_TECH: {
    id: 31,
  },
  ASSESSMENT_RANGEFORCE: {
    id: 32,
  },
  ASSESSMENT_LEARN_ON_DEMAND: {
    id: 33,
  },
  LAB_INFOSEC_LEARNING: {
    id: 34,
  },
  COURSE_AVATAO: {
    id: 35,
  },
  CHALLENGE_AVATAO: {
    id: 36,
  },
  TUTORIAL_AVATAO: {
    id: 37,
  },
  LESSON_ACTIVITY: {
    id: 38,
  },
  THREAT_ACTOR_PATH: {
    id: 39,
    group: CONTENT_GROUPS.THREAT_ACTOR_CAMPAIGN,
  },
  CYBRARY_LAB: {
    id: 40,
    group: CONTENT_GROUPS.CYBRARY_LAB,
  },
  CERTIFICATION_PREP_PATH: {
    id: 41,
    group: CONTENT_GROUPS.CERTIFICATION_PREP,
  },
  LIVE_TRAINING_COURSE: {
    id: 42,
    group: CONTENT_GROUPS.LIVE_TRAINING_COURSE,
  },
  CYBRARY_SELECT_PATH: {
    id: 43,
  },
  CYBRARY_LAB_COURSE: {
    id: 44,
    group: CONTENT_GROUPS.VIRTUAL_LAB,
  },
  CYBRARY_ASSESSMENT: {
    id: 45,
    group: CONTENT_GROUPS.ASSESSMENT_ACTIVITY,
  },
  CYBRARY_ASSESSMENT_COURSE: {
    id: 46,
    group: CONTENT_GROUPS.ASSESSMENT,
  },
  CYBRARY_ASSESSMENT_PATH: {
    id: 47,
    group: CONTENT_GROUPS.ASSESSMENT_PATH,
  },
  COLLECTION: {
    id: 48,
    group: CONTENT_GROUPS.GENERIC_COLLECTION,
  },
  CYBRARY_LAB_V2: {
    id: 49,
    group: CONTENT_GROUPS.CYBRARY_LAB,
  },
  CAREER_PROGRAM: {
    id: 50,
    group: CONTENT_GROUPS.CAREER_PATH,
  },
  CHALLENGE: {
    id: 51,
    group: CONTENT_GROUPS.CHALLENGE,
  },
  SKILL_PROGRAM: {
    id: 52,
    group: CONTENT_GROUPS.SKILL_PROGRAM,
  },
  CERT_PREP_PROGRAM: {
    id: 53,
    group: CONTENT_GROUPS.CERTIFICATION_PREP,
  },
};

export const COUNTRIES = ALL_COUNTRIES;

/** Roles shared in both cip & teams form... for now... */
export const SHARED_ROLES = [
  {
    text: 'C-Suite Executive',
    value: 'C-Suite Executive',
  },
  {
    text: 'SVP, VP, Department Head',
    value: 'SVP, VP, Department Head',
  },
  {
    text: 'Director, Sr. Director',
    value: 'Director, Sr. Director',
  },
  {
    text: 'Manager, Sr. Manager',
    value: 'Manager, Sr. Manager',
  },
  {
    text: 'Infosec Generalist',
    value: 'Infosec Generalist',
  },
  {
    text: 'Architect',
    value: 'Architect',
  },
  {
    text: 'Analyst',
    value: 'Analyst',
  },
  {
    text: 'Engineer',
    value: 'Engineer',
  },
  {
    text: 'Developer',
    value: 'Developer',
  },
];

/** Departments shared in both cip & teams form... for now... */
export const SHARED_DEPARTMENTS = [
  {
    text: 'Data Science',
    value: 'Data Science',
  },
  {
    text: 'Engineering',
    value: 'Engineering',
  },
  {
    text: 'IT',
    value: 'IT',
  },
  {
    text: 'Learning & Development',
    value: 'Learning & Development',
  },
  {
    text: 'Operations',
    value: 'Operations',
  },
  {
    text: 'QA/Testing',
    value: 'QA/Testing',
  },
  {
    text: 'Risk & Compliance',
    value: 'Risk & Compliance',
  },
  {
    text: 'Sales Engineering',
    value: 'Sales Engineering',
  },
  {
    text: 'Sales Operation',
    value: 'Sales Operation',
  },
  {
    text: 'Security',
    value: 'Security',
  },
  {
    text: 'Support/Help Desk',
    value: 'Support/Help Desk',
  },
  {
    text: 'Technical Marketing',
    value: 'Technical Marketing',
  },
];
export const TEAM_LEADS = [
  {
    text: 'I lead practitioners',
    value: 'I lead practitioners',
  },
  {
    text: 'I lead a team of managers',
    value: 'I lead a team of managers',
  },
  {
    text: 'Both',
    value: 'Both',
  },
  {
    text: 'No',
    value: 'No',
  },
];

export const TEAM_LEAD_ROLES = ['Both', 'I lead practitioners', 'I lead a team of managers'];
export const PRACTITIONER_ROLES = ['Infosec Generalist', 'Architect', 'Analyst', 'Engineer', 'Developer'];
export const MANAGER_PLUS_ROLES = ['C-Suite Executive', 'SVP, VP, Department Head', 'Director, Sr. Director', 'Manager, Sr. Manager'];

export const DEFAULT_COOKIE_SETTINGS = {
  domain: process.env.NODE_ENV === 'development' ? 'localhost' : '.cybrary.it',
  secure: process.env.NODE_ENV === 'development' ? null : true,
  sameSite: process.env.NODE_ENV === 'development' ? null : 'lax',
};

// GeoIP lookup constants used in region-based pricing
export const GEO_LOOKUP_URL = 'https://8dkfarln27.execute-api.us-east-2.amazonaws.com/Prod/geoip';
export const GEO_COOKIE_NAME = 'cyb_geo';
export const GEO_DEFAULT_DATA = { planRegion: 'US', isDefaultGeoData: true };
export const GEO_COOKIE_PARAMS = {
  ...DEFAULT_COOKIE_SETTINGS,
  path: '/',
  expires: 7,
};
// Chargebee Plan IDs
export const CHARGEBEE_CIP_ANNUAL = 'cyb-insider-pro-1-yr';
export const CHARGEBEE_CIP_MONTHLY = 'cyb-insider-pro-1-mo';
export const CHARGEBEE_TEAM_ANNUAL = 'small-business-annual';
export const CHARGEBEE_CIP_ANNUAL_INDIA = 'cyb-insider-pro-1-yr_india';
export const CHARGEBEE_CIP_MONTHLY_INDIA = 'cyb-insider-pro-1-mo_india';
export const CHARGEBEE_TEAM_ANNUAL_INDIA = 'small-business-annual_india';
export const CHARGEBEE_ANNUAL_TEAMS_PLANS = [CHARGEBEE_TEAM_ANNUAL, CHARGEBEE_TEAM_ANNUAL_INDIA];
export const CHARGEBEE_ANNUAL_CIP_PLANS = [CHARGEBEE_CIP_ANNUAL, CHARGEBEE_CIP_ANNUAL_INDIA];
export const CHARGEBEE_MONTHLY_CIP_PLANS = [CHARGEBEE_CIP_MONTHLY, CHARGEBEE_CIP_MONTHLY_INDIA];
export const CHARGEBEE_ANNUAL_PLANS = [...CHARGEBEE_ANNUAL_TEAMS_PLANS, ...CHARGEBEE_ANNUAL_CIP_PLANS];
export const CHARGEBEE_REGIONS = {
  US: {
    cip: {
      monthly: CHARGEBEE_CIP_MONTHLY,
      annual: CHARGEBEE_CIP_ANNUAL,
    },
    team: {
      annual: CHARGEBEE_TEAM_ANNUAL,
    },
  },
  IN: {
    cip: {
      monthly: CHARGEBEE_CIP_MONTHLY_INDIA,
      annual: CHARGEBEE_CIP_ANNUAL_INDIA,
    },
    team: {
      annual: CHARGEBEE_TEAM_ANNUAL_INDIA,
    },
  },
};
/**
 * PADDLE
 */
export const PADDLE_COMPONENT_ID = 'paddle-wrapper';
export const PADDLE_PRICE_ID_CIP_MONTHLY = process.env.REACT_APP_PADDLE_PRICE_ID_CIP_MONTHLY;
export const PADDLE_PRICE_ID_CIP_QUARTERLY = process.env.REACT_APP_PADDLE_PRICE_ID_CIP_QUARTERLY;
export const PADDLE_PRICE_ID_CIP_ANNUAL = process.env.REACT_APP_PADDLE_PRICE_ID_CIP_ANNUAL;
export const PADDLE_PRICE_ID_CIP_BIENNIAL = process.env.REACT_APP_PADDLE_PRICE_ID_CIP_BIENNIAL;
export const PADDLE_PRICE_ID_TEAM_ANNUAL = process.env.REACT_APP_PADDLE_PRICE_ID_TEAM_ANNUAL;
export const PADDLE_DISCOUNT_ID_CIP_MONTHLY = process.env.REACT_APP_PADDLE_DISCOUNT_ID_CIP_MONTHLY;
export const PADDLE_DISCOUNT_ID_CIP_ANNUAL = process.env.REACT_APP_PADDLE_DISCOUNT_ID_CIP_ANNUAL;
export const PADDLE_DISCOUNT_ID_CIP_EDU_MIL_GOV = process.env.REACT_APP_PADDLE_DISCOUNT_ID_CIP_EDU_MIL_GOV;
export const PADDLE_DISCOUNT_ID_CIP_TWELVE_FOR_SIX = process.env.REACT_APP_PADDLE_DISCOUNT_ID_CIP_TWELVE_FOR_SIX;
export const PADDLE_DISCOUNT_ID_CIP_THREE_FOR_TWO = process.env.REACT_APP_PADDLE_DISCOUNT_ID_CIP_THREE_FOR_TWO;
export const PADDLE_DISCOUNT_ID_CIP_NOV_PROMO = process.env.REACT_APP_PADDLE_DISCOUNT_ID_CIP_NOV_PROMO;
export const PADDLE_SUBSCRIPTIONS = {
  cip: {
    monthly: PADDLE_PRICE_ID_CIP_MONTHLY,
    annual: PADDLE_PRICE_ID_CIP_ANNUAL,
    quarterly: PADDLE_PRICE_ID_CIP_QUARTERLY,
    biennial: PADDLE_PRICE_ID_CIP_BIENNIAL,
  },
  team: {
    annual: PADDLE_PRICE_ID_TEAM_ANNUAL,
  },
};
/** Default Teams cart items */
export const TEAMS_CHECKOUT_DEFAULT_ITEMS = [
  {
    priceId: PADDLE_PRICE_ID_TEAM_ANNUAL,
    quantity: 5,
  },
];
/** Default CIP cart items */
export const CIP_CHECKOUT_DEFAULT_MONTHLY_ITEMS = [
  {
    priceId: PADDLE_PRICE_ID_CIP_MONTHLY,
    quantity: 1,
  },
];
export const CIP_CHECKOUT_DEFAULT_QUARTERLY_ITEMS = [
  {
    priceId: PADDLE_PRICE_ID_CIP_QUARTERLY,
    quantity: 1,
  },
];
export const CIP_CHECKOUT_DEFAULT_ANNUAL_ITEMS = [
  {
    priceId: PADDLE_PRICE_ID_CIP_ANNUAL,
    quantity: 1,
  },
];
export const CIP_CHECKOUT_DEFAULT_BIENNIAL_ITEMS = [
  {
    priceId: PADDLE_PRICE_ID_CIP_BIENNIAL,
    quantity: 1,
  },
];
export const CIP_PLAN_QUANTITY = 1;
export const CHECKOUT_PACKAGE_MAP = {
  annual: CIP_CHECKOUT_DEFAULT_ANNUAL_ITEMS,
  monthly: CIP_CHECKOUT_DEFAULT_MONTHLY_ITEMS,
  quarterly: CIP_CHECKOUT_DEFAULT_QUARTERLY_ITEMS,
  biennial: CIP_CHECKOUT_DEFAULT_BIENNIAL_ITEMS,
  teams: TEAMS_CHECKOUT_DEFAULT_ITEMS,
};

export const CHECKOUT_PRICE_BOX_TITLES = {
  default: 'Price',
  annual: 'Annual',
  monthly: 'Monthly',
  biennial: 'Biennial',
  quarterly: 'Quarterly',
};
export const CHECKOUT_MONTHS_PER_CYCLE = {
  [PADDLE_PRICE_ID_CIP_MONTHLY]: 1,
  [PADDLE_PRICE_ID_CIP_ANNUAL]: 12,
  [PADDLE_PRICE_ID_CIP_BIENNIAL]: 24,
  [PADDLE_PRICE_ID_CIP_QUARTERLY]: 3,
};

export const PATH_OMITTED_CONTENT_TYPES = [
  'Career Path',
  'Career Program',
  'Skill Path',
  'Certification Prep',
  'Cert Prep',
  'Assessment Path',
  'Threat Actor Path',
  'Collection',
  'Cybrary Select',
];
export const PUBLIC_PAGES = [
  '/verification-code',
  '/saml-result',
  '/forgot-password',
  '/reset-password',
  '/link-account',
  '/login',
  '/account-change',
  '/email-login',
  '/acctmgmt',
];
export const BLACKLISTED_ONBOARDING_PAGES = [
  ...PUBLIC_PAGES,
  '/logout',
  '/onboarding/terms-of-service',
  '/onboarding/recovery-email',
  '/onboarding/international',
  '/upgrade/teams-checkout',
  '/upgrade/teams-checkout/paddle',
  '/upgrade/checkout',
  '/upgrade/checkout/paddle',
  '/upgrade/checkout/quarterly',
  '/google-marketplace-onboarding',
];

/*
  userTeams from the userStore has a key "role" which includes 'team-reporting-admin', 'team-admin', 'org-owner', 'team-member'
  role can be team-member but if you are a group admin or group reporting admin you can still view team dashboard
*/
export const TEAMS_DASHBOARD_ACCESS_ROLES = ['team-reporting-admin', 'team-admin', 'org-owner'];

export const CURRENCY_SYMBOLS = {
  AED: 'د.إ',
  AFN: '؋',
  ALL: 'L',
  AMD: '֏',
  ANG: 'ƒ',
  AOA: 'Kz',
  ARS: 'AR$',
  AUD: 'AU$',
  AWG: 'ƒ',
  AZN: '₼',
  BAM: 'KM',
  BBD: 'BB$',
  BDT: '৳',
  BGN: 'лв',
  BHD: '.د.ب',
  BIF: 'FBu',
  BMD: 'BM$',
  BND: 'BN$',
  BOB: '$b',
  BOV: 'BOV',
  BRL: 'R$',
  BSD: 'BS$',
  BTC: '₿',
  BTN: 'Nu.',
  BWP: 'P',
  BYN: 'Br',
  BYR: 'Br',
  BZD: 'BZ$',
  CAD: 'CA$',
  CDF: 'FC',
  CHE: 'CHE',
  CHF: 'CHF',
  CHW: 'CHW',
  CLF: 'CLF',
  CLP: 'CL$',
  CNH: '¥',
  CNY: '¥',
  COP: 'CO$',
  COU: 'COU',
  CRC: '₡',
  CUC: 'CU$',
  CUP: '₱',
  CVE: 'CV$',
  CZK: 'Kč',
  DJF: 'Fdj',
  DKK: 'kr',
  DOP: 'RD$',
  DZD: 'دج',
  EEK: 'kr',
  EGP: '£',
  ERN: 'Nfk',
  ETB: 'Br',
  ETH: 'Ξ',
  EUR: '€',
  FJD: 'FJ$',
  FKP: '£',
  GBP: '£',
  GEL: '₾',
  GGP: '£',
  GHC: '₵',
  GHS: 'GH₵',
  GIP: '£',
  GMD: 'D',
  GNF: 'FG',
  GTQ: 'Q',
  GYD: 'GY$',
  HKD: 'HK$',
  HNL: 'L',
  HRK: 'kn',
  HTG: 'G',
  HUF: 'Ft',
  IDR: 'Rp',
  ILS: '₪',
  IMP: '£',
  INR: '₹',
  IQD: 'ع.د',
  IRR: '﷼',
  ISK: 'kr',
  JEP: '£',
  JMD: 'J$',
  JOD: 'JD',
  JPY: '¥',
  KES: 'KSh',
  KGS: 'лв',
  KHR: '៛',
  KMF: 'CF',
  KPW: '₩',
  KRW: '₩',
  KWD: 'KD',
  KYD: 'KY$',
  KZT: '₸',
  LAK: '₭',
  LBP: '£',
  LKR: '₨',
  LRD: 'LR$',
  LSL: 'M',
  LTC: 'Ł',
  LTL: 'Lt',
  LVL: 'Ls',
  LYD: 'LD',
  MAD: 'MAD',
  MDL: 'lei',
  MGA: 'Ar',
  MKD: 'ден',
  MMK: 'K',
  MNT: '₮',
  MOP: 'MOP$',
  MRO: 'UM',
  MRU: 'UM',
  MUR: '₨',
  MVR: 'Rf',
  MWK: 'MK',
  MXN: 'MX$',
  MXV: 'MXV',
  MYR: 'RM',
  MZN: 'MT',
  NAD: 'NA$',
  NGN: '₦',
  NIO: 'C$',
  NOK: 'kr',
  NPR: '₨',
  NZD: 'NZ$',
  OMR: '﷼',
  PAB: 'B/.',
  PEN: 'S/.',
  PGK: 'K',
  PHP: '₱',
  PKR: '₨',
  PLN: 'zł',
  PYG: 'Gs',
  QAR: '﷼',
  RMB: '￥',
  RON: 'lei',
  RSD: 'Дин.',
  RUB: '₽',
  RWF: 'R₣',
  SAR: '﷼',
  SBD: 'SB$',
  SCR: '₨',
  SDG: 'ج.س.',
  SEK: 'kr',
  SGD: 'S$',
  SHP: '£',
  SLL: 'Le',
  SOS: 'S',
  SRD: 'SR$',
  SSP: '£',
  STD: 'Db',
  STN: 'Db',
  SVC: 'SV$',
  SYP: '£',
  SZL: 'E',
  THB: '฿',
  TJS: 'SM',
  TMT: 'T',
  TND: 'د.ت',
  TOP: 'T$',
  TRL: '₤',
  TRY: '₺',
  TTD: 'TT$',
  TVD: 'TV$',
  TWD: 'NT$',
  TZS: 'TSh',
  UAH: '₴',
  UGX: 'USh',
  USD: '$',
  UYI: 'UYI',
  UYU: '$U',
  UYW: 'UYW',
  UZS: 'лв',
  VEF: 'Bs',
  VES: 'Bs.S',
  VND: '₫',
  VUV: 'VT',
  WST: 'WS$',
  XAF: 'FCFA',
  XBT: 'Ƀ',
  XCD: 'XC$',
  XOF: 'CFA',
  XPF: '₣',
  XSU: 'Sucre',
  XUA: 'XUA',
  YER: '﷼',
  ZAR: 'R',
  ZMW: 'ZK',
  ZWD: 'Z$',
  ZWL: 'ZW$',
};
export const BASE_BUTTON_STYLES =
  'inline-block shrink-0 py-2.5 px-6 text-sm font-bold leading-5 text-center text-white hover:text-white rounded-sm bg-cyb-pink-500 hover:bg-pink-600';

export const SHARED_TOPIC_BUTTON_STYLES = twMerge('px-1 py-1.5 text-xs font-normal rounded-md min-w-[80px]');
export const SECONDARY_BUTTON_STYLES = 'inline-block shrink-0 py-2.5 px-6 text-sm font-bold leading-5 text-center text-gray-900 rounded-sm bg-transparent hover:text-black';

export const OAUTH_PROVIDERS = [
  {
    id: 'gg',
    name: 'Google',
    providerId: ProviderId.GOOGLE,
    logo: 'gg-logo',
  },
  {
    id: 'ap',
    name: 'Apple',
    providerId: 'apple.com',
    logo: 'ap-logo',
  },
  {
    id: 'ms',
    name: 'Microsoft',
    providerId: 'microsoft.com',
    logo: 'ms-logo',
  },
  {
    id: 'fb',
    name: 'Facebook',
    providerId: ProviderId.FACEBOOK,
    logo: 'fb-logo',
  },
  {
    id: 'ld',
    name: 'LinkedIn',
    providerId: 'linkedin.com',
    logo: 'ld-logo',
  },
  {
    id: 'saml',
    name: 'saml',
    providerId: 'saml.t_',
    logo: 'saml-logo',
  },
];

export const USERPILOT_EVENTS = {
  FINISHED_ONBOARDING: 'Finished Onboarding',
  FINISHED_TEAM_ONBOARDING: 'Finished Team Onboarding',
  VIEWED_BASELINE_INDIVIDUAL_REPORT: 'Viewed Baseline Individual Report',

  // IMMERSIVE
  LEFT_IMMERSIVE: 'Left Immersive',
  IMMERSIVE_INTERSTITIAL: 'Immersive Interstitial',
  IMMERSIVE_STARTED_ACTIVITY: 'Immersive Started Activity',
  IMMERSIVE_COMPLETED_ACTIVITY: 'Immersive Completed Activity',

  // TEAMS
  VIEWED_TEAM_DASHBOARD: 'Viewed Team Dashboard',
  VIEWED_DEMO_TEAM_DASHBOARD: 'Viewed Demo Team Dashboard',
  INVITE_TO_TEAM_BUTTON: 'Invite to Team Button',
  ADD_GROUP_BUTTON: 'Add Group Button',
  ADD_PATH_BUTTON: 'Add Path Button',
  ADD_GOAL_BUTTON: 'Add Goal Button',

  // PROMOS
  CIP_BANNER_CLICKED_ADD_EMAIL: 'CIP Banner Clicked Add Email',
  CIP_BANNER_CLICKED_UPGRADE: 'CIP Banner Clicked Upgrade',
};

export const APP_FORM_IDS = {
  AUTH: {
    EMAIL_LOGIN: 'AUTH_EMAIL_LOGIN_FORM',
    EMAIL_PASSWORD_LOGIN: 'AUTH_EMAIL_PASSWORD_LOGIN_FORM',
    FORGOT_PASSWORD: 'AUTH_FORGOT_PASSWORD_FORM',
    CREATE_ACCOUNT: 'AUTH_CREATE_ACCOUNT_FORM',
    DELETE_ACCOUNT: 'AUTH_DELETE_ACCOUNT_FORM',
  },
  ONBOARDING: {
    TERMS_OF_SERVICE: 'ONBOARDING_TERMS_OF_SERVICE_FORM',
    CIP: 'ONBOARDING_CIP_FORM',
    TEAM: 'ONBOARDING_TEAM_FORM',
  },
  PROFILE: {
    EDIT: 'EDIT_PROFILE_FORM',
  },
  TEAM: {
    INVITE: 'TEAM_INVITE_FORM',
    CREATE_PATH: 'TEAM_CREATE_PATH_FORM',
    EDIT_PATH: 'TEAM_EDIT_PATH_FORM',
  },
  CIP: {
    CANCELLATION: 'CIP_CANCELLATION_FROM',
  },
  ADMIN: {
    ADD_MEMBERS: 'ADMIN_ADD_MEMBERS_FORM',
  },
  CHECKOUT: {
    CREATE_ACCOUNT: 'CHECKOUT_CREATE_ACCOUNT_FORM',
    FAILED_CREATE_ACCOUNT_EMAIL: 'CHECKOUT_FAILED_CREATE_ACCOUNT_EMAIL_FORM',
    FAILED_CREATE_ACCOUNT_RECAPTCHA: 'CHECKOUT_FAILED_CREATE_ACCOUNT_RECAPTCHA_FORM',
  },
  ABM: {
    EMAIL_VERIFICATION: 'ABM_EMAIL_VERIFICATION_FORM',
  },
};

export const CONFETTI_COLORS = ['#0db350', '#e2037a', '#1a6ef4', '#e2037a', '#ECC94B'];

const HELP_BASE_URL = 'https://help.cybrary.it/hc/en-us';

export const HELP_DESK_LINKS = {
  HOMEPAGE: HELP_BASE_URL,
  EDU_MIL_GOV_UPGRADE: `${HELP_BASE_URL}/articles/29027133843603-Cybrary-Impact-Hub-Discount-Program`,
  VENDOR_REPORTING_PROGRESS: `${HELP_BASE_URL}/articles/1500012804562-Cybrary-Vendor-Reporting-Progress-Completion-Reporting`,
  ROLES_PERMS_AND_USER_ROLES: `${HELP_BASE_URL}/articles/360056805794-Roles-Permissions-User-Roles`,
  COMMITMENT_TO_WEB_ACCESSIBILITY: `${HELP_BASE_URL}/articles/1500002668302-Cybrary-s-Commitment-to-Web-Accessibility`,
  ADD_LEARNING_SCHEDULE_TO_CALENDAR: `${HELP_BASE_URL}/articles/1500011421001-Adding-Your-Learning-Schedule-to-Your-Calendar`,
  HOW_TO_REGISTER_AND_LOGIN: `${HELP_BASE_URL}/articles/18412675308691-How-to-Get-Started-Register-and-Login-to-Cybrary`,
  CYBRARY_FOR_TEAMS: `${HELP_BASE_URL}/sections/360010736014-Cybrary-for-Teams`,
};

export const LOADING_FACTS = [
  {
    icon: 'path',
    title: 'Did you know…',
    description: "Cybrary's Baselining tool empowers managers to identify and remediate skill gaps with custom training pathways.",
  },
  {
    icon: 'lock',
    title: 'Did you know…',
    description: "Cybrary for Teams can help minimize your organization's cyber risk through regular training.",
  },
  {
    icon: 'road',
    title: 'Did you know…',
    description: "Cybrary's Career Paths are comprehensive training programs that prepare learners for in-demand roles and provide a Credly badge to prove it.",
  },
  {
    icon: 'graduation-cap',
    title: 'Did you know…',
    description: "Cybrary's Cert Prep catalog covers more than 20 in-demand industry certifications with video courses, labs, and practice tests.",
  },
];

export const CYBRARY_LOGO_URL = 'https://images.ctfassets.net/kvf8rpi09wgk/6HtXDpiYT7GMU8yp0DS4Dy/5b42c213353afb410ac8e52e7db7c77f/cybrary_logo_black.svg?w=250';
