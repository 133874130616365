/* eslint-disable no-console */
import queryString from 'query-string';
import { findById } from './helpers';

/**
 * Get ip connection protocol
 * @param ipData
 * @returns
 */
const getProtocol = (vnc, windowsOS) => {
  let protocol = '';
  if (windowsOS) {
    protocol = 'rdp';
  } else if (vnc) {
    protocol = 'vnc';
  } else {
    protocol = 'ssh';
  }
  return protocol;
};

// Return the resources in order based on the order array of identifiers
export const orderResources = (resources, order, idKey = 'identifier') => {
  if (!order.length) {
    return resources;
  }
  const ordered = [];
  for (let i = 0; i < order.length; i++) {
    const identifier = order[i];
    const r = findById(resources, identifier, idKey);
    if (r) {
      ordered.push(r);
    }
  }
  return ordered.length ? ordered : resources;
};

/**
 * Get Resources from outputs object
 * @param outputs
 * @returns
 */
export const getResourceFromOutput = (outputs, resourcesInfo, labStatus) => {
  if (!outputs || !outputs.length) {
    return [];
  }

  // Order the lab outputs before formatting
  const orderedLabOutputs = outputs.sort((a, b) => a.ordinal - b.ordinal);

  return orderedLabOutputs.map((labOutput) => {
    const { name, status, data, instanceId, ordinal, labDefinitionResourceId } = labOutput;
    const { userPassword, vnc, windowsOS, networkInterfaces, connectionParams } = data;
    const { publicIp } = networkInterfaces[0];

    const resourceDefinition = resourcesInfo ? findById(resourcesInfo, labDefinitionResourceId, 'labDefinitionResourceId') : null;

    const { visible, customResourceName, labResource } = resourceDefinition || {};
    const { configuration: resourceConfig } = labResource || {};
    const { loginPassword, loginUser } = resourceConfig || {};

    const isHidden = !visible;

    const connectData = connectionParams ? queryString.parse(connectionParams) : {};
    const ret = {
      name,
      customResourceName,
      labDefinitionResourceId,
      ip: publicIp,
      protocol: getProtocol(vnc, windowsOS),
      password: userPassword,
      loginPassword,
      loginUser,
      status: labStatus !== 'running' && status === 'running' ? 'booting' : status,
      instanceId,
      ordinal,
      hidden: isHidden,
      connectable: false,
      ...connectData,
    };
    if (connectionParams) {
      // @ts-ignore
      ret['connect-string'] = connectionParams;
      ret.connectable = !isHidden;
    }
    return ret;
  });
};

/**
 * Print clab events to textarea
 * @param message
 */
export const handleClabEvent = (message) => {
  try {
    console.log(JSON.parse(message.body), 'message body');
  } catch (e) {
    console.log(e, 'error parsing message');
  }
  const textarea = document.getElementById('clab-events');
  if (textarea) {
    let newText = '';
    try {
      const obj = JSON.parse(message.body);
      const pretty = JSON.stringify(obj, undefined, 2);
      newText = pretty;
    } catch (err) {
      newText = message.body;
    }
    textarea.value = `${newText}
------------------
${textarea.value}`;
  }
};

/**
 * Format lab details
 */
export const formatLabDetails = (labResponse, definition) => {
  const { labInstanceIdentifier, labInstance, outputs, status: responseStatus, attributes } = labResponse || {};
  const { labId, identifier } = labInstance;
  const metaAttribute = findById(attributes || [], 'meta', 'key');
  const metaString = metaAttribute?.value || null;
  const meta = metaString ? JSON.parse(metaString) : {};
  const ttlAttribute = findById(attributes || [], 'ttl', 'key');
  const ttlString = ttlAttribute?.value || '60';
  const ttl = 1 * ttlString;
  const info = meta?.info || null;
  const { resourcesNew } = definition;
  const status = responseStatus || labInstance?.status;
  const { expires } = labInstance || {};
  return {
    labId,
    identifier,
    labInstanceIdentifier,
    status,
    info,
    expires,
    ttl,
    sessionId: '',
    resources: getResourceFromOutput(outputs, resourcesNew, status),
  };
};

/**
 * Get Resources by lab id, move to resource util
 * @param id
 * @param labs
 * @returns
 */
export const getResourcesByLabId = (id, labs) => {
  const lab = labs.find((item) => item.labId === id);
  return lab && lab.resources ? lab.resources : [];
};

export const padZeros = (num) => {
  return `${num}`.padStart(2, '0');
};

export const convertSecsToHHMMSS = (seconds) => {
  let secondsCopy = seconds;
  const hours = Math.floor(secondsCopy / 3600);
  secondsCopy -= hours * 3600;
  const mins = Math.floor(secondsCopy / 60);
  secondsCopy -= mins * 60;
  const secs = Math.floor(secondsCopy % 60);
  return `${padZeros(hours)}:${padZeros(mins)}:${padZeros(secs)}`;
};

export const getColorFromScore = (points, pointsEarned = 0) => {
  const percentage = (pointsEarned / points) * 100;
  if (!percentage) {
    return 'bg-red-700';
  }
  if (percentage <= 50) {
    return 'bg-orange-500';
  }
  if (percentage <= 99) {
    return 'bg-yellow-500';
  }
  return 'bg-green-600';
};
