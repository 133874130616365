import React from 'react';
import AddLink from '../AddLink/AddLink';
import { HELP_DESK_LINKS } from '../../constants';

/**
 * Sitewide footer
 */
function FooterTop({ omitTop }) {
  if (omitTop) {
    return null;
  }
  return (
    <div className="flex justify-start py-4 px-0 md:justify-center footer-nav">
      <ul className="inline-block text-sm font-semibold text-gray-600">
        <li className="inline-block relative mb-1 ml-8 md:ml-0">
          <AddLink className="hover:text-black" to={`${process.env.REACT_APP_V2_SITE_URL}/instructors/`} target="_blank" rel="noopener noreferrer">
            Instructors
          </AddLink>
        </li>
        <li className="inline-block relative mb-1 ml-8">
          <AddLink className="hover:text-black" to={`${process.env.REACT_APP_V2_SITE_URL}/podcasts/`} target="_blank" rel="noopener noreferrer">
            Podcasts
          </AddLink>
        </li>
        <li className="inline-block relative mb-1 ml-8">
          <AddLink className="hover:text-black" to={`${process.env.REACT_APP_V2_SITE_URL}/blog/`} target="_blank" rel="noopener noreferrer">
            Cybrary Blog
          </AddLink>
        </li>
        <li className="inline-block relative mb-1 ml-8">
          <AddLink className="hover:text-black" to={HELP_DESK_LINKS.HOMEPAGE} target="_blank" rel="noopener noreferrer">
            Support
          </AddLink>
        </li>
      </ul>
    </div>
  );
}

export function FooterBottom() {
  return (
    <div className="flex flex-col justify-start ml-8 md:flex-row md:justify-center md:ml-0">
      <div className="mr-2 mb-2 text-xs text-gray-600 sm:mr-6 md:mb-0">© {new Date().getFullYear()} Cybrary</div>
      <ul className="flex flex-wrap items-center text-xs md:justify-center">
        <li className="mr-2 mb-2 sm:mr-6 md:mb-0">
          <AddLink
            to={`${process.env.REACT_APP_V2_SITE_URL}/terms-service/`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-black hover:text-cyb-pink-500 underline hover:underline"
          >
            Terms of Service
          </AddLink>
        </li>
        <li className="mr-2 mb-2 sm:mr-6 md:mb-0">
          <AddLink
            to={`${process.env.REACT_APP_V2_SITE_URL}/privacy-policy/`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-black hover:text-cyb-pink-500 underline hover:underline"
          >
            Privacy Policy
          </AddLink>
        </li>
      </ul>
    </div>
  );
}

function Footer({ immersive, omitTop }) {
  return (
    <div className="relative bottom-0 px-4 pt-4 pb-8 md:p-4 app-footer">
      <FooterTop omitTop={immersive || omitTop} />
      <FooterBottom />
    </div>
  );
}

export default Footer;
