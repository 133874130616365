import React from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import Header from '../../components/Header/Header';
import Divider from '../../components/Divider/Divider';
import withRouter from '../../components/Router/withRouter';
import Title from '../../components/Title/Title';
import Loading from '../../components/Loading/Loading';
import StyledError from '../../components/Error/StyledError';
import FormatUtil from '../../utils/formatUtil';
import GaUtil from '../../utils/gaUtil';
import NotificationUtil from '../../utils/notificationUtil';
import ContentUpdateFlag from '../../components/ContentUpdates/ContentUpdateFlag';
import ContentUpdateLabel from '../../components/ContentUpdates/ContentUpdateLabel';
import CurriculumConfirm from '../../components/Enterprise/CurriculumConfirm';
import ArchivedLabel from '../../components/ContentUpdates/ArchivedLabel';
import BetaLabel from '../../components/Label/BetaLabel';
import Container from '../../components/Container/Container';
import Label from '../../components/Label/Label';

import './enterprise.css';

const Curriculum = inject(
  'userStore',
  'enterpriseStore',
  'commonStore',
  'authStore',
  'notificationsStore'
)(
  observer(
    class Curriculum extends React.Component {
      state = {
        orgId: null,
      };

      componentDidMount() {
        this.props.commonStore.setPageTitle('Path Details | Cybrary');
        this.props.authStore.fireAttributionEvent();
        this.props.userStore.setPreferredTeamInit('', this.props.match.params.orgId, this.init);
      }

      componentWillUnmount() {
        this.props.enterpriseStore.reset();
        this.props.notificationsStore.resetReplacedContent();
      }

      init = (orgId) => {
        const newState = {
          ...this.state,
          orgId,
        };
        this.setState(newState);
        this.props.enterpriseStore.loadCurriculum(orgId, this.props.match.params.id).then(() => {
          this.props.enterpriseStore.setCurriculumLoading(false);
          // If this curriculum has content replaced, need to get those details
          if (this.props.enterpriseStore.curriculum && this.props.enterpriseStore.curriculum.content_replaced_at) {
            this.props.notificationsStore.getReplacedContent(this.props.enterpriseStore.curriculum.id);
          }
        });
      };

      getTotalCurriculumTime = (content) => {
        let totalTime = 0;
        content.forEach((item) => {
          totalTime += item.duration_seconds;
        });

        return totalTime ? (
          <div style={{ marginTop: '2em' }}>
            <span style={{ fontWeight: 700 }}>Total Time: </span>
            {FormatUtil.formatTime(totalTime, 'hm')}
          </div>
        ) : null;
      };

      copyCurriculum = (id, teamId, name) => {
        const copyUrl = `/enterprise/${this.state.orgId}/paths/copy/${id}`;
        GaUtil.fireEvent('Copy', 'Product Interaction', `Copy ${name}`);
        this.props.enterpriseStore.curriculumAction(
          copyUrl,
          !teamId,
          this.props.commonStore.triggerConfirm,
          <CurriculumConfirm type="copy" />,
          this.props.commonStore.resetConfirmState,
          this.props.navigate
        );
      };

      assignCurriculum = (id, teamId, name, type) => {
        let assignUrl = `/enterprise/${this.state.orgId}/goals/?action=add&content_type=path&content_ids=${id}`;

        if (type === 'Assessment Path') {
          assignUrl += '&outcome_type=team_assessment';
        }

        // Only fire event if a Cybrary curriculum
        if (!teamId) {
          GaUtil.fireEvent('Assign', 'Product Interaction', `Assign ${name}`);
        }
        this.props.enterpriseStore.curriculumAction(
          assignUrl,
          !teamId,
          this.props.commonStore.triggerConfirm,
          <CurriculumConfirm type="goal" />,
          this.props.commonStore.resetConfirmState,
          this.props.navigate
        );
      };

      getActionButtons = (curriculum) => {
        const { team, user } = this.props.userStore;
        const actions = [];
        const isTeamCurriculum = !!curriculum.team_id;
        const isUserCurriculum = curriculum.author_id === user.id;
        const canManageCurricula = team && team.permissions && team.permissions.canManageCurricula;
        // Can only edit if it is a user owned curriculum or a team curriculum AND you are an admin
        const canEdit = isUserCurriculum || (canManageCurricula === 'all' && isTeamCurriculum);
        if (canManageCurricula) {
          if (canEdit) {
            actions.push(
              <button
                key="edit"
                onClick={() => this.props.navigate(`/enterprise/${this.state.orgId}/paths/edit/${curriculum.id}`)}
                className="py-2.5 px-6 text-sm font-bold leading-5 text-center text-black bg-gray-200 rounded-sm"
              >
                Edit Path
              </button>
            );
          } else if (curriculum.type !== 'Assessment Path') {
            actions.push(
              <button
                key="copy"
                onClick={() => this.copyCurriculum(curriculum.id, curriculum.team_id, curriculum.name)}
                className="py-2.5 px-6 text-sm font-bold leading-5 text-center text-black bg-gray-200 rounded-sm"
              >
                Copy Path
              </button>
            );
          }
          actions.push(
            <button
              key="assign"
              className="py-2.5 px-6 ml-4 text-sm font-bold leading-5 text-center text-white hover:text-white bg-cyb-pink-500 hover:bg-pink-600 rounded-sm"
              onClick={() => this.assignCurriculum(curriculum.id, curriculum.team_id, curriculum.name, curriculum.type)}
            >
              Use in Goal
            </button>
          );
        }
        return actions;
      };

      render() {
        const store = this.props.enterpriseStore;
        const loading = store.loading || !store.curriculum;
        if (loading) {
          return (
            <Container>
              <Loading message="Loading..." />
            </Container>
          );
        }
        if (store.error) {
          return (
            <Container>
              <StyledError error={store.error} />
            </Container>
          );
        }

        const { curriculum } = store;
        const crumbs = [
          {
            href: `/enterprise/${this.state.orgId}/paths`,
            label: 'Paths',
          },
        ];
        const { replacedContent } = this.props.notificationsStore;
        return (
          <Container size="md" className="curriculum-details">
            <div className="items-end md:flex">
              <Title title="Path Details" breadcrumbs={crumbs} />
              <div className="pb-12 mt-4 md:flex-1 md:mt-0 md:text-right">{this.getActionButtons(curriculum)}</div>
            </div>
            <div style={{ border: 'none', marginTop: 0, paddingTop: 0 }}>
              <Divider horizontal style={{ marginBottom: '3rem' }}>
                <Header as="h4">Details</Header>
              </Divider>
              <Header as="h3">
                {curriculum.name}
                {'  '}
                <Label basic>{!curriculum.team_id ? 'Cybrary' : 'Custom'} Path</Label>
              </Header>
              <p className="mb-12 text-sm text-gray-600">{curriculum.description}</p>

              <Divider horizontal style={{ marginBottom: '3rem' }}>
                <Header as="h4">Path Items</Header>
              </Divider>
              <div>
                {curriculum.contents.map((item, idx) => {
                  const contentType = FormatUtil.formatContentType(item.type);
                  const tags = item.tags ? item.tags : [];
                  const betaLabel = tags.indexOf('Beta') !== -1 ? <BetaLabel /> : null;
                  const contentUpdates = NotificationUtil.transformContentNotifications(replacedContent, 'old_content_description_id');
                  const contentUpdate = contentUpdates[item.id] && contentUpdates[item.id].processed ? contentUpdates[item.id] : null;
                  const isNewContentComingSoon = NotificationUtil.checkContentUpdateComingSoon(contentUpdate);
                  const contentArchiveDate = item.archive_at && !item.archived_at ? moment(item.archive_at).format('MM/DD/YYYY') : null;
                  return (
                    <div className="flex content-item" key={item.id}>
                      <div className="w-3/4">
                        <span className="index">{idx + 1}.</span>
                        {item.title} <Label basic>{contentType}</Label>
                        {item.archived_at && <ArchivedLabel className="ml-2" />}
                        {betaLabel}
                        {contentArchiveDate && <ContentUpdateLabel basic className="ml-2" labelText={`Archiving on ${contentArchiveDate}`} />}
                        {contentUpdate && !isNewContentComingSoon && (
                          <span className="ml-2">
                            <ContentUpdateFlag showAsLabel data={contentUpdate} summaryTitle="New Version Available!" contentTitle={item.title} />
                          </span>
                        )}
                      </div>
                      <div className="w-1/4 text-right duration">{FormatUtil.formatTime(item.duration_seconds, 'hm')}</div>
                    </div>
                  );
                })}
              </div>
              {this.getTotalCurriculumTime(curriculum.contents)}
            </div>
          </Container>
        );
      }
    }
  )
);

export default withRouter(Curriculum);
