import React, { useState } from 'react';
import { observer, inject } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import Bugsnag from '@bugsnag/js';
import { handleEnroll } from '../../utils/pathsUtil';
import EnrollmentButton from '../BrowsePromo/EnrollmentButton';
import { useCustomPathsContext } from '../../providers/CustomPathsProvider';

/**
 * @params - userStore
 * @params - enrollmentStore
 * @params - commonStore
 * @params - item - path object
 * @returns - EnrollmentButton component with styles and behavior specific to custom paths
 */
const CustomPathsEnrollmentCta = inject(
  'userStore',
  'enrollmentStore',
  'commonStore'
)(
  observer(({ userStore, enrollmentStore, commonStore, item = {} }) => {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { getIsEnrolled } = useCustomPathsContext();

    /** item by default from curriculum doesn't include content_type.name which is needed by the enrollment button component */
    const modifiedItem = {
      ...item,
      content_type: {
        ...item?.content_type,
        name: 'curriculum',
        nice_name: 'Curriculum',
      },
    };
    const isEnrolled = getIsEnrolled(item?.id);
    const stores = { enrollmentStore, userStore, commonStore };

    const handleCustomPathEnroll = async () => {
      /** handle enroll is not async so we don't bother setting loading to false */
      try {
        setIsLoading(true);
        handleEnroll(modifiedItem, stores, navigate);
      } catch (error) {
        Bugsnag.notify(error);
        setIsLoading(false);
      }
    };

    const buttonStyles = `font-bold py-2 px-6 text-xs hover:cursor-pointer rounded ${
      isEnrolled ? 'bg-cyb-pink-500 hover:bg-cyb-pink-600 text-white' : 'bg-gray-200 hover:bg-gray-300 text-black'
    }`;
    const buttonText = isEnrolled ? 'Continue' : 'Start';
    return <EnrollmentButton handleEnroll={handleCustomPathEnroll} item={modifiedItem} type="Curriculum" text={buttonText} buttonClasses={buttonStyles} isLoading={isLoading} />;
  })
);
export default CustomPathsEnrollmentCta;
