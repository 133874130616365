import React, { useState } from 'react';
import { observer, inject } from 'mobx-react';
import Bugsnag from '@bugsnag/js';
import { PATH_OMITTED_CONTENT_TYPES } from '../../constants';
import OptionsMenu from '../OptionsMenu/OptionsMenu';
import Modal from '../Modal/Modal';
import PathSelectWrapper from '../Paths/PathSelectCreateWrapper';

/**
 * The actions menu
 * @param { permalink, content_type, title, prefix, menuAbove, iconContainerClassName, openShareModal }
 * @returns jsx OptionsMenu
 */
const ContentCardActionsMenu = inject(
  'userStore',
  'commonStore'
)(
  observer(({ content, userStore, commonStore, menuAbove, iconContainerClassName, addToPathOption, isComingSoon }) => {
    const [addToPathOpen, setAddToPathOpen] = useState(false);
    const { id, permalink, wwwPermalink, type, title, prefix } = content;
    const isBookMarked = userStore?.bookmarks?.includes(id);
    const optionsText = isBookMarked ? 'Remove from' : 'Add to';
    const actionsText = isBookMarked ? 'Removed from' : 'Added to';
    const errorText = isBookMarked ? 'removing' : 'adding';
    const text = ' Saved Items';
    const defaultActions = [
      {
        text: 'Share',
        action: () =>
          commonStore.triggerShareComponent({
            permalink: wwwPermalink || permalink,
            type,
            title,
            prefix,
          }),
      },
      {
        text: optionsText.concat(text),
        action: () => {
          userStore
            .toggleBookmark(id, title)
            .then(() => {
              commonStore.triggerToast('success', {
                content: actionsText.concat(text),
              });
            })
            .catch((error) => {
              commonStore.triggerToast('error', {
                content: `There was a problem ${errorText} this item. Please try again.`,
              });
              Bugsnag.notify(error);
            });
        },
      },
    ];

    // If the prop to include this option exists, and this content type is allowed in paths
    if (addToPathOption && !PATH_OMITTED_CONTENT_TYPES.includes(content.type) && !isComingSoon) {
      defaultActions.unshift({
        text: 'Add to Path',
        action: () => setAddToPathOpen(true),
      });
    }
    return (
      <>
        <OptionsMenu menuClasses={menuAbove ? 'right-0' : ''} noBackground options={defaultActions} menuAbove={menuAbove} iconContainerClassName={iconContainerClassName} />
        <Modal size="md" open={addToPathOpen} toggle={() => setAddToPathOpen(!addToPathOpen)}>
          <PathSelectWrapper userStore={userStore} commonStore={commonStore} successCallback={() => setAddToPathOpen(!addToPathOpen)} content={content} />
        </Modal>
      </>
    );
  })
);

export default ContentCardActionsMenu;
