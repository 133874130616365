import React from 'react';

export default function Android({ classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';

  return (
    <svg className={className} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...accessibilityProps}>
      <path
        d="M18.344 8.71c-.707 0-1.279.572-1.279 1.278v4.53c0 .709.572 1.28 1.279 1.28s1.279-.571 1.279-1.28v-4.53C19.623 9.282 19.051 8.71 18.344 8.71zM5.656
        8.71c-.707 0-1.279.572-1.279 1.278v4.53c0 .709.572 1.28 1.279 1.28s1.279-.571 1.279-1.28v-4.53C6.936 9.282 6.363 8.71 5.656 8.71zM14.545
        4.901l.768-1.388c.096-.167.033-.377-.133-.47-.168-.094-.379-.033-.471.135L13.92 4.598C13.332 4.363 12.684 4.23 12 4.23s-1.332.132-1.92.367L9.291 3.179C9.201
        3.011 8.99 2.95 8.82 3.044c-.166.093-.227.303-.133.47l.768 1.388C8.072 5.69 7.148 7.099 7.148 8.71c0 .011 0 .021.002.034C7.148 8.75 7.148 8.757 7.148
        8.763v.001h9.703V8.763c0-.006 0-.013 0-.019 0-.013 0-.023 0-.034C16.852 7.099 15.928 5.691 14.545 4.901zM9.76 6.869c-.189 0-.344-.156-.344-.346
        0-.19.154-.346.344-.346.191 0 .348.155.348.346C10.107 6.713 9.951 6.869 9.76 6.869zM14.24 6.869c-.191 0-.346-.156-.346-.346 0-.19.154-.346.346-.346.189 0
        .344.155.344.346C14.584 6.713 14.43 6.869 14.24 6.869zM7.148 16.12c0 .646.525 1.171 1.174 1.171h.586v2.401c0 .707.574 1.278 1.279
        1.278s1.279-.571 1.279-1.278v-2.401h1.066v2.401c0 .707.572 1.278 1.277 1.278.709 0 1.281-.571 1.281-1.278v-2.401h.586c.648 0 1.174-.524 1.174-1.171V9.028H7.148V16.12z"
      />
    </svg>
  );
}
