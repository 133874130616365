import React from 'react';

export default function Hacker({ classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" {...accessibilityProps}>
      <g id="Layer_9" data-name="Layer 9">
        <path d="M28,42h0a6,6,0,0,0,0,12h0a6,6,0,0,0,0-12Zm-4,6a4,4,0,1,1,4,4A4,4,0,0,1,24,48Z" />
        <path d="M32,21h1a1,1,0,0,0,0-2H32a1,1,0,0,0,0,2Z" />
        <path d="M24,19H23a1,1,0,0,0,0,2h1a1,1,0,0,0,0-2Z" />
        <path d="M63.873,36.51l-9-16A1,1,0,0,0,54,20h0a1,1,0,0,0-.872.512L46.7,32H44.72l3.145-5.5a1,1,0,0,0,.132-.51c0-.189-.045-.8-.05-.824-.279-5.927-2.652-12.137-7.111-18.479a15.674,15.674,0,0,0-25.678,0C10.7,13.027,8.325,19.238,8.046,25.164c0,.031-.047.633-.049.822a1,1,0,0,0,.131.51L11.273,32H7.135a3,3,0,0,0-2.993,3.2l.395,5.93-3.7,7.859A8.9,8.9,0,0,0,0,52.752,8.783,8.783,0,0,0,2.586,59L5.953,62.37l.046.7A1,1,0,0,0,7,64H49a1,1,0,0,0,1-.934l.047-.7L53.407,59A8.779,8.779,0,0,0,56,52.752a8.875,8.875,0,0,0-.842-3.763l-3.692-7.845-.006-.01L51.666,38H63a1,1,0,0,0,.872-1.49ZM42.417,32H34.008a10.047,10.047,0,0,0,3.976-6.814l.693-5.542c4.235,1.465,6.887,3.7,7.3,5.848,0,.084.016.169.018.252ZM20,24.938,19.883,24H25a1.006,1.006,0,0,0,.555-.168L28,22.2l2.445,1.63A1.006,1.006,0,0,0,31,24h5.117L36,24.938a8.062,8.062,0,0,1-16,0ZM36.367,22H31.3l-2.748-1.832a1,1,0,0,0-1.11,0L24.7,22H19.633l-.367-2.934A35.407,35.407,0,0,1,28,18a34.864,34.864,0,0,1,8.739,1.052ZM16.793,7.833a13.676,13.676,0,0,1,22.407,0,37.344,37.344,0,0,1,6.191,13.226C41.941,18.01,35.464,16,28,16S14.055,18,10.61,21.029A37.363,37.363,0,0,1,16.793,7.833ZM10,25.744c0-.085.015-.171.018-.256.421-2.37,3.238-4.478,7.3-5.85l.694,5.548A10.047,10.047,0,0,0,21.992,32H13.577ZM4,57.589a6.794,6.794,0,0,1-2-4.837,6.882,6.882,0,0,1,.651-2.912l2.163-4.6.94,14.1Zm49.346-7.748A6.885,6.885,0,0,1,54,52.752a6.793,6.793,0,0,1-2,4.837l-1.75,1.751.94-14.1ZM48.061,62H7.932l-1.8-26.934a1,1,0,0,1,1-1.066H45.583l-1.405,2.512A1,1,0,0,0,45.05,38h4.612ZM46.755,36,54,23.044,61.291,36Z" />
        <path d="M53,26v4a1,1,0,0,0,2,0V26a1,1,0,0,0-2,0Z" />
        <circle cx="54" cy="34" r="1" />
      </g>
    </svg>
  );
}
