import React from 'react';
import moment from 'moment';
import Modal from '../Modal/modal';

function AuditRow({ log = null, labels = false }) {
  const timestamp = !labels ? moment.unix(log.timestamp / 1000).format('M/DD/YY HH:mm:ss') : 'Created';
  const level = !labels ? log.level : 'Level';
  const randomName = !labels ? log.randomName : 'Name';
  const email = !labels ? log.email : 'Email';
  const description = !labels ? log.description : 'Description';

  const wrapperClass = labels ? 'flex text-sm my-2 border-b border-gray-500 pb-1' : 'flex text-xs my-2 text-gray-300 border-b border-gray-700 pb-2 pt-2';

  return (
    <div className={wrapperClass}>
      <div className="w-[120px] truncate">{timestamp}</div>
      <div className="w-[80px] truncate">{level}</div>
      <div className="w-[120px] truncate">{randomName}</div>
      <div className="w-[130px] truncate">{email}</div>
      <div className="flex-1">{description}</div>
    </div>
  );
}

export default function AuditModal({ open, close, auditLogs }) {
  return (
    <Modal open={open} onClose={close} maxW="max-w-6xl">
      <div className="px-4 pt-4 text-neutral-100">
        <h2 className="mb-4 text-4xl font-semibold">Audit Logs</h2>
        {(!auditLogs || !auditLogs.length) && <p className="mb-4 text-lg">There are no audit logs to display.</p>}
        {!!auditLogs && !!auditLogs.length && (
          <div className="my-4">
            <AuditRow labels />
            {auditLogs.map((log) => (
              <AuditRow log={log} key={log.timestamp} />
            ))}
          </div>
        )}
      </div>
    </Modal>
  );
}
