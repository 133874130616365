import React from 'react';

function Chat({ classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';

  return (
    <svg className={className} viewBox="0 0 100 125" fill="none" xmlns="http://www.w3.org/2000/svg" {...accessibilityProps}>
      <path fill="currentColor" d="M63.44,35H36.56a3,3,0,0,0,0,6H63.44a3,3,0,1,0,0-6Z" />
      <path fill="currentColor" d="M63.44,47.45H36.56a3,3,0,0,0,0,6H63.44a3,3,0,1,0,0-6Z" />
      <path fill="currentColor" d="M47.1,59.86H36.56a3,3,0,0,0,0,6H47.1a3,3,0,0,0,0-6Z" />
      <path
        fill="currentColor"
        d="M48.82,9.53A40.23,40.23,0,0,0,12.54,64.37a39.87,39.87,0,0,0,5.67,9.94l-3.33,7.82a6,6,0,0,0,6.56,8.26L36.82,87.7a39.85,39.85,0,0,0,17.08,2A40.38,40.38,0,0,0,90.17,51.61,39.85,39.85,0,0,0,78.67,21.54,40.38,40.38,0,0,0,48.82,9.53Zm35.35,41.8A34.35,34.35,0,0,1,53.32,83.78a33.91,33.91,0,0,1-15.19-2,3,3,0,0,0-1.56-.14L20.4,84.48l4-9.49A3,3,0,0,0,24,71.88,34.2,34.2,0,1,1,84.17,51.33Z"
      />
    </svg>
  );
}
export default Chat;
