import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Bugsnag from '@bugsnag/js';
import { useNavigate, useLocation } from 'react-router-dom';
import querystring from 'query-string';
import Agents from '../../agents/agents';
import Loading from '../Loading/Loading';
import ActivityLog from './ActivityLog';
import LogFilters from './LogFilters';
import withRouter from '../Router/withRouter';

const isToday = (date) => {
  if (!date) {
    return false;
  }
  const today = moment();
  const momentDate = moment(date);
  return momentDate.isSame(today, 'day');
};

// Set up default filters based on query params
const getDefaultFilters = (params, defaultStartDate) => {
  const filters = {};
  if (params && Object.keys(params).length) {
    Object.keys(params).forEach((paramKey) => {
      if (paramKey === 'events') {
        filters[paramKey] = params[paramKey].split(',');
      } else if (
        paramKey === 'event' ||
        paramKey === 'searchQuery' ||
        paramKey === 'cybraryAdmin' ||
        paramKey === 'activePg' ||
        (paramKey === 'startDate' && params[paramKey] !== defaultStartDate) ||
        (paramKey === 'endDate' && !isToday(params[paramKey]))
      ) {
        filters[paramKey] = params[paramKey];
      }
    });
  }
  return filters;
};

function ActivityLogWrapper({ team, agentObj, filters, setFilterOptions, formatColumns, activityLogClasses = '', filterType, headerMappings, match }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [init, setInit] = useState(false);
  const [activeFilters, setActiveFilters] = useState({});

  const { orgId } = match?.params || {};

  const { id, created_at } = team || {};
  const isDemo = orgId === 'demo';
  const defaultStartDate = team && created_at ? moment(created_at).startOf('day').format('YYYY-MM-DD HH:mm:ss') : new Date();
  const defaultEndDate = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss');

  // Fetch existing events for this team's activity log from server, and get default active filters from query params on page load
  useEffect(() => {
    const getEventFilters = async () => {
      try {
        const events = await Agents[agentObj].getActivityLogsEvents(id);
        setFilterOptions(events);
      } catch (e) {
        Bugsnag.notify(e);
      }
    };
    // skip fetching event filters if we're in a Demo to avoid requests to the backend
    if (!isDemo) {
      getEventFilters();
    }

    // Get active filters from params on load
    const paramsString = location.search;
    if (paramsString) {
      const defaultQueryParams = querystring.parse(paramsString);
      setActiveFilters(getDefaultFilters(defaultQueryParams, defaultStartDate));
    }
    setInit(true);
  }, []);

  // Update page query params as filters update
  useEffect(() => {
    if (init) {
      const queryParams = { ...activeFilters };
      if (queryParams.activePg === 1) {
        delete queryParams.activePg;
      }
      if (queryParams.startDate === defaultStartDate) {
        delete queryParams.startDate;
      }
      if (queryParams.events) {
        queryParams.events = queryParams.events.join(',');
      }
      navigate({
        search: `?${querystring.stringify(queryParams)}`,
      });
    }
  }, [init, activeFilters]);

  if (!init) {
    return <Loading message="Loading..." />;
  }

  const onPageChange = (pg) => {
    setActiveFilters({ ...activeFilters, activePg: pg });
  };

  return (
    <>
      <LogFilters
        filters={filters}
        activeFilters={activeFilters}
        setFilters={setActiveFilters}
        defaultStartDate={defaultStartDate}
        defaultEndDate={defaultEndDate}
        type={filterType}
        disabled={isDemo}
      />
      <div className={activityLogClasses}>
        <ActivityLog
          agentObj={agentObj}
          team={team}
          filters={activeFilters}
          formatEventWithAdminType
          formatColumns={formatColumns}
          changePage={isDemo ? () => {} : onPageChange}
          headerMappings={headerMappings}
        />
      </div>
    </>
  );
}

export default withRouter(ActivityLogWrapper);
