import React from 'react';
import { v4 as uuidv4 } from 'uuid';

function Paths({ active, id, filled, inverted }) {
  if (active || filled) {
    const myId = `teamsicon${id || uuidv4()}`;
    return (
      <>
        <g clipPath={`url(#${myId})`}>
          <path
            d="M20.7996 8.8C20.7996 10.073 20.2939 11.2939 19.3937 12.1941C18.4935 13.0943 17.2726 13.6 15.9996 13.6C14.7266 13.6 13.5057 13.0943 12.6055 12.1941C11.7053
              11.2939 11.1996 10.073 11.1996 8.8C11.1996 7.52696 11.7053 6.30606 12.6055 5.40589C13.5057 4.50571 14.7266 4 15.9996 4C17.2726 4 18.4935 4.50571 19.3937
              5.40589C20.2939 6.30606 20.7996 7.52696 20.7996 8.8ZM28.7996 12C28.7996 12.8487 28.4625 13.6626 27.8623 14.2627C27.2622 14.8629 26.4483 15.2 25.5996
              15.2C24.7509 15.2 23.937 14.8629 23.3369 14.2627C22.7368 13.6626 22.3996 12.8487 22.3996 12C22.3996 11.1513 22.7368 10.3374 23.3369 9.73726C23.937 9.13714
              24.7509 8.8 25.5996 8.8C26.4483 8.8 27.2622 9.13714 27.8623 9.73726C28.4625 10.3374 28.7996 11.1513 28.7996 12ZM22.3996 23.2C22.3996 21.5026 21.7253 19.8747
              20.5251 18.6745C19.3249 17.4743 17.697 16.8 15.9996 16.8C14.3022 16.8 12.6744 17.4743 11.4741 18.6745C10.2739 19.8747 9.59961 21.5026 9.59961
              23.2V28H22.3996V23.2ZM9.59961 12C9.59961 12.8487 9.26247 13.6626 8.66235 14.2627C8.06224 14.8629 7.2483 15.2 6.39961 15.2C5.55092 15.2 4.73698 14.8629
              4.13687 14.2627C3.53675 13.6626 3.19961 12.8487 3.19961 12C3.19961 11.1513 3.53675 10.3374 4.13687 9.73726C4.73698 9.13714 5.55092 8.8 6.39961 8.8C7.2483
              8.8 8.06224 9.13714 8.66235 9.73726C9.26247 10.3374 9.59961 11.1513 9.59961 12ZM25.5996 28V23.2C25.6019 21.5733 25.1889 19.9728 24.3996 18.5504C25.109 18.3689
              25.8504 18.3518 26.5674 18.5004C27.2844 18.649 27.9579 18.9593 28.5367 19.4079C29.1155 19.8564 29.5842 20.4311 29.907 21.0883C30.2299 21.7455 30.3983 22.4678
              30.3996 23.2V28H25.5996ZM7.59961 18.5504C6.81041 19.9729 6.39737 21.5733 6.39961 23.2V28H1.59961V23.2C1.5993 22.4673 1.76675 21.7442 2.08912 21.0862C2.4115
              20.4282 2.88024 19.8527 3.45942 19.4039C4.03861 18.955 4.71286 18.6448 5.43051 18.4968C6.14815 18.3489 6.89014 18.3672 7.59961 18.5504Z"
            fill={!inverted ? 'black' : 'white'}
          />
        </g>
        <defs>
          <clipPath id={myId}>
            <rect width="28.8" height="24" fill={!inverted ? 'white' : 'black'} transform="translate(1.59961 4)" />
          </clipPath>
        </defs>
      </>
    );
  }
  return (
    <path
      d="M23 27.1998H30V24.3998C29.9999 23.527 29.7279 22.6758 29.2218 21.9647C28.7157 21.2536 28.0005 20.7178 27.1759 20.4319C26.3512 20.1459 25.4579 20.124 24.6202
          20.3692C23.7825 20.6144 23.042 21.1145 22.5016 21.8M23 27.1998H9M23 27.1998V24.3998C23 23.4814 22.8236 22.6036 22.5016 21.8M22.5016 21.8C21.9817 20.5007 21.0845
          19.3871 19.9256 18.6026C18.7667 17.8181 17.3994 17.3988 16 17.3988C14.6006 17.3988 13.2333 17.8181 12.0744 18.6026C10.9155 19.3871 10.0183 20.5007 9.4984 21.8M9
          27.1998H2V24.3998C2.00006 23.527 2.27207 22.6758 2.77821 21.9647C3.28434 21.2536 3.99946 20.7178 4.82415 20.4319C5.64884 20.1459 6.54213 20.124 7.37983
          20.3692C8.21754 20.6144 8.95804 21.1145 9.4984 21.8M9 27.1998V24.3998C9 23.4814 9.1764 22.6036 9.4984 21.8M20.2 8.9998C20.2 10.1137 19.7575 11.182 18.9698
          11.9697C18.1822 12.7573 17.1139 13.1998 16 13.1998C14.8861 13.1998 13.8178 12.7573 13.0302 11.9697C12.2425 11.182 11.8 10.1137 11.8 8.9998C11.8 7.8859 12.2425
          6.81761 13.0302 6.02996C13.8178 5.2423 14.8861 4.7998 16 4.7998C17.1139 4.7998 18.1822 5.2423 18.9698 6.02996C19.7575 6.81761 20.2 7.8859 20.2 8.9998ZM28.6
          13.1998C28.6 13.9424 28.305 14.6546 27.7799 15.1797C27.2548 15.7048 26.5426 15.9998 25.8 15.9998C25.0574 15.9998 24.3452 15.7048 23.8201 15.1797C23.295 14.6546 23
          13.9424 23 13.1998C23 12.4572 23.295 11.745 23.8201 11.2199C24.3452 10.6948 25.0574 10.3998 25.8 10.3998C26.5426 10.3998 27.2548 10.6948 27.7799 11.2199C28.305
          11.745 28.6 12.4572 28.6 13.1998ZM9 13.1998C9 13.9424 8.705 14.6546 8.1799 15.1797C7.6548 15.7048 6.94261 15.9998 6.2 15.9998C5.45739 15.9998 4.7452 15.7048 4.2201
          15.1797C3.695 14.6546 3.4 13.9424 3.4 13.1998C3.4 12.4572 3.695 11.745 4.2201 11.2199C4.7452 10.6948 5.45739 10.3998 6.2 10.3998C6.94261 10.3998 7.6548 10.6948
          8.1799 11.2199C8.705 11.745 9 12.4572 9 13.1998Z"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke="currentColor"
    />
  );
}

export default function TeamsIcon({ active, classes, id, filled, inverted, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';
  return (
    <svg className={className} fill="none" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...accessibilityProps}>
      <Paths active={active} filled={filled} inverted={inverted} id={id} />
    </svg>
  );
}
