import { useState, useMemo } from 'react';
import agents from '../../agents/agents';
import BugsnagUtil from '../../utils/bugsnagUtil';

/**
 * Manages updating and canceling of paddle subscriptions
 * @see `usePaddleCheckout` For creating new checkouts
 * @see `usePaddlePricing` For getting pricing estimates
 * @see `usePaddleSubscription` For managing exsisting subscription items
 * @param {array} initItems Initial items to set ex: [{ price_id: 'pri_1k2hb4, quantity: 1 }
 * @param {string} initDiscountId Initial discount ID to apply to estimate
 */
function usePaddleSubscription() {
  const [response, setResponse] = useState(null); // prices we fetched
  const [isLoading, setIsLoading] = useState(true); // loading state
  const [error, setError] = useState(null); // error state

  /**
   * Updates an existing subscription_id
   * @param {string} subscriptionId Subscription ID to update
   * @param {object} updates Updates to apply to subscription
   */
  const updateSubscription = async ({ subscriptionId, updates = {} }) => {
    setIsLoading(true);
    try {
      const updateResponse = await agents.paddle.updateSubscription({ subscriptionId, updates });
      setResponse(updateResponse);
    } catch (err) {
      BugsnagUtil.notifyWithNamedMetadata(err, 'updateSubscription', { subscriptionId, updates });
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Cancels an existing subscription_id
   * @param {string} subscriptionId Subscription ID to cancel
   */
  const cancelSubscription = async ({ subscriptionId }) => {
    setIsLoading(true);
    try {
      const cancelResponse = await agents.paddle.cancelSubscription(subscriptionId);
      setResponse(cancelResponse);
    } catch (err) {
      BugsnagUtil.notifyWithNamedMetadata(err, 'cancelSubscription', { subscriptionId });
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Gets a subscription from paddle by subscriptionId
   * @param {string} subscriptionId Subscription ID to get portal link for
   * @returns {Promise} Promise that resolves to the portal link
   */
  const getSubscription = async (subscriptionId) => {
    setIsLoading(true);
    try {
      const subscription = await agents.paddle.getSubscription(subscriptionId);
      setIsLoading(false);
      return subscription;
    } catch (err) {
      BugsnagUtil.notifyWithNamedMetadata(err, 'getSubscription', { subscriptionId });
      setError(err);
      setIsLoading(false);
    }
    return null;
  };

  /**
   * Gets the transaction id for a subscription
   * @param {string} subscriptionId Paddle subscription ID
   * @returns {Promise} Promise that resolves to the transaction id
   */
  const getTransactionId = async (subscriptionId) => {
    const subscription = await getSubscription(subscriptionId);
    if (!subscription) return null;
    return subscription?.transaction_id;
  };

  /**
   * Gets the portal link for a subscription
   * @param {string} subscriptionId Subscription ID to get portal link for
   * @returns {Promise} Promise that resolves to the portal link
   */
  const getCustomerId = async (subscriptionId) => {
    const subscription = await getSubscription(subscriptionId);
    if (!subscription) return null;
    return subscription?.customer_id;
  };

  /**
   * Gets the customer_id for a subscription
   * @param {string} subscriptionId Subscription ID to get portal link for
   * @returns {Promise} Promise that resolves to the portal link
   */
  const getPortalLink = async (subscriptionId) => {
    const subscription = await getSubscription(subscriptionId);
    if (!subscription) return null;
    return subscription?.management_urls?.update_payment_method;
  };

  return useMemo(() => {
    return {
      updateSubscription,
      cancelSubscription,
      getPortalLink,
      getTransactionId,
      getCustomerId,
      getSubscription,
      response,
      isLoading,
      error,
      setError,
    };
  }, [response, isLoading, error, setError]);
}

export default usePaddleSubscription;
