// Used to be Pending
const PENDING = 'pending';
const CONNECTING = 'Connecting';
const INITIALIZING = 'Initializing';
const READY = 'Ready';
const STOPPED = 'Stopped';
const ERROR = 'Error';
const DELETING = 'Deleting';
const DELETED = 'Deleted';

// Resource status
const STOPPING = 'STOPPING';
const TERMINATED = 'TERMINATED';
const STAGING = 'STAGING';
// Used to be RUNNING
const RUNNING = 'running';
const PROVISIONING = 'imaging';

export const statusNames = { PENDING, READY, STOPPED, STOPPING, PROVISIONING, TERMINATED, STAGING, RUNNING, ERROR, DELETING, DELETED };

const Status = {
  pending: PENDING,
  planning: CONNECTING,
  applying: INITIALIZING,
  applied: READY,
  plan_queued: PENDING,
  apply_queued: PENDING,
  stopped: STOPPED,
  errored: ERROR,
  deleting: DELETING,
  deleted: DELETED,
};

const BLUE = {
  ball: 'bg-blue-600',
  text: 'text-blue-600',
  border: 'border border-blue-600',
  bg: 'bg-blue-100',
};

const YELLOW = {
  ball: 'bg-yellow-600',
  text: 'text-yellow-600',
  border: 'border border-yellow-600',
  bg: 'bg-yellow-100',
};

const GREEN = {
  ball: 'bg-green-600',
  text: 'text-green-600',
  border: 'border border-green-600',
  bg: 'bg-green-100',
};

const RED = {
  ball: 'bg-red-600',
  text: 'text-red-600',
  border: 'border border-red-600',
  bg: 'bg-red-100',
};

const GRAY = {
  ball: 'bg-gray-600',
  text: 'text-gray-600',
  border: 'border border-gray-600',
  bg: 'bg-gray-100',
};

export const DEFAULT_COLOR = BLUE;

export const StatusColors = {
  [PENDING]: YELLOW,
  [STOPPED]: GRAY,
  [STOPPING]: GRAY,
  [STAGING]: GRAY,
  [PROVISIONING]: BLUE,
  [DELETED]: RED,
  [TERMINATED]: RED,
  [DELETING]: RED,
  [READY]: GREEN,
  [RUNNING]: GREEN,
};

export default Status;
