import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';

function SingleFileUpload(props) {
  const [file, setFile] = useState(null);

  const { getRootProps, getInputProps } = useDropzone({
    accept: props.acceptedTypes,
    maxFiles: 1,
    onDrop: (acceptedFile) => {
      setFile(acceptedFile[0]);
      props.onChange(undefined, { name: props.name, value: acceptedFile[0] });
    },
  });

  const inputProps = { ...getInputProps() };
  inputProps.className = 'sr-only';
  inputProps.tabIndex = null;
  inputProps['aria-label'] = props.ariaLabel;
  const dropzoneProps = { ...getRootProps() };
  const previewText = props.placeholder || '';
  return (
    <div {...dropzoneProps} className="p-2 w-full rounded border-xs border-gray-300 focus-within:border-blue-500 cursor-pointer">
      <div className="text-gray-600">{file ? `${file.path} - ${file.size} bytes` : previewText}</div>
      <input placeholder={previewText} id={props.fieldId || props.name} {...inputProps} aria-describedby={props.ariaDescribedBy} aria-required={props.required} />
    </div>
  );
}

export default SingleFileUpload;
