import React, { useMemo, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { twMerge } from 'tailwind-merge';
import { isActive, getActiveSecondaryNavItem } from '../../navigation/nav';
import AddLink from '../AddLink/AddLink';
import If from '../If/If';
import Container from '../Container/Container';
import Loading from '../Loading/Loading';
import { useCybAssessmentPath } from '../../providers/CybAssessmentPathProvider';
import { useCybAssessmentAnalytics } from '../../providers/CybAssessmentAnalyticsProvider';
import FormatUtil from '../../utils/formatUtil';

function SidebarLink({ active, item, userStore }) {
  const { href, label, hrefReplace, display, divider, disabled } = item;
  if (display && !display(userStore)) {
    return null;
  }

  if (divider) {
    return <div className="my-4 mx-7 border-t-xs border-gray-400" />;
  }

  const linkHref = useMemo(() => {
    // If the item is disabled, don't return a link
    if (disabled) {
      return '';
    }

    // Some URLs require dynamic replacement values, send along the href and the user store to the provided function
    if (hrefReplace) {
      return hrefReplace(href, userStore);
    }
    return href;
  }, [href, hrefReplace, userStore]);

  const mergedLinkClasses = useMemo(() => {
    const uniqueClass = `sidebar-nav-${FormatUtil.slugify(label)}`;
    // Applied to all sidebar links
    const allLinkClasses = `block pl-7 py-3 text-normal text-sm border-l-4 leading-6 rounded-r-lg`;

    // Applied to active and inactive links respectively
    const activeLinkClasses = 'border-cyb-pink-500 text-black font-semibold bg-gray-200';
    const inactiveLinkClasses = 'border-white text-gray-600';
    const linkClasses = active ? activeLinkClasses : inactiveLinkClasses;

    // Hover states for active and inactive links
    const inactiveLinkHoverClasses = !disabled && !active ? 'hover:bg-gray-200 hover:border-gray-200 hover:text-gray-900 hover:font-semibold' : '';
    const linkCursorClasses = disabled ? 'cursor-not-allowed' : '';
    return twMerge(uniqueClass, allLinkClasses, linkClasses, inactiveLinkHoverClasses, linkCursorClasses);
  }, [active, disabled, label]);

  return (
    <AddLink className={mergedLinkClasses} to={linkHref} active={active} disabled={disabled}>
      {label}
    </AddLink>
  );
}

function SidebarNav({ userStore }) {
  const orgId = userStore?.adminTeam?.id;

  const location = useLocation() || window.location;

  const activeNavItem = getActiveSecondaryNavItem(userStore, location);

  const { tracks } = useCybAssessmentAnalytics();

  const userTracks = tracks[userStore?.user?.id];

  const {
    overviews,
    isLoadingOverview,
    actions: { getOverview },
  } = useCybAssessmentPath();

  const fetchDynamicData = useCallback(() => {
    if (activeNavItem?.dynamicDataType === 'CYBRARY_ASSESSMENT_PATHS') {
      const assessmentPaths = overviews[orgId];

      if (!assessmentPaths) {
        getOverview(orgId);
      }
    }
  }, [activeNavItem?.dynamicDataType, overviews, orgId, getOverview]);

  const dynamicNavItems = useMemo(() => {
    if (activeNavItem?.dynamicDataType === 'CYBRARY_ASSESSMENT_PATHS') {
      const assessmentPaths = overviews[orgId];

      const baseNavItems = [
        {
          label: 'All Paths',
          href: activeNavItem.href,
          id: uuidv4(),
        },
      ];

      if (assessmentPaths && userTracks) {
        const overviewAssessmentPathIds = assessmentPaths?.default?.map((path) => path.assessment_path_id);
        const mappedAssessmentTracks = userTracks?.filter(
          (track) => overviewAssessmentPathIds.includes(track['content-id']) && (track.status !== 'Unlisted' || track['display-in-catalog'])
        );

        const pathNavItems = mappedAssessmentTracks?.map((track) => ({
          label: track['content-name'],
          href: `${activeNavItem.href}/${orgId}/paths/assessments/${track['content-id']}`,
          id: uuidv4(),
          startsWith: true,
        }));

        baseNavItems.push(...(pathNavItems ?? {}));
      }

      return baseNavItems;
    }

    return null;
  }, [activeNavItem, orgId, overviews, userTracks]);

  const navItems = useMemo(() => {
    if (dynamicNavItems) {
      return dynamicNavItems;
    }

    if (activeNavItem?.children?.length) {
      return activeNavItem.children;
    }

    return null;
  }, [activeNavItem, dynamicNavItems]);

  const isLoadingDynamicData = useMemo(() => {
    if (activeNavItem?.dynamicDataType === 'CYBRARY_ASSESSMENT_PATHS') {
      return isLoadingOverview;
    }
    return null;
  }, [activeNavItem, isLoadingOverview]);

  useEffect(() => {
    fetchDynamicData();
  }, [fetchDynamicData]);

  if (!navItems?.length || (activeNavItem?.displayChildContainer && !activeNavItem.displayChildContainer(userStore))) {
    return null;
  }

  return (
    <nav aria-label="Sidebar Navigation" className="hidden pt-16 pr-8 w-72 bg-white lg:block sidebar-navbar">
      <ul>
        {navItems?.map((item) => (
          <li key={item.id}>
            <SidebarLink item={item} userStore={userStore} active={isActive(item, 2, userStore, location)} />
          </li>
        ))}
      </ul>
      <If condition={isLoadingDynamicData}>
        <Container className="mt-4">
          <Loading />
        </Container>
      </If>
    </nav>
  );
}

export default SidebarNav;
