import React from 'react';

function FlexLegends({ legendData }) {
  if (!legendData?.length) {
    return null;
  }
  return (
    <div className="flex flex-wrap justify-center content-center items-center -mt-0.5 space-x-4">
      {legendData.map((item) => (
        <div key={item.id} className="flex items-center py-[4px] space-x-1">
          <div className="w-4 h-4" style={{ backgroundColor: item.color }} />
          <div className="text-xs text-gray-700">{item.id}</div>
        </div>
      ))}
    </div>
  );
}

export default FlexLegends;
