import GaUtil from './gaUtil';

export function getFacebookText(type, title) {
  switch (type) {
    case 'profile':
      return `I'm developing my IT & cybersecurity skills with Cybrary, the industry's leading skills development platform. 
      Check out my profile below. Create a free profile, and we can compare our progress!`;
    case 'public-profile':
      return `Check out ${title} profile on Cybrary, the industry leading skills development platform. Create your own profile, and you can compare your progress!`;
    default:
      return `Check out this amazing "${title}" ${type} on Cybrary.IT. Sign up for FREE and access this and tons of other cybersecurity and I.T. career resources!`;
  }
}
export function getTwitterText(type, title) {
  switch (type) {
    case 'profile':
      return `I'm developing my IT & cybersecurity skills with Cybrary, the industry's leading skills development platform. 
      Check out my profile below. Create a free profile, and we can compare our progress!`;
    case 'public-profile':
      return `Check out ${title}'s profile on Cybrary, the industry leading skills development platform. Create your own profile, and you can compare your progress!`;
    default:
      return `Check out this amazing “${title}” ${type} from @CybraryIT. Sign up for FREE to access this and tons of other cybersecurity and I.T. career resources!
  
      `;
  }
}

export function getEmailText(type, title) {
  switch (type) {
    case 'profile':
      return `I'm developing my IT & cybersecurity skills with Cybrary, the industry's leading skills development platform. 
      Check out my profile below. Create a free profile, and we can compare our progress!`;
    case 'public-profile':
      return `Check out ${title}'s profile on Cybrary, the industry leading skills development platform. Create your own profile, and you can compare your progress!`;
    default:
      return `Hello,
  
        Recently, I have been building my cybersecurity and I.T. knowledge with the fastest growing catalog in the industry on Cybrary.
        You can join for free too and I recommend you check out this course:
  
  Happy learning!
  
  `;
  }
}

export function getEmailSubject(type) {
  switch (type) {
    case 'profile':
    case 'public-profile':
      return `Check out this profile on Cybrary!`;
    default:
      return `Recommendation for you on Cybrary`;
  }
}

export function fetchUrl(prefix, type, permalink) {
  const contentType = prefix || type;
  switch (contentType) {
    case 'public-profile':
    case 'profile':
      return `${process.env.REACT_APP_BASE_URL}/profile/${permalink}`;
    case 'course':
    case 'program':
      return `${process.env.REACT_APP_V2_SITE_URL}/${permalink}`;
    default:
      return `${process.env.REACT_APP_V2_SITE_URL}/catalog/${permalink}`;
  }
}

export function handleGaEvent(type, title) {
  return new Promise((resolve) => {
    GaUtil.fireEvent(`Share ${type}`, 'Product Interaction', title);
    resolve();
  });
}
