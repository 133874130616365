import React, { useState } from 'react';
import { observer, inject } from 'mobx-react';
import { Fade } from 'react-awesome-reveal';
import Cookies from 'js-cookie';
import Bugsnag from '@bugsnag/js';
import { twMerge } from 'tailwind-merge';
import Icon from '../Icon/Icon';
import Image from '../Image/Image';
import Tooltip from '../Tooltip/Tooltip';
import DynamicForm from '../DynamicForm/DynamicForm';
import Agents from '../../agents/agents';
import UserIcon from '../../Icons/UserIcon';
import userStore from '../../stores/userStore';
import { useProfileContext } from '../../providers/ProfileProvider';
import { DEFAULT_COOKIE_SETTINGS } from '../../constants';

const COOKIE_PROPS = {
  ...DEFAULT_COOKIE_SETTINGS,
  path: '/',
  expires: 0.25,
};

function ImageWrapper({ isDefaultAvatar, avatarUrl, className = 'w-32 h-32 lg:w-60 lg:h-60 cursor-pointer' }) {
  return isDefaultAvatar ? <UserIcon classes={className} /> : <Image src={avatarUrl} alt="profile" circle className={className} />;
}

const Avatar = inject(
  'commonStore',
  'profileStore'
)(
  observer(
    ({
      commonStore,
      profileStore,
      pfpUrl = '',
      avatarWrapperClasses,
      editIconClasses = 'absolute right-0 bottom-6 p-1 w-10 h-10 text-gray-400 group-hover:text-gray-800 bg-white rounded-full cursor-pointer lg:bottom-12',
      editButtonClasses = '',
      isEditable = true,
    }) => {
      if (!isEditable) {
        return <ImageWrapper isDefaultAvatar={pfpUrl === 'https://assets.cybrary.it/uploads/user-avatars/profile.jpg'} avatarUrl={pfpUrl} className={avatarWrapperClasses} />;
      }
      const [edit, setEdit] = useState(false);
      const { setPersonalProfileData } = useProfileContext();

      const isDefaultAvatar = pfpUrl === 'https://assets.cybrary.it/uploads/user-avatars/profile.jpg';
      const avatarUpload = {
        name: 'avatarUpload',
        order: ['image', ['cancel', 'submit']],
        fields: {
          image: {
            type: 'singleImageUpload',
            dropWidth: '150px',
            dropHeight: '150px',
            // 99KB is 101376 Bytes
            maxFileSize: 101376,
            imgPreview: true,
            popupText: 'Click here, or drag in an image to upload. Accepted image types include jpeg, png, gif, or bmp. Max file size is 99KB',
            icon: 'camera',
            acceptedTypes: 'image/jpeg, image/png, image/gif, image/bmp',
            defaultValue: !isDefaultAvatar ? pfpUrl : '',
          },
          cancel: {
            type: 'button',
            label: 'Cancel',
            className: 'py-3 px-6 ml-auto text-sm font-bold text-cyb-pink-500 rounded-lg border-1 border-cyb-pink-500 mr-4',
            onClick: () => setEdit(false),
          },
          submit: {
            type: 'button',
            color: 'bg-cyb-pink-500 mt-5 flex',
            label: 'Save',
            className: 'mr-auto py-3 px-6 text-sm font-bold rounded-lg',
          },
        },
      };

      const updateUserMeta = (avatarUrl) => {
        // cookie
        let userMetaCookie;
        try {
          userMetaCookie = JSON.parse(Cookies.get('user-meta'));
          userMetaCookie.avatar_url = avatarUrl;
          Cookies.set('user-meta', JSON.stringify(userMetaCookie), COOKIE_PROPS);
        } catch (e) {
          Bugsnag.notify(e);
        }
      };

      const onSubmit = async (data) => {
        const formData = new FormData();
        formData.append('image', data.image);

        try {
          const response = await Agents.profile.uploadAvatarImage(formData);
          profileStore.updateAvatar(response);
          userStore.setAvatarUrl(response);
          setPersonalProfileData((profileData) => ({ ...profileData, avatar_url: response }));
          updateUserMeta(response);
        } catch (error) {
          // Aws\\S3\\Exception\\S3Exception and file incorrect size are most common errors...
          commonStore.triggerToast('error', { content: `An error has occurred: ${error.response.data.errors.image[0] || error.message}` });
          profileStore.updateErrors(error);
          return;
        }
        commonStore.triggerToast('success', { content: 'Your profile has been updated!' });
        setEdit(false);
      };

      if (!edit) {
        return (
          <button type="button" aria-label="Update Avatar" onClick={() => setEdit(true)} className={twMerge('group inline-block relative', editButtonClasses)}>
            <Fade appear duration={300}>
              <Tooltip content="Update Avatar" position="bottom" action="mouseenter" distance={50} theme="dark" omitTabIndex>
                <ImageWrapper isDefaultAvatar={isDefaultAvatar} avatarUrl={pfpUrl} className={avatarWrapperClasses} />
              </Tooltip>
              <Icon name="plus-circle" className={editIconClasses} active />
            </Fade>
          </button>
        );
      }
      return (
        <div>
          <Fade appear duration={300}>
            <DynamicForm
              form={avatarUpload}
              formName={avatarUpload.name}
              onSubmit={onSubmit}
              customClassName="flex flex-col items-center"
              serverErrors={profileStore.profile.errors}
              clearErrorForField={profileStore.clearErrors}
            />
          </Fade>
        </div>
      );
    }
  )
);

export default Avatar;
