import React, { useState, useEffect } from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

function PhoneInputField({ id, name, country, defaultValue, required, ariaDescribedBy, error, onChange, placeholder, className = '' }) {
  const [input, setInput] = useState(defaultValue);

  useEffect(() => {
    onChange(undefined, { name, value: input });
  }, [onChange, name, input]);

  return (
    <PhoneInput
      placeholder={placeholder}
      onChange={(val) => setInput(val)}
      name={name}
      id={id}
      value={input}
      country={country}
      defaultCountry={country}
      aria-required={required}
      aria-describedby={ariaDescribedBy}
      countryOptionsOrder={['US', '|', '...']}
      className={`${error ? 'PhoneInputError' : ''}  ${className}`}
    />
  );
}

export default PhoneInputField;
