import React from 'react';

export default function PersonShield({ classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';

  return (
    <svg className={className} viewBox="0 0 213 143" fill="none" xmlns="http://www.w3.org/2000/svg" {...accessibilityProps}>
      <g clipPath="url(#clip0_950_2449)">
        <path
          d="M206.761 142.186L207.075 142.18C206.953 138.605 206.58 135.044 205.959 131.524C204.6 123.987 202.339 119.038 
          199.24 116.812L199.06 117.076C206.299 122.274 206.757 141.987 206.761 142.186Z"
          fill="#F1F1F1"
        />
        <path
          d="M202.83 142.109L203.145 142.103C203.138 141.747 202.942 133.355 199.711 131.036L199.531 131.299C202.631 
        133.525 202.829 142.024 202.83 142.109Z"
          fill="#F1F1F1"
        />
        <path
          d="M197.271 117.105C198.139 117.105 198.843 116.385 198.843 115.497C198.843 114.609 198.139 113.89 197.271 
          113.89C196.403 113.89 195.699 114.609 195.699 115.497C195.699 116.385 196.403 117.105 197.271 117.105Z"
          fill="#F1F1F1"
        />
        <path
          d="M198.049 130.93C198.917 130.93 199.621 130.21 199.621 129.322C199.621 128.435 198.917 127.715 198.049 
          127.715C197.181 127.715 196.477 128.435 196.477 129.322C196.477 130.21 197.181 130.93 198.049 130.93Z"
          fill="#F1F1F1"
        />
        <path
          d="M203.563 118.069C203.406 119.309 203.569 120.569 204.038 121.725C204.856 120.789 205.392 119.632 205.582 
          118.392C205.772 117.152 205.607 115.883 205.108 114.736C204.316 115.69 203.784 116.839 203.563 118.069Z"
          fill="#F1F1F1"
        />
        <path
          d="M198.425 122.756C199.592 123.13 200.834 123.185 202.028 122.915C201.27 121.928 200.249 121.186 199.088 
          120.779C197.927 120.372 196.676 120.316 195.485 120.62C196.266 121.583 197.279 122.319 198.425 122.756Z"
          fill="#F1F1F1"
        />
        <path
          d="M198.367 134.999C199.185 135.261 200.055 135.3 200.892 135.11C200.361 134.418 199.645 133.898 198.832 
          133.613C198.018 133.328 197.141 133.289 196.307 133.501C196.853 134.176 197.564 134.693 198.367 134.999Z"
          fill="#F1F1F1"
        />
        <path
          d="M72.8844 142.186L72.428 142.177C72.6045 136.988 73.1459 131.819 74.0479 126.71C76.0211 115.771 79.3023 
          108.587 83.8002 105.357L84.0622 105.739C73.555 113.284 72.8897 141.898 72.8844 142.186Z"
          fill="#F1F1F1"
        />
        <path
          d="M78.5884 142.075L78.1321 142.066C78.1419 141.549 78.4271 129.369 83.1157 126.003L83.3778 
          126.385C78.8783 129.615 78.5906 141.951 78.5884 142.075Z"
          fill="#F1F1F1"
        />
        <path
          d="M86.6583 105.782C87.9184 105.782 88.9399 104.737 88.9399 103.449C88.9399 102.16 87.9184 101.115 
          86.6583 101.115C85.3982 101.115 84.3766 102.16 84.3766 103.449C84.3766 104.737 85.3982 105.782 
          86.6583 105.782Z"
          fill="#F1F1F1"
        />
        <path
          d="M85.5284 125.848C86.7885 125.848 87.81 124.804 87.81 123.515C87.81 122.226 86.7885 121.182 
          85.5284 121.182C84.2683 121.182 83.2468 122.226 83.2468 123.515C83.2468 124.804 84.2683 125.848 
          85.5284 125.848Z"
          fill="#F1F1F1"
        />
        <path
          d="M77.5249 107.181C77.7536 108.981 77.516 110.81 76.8361 112.487C75.6485 111.129 74.87 109.45 
          74.5945 107.65C74.319 105.85 74.5582 104.008 75.2833 102.344C76.4317 103.728 77.2047 105.395 
          77.5249 107.181Z"
          fill="#F1F1F1"
        />
        <path
          d="M84.9828 113.983C83.2896 114.527 81.4864 114.606 79.7537 114.214C80.8539 112.781 82.3357 
          111.705 84.0205 111.114C85.7053 110.523 87.5213 110.442 89.2496 110.883C88.1173 112.28 
          86.646 113.349 84.9828 113.983Z"
          fill="#F1F1F1"
        />
        <path
          d="M85.0671 131.754C83.8803 132.135 82.6164 132.191 81.4019 131.916C82.173 130.912 83.2116 
          130.157 84.3925 129.743C85.5735 129.329 86.8463 129.272 88.0578 129.581C87.2641 130.561 
          86.2328 131.31 85.0671 131.754Z"
          fill="#F1F1F1"
        />
        <path
          d="M37.6331 42.8198L36.5352 35.9512L33.4355 35.1587C33.4355 35.1587 30.9753 34.3838 
          30.4002 39.4515C29.8252 44.5194 30.271 53.9812 30.271 53.9812L23.4652 76.0216C22.9426
          76.2756 22.5027 76.6782 22.1981 77.1813C21.8934 77.6843 21.737 78.2665 21.7476 
          78.8581C21.7581 79.4497 21.9351 80.0257 22.2575 80.5171C22.5798 81.0085 23.0338 
          81.3945 23.5651 81.6288C24.0964 81.8632 24.6824 81.936 25.2531 81.8385C25.8238 
          81.741 26.355 81.4775 26.783 81.0793C27.211 80.6811 27.5177 80.1652 27.6664 
          79.5934C27.8151 79.0215 27.7996 78.418 27.6215 77.855L36.4707 57.1514L37.6331 
          42.8198Z"
          fill="#FFB7B7"
        />
        <path
          d="M56.483 37.4154L54.8556 30.6576L57.4022 28.6841C57.4022 28.6841 59.3673 
          26.9835 61.8418 31.4134C64.3164 35.8433 67.5391 44.7244 67.5391 44.7244L82.2604 
          62.262C82.8385 62.2857 83.3977 62.4793 83.871 62.8197C84.3443 63.16 84.7117 
          63.6326 84.9291 64.181C85.1466 64.7293 85.2049 65.3301 85.0971 65.9115C84.9893 
          66.4928 84.72 67.0301 84.3214 67.459C83.9227 67.8878 83.4117 68.1901 82.8494 
          68.3297C82.2871 68.4692 81.6974 68.44 81.1509 68.2457C80.6044 68.0513 80.1242 
          67.7 79.7679 67.2338C79.4116 66.7676 79.1942 66.2063 79.1417 65.617L63.0544 
          50.1293L56.483 37.4154Z"
          fill="#FFB7B7"
        />
        <path
          d="M69.2047 138.587L72.8962 138.587L74.6525 124.025L69.2039 124.026L69.2047 
        138.587Z"
          fill="#FFB7B7"
        />
        <path
          d="M68.2629 137.355L75.533 137.354H75.5333C76.762 137.355 77.9404 137.854 78.8093 
          138.742C79.6781 139.631 80.1663 140.836 80.1664 142.093V142.246L68.2632 
          142.247L68.2629 137.355Z"
          fill="#2F2E41"
        />
        <path
          d="M22.8334 138.587L26.5249 138.587L28.2812 124.025L22.8326 124.026L22.8334 
        138.587Z"
          fill="#FFB7B7"
        />
        <path
          d="M21.8917 137.355L29.1618 137.354H29.1621C30.3908 137.355 31.5692 137.854 
          32.438 138.742C33.3069 139.631 33.795 140.836 33.7951 142.093V142.246L21.8919 
          142.247L21.8917 137.355Z"
          fill="#2F2E41"
        />
        <path
          d="M45.4743 24.4724C50.9886 24.4724 55.4588 19.9009 55.4588 14.2616C55.4588 
          8.62232 50.9886 4.05078 45.4743 4.05078C39.96 4.05078 35.4898 8.62232 35.4898 
          14.2616C35.4898 19.9009 39.96 24.4724 45.4743 24.4724Z"
          fill="#2F2E41"
        />
        <path
          d="M37.6809 8.75651C39.2944 7.10648 39.6214 4.76566 38.4113 3.52814C37.2012 
          2.29062 34.9123 2.62502 33.2988 4.27505C31.6853 5.92508 31.3584 8.2659 32.5684 
          9.50343C33.7785 10.7409 36.0675 10.4065 37.6809 8.75651Z"
          fill="#2F2E41"
        />
        <path
          d="M55.8808 5.51377C56.553 3.90433 55.3969 1.85653 53.2986 0.939876C51.2002 
          0.0232194 48.9542 0.584827 48.282 2.19426C47.6097 3.80369 48.7658 5.8515 
          50.8641 6.76815C52.9625 7.68481 55.2085 7.1232 55.8808 5.51377Z"
          fill="#2F2E41"
        />
        <path
          d="M53.2263 20.8559C55.4711 16.6564 53.9619 11.3911 49.8555 9.09542C45.7491 
          6.79977 40.6005 8.34314 38.3557 12.5426C36.1109 16.7421 37.6201 22.0075 
          41.7265 24.3031C45.8329 26.5988 50.9815 25.0554 53.2263 20.8559Z"
          fill="#FFB7B7"
        />
        <path
          d="M36.8871 11.2489C38.8235 12.6138 41.1218 13.3402 43.4735 13.3305C42.6346 
          13.9254 41.6796 14.3272 40.6742 14.5083C43.801 15.1942 47.0326 15.2128 50.1667 
          14.5628C50.8634 14.4486 51.5356 14.2125 52.1539 13.8648C52.462 13.6889 52.7304 
          13.4486 52.9418 13.1595C53.1531 12.8705 53.3026 12.5392 53.3805 12.1873C53.5887 
          10.9705 52.6617 9.86509 51.6982 9.12009C50.2231 7.99685 48.5223 7.22297 46.7189 
          6.85442C44.9155 6.48588 43.0545 6.53189 41.2706 6.98913C40.1057 7.29704 38.9388 
          7.81731 38.1823 8.77397C37.4257 9.73063 37.2015 11.2055 37.9224 12.1906L36.8871 
          11.2489Z"
          fill="#2F2E41"
        />
        <path
          d="M41.5442 27.9688L33.1572 34.9736L40.4853 52.8427L40.6251 55.7018C40.6251 55.7018 
          33.0178 62.8748 28.3109 82.9123C23.6039 102.95 14.0972 129.575 14.0972 129.575L33.1158 
          134.333L46.339 89.006L63.1955 124.566L65.5935 135.084L80.2949 130.911L74.6211 
          102.685L60.705 58.8714L61.8477 46.7675C61.8477 46.7675 64.3943 40.726 59.9704 
          34.3297L58.5828 28.3195L51.3998 27.0674L41.5442 27.9688Z"
          fill="#2F2E41"
        />
        <path
          d="M182.577 91.5607H124.349C123.884 91.5601 123.438 91.371 123.109 91.0347C122.78 
          90.6985 122.596 90.2426 122.595 89.7671V69.6784C122.596 69.2029 122.78 68.747 
          123.109 68.4107C123.438 68.0745 123.884 67.8853 124.349 67.8848H182.577C183.042 
          67.8853 183.488 68.0745 183.817 68.4107C184.146 68.747 184.331 69.2029 184.331 
          69.6784V89.7671C184.331 90.2426 184.146 90.6985 183.817 91.0347C183.488 91.371 
          183.042 91.5602 182.577 91.5607Z"
          fill="#E5E5E5"
        />
        <path d="M147.149 73.2656H131.715V75.0593H147.149V73.2656Z" fill="white" />
        <path
          d="M167.494 75.418C168.075 75.418 168.547 74.9362 168.547 74.3418C168.547 
        73.7474 168.075 73.2656 167.494 73.2656C166.913 73.2656 166.442 73.7474 166.442 
        74.3418C166.442 74.9362 166.913 75.418 167.494 75.418Z"
        />
        <path
          d="M171.002 75.418C171.583 75.418 172.054 74.9362 172.054 74.3418C172.054 
        73.7474 171.583 73.2656 171.002 73.2656C170.42 73.2656 169.949 73.7474 169.949 
        74.3418C169.949 74.9362 170.42 75.418 171.002 75.418Z"
        />
        <path
          d="M174.509 75.418C175.091 75.418 175.562 74.9362 175.562 74.3418C175.562 
        73.7474 175.091 73.2656 174.509 73.2656C173.928 73.2656 173.457 73.7474 173.457 
        74.3418C173.457 74.9362 173.928 75.418 174.509 75.418Z"
        />
        <path
          d="M182.577 117.03H124.349C123.884 117.03 123.438 116.841 123.109 116.504C122.78 
          116.168 122.596 115.712 122.595 115.237V95.1481C122.596 94.6726 122.78 94.2167 
          123.109 93.8804C123.438 93.5442 123.884 93.355 124.349 93.3545H182.577C183.042 
          93.355 183.488 93.5442 183.817 93.8804C184.146 94.2167 184.331 94.6726 184.331 
          95.1481V115.237C184.331 115.712 184.146 116.168 183.817 116.504C183.488 116.841 
          183.042 117.03 182.577 117.03Z"
          fill="#E5E5E5"
        />
        <path d="M147.149 98.7354H131.715V100.529H147.149V98.7354Z" fill="white" />
        <path
          d="M167.494 100.888C168.075 100.888 168.546 100.406 168.546 99.8115C168.546 
        99.2172 168.075 98.7354 167.494 98.7354C166.913 98.7354 166.442 99.2172 166.442 
        99.8115C166.442 100.406 166.913 100.888 167.494 100.888Z"
        />
        <path
          d="M171.002 100.888C171.583 100.888 172.054 100.406 172.054 99.8115C172.054 
        99.2172 171.583 98.7354 171.002 98.7354C170.42 98.7354 169.949 99.2172 169.949 
        99.8115C169.949 100.406 170.42 100.888 171.002 100.888Z"
        />
        <path
          d="M174.51 100.888C175.091 100.888 175.562 100.406 175.562 99.8115C175.562 
        99.2172 175.091 98.7354 174.51 98.7354C173.928 98.7354 173.457 99.2172 173.457 
        99.8115C173.457 100.406 173.928 100.888 174.51 100.888Z"
        />
        <path
          d="M182.577 142.5H124.349C123.884 142.5 123.438 142.31 123.109 141.974C122.78 
          141.638 122.596 141.182 122.595 140.707V120.618C122.596 120.142 122.78 119.686 
          123.109 119.35C123.438 119.014 123.884 118.825 124.349 118.824H182.577C183.042 
          118.825 183.488 119.014 183.817 119.35C184.146 119.686 184.331 120.142 184.331 
          120.618V140.707C184.331 141.182 184.146 141.638 183.817 141.974C183.488 142.31 
          183.042 142.5 182.577 142.5Z"
          fill="#E5E5E5"
        />
        <path d="M147.149 124.205H131.715V125.999H147.149V124.205Z" fill="white" />
        <path
          d="M167.494 126.357C168.075 126.357 168.546 125.876 168.546 125.281C168.546 
        124.687 168.075 124.205 167.494 124.205C166.913 124.205 166.442 124.687 166.442 
        125.281C166.442 125.876 166.913 126.357 167.494 126.357Z"
        />
        <path
          d="M171.002 126.357C171.583 126.357 172.054 125.876 172.054 125.281C172.054 
        124.687 171.583 124.205 171.002 124.205C170.42 124.205 169.949 124.687 169.949 
        125.281C169.949 125.876 170.42 126.357 171.002 126.357Z"
        />
        <path
          d="M174.51 126.357C175.091 126.357 175.562 125.876 175.562 125.281C175.562 
        124.687 175.091 124.205 174.51 124.205C173.928 124.205 173.457 124.687 173.457 
        125.281C173.457 125.876 173.928 126.357 174.51 126.357Z"
        />
        <path
          d="M112.703 99.1844C111.594 99.1807 110.49 99.0346 109.417 98.7496L109.205 
          98.6898L109.009 98.5892C101.913 94.9508 95.9264 90.1449 91.2156 84.3051C87.3161 
          79.4426 84.2759 73.9217 82.2316 67.9907C79.787 60.8898 78.611 53.3972 78.7595 
          45.8707C78.7625 45.7127 78.7651 45.5907 78.7651 45.5066C78.7651 41.8479 80.7509 
          38.6377 83.8242 37.3281C86.1764 36.3258 107.533 27.3549 109.076 26.7067C111.982 
          25.2176 115.082 26.4605 115.578 26.6779C116.691 27.1432 136.434 35.4013 140.701 
          37.4789C145.098 39.6202 146.271 43.4669 146.271 45.4022C146.271 54.1641 144.787 
          62.3527 141.86 69.7408C139.497 75.7208 136.147 81.2416 131.957 86.0625C123.873 
          95.3664 115.787 98.6647 115.709 98.6937C114.742 99.0338 113.725 99.1998 112.703 
          99.1844ZM110.802 94.3672C111.503 94.5279 113.117 94.769 114.169 94.3766C115.505 
          93.8781 122.274 90.2888 128.598 83.0101C137.337 72.9533 141.771 60.311 141.777 
          45.4345C141.762 45.1331 141.552 42.9835 138.769 41.628C134.585 39.5906 114.064 
          31.0094 113.857 30.9229L113.8 30.8983C113.37 30.714 112.002 30.3258 111.058 
          30.8314L110.869 30.9214C110.641 31.0175 87.9703 40.5398 85.5525 41.57C83.8612 
          42.2907 83.2586 44.0761 83.2586 45.5066C83.2586 45.6112 83.256 45.7633 83.2522 
          45.9598C83.059 56.1389 85.3623 81.1864 110.802 94.3672Z"
          fill="#3F3D56"
        />
        <path
          d="M110.016 28.7967C110.016 28.7967 87.1394 38.4054 84.6884 39.4498C82.2373 
        40.4942 81.0118 43.0008 81.0118 45.5074C81.0118 48.014 79.1735 80.7218 110.016 
        96.5359C110.016 96.5359 112.815 97.328 114.939 96.5359C117.062 95.7438 144.024 
        82.3753 144.024 45.403C144.024 45.403 144.024 41.6431 139.735 39.5542C135.445 
        37.4654 114.707 28.7967 114.707 28.7967C114.707 28.7967 112.16 27.6479 110.016 
        28.7967Z"
        />
        <path
          opacity="0.2"
          d="M112.467 36.3164V87.5751C112.467 87.5751 135.547 76.2131 
        135.343 46.3428L112.467 36.3164Z"
          fill="black"
        />
        <path
          d="M212.243 142.5H0.756659C0.688589 142.5 0.623306 142.472 0.575173 
          142.423C0.527041 142.373 0.5 142.307 0.5 142.237C0.5 142.167 0.527041 
          142.101 0.575173 142.051C0.623306 142.002 0.688589 141.975 0.756659 
          141.975H212.243C212.311 141.975 212.377 142.002 212.425 142.051C212.473 
          142.101 212.5 142.167 212.5 142.237C212.5 142.307 212.473 142.373 212.425 
          142.423C212.377 142.472 212.311 142.5 212.243 142.5Z"
          fill="#CBCBCB"
        />
      </g>
      <defs>
        <clipPath id="clip0_950_2449">
          <rect width="212" height="142" fill="white" transform="translate(0.5 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
