import React, { useState, useCallback, useMemo } from 'react';
import Collapsible from 'react-collapsible';
import moment from 'moment';
import { useToggle } from 'usehooks-ts';
import { inject } from 'mobx-react';
import Icon from '../Icon/Icon';
import Table from '../Table/Table';
import Header from '../Header/Header';
import Tooltip from '../Tooltip/Tooltip';
import Button from '../Button/Button';
import If from '../If/If';
import Modal from '../Modal/Modal';
import WidgetContainer from '../Container/WidgetContainer';
import agents from '../../agents/agents';

function AdminUserSubscriptionRow({ license }) {
  const [open, setOpen] = useState(false);
  const iconName = open ? 'chevron-down' : 'chevron-right';

  return (
    <>
      <Table.Row>
        <Table.Cell>
          {license.packages?.length &&
            license.packages.map((pkg, idx) => {
              return (
                <span key={pkg.name}>
                  {pkg.name} {idx < license.packages.length - 1 ? ', ' : ''}
                </span>
              );
            })}
        </Table.Cell>
        <Table.Cell>{license.subscription_id}</Table.Cell>
        <Table.Cell>{moment(license.expires_at).format('M/D/YYYY')}</Table.Cell>
        {license.chargebeeData || license.paddleData ? (
          <Table.Cell collapsing textAlign="right">
            <Tooltip omitTabIndex position="top-end" content="Click to view more data">
              <button onClick={() => setOpen(!open)}>
                <Icon name={iconName} />
              </button>
            </Tooltip>
          </Table.Cell>
        ) : (
          <Table.Cell />
        )}
      </Table.Row>
      {license.chargebeeData && open && (
        <Table.Row>
          <Table.Cell colSpan={13}>
            <Collapsible open={open} transitionTime={200}>
              <ChargebeeData chargebeeData={license.chargebeeData} />
            </Collapsible>
          </Table.Cell>
        </Table.Row>
      )}
      {license.paddleData && open && (
        <Table.Row>
          <Table.Cell colSpan={13}>
            <Collapsible open={open} transitionTime={200}>
              <PaddleData paddleData={license.paddleData} />
            </Collapsible>
          </Table.Cell>
        </Table.Row>
      )}
    </>
  );
}

function CybraryAdminUserSubscriptionComponent({ data, commonStore }) {
  const [open, toggle] = useToggle();
  const [isRevokingSubscriptionLicense, setIsRevokingSubscriptionLicense] = useState(false);

  /**
   * This action 'revokes' the users active subscription license by simply setting the expiry to the current date, immediatley making it invalid,
   * and is not to be confused with actual cancellation of the subscription via Paddle.
   *
   * The objective of this operation is to give CX a way to immediately revoke CIP access to those users that have
   * been 'wrongly charged' for an extra billing cycle, already issued a refund and had - their subscription manually cancelled by CX via Paddle.
   *
   * CX doesn't want the user to have that extra ~month of free CIP access. Once Paddle formally processes the cancellation after the grace period -
   * the status and deletion of the subscription license will be handled accordingly by our system.
   */
  const onRevokeHandler = useCallback(async () => {
    toggle();
    try {
      const activeSubscriptionLicense = data?.licenses.find((license) => license.status === 'active');

      if (!activeSubscriptionLicense) {
        return;
      }

      setIsRevokingSubscriptionLicense(true);

      await agents.admin.expireUserContentLicense(data.id, activeSubscriptionLicense.id);

      commonStore.triggerToast('success', {
        content: `User subscription has been successfully revoked`,
      });
    } catch (e) {
      commonStore.triggerToast('error', {
        content: 'Something went wrong. Please try again.',
      });
    } finally {
      setIsRevokingSubscriptionLicense(false);
    }
  }, [data]);

  const hasActiveSubscription = useMemo(() => data?.is_cip && data?.licenses.some((license) => license.status === 'active'), [data]);
  const licenses = data?.licenses;

  return (
    <WidgetContainer className="mt-6 admin-user-subscription" omitBorder omitBackground>
      <Header as="h2" className="flex flex-row justify-between items-center">
        Subscription
        <If condition={hasActiveSubscription}>
          <Button
            className=""
            color="red"
            disabled={isRevokingSubscriptionLicense}
            loading={isRevokingSubscriptionLicense}
            loadingProps={{ message: 'Processing...', messageClassName: 'ml-2', wrapperClassName: 'flex items-center' }}
            onClick={toggle}
          >
            Revoke Subscription
          </Button>
        </If>
      </Header>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Packages</Table.HeaderCell>
            <Table.HeaderCell>Subscription ID</Table.HeaderCell>
            <Table.HeaderCell>Expires</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {licenses.map((license) => {
            return (
              <React.Fragment key={license.id}>
                <AdminUserSubscriptionRow license={license} />
              </React.Fragment>
            );
          })}
        </Table.Body>
      </Table>
      <Modal className="cip-cancel-modal" open={open} toggle={toggle} ariaLabelledBy="cip-cancel-modal">
        <div className="px-7 pt-7">
          <div>
            <h2 id="cip-cancel-modal" className="my-4 text-2xl font-bold">
              Are you sure you want to revoke this subscription?
            </h2>
            <p className="mt-6 font-semibold">
              User will <b>immediately</b> lose access to <b>Cybrary Insider Pro</b>.
            </p>
            <div className="mt-8 text-right">
              <button className="px-6 h-10 text-lg font-bold leading-5 text-center text-white bg-cyb-pink-500 hover:bg-cyb-pink-600 rounded-sm" onClick={toggle}>
                Continue Insider Pro Access
              </button>
              <div className="mt-5">
                <button className="px-6 h-10 text-lg font-bold leading-5 text-center text-black bg-neutral-200 hover:bg-neutral-300 rounded-sm" onClick={onRevokeHandler}>
                  Revoke Subscription
                </button>
                <p className="mt-2 text-xs text-gray-600">(Their progress and account will not be deleted)</p>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </WidgetContainer>
  );
}

const CybraryAdminUserSubscription = inject('commonStore')(CybraryAdminUserSubscriptionComponent);

function ChargebeeData({ chargebeeData }) {
  const { customer, subscription } = chargebeeData;
  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Email</Table.HeaderCell>
          <Table.HeaderCell>Cf_U_ID</Table.HeaderCell>
          <Table.HeaderCell>Cf_Admin_ID</Table.HeaderCell>
          <Table.HeaderCell>Subscription ID</Table.HeaderCell>
          <Table.HeaderCell>Plan ID</Table.HeaderCell>
          <Table.HeaderCell>Renewal</Table.HeaderCell>
          <Table.HeaderCell>Status</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row key={customer.id}>
          <Table.Cell>{customer.email}</Table.Cell>
          <Table.Cell>{customer.cf_u_id}</Table.Cell>
          <Table.Cell>{subscription.cf_admin_id}</Table.Cell>
          <Table.Cell>{subscription.id}</Table.Cell>
          <Table.Cell>{subscription.plan_id}</Table.Cell>
          <Table.Cell>{moment.unix(subscription.next_billing_at).format('M/D/YYYY')}</Table.Cell>
          <Table.Cell>{subscription.status}</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
}

function PaddleData({ paddleData }) {
  const subscriptionItem = paddleData.items[0];
  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Customer ID</Table.HeaderCell>
          <Table.HeaderCell>Subscription ID</Table.HeaderCell>
          <Table.HeaderCell>Price ID</Table.HeaderCell>
          <Table.HeaderCell>Currency Code</Table.HeaderCell>
          <Table.HeaderCell>Renewal</Table.HeaderCell>
          <Table.HeaderCell>Status</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row key={paddleData.id}>
          <Table.Cell>{paddleData.customer_id}</Table.Cell>
          <Table.Cell>{paddleData.id}</Table.Cell>
          <Table.Cell>{subscriptionItem.price.id}</Table.Cell>
          <Table.Cell>{subscriptionItem.price.unit_price.currency_code}</Table.Cell>
          <Table.Cell>{moment(subscriptionItem.next_billed_at).format('M/D/YYYY')}</Table.Cell>
          <Table.Cell>{subscriptionItem.status}</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
}

export default CybraryAdminUserSubscription;
