import React, { useState } from 'react';
import moment from 'moment';
import Card from '../Card/Card';
import FormatUtil from '../../utils/formatUtil';
import './awards-content.css';
import Tooltip from '../Tooltip/Tooltip';
import CertificateModalContent from '../../pages/Certificates/CertificateModalContent';
import Modal from '../Modal/Modal';

/**
 * Awarded content
 * @param props
 * @returns {*}
 * @constructor
 */
function GetAwardContent({ type, award, idx, style }) {
  const [open, setOpen] = useState(false);

  const toggleCertModal = (modalState) => {
    setOpen(modalState);
  };
  if (type === 'certificates') {
    const issuedAt = moment(award.issued_at).format('M/D/YYYY');
    return (
      <Card key={idx} className="flex flex-col p-0 my-0">
        <Modal
          open={open}
          toggle={() => toggleCertModal(!open)}
          ariaLabelledBy="cert-title"
          trigger={
            <button aria-label="Toggle Certificate Modal" className="pdf-print-hidden" onClick={() => toggleCertModal(!open)}>
              <img style={style || null} alt="" src="https://images.ctfassets.net/kvf8rpi09wgk/7a9Hi5dmSSnzuNJqMTcqUC/5264b28124e7ec8087d82c771a3f84bb/Cybrary-Cert.png?h=200" />
            </button>
          }
        >
          <CertificateModalContent cert={award} omitSocial />
        </Modal>
        <p className="px-2 my-auto w-full text-xs text-left">{award.title || award.name}</p>
        <p className="px-2 pb-2 my-auto w-full text-2xs text-left">{`Completed ${issuedAt}`}</p>
      </Card>
    );
  }
  return (
    <Card key={idx} className="badge-popup-container">
      <Tooltip triggerContent={<img src={FormatUtil.correctImageUrl(award.url)} alt="" className="badge-popup" />} theme="dark" position="top-start" content={award.title} />
    </Card>
  );
}

function Awards(props) {
  /**
   * Get the award content
   * @param type
   * @param award
   * @param idx
   * @returns {*}
   */

  /**
   * Get a user's awarded certificates and badges
   */
  if (!props.awards || !props.awards.length) {
    // if there are no awards to display
    return <div>Earn {props.awardType} by completing activities</div>;
  }

  const awardedItems = props.awards.map((award, idx) => {
    return <GetAwardContent key={award.certificate_id} type={props.awardType} award={award} idx={idx} style={props.style} />;
  });
  return <div className="grid grid-cols-3 gap-2 sm:grid-cols-5 md:grid-cols-7 lg:grid-cols-9">{awardedItems}</div>;
}

export default Awards;
