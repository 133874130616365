import React, { useEffect, useState, useRef } from 'react';
import { useWindowWidth } from '@react-hook/window-size';
import { throttle } from 'lodash';
import { observer, inject } from 'mobx-react';
import VisibilitySensor from 'react-visibility-sensor';

import { setStickyNavActiveTab } from '../../utils/tabsUtil';

import CertificationPrepSteps from './CertificationPrepSteps';
import CertificationRecentScore, { CertificationRecentScoreProgress, CertificationRecentScoreLaunchReport } from './CertificationRecentScore';
import CertificationAdditionalContent from './CertificationAdditionalContent';
import CertificationHeader from './CertificationHeader';
import CertificationCtaButton from './CertificationCtaButton';
import Container from '../Container/Container';
import ContentTabs from '../ContentTabs/ContentTabs';
import Divider from '../Divider/Divider';
import FeedbackModal from '../Feedback/FeedbackModal';
import Loading from '../Loading/Loading';
import StyledError from '../Error/StyledError';
import StickyContentTabs from '../ContentTabs/StickyContentTabs';
import useCertificationProgress from '../../hooks/useCertificationProgress';

const handleVisibilitySensorChange = (isElementVisible, isFullView, setIsStickyTabActive) => {
  if (isFullView) {
    // on desktop we want the stick nav to show after the header
    setIsStickyTabActive(!isElementVisible);
  } else {
    // on mobile... we always want the stick nav showing...
    setIsStickyTabActive(true);
  }
};
const tabs = [
  {
    id: 'prep-steps',
    name: 'Prep Steps',
    scrollSelector: '#prep-steps',
    heightSwitch: 0.5,
  },
  {
    id: 'additional-content',
    name: 'Additional Content',
    scrollSelector: '#additional-content',
    heightSwitch: 0.8,
  },
];

const getActiveStep = (steps) => {
  let activeStep = steps[1];
  if (steps && Object.keys(steps).length) {
    const activeKey = Object.keys(steps).find((stepKey) => {
      const step = steps[stepKey];
      return step.active;
    });
    activeStep = steps[activeKey];
  }
  return activeStep;
};

const Certification = inject(
  'commonStore',
  'catalogStore'
)(
  observer(({ commonStore, catalogStore }) => {
    const additionalContentRef = useRef();
    const [activeTab, setActiveTab] = useState('Prep Steps');
    const [isStickyTabActive, setIsStickyTabActive] = useState(false);
    const [isFullView, setIsFullView] = useState(false);
    const [isElementVisible, setIsElementVisible] = useState(true);
    const { item } = catalogStore;
    const { content_item: contentItem, permalink, average_rating: averageRating, rating_count: ratingCount } = item;
    const { content_description_id: contentDescriptionId } = contentItem;
    const { long_description: longDescription, title, thumbnail_url: thumbnailUrl } = item;

    const { certContentProgress, practiceExamScores, certContentMap, prepSteps, loading, error } = useCertificationProgress({
      certificationItem: item,
      enabled: true,
    });

    useEffect(() => {
      commonStore.setPageTitle(`${item ? title : ''} | Cybrary`);

      const el = document.querySelector('.cyb-router');
      el.addEventListener(
        'scroll',
        throttle(() => setStickyNavActiveTab(tabs, setActiveTab), 1000, { leading: false, trailing: true })
      );
      return () => el.removeEventListener('scroll', () => setStickyNavActiveTab(tabs, setActiveTab));
    }, []);

    // Check window width
    useEffect(() => {
      if (window.innerWidth <= 1024) {
        setIsFullView(false);
      } else {
        setIsFullView(true);
      }
    }, [useWindowWidth({ wait: 200 })]);

    // handle stickyNav display on resizing
    useEffect(() => {
      // ensures intended stickyNav behavior for desktop and mobile
      handleVisibilitySensorChange(isElementVisible, isFullView, setIsStickyTabActive);
    }, [isFullView, isElementVisible]);

    const scrollToAdditionalLearning = () => {
      if (additionalContentRef && additionalContentRef.current) {
        additionalContentRef.current.scrollIntoView({
          behavior: 'smooth',
        });
      }
    };
    if (loading) {
      return <Loading message="Loading..." />;
    }

    if (error) {
      return <StyledError error={error} />;
    }

    const ctaData = getActiveStep(prepSteps);
    const browseCertsPathUrl = '/browse/refined?resultsView=list&view=certificationPrep';

    const checkHasProgress = certContentProgress.filter((content) => {
      return content.progress ? 1 * content.progress : 0;
    });
    const hasProgress = !!checkHasProgress.length;

    return (
      <div id="certification-page">
        {isStickyTabActive ? (
          <StickyContentTabs
            displayClassName=""
            tabs={tabs}
            setActiveTab={setActiveTab}
            activeTab={activeTab}
            backButtonUrl={browseCertsPathUrl}
            commonStore={commonStore}
            title={title}
            type={item.content_type.nice_name}
            image={thumbnailUrl}
            permalink="certifications"
            buttons={
              <div className="w-full lg:w-auto">
                <CertificationCtaButton data={ctaData} fluid />
              </div>
            }
            mobileSubBar={
              <div className="flex justify-between">
                <div className="text-sm text-center text-gray-600">
                  <p className="mb-0">Your Recent</p>
                  <p>Score</p>
                </div>
                <div className="mx-6">
                  <CertificationRecentScoreProgress data={practiceExamScores} />
                </div>
                <CertificationRecentScoreLaunchReport data={practiceExamScores} />
              </div>
            }
          />
        ) : null}
        <Container size="md-1280" className="my-12">
          <CertificationHeader
            commonStore={commonStore}
            contentType={item.content_type.nice_name}
            contentTitle={title}
            contentDescription={longDescription}
            contentImageUrl={thumbnailUrl}
            browseCertsPathUrl={browseCertsPathUrl}
            ctaData={ctaData}
            contentDescriptionId={contentDescriptionId}
            averageRating={averageRating}
            ratingCount={ratingCount}
            hasProgress={hasProgress}
          />

          <div className="mt-12 mb-8">
            <ContentTabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} wrapperClasses="mt-12 border-t-xs md:border-t-0" />
          </div>
          <div className="flex flex-col lg:flex-row lg:gap-x-8 xl:gap-x-20">
            {/* Start of left side column */}
            <div id="prep-steps" className="scroll-mt-32">
              <VisibilitySensor onChange={(isVisible) => setIsElementVisible(isVisible)} partialVisibility offset={{ top: 240 }}>
                <h2 className="mb-3 text-2xl font-black">Your Roadmap to Certification Prep Success</h2>
              </VisibilitySensor>
              <p className="text-sm text-gray-600">Ensure you are ready to take your certification exam by following the journey steps below.</p>
              <CertificationPrepSteps
                className="mt-6"
                learningContent={[...certContentMap.courses, ...certContentMap.labBundle, ...certContentMap.other]}
                scrollToAdditionalLearning={scrollToAdditionalLearning}
                steps={prepSteps}
              />
            </div>
            <Divider marginTop="mt-8" marginBottom="mb-8" />
            {/* Start of Right side column */}
            <div className="hidden sm:block lg:shrink-0 lg:w-[424px]">
              <CertificationRecentScore data={practiceExamScores} permalink={permalink} />
            </div>
          </div>
          <Divider marginTop="mt-0 sm:mt-8" marginBottom="mb-0 sm:mb-8" className="hidden sm:block" />
          <div id="additional-content" ref={additionalContentRef} className="scroll-mt-32">
            <CertificationAdditionalContent content={item.content_item.related} />
          </div>
        </Container>
        <FeedbackModal />
      </div>
    );
  })
);

export default Certification;
