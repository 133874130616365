import React from 'react';

export default function Diamond({ classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" stroke="currentColor" viewBox="0 0 122.88 95.01" {...accessibilityProps}>
      <path d="M.67,28.18,20.32,1.12A2.7,2.7,0,0,1,22.51,0h77.8a2.74,2.74,0,0,1,2.34,1.33l19.5,26.79a2.69,2.69,0,0,1,.73,1.84,2.74,2.74,0,0,1-.73,1.86h0L64.05,94.15a2.71,2.71,0,0,1-3.83.14l-.16-.17L.75,31.84a2.87,2.87,0,0,1-.31-.4l0,0h0a2.71,2.71,0,0,1,.26-3.22Zm44.38,4.49L62,83.55,78.63,32.67Zm39.27,0L68.37,81.59,114,32.67ZM55.74,81.74,39.36,32.67H9L55.74,81.74ZM27.66,5.42,41.38,25.31,56.05,5.42Zm39.24,0L82.19,25.31,95.3,5.42Zm33.52,2-13,19.8h27.47L100.42,7.45ZM76.87,27.25,61.46,7.2,46.66,27.25Zm-40.72,0L22.46,7.39,8,27.25Z" />
    </svg>
  );
}
