import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { useParams } from 'react-router-dom';
import TeamHierarchy from '../../components/Enterprise/TeamHierarchy';
import './enterprise.css';

const OrganizationGroups = inject(
  'enterpriseStore',
  'commonStore',
  'userStore',
  'authStore'
)(
  observer(({ enterpriseStore, commonStore, userStore, authStore }) => {
    const { orgId } = useParams();
    const isDemo = orgId === 'demo';

    useEffect(() => {
      authStore.fireAttributionEvent();
      commonStore.setPageTitle(`Groups${isDemo ? ' Demo' : ''} | Cybrary`);
      if (isDemo) {
        userStore.enterDemo();
        enterpriseStore.enterDemo();
      } else {
        userStore.setPreferredTeamInit('', orgId);
      }

      return () => {
        enterpriseStore.setDefaultGroupTableData();
        if (isDemo) {
          userStore.exitDemo();
          enterpriseStore.exitDemo();
        }
      };
    }, [orgId]);

    if (!userStore.team || userStore.team.id * 1 !== orgId * 1) {
      return null;
    }

    return <TeamHierarchy team={userStore.team} />;
  })
);

export default OrganizationGroups;
