import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import { newTracker, enableActivityTracking, setVisitorCookieTimeout, addGlobalContexts } from '@snowplow/browser-tracker';
import { FormTrackingPlugin } from '@snowplow/browser-plugin-form-tracking';
import Bugsnag from '@bugsnag/js';
import { Userpilot } from 'userpilot';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import GaUtil from '../../utils/gaUtil';
import Agents from '../../agents/agents';
import usePaddleCustomerId from '../../hooks/checkout/usePaddleCustomerId';

const bootSearchInsights = () => {
  // Initialize Search Insights library
  window.aa('init', {
    appId: process.env.REACT_APP_INSTANTSEARCH_APP_ID,
    apiKey: process.env.REACT_APP_INSTANTSEARCH_API_KEY,
  });
};

const bootDrift = async (setDriftPlaybookReceived, userId, user = {}) => {
  if (!window.location.hostname.match('localhost')) {
    let userJwt = '';
    // try/catch request for drift identify JWT error handling
    try {
      userJwt = await Agents.auth.getDriftJWT();
    } catch (error) {
      Bugsnag.notify(error);
    }
    // eslint-disable-next-line no-unused-expressions, func-names, consistent-return
    !(function () {
      // eslint-disable-next-line no-multi-assign
      const t = (window.driftt = window.drift = window.driftt || []);
      if (!t.init) {
        // eslint-disable-next-line no-void, no-console
        if (t.invoked) return void (window.console && console.error && console.error('Drift snippet included twice.'));
        // eslint-disable-next-line no-unused-expressions, no-sequences
        (t.invoked = !0),
          (t.methods = ['identify', 'config', 'track', 'reset', 'debug', 'show', 'ping', 'page', 'hide', 'off', 'on']),
          // eslint-disable-next-line func-names
          (t.factory = function (e) {
            // eslint-disable-next-line func-names
            return function () {
              // eslint-disable-next-line prefer-rest-params
              const n = Array.prototype.slice.call(arguments);
              // eslint-disable-next-line no-sequences
              return n.unshift(e), t.push(n), t;
            };
          }),
          // eslint-disable-next-line func-names
          t.methods.forEach(function (e) {
            t[e] = t.factory(e);
          }),
          // eslint-disable-next-line no-shadow, func-names
          (t.load = function (t) {
            const e = 3e5;
            const n = Math.ceil(new Date() / e) * e;
            const o = document.createElement('script');
            // eslint-disable-next-line no-unused-expressions, no-sequences
            (o.type = 'text/javascript'), (o.async = !0), (o.crossorigin = 'anonymous'), (o.src = `https://js.driftt.com/include/${n}/${t}.js`);
            const i = document.getElementsByTagName('script')[0];
            i.parentNode.insertBefore(o, i);
          });
      }
    })();
    const { id, first_name, last_name, country, clab_access, email, is_paid, is_cip, is_enterprise, phone, registered_at, last_login_date } = user;
    // eslint-disable-next-line no-undef
    drift.SNIPPET_VERSION = '0.3.1';
    // eslint-disable-next-line no-undef
    drift.identify(
      `${userId}`, // drift expects userId to be a string
      { cybrary_id: id, first_name, last_name, country, clab_access, email, is_paid, is_cip, is_enterprise, phone, registered_at, last_login_date },
      { userJwt }
    );
    // eslint-disable-next-line no-undef
    drift.load('et877eb76d7p');
    // eslint-disable-next-line no-undef
    drift.on('ready', () => {
      window?.drift?.on('conversation:playbookFired', () => {
        setDriftPlaybookReceived(true);
      });
    });
  }
};

const setupFullstory = (userId, team) => {
  if (!window.location.hostname.match('localhost')) {
    const fsUserVars = {
      displayName: `user${userId}`,
      // email: email,
      // Add your own custom user variables here, details at
      // https://help.fullstory.com/hc/en-us/articles/360020828113-FS-identify-Identifying-users
    };
    if (team) {
      fsUserVars.packageType_str = team.package_types && team.package_types.length ? team.package_types.join(', ') : '';
      fsUserVars.userRole_str = team.role;
    }

    window.FS.identify(userId, fsUserVars);
  }
};

const initSnowplow = (userId, email) => {
  // Initialize Snowplow - Only BWB for now while testing
  if (process.env.REACT_APP_SNOWPLOW_COLLECTOR && process.env.REACT_APP_SNOWPLOW_ID) {
    newTracker('sp1', process.env.REACT_APP_SNOWPLOW_COLLECTOR, {
      appId: process.env.REACT_APP_SNOWPLOW_ID,
      discoverRootDomain: true,
      cookieSameSite: 'Lax',
      plugins: [FormTrackingPlugin()],
      postPath: '/cybrary/yx5',
    });
    setVisitorCookieTimeout(3540);
    enableActivityTracking({
      minimumVisitLength: 30,
      heartbeatDelay: 10,
    });
    if (userId) {
      // snowplow custom context created by Kenny in order to pass user's data with form submissions
      const context = [
        {
          schema: 'iglu:com.cybrary/user/jsonschema/1-0-0',
          data: {
            userId,
            email,
          },
        },
      ];
      addGlobalContexts(context);
    }
  }
};

const bootGA = (user, team, profileData) => {
  if (!process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
    return;
  }
  // Boot up google analytics
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID, {
    gaOptions: {
      userId: user.id || null,
    },
  });

  if (profileData) {
    if (profileData.role !== undefined) {
      // set dimension1 for work role
      GaUtil.fireCustomDimension('dimension1', profileData.role);
    }
    if (profileData.title !== undefined) {
      // Set dimension 2 for job title
      GaUtil.fireCustomDimension('dimension2', profileData.title);
    }
  }

  GaUtil.fireCustomDimension('dimension3', 'Yes');
  if (user.id) {
    GaUtil.fireCustomDimension('dimension19', user.id.toString());
  }
  if (team && team.package_types && team.package_types.length) {
    GaUtil.fireCustomDimension('dimension20', team.package_types.join(', '));
  }
  if (user.is_cip) {
    GaUtil.fireCustomDimension('dimension20', 'Cybrary Insider Pro');
  }
};

const checkHideZendesk = (path, driftPlaybookReceived) => {
  const zendeskBlackList = /^\/immersive.*|^\/onboarding.*/;
  const onBlacklistedPage = zendeskBlackList.test(path);
  // If Zendesk has been initialized, show/hide based on current location
  // If we received a playbook from drift, we want to hide zendesk as well (user will communicate via drift instead)
  if (window?.zE) {
    if (onBlacklistedPage || driftPlaybookReceived) {
      window.zE('webWidget', 'hide');
    } else {
      window.zE('webWidget', 'show');
    }
  }
};

/**
 * Converts an array of id's to a string of id's wrapped in brackets and separated by commas
 * For use in UserPilot to allow for filtering by team. Brackets allow for filtering by exact match
 * @param {Array} teamsIdArray
 * @returns {String} - String of id's wrapped in brackets and separated by commas
 */
const transformTeamsIdsForUserPilot = (teamsIdArray) => {
  if (teamsIdArray && teamsIdArray.length) {
    return teamsIdArray.map((teamId) => `[${teamId}]`).join(',');
  }
  return '';
};

// Build user data to be sent to third party services to identify user
const buildUserData = (userStore) => {
  const { user, userProfileStats, teamData } = userStore;
  if (user) {
    let licenseType = 'Free';
    if (user.is_cip) {
      licenseType = 'CIP';
    } else if (user.is_enterprise) {
      licenseType = 'Teams';
    }
    const onboardingData = user?.onboarding_data || {};
    const activeSubscription = userStore?.subscriptions?.activeSubscription;
    return {
      fullName: user?.full_name,
      email: user?.email,
      company: userStore?.profileData?.company,
      registrationDate: moment(user.registered_at).toISOString(),
      role: user?.onboarding_role || userStore.role,
      goal: user?.onboarding_goal || userStore.goal,
      licenseType,
      onboardingExperienceLevel: onboardingData?.experienceLevel,
      onboardingGoal: onboardingData?.goal,
      onboardingMembershipFor: onboardingData?.membershipFor,
      onboardingTeamLead: onboardingData?.teamLead,
      onboardingTitle: onboardingData?.title,
      // User Profile Data
      firstName: user?.first_name,
      lastName: user?.last_name,
      username: user?.username,
      // User Profile Stats
      completedAssessments: userProfileStats?.completed_assessments,
      completedCourses: userProfileStats?.completed_courses,
      completedLabs: userProfileStats?.completed_labs,
      completedLearningHours: userProfileStats?.completed_learning_hours,
      // Active subscription
      activeSubscriptionSource: activeSubscription?.source,
      activeSubscriptionType: activeSubscription?.type,
      activeSubscriptionActualUnitPrice: activeSubscription?.actual_unit_price,
      // Active Team Information
      teamName: teamData?.name,
      teamRole: teamData?.role,
      teamId: teamData?.id,
      teamMaxSeats: teamData?.max_seats,
      teamMemberCount: teamData?.member_count,
      teamLicensesUsed: teamData?.active_seats,
      teamJoinedAt: teamData?.joined_at,
      // Team Onboarding Data
      teamOnboardingRole: teamData?.onboarding?.role,
      teamOnboardingRoleOther: teamData?.onboarding?.role_other,
      teamOnboardingGoals: teamData?.onboarding?.goals?.join(','),
      teamOnboardingLearningApproach: teamData?.onboarding?.learning_approach,
      // Team invite_url
      teamInviteLink: userStore?.potentialTeam?.invite_url || '',
      // All Teams (active/inactive) a user is a part of: Ex: "[105],[227],[1253]"
      userTeams: userStore?.userTeams ? transformTeamsIdsForUserPilot(Object.keys(userStore?.userTeams)) : '',
      // Active Teams a user is a part of: Ex: "[105],[227],[1253]"
      activeTeams: transformTeamsIdsForUserPilot(userStore?.activeTeams),
      // Teams a user is an admin of: Ex: "[105],[227],[1253]"
      adminTeams: userStore?.adminTeams ? transformTeamsIdsForUserPilot(Object.keys(userStore?.adminTeams)) : '',
      // Teams a user is an owner of: Ex: "[105],[227],[1253]"
      ownerTeams: userStore?.ownerTeams ? transformTeamsIdsForUserPilot(Object.keys(userStore?.ownerTeams)) : '',
      // Internal Team flag. Production Cybrary Team ID = 2. Ex: "true"
      isInternal: !!userStore?.userTeams?.['2'],
    };
  }
  return {};
};

// Initialize & identify UserPilot user, a third party service for user guides.
const bootUserpilot = (userStore) => {
  if (!process.env.REACT_APP_USERPILOT_ID) {
    return;
  }
  Userpilot.initialize(process.env.REACT_APP_USERPILOT_ID);
  if (userStore.user) {
    const userData = buildUserData(userStore) || {};
    Userpilot.identify(userStore?.user?.id, userData);
  }
};

// Initialize & identify ProfitWell user
const bootProfitWell = (customerId) => {
  if (window.profitwell && customerId) {
    window.profitwell('start', { user_id: customerId });
  }
};

const ThirdPartyJavascript = inject('userStore')(
  observer(({ children, userStore }) => {
    const [init, setInit] = useState(false);
    const [driftPlaybookReceived, setDriftPlaybookReceived] = useState(false);
    const location = useLocation();
    const { customerId } = usePaddleCustomerId(userStore);

    useEffect(() => {
      bootProfitWell(customerId);
    }, [customerId]);

    useEffect(() => {
      // Don't want to load anything if no user or subscriptions, and only want this to occur once
      // No user = return
      if (!userStore.user) {
        return;
      }
      // If we have a user, but no subscriptions, we want to loadUserSubscriptions to get subscriptions
      if (!userStore.subscriptions) {
        userStore.loadUserSubscriptions();
        return;
      }
      // If we have a user and subscriptions, we want to initialize third party services once
      if (!init) {
        const { user, isFree, isCip, team, profileData } = userStore;
        // Add user ID to BugSnag
        Bugsnag.setUser(user.id);

        // Userpilot
        bootUserpilot(userStore);

        // Zendesk
        userStore.zendeskInit(() => checkHideZendesk(location.pathname, driftPlaybookReceived));
        // Drift
        if (isFree || isCip) {
          bootDrift(setDriftPlaybookReceived, user.id, userStore.user);
        }
        // push login (and nru_signup event) if needed to the data layer
        GaUtil.pushLoginEventsToDataLayer(user);
        // Google Analytics
        bootGA(user, team, profileData);
        // Snowplow
        initSnowplow(user.id, user.email);
        // Fullstory
        setupFullstory(user.id, team);
        // Algolia search insights
        bootSearchInsights();
        setInit(true);
      }
    }, [userStore.user, userStore?.subscriptions]);

    // Reload userPilot on location change
    useEffect(() => {
      Userpilot.reload();
    }, [location.pathname]);

    // Listener for route changes for reactions based on route, or based on drift firing a playbook
    useEffect(() => {
      checkHideZendesk(location.pathname, driftPlaybookReceived);
    }, [location.pathname, driftPlaybookReceived]);

    return children;
  })
);

export default ThirdPartyJavascript;
