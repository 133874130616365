import React, { useState, useEffect } from 'react';
import FocusLock from 'react-focus-lock';
import { twMerge } from 'tailwind-merge';
import Icon from '../Icon/Icon';
import AddLink from '../AddLink/AddLink';
import FormatUtil from '../../utils/formatUtil';
import ScreenClose from '../ScreenClose/ScreenClose';

function checkIsModuleCompleted(module) {
  for (let i = 0; i < module.activities.length; i++) {
    const activity = module.activities[i];
    // return false if any one of the activities is not completed
    if (!activity.complete) {
      return false;
    }
  }
  return true;
}

function getTopMargin(isSidebar, noTopMargin) {
  if (noTopMargin) {
    return '';
  }
  return isSidebar ? 'mt-32' : 'mt-16';
}

function Outline({ currentActivity, outline, canAccessActivity, isSidebar, isIncompleteOutline, noTopMargin = false, disableNav = false }) {
  const [outlineDropDown, setOutlineDropDown] = useState(false);
  const [moduleIds, setModuleIds] = useState({ active: null, open: [] });
  const [activityId, setActivityId] = useState(null);
  useEffect(() => {
    setActivityId(currentActivity);
    // find the initial module to set active
    if (!moduleIds.active) {
      const { outline: items } = outline;
      items.forEach((module) => {
        if (module.active) {
          setModuleIds({ active: module.id, open: [module.id] });
        }
      });
    }
  }, [currentActivity]);

  function handleModuleClick(id) {
    const hasModuleIndex = moduleIds.open.indexOf(id) !== -1;

    if (hasModuleIndex) {
      const openModules = moduleIds.open.filter((moduleId) => moduleId !== id);
      setModuleIds({ active: id, open: openModules });
    } else {
      setModuleIds({ active: id, open: [...moduleIds.open, id] });
    }
  }

  const outlineDropDownClasses = `block bg-white h-full w-full ${getTopMargin(isSidebar, noTopMargin)}`;

  return (
    <>
      <ScreenClose isOpen={outlineDropDown} handleClose={() => setOutlineDropDown(false)} />
      <FocusLock autoFocus={false} disabled={!outlineDropDown} returnFocus>
        <div id="outline-dropdown" className={outlineDropDownClasses}>
          <ActivityListWrapper
            outline={outline}
            activityId={activityId}
            moduleIds={moduleIds}
            handleModuleClick={(id) => handleModuleClick(id)}
            canAccessActivity={canAccessActivity}
            isIncompleteOutline={isIncompleteOutline}
            disableNav={disableNav}
          />
        </div>
      </FocusLock>
    </>
  );
}

function ModuleTitle({ module, openModule, index, active, isOpen, isIncompleteOutline }) {
  const { id: moduleId, title, duration } = module;
  const isActive = active === moduleId;
  const isModuleCompleted = checkIsModuleCompleted(module);
  const incompleteOutlineModule = isIncompleteOutline ? '' : 'group-hover:text-black';
  const moduleClass = `${isActive && !isIncompleteOutline ? 'text-black font-extrabold' : `text-gray-600 ${incompleteOutlineModule} font-bold`} text-left text-lg mr-3`;
  return (
    <button
      aria-label={title}
      aria-controls={`activities-${index + 1}`}
      aria-expanded={isOpen}
      onClick={() => openModule(moduleId)}
      className={`flex items-center justify-between w-full pl-7 pr-4 ${!isIncompleteOutline ? 'cursor-pointer' : ''} group`}
    >
      <div>
        <div className="flex items-center mb-2 text-2xs leading-3">
          <span className="font-semibold text-gray-600 uppercase">Module {index + 1}</span>
          {!!duration && <span className="pl-1 ml-1 text-gray-600 border-l-xs border-gray-400">{FormatUtil.formatTime(duration, 'hma')}</span>}
          {isModuleCompleted && (
            <span className="pl-1 ml-1">
              <Icon accessibilityProps={{ focusable: false, 'aria-label': 'Completed' }} name="check" className="w-5 h-5 text-green-500" />
            </span>
          )}
        </div>
        <p className={moduleClass}>{title}</p>
      </div>
      {!isIncompleteOutline && (
        <Icon
          accessibilityProps={{ focusable: false, 'aria-label': isOpen ? 'Open' : 'Closed' }}
          name={isOpen ? 'chevron-up' : 'chevron-down'}
          className="flex-none mr-5 ml-auto w-5 h-5 text-gray-600 group-hover:text-black"
        />
      )}
    </button>
  );
}

function ActivityIcon({ active, activity, canAccessActivity, disableNav = false }) {
  let activityIcon = canAccessActivity(activity) ? 'circle' : 'lock';
  const isActive = active === activity.id;
  if (disableNav && !isActive) {
    activityIcon = 'lock';
  }
  const iconClasses = isActive && !activity.isFree ? 'text-black' : 'text-gray-400';
  return (
    <div>
      {activity.complete ? (
        <Icon accessibilityProps={{ focusable: false, 'aria-label': 'Completed' }} name="check" className="mr-2 -ml-1 w-5 h-5 text-green-500" />
      ) : (
        <Icon accessibilityProps={{ focusable: false, 'aria-label': activity.isFree ? 'Incompleted' : 'Locked' }} name={activityIcon} className={`h-4 w-4 mr-2 ${iconClasses}`} />
      )}
    </div>
  );
}

function ActivityList({ activities, moduleIndex, active, canAccessActivity, isIncompleteOutline, disableNav = false }) {
  const moduleNumber = moduleIndex ? moduleIndex + 1 : 1;

  return (
    <ul id={`activities-${moduleNumber}`} className="mt-3">
      {activities.map((activity) => {
        const activeActivity = active === activity.id;
        // Links are either disabled or real, based on disableNav property.  Disabled links are just null returning functions
        const linkProperties = disableNav ? { onClick: () => null } : { to: activity.url };
        const activityClasses = twMerge(
          activeActivity && !isIncompleteOutline
            ? 'text-black hover:text-black bg-gray-200 font-semibold border-l-2 border-cyb-pink-500'
            : 'text-gray-600 hover:bg-gray-200 hover:text-black hover:font-semibold',
          !activeActivity && disableNav ? 'cursor-not-allowed' : ''
        );
        const subtitles = [];
        if (activity.xp) {
          subtitles.push(`${activity.xp} XP`);
        }
        if (activity.duration) {
          subtitles.push(FormatUtil.formatTime(activity.duration, 'hma'));
        }
        if (activity.optional) {
          subtitles.push('Optional');
        }
        return (
          <li key={activity.id}>
            <AddLink {...linkProperties} className={`w-full flex flex-col ${activityClasses} text-sm py-4 pl-7 pr-4 hover:bg-gray-200`}>
              <div className="flex items-start">
                {!isIncompleteOutline && <ActivityIcon active={active} activity={activity} canAccessActivity={canAccessActivity} disableNav={disableNav} />}
                <span className="truncate">{activity.title}</span>
              </div>
              {!!subtitles?.length && (
                <div className={`flex items-center ${activeActivity ? 'text-black' : 'text-gray-600'} text-2xs ml-6 mt-0`}>
                  <span>{subtitles.join(' | ')}</span>
                </div>
              )}
            </AddLink>
          </li>
        );
      })}
    </ul>
  );
}

function ActivityListWrapper({ outline, activityId, moduleIds, handleModuleClick, canAccessActivity, isIncompleteOutline, disableNav = false }) {
  const items = isIncompleteOutline ? outline.incompleteOutline : outline.outline;
  return items.map((content, index) => {
    if (['Cybrary Live Session', 'VideoActivity', 'Video Activity', 'video'].indexOf(content.type) !== -1) {
      return <ActivityList key={content.id} activities={content.activities} active={activityId} canAccessActivity={canAccessActivity} isIncompleteOutline={isIncompleteOutline} />;
    }
    const isOpenModule = isIncompleteOutline ? true : moduleIds.open.indexOf(content.id) !== -1;
    return (
      <div key={content.id} className="pt-6 pb-4 border-b-xs border-gray-400">
        <ModuleTitle index={index} module={content} openModule={handleModuleClick} active={moduleIds.active} isOpen={isOpenModule} isIncompleteOutline={isIncompleteOutline} />
        {content.activities && content.activities.length && isOpenModule && (
          <ActivityList
            disableNav={disableNav}
            activities={content.activities}
            moduleIndex={index}
            active={activityId}
            canAccessActivity={canAccessActivity}
            isIncompleteOutline={isIncompleteOutline}
          />
        )}
      </div>
    );
  });
}

export default Outline;
