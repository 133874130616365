import React, { createContext, useContext, useState, useMemo, useCallback } from 'react';
import Agents from '../agents/agents';
import BugsnagUtil from '../utils/bugsnagUtil';

const CybAssessmentPathContentContext = createContext();

/**
 * Cybrary Assessment Path Assessment Provider.
 *
 * Provider to interface with the content of teams assessment paths.
 * @param {*} children - React children
 * @returns <CybAssessmentPathContentProvider.Provider />
 */
function CybAssessmentPathContentProvider({ children }) {
  // array of assessment path assessments from api
  const [assessments, setAssessments] = useState([]);
  // index of assessment learners
  const [assessmentLearners, setAssessmentLearners] = useState({});
  // index of assessment score distributions
  const [assessmentScoreDistributions, setAssessmentScoreDistributions] = useState({});
  // loading states
  const [isLoadingAssessment, setIsLoadingAssessment] = useState(false);
  const [isLoadingAssessmentLearners, setIsLoadingAssessmentLearners] = useState(false);
  const [isLoadingAssessmentScoreDistributions, setIsLoadingAssessmentScoreDistributions] = useState(false);
  // Error states
  const [assessmentError, setAssessmentError] = useState(null);
  const [assessmentLearnersError, setAssessmentLearnersError] = useState(null);
  const [assessmentScoreDistributionsError, setAssessmentScoreDistributionsError] = useState(null);
  // Learners for the current assessment - stored here to avoid re-computing this data in components since its shared
  const [currentAssessmentLearners, setCurrentAssessmentLearners] = useState(null);
  // Current assessment - stored here to avoid re-computing this data in components since its shared
  const [currentAssessment, setCurrentAssessment] = useState(null);
  // Score distributions for the current assessment - stored here to avoid re-computing this data in components since its shared
  const [currentAssessmentScoreDistributions, setCurrentAssessmentScoreDistributions] = useState(null);

  /**
   * get or fetch assessment by `orgId` and `contentId` and set `currentAssessment`
   * @param {number} orgId - Org ID
   * @param {number} contentId - Assessment ID
   */
  const getAssessment = useCallback(
    async (orgId, contentId) => {
      try {
        const parsedOrgId = parseInt(orgId, 10);

        let assessment = assessments.find((assess) => assess.org_id === parsedOrgId && assess.id === parseInt(contentId, 10));

        if (!assessment) {
          setIsLoadingAssessment(true);
          setAssessmentError(null);

          // we get the content via `catalog` api and not the `getCurriculum` api becuase it gives more data
          const content = await Agents.catalog.getContentDescriptionById(contentId);

          assessment = {
            ...content,
            org_id: parsedOrgId,
          };

          setAssessments((prevState) => [...prevState, assessment]);
        }

        setCurrentAssessment(assessment);
      } catch (getAssessmentError) {
        setAssessmentError(getAssessmentError);
        BugsnagUtil.notify(getAssessmentError);
      } finally {
        setIsLoadingAssessment(false);
      }
    },
    [assessments]
  );

  /**
   * get or fetch learners for current assessment by `orgId` and set `currentAssessmentLearners`
   * @param {number} orgId - Org ID
   */
  const getAssessmentLearners = useCallback(
    async (orgId) => {
      if (!currentAssessment) {
        return;
      }

      try {
        let learnersEntry = assessmentLearners[currentAssessment.id];

        if (!learnersEntry) {
          setIsLoadingAssessmentLearners(true);
          setAssessmentLearnersError(null);

          // first, make request to get assessment learners with no specific data format
          const res = await Agents.reports.getReportData(orgId, 'assessment-learners', `?contentDescriptionId=${currentAssessment.id}`);

          // secondly, make request to get assessment learners in table format
          const resTableFormat = await Agents.reports.getReportData(orgId, 'assessment-learners', `?contentDescriptionId=${currentAssessment.id}&format=table`);

          learnersEntry = {
            default: res,
            table: resTableFormat,
          };

          setAssessmentLearners((prevState) => ({
            ...prevState,
            [currentAssessment.id]: learnersEntry,
          }));
        }

        setCurrentAssessmentLearners(learnersEntry);
      } catch (getAssessmentLearnersError) {
        setAssessmentLearnersError(getAssessmentLearnersError);
        BugsnagUtil.notify(getAssessmentLearnersError);
      } finally {
        setIsLoadingAssessmentLearners(false);
      }
    },
    [currentAssessment, assessmentLearners]
  );

  /**
   * get or fetch score distributions for current assessment by `orgId` and set `currentAssessmentScoreDistributions`
   * @param {number} orgId - Org ID
   */
  const getAssessmentScoreDistributions = useCallback(
    async (orgId) => {
      if (!currentAssessment) {
        return;
      }

      try {
        let entry = assessmentScoreDistributions[currentAssessment.id];

        if (!entry) {
          setIsLoadingAssessmentScoreDistributions(true);
          setAssessmentScoreDistributionsError(null);

          // first, make request to get assessemnet score distributions with no specific data format
          const res = await Agents.reports.getReportData(orgId, 'assessment-score-range', `?contentDescriptionId=${currentAssessment.id}`);

          // secondly, make request to get assessemnet score distributions in table format
          const resTableFormat = await Agents.reports.getReportData(orgId, 'assessment-score-range', `?contentDescriptionId=${currentAssessment.id}&format=table`);

          entry = {
            default: res,
            table: resTableFormat,
          };

          setAssessmentScoreDistributions((prevState) => ({
            ...prevState,
            [currentAssessment.id]: entry,
          }));
        }

        setCurrentAssessmentScoreDistributions(entry);
      } catch (getAssessmentScoreDistributionsError) {
        setAssessmentScoreDistributionsError(getAssessmentScoreDistributionsError);
        BugsnagUtil.notify(getAssessmentScoreDistributionsError);
      } finally {
        setIsLoadingAssessmentScoreDistributions(false);
      }
    },
    [currentAssessment, assessmentScoreDistributions]
  );

  const actions = useMemo(
    () => ({
      getAssessment,
      getAssessmentLearners,
      getAssessmentScoreDistributions,
    }),
    [getAssessment, getAssessmentLearners, getAssessmentScoreDistributions]
  );

  const value = useMemo(
    () => ({
      // State
      assessments,
      currentAssessment,
      currentAssessmentLearners,
      currentAssessmentScoreDistributions,
      assessmentError,
      assessmentLearnersError,
      assessmentScoreDistributionsError,
      isLoadingAssessment,
      isLoadingAssessmentLearners,
      isLoadingAssessmentScoreDistributions,
      // Actions
      actions,
    }),
    [
      assessments,
      currentAssessment,
      currentAssessmentLearners,
      currentAssessmentScoreDistributions,
      assessmentError,
      assessmentLearnersError,
      assessmentScoreDistributionsError,
      isLoadingAssessment,
      isLoadingAssessmentLearners,
      isLoadingAssessmentScoreDistributions,
      actions,
    ]
  );

  return <CybAssessmentPathContentContext.Provider value={value}>{children}</CybAssessmentPathContentContext.Provider>;
}

export const useCybAssessmentPathContent = () => useContext(CybAssessmentPathContentContext);

export default CybAssessmentPathContentProvider;
