import React from 'react';

function Divider({ horizontal, vertical, children, className, style = {}, marginTop, marginBottom }) {
  if (horizontal) {
    if (children) {
      return (
        <div className={`divider-horizontal flex leading-4 ${className || ''}`} style={style}>
          <div className="mx-4">{children}</div>
        </div>
      );
    }
    return <div className={`border-b-xs border-gray-400 leading-4 text-base h-0 ${className || ''}`} style={style} />;
  }
  if (vertical) {
    if (children) {
      return (
        <div className={`flex flex-col justify-center items-center ${className || ''}`} style={style}>
          <div className="pb-4 divider-vertical" />
          <div className="py-4 divider-vertical-content">{children}</div>
          <div className="pt-4 divider-vertical" />
        </div>
      );
    }
    return <div className={`border-l-xs border-gray-400 inline-block ${className || ''}`} style={style} />;
  }
  // DEFAULT HORIZONTAL
  return (
    <div className={`${marginTop || 'mt-12'} ${marginBottom || 'mb-12'} ${className || ''}`}>
      <div className="border-b-xs border-gray-400" />
    </div>
  );
}

export default Divider;
