import React from 'react';
import FormatUtil from '../../utils/formatUtil';

export default function ResultsSummary({ srOnly, numOfResults, className }) {
  // Still want to show even if 0
  if (numOfResults >= 0) {
    const results = `${numOfResults || 0} ${FormatUtil.pluralize(numOfResults, 'result')}`;
    const containerClass = `${className || ''} ${srOnly || !numOfResults ? 'sr-only' : ''}`;
    return (
      <div aria-live="polite" className={containerClass}>
        <h2 className="text-2xl font-black sr-only">Results:</h2>
        <p className="text-sm" role="status">
          Showing {results}
        </p>
      </div>
    );
  }
  return null;
}
