import React from 'react';
import AddLink from '../AddLink/AddLink';
import FormatUtil from '../../utils/formatUtil';

function formatNotificationBody(body, key, meta) {
  if (!meta) {
    return body;
  }
  const { name, team_name: teamName, content_description: contentDescription, due_date: dueDate } = meta;
  const contentDescriptionTitle = contentDescription ? contentDescription.title : meta.content_description_title;
  if (!contentDescriptionTitle) {
    return body;
  }
  switch (key) {
    case 'assignment-created|user':
      return `You've been assigned ${name}. Let's get started.`;

    case 'certificate-claim|user':
      return `Congratulations! You've earned your ${contentDescriptionTitle} certificate of completion.
              Click through to your profile to view your certificate and share your achievement with the world.`;

    case 'assignment-completed|user':
      return `Nice work! You've completed ${contentDescriptionTitle}. Ready to keep going?`;

    case 'content-updated|user':
      return `${contentDescriptionTitle} has recently been updated. What's changed? Click this message to visit My Enrollments for more details.`;

    case 'assignment-due|today|user':
      return `Your assignment, ${name}, is due today. Good work on getting here, let's finish strong!`;

    case 'assignment-due|tomorrow|user':
      return `Your assignment, ${name}, is due tomorrow. Time to buckle bown!`;

    case 'assignment-due|1-week|user':
      return `Don't forget, your assignment, ${name}, is due on ${dueDate}. Don't lose momentum, you can do it!`;

    case 'assignment-due|2-weeks|user':
      return `Just a friendly reminder that your assignment, ${name}, is due on ${dueDate}. Keep up the good work!`;

    case 'content-updated|admin':
      return `You previously assigned ${contentDescriptionTitle} to ${teamName}. This assignment or its contents 
              have recently been updated. Click this message to see what's changed.`;

    case 'assignment-due|today|admin':
      return `${teamName} has ${name} due today. How'd they do? Assess their performance and prepare the next assignment. Let's keep building their skills!`;

    case 'assignment-due|tomorrow|admin':
      return `${teamName} has ${name} due tomorrow. Now would be a good time to review their progress.`;

    case 'assignment-due|1-week|admin':
      return `Don't forget, ${teamName} has ${name} due on ${dueDate}. Feel free to send a little encouragement their way, it can't hurt!`;

    case 'assignment-due|2-weeks|admin':
      return `Just a friendly reminder that ${teamName} has ${name} due on ${dueDate}.`;

    default:
      return body;
  }
}

function formatDate(d) {
  return FormatUtil.convertUtcToLocal(d).format('ddd, MMM D, hh:mm a');
}

function Notification({ notification }) {
  if (!notification || !notification.data) {
    return null;
  }

  const { subject, body, link, meta, key } = notification.data;

  const formattedBody = formatNotificationBody(body, key, meta);
  const linkClasses = 'text-cyb-pink-500 hover:text-cyb-pink-500 px-0 underline';

  const targetProp = /^http/.test(link) ? { target: '_blank', rel: 'noopener nofollow' } : {};

  return (
    <li className="pb-8 mb-12 border-b-xs border-gray-400 lg:pb-12">
      <div className="flex items-center mb-2 w-full space-between">
        <div className="flex relative flex-1">
          <h2 className="text-lg font-bold">{subject}</h2>
          {!notification.read_at && <div className="z-10 ml-1 w-2 h-2 bg-cyb-blue-500 rounded" />}
        </div>

        {link && (
          <AddLink to={link} {...targetProp} className={linkClasses}>
            Visit
            <span className="sr-only"> Notification &lsquo;{subject}&rsquo;</span>
          </AddLink>
        )}
      </div>
      <p className="mb-4 w-full text-sm text-gray-600 lg:w-3/4">
        {/* eslint-disable-next-line react/no-danger */}
        <span dangerouslySetInnerHTML={{ __html: formattedBody }} />
      </p>
      <p className="text-xs text-gray-600">{formatDate(notification.created_at)}</p>
    </li>
  );
}

export default Notification;
