import React from 'react';

export default function GitHub({ classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6 bg-white';
  return (
    <svg className={className} viewBox="0 0 20 20" fill="currentColor" {...accessibilityProps}>
      <path
        d="M17.2727 0H2.72727C2.00396 0 1.31026 0.287337 0.7988 0.7988C0.287337 1.31026 0 2.00396 0 2.72727L0 17.2727C0 17.996 
      0.287337 18.6897 0.7988 19.2012C1.31026 19.7127 2.00396 20 2.72727 20H17.2727C17.996 20 18.6897 19.7127 19.2012 19.2012C19.7127 
      18.6897 20 17.996 20 17.2727V2.72727C20 2.00396 19.7127 1.31026 19.2012 0.7988C18.6897 0.287337 17.996 0 17.2727 0ZM11.9602 
      15.9716C11.6398 16.033 11.5307 15.8398 11.5307 15.6795C11.5307 15.4795 11.5386 14.8193 11.5386 13.9977C11.5386 13.4216 11.3352 
      13.0455 11.117 12.8614C12.5068 12.7102 13.9682 12.1909 13.9682 9.83182C13.9774 9.21937 13.7475 8.62746 13.3273 8.18182C13.3898 
      8.02614 13.6011 7.40114 13.2648 6.55568C13.2648 6.55568 12.742 6.39091 11.5602 7.18523C10.5368 6.90947 9.45864 6.90947 8.43523 
      7.18523C7.23977 6.38977 6.71591 6.55568 6.71591 6.55568C6.375 7.40341 6.59091 8.02841 6.65568 8.18182C6.23446 8.62607 6.00364 
      9.21741 6.0125 9.82955C6.0125 12.1818 7.47159 12.7102 8.85341 12.8636C8.62681 13.0808 8.48636 13.3726 8.45795 13.6852C8.1 
      13.842 7.19432 14.1136 6.63977 13.175C6.63977 13.175 6.31023 12.5841 5.68295 12.542C5.68295 12.542 5.07273 12.5341 5.63864 
      12.9148C5.63864 12.9148 6.05 13.1045 6.33409 13.8125C6.33409 13.8125 6.70114 15.0068 8.43977 14.6375C8.43977 15.1489 8.44659 
      15.5352 8.44659 15.6818C8.44659 15.8284 8.33296 16.0341 8.02046 15.9761C5.54091 15.167 3.75 12.8659 3.75 10.1523C3.75 6.76023 
      6.54659 4.01591 10 4.01591C13.4534 4.01591 16.25 6.76591 16.25 10.158C16.25 12.8614 14.4614 15.1648 11.9602 15.9716Z"
      />
    </svg>
  );
}
