import Bugsnag from '@bugsnag/js';
import { useState, useEffect } from 'react';
import SearchUtil from '../utils/searchUtil';
import agents from '../agents/agents';

const getAlgoliaIndex = (isEnt, isPaid) => {
  let algoliaIndex = '';

  switch (true) {
    // as of today... if user isEnt and (free OR paid)... we still want them to use the CIP index
    case isEnt:
      algoliaIndex = process.env.REACT_APP_INSTANTSEARCH_RECOMMENDATIONS_CIP;
      break;
    // paying CIP user
    case !isEnt && isPaid:
      algoliaIndex = process.env.REACT_APP_INSTANTSEARCH_RECOMMENDATIONS_CIP;
      break;
    // free user
    case !isEnt && !isPaid:
    default:
      algoliaIndex = process.env.REACT_APP_INSTANTSEARCH_RECOMMENDATIONS_FREE;
  }
  return algoliaIndex;
};

export const useAlgoliaRecommendedContent = (
  isEnt = false,
  isPaid = false,
  // see searchUtil(fetchFromAlgolia) for examples of more searchOptions
  searchOptions = {
    filters: 'display_in_catalog:true',
    hitsPerPage: 20,
  }
) => {
  const [recommendedContent, setRecommendedContent] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const algoliaIndex = getAlgoliaIndex(isEnt, isPaid);
    const getRecommendedContent = async () => {
      try {
        const response = await SearchUtil.fetchFromAlgolia(algoliaIndex, searchOptions);
        setRecommendedContent(response);
      } catch (error) {
        Bugsnag.notify(error, (event) => {
          const { errorMessage } = event.errors[0];
          // eslint-disable-next-line no-param-reassign
          event.context = `useRecommendedContentHook: ${errorMessage}`;
          event.addMetadata('useRecommendedContent', {
            isEnt,
            isPaid,
            searchOptions,
          });
        });
      } finally {
        setIsLoading(false);
      }
    };
    getRecommendedContent();
  }, [isEnt, isPaid]);

  return [recommendedContent, isLoading];
};

export const useBackendRecommendedContent = () => {
  const [recommendedContent, setRecommendedContent] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await agents.catalog.getRecommendations();
        setRecommendedContent(response);
      } catch (err) {
        setError(err);
        Bugsnag.notify(err, (event) => {
          const { errorMessage } = event.errors[0];
          // eslint-disable-next-line no-param-reassign
          event.context = `useRecommendedContentHook: ${errorMessage}`;
        });
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return [recommendedContent, isLoading, error];
};
