import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import { colorSchemes } from '@nivo/colors';
import If from '../If/If';
import FlexLegends from './FlexLegends';

// Data is expected to be an array of objects, each object represents a single line, with an "id" and a data array. The data array
// for each line contains objects with two properties - x and y.

function LineChart({
  data,
  tooltip = null,
  axisLeftFormatter = null,
  stacked = true,
  yMin = 0,
  yMax = 'auto',
  legends = false,
  onClick = null,
  colorScheme = 'category10',
  areaOpacity = 0.2,
  markers = null,
  customColors = null,
}) {
  const colorsFromOurScheme = customColors || colorSchemes[colorScheme];
  const tooltipProps = tooltip ? { tooltip } : {};
  const colorProps = customColors ? { colors: { datum: 'color' } } : { colors: { scheme: colorScheme } };
  const axisLeftFormatterProps = axisLeftFormatter
    ? {
        format: axisLeftFormatter,
      }
    : {};
  const yScale = stacked
    ? {
        yScale: {
          type: 'linear',
          min: yMin,
          max: yMax,
          stacked: true,
          reverse: false,
        },
      }
    : {};
  const legendData = legends ? data.map((item, index) => ({ ...item, color: colorsFromOurScheme[index] })) : [];
  return (
    <>
      <div className="relative h-[400px]">
        <ResponsiveLine
          data={data}
          margin={{ top: 30, right: 60, bottom: 50, left: 60 }}
          xScale={{ type: 'point' }}
          {...yScale}
          yFormat=" >-.2f"
          animate
          axisTop={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            truncateTickAt: 0,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            truncateTickAt: 0,
            ...axisLeftFormatterProps,
          }}
          axisRight={null}
          {...colorProps}
          pointSize={10}
          pointColor={{ theme: 'background' }}
          pointBorderWidth={2}
          pointBorderColor={{ from: 'serieColor' }}
          pointLabel="data.yFormatted"
          pointLabelYOffset={-12}
          enableArea
          areaOpacity={areaOpacity}
          enableTouchCrosshair
          useMesh
          onClick={onClick}
          curve="monotoneX"
          markers={markers}
          {...tooltipProps}
          layers={['grid', 'markers', 'axes', 'areas', 'crosshair', 'lines', 'points', 'slices', 'mesh']}
        />
      </div>
      <If condition={!!legends}>
        <FlexLegends legendData={legendData} />
      </If>
    </>
  );
}

export default LineChart;
