import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { twMerge } from 'tailwind-merge';
import EDUUpgradeBanner from './EDUUpgradeBanner';
import TeamsLicenseCheckBanner from './TeamsLicenseCheckBanner';
import UpgradePromoBanner from './UpgradePromoBanner';
import useBetaCookie from '../../hooks/cookies/useBetaCookie';
import { usePromo } from '../../providers/PromoProvider';

const BANNER_STYLE_CLASSNAME = 'banner-adjustment-upgrade';

const DEFAULT_TARGET_URL = `/upgrade/checkout/annual`;

const HeaderPromoBanner = inject(
  'userStore',
  'profileStore',
  'commonStore'
)(
  observer(({ userStore, profileStore, commonStore, className, inline = false }) => {
    const { isTeamsLicenseCheckPromoBetaEnabled } = useBetaCookie();
    const { additionalClasses, addCybRouterClass, removeCybRouterClass } = commonStore;
    const { title } = userStore?.user?.onboarding_data || {};
    const { isEduGovMilUser, isPublicEmailOnlyUser, totalVerifiedEmailAccounts } = profileStore;

    const { activePromo, isHeaderBannerDismissed } = usePromo();

    useEffect(() => {
      if (inline || !activePromo || isHeaderBannerDismissed) {
        removeCybRouterClass(BANNER_STYLE_CLASSNAME);
      } else {
        addCybRouterClass(BANNER_STYLE_CLASSNAME);
      }
      return () => {
        removeCybRouterClass(BANNER_STYLE_CLASSNAME);
      };
    }, [inline, activePromo, additionalClasses?.length, isHeaderBannerDismissed]);

    /** Teams License Check Banner */
    const isTeamsLicenseCheckPromoShown =
      isTeamsLicenseCheckPromoBetaEnabled && isPublicEmailOnlyUser && totalVerifiedEmailAccounts < 2 && !userStore.isEnterprise && !userStore.isCip;
    if (isTeamsLicenseCheckPromoShown) {
      return <TeamsLicenseCheckBanner className={className} />;
    }

    /**
     * LEGACY BANNER - EDU MIL GOV Banner
     * This is the old banner that shows for edu, mil, and gov that isn't worth rewriting to the new promo system at this time
     */

    const isStudent = title === 'Student';
    const isEduGovMilPromoShown = (isStudent || isEduGovMilUser) && !userStore.isEnterprise && !userStore.isCip;
    if (isEduGovMilPromoShown) {
      return <EDUUpgradeBanner className={className} isEduGovMilUser={isEduGovMilUser} />;
    }

    if (!activePromo) {
      return null;
    }

    const promoTitleClassName = twMerge('md:whitespace-nowrap', activePromo?.titleClassName);
    const promoBodyClassName = twMerge('font-normal', activePromo?.bodyClassName);
    const promoFooterClassName = twMerge('flex justify-center items-center py-0 text-xs italic font-normal align-middle whitespace-nowrap', activePromo?.footerClassName);

    const bannerClassName = twMerge(className, activePromo?.bannerClassName);

    if (activePromo?.component) {
      return <activePromo.component className={bannerClassName} activePromo={activePromo} />;
    }

    return (
      <UpgradePromoBanner
        className={bannerClassName}
        iconName={activePromo.iconName || 'unlock'}
        iconClassName={activePromo.iconClassName}
        targetUrl={activePromo.targetUrl || DEFAULT_TARGET_URL}
        spLabel={activePromo.spLabel}
        spCategory={activePromo.spCategory}
        ctaButtonText={activePromo.ctaButtonText}
        ctaButtonClassName={activePromo.ctaButtonClassName}
        isDismissible={activePromo.isDismissible}
        isDismissed={isHeaderBannerDismissed}
        dismissedKey={activePromo.dismissedKey}
        delayOnDismiss={activePromo.delayOnDismiss}
      >
        <div className={promoTitleClassName}>{activePromo.title}</div>
        <div className={promoBodyClassName}>{activePromo.body}</div>
        <span className={promoFooterClassName}>{activePromo.footer}</span>
      </UpgradePromoBanner>
    );
  })
);
export default HeaderPromoBanner;
