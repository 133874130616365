import React, { useRef } from 'react';
import SelectBoxes from '../SelectBoxes/SelectBoxes';
import Divider from '../Divider/Divider';
import Checkbox from '../FormFields/Checkbox';

function WizardParticipantsSelection({ setValue, selectedParticipantType, selectedParticipantIds, members, groups, searchGroups, team, filterOptions, includeAdmins }) {
  const inputRef = useRef();
  const handleClick = (section, val, fullSelections) => {
    if (section === 'participant_type') {
      setValue('participants', []);
      // If group is selected, backend is expecting it to be labeled 'team'
      setValue(section, val === 'group' ? 'team' : val);
      // If team is selected, set the participant_ids array to just the team ID
      if (val === 'team') {
        setValue('participant_ids', [team.id]);
        setValue('participants', [{ ...team }]);
      } else {
        setTimeout(() => {
          inputRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 100);
        setValue('participant_ids', []);
      }
      // If the goal participants is set to groups, set include_admins false by default, otherwise true
      setValue('include_admins', val !== 'group');
    } else {
      setValue(section, val);
      setValue('participants', [...fullSelections]);
    }
  };

  const options = [
    {
      text: 'Group(s)',
      value: 'group',
    },
    {
      text: 'Individual team member(s)',
      value: 'user',
    },
  ];

  // If user user can manage team, give 'My entire team' option
  if (team.permissions && team.permissions.canManageTeam) {
    options.unshift({
      text: 'My entire team',
      value: 'team',
    });
  }

  return (
    <>
      <SelectBoxes
        containerClasses="overflow-y-auto w-full"
        selections={selectedParticipantType}
        handleSelect={(selection) => handleClick('participant_type', selection)}
        options={options}
      />
      {selectedParticipantType === 'group' ? (
        <div>
          <Divider marginTop="mt-6" marginBottom="mb-6" />
          <div ref={inputRef} />
          <p className="mb-4 text-sm text-gray-600">Select one or more groups from the list below that you want to complete to this goal.</p>
          <div className="flex items-center mb-4 text-sm">
            <Checkbox toggle className="mr-8" onChange={(e, { value }) => setValue('include_admins', value)} checked={includeAdmins} ariaLabelledBy="toggle-admins" />
            <p id="toggle-admins">Include the Group Admin(s) in this goal</p>
          </div>
          <SelectBoxes
            containerClasses="overflow-y-auto w-11/12"
            multiSelect
            showSelectAll
            returnFullSelections
            handleSearch={(query) => searchGroups(query)}
            selections={selectedParticipantIds}
            handleSelect={(selection, fullSelections) => handleClick('participant_ids', selection, fullSelections)}
            options={groups.selectOptions}
            loading={groups.loading}
          />
        </div>
      ) : null}
      {selectedParticipantType === 'user' ? (
        <div>
          <Divider marginTop="mt-6" marginBottom="mb-6" />
          <div ref={inputRef} />
          <p className="mb-4 text-sm text-gray-600">Select one or more team members from the list below that you want to complete to this goal.</p>
          <SelectBoxes
            containerClasses="overflow-y-auto w-11/12"
            multiSelect
            showSelectAll
            returnFullSelections
            handleSearch={(query) => filterOptions('goalParticipants', query)}
            selections={selectedParticipantIds}
            handleSelect={(selection, fullSelections) => handleClick('participant_ids', selection, fullSelections)}
            options={members.data}
            loading={members.loading}
          />
        </div>
      ) : null}
    </>
  );
}

export default WizardParticipantsSelection;
