import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const routerProps = {
      location: useLocation(),
      navigate: useNavigate(),
      match: {
        params: useParams(),
      },
    };
    return <Component {...props} {...routerProps} />;
  }

  return ComponentWithRouterProp;
}

export default withRouter;
