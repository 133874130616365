import React, { useState } from 'react';
import queryString from 'query-string';
import { AttentionSeeker, Fade } from 'react-awesome-reveal';
import Tabs from '../../Tabs/Tabs';
import LearningProgressTab from './LearningProgressTab/LearningProgressTab';
import CertCompletionTab from './CertCompletionTab';
import CertificationsTab from './CertificationsTab/CertificationsTab';
import Icon from '../../Icon/Icon';
import BadgesTab from './BadgesTab/BadgesTab';
import If from '../../If/If';
import BadgesToggle from './BadgesTab/BadgesToggle';

/**
 * @param {object} certificates -  certifications of completion for the user
 * @param {array} certContentTypes - array of the keys in the certs object
 * @param {boolean} isCertsLoading - boolean indicating whether the certificates are loading
 * @param {Array} certificationsProfileData - array of objects with the users certifications
 * @param {number} completedCourses - number of completed courses
 * @param {number} completedLabs - number of completed labs
 * @param {number} completedLearningHours - number of completed learning hours
 * @param {number} passedAssessments - number of passed assessments
 * @param {boolean} isStatsLoading - boolean indicating whether the stats are loading
 * @param {array} certOptions - array of objects with certification options for add/edit cert dropdown
 * @param {boolean} isEditable - boolean indicating whether the profile is editable
 * @param {boolean} isPrivate - boolean indicating whether the profile is private
 * @param {object} xpTaxonomy - object with the xp taxonomy data
 * @param {array} badges - array of objects with the users badges (earned and unearned)
 * @param {string} username - the username of the logged in user
 * @returns <ProfileTabs /> the new profile page
 */
function ProfileTabs({
  certificates,
  certContentTypes,
  isCertsLoading,
  certificationsProfileData,
  completedCourses,
  completedLabs,
  completedLearningHours,
  passedAssessments,
  isStatsLoading,
  certOptions,
  isEditable,
  isPrivate,
  xpTaxonomy,
  badges,
  username,
}) {
  const queryParams = queryString.parse(window.location.search);
  // Get the total number of completed certifications for display in tab header
  // certificates is an object with keys of the cert group name and values of the cert group items
  // ex: { 'Course': [{}, {}, ...], 'Microcourse': [{}, {}, ...], 'Lab': [{}, {}, ...], ... }
  const numOfCompletedCertifications = certificates ? Object.keys(certificates).reduce((totalCerts, currentGroupKey) => totalCerts + certificates[currentGroupKey].length, 0) : 0;
  const numOfCertifications = certificationsProfileData?.length || 0;
  // Earned / All badges toggle - default to showing all badges with support for the query param to show only earned badges
  const [showAllBadges, setShowAllBadges] = useState(!queryParams?.showEarnedBadges);
  const displayBadges = showAllBadges ? badges : badges.filter((badge) => badge.completed_at);
  // Get the total number of badges with respect to the current display for the tab header
  const numOfBadges = displayBadges?.length || 0;
  const numOfBadgesEarned = displayBadges?.filter((badge) => badge.completed_at).length || 0;
  // Set Badges title based on whether or not the user is viewing all badges or just earned badges
  const badgesTitle = showAllBadges ? `Badges (${numOfBadgesEarned}/${numOfBadges})` : `Badges (${numOfBadges})`;
  // Tabs to display in the profile page
  const tabs = [
    { id: 'learning-progress', text: `Learning Progress` },
    { id: 'badges', text: badgesTitle },
    { id: 'certifications', text: `Certifications (${numOfCertifications || 0})` },
    { id: 'cert-completion', text: `Certificates of Completion  (${numOfCompletedCertifications || 0})` },
  ];
  const tabIds = tabs.map((tab) => tab.id);
  const defaultTabId = tabIds.includes(queryParams?.tab) ? queryParams.tab : 'learning-progress';
  // defaultIndex is the index of the default tab on load (default is 0) but with query params it will change...
  // the index will tell the tabs component which tab should be active by default
  const defaultIndex = tabIds.indexOf(defaultTabId);

  const [activeTab, setActiveTab] = useState(defaultTabId);

  return (
    <div>
      {!!isPrivate && (
        <div className="absolute inset-0 top-20 z-10 text-center cursor-not-allowed ">
          <Fade duration={500} triggerOnce className="h-full">
            <div className="flex flex-col justify-center mt-6 h-full text-xl">
              <AttentionSeeker delay={200} effect="headShake">
                <Icon name="lock" className="p-4 mx-auto mb-8 w-36 h-36 rounded-full border-8 border-black" />
              </AttentionSeeker>
              <h2 className="mx-auto font-black">This profile is private.</h2>
              <h3 className="mx-auto">You do not have access to view this profile.</h3>
            </div>
          </Fade>
        </div>
      )}
      <div className={`w-full ${isPrivate ? 'blur-[4px]' : ''}`}>
        {/** Header Tabs */}
        <Tabs
          containerClasses="mb-4"
          tabListClasses="gap-x-12"
          tabs={tabs}
          selected={activeTab}
          onChange={(tab) => {
            setActiveTab(tab.id);
            // keep any existing query params and update the tab
            const newQueryParams = { ...queryParams, tab: tab.id };
            window.history.pushState(null, null, `?${queryString.stringify(newQueryParams)}`);
          }}
          defaultIndex={defaultIndex}
        />
        {/** Tab Body */}
        <If condition={activeTab === 'learning-progress'}>
          <LearningProgressTab
            completedCourses={completedCourses}
            completedLabs={completedLabs}
            completedLearningHours={completedLearningHours}
            passedAssessments={passedAssessments}
            xpTaxonomy={xpTaxonomy}
            isLoading={isStatsLoading}
            username={username}
          />
        </If>
        <If condition={activeTab === 'certifications'}>
          <CertificationsTab certificationsProfileData={certificationsProfileData} certOptions={certOptions} isEditable={isEditable} />
        </If>
        <If condition={activeTab === 'cert-completion'}>
          <CertCompletionTab isLoading={isCertsLoading} certs={certificates} contentTypes={certContentTypes} />
        </If>
        <If condition={activeTab === 'badges'}>
          <BadgesToggle showAllBadges={showAllBadges} setShowAllBadges={setShowAllBadges} />
          <BadgesTab badges={displayBadges} />
        </If>
      </div>
    </div>
  );
}
export default ProfileTabs;
