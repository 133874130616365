import React from 'react';

export default function UserPlus({ classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 1200 1200" stroke="currentColor" {...accessibilityProps}>
      <g fillRule="evenodd">
        <path
          fill="currentColor"
          d="m860.59 1050 1.9141 0.039062 1.6133-0.14844c7.1641-0.89844 35.887-6.4141 35.887-37.352 0-186.41-151.09-337.5-337.5-337.5h-75c-186.41 0-337.5 151.09-337.5 337.5 0-1.875 0.11328-3.4492 0.30078-4.7617-0.1875 1.5391-0.30078 3.1484-0.30078 4.7617 0 20.699 16.801 37.5 37.5 37.5 0 0 37.5-2.1367 37.5-37.5 0-144.98 117.52-262.5 262.5-262.5h75c144.98 0 262.5 117.52 262.5 262.5 0 0.78906 0.039062 1.5391 0.074219 2.2891 0.5625 12.188 5.7734 20.137 12.113 25.352 6.2266 5.7383 14.398 9.375 23.398 9.8242z"
        />
        <path
          fill="currentColor"
          d="m525 150c-165.56 0-300 134.44-300 300s134.44 300 300 300 300-134.44 300-300-134.44-300-300-300zm0 75c124.16 0 225 100.84 225 225s-100.84 225-225 225-225-100.84-225-225 100.84-225 225-225z"
        />
        <path fill="currentColor" d="m862.5 712.5h187.5c20.699 0 37.5-16.801 37.5-37.5s-16.801-37.5-37.5-37.5h-187.5c-20.699 0-37.5 16.801-37.5 37.5s16.801 37.5 37.5 37.5z" />
        <path fill="currentColor" d="m918.75 581.25v187.5c0 20.699 16.801 37.5 37.5 37.5s37.5-16.801 37.5-37.5v-187.5c0-20.699-16.801-37.5-37.5-37.5s-37.5 16.801-37.5 37.5z" />
      </g>
    </svg>
  );
}
