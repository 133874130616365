import React from 'react';

import DynamicForm from '../DynamicForm/DynamicForm';

const createTeamForm = {
  name: 'Create Team',
  order: ['seatCount', 'teamName', 'adminName', 'submit'],
  fields: {
    seatCount: {
      type: 'quantity',
      defaultValue: 2,
      label: 'Team Members',
      validations: [
        'required',
        (vals) => (vals && vals.seatCount >= 2 ? null : 'Must be at least 2 team members.'),
        (vals) => (vals && vals.seatCount < 501 ? null : 'Must be at 500 or less team members.'),
      ],
      minValue: 0,
      maxValue: 500,
      aboveMaxMessage: <p className="text-sm">Please select a value that is 500 or less</p>,
      belowMinMessage: (
        <p className="text-sm">
          If less than 2, check out <a href="https://www.cybrary.it/upgrade/checkout/?plan=monthly">Cybrary Insider Pro</a>
        </p>
      ),
    },
    teamName: {
      type: 'text',
      label: 'Company Name',
      validations: ['required'],
    },
    adminName: {
      type: 'text',
      label: 'Admin Name',
      validations: ['required'],
    },
    submit: {
      type: 'button',
      color: 'pink',
      label: 'Proceed',
      className: 'w-full px-6 py-3 text-sm md-991:w-auto',
    },
  },
};

function CreateTeam({ teamData = {}, setPlanQuantity, handleSubmit }) {
  const form = { ...createTeamForm };
  form.fields.seatCount.callback = (val) => setPlanQuantity(val);
  form.fields.seatCount.defaultValue = teamData.quantity || teamData.seatCount;
  form.fields.teamName.defaultValue = teamData.teamName;
  form.fields.adminName.defaultValue = teamData.adminName;

  return <DynamicForm form={form} onSubmit={handleSubmit} />;
}

export default CreateTeam;
