import React from 'react';

export default function Terminal({ accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true }, active, classes }) {
  const className = classes || 'w-6 h-6';
  const strokeProps = active ? { stroke: 'black' } : { stroke: 'currentColor' };
  const fillProps = active ? 'black' : 'none';
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill={fillProps} className={className} {...strokeProps} viewBox="0 0 20 20" {...accessibilityProps}>
      <path
        fillRule="evenodd"
        d="M2 5a2 2 0 012-2h12a2 2 0 012 2v10a2 2 0 01-2 2H4a2 2 0 01-2-2V5zm3.293 1.293a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-3 3a1 1 0 
        01-1.414-1.414L7.586 10 5.293 7.707a1 1 0 010-1.414zM11 12a1 1 0 100 2h3a1 1 0 100-2h-3z"
        clipRule="evenodd"
      />
    </svg>
  );
}
