// This is consumed by the DynamicForm component.

// Review form Cybrary Live Sessions, Courses, and MicroCourses
const Form = {
  name: 'Course Review Form',
  order: ['overall_quality', 'review', ['cancelBtn', 'submit']],
  columns: ['w-1/2 flex-col', 'w-1/2 flex-col', 'w-full flex-row justify-end items-center'],
  fields: {
    overall_quality: {
      type: 'ratingStars',
      label: 'Overall Rating',
      validations: ['required'],
      labelClass: 'text-2xs uppercase text-gray-600 font-semibold mb-3',
      size: 'xl',
    },
    review: {
      type: 'textarea-count',
      label: 'Write your review',
      maxLength: 500,
      validations: [],
      style: { fontWeight: '400' },
    },
    cancelBtn: {
      type: 'link',
      label: 'Cancel',
      buttonClassName: 'underline mr-8',
    },
    submit: {
      type: 'button',
      color: 'pink',
      label: 'Save',
    },
  },
};

export default Form;
