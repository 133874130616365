import Bugsnag from '@bugsnag/js';
import { observable, action, makeObservable } from 'mobx';
import Agents from '../agents/agents';

class UserNotificationStore {
  notifications = {};

  unread = false;

  loadingNotifications = false;

  lastUnreadCheckTimestamp = null;

  constructor() {
    makeObservable(this, {
      notifications: observable,
      unread: observable,
      lastUnreadCheckTimestamp: observable,
      loadingNotifications: observable,
      setNotifications: action,
      setUnread: action,
      setLoadingNotifications: action,
      setLastUnreadCheckTimestamp: action,
      checkForUnread: action,
      checkForUnreadOnInterval: action,
      markRead: action,
      getNotifications: action,
    });
  }

  setNotifications(notifications) {
    this.notifications = notifications;
  }

  setUnread(unread) {
    this.unread = unread;
  }

  setLoadingNotifications(loading) {
    this.loadingNotifications = loading;
  }

  setLastUnreadCheckTimestamp(timestamp) {
    this.lastUnreadCheckTimestamp = timestamp;
  }

  checkForUnread() {
    return Agents.notifications
      .checkUnread()
      .then((response) => {
        this.setUnread(!!response?.unread);
      })
      .catch((error) => {
        Bugsnag.notify(error);
      })
      .finally(() => {
        this.setLastUnreadCheckTimestamp(Date.now());
      });
  }

  /**
   * Check for unread notifications on an interval until a condition is met
   * @param {Boolean} condition - the condition to check for. When true, the interval will stop
   * @param {Number} options.maxAttempts - (Optional) the max number of attempts to check for unread notifications. Defaults to 10
   * @param {Number} options.delay - (Optional) the delay between each attempt to check for unread notifications. Defaults to 5000ms
   */
  checkForUnreadOnInterval(condition, options) {
    // OPTIONS
    const maxAttempts = options?.maxAttempts || 10;
    const delay = options?.delay || 5000;

    // COUNTER
    let count = 0;

    // @see - https://developer.mozilla.org/en-US/docs/Web/API/setInterval
    const interval = setInterval(() => {
      // If we've reached the max attempts, stop the interval
      if (condition || count >= maxAttempts) {
        clearInterval(interval);
      } else {
        // If the condition is not met, check for unread notifications
        this.checkForUnread();
        count += 1;
      }
    }, delay);
  }

  markRead(ids) {
    return Agents.notifications
      .markRead(ids)
      .then(() => {
        // Force a check of unread
        this.checkForUnread();
      })
      .catch((error) => {
        Bugsnag.notify(error);
      });
  }

  getNotifications(params, options) {
    const { autoRead = true } = options || {};
    this.setLoadingNotifications(true);
    return Agents.notifications
      .getNotifications(params)
      .then((response) => {
        this.setLoadingNotifications(false);
        this.setNotifications(response);
        // Mark any unread notifications in the response as read if autoRead is true for this request
        if (autoRead && response?.data?.length) {
          const unreadIds = response.data.filter((item) => item.read_at === null);
          if (unreadIds.length) {
            this.markRead(unreadIds.map((item) => item.id));
          }
        }
      })
      .catch((error) => {
        Bugsnag.notify(error);
        this.setLoadingNotifications(false);
        this.setNotifications({});
      });
  }
}

export default new UserNotificationStore();
