import React, { useState } from 'react';
import Button from '../Button/Button';
import CertificationExamReport from './CertificationExamReport';
import CircularProgress from '../ProgressBar/CircularProgress';
import Modal from '../Modal/Modal';
import StyleUtil from '../../utils/styleUtil';

function checkHasScore(data) {
  return data && data.length;
}
function getScore(data, hasScore) {
  const recentScoreData = hasScore ? data[0] : {};
  return recentScoreData.score || 0;
}
export function CertificationRecentScoreProgress({ data }) {
  const hasScore = checkHasScore(data);
  const score = getScore(data, hasScore);
  return (
    <CircularProgress classes="w-12 h-12 mx-auto sm:w-[200px] sm:h-[200px]" strokeColor={StyleUtil.getExamProgressColor(score)} strokeWidth={4} percentageValue={score}>
      <div className="text-center">
        <p className="mb-0 text-sm leading-[72px] sm:text-[64px]">{score}%</p>
        <p className="hidden mb-0 sm:block">SCORE</p>
      </div>
    </CircularProgress>
  );
}

export function CertificationRecentScoreLaunchReport({ data, permalink }) {
  const [modalOpen, setModalOpen] = useState(false);
  const hasScore = checkHasScore(data);
  return (
    <>
      <Button ariaLabel="Launch Recent Scores Report" disabled={!hasScore} onClick={() => setModalOpen(true)} color="gray">
        Launch Report
      </Button>
      <Modal open={modalOpen} toggle={() => setModalOpen(!modalOpen)} ariaLabelledBy="report-title">
        <div className="px-8 pt-16 pb-8">
          <h2 className="mb-4 text-2xl font-black text-center md:mb-16 md:text-5xl">Exam Score Report</h2>
          <CertificationExamReport data={data} permalink={permalink} />
        </div>
      </Modal>
    </>
  );
}

export function CertificationRecentScoreBody({ data, permalink, noBorder }) {
  const hasScore = checkHasScore(data);
  const score = getScore(data, hasScore);
  const borderStyles = noBorder ? '' : 'border border-gray-400';
  return (
    <div className={`flex flex-col gap-y-8 justify-center items-center p-4 rounded-sm ${borderStyles}`}>
      <CertificationRecentScoreProgress data={data} />
      <p className="mb-0 text-xs leading-6">
        Your recent score is {score}%. Please launch the full report to view specific areas to improve on and recommendations for your next steps.
      </p>
      <CertificationRecentScoreLaunchReport data={data} permalink={permalink} />
    </div>
  );
}

export default function CertificationRecentScore({ data, permalink }) {
  return (
    <div>
      <h2 className="mb-2 text-lg font-bold">Your Recent Score</h2>
      <CertificationRecentScoreBody data={data} permalink={permalink} />
    </div>
  );
}
