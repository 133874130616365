import React, { useState, useEffect } from 'react';
import DateRangeFilter from '../Enterprise/DateRangeFilter';
import Dropdown from '../Dropdown/Dropdown';
import FormatUtil from '../../utils/formatUtil';
import SearchInput from '../Search/SearchInput';

const getFilterOptions = (filters = []) => filters.map((filter) => ({ text: filter.label, value: filter.value }));

function ActivityFilters({ onFilterChange, onDateChange, onSearchChange, filters, activeFilters, defaultStartDate, defaultEndDate, disabled }) {
  const [searchQuery, setSearchQuery] = useState('');
  const today = new Date();
  const eventOptions = getFilterOptions(filters);

  useEffect(() => {
    if (activeFilters.searchQuery && activeFilters.searchQuery.length) {
      setSearchQuery(activeFilters.searchQuery);
    }
  }, []);

  const checkSendSearch = (e) => {
    // Check to see if we pressed enter key
    const key = e.which || e.keyCode;
    if (key === 13) {
      onSearchChange(e.target.value);
    }
  };

  return (
    <div className="flex gap-x-8 items-center w-full">
      <SearchInput
        width="w-full"
        placeholder="Search Members"
        onKeyPress={checkSendSearch}
        aria-label="Search members, press enter to submit"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        disabled={disabled}
      />
      <span>Filters:</span>
      <div className="shrink-0">
        <DateRangeFilter
          dateChange={onDateChange}
          dateSelectionWrapperClasses="flex items-center"
          omitDateRange
          startDate={activeFilters.startDate || defaultStartDate || today}
          endDate={activeFilters.endDate || defaultEndDate || today}
          disabled={disabled}
        />
      </div>
      <div className="shrink-0 w-72">
        <Dropdown
          isMulti
          options={eventOptions}
          value={activeFilters.events || []}
          onChange={(selections) => {
            onFilterChange(
              'events',
              selections.map((item) => item.value)
            );
          }}
          hideSelectedOptions={false}
          showOptionsCheckboxes
          omitSelectedLabels
          placeholder={activeFilters?.events?.length ? `${activeFilters.events.length} ${FormatUtil.pluralize(activeFilters.events.length, 'Event')} Selected` : 'All Events'}
          disabled={disabled}
        />
      </div>
    </div>
  );
}

export default ActivityFilters;
