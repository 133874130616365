import React from 'react';
import { inject, observer } from 'mobx-react';
import Collapsible from 'react-collapsible';
import { twMerge } from 'tailwind-merge';
import GroupSelect from './GroupSelect';
import Dropdown from '../Dropdown/Dropdown';
import DropdownFilter from '../Dropdown/DropdownFilter';
import SearchInput from '../Search/SearchInput';
import Segment from '../Segment/Segment';
import { getRoleOptions } from '../../utils/teamsUtil';

function MemberTableEditOptions({ packageTypes, isOrgOwner, changeRoleConfirm, showRemoveConfirm, changeLicenseConfirm, groupScope }) {
  const roleOptions = getRoleOptions(isOrgOwner, groupScope);
  const licenseOptions = [
    {
      key: 'grant',
      text: 'Grant License',
      value: true,
    },
    {
      key: 'revoke',
      text: 'Revoke License',
      value: false,
    },
  ];
  return (
    <div className="actions-container">
      <div className="grid grid-cols-12 gap-y-4 items-center md:gap-x-8">
        <p className="col-span-12 mb-0 lg:col-span-12">Edit:</p>
        {isOrgOwner || groupScope ? (
          <div className="col-span-12 md:col-span-6 lg:col-span-3">
            <Dropdown
              ariaLabel="Edit selected members role"
              fluid
              placeholder="Change Role"
              options={roleOptions}
              onChange={changeRoleConfirm}
              value={null}
              selection
              className="change-role-dropdown"
              selectOnNavigation={false}
            />
          </div>
        ) : null}
        {!!packageTypes && packageTypes.length && isOrgOwner ? (
          <div className="col-span-12 md:col-span-6 lg:col-span-3">
            <Dropdown
              ariaLabel="Edit selected members license status"
              fluid
              placeholder="Manage License"
              options={licenseOptions}
              onChange={changeLicenseConfirm}
              value={null}
              selection
              className="change-role-dropdown"
              selectOnNavigation={false}
            />
          </div>
        ) : null}
        <div className="col-span-12 lg:col-span-3">
          <button className="py-2.5 px-6 text-sm font-bold leading-5 text-center text-black bg-gray-200 rounded-sm" onClick={showRemoveConfirm}>
            Remove Members
          </button>
        </div>
      </div>
    </div>
  );
}

function MemberTableFilters({ team, filterTable, isOrgOwner, setSearch, checkSendSearch, groupScope, roleParam, groupParam, licenseParam }) {
  const roleOptions = getRoleOptions(isOrgOwner, groupScope);
  const isDemo = team?.id === 'demo';
  roleOptions.splice(0, 0, {
    key: 'all',
    text: 'All Roles',
    value: '',
  });

  const licenseOptions = [
    {
      key: 'all',
      text: 'All License Statuses',
      value: '',
    },
    {
      key: 'has-license',
      text: 'Has License',
      value: 'Yes',
    },
    {
      key: 'no-license',
      text: 'No License',
      value: 'No',
    },
  ];
  const groupSelectDefault =
    team && team.permissions && (team.permissions.canManageTeam || team.permissions.canViewReports === 'all')
      ? {
          text: `All Groups`,
          value: '',
        }
      : null;
  return (
    <div className="actions-container">
      <div className="grid grid-cols-12 gap-y-4 items-center md:gap-x-8">
        <div className={`${groupScope ? 'lg:col-span-4' : 'lg:col-span-3'} col-span-12`}>
          <SearchInput placeholder="Search Members" onChange={setSearch} onKeyPress={checkSendSearch} ariaLabel="Search members" disabled={isDemo} />
        </div>
        <div className="col-span-12 lg:col-span-1 lg:text-right">
          <p>Filters:</p>
        </div>
        {/* Dropdowns (filter by roles, and filter by license) are set to isSearchable by default. 
          When not isSearchable, some aria props, such as aria-owns, do not properly get applied and 
          therefore may not be fully accessible */}
        <div className="col-span-12 md:col-span-6 lg:col-span-2">
          <DropdownFilter
            name="filter-by-role"
            ariaLabel="Filter by role"
            fluid
            placeholder="All Roles"
            options={roleOptions}
            value={roleParam}
            onChange={({ value }) => filterTable('role', value)}
            isSearchable
            disabled={isDemo}
          />
        </div>
        <div className="col-span-12 md:col-span-6 lg:col-span-3">
          <DropdownFilter
            name="filter-by-license"
            ariaLabel="Filter by license status"
            placeholder="All License Statuses"
            options={licenseOptions}
            value={licenseParam}
            onChange={({ value }) => filterTable('license', value)}
            isSearchable
            disabled={isDemo}
          />
        </div>
        {!groupScope ? (
          <div className="col-span-12 md:col-span-6 lg:col-span-3">
            <GroupSelect
              team={team}
              ariaLabel="Filter by groups"
              handleGroupSelect={(value) => filterTable('groupId', value)}
              selectedGroup={groupParam}
              placeholder="All Groups"
              defaultOption={groupSelectDefault}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}

function FilterEditButton({ viewOnly, disabled, buttonText, handleClick, className }) {
  if (viewOnly) {
    return null;
  }
  const btnClasses = twMerge('py-2.5 px-6 text-sm font-bold leading-5 text-center text-black bg-gray-200 rounded-sm disabled:opacity-50', className);
  return (
    <button disabled={disabled} onClick={() => handleClick()} className={btnClasses}>
      {buttonText}
    </button>
  );
}

const MemberTableActions = inject(
  'commonStore',
  'userStore',
  'enterpriseStore',
  'authStore'
)(
  observer(
    class MemberTableActions extends React.Component {
      state = {
        showEditMenu: false,
        btnTransition: false,
      };

      componentDidUpdate() {
        if (this.state.showEditMenu && !this.props.enterpriseStore.teamMemberTableData.selectedRows.length) {
          // eslint-disable-next-line react/no-did-update-set-state
          this.toggleEditMenu();
        }
      }

      toggleEditMenu = () => {
        const newState = {
          ...this.state,
          btnTransition: true,
          showEditMenu: !this.state.showEditMenu,
        };
        this.setState(newState, () => {
          // Prevents user from spamming the button and the render state getting mixed up
          setTimeout(() => {
            const timeoutState = {
              ...this.state,
              btnTransition: false,
            };
            this.setState(timeoutState);
          }, 300);
        });
      };

      filterTable = (filter, val) => {
        this.props.enterpriseStore.setQueryParam('teamMemberTableData', 'activePg', 1);
        this.props.enterpriseStore.setQueryParam('teamMemberTableData', filter, val);
        this.props.enterpriseStore.getTeamMemberTableData(this.props.teamId, false, true);
      };

      refreshData = (message) => {
        this.props.commonStore.triggerToast('success', {
          content: message,
        });
        // After removing members, refresh table data and team info
        if (this.props.isOrgOwner && !this.props.groupScope) {
          this.props.userStore.loadAndSetTeamData(this.props.teamId, this.props.userStore.team.role);
        }
        this.props.enterpriseStore.getTeamMemberTableData(this.props.teamId, false, true);
        this.toggleEditMenu();
      };

      // /**
      //  * Changes the user's role on the team
      //  */
      changeRole = (value) => {
        this.props.enterpriseStore
          .changeUserRoles(this.props.enterpriseStore.teamMemberTableData.selectedRows, value, this.props.teamId, 'teamMemberTableData')
          .then(() => {
            this.refreshData('User role has been updated for this team');
          })
          .catch((error) => {
            this.props.commonStore.triggerToast('error', {
              errorCode: (error && error.response && error.response.status) || null,
            });
          });
      };

      changeRoleConfirm = ({ value }) => {
        if (this.props.enterpriseStore.teamMemberTableData.selectedRows.length) {
          if (this.props.customRoleConfirmHandler) {
            this.props.customRoleConfirmHandler(value, () => {
              this.props.commonStore.resetConfirmState();
              this.changeRole(value);
            });
          } else {
            this.props.commonStore.triggerConfirm({
              content: 'Are you sure you want to change user roles for this team?',
              cancel: () => this.props.commonStore.resetConfirmState(),
              continue: () => {
                this.props.commonStore.resetConfirmState();
                this.changeRole(value);
              },
            });
          }
        }
      };

      /**
       * Removes members from the team
       */
      removeMembers = () => {
        if (this.props.enterpriseStore.teamMemberTableData.selectedRows.length) {
          this.props.enterpriseStore
            .removeMembers(this.props.teamId, this.props.enterpriseStore.teamMemberTableData.selectedRows, 'teamMemberTableData', !!this.props.groupScope)
            .then(() => {
              this.refreshData('User(s) removed from team');
            })
            .catch((e) => {
              this.props.commonStore.triggerToast('error', {
                errorCode: (e && e.response && e.response.status) || null,
              });
            });
        }
      };

      showRemoveConfirm = () => {
        if (this.props.enterpriseStore.teamMemberTableData.selectedRows.length) {
          if (this.props.customRemoveConfirmHandler) {
            this.props.customRemoveConfirmHandler(() => {
              this.props.commonStore.resetConfirmState();
              this.removeMembers();
            });
          } else {
            this.props.commonStore.triggerConfirm({
              content: 'Are you sure you want to remove the selected user(s) from the team? They will lose access to paid content and will not appear in reporting.',
              cancel: () => this.props.commonStore.resetConfirmState(),
              continue: () => {
                this.props.commonStore.resetConfirmState();
                this.removeMembers();
              },
            });
          }
        }
      };

      /**
       * Changes the user's license status
       */
      changeLicense = (value) => {
        this.props.enterpriseStore
          .manageLicenses(this.props.teamId, this.props.enterpriseStore.teamMemberTableData.selectedRows, value)
          .then(() => {
            this.props.authStore.fireAttributionEvent(!value ? 'license:revoked' : 'license:granted');
            this.refreshData("User's license status has been updated");
          })
          .catch((err) => {
            if (err.response && err.response.data) {
              const errorMessage = err.response.data.message || null;
              const errorContent =
                err.response.status === 422 && errorMessage && errorMessage.includes('You do not have enough licenses') ? 'All licenses are currently being used' : null;
              this.props.commonStore.triggerToast('error', {
                errorCode: err.response.status,
                content: errorContent,
              });
            }
          });
      };

      changeLicenseConfirm = ({ value }) => {
        if (this.props.enterpriseStore.teamMemberTableData.selectedRows.length) {
          this.props.commonStore.triggerConfirm({
            content: 'Are you sure you want to change the license status for the selected users?',
            cancel: () => this.props.commonStore.resetConfirmState(),
            continue: () => {
              this.props.commonStore.resetConfirmState();
              this.changeLicense(value);
            },
          });
        }
      };

      // /**
      //  * Sets search value in store for service call searchQuery
      //  */
      setSearch = (e) => {
        this.props.enterpriseStore.setQueryParam('teamMemberTableData', 'searchQuery', e.target.value);
      };

      checkSendSearch = (e) => {
        // Check to see if we pressed enter key
        const key = e.which || e.keyCode;
        if (key === 13) {
          this.props.enterpriseStore.getTeamMemberTableData(this.props.teamId, true, false);
        }
      };

      render() {
        const { packageTypes, isOrgOwner, groupScope, viewOnly, team, isDemo } = this.props;
        const { teamMemberTableData } = this.props.enterpriseStore;
        return (
          <Segment className="px-0 pt-0 border-none team-actions">
            <div className={`collapse-container mb-12 ${this.state.btnTransition ? 'transition' : ''} ${this.state.showEditMenu ? 'edit' : 'filter'}`}>
              {!viewOnly ? (
                <Collapsible open={this.state.showEditMenu} transitionTime={200}>
                  <MemberTableEditOptions
                    packageTypes={packageTypes}
                    isOrgOwner={isOrgOwner}
                    changeRoleConfirm={this.changeRoleConfirm}
                    showRemoveConfirm={this.showRemoveConfirm}
                    changeLicenseConfirm={this.changeLicenseConfirm}
                    groupScope={!!groupScope}
                    isDemo={isDemo}
                  />
                </Collapsible>
              ) : null}
              <Collapsible open={!this.state.showEditMenu} transitionTime={200}>
                <MemberTableFilters
                  team={team}
                  filterTable={this.filterTable}
                  isOrgOwner={isOrgOwner}
                  setSearch={this.setSearch}
                  checkSendSearch={this.checkSendSearch}
                  licenseParam={teamMemberTableData.queryParams.license}
                  roleParam={teamMemberTableData.queryParams.role}
                  groupParam={teamMemberTableData.queryParams.groupId}
                  groupScope={!!groupScope}
                />
              </Collapsible>
            </div>
            <FilterEditButton
              viewOnly={!!this.props.viewOnly}
              disabled={!teamMemberTableData.selectedRows || !teamMemberTableData.selectedRows.length || this.state.btnTransition}
              buttonText={!this.state.showEditMenu ? 'Edit Selected' : 'Show Filters'}
              handleClick={isDemo ? () => {} : this.toggleEditMenu}
              className={isDemo ? 'cursor-not-allowed' : ''}
            />
          </Segment>
        );
      }
    }
  )
);

export default MemberTableActions;
