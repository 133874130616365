import React from 'react';
import { inject, observer } from 'mobx-react';
import Modal from '../Modal/Modal';
import ReferralInviteForm from './ReferralInviteForm';

const ReferralInviteModal = inject('commonStore')(
  observer(({ commonStore }) => {
    const { isReferralModalOpen, setIsReferralModalOpen } = commonStore;
    return (
      <Modal sizeClasses="md:w-auto max-w-[1100px]" open={isReferralModalOpen} toggle={() => setIsReferralModalOpen(false)}>
        <ReferralInviteForm />
      </Modal>
    );
  })
);

export default ReferralInviteModal;
