import React from 'react';
import SelectBoxes from '../SelectBoxes/SelectBoxes';

function WizardOwnersSelection({ owners, setValue, selectedOwnerIds, filterOptions, userId, isEditView }) {
  const handleClick = (section, val, fullSelections) => {
    setValue(section, val);
    setValue('owners', fullSelections);
  };

  const topLevelOption = {
    text: 'Myself',
    value: userId,
  };

  // If 'myself' is included in the options, filter out and add to front (so first option)
  const ownerOptions = owners.data.filter((option) => option.value !== userId);
  if (owners.data.length > ownerOptions.length) {
    ownerOptions.unshift(topLevelOption);
  }

  return (
    <div>
      <SelectBoxes
        disabled={isEditView}
        containerClasses="overflow-y-auto w-11/12"
        multiSelect
        showSelectAll
        returnFullSelections
        handleSearch={(query) => filterOptions('goalOwners', query)}
        selections={selectedOwnerIds}
        handleSelect={(selection, fullSelections) => handleClick('owner_ids', selection, fullSelections)}
        options={ownerOptions}
        loading={owners.loading}
      />
    </div>
  );
}

export default WizardOwnersSelection;
