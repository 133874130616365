import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { kebabCase } from 'lodash';
import Button from '../Button/Button';
import ContentOverlay from '../ContentOverlay/ContentOverlay';
import Title from '../Title/Title';
import UserGoalsWizard from '../Goals/UserGoalsWizard';
import BrowseUtil from '../../utils/browseUtil';

// content types that should display goals section
const VALID_CONTENT_TYPES = ['Course', 'Career Path', 'Collection', 'Assessment Path'];

// This function is responsible for getting the relevant id for pendo testing (career path, certification course, other course)
function getSectionId(type, isCertificationCourse) {
  const kebabCaseType = kebabCase(type);
  if (type === 'Course' && isCertificationCourse) {
    return 'cert-course-page-create-goal';
  }
  return `${kebabCaseType}-page-create-goal`;
}

// This function determines text for Create Goal panel to display on Course and Career Path Pages
function getSectionText(type, isCertificationCourse) {
  // if type is Career Path, Assessment Path, Collection - return Path text
  if (type === 'Career Path' || type === 'Assessment Path' || type === 'Collection') {
    const term = type !== 'Career Path' ? 'Path' : type;
    return `Looking to advance your career? Set a goal to complete this ${term}. We’ll help you track your progress and hold yourself accountable.`;
  }
  // else if type is Course and isCertificationCourse is true
  if (type === 'Course' && isCertificationCourse) {
    // Certification Course Text
    return 'Preparing for an upcoming certification exam? Set a goal to complete this certification course. We’ll help you track your progress and assess your readiness.';
  }
  // otherwise it is just an ordinary course
  return 'Working towards a new skill? Set a goal to complete this course. We’ll help you track your progress and keep yourself accountable.';
}

function checkIfContentInGoal(contentId, goals) {
  if (!goals || !goals.length || !contentId) {
    return false;
  }
  for (let i = 0; i < goals.length; i++) {
    if (goals[i].content_ids && goals[i].content_ids.indexOf(contentId) > -1) {
      return true;
    }
  }
  return false;
}

function onSubmit(setOverlay, goalsStore) {
  setOverlay(false);
  // Get an updated list of goals
  goalsStore.getUserGoalsList().then(() => {
    // If we're not tracking a goal, look for one to track now
    if (!goalsStore.hasTrackingGoal) {
      goalsStore.setCurrentTrackingGoal();
      goalsStore.startTrackingGoalPolling();
    }
  });
}

const GoalSection = inject('goalsStore')(
  observer(({ goalsStore, type, groupsInfo, userType, permalink, contentDescriptionId }) => {
    const [overlayIsOpen, setOverlayIsOpen] = useState(false);

    if (!VALID_CONTENT_TYPES.includes(type) || userType === 'enterprise' || checkIfContentInGoal(contentDescriptionId, goalsStore.userGoalsList.activeGoals)) {
      return null;
    }

    // if we have terms: check for keyword "certifications", true if found, false if not... otherwise false
    const isCertificationCourse = groupsInfo && type === 'Course' ? BrowseUtil.checkForCertifications(groupsInfo).length > 0 : false;

    const goalSectionId = getSectionId(type, isCertificationCourse);

    return (
      <div>
        <h3 className="text-2xl font-black">Create Goal</h3>
        <div className="p-4 my-4 rounded-sm border border-gray-400">
          <div className="mb-4 text-sm">{getSectionText(type, isCertificationCourse)}</div>
          <Button id={`${goalSectionId}`} onClick={() => setOverlayIsOpen(true)} color="gray">
            Create Goal
          </Button>
        </div>
        <ContentOverlay open={overlayIsOpen} dismiss={() => setOverlayIsOpen(false)} width="w-[80vw]" ariaLabelledBy="goal-title">
          <div className="py-4 px-8">
            <Title title="Create Goal" titleId="goal-title" omitPadding wrapperClasses="mb-4" />
            <p className="hidden mb-9 text-sm text-gray-600 md:block">
              Follow the guide below to set your professional development goal and we&apos;ll get you started in the right direction.
            </p>
            <UserGoalsWizard goalPreselection={isCertificationCourse ? 'certification' : null} contentItem={permalink} onSubmit={() => onSubmit(setOverlayIsOpen, goalsStore)} />
          </div>
        </ContentOverlay>
      </div>
    );
  })
);

export default GoalSection;
