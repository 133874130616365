import React from 'react';

export default function Box({ children, className = '', onClick }) {
  const classes = `py-4 px-4 rounded-md border border-neutral-600 ${className}`;
  if (onClick) {
    return (
      <button type="button" className={classes} onClick={onClick}>
        {children}
      </button>
    );
  }
  return <div className={classes}>{children}</div>;
}
