import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';

import Collapsible from 'react-collapsible';
import CheckboxDisplay from '../Mitre/CheckboxDisplay';
import Icon from '../Icon/Icon';
import Loading from '../Loading/Loading';
import FilterUtil from '../../utils/filterUtil';
import FormatUtil from '../../utils/formatUtil';
import If from '../If/If';

function ClearAll({ onClick, filtersCount, view }) {
  if (view === 'all' && (!onClick || !filtersCount)) {
    return null;
  }
  return (
    <>
      <p className="flex-1 mb-0 text-2xl font-black">({filtersCount})</p>
      <button color="gray" className="flex gap-x-1 items-center p-3 text-xs font-bold text-black bg-gray-200 rounded-sm" onClick={onClick}>
        <Icon name="x" className="w-4 h-4" />
        Clear All
      </button>
    </>
  );
}

function SingleSelectFilters({ options, view, onClick, isLoading }) {
  return options.map((option) => {
    const isActive = view === option.value;
    // Loop over the individual filters within each facet category
    const filterCount = option.count;
    const filterName = option.text;
    const showFilterCount = filterCount !== undefined && !isLoading;

    return (
      <div className="my-4 last:my-0" key={filterName}>
        <button className={`flex items-center text-sm text-left ${isActive ? 'text-black text-semibold' : 'text-gray-600'}`} onClick={() => onClick(option)}>
          <CheckboxDisplay className="shrink-0 mr-2" isSelected={isActive} isRadio />
          {filterName} {showFilterCount ? `(${filterCount})` : null}
        </button>
      </div>
    );
  });
}

function MultiSelectFilters({ filters, facet, searchFilters, filterHeader, removeFilter, addFilter, isLoading }) {
  return filters.map((filter) => {
    // Loop over the individual filters within each facet category
    const filterCount = facet.data[filter];
    const filterName = FilterUtil.transformFacetFilterName(filter);
    const showFilterCount = filterCount !== undefined && !isLoading;

    const checkFilterSelected = (!!searchFilters && !!searchFilters[facet.name] && searchFilters[facet.name].indexOf(filter) > -1) || !filterCount;

    if (filterCount === 0 || !filterCount || !filterName) {
      return null;
    }
    if (filterHeader === 'Category' && filter.toLowerCase() === 'certifications') {
      return null;
    }
    if (filterHeader === 'NIST Work Roles' && !filter.toLowerCase().includes('workrole')) {
      return null;
    }
    if (filterHeader === 'NICE Work Roles' && !filter.toLowerCase().includes('nice-work-role')) {
      return null;
    }
    if (filterHeader === 'NICE Categories' && !filter.toLowerCase().includes('nice-category')) {
      return null;
    }
    if (filterHeader === 'Domains' && !filter.toLowerCase().includes('domains')) {
      return null;
    }
    if (filterHeader === 'Topics' && !filter.toLowerCase().includes('topics')) {
      return null;
    }
    return (
      <div className="my-4 last:my-0" key={filterName}>
        <button
          className={`flex items-center text-sm text-left ${checkFilterSelected ? 'text-black text-semibold' : 'text-gray-600'}`}
          onClick={checkFilterSelected ? () => removeFilter(facet.name, filter) : () => addFilter(facet.name, filter)}
        >
          <CheckboxDisplay className="shrink-0 mr-2" isSelected={checkFilterSelected} />
          {`${filterName} ${showFilterCount ? `(${filterCount})` : ''}`}
        </button>
      </div>
    );
  });
}

function CollapsibleWrapper({ setOpen, filterHeaderId, filterHeader, ariaLabelText, open, children }) {
  return (
    <Collapsible
      transitionTime={200}
      open={open}
      onTriggerOpening={() => setOpen(true)}
      onTriggerClosing={() => setOpen(false)}
      trigger={
        <div id={filterHeaderId} className="flex">
          <h3 className="flex-1 text-lg font-bold">{filterHeader}</h3>
          <button aria-label={`${ariaLabelText} ${filterHeader || ''} Collapsible`} className="ml-auto">
            <Icon className={`w-4 h-4 text-black transition-transform transform duration-200 linear ${open ? '' : 'rotate-180'}`} name="chevron-up" />
          </button>
        </div>
      }
    >
      {children}
    </Collapsible>
  );
}

function AdvancedFacets({ facets, searchFilters, certCount, addFilter, removeFilter, isLoading }) {
  const advancedFacets = FilterUtil.getAdvancedFacets();
  return advancedFacets.map((facetCategory) => {
    // Hide terms_info section, we want to show specific filter sections under terms_info (e.g. domains, topics, workroles)
    if (facets.length === 0 || facetCategory === 'terms_info') {
      return null;
    }
    // Get the data for this facet
    const facet = FilterUtil.getFacetData(facets, facetCategory);
    const filterHeader = FilterUtil.getFilterHeader(facetCategory);
    const filters = FilterUtil.getFilters(facet, certCount);
    const selectedFilters = FilterUtil.getSelectedFilters(searchFilters, facetCategory);
    // Loop over the top level facet categories and display a collapsible for each facet category
    const showCollapsible = (filters && filters.length) || (selectedFilters && selectedFilters.length);
    const isDefaultOpen = selectedFilters.filter((filter) => {
      let formatFilter = '';
      if (filter && typeof filter === 'object' && filter[0]) {
        // array destructuring assignment
        [formatFilter] = filter;
      } else if (filter && typeof filter === 'string') {
        formatFilter = filter;
      }
      return filters.indexOf(formatFilter) !== -1;
    }).length;
    return (
      <div key={filterHeader}>
        <DisplayFilters isDefaultOpen={isDefaultOpen} filterHeader={filterHeader} showCollapsible={showCollapsible}>
          <MultiSelectFilters
            filterHeader={filterHeader}
            facet={facet}
            filters={filters}
            searchFilters={searchFilters}
            addFilter={addFilter}
            removeFilter={removeFilter}
            isLoading={isLoading}
          />
        </DisplayFilters>
      </div>
    );
  });
}

function DisplayFilters({ filterHeader, showCollapsible, isDefaultOpen, children }) {
  const [open, setOpen] = useState(isDefaultOpen || false);
  const ariaLabelText = open ? 'Collapse' : 'Expand';
  const filterHeaderId = FormatUtil.lowerCaseHyphenText(filterHeader);
  return (
    <div key={filterHeader} className={`facets-row ${filterHeaderId} mb-6`}>
      {showCollapsible ? (
        <CollapsibleWrapper open={open} setOpen={setOpen} filterHeaderId={filterHeaderId} filterHeader={filterHeader} ariaLabelText={ariaLabelText}>
          {children}
        </CollapsibleWrapper>
      ) : null}
    </div>
  );
}

const BrowseFilters = inject('userStore')(
  observer(
    ({
      loading,
      addFilter,
      data,
      facets,
      searchFilters,
      filtersCount,
      removeFilter,
      removeAllFilters,
      changeCategoryView,
      contentView,
      handleHighlightView,
      certCount,
      isDefaultOpen,
      containerClasses,
      filterClasses,
      view,
      userStore,
    }) => {
      const { isEnterprise: isEnterpriseUser } = userStore;
      const searchFacets = FilterUtil.getSearchFacets();
      const viewOptions = FilterUtil.getViewOptions(data);
      const contentOptions = FilterUtil.getContentOptions();
      return (
        <div className={containerClasses || ''}>
          <div className="flex sticky top-0 z-10 gap-x-2 items-center py-3 px-4 h-16 bg-white border-b-xs border-gray-300 lg:static lg:p-0 lg:mb-6 lg:h-[52px] lg:border-none">
            <h2 className="text-2xl font-black">Filters</h2>
            <ClearAll onClick={removeAllFilters} filtersCount={filtersCount} view={view} />
          </div>
          <div className={filterClasses || ''}>
            <If condition={loading && !data}>
              <Loading wrapperClassName="mt-44" message="Loading..." />
            </If>
            {searchFacets.map((facetCategory) => {
              if (facets.length === 0) {
                return null;
              }

              // Get the data for this facet
              const facet = FilterUtil.getFacetData(facets, facetCategory);
              const filterHeader = FilterUtil.getFilterHeader(facetCategory);
              const filters = FilterUtil.getFilters(facet, certCount);
              const selectedFilters = FilterUtil.getSelectedFilters(searchFilters, facetCategory);

              // Loop over the top level facet categories and display a collapsible for each facet category
              const showCollapsible = (!!filters && filters.length) || (!!selectedFilters && selectedFilters.length);
              if (facetCategory === 'content_group') {
                return (
                  <div key={filterHeader}>
                    <DisplayFilters filterHeader={filterHeader} showCollapsible={showCollapsible} isDefaultOpen={isDefaultOpen}>
                      <SingleSelectFilters options={viewOptions} view={view} onClick={changeCategoryView} isEnterpriseUser={isEnterpriseUser} isLoading={loading} />
                    </DisplayFilters>
                  </div>
                );
              }
              if (facetCategory === 'content_filters') {
                return (
                  <div key="Content Filters">
                    <DisplayFilters filterHeader="Content Filters" showCollapsible isDefaultOpen>
                      <SingleSelectFilters options={contentOptions} view={contentView} onClick={handleHighlightView} isEnterpriseUser={isEnterpriseUser} isLoading={loading} />
                    </DisplayFilters>
                  </div>
                );
              }
              return (
                <div key={filterHeader}>
                  <DisplayFilters filterHeader={filterHeader} showCollapsible={showCollapsible} isDefaultOpen={isDefaultOpen}>
                    <MultiSelectFilters
                      filterHeader={filterHeader}
                      facet={facet}
                      filters={filters}
                      searchFilters={searchFilters}
                      selectedFilters={selectedFilters}
                      showCollapsible={showCollapsible}
                      isDefaultOpen={isDefaultOpen}
                      addFilter={addFilter}
                      removeFilter={removeFilter}
                      isLoading={loading}
                    />
                  </DisplayFilters>
                </div>
              );
            })}
            <AdvancedFacets searchFilters={searchFilters} removeFilter={removeFilter} addFilter={addFilter} facets={facets} certCount={certCount} isLoading={loading} />
          </div>
        </div>
      );
    }
  )
);

export default BrowseFilters;
