import React from 'react';

export default function Share({ classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';
  return (
    <svg className={className} fill="none" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg" {...accessibilityProps}>
      <path
        d="M8.57698 14.7889C8.84629 14.2503 8.99828 13.6423 8.99828 12.9997C8.99828 12.3571 8.84629 11.7491 8.57698 11.2105M8.57698 14.7889C8.17394 15.5947 7.51065 16.2408 
        6.69456 16.6226C5.87847 17.0044 4.95739 17.0994 4.08053 16.8924C3.20367 16.6853 2.4224 16.1883 1.8633 15.4818C1.30419 14.7753 1 13.9007 1 12.9997C1 12.0987 1.30419 
        11.2241 1.8633 10.5176C2.4224 9.81113 3.20367 9.31408 4.08053 9.10702C4.95739 8.89996 5.87847 8.99502 6.69456 9.37679C7.51065 9.75856 8.17394 10.4047 8.57698 
        11.2105M8.57698 14.7889L17.4191 19.21M8.57698 11.2105L17.4191 6.78944M17.4191 19.21C16.9445 20.159 16.8665 21.2577 17.202 22.2644C17.5375 23.271 18.2592 24.1031 19.2083 
        24.5776C20.1574 25.0522 21.256 25.1302 22.2627 24.7947C23.2693 24.4591 24.1014 23.7375 24.5759 22.7884C25.0505 21.8393 25.1286 20.7406 24.793 19.734C24.4575 18.7274 
        23.7358 17.8953 22.7867 17.4208C22.3168 17.1858 21.8052 17.0457 21.2811 17.0084C20.7571 16.9712 20.2308 17.0375 19.7323 17.2037C18.7257 17.5392 17.8936 18.2609 17.4191 
        19.21ZM17.4191 6.78944C17.654 7.25928 17.9792 7.67824 18.3761 8.02239C18.773 8.36655 19.2338 8.62917 19.7321 8.79525C20.2305 8.96133 20.7567 9.02763 21.2807 
        8.99035C21.8047 8.95307 22.3162 8.81295 22.7861 8.57799C23.2559 8.34303 23.6749 8.01782 24.019 7.62094C24.3632 7.22407 24.6258 6.76329 24.7919 6.26492C24.958 5.76655 
        25.0243 5.24034 24.987 4.71635C24.9497 4.19236 24.8096 3.68084 24.5746 3.211C24.1001 2.26212 23.268 1.54061 22.2615 1.20519C21.255 0.869769 20.1565 0.947921 19.2076 
        1.42245C18.2587 1.89698 17.5372 2.72902 17.2018 3.73552C16.8664 4.74203 16.9445 5.84055 17.4191 6.78944Z"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
