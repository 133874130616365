import React from 'react';

export default function LightningBolt({ classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';
  return (
    <svg className={className} viewBox="0 0 10 17" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...accessibilityProps}>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M0.0317046 9.62484L6.28034 0.946167C6.38959 0.794425 6.62751 0.909992 6.57576 1.08968L4.90284 6.89833C4.87217 7.00483 4.95217 7.11108 5.063 7.11108H9.51626C9.65209 7.11108 9.73084 7.26492 9.65151 7.37517L3.40284 16.0538C3.29359 16.2056 3.05566 16.09 3.10741 15.9103L4.78034 10.1017C4.81101 9.99517 4.731 9.88892 4.62017 9.88892H0.166963C0.031113 9.88892 -0.0476704 9.73508 0.0317046 9.62484Z"
        fill="currentColor"
      />
    </svg>
  );
}
