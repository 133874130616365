import React, { Component } from 'react';
import queryString from 'query-string';
import Header from '../../components/Header/Header';
import Container from '../../components/Container/Container';
import BlankTemplate from '../../components/BlankTemplate/BlankTemplate';
import Message from '../../components/Message/Message';

class LODLimit extends Component {
  getErrorMsg = (errorCode) => {
    switch (1 * errorCode) {
      case 2:
        return 'Another lab is currently active. Please save or end this lab before launching a new lab';
      case 110:
        return 'This activity has been taken down by the vendor temporarily for review or maintenance. Please try again at a later date.';
      case 100:
      default:
        return 'IT Pro Challenge virtual labs have a six launch limit. You have used all your attempts for this lab.';
    }
  };

  render() {
    const queryParams = queryString.parse(window.location.search);
    return (
      <>
        <BlankTemplate />
        <Container size="md" className="my-16">
          <Message icon="exclamation-circle">
            <Header as="h3" className="mb-2">
              This virtual lab is no longer available.
            </Header>
            <p>{this.getErrorMsg(queryParams.errorCode)}</p>
          </Message>
        </Container>
      </>
    );
  }
}

export default LODLimit;
