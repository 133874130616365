import React from 'react';

export default function ShortDescription({ shortDescription, className }) {
  if (!shortDescription) {
    return null;
  }

  return (
    <div className={className}>
      <h2 className="mb-6 text-2xl font-black">Overview</h2>
      <p className="max-w-150 text-sm">{shortDescription}</p>
    </div>
  );
}
