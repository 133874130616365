import React from 'react';
import ReactMarkdown from 'react-markdown';
import InstructorCard from '../Item/InstructorCard';

function InstructorItem({ instructors }) {
  if (!instructors || !instructors.length) {
    return null;
  }
  return (
    <div className="mb-4">
      <p className="mb-4 text-2xs font-semibold text-gray-600 uppercase">Instructor{instructors.length > 1 ? 's' : ''}</p>
      {instructors.map((instructor) => {
        return <InstructorCard key={instructor.id} name={instructor.name} thumbnail={instructor.thumbnail} noLabel noBorder />;
      })}
    </div>
  );
}

function MenuItem({ content, isSidebar, outline, tasks = null }) {
  const paddingClass = isSidebar ? 'pl-6 pr-8' : 'px-0';
  const { type, value, subtitle, items, instructors, component } = content;
  if (type === 'outline') {
    return outline;
  }
  if (type === 'tasks') {
    return tasks;
  }
  if (type === 'component') {
    return component;
  }
  if (type === 'text') {
    return (
      <div className={`py-8 ${paddingClass}`}>
        <p className="mb-4 text-2xs font-semibold uppercase">{subtitle}</p>
        {!!items &&
          items.map((item, i) => {
            const marginClass = i === items.length - 1 ? 'mb-0' : 'mb-4';
            return (
              <p className={`text-sm text-black ${marginClass}`} key={item}>
                {item}
              </p>
            );
          })}
      </div>
    );
  }
  if (type === 'markdown') {
    const hasInstructor = instructors && instructors.length;
    return (
      <div className={`py-8 ${paddingClass}`}>
        <InstructorItem instructors={instructors} />
        <p className={`font-semibold text-2xs text-gray-600 uppercase mb-4 ${hasInstructor ? 'pt-6' : ''}`}>{subtitle}</p>
        <ReactMarkdown className="has-markdown-inside">{value}</ReactMarkdown>
      </div>
    );
  }
  if (type === 'list') {
    return (
      <ul className={`py-8 ${paddingClass}`}>
        <li className="mb-4 text-2xs font-semibold text-gray-600 uppercase">{subtitle}</li>
        {!!items &&
          items.map((item) => {
            return (
              <li key={item.label} className="mb-4 text-sm text-left lg:mb-2">
                <a
                  href={item.href}
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(item.href, '_blank');
                  }}
                  target="_blank"
                  className="text-sm text-gray-600 hover:text-black underline hover:underline cursor-pointer"
                  rel="noreferrer noopener"
                >
                  {item.label}
                </a>
              </li>
            );
          })}
      </ul>
    );
  }
  return null;
}

function getSidebarClass(isSidebar, noTopMargin) {
  if (noTopMargin) {
    return 'pl-7 pr-8';
  }
  return isSidebar ? 'mt-32' : 'pl-7 pr-8 mt-16';
}

function MenuContent({ content, isSidebar, outline, noTopMargin = false, tasks = null }) {
  const sidebarClass = getSidebarClass(isSidebar, noTopMargin);
  return (
    <div tabIndex="0" role="tabpanel" id={`${content.id}-tab`} aria-labelledby={content.id} className={`${content.type !== 'outline' ? sidebarClass : ''}`}>
      <MenuItem content={content} isSidebar={isSidebar} outline={outline} tasks={tasks} />
    </div>
  );
}

export default MenuContent;
