import React from 'react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import { twMerge } from 'tailwind-merge';
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary';
import LabSection from './LabSection';
import Lx2Video from './Lx2Video';

/**
 * Helper function to provide alignment classes based on an optional align value
 */
const provideAlignmentClasses = (align) => {
  if (align === 'textAlignLeft') {
    return 'text-left';
  }
  if (align === 'textAlignRight') {
    return 'text-right';
  }
  if (align === 'textAlignCenter') {
    return 'text-center';
  }
  return '';
};

/**
 * Render a leaf node, aka text with some optional styles applied.
 * Leaf nodes represent the bottom of the tree, they can never wrap anything.
 * Basically, this is the text that goes within paragraphs, lists, h2's, etc.
 */
function RenderText({ item, parentType }) {
  const { bold, italic, underline, inlinecode, text } = item;
  if (!text) {
    return null;
  }

  const boldClasses = bold ? 'font-bold' : '';
  const underlineClasses = underline ? 'underline' : '';
  const italicClasses = italic ? 'italic' : '';
  const inlinecodeClasses = inlinecode ? 'font-mono p-1 text-xs bg-gray-300 rounded-sm' : '';
  const classes = `${boldClasses} ${underlineClasses} ${italicClasses} ${inlinecodeClasses}`;
  const trimmedClassName = classes.trim();
  const classProp = trimmedClassName.length ? { className: trimmedClassName } : {};
  // Omit the space before the text in certain cases -
  // 1. The starting character isn't a letter or number (when not inline code)
  // 2. This is text inside a link (handled at link level) or a code block
  const omitSpaceBefore = (!inlinecodeClasses && !/^[a-zA-Z0-9]/.test(text)) || ['link', 'code'].includes(parentType);

  // Laravel strips starting and trailing whitespaces from bodies of text. Add that before returning the styled text node
  return (
    <>
      {!omitSpaceBefore && ' '}
      <span {...classProp}>{text || ''}</span>
    </>
  );
}

/**
 * Render a link, you can add a target="_blank" and match styles as necessary
 */
function LinkElement({ item }) {
  const { url, target, children } = item;
  // If the first child node in this link does not have a type (is text then), add a space before the link
  const firstChildIsText = !children[0].type;
  return (
    <>
      {firstChildIsText && ' '}
      <a href={url} rel="noopener noreferrer" target={target || '_blank'} className="text-sm leading-6 text-cyb-pink-500 hover:text-black underline">
        <RenderLeaves items={children} parentType="link" />
      </a>
    </>
  );
}

/**
 * Code block
 */
function CodeElement({ item }) {
  const { align, children } = item;
  return (
    <pre className={`p-2 text-gray-100 bg-blue-900 rounded-lg font-mono text-xs whitespace-pre-wrap ${provideAlignmentClasses(align)}`}>
      <code>
        <RenderLeaves items={children} parentType="code" />
      </code>
    </pre>
  );
}

/**
 * H1 - H4 components. If we need others, we can add them easily.  Here you can match the styles as necessary to the existing UI.
 * When rendered, H tags should not be higher than an H4 because of Accessibility/Screen Readers
 */
function Header1Element({ item }) {
  const { align, children } = item;
  return (
    <h4 className={`font-black text-5xl text-black mt-8 mb-4 ${provideAlignmentClasses(align)}`}>
      <RenderLeaves items={children} />
    </h4>
  );
}
function Header2Element({ item }) {
  const { align, children } = item;
  return (
    <h5 className={`font-black text-2xl text-black mt-8 mb-4 ${provideAlignmentClasses(align)}`}>
      <RenderLeaves items={children} />
    </h5>
  );
}
function Header3Element({ item }) {
  const { align, children } = item;
  return (
    <h6 className={`font-bold text-lg text-black mt-8 mb-4 ${provideAlignmentClasses(align)}`}>
      <RenderLeaves items={children} />
    </h6>
  );
}
function Header4Element({ item }) {
  const { align, children } = item;
  return (
    <h6 className={`font-semibold text-base text-black mt-8 mb-4 ${provideAlignmentClasses(align)}`}>
      <RenderLeaves items={children} />
    </h6>
  );
}

/**
 * Lists, should be simple to match styles to the existing standard
 */
function OrderedListElement({ item }) {
  const { align, children } = item;
  return (
    <ol className={`list-decimal mb-4 ml-8 text-gray-700 text-sm leading-6 list-outside ${provideAlignmentClasses(align)}`}>
      <RenderLeaves items={children} />
    </ol>
  );
}
function UnorderedListElement({ item }) {
  const { align, children } = item;
  return (
    <ul className={`list-disc mb-4 ml-8 text-gray-700 text-sm leading-6 list-outside ${provideAlignmentClasses(align)}`}>
      <RenderLeaves items={children} />
    </ul>
  );
}
function ListItemElement({ item }) {
  const { align, children } = item;
  return (
    <li className={`text-gray-700 text-sm leading-6 mb-2 ${provideAlignmentClasses(align)}`}>
      <RenderLeaves items={children} />
    </li>
  );
}

/**
 * The default component, a paragraph, match text color, size, margin, etc.
 */
function Paragraph({ item }) {
  const { align, children } = item;
  return (
    <p className={`mb-4 text-gray-700 text-sm leading-6 ${provideAlignmentClasses(align)}`}>
      <RenderLeaves items={children} />
    </p>
  );
}

/**
 * Receives an item with a url, (optional) description, and an (optional) title for the image
 */
function Image({ item }) {
  const { url, description, title = 'Photo' } = item;
  if (!url) {
    return null;
  }
  const imagePaddingClass = description ? 'px-4 pt-4 pb-4 md:px-8 md:pt-8' : 'p-4 md:p-8';
  const imageClasses = twMerge(`mx-auto w-full h-auto rounded-sm h-max-48`, imagePaddingClass);
  const imageSrc = `https:${url}`;

  return (
    <figure className="flex flex-col justify-center items-center my-4 bg-gray-200 rounded-sm">
      <ErrorBoundary FallbackComponent={<img className={imageClasses} src={imageSrc} alt={title} />}>
        <Zoom openText="Click to enlarge image" closeText="Click to reset image" zoomMargin={100}>
          <img className={imageClasses} src={imageSrc} alt={title} />
        </Zoom>
      </ErrorBoundary>
      {!!description && (
        <figcaption>
          <p className="pb-4 mt-1 text-sm leading-6 text-center text-gray-700">{description}</p>
        </figcaption>
      )}
    </figure>
  );
}

/**
 * Receives an item with a URL to a vimeo video.  That SHOULD be neough for you to load the Cybrary video player
 */
function Video({ item, activity }) {
  const { url, description } = item;
  if (!url) {
    return null;
  }
  return (
    <>
      <Lx2Video videoId={url} activity={activity} />
      {description ? <p className="my-4 text-sm leading-6 text-center text-gray-700">{description}</p> : null}
    </>
  );
}

function BlockQuoteElement({ item }) {
  const { align, children } = item;
  return (
    <div className={`border-l-4 border-l-gray-300 min-h-[20px] pl-4 text-gray-700 text-sm ${provideAlignmentClasses(align)}`}>
      <RenderLeaves items={children} />
    </div>
  );
}

function HighlightElement({ item }) {
  const { align, children } = item;
  return (
    <div className={`bg-gray-200 p-4 min-h-[20px] text-gray-700 text-sm ${provideAlignmentClasses(align)}`}>
      <RenderLeaves items={children} />
    </div>
  );
}

/**
 * Passed children of other elements, loops over them, rendering leaf nodes (RenderText)
 * or full nodes (ItemSwitch) depending on the existence of the type property
 */
function RenderLeaves({ items, parentType }) {
  if (!items || !items.length) {
    return null;
  }
  return items.map((child, i) => {
    if (!child) {
      return null;
    }
    const { type } = child;
    const key = `${child.id}_${i}`;
    if (type) {
      return <ItemSwitch key={key} item={child} />;
    }
    return <RenderText key={key} item={child} parentType={parentType} />;
  });
}

/**
 * Switch for rendering an element based on type, which is present on nodes that are NOT leaves, aka they
 * can wrap other nodes (an h1 could have text in it that is in italics, for instance)
 */
function ItemSwitch({ item, activity, userStore }) {
  const { type } = item;

  switch (type) {
    case 'image':
      return <Image item={item} />;

    case 'video':
      return <Video item={item} activity={activity} />;

    case 'lab': {
      const { labId, permalink } = item;
      return <LabSection key={labId} permalink={permalink} userStore={userStore} noX noY />;
    }

    case 'code':
      return <CodeElement item={item} />;

    case 'h1':
      return <Header1Element item={item} />;

    case 'h2':
      return <Header2Element item={item} />;

    case 'h3':
      return <Header3Element item={item} />;

    case 'h4':
      return <Header4Element item={item} />;

    case 'link':
      return <LinkElement item={item} />;

    case 'list-item':
      return <ListItemElement item={item} />;

    case 'ol':
      return <OrderedListElement item={item} />;

    case 'ul':
      return <UnorderedListElement item={item} />;

    case 'blockquote':
      return <BlockQuoteElement item={item} />;

    case 'highlight':
      return <HighlightElement item={item} />;

    case 'paragraph':
    default:
      return <Paragraph item={item} />;
  }
}

export default function ContentRenderer({ content, userStore, activity }) {
  if (!content || !content.length) {
    return null;
  }
  return content.map((item, i) => {
    const key = `${item.id}_${i}`;
    return <ItemSwitch item={item} key={key} userStore={userStore} activity={activity} />;
  });
}
