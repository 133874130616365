// Import FirebaseAuth and firebase.
import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import Login from '../../components/Login/Login';
import { bootPendo } from '../../utils/pendoUtil';

const LoginPage = inject('commonStore')(
  observer(({ commonStore }) => {
    useEffect(() => {
      commonStore.setPageTitle('Login | Cybrary');
      // Pendo - Boot anonymously
      bootPendo();
    }, []);

    return <Login />;
  })
);

export default LoginPage;
