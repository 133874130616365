import React, { useMemo } from 'react';
import Icon from '../Icon/Icon';

const buttonClass = 'flex justify-center items-center cursor-pointer w-8 h-8 hover:bg-gray-200 hover:text-cyb-pink-500';
const linkClass = 'border-r last:border-0 border-gray-300';

const getPageHref = (pathname, pageIdx, activePage, shim) => {
  if (pageIdx === 1 - shim) {
    return pathname;
  }
  if (pageIdx === activePage) {
    return null;
  }
  return `${pathname}${pageIdx}/`;
};

const getOnClick = (pathname, pageIdx, activePage, shim, onPageChange) => {
  if (pageIdx === activePage) {
    return null;
  }
  return onPageChange
    ? {
        onClick: (e) => {
          e.preventDefault();
          onPageChange(pageIdx);
        },
      }
    : { href: getPageHref(pathname, pageIdx, activePage, shim) };
};

const getFirstPageBatch = (count, pathname, totalPages, activePage, shim, onPageChange) => {
  const ret = [];
  for (let i = 1 - shim; i <= count - shim; i++) {
    if (i >= 1 - shim && i <= totalPages) {
      const onClick = getOnClick(pathname, i, activePage, shim, onPageChange);
      const isActive = i === activePage;
      const ariaLabelText = isActive ? `Current Page, Page ${i + shim}` : `Goto Page ${i + shim}`;
      const activeLinkClass = isActive ? 'bg-gray-300 ' : '';
      const ariaCurrentProp = isActive ? { 'aria-current': 'true' } : {};
      ret.push(
        <li className={linkClass} key={i + shim}>
          <a className={`text-cyb-black hover:text-cyb-black ${buttonClass} ${activeLinkClass}`} {...onClick} aria-label={ariaLabelText} {...ariaCurrentProp}>
            {i + shim}
          </a>
        </li>
      );
    }
  }
  return ret;
};

const getLastPageBatch = (pathname, totalPages, activePage, shim, onPageChange) => {
  const ret = [];
  if (totalPages > 3 && activePage > totalPages - 2) {
    for (let i = totalPages - 2; i <= totalPages; i++) {
      if (i <= totalPages && i >= 1) {
        const onClick = getOnClick(pathname, i, activePage, shim, onPageChange);
        const isActive = i === activePage;
        const ariaCurrentProp = isActive ? { 'aria-current': 'true' } : {};
        const activeLinkClass = isActive ? 'bg-gray-300 ' : '';
        const ariaLabelText = isActive ? `Current Page, Page ${i + shim}` : `Goto Page ${i + shim}`;
        ret.push(
          <li className={linkClass} key={i + shim}>
            <a className={`text-cyb-black hover:text-cyb-black ${buttonClass} ${activeLinkClass}`} {...onClick} aria-label={ariaLabelText} {...ariaCurrentProp}>
              {i + shim}
            </a>
          </li>
        );
      }
    }
  }
  return ret;
};

const getMiddlePagesBatch = (pathname, totalPages, activePage, shim, onPageChange) => {
  const ret = [];
  if (activePage >= 3 - shim && activePage <= totalPages - 2) {
    for (let i = activePage - 1; i <= activePage + 1; i++) {
      const isActive = i === activePage;
      const onClick = getOnClick(pathname, i, activePage, shim, onPageChange);
      const activeLinkClass = isActive ? 'bg-gray-300 ' : '';
      const ariaLabelText = isActive ? `Current Page, Page ${i + shim}` : `Goto Page ${i + shim}`;
      const ariaCurrentProp = isActive ? { 'aria-current': 'true' } : {};
      ret.push(
        <li className={linkClass} key={i + shim}>
          <a className={`text-cyb-black hover:text-cyb-black ${buttonClass} ${activeLinkClass}`} {...onClick} aria-label={ariaLabelText} {...ariaCurrentProp}>
            {i + shim}
          </a>
        </li>
      );
    }
  }
  return ret;
};

const getFirstPageAndEllipsis = (pathname, totalPages, activePage, shim, onPageChange) => {
  const ret = [];
  if (totalPages > 3 && activePage >= 3 - shim) {
    const onClick = getOnClick(pathname, 1 - shim, activePage, shim, onPageChange);
    ret.push(
      <li className={linkClass} key="first-page">
        <a className={`text-cyb-black hover:text-cyb-black ${buttonClass}`} {...onClick} aria-label="Goto Page 1">
          1
        </a>
      </li>
    );
    ret.push(
      <li className={linkClass} key="beg-ellipses">
        <div className={buttonClass}>
          <Icon className="w-2 h-2" name="dots-horizontal" />
        </div>
      </li>
    );
  }
  return ret;
};

const getLastPageAndEllipsis = (pathname, totalPages, activePage, shim, onPageChange) => {
  const ret = [];
  if (totalPages > 3 && activePage <= totalPages - 2) {
    const onClick = getOnClick(pathname, totalPages, activePage, shim, onPageChange);
    ret.push(
      <li className={linkClass} key="end-ellipses">
        <div className={buttonClass}>
          <Icon name="dots-horizontal" className="w-2 h-2 text-center" />
        </div>
      </li>
    );
    ret.push(
      <li className={linkClass} key="last-page">
        <a className={`text-cyb-black hover:text-cyb-black text-xs ${buttonClass}`} {...onClick} aria-label={`Goto Page ${totalPages + shim}`}>
          {totalPages + shim}
        </a>
      </li>
    );
  }
  return ret;
};

function Pagination({ pathname, activePage, totalPages, isZeroIndexed, onPageChange, maxPages = 5 }) {
  const activePageAsNumber = Number(activePage);

  const shim = isZeroIndexed ? 1 : 0;
  const firstIndex = 1 - shim;
  const goToFirstClick = getOnClick(pathname, firstIndex, activePageAsNumber, shim, onPageChange);
  const goToPrevClick = getOnClick(pathname, activePageAsNumber - 1, activePageAsNumber, shim, onPageChange);
  const goToNextClick = getOnClick(pathname, activePageAsNumber + 1, activePageAsNumber, shim, onPageChange);
  const goToLastClick = getOnClick(pathname, totalPages, activePageAsNumber, shim, onPageChange);

  const navItems = useMemo(() => {
    if (totalPages <= 1) {
      return null;
    }

    const items = [];
    // Add double prev button if we are more than 2 pages in
    if (activePageAsNumber > firstIndex + 1) {
      items.push(
        <li className={linkClass} key="double-prev">
          <a className={buttonClass} rel="prev" {...goToFirstClick} aria-label="First Page">
            <Icon name="chevron-double-left" className="w-2 h-2" />
          </a>
        </li>
      );
    }

    // Add previous button if we are more than 1 page in
    if (activePageAsNumber > firstIndex) {
      // Previous button - Always shown, not always active
      items.push(
        <li className={`hidden md:flex ${linkClass}`} key="prev">
          <a className={buttonClass} rel="prev" {...goToPrevClick} aria-label="Previous Page">
            <Icon name="chevron-left" className="w-2 h-2" />
          </a>
        </li>
      );
    }

    // If there are maxPages or less pages, we just show those pages - nothing fancy
    if (totalPages <= maxPages - shim) {
      items.push(...getFirstPageBatch(maxPages, pathname, totalPages, activePageAsNumber, shim, onPageChange));
    } else {
      // If we are within 3 pages from the beginning, we show those MAXPAGES pages
      if (activePageAsNumber < 3 - shim) {
        items.push(...getFirstPageBatch(3, pathname, totalPages, activePageAsNumber, shim, onPageChange));
      }
      // If we are more than 3 pages in and there are more than MAXPAGES pages, show page 1 and ellipsis
      items.push(...getFirstPageAndEllipsis(pathname, totalPages, activePageAsNumber, shim, onPageChange));

      // If we are within 3 pages from the end, we show those last MAXPAGES pages (unless we have less than MAXPAGES pages total - Then already handled where we show first MAXPAGES pages)
      items.push(...getLastPageBatch(pathname, totalPages, activePageAsNumber, shim, onPageChange));

      // If not near the beginning or end, show the active page with the sibling pages
      items.push(...getMiddlePagesBatch(pathname, totalPages, activePageAsNumber, shim, onPageChange));

      // if we are more than 3 pages in but not more than 3 pages from the end, show the last page
      items.push(...getLastPageAndEllipsis(pathname, totalPages, activePageAsNumber, shim, onPageChange));
    }

    // Add next button if we are not on the last page
    if (activePageAsNumber !== totalPages) {
      items.push(
        <li className={`hidden md:flex ${linkClass}`} key="next">
          <a className={buttonClass} rel="next" {...goToNextClick} aria-label="Next Page">
            <Icon name="chevron-right" className="w-2 h-2" />
          </a>
        </li>
      );
    }
    // Add double next button if we have 2 or more pages to go
    if (totalPages - activePageAsNumber >= 2) {
      items.push(
        <li className={linkClass} key="double-next">
          <a className={buttonClass} rel="next" {...goToLastClick} aria-label="Last Page">
            <Icon name="chevron-double-right" className="w-2 h-2" />
          </a>
        </li>
      );
    }
    return items;
  }, [pathname, activePageAsNumber, totalPages, shim, onPageChange]);

  if (totalPages <= 1) {
    return null;
  }

  return (
    <nav aria-label="Pagination Navigation">
      <ul className="inline-flex overflow-hidden text-xs text-center bg-white rounded-sm border border-gray-300">{navItems}</ul>
    </nav>
  );
}

export default Pagination;
