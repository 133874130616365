import React, { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import Container from './Container';

/**
 * Wrapper to container widget to add consistent styling and padding to widgets
 * @See Dashboard for usage examples - src\pages\Dashboard\Dashboard.js
 * @param {string} children - The widget to be wrapped
 * @param {string} className - Tailwind classes to apply to the container
 * @param {string} id - ID to apply to the container
 * @param {string} size - Size of the container (to pass to the <Container /> component)
 * @param {boolean} omitPadding - Whether or not to omit padding from the container
 * @param {boolean} omitBorder - Whether or not to omit border from the container
 * @param {boolean} omitBackground - Whether or not to omit background from the container
 * @param {boolean} primaryWidget - Whether or not the widget is a primary widget. Adds additional margiin on desktop to the right
 * @param {boolean} secondaryWidget - Whether or not the widget is a secondary widget. Adds additional margin on mobile to top
 * @returns <WidgetContainer />
 */
function WidgetContainer({ children, id, className, size, omitPadding, omitBorder, omitBackground, primaryWidget, secondaryWidget }) {
  /**
   * Generate the class names for the container based on the props
   */
  const classNamesForContainer = useMemo(() => {
    let classes = `mx-auto w-full box-border`;
    if (!omitBackground) {
      // Background class to apply to the container
      classes = twMerge(classes, 'bg-[#FBFBFB]');
    }
    if (!omitBorder) {
      // Border class to apply to the container
      classes = twMerge(classes, 'border border-gray-400 lg:border-gray-600 lg:border-1');
    }
    if (!omitPadding) {
      // Padding class to apply to the container
      classes = twMerge(classes, 'p-2 lg:p-4');
    }
    if (primaryWidget) {
      // Additional margin right to apply to the primary containers on desktop
      classes = twMerge(classes, 'mr-0 lg:mr-4');
    }
    if (secondaryWidget) {
      // Additional margin top to apply to the secondary containers on mobile
      classes = twMerge(classes, 'mt-4 lg:mt-0');
    }
    if (className) {
      // Additional classes to apply to the container
      classes = twMerge(classes, className);
    }
    return classes;
  }, [className, omitPadding, omitBorder, omitBackground, primaryWidget, secondaryWidget]);

  /**
   * Render the container with the generated class names
   * Pass the size prop to the container
   * Omit padding from the <Container />. We handle padding in this wrapper component
   */
  return (
    <Container id={id} className={classNamesForContainer} size={size} omitPadding>
      {children}
    </Container>
  );
}

export default WidgetContainer;
