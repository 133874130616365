import React from 'react';
import AddLink from '../AddLink/AddLink';
import { BASE_BUTTON_STYLES } from '../../constants';
import Header from '../Header/Header';

function NoForumTopics({ categoryLink = process.env.REACT_APP_FORUMS_URL }) {
  return (
    <div className="flex flex-col justify-center items-center p-4 text-gray-600">
      <Header as="h3">No topics found at this time!</Header>
      <AddLink to={categoryLink} target="_blank" className={BASE_BUTTON_STYLES}>
        Start a Discussion
      </AddLink>
    </div>
  );
}

export default NoForumTopics;
