import React from 'react';
import { components } from 'react-select';

function ReactSelectMenuList(props) {
  return (
    <div id={props.selectProps.menuId} aria-expanded="true" role="listbox">
      <components.MenuList {...props} />
    </div>
  );
}
export default ReactSelectMenuList;
