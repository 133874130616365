import React from 'react';
import Icon from '../Icon/Icon';
import AddLink from '../AddLink/AddLink';
import Tooltip from '../Tooltip/Tooltip';

function CheckAndDisplayCerts(props) {
  const { certData, activityId, enrollmentId, completionDate, type, buttonFormat, fullWidth, certModalOpen, onClick } = props;

  if (!certData || !activityId || !enrollmentId || !type || !completionDate || !['Course', 'MicroCourse', 'Career Path', 'Virtual Lab'].includes(type) || !certData[type]) {
    return null;
  }

  let matchedCert = null;
  // Loop through the certs and find the certData that matches this activity/enrollment ID
  certData[type].forEach((item) => {
    // Check if the cert record's enrollment ID matches what was passed in
    if (1 * item.id === 1 * activityId && 1 * item.enrollment_id === 1 * enrollmentId) {
      matchedCert = item;
    }
  });

  if (!matchedCert) {
    return null;
  }
  const certId = matchedCert.certificate_id;
  const certConfig = {
    icon: 'certificate',
    popupContent: 'Download certificate of completion',
    certLink: `${process.env.REACT_APP_COURSE_API_URL}/certificate/${certId}/view`,
    target: '_blank',
  };

  if (buttonFormat) {
    const width = fullWidth ? 'w-full' : 'w-auto';
    return (
      <button onClick={() => onClick(!certModalOpen, matchedCert)} className={`rounded-sm bg-gray-200 text-black font-bold text-center leading-5 py-2.5 px-6 text-sm ${width}`}>
        Download Certificate
      </button>
    );
  }

  return (
    <Tooltip
      content={certConfig.popupContent}
      position="top-end"
      omitTabIndex
      triggerContent={
        <AddLink target="_blank" to={certConfig.certLink}>
          <Icon
            className="w-4 h-4 text-cyb-pink-500"
            name={certConfig.icon}
            onClick={(e) => {
              e.stopPropagation(); // Prevents the showhide row from opening too when in a expanding table row
            }}
          />
        </AddLink>
      }
    />
  );
}

export default CheckAndDisplayCerts;
