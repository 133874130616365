import React from 'react';
import AddLink from '../../components/AddLink/AddLink';
import Loading from '../../components/Loading/Loading';

function Message({ loading, error, formattedOffer }) {
  const content = {};
  if (loading) {
    return <Loading />;
  }

  if (error) {
    content.header = 'There was an error processing your request';
    content.body = (
      <p className="mt-4 text-sm">
        Please click on the chat bubble below to chat with us now, or anytime, so we can get you on track to developing your career to the next level. You can also email us at{' '}
        <AddLink className="text-cyb-pink-500 hover:text-black underline cursor-pointer" to="mailto:support@cybrary.it">
          support@cybrary.it
        </AddLink>
        .
      </p>
    );
  }

  if (formattedOffer && !error) {
    const { newPrice, newBasePrice, nextPaymentDate, applyCoupon, adjustPricing } = formattedOffer;
    content.header = 'Thank you for choosing to continue with Cybrary Insider Pro!';
    content.subHeader = null;
    if (applyCoupon && newPrice) {
      content.subHeader = (
        <p className="mt-4 text-sm">
          Your membership will automatically continue at {newPrice}.<br />
          This new price will be applied to your next bill on {nextPaymentDate}.
        </p>
      );
    }
    if (adjustPricing && newBasePrice) {
      content.subHeader = (
        <p className="mt-4 text-sm">
          Your membership will automatically continue at {newBasePrice}.<br />
          This new price will be applied to your next bill on {nextPaymentDate}.
        </p>
      );
    }
    content.body = (
      <p className="mt-4 text-sm">
        We are ready to help you develop your career. Please chat with us anytime so we can help you develop your career to the next level. You can also email us at{' '}
        <AddLink className="text-cyb-pink-500 hover:text-black underline cursor-pointer" to="mailto:support@cybrary.it">
          support@cybrary.it
        </AddLink>{' '}
        with any questions or requests for assistance.
      </p>
    );
  }

  return (
    <div>
      {content.header ? <h2 className="mb-4 text-2xl text-black">{content.header}</h2> : null}
      {content.subHeader ? content.subHeader : null}
      {content.body ? <div>{content.body}</div> : null}
      <div className="mt-8">
        <AddLink to="/">
          <button className="py-2.5 px-6 text-sm font-bold leading-5 text-center text-white hover:text-white bg-cyb-pink-500 hover:bg-pink-600 rounded-sm">Home</button>
        </AddLink>
      </div>
    </div>
  );
}

function CancellationThanks({ loading, error, formattedOffer }) {
  return (
    <div className="pt-12 pb-8">
      <Message loading={loading} error={error} formattedOffer={formattedOffer} />
    </div>
  );
}

export default CancellationThanks;
