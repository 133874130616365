import React from 'react';
import Table from '../Table/Table';
import Header from '../Header/Header';
import AddLink from '../AddLink/AddLink';
import WidgetContainer from '../Container/WidgetContainer';

function CybraryAdminTeamInfo({ teams }) {
  return (
    <WidgetContainer className="mt-6" omitBorder omitBackground>
      <Header as="h2">Teams</Header>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Role</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {teams.map((team) => {
            return (
              <Table.Row key={team.id}>
                <Table.Cell>
                  <AddLink className="underline" to={`/admin/view-team/${team.id}`}>
                    {team.id}
                  </AddLink>
                </Table.Cell>
                <Table.Cell>{team.name}</Table.Cell>
                <Table.Cell>{team.member_status}</Table.Cell>
                <Table.Cell>{team.role}</Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </WidgetContainer>
  );
}

export default CybraryAdminTeamInfo;
