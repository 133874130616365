import React from 'react';
import { twMerge } from 'tailwind-merge';
import AddLink from '../AddLink/AddLink';
import { BASE_BUTTON_STYLES } from '../../constants';

export function StartLearningButton({ permalink, className }) {
  return (
    <AddLink className={twMerge(BASE_BUTTON_STYLES, 'bg-gray-600 hover:bg-slate-600', className)} to={`/browse/${permalink}`}>
      Start Learning
    </AddLink>
  );
}

export function ContinueLearningButton({ className, to }) {
  return (
    <AddLink className={twMerge(BASE_BUTTON_STYLES, 'bg-cyb-pink-500 hover:bg-pink-600', className)} to={to}>
      Continue Learning
    </AddLink>
  );
}
