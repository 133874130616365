import React from 'react';

export default function Rocket2({ classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';
  return (
    <svg className={className} viewBox="0 0 130 135" fill="none" xmlns="http://www.w3.org/2000/svg" {...accessibilityProps}>
      <path
        d="M13.167 58.6435L29.258 65.2667C27.4437 69.8134 25.9963 74.4955 
      24.9299 79.2698L24.3194 82.0233L47.3876 104.934L50.1616 104.343C54.9715 
      103.284 59.6884 101.847 64.2689 100.047L70.9415 116.019L70.9406 
      116.018C71.0731 116.339 71.3605 116.573 71.704 116.64C72.0475 116.706 
      72.4016 116.597 72.6464 116.348L82.7306 106.338C86.0582 103.035 87.8231 
      98.4888 87.586 93.8232L87.2261 87.3932C105.897 73.7071 124.788 49.7087 129.935 
      7.8248C130.231 5.6965 129.508 3.55281 127.981 2.02866C126.455 0.505421 124.3 -0.222575 
      122.154 0.0597818C79.97 5.20854 55.7794 23.9756 41.9924 42.4527L35.5278 42.1223C30.8379 
      41.8687 26.2583 43.5946 22.9193 46.8725L12.8351 56.8821C12.5507 57.1222 12.4172 57.4949 
      12.4858 57.8598C12.5545 58.2238 12.8148 58.5235 13.168 58.6445L13.167 58.6435ZM77.8617 
      34.2458C80.2052 31.9273 83.3798 30.6278 86.6881 30.6318C89.9971 30.6356 93.169 31.9428 95.5068 
      34.266C97.8446 36.5892 99.1584 39.7395 99.1584 43.0231C99.1584 46.3067 97.8444 49.4571 95.5068 
      51.7803C93.1691 54.1036 89.9974 55.4118 86.6881 55.4145C83.38 55.4183 80.2052 54.1189 77.8617 
      51.8004C75.5105 49.4753 74.1898 46.3173 74.1898 43.0231C74.1898 39.729 75.5106 36.571 77.8617 
      34.2458ZM10.155 106.765C7.4845 105.834 4.6176 105.607 1.8317 106.103C1.32857 106.212 0.805097 
      106.056 0.445137 105.69C-0.00478846 105.246 -0.126707 104.57 0.139374 103.997C3.09446 97.6911 
      10.9317 85.0104 25.1226 95.2543C25.2725 95.3869 25.3587 95.578 25.3587 95.7768C25.3587 95.9766 
      25.2725 96.1677 25.1226 96.3002C21.2696 99.3121 19.0915 103.964 19.255 108.83C19.2763 109.414 19.7485 
      109.883 20.3368 109.904C25.2172 110.099 29.9013 107.984 32.9599 104.204C33.0944 104.04 33.2957 103.945 33.5075 
      103.945C33.7204 103.945 33.9217 104.04 34.0561 104.204C36.0261 106.531 41.4496 114.131 35.4437 121.469C32.8215 
      124.598 29.0634 126.576 24.984 126.977C19.1581 127.597 8.39368 129.483 3.99698 134.468C3.63704 134.89 3.07196 
      135.082 2.52625 134.967C1.98151 134.852 1.54416 134.448 1.38836 133.916C-0.164608 128.657 -2.57962 116.899 10.155 
      106.765Z"
      />
    </svg>
  );
}
