import { observable, computed, action, makeObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import Cookies from 'js-cookie';
import moment from 'moment';
import Bugsnag from '@bugsnag/js';
import Agents from '../agents/agents';
import GaUtil from '../utils/gaUtil';
import DEMO_TEAM_DATA from '../pages/Enterprise/Demo/DEMO_TEAM_DATA.json';
import EnrollmentUtil from '../utils/enrollmentUtil';
import { CHARGEBEE_CIP_ANNUAL, CHARGEBEE_CIP_MONTHLY, CHARGEBEE_TEAM_ANNUAL, DEFAULT_COOKIE_SETTINGS } from '../constants';

const COOKIE_PROPS = {
  ...DEFAULT_COOKIE_SETTINGS,
  path: '/',
  expires: 0.25,
};

/**
 * domain store of users via mobx
 */
class UserStore {
  user = null;

  userProfile = null;

  userProfileStats = null;

  isUserProfileStatsLoading = false;

  isUserXpInitialized = false;

  /**
   * Track changes to xp between userProfileStats calls
   * Used for displaying xp changes in the header and level up notifications
   */
  xpChanges = {
    xp: 0,
    level: 0,
  };

  xpCurrentLevelProgress = {
    xpNeededToLevelUp: 0,
    currentLevelXpProgress: 0,
    xpProgressPercent: 0,
    nextLevelTotalXp: 0,
  };

  userTeams = null;

  teamData = null;

  permissions = null;

  licenses = null;

  subscriptions = null;

  isLoading = false;

  bookmarks = null;

  bookmarksLoading = false;

  // @TODO update with cybrary default image
  avatarUrl = 'https://pbs.twimg.com/profile_images/968595174719983616/3ZGuTPJC.jpg';

  constructor() {
    makeObservable(this, {
      user: observable,
      resetUser: action,
      userProfile: observable,
      userProfileStats: observable,
      isUserProfileStatsLoading: observable,
      setIsUserProfileStatsLoading: action,
      xpChanges: observable,
      xpCurrentLevelProgress: observable,
      userTeams: observable,
      teamData: observable,
      permissions: observable,
      licenses: observable,
      subscriptions: observable,
      isLoading: observable,
      bookmarks: observable,
      bookmarksLoading: observable,
      avatarUrl: observable,
      // isTestUser: computed,
      isLoggedIn: computed,
      profileData: computed,
      fetchCategoryPreferences: action,
      fetchBookmarks: action,
      fetchLevelPreferences: action,
      getCategoryPreferences: action,
      getLevelPreferences: action,
      getBookmarks: action,
      goal: computed,
      inferredRole: computed,
      role: computed,
      isNewUser: computed,
      name: computed,
      userAvatarUrl: computed,
      team: computed,
      potentialTeam: computed,
      activeTeams: computed,
      teamsAdministering: computed,
      isEnterpriseAdmin: computed,
      teamsWithAdminAccess: computed,
      teamsWithOnboardingAccess: computed,
      adminTeams: computed,
      ownerTeams: computed,
      adminTeam: computed,
      isTeamMember: computed,
      isCip: computed,
      isFree: computed,
      isEnterprise: computed,
      userType: computed,
      hasPremiumContentLicense: computed,
      isUpgradeModalOpen: observable,
      upgradeModalOpen: action,
      upgradeModalClose: action,
      setLicenses: action,
      setPermissions: action,
      setUserProfile: action,
      setUserTeams: action,
      setTeam: action,
      setTeamLogo: action,
      setPreferredTeam: action,
      setPreferredTeamInit: action,
      setPreferredGroups: action,
      setPreferredGroup: action,
      setAvatarUrl: action,
      setIsLoading: action,
      setUser: action,
      setUserSubscriptions: action,
      loadUserProfile: action,
      loadUserProfileStats: action,
      getUserPermissions: action,
      loadUserMeta: action,
      loadUserTeams: action,
      loadAndSetTeamData: action,
      loadingUserPreferences: observable,
      loadingCategories: observable,
      loadingLevel: observable,
      loadUserSubscriptions: action,
      categoryIsSubmitting: observable,
      levelIsSubmitting: observable,
      categoryAndLevelAreSubmitting: observable,
      categoryErrors: observable,
      levelErrors: observable,
      verticalAndSkillErrors: observable,
      categoryData: observable,
      levelData: observable,
      originalCategoryData: observable,
      originalLevelData: observable,
      isPreferencesModalOpen: observable,
      isEditablePreferences: observable,
      setIsLoadingUserPreferences: action,
      setloadingCategories: action,
      setloadingLevel: action,
      userPreferences: computed,
      setCategoryErrors: action,
      setLevelErrors: action,
      setVerticalAndSkillErrors: action,
      setCategoryData: action,
      setLevelData: action,
      setOriginalCategoryData: action,
      setOriginalLevelData: action,
      setCategoryIsSubmitting: action,
      setLevelIsSubmitting: action,
      setCategoryAndLevelAreSubmitting: action,
      preferencesModalOpen: action,
      preferencesModalClose: action,
      setEditablePreferences: action,
      clearVerticalAndSkillErrorsForKey: action,
      submitCategoriesAndLevelForm: action,
      getCategoryAndLevelPreferences: action,
      toggleBookmark: action,
      fetchSubscriptionStatus: action,
      removeSubscriptionStatus: action,
      contentPreferences: observable,
      setDefaultContentPreferences: action,
      getContentPreferences: action,
      addContentPreference: action,
      isUserWithinWeekOfRegistration: computed,
      isUserEnrolledInFreeMonthlyCourseLastWeek: computed,
      isUserOnActiveTeam: computed,
      hasActiveTeamsLicense: computed,
      exitDemo: action,
      enterDemo: action,
    });

    makePersistable(this, { name: 'UserStore', properties: ['categoryData', 'levelData', 'bookmarks'], storage: window.localStorage, debugMode: false });
  }

  resetUser() {
    this.userProfile = null;
    this.userProfileStats = null;
    this.userTeams = null;
    this.teamData = null;
    this.permissions = null;
    this.licenses = null;
    this.user = null;
    this.subscriptions = null;
  }

  /**
   * Check if user id is even for A/B Test
   */

  // get isTestUser() {
  //   if (!this.user || !this.user.id) {
  //     return false;
  //   }
  //   return this.user.id % 2 === 0;
  // }

  /**
   * Automatically calculated pieces of state
   */
  get isLoggedIn() {
    return !!this.user;
  }

  get profileData() {
    if (this.user && this.user.onboarding_data && Object.keys(this.user.onboarding_data).length) {
      return this.user && this.user.onboarding_data;
    }
    return null;
  }

  /**
   * Attempt to find the user's goal.
   */
  get goal() {
    if (this.user && this.user.onboarding_data && this.user.onboarding_data.goal !== undefined) {
      return this.user.onboarding_data.goal;
    }
    return null;
  }

  /**
   * Attempt to find the inferred role
   */
  get inferredRole() {
    if (this.user && this.user.onboarding_data && this.user.onboarding_data.inferred_role !== undefined) {
      return this.user.onboarding_data.inferred_role;
    }
    return null;
  }

  /**
   * Attempt to find the user's role.
   */
  get role() {
    if (this.user && this.user.onboarding_data && this.user.onboarding_data.role !== undefined) {
      return this.user.onboarding_data.role;
    }
    return null;
  }

  /**
   * Return true if the user registered today
   */
  get isNewUser() {
    if (this.user && this.user.registered_at) {
      const registered = moment(this.user.registered_at);
      return !registered.isBefore(moment().utc(), 'day');
    }
    return false;
  }

  get name() {
    if (this.user && this.user.real_name) {
      return this.user.real_name;
    }
    if (this.user && this.user.name) {
      return this.user.name;
    }
    return null;
  }

  get userAvatarUrl() {
    if (this.avatarUrl.indexOf('https') >= 0) {
      return this.avatarUrl;
    }
    return `https:${this.avatarUrl}`;
  }

  get team() {
    return this.teamData;
  }

  /*
   * Find potential team that the user is not on, but can join. If team exists in userTeams, with invited_by_domain === true AND role === null, this is a potential team
   */
  get potentialTeam() {
    let ret = null;
    if (this.userTeams && Object.keys(this.userTeams).length) {
      const potentialTeams = Object.keys(this.userTeams).filter((teamId) => {
        const team = this.userTeams[teamId];
        return team.invited_by_domain && team.role === null;
      });
      ret = this.userTeams[potentialTeams[0]] || null;
    }
    return ret;
  }

  getSpecificTeam(id) {
    return this.userTeams[id];
  }

  get activeTeams() {
    if (!this.userTeams || !Object.keys(this.userTeams).length) {
      return [];
    }
    return Object.keys(this.userTeams).filter((teamId) => {
      const userTeam = this.userTeams[teamId];
      return userTeam.status === 'Active' && userTeam.role;
    });
  }

  get isUserOnActiveTeam() {
    return this.activeTeams && !!this.activeTeams.length;
  }

  get teamsAdministering() {
    let ret = [];
    const { team } = this;
    if (team && team.role === 'team-admin') {
      ret = [team];
    }
    return ret;
  }

  get isEnterpriseAdmin() {
    const { teamsAdministering } = this;
    return teamsAdministering && teamsAdministering.length;
  }

  /* Returns all teams that the user has admin access over some team components (not neccessarily team admin access) */
  get teamsWithAdminAccess() {
    const { userTeams: teams } = this;
    const ret = {};
    if (teams) {
      const keys = Object.keys(teams);
      keys.forEach((key) => {
        const team = teams[key];
        const { permissions } = team;
        if (
          permissions &&
          (permissions.canManageAdmins ||
            permissions.canManageAssignments ||
            permissions.canManageCurricula ||
            permissions.canManageGoals ||
            permissions.canManageGroup ||
            permissions.canManageTeam ||
            permissions.canViewReports)
        ) {
          ret[team.id] = team;
        }
      });
    }
    return ret;
  }

  /* Returns all teams that the user has onboarding access for */
  get teamsWithOnboardingAccess() {
    const { userTeams: teams } = this;
    const ret = {};
    if (teams) {
      const keys = Object.keys(teams);
      keys.forEach((key) => {
        const team = teams[key];
        const { permissions } = team;
        if (permissions && permissions.canManageOnboarding) {
          ret[team.id] = team;
        }
      });
    }
    return ret;
  }

  /* Returns all teams that the user can manage paths for */
  get teamsWithPathsAdminAccess() {
    const { userTeams: teams } = this;
    const ret = {};
    if (teams) {
      const keys = Object.keys(teams);
      keys.forEach((key) => {
        const team = teams[key];
        const { permissions } = team;
        if (permissions && permissions.canManageCurricula) {
          ret[team.id] = team;
        }
      });
    }
    return ret;
  }

  /* Returns all teams that the user is an admin (or owner) of */
  get adminTeams() {
    const { userTeams: teams } = this;
    const ret = {};
    if (teams) {
      const keys = Object.keys(teams);
      keys.forEach((key) => {
        const team = teams[key];
        const { permissions } = team;
        if (permissions && (permissions.canManageAdmins || permissions.canManageTeam)) {
          ret[team.id] = team;
        }
      });
    }
    return ret;
  }

  get ownerTeams() {
    const { userTeams: teams } = this;
    const ret = {};
    if (teams) {
      const keys = Object.keys(teams);
      keys.forEach((key) => {
        const team = teams[key];
        const { permissions } = team;
        if (permissions && permissions.canManageAdmins) {
          ret[team.id] = team;
        }
      });
    }
    return ret;
  }

  get adminTeam() {
    const teams = this.teamsWithAdminAccess;
    if (!teams || !Object.keys(teams).length) {
      return null;
    }
    const keys = Object.keys(teams);
    if (this.teamData && teams[this.teamData.id]) {
      return this.teamData;
    }
    // Just grab the first team I can administer if we've gotten this far.
    return teams[keys[0]];
  }

  get isTeamMember() {
    const { team } = this;
    if (team && (team.role === 'team-member' || team.role === null)) {
      return true;
    }
    return false;
  }

  get isCip() {
    return !!(this.user && this.user.is_cip);
  }

  get isFree() {
    return this.user && !this.user.is_paid;
  }

  get isEnterprise() {
    return this.user && this.user.is_enterprise;
  }

  get userType() {
    if (this.user) {
      if (this.user.is_cip) {
        return 'cip';
      }
      if (this.user.is_enterprise && this.user.is_paid) {
        return 'enterprise';
      }
    }
    return 'free';
  }

  get hasPremiumContentLicense() {
    const premiumPackages = ['avatao'];
    let hasPremium = false;
    if (this.permissions && this.permissions.package_types && this.permissions.package_types.length) {
      // Loop through user package types and see if user has any premium packages
      for (let i = 0; i < this.permissions.package_types.length; i++) {
        const packageType = this.permissions.package_types[i];
        if (premiumPackages.indexOf(packageType) > -1) {
          hasPremium = true;
        }
      }
    }
    return hasPremium;
  }

  // Check if a user is on a team that has assigned them a license.
  // If a user has a license with a team_id we know they have been assigned one.
  get hasActiveTeamsLicense() {
    return !!this.licenses.find((license) => !!license.team_id);
  }

  isUpgradeModalOpen = false;

  upgradeModalOpen() {
    this.isUpgradeModalOpen = true;
  }

  upgradeModalClose() {
    this.isUpgradeModalOpen = false;
  }

  setLicenses(licenses) {
    this.licenses = licenses;
  }

  setPermissions(permissions) {
    this.permissions = permissions;
  }

  setUserProfile(userProfile) {
    this.userProfile = userProfile;
  }

  setUserTeams(userTeams) {
    this.userTeams = userTeams;
  }

  setTeam(teamData) {
    this.teamData = teamData;
  }

  setTeamLogo(url) {
    this.teamData.logo_url = url;
  }

  setUserSubscriptions(subscriptions) {
    this.subscriptions = subscriptions;
  }

  // Set current_team_id in user_preference -- Used for nav team switcher
  setMetaPreferenceTeam(id) {
    localStorage.setItem('preferred-team', id);
  }

  setPreferredTeam(key) {
    if (!!key && !!this.userTeams && !!this.userTeams[key]) {
      this.teamData = this.userTeams[key];
    } else if (this.userTeams) {
      const preferredTeam = localStorage.getItem('preferred-team');
      // IF you are removed from a team, the team doesn't get removed from user_preference currently.. So if not found, set it to first team found
      if (preferredTeam && this.userTeams[preferredTeam]) {
        this.teamData = this.userTeams[preferredTeam];
      } else {
        this.teamData = this.userTeams[Object.keys(this.userTeams)[0]];
      }
    }
    if (this.teamData) {
      this.setPreferredGroup(this.teamData.id);
    }
  }

  // Set preferred team on component update
  setPreferredTeamInit(oldParam, newParam, init) {
    if (oldParam !== newParam) {
      this.setPreferredTeam(newParam);
      if (init) {
        init(newParam);
      }
    }
  }

  setPreferredGroups() {
    return Object.keys(this.userTeams).forEach((orgId) => {
      this.setPreferredGroup(orgId);
      this.setTeamPermissions(orgId);
    });
  }

  // Sets preferred groups, permissions, and teamGroupId's for each team
  // Team group ID's are the preferred team or group ID to use, based on role, for certain service calls
  setPreferredGroup(orgId, groupId) {
    let preferredGroup = null;
    if (!!groupId && !!orgId) {
      // Need to loop over user's groups and find group matching ID
      preferredGroup = groupId;
    } else if (!!sessionStorage[`preferredGroup-${orgId}`] && sessionStorage[`preferredGroup-${orgId}`].length) {
      // if no group ID supplied, check if they have it specified in session storage
      preferredGroup = sessionStorage[`preferredGroup-${orgId}`];
    } else {
      // If nothing in session storage, set it to first group
      preferredGroup = this.findPreferredGroup(orgId);
    }

    if (preferredGroup) {
      this.userTeams[orgId].preferredGroup = preferredGroup * 1;
      sessionStorage.setItem(`preferredGroup-${orgId}`, preferredGroup);
    }
    this.setTeamGroupIds(orgId);
  }

  // Helper function to find the specified group ID in the array of user's groups
  findPreferredGroup = (orgId) => {
    const team = this.userTeams[orgId];

    if (!this.userTeams || !team || !team.user_groups || !team.user_groups.length) {
      return null;
    }

    const sortedGroupArr = team.user_groups.slice().sort(this.compare);
    // Pick best preferred group (first group alphabetically with the highest role)
    // First look for any groups with team-admin role
    for (let i = 0; i < sortedGroupArr.length; i++) {
      if (sortedGroupArr[i].role === 'team-admin') {
        return sortedGroupArr[i].id;
      }
    }
    // If no team-admin role found, return first team-reporting-admin found
    for (let i = 0; i < sortedGroupArr.length; i++) {
      if (sortedGroupArr[i].role === 'team-reporting-admin') {
        return sortedGroupArr[i].id;
      }
    }
    // If no team-admin or team-reporting-admin, just return first group, although they shouldn't need a preferred group
    return (sortedGroupArr[0] && sortedGroupArr[0].id) || null;
  };

  // Array of objects sort
  compare = (a, b) => {
    // Use toUpperCase() to ignore character casing
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();

    let comparison = 0;
    if (nameA > nameB) {
      comparison = 1;
    } else if (nameA < nameB) {
      comparison = -1;
    }
    return comparison;
  };

  setAvatarUrl(avatarUrl) {
    if (avatarUrl) {
      this.avatarUrl = avatarUrl;
    }
  }

  setIsLoading(isLoading) {
    this.isLoading = isLoading;
  }

  // Check the permissions for the user on a given content description.  Returns true/false.
  checkPermissions(contentDescription) {
    // Cybrary Select permissions check
    // Only Teams user's allowed (no CIP or Free)
    if (EnrollmentUtil.isCybrarySelect(contentDescription) || EnrollmentUtil.isCustomPath(contentDescription)) {
      return this.hasActiveTeamsLicense;
    }
    // Regular handling of permissions
    if (this.permissions?.content_type_ids && contentDescription?.content_type) {
      if (this.permissions.content_type_ids.includes(contentDescription.content_type.id)) {
        return true;
      }
    }
    return contentDescription && contentDescription.is_free;
  }

  setUser(user) {
    this.user = user;
  }

  // Load user profile from backend
  loadUserProfile() {
    Agents.auth
      .getUserProfile()
      .then(
        action('fetchSuccess', (response) => {
          this.userProfile = response.data;
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
        })
      );
  }

  /** calculate and set state for xpChanges */
  calcProfileStatChanges(oldStats, newStats) {
    const oldXp = oldStats?.experience_points || 0;
    const newXp = newStats?.experience_points || 0;
    const oldLevel = oldStats?.level || 0;
    const newLevel = newStats?.level || 0;

    this.xpChanges = {
      xp: newXp - oldXp,
      level: newLevel - oldLevel,
    };
  }

  /** calculates and sets state for xpCurrentLevelProgress */
  calcCurrentLevelProgress = (stats) => {
    const { experience_points = 0, current_level_experience_points = 0, next_level_experience_points = 0 } = stats || {};
    const currentLevelXpProgress = experience_points - current_level_experience_points;
    const nextLevelTotalXp = next_level_experience_points - current_level_experience_points;
    const xpNeededToLevelUp = nextLevelTotalXp - currentLevelXpProgress;
    const xpProgressPercent = Math.round((currentLevelXpProgress / nextLevelTotalXp) * 100);

    this.xpCurrentLevelProgress = {
      xpNeededToLevelUp,
      currentLevelXpProgress,
      xpProgressPercent,
      nextLevelTotalXp,
    };
  };

  async fetchUserProfileStats() {
    let stats = null;
    try {
      this.isUserProfileStatsLoading = true;
      /** fetch latest stats */
      stats = await Agents.profile.getProfileStats();

      /** update our global state with latest stats */
    } catch (error) {
      Bugsnag.notify(error);
    } finally {
      this.isUserProfileStatsLoading = false;
    }
    return stats;
  }

  async fetchUserXpStats() {
    let xpStats = null;
    try {
      this.isUserProfileStatsLoading = true;
      /** fetch latest stats */
      xpStats = await Agents.profile.getExperiencePoints();

      /** update our global state with latest stats */
    } catch (error) {
      Bugsnag.notify(error);
    } finally {
      this.isUserProfileStatsLoading = false;
    }
    return xpStats;
  }

  // Load user profile stats from backend for display on dashboard
  /**
   * Loads user profile stats from backend for display on dashboard
   * Optionally pass in isXpOnly to only load xp stats (used in immersive)
   * @param {Boolean} isXpOnly - optional param to only load xp stats
   */
  async loadUserProfileStats(isXpOnly = false) {
    let newStats = null;
    try {
      if (isXpOnly) {
        newStats = await this.fetchUserXpStats();
      } else {
        newStats = await this.fetchUserProfileStats();
      }

      /** calc level progress needed for progress related components */
      this.calcCurrentLevelProgress(newStats);
      /** only want to calculate changes in stats AFTER we know loadProfileStats has executed at least once */
      if (this.isUserXpInitialized) {
        const oldStats = { ...this.userProfileStats };

        /** we can't have changes in stats until AFTER we have fetched initial stats */
        this.calcProfileStatChanges(oldStats, newStats);
      }
      // update our global state overwriting old stats with any newly loaded stats
      this.userProfileStats = { ...this.userProfileStats, ...newStats };
    } catch (error) {
      this.resetXpChanges();
      Bugsnag.notify(error);
    } finally {
      this.isUserXpInitialized = true;
    }
  }

  resetXpChanges() {
    this.xpChanges = {
      xp: 0,
      level: 0,
    };
  }

  // Update user language preference
  async updateLanguagePreference(locale) {
    await Agents.profile.updateLanguagePreference({ language: locale });

    this.user.language = locale;
  }

  // Flatten a single permissions array, taking in the items from the license object
  // Results in an array of IDs, with only one occurrence per ID
  flattenPermissionArray(permArray, items) {
    // Flatten the content type ids arrays
    const permissions = [...permArray];
    if (items && items.length) {
      for (let i = 0; i < items.length; i++) {
        if (permArray.indexOf(items[i]) === -1) {
          permissions.push(items[i]);
        }
      }
    }
    return permissions;
  }

  // Take the new user licenses structure and transform it to content permissions structure
  transformLicensesToPermissions(licenses) {
    const permissions = {
      content_description_ids: [],
      content_type_ids: [],
      package_types: [],
    };
    if (licenses && licenses.length) {
      licenses.forEach((license) => {
        permissions.content_description_ids = this.flattenPermissionArray(permissions.content_description_ids, license.content_description_ids);
        permissions.content_type_ids = this.flattenPermissionArray(permissions.content_type_ids, license.content_type_ids);
        permissions.package_types = this.flattenPermissionArray(permissions.package_types, license.package_types);
      });
    }

    localStorage.setItem(`content-packages`, permissions.package_types);
    return permissions;
  }

  // Get User Permissions
  getUserPermissions() {
    return new Promise((resolve, reject) => {
      Agents.auth
        .getUserLicenses()
        .then(
          action('fetchPermissionsSuccess', (response) => {
            // Convert the licenses to be in permissions format
            const permissions = this.transformLicensesToPermissions(response);
            this.setPermissions(permissions);
            // Let's keep track of the license, in case it's needed for UI purposes (it will be)
            this.setLicenses(response);
            return resolve();
          })
        )
        .then(async () => {
          // get the user's prefrences, bookmarks, and subscriptions
          await Promise.allSettled([this.getCategoryAndLevelPreferences(), this.getBookmarks(), this.loadUserSubscriptions()]);
          return resolve();
        })
        .catch(
          action('fetchError', (error) => {
            Bugsnag.notify(error);
            return reject(error);
          })
        );
    });
  }

  async loadUserSubscriptions() {
    const subscriptions = {
      allSubscriptions: null,
      activeSubscription: null,
    };
    try {
      const allSubscriptions = await Agents.auth.getSubscriptions();
      const activeSubscription = allSubscriptions?.find((sub) => sub?.status === 'active');
      subscriptions.allSubscriptions = allSubscriptions;
      subscriptions.activeSubscription = activeSubscription;
      if (activeSubscription?.source === 'paddle') {
        // Paddle price IDs map to different subscription types and cadences
        switch (activeSubscription?.price_id) {
          case process.env.REACT_APP_PADDLE_PRICE_ID_CIP_MONTHLY:
            subscriptions.activeSubscription.type = 'CIP Monthly';
            break;
          case process.env.REACT_APP_PADDLE_PRICE_ID_CIP_ANNUAL:
            subscriptions.activeSubscription.type = 'CIP Annual';
            break;
          case process.env.REACT_APP_PADDLE_PRICE_ID_TEAM_ANNUAL:
            subscriptions.activeSubscription.type = 'Team Annual';
            break;
          default:
            subscriptions.activeSubscription.type = 'Unknown';
            break;
        }
      } else if (activeSubscription?.source === 'chargebee') {
        // Chargebee plan IDs map to different subscription types and cadences
        switch (activeSubscription?.plan_id) {
          case CHARGEBEE_CIP_MONTHLY:
            subscriptions.activeSubscription.type = 'CIP Monthly';
            break;
          case CHARGEBEE_CIP_ANNUAL:
            subscriptions.activeSubscription.type = 'CIP Annual';
            break;
          case CHARGEBEE_TEAM_ANNUAL:
            subscriptions.activeSubscription.type = 'Team Annual';
            break;
          default:
            subscriptions.activeSubscription.type = 'Unknown';
            break;
        }
      } else if (subscriptions?.activeSubscription) {
        subscriptions.activeSubscription.type = 'Unknown';
      }
      this.setUserSubscriptions(subscriptions);
      return subscriptions;
    } catch (error) {
      Bugsnag.notify(error);
      return subscriptions;
    }
  }

  setUserMetaCookie = (meta) => {
    const metaCookie = {
      id: meta.id,
      is_cip: meta.is_cip,
      is_enterprise: meta.is_enterprise,
      is_paid: meta.is_paid,
      avatar_url: meta.avatar_url,
      registered_at: meta.registered_at,
    };
    Cookies.set('user-meta', JSON.stringify(metaCookie), COOKIE_PROPS);
  };

  // Load user meta from backend
  loadUserMeta(skipPermission) {
    // Request this data from server
    return Agents.auth
      .getUserMeta()
      .then(
        action('fetchSuccess', (response) => {
          this.setUserMetaCookie(response);
          this.setUser(response);
          this.setAvatarUrl(response.avatar_url);
        })
      )
      .then(() => {
        if (skipPermission) {
          return Promise.resolve();
        }
        // secondary request to get permissions
        return this.getUserPermissions();
      })
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          return Promise.reject(error);
        })
      );
  }

  // Load user's associated teams
  loadUserTeams() {
    return Agents.auth
      .getUserTeams()
      .then(
        action('fetchSuccess', (response) => {
          this.setUserTeams(response);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          return Promise.reject(error);
        })
      );
  }

  postAvatar = (data) => {
    const formData = new FormData(); // Back end is expecting form data
    formData.append('image', data.image);
    return Agents.profile.uploadAvatarImage(formData);
  };

  /* Enterprise app permissions based on the org role and the highest role among groups */
  setTeamPermissions(teamId) {
    const team = this.getSpecificTeam(teamId);
    const permissions = this.getTeamPermissions(team);
    team.permissions = permissions.teamPermissions;
    team.adminGroupsList = permissions.adminGroupsList;
    team.reportAdminGroupsList = permissions.reportAdminGroupsList;
    this.setTeamGroupIds(teamId);
  }

  getTeamPermissions(team) {
    const groups = team.user_groups;
    // Create an array of groups that the user is an admin of - Assists with permissions at team level and group levels downstream
    const adminGroupsList = [];
    const reportAdminGroupsList = [];
    groups.forEach((group) => {
      if (group.role === 'team-admin') {
        adminGroupsList.push(group.id);
      }
      if (group.role === 'team-reporting-admin') {
        reportAdminGroupsList.push(group.id);
      }
    });
    const teamPermissions = {
      canManageAdmins: this.canManageAdmins(team),
      canManageTeam: this.canManageTeam(team),
      canManageOnboarding: this.canManageOnboarding(team),
      canManageGroup: this.canManageGroup(team, adminGroupsList),
      canManageCurricula: this.canManageCurricula(team, adminGroupsList),
      canManageGoals: this.canManageCurricula(team, adminGroupsList), // For now, same as canManageCurricula
      canManageAssignments: this.canManageAssignments(team, adminGroupsList),
      canViewReports: this.canViewReports(team, adminGroupsList, reportAdminGroupsList),
    };
    return { teamPermissions, adminGroupsList, reportAdminGroupsList };
  }

  /* Set team or group ID to use in routes based on role across org and groups */
  setTeamGroupIds = (teamId) => {
    const team = this.getSpecificTeam(teamId);
    team.curriculaTeamGroupId = this.curriculaTeamGroupId(team);
    team.reportsTeamGroupId = this.reportsTeamGroupId(team);
    team.assignmentsTeamGroupId = this.assignmentsTeamGroupId(team);
    team.goalsTeamGroupId = this.getGoalsTeamGroupId(team);
  };

  // Enterprise tool permissions based on users role
  canManageAdmins(team) {
    if (team && (team.role === 'org-owner' || team.role === 'cybrary-admin')) {
      return true;
    }
    return false;
  }

  canManageTeam(team) {
    if (team && (team.role === 'team-admin' || team.role === 'cybrary-admin' || team.role === 'org-owner')) {
      return true;
    }
    return false;
  }

  canManageOnboarding(team) {
    return team && (team.role === 'cybrary-admin' || team.role === 'org-owner');
  }

  canManageGroup(team, adminGroupsList) {
    if ((team && (team.role === 'team-admin' || team.role === 'org-owner')) || adminGroupsList.length > 0) {
      return true;
    }
    return false;
  }

  canManageCurricula(team, adminGroupsList) {
    if (team && (team.role === 'team-admin' || team.role === 'org-owner')) {
      return 'all';
    }
    if (!!adminGroupsList && adminGroupsList.length > 0) {
      return 'group';
    }
    return null;
  }

  canManageAssignments(team, adminGroupsList) {
    if ((team && team.role === 'team-admin') || team.role === 'org-owner') {
      return 'all';
    }
    if (!!adminGroupsList && adminGroupsList.length > 0) {
      return 'group';
    }
    return null;
  }

  canViewReports(team, adminGroupsList, reportAdminGroupsList) {
    if (team && (team.role === 'team-admin' || team.role === 'team-reporting-admin' || team.role === 'org-owner')) {
      return 'all';
    }
    if (!!reportAdminGroupsList && (reportAdminGroupsList.length > 0 || adminGroupsList.length > 0)) {
      return 'group';
    }
    return null;
  }

  // Group or Org ID, based on permissions/roles, to use for curricula endpoints
  curriculaTeamGroupId(team) {
    const { permissions } = team;
    if (!permissions) {
      return null;
    }
    // If user is an Org level admin, always use the team ID (has all perms)
    if (permissions.canManageTeam) {
      return team.id;
    }
    if (permissions.canManageCurricula) {
      return team.preferredGroup;
    }
    return null;
  }

  // Group or Org ID, based on permissions/roles, to use for reports and dashboard endpoints
  reportsTeamGroupId = (team) => {
    const { permissions } = team;
    if (!permissions) {
      return null;
    }
    // If user is an Org level admin, always use the team ID (has all perms). If can view all reports, use team ID
    if (permissions.canManageTeam || permissions.canViewReports === 'all') {
      return team.id;
    }
    if (permissions.canViewReports === 'group') {
      return team.preferredGroup;
    }
    return null;
  };

  // Group or Org ID, based on permissions/roles, to use for assignment endpoints
  assignmentsTeamGroupId = (team) => {
    const { permissions } = team;
    if (!permissions) {
      return null;
    }
    // If user is an Org level admin or Org owner, always use the team ID (has all perms). If can manage all assignments, use team ID
    if (permissions.canManageTeam || permissions.canManageAssignments === 'all') {
      return team.id;
    }
    if (permissions.canManageAssignments === 'group') {
      return team.preferredGroup;
    }
    return null;
  };

  // Group or Org ID, based on permissions/roles. For Goals admin endpoints
  getGoalsTeamGroupId = (team) => {
    const { permissions } = team;
    if (!permissions) {
      return null;
    }

    // If user is an Org level admin or Org owner, always use the team ID (has all perms)
    if (permissions.canManageTeam || permissions.canManageGoals === 'all') {
      return team.id;
    }
    // If you can manage the goals at a group level, or view reports at group level, use group ID
    if (permissions.canManageGoals === 'group' || permissions.canViewReports === 'group') {
      return team.preferredGroup;
    }

    return null;
  };

  // Load and set team data based on provided team ID. Adds to user's team object and sets provided team as preferred
  loadAndSetTeamData(teamId, role, larping = false) {
    return Agents.auth
      .getTeamData(teamId)
      .then(
        action('fetchSuccess', (response) => {
          const userTeams = larping ? {} : { ...this.userTeams };
          // Now need to get team permissions and set back into store data
          const updatedTeamData = { ...response };
          const permissions = this.getTeamPermissions(updatedTeamData);
          updatedTeamData.permissions = permissions.teamPermissions;
          updatedTeamData.adminGroupsList = permissions.adminGroupsList;
          updatedTeamData.reportAdminGroupsList = permissions.reportAdminGroupsList;
          updatedTeamData.role = role || updatedTeamData.role;
          userTeams[teamId] = updatedTeamData;
          this.userTeams = userTeams;
          this.setTeam(updatedTeamData);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          return Promise.reject(error);
        })
      );
  }

  loadingUserPreferences = false;

  loadingCategories = false;

  loadingLevel = false;

  categoryIsSubmitting = false;

  levelIsSubmitting = false;

  categoryAndLevelAreSubmitting = false;

  categoryErrors = null;

  levelErrors = null;

  verticalAndSkillErrors = null;

  categoryData = null;

  levelData = null;

  originalCategoryData = null;

  originalLevelData = null;

  isPreferencesModalOpen = false;

  isEditablePreferences = false;

  setIsLoadingUserPreferences(loading) {
    this.loadingUserPreferences = loading;
  }

  setloadingCategories(loading) {
    this.loadingCategories = loading;
  }

  setloadingLevel(loading) {
    this.loadingLevel = loading;
  }

  get userPreferences() {
    return { categoryTerms: this.getCategoryData(), level: this.getLevelData() };
  }

  getCategoryPreferences() {
    if (this.categoryData == null) {
      return this.fetchCategoryPreferences().then(() => {
        return this.categoryData;
      });
    }
    return this.categoryData;
  }

  getLevelPreferences() {
    if (this.levelData == null) {
      return this.fetchLevelPreferences().then(() => {
        return this.level;
      });
    }
    return this.level;
  }

  getBookmarks() {
    if (this.bookmarks == null) {
      return this.fetchBookmarks().then(() => {
        return this.bookmarks;
      });
    }
    return this.bookmarks;
  }

  setCategoryErrors(categoryErrors) {
    this.categoryErrors = categoryErrors;
  }

  setLevelErrors(levelErrors) {
    this.levelErrors = levelErrors;
  }

  setVerticalAndSkillErrors(errors) {
    this.verticalAndSkillErrors = errors;
  }

  setCategoryData(data) {
    this.categoryData = data;
  }

  setLevelData(data) {
    this.levelData = data;
  }

  setOriginalCategoryData(data) {
    this.originalCategoryData = data;
  }

  setOriginalLevelData(data) {
    this.originalLevelData = data;
  }

  setCategoryIsSubmitting(loading) {
    this.categoryIsSubmitting = loading;
  }

  setLevelIsSubmitting(loading) {
    this.levelIsSubmitting = loading;
  }

  setCategoryAndLevelAreSubmitting(submitting) {
    this.categoryAndLevelAreSubmitting = submitting;
  }

  preferencesModalOpen() {
    this.isPreferencesModalOpen = true;
  }

  preferencesModalClose() {
    this.isPreferencesModalOpen = false;
  }

  setEditablePreferences(isEditablePreferences) {
    this.isEditablePreferences = isEditablePreferences;
  }

  clearVerticalAndSkillErrorsForKey(key) {
    if (this.verticalAndSkillErrors && this.verticalAndSkillErrors.errors && this.verticalAndSkillErrors.errors[key]) {
      this.verticalAndSkillErrors.errors[key] = undefined;
    }
  }

  submitCategoriesAndLevelForm() {
    this.setCategoryAndLevelAreSubmitting(true);
    const categoryData = { terms: this.categoryData };
    const levelData = { level: this.levelData.name };
    this.setLevelIsSubmitting(true);
    return Agents.auth
      .submitSkillForm(levelData)
      .then(
        action('submitSuccess', () => {
          this.setLevelIsSubmitting(false);
          this.fetchLevelPreferences();
        })
      )
      .then(() => {
        this.setCategoryIsSubmitting(true);
        return Agents.auth
          .submitVerticalForm(categoryData)
          .then(
            action('submitSuccess', () => {
              this.setCategoryIsSubmitting(false);
            })
          )
          .catch(
            action('submitError', (error) => {
              Bugsnag.notify(error);
              this.setCategoryIsSubmitting(false);
              this.fetchCategoryPreferences();
            })
          );
      })
      .catch(
        action('submitError', (error) => {
          Bugsnag.notify(error);
          this.setLevelIsSubmitting(false);
          this.setCategoryAndLevelAreSubmitting(false);
        })
      );
  }

  getCategoryAndLevelPreferences() {
    this.setIsLoadingUserPreferences(true);

    return Promise.all([this.getLevelPreferences(), this.getCategoryPreferences()]).then(() => {
      this.setIsLoadingUserPreferences(false);
    });
  }

  fetchCategoryPreferences() {
    this.setloadingCategories(true);
    return Agents.auth
      .getCategoryPreferences()
      .then(
        action('fetchSuccess', (response) => {
          this.setCategoryData(response);
          this.setOriginalCategoryData(response);
          this.setloadingCategories(false);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.setloadingCategories(false);
        })
      );
  }

  fetchLevelPreferences() {
    this.setloadingLevel(true);
    return Agents.auth
      .getLevelPreferences()
      .then(
        action('fetchSuccess', (response) => {
          this.setLevelData(response);
          this.setOriginalLevelData(response);
          this.setloadingLevel(false);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.setloadingLevel(false);
        })
      );
  }

  toggleBookmark(contentDescriptionId, title) {
    if (this.bookmarks.indexOf(contentDescriptionId) === -1) {
      this.bookmarks.push(contentDescriptionId);
      if (title) {
        GaUtil.fireEvent('Add Bookmark', 'Product Interaction', title);
      }
      return Agents.catalog.bookmark(contentDescriptionId);
    }
    this.bookmarks.splice(this.bookmarks.indexOf(contentDescriptionId), 1);
    if (title) {
      GaUtil.fireEvent('Remove Bookmark', 'Product Interaction', title);
    }
    return Agents.catalog.unbookmark(contentDescriptionId);
  }

  fetchBookmarks = () => {
    this.bookmarksLoading = true;
    return Agents.catalog
      .getBookmarks()
      .then(
        action('fetchSuccess', (response) => {
          this.bookmarks = response;
          this.bookmarksLoading = false;
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.bookmarksLoading = false;
        })
      );
  };

  fetchSubscriptionStatus() {
    return window.localStorage.getItem('newSubscription') ? JSON.parse(window.localStorage.getItem('newSubscription')) : false;
  }

  removeSubscriptionStatus() {
    window.localStorage.removeItem('newSubscription');
  }

  bootZendesk(onLoad) {
    const d = document;
    const s = d.createElement('script');
    s.id = 'ze-snippet';
    s.src = `https://static.zdassets.com/ekr/snippet.js?key=${process.env.REACT_APP_ZENDESK_APP_ID}`;
    s.async = 1;
    if (onLoad) {
      s.addEventListener('load', () => {
        onLoad();
      });
    }
    d.getElementsByTagName('head')[0].appendChild(s);
  }

  zendeskInit(checkHideZendesk) {
    if (window.location.hostname.match('localhost')) {
      return null;
    }
    const prefillData = {
      name: {
        value: this.user.real_name || this.user.name,
      },
      email: {
        value: this.user.email,
      },
    };
    // Get Zendesk JWT and pass that in for authenticating and identifying user
    return Agents.auth
      .getZendeskJWT()
      .then((zendeskJwt) => {
        // https://developer.zendesk.com/embeddables/docs/widget/settings#authenticate
        window.zESettings = {
          webWidget: {
            authenticate: {
              chat: {
                jwtFn: (callback) => {
                  callback(zendeskJwt);
                },
              },
            },
            contactForm: {
              ticketForms: [{ id: 360005551414 }],
            },
            zIndex: 99, // Override the default of 999998
          },
        };
        this.bootZendesk(() => {
          window.zE('webWidget', 'prefill', prefillData); // Prefill the "Leave a message" form, if users ever see it
          checkHideZendesk();
        });
      })
      .catch((error) => {
        Bugsnag.notify(error);
        window.zESettings = {
          webWidget: {
            contactForm: {
              ticketForms: [{ id: 360005551414 }],
            },
            zIndex: 99, // Override the default of 999998
          },
        };
        // Use user data to identify user instead
        const identifyData = { name: this.user.real_name || this.user.name, email: this.user.email };
        this.bootZendesk(() => {
          window.zE('webWidget', 'identify', identifyData); // Identify user in chat, since we weren't able to do so by JWT
          window.zE('webWidget', 'prefill', prefillData); // Prefill the "Leave a message" form, if users ever see it
          checkHideZendesk();
        });
      });
  }

  /* Content Preferences */
  contentPreferences = {
    certification: [],
    topic: [],
    loading: true,
    error: false,
  };

  setDefaultContentPreferences = () => {
    this.contentPreferences = {
      certification: [],
      topic: [],
      loading: true,
      error: false,
    };
  };

  getContentPreferences = () => {
    this.contentPreferences.error = false;
    return Agents.profile
      .getContentPreferences('?categories[]=Certification&categories[]=Topic&verified=1')
      .then(
        action('fetchSuccess', (response) => {
          const certifications = [];
          const topics = [];
          // Split out Certifications vs Topics (skills)
          if (response && response.length) {
            response.forEach((term) => {
              if (term.category === 'Certification') {
                certifications.push(term);
              } else {
                topics.push(term);
              }
            });
          }
          this.contentPreferences.loading = false;
          this.contentPreferences.certification = certifications;
          this.contentPreferences.topic = topics;
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.contentPreferences.loading = false;
          this.contentPreferences.error = error;
        })
      );
  };

  addContentPreference = (category, selection) => {
    // Copy the current content preferences in case request fails after optimistically updating
    const currCategoryPreferences = [...this.contentPreferences[category]];
    // Optimistic update - Add this term to the selected terms
    this.contentPreferences[category].push(selection);
    return Agents.profile.setContentPreferences({ terms: [selection.id] }).catch(
      action('fetchError', (error) => {
        Bugsnag.notify(error);
        // Undo optimistic update
        this.contentPreferences[category] = currCategoryPreferences;
      })
    );
  };

  deleteContentPreference = (category, selection) => {
    // Copy the current content preferences in case request fails after optimistically updating
    const currCategoryPreferences = [...this.contentPreferences[category]];
    // Optimistic update - Splice this term from the selected terms array
    let selectionIdx = null;
    for (let i = 0; i < this.contentPreferences[category].length; i++) {
      if (this.contentPreferences[category][i].id === selection.id) {
        selectionIdx = i;
        break;
      }
    }
    this.contentPreferences[category].splice(selectionIdx, 1);
    return Agents.profile.deleteContentPreferences({ terms: [selection.id] }).catch(
      action('fetchError', (error) => {
        Bugsnag.notify(error);
        // Undo optimistic update
        this.contentPreferences[category] = currCategoryPreferences;
      })
    );
  };

  isFirstWeekCancellation = (startDate) => {
    const formattedDate = moment.unix(startDate).utc();
    const todaysDate = moment().endOf('day');
    return todaysDate.diff(formattedDate, 'days') <= 7;
  };

  calculateDaysSinceRegistration = () => {
    const { registered_at: registeredAt } = this.user;
    const formattedDate = moment(registeredAt);
    const todaysDate = moment().endOf('day');
    return todaysDate.diff(formattedDate, 'days');
  };

  get isUserWithinWeekOfRegistration() {
    return this.calculateDaysSinceRegistration() <= 7;
  }

  getMonthlyFreeCourseEnrollmentDate = () => {
    return Cookies.get('monthly-free-course-enrollment') || null;
  };

  calculateDaysSinceFreeMonthlyEnrollment = () => {
    const freeCourseEnrollDate = this.getMonthlyFreeCourseEnrollmentDate();
    if (!freeCourseEnrollDate) {
      return -1;
    }
    const formattedDate = moment(freeCourseEnrollDate);
    const todaysDate = moment().endOf('day');
    return todaysDate.diff(formattedDate, 'days');
  };

  get isUserEnrolledInFreeMonthlyCourseLastWeek() {
    const daysSinceFreeMonthlyEnrollment = this.calculateDaysSinceFreeMonthlyEnrollment();
    return daysSinceFreeMonthlyEnrollment > -1 && daysSinceFreeMonthlyEnrollment < 8;
  }

  calculateWeekFromDate = (date) => {
    if (!date) {
      return '';
    }
    const formattedDate = moment(date);
    const weekFromDate = formattedDate.add(1, 'week').endOf('day');
    const todaysDate = moment().endOf('day');
    if (todaysDate.isSame(weekFromDate)) {
      return 'today!';
    }
    return weekFromDate.format('MM/DD');
  };

  enterDemo = () => {
    this.setTeam(DEMO_TEAM_DATA);
  };

  exitDemo = () => {
    // reset user's team if we are in a demo
    if (this.team.id === 'demo') {
      const firstTeamId = this.userTeams ? Object.keys(this.userTeams)[0] : null;
      const firstTeam = firstTeamId ? this.userTeams[firstTeamId] : {};
      this.setTeam({ ...firstTeam });
    }
  };

  setIsUserProfileStatsLoading = (loading) => {
    this.isUserProfileStatsLoading = loading;
  };
}

export default new UserStore();
