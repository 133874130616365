import React, { useMemo } from 'react';
import { ClockIcon } from '@heroicons/react/outline';
import { CircularProgressBar } from '@tomik23/react-circular-progress-bar';
import moment from 'moment';
import { convertSecsToHHMMSS } from '../../../Clab/utils/labUtil';

export default function AssessmentScoreSummary({ score = 0, timeInSeconds, submissionTimestamp }) {
  const percent = useMemo(() => Math.round(score * 100), [score]);
  const time = useMemo(() => convertSecsToHHMMSS(timeInSeconds), [timeInSeconds]);
  const parsedSubmissionTimestamp = useMemo(() => moment(submissionTimestamp), [submissionTimestamp]);
  const circleProps = useMemo(
    () => ({
      percent: percent > 100 ? 100 : percent,
      colorSlice: '#ffffff',
      colorCircle: '#cccccc',
      // fontColor: '#365b74',
      // fontSize: '1.6rem',
      // fontWeight: 400,
      size: 160,
      stroke: 8,
      strokeBottom: 8,
      speed: 120,
      // cut: 0,
      // rotation: 127,
      // rotation: 180,
      fill: 'transparent',
      unit: '',
      // textPosition: '0.35em',
      animationOff: false,
      // strokeDasharray: '10,1',
      inverse: false,
      round: true,
      number: false,
      // cut: 20,
      // Choose the gradient you want for the line
      // Pink gradient
      linearGradient: ['#fc58ae', '#cf287f'],
      // Green graident
      // linearGradient: ['#51d135', '#84fb5e'],

      // styles: {
      //   borderRadius: '50%',
      //   boxShadow: 'inset 0 0 25px 10px #a2caff'
      // }
    }),
    [percent]
  );

  return (
    <div className="flex flex-row justify-center items-center">
      <div className="relative">
        <CircularProgressBar {...circleProps}>
          <div className="flex absolute top-0 left-0 justify-center items-center w-full h-full">
            <p className="text-xl font-semibold text-center ">{percent}%</p>
          </div>
        </CircularProgressBar>
      </div>
      <div className="pl-8">
        <p className="mb-3 text-neutral-500 text-md">
          <ClockIcon className="inline -mt-1 mr-2 w-5 h-5" />
          {time}
        </p>
        <p className="text-sm text-neutral-600">
          Submitted {parsedSubmissionTimestamp.format('MM-DD-YYYY')} at {parsedSubmissionTimestamp.format('hh:mm A')}
        </p>
      </div>
    </div>
  );
}
