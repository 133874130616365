import React, { useCallback, useEffect, useMemo, memo } from 'react';
import Container from '../Container/Container';
import Loading from '../Loading/Loading';
import StyledError from '../Error/StyledError';
import AdminTable from '../AdminTable/AdminTable';
import AddLink from '../AddLink/AddLink';
import ValidationUtil from '../../utils/validationUtil';
import ProgressLabel from '../Enterprise/ProgressLabel';
import FormatUtil from '../../utils/formatUtil';
import MetricsSettings from '../Analytics/MetricsSettings';
import PercentageBar from '../Analytics/PercentageBar';
import { useCybAssessmentPath } from '../../providers/CybAssessmentPathProvider';
import { useCybAssessmentPathContent } from '../../providers/CybAssessmentPathContentProvider';

/**
 * Custom Score Column Label
 * @param {TableRow} row
 * @param {string} toHref
 * @returns JSX.Element
 */
function ScoreLabel({ row, toHref }) {
  if (row.value === null) {
    return '-';
  }
  const proficiencyLevel = FormatUtil.getProficiencyLevel(row.value);
  const { color } = MetricsSettings.competencyLevels[proficiencyLevel];
  return (
    <span style={{ position: 'relative' }}>
      {proficiencyLevel === 'Proficient' ? <div className="print:hidden pdf-print proficient-badge" /> : null}
      <div className="hidden print:block">{row.value}%</div>
      <div className="print:hidden pdf-print">
        <PercentageBar value={`${row.value}%`} key={row.value} color={color} maxHeight="2em" width={`${row.value}%`} toHref={toHref} />
      </div>
    </span>
  );
}

/**
 * `AssessmentPathLearners` - content for `overview` tab in `AssessmentPathResults` abd `AssessmentResults` pages.
 * Used in `AssessmentPathTabs`.
 * @param {string} orgId - team/organization id
 * @param {boolean} asAssessment - component to be used for `AssessmentResults` page
 * @returns JSX.Element
 */
function AssessmentPathLearners({ orgId, asAssessment }) {
  const { currentAssessmentPath, currentLearners, isLoadingLearners, learnersError, actions } = useCybAssessmentPath();

  const {
    currentAssessment,
    currentAssessmentLearners,
    isLoadingAssessmentLearners,
    assessmentLearnersError,
    actions: assessmentPathContentActions,
  } = useCybAssessmentPathContent();

  const learners = asAssessment ? currentAssessmentLearners : currentLearners;

  const isLoading = asAssessment ? isLoadingAssessmentLearners : isLoadingLearners;

  const error = asAssessment ? assessmentLearnersError : learnersError;

  const renderColumn = useCallback(
    (row, fullRow, headings, column) => {
      if (row.value && column.showCol) {
        if (column.key === 'progress') {
          return <ProgressLabel progressNum={row.value} />;
        }

        const isHighlightedRow = fullRow.find((item) => item.highlighted_row);

        const percentage = Math.round(parseFloat(row.value));

        if (isHighlightedRow) {
          return `${percentage}%`;
        }

        let assesmentCourseId = '';
        let learnerId = '';
        let learnerName = '';
        let toHref = '';

        // set learner id
        const learnerIdIndex = headings.findIndex((item) => item.key === 'user_id');

        if (learnerIdIndex >= 0) {
          learnerId = fullRow[learnerIdIndex].value;
        }

        // set learner name
        const learnerNameIndex = headings.findIndex((item) => item.key === 'real_name');

        if (learnerNameIndex >= 0) {
          learnerName = fullRow[learnerNameIndex].value;
        }

        if (asAssessment) {
          assesmentCourseId = currentAssessment?.id;

          toHref = `${window.location.pathname}/summary?learnerId=${learnerId}&learnerName=${learnerName}`;
        } else {
          const segments = column.key.split('_');
          assesmentCourseId = segments[segments.length - 1];
          toHref = `${window.location.pathname}/${assesmentCourseId}/summary?learnerId=${learnerId}&learnerName=${learnerName}`;
        }

        if (column.key === 'score') {
          return <ScoreLabel row={row} toHref={toHref} />;
        }

        const isValidNumericCourseId = ValidationUtil.isValidNumber(assesmentCourseId);

        if (isValidNumericCourseId) {
          return (
            <AddLink className="text-cyb-pink-500 underline" to={toHref}>
              {percentage}%
            </AddLink>
          );
        }

        return `${percentage}%`;
      }

      return '--';
    },
    [asAssessment, currentAssessmentPath, currentAssessment, window.location.pathname]
  );

  const formatColumns = useMemo(() => {
    const validIndexes = [];
    const cols = [];

    if (learners) {
      learners.table.tableData.forEach((innerArray) => {
        innerArray.forEach((entry, index) => {
          if (asAssessment) {
            const columnKey = learners.table.columns[index].key;
            if (columnKey === 'score' || columnKey === 'progress') {
              validIndexes.push(index);
            }
          } else if (ValidationUtil.isValidNumber(entry.value)) {
            validIndexes.push(index);
          }
        });
      });

      const validIndexesDeduplicated = [...new Set(validIndexes)];

      validIndexesDeduplicated.forEach((validIndex) => {
        const entry = {
          method: (row, fullRow, headings) => renderColumn(row, fullRow, headings, headings[validIndex]),
          colIdx: validIndex,
        };

        cols.push(entry);
      });
    }

    return cols;
  }, [learners, asAssessment, renderColumn]);

  const tableData = useMemo(() => {
    if (!learners) {
      return null;
    }

    if (asAssessment) {
      const learningHoursColIndex = learners.table.columns.findIndex((col) => col.key === 'learning_hours');

      if (learningHoursColIndex >= 0) {
        // hide learning hours column
        learners.table.columns[learningHoursColIndex].showCol = false;
      }

      return learners.table.tableData;
    }

    // find index of `Totals` row
    const index = learners.table.tableData.findIndex((rowArray) => rowArray.find((item) => item.value === 'Totals'));

    if (index < 0) {
      return learners.table.tableData;
    }

    // mark row as `highlighted_row`
    const highlightedRowEntry = {
      highlighted_row: true,
    };
    learners.table.tableData[index].push(highlightedRowEntry);

    if (index === learners.table.tableData.length - 1) {
      const totalsEntryIndex = learners.table.tableData[index].findIndex((item) => item.value === 'Totals');

      if (totalsEntryIndex > -1) {
        // Update the value to `Average`
        learners.table.tableData[index][totalsEntryIndex].value = 'Average';
      }

      return learners.table.tableData;
    }

    // Remove the `Totals` row at the specified index
    const row = learners.table.tableData.splice(index, 1)[0];

    // Update the value to `Average`
    row[1].value = 'Average';
    // Push the removed `Totals` row to the end of the array
    learners.table.tableData.push(row);

    return learners.table.tableData;
  }, [learners, asAssessment]);

  const ignoredCols = useMemo(() => {
    const value = [];

    if (tableData) {
      // get the totalsRow, which is the last row
      const totalsRow = tableData[tableData.length - 1];
      // find the index of the `highlighted_row` entry for that row
      const highlightedRowEntryIndex = totalsRow?.findIndex((item) => item?.highlighted_row);

      if (highlightedRowEntryIndex >= 0) {
        value.push(highlightedRowEntryIndex);
      }
    }
    return value;
  }, [tableData]);

  useEffect(() => {
    if (asAssessment) {
      assessmentPathContentActions.getAssessmentLearners(orgId);
    } else {
      actions.getLearners(orgId);
    }
  }, [orgId]);

  if (isLoading) {
    return (
      <Container>
        <Loading message="Loading..." />
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <StyledError error={error} />
      </Container>
    );
  }

  if (!learners) {
    return null;
  }

  return (
    <Container size="fluid" className="pt-0 mt-8 w-full" omitPadding>
      <AdminTable
        wrapperClassName="m-0"
        colClasses="whitespace-nowrap"
        headings={learners.table.columns}
        data={tableData}
        formatColumns={formatColumns}
        noResultsMsg={<p className="p-4">No data to display during this time period</p>}
        ignoredCols={ignoredCols}
      />
    </Container>
  );
}

export default memo(AssessmentPathLearners);
