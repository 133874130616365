import React from 'react';

export default function Notebook({ classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...accessibilityProps}>
      <g id="outline">
        <path d="M136,120H312a8,8,0,0,0,0-16H136a8,8,0,0,0,0,16Z" />
        <path d="M136,168H312a8,8,0,0,0,0-16H136a8,8,0,0,0,0,16Z" />
        <path d="M136,216H312a8,8,0,0,0,0-16H136a8,8,0,0,0,0,16Z" />
        <path d="M136,264H312a8,8,0,0,0,0-16H136a8,8,0,0,0,0,16Z" />
        <path d="M136,312h64a8,8,0,0,0,0-16H136a8,8,0,0,0,0,16Z" />
        <path d="M452.132,205.015a24.024,24.024,0,0,0-32.841,8.571L400,246.5V48a8,8,0,0,0-8-8H331.314s-24.038-24.48-25-23.656A7.976,7.976,0,0,0,304,16H72a8,8,0,0,0-8,8V49.013a32,32,0,0,0,0,61.974v18.026a32,32,0,0,0,0,61.974v18.026a32,32,0,0,0,0,61.974v10.026a32,32,0,0,0,0,61.974V392a8,8,0,0,0,8,8h8v24a8,8,0,0,0,8,8H291.292l-5.482,9.354a7.938,7.938,0,0,0-.981,2.721c-.008.046-.021.085-.028.132l-.02.131,0,.03-6.4,42.44a8,8,0,0,0,12.817,7.511L325.226,467.9c2.18-1.693,3.245-2.729,3.076-4.119L346.926,432H392a8,8,0,0,0,8-8V341.438l60.7-103.583A24.027,24.027,0,0,0,452.132,205.015ZM437.977,217.98a8,8,0,0,1,8.922,11.786l-4.045,6.9-13.8-8.09,4.046-6.9A7.944,7.944,0,0,1,437.977,217.98ZM317.462,450.632l-13.8-8.09L408.826,263.089l13.8,8.09ZM312,43.314,340.686,72H312Zm104.916,205.97,4.044-6.9,13.805,8.09-4.045,6.9ZM384,56V272a8.041,8.041,0,0,0,.152,1.546L368,301.108V80a7.978,7.978,0,0,0-2.336-5.649l-.007-.008L347.314,56ZM80,32H296V80a8,8,0,0,0,8,8h48V328.41L319.422,384H80V342.988a31.974,31.974,0,0,0,22.991-22.994,8,8,0,0,0-15.5-3.988A15.948,15.948,0,0,1,80,325.85V270.988a31.974,31.974,0,0,0,22.991-22.994,8,8,0,0,0-15.5-3.988A15.948,15.948,0,0,1,80,253.85V190.988a31.974,31.974,0,0,0,22.991-22.994,8,8,0,0,0-15.5-3.988A15.948,15.948,0,0,1,80,173.85V110.988a31.974,31.974,0,0,0,22.991-22.994,8,8,0,0,0-15.5-3.988A15.948,15.948,0,0,1,80,93.85ZM56,80a16,16,0,0,1,8-13.835v27.67A16,16,0,0,1,56,80Zm0,80a16,16,0,0,1,8-13.835v27.67A16,16,0,0,1,56,160Zm0,80a16,16,0,0,1,8-13.835v27.67A16,16,0,0,1,56,240Zm0,72a16,16,0,0,1,8-13.835v27.67A16,16,0,0,1,56,312ZM96,416V400H310.045l-9.377,16Zm202.861,42.276,7.219,4.231-8.9,6.908ZM384,416H356.3L384,368.739Z" />
      </g>
    </svg>
  );
}
