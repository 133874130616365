import React from 'react';

export default function Rocket({ classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';
  return (
    <svg className={className} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...accessibilityProps}>
      <path
        d="M31.9623 2.38899C31.893 1.11786 30.882 0.107052 29.6109 0.0376769C27.046 -0.102448 22.027 0.0338645 17.2278 2.51618C14.7812 3.78168 12.1937 
        5.92724 10.1288 8.40274C10.1033 8.43324 10.0786 8.46393 10.0533 8.49449L5.36708 8.85649C4.59571 8.91611 3.90865 9.32043 3.48202 9.96568L0.284896 
        14.8014C-0.038666 15.2908 -0.0901035 15.9032 0.147209 16.4397C0.384584 16.9762 0.872334 17.3501 1.45202 17.4399L5.40021 18.0514C5.38627 18.1281 
        5.37227 18.2048 5.35946 18.2816C5.22802 19.0678 5.48852 19.8762 6.0564 20.444L11.5559 25.9436C12.0267 26.4144 12.6629 26.6739 13.3148 
        26.6739C13.449 26.6739 13.584 26.6629 13.7184 26.6404C13.7951 26.6276 13.8718 26.6136 13.9486 26.5997L14.5601 30.5479C14.6499 31.1276 15.0238 
        31.6154 15.5601 31.8527C15.7833 31.9515 16.0195 32.0002 16.2546 32.0002C16.5848 32.0002 16.9127 31.9041 17.1985 31.7151L22.0342 28.5179C22.6795 
        28.0912 23.0838 27.4042 23.1433 26.6329L23.5053 21.9467C23.5358 21.9214 23.5666 21.8967 23.5971 21.8712C26.0726 19.8063 28.2183 17.2188 29.4837 
        14.7722C31.9661 9.97274 32.1022 4.95368 31.9623 2.38899ZM21.0001 26.9538L16.3745 30.0121L15.7785 26.1643C17.7335 25.5895 19.6674 24.6689 
        21.5098 23.4356L21.274 26.4884C21.2593 26.6788 21.1595 26.8485 21.0001 26.9538ZM12.8817 24.6177L7.38215 19.1182C7.24183 18.9778 7.17696 18.7806 
        7.20877 18.5907C7.37227 17.613 7.62633 16.6823 7.94083 15.8056L16.1926 24.0573C15.1396 24.4345 14.1964 24.6595 13.4092 24.7911C13.219 24.8227 
        13.022 24.7581 12.8817 24.6177ZM5.51152 10.7259L8.56427 10.4901C7.3309 12.3326 6.41027 14.2665 5.83546 16.2215L1.98771 15.6256L5.04608 
        10.9998C5.15146 10.8404 5.32115 10.7406 5.51152 10.7259ZM22.3962 20.4313C20.8816 21.6946 19.4115 22.6084 18.0563 23.2696L8.73002 13.9432C9.59615 
        12.1742 10.6532 10.7012 11.5686 9.6038C13.4795 7.31286 15.8562 5.33655 18.0893 4.18155C22.4939 1.90324 27.1346 1.78055 29.5088 1.9098C29.8231 
        1.92693 30.073 2.17686 30.0901 2.49118C30.2196 4.86543 30.0966 9.50605 27.8183 13.9107C26.6634 16.1437 24.6871 18.5204 22.3962 20.4313Z"
      />
      <path
        d="M21.9204 14.7653C23.1205 14.7652 24.3212 14.3083 25.2348 13.3946C26.1202 12.5093 26.6077 11.3322 26.6077 10.0802C26.6077 8.82818 26.1202 
        7.65105 25.2348 6.76574C23.4072 4.93805 20.4335 4.93818 18.606 6.76574C17.7206 7.65105 17.233 8.82818 17.233 10.0802C17.233 11.3322 17.7207 
        12.5093 18.606 13.3946C19.5198 14.3085 20.7198 14.7654 21.9204 14.7653ZM19.9317 8.09149C20.4799 7.54318 21.2001 7.26905 21.9203 7.26905C22.6405 
        7.26905 23.3607 7.54318 23.909 8.09149C24.4402 8.62268 24.7327 9.32893 24.7327 10.0801C24.7327 10.8313 24.4402 11.5376 23.909 12.0688C22.8125 
        13.1654 21.0282 13.1653 19.9316 12.0688C19.4004 11.5376 19.1078 10.8314 19.1078 10.0802C19.1078 9.32899 19.4005 8.62268 19.9317 8.09149Z"
      />
      <path
        d="M0.956023 26.3708C1.19596 26.3708 1.4359 26.2793 1.6189 26.0962L4.67971 23.0353C5.04584 22.6692 5.04584 22.0757 4.67971 21.7095C4.31365 
        21.3434 3.72002 21.3434 3.3539 21.7095L0.293148 24.7703C-0.0729766 25.1365 -0.0729766 25.73 0.293148 26.0962C0.476148 26.2792 0.716086 26.3708 
        0.956023 26.3708Z"
      />
      <path
        d="M7.48466 24.5148C7.1186 24.1487 6.52498 24.1487 6.15885 24.5148L0.274228 30.3995C-0.0918975 30.7656 -0.0918975 31.3592 0.274228 31.7253C0.45729 
        31.9084 0.697165 31.9999 0.937103 31.9999C1.17704 31.9999 1.41698 31.9084 1.59998 31.7253L7.4846 25.8406C7.85079 25.4745 7.85079 24.881 7.48466 
        24.5148Z"
      />
      <path
        d="M8.96431 27.32L5.9035 30.3808C5.53738 30.7469 5.53738 31.3405 5.9035 31.7066C6.08656 31.8897 6.3265 31.9813 6.56637 31.9813C6.80625 31.9813 
        7.04625 31.8898 7.22925 31.7066L10.2901 28.6458C10.6562 28.2797 10.6562 27.6861 10.2901 27.32C9.92399 26.9539 9.33037 26.9539 8.96431 27.32Z"
      />
    </svg>
  );
}
