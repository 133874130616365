import React from 'react';
import { observer } from 'mobx-react';
import Icon from '../Icon/Icon';
import FormatUtil from '../../utils/formatUtil';
import OptionsMenu from '../OptionsMenu/OptionsMenu';
import CreateGroup from './CreateGroup';
import AvatarsList from '../Avatar/AvatarsList';
import Tooltip from '../Tooltip/Tooltip';
import Modal from '../Modal/Modal';

export default observer(
  class TeamHierarchyGroup extends React.Component {
    state = {
      createGroupModalOpen: false,
    };

    /**
     * Toggle create group modal
     */
    handleCreateGroupModal = (state) => {
      const newState = {
        ...this.state,
        createGroupModalOpen: state,
      };
      this.setState(newState);
    };

    /**
     * Callback after group is created to refresh data and close modal
     */
    createGroupCallback = () => {
      this.props.refreshData();
      this.handleCreateGroupModal(false);
    };

    /**
     * Get edit dropdown items based on conditions
     */
    getEditDropdownItems = () => {
      const { group, isSubgroup, deleteGroup, getTeamInfo, canManageGroup } = this.props;
      const options = [
        {
          text: 'View Group Info',
          action: () => getTeamInfo(group.parent_id, group.id, group.name, group.parentName, !!isSubgroup, canManageGroup),
        },
        {
          text: 'Delete Group',
          action: () => deleteGroup(group.parent_id, group.id, isSubgroup, group.name, group.parentName),
        },
      ];
      if (!isSubgroup) {
        options.splice(1, 0, { text: 'Add Sub-Group', action: () => this.handleCreateGroupModal(true) });
      }
      return options;
    };

    render() {
      const { group, toggleCollapsible, columnsConfig, isSubgroup, getTeamInfo, canManageGroup } = this.props;
      const hasChildren = group.children && group.children.length;
      const childrenClass = `sub-group-column ${hasChildren ? 'has-children' : ''}`;
      const rowClass = !canManageGroup ? 'text-gray-600' : '';
      return (
        <div className={`grid grid-cols-12 text-sm items-center groups-row ${rowClass}`}>
          <div className={`group-name-column text-${columnsConfig.name.alignment || 'left'} col-span-${columnsConfig.name.width} ${canManageGroup ? 'clickable' : ''}`}>
            <Tooltip
              disabled={!!canManageGroup}
              triggerContent={
                <button
                  className={!canManageGroup ? 'cursor-not-allowed' : ''}
                  onClick={canManageGroup ? () => getTeamInfo(group.parent_id, group.id, group.name, group.parentName, !!isSubgroup, canManageGroup) : null}
                >
                  {group.name}
                </button>
              }
              content="Only admins of this group can view it"
              omitTabIndex
            />
          </div>
          {!isSubgroup && (
            <div className={`text-${columnsConfig.children.alignment || 'left'} col-span-${columnsConfig.children.width}`}>
              <button className={childrenClass} onClick={() => toggleCollapsible(group.id)}>
                <Icon name="sitemap" className="inline-block mr-2 mb-1 w-4 h-4" />
                {(group.children && group.children.length) || null}
              </button>
            </div>
          )}
          <div className={`text-${columnsConfig.admins.alignment || 'left'} col-span-${columnsConfig.admins.width}`}>
            <AvatarsList data={group.admins.slice()} />
          </div>
          <div className={`text-${columnsConfig.member_count.alignment || 'left'} col-span-${columnsConfig.member_count.width}`}>{group.member_count}</div>
          <div className={`text-${columnsConfig.created_at.alignment || 'left'} col-span-${columnsConfig.created_at.width}`}>
            {FormatUtil.convertUtcToLocal(group.created_at).format('M/D/YYYY')}
          </div>
          <div className={`text-${columnsConfig.actions.alignment || 'left'} col-span-${columnsConfig.actions.width} pr-6`}>
            {canManageGroup && <OptionsMenu menuClasses="right-12 top-0" options={this.getEditDropdownItems()} />}
            <Modal position="center" paddingBottom="pb-0" size="sm" open={this.state.createGroupModalOpen} toggle={() => this.handleCreateGroupModal(false)} ariaLabel="Add Group">
              <div className="p-4 text-left">
                <h2 className="text-2xl font-black">Add Group</h2>
                <CreateGroup teamId={group.id} view="add" callback={this.createGroupCallback} />
              </div>
            </Modal>
          </div>
        </div>
      );
    }
  }
);
