import React from 'react';
import { inject, observer } from 'mobx-react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/UseAuth';
import { TEAMS_DASHBOARD_ACCESS_ROLES } from '../constants';

// This is our primary middleware.  It will check any conditions that need to be satisfied before a private route can be rendered
// Conditions such as: are you logged in?  Are you verified? Have you been through onboarding? Etc.
// The component will either render the desired route, or redirect the user to the appropriate place.

/**
 * Gets all the IDs of the teams that the user has access to the team dashboard for
 * Who has access to the team dashboard?
 * - users with role of... team-reporting-admin, team-admin, org-owner OR team-member that is a group admin or group reporting admin
 *
 * @params {Object} userTeams - userStore.userTeams
 * @returns {Array} - Array of teamIds that the user has access to the team dashboard
 */
const getAllowedTeamIds = (userTeams = {}) => {
  if (!userTeams) return [];
  const dashboardAccessRoles = Object.keys(userTeams).filter((teamId) => TEAMS_DASHBOARD_ACCESS_ROLES.includes(userTeams[teamId]?.role));
  const groupAdminRoles = Object.keys(userTeams).filter((teamId) => userTeams[teamId].adminGroupsList?.length);
  const groupReportingAdminRoles = Object.keys(userTeams).filter((teamId) => userTeams[teamId].reportAdminGroupsList?.length);
  return [...dashboardAccessRoles, ...groupAdminRoles, ...groupReportingAdminRoles];
};

const PrivateRoute = inject(
  'userStore',
  'authStore'
)(
  observer(({ userStore, authStore, children }) => {
    const auth = useAuth();
    const { user, userTeams } = userStore;

    // Redirects based on user data and current location
    const checkUserRedirect = (location) => {
      /**
       * TOS redirect - If user hasn't accepted TOS, redirect them to do so before they can do anything
       */
      const tosWhiteList = /terms-of-service|team-join/;
      if (user && !user.terms_accepted_at && !tosWhiteList.test(location.pathname)) {
        return `/onboarding/terms-of-service`;
      }

      /**
       * Teams redirect - If a user is not a part of the team they are trying to view, navigate them away
       */
      const allowedTeamIds = getAllowedTeamIds(userTeams);
      const teamIdRegex = /\/enterprise\/([A-Z,a-z,\d]{1,9})/g;
      const isRequestLicensePage = location.pathname.endsWith('/request-license');
      const teamIdMatches = location?.pathname?.match(teamIdRegex); // ['/enterprise/227']
      if (teamIdMatches?.length) {
        const teamId = teamIdMatches[0].replace('/enterprise/', ''); // ['/enterprise/227'] -> '227'
        if (!allowedTeamIds.includes(teamId) && teamId !== 'demo' && !isRequestLicensePage) {
          // navigate user to the first allowed team page, or homepage if free.
          if (allowedTeamIds[0]) {
            return `/enterprise/${allowedTeamIds[0]}`;
          }
          return `/`;
        }
      }

      /**
       * Incomplete Google Marketplace Registration redirect
       */
      const googleMarketplaceId = user?.googleMarketplaceId;
      const googleMarketplaceRegistrationUrl = '/google-marketplace-onboarding';
      // redirect to google marketplace onboarding page IF
      if (
        googleMarketplaceId && // user has a google marketplace checkout id on their account
        allowedTeamIds.length === 0 && // user is not a part of any teams
        location.pathname !== googleMarketplaceRegistrationUrl // user is not already on the google marketplace registration page
      ) {
        return googleMarketplaceRegistrationUrl;
      }

      return null;
    };

    // If we don't have user data, or a jwt, sign the user out immediately
    if (!userStore.user || !authStore.token) {
      /**
       * After discussing this with Marie, we are going to disable this redirect
       * // If we're on a browse page, Redirect to the same url, minus /browse, and on www
       * if (/browse\/(course|skill-certification-course|career-path)\//.test(window.location.pathname)) {
       *   window.location.href = process.env.REACT_APP_V2_SITE_URL + window.location.pathname.replace('/browse/', '/');
       * } else {
       *   auth.signout();
       * }
       */
      auth.signout();
      return null;
    }

    // Either returns the desired route (aka children), OR a redirect to the login route!
    // The logic in the render function can be modified to redirect to various different locations (email verification route, onboarding, etc)
    const location = useLocation();

    const redirectUserTo = checkUserRedirect(location);
    if (redirectUserTo) {
      return (
        <Navigate
          to={{
            pathname: redirectUserTo,
            state: {
              from: location,
            },
          }}
        />
      );
    }
    return children;
  })
);

export default PrivateRoute;
