import { useState, useEffect } from 'react';
import Bugsnag from '@bugsnag/js';
import agents from '../agents/agents';

/**
 * This hook is used in the public profile page to manage the fetching of data
 * To change profiles, simply call setUsername with the new username to load.
 *
 * Loading happens from the following endpoi
 * @param {string} initUsername - The username to load data for
 * @returns {object} - An object containing the state of the data
 */
function usePublicProfileData(initUsername) {
  const [username, setUsername] = useState(initUsername); // the username to load data for
  const [isPrivate, setIsPrivate] = useState(false); // whether or not the profile is private. True when profile doesn't exist or is private

  // loading states
  const [isLoading, setIsLoading] = useState(true); // overall loading state
  const [isProfileDataLoading, setIsProfileDataLoading] = useState(true);
  const [isStatsDataLoading, setIsStatsDataLoading] = useState(true);
  const [isCertificatesDataLoading, setIsCertificatesDataLoading] = useState(true);

  // error state
  const [error, setError] = useState(null);

  // data states
  const [personalData, setPersonalData] = useState({});
  const [certificationsData, setCertificationsData] = useState({});
  const [profileStats, setProfileStats] = useState({});
  const [certificatesData, setCertificatesData] = useState({});
  const [badges, setBadges] = useState([]);

  /**
   * Handle errors from fetching data by sending them to Bugsnag and setting them to state
   * @param {Error} fetchError
   */
  const handleBugsnagError = (fetchError) => {
    // 403 is expected if the user does not exist or has a private profile
    if (fetchError?.response?.status !== 403) {
      // URL's will vary, so we need to use a static context for error events
      Bugsnag.notify(fetchError, (errorEvent) => {
        const { errorMessage } = errorEvent.errors[0];
        // eslint-disable-next-line no-param-reassign
        errorEvent.context = `usePublicProfileData: ${errorMessage}`;
        errorEvent.addMetadata('usePublicProfileData', {
          username,
        });
      });
    }
  };

  // handle the fetching and setting of personal data
  const loadPublicProfileData = async () => {
    try {
      const response = await agents.profile.getPublicProfile(username);
      setPersonalData(response?.personal || {});
      setCertificationsData(response?.certifications || {});
      setBadges(response?.badges || []);
      return response;
    } catch (fetchError) {
      handleBugsnagError(fetchError);
      throw fetchError;
    } finally {
      setIsProfileDataLoading(false);
    }
  };

  // handle the fetching and setting of profile stats
  const loadPublicProfileStats = async () => {
    try {
      const response = await agents.profile.getPublicProfileStats(username);
      setProfileStats(response);
      return response;
    } catch (fetchError) {
      handleBugsnagError(fetchError);
      throw fetchError;
    } finally {
      setIsStatsDataLoading(false);
    }
  };

  // handle the fetching and setting of certificates data
  const loadPublicCertificatesData = async () => {
    try {
      const response = await agents.certificate.getPublicCertificates(username);
      setCertificatesData(response);
      return response;
    } catch (fetchError) {
      handleBugsnagError(fetchError);
      throw fetchError;
    } finally {
      setIsCertificatesDataLoading(false);
    }
  };

  // reload data when username changes
  useEffect(() => {
    // set loading state
    setIsLoading(true);
    setIsProfileDataLoading(true);
    setIsStatsDataLoading(true);
    setIsCertificatesDataLoading(true);

    // reset error state
    setError(null);

    // reset data state
    setPersonalData({});
    setCertificationsData({});
    setProfileStats({});
    setCertificatesData({});
    setBadges([]);

    // load data
    try {
      const requests = [loadPublicProfileData, loadPublicProfileStats, loadPublicCertificatesData];
      const promises = requests.map((request) => request());
      Promise.allSettled(promises).then((response) => {
        // Enable profile only if all request successfully loaded
        // This ensures we have valid profile for the username that is set to public.
        setIsPrivate(response.some((res) => res.status === 'rejected'));
      });
    } catch (fetchError) {
      handleBugsnagError(fetchError);
    } finally {
      setIsLoading(false);
    }
  }, [username]);

  return {
    isLoading,
    isCertificatesDataLoading,
    isProfileDataLoading,
    isStatsDataLoading,
    isPrivate,
    error,
    username,
    setUsername,
    personalData,
    certificationsData,
    profileStats,
    certificatesData,
    badges,
  };
}
export default usePublicProfileData;
