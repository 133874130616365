import React from 'react';

export default function CompletionCheck({ classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-[26px] h-[28px]';
  return (
    <svg className={className} viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...accessibilityProps}>
      <path d="M2 16.4L8.32195 26L24.1268 2" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
