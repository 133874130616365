import React from 'react';
import WidgetContainer from '../Container/WidgetContainer';
import Card from '../Card/Card';
import Header from '../Header/Header';
import Image from '../Image/Image';
import If from '../If/If';
import LoadingCard from './LoadingCard';
import LockedContainer from '../Container/LockedContainer';
import { useABM } from '../../providers/ABMProvider';
import AddLink from '../AddLink/AddLink';

/**
 * Top Badges Earned Widget for ABM Page
 * @returns {JSX} - The Top Badges Earned Widget
 */
function TopBadgesEarnedWidget() {
  const { isABMTeamLoading, isVerified, company } = useABM();
  const { top_badges: topBadges } = company || {};

  return (
    <LockedContainer msg="Verify your company email to unlock" isLocked={!isVerified && !isABMTeamLoading} isFullWidth>
      <WidgetContainer className="h-fit">
        <Header as="h2" className="mb-4">
          Top Badges Earned
        </Header>
        {/* Loading State */}
        <If condition={isABMTeamLoading}>
          <LoadingCard />
          <LoadingCard />
          <LoadingCard />
        </If>
        {/* Empty State */}
        <If condition={!topBadges?.length && !isABMTeamLoading}>
          <AddLink to="/profile?tab=badges">
            <Card>
              <p>Your team has not yet earned any badges!</p>
            </Card>
          </AddLink>
        </If>
        {/* Default State */}
        <If condition={topBadges?.length && !isABMTeamLoading}>
          {topBadges?.map(({ id, unlocked_image_url, name, earned_count }) => (
            <AddLink to="/profile?tab=badges" key={id}>
              <Card inlineContent inlineDisplay stackDisplay>
                <Card.Section className="mr-2 w-8 ">
                  <Image src={unlocked_image_url || 'https://via.placeholder.com/80'} alt="placeholder" className="my-0 w-auto max-h-8" />
                </Card.Section>
                <Header as="h3" className="grow mb-0">
                  {name}
                </Header>
                <span className="text-sm">x{earned_count}</span>
              </Card>
            </AddLink>
          ))}
        </If>
        {/* Error State? */}
      </WidgetContainer>
    </LockedContainer>
  );
}

export default TopBadgesEarnedWidget;
