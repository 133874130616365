import axios from 'axios';
import queryString from 'query-string';

const clabApiBaseURL = process.env.REACT_APP_CLAB_API_URL;

// We will send along these headers with each request
const getAuthHeader = () => {
  const token = localStorage.getItem('clabjwt') || '';
  return token ? { Authorization: `${token}` } : {};
};

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => {
    throw error;
  }
);

const getRequestOptions = () => {
  return {
    headers: getAuthHeader(),
    withCredentials: true,
  };
};

const response = (res) => res?.data;

// Override defaults or headers with options
const requests = {
  get: (url) => axiosInstance.get(url, getRequestOptions()).then(response),
  post: (url, data) => axiosInstance.post(url, data, getRequestOptions()).then(response),
  put: (url, data) => axiosInstance.put(url, data, getRequestOptions()).then(response),
  patch: (url, data) => axiosInstance.patch(url, data, getRequestOptions()).then(response),
  delete: (url) => axiosInstance.delete(url, getRequestOptions()).then(response),
};

// Export generic api request handler
export const getApiRequest = (route) => requests.get(`${clabApiBaseURL}${route}`);
export const postApiRequest = (route, data) => requests.post(`${clabApiBaseURL}${route}`, data);
export const putApiRequest = (route, data) => requests.put(`${clabApiBaseURL}${route}`, data);
export const deleteApiRequest = (route) => requests.delete(`${clabApiBaseURL}${route}`);

export const Auth = {
  localLogin: (data) => requests.post(`${clabApiBaseURL}/user/token`, data),
  iapLogin: () => requests.get(`${clabApiBaseURL}/?gcp-iap-mode=IDENTITY`),
};

export const Infra = {
  getTemplates: () => requests.get(`${clabApiBaseURL}/lab`),
  deleteLabTemplate: (labId) => requests.delete(`${clabApiBaseURL}/lab/${labId}`),
  getLabs: () => requests.get(`${clabApiBaseURL}/infra/lab`),
  getLabById: (labId) => requests.get(`${clabApiBaseURL}/infra/lab/${labId}`),
  startTemplate: (labId, sessionName = '', snapshotId = null) => {
    const params = snapshotId ? { sessionName, snapshotId } : { sessionName };
    return requests.post(`${clabApiBaseURL}/infra/lab/${labId}?${queryString.stringify(params)}`, {});
  },
  startLab: (labId) => requests.patch(`${clabApiBaseURL}/infra/lab/${labId}/start`),
  stopLab: (labId) => requests.patch(`${clabApiBaseURL}/infra/lab/${labId}/stop`),
  beginLab: (labId, query = '') => requests.patch(`${clabApiBaseURL}/infra/lab/${labId}/begin${query}`),
  restartLab: (labId) => requests.patch(`${clabApiBaseURL}/infra/lab/${labId}/restart`),
  deleteLab: (labId) => requests.delete(`${clabApiBaseURL}/infra/lab/${labId}`),
  startResource: (labId, resourceId) => requests.patch(`${clabApiBaseURL}/infra/lab/${labId}/resource/${resourceId}/start`),
  stopResource: (labId, resourceId) => requests.patch(`${clabApiBaseURL}/infra/lab/${labId}/resource/${resourceId}/stop`),
  getResourceStatus: (labId) => requests.get(`${clabApiBaseURL}/infra/lab/${labId}/resource`),
  clearLabDefinitions: () => requests.delete(`${clabApiBaseURL}/lab/all`),
  reseedLabDefinitions: () => requests.put(`${clabApiBaseURL}/infra/seed`, {}),
  snapshot: (labId, snapshotName = '', deleteLabOnComplete = false) => {
    const params = { snapshotName, deleteLabOnComplete };
    return requests.patch(`${clabApiBaseURL}/infra/lab/${labId}/snapshot?${queryString.stringify(params)}`, {});
  },
};

export const LabDefinition = {
  getDefinition: (id) => requests.get(`${clabApiBaseURL}/lab/${id}`),
  saveDefinition: (postData) => requests.post(`${clabApiBaseURL}/lab`, postData),
};

export const Event = {
  saveUserKeyEvent: (data) => requests.post(`${clabApiBaseURL}/event/key`, data),
};

export const Session = {
  getAllSessions: (query = '') => requests.get(`${clabApiBaseURL}/user/session${query}`),
  getSession: (sessionId) => requests.get(`${clabApiBaseURL}/user/session/${sessionId}`),
  getSessionByUserAndLabId: (userId, labId) => requests.get(`${clabApiBaseURL}/user/session/${userId}/${labId}`),
  clearUserSessions: () => requests.delete(`${clabApiBaseURL}/user/session/all`),
};

export const DashboardEvent = {
  getLabStatusHistory: (sessionId) => requests.get(`${clabApiBaseURL}/event/history/status?sessionId=${sessionId}`),
  getLabStateHistory: (sessionId) => requests.get(`${clabApiBaseURL}/event/history/state?sessionId=${sessionId}`),
  getResourceStatusHistory: (sessionId) => requests.get(`${clabApiBaseURL}/event/history/resource?sessionId=${sessionId}`),
  getKeyboardHistory: (sessionId) => requests.get(`${clabApiBaseURL}/event/history/key?sessionId=${sessionId}`),
};
