import React, { createContext, useContext, useState, useMemo } from 'react';
import useDiscourse from '../hooks/useDiscourse';
import { deconstructCategorySlug } from '../utils/discourseUtil';
import BugsnagUtil from '../utils/bugsnagUtil';

const ForumsSidebarContext = createContext();

const DEFAULT_TITLE = 'Forums Discussions';

/**
 * Provider for the Forums sidebar
 * Allows the Forums sidebar to be opened and configured from anywhere in the app
 * @param {ReactElement} children - the children to render
 * @returns {ReactElement} - the rendered children
 */
function ForumsSidebarProvider({ children }) {
  const [isLoading, setIsLoading] = useState(false); // whether or not the sidebar is open
  const [forumsSidebarError, setForumsSidebarError] = useState(null); // error state for the forums sidebar
  const [isOpen, setIsOpen] = useState(false); // whether or not the sidebar is open
  const [topTopics, setTopTopics] = useState([]); // the top topics to show in the sidebar
  const [newTopics, setNewTopics] = useState([]); // the new topics to show in the sidebar
  const [title, setTitle] = useState(DEFAULT_TITLE); // the title of the sidebar
  const [activeCategory, setActiveCategory] = useState(null); // the active category to show in the sidebar
  const { fetchTopicsForCategory } = useDiscourse();

  /**
   * Open the forums sidebar and sets the given category to show
   */
  const open = async (category, options = {}) => {
    // If no newCategorySlug provided, return
    if (!category) return;
    // Fetch top and new topics for the category
    const { categorySlug, categoryId } = deconstructCategorySlug(category);
    // If we fail to deconstruct the newCategorySlug provided, return
    if (!categoryId || !categorySlug) return;
    setActiveCategory(category);
    // Fetch top and new topics for the category
    setIsLoading(true);
    // Set title from options
    setTitle(options?.title || DEFAULT_TITLE);
    try {
      const [newTopicsResponse, topTopicsResponse] = await Promise.allSettled([
        fetchTopicsForCategory(categorySlug, categoryId, 'latest', { ascending: false, order: 'created', exclude_tag: 'hide-in-app' }),
        fetchTopicsForCategory(categorySlug, categoryId, 'top', { ascending: false, order: 'posts', exclude_tag: 'hide-in-app' }),
      ]);
      setNewTopics(newTopicsResponse?.value?.topic_list?.topics);
      setTopTopics(topTopicsResponse?.value?.topic_list?.topics);

      // Open the sidebar
      setIsOpen(true);
    } catch (error) {
      BugsnagUtil.notify(error);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Close the forums sidebar and reset state
   */
  const close = () => {
    setIsOpen(false);
    setTopTopics([]);
    setNewTopics([]);
    setForumsSidebarError(null);
    setActiveCategory(null);
  };

  const values = useMemo(
    () => ({
      isOpen,
      open,
      close,

      isLoading,
      forumsSidebarError,
      topTopics,
      newTopics,

      title,
      activeCategory,
    }),
    [isOpen, open, close, isLoading, topTopics, newTopics, forumsSidebarError, title, activeCategory]
  );

  return <ForumsSidebarContext.Provider value={values}>{children}</ForumsSidebarContext.Provider>;
}

export const useForumsSidebar = () => useContext(ForumsSidebarContext);
export default ForumsSidebarProvider;
