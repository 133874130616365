import React from 'react';

function VisaCard({ classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';
  return (
    <svg className={className} {...accessibilityProps} version="1.1" viewBox="0 0 780 500" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M53.487,14h673.524c21.257,0,38.488,16.816,38.488,37.559v394.38   
        c0,20.744-17.231,37.561-38.488,37.561H53.487C32.231,483.5,15,466.684,15,445.939V51.559C15,30.816,32.231,14,53.487,14z"
        fill="none"
        stroke="currentColor"
        strokeWidth="30"
      />
      <path
        d="m296.86 342.7l32.099-183.82h51.344l-32.123 183.82h-51.32zm236.8-179.86c-10.172-3.724-26.108-7.721-46.014-7.721-50.731 
        0-86.466 24.931-86.77 60.662-0.287 26.414 25.511 41.149 44.984 49.941 19.985 9.01 26.703 14.755 26.608 22.801-0.127 12.322-15.96 
        17.952-30.715 17.952-20.549 0-31.467-2.786-48.327-9.65l-6.616-2.921-7.207 41.149c11.993 5.132 34.168 9.578 57.191 9.808 53.967 
        0 89.002-24.646 89.401-62.801 0.193-20.912-13.484-36.826-43.105-49.945-17.945-8.502-28.936-14.178-28.818-22.788 0-7.64 9.301-15.812 
        29.399-15.812 16.791-0.254 28.951 3.319 38.427 7.043l4.601 2.122 6.96-39.843m132.12-3.963h-39.671c-12.29 0-21.487 3.274-26.886 
        15.245l-76.245 168.46h53.91s8.815-22.653 10.81-27.625c5.892 0 58.264 0.079 65.752 0.079 1.535 6.436 6.245 27.546 6.245 
        27.546h47.641l-41.556-183.71zm-62.943 118.7c4.246-10.591 20.455-51.385 20.455-51.385-0.303 0.491 4.215-10.643 6.81-17.543l3.468 
        15.849s9.831 43.878 11.886 53.079h-42.619zm-349.55-118.7l-50.264 125.35-5.356-25.476c-9.357-29.366-38.511-61.182-71.103-77.11l45.959 
        160.76 54.32-0.061 80.827-183.47h-54.383"
        fill="currentColor"
      />
      <path
        d="m156.11 158.88h-82.787l-0.655 3.825c64.406 15.216 107.02 51.984 124.72 
        96.166l-18.002-84.473c-3.108-11.638-12.121-15.111-23.272-15.518"
        fill="currentColor"
      />
    </svg>
  );
}
export default VisaCard;
