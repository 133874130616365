import React from 'react';
import { inject, observer } from 'mobx-react';
import Rating from 'react-rating';
import Icon from '../Icon/Icon';
import Tooltip from '../Tooltip/Tooltip';
import FormatUtil from '../../utils/formatUtil';

function RatingsStars({
  average,
  textColor,
  count,
  mobileStackRatingText,
  hasProgress,
  useIcon,
  inverted,
  noRatingReview,
  preventClick,
  ratingsClassName,
  starClasses = 'w-4 h-4 mr-1',
}) {
  let countText = '';
  if (hasProgress && count === 0 && !preventClick) {
    countText = 'Review';
  } else {
    countText = (
      <>
        {FormatUtil.numberWithCommas(count)}
        <span className="sr-only"> reviews, {average} out of 5 stars</span>
      </>
    );
  }
  const invertedClass = inverted ? 'inverted' : '';
  const textColorClass = textColor || 'text-white';
  const textPositionClass = mobileStackRatingText ? 'text-center' : 'inline';
  const containerFlexClasses = mobileStackRatingText ? 'md:flex md:items-center' : 'ratings-container flex items-center';
  return (
    <div className={`${containerFlexClasses} ${invertedClass} ${ratingsClassName || ''}`}>
      {!!average && <div className={`mr-1 text-2xs text-cyb-orange-500 ${textPositionClass}`}>{average}</div>}
      <div className="pt-0.5">
        <Rating
          emptySymbol={<Icon name="star" className={`${starClasses} text-gray-400`} />}
          fullSymbol={<Icon name="star" className={`${starClasses} text-cyb-yellow-500`} active />}
          initialRating={useIcon ? 0 : average}
          stop={useIcon ? 1 : 5}
          className="whitespace-nowrap"
          readonly
        />
      </div>
      {useIcon || noRatingReview ? null : <div className={`text-2xs ${textColorClass} ${textPositionClass}`}>({countText})</div>}
    </div>
  );
}

/**
 * The button to trigger the review modal
 */
const FeedbackButton = inject(
  'feedbackStore',
  'userStore'
)(
  observer(
    class FeedbackButton extends React.Component {
      openFeedbackModal = () => {
        const { feedbackStore, type, title, contentDescriptionId } = this.props;
        if (contentDescriptionId) {
          feedbackStore.setContentDescriptionId(contentDescriptionId);
        }
        const { formSet } = feedbackStore;

        feedbackStore.setTitle(title);
        feedbackStore.setContentType(type);

        if (formSet.assessmentType.indexOf(type) !== -1) {
          // type is an assessment
          feedbackStore.setFeedbackForm('assessment');
        } else if (formSet.collectionType.indexOf(type) !== -1) {
          // type is a collection, or certification prep
          feedbackStore.setFeedbackForm('collection');
        } else if (formSet.labType.indexOf(type) !== -1) {
          // type is a lab
          feedbackStore.setFeedbackForm('lab');
        } else if (formSet.courseType.indexOf(type) !== -1) {
          // type is a course, microcourse, cybrary live
          feedbackStore.setFeedbackForm('course');
        } else {
          // type is a audio/video based activity
          feedbackStore.setFeedbackForm('video');
        }
        feedbackStore.openFeedbackModal();
      };

      openFeedbackDropdown = () => {
        const { feedbackStore, type, title, contentDescriptionId, toggleFeedbackDisplay } = this.props;
        if (contentDescriptionId) {
          feedbackStore.setContentDescriptionId(contentDescriptionId);
        }
        feedbackStore.setContentType(type);
        feedbackStore.setTitle(title);
        const { formSet } = feedbackStore;
        if (formSet.courseType.indexOf(type) !== -1) {
          // type is a course, microcourse, cybrary live
          feedbackStore.setFeedbackForm('course');
        } else if (formSet.assessmentType.indexOf(type) !== -1) {
          // type is an assessment
          feedbackStore.setFeedbackForm('assessment');
        } else if (formSet.labType.indexOf(type) !== -1) {
          // type is a lab
          feedbackStore.setFeedbackForm('lab');
        } else if (formSet.collectionType.indexOf(type) !== -1) {
          // type is a collection
          feedbackStore.setFeedbackForm('collection');
        } else {
          // type is a audio/video based activity
          feedbackStore.setFeedbackForm('video');
        }
        toggleFeedbackDisplay();
      };

      displayReviewText = (type, hasProgress, tooltipText, isFree, isUserOnActiveTeam) => {
        let displayReviewText = 'Enroll to rate and review';
        if (hasProgress) {
          displayReviewText = `Rate this ${type.toLowerCase()}`;
        }
        if (tooltipText) {
          displayReviewText = tooltipText;
        }
        if (isFree && !isUserOnActiveTeam) {
          displayReviewText = 'Upgrade to rate and review';
        }
        return displayReviewText;
      };

      displayRatingStars = (cannotClick) => {
        const { average, count, hasProgress, textColor, useIcon, inverted, noRatingReview, ratingsClassName, starClasses, mobileStackRatingText } = this.props;
        // We want if we are on a Card or SearchItem view to keep the cursor pointer if we use the star rating somewhere else use the cursor not allowed.
        return (
          <RatingsStars
            average={average}
            count={count}
            textColor={textColor}
            mobileStackRatingText={mobileStackRatingText}
            hasProgress={hasProgress}
            useIcon={useIcon}
            preventClick={cannotClick}
            ratingsClassName={ratingsClassName}
            inverted={inverted}
            noRatingReview={noRatingReview}
            starClasses={starClasses}
          />
        );
      };

      render() {
        const { type, average, count, hasProgress, hidePopup, displayOnly, theme, isDropdown, buttonLabel, userStore, className, tooltipText } = this.props;
        const { isFree, isUserOnActiveTeam } = userStore;
        const cannotClick = !hasProgress || isFree;
        let openModal = true;
        if (cannotClick) {
          openModal = false;
        }
        const handleOnClick = () => {
          if (openModal && !isDropdown) {
            return this.openFeedbackModal();
          }
          if (openModal && isDropdown) {
            return this.openFeedbackDropdown();
          }
          return null;
        };

        const displayReviewText = this.displayReviewText(type, hasProgress, tooltipText, isFree, isUserOnActiveTeam);
        let cursorClass = cannotClick ? 'cursor-not-allowed' : 'cursor-pointer';
        // When in browse card/displaying only, no click enable/disable cursor needed (just showing for display)
        if (displayOnly) {
          cursorClass = '';
        }
        const displayRatingStars = buttonLabel || this.displayRatingStars(cannotClick);
        if (displayOnly) {
          return displayRatingStars;
        }
        return (
          <Tooltip
            disabled={!!hidePopup}
            position="top-end"
            size="small"
            theme={theme || 'light'}
            content={<div className="font-semibold text-center">{displayReviewText}</div>}
            omitTabIndex
            triggerContent={
              <button
                aria-label={`Average of ${average || 0} stars from ${count || 0} reviews. ${displayReviewText}`}
                aria-disabled={cannotClick}
                className={`reviews-button rounded-sm text-sm leading-5 ${cursorClass} ${className || 'bg-none'}`}
                onClick={handleOnClick}
              >
                {displayRatingStars}
              </button>
            }
          />
        );
      }
    }
  )
);

export default FeedbackButton;
