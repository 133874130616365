import React from 'react';
import { inject, observer } from 'mobx-react';
import UpgradeButton from '../UpgradeButton/UpgradeButton';
import EnrollmentUtil from '../../utils/enrollmentUtil';
import Button from '../Button/Button';

const { isCourse, isCollection, isCybrarySelect } = EnrollmentUtil;

const EnrollmentButton = inject('userStore')(
  observer(function EnrollmentButton({ userStore, type, text, progress, handleEnroll, item, buttonClasses, color, isLoading = false }) {
    // Get the query params to be included with the upgrade link
    const getUpgradeLinkParams = (content) => {
      if (content.content_type.name === 'career_path') {
        const nameOfPath = content.permalink.split('/')[1];
        return `?appbrowse=collection&content=${nameOfPath}`;
      }
      if (isCybrarySelect(content)) {
        // focus on the teams solution when navigating from a cybrary select path
        return `?solution=teams`;
      }
      return `?appbrowse=${content.content_type.name.toLowerCase().replace(' ', '-')}`;
    };

    let enrollText = '';
    let upgradeText = '';

    // Courses, MicroCourse, Career Paths
    if (isCourse(type) || isCollection(type)) {
      // Determine content progress
      if (progress) {
        switch (true) {
          case progress.content_progress && progress.content_progress.progress_event === 'completed':
            enrollText = 'Revisit';
            break;
          case progress.content_progress && progress.content_progress.progress_event === 'started':
            enrollText = 'Continue';
            break;
          case progress.content_progress && !progress.content_progress.progress_event:
          default:
            enrollText = 'Start';
        }
      } else {
        enrollText = 'Start';
      }
    } else {
      // Assessments, Labs, and Tests for non-free users
      enrollText = 'Launch';
    }

    if (isCybrarySelect(item)) {
      upgradeText = 'Upgrade to Teams to Start today';
    }

    // Allow the user store to perform the permissions check (takes licenses into account)
    if (item && !userStore.checkPermissions(item)) {
      return (
        <UpgradeButton
          color={color || 'pink'}
          buttonClasses={buttonClasses || ''}
          upgradeText={upgradeText || text || 'Upgrade to Start learning today'}
          upgradeLinkParams={getUpgradeLinkParams(item)}
        />
      );
    }

    return (
      <Button onClick={handleEnroll} color={color || 'pink'} className={`${buttonClasses || ''}`} loading={isLoading}>
        {text || enrollText}
      </Button>
    );
  })
);

export default EnrollmentButton;
