import React, { useState, useEffect } from 'react';
import { random } from 'lodash';
import Divider from '../../Divider/Divider';
import Loading from '../../Loading/Loading';
import { useProfileContext } from '../../../providers/ProfileProvider';

function UpdateUsername({ handleUpdateDefaultUsername }) {
  const [randomNum] = useState(random(0, 9999));
  const { personalProfileData, isLoading, serverErrors, setServerErrors } = useProfileContext();
  const { username = '', firstName = '', lastName = '' } = personalProfileData;

  const [usernameInput, setUsernameInput] = useState(username);
  const [isValid, setIsValid] = useState(true);

  // make sure we update initial username after it has loaded
  useEffect(() => {
    setUsernameInput(username);
  }, [username]);

  if (isLoading || !username) return <Loading />;

  const trimmedFirstName = firstName.substring(0, 10);
  const trimmedLastName = lastName.substring(0, 10);
  const firstNameFirstThreeLetters = trimmedFirstName.substring(0, 3);
  const lastNameFirstThreeLetters = trimmedLastName.substring(0, 3);
  // marwal123
  const suggestion1 = `${firstNameFirstThreeLetters}${lastNameFirstThreeLetters}${randomNum}`;
  // mary.waller123
  const suggestion2 = `${trimmedFirstName}_${trimmedLastName}${randomNum}`;
  // waller.mary123
  const suggestion3 = `${trimmedLastName}_${trimmedFirstName}${randomNum}`;
  // walmar123
  const suggestion4 = `${lastNameFirstThreeLetters}${firstNameFirstThreeLetters}${randomNum}`;
  const suggestions = [suggestion1, suggestion2, suggestion3, suggestion4];

  const handleChange = (event) => {
    if (serverErrors?.username) {
      setServerErrors({});
    }
    const inputValue = event.target.value;
    const pattern = /^[a-zA-Z0-9_-]*$/;
    const minLength = 5;
    const maxLength = 32;

    if (inputValue.length < minLength || inputValue.length > maxLength || !pattern.test(inputValue)) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }

    setUsernameInput(inputValue);
  };

  return (
    <div id="update-username-container" className="flex flex-col justify-center items-center py-14 px-7 w-full">
      <p className="text-2xl font-black">Welcome back! We&apos;ve made some changes.</p>
      <p className="mb-10 text-2xl font-black text-cyb-pink-500">Please update your username </p>

      <div className="mb-4 w-full">
        <label htmlFor="change-default-username" className="mb-4 text-lg font-bold">
          Username
          <span className="text-red-600">*</span>
        </label>
        <form className="w-full sm:flex" onSubmit={(e) => handleUpdateDefaultUsername(e, usernameInput)}>
          <input
            type="text"
            className="p-2 mr-4 mb-4 max-w-[340px] rounded-sm border-1 border-gray-300 sm:mb-0 sm:min-w-[250px] md:min-w-[350px]"
            id="change-default-username"
            value={usernameInput}
            onChange={handleChange}
            required
          />
          <button type="submit" className="py-3 px-6 text-white bg-cyb-pink-500 rounded-sm">
            {' '}
            Submit
          </button>
        </form>
        {!isValid && (
          <p className="mt-2 text-xs text-red-600">Invalid input. Please enter only alphanumeric characters, underscores, or hyphens, between 5 and 30 characters long.</p>
        )}
        {!!serverErrors?.username && <p className="mt-2 text-xs text-red-600">{serverErrors?.username[0]}</p>}
      </div>

      <Divider className="w-full" marginBottom="mb-2" marginTop="mt-2" />
      <div className="flex flex-col justify-center items-center">
        <p className="mb-5 text-sm font-bold">Username Suggestions</p>
        <div className="flex flex-wrap">
          {suggestions.map((suggestion, idx) => {
            return (
              <button
                // eslint-disable-next-line react/no-array-index-key
                key={`${suggestion}-${idx}`}
                aria-label={`use suggested username: ${suggestion}`}
                onClick={() => {
                  setUsernameInput(suggestion);
                  setIsValid(true);
                }}
              >
                <p className="mr-4 text-sm font-normal">{suggestion}</p>
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
}
export default UpdateUsername;
