import React, { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import { CYBRARY_LOGO_URL } from '../../constants';

function CybraryLogo({ width = 224, height = 58, className = '' }) {
  const mergedClasses = useMemo(() => {
    const defaultClasses = 'm-auto mb-8';
    return twMerge(defaultClasses, className);
  }, [className]);

  return <img alt="Cybrary Logo" width={width} height={height} className={mergedClasses} src={CYBRARY_LOGO_URL} />;
}

export default CybraryLogo;
