import React, { Component, useMemo, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import queryString from 'query-string';
import GoalsUtil from '../../utils/goalsUtil';
import FormatUtil from '../../utils/formatUtil';
import Wizard from '../Wizard/Wizard';
import WizardTimeframeSelection from './WizardTimeframeSelection';
import WizardStepDescription from './WizardStepDescription';
import WizardOutcomeSelection from './WizardOutcomeSelection';
import WizardOutcomeStepperDetails from './WizardOutcomeStepperDetails';
import { GoalLearningActivity, DetailsStepperDisplay, GoalDetails } from './TeamsGoalsWizard';

function GoalOutcome({ setValue, selectedOutcomeType, selectedOutcomeSettings, isEditView }) {
  const inputRef = useRef();

  const handleSelection = (section, val) => {
    if (section === 'outcome_type') {
      setValue(section, val);
      if (val.includes('_program')) {
        setValue('content_type', val);
      }
      setValue('outcome_settings', {});
      const scrollSections = ['ceus'];
      if (scrollSections.indexOf(val) > -1) {
        setTimeout(() => {
          inputRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 100);
      }
    } else {
      // handle outcome_settings
      const settings = {};
      // CEUs and learning hours outcome types need settings set. Learning hours needs to save in seconds
      if (selectedOutcomeType === 'ceus' && val > 0) {
        settings.ceus_total = val || null;
      } else if (selectedOutcomeType === 'learning_hours' && val > 0) {
        settings.learning_hours_total = parseInt(val, 10);
        settings.learning_seconds_total = settings.learning_hours_total ? FormatUtil.convertHoursToSeconds(settings.learning_hours_total) : null;
      }
      setValue(section, settings);
    }
  };

  const outcomeOptions = useMemo(
    () => [
      {
        text: 'Complete a Career Path',
        value: 'career_program',
      },
      {
        text: 'Complete a Cert Prep Path',
        value: 'cert_prep_program',
      },
      {
        text: 'Complete a Skill Path',
        value: 'skill_program',
      },
    ],
    []
  );

  if (selectedOutcomeType === 'recommended_learning') {
    // add recommended learning to the outcome options
    outcomeOptions.unshift({ text: 'Complete Recommended Learning', value: 'recommended_learning' });
  }

  return (
    <WizardOutcomeSelection
      selectedOutcomeType={selectedOutcomeType}
      selectedOutcomeSettings={selectedOutcomeSettings}
      isEditView={isEditView}
      outcomeOptions={outcomeOptions}
      inputRef={inputRef}
      handleSelection={handleSelection}
      goalType="user"
    />
  );
}

const UserGoalsWizard = inject(
  'commonStore',
  'goalsStore'
)(
  observer(
    class UserGoalsWizard extends Component {
      state = {
        submitting: false,
        error: null,
        validationMap: {},
      };

      componentDidMount() {
        const { goalsStore, contentItem, goalPreselection } = this.props;
        const { setGoalData, findAndSetGoalData, goalData, getPaths } = goalsStore;

        getPaths();

        if (goalPreselection) {
          setGoalData('user', 'outcome_type', goalPreselection);
        }

        if (contentItem) {
          this.getPreselectedContent(contentItem);
        }

        const params = queryString.parse(window.location.search);

        if (params && Object.keys(params).length) {
          // Loop through params and set values if applicable
          Object.keys(params).forEach((key) => {
            if (key in goalData.data) {
              const value = params[key];
              // Some values need to be in certain formats (arrays)
              if (key === 'outcome_type') {
                setGoalData('user', 'outcome_type', value);
                setGoalData('user', 'outcome_settings', {});
              } else if (key === 'content_ids') {
                // split string into array of numbers
                const contentIds = value.split(',').map((id) => 1 * id);
                setGoalData('user', 'content_ids', contentIds);
                findAndSetGoalData('user', 'contents', 1 * value, params.content_type === 'path');
              } else if (key === 'participant_ids') {
                setGoalData('user', 'participant_ids', [1 * value]);
                setGoalData('user', 'participant_type', 'user');
                findAndSetGoalData('user', 'participants', { value: 1 * value });
              } else if (key === 'owner_ids') {
                setGoalData('user', 'owner_ids', [1 * value]);
                setGoalData('user', 'owners', [{ value: 1 * value, text: 'Myself' }]);
              } else {
                setGoalData('user', key, value);
              }
            }
          });
        }

        this.setValidationMap();
      }

      componentWillUnmount() {
        this.props.goalsStore.setDefaultGoalData();
        this.props.goalsStore.setDefaultLearningActivities();
      }

      getPreselectedContent = (item) => {
        this.props.goalsStore.getPresetContentData(item).then(() => {
          if (this.props.goalsStore.userGoalContentRecommendations.data && this.props.goalsStore.userGoalContentRecommendations.data.length) {
            const contentType = this.props.goalsStore.userGoalContentRecommendations.data[0].type === 'Career Path' ? 'path' : 'activity';
            this.setValue('content_type', contentType);
          }
        });
      };

      handleSubmit = (lastStep) => {
        const err = this.checkForErrors(lastStep);
        if (!err) {
          this.saveGoal();
        }
      };

      saveGoal = () => {
        const { goalId, onSubmit } = this.props;
        const newState = {
          ...this.state,
          submitting: true,
        };
        this.setState(newState, () => {
          // If we have a goal id we are editing an existing goal
          this.props.goalsStore
            .saveGoal(goalId)
            .then(() => {
              newState.submitting = false;
              this.setState(newState, () => {
                this.props.commonStore.triggerToast('success', { content: `Your goal has been ${goalId ? 'updated' : 'set!'}` });
                onSubmit();
                // clear all query params
                window.history.replaceState({}, document.title, window.location.pathname);
              });
            })
            .catch(() => {
              newState.submitting = false;
              this.setState(newState);
              this.props.commonStore.triggerToast('error', { content: 'Something went wrong. Unable to save this goal at this time.' });
            });
        });
        return null;
      };

      setValidationMap = () => {
        const validationMap = GoalsUtil.getGoalValidationMap('user', this.props.goalsStore.goalData.data);
        const newState = {
          ...this.state,
          validationMap,
        };
        this.setState(newState);
      };

      setValue = (field, val) => {
        const { setGoalData } = this.props.goalsStore;
        const newState = {
          ...this.state,
          error: null,
        };
        // Clear out any errors visible
        this.setState(newState, () => {
          setGoalData('user', field, val);
          this.setValidationMap();
        });
      };

      checkForErrors = (fromSection, toSection) => {
        const errorCheck = this.state.validationMap[fromSection.id];
        const newState = {
          ...this.state,
        };
        // If you're able to navigate to the destination section, hide any errors shown from current section
        if (toSection && !toSection.preventNavigation) {
          newState.error = null;
          this.setState(newState);
        } else if (!errorCheck.valid && errorCheck.error) {
          newState.error = errorCheck.error;
          this.setState(newState);
          return errorCheck.error;
        }
        return null;
      };

      getContentRecommendations = (outcome, learningType) => {
        this.props.goalsStore.getContentRecommendations(outcome, learningType);
      };

      render() {
        const { submitting, validationMap } = this.state;
        const { goalData, paths, getPaths, filterOptions, getLearningActivities, learningActivities } = this.props.goalsStore;
        const { outcome_type, outcome_settings, content_type, content_ids, contents, due_type, due_date, name, description } = goalData.data;
        const isEditView = !!this.props.goalId;
        const disabledContainerClasses = isEditView ? 'text-gray-600' : '';

        return (
          <div>
            <Wizard
              errorMessage={this.state.error}
              submitText="Save"
              isValid={!submitting && GoalsUtil.checkGoalValid('user', goalData.data)}
              onSubmit={this.handleSubmit}
              scrollToError
              startStep="outcome_type"
              submitId="create-learner-goal-wizard"
              steps={[
                {
                  id: 'outcome_type',
                  content: {
                    heading: 'Set Your Goal',
                    description: <WizardStepDescription step="outcome_type" goalType="user" />,
                    stepperDetails: !!validationMap.outcome_type && !!validationMap.outcome_type.valid && (
                      <WizardOutcomeStepperDetails
                        outcomeDisplay={GoalsUtil.getOutcomeDisplay(outcome_type, outcome_settings)}
                        practiceTestDisplay={GoalsUtil.getPracticeTestDetails(goalData.data)}
                      />
                    ),
                  },
                  additionalContent: <GoalOutcome setValue={this.setValue} selectedOutcomeType={outcome_type} selectedOutcomeSettings={outcome_settings} isEditView={isEditView} />,
                  onStepSwitch: this.checkForErrors,
                  isValid: validationMap.outcome_type && validationMap.outcome_type.valid,
                },
                {
                  id: 'content_type',
                  content: {
                    heading: 'Set Your Learning',
                    description: <WizardStepDescription step="content_type" disabled={isEditView} outcomeType={outcome_type} goalType="user" />,
                    stepperDetails:
                      validationMap.content_type && validationMap.content_type.valid && GoalsUtil.getLearningActivityDisplay({ content_type, contents, omitContentType: true }),
                    contentContainerClasses: disabledContainerClasses,
                  },
                  additionalContent: (
                    <GoalLearningActivity
                      setValue={this.setValue}
                      selectedContentType={content_type}
                      selectedContentIds={content_ids}
                      selectedOutcomeType={outcome_type}
                      paths={{ ...paths }}
                      getLearningActivities={(val, contentType) => getLearningActivities(val, null, contentType)}
                      learningActivityOptions={{ ...learningActivities }}
                      getPaths={() => getPaths(null, null)}
                      filterOptions={filterOptions}
                      isEditView={isEditView}
                    />
                  ),
                  preventNavigation: !validationMap.outcome_type || !validationMap.outcome_type.valid,
                  onStepSwitch: this.checkForErrors,
                  isValid: validationMap.content_type && validationMap.content_type.valid && content_ids?.length > 0,
                },
                {
                  id: 'due_type',
                  content: {
                    heading: 'Set Your Timeframe',
                    description: <WizardStepDescription step="due_type" disabled={isEditView} goalType="user" />,
                    stepperDetails: validationMap.due_type && validationMap.due_type.valid && GoalsUtil.getDeadlineDetails(goalData.data.due_type, goalData.data.due_date),
                  },
                  additionalContent: (
                    <WizardTimeframeSelection
                      setValue={this.setValue}
                      selectedDueType={due_type}
                      selectedDueDate={due_date}
                      isEditView={isEditView}
                      goalType="user"
                      omitRecurringOption
                    />
                  ),
                  preventNavigation: !validationMap.content_type || !validationMap.content_type.valid || !content_ids?.length,
                  onStepSwitch: this.checkForErrors,
                  isValid: validationMap.due_type && validationMap.due_type.valid,
                },
                {
                  id: 'name',
                  content: {
                    heading: 'Name & Details',
                    stepperDetails: <DetailsStepperDisplay name={name} description={description} />,
                  },
                  additionalContent: <GoalDetails setValue={this.setValue} nameValue={name} descriptionValue={description} />,
                  preventNavigation: !validationMap.due_type || !validationMap.due_type.valid,
                  onStepSwitch: this.checkForErrors,
                  isValid: validationMap.name && validationMap.name.valid,
                },
              ]}
            />
          </div>
        );
      }
    }
  )
);

export default UserGoalsWizard;
