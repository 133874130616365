import React, { useState } from 'react';
import CertificationCard from './CertificationCard';
import AddCertificationCard from './AddCertificationCard';

function Certifications({ certificationsProfileData, certOptions, isEditable }) {
  if (!certificationsProfileData?.length) {
    const emptyText = isEditable ? 'Add industry certifications that you have earned' : 'There are no certifications to show here.';
    return <p className="mt-4">{emptyText}</p>;
  }
  return (
    <div id="certifications" className="mt-4">
      {certificationsProfileData.map(({ name: certTitle, issuedAt, expiresAt, company, certId, permalink, thumbnailUrl }) => {
        return (
          <CertificationCard
            key={certId || certTitle}
            certTitle={certTitle}
            issuedDate={issuedAt}
            expiresDate={expiresAt}
            permalink={permalink}
            company={company}
            thumbnailUrl={thumbnailUrl}
            certOptions={certOptions}
            isEditable={isEditable}
          />
        );
      })}
    </div>
  );
}

function CertificationsTab({ certificationsProfileData, certOptions = [], isEditable }) {
  const [addCertIsOpen, setAddCertIsOpen] = useState(false);
  return (
    <div id="certifications-container">
      {!!isEditable && (
        <div className="flex justify-end">
          <button
            type="button"
            id="add-certification-button"
            className="py-3 px-6 ml-auto text-sm font-bold text-cyb-pink-500 rounded-lg border-1 border-cyb-pink-500"
            onClick={() => setAddCertIsOpen(true)}
          >
            {' '}
            + Certification{' '}
          </button>
        </div>
      )}
      {!!addCertIsOpen && !!isEditable && <AddCertificationCard handleClose={() => setAddCertIsOpen(false)} certOptions={certOptions} />}
      <Certifications certificationsProfileData={certificationsProfileData} certOptions={certOptions} isEditable={isEditable} />
    </div>
  );
}
export default CertificationsTab;
