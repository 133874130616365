import { PADDLE_PRICE_ID_CIP_ANNUAL, PADDLE_PRICE_ID_CIP_MONTHLY } from '../../../constants';

export function getAmountDueRowTitle(item) {
  // item?.price_id is for NEW checkouts
  // item?.price?.id is for EXISTING subscriptions
  const priceId = item?.price_id || item?.price?.id;
  switch (priceId) {
    case PADDLE_PRICE_ID_CIP_MONTHLY:
      return 'Monthly Individual Subscription';
    case PADDLE_PRICE_ID_CIP_ANNUAL:
      return 'Annual Individual Subscription';
    default:
      return item.product.name;
  }
}
