import React from 'react';
import Tooltip from '../Tooltip/Tooltip';

function Skill({ skillName }) {
  return (
    <Tooltip position="top" content={skillName}>
      <div className="flex justify-center items-center bg-black rounded">
        <p className="py-1.5 px-2 text-sm font-normal text-white capitalize truncate">{skillName}</p>
      </div>
    </Tooltip>
  );
}

export default function AdditionalSkills({ skills, className }) {
  if (!skills || !skills.length) {
    return null;
  }
  const trimmedSkills = skills.length > 12 ? skills.splice(0, 12) : skills;
  return (
    <div className={className || ''}>
      <h2 className="mb-6 text-2xl	font-black">Additional Skills You Will Gain</h2>
      <div className="grid grid-cols-2 grid-flow-row gap-4 md:grid-cols-3 md:gap-6">
        {trimmedSkills.map((skill) => {
          const { fields } = skill;

          if (!fields) {
            return null;
          }

          const { name } = fields;
          return <Skill key={name} skillName={name} />;
        })}
      </div>
    </div>
  );
}
