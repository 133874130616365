import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Bugsnag from '@bugsnag/js';
import Select from 'react-select';
import Loading from '../Loading/Loading';
import Button from '../Button/Button';
import Agents from '../../agents/agents';
import Input from '../FormFields/Input';
import FieldHelpText from '../FormFields/FieldHelpText';
import Icon from '../Icon/Icon';
import GroupSelect from '../Enterprise/GroupSelect';
import fieldClasses from '../FormFields/fieldClasses';

function AttributeMapField({ field, onChange, onDelete, error, disabled, team }) {
  const matchTypeOptions = [
    { value: 'exact', label: 'Exact' },
    { value: 'contains', label: 'Contains' },
    { value: 'startsWith', label: 'Starts With' },
    { value: 'endsWith', label: 'Ends With' },
  ];
  const fieldDisabled = field.cybAdminMapping || disabled;
  const getSelectedValue = (value) => {
    return matchTypeOptions.find((option) => option.value === value);
  };
  const inputFieldClasses = fieldClasses(false, fieldDisabled).replace('py-2', 'py-0');

  return (
    <>
      <div key={field.id} className="flex col-span-2 mb-2">
        <div className="grid row-span-2">
          <Input
            id="attribute"
            type="text"
            disabled={fieldDisabled}
            className={inputFieldClasses}
            name="attribute"
            defaultValue={field.attribute}
            onChange={(e, v) => onChange(field.id, 'attribute', v.value)}
            ariaLabel="Attribute"
          />
          <FieldHelpText helpText="IDP Attribute name" error={error && error.attribute} ariaId="attribute" />
        </div>
      </div>
      <div className="flex col-span-2 mb-2">
        <div className="grid row-span-2">
          <Select
            id="matchType"
            onChange={(newValue) => onChange(field.id, 'matchType', newValue.value)}
            options={matchTypeOptions}
            defaultValue={getSelectedValue(field.matchType)}
            ariaLabel="Attribute Match Type"
            disabled={fieldDisabled}
          />
          <FieldHelpText helpText="Match operator" error={error && error.matchType} ariaId="matchType" />
        </div>
      </div>
      <div className="flex col-span-3 mb-2">
        <div className="grid row-span-2">
          <Input
            className={inputFieldClasses}
            type="text"
            id="attributeValue"
            disabled={fieldDisabled}
            name="attributeValue"
            defaultValue={field.attributeValue}
            onChange={(e, v) => onChange(field.id, 'attributeValue', v.value)}
            ariaLabel="Attribute Value"
          />

          <FieldHelpText helpText="IDP attribute" error={error && error.attributeValue} ariaId="attributeValue" />
        </div>
      </div>
      <div className="flex col-span-4 mb-2 ">
        <div className="grid row-span-2">
          <GroupSelect
            team={team}
            handleGroupSelect={(e) => onChange(field.id, 'teamId', e)}
            selectedGroup={field.teamId}
            placeholder="Select a Group"
            ariaLabel="Team Group"
            disabled={fieldDisabled}
          />
          <FieldHelpText helpText="Assigned Team" error={error && error.teamId} ariaId="TeamId" />
        </div>
      </div>
      <div className="flex col-span-1 mb-2">
        <div className="grid row-span-2">
          <button disabled={disabled} type="button" aria-label="Delete" id={field.id} onClick={!disabled ? onDelete : () => false} className="py-2.5 px-6 cursor-pointer">
            <Icon name="trash" className="w-6 h-6 text-gray-600 group-hover:text-red-500" />
          </button>
          <div>&nbsp;</div>
        </div>
      </div>
    </>
  );
}

function AttributeMap({ org, provider, commonStore }) {
  if (!org || !provider || !provider.team_id || !provider.id) {
    return null;
  }

  const providerId = provider.id;

  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [attributeMap, setAttributeMap] = useState(provider?.attribute_map || []);
  const [serverErrors, setServerErrors] = useState({});

  const addAttributeMapField = () => {
    const uid = uuidv4();
    const newField = {
      id: uid,
      attribute: '',
      matchType: '',
      attributeValue: '',
      teamId: '',
      new: true,
    };

    setAttributeMap([...attributeMap, newField]);
  };

  const parseErrors = (errors) => {
    const prefix = 'attributes';
    const attributeFields = ['attribute', 'matchType', 'attributeValue', 'teamId'];
    const errorMap = [];
    if (errors) {
      attributeMap.forEach((v, index) => {
        attributeFields.forEach((fieldName) => {
          if (errors[`${prefix}.${index}.${fieldName}`]) {
            if (!errorMap[v.id]) {
              errorMap[v.id] = {};
            }
            errorMap[v.id][fieldName] = errors[`${prefix}.${index}.${fieldName}`];
          }
        });
      });
    }
    return errorMap;
  };

  const fetchAttributeMap = async () => {
    setLoading(true);
    try {
      const response = await Agents.enterprise.getSsoAttributeMap(provider.team_id, providerId);

      if (response && response.attribute_map.length > 0) {
        setAttributeMap(response.attribute_map);
      } else {
        addAttributeMapField();
      }
    } catch (error) {
      Bugsnag.notify(error);
    } finally {
      setLoading(false);
    }
  };

  const updateAttributeMap = () => {
    setSubmitting(true);
    Agents.enterprise
      .updateSsoAttributeMap(provider.team_id, providerId, attributeMap)
      .then(async () => {
        await fetchAttributeMap();
        commonStore.triggerToast('success', {
          content: 'Updated Attribute Mapping',
        });
      })
      .catch((err) => {
        if (err && err.response && err.response.status === 422 && err.response.data && err.response.data.errors) {
          setServerErrors(parseErrors(err.response.data.errors));
        } else {
          Bugsnag.notify(err);
        }
        commonStore.triggerToast('error', {
          content: 'Something went wrong. Unable to delete the Attribute Mapping.',
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    updateAttributeMap();
  };

  const clearError = (fieldId) => {
    if (serverErrors) {
      const newState = { ...serverErrors };
      delete newState[fieldId];
      setServerErrors(newState);
    }
  };

  const onChange = (fieldId, field, value) => {
    clearError(fieldId);
    const newState = [...attributeMap];
    const fieldIndex = attributeMap.findIndex((oldField) => oldField.id === fieldId);

    newState[fieldIndex][field] = value;
    setAttributeMap(newState);
  };

  const deleteField = (fieldId) => {
    const newState = [...attributeMap];
    setAttributeMap(newState.filter((field) => field.id !== fieldId));
    updateAttributeMap();
    const field = attributeMap.find((fieldToDelete) => fieldToDelete.id === fieldId);
    if (field) {
      Agents.enterprise.deleteSsoAttributeMap(provider.team_id, providerId, fieldId).then(() => {});
    }
    setServerErrors([]);
  };

  const showDeleteConfirm = (id) => {
    commonStore.triggerConfirm({
      content: 'Are you sure you want to delete this attribute mapping?',
      cancel: () => commonStore.resetConfirmState(),
      continue: () => {
        commonStore.resetConfirmState();
        deleteField(id);
      },
    });
  };

  useEffect(() => {
    fetchAttributeMap();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <form onSubmit={onSubmit} disabled={submitting} className="px-4 pt-4">
      <div className="grid grid-cols-12 gap-x-0.5 justify-items-start pb-4">
        <div className="flex col-span-2 mb-2">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className="block mb-1 font-bold text-gray-800 text-md" htmlFor="attribute">
            Attribute Name
          </label>
        </div>
        <div className="flex col-span-2 mb-2">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className="block mb-1 font-bold text-gray-800 text-md" htmlFor="matchType">
            Match Type
          </label>
        </div>
        <div className="flex col-span-3 mb-2">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className="block mb-1 font-bold text-gray-800 text-md" htmlFor="attributeValue">
            Attribute Value
          </label>
        </div>

        <div className="flex col-span-4 mb-2">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className="block mb-1 font-bold text-gray-800 text-md" htmlFor="teamId">
            Team
          </label>
        </div>
        <div className="flex col-span-1 justify-end ">
          <Button onClick={addAttributeMapField} alt="Add Attribute Mapping">
            <Icon name="plus" className="w-4 h-4" />
          </Button>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-x-1 justify-items-start">
        {attributeMap.map((field) => {
          return <AttributeMapField key={field.id} field={field} onChange={onChange} onDelete={() => showDeleteConfirm(field.id)} error={serverErrors[field.id]} team={org} />;
        })}
      </div>

      <div className="flex justify-start pb-4">
        <Button type="submit">Update</Button>
      </div>
    </form>
  );
}

export default AttributeMap;
