import React from 'react';
import { v4 as uuidv4 } from 'uuid';

function Paths({ active }) {
  if (active) {
    return (
      <path
        d="M27.6552 4.5447C24.5953 1.48492 20.5272 -0.200195 16.2 -0.200195C11.8728 -0.200195 7.80467 1.48492 4.74483 4.5447C1.68512 7.60447 0 11.6726 0 15.9998C0 20.327
            1.68512 24.3951 4.74483 27.4549C7.80467 30.5147 11.8728 32.1998 16.2 32.1998C20.5272 32.1998 24.5953 30.5147 27.6552 27.4549C30.7149 24.3951 32.4 20.327 32.4
            15.9998C32.4 11.6726 30.7149 7.60447 27.6552 4.5447ZM16.2 30.3014C11.9684 30.3014 8.16094 28.4534 5.54015 25.5229C7.16489 21.2156 11.3241 18.1514 16.2
            18.1514C13.0545 18.1514 10.5047 15.6015 10.5047 12.4561C10.5047 9.3106 13.0545 6.76074 16.2 6.76074C19.3455 6.76074 21.8953 9.3106 21.8953 12.4561C21.8953
            15.6015 19.3455 18.1514 16.2 18.1514C21.0759 18.1514 25.2351 21.2156 26.8599 25.5229C24.2391 28.4534 20.4316 30.3014 16.2 30.3014Z"
        fill="black"
        stroke="white"
        strokeWidth="0.4"
      />
    );
  }
  return (
    <path
      d="M27.456 4.5447C24.3961 1.48492 20.328 -0.200195 16.0008 -0.200195C11.6736 -0.200195 7.60545 1.48492 4.54561 4.5447C1.4859 7.60447 -0.199219 11.6726 -0.199219
          15.9998C-0.199219 20.327 1.4859 24.3951 4.54561 27.4549C7.60545 30.5147 11.6736 32.1998 16.0008 32.1998C20.328 32.1998 24.3961 30.5147 27.456 27.4549C30.5157
          24.3951 32.2008 20.327 32.2008 15.9998C32.2008 11.6726 30.5157 7.60447 27.456 4.5447ZM16.0008 30.3014C12.1505 30.3014 8.65117 28.7714 6.07752 26.2881C7.47123
          22.0297 11.4753 19.1006 16.0008 19.1006C20.5262 19.1006 24.5303 22.0297 25.9241 26.2882C23.3504 28.7714 19.8511 30.3014 16.0008 30.3014ZM11.2547 12.4561C11.2547
          9.83906 13.3838 7.70996 16.0008 7.70996C18.6178 7.70996 20.7469 9.83906 20.7469 12.4561C20.7469 15.0731 18.6178 17.2021 16.0008 17.2021C13.3838 17.2021 11.2547
          15.0731 11.2547 12.4561ZM27.3496 24.6917C25.953 21.4242 23.2108 18.9414 19.8966 17.8352C21.5609 16.6265 22.6453 14.6657 22.6453 12.4561C22.6453 8.79226 19.6646
          5.81152 16.0008 5.81152C12.337 5.81152 9.35625 8.79226 9.35625 12.4561C9.35625 14.6657 10.4407 16.6265 12.1051 17.8352C8.79077 18.9414 6.04854 21.4242 4.65192
          24.6917C2.8012 22.281 1.69922 19.2669 1.69922 15.9998C1.69922 8.11389 8.11486 1.69824 16.0008 1.69824C23.8867 1.69824 30.3023 8.11389 30.3023 15.9998C30.3023
          19.2669 29.2004 22.281 27.3496 24.6917Z"
      fill="currentColor"
      stroke="white"
      strokeWidth="0.4"
    />
  );
}

export default function UserIcon({ active, classes, id, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';
  const strokeProps = active ? {} : { stroke: 'currentColor' };
  const myId = `usericon${id || uuidv4()}`;

  return (
    <svg className={className} fill="none" {...strokeProps} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...accessibilityProps}>
      <g clipPath={`url(#${myId})`}>
        <Paths active={active} />
      </g>
      <defs>
        <clipPath id={myId}>
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
