import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import AddLink from '../AddLink/AddLink';
import Container from '../Container/Container';
import FormatUtil from '../../utils/formatUtil';

function PromoBanner({ upgradeLink, data, expirationDate, colorsScheme }) {
  return (
    <AddLink to={upgradeLink} target="_blank">
      <div className="py-2" style={{ background: colorsScheme.background }} id="promo-banner">
        <Container size="lg">
          <div className="flex flex-col justify-between items-center mx-auto w-full md:flex-row">
            <div className="px-4 pt-2 w-full md:p-3 md:w-2/3">
              <div className="m-0 text-lg font-bold md:text-xl lg:text-2xl" style={{ color: colorsScheme.color }}>
                {data.title}
              </div>
              <div className="text-sm" style={{ color: colorsScheme.color }}>
                {data.description} {expirationDate}
              </div>
            </div>
            <div className="px-4 pb-2 w-full md:p-3 md:w-1/2 md:text-right">
              <div className="text-xs" style={{ color: colorsScheme.color }}>
                {data.subDescription}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </AddLink>
  );
}

const PromotionBanner = inject(
  'userStore',
  'commonStore'
)(
  observer(({ userStore, commonStore }) => {
    const userExists = !!userStore.user;
    const userIsFree = !!userStore.isFree;

    useEffect(() => {
      if (userExists && userIsFree) {
        commonStore.fetchPromotionBanners(userStore.isUserWithinWeekOfRegistration, userStore.isUserEnrolledInFreeMonthlyCourseLastWeek, userStore.isEnterprise);
      }
    }, [userExists, userIsFree]);

    const { displayed, banner, which } = commonStore.promotionBanner;
    const location = useLocation() || window.location;
    const isInOnboarding = /onboarding/g.test(location.pathname);
    const isOnTeamsCheckout = /teams-checkout/g.test(location.pathname);
    const showPromoBanner = displayed && banner && !commonStore.isInImmersive && !isInOnboarding && userStore.isFree && !userStore.isEnterprise && !isOnTeamsCheckout;

    if (!userExists || !userIsFree || !showPromoBanner) {
      commonStore.removeCybRouterClass('promo-banner-adjustment');
      return null;
    }

    commonStore.addCybRouterClass('promo-banner-adjustment');
    const colorsScheme = FormatUtil.getColorsScheme(banner);
    const campaign = banner.campaign && banner.campaign !== 'no-campaign' ? `&campaign=${banner.campaign}` : '';
    let params = '?appad=upgradebanner';
    if (campaign) {
      params = params.concat(campaign);
    }
    const { bannerUrl, permalink } = banner;
    /** permalink is also the "promo type"...
     * any custom promo type, will use the direct bannerUrl with no formatting */
    const customPromoPermalinks = ['custom'];
    const isCustomPromo = customPromoPermalinks.includes(permalink);
    const promoLink = isCustomPromo ? bannerUrl : commonStore.getUpgradeLink(params, bannerUrl);

    let expirationDate = '';
    if (which === 'end-of-trial') {
      // End of Trial promotion banner
      expirationDate = userStore.calculateWeekFromDate(userStore.user.registered_at);
    }
    if (which === 'monthly-free-course') {
      expirationDate = userStore.calculateWeekFromDate(userStore.getMonthlyFreeCourseEnrollmentDate());
    }
    return <PromoBanner upgradeLink={promoLink} colorsScheme={colorsScheme} data={banner} expirationDate={expirationDate} />;
  })
);

export default PromotionBanner;
