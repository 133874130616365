import React from 'react';
import { v4 as uuidv4 } from 'uuid';

function Paths({ active }) {
  if (active) {
    return (
      <>
        <path
          d="M4.89394 4C4.89308 4 4.89211 4 4.89124 4C4.58859 4 4.30393 4.11582 4.08898 4.32631C3.87166 4.53912 3.75195 4.82248 3.75195 5.12414V21.9805C3.75195 22.5986
            4.26622 23.1027 4.89846 23.1043C7.56385 23.1105 12.0294 23.6559 15.1101 26.8207V9.18006C15.1101 8.97053 15.0555 8.77369 14.9526 8.6108C12.4242 4.61348 7.56536
            4.00613 4.89394 4Z"
          fill="black"
        />
        <path
          d="M28.2467 21.9806V5.12414C28.2467 4.82248 28.127 4.53912 27.9096 4.32631C27.6947 4.11582 27.4098 4 27.1075 4C27.1065 4 27.1055 4 27.1047 4C24.4334 4.00624
            19.5745 4.61358 17.046 8.61091C16.9431 8.7738 16.8887 8.97064 16.8887 9.18017V26.8207C19.9693 23.6559 24.4349 23.1105 27.1003 23.1043C27.7324 23.1027 28.2467
            22.5986 28.2467 21.9806Z"
          fill="black"
        />
        <path
          d="M30.8554 7.8877H30.0252V21.9808C30.0252 23.559 28.7153 24.846 27.1052 24.8499C24.8444 24.8552 21.1166 25.2892 18.4766 27.7421C23.0426 26.6446 27.8559 27.358
            30.5991 27.9717C30.9416 28.0483 31.2956 27.9692 31.57 27.7545C31.8435 27.5403 32.0003 27.2201 32.0003 26.8765V9.01163C32.0004 8.39191 31.4867 7.8877 30.8554 7.8877Z"
          fill="black"
        />
        <path
          d="M1.97517 21.9808V7.8877H1.14489C0.513726 7.8877 0 8.39191 0 9.01163V26.8762C0 27.2199 0.156876 27.54 0.430331 27.7542C0.704541 27.9688 1.05816 28.0482
            1.40122 27.9714C4.14439 27.3576 8.95788 26.6443 13.5237 27.7418C10.8837 25.2889 7.15596 24.8551 4.89515 24.8498C3.28513 24.846 1.97517 23.559 1.97517 21.9808Z"
          fill="black"
        />
      </>
    );
  }
  return (
    <path
      d="M31.1135 7.10021L28.8423 7.1001V4.9111C28.8423 4.40994 28.4485 3.98415 27.9161 3.91C27.8232 3.897 25.6106 3.59776 22.9463 4.04916C19.9761 4.55235 17.6073
          5.79327 16.0007 7.66922C14.394 5.79327 12.0252 4.55235 9.05515 4.04916C6.39072 3.59787 4.17815 3.897 4.08531 3.91C3.55295 3.98425 3.15913 4.41004 3.15913
          4.9111V7.1001H0.887978C0.287652 7.1001 -0.199219 7.55342 -0.199219 8.11248V27.1884C-0.199219 27.5162 -0.0288193 27.8236 0.258198 28.0136C0.545215 28.2035
          0.913178 28.2523 1.24558 28.1444C1.32718 28.1181 9.47439 25.5292 15.5495 28.1094C15.8362 28.2312 16.1653 28.2312 16.452 28.1094C22.5102 25.5365 30.6748
          28.1183 30.756 28.1444C30.8725 28.1822 30.9931 28.2008 31.1134 28.2008C31.3362 28.2008 31.5568 28.137 31.7434 28.0137C32.0304 27.8237 32.2008 27.5163 32.2008
          27.1885V8.11258C32.2007 7.55353 31.7139 7.10021 31.1135 7.10021ZM1.97528 25.8339V9.12476H3.15913V22.6061C3.15913 22.9003 3.29735 23.1796 3.53637 23.3719C3.77539
          23.5642 4.09328 23.6504 4.40571 23.6074C4.46571 23.5993 9.1889 22.9956 12.6396 25.2C8.38893 24.5232 4.13462 25.3061 1.97528 25.8339ZM14.9135 24.2871C13.3937
          23.0085 11.414 22.1439 9.05515 21.7443C7.91995 21.5519 6.86679 21.4958 6.02461 21.4958C5.77458 21.4958 5.54309 21.5008 5.33374 21.5086V5.8407H5.33364C7.42326
          5.75294 12.5066 5.96096 14.9135 9.7601V24.2871ZM17.0879 9.7602C19.4863 5.97732 24.5775 5.75985 26.6678 5.84324V21.5086C25.7533 21.4743 24.4184 21.4948 22.9463
          21.7443C20.5874 22.1439 18.6078 23.0084 17.0879 24.2871V9.7602ZM19.3582 25.2006C22.8094 22.9949 27.5351 23.5992 27.594 23.6073C27.9072 23.6511 28.2249 23.5654
          28.4646 23.3732C28.7044 23.1808 28.8423 22.9009 28.8423 22.6061V9.12486H30.0262V25.8339C27.8662 25.3058 23.6101 24.5227 19.3582 25.2006Z"
      strokeWidth="0.4"
      fill="currentColor"
      stroke="white"
    />
  );
}

export default function LearningIcon({ accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true }, active, classes, id }) {
  const className = classes || 'w-6 h-6';
  const strokeProps = active ? {} : { stroke: 'currentColor' };
  const myId = `mylearningicon${id || uuidv4}`;
  return (
    <svg className={className} fill="none" {...strokeProps} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...accessibilityProps}>
      <g clipPath={`url(#${myId})`}>
        <Paths active={active} />
      </g>
      <defs>
        <clipPath id={myId}>
          <rect width="32" height="24" fill="white" transform="translate(0 4)" />
        </clipPath>
      </defs>
    </svg>
  );
}
