import React from 'react';

export default function Grid({ classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';
  return (
    <svg className={className} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...accessibilityProps}>
      <path
        d="M5.68191 0.0449219H10.1021V4.46341H5.68191V0.0449219ZM5.68191 5.72683H10.1021V10.1453H5.68191V5.72683ZM0 5.72683H4.41849V10.1453H0V5.72683ZM0 
        0.0449219H4.41849V4.46341H0V0.0449219ZM0.00173229 11.5358H4.41849V15.9554H0.00173229V11.5358ZM5.68364 
        11.5358H10.1021V15.9554H5.68364V11.5358ZM11.5798 0.0449219H16V4.46341H11.5798V0.0449219ZM11.5798 5.72683H16V10.1453H11.5798V5.72683ZM11.5798 
        11.5358H16V15.9554H11.5798V11.5358Z"
        fill="currentColor"
      />
    </svg>
  );
}
