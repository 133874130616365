/* eslint-disable no-console */
import { useEffect, useState } from 'react';
import queryString from 'query-string';
import { SocketConnection } from '../services/SocketConnection';
import { getApiRequest, Infra, Session } from '../agents';
import { findById, findIndexById } from '../utils/helpers';
import { formatLabDetails, convertSecsToHHMMSS } from '../utils/labUtil';
import { useClab } from '../../../providers/ClabProvider';

export default function useLearnerLab() {
  const [userSession, setUserSession] = useState(null);
  const [labSocket, setLabSocket] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [playbook, setPlaybook] = useState(null);
  const [summary, setSummary] = useState(null);
  const [auditLogs, setAuditLogs] = useState(null);
  const [expires, setExpires] = useState(null);
  const [labComplete, setLabComplete] = useState(false);
  const { resources, setResources, lab, setLab, session, token, account, activeResource, setActiveResource } = useClab();
  const [flags, setFlags] = useState({});
  const [completedFlags, setCompletedFlags] = useState([]);
  const [flagNotification, setFlagNotification] = useState(null);

  const accountId = account?.id;

  // Connect to a given resource
  const connect = (resource) => {
    if (!resource) {
      return;
    }

    const indexName = resource.customResourceName || resource.name;

    let index = findIndexById(resources, indexName, 'customResourceName');
    if (index === -1) {
      index = findIndexById(resources, indexName, 'name');
    }
    if (index === -1) {
      return;
    }
    const newResources = [...resources];
    newResources.forEach((res) => {
      const comparisonName = res.customResourceName || res.name;
      if (indexName === comparisonName) {
        res.activeTab = true;
        setActiveResource(indexName);
        res.connected = true;
      } else {
        res.activeTab = false;
        // Completely disconnect from inactive machines
        res.connected = false;
      }
    });
    setResources(newResources);
  };

  // Disconnect from a given resource
  const disconnect = (resource) => {
    if (!lab) {
      return;
    }
    const { resources: res } = lab;
    const indexName = resource.customResourceName || resource.name;
    let index = findIndexById(res, indexName, 'customResourceName');
    if (index === -1) {
      index = findIndexById(res, indexName, 'name');
    }
    if (index === -1) {
      return;
    }
    const newResources = [...res];
    newResources[index].connected = false;
    newResources[index].activeTab = false;
    setActiveResource(null);
    setResources(newResources);
  };

  const loadLab = async (uuid, sesh, forceLoading = false) => {
    setLoading(true);
    try {
      // Load our lab, and format it
      const labDetails = await Infra.getLabById(uuid);
      const { labId } = labDetails.labInstance;
      // Grab the definition as well
      const definition = await getApiRequest(`/lab/${labId}`);
      const formattedLab = formatLabDetails(labDetails, definition);
      formattedLab.sessionId = sesh;
      setLab(formattedLab);
      if (forceLoading) {
        setResources(formattedLab.resources || []);
        // Set the first resource as active
        const firstResource = formattedLab?.resources?.length ? formattedLab.resources[0] : null;
        const firstResourceName = firstResource ? firstResource.customResourceName || firstResource.name : null;
        setActiveResource(firstResourceName);
        setLoading(false);
      }
    } catch (err) {
      console.log(err, 'error loading lab');
      setLoading(false);
      setError(err?.message || 'There was an unknown error loading the lab.');
    }
  };

  const loadUserSession = async (uid, labInstanceIdentifier) => {
    try {
      // Load our lab, and format it
      const sessionResult = await Session.getSessionByUserAndLabId(uid, labInstanceIdentifier);
      if (sessionResult && sessionResult.userSessionId) {
        const sessionDetails = await Session.getSession(sessionResult.userSessionId);
        setUserSession(sessionDetails);
      }
    } catch (err) {
      console.log(err, 'error loading session');
    }
  };

  const beginLab = (id) => {
    // Reset our audit logs and summary
    setAuditLogs([]);
    setSummary(null);
    return Infra.beginLab(id);
  };

  const endLab = async (id) => {
    // Reset our audit logs and summary, set error message to display
    setAuditLogs([]);
    setSummary(null);
    setLab(null);
    try {
      await Infra.deleteLab(id);
    } catch (err) {
      console.log(err, 'error ending lab');
    }
    setError('Your lab session has ended. To start a new one, refresh this page and click Start Lab.');
  };

  const addFlag = (flag) => {
    const flagCopy = { ...flag };
    flagCopy.time = convertSecsToHHMMSS(flagCopy['time-seconds-elapsed']);
    setFlags((currentFlags) => {
      const flagsCopy = { ...currentFlags };
      flagsCopy[flag['activity-id']] = flagCopy;
      return flagsCopy;
    });
    // This function is only ever called from a completed flag, so always add it to the completed flags array
    setCompletedFlags((currentCompletedFlags) => {
      const newCompletedFlags = [...currentCompletedFlags];
      newCompletedFlags.push(flagCopy);
      return newCompletedFlags;
    });
    setFlagNotification(() => flagCopy);
  };

  const formatResources = (sources = []) => {
    return sources.map((source) => {
      const connectData = source['connect-string'] ? queryString.parse(source['connect-string']) : {};
      return { ...source, ...connectData };
    });
  };

  const addPlaybook = (data) => {
    // Only set these state vals if there isn't a value already -- Prevents un-needed set states if playbook is sent multiple times
    setPlaybook((currentPlaybook) => (!currentPlaybook ? data : currentPlaybook));
    if (data?.module?.activities) {
      setFlags((currentFlags) => (!Object.keys(currentFlags).length ? data.module.activities : currentFlags));
    }
    setLoading(() => false);
    if (data?.resources) {
      const connectableResources = data.resources.filter((resource) => resource.connectable && resource['connect-string']);
      if (connectableResources && connectableResources.length) {
        const formattedResources = formatResources([...data.resources]);
        setResources((currentResources) => (!currentResources.length ? formattedResources : currentResources));
      }
    }
  };

  const addAuditLog = (data) => {
    const { payload, timestamp } = data;
    const { user, description, 'log-level': level } = payload;
    const { email, 'random-name': randomName } = user;

    const newLog = {
      timestamp,
      description,
      email,
      randomName,
      level,
    };

    setAuditLogs((currentAuditLogs) => {
      const auditLogsCopy = currentAuditLogs ? [...currentAuditLogs] : [];
      auditLogsCopy.push(newLog);
      return auditLogsCopy;
    });
  };

  // Control our timer
  const startTimer = () => {
    const durationSeconds = playbook && playbook['time-limit-minutes'] ? playbook['time-limit-minutes'] * 60 : 30 * 60;
    // Going to hardcode a countdown from a few minutes
    const time = new Date();
    time.setSeconds(time.getSeconds() + durationSeconds);
    setExpires(time);
  };

  const handleLabComplete = (summaryData) => {
    setSummary(() => summaryData);
    setLabComplete(() => true);
    setExpires(() => null);
  };

  const handleResourceUpdate = (res, eventPayload) => {
    const { outputs, status: labStatus } = eventPayload;
    if (!res || !res.length || !outputs || !outputs.length) {
      return res;
    }
    const isLabRunning = labStatus === 'running';
    // Ok, we have new status fields to update, let's create a new resources array and handle that
    const newResources = [...res];
    for (let i = 0; i < newResources.length; i++) {
      const { labResourceIdentifier, status } = newResources[i];
      if (labResourceIdentifier) {
        const foundResource = findById(outputs, labResourceIdentifier, 'resourceIdentifier', null);
        if (foundResource && foundResource.status !== status) {
          // If the lab is NOT running, but our resource is, set the resource status to booting
          newResources[i].status = foundResource.status === 'running' && !isLabRunning ? 'booting' : foundResource.status;
        }
      }
    }
    return newResources;
  };

  const openLabSocket = () => {
    const socket = new SocketConnection(`${process.env.REACT_APP_CLAB_API_URL}/clab`);
    setLabSocket(socket);
    // start socket
    socket.subscribe('/user/topic/lab', (message) => {
      try {
        const msg = JSON.parse(message.body);
        const { payload, type } = msg || {};
        if (!payload) {
          return;
        }

        // Handle different message types received via WS
        switch (type) {
          case 'c2_playbook':
            addPlaybook(payload);
            break;
          case 'c2_flag':
            addFlag(payload);
            break;
          case 'c2_summary':
            if (payload.completed) {
              handleLabComplete(payload);
            }
            break;
          case 'c2_audit':
            addAuditLog(msg);
            break;
          case 'lab_status':
            // Update resource and lab statuses
            setResources((res) => {
              return handleResourceUpdate(res, payload);
            });
            setLab((item) => {
              return item ? { ...item, status: payload.status } : null;
            });
            break;
          default:
            break;
        }
        // Check for the terminate status
        if (payload.terminate) {
          setError(() => 'Your lab session has ended. To start a new one, refresh this page and click Start Lab.');
        }
      } catch (e) {
        console.log(e, 'error parsing message');
      }
    });
    socket.connect(`${session}`, `${token}`, accountId);
  };

  useEffect(() => {
    if (lab && !labSocket) {
      openLabSocket();
    }
    // Disconnect if we have lost the lab and we still have a socket open
    if (!lab && labSocket) {
      console.log(new Date(), 'CALLING DISCONNECT FROM WITHIN THE USE EFFECT', labSocket);
      labSocket.disconnect();
    }
  }, [lab]);

  useEffect(() => {
    return () => {
      if (labSocket) {
        console.log(new Date(), 'CALLING DISCONNECT FROM WITHIN THE USE EFFECT EXIT FUNCTION', lab, labSocket);
        labSocket.disconnect();
      }
    };
  }, [labSocket]);

  return {
    loadLab,
    lab,
    loading,
    error,
    connect,
    disconnect,
    beginLab,
    flags,
    completedFlags,
    flagNotification,
    playbook,
    resources,
    activeResource,
    expires,
    labComplete,
    startTimer,
    handleLabComplete,
    auditLogs,
    summary,
    endLab,
    loadUserSession,
    userSession,
  };
}
