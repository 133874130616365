import React from 'react';

export default function Sparkler({ classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';
  return (
    <svg className={className} {...accessibilityProps} viewBox="0 0 21 20" fill="currentColor" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.4375 8.75C8.12937 10.2372 8.625 16.875 8.625 16.875C8.625 16.875 9.12063 10.2372 15.8125 8.75C9.12063 7.26281 8.625 0.625 8.625 0.625C8.625 0.625 8.12937 7.26281 1.4375 8.75Z"
        fill="black"
      />
      <path
        d="M11.4375 15C12.4916 15.2032 13.4535 15.7368 14.184 16.5234C14.9144 17.31 15.3753 18.3088 15.5 19.375C15.6247 18.3088 16.0856 17.31 16.816 16.5234C17.5465 15.7368 18.5084 15.2032 19.5625 15C18.5084 14.7968 17.5465 14.2632 16.816 13.4766C16.0856 12.69 15.6247 11.6912 15.5 10.625C15.3753 11.6912 14.9144 12.69 14.184 13.4766C13.4535 14.2632 12.4916 14.7968 11.4375 15Z"
        fill="black"
      />
    </svg>
  );
}
