import React from 'react';
import moment from 'moment';
import { twMerge } from 'tailwind-merge';
import Icon from '../Icon/Icon';
import AdminTable from '../AdminTable/AdminTable';
import StatSection from './StatSection';
import Tooltip from '../Tooltip/Tooltip';
import AddLink from '../AddLink/AddLink';
import FormatUtil from '../../utils/formatUtil';

function StatSectionContainer(props) {
  const { header, headerClasses, headerTextClasses, tabularView, linkAriaLabel, type, displayData, className, href, reportLinkIcon, tooltip, orgId, loading, error } = props;
  let headingWrapperClasses = `flex items-center px-4 pt-3`;
  if (header) {
    if (tabularView) {
      headingWrapperClasses = twMerge(headingWrapperClasses, 'mb-4');
    }
    headingWrapperClasses = twMerge(headingWrapperClasses, 'justify-between', 'pb-3 border-b-xs border-gray-400');
  } else {
    headingWrapperClasses = twMerge(headingWrapperClasses, 'justify-end');
  }

  const getLinkAriaLabel = () => {
    if (!linkAriaLabel) {
      return `${header || type} breakdown`;
    }
    return linkAriaLabel;
  };

  const tableHeadings = displayData ? displayData.columns : [];
  const tableData = displayData ? displayData.tableData : [];
  const isDemo = orgId === 'demo';
  return (
    <div className={twMerge(`stat-container`, className)}>
      <div className={twMerge(headingWrapperClasses, headerClasses)}>
        {header && <h3 className={twMerge('text-lg font-bold', headerTextClasses)}>{header}</h3>}
        <div className="print:hidden flex gap-x-2 items-center stat-links">
          {/** Always show link icons in demo view even if no href is provided */}
          {(href || isDemo) && (
            <AddLink className={`text-cyb-pink-500 hover:text-black ${isDemo ? 'cursor-not-allowed' : ''}`} to={isDemo ? null : href} aria-label={getLinkAriaLabel()}>
              <Icon className="w-4 h-4 text-cyb-pink-500 fill-current" name={reportLinkIcon || 'line-graph'} />
            </AddLink>
          )}
          {tooltip && (
            <Tooltip triggerContent={<Icon name="question-circle" className="w-5 h-5 text-gray-600 hover:text-black" style={{ marginLeft: '0.5rem' }} />} content={tooltip} />
          )}
        </div>
      </div>
      {tabularView ? (
        <AdminTable
          wrapperClassName="m-0"
          headings={tableHeadings}
          data={tableData}
          tableLoading={loading}
          tableError={error}
          formatColumns={[
            {
              method: (col) => (col.value ? moment(col.value).format('MM/DD/YYYY') : ''),
              colIdx: displayData ? FormatUtil.getColIndex(displayData.columns, 'period') : -1,
            },
          ]}
          noResultsMsg={<p className="p-4">No data to display during this time period</p>}
        />
      ) : (
        <StatSection {...props} />
      )}
    </div>
  );
}

export default StatSectionContainer;
