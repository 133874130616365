import React, { useState } from 'react';
import moment from 'moment';
import AddLink from '../AddLink/AddLink';
import Modal from '../Modal/Modal';
import CheckboxBoolean from '../DynamicForm/CheckboxBoolean';
import Icon from '../Icon/Icon';

function PreviousButton({ previous, gotoPreviousActivity, disableNav = false }) {
  const previousCourseAriaLabel = previous.id ? { 'aria-label': `To Previous Activity ${previous.title}` } : {};
  const disabled = !previous.url || disableNav;
  const previousClasses = disabled ? 'text-gray-400 cursor-not-allowed' : 'cursor-pointer hover:text-black';

  return (
    <AddLink
      onClick={!disabled ? gotoPreviousActivity : null}
      {...previousCourseAriaLabel}
      className={`bg-white hover:bg-gray-100 border-xs border-gray-400 rounded-sm p-3 border-collapse mr-2 ${previousClasses}`}
    >
      <Icon name="chevron-left" className="w-4 h-4" />
    </AddLink>
  );
}

function NextButton({ next, complete, goToNextActivity, isLast, optional, emptyChallenge, disabled, forceNormalButton = false }) {
  const pinkButtonClasses = 'flex items-center bg-cyb-pink-500 hover:bg-cyb-pink-600 hover:text-white border rounded px-5 py-2 text-white cursor-pointer';

  // Default Active arrow
  let buttonState = {
    text: null,
    icon: true,
    className: `bg-white hover:bg-gray-100 border-xs border-gray-400 rounded-sm p-3 border-collapse cursor-pointer`,
    nextCourseAriaLabel: next.id ? { 'aria-label': `To Next Activity ${next.title}` } : { 'aria-label': `To Complete Page` },
  };
  if (!forceNormalButton && disabled) {
    buttonState = {
      ...buttonState,
      className: `bg-white hover:bg-gray-100 border-xs border-gray-400 rounded-sm p-3 border-collapse text-gray-400 cursor-not-allowed`,
    };
  } else if (!forceNormalButton && complete && isLast) {
    // Active complete
    buttonState = {
      text: 'Complete',
      icon: true,
      className: pinkButtonClasses,
      nextCourseAriaLabel: { 'aria-label': `Completed` },
    };
  } else if (!forceNormalButton && ((complete && !isLast) || emptyChallenge || optional)) {
    // Active Continue
    buttonState = {
      ...buttonState,
      text: 'Continue',
      icon: true,
      className: pinkButtonClasses,
    };
  }

  return (
    <AddLink onClick={!disabled ? goToNextActivity : null} className={buttonState.className} {...buttonState.nextCourseAriaLabel}>
      {buttonState.text && <span className="font-bold">{buttonState.text}</span>}
      {buttonState.icon && <Icon name="chevron-right" className="ml-1 w-4 h-4" />}
    </AddLink>
  );
}

function PreviousNextButtons({
  activity,
  previous,
  next,
  navigate,
  completedActivityId,
  isLast,
  isPopup,
  isItemLastActivity,
  gotoCompletePage,
  emptyChallenge,
  completeActivity,
  activeInterstitial,
  setActiveInterstitial,
  disableNext,
  isMobile,
  setIsPrimarySidebarOpen = () => {}, // default in case this is not passed in in old immersive
  setDisableSidebar = () => {}, // default in case this is not passed in in old immersive
  disableNav = false,
  onCompletePage = false,
}) {
  const { id: activityId, optional } = activity;
  const isLastActivity = isLast || isItemLastActivity();
  const complete = completedActivityId === activityId || activeInterstitial;

  const [continueModal, setContinueModal] = useState(false);
  const [hideContinue, setHideContinue] = useState(window.localStorage.getItem('hideContinue'));

  const setHideContinueDate = (data) => {
    if (data && data.value) {
      const futureDate = moment().add(1, 'M').format('MM-DD-YYYY');
      setHideContinue(futureDate);
      window.localStorage.setItem('hideContinue', futureDate);
    } else {
      setHideContinue(null);
      window.localStorage.removeItem('hideContinue');
    }
  };

  const continueAnyway = () => {
    if (!onCompletePage && isLastActivity) {
      gotoCompletePage();
    } else if (next.url) {
      navigate(next.url);
    } else {
      navigate('/?finishedActivities=1');
    }
  };

  const closeInterstitial = () => {
    // show and enable sidebar when leaving an interstitial if on desktop
    // this needs to be done here in response to the click, rather than the activeInterstitial state change
    setIsPrimarySidebarOpen(!isMobile);
    setDisableSidebar(false);
    setActiveInterstitial();
  };

  const goToNextActivity = () => {
    // When an interstitial is open, the next button
    // should close the interstitial first
    if (activeInterstitial) {
      return closeInterstitial();
    }
    const daysRemaining = hideContinue ? moment().diff(hideContinue, 'days') : 0;
    if (daysRemaining === 0 && !complete && !emptyChallenge && !optional) {
      setContinueModal(!continueModal);
      return null;
    }

    if (emptyChallenge && !complete) {
      completeActivity();
    }

    return continueAnyway();
  };

  const gotoPreviousActivity = () => {
    if (activeInterstitial) {
      closeInterstitial();
    }
    navigate(previous.url);
  };

  const maybeGoToNextActivity = () => {
    if (!disableNext) {
      return goToNextActivity();
    }
    // If this is an upgrade interstitial (disabledNext is true), we want to clear the interstitial and call continueAnyway
    // This won't be called if we are on the last item and we are using a disabled next button, since the button will be disabled
    if (activeInterstitial) {
      closeInterstitial();
    }
    return continueAnyway();
  };

  // Disable next is set if we are showing the upgrade interstitial.
  // Change that behavior to only show
  // a disabled next button if we are on showing upgrade AND on the last lesson.
  const nextActuallyDisabled = (disableNext && isLastActivity) || disableNav;

  const position = isPopup ? 'bottom' : 'top right';
  const modalClassName = isPopup ? 'fixed inset-x-0 bottom-0 w-full rounded-0' : 'mt-6';

  return (
    <>
      {previous && <PreviousButton previous={previous} gotoPreviousActivity={gotoPreviousActivity} disableNav={disableNav} />}

      <Modal
        position={position}
        open={continueModal}
        toggle={() => setContinueModal(!continueModal)}
        ariaLabelledBy="continue-confirmation"
        className={`bg-cyb-blue-500 py-6 px-4 ${modalClassName}`}
        closeXClassName="text-white flex items-center justify-center w-7 h-7"
        transparentBg
        omitMargin
        omitRounded={isPopup}
        paddingBottom="pb-0"
        sizeClasses={isPopup ? 'none' : 'md:w-80'}
      >
        <div className="w-60">
          <p className="text-sm font-medium text-white">
            You still have incomplete tasks in this activity. Note that you are required to complete the tasks in order to receive credit for this activity
          </p>

          <CheckboxBoolean
            id="dont-show"
            name="dont-show-modal"
            defaultValue={false}
            onChange={(e, data) => setHideContinueDate(data)}
            label="Don't show this message again"
            labelClass="text-sm text-white"
            inputClasses="form-tick inline-block p-3 appearance-none checked:bg-black bg-white border border-gray-400"
            innerWrapperClass="flex items-center"
          />
        </div>
        <div className="flex justify-end mt-6">
          <AddLink onClick={continueAnyway} className="py-2.5 px-6 text-sm text-black hover:text-black bg-white rounded-sm border border-white">
            Continue Anyway
          </AddLink>
        </div>
      </Modal>
      {next && (
        <NextButton
          disabled={nextActuallyDisabled}
          next={next}
          complete={complete}
          optional={optional}
          goToNextActivity={maybeGoToNextActivity}
          isLast={isLast}
          emptyChallenge={emptyChallenge}
          forceNormalButton={disableNext}
        />
      )}
    </>
  );
}

export default PreviousNextButtons;
