import React, { useMemo, useEffect } from 'react';
import { twMerge } from 'tailwind-merge';
import ABMProvider, { useABM } from '../../providers/ABMProvider';
import Container from '../../components/Container/Container';
import TopBadgesEarnedWidget from '../../components/ABM/TopBadgesEarnedWidget';
import TopEnrollmentsWidget from '../../components/ABM/TopEnrollmentsWidget';
import TrendingContentWidget from '../../components/ABM/TrendingContentWidget';
import TopCertPrepProgramsWidget from '../../components/ABM/TopCertPrepProgramsWidget';
import TopCertBodiesWidget from '../../components/ABM/TopCertBodiesWidget';
import CompanySkillsTrackerWidget from '../../components/ABM/CompanySkillsTrackerWidget';
import ABMEmailVerificationWidget from '../../components/ABM/ABMEmailVerificationWidget';
import ABMTeamMetrics from '../../components/ABM/ABMTeamMetrics';
import ActionUtil from '../../utils/actionsUtil';
import Header from '../../components/Header/Header';

/**
 * Abstract styling for each row in the ABM page
 * @param {JSX} children - The children to be rendered in the row
 * @param {boolean} solo - Whether there is a single child in the row
 * @param {boolean} alignStart - Whether the container should align items start
 * @returns {JSX} - The rendered row
 */
function ABMRow({ children, solo, alignStart }) {
  const containerClasses = useMemo(() => {
    let classes = 'flex flex-col-reverse lg:flex-row';

    if (solo) {
      classes = twMerge(classes, 'gap-x-4');
    } else {
      classes = twMerge(classes, 'gap-4');

      if (alignStart) {
        classes = twMerge(classes, 'items-start');
      }
    }

    return classes;
  }, []);

  return (
    <Container size="full" className={containerClasses} omitPadding>
      {children}
    </Container>
  );
}

/**
 * Abstract styling for the left column in the ABM page
 * @param {JSX} children - The children to be rendered in the left column
 * @param {boolean} alignStart - Whether the container should align items start
 * @returns {JSX} - The rendered left column
 */
function ABMLeftColumn({ children, alignStart, className }) {
  const containerClasses = useMemo(() => {
    let classes = 'flex gap-4';

    if (alignStart) {
      classes = twMerge(classes, 'items-start');
    }

    if (className) {
      classes = twMerge(classes, className);
    }

    return classes;
  }, []);

  return (
    <Container size="3/5" className={containerClasses} omitPadding>
      {children}
    </Container>
  );
}

/**
 * Abstract styling for the right column in the ABM page
 * @param {JSX} children - The children to be rendered in the right column
 * @returns {JSX} - The rendered right column
 */
function ABMRightColumn({ children }) {
  return (
    <Container size="2/5" className="flex gap-4" omitPadding>
      {children}
    </Container>
  );
}

function ABMTitle() {
  const { company } = useABM();
  return (
    <Header as="h1" className="mb-4">
      {company?.name || 'Your'} Team
    </Header>
  );
}

function ABMPage() {
  useEffect(() => {
    return () => {
      ActionUtil.scrollToTop();
    };
  }, []);

  /**
   * ABM Page
   */
  return (
    <Container size="md-1280" className="flex flex-col gap-4 pt-4">
      <ABMProvider>
        {/** First Row */}
        <ABMRow>
          {/** Team Metrics */}
          <ABMLeftColumn className="flex-col justify-end">
            <ABMTitle />
            <ABMRow>
              <ABMTeamMetrics />
            </ABMRow>
          </ABMLeftColumn>
          {/** Verification Widget */}
          <ABMRightColumn>
            <ABMEmailVerificationWidget />
          </ABMRightColumn>
        </ABMRow>
        {/** Second Row */}
        <ABMRow>
          {/** Trending Courses Widget */}
          <ABMLeftColumn>
            <TrendingContentWidget dataSetKey="trending_courses" />
          </ABMLeftColumn>
          {/** Badges Widget */}
          <ABMRightColumn>
            <TopBadgesEarnedWidget />
          </ABMRightColumn>
        </ABMRow>
        {/** Third Row */}
        <ABMRow solo>
          {/** Top Enrollments Widget */}
          <ABMLeftColumn>
            <TopEnrollmentsWidget />
          </ABMLeftColumn>
          <ABMRightColumn />
        </ABMRow>
        {/** Fourth Row */}
        <ABMRow solo>
          {/** Company Skills Tracker Widget */}
          <ABMLeftColumn>
            <CompanySkillsTrackerWidget />
          </ABMLeftColumn>
          <ABMRightColumn />
        </ABMRow>
        {/** Fifth Row */}
        <ABMRow solo>
          {/** Staying Ahead of Threats Widget */}
          <ABMLeftColumn>
            <TrendingContentWidget dataSetKey="trending_staying_ahead" />
          </ABMLeftColumn>
          <ABMRightColumn />
        </ABMRow>
        {/** Sixth Row */}
        <ABMRow solo>
          <ABMLeftColumn alignStart>
            {/** Top Certification Bodies Widget Placeholder */}
            <TopCertBodiesWidget />
            {/** Top Certification Prep Programs Widget Placeholder */}
            <TopCertPrepProgramsWidget />
          </ABMLeftColumn>
          <ABMRightColumn />
        </ABMRow>
      </ABMProvider>
    </Container>
  );
}

export default ABMPage;
