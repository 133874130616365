import React, { useState, useEffect } from 'react';
import { debounce } from 'lodash';
import fieldClasses from './fieldClasses';
import FieldHelpText from './FieldHelpText';
import Agent from '../../agents/agents';
import Loading from '../Loading/Loading';

let mapBoxDebounce = null;

function Address(props) {
  const { name, defaultValue, disabled, description, placeholder, onChange, error } = props;
  let defaultInput = '';
  // we are supporting two types of default values... legacy is an object, new way is a string
  if (typeof defaultValue === 'string') {
    defaultInput = defaultValue;
  } else if (defaultValue && defaultValue.format) {
    defaultInput = defaultValue.format;
  }

  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState(defaultInput);
  const [inputError, setError] = useState(error);
  const [predictions, setPredictions] = useState([]);

  const handleOnBlur = () => {
    setTimeout(() => {
      setPredictions([]);
    }, 750);
  };

  useEffect(() => {
    mapBoxDebounce = debounce((value) => {
      Agent.location
        .mapBoxGeocoding(value)
        .then((response) => {
          const allLocations = response.body.features.map((location) => {
            return {
              id: location.id,
              name: location.place_name,
              lng: location.center[0],
              lat: location.center[1],
            };
          });

          if (!allLocations.length) {
            setError('Enter a valid city, state, or country');
          }

          setError(null);
          setPredictions(allLocations);
          setLoading(false);
        })
        .catch(() => {
          setPredictions([]);
          setError('An error occurred , Please try again');
        });
    }, 650);
  }, []);

  const onLocationClick = (location) => {
    setInput(location);
    setPredictions([]);
    onChange(undefined, {
      name,
      value: location,
    });
  };

  const handleOnChange = (value) => {
    if (value && value.length > 3) {
      setLoading(true);
      setPredictions([]);
      mapBoxDebounce(value);
    }
    setInput(value);
    onChange(undefined, {
      name,
      value,
    });
  };

  return (
    <>
      <input
        disabled={disabled || false}
        id={name}
        className={fieldClasses(error, disabled)}
        type="text"
        placeholder={placeholder}
        value={input}
        onChange={(e) => handleOnChange(e.target.value)}
        onBlur={handleOnBlur}
      />
      {predictions.length ? (
        <div className="absolute z-30 pb-2 w-full text-left bg-white">
          <ul className="shadow">
            {predictions.map((locationItem) => (
              <li key={locationItem.id} className="py-2 px-3 hover:bg-gray-200 border cursor-pointer">
                <button type="button" onClick={() => onLocationClick(locationItem.name)}>
                  {locationItem.name}
                </button>
              </li>
            ))}
          </ul>
        </div>
      ) : null}
      {loading && (
        <div className="absolute z-30 pb-2 w-full text-left bg-white shadow-lg">
          <Loading message="Loading..." />
        </div>
      )}

      <FieldHelpText helpText={description} error={inputError} />
    </>
  );
}

export default Address;
