import React, { createContext, useContext, useState, useMemo, useEffect } from 'react';
import Bugsnag from '@bugsnag/js';
import { inject, observer } from 'mobx-react';
import agents from '../agents/agents';

const ABMContext = createContext();

/**
 * Provider for ABM Page
 * @param {ReactElement} children - the children to render
 * @returns {ReactElement} - the rendered children
 */

const ABMProvider = inject('commonStore')(
  observer(({ children, commonStore }) => {
    // ABM Team
    const [abmTeam, setAbmTeam] = useState(null); // the marketing team object from the server
    const [isABMTeamLoading, setIsABMTeamLoading] = useState(true); // whether or not the marketing team is loading
    const [abmTeamError, setABMTeamError] = useState(null); // the error from fetching the marketing team

    // Leave Team Loading/Error States
    const [isLeaveTeamLoading, setIsLeaveTeamLoading] = useState(false);
    const [leaveTeamError, setLeaveTeamError] = useState('');

    /**
     * Fetch the ABM team from the server.
     *
     * This is a two step process -
     * first we get the company from api-auth to see if we have a team
     * then we get the full team data from the api-courses
     */
    const fetchABMTeam = async () => {
      setIsABMTeamLoading(true);
      setABMTeamError(null);
      setAbmTeam(null);
      try {
        const companyResponse = await agents.abm.getCompany();

        // If we don't have a company, we don't have a team, set the team to null and return
        if (!companyResponse?.team?.id) {
          setAbmTeam(null);
          return;
        }
        // Get data from API Courses if we're verified and merge it with the api-auth data
        if (companyResponse.verified) {
          try {
            companyResponse.dashboardData = await agents.abm.getDashboardData(companyResponse.team.id);
          } catch (error) {
            Bugsnag.notify(error);
          }
        }
        setAbmTeam(companyResponse);
      } catch (error) {
        Bugsnag.notify(error);
        setABMTeamError(error);
      } finally {
        setIsABMTeamLoading(false);
      }
    };

    // Load the company/marketing team/abm team/idk wtf this is called anymore man just load the thing
    useEffect(() => {
      fetchABMTeam();
    }, []);

    /**
     * Leaves the team by calling the delete endpoint
     * @returns {Promise<boolean>} - whether or not the user left the team
     */
    const leaveTeam = async () => {
      return new Promise((resolve) => {
        commonStore.triggerConfirm({
          content: 'Are you sure you want to leave this team?',
          confirmBtn: 'Yes, Leave Team',
          continue: async () => {
            commonStore.resetConfirmState();
            setIsLeaveTeamLoading(true);
            try {
              await agents.abm.deleteCompany();
              setAbmTeam(null);
              resolve(true);
            } catch (error) {
              Bugsnag.notify(error);
              setLeaveTeamError(error);
              resolve(false);
            } finally {
              setIsLeaveTeamLoading(false);
            }
          },
          cancel: () => {
            resolve(false);
            commonStore.resetConfirmState();
          },
        });
      });
    };

    // values returned to be used in the user's profile page
    const values = useMemo(
      () => ({
        // ABM Team Data
        abmTeam,
        isABMTeamLoading,
        abmTeamError,
        fetchABMTeam,
        // Leave Team function and loading/error states
        leaveTeam,
        isLeaveTeamLoading,
        leaveTeamError,
        // Start Abstractions for ease of use
        isVerified: abmTeam?.verified,
        isVerificationExpired: abmTeam?.verified_at && new Date(abmTeam.verified_at) < new Date(Date.now() - 6 * 30 * 24 * 60 * 60 * 1000),
        company: abmTeam?.team,
        dashboardData: abmTeam?.dashboardData,
      }),
      [abmTeam, abmTeamError, isABMTeamLoading, fetchABMTeam, leaveTeam, isLeaveTeamLoading, leaveTeamError]
    );

    return <ABMContext.Provider value={values}>{children}</ABMContext.Provider>;
  })
);

// Hook to use the ABM context
export const useABM = () => useContext(ABMContext);

// Export the provider
export default ABMProvider;
