import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import queryString from 'query-string';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from '../../components/Header/Header';
import Container from '../../components/Container/Container';
import ConversationLinks from '../../components/ConversationLinks/ConversationLinks';

const InsiderProLinkPage = inject(
  'authStore',
  'commonStore',
  'userStore'
)(
  observer(({ authStore, commonStore, userStore }) => {
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
      if (!userStore.isCip) {
        navigate('/');
      } else {
        authStore.fireAttributionEvent();
        const queryParams = location.search ? queryString.parse(location.search) : {};
        const freshCip = !!(queryParams.refreshUser || queryParams.fresh);
        if (freshCip) {
          // trigger toaster to display
          commonStore.triggerToast('success', {
            content: 'Your Insider Pro membership has been activated!',
          });
          // remove the query string param so we don't fire this twice on a reload
          navigate('/cybrary-insider-pro', { replace: true });
        }
      }
    }, []);

    const triggerChat = () => {
      window.zE('webWidget', 'open');
    };

    const retData = [
      {
        heading: 'Get Started: Join an Orientation Session',
        text: 'Sign up for an orientation session with the Cybrary team.',
        href: 'https://calendly.com/insider-pro/insider-pro-orientation',
        target: '_blank',
        icon: 'paper-plane',
      },
      {
        heading: 'Discover your Career Path',
        text: 'Enroll in a career path aligned to your industry experience from beginner to advanced.',
        href: '/browse/refined?view=careerPath',
        target: '_blank',
        icon: 'road',
      },
      {
        heading: 'Join the Discord Community',
        text:
          'The place for established and up-and-coming IT and cybersecurity professionals to connect with each other and mentors to exchange ideas about the industry, ' +
          'the technologies, best practices, or anything and everything IT and cybersecurity related!',
        href: '/community',
        icon: 'discord',
      },
      {
        heading: 'Connect with a Mentor',
        text: 'Mentors are valuable in helping you get started in your journey. Join the discord community to connect with more than 50 mentors ready to work one-on-one with you.',
        href: '/community',
        icon: 'handshake',
      },
      {
        heading: 'Continue Learning',
        text: 'Access your Career Path, Path, Virtual Labs, Video Courses, Assessments and more!',
        href: '/my-learning/career-paths',
        icon: 'graduation-cap',
      },
      {
        heading: 'Chat with the Cybrary team',
        text: 'Not sure where to start? Have more questions about Cybrary? Reach out to the Customer Success Team!',
        action: triggerChat,
        icon: 'question-circle',
      },
    ];

    return (
      <Container className="insider-homepage" size="md">
        <div className="my-4 w-3/4">
          <Header as="h2"> Welcome to Cybrary Insider Pro Home</Header>
          <p>
            Welcome to Cybrary Insider Pro - Whether you are developing your career through a Career Path, preparing for a certification, skilling up to earn a raise, or find a new
            job, thank you for joining and giving us the opportunity to guide you to your next job or career goal.
          </p>
        </div>
        <ConversationLinks data={retData} />
      </Container>
    );
  })
);

export default InsiderProLinkPage;
