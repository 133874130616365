import React from 'react';
import { Fade } from 'react-awesome-reveal';
import ProfileStatCard from '../../../Card/ProfileStatCard';
import LearningProgressChart from './LearningProgressChart';

/**
 * @param {number} completedLearningHours - number of completed learning hours
 * @param {number} completedCourses - number of completed courses
 * @param {number} completedLabs - number of completed labs
 * @param {number} passedAssessments - number of passed assessments
 * @param {boolean} isLoading - boolean indicating whether the stats are loading
 * @param {string} username - username for the user's profile we are viewing
 * @returns <LearningProgressTab /> the learning progress tab contents
 */
function LearningProgressTab({ completedLearningHours, completedCourses, completedLabs, passedAssessments, isLoading, username }) {
  const profileCardClasses = 'max-w-[200px] mb-8';
  return (
    <Fade duration={500} triggerOnce>
      <div className="flex flex-wrap justify-center items-center mt-8 w-full md:justify-between">
        <ProfileStatCard value={completedLearningHours} iconName="book" title="TOTAL LEARNING HOURS" isLoading={isLoading} className={`md:mr-8 ${profileCardClasses}`} />
        <ProfileStatCard value={completedCourses} iconName="edit" title="COURSES COMPLETED" color="purple-500" isLoading={isLoading} className={`md:mr-8 ${profileCardClasses}`} />
        <ProfileStatCard value={completedLabs} iconName="flask" title="LABS COMPLETED" color="blue-500" isLoading={isLoading} className={`md:mr-8 ${profileCardClasses}`} />
        <ProfileStatCard value={passedAssessments} iconName="check" title="ASSESSMENTS COMPLETED" color="yellow-500" isLoading={isLoading} className={profileCardClasses} />
      </div>
      <div>
        <LearningProgressChart username={username} />
      </div>
    </Fade>
  );
}
export default LearningProgressTab;
