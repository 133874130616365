import moment from 'moment';

const DateRangeOptions = {
  today: {
    text: 'Today',
    startDate: moment(),
    endDate: moment(),
  },
  '7days': {
    text: 'Last 7 Days',
    startDate: moment().subtract(7, 'd'),
    endDate: moment(),
  },
  '30days': {
    text: 'Last 30 Days',
    startDate: moment().subtract(30, 'd'),
    endDate: moment(),
  },
  '3months': {
    text: 'Last 3 Months',
    startDate: moment().subtract(3, 'months'),
    endDate: moment(),
  },
  '6months': {
    text: 'Last 6 Months',
    startDate: moment().subtract(6, 'months'),
    endDate: moment(),
  },
  year: {
    text: 'Last Year',
    startDate: moment().subtract(1, 'y'),
    endDate: moment(), // from same day
  },
  monthToDate: {
    text: 'Month to Date',
    startDate: moment().startOf('month'),
    endDate: moment(),
  },
  yearToDate: {
    text: 'Year to Date',
    startDate: moment().startOf('year'),
    endDate: moment(),
  },
  groupCreation: {
    text: 'Group Creation',
    startDate: moment('2015-01-01'),
    endDate: moment(),
  },
  allTime: {
    text: 'All-Time',
    startDate: moment('2015-01-01'),
    endDate: moment(),
  },
};

export default DateRangeOptions;
