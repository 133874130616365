import React from 'react';
import { v4 as uuidv4 } from 'uuid';

function Paths({ active, id }) {
  if (active) {
    const myId = `slackicon${id || uuidv4()}`;
    return (
      <>
        <g clipPath={`url(#${myId})`}>
          <path
            d="M12.8433 16.6514C11.4513 16.6514 10.3223 17.7804 10.3223 19.1724V25.4784C10.3223 26.8704 11.4513 27.9994 12.8433 27.9994C14.2353 27.9994 15.3643 26.8704
              15.3643 25.4784V19.1724C15.3633 17.7804 14.2343 16.6514 12.8433 16.6514Z"
            fill="currentColor"
          />
          <path
            d="M4.01953 19.1724C4.01953 20.5654 5.14953 21.6954 6.54253 21.6954C7.93553 21.6954 9.06553 20.5654 9.06553 19.1724V16.6494H6.54453C6.54353 16.6494
              6.54353 16.6494 6.54253 16.6494C5.14953 16.6494 4.01953 17.7794 4.01953 19.1724Z"
            fill="currentColor"
          />
          <path
            d="M12.8463 3.99902C12.8453 3.99902 12.8443 3.99902 12.8433 3.99902C11.4503 3.99902 10.3203 5.12902 10.3203 6.52202C10.3203 7.91502 11.4503 9.04502 12.8433
              9.04502H15.3643C15.3643 7.61402 15.3643 7.96202 15.3643 6.51702C15.3633 5.12602 14.2363 3.99902 12.8463 3.99902Z"
            fill="currentColor"
          />
          <path
            d="M6.52495 15.3702H12.843C14.236 15.3702 15.366 14.2402 15.366 12.8472C15.366 11.4542 14.236 10.3242 12.843 10.3242H6.52495C5.13195 10.3242 4.00195 11.4542
              4.00195 12.8472C4.00195 14.2402 5.13195 15.3702 6.52495 15.3702Z"
            fill="currentColor"
          />
          <path
            d="M25.4575 10.3232C24.0665 10.3232 22.9395 11.4502 22.9395 12.8412V12.8462V15.3692H25.4605C26.8535 15.3692 27.9835 14.2392 27.9835 12.8462C27.9835 11.4532
              26.8535 10.3232 25.4605 10.3232C25.4595 10.3232 25.4585 10.3232 25.4575 10.3232Z"
            fill="currentColor"
          />
          <path
            d="M16.6406 6.52198V12.847C16.6406 14.239 17.7696 15.368 19.1616 15.368C20.5536 15.368 21.6826 14.239 21.6826 12.847V6.52198C21.6826 5.12998 20.5536 4.00098
              19.1616 4.00098C17.7696 4.00098 16.6406 5.12998 16.6406 6.52198Z"
            fill="currentColor"
          />
          <path
            d="M21.6826 25.4761C21.6826 24.0841 20.5536 22.9551 19.1616 22.9551H16.6406V25.4781C16.6416 26.8691 17.7696 27.9971 19.1616 27.9971C20.5536 27.9971 21.6826
              26.8681 21.6826 25.4761Z"
            fill="currentColor"
          />
          <path
            d="M25.4797 16.6494H19.1617C17.7687 16.6494 16.6387 17.7794 16.6387 19.1724C16.6387 20.5654 17.7687 21.6954 19.1617 21.6954H25.4797C26.8727 21.6954 28.0027
              20.5654 28.0027 19.1724C28.0027 17.7794 26.8727 16.6494 25.4797 16.6494Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id={myId}>
            <rect width="24" height="24" fill="white" transform="translate(4 4)" />
          </clipPath>
        </defs>
      </>
    );
  }
  return (
    <path
      d="M20.8008 15.9998V13.5998C20.8008 13.1251 20.9415 12.6611 21.2053 12.2664C21.469 11.8718 21.8438 11.5641 22.2823 11.3825C22.7209 11.2008 23.2034 11.1533 23.669
          11.2459C24.1346 11.3385 24.5622 11.5671 24.8978 11.9027C25.2335 12.2384 25.4621 12.666 25.5547 13.1316C25.6473 13.5971 25.5997 14.0797 25.4181 14.5182C25.2364
          14.9568 24.9288 15.3316 24.5341 15.5953C24.1395 15.859 23.6755 15.9998 23.2008 15.9998H20.8008ZM20.8008 15.9998H16.0008H20.8008ZM20.8008 15.9998V7.1998C20.8008
          6.56329 20.5479 5.95284 20.0978 5.50275C19.6478 5.05266 19.0373 4.7998 18.4008 4.7998C17.7643 4.7998 17.1538 5.05266 16.7037 5.50275C16.2536 5.95284 16.0008
          6.56329 16.0008 7.1998V15.9998H20.8008ZM16.0008 15.9998V11.1998V15.9998ZM16.0008 15.9998H7.20078C6.56426 15.9998 5.95381 15.7469 5.50372 15.2969C5.05364 14.8468
          4.80078 14.2363 4.80078 13.5998C4.80078 12.9633 5.05364 12.3528 5.50372 11.9027C5.95381 11.4527 6.56426 11.1998 7.20078 11.1998H16.0008V15.9998ZM16.0008
          15.9998H24.8008C25.4373 15.9998 26.0478 16.2527 26.4978 16.7027C26.9479 17.1528 27.2008 17.7633 27.2008 18.3998C27.2008 19.0363 26.9479 19.6468 26.4978
          20.0969C26.0478 20.5469 25.4373 20.7998 24.8008 20.7998H16.0008V15.9998ZM16.0008 15.9998V20.7998V15.9998ZM16.0008 15.9998H11.2008H16.0008ZM16.0008
          15.9998V24.7998C16.0008 25.4363 15.7479 26.0468 15.2978 26.4969C14.8478 26.9469 14.2373 27.1998 13.6008 27.1998C12.9643 27.1998 12.3538 26.9469 11.9037
          26.4969C11.4536 26.0468 11.2008 25.4363 11.2008 24.7998V15.9998H16.0008ZM16.0008 11.1998V8.7998C16.0008 8.32513 15.86 7.86111 15.5963 7.46644C15.3326
          7.07176 14.9578 6.76414 14.5192 6.58249C14.0807 6.40084 13.5981 6.35332 13.1326 6.44592C12.667 6.53852 12.2394 6.7671 11.9037 7.10275C11.5681 7.43839 11.3395
          7.86603 11.2469 8.33159C11.1543 8.79714 11.2018 9.2797 11.3835 9.71825C11.5651 10.1568 11.8727 10.5316 12.2674 10.7953C12.6621 11.059 13.1261 11.1998 13.6008
          11.1998H16.0008ZM16.0008 20.7998H18.4008C18.8755 20.7998 19.3395 20.9406 19.7341 21.2043C20.1288 21.468 20.4364 21.8428 20.6181 22.2814C20.7997 22.7199 20.8473
          23.2025 20.7547 23.668C20.6621 24.1336 20.4335 24.5612 20.0978 24.8969C19.7622 25.2325 19.3346 25.4611 18.869 25.5537C18.4034 25.6463 17.9209 25.5988 17.4823
          25.4171C17.0438 25.2355 16.669 24.9279 16.4053 24.5332C16.1415 24.1385 16.0008 23.6745 16.0008 23.1998V20.7998ZM11.2008 15.9998V18.3998C11.2008 18.8745 11.06
          19.3385 10.7963 19.7332C10.5326 20.1279 10.1578 20.4355 9.71922 20.6171C9.28068 20.7988 8.79812 20.8463 8.33256 20.7537C7.86701 20.6611 7.43937 20.4325 7.10373
          20.0969C6.76808 19.7612 6.5395 19.3336 6.4469 18.868C6.35429 18.4025 6.40182 17.9199 6.58347 17.4814C6.76512 17.0428 7.07273 16.668 7.46741 16.4043C7.86209
          16.1406 8.32611 15.9998 8.80078 15.9998H11.2008Z"
      stroke="currentColor"
      strokeWidth="1.6"
    />
  );
}

export default function TeamsIcon({ active, classes, id, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';
  return (
    <svg className={className} fill="none" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...accessibilityProps}>
      <Paths active={active} id={id} />
    </svg>
  );
}
