import React, { useEffect } from 'react';

export default function ScreenClose({ isOpen, handleClose }) {
  const close = (e) => {
    if (e && e.key === 'Escape' && isOpen) {
      handleClose();
    }
  };
  useEffect(() => {
    // if open add eventListener, else remove it
    window.addEventListener('keydown', close);
    return () => {
      window.removeEventListener('keydown', close);
    };
  }, [isOpen]);

  return !isOpen ? null : (
    <button onClick={handleClose} className="fixed inset-0 z-10 w-full h-full cursor-default">
      &nbsp;
    </button>
  );
}
