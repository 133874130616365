import React from 'react';
import Icon from '../Icon/Icon';
import AddLink from '../AddLink/AddLink';
import Tooltip from '../Tooltip/Tooltip';
import { HELP_DESK_LINKS } from '../../constants';

function ReportHeadingTooltip() {
  return (
    <Tooltip
      interactive
      html={
        <p>
          Progress and Completion information is updated on different schedules for each vendor on the platform. Click{' '}
          <AddLink className="text-cyb-pink-500 hover:text-black underline" to={HELP_DESK_LINKS.VENDOR_REPORTING_PROGRESS} target="_blank" rel="noopener noreferrer">
            here
          </AddLink>{' '}
          for more information.
        </p>
      }
    >
      <Icon name="question-circle" className="inline-block print:hidden w-5 h-5 text-gray-600 hover:text-black" />
    </Tooltip>
  );
}

export default ReportHeadingTooltip;
