import React, { createContext, useContext, useState, useMemo } from 'react';

const ImmersiveTasksContext = createContext();

/**
 * Basic provider to make legacy tasks in immersive have state
 * that is elevated (and accessible) beyond the local challenge component.
 */
function ImmersiveTasksProvider({ children }) {
  const [values, setValues] = useState({});

  // Reset to the original state, for when we load a new activity
  const reset = () => {
    setValues({});
  };

  const state = useMemo(
    () => ({
      values,
      setValues,
      reset,
    }),
    [values]
  );

  return <ImmersiveTasksContext.Provider value={state}>{children}</ImmersiveTasksContext.Provider>;
}

export const useImmersiveTasks = () => useContext(ImmersiveTasksContext);
export default ImmersiveTasksProvider;
