import React, { useEffect, useState } from 'react';
import Bugsnag from '@bugsnag/js';
import { observer, inject } from 'mobx-react';
import moment from 'moment';
import useAdminBulkImport from '../../hooks/useAdminBulkImport';
import FormatUtil from '../../utils/formatUtil';
import DynamicForm from '../DynamicForm/DynamicForm';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import Collapsible from '../Collapsible/Collapsible';
import PercentageBar from '../Analytics/PercentageBar';

function ImportUploadErrors({ errors }) {
  if (!errors || !errors.length) {
    return null;
  }

  return (
    <div className="inline-block py-2 px-4 mb-8 text-sm text-cyb-red-700 rounded-sm border-xs border-cyb-red-700">
      <h4 className="mb-2 text-base font-bold leading-4">Error</h4>
      {errors.map((err) => {
        return (
          <p key={err} className="mb-0">
            {err}
          </p>
        );
      })}
    </div>
  );
}

function ImportItem({ data, getImportCsv, importTypeDisplay }) {
  const [itemExpanded, setItemExpanded] = useState(false);
  const creationDate = moment(data.created_at).format('M/DD/YYYY');
  const progress = Math.floor((data.processed / data.total) * 100);
  const skippedKeys = data.skipped ? Object.keys(data.skipped) : [];
  const skippedImports = skippedKeys.map((key) => data.skipped[key]);
  const skippedImportsLength = skippedImports.length;
  return (
    <div className="pb-4 mt-8 mb-4 border-b-xs last:border-b-0 border-gray-400 xl:pb-0 xl:mt-0 xl:border-b-0">
      <div key={data.id} className="flex flex-col gap-y-2 items-start my-2 text-sm xl:flex-row xl:gap-x-8 xl:gap-y-0 xl:items-center">
        <p className="mb-0 w-16">{creationDate}</p>
        <div className="flex gap-x-4 justify-between items-center w-64">
          <div className="overflow-hidden w-full h-1 bg-gray-300 rounded-lg">
            <PercentageBar color="#DF057B" maxHeight="0.5em" width={`${progress || 0}%`} />
          </div>
          <p className="shrink-0 mb-0 w-24 text-xs">{progress}% Complete</p>
        </div>
        <p className="mb-0 w-40">
          {importTypeDisplay}s {importTypeDisplay === 'invite' ? 'sent' : 'completed'}: {data.processed - skippedImportsLength}
        </p>
        <Button className="grow-0" color="gray" onClick={() => getImportCsv(data.id, moment(data.created_at).format('MM-DD-YYYY'))} ariaLabel="Download this import CSV">
          <Icon name="download" className="m-0 w-4 h-4" />
        </Button>
        {!!skippedImportsLength && (
          <button onClick={() => setItemExpanded(!itemExpanded)} className="flex items-center">
            <p className="flex gap-x-2 items-center mb-0 text-sm font-semibold text-cyb-red-700">
              <Icon name="exclamation-circle" /> {skippedImportsLength} skipped {FormatUtil.pluralize(skippedImportsLength, importTypeDisplay)}
            </p>
            <span className="sr-only">Click to view details</span>
            <Icon className="ml-2 w-4 h-4" name={`chevron-${itemExpanded ? 'up' : 'down'}`} />
          </button>
        )}
      </div>
      {!!skippedImportsLength && (
        <Collapsible open={itemExpanded} ariaLabel={`Skipped ${importTypeDisplay}s ${creationDate}`}>
          <p className="mb-2 text-sm font-bold">Skipped {importTypeDisplay}s:</p>
          <ul className="mb-4 list-disc list-inside">
            {skippedImports.map((item) => {
              return (
                <li key={item} className="mb-2 ml-2 text-sm">
                  {item}
                </li>
              );
            })}
          </ul>
        </Collapsible>
      )}
    </div>
  );
}

function BulkImportList({ imports, getImportCsv, importTypeDisplay, logDescription }) {
  if (!imports || !imports.length) {
    return null;
  }
  return (
    <div>
      <h4 className="mb-2 font-bold leading-4 capitalize">Bulk {importTypeDisplay} Log</h4>
      <p className="text-xs">{logDescription}</p>
      {imports.map((item) => {
        return <ImportItem data={item} key={item.id} getImportCsv={getImportCsv} importTypeDisplay={importTypeDisplay} />;
      })}
    </div>
  );
}

const getImportForm = (submitText) => {
  return {
    order: ['downloadCSV', ['csv', 'submit']],
    fields: {
      csv: {
        type: 'singleFileUpload',
        acceptedTypes: '.csv',
        placeholder: 'Click here, or drop CSV here',
        ariaLabel: 'Import CSV',
      },
      submit: {
        type: 'button',
        className: 'capitalize',
        color: 'pink',
        label: submitText || 'Submit',
      },
      downloadCSV: {
        type: 'button',
        color: 'gray',
        ariaLabel: 'Download CSV template',
        icon: { name: 'download', className: 'mr-0 w-5 h-5 float-right', position: 'right' },
        label: 'Download Template',
        flex: true,
      },
    },
  };
};

const AdminBulkImport = inject('commonStore')(
  observer(({ commonStore, teamId, importConfig, importTypeDisplay, formConfig = {} }) => {
    const { getTemplateCSV, startImport, imports, watchImports, getPreviousImportCSV } = useAdminBulkImport(importConfig.routesObject, importConfig.importType);
    const [uploadErrors, setUploadErrors] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const hasRequiredProps = !!teamId && !!importConfig;

    useEffect(() => {
      // Check for active imports
      if (hasRequiredProps) {
        watchImports(teamId);
      }
    }, [teamId]);

    if (!hasRequiredProps) {
      return null;
    }

    const getCSVTemplate = async (event) => {
      event.preventDefault();
      try {
        await getTemplateCSV(importConfig.templateFileName, teamId);
      } catch (e) {
        Bugsnag.notify(e);
        commonStore.triggerToast('error', { content: 'Something went wrong. Unable to retrieve import template at this time.' });
      }
    };

    const postImportCSV = async (data) => {
      setUploadErrors(null);
      setIsSubmitting(true);
      try {
        await startImport(teamId, data, importConfig.dataKey);
        // Start polling
        watchImports(teamId);
        setIsSubmitting(false);
      } catch (e) {
        if (e && e.response && e.response.status === 422) {
          const errors = e.response.data && e.response.data.errors ? e.response.data.errors : {};
          const errorKeys = Object.keys(errors);
          const errorsArray = errorKeys.map((key) => errors[key]);
          setUploadErrors(errorsArray);
        } else {
          Bugsnag.notify(e);
          commonStore.triggerToast('error', { content: 'Something went wrong. Unable to process this import. Please try again.' });
        }
        setIsSubmitting(false);
      }
    };

    const handleSubmit = (data) => {
      if (importConfig.confirmText) {
        commonStore.triggerConfirm({
          content: importConfig.confirmText,
          continueBtn: formConfig.submitText || 'Continue',
          cancelBtn: 'Cancel',
          continue: () => {
            postImportCSV(data);
            commonStore.resetConfirmState();
          },
          cancel: () => commonStore.resetConfirmState(),
        });
      } else {
        postImportCSV(data);
      }
    };

    const importForm = { ...getImportForm(formConfig.submitText) };
    importForm.fields.downloadCSV.onClick = (e) => getCSVTemplate(e);
    importForm.fields.submit.disabled = isSubmitting;

    return (
      <div>
        <DynamicForm form={importForm} formName={formConfig.formName || 'bulkImport'} onSubmit={handleSubmit} customClassName="mt-0" />
        {formConfig.instructions || ''}
        <ImportUploadErrors errors={uploadErrors} />
        <BulkImportList
          imports={imports}
          importTypeDisplay={importTypeDisplay}
          getImportCsv={(id, date) => getPreviousImportCSV(teamId, id, date, importConfig.templateFileName)}
          logDescription={importConfig.logDescription}
        />
      </div>
    );
  })
);

export default AdminBulkImport;
