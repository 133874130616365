import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import moment from 'moment';
import Icon from '../Icon/Icon';
import ContentHero from './ContentHero';
import ContentBody from './ContentBody';
import FeedbackModal from '../Feedback/FeedbackModal';
import FormatUtil from '../../utils/formatUtil';
import BrowseUtil from '../../utils/browseUtil';
import StyleUtil from '../../utils/styleUtil';
import PermalinkUtil from '../../utils/permalinkUtil';
import InstructorCard, { VendorCard } from '../Item/InstructorCard';
import GoalSection from './GoalSection';
import ContentBadge from './ContentBadge';

function IncludedInList({ items, niceName }) {
  if (!items || !items.length || !items.map) {
    return null;
  }

  return (
    <>
      <div className="mt-2 mb-1 font-semibold text-black">{niceName}</div>
      <ul className="list-inside">
        {items.map((item) => {
          return (
            <li key={item.title}>
              <a className="text-black hover:text-cyb-pink-500 underline hover:underline" href={PermalinkUtil.formatInApp(item.permalink, item.type)}>
                {item.title}
              </a>
            </li>
          );
        })}
      </ul>
    </>
  );
}

function ComprehensiveLearning({ comprehensiveCourses, comprehensiveCareerPaths }) {
  if ((!comprehensiveCourses || !comprehensiveCourses.length) && (!comprehensiveCareerPaths || !comprehensiveCareerPaths.length)) {
    return null;
  }

  return (
    <div className="mt-4 mb-8">
      <h3 className="text-2xl font-black">Comprehensive Learning</h3>
      <p className="mb-4 text-gray-600">See the full benefits of our immersive learning experience with interactive courses and guided career paths.</p>
      <IncludedInList items={comprehensiveCareerPaths} niceName="Career Paths" />
      <IncludedInList items={comprehensiveCourses} niceName="Courses" />
    </div>
  );
}

function getCollectionPageCtaText(type, item) {
  switch (item?.nice_name) {
    case 'Certification Prep':
      return `This ${FormatUtil.formatContentType(type)} is part of Certification Prep for:${' '}`;
    case 'Cybrary Select':
      return `This ${FormatUtil.formatContentType(type)} is part of a Cybrary Select Path:${' '}`;
    default:
      return `This ${FormatUtil.formatContentType(type)} is part of a ${item.nice_name}:${' '}`;
  }
}

function Collection({ collection, type }) {
  if (!collection || !collection.length) return null;

  // remove any duplicate collections by permalink
  const deDuppedCollection = FormatUtil.dedupArrayOfObjects(collection, 'permalink');

  // map over array, make sure no duplicates, make sure no TAP... only Career Path and Certification Prep
  return deDuppedCollection.map((item, index) => {
    const href = `/browse/${item.permalink}`;

    // if there is more then one collection, don't add the padding top
    const paddingTop = index > 0 ? '' : 'pt-12';
    const collectionText = getCollectionPageCtaText(type, item);

    // return all dedupped collections that aren't Threat Actor Path
    return item.nice_name !== 'Threat Actor Path' ? (
      <div key={item.permalink} className={`${paddingTop} mb-4 lg:mb-0`}>
        <p className="flex items-center pt-4">
          <Icon name="road" className="mr-2 w-6 h-6" />
          <div className="flex items-center">
            <p className="pr-2 mb-0">{collectionText}</p>
            <a href={href} className="ml-1 text-black hover:text-cyb-pink-500 underline hover:underline">
              {item.title}
            </a>
          </div>
        </p>
      </div>
    ) : null;
  });
}

function ProviderCardWrapper({ provider }) {
  if (provider.name === 'Cybrary') {
    return null;
  }
  if (provider.type === 'Instructor') {
    return (
      <InstructorCard
        key={provider.name}
        type={provider.type}
        name={provider.name}
        thumbnail={provider.image || provider.thumbnail}
        permalink={provider.permalink}
        title={provider.title}
        short_bio={provider.short_bio}
        instructor_level={provider.instructor_level || provider.type}
      />
    );
  }
  if (['Vendor', 'Alliance'].indexOf(provider.type) !== -1) {
    return <VendorCard key={provider.name} name={provider.name} image={provider.image || provider.thumbnail} href={provider.permalink} shortDescription={provider.short_bio} />;
  }
  return null;
}

function ProvidersSections({ providers }) {
  if (!providers || !providers.length) {
    return null;
  }

  const providersObj = {
    instructors: [],
    alliances: [],
    voiceOverArtists: [],
    others: [],
  };
  // Get each provider type in own array
  providers.forEach((provider) => {
    let providerType = 'others';
    if (provider.type && provider.type === 'Instructor' && provider.instructor_level !== 'Voice Over Artist') {
      // Display Instructor as Instructor
      if (!providersObj.instructors.length) {
        providersObj.instructors.push(
          <h3 key="instructed by" className="text-2xl font-black">
            Instructor
          </h3>
        );
      }
      providerType = 'instructors';
    } else if (provider.type && provider.type === 'Instructor' && provider.instructor_level === 'Voice Over Artist') {
      // Display Instructor as Voice Over Artist if Instructor Level is Voice Over Artist
      if (!providersObj.voiceOverArtists.length) {
        providersObj.voiceOverArtists.push(
          <h3 key="voice over by" className="text-2xl font-black">
            Voice Over Artist
          </h3>
        );
      }
      providerType = 'voiceOverArtists';
    } else if (provider.type && provider.type === 'Alliance') {
      // Display Alliance as Provider
      if (!providersObj.alliances.length) {
        providersObj.alliances.push(
          <h3 key="delivered by" className="text-2xl font-black">
            Provider
          </h3>
        );
      }
      providerType = 'alliances';
    } else if (!providersObj.others.length && provider.name !== 'Cybrary') {
      // Display Learning Partner Label for vendors except when Cybrary is Vendor...
      providersObj.others.push(
        <h3 key="learning partner" className="text-2xl font-black">
          Learning Partner
        </h3>
      );
    }
    providersObj[providerType].push(<ProviderCardWrapper provider={provider} key={provider.name} />);
  });
  return Object.keys(providersObj).map((provider) => {
    if (!providersObj[provider].length) {
      return null;
    }
    return (
      <div className="mt-4 mb-8" key={provider}>
        {providersObj[provider]}
      </div>
    );
  });
}

function DynamicCert({ groupsInfo }) {
  const certCopy = BrowseUtil.getCertifications();
  const onlyCertifications = BrowseUtil.checkForCertifications(groupsInfo);
  const newCertCopy = onlyCertifications.length ? BrowseUtil.omitDynamicCertifications(onlyCertifications, certCopy) : null;
  if (newCertCopy && newCertCopy.length) {
    return (
      <div className="mt-4 mb-8">
        <h3 className="text-2xl font-black">Certification Body</h3>
        {newCertCopy.map((cert) => {
          return <VendorCard key={cert.name} name="Certification Body" image={cert.imgPath} />;
        })}
      </div>
    );
  }
  return null;
}

function CourseComponentsWrapper({ hasMultipleModalities, modalities, userIsFree, courseIsFree }) {
  if (!hasMultipleModalities || !modalities) {
    return null;
  }
  return (
    <div className="mt-4 mb-8">
      <h3 className="text-2xl font-black">Course Components</h3>
      <CourseComponents modalities={modalities} userIsFree={userIsFree} courseIsFree={courseIsFree} />
    </div>
  );
}

function CourseComponents({ modalities, userIsFree, courseIsFree }) {
  return Object.keys(modalities).map((modality, idx) => {
    const modalityData = modalities[modality];
    const modalityMeta = FormatUtil.getModalityMeta(modality);
    if (!modalityMeta.description) {
      return null;
    }
    let borderPink = false;
    if (modality === 'Video') {
      // If this modality is a Video and the course is NOT free, but user is = pink icon
      if (!courseIsFree && userIsFree) {
        borderPink = true;
      }
    } else if (modalityData.hasPaidActivity && userIsFree) {
      // If this modality is NOT a Video and modality has a paid activity, but user is free = pink icon
      borderPink = true;
    }
    return (
      <React.Fragment key={modality}>
        <div className="mt-4 mb-8">
          <div className="grid grid-cols-12 gap-4">
            <div className="flex col-span-4 justify-center items-center">
              <div className={`w-12 h-12 rounded-full flex items-center justify-center bg-gray-600 ${borderPink ? 'border-2 border-cyb-pink-500' : ''}`}>
                <Icon name={modalityMeta.icon} className="w-6 h-6 text-white" />
              </div>
            </div>
            <div className="flex col-span-8 items-center text-sm">
              <span>{modalityMeta.description}</span>
            </div>
          </div>
        </div>
        {idx !== Object.keys(modalities).length - 1 ? <div className="w-full h-1 border-b-xs border-gray-400" /> : null}
      </React.Fragment>
    );
  });
}

function CertCompletion({ certificationOfferText }) {
  if (!certificationOfferText) {
    return null;
  }
  return (
    <div className="mt-4 mb-8">
      <h3 className="text-2xl font-black">Certificate of Completion</h3>
      <div className="p-4 my-4 rounded-sm border-xs border-gray-400">
        <img
          height="200"
          width="300"
          src="https://images.ctfassets.net/kvf8rpi09wgk/7a9Hi5dmSSnzuNJqMTcqUC/6fc52822c1bfab1989c1ed3949c2f390/Cybrary-Cert.png?h=400"
          alt=""
          className="mx-auto"
        />
        <p className="pt-2 text-gray-600">{certificationOfferText}</p>
      </div>
    </div>
  );
}

function getContentDescriptionId(item) {
  return (item && item.content_item && item.content_item.content_description_id) || null;
}

const BrowsePromo = inject(
  'userStore',
  'authStore',
  'commonStore'
)(
  observer(
    class BrowsePromo extends Component {
      componentDidMount() {
        this.props.enrollmentStore.getEnrollmentData(this.props.id); // Get Enrollment data using ID
      }

      /**
       * get the background style for the app page header and label style
       * @params type
       */
      getTypeStyle = (type) => {
        let metaImage = null;
        if (!!this.props.item && !!this.props.item.content_item && !!this.props.item.content_item.meta && !!this.props.item.content_item.meta.backgroundImage) {
          metaImage = this.props.item.content_item.meta.backgroundImage;
        } else if (this.props.bgImage) {
          metaImage = this.props.bgImage;
        }
        return StyleUtil.getTypeStyle(type, metaImage);
      };

      createLabels = (labels) => {
        const otherCategories = {};
        const categories = {};
        const lowerNiceNist = 'workrole';

        labels.forEach((label, idx) => {
          const seperateLabel = labels[idx].split('|'); // splits term tags array into category/term(s)
          const labelsStr = seperateLabel[1].trim();
          let category = seperateLabel[0];
          if (category.trim().toLowerCase() === lowerNiceNist) {
            category = lowerNiceNist;
          } else {
            category = seperateLabel[0].trim();
          }

          if (!otherCategories[category]) {
            otherCategories[category] = [];
          }
          otherCategories[category].push(labelsStr);
        });

        // Need nice nist and competency area in own object to be displayed separately at render
        if (otherCategories['Competency Area']) {
          categories['Competency Area'] = otherCategories['Competency Area'];
          delete otherCategories['Competency Area'];
        }

        // Need nice nist and competency area in own object to be displayed separately at render
        if (otherCategories[lowerNiceNist]) {
          categories[lowerNiceNist] = otherCategories[lowerNiceNist];
          delete otherCategories[lowerNiceNist];
        }

        const returnLabels = {
          otherCategories,
          categories,
        };
        return returnLabels;
      };

      render() {
        const {
          id,
          item,
          type,
          title,
          duration,
          level,
          ceu,
          shortDescription,
          syllabus,
          handleEnroll,
          courseId,
          courseProgress,
          userType,
          provider,
          bgImage,
          learningActivities,
          isBookmarked,
          userIsFree,
          isUserOnActiveTeam,
          collection,
          description,
          handleItemClick,
          certificate,
          meta,
          additionalMaterials,
          prereqs,
          certificationOfferText,
          courseIsFree,
          enrollmentStore,
          authStore,
          userStore,
          commonStore,
          comprehensiveCourses,
          comprehensiveCareerPaths,
          contentTypeDurations,
        } = this.props;
        const typeStyle = this.getTypeStyle(type);
        const comingSoon = item && item.status ? item.status === 'Coming Soon' : false;
        let noInstructor = true;
        if (provider && provider.length) {
          provider.forEach((providerItem) => {
            if (providerItem.type && providerItem.type === 'Instructor') {
              noInstructor = false;
            }
          });
        }
        const backgroundImage = !!typeStyle && typeStyle.backgroundImage ? typeStyle.backgroundImage : bgImage;
        // If we have a cert, or we are coming soon with no instructor, we are displaying a second column
        const showSecondColumn = !!(certificationOfferText || comingSoon || !noInstructor || provider.length);
        const bookmarkCount = item.bookmarks_count ? item.bookmarks_count : 0;
        const ratingsAverage = item.average_rating ? item.average_rating : 0;
        const ratingsCount = item.rating_count ? item.rating_count : 0;
        const enrollmentCount = item.enrollment_count ? item.enrollment_count : 0;
        const groupsInfo = item.groups_info ? item.groups_info : null;
        const activityTypes = FormatUtil.getActivityTypes(learningActivities);
        if (Object.keys(activityTypes).indexOf('Article Activity') > -1) {
          // We don't care about articles in this case
          delete activityTypes['Article Activity'];
        }
        const modalities = FormatUtil.getModalitiesByContentType(activityTypes);
        const offersLabs = FormatUtil.checkOffersLabs(activityTypes);
        const hasMultipleModalities = Object.keys(modalities).length > 1;
        const enrollmentData = enrollmentStore.enrollmentData ? enrollmentStore.enrollmentData : null;
        const contentDescriptionId = getContentDescriptionId(item);

        return (
          <div className="app-page">
            <div style={{ backgroundImage: `url(${backgroundImage})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover' }}>
              <div className="bg-black bg-gradient-to-r from-black lg:bg-black/60">
                <div className="mx-auto max-w-screen-xl">
                  <ContentHero
                    title={title}
                    type={type}
                    typeStyle={typeStyle}
                    id={id}
                    comingSoon={comingSoon}
                    time={duration}
                    difficulty={level}
                    shortDescription={shortDescription}
                    ceu={ceu}
                    syllabus={syllabus}
                    handleEnroll={handleEnroll}
                    item={item}
                    courseId={courseId}
                    progress={courseProgress}
                    userType={userType}
                    isBookmarked={isBookmarked}
                    bookmarkCount={bookmarkCount}
                    ratingsAverage={ratingsAverage}
                    ratingsCount={ratingsCount}
                    enrollmentCount={enrollmentCount}
                    offersLabs={offersLabs}
                    enrollmentStore={enrollmentStore}
                    authStore={authStore}
                    userStore={userStore}
                    commonStore={commonStore}
                    replacedBy={item.replacedBy}
                    replacing={item.replacing}
                    contentTypeDurations={contentTypeDurations}
                  />
                </div>
              </div>
            </div>
            <div className="mx-auto max-w-screen-xl">
              <div className="grid grid-cols-1 lg:grid-cols-6 lg:gap-8">
                {/* DESKTOP DISPLAY */}
                <div className="hidden col-span-1 px-4 lg:block lg:col-span-4">
                  <Collection collection={collection} type={type} />
                  <ContentBody
                    prereqs={prereqs}
                    userType={userType}
                    userIsFree={userIsFree}
                    isUserOnActiveTeam={isUserOnActiveTeam}
                    courseIsFree={courseIsFree}
                    learningActivities={learningActivities}
                    description={description}
                    shortDescription={shortDescription}
                    meta={meta}
                    additionalMaterials={additionalMaterials}
                    comingSoon={comingSoon}
                    courseId={courseId}
                    typeStyle={typeStyle}
                    enrollmentData={enrollmentData}
                    progress={courseProgress}
                    certificate={certificate}
                    certificationOfferText={certificationOfferText}
                    handleItemClick={handleItemClick}
                  />
                </div>
                {showSecondColumn ? (
                  <div className="hidden col-span-2 px-4 pt-12 lg:block">
                    <ContentBadge badgeId={item.badge_id} />
                    <GoalSection groupsInfo={groupsInfo} type={type} userType={userType} permalink={item.permalink} contentDescriptionId={contentDescriptionId} />
                    <ProvidersSections providers={provider} />
                    <DynamicCert groupsInfo={groupsInfo} />
                    <CourseComponentsWrapper hasMultipleModalities={hasMultipleModalities} modalities={modalities} userIsFree={userIsFree} courseIsFree={courseIsFree} />
                    <CertCompletion certificationOfferText={certificationOfferText} />
                    <ComprehensiveLearning comprehensiveCourses={comprehensiveCourses} comprehensiveCareerPaths={comprehensiveCareerPaths} />
                  </div>
                ) : null}
                {/* MOBILE DISPLAY */}
                <div className="block px-4 lg:hidden">
                  <ContentBody
                    prereqs={prereqs}
                    certificationOfferText={certificationOfferText}
                    certificate={certificate}
                    comingSoon={comingSoon}
                    additionalMaterials={additionalMaterials}
                    meta={meta}
                    courseId={courseId}
                    progress={courseProgress}
                    userType={userType}
                    learningActivities={learningActivities}
                    handleItemClick={handleItemClick}
                    description={description}
                    shortDescription={shortDescription}
                    typeStyle={typeStyle}
                    userIsFree={userIsFree}
                    courseIsFree={courseIsFree}
                    enrollmentData={enrollmentData}
                  />
                </div>
                {showSecondColumn ? (
                  <div className="block px-4 lg:hidden">
                    <Collection collection={collection} type={type} />
                    <ContentBadge badgeId={item.badge_id} />
                    <GoalSection type={type} groupsInfo={groupsInfo} userType={userType} permalink={item.permalink} contentDescriptionId={contentDescriptionId} />
                    <ProvidersSections providers={provider} />
                    <DynamicCert groupsInfo={groupsInfo} />
                    <CourseComponentsWrapper hasMultipleModalities={hasMultipleModalities} modalities={modalities} userIsFree={userIsFree} courseIsFree={courseIsFree} />
                    <CertCompletion certificationOfferText={certificationOfferText} />
                    <ComprehensiveLearning comprehensiveCourses={comprehensiveCourses} comprehensiveCareerPaths={comprehensiveCareerPaths} />
                  </div>
                ) : null}
              </div>
              {item && item.released_at_timestamp ? <p className="px-4 pt-8 text-xs">Released: {moment.unix(item.released_at_timestamp).format('M/D/YYYY')}</p> : null}
            </div>
            <FeedbackModal />
          </div>
        );
      }
    }
  )
);

export default BrowsePromo;
