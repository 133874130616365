import React, { useMemo } from 'react';
import AddLink from '../AddLink/AddLink';
import AuthUtil from '../../utils/authUtil';

export function ForgotPassword() {
  return (
    <AddLink className="mb-4 text-cyb-pink-500 hover:text-black" to="/forgot-password">
      Forgot your password?
    </AddLink>
  );
}

function LoginLinks({ startOver, omitLoginLink, omitForgotPasswordLink, omitRegisterLink }) {
  const signUpUrl = useMemo(() => {
    // Local and BWB environments use the same route
    if (AuthUtil.isBWBEnvironment() || AuthUtil.isDevEnvironment()) {
      return `/register/${window.location.search}`;
    }
    // All other environments use the marketing site URL
    return `${process.env.REACT_APP_V2_SITE_URL}/register/${window.location.search}`;
  }, [window.location.search]);
  return (
    <div className="flex flex-col items-center">
      {!omitLoginLink && (
        <div className="mb-4 text-center">
          <AddLink className="text-cyb-pink-500 hover:text-black underline" to="/login">
            Back to Sign In &raquo;
          </AddLink>
        </div>
      )}
      {startOver && (
        <button className="inline-block my-4 text-cyb-pink-600 hover:text-black underline" onClick={startOver}>
          Try another email
        </button>
      )}
      {!omitForgotPasswordLink && <ForgotPassword />}
      {!omitRegisterLink && (
        <div>
          Don&apos;t have an account?
          <AddLink className="ml-2 text-cyb-pink-500 hover:text-black underline" to={signUpUrl}>
            Sign Up &raquo;
          </AddLink>
        </div>
      )}
    </div>
  );
}

export default LoginLinks;
