import React from 'react';
import UpgradeButton from '../../../components/UpgradeButton/UpgradeButton';
import Icon from '../../../components/Icon/Icon';
import CopyInviteLinkForm from '../../../components/ReferralModal/CopyInviteLinkForm';

const cipFeatures = [
  'Unlimited Courses',
  'Unlimited Certification Preparation',
  'Unlimited Virtual Labs',
  'Unlimited Assessments',
  'Mentors',
  'Career Coaching',
  'Community & Networking',
  'Skill Proficiency Reporting',
];
export default function UpgradeInterstitialModule({ handleClose = () => {} }) {
  return (
    <div className="mt-4 h-full text-center">
      <div className="my-6 text-[48px] font-black text-black">Congratulations</div>
      <div className="my-4 text-[20px] text-black">You have completed this module.</div>
      <div className="my-6 text-[24px] font-black text-black">Keep the momentum going with Cybrary Insider Pro.</div>
      <div className="flex justify-center items-center my-8">
        <UpgradeButton color="pink" upgradeText="Upgrade" />
        <div className="my-4 mx-6 text-[12px] text-black">Or</div>
        <button className="py-2.5 px-6 text-sm font-bold leading-5 text-center text-black bg-gray-200 hover:bg-gray-300 rounded-sm border-black border-sm" onClick={handleClose}>
          Continue
        </button>
      </div>
      <div className="flex m-auto max-w-240">
        <div className="w-full md:w-1/2">
          <img className="m-auto max-h-72" alt="" src="https://images.ctfassets.net/kvf8rpi09wgk/7z9Z0YTrakL4JRezMBMz5E/47c4771c71901b15cbfb3ee7a61837dd/All_the_data-bro_1.svg" />
        </div>
        <div className="ml-12 w-full md:w-1/2">
          <div className="mb-2 font-bold text-left">All features in Cybrary Basic Access, plus</div>
          {cipFeatures.map((li) => (
            <div className="flex mb-1 ml-4 text-left" key={li}>
              <Icon name="check" className="mr-2 w-6 h-6 text-green-600" />
              <div>{li}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="mx-auto w-full md:w-1/2">
        <p className="mt-4 mb-0 font-bold text-left text-black text-md">Invite your friends and share the fun!</p>
        <CopyInviteLinkForm />
      </div>
    </div>
  );
}
