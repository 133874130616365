import React from 'react';
import { HashLink } from 'react-router-hash-link';
import Bugsnag from '@bugsnag/js';
import Icon from '../Icon/Icon';
import CircularProgress from '../ProgressBar/CircularProgress';
import EnrollmentButton from '../BrowsePromo/EnrollmentButton';
import OptionsMenu from '../OptionsMenu/OptionsMenu';
import Button from '../Button/Button';
import { StatusIcon } from '../ContentStatus/StatusLegend';
import FormatUtil from '../../utils/formatUtil';
import AddLink from '../AddLink/AddLink';
import Label from '../Label/Label';
import If from '../If/If';

function PathTableCell({ children }) {
  return <div className="hidden py-7 text-sm text-center text-gray-600 align-middle border-t-xs border-gray-400 lg:table-cell">{children}</div>;
}

function PathRow({ path, pathType, userStore, commonStore, enrollmentStore, navigate, getOptions, handleEnroll, useMiniRow }) {
  const {
    id,
    title,
    thumbnail_url,
    shortDescription,
    permalink,
    attack_patterns,
    duration_seconds,
    ceu_count,
    progressData,
    type,
    percentCompleted,
    progressEvent,
    content_status,
    tags_info,
  } = path;
  const duration = duration_seconds ? Math.round(duration_seconds / 3600) : '';
  const shareOptions = { permalink, title, type };
  const stores = { userStore, enrollmentStore, commonStore };
  const isFreeWithPaidLabs = tags_info?.includes('Free With Paid Labs');
  const isDFIR = tags_info?.includes('DFIR');
  const isRedTeam = tags_info?.includes('Red Team');
  const isFreeUser = !userStore?.user?.is_paid;
  const isCybrarySelect = pathType === 'cybrarySelect';
  const isThreatActorCampaigns = pathType === 'threatActors';
  const started = progressEvent === 'started';
  const comingSoon = content_status === 'Coming Soon';
  const completed = percentCompleted === 100;
  const pathPermalinkPrefix = isCybrarySelect ? '' : 'threat-actor-campaigns/';
  const pathPermalink = `/browse/${pathPermalinkPrefix}${permalink}${started ? '#outline' : ''}`;
  const statusIconClass = comingSoon ? 'mx-auto w-5 h-5 text-cyb-blue-500 fill-current' : 'mx-auto w-5 h-5 text-cyb-green-700';

  let statusIcon = null;
  if (comingSoon) {
    statusIcon = 'comingSoon';
  } else if (completed) {
    statusIcon = 'complete';
  }

  const subscribe = async () => {
    const { bookmarks } = userStore || {};
    const isBookMarked = bookmarks.length && bookmarks.indexOf(id) !== -1;

    try {
      if (!isBookMarked) {
        await userStore.toggleBookmark(id, title);
        commonStore.triggerToast('success', {
          content: 'Added to Saved Items',
        });
      }
    } catch (error) {
      commonStore.triggerToast('error', {
        content: `There was a problem adding this item. Please try again.`,
      });
      Bugsnag.notify(error);
    }
    return null;
  };
  let pathStatsString = `${attack_patterns} Techniques | ${duration}hrs | ${ceu_count}CEUs`;
  if (isCybrarySelect) {
    pathStatsString = `${duration}hrs`;
  }
  if (useMiniRow) {
    return (
      <div className="table-row">
        <div className="table-cell py-5 border-t-xs border-gray-400">
          <div className="flex flex-col">
            <HashLink to={pathPermalink} className="text-black hover:text-black hover:no-underline">
              <p className="pb-2 mb-0 w-[90%] text-sm text-black">{FormatUtil.formatLongText(title, 95)}</p>
              <div className="flex text-xs text-gray-600">{pathStatsString}</div>
            </HashLink>
          </div>
        </div>
        <div className="table-cell py-3 text-sm text-center text-gray-600 align-middle border-t-xs border-gray-400">
          {started && !completed && !comingSoon && (
            <CircularProgress classes="w-14 h-14 mx-auto" strokeWidth={4} percentageValue={percentCompleted}>
              <Icon name="trophy" className="mx-auto w-7 h-7" />
            </CircularProgress>
          )}
          <StatusIcon className={statusIconClass} status={statusIcon} />
        </div>
        <div className="py-7 text-sm text-center text-gray-600 align-middle border-t-xs border-gray-400 lg:table-cell">
          <div className="flex justify-end items-center">
            {comingSoon ? (
              <Button color="gray" onClick={subscribe}>
                Subscribe
              </Button>
            ) : (
              <EnrollmentButton
                color="gray"
                type={type}
                handleEnroll={() => handleEnroll(path, stores, navigate)}
                userType={userStore.userType}
                progress={progressData}
                item={path.data}
              />
            )}
            <div className="hidden ml-2 lg:block">
              <OptionsMenu options={getOptions(commonStore, shareOptions)} iconContainerClassName=" border hover:bg-gray-200 border-gray-400 px-2" />
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="table-row">
      <div className="table-cell py-5 border-t-xs border-gray-400 lg:py-7">
        <div className="flex">
          <HashLink to={pathPermalink} className="flex text-black hover:text-black hover:no-underline">
            <img className="hidden pr-4 w-auto min-w-[240px] h-40 rounded-sm lg:block" src={thumbnail_url} alt="" />
            <div>
              <p className="mb-0 text-sm text-black lg:pb-2 lg:text-base lg:font-bold">{title}</p>
              <div className="hidden pt-2 lg:block">
                <p className="pb-2 mb-0 text-sm leading-6 text-black">
                  {shortDescription} {isFreeUser && isFreeWithPaidLabs && <span className="text-xs text-red-500">Free with paid labs.</span>}
                </p>
                <div className="flex">
                  {isDFIR && (
                    <Label color="black" basic>
                      DFIR
                    </Label>
                  )}
                  {isRedTeam && (
                    <Label color="black" basic>
                      Red Team
                    </Label>
                  )}
                </div>
              </div>
              <div className="flex pt-2 text-xs text-gray-600 lg:hidden">{pathStatsString}</div>
            </div>
          </HashLink>
        </div>
      </div>
      {isThreatActorCampaigns && (
        <>
          <PathTableCell>{attack_patterns}</PathTableCell>
          <PathTableCell>{duration}</PathTableCell>
          <PathTableCell>{ceu_count}</PathTableCell>
        </>
      )}
      {isCybrarySelect && <PathTableCell>{duration}</PathTableCell>}
      <div className="table-cell py-4 text-sm text-center text-gray-600 align-middle border-t-xs border-gray-400 lg:py-7">
        {started && !completed && !comingSoon && (
          <CircularProgress classes="w-14 h-14 mx-auto" strokeWidth={4} percentageValue={percentCompleted}>
            <Icon name="trophy" className="mx-auto w-7 h-7" />
          </CircularProgress>
        )}
        <StatusIcon className={statusIconClass} status={statusIcon} />
      </div>
      <PathTableCell>
        <div className="flex justify-evenly items-center">
          {isCybrarySelect && (
            <AddLink
              className="items-center py-2.5 px-6 mr-2 text-sm font-bold leading-5 text-center text-black bg-gray-200 hover:bg-gray-300 bg-center border-sm"
              to={pathPermalink}
            >
              Learn More
            </AddLink>
          )}
          {comingSoon ? (
            <Button color="gray" onClick={subscribe}>
              Subscribe
            </Button>
          ) : (
            <EnrollmentButton
              color="gray"
              type={type}
              handleEnroll={() => handleEnroll(path, stores, navigate)}
              userType={userStore.userType}
              progress={progressData}
              item={path}
            />
          )}
          <div className="hidden ml-2 lg:block">
            <OptionsMenu options={getOptions(commonStore, shareOptions)} iconContainerClassName=" border hover:bg-gray-200 border-gray-400 px-2" />
          </div>
        </div>
      </PathTableCell>
    </div>
  );
}

function PathTableHeaders({ useMiniRow, pathType }) {
  const miniTableCell = useMiniRow ? 'hidden' : 'hidden lg:table-cell';
  const miniTitle = useMiniRow ? 'pt-8 pl-0 w-3/4' : 'pt-8 pl-0 w-3/4 lg:pt-10 lg:pl-60 lg:w-1/2';
  const isCybrarySelect = pathType === 'cybrarySelect';
  const isThreatActorCampaigns = pathType === 'threatActors';
  const isCareerPrograms = pathType === 'careerPrograms';
  const title = isCareerPrograms ? 'Program' : 'Campaign Title & Overview';

  return (
    <div className="table-header-group">
      <div className="table-row text-2xs font-semibold text-gray-600 uppercase">
        <div className={`table-cell pb-4 ${miniTitle}`}>{title}</div>
        <If condition={isCareerPrograms}>
          <>
            <div className={`px-4 text-center ${miniTableCell}`}>Domain</div>
            <div className={`px-4 text-center ${miniTableCell}`}>Difficulty</div>
            <div className={`px-4 text-center ${miniTableCell}`}>XP</div>
            <div className="table-cell px-4 text-center">Status</div>
          </>
        </If>
        <If condition={isThreatActorCampaigns}>
          <>
            <div className={`px-4 text-center ${miniTableCell}`}>Techniques</div>
            <div className={`px-4 text-center ${miniTableCell}`}>Hours</div>
            <div className={`px-4 text-center ${miniTableCell}`}>Ceus</div>
            <div className="table-cell px-4 text-center">Status</div>
          </>
        </If>
        <If condition={isCybrarySelect}>
          <>
            <div className={`px-4 text-center ${miniTableCell}`}>Hours</div>
            <div className="table-cell px-4 text-center">Status</div>
          </>
        </If>
        <div className="table-cell" />
      </div>
    </div>
  );
}

export default function PathTable({ paths, pathType, userStore, commonStore, enrollmentStore, navigate, getOptions, handleEnroll, useMiniRow }) {
  return (
    <div className="table w-full">
      <PathTableHeaders useMiniRow={useMiniRow} pathType={pathType} />
      <div className="table-row-group mb-10">
        <If condition={Boolean(paths && paths.length)}>
          {paths.map((path) => (
            <PathRow
              key={path.id}
              path={path}
              pathType={pathType}
              userStore={userStore}
              commonStore={commonStore}
              enrollmentStore={enrollmentStore}
              navigate={navigate}
              getOptions={getOptions}
              handleEnroll={handleEnroll}
              useMiniRow={useMiniRow}
            />
          ))}
        </If>
      </div>
    </div>
  );
}
