import React from 'react';
import { getCN } from '../../utils/formatUtil';

function BoxIconField(props) {
  const { fieldsPerRow, option, isSelected, handleClick, icon, children, headerClasses } = props;

  const fieldsPerRowClass = {
    1: 'w-full',
    2: 'w-full sm:w-1/2',
    3: 'w-full sm:w-1/2 lg:w-1/3',
    4: 'w-full sm:w-1/2 lg:w-1/4',
  };

  const boxContainerClass = getCN(`
    my-3 px-4
    ${fieldsPerRowClass[fieldsPerRow]}
  `);

  const boxClass = getCN(`
    transform hover:-translate-y-1 transition-transform	cursor-pointer p-2 rounded-sm text-center border-2 w-52 h-52 mx-auto 
    ${isSelected ? 'border-cyb-pink-500' : 'border-gray-400'}
  `);

  const headerClass = getCN(`
    capitalize
    ${headerClasses || 'mb-2'}
  `);

  return (
    <div className={boxContainerClass}>
      <button className={boxClass} onClick={(e) => handleClick(e, option)}>
        <div className="mb-2">{icon}</div>
        <div className={headerClass}>{option.key}</div>
        <div>{children}</div>
        {!!option && option.subText && <div className="py-2 text-sm italic">{option.subText}</div>}
      </button>
    </div>
  );
}

export default BoxIconField;
