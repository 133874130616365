import React from 'react';
import { useLocation } from 'react-router-dom';
import AddLink from '../AddLink/AddLink';

function UpgradeLogo() {
  const location = useLocation() || window.location;
  const isUpgradePage = /upgrade\/(teams-)?checkout/g.test(location.pathname);
  const isUpdatePage = /upgrade\/update/g.test(location.pathname);
  if (!isUpgradePage && !isUpdatePage) {
    return null;
  }
  return (
    <div className="flex justify-center items-center w-full bg-white">
      <div className="pb-4 mx-4 mt-6 mb-0">
        <AddLink to="/" aria-label="Home" className="block">
          <img
            className="inline-block ml-0 w-[120px] h-[31px] sm:w-[180px] sm:h-[47px]"
            src="https://images.ctfassets.net/kvf8rpi09wgk/6HtXDpiYT7GMU8yp0DS4Dy/5b42c213353afb410ac8e52e7db7c77f/cybrary_logo_black.svg"
            alt="Cybrary"
          />
        </AddLink>
      </div>
    </div>
  );
}

export default UpgradeLogo;
