import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { Fade } from 'react-awesome-reveal';
import DynamicForm from '../../DynamicForm/DynamicForm';
import { useProfileContext } from '../../../providers/ProfileProvider';
import { APP_FORM_IDS } from '../../../constants';
/**
 * @param {function} handleCloseEditForm - function to close the edit form
 * @param {object} commonStore - the common store where toast functionality exists
 * @returns <EditProfile /> - the edit profile data
 * @important - The form updates the profile personal data on the backend... any data not included
 * in the request will be deleted from the backend. This is why you see fields like country, email, and phone number
 * appended to the payload. They are not editable in the form, but are still included in the request to the backend
 * to prevent them from being deleted.
 */

const EditProfile = inject('commonStore')(
  observer(({ commonStore, handleCloseEditForm }) => {
    const { personalProfileData, updateProfile, serverErrors } = useProfileContext();

    const {
      avatar_url = '',
      has_default_username,
      firstName = '',
      lastName = '',
      pronouns = '',
      username = '',
      phone = '',
      email = '',
      country = 'US',
      bio = '',
      location = '',
      tagline = '',
      github = '',
      instagram = '',
      linkedin = '',
      facebook = '',
      twitter = '',
      website = '',
    } = personalProfileData;

    const EditProfileForm = {
      id: APP_FORM_IDS.PROFILE.EDIT,
      name: 'edit-profile',
      order: [['firstName', 'lastName'], 'username', 'pronouns', 'location', 'tagline', 'bio', 'instagram', 'linkedin', 'facebook', 'twitter', 'github', 'website', ['submit']],
      fields: {
        firstName: { type: 'text', label: 'First Name', validations: ['required', 'string', 'max:50'], defaultValue: firstName },
        lastName: { type: 'text', label: 'Last Name', validations: ['required', 'string', 'max:50'], defaultValue: lastName },
        username: { type: 'text', label: 'Username', validations: ['required', 'string', 'max:50'], defaultValue: username },
        pronouns: {
          type: 'select',
          label: 'Pronouns',
          placeholder: pronouns || 'Choose Pronouns',
          options: [
            {
              text: 'He/Him',
              value: 'He/Him',
            },
            {
              text: 'She/Her',
              value: 'She/Her',
            },
            {
              text: 'They/Them',
              value: 'They/Them',
            },
          ],
          defaultValue: pronouns,
        },
        location: {
          type: 'address',
          label: 'What city are you located in?',
          // implemented like this to handle legacy code which supported an object
          // i.e. { format: 'San Francisco, CA, USA', lng: -122.4194, lat: 37.7749}
          defaultValue: typeof location === 'string' ? location : location?.format,
        },
        tagline: { type: 'text', label: 'Headline', validations: ['string', 'max:255'], defaultValue: tagline },
        bio: {
          type: 'textarea',
          label: 'Summary',
          size: { rows: 5, cols: 3 },
          descriptionPosition: 'top',
          description: `Add a summary about your expertise and interests. You can talk about your years of experience, industry, or skills.
           \n        People also talk about their achievements or previous job experiences`,
          validations: ['string', 'max:255'],
          defaultValue: bio,
        },
        instagram: { type: 'text', label: 'Instagram', validations: ['string', 'max:255'], defaultValue: instagram },
        linkedin: { type: 'text', label: 'Linkedin', validations: ['string', 'max:255'], defaultValue: linkedin },
        facebook: { type: 'text', label: 'Facebook', validations: ['string', 'max:255'], defaultValue: facebook },
        twitter: { type: 'text', label: 'Twitter', validations: ['string', 'max:255'], defaultValue: twitter },
        github: { type: 'text', label: 'Github', validations: ['string', 'max:255'], defaultValue: github },
        website: { type: 'text', label: 'Website', validations: ['string', 'max:255'], defaultValue: website },
        submit: { type: 'button', color: 'pink', label: 'Save Changes', className: 'mx-auto mt-8 rounded-lg' },
      },
    };

    // if we have a server error throw a toast
    useEffect(() => {
      if (Object.keys(serverErrors).length > 0) {
        Object.keys(serverErrors).forEach((key) => {
          return commonStore.triggerToast('error', {
            content: `Error updating profile: ${serverErrors[key]?.join(', ')}`,
          });
        });
      }
    }, [serverErrors]);

    const handleUpdateProfile = (formValues) => {
      /**
       * @important - the form does not include the phone number, country, email or avatar_url fields... if we don't send these along they will be deleted...
       */
      const payload = { personal: { ...formValues, avatar_url, phone, email, country, has_default_username } };
      updateProfile('personal', payload).then((response) => {
        // if response is === true it succeeded... if it failed response will be an object with errors
        if (response === true) {
          commonStore.triggerToast('success', {
            content: `Profile updated successfully`,
          });
          handleCloseEditForm();
        }
      });
    };
    return (
      <div id="edit-profile">
        <div id="edit-profile-header-row" className="flex justify-between">
          <h1 className="text-5xl font-black">Edit Profile</h1>
          <button type="button" className="py-3 px-6 ml-auto text-sm font-bold text-cyb-pink-500 rounded-md border-1 border-cyb-pink-500" onClick={handleCloseEditForm}>
            {' '}
            Cancel{' '}
          </button>
        </div>
        <div>
          {/* Wrapper div needed for Fade effect to work */}
          <Fade duration={300}>
            <DynamicForm
              id={APP_FORM_IDS.PROFILE.EDIT}
              formId={APP_FORM_IDS.PROFILE.EDIT}
              form={EditProfileForm}
              formName={EditProfileForm?.name}
              onSubmit={handleUpdateProfile}
              customClassName="w-full"
            />
          </Fade>
        </div>
      </div>
    );
  })
);
export default EditProfile;
