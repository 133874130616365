import { v4 as uuidv4 } from 'uuid';

export function getExcludeProgressBefore(item, enrollment) {
  if (!item && !enrollment) {
    return null;
  }
  const contentId = item?.content_description_id ? item.content_description_id : enrollment.content_description_id;
  if (enrollment?.descendant_enrollments?.length) {
    const enrollmentDescendent = enrollment.descendant_enrollments.find((descendant) => descendant.content_description_id === contentId);
    if (enrollmentDescendent?.exclude_progress_before) {
      return enrollmentDescendent.exclude_progress_before;
    }
  }

  if (item?.exclude_progress_before) {
    return item.exclude_progress_before;
  }

  if (enrollment?.exclude_progress_before) {
    return enrollment.excludeProgressBefore;
  }

  return null;
}

/**
 * Get the video id from a url.
 * @param {string} url - the URL we are extracting the video id from
 * @returns string|null - the video id from the url
 */
export const getVideoId = (url) => {
  if (url) {
    const parts = url.split('video/');
    if (parts.length === 2) {
      return parts[1];
    }
  }
  return null;
};

/**
 * Take our activity and provide a normalized structure for the vendor data, regardless of the "version" of activity implementation
 * @param {object|null} activity - The activity we are attempting to normalize vendor data for
 * @returns object
 */
export const normalizeActivityVendorData = (activity) => {
  if (!activity) {
    return {};
  }
  const { vendor_data: vendorData, type, slug } = activity;
  // Handle lesson content (and new v2 lesson content, which contains questions but no content array)
  if (vendorData?.content?.length || vendorData?.questions?.length) {
    // Make sure all content items and questions have ids before sending back vendor data
    return {
      ...vendorData,
      content: vendorData?.content?.length ? vendorData.content.map((item) => (item?.id ? item : { ...item, id: uuidv4() })) : [],
      questions: vendorData?.questions?.length ? vendorData.questions.map((item) => (item?.id ? item : { ...item, id: uuidv4() })) : [],
    };
  }

  // Handle legacy content
  const data = {
    question: {},
    content: [],
    legacy: true,
  };
  if (type === 'Video Activity' || type === 'Cybrary Live Session') {
    data.content.push({
      id: uuidv4(),
      type: 'video',
      legacy: true,
      videoId: getVideoId(activity.url),
    });
  } else if (type === 'Article Activity') {
    data.content.push({
      id: uuidv4(),
      type: 'article',
      legacy: true,
      content: activity.content,
    });
  } else if (slug) {
    data.content.push({
      id: uuidv4(),
      type: 'lab',
      permalink: slug,
    });
  }
  return data;
};

/**
 * Normalize an activity, regardless of the "version" of the activity info we are provided.
 * @param {object|null} activity - The activity we are going to normalize.
 * @returns object|null
 */
export const normalizeActivity = (activity) => {
  if (!activity) {
    return null;
  }
  const data = normalizeActivityVendorData(activity);
  const { question, questions, content: activityContent, legacy } = data;
  let questionsList = questions || null;
  if (!questionsList && question) {
    questionsList = [question];
  }

  // If this is a clab lesson activity, grab the id and set isClab based off of it's existence
  const clabId = activity?.vendor_data?.clabContentId;
  const isClab = !!clabId;
  // Check to see if this is a Cybrary Assessment
  const isCybAssessment = activity?.type === 'Assessment Activity';
  const cybAssessmentId = activity?.assessment?.uuid;
  // We are NOT an assessment, but we have one in the sidebar if this is a lesson with an assessment id
  const hasCybAssessment = activity?.type === 'Lesson Activity' && !!cybAssessmentId;
  // Initial isFree value for the activity is based on the server response (authorized), will get overridden once the outline is built
  const isFree = !!activity?.authorized;
  // Return the formatted activity
  return {
    ...activity,
    questions: questionsList,
    activityContent,
    videoProgress: activity.progress,
    legacy,
    isClab,
    clabId,
    cybAssessmentId,
    hasCybAssessment,
    isCybAssessment,
    isFree,
  };
};

/**
 * If the item is a stand-alone enrollment (like an enrollment activity), return true.
 * @param {object|null} item - The item, if it exists.
 * @param {string|int|null} itemId - The item id, if we have one.
 * @returns bool - Whether or not the provided item is an "item activity"
 */
export const checkIfItemActivity = (item, itemId) => {
  const { isItemActivity, learning_modules: learningModules } = item || {};
  return isItemActivity || !learningModules || !learningModules.length || itemId === 'enrollment';
};

/**
 * Determine if the passed activity is the last activity in the item. Returns false if no item or activity.
 * @param {object|null} item The curricula item we are examining
 * @param {object|null} activity The activity we are checking
 * @returns {boolean} T/F depending on if the activity is the last activity in the curricula item.
 */
export const checkIfActivityIsLastInItem = (item, activity) => {
  if (!item || !activity) {
    return false;
  }
  const { learning_modules: learningModules } = item;
  if (!learningModules) {
    return false;
  }
  const lastModule = learningModules[learningModules.length - 1];
  const lastModuleActivities = lastModule.activities;
  const lastActivity = lastModuleActivities[lastModuleActivities.length - 1];
  return lastActivity?.id === activity?.id;
};

/**
 * Normalize an item, regardless of the type of item (a fake one due to it being an Item Activity, or just a regular curriculum item).
 * @param {object|null} item
 * @returns object|null
 */
export const normalizeItem = (item) => {
  if (!item) {
    return null;
  }
  const { progress, content_description: contentDescription, meta } = item;
  const {
    title,
    ceu_count: ceuCount,
    thumbnail_url: thumbnail,
    short_description: shortDescription,
    long_description: longDescription,
    instructors_info: instructors,
  } = contentDescription || {};
  const description = shortDescription || longDescription;
  let resources = null;
  if (meta && meta.supplementalMaterials) {
    resources = meta.supplementalMaterials.map((materialItem) => {
      return {
        label: materialItem.title,
        href: materialItem.url,
      };
    });
  }
  return {
    ...item,
    title,
    ceuCount,
    resources,
    progress: progress > 100 ? 100 : progress,
    thumbnail,
    description,
    instructors,
  };
};
