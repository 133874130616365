import React from 'react';
import { useNavigate } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import AddLink from '../AddLink/AddLink';
import EnrollmentUtil from '../../utils/enrollmentUtil';
import EnrollmentButton from '../BrowsePromo/EnrollmentButton';

const CertificationCtaButton = inject(
  'commonStore',
  'enrollmentStore',
  'userStore'
)(
  observer(({ data, fluid, commonStore, enrollmentStore, userStore }) => {
    const navigate = useNavigate();
    if (!data) {
      return null;
    }

    const handleEnroll = () => {
      EnrollmentUtil.handleStartEnroll(userStore, enrollmentStore, commonStore, navigate, data.contentData);
    };

    const isActiveStep = data.active;
    const stepColorClasses = isActiveStep ? 'text-white bg-cyb-pink-500 hover:bg-cyb-pink-600 hover:text-white' : 'text-black bg-gray-200 hover:bg-gray-300 hover:text-black';
    const fluidClasses = fluid ? 'w-full lg:w-auto' : '';
    if (data && data.href) {
      return (
        <AddLink className={`py-2.5 px-6 text-sm font-bold leading-5 text-center ${stepColorClasses} ${fluidClasses} rounded-sm`} to={data.href} target="_blank">
          {data.ctaText}
        </AddLink>
      );
    }

    if (data && data.contentData) {
      return <EnrollmentButton color={isActiveStep ? 'pink' : 'gray'} text={data.ctaText} handleEnroll={handleEnroll} item={data.contentData} buttonClasses={`${fluidClasses}`} />;
    }

    return null;
  })
);

export default CertificationCtaButton;
