import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { inject, observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import { useProfileContext } from '../../../providers/ProfileProvider';
import { PUBLIC_PAGES } from '../../../constants';
import PermalinkUtil from '../../../utils/permalinkUtil';
import Modal from '../../Modal/Modal';
import UpdateUsername from './UpdateUsername';
import Icon from '../../Icon/Icon';

function UsernameUpdated({ username = '' }) {
  return (
    <div id="username-updated-container" className="flex flex-col justify-center items-center py-14 px-7 w-full">
      <p className="my-14 text-2xl font-black">Thank you for updating your username!</p>
      <Icon name="license" className="mb-14 w-28 h-28 text-cyb-pink-500" />
      <p className="text-2xl font-black">
        Your new username is: <span className="text-cyb-pink-500">{username}</span>
      </p>
    </div>
  );
}

const UsernameModal = inject('userStore')(
  observer(({ userStore }) => {
    const [open, setOpen] = useState(false);

    const [step, setStep] = useState('update-username');

    const location = useLocation();
    const { personalProfileData, updateProfile } = useProfileContext();
    const { has_default_username = false, username } = personalProfileData || {};
    const { profileData, user } = userStore;
    // cookie will be true or undefined. if it is undefined, will default to false
    const hideUsernameModal = Cookies.get('hide_username_modal') || false;

    useEffect(() => {
      // Get list of important pages that should be omitted from forcing this modal onto user
      const whiteListedPages = [
        ...PUBLIC_PAGES,
        '/logout',
        '/profile',
        '/onboarding/terms-of-service',
        '/onboarding/recovery-email',
        '/upgrade/teams-checkout',
        '/upgrade/checkout',
        '/google-marketplace-onboarding',
      ];
      const isTeamInvitePage = /^\/team-invite*/.test(location.pathname);
      const cleanPathName = PermalinkUtil.removeTrailingSlash(location.pathname);
      /*
          Only open if...
          we have user data,
          we don't have a cookie saying hide username modal
          we have profile data (prevent being open during onboarding),
          we ARE NOT on a whitelisted page or the team-invite page
          and we have a default user name that should be updated
        */
      if (user) {
        setOpen(
          user &&
            profileData &&
            !hideUsernameModal &&
            !whiteListedPages.includes(cleanPathName) &&
            !isTeamInvitePage &&
            has_default_username &&
            personalProfileData?.firstName &&
            personalProfileData?.lastName
        );
      }
    }, [location, user]);

    const handleUpdateDefaultUsername = (e, usernameInput) => {
      e.preventDefault();
      /**
       * @important - the form does not include the rest of the "personal" fields... if we don't send these along they will be deleted...
       * @important - we use has_default_username: 0 b/c the laravel was not working well with sending along a boolean value
       */
      const payload = { personal: { ...personalProfileData, username: usernameInput, has_default_username: 0 } };
      // some older accounts do not have a country set in profile data... set it to US by default to avoid 422 error
      if (!payload?.personal?.country) {
        payload.personal.country = 'US';
      }
      // if location is an object, set it to the format string
      if (payload?.personal?.location && typeof payload?.personal?.location === 'object') {
        payload.personal.location = payload?.personal?.location?.format || '';
      }
      updateProfile('personal', payload).then((response) => {
        // if response is === true it succeeded... if it failed response will be an object with errors
        if (response === true) {
          setStep('username-updated');
        }
      });
    };

    const handleCloseModal = (e) => {
      /* 
        if it is the first step, we want send a request to the backend to update the has_default_username to false
        if user decides to exit the modal. If we make it to the second step that means the submit button updated username and 
        has_default_username meaning we don't need to do it again upon closing the modal. 
      */
      if (step === 'update-username') {
        handleUpdateDefaultUsername(e, username);
      }
      setOpen(false);
    };
    return (
      <Modal sizeClasses="md:max-w-[584px] w-full" className="bg-gray-200" paddingBottom="pb-0" open={open} toggle={(e) => handleCloseModal(e)} position="center">
        {step === 'update-username' && <UpdateUsername handleUpdateDefaultUsername={handleUpdateDefaultUsername} />}
        {step === 'username-updated' && <UsernameUpdated username={username} />}
      </Modal>
    );
  })
);
export default UsernameModal;
