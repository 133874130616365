import React, { useState } from 'react';
import { observer, inject } from 'mobx-react';
import { twMerge } from 'tailwind-merge';
import { Fade, Bounce } from 'react-awesome-reveal';
import DynamicForm from '../DynamicForm/DynamicForm';
import Title from '../Title/Title';
import If from '../If/If';
import Icon from '../Icon/Icon';
import Button from '../Button/Button';
import CopyInviteLinkForm from './CopyInviteLinkForm';
import agents from '../../agents/agents';
import BugsnagUtil from '../../utils/bugsnagUtil';

const FIRST_NAME_FIELD = {
  type: 'text',
  placeholder: 'First Name',
};
const LAST_NAME_FIELD = {
  type: 'text',
  placeholder: 'Last Name',
};
const EMAIL_FIELD = {
  type: 'email',
  placeholder: 'name@example.com',
};

const INITIAL_INVITEE_COUNT = 3; // initial number of invitees to show
const MAX_INVITEE_COUNT = 20; // max number of invitees to show

/**
 * Creates the referral form fields object for the dynamic form
 * @param {boolean} isLoading - flag for if the form is loading
 */
const getReferralForm = ({ isLoading }) => {
  const fields = {};
  // Add invitee fields, one for each invitee up to MAX_INVITEE_COUNT
  for (let i = 1; i <= MAX_INVITEE_COUNT; i++) {
    fields[`firstName${i}`] = { ...FIRST_NAME_FIELD };
    fields[`lastName${i}`] = { ...LAST_NAME_FIELD };
    fields[`email${i}`] = { ...EMAIL_FIELD };

    // hide current field if the previous index's email is empty
    if (i > INITIAL_INVITEE_COUNT) {
      const condition = [
        {
          field: `email${i - 1}`,
          op: 'neq',
          value: undefined, // Needs to be undefined to avoid the field being hidden when enetered and then deleted
        },
      ];
      fields[`firstName${i}`].conditions = condition;
      fields[`lastName${i}`].conditions = condition;
      fields[`email${i}`].conditions = condition;
    }
  }

  // Add submit button
  fields.submit = {
    type: 'button',
    color: 'pink',
    label: isLoading ? 'Sending...' : 'Send Invitiations', // give visual feedback that the form is submitting
    className: 'flex justify-center w-full px-6 py-3 text-sm md-991:w-auto mx-auto min-w-[11rem] min-h-[3rem]',
  };

  // set order, grouping first name, last name, and email together in a sub array
  const order = [];
  for (let i = 1; i <= MAX_INVITEE_COUNT; i++) {
    order.push([`firstName${i}`, `lastName${i}`, `email${i}`]);
  }
  // add submit button to the end of the order array
  order.push(['submit']);
  // end results example: orders:
  // [ [ 'firstName1', 'lastName1', 'email1' ], [ 'firstName2', 'lastName2', 'email2' ], [ 'firstName3', 'lastName3', 'email3' ], [ 'submit' ] ]

  return {
    name: 'referralForm',
    order,
    fields,
  };
};

/**
 * Transform the form data into an array of invitees for submission to backend
 * @param {FormData} data - form data from the dynamic form submission
 * @returns {Array} - array of invitees
 * @example { firstName1: "abc", lastName1: "efg", email1: "rtfm@cybrary.it" } -> [ { firstName: "abc", lastName: "efg", email: "rtfm@cybrary.it"} ]
 */
const transformReferralFormDataForRequest = (data) => {
  const invitees = [];
  for (let i = 1; i <= MAX_INVITEE_COUNT; i++) {
    // Only add invitees that have an email entered in the form
    if (data[`email${i}`]) {
      invitees.push({
        firstName: data[`firstName${i}`],
        lastName: data[`lastName${i}`],
        email: data[`email${i}`],
      });
    }
  }
  return invitees;
};

/**
 * Success screen for referral form
 * Shown after successful submission of the referral form
 * @returns {JSX.Element}
 */
function SuccessScreen({ onReturnButtonClick }) {
  // onclick handler, if none is provided, do nothing
  const onClick = onReturnButtonClick ? () => onReturnButtonClick() : () => {};
  return (
    <div className="flex flex-col justify-center items-center w-full h-full">
      <Fade>
        <Title title="Invitations Sent!" />
        <Bounce delay={300}>
          <Icon name="mail" className="my-8 w-128 h-48 text-cyb-pink-500" />
        </Bounce>
        <h2 className="m-0 text-2xl font-black">Your friends have been invited to Cybrary</h2>
        <Button className="flex mt-16" color="gray" onClick={onClick}>
          <Icon name="chevron-left" className=" mr-2 w-4 h-4" />
          Invite More Friends
        </Button>
      </Fade>
    </div>
  );
}

/**
 * Invitee bullet point list
 * @param {Array} invitees - array of invitees eg [{ firstName: "abc", lastName: "efg", email: "c@cyrbary.it" }]
 */
function InviteeList({ invitees }) {
  return (
    <ul className="list-disc list-inside">
      {invitees.map((invitee) => (
        <li key={invitee.email}>{invitee.email}</li>
      ))}
    </ul>
  );
}

const ReferralInviteForm = inject('commonStore')(
  observer(({ commonStore, className, serverErrors, clearErrorForField }) => {
    const [isLoading, setIsLoading] = useState(false); // loading state for form submission
    const [isSuccessScreenOpen, setIsSuccessScreenOpen] = useState(false); // flag for if the success screen is open

    /**
     * Send invitees to server
     * @param {Array} invitees - array of invitees
     */
    const sendInvites = async (invitees) => {
      commonStore.resetConfirmState();
      setIsLoading(true);
      // send invitees to server
      try {
        await agents.auth.sendReferralInvites({ invites: invitees });
        setIsSuccessScreenOpen(true);
      } catch (error) {
        commonStore.triggerToast('error', {
          content: 'Something went wrong sending invites. Please try again.',
        });
        BugsnagUtil.notify(error);
      } finally {
        setIsLoading(false);
      }
    };

    /**
     * Handle form submission by
     * transforming the form data into an array of invitees
     * and sending them to the server
     * @param {FormData} data - form data from the dynamic form submission
     */
    const handleSubmitInvites = (data) => {
      commonStore.resetToastState();
      const invitees = transformReferralFormDataForRequest(data);
      // if there are no invitees, show an error
      if (!invitees || invitees.length === 0) {
        return commonStore.triggerToast('error', {
          content: 'Please enter at least one email address',
        });
      }

      // find duplicate invitees
      const duplicateInvitees = invitees.filter((invitee, index) => {
        const firstIndex = invitees.findIndex((i) => i.email === invitee.email);
        return firstIndex !== index;
      });

      // find unique invitees
      const uniqueInvitees = invitees.filter((invitee, index) => {
        const firstIndex = invitees.findIndex((i) => i.email === invitee.email);
        return firstIndex === index;
      });

      // if there are duplicate invitees, confirm with the user
      if (duplicateInvitees.length > 0) {
        const content = (
          <div>
            <p className="mb-4">You have enetered the following duplicate emails that will not be sent:</p>
            <InviteeList invitees={duplicateInvitees} />
            <p className="mt-4">Only the first occurance of each email will be invited. The following invites will be sent:</p>
            <InviteeList invitees={uniqueInvitees} />
          </div>
        );
        return commonStore.triggerConfirm({
          content,
          cancel: () => commonStore.resetConfirmState(),
          continue: () => sendInvites(invitees),
        });
      }
      return sendInvites(invitees);
    };

    return (
      <div className={twMerge('flex justify-center items-center mx-auto lg:max-w-[75rem] px-8 min-h-[60vh]', className)}>
        <If condition={isSuccessScreenOpen}>
          <SuccessScreen onReturnButtonClick={() => setIsSuccessScreenOpen(false)} />
        </If>
        <If condition={!isSuccessScreenOpen}>
          <div>
            <Title title="Invite your friends to join you on Cybrary!" />
            <h2 className="m-0 text-2xl font-black">simply use the form below</h2>
            <DynamicForm form={getReferralForm({ isLoading })} onSubmit={handleSubmitInvites} serverErrors={serverErrors} clearErrorForField={clearErrorForField} />
            <Title title="OR invite your friends via this link" />
            <CopyInviteLinkForm />
          </div>
        </If>
      </div>
    );
  })
);

export default ReferralInviteForm;
