import React from 'react';
import FormatUtil from '../../../utils/formatUtil';
import { PADDLE_PRICE_ID_TEAM_ANNUAL } from '../../../constants';
import Icon from '../../../components/Icon/Icon';
import Loading from '../../../components/Loading/Loading';
import OrderSummaryRow from './Sections/OrderSummaryRow';
import OrderSummaryContainer from './Sections/OrderSummaryContainer';
import OrderSummaryDiscountRow from './Sections/OrderSummaryDiscountRow';
import { getAmountDueRowTitle } from './orderSummaryUtil';
import If from '../../../components/If/If';

/**
 * Edit icon button for teams seat qty
 * @param {function} onClick - Callback for editing qty
 * @returns {React.ReactNode} - Edit button
 */
function EditButton({ onClick = () => {} }) {
  return (
    <button className="ml-2" onClick={onClick}>
      <Icon name="pencil" className="w-3 h-3" />
    </button>
  );
}

/**
 * Shows amount due rows for new checkout items
 * Includes edit qty button for teams and any discounts
 * @param {object} item - Item from checkout
 * @param {string} rowClass - Class to apply to row
 * @param {string} code - Discount code
 * @param {string} currency - Currency code
 * @param {function} onEditTeamsQty - Callback for editing qty
 * @returns {React.ReactNode} - Amount due rows
 */
function AmountDueRows({ item, rowClass, code, currency, onEditTeamsQty }) {
  const { quantity, totals: priceTotals } = item;
  const isTeamProduct = item.price_id === PADDLE_PRICE_ID_TEAM_ANNUAL;
  const formattedDiscount = priceTotals?.discount ? FormatUtil.formatPriceWithSymbol(priceTotals.discount, currency) : null;
  const hasDiscount = !!parseInt(priceTotals?.discount, 10);

  /** Teams product rows */
  if (isTeamProduct) {
    const pricePerSeat = priceTotals.subtotal / quantity;
    const pricePerSeatPerMonth = pricePerSeat / 12;
    const formattedPricePerSeat = FormatUtil.formatPriceWithSymbol(pricePerSeatPerMonth, currency);
    const NumberOfTeammates = (
      <>
        {quantity}
        <EditButton onClick={onEditTeamsQty} />
      </>
    );
    return (
      <>
        {/* Omit the decimal numbers */}
        <OrderSummaryRow title="Price Per Teammate" value={formattedPricePerSeat} className={rowClass} />
        <OrderSummaryRow title="Number of Teammates" value={NumberOfTeammates} valueDelay={0} className={rowClass} />
        <OrderSummaryDiscountRow discountAmount={formattedDiscount} rowClass={rowClass} code={code} />
      </>
    );
  }
  /** CIP product rows */
  const formattedPrice = FormatUtil.formatPriceWithSymbol(priceTotals?.subtotal, currency);
  return (
    <>
      <OrderSummaryRow title={getAmountDueRowTitle(item)} value={formattedPrice} className={rowClass} />
      <If condition={hasDiscount}>
        <OrderSummaryDiscountRow discountAmount={formattedDiscount} rowClass={rowClass} code={code} />
      </If>
    </>
  );
}

/**
 * Order summary for New Checkouts
 * @param {boolean} basic - If true, use basic styles
 * @param {object} checkoutData - Data from checkout
 * @param {boolean} checkoutLoading - If true, show loading rows
 * @param {function} onEditTeamsQty - Callback for editing qty
 * @returns {React.ReactNode} - Order summary
 */
function OrderSummaryCheckout({ basic, checkoutData, checkoutLoading, onEditTeamsQty }) {
  const { items, currency_code, totals, discount } = checkoutData || {};
  const { code } = discount || {};
  const rowClass = basic ? 'border-b bg-none' : 'border bg-white';
  const priceRowClass = basic ? 'font-normal' : 'font-bold';
  const totalPrice = !checkoutLoading ? FormatUtil.formatPriceWithSymbol(totals?.total || '0.00', currency_code) : <Loading />;
  const totalTax = totals?.tax ? FormatUtil.formatPriceWithSymbol(totals?.tax, currency_code) : null;

  return (
    <OrderSummaryContainer basic={basic} isLoading={checkoutLoading}>
      {/** Add AmountDueRows for every item on the checkout */}
      {items?.map((item) => (
        <AmountDueRows key={item.price_id} item={item} code={code} rowClass={rowClass} currency={currency_code} onEditTeamsQty={onEditTeamsQty} checkoutLoading={checkoutLoading} />
      ))}
      {/** Add tax row */}
      {totalTax && <OrderSummaryRow className={`${rowClass}`} title="Tax" value={totalTax} />}
      {/** Add total row */}
      <OrderSummaryRow className={`${rowClass} ${priceRowClass}`} title="Total Due Today" value={totalPrice} />
    </OrderSummaryContainer>
  );
}

export default OrderSummaryCheckout;
