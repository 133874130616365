import React from 'react';
import Bugsnag from '@bugsnag/js';
import Icon from '../Icon/Icon';
import Button from '../Button/Button';

function ForceThrowError(error = '💥 KABOOM 💥') {
  throw new Error(error);
}

function FallbackDisplay() {
  return (
    <div className="grid justify-center text-center">
      <Icon name="face-frown" className="m-auto my-2 w-16 h-16 text-cyb-pink-500" />
      <div>Uh-oh.. looks like we broke something.</div>
      <div>Please try refreshing the page, or checking back later.</div>
    </div>
  );
}

function FallbackDisplaySmall() {
  return (
    <div className="grid justify-center m-1 text-center">
      <Icon name="face-frown" className="m-auto w-8 h-8 text-cyb-pink-500" />
      <div className="text-sm">Error, Please Refresh</div>
    </div>
  );
}

function FallbackDisplayExtraSmall() {
  return (
    <div className="grid justify-center m-1 text-center">
      <Icon name="face-frown" className="m-auto w-8 h-8 text-cyb-pink-500" />
      <div className="text-sm">Error</div>
    </div>
  );
}

function FallbackDisplayIconOnly() {
  return (
    <div className="grid justify-center m-1 text-center">
      <Icon name="face-frown" className="m-auto w-8 h-8 text-cyb-pink-500" />
    </div>
  );
}

function ErrorBoundary({ children, FallbackComponent = FallbackDisplay, onError, testing }) {
  const [isTesting, setIsTesting] = React.useState(false);

  // If we get here before Bugsnag is initialized, just render the children
  if (!Bugsnag.isStarted()) {
    return children;
  }

  const BugsnagBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
  return (
    <>
      <BugsnagBoundary FallbackComponent={FallbackComponent} onError={onError}>
        {children}
        {testing && isTesting && <ForceThrowError />}
      </BugsnagBoundary>
      {testing && !isTesting && (
        <div className="absolute bottom-0 z-220">
          <Button onClick={() => setIsTesting(true)}>Throw Error</Button>
        </div>
      )}
    </>
  );
}

ErrorBoundary.FallbackComponents = {
  FallbackDisplay,
  FallbackDisplaySmall,
  FallbackDisplayExtraSmall,
  FallbackDisplayIconOnly,
  EmptyFallbackDisplay: () => <div />,
};

export default ErrorBoundary;
