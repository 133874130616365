import React from 'react';
import { inject, observer } from 'mobx-react';
import Cookies from 'js-cookie';
import Collapsible from 'react-collapsible';
import Icon from '../Icon/Icon';
import './site-banner.css';
import Message from '../Message/Message';
import Button from '../Button/Button';
import { DEFAULT_COOKIE_SETTINGS } from '../../constants';

const ACTIVE_BANNER = {
  title: 'Learn on How To Make a Banner',
  dismissible: true,
  fullWidth: false,
  message: `Learn on How To Make a Banner labs are currently unavailable. We are working to ...`,
  id: 'test-10192022',
};

const COOKIE_PREFIX = 'site-banner-';
const SiteBanner = inject('userStore')(
  observer(
    class SiteBanner extends React.Component {
      state = {
        visible: false,
        banner: ACTIVE_BANNER,
        // Keep this over-ride as an example of passing JSX to the message
        // banner: {
        //   id: 'maint-10192022',
        //   dismissible: true,
        //   message: (
        //     <div style={{ marginTop: '1em' }}>
        //       <div style={{ marginBottom: '1em' }}>
        //         <Header as="h4" style={{ marginBottom: '1em' }}>
        //           Scheduled Maintenance
        //         </Header>
        //         The Cybrary platform will be unavailable on Tuesday, September 1st, from 7:00pm to 1am Eastern Time due to
        //         scheduled maintenance. You will be unable to access the platform
        //         and any content during this time.
        //       </div>
        //       <Button color="green" onClick={() => this.doSomething()}>
        //         Click here
        //       </Button>
        //     </div>
        //   ),
        // },
      };

      componentDidMount() {
        const { id } = this.state.banner;
        if (Cookies.get(`${COOKIE_PREFIX}${id}`)) {
          this.setState({ visible: false });
        }
      }

      handleDismiss = () => {
        const { id } = this.state.banner;
        Cookies.set(`${COOKIE_PREFIX}${id}`, true, {
          ...DEFAULT_COOKIE_SETTINGS,
          path: '/',
          expires: 365,
        });
        this.setState({ visible: false });
      };

      getMessageContent = (data, mobileView) => {
        const buttonStyles = mobileView
          ? {
              margin: '1em 0',
            }
          : {
              marginLeft: '1em',
            };
        return (
          <div className="content-container">
            <div>{data.message}</div>
            <div>
              {data.dismissible && (
                <Button as="a" className="banner-btn" floated="right" onClick={this.handleDismiss} style={buttonStyles}>
                  Dismiss
                </Button>
              )}
            </div>
          </div>
        );
      };

      render() {
        const { banner, visible } = this.state;
        if (banner) {
          if (!this.props.hide && visible && !Cookies.get(`${COOKIE_PREFIX}${this.state.id}`)) {
            // Full width, fixed bottom banner
            if (banner.fullWidth) {
              return (
                <div>
                  <Message className="fixed bottom-0 z-210 px-6 text-left site-banner" isDismissible={false}>
                    <div className="hidden md:block" style={{ width: '80%', margin: '0.5em 0.5em 0.5em 8.5em' }}>
                      <div className="text-base font-bold">{banner.title}</div>
                      {this.getMessageContent(banner, false)}
                    </div>
                    <Collapsible
                      triggerTagName="div"
                      className="md:hidden"
                      openedClassName="md:hidden"
                      open={false}
                      transitionTime={200}
                      trigger={
                        <div>
                          <span
                            style={{
                              margin: '2em 0em',
                              whiteSpace: 'nowrap',
                              display: 'inline-block',
                            }}
                          >
                            {banner.title}
                          </span>
                          <Icon className="show-hide-chevron" style={{ marginTop: '0.25em' }} />
                        </div>
                      }
                    >
                      {this.getMessageContent(banner, true)}
                    </Collapsible>
                  </Message>
                </div>
              );
            }
            // smaller width fixed bottom left
            return (
              <Message
                isDismissible={banner.isDismissible}
                onDismiss={this.handleDismiss}
                className="absolute bottom-4 left-1 z-210 w-96 text-left site-banner"
                msgHeader={banner.title}
                msgBody={banner.message}
              />
            );
          }
        }
        return null;
      }
    }
  )
);

export default SiteBanner;
