import React from 'react';

function Section({ highlight, children, noX, noY }) {
  // Provides default padding and bg color
  const bgColor = highlight ? `bg-gray-100 mx-4 md:mx-10` : `bg-white`;
  const xPadding = !noX ? 'px-4 md:px-10' : '';
  let ySpacing = !noY ? 'py-4 mb-4' : 'py-0 my-4';
  ySpacing = highlight ? 'py-8 mb-6' : ySpacing;
  return <div className={`${bgColor} rounded ${xPadding} ${ySpacing} text-gray-800`}>{children}</div>;
}

export default Section;
