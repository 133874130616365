import React, { useEffect, useState, useMemo } from 'react';
import LoadSpinner from '../../../Clab/components/Loading/LoadSpinner';
import AssessmentQuestion from './AssessmentQuestion';
import { useCybAssessment } from '../../../../providers/CybAssessmentProvider';
import IncompleteScreen from './IncompleteScreen';
import AssessmentTimer from './AssessmentTimer';
import { useClab } from '../../../../providers/ClabProvider';
import Modal from '../../../Modal/Modal';

function SubmitBar({ questions, completeActivity, isDoubleSidebar }) {
  const { launched, getTotalAnsweredQuestions, expires, submitAssessment } = useCybAssessment();

  if (!launched) {
    return null;
  }

  if (!questions?.length) {
    return null;
  }

  const afterSubmitCallback = () => completeActivity();

  const forceSubmit = async () => {
    await submitAssessment(afterSubmitCallback, true);
  };

  const total = questions.length;
  const num = getTotalAnsweredQuestions();
  const allAnswered = num === total;

  const submitColor = allAnswered ? 'text-white bg-cyb-pink-500 hover:bg-pink-600' : 'text-gray-800 bg-gray-300 hover:bg-gray-400';
  const widthClass = isDoubleSidebar ? 'w-[380px]' : 'w-full';

  return (
    <div className={`flex overflow-hidden fixed top-16 z-10 justify-between items-center px-6 ${widthClass} h-16 bg-white border-r border-gray-400 shadow-sm`}>
      <div className="text-base font-bold">Questions {`(${num}/${total})`}</div>
      {!!expires && <AssessmentTimer expires={expires} onEnd={forceSubmit} />}
      <button
        type="button"
        onClick={() => submitAssessment(afterSubmitCallback)}
        className={`py-2 px-6 text-base font-semibold text-center rounded-md cursor-pointer ${submitColor}`}
      >
        Submit
      </button>
    </div>
  );
}

function AssessmentQuestionWrapper({ question, getQuestionNameForId, getQuestionValueForId, onUpdate }) {
  const { 'question-id': questionId } = question;
  const name = getQuestionNameForId(questionId);
  const currentValue = getQuestionValueForId(questionId);
  return (
    <div className="mx-6 rounded-sm border last:border-1 border-b-0 border-gray-400">
      <AssessmentQuestion question={question} name={name} value={currentValue} onUpdate={onUpdate} num={question.ordinal} className="bg-gray-100" />
    </div>
  );
}

function AssessmentInstructionWrapper({ instruction }) {
  // eslint-disable-next-line react/no-danger
  const paragraphs = instruction.instruction.split('\n').map((paragraph, index) => <p className="italic" key={`${`${index}`}`} dangerouslySetInnerHTML={{ __html: paragraph }} />);

  return <div className="py-6 px-3 mx-6 text-gray-900 bg-white border-t-1 border-gray-400">{paragraphs}</div>;
}

function ChallengeAssessment({ completeActivity, isDoubleSidebar = false }) {
  const { resources } = useClab();

  const { loading, starting, launched, assessment, onUpdate, getQuestionNameForId, getQuestionValueForId, incompleteSubmission, setIncompleteSubmission, submitting, submitted } =
    useCybAssessment();

  const [canStart, setCanStart] = useState(false);

  const statusText = useMemo(() => {
    if (starting) {
      return 'Starting...';
    }
    if (submitting || submitted) {
      return 'Submitting...';
    }
    return 'Loading';
  }, [starting, submitting, submitted]);

  useEffect(() => {
    // Check to see if we have at least one resource set to 'running', if so, we are allowed to start this lab
    if (canStart || !resources?.length) {
      return;
    }
    const hasRunningResource = () => {
      for (let i = 0; i < resources.length; i++) {
        if (resources[i].status === 'running') {
          return true;
        }
      }
      return false;
    };
    setCanStart(hasRunningResource());
  }, [resources]);

  if (loading || starting || submitting || submitted) {
    return (
      <div className="flex items-center w-full h-full">
        <LoadSpinner text={statusText} spinnerClass="h-14 w-14 text-center" />
      </div>
    );
  }

  // Nothing to display if there are no questions or if we haven't yet launched the assessment
  if (!assessment?.items?.length || !launched) {
    return null;
  }

  return (
    <>
      <div className="relative pt-10">
        <SubmitBar questions={assessment?.questions} completeActivity={completeActivity} isDoubleSidebar={isDoubleSidebar} />
        <div className="pt-4 pb-20">
          {assessment?.items.map((item) => {
            const key = `${item.item.id ?? item.item.ordinal}_${item.item.type}`;

            if (item.type === 'INSTRUCTION') {
              return <AssessmentInstructionWrapper instruction={item.item} key={key} />;
            }

            return (
              <AssessmentQuestionWrapper
                question={item.item}
                getQuestionNameForId={getQuestionNameForId}
                getQuestionValueForId={getQuestionValueForId}
                onUpdate={onUpdate}
                key={key}
              />
            );
          })}
        </div>
      </div>

      <Modal size="sm" position="center" ariaLabelledBy="incomplete-assessment" open={incompleteSubmission} toggle={() => setIncompleteSubmission(false)}>
        <IncompleteScreen completeActivity={completeActivity} />
      </Modal>
    </>
  );
}

export default ChallengeAssessment;
