import React from 'react';
import { inject, observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import AchievementsMessage from './AchievementsMessage';

const GoalAchievementMessage = inject('goalsStore')(
  observer(({ immersiveView, goalsStore }) => {
    const { trackingGoal, lookForNewTrackingGoal } = goalsStore;
    const location = useLocation() || window.location;
    const isOnboarding = /onboarding\//g.test(location.pathname);
    if (!trackingGoal || trackingGoal.status === 'in_progress' || isOnboarding) {
      return null;
    }
    const titleClasses = 'text-2xl font-black';
    const messageClasses = 'text-sm';
    return (
      <AchievementsMessage visible immersiveView={!!immersiveView} onDismiss={() => lookForNewTrackingGoal()}>
        {trackingGoal.status === 'completed' ? (
          <>
            <p className={titleClasses}>Well done!</p>
            <p className={messageClasses}>You&apos;ve just completed the {trackingGoal.name} goal- pat yourself on the back!</p>
          </>
        ) : (
          <>
            <p className={titleClasses}>Goal expired</p>
            <p className={messageClasses}>The deadline just passed for your goal of {trackingGoal.name}.</p>
          </>
        )}
      </AchievementsMessage>
    );
  })
);

export default GoalAchievementMessage;
