import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import Cookies from 'js-cookie';
import UserOnboardingStep from '../../UserOnboardingStep';
import DynamicForm from '../../../DynamicForm/DynamicForm';
import { useUserOnboarding } from '../../../../providers/UserOnboardingProvider';
import { COUNTRIES, APP_FORM_IDS, SHARED_DEPARTMENTS, SHARED_ROLES, TEAM_LEADS } from '../../../../constants';
import OnboardingFormContainer from '../../OnboardingFormContainer';

function TeamFormStepComponent({ userStore, authStore }) {
  const { setSavedFormFields, teamOrCip, saveUserOnboardingData, exitUserOnboarding, isSubmitting, isLoading, profileData } = useUserOnboarding();
  const { user, teamData, isEnterprise } = userStore;
  const navigate = useNavigate();
  const [defaultCountryCode, setDefaultCountryCode] = React.useState(null);

  // Grab the admin's name from the cookie set during checkout
  const [adminFirstName, adminLastName] = useMemo(() => {
    const adminName = Cookies.get('cyb_an');
    const nameParts = adminName?.split(' ', 2);
    return [nameParts?.[0], nameParts?.[1]];
  }, []);

  const handleStep = async (formFields) => {
    setSavedFormFields(formFields);
    await saveUserOnboardingData(formFields);

    // Exit the onboarding flow
    await exitUserOnboarding();

    navigate('/enterprise/demo');
  };

  useEffect(() => {
    // grab the user's country code from geo cookie
    (async () => {
      try {
        const geo = await authStore.getGeoInfo();
        setDefaultCountryCode(geo?.planRegion || 'US');
      } catch (e) {
        setDefaultCountryCode('US');
      }
    })();
  }, []);

  const requiredIfNotEnterprise = isEnterprise ? [] : ['required'];

  const TEAM_ONBOARDING_FORM_FIELDS = {
    order: [['firstName', 'lastName'], ['title', 'teamLead'], 'department', 'company', 'business_email', 'phone', 'country', 'membershipFor', 'goal', 'submit'],
    fields: {
      firstName: {
        id: 'onboarding-team-first-name',
        type: 'text',
        label: 'First Name',
        validations: ['required'],
        defaultValue: user?.first_name || adminFirstName,
      },
      lastName: {
        id: 'onboarding-team-last-name',
        type: 'text',
        label: 'Last Name',
        validations: ['required'],
        defaultValue: user?.last_name || adminLastName,
      },
      title: {
        id: 'onboarding-team-title',
        type: 'select',
        label: 'Title',
        placeholder: 'Select a title',
        options: SHARED_ROLES,
        validations: requiredIfNotEnterprise,
        defaultValue: profileData?.title,
      },
      teamLead: {
        id: 'onboarding-team-team-lead',
        type: 'select',
        label: 'Do you lead a team?',
        placeholder: 'Select an option',
        options: TEAM_LEADS,
        validations: requiredIfNotEnterprise,
        defaultValue: profileData?.teamLead,
      },
      department: {
        id: 'onboarding-team-department',
        type: 'select',
        placeholder: 'Select Department',
        label: 'Department',
        quasiRequired: !isEnterprise,
        options: SHARED_DEPARTMENTS,
        defaultValue: profileData?.department,
      },
      company: {
        id: 'onboarding-team-company',
        type: 'text',
        label: 'Company',
        validations: ['required'],
        defaultValue: profileData?.company || teamData?.name,
      },
      business_email: {
        id: 'onboarding-business-email',
        type: 'email',
        label: 'Business Email',
        placeholder: 'name@example.com',
        validations: ['required'],
        description: 'Please enter your business email address. This form does not accept addresses from public email providers.',
        defaultValue: profileData?.business_email || user?.email,
      },
      phone: {
        id: 'onboarding-team-phone',
        type: 'phone',
        label: 'Phone Number',
        validations: ['required'],
        defaultValue: user?.phone,
      },
      country: {
        id: 'onboarding-team-country',
        type: 'select',
        label: 'Country',
        placeholder: 'Select a country',
        defaultValue: defaultCountryCode || 'US',
        options: COUNTRIES,
        validations: ['required'],
      },
      membershipFor: {
        id: 'onboarding-team-membership-for',
        type: 'text',
        defaultValue: teamOrCip,
        className: 'hidden',
      },
      goal: {
        id: 'onboarding-team-goal',
        type: 'text',
        defaultValue: '',
        className: 'hidden',
      },
      submit: {
        type: 'button',
        color: 'pink',
        className: 'mx-auto',
        label: 'Submit',
        loading: isSubmitting || isLoading,
      },
    },
  };

  // Wait until we have the user's country code before rendering the form
  // This avoid a race condition where the form is rendered before the country code is fetched
  // DynamicForm will not pick up this state update if it happens after the form is rendered
  if (!defaultCountryCode) return null;

  return (
    <UserOnboardingStep title="Create Your Profile">
      <OnboardingFormContainer>
        <DynamicForm form={TEAM_ONBOARDING_FORM_FIELDS} formId={APP_FORM_IDS.ONBOARDING.TEAM} onSubmit={handleStep} />
      </OnboardingFormContainer>
    </UserOnboardingStep>
  );
}

const TeamFormStep = inject('userStore', 'authStore')(observer(TeamFormStepComponent));
export default TeamFormStep;
