import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import DatePicker from '../DatePicker/DatePicker';
import SelectBoxes from '../SelectBoxes/SelectBoxes';
import WizardStepDescription from './WizardStepDescription';
import Dropdown from '../Dropdown/Dropdown';
import GoalsUtil from '../../utils/goalsUtil';
import Divider from '../Divider/Divider';
import If from '../If/If';

const getDeadlineOptions = (omitRecurringOption, disableRecurringOption, isEditView) => {
  const options = [
    {
      text: 'By a specific date',
      value: 'date',
      id: 'deadline-specific-date',
    },
    {
      text: 'No deadline',
      value: 'none',
      id: 'deadline-no-deadline',
    },
  ];

  if (!isEditView) {
    options.unshift(
      {
        text: '30 days',
        value: 'date_30',
        id: 'deadline-30-days',
      },
      {
        text: '60 days',
        value: 'date_60',
        id: 'deadline-60-days',
      },
      {
        text: '90 days',
        value: 'date_90',
        id: 'deadline-90-days',
      }
    );
  }

  if (!omitRecurringOption) {
    options.push({
      text: 'On a recurring basis',
      value: 'recurring',
      id: 'deadline-recurring',
      disabled: !!disableRecurringOption,
      tooltip: disableRecurringOption && 'To create a recurring goal, please create a new goal.',
    });
  }
  return options;
};

function RecurringGoalSection({ selectedDueInterval, inputRef, goalType, onChange }) {
  const [duration, setDuration] = useState('');
  const [interval, setInterval] = useState('');
  const [init, setInit] = useState(false);

  useEffect(() => {
    if (selectedDueInterval && selectedDueInterval.length) {
      const incrementObj = GoalsUtil.getIntervalBreakdown(selectedDueInterval);
      setDuration(incrementObj.count);
      setInterval(incrementObj.increment);
    }
    setInit(true);
  }, []);

  useEffect(() => {
    // Prevent this from firing on first render
    if (init) {
      if (duration && duration.length && interval && interval.length) {
        onChange('due_interval', `P${duration}${interval}`);
      } else {
        onChange('due_interval', null);
      }
    }
  }, [duration, interval]);

  const handleChange = (field, val) => {
    // Check if field is duration, and value is a number and less than 3 digits in length
    if (field === 'duration' && /^[0-9]{0,2}$/.test(val)) {
      // Make sure it's a valid val
      setDuration(val);
    } else if (field === 'interval') {
      setInterval(val);
    }
  };

  return (
    <div>
      <Divider marginTop="mt-6" marginBottom="mb-6" />
      <div ref={inputRef} />
      <div className="mb-4 text-sm text-gray-600">
        <WizardStepDescription step="recurring" goalType={goalType} />
      </div>
      <div className="flex items-center">
        <span>Every</span>
        <input
          id="recurring-duration"
          name="recurring-duration"
          required
          placeholder="Duration"
          onChange={(e) => handleChange('duration', e.target.value)}
          className="p-2 ml-2 w-36 rounded border border-gray-400 disabled:cursor-not-allowed"
          value={duration}
        />
        <Dropdown
          value={interval}
          placeholder="Interval"
          options={[
            {
              key: 'weeks',
              text: 'Weeks',
              value: 'W',
            },
            {
              key: 'months',
              text: 'Months',
              value: 'M',
            },
          ]}
          onChange={(e) => handleChange('interval', e.value)}
          onBlur={false}
          classes="w-36 ml-2 disabled:cursor-not-allowed"
        />
      </div>
    </div>
  );
}

function WizardTimeframeSelection({ selectedDueType, selectedDueDate, selectedDueInterval, setValue, goalType, isEditView, disableRecurringOption, omitRecurringOption }) {
  const inputRef = useRef();
  const onChange = (section, val) => {
    setValue(section, val);
    if (section === 'due_type') {
      setValue('due_date', null);
      onChange('due_interval', null);
      if (val === 'date' || val === 'recurring') {
        setTimeout(() => {
          inputRef?.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 400);
      }
    }
  };
  const [selectedPresetDueType, setSelectedPresetDueType] = useState(null);
  const dueDate = selectedDueDate ? new Date(moment(selectedDueDate)) : null;

  const onSelectDueType = (selection) => {
    if (selection.includes('date_')) {
      setSelectedPresetDueType(selection);
      onChange('due_type', 'date');
      const days = selection.split('_')[1];
      const presetDueDate = moment().add(Number(days), 'days').format('YYYY-MM-DD');
      onChange('due_date', presetDueDate);
    } else {
      setSelectedPresetDueType(null);
      onChange('due_type', selection);
    }
  };

  return (
    <>
      <SelectBoxes
        containerClasses="overflow-y-auto w-11/12"
        selections={selectedPresetDueType ?? selectedDueType}
        handleSelect={onSelectDueType}
        options={getDeadlineOptions(omitRecurringOption, disableRecurringOption, isEditView)}
      />
      <If condition={selectedDueType === 'date' && !selectedPresetDueType}>
        <div>
          <Divider marginTop="mt-6" marginBottom="mb-6" />
          <div ref={inputRef} />
          <div className="mb-4 text-sm text-gray-600">
            <WizardStepDescription step="date" goalType={goalType} />
          </div>
          <DatePicker
            onChange={(date) => onChange('due_date', moment(date).format('YYYY-MM-DD'))}
            value={dueDate || null}
            className="w-60"
            style={{ paddingTop: '5px', paddingBottom: '5px' }}
            label="Due Date"
            defaultOpen
          />
        </div>
      </If>
      <If condition={selectedDueType === 'recurring'}>
        <RecurringGoalSection selectedDueInterval={selectedDueInterval} inputRef={inputRef} goalType={goalType} onChange={onChange} />
      </If>
    </>
  );
}

export default WizardTimeframeSelection;
