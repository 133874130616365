import React, { useState } from 'react';
import Switch from 'react-switch';
import { getCN } from '../../utils/formatUtil';

function Checkbox({
  id,
  name,
  label,
  ariaDescribedBy,
  required,
  value,
  disabled,
  checked,
  onChange,
  className,
  labelClasses,
  inputClasses,
  toggle,
  ariaLabel,
  ariaLabelledBy,
  width,
  height,
}) {
  const labelClass = getCN(`
  ${labelClasses || 'text-gray-600'}
  cursor-pointer
  `);

  const wrapperClass = getCN(`
  ${className || 'mb-2 pb-2 flex items-start mr-4'}
  `);

  const inputClass = getCN(`
  mr-2 mt-1 w-4 h-4 shrink-0 ${inputClasses}
  `);
  // eslint-disable-next-line no-unused-vars
  const [input, setInput] = useState({});

  const handleChange = (e) => {
    setInput({ value: e.target.value, checked: e.target.checked });
    onChange(undefined, { name, value: e.target.value, checked: e.target.checked });
  };

  if (toggle) {
    return (
      <div className={wrapperClass}>
        <Switch
          onChange={(isChecked) => handleChange({ target: { value: isChecked, checked: isChecked } })}
          aria-describedby={ariaDescribedBy}
          aria-label={ariaLabel}
          aria-labelledby={ariaLabelledBy}
          checked={!!checked}
          checkedIcon={false}
          uncheckedIcon={false}
          height={height || 20}
          width={width || 40}
          activeBoxShadow="0 0 0 2px #0055c5"
          offColor="#707070"
          onColor="#2F855A"
          disabled={disabled}
          name={name}
          className={inputClass}
        />
      </div>
    );
  }

  const InputElement = (
    <input
      type="checkbox"
      aria-describedby={ariaDescribedBy}
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledBy}
      id={id}
      name={name}
      value={value}
      disabled={disabled}
      checked={checked}
      onChange={handleChange}
      className={inputClass}
      aria-required={required}
    />
  );

  return (
    <div className={wrapperClass}>
      {label ? (
        <label htmlFor={id}>
          {InputElement}
          <span className={labelClass}>{label}</span>
        </label>
      ) : (
        InputElement
      )}
    </div>
  );
}
export default Checkbox;
