import React from 'react';
import { useTimer } from 'react-timer-hook';

function PaddedNumber({ num }) {
  const numAsString = `${num}`;
  return numAsString.padStart(2, '0');
}

export default function AssessmentTimer({ expires, onEnd }) {
  const handleExpire = () => {
    if (onEnd) {
      onEnd();
    }
  };

  const { isRunning, minutes, hours, seconds } = useTimer({ expiryTimestamp: new Date(expires), autoStart: true, onExpire: handleExpire });

  const totalTime = hours * 60 * 60 + minutes * 60 + seconds;

  const textColor = totalTime < 300 ? 'text-red-600' : 'text-green-600';

  return (
    <div className={`flex-1 font-mono text-base text-center ${textColor}`}>
      {isRunning && (
        <>
          <PaddedNumber num={hours} />
          :
          <PaddedNumber num={minutes} />
          :
          <PaddedNumber num={seconds} />
        </>
      )}
      {!isRunning && <>Ended</>}
    </div>
  );
}
