import React from 'react';
import Card from '../Card/Card';
import LoadingText from '../Loading/LoadingText';

/**
 * Loading Card for ABM Widgets
 * @returns {JSX} - The Loading Card
 */
function LoadingCard() {
  return (
    <Card inlineContent stackDisplay>
      <LoadingText wrapperClassName="w-1/5" />
      <LoadingText wrapperClassName="w-3/5" />
      <LoadingText wrapperClassName="w-1/5" />
    </Card>
  );
}

export default LoadingCard;
