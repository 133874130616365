import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Icon from '../Icon/Icon';

function ListIcon({ icon, iconSize, iconColor, iconClassName }) {
  return icon ? <Icon name={`${icon}`} size={`${iconSize}`} color={`${iconColor}`} className={iconClassName} /> : null;
}

export function ListItem({ item, children, icon, iconSize, iconColor, iconClassName, className, onClick }) {
  return onClick ? (
    <button className={className || ''} onClick={onClick}>
      <ListIcon icon={icon} iconSize={iconSize} iconColor={iconColor} iconClassName={iconClassName} /> {item || children}
    </button>
  ) : (
    <div role="listitem" className={className || ''}>
      <ListIcon icon={icon} iconSize={iconSize} iconColor={iconColor} iconClassName={iconClassName} />
      {item || children}
    </div>
  );
}

function List({ className, children, content }) {
  const listContent = [];
  if (children && Array.isArray(children)) {
    const newChildren = [];
    children.forEach((child) => {
      const newChild = child && child.props && !child.props.role ? React.cloneElement(child, { role: 'listitem', key: uuidv4() }) : child;
      newChildren.push(newChild);
    });
    listContent.push(newChildren);
  } else if (children && typeof children === 'object') {
    const newChild = children.props && !children.props.role ? React.cloneElement(children, { role: 'listitem', key: uuidv4() }) : children;
    listContent.push(newChild);
  } else if (content && content.length) {
    content.forEach((item) => {
      listContent.push(<ListItem item={item} key={uuidv4()} />);
    });
  }
  return (
    <div role="list" className={className || ''}>
      {listContent}
    </div>
  );
}

export default List;
