import React from 'react';
import moment from 'moment';
import FormatUtil from '../../utils/formatUtil';
import Image from '../Image/Image';

function TeamHeader({ data }) {
  if (!data || !Object.keys(data).length) {
    return null;
  }

  const { name, max_seats, active_seats, member_count, status, created_at, logo_url } = data;
  const detailsClass = 'text-sm mb-0';
  const detailsHeadingClass = 'font-semibold mr-2';
  return (
    <div className="flex gap-x-8 items-center">
      {logo_url && <Image src={logo_url} marginBottom="mb-0" omitMarginAuto />}
      <div>
        <h1 className="my-4 text-4xl font-black lg:text-5xl">{name}</h1>
        <p className={detailsClass}>
          <span className={detailsHeadingClass}>Created at:</span> {moment(created_at).format('MM/DD/YYYY')}
        </p>
        <p className={detailsClass}>
          <span className={detailsHeadingClass}>Total Members:</span> {member_count}
        </p>
        {max_seats && active_seats ? (
          <p className={detailsClass}>
            <span className={detailsHeadingClass}>Licenses Used:</span> {FormatUtil.formatNumbers(active_seats)} of {FormatUtil.formatNumbers(max_seats)}
          </p>
        ) : null}
        <p className={detailsClass}>
          <span className={detailsHeadingClass}>Status:</span> {status}
        </p>
      </div>
    </div>
  );
}

export default TeamHeader;
