import Bugsnag from '@bugsnag/js';
import { inject, observer } from 'mobx-react';
import React, { useEffect } from 'react';
import SearchResults from '../Browse/SearchResults';
import withRouter from '../Router/withRouter';

/**
 * Uses the searchStore to load and display content
 * according to the categories defined in the searchStore
 * Used in custom search pages (CVE/Cybrary challenges) to
 * build upon this core display
 * @param tags Tags to filter results by
 * @param operand 'AND' | 'OR'
 */
const SearchGridFiltered = inject('searchStore')(
  observer(({ searchStore, navigate, tags = [], operand = 'AND' }) => {
    useEffect(() => {
      (async () => {
        // Use searchStore to load in all tagged content
        // (i.e. courses, TAC's, Labs, etc..) and display
        // each content type in its own labeled grid carrousel
        try {
          if (tags?.length) {
            searchStore.addFilter('tags_info', tags, operand);
          }
          await searchStore.loadSearchItems();
        } catch (err) {
          Bugsnag.notify(err);
        }
      })();
      return () => {
        searchStore.setDefaultSearchItems();
        searchStore.setDefaultSearchFilters();
      };
    }, []);

    const { searchItems } = searchStore;
    const tagsQueryParams = tags.map((tag) => `tags_info=${encodeURIComponent(tag)}`).join('&');
    const changeCategoryView = () => navigate(`/browse/refined?${tagsQueryParams}&resultsView=grid&view=course`);

    return <SearchResults {...searchItems} currentPage={searchStore.searchFilters.page} changeCategoryView={changeCategoryView} />;
  })
);

export default withRouter(SearchGridFiltered);
