import React from 'react';
import { v4 as uuidv4 } from 'uuid';

function Paths({ active }) {
  if (active) {
    return (
      <>
        <path d="M12.6816 25.835C13.245 27.1086 14.52 28 16 28C17.4799 28 18.7548 27.1086 19.3183 25.835H12.6816Z" fill="black" />
        <path
          d="M15.9989 6.29297C16.9755 6.29297 17.9133 6.46528 18.7835 6.78076V6.67356C18.7835 5.19935 17.5841 4 16.11 4H15.8884C14.4142 4 13.2148 5.19935 13.2148
            6.67356V6.77939C14.0846 6.46486 15.022 6.29297 15.9989 6.29297Z"
          fill="black"
        />
        <path
          d="M24.7872 24.426H7.21114C6.87862 24.426 6.57404 24.2017 6.50488 23.8765C6.43642 23.5544 6.5832 23.2365 6.88097 23.099C6.95163 23.0532 7.48351
            22.6812 8.016 21.556C8.99397 19.4897 9.19924 16.5788 9.19924 14.5008C9.19924 10.7514 12.2496 7.70117 15.9989 7.70117C19.7394 7.70117 22.7841 10.7367
            22.7986 14.4738C22.7989 14.4828 22.7991 14.4918 22.7991 14.5008C22.7991 16.5788 23.0044 19.4897 23.9823 21.556C24.5148 22.6812 25.0467 23.0532 25.1174
            23.099C25.4151 23.2365 25.562 23.5544 25.4935 23.8765C25.4243 24.2017 25.1198 24.426 24.7872 24.426ZM25.1253 23.104H25.1257H25.1253Z"
          fill="black"
        />
      </>
    );
  }
  return (
    <path
      d="M25.1024 23.0994C25.0318 23.0536 24.5009 22.6816 23.9692 21.5564C22.9928 19.49 22.7879 16.5792 22.7879 14.5012C22.7879 14.4921 22.7877 14.4832 22.7874
          14.4742C22.7767 11.7278 21.1322 9.36034 18.7789 8.29811V6.67356C18.7789 5.19934 17.5815 4 16.1098 4H15.8886C14.4168 4 13.2194 5.19934 13.2194 6.67356V8.29802C10.8582
          9.36367 9.21041 11.7432 9.21041 14.5012C9.21041 16.5792 9.00548 19.49 8.02912 21.5564C7.49751 22.6816 6.96656 23.0535 6.89596 23.0994C6.59868 23.2369 6.4521
          23.5547 6.52049 23.8768C6.58954 24.2021 6.89357 24.4263 7.22559 24.4263H12.3794C12.4081 26.4017 14.0204 28 15.9992 28C17.978 28 19.5902 26.4017 19.619
          24.4263H24.7728C25.1047 24.4263 25.4088 24.2021 25.4779 23.8768C25.5462 23.5547 25.3996 23.2369 25.1024 23.0994ZM14.6257 6.67356C14.6257 5.97602 15.1922 5.40855
          15.8886 5.40855H16.1098C16.8062 5.40855 17.3727 5.97602 17.3727 6.67356V7.84155C16.9289 7.74981 16.4695 7.70153 15.999 7.70153C15.5286 7.70153 15.0694 7.74977
          14.6257 7.84141L14.6257 6.67356ZM15.9992 26.5915C14.7958 26.5915 13.8141 25.625 13.7856 24.4264H18.2127C18.1842 25.625 17.2026 26.5915 15.9992 26.5915ZM18.8304
          23.0177H8.82801C8.94965 22.8282 9.07345 22.6135 9.19649 22.371C10.1389 20.5127 10.6167 17.8649 10.6167 14.5012C10.6167 11.5285 13.0312 9.11008 15.9989 9.11008C18.9667
          9.11008 21.3812 11.5285 21.3812 14.5035C21.3812 14.5122 21.3813 14.5208 21.3816 14.5294C21.3843 17.8797 21.8621 20.518 22.8019 22.371C22.9249 22.6136 23.0488
          22.8282 23.1704 23.0177H18.8304Z"
      fill="currentColor"
      strokeWidth="0.1"
    />
  );
}

export default function BellIcon({ active, classes, id, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';
  const strokeProps = active ? {} : { stroke: 'currentColor' };
  const myId = `bellicon${id || uuidv4()}`;
  return (
    <svg className={className} fill="none" {...strokeProps} {...accessibilityProps} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
      <g clipPath={`url(#${myId})`}>
        <Paths active={active} />
      </g>
      <defs>
        <clipPath id={myId}>
          <rect width="24" height="24" fill="white" transform="translate(4 4)" />
        </clipPath>
      </defs>
    </svg>
  );
}
