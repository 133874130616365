import React, { useState, useEffect } from 'react';
import fieldClasses from './fieldClasses';

function Input({
  id,
  name,
  type,
  defaultValue,
  disabled,
  required,
  placeholder,
  ariaDescribedBy,
  error,
  onChange,
  onFocus,
  onBlur,
  autocomplete,
  minNumber,
  maxNumber,
  className,
  onKeyDown,
  ariaLabel,
  value,
  onChangeCallback,
}) {
  const [input, setInput] = useState(defaultValue || '');
  const localClassName = className || fieldClasses(error, disabled);

  useEffect(() => {
    if (defaultValue) {
      setInput(defaultValue);
    }
  }, [defaultValue]);

  const handleChange = (val) => {
    setInput(val);
    // Dynamic form onChange handler
    if (onChange) {
      onChange(undefined, { name, value: val });
    }
    // Custom onChange handler
    if (typeof onChangeCallback === 'function') {
      onChangeCallback(val);
    }
  };

  return (
    <input
      disabled={disabled || false}
      id={id}
      className={localClassName}
      type={type}
      placeholder={placeholder}
      value={value ?? input}
      onFocus={onFocus || null}
      onBlur={onBlur || null}
      onKeyDown={onKeyDown || null}
      onChange={(e) => {
        handleChange(e.target.value);
      }}
      autoComplete={autocomplete || ''}
      aria-label={ariaLabel}
      aria-required={required}
      aria-describedby={ariaDescribedBy}
      min={minNumber || null}
      max={maxNumber || null}
    />
  );
}
export default Input;
