import React from 'react';
import { observer, inject } from 'mobx-react';

import DynamicForm from '../../../DynamicForm/DynamicForm';
import { useProfileContext } from '../../../../providers/ProfileProvider';

/**
 * @param {function} handleClose - function to close the add certification by updating state card after submitting or canceled is clicked
 * @param {object} commonStore - commonStore passed in as props
 * @param {array} certOptions - array of certification options
 * @returns <AddCertificationCard /> component
 */
const AddCertificationCard = inject('commonStore')(
  observer(({ commonStore, handleClose, certOptions }) => {
    const { certificationsProfileData, updateProfile } = useProfileContext();
    const NewCertificationForm = {
      id: 'certificationsForm',
      name: 'Certifications',
      order: ['name', ['issuedAt', 'expiresAt'], ['cancel', 'submit']],
      fields: {
        name: {
          type: 'select',
          label: 'Certification Name',
          placeholder: 'Select a Certification',
          validations: ['required'],
          options: certOptions,
        },
        issuedAt: {
          type: 'datePicker',
          label: 'Issued At',
          dateFormat: 'YYYY-MM',
          className: 'w-1/2',
          validations: ['required'],
        },
        expiresAt: {
          type: 'datePicker',
          label: 'Expired At',
          dateFormat: 'YYYY-MM',
          validations: ['required'],
        },
        cancel: {
          type: 'button',
          label: 'Cancel',
          className: 'py-3 px-6 ml-auto text-sm font-bold text-cyb-pink-500 rounded-lg border-1 border-cyb-pink-500 mr-4',
          onClick: handleClose,
        },
        submit: {
          type: 'button',
          label: 'Save Changes',
          color: 'pink',
          className: 'py-3 px-6 ml-auto text-sm font-bold rounded-lg',
        },
      },
    };

    const handleSubmit = (formValues) => {
      const cleanedFormValues = {
        certId: certOptions.find((cert) => cert.text === formValues.name)?.id,
        company: certOptions.find((cert) => cert.text === formValues.name)?.text,
        thumbnailUrl: certOptions.find((cert) => cert.text === formValues.name)?.thumbnailUrl,
        permalink: certOptions.find((cert) => cert.text === formValues.name)?.url,
        name: formValues.name,
        issuedAt: formValues.issuedAt,
        expiresAt: formValues.expiresAt,
      };

      const updatedCertifications = [...certificationsProfileData, cleanedFormValues];
      updateProfile('certifications', { certifications: updatedCertifications }).then((response) => {
        if (response) {
          commonStore.triggerToast('success', {
            content: `Certification Successfully added`,
          });
          handleClose();
        } else {
          commonStore.triggerToast('error', {
            content: `Error adding certifications`,
          });
        }
      });
    };
    return (
      <div id="add-cert-card" className="px-4 pt-4 pb-0 mt-4 bg-gray-200 rounded-lg border-1 border-gray-600">
        <DynamicForm id="add-certification-form" form={NewCertificationForm} onSubmit={handleSubmit} />
      </div>
    );
  })
);
export default AddCertificationCard;
