import React, { useState } from 'react';
import Icon from '../Icon/Icon';

function getStyle(status) {
  switch (status) {
    case 'error':
      return {
        borderColor: 'border-cyb-red-700',
        headerTextColor: 'text-cyb-red-700',
        msgTextColor: 'text-cyb-red-700',
      };
    case 'warning':
      return {
        borderColor: 'border-yellow-700',
        headerTextColor: 'text-orange-700',
        msgTextColor: 'text-orange-700',
      };
    case 'success':
      return {
        borderColor: 'border-cyb-green-700',
        headerTextColor: 'text-cyb-green-700',
        msgTextColor: 'text-cyb-green-700',
      };
    case 'info':
      return {
        borderColor: 'border-cyb-blue-500',
        headerTextColor: 'text-cyb-blue-500',
        msgTextColor: 'text-cyb-blue-500',
      };
    default:
      return {
        borderColor: 'border-gray-400',
        headerTextColor: 'text-black',
        msgTextColor: 'text-black',
        bgColor: 'bg-gray-300',
      };
  }
}

function Message({ status, className, children, msgHeader, msgBody, icon, width = 'w-full', isDismissible = false, onDismiss = () => {} }) {
  const [isHidden, setHidden] = useState(false);
  if (isHidden) {
    return null;
  }
  const { borderColor, headerTextColor, msgTextColor, bgColor } = getStyle(status);
  const formattedClassName = className || '';
  const formatDivClassName = `${borderColor} ${bgColor} ${headerTextColor} ${formattedClassName}`;
  const formatHeaderClassName = `${headerTextColor}`;
  const formatMsgClassName = `${msgTextColor}`;
  return (
    <div className={`${width} flex items-center flex-col md:flex-row border-1 border-solid rounded py-4 px-6 ${formatDivClassName}`}>
      {icon && (
        <div className="mx-auto md:pr-4 md:mx-0">
          <Icon name={icon} />
        </div>
      )}
      <div className={`flex-auto ${isDismissible ? 'mr-4' : ''}`}>
        {msgHeader && <div className={`font-bold text-base ${formatHeaderClassName}`}>{msgHeader}</div>}
        {msgBody && <div className={`text-sm ${formatMsgClassName}`}>{msgBody}</div>}
        {!msgBody && children}
      </div>
      {isDismissible && (
        <div
          className="relative top-[-1rem] right-[-1rem] cursor-pointer"
          onClick={() => {
            setHidden(true);
            onDismiss();
          }}
          title="Dismiss"
          role="button"
          tabIndex={0}
        >
          <Icon name="x" />
        </div>
      )}
    </div>
  );
}
export default Message;
