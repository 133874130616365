import React, { useEffect, useState } from 'react';
import { observer, inject } from 'mobx-react';
import { useParams, useNavigate } from 'react-router-dom';
import Loading from '../../components/Loading/Loading';
import Title from '../../components/Title/Title';
import Container from '../../components/Container/Container';

const routeMapping = {
  dashboard: '/enterprise/:teamId/organization/dashboard',
  'create-assignment': '/enterprise/:teamId/assignment/create',
  assignments: '/enterprise/:teamId/assignments',
  members: '/enterprise/:teamId/organization/members',
  paths: '/enterprise/:teamId/paths',
  goals: '/enterprise/:teamId/goals',
  analytics: '/enterprise/:teamId/analytics',
  settings: '/enterprise/:teamId/settings',
};

const TeamsLinkRouter = inject(
  'authStore',
  'userStore'
)(
  observer(({ authStore, userStore }) => {
    const [teams, setTeams] = useState([]);
    const params = useParams();
    const navigate = useNavigate();
    const { section } = params;

    const routeUserToTeamPage = (team) => {
      if (team) {
        const sectionUrl = routeMapping[section];
        const formattedUrl = sectionUrl.replace(':teamId', team.id);

        userStore.setTeam(team);
        userStore.setTeamPermissions(team.id);
        userStore.setMetaPreferenceTeam(team.id);
        navigate(`${formattedUrl}${window.location.search}`);
      }
    };

    useEffect(() => {
      authStore.fireAttributionEvent();
      // If the section trying to be accessed isn't in the map, just redirect the user to the homepage
      if (!routeMapping[section]) {
        navigate('/');
      } else {
        const { adminTeams } = userStore;
        const adminTeamsIds = Object.keys(adminTeams);
        if (adminTeamsIds.length) {
          // If admin of only one team - Route user to that team page
          if (adminTeamsIds.length === 1) {
            routeUserToTeamPage(adminTeams[adminTeamsIds[0]]);
          } else {
            // Otherwise, show a list of teams for user to choose
            const teamsArr = [];
            adminTeamsIds.forEach((team) => {
              teamsArr.push(adminTeams[team]);
            });
            setTeams(teamsArr);
          }
        } else {
          // No admin teams, just redirect user to app dashboard
          navigate('/');
        }
      }
    }, []);

    const getSectionName = () => {
      const name = section.charAt(0).toUpperCase() + section.slice(1);
      return name.split('-').join(' ');
    };

    if (!teams.length) {
      return <Loading />;
    }

    const sectionName = getSectionName();
    return (
      <Container size="md" className="mb-16">
        <Title title={sectionName} />
        <p>Please choose which team you would like to proceed with:</p>
        <ul className="ml-2">
          {teams.map((team) => {
            return (
              <li className="mb-2" key={team.id}>
                <button onClick={() => routeUserToTeamPage(team)} className="mb-2 text-cyb-pink-500 hover:text-black cursor-pointer">
                  <span className="sr-only">View Team: </span>
                  {team.name}
                </button>
              </li>
            );
          })}
        </ul>
      </Container>
    );
  })
);

export default TeamsLinkRouter;
