import React from 'react';

function AmericanExpress({ classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';
  return (
    <svg className={className} {...accessibilityProps} version="1.1" viewBox="0 0 780 500" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M53.539,15h674.423c21.285,0,38.538,16.923,38.538,37.8v396.9   
        c0,20.875-17.253,37.8-38.538,37.8H53.539C32.254,487.5,15,470.575,15,449.7V52.8C15,31.923,32.254,15,53.539,15z"
        fill="none"
        stroke="currentColor"
        strokeWidth="30"
      />
      <polygon points="91.35 174.04 77.966 142.19 64.658 174.04 91.35 174.04" fill="currentColor" />
      <path
        d="m386.52 161.93c-2.688 1.593-5.865 1.645-9.674 1.645h-23.756v-17.749h24.08c3.408 0 6.963 0.15 9.274 1.441 2.536 1.165 4.104 
        3.643 4.104 7.064 0 3.493-1.491 6.304-4.028 7.599z"
        fill="currentColor"
      />
      <path
        d="m637.52 113.08v13.162l-7.607-13.162h-55.746v13.162l-6.986-13.162h-75.303c-12.604 0-23.684 1.72-32.632 
        6.507v-6.507h-51.966v6.507c-5.695-4.935-13.456-6.507-22.085-6.507h-189.85l-12.737 
        28.786-13.081-28.786h-59.796v13.162l-6.569-13.162h-50.996l-23.682 52.988v42.384l35.019-79.908h29.054l33.259 
        75.657v-75.657h31.917l25.593 54.208 23.507-54.208h32.56v79.908h-20.046l-0.076-62.583-28.354 
        62.583h-17.171l-28.43-62.638v62.638h-39.774l-7.513-17.822h-40.716l-7.591 17.822h-21.238v16.075h1e-3v2e-3h33.413l7.535-17.75h16.865l7.514 
        17.748h65.741v-13.569l5.868 13.626h34.13l5.867-13.83v13.772h163.38l-0.078-29.133h3.162c2.214 0.075 2.86 0.274 2.86 
        3.842v25.291h84.501v-6.782c6.813 3.567 17.415 6.782 31.365 6.782h35.549l7.606-17.748h16.867l7.44 17.748h68.506v-16.859l10.371 
        16.859h54.899v-111.45h-54.331zm-318.34 32.105h-45.77v14.404h44.673v16.38h-44.673v15.958h45.77v16.525h-65.326v-79.908h65.326v16.641zm87.956 
        32.908c2.76 3.974 3.237 7.525 3.237 14.662v15.697h-19.727l-0.074-10.077c0-4.808 
        0.471-11.724-3.087-15.569-2.856-2.81-7.211-3.419-14.252-3.419h-20.992v29.064h-19.554v-79.908h44.978c9.993 0 17.354 0.259 23.679 3.829 6.188 
        3.569 9.896 8.781 9.896 17.692 0 12.743-8.705 19.324-13.779 21.3 
        4.279 1.592 7.933 4.401 9.675 6.729zm15.512 30.359v-79.908h19.95v79.908h-19.95zm251.59-1e-3l-27.713 1e-3 
        -37.069-59.977v59.975h-39.826l-7.609-17.822h-40.623l-7.381 17.822h-22.883c-9.509 0-21.542-2.053-28.356-8.837-6.873-6.785-10.447-15.975-10.447-30.506 
        0-11.85 2.137-22.685 10.542-31.246 6.322-6.376 16.224-9.319 29.698-9.319h18.93v17.124h-18.535c-7.138 0-11.167 1.036-15.048 4.734-3.331 
        3.363-5.622 9.724-5.622 18.1 0 8.562 1.743 14.732 5.378 18.767 3.011 3.161 8.479 4.121 13.63 4.121h8.782l27.56-62.842h29.304l33.106 
        75.581v-75.581h29.776l34.374 55.651v-55.651h20.032v79.905z"
        fill="currentColor"
      />
      <polygon points="555.37 174.04 541.84 142.19 528.38 174.04" fill="currentColor" />
      <polygon
        points="280.85 329.93 232.77 329.93 232.77 314.02 275.7 314.02 275.7 297.72 232.77 297.72 232.77 283.19 281.8 283.19 303.19 306.48 280.85 329.93"
        fill="currentColor"
      />
      <polygon points="358.32 339.08 328.3 306.52 358.32 275 358.32 339.08" fill="currentColor" />
      <path d="m402.73 303.54h-25.271v-20.354h25.499c7.059 0 11.96 2.811 11.96 9.801-1e-3 6.911-4.676 10.554-12.188 10.553z" fill="currentColor" />
      <path
        d="m498.97 299.49c-2.613 1.516-5.85 1.645-9.655 1.645h-23.754v-17.949h24.08c3.479 0 6.965 0.071 9.331 1.442 2.534 1.293 4.051 3.771 4.051 
        7.19 0 3.418-1.517 6.174-4.051 7.672h-2e-3z"
        fill="currentColor"
      />
      <path
        d="m753.07 336.04v-2e-3c-4.748 6.783-14.009 10.225-26.538 10.225h-37.765v-17.14h37.61c3.732 0 6.345-0.479 7.915-1.979 1.485-1.338 2.323-3.233 
        2.312-5.213 0-2.328-0.949-4.179-2.391-5.285-1.418-1.221-3.481-1.771-6.891-1.771-18.36-0.609-41.268 0.553-41.268-24.741 0-11.595 7.534-23.794 
        28.058-23.794h38.953v-15.904h-36.191c-10.922 0-18.854 2.555-24.477 6.526v-6.526h-53.529c-8.56 0-18.608 2.074-23.361 
        6.526v-6.526h-95.592v6.526c-7.606-5.358-20.445-6.526-26.37-6.526h-63.053v6.526c-6.018-5.69-19.404-6.526-27.562-6.526h-70.568l-16.148 
        17.069-15.125-17.069h-105.41v111.53h103.43l16.639-17.34 15.676 17.34 63.755 0.053v-26.233h6.269c8.459 0.127 18.436-0.202 
        27.237-3.921v30.098h52.583v-29.066h2.54c3.234 0 3.556 0.131 3.556 3.291v25.772h159.75c10.143 0 20.741-2.536 26.612-7.136v7.136h50.672c10.545 
        0 20.844-1.442 28.68-5.138v-20.777h-4e-3zm-350.8-16.111h-24.4v26.809h-38.009l-24.08-26.459-25.023 26.459h-77.461v-79.93h78.652l24.061 26.203 
        24.873-26.203h62.488c15.516 0 32.952 4.197 32.952 26.332 0 22.205-16.96 26.789-34.053 26.789zm117.34-3.625c2.759 3.898 3.16 7.541 3.239 
        14.59v15.844h-19.634v-10.002c0-4.805 0.478-11.928-3.16-15.646-2.857-2.862-7.212-3.546-14.347-3.546h-20.899v29.191h-19.648v-79.928h45.147c9.9 
        0 17.11 0.429 23.531 3.772 6.17 3.64 10.051 8.632 10.051 17.747-1e-3 12.759-8.712 19.268-13.858 21.266 4.353 1.57 7.909 4.382 9.578 
        6.712zm80.781-32.967h-45.794v14.531h44.676v16.307h-44.676v15.906l45.794 0.068v16.587h-65.268v-79.929h65.268v16.53zm50.249 
        63.396h-38.086v-17.141h37.936c3.708 0 6.341-0.477 7.987-1.979 1.343-1.233 
        2.311-3.029 2.311-5.21 0-2.33-1.046-4.181-2.388-5.286-1.496-1.221-3.557-1.771-6.965-1.771-18.287-0.609-41.188 0.551-41.188-24.742 0-11.592 
        7.457-23.795 27.958-23.795h39.204v17.013h-35.869c-3.559 0-5.871 0.131-7.837 1.442-2.144 1.295-2.938 3.217-2.938 5.752 0 3.014 1.818 5.063 
        4.277 5.953 2.063 0.702 4.281 0.904 7.611 0.904l10.526 0.277c10.615 0.253 17.904 2.047 22.335 6.429h-1e-3c3.805 3.848 5.848 8.706 5.848 
        16.935 0 17.192-10.999 25.219-30.721 25.219z"
        fill="currentColor"
      />
      <path
        d="m753.11 283.19h-35.645c-3.557 0-5.923 0.129-7.911 1.442-2.064 1.293-2.856 3.218-2.856 5.752 0 3.015 1.739 5.068 4.272 5.953 2.063 0.702 
        4.279 0.908 7.535 0.908l10.603 0.274c10.693 0.26 17.831 2.053 22.185 6.435 0.794 0.61 1.271 1.292 1.817 1.979v-22.743z"
        fill="currentColor"
      />
    </svg>
  );
}

export default AmericanExpress;
