import React from 'react';
import moment from 'moment';
import VerticalActivityFilters from './VerticalActivityFilters';
import HorizontalActivityFilters from './HorizontalActivityFilters';

const isToday = (date) => {
  if (!date) {
    return false;
  }
  const today = moment();
  const momentDate = moment(date);
  return momentDate.isSame(today, 'day');
};

const dateChange = (startDate, endDate, filters, setFilters) => {
  const startDateFormatted = moment(startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss');
  const endDateFormatted = moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss');
  const currentFilters = { ...filters };
  if (startDateFormatted !== currentFilters.startDate) {
    currentFilters.startDate = startDateFormatted;
    currentFilters.activePg = 1;
  }
  if (!isToday(endDate)) {
    currentFilters.endDate = endDateFormatted;
    currentFilters.activePg = 1;
  } else {
    delete currentFilters.endDate;
  }
  setFilters(currentFilters);
};

const filterChange = (section, value, filters, setFilters) => {
  const currentFilters = { ...filters };
  if (currentFilters[section] === value || !value.length) {
    delete currentFilters[section];
  } else {
    currentFilters[section] = value;
  }
  currentFilters.activePg = 1;
  setFilters(currentFilters);
};

const searchChange = (value, filters, setFilters) => {
  const currentFilters = { ...filters };
  if (!value || !value.length) {
    delete currentFilters.searchQuery;
  } else {
    currentFilters.searchQuery = value;
    currentFilters.activePg = 1;
  }
  setFilters(currentFilters);
};

const clearFilters = (filters, setFilters, defaultStartDate) => {
  const currentFilters = { ...filters };
  delete currentFilters.event;
  delete currentFilters.events;
  delete currentFilters.cybraryAdmin;
  delete currentFilters.endDate;
  currentFilters.startDate = defaultStartDate;
  currentFilters.activePg = 1;

  setFilters(currentFilters);
};

// Get filter values for filters that user controls
const getActiveFilters = (filters, defaultStartDate) => {
  const activeFilters = { ...filters };
  delete activeFilters.activePg;
  if (activeFilters.startDate === defaultStartDate) {
    delete activeFilters.startDate;
  }
  if (isToday(activeFilters.endDate)) {
    delete activeFilters.endDate;
  }
  return activeFilters;
};

function LogFilters({ filters, activeFilters, setFilters, defaultStartDate, defaultEndDate, type, disabled }) {
  if (type === 'horizontal') {
    return (
      <HorizontalActivityFilters
        onFilterChange={(section, value) => filterChange(section, value, activeFilters, setFilters)}
        onDateChange={(startDate, endDate) => dateChange(startDate, endDate, activeFilters, setFilters)}
        onSearchChange={(val) => searchChange(val, activeFilters, setFilters)}
        filters={filters}
        activeFilters={getActiveFilters(activeFilters, defaultStartDate)}
        defaultStartDate={defaultStartDate}
        defaultEndDate={defaultEndDate}
        disabled={disabled}
      />
    );
  }

  return (
    <VerticalActivityFilters
      onFilterChange={(section, value) => filterChange(section, value, activeFilters, setFilters)}
      onDateChange={(startDate, endDate) => dateChange(startDate, endDate, activeFilters, setFilters)}
      filters={filters}
      activeFilters={getActiveFilters(activeFilters, defaultStartDate)}
      clearAll={() => clearFilters(activeFilters, setFilters, defaultStartDate)}
      defaultStartDate={defaultStartDate}
      defaultEndDate={defaultEndDate}
      disabled={disabled}
    />
  );
}

export default LogFilters;
