import React from 'react';
import Container from '../Container/Container';

function OnboardingContainer({ children }) {
  return (
    <Container size="dashboard" className="flex-col w-[100vw] h-[100vh]" omitPadding>
      {children}
    </Container>
  );
}

export default OnboardingContainer;
