import React, { useState } from 'react';
import Icon, { ICON_NAMES } from '../../components/Icon/Icon';
import Container from '../../components/Container/Container';

function IconsPage() {
  const [size, setSize] = useState(8);
  const [isFilled, setIsFilled] = useState(false);

  const iconStyles = `w-${size} h-${size} text-cyb-pink-500 ${isFilled ? 'fill-current' : ''}`;

  const PreviewIcons = ICON_NAMES.map((ICON_NAME) => {
    return (
      <div className="mb-5 w-1/4" key={ICON_NAME}>
        <p className="flex text-lg">
          <Icon name={ICON_NAME} className={iconStyles} />
          <p className="pl-4">{ICON_NAME}</p>
        </p>
      </div>
    );
  });

  const handleChangeSize = (e) => {
    const value = e.target.value * 1;
    // invalid sizes
    if (![9, 11, 13, 17, 19, 21, 22, 23, 24].includes(value)) {
      setSize(value);
    }
  };

  const handleChangeFilled = (e) => {
    setIsFilled(e.target.checked);
  };

  return (
    <Container size="lg" className="flex flex-wrap mt-5 w-full">
      <div className="flex flex-col mb-4 space-y-2 w-full text-lg">
        <div>
          <label htmlFor="size">Size</label>
          <input id="size" type="range" min="1" max="24" value={size} onChange={handleChangeSize} className="mx-1 w-[100px]" />
        </div>
        <div>
          <label htmlFor="filled">Filled</label>
          <input id="filled" type="checkbox" checked={isFilled} onChange={handleChangeFilled} className="mx-1" />
        </div>
        <code className="p-2 text-sm bg-slate-200">{`<Icon name="{iconName}" className="${iconStyles}" />`}</code>
      </div>
      {PreviewIcons}
    </Container>
  );
}

export default IconsPage;
