import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { useAuth } from '../../contexts/UseAuth';
import Container from '../../components/Container/Container';
import ResetPw from '../../components/Login/ResetPw';
import StatusMessage from '../../components/Login/StatusMessage';
import { bootPendo } from '../../utils/pendoUtil';
import CybraryLogo from '../../components/CybraryLogo/CybraryLogo';

const ResetPassword = inject('commonStore')(
  observer(({ commonStore }) => {
    const [submitting, setSubmitting] = useState(false);
    const [status, setStatus] = useState(null);
    const { submitResetPassword, user, signout } = useAuth();
    const location = useLocation() || window.location;
    const navigate = useNavigate();

    useEffect(() => {
      commonStore.setPageTitle('Reset Password | Cybrary');
      // Pendo - Boot anonymously
      bootPendo();
    }, []);

    useEffect(() => {
      // Prevent a user from accessing this page without being signed out
      if (user) {
        // true tells signout to NOT redirect the user
        signout(true);
      }
    }, [user, signout]);

    // Define our logic for submitting email
    const onSubmitResetPassword = async (data) => {
      setSubmitting(true);
      setStatus(null);
      const queryParams = queryString.parse(location.search);
      const postData = {
        newPassword: data.password,
        newPassword_confirmation: data.password_confirm,
        oobCode: queryParams.oobCode,
      };
      try {
        await submitResetPassword(postData);
        setSubmitting(false);
        navigate('/login?action=reset&status=success');
      } catch (e) {
        setSubmitting(false);
        if (e.response && e.response.status === 400 && e.response.data && e.response.data.message === 'Invalid Code') {
          setStatus({ type: 'error', code: 'pwResetInvalidCode' });
        } else {
          setStatus({ type: 'error', code: 'pwResetDefault' });
        }
      }
    };

    // Ensure that we don't show anything while waiting for the user to be signed out
    if (user) {
      return null;
    }

    return (
      <Container className="py-4 mb-8 max-w-screen-xl">
        <div className="mx-auto w-full md:w-120">
          <CybraryLogo />
          {status && <StatusMessage status={status} type={status.type} />}
          <ResetPw onSubmit={onSubmitResetPassword} submitting={submitting} />
        </div>
      </Container>
    );
  })
);

export default ResetPassword;
