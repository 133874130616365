import React from 'react';
import { inject } from 'mobx-react';
import moment from 'moment';
import Icon from '../Icon/Icon';
import Header from '../Header/Header';
import AddLink from '../AddLink/AddLink';
import AdminTable from '../AdminTable/AdminTable';
import Modal from '../Modal/Modal';
import Divider from '../Divider/Divider';
import Segment from '../Segment/Segment';
import DynamicForm from '../DynamicForm/DynamicForm';
import Label from '../Label/Label';

import FormatUtil from '../../utils/formatUtil';
import Agents from '../../agents/agents';

@inject('enterpriseStore', 'commonStore')
class AssignmentDashboardMembers extends React.Component {
  state = {
    assignmentModalView: null,
    userInfo: {},
  };

  // /**
  //  * Sets sort column and direction vars in store for service call query params
  //  * @param heading
  //  */
  tableSort = (heading) => {
    const { queryParams } = this.props;
    if (heading) {
      if (heading === queryParams.sortCol) {
        queryParams.sortDirection = queryParams.sortDirection === 'desc' ? 'asc' : 'desc';
      } else {
        queryParams.sortCol = heading;
        queryParams.sortDirection = 'desc';
      }
      queryParams.activePg = 1;
      this.props.enterpriseStore.setDashboardQueryParams('assignmentDashboardData', 'members', queryParams);

      this.props.getAssignmentTableData(this.props.orgId, this.props.assignmentId);
    }
  };

  // /**
  //  * Sets page number from pagination in store for service call query params
  //  * @param pg
  //  */
  pagChangePg = (pg) => {
    const { queryParams } = this.props;
    queryParams.activePg = pg;
    this.props.enterpriseStore.setDashboardQueryParams('memberDashboardData', 'learning', queryParams);

    this.props.getAssignmentTableData(this.props.orgId, this.props.assignmentId);
  };

  formatTimeRemainingColumn = (data) => {
    if (!(1 * data.value)) {
      return '';
    }
    return FormatUtil.formatTime(data.value, 'hm');
  };

  formatStatusColumn = (data) => {
    const iconClasses = 'w-4 h-4 inline-block mr-1';
    if (data.value === 'Completed') {
      return (
        <Label color="green">
          <Icon name="checkmark-circle" className={iconClasses} /> Completed
        </Label>
      );
    }
    if (data.value === 'Past Due') {
      return (
        <Label color="red">
          <Icon name="exclamation-triangle" className={iconClasses} /> Past Due
        </Label>
      );
    }
    if (data.value === 'Not Started') {
      return (
        <Label basic>
          <Icon name="calendar" className={iconClasses} /> Not Started
        </Label>
      );
    }
    return (
      <Label color="yellow">
        <span className="text=[#141414]">
          <Icon name="circle" className={iconClasses} /> In Progress
        </span>
      </Label>
    );
  };

  linkToMemberDashboard = (data, row) => {
    if (!row[0].value) return data.value;
    return (
      <AddLink
        className="hover:font-semibold text-black hover:text-black underline hover:underline"
        to={`/enterprise/${this.props.orgId}/organization/member/${row[0].value}/dashboard`}
      >
        {data.value}
      </AddLink>
    );
  };

  // /**
  //  * Displays/Hides modal for assignment details/actions
  //  */
  toggleAssignmentModal = (userInfo, view) => {
    const newState = { ...this.state };
    newState.assignmentModalView = view || null;
    newState.userInfo = userInfo || {};
    this.setState(newState);
  };

  // /**
  //  * Toggle modal to show form to set assignment to complete
  //  */
  completeAssignment = (row, headings) => {
    const userInfo = {
      id: row[FormatUtil.getColIndex(headings, 'id')].value,
      name: row[FormatUtil.getColIndex(headings, 'name')].value,
    };
    this.toggleAssignmentModal(userInfo, 'markComplete');
  };

  // /**
  //  * Check if this assignment row is NOT complete
  //  */
  checkShowCompleteAssignment = (row, headings) => {
    if (!this.props.canManageAssignments) {
      return false;
    }
    const status = row[FormatUtil.getColIndex(headings, 'status')].value;
    return status !== 'Completed';
  };

  // /**
  //  * Check if this table row IS complete and there is a note to display
  //  */
  checkShowCompletionDetails = (row, headings) => {
    if (!this.props.canManageAssignments) {
      return false;
    }
    const note = row[FormatUtil.getColIndex(headings, 'note')].value;
    return !this.checkShowCompleteAssignment(row, headings) && !!note;
  };

  // /**
  //  * Toggle modal to show complete assignment details
  //  */
  showCompletionDetails = (row, headings) => {
    const userInfo = {
      note: row[FormatUtil.getColIndex(headings, 'note')].value,
      name: row[FormatUtil.getColIndex(headings, 'name')].value,
    };
    this.toggleAssignmentModal(userInfo, 'details');
  };

  // /**
  //  * Modal content - Based on view state
  //  */
  getModalContent = (view) => {
    if (view === 'details') {
      const note = this.state.userInfo.note ? JSON.parse(this.state.userInfo.note) : null;
      const byUserNameClause = !!note && note.user_name ? ` by ${note.user_name}` : '';
      return (
        <>
          <h2 id="assignment-dashboard-modal" className="p-4 text-2xl font-bold">
            Assignment Details for {this.state.userInfo.name}
          </h2>
          <Divider marginTop="mt-0" marginBottom="mb-0" />
          <div className="px-7 pt-14">
            {!!note && note.timestamp ? (
              <p>
                This assignment was marked complete on {moment.unix(note.timestamp).format('MM/DD/YYYY')}
                {byUserNameClause}.
              </p>
            ) : null}
            {!!note && note.note ? (
              <div>
                <div style={{ fontWeight: 600, display: 'inline-block', marginRight: '1em' }} width={4}>
                  Additional Notes:
                </div>
                <div style={{ display: 'inline-block' }} width={12}>
                  {note.note}
                </div>
              </div>
            ) : null}
          </div>
        </>
      );
    }

    return (
      <>
        <h2 id="complete-assignment-modal" className="p-4 text-2xl font-bold">
          Complete Assignment for {this.state.userInfo.name}
        </h2>
        <Divider marginTop="mt-0" marginBottom="mb-0" />
        <div className="px-7 pt-14">
          <DynamicForm
            form={{
              order: ['note', 'submit'],
              fields: {
                note: {
                  type: 'textarea',
                  label: 'Note',
                  maxLength: 1000,
                },
                submit: {
                  type: 'button',
                  color: 'pink',
                  label: 'Complete Assignment',
                },
              },
            }}
            formName="complete-assessment"
            handleOnChange={this.props.handleOnChange}
            onSubmit={this.completeAssignmentSubmit}
          />
        </div>
      </>
    );
  };

  completeAssignmentSubmit = (data) => {
    this.toggleAssignmentModal();
    const userId = this.state.userInfo.id;
    // If we don't have their userId stored (shouldn't happen), show error
    if (!userId) {
      this.props.commonStore.triggerToast('error', {
        content: 'Something went wrong. Assignment not marked as complete.',
      });
    }
    const putData = {
      status: 'Completed',
    };
    if (!!data && data.note) {
      putData.note = data.note;
    }
    Agents.enterprise
      .putAssignment(this.props.assignmentId, this.props.orgId, userId, putData)
      .then(() => {
        this.props.commonStore.triggerToast('success', {
          content: 'Assignment now marked complete.',
        });
        this.props.getAssignmentTableData(this.props.orgId, this.props.assignmentId);
      })
      .catch(() => {
        this.props.commonStore.triggerToast('error', {
          content: 'Something went wrong. Assignment not marked as complete.',
        });
      });
  };

  render() {
    const modalLabelId = this.state.assignmentModalView === 'details' ? 'assignment-details-modal' : 'complete-assignment-modal';

    return (
      <Segment className="px-0 border-none assignment-dashboard-members-table">
        <Header as="h2">Assigned Members</Header>
        <AdminTable
          headings={this.props.data.columns}
          data={this.props.data.tableData}
          tableLoading={this.props.loading}
          tableError={this.props.error}
          headerClickFunc={this.tableSort}
          sortCol={this.props.queryParams.sortCol}
          sortDirection={this.props.queryParams.sortDirection}
          displayCheckBox={false}
          customColAlign={{
            right: [FormatUtil.getColIndex(this.props.data.columns, 'status')],
          }}
          formatColumns={[
            {
              method: this.linkToMemberDashboard,
              colIdx: FormatUtil.getColIndex(this.props.data.columns, 'name'),
            },
            {
              method: this.props.getProgressLabel,
              colIdx: FormatUtil.getColIndex(this.props.data.columns, 'progress'),
            },
            {
              method: this.formatTimeRemainingColumn,
              colIdx: FormatUtil.getColIndex(this.props.data.columns, 'time_remaining'),
            },
            {
              method: this.formatStatusColumn,
              colIdx: FormatUtil.getColIndex(this.props.data.columns, 'status'),
            },
          ]}
          rowOptions={[
            {
              text: 'Mark as complete',
              icon: 'check',
              action: this.completeAssignment,
              displayCondition: this.checkShowCompleteAssignment,
            },
            {
              text: 'See note',
              icon: 'info circle',
              action: this.showCompletionDetails,
              displayCondition: this.checkShowCompletionDetails,
            },
          ]}
          /* Pagination options */
          pagNumPgs={this.props.data.pagPagesCount}
          pagChangePg={this.pagChangePg}
          pagActivePg={this.props.queryParams.activePg}
        />
        <Modal open={!!this.state.assignmentModalView} toggle={() => this.toggleAssignmentModal()} ariaLabelledBy={modalLabelId}>
          {this.getModalContent(this.state.assignmentModalView)}
        </Modal>
      </Segment>
    );
  }
}

export default AssignmentDashboardMembers;
