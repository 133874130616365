import React from 'react';
import TextArea from '../FormFields/TextArea';

class TextAreaCount extends React.Component {
  state = {
    charsLeft: this.props.maxLength || 500,
  };

  componentDidMount() {
    if (this.props.defaultValue) {
      this.handleChange(undefined, {
        name: this.props.name,
      });
      this.handleCharCount(this.props.defaultValue);
    }
  }

  handleCharCount = (value) => {
    const { maxLength } = this.props;
    const charCount = value && value.length ? value.length : 0;
    const charsLeft = maxLength - charCount;
    this.setState({ charsLeft });
  };

  handleChange = (e, data) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.handleCharCount(data.value);
    this.props.onChange(e, data);
  };

  renderCharactersLeft = () => {
    const { charsLeft } = this.state;

    let content;
    if (charsLeft >= 0) {
      content = <span style={{ color: '#000' }}>{`${charsLeft}/${this.props.maxLength}`}</span>;
    } else if (charsLeft != null && charsLeft < 0) {
      const string = charsLeft.toString().substring(1);
      content = <span style={{ color: 'red' }}>{`too many characters: ${string}`}</span>;
    } else {
      content = null;
    }
    return content;
  };

  render() {
    const { id, label, placeholder, name, ariaDescribedBy, defaultValue, maxLength, required, disabled, style } = this.props;
    return (
      <div>
        <TextArea
          id={id}
          label={label}
          placeholder={placeholder}
          onChange={this.handleChange}
          name={name}
          defaultValue={defaultValue}
          maxLength={maxLength || 300}
          className="mb-1"
          required={required}
          ariaDescribedBy={ariaDescribedBy}
          disabled={disabled}
          style={style}
        />
        <div className="text-right">{this.renderCharactersLeft()}</div>
      </div>
    );
  }
}

export default TextAreaCount;
