import React from 'react';
import ReactMarkdown from 'react-markdown';
import Header from '../Header/Header';
import Card from '../Card/Card';
import FormatUtil from '../../utils/formatUtil';
import Label from '../Label/Label';

export function VendorCard({ href, shortDescription, name, image }) {
  return (
    <Card className="border-none vendor-card">
      <a className="flex " href={href}>
        <div className={`px-4 md:mb-0 ${shortDescription ? 'w-1/2 md:w-1/3' : 'w-1/2 md:w-full'} shrink-0`}>
          {image ? <img src={image} alt={name} className="w-40 rounded-full" /> : <h2>{name}</h2>}
        </div>
        {shortDescription && (
          <div>
            <ReactMarkdown className="has-markdown-inside">{shortDescription}</ReactMarkdown>
          </div>
        )}
      </a>
    </Card>
  );
}
function InstructorCard({ name, thumbnail, title, permalink, instructor_level, short_bio, type, providerContent, providersMeta, inverted, noLabel, noBorder }) {
  const thumbnailQueryStringParams = type === 'Vendor' || type === 'Alliance' ? '?w=120&h=120' : '?w=80&h=80&fit=thumb';
  const image = thumbnail && type !== 'Certification' ? thumbnail + thumbnailQueryStringParams : thumbnail;
  const defaultInstructorImage = `https://images.ctfassets.net/kvf8rpi09wgk/3MtnvKfp1MtTjJegCgRON9/c29887b455fc138acb704172cf5a366f/Cybrary_C_Logo_-_1000ppi.png
    ${thumbnailQueryStringParams}`;
  const instructorImage = image || defaultInstructorImage;
  const circularClass = image ? 'circular' : '';

  const getInstructorColor = (level) => {
    let color = 'blue';
    switch (level) {
      case 'Master Instructor':
        color = 'orange';
        break;
      case 'Senior Instructor':
        color = 'purple';
        break;
      default:
        return color;
    }
    return color;
  };

  let href = '';
  if (permalink && (type === 'Vendor' || type === 'Alliance')) {
    href = `${process.env.REACT_APP_V2_SITE_URL}/catalog/${permalink}`;
  } else if (permalink && type === 'Certification') {
    href = permalink;
  } else if (permalink) {
    href = `${process.env.REACT_APP_V2_SITE_URL}/${permalink}`;
  }
  if (type === 'Vendor' && name === 'Cybrary') {
    // If Cybrary is Vendor, do not display instructor card
    return null;
  }
  if (type === 'Vendor' || type === 'Alliance' || type === 'Certification') {
    return <VendorCard href={href} shortDescription={short_bio} name={name} image={image} />;
  }
  const borderClass = noBorder ? 'border-none' : '';
  return (
    <Card className={`mb-4 instructor-card ${borderClass}`}>
      <a href={href}>
        <div className="flex items-center">
          <div className={`instructor-image-container ${circularClass}`}>
            <img src={instructorImage} alt={name} className="mr-4 rounded-full" />
          </div>
          <div className="instructor-details-container">
            <Header as="h3" className={`mb-2 ${inverted ? 'text-white' : 'text-black'}`}>
              {name}
            </Header>
            {title ? <p className="text-xs text-black">{title}</p> : null}
            {providersMeta && providersMeta.length
              ? providersMeta.map((item) => {
                  return (
                    <div key={item}>
                      {item === 'name' ? (
                        <Header as="h3" className={`mb-2 ${inverted ? 'text-white' : 'text-black'}`}>
                          {providerContent[item]}
                        </Header>
                      ) : (
                        <div>
                          <p className="font-semibold">{FormatUtil.ucwords(item)}</p>
                          <p className="mb-1">{providerContent[item]}</p>
                        </div>
                      )}
                    </div>
                  );
                })
              : null}
            {!noLabel && (
              <div>
                <Label color={getInstructorColor(instructor_level)}>{instructor_level || 'Instructor'}</Label>
              </div>
            )}
          </div>
        </div>
      </a>
    </Card>
  );
}

export default InstructorCard;
