import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Navigate } from 'react-router-dom';
import Header from '../../components/Header/Header';
import Icon from '../../components/Icon/Icon';
import Table from '../../components/Table/Table';
import Loading from '../../components/Loading/Loading';
import AdminTable from '../../components/AdminTable/AdminTable';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import DynamicForm from '../../components/DynamicForm/DynamicForm';
import Segment from '../../components/Segment/Segment';
import AddLink from '../../components/AddLink/AddLink';
import Container from '../../components/Container/Container';
import Label from '../../components/Label/Label';

import FormatUtil from '../../utils/formatUtil';
import './admin.css';
import CybraryAdminLogo from './CybraryAdminLogo';

const CybraryAdminBrowseUser = inject(
  'commonStore',
  'adminStore',
  'authStore'
)(
  observer(
    class CybraryAdminBrowseUser extends Component {
      componentDidMount() {
        // Confirm that we are allowed on the admin page
        this.props.adminStore.checkAccess(this.props.authStore);
        this.props.commonStore.hidePrimaryNav();
      }

      componentWillUnmount() {
        this.props.adminStore.setDefaultUserLookupTableData();
      }

      getBreadCrumbs = () => {
        const crumbs = [];
        crumbs.push({
          href: '/admin',
          label: 'Admin',
        });
        crumbs.push({
          href: '/admin/user',
          label: 'Browse Users',
        });
        return crumbs;
      };

      checkFieldLength = (data) => {
        return data.search && data.search.length > 3 ? null : 'Search must include at least 4 characters';
      };

      searchMemberForm = () => {
        const order = [['search', 'includeUnverifiedUsers'], 'submit'];
        return {
          order,
          fields: {
            search: {
              type: 'text',
              placeholder: 'Search by name, email, username, or ID',
              description: 'Minimum of 4 characters required for search',
              validations: [this.checkFieldLength],
            },
            includeUnverifiedUsers: {
              type: 'boolean',
              label: 'Include unverified users',
              defaultValue: false,
            },
            submit: {
              type: 'button',
              color: 'pink',
              icon: 'search',
              label: 'Search',
            },
          },
        };
      };

      // /**
      //  * Gets a new set of table data sorted by the heading user has clicked
      //  */
      tableSort = (heading) => {
        if (heading) {
          if (heading === this.props.adminStore.userLookupTableData.queryParams.sortCol) {
            this.props.adminStore.userLookupTableData.queryParams.sortDirection = this.props.adminStore.userLookupTableData.queryParams.sortDirection === 'desc' ? 'asc' : 'desc';
          } else {
            this.props.adminStore.userLookupTableData.queryParams.sortCol = heading;
            this.props.adminStore.userLookupTableData.queryParams.sortDirection = 'desc';
          }
          this.props.adminStore.userLookupTableData.queryParams.activePg = 1;
          this.props.adminStore.getUserLookupTableData();
        }
      };

      // /**
      //  * Sets page number from pagination in store for service call query params
      //  */
      pagChangePg = (pg) => {
        this.props.adminStore.userLookupTableData.queryParams.activePg = pg;
        this.props.adminStore.getUserLookupTableData();
      };

      handleSubmit = (data) => {
        if (!!data && !!data.search && data.search.length) {
          this.props.adminStore.userLookupTableData.queryParams.searchQuery = data.search;
          this.props.adminStore.userLookupTableData.queryParams.includeUnverified = data.includeUnverifiedUsers ? 1 : 0;
          this.props.adminStore.getUserLookupTableData();
        }
      };

      formatUserId = (row, headings) => {
        const memberId = row[FormatUtil.getColIndex(headings, 'id')].value;

        let viewLink = `/admin/user/${memberId}/dashboard`;
        const verified = row[FormatUtil.getColIndex(headings, 'verified')].value;
        viewLink = verified ? `/admin/user/${memberId}/dashboard` : `/admin/unverified-user/${memberId}/dashboard`;

        return (
          <Table.Cell>
            <div className="flex gap-x-4">
              <AddLink
                to={viewLink}
                className="block shrink-0 py-2.5 px-6 text-sm font-bold leading-5 text-center text-white hover:text-white bg-cyb-pink-500 hover:bg-pink-600 rounded-sm"
              >
                <Icon name="license" className="inline-block mr-2 mb-1 w-4 h-4" />
                View
              </AddLink>
              <AddLink
                to={`/admin/user/${memberId}/vendor-results`}
                className="block py-2.5 px-6 text-sm font-bold leading-5 text-center text-black hover:text-black bg-gray-200 hover:bg-gray-300 rounded-sm"
              >
                <Icon name="flask" className="inline-block mr-2 mb-1 w-4 h-4" />
                Vendor Results
              </AddLink>
            </div>
          </Table.Cell>
        );
      };

      formatVerified = (data) => {
        const verifiedStatus = data.value;
        return (
          <Label basic color={verifiedStatus ? 'green' : 'red'}>
            {verifiedStatus ? 'Yes' : 'No'}
          </Label>
        );
      };

      render() {
        const { loading, denyAccess } = this.props.adminStore;

        // If we should deny access, just get out now
        if (denyAccess) {
          return <Navigate to="/" />;
        }
        if (loading === null) {
          // On initial load, before user searches, don't want anything to display, including the loading icon
          return null;
        }
        if (loading) {
          return (
            <Container>
              <CybraryAdminLogo />
              <Loading message="Loading..." />
            </Container>
          );
        }
        return (
          <Container className="admin-view-team">
            <CybraryAdminLogo />
            <Segment className="border-none">
              <Breadcrumbs crumbs={this.getBreadCrumbs()} />
              <Header as="h1">Search For Users</Header>
              {this.props.authStore.hasAdminUserLookupAccess() ? (
                <DynamicForm form={this.searchMemberForm()} formName="searchMemberForm" onSubmit={this.handleSubmit} />
              ) : (
                <p className="p-2 mb-1 w-full text-gray-700 bg-white rounded border border-gray-300">You must request permission to use this feature.</p>
              )}
              <AdminTable
                /* Table Data */
                headings={this.props.adminStore.userLookupTableData.tableHeadings}
                data={this.props.adminStore.userLookupTableData.tableData}
                tableLoading={this.props.adminStore.userLookupTableData.loading}
                tableError={this.props.adminStore.userLookupTableData.error}
                displayCheckBox={false}
                headerClickFunc={this.tableSort}
                sortCol={this.props.adminStore.userLookupTableData.queryParams.sortCol}
                sortDirection={this.props.adminStore.userLookupTableData.queryParams.sortDirection}
                addCustomCol={[
                  {
                    method: this.formatUserId,
                    position: 5,
                    header: 'View Profile Details',
                  },
                ]}
                formatColumns={[
                  {
                    method: this.formatVerified,
                    colIdx: FormatUtil.getColIndex(this.props.adminStore.userLookupTableData.tableHeadings, 'verified'),
                  },
                ]}
                className="five column"
                /* Pagination options */
                pagNumPgs={this.props.adminStore.userLookupTableData.pagPagesCount}
                pagChangePg={this.pagChangePg}
                pagActivePg={this.props.adminStore.userLookupTableData.queryParams.activePg}
              />
            </Segment>
          </Container>
        );
      }
    }
  )
);

export default CybraryAdminBrowseUser;
