import React, { useState, useEffect } from 'react';
import useBrowsePaths from '../../hooks/useBrowsePaths';
import PathTable from '../Path/PathTable';

function SideSlideoutPathTable({ technique, userStore, commonStore, enrollmentStore, navigate, getOptions, handleEnroll, useMiniRow }) {
  const { id: techniqueId } = technique || {};
  const filter = techniqueId ? { attack_patterns: techniqueId } : {};
  const { loading, error, paths, fetchPaths } = useBrowsePaths();
  const [pathFilters, setPathFilters] = useState(filter);
  const { attack_patterns } = pathFilters || {};

  useEffect(() => {
    if (techniqueId !== attack_patterns) {
      setPathFilters(filter);
    }
  }, [techniqueId]);

  useEffect(() => {
    fetchPaths('threatActors', pathFilters);
  }, [pathFilters]);

  if (loading || error || !paths || !paths.length) {
    return null;
  }
  return (
    <div className="mt-8">
      <h3 className="text-2xl font-black">Threat Actor Campaigns</h3>
      <PathTable
        paths={paths}
        userStore={userStore}
        commonStore={commonStore}
        enrollmentStore={enrollmentStore}
        navigate={navigate}
        getOptions={getOptions}
        handleEnroll={handleEnroll}
        useMiniRow={useMiniRow}
      />
    </div>
  );
}

export default SideSlideoutPathTable;
