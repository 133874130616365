import { useState, useEffect } from 'react';

const useNetwork = () => {
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);

  const updateNetworkStatus = () => {
    setIsOnline(window.navigator.onLine);
  };

  useEffect(() => {
    window.addEventListener('offline', updateNetworkStatus);
    window.addEventListener('online', updateNetworkStatus);
    return () => {
      window.removeEventListener('offline', updateNetworkStatus);
      window.removeEventListener('online', updateNetworkStatus);
    };
  });

  return {
    isOnline,
  };
};

export default useNetwork;
