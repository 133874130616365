import { uniq } from 'lodash';

const facets = ['categories_info', 'content_group', 'level.name', 'groups_info', 'terms_info', 'vendor.name'];
const searchFacets = ['content_group', 'content_filters', 'domains'];
const advancedFacets = ['level.name', 'topics', 'nice_category', 'nice_work_role', /** 'work_role', */ 'terms_info', 'groups_info', 'vendor.name'];
const filterOptions = {
  type: [
    {
      text: 'All',
      value: '',
    },
    {
      text: 'Courses',
      value: 'Courses',
    },
    {
      text: 'Career Paths',
      value: 'Career Paths',
    },
    {
      text: 'Assessment Paths',
      value: 'Assessment Paths',
    },
    {
      text: 'Skill Paths',
      value: 'Skill Path',
    },
    {
      text: 'Custom Paths',
      value: 'Custom Paths',
    },
    {
      text: 'Collections',
      value: 'Collections',
    },
    {
      text: 'Hands on Labs',
      value: 'Hands on Labs',
    },
    {
      text: 'Practice Tests',
      value: 'Practice Tests',
    },
    {
      text: 'Challenges',
      value: 'Challenges',
    },
    {
      text: 'Assessments',
      value: 'Assessments',
    },
    {
      text: 'Certification Prep',
      value: 'Certification Prep',
    },
    {
      text: 'Threat Actor Campaign',
      value: 'Threat Actor Campaign',
    },
  ],
  level: [
    {
      text: 'All',
      value: '',
    },
    {
      text: 'Beginner',
      value: 'Beginner',
    },
    {
      text: 'Intermediate',
      value: 'Intermediate',
    },
    {
      text: 'Advanced',
      value: 'Advanced',
    },
  ],
  vendor: [
    {
      text: 'Cybrary',
      value: 'Cybrary',
    },
    {
      text: 'CybrScore',
      value: 'CybrScore',
    },
    {
      text: 'Cydefe',
      value: 'Cydefe',
    },
    {
      text: 'iMocha',
      value: 'iMocha',
    },
    {
      text: 'Kaplan',
      value: 'Kaplan',
    },
    {
      text: 'CyberVista',
      value: 'CyberVista',
    },
    {
      text: 'Practice Labs',
      value: 'Practice Labs',
    },
    {
      text: 'Next Tech',
      value: 'next tech',
    },
    {
      text: 'Rangeforce Lab',
      value: 'Rangeforce',
    },
    {
      text: 'Skillable',
      value: 'Skillable',
    },
    {
      text: 'Infosec Learning',
      value: 'Infosec Learning',
    },
  ],
  sort: [
    {
      text: 'Popular',
      value: '',
    },
    {
      text: 'Relevance',
      value: 'Relevance',
    },
    {
      text: 'Newest',
      value: 'Newest',
    },
  ],
  status: [
    {
      text: 'All',
      value: '',
    },
    {
      text: 'Active',
      value: 'Active',
    },
    {
      text: 'Coming Soon',
      value: 'Coming Soon',
    },
  ],
  terms_info: [
    {
      text: 'All-Source Analyst',
      value: 'Workrole|All-Source-Analyst',
    },
    {
      text: 'All-Source Collection Manager',
      value: 'Workrole|All-Source-Collection-Manager',
    },
    {
      text: 'All-Source Collection Requirements Manager',
      value: 'Workrole|All-Source-Collection-Requirements-Manager',
    },
    {
      text: 'Authorizing Official/Designating Representative',
      value: 'Workrole|Authorizing-Official/Designating-Representative',
    },
    {
      text: 'Communications Security (COMSEC) Manager',
      value: 'Workrole|Communications-Security-(COMSEC)-Manager',
    },
    {
      text: 'Cyber Crime Investigator',
      value: 'Workrole|Cyber-Crime-Investigator',
    },
    {
      text: 'Cyber Defense Analyst',
      value: 'Workrole|Cyber-Defense-Analyst',
    },
    {
      text: 'Cyber Defense Forensics Analyst',
      value: 'Workrole|Cyber-Defense-Forensics-Analyst',
    },
    {
      text: 'Cyber Defense Incident Responder',
      value: 'Workrole|Cyber-Defense-Incident-Responder',
    },
    {
      text: 'Cyber Defense Infrastructure Support Specialist',
      value: 'Workrole|Cyber-Defense-Infrastructure-Support-Specialist',
    },
    {
      text: 'Cyber Instructional Curriculum Developer',
      value: 'Workrole|Cyber-Instructional-Curriculum-Developer',
    },
    {
      text: 'Cyber Instructor',
      value: 'Workrole|Cyber-Instructor',
    },
    {
      text: 'Cyber Intel Planner',
      value: 'Workrole|Cyber-Intel-Planner',
    },
    {
      text: 'Cyber Legal Advisor',
      value: 'Workrole|Cyber-Legal-Advisor',
    },
    {
      text: 'Cyber Operator',
      value: 'Workrole|Cyber-Operator',
    },
    {
      text: 'Cyber Ops Planner',
      value: 'Workrole|Cyber-Ops-Planner',
    },
    {
      text: 'Cyber Policy and Strategy Manager',
      value: 'Workrole|Cyber-Policy-and-Strategy-Manager',
    },
    {
      text: 'Cyber Workforce Developer and Manager',
      value: 'Workrole|Cyber-Workforce-Developer-and-Manager',
    },
    {
      text: 'Data Analyst',
      value: 'Workrole|Data-Analyst',
    },
    {
      text: 'Database Administrator',
      value: 'Workrole|Database-Administrator',
    },
    {
      text: 'Enterprise Architect',
      value: 'Workrole|Enterprise-Architect',
    },
    {
      text: 'Executive Cyber Leadership',
      value: 'Workrole|Executive-Cyber-Leadership',
    },
    {
      text: 'Exploitation Analyst',
      value: 'Workrole|Exploitation-Analyst',
    },
    {
      text: 'IT Investment/Portfolio Manager',
      value: 'Workrole|IT-Investment/Portfolio-Manager',
    },
    {
      text: 'IT Program Auditor',
      value: 'Workrole|IT-Program-Auditor',
    },
    {
      text: 'IT Project Manager',
      value: 'Workrole|IT-Project-Manager',
    },
    {
      text: 'Information Systems Security Developer',
      value: 'Workrole|Information-Systems-Security-Developer',
    },
    {
      text: 'Information Systems Security Manager',
      value: 'Workrole|Information-Systems-Security-Manager',
    },
    {
      text: 'Knowledge Manager',
      value: 'Workrole|Knowledge-Manager',
    },
    {
      text: 'Law Enforcement/Counterintelligence Forensics Analyst',
      value: 'Workrole|Law-Enforcement/Counterintelligence-Forensics-Analyst',
    },
    {
      text: 'Mission Assessment Specialist',
      value: 'Workrole|Mission-Assessment-Specialist',
    },
    {
      text: 'Multi-Disciplined Language Analyst',
      value: 'Workrole|Multi-Disciplined-Language-Analyst',
    },
    {
      text: 'Network Operations Specialist',
      value: 'Workrole|Network-Operations-Specialist',
    },
    {
      text: 'Partner Integration Planner',
      value: 'Workrole|Partner-Integration-Planner',
    },
    {
      text: 'Privacy Officer/Privacy Compliance Manager',
      value: 'Workrole|Privacy-Officer/Privacy-Compliance-Manager',
    },
    {
      text: 'Product Support Manager',
      value: 'Workrole|Product-Support-Manager',
    },
    {
      text: 'Program Manager',
      value: 'Workrole|Program-Manager',
    },
    {
      text: 'Research & Development Specialist',
      value: 'Workrole|Research-&-Development-Specialist',
    },
    {
      text: 'Secure Software Assessor',
      value: 'Workrole|Secure-Software-Assessor',
    },
    {
      text: 'Security Architect',
      value: 'Workrole|Security-Architect',
    },
    {
      text: 'Security Control Assessor',
      value: 'Workrole|Security-Control-Assessor',
    },
    {
      text: 'Software Developer',
      value: 'Workrole|Software-Developer',
    },
    {
      text: 'System Administrator',
      value: 'Workrole|System-Administrator',
    },
    {
      text: 'System Testing and Evaluation Specialist',
      value: 'Workrole|System-Testing-and-Evaluation-Specialist',
    },
    {
      text: 'Systems Developer',
      value: 'Workrole|Systems-Developer',
    },
    {
      text: 'Systems Requirements Planner',
      value: 'Workrole|Systems-Requirements-Planner',
    },
    {
      text: 'Systems Security Analyst',
      value: 'Workrole|Systems-Security-Analyst',
    },
    {
      text: 'Target Developer',
      value: 'Workrole|Target-Developer',
    },
    {
      text: 'Target Network Analyst',
      value: 'Workrole|Target-Network-Analyst',
    },
    {
      text: 'Technical Support Specialist',
      value: 'Workrole|Technical-Support-Specialist',
    },
    {
      text: 'Vulnerability Assessment Analyst',
      value: 'Workrole|Vulnerability-Assessment-Analyst',
    },
    {
      text: 'Threat/Warning Analyst',
      value: 'Workrole|Threat/warning-Analyst',
    },
  ],
  groups_info: [
    {
      text: 'All Certifications',
      value: 'certifications|all',
    },
    {
      text: 'Microsoft',
      value: 'certifications|microsoft',
    },
    {
      text: 'Cybersecurity Skills',
      value: 'skillset|cybersecurity-skills',
    },
    {
      text: 'CompTIA',
      value: 'certifications|comptia',
    },
    {
      text: 'Oracle',
      value: 'certifications|oracle',
    },
    {
      text: 'It Skills',
      value: 'skillset|it-skills',
    },
    {
      text: 'Cisco',
      value: 'certifications|cisco',
    },
    {
      text: 'Microsoft Azure',
      value: 'certifications|microsoft-azure',
    },
    {
      text: 'Tools/Technologies',
      value: 'skillset|tools/technologies',
    },
    {
      text: 'Programming/Scripting-languages',
      value: 'skillset|programming/scripting-languages',
    },
    {
      text: 'ISC2',
      value: 'certifications|isc2',
    },
    {
      text: 'VM Ware',
      value: 'certifications|vmware',
    },
    {
      text: 'AWS',
      value: 'certifications|aws',
    },
    {
      text: 'ISACA',
      value: 'certifications|isaca',
    },
    {
      text: 'PMI',
      value: 'certifications|pmi',
    },
    {
      text: 'ITIL',
      value: 'certifications|itil',
    },
    {
      text: 'CIW',
      value: 'certifications|ciw',
    },
    {
      text: 'EC-Council',
      value: 'certifications|ec-council',
    },
    {
      text: 'BCS',
      value: 'certifications|bcs',
    },
    {
      text: 'CertNexus',
      value: 'certifications|certnexus',
    },
    {
      text: 'Citrix',
      value: 'certifications|citrix',
    },
    {
      text: 'CWNA',
      value: 'certifications|cwna',
    },
    {
      text: 'CWNP',
      value: 'certifications|cwnp',
    },
    {
      text: 'CyberSec',
      value: 'certifications|cybersec',
    },
    {
      text: 'GIAC',
      value: 'certifications|giac',
    },
    {
      text: 'IC3',
      value: 'certifications|ic3',
    },
    {
      text: 'ISTQB',
      value: 'certifications|istqb',
    },
    {
      text: 'LPI',
      value: 'certifications|lpi',
    },
    {
      text: 'PRINCE 2',
      value: 'certifications|prince2',
    },
    {
      text: 'Data Science Skills',
      value: 'skillset|data-science-skills',
    },
    {
      text: 'CSA',
      value: 'certifications|csa',
    },
    {
      text: 'IAPP',
      value: 'certifications|iapp',
    },
    {
      text: 'MITRE Engenuity',
      value: 'certifications|mitre-engenuity',
    },
    {
      text: 'CNCF',
      value: 'certifications|cncf',
    },
    {
      text: 'CompTIA',
      value: 'certifications|comptia',
    },
  ],
};

export default class FilterUtil {
  static getFacets = () => {
    return facets;
  };

  static getSearchFacets = () => {
    return searchFacets;
  };

  static getAdvancedFacets = () => {
    return advancedFacets;
  };

  static getFilterOptions = () => {
    return filterOptions;
  };

  static filteredFacetData = (data, filter) => {
    return Object.keys(data)
      .filter((key) => key.includes(filter))
      .reduce(
        (obj, key) => {
          const newObj = obj;
          newObj.data[key] = data[key];
          return newObj;
        },
        { name: 'terms_info', exhaustive: true, data: {} }
      );
  };

  static getFacetData = (dataFacets, facetCategory) => {
    let facet = null;
    dataFacets.forEach((val) => {
      if (val.name === facetCategory) {
        facet = val;
      }
      if (val.name === 'terms_info') {
        switch (facetCategory) {
          case 'domains':
            facet = this.filteredFacetData(val.data, 'domains|');
            break;
          case 'topics':
            facet = this.filteredFacetData(val.data, 'topics|');
            break;
          case 'work_role':
            facet = this.filteredFacetData(val.data, 'workrole|');
            break;
          case 'nice_work_role':
            facet = this.filteredFacetData(val.data, 'nice-work-role|');
            break;
          case 'nice_category':
            facet = this.filteredFacetData(val.data, 'nice-category|');
            break;
          default:
            break;
        }
      }
    });
    return facet;
  };

  static getFilterHeader = (facet) => {
    const facetMap = {
      'level.name': 'Difficulty',
      'vendor.name': 'Vendor',
      content_group: 'Activity Type',
      groups_info: 'Certification Bodies',
      domains: 'Domains',
      topics: 'Topics',
      work_role: 'NIST Work Roles',
      nice_work_role: 'NICE Work Roles',
      nice_category: 'NICE Categories',
    };
    return facetMap[facet] || facet;
  };

  static getSelectedFilters = (selectedFilters, facetCategory) => {
    const filters = [];
    const termsInfoSubcategories = ['domains', 'topics', 'work_role', 'nice_work_role', 'nice_category'];
    if (termsInfoSubcategories.includes(facetCategory)) {
      const termsSelected = selectedFilters.terms_info;
      if (termsSelected) {
        termsSelected.forEach((item) => {
          if (
            (/domains/g.test(item.toLowerCase()) && facetCategory === 'domains') ||
            (/topics/g.test(item.toLowerCase()) && facetCategory === 'topics') ||
            (/workrole/g.test(item.toLowerCase()) && facetCategory === 'work_role') ||
            (/nice-work-role/g.test(item.toLowerCase()) && facetCategory === 'nice_work_role') ||
            (/nice-category/g.test(item.toLowerCase()) && facetCategory === 'nice_category')
          ) {
            filters.push(item);
          }
        });
      }
    } else if (selectedFilters && selectedFilters[facetCategory]) {
      filters.push(selectedFilters[facetCategory]);
    }
    return filters;
  };

  static getFilters = (facet, certCount) => {
    const copyOfFacet = { ...facet };
    let filters = [];
    if (copyOfFacet && copyOfFacet.data) {
      if (copyOfFacet.name === 'groups_info' && certCount) {
        copyOfFacet.data['certifications|all'] = certCount;
      }

      filters = Object.keys(copyOfFacet.data);

      if (copyOfFacet.name === 'content_group') {
        filters = ['Career Path', 'Custom Paths', 'Collections', 'Virtual Lab', 'Course', 'Assessment', 'Practice Test', 'Assessment Paths', 'Skill Paths'];
      } else if (copyOfFacet.name === 'level.name') {
        filters.splice(0, 3, 'Beginner', 'Intermediate', 'Advanced');
      }

      if (copyOfFacet.name === 'groups_info' || copyOfFacet.name === 'vendor.name' || copyOfFacet.name === 'terms_info') {
        filters = filters.sort();
      }
    }

    return uniq(filters);
  };

  // Replace old facet names with new facet names in filterOptions array
  static transformFacetFilterName = (oldName) => {
    let name = oldName;
    const terms = filterOptions.terms_info;
    const groupInfo = filterOptions.groups_info;
    for (let i = 0; i < terms.length; i++) {
      if (name.toLowerCase() === terms[i].value.toLowerCase()) {
        name = terms[i].text;
      }
    }
    for (let i = 0; i < groupInfo.length; i++) {
      if (name.toLowerCase() === groupInfo[i].value.toLowerCase()) {
        name = groupInfo[i].text;
      }
    }

    // Replace old facet names with new facet names in filterOptions array
    if (name.split('|')[1] !== undefined) {
      const capitalArray = [];
      const splitName = name.split('|')[1].replace(/-/g, ' ');
      const result = splitName.split(' ');
      for (let i = 0; i < result.length; i++) {
        let capitalWord = result[i].replace(result[i].charAt(0), result[i].charAt(0).toUpperCase());
        if (capitalWord === 'And') {
          capitalWord = result[i].replace('And', 'and');
        }
        capitalArray.push(capitalWord);
      }
      name = capitalArray.join(' ');
    }
    return name;
  };

  static deleteFacets = (facet, categoryArray) => {
    const copyOfFacet = { ...facet };
    Object.keys(copyOfFacet.data).forEach((item) => {
      if (categoryArray.includes(item.toLowerCase())) {
        delete copyOfFacet.data[item];
      }
    });
    return copyOfFacet;
  };

  static formattedSearchItemFacets = (allFacets) => {
    if (!allFacets || (!!allFacets && facets.length <= 0)) {
      return [];
    }
    return allFacets.map((facet) => {
      const newFacet = { ...facet };
      let deleteCategories = null;

      // Delete unwanted vendor filters
      if (newFacet.name === 'vendor.name') {
        deleteCategories = ['Palo Alto Networks'];
        this.deleteFacets(newFacet, deleteCategories);
      }

      // Delete everything that doesn't start with certifications
      if (newFacet.name === 'groups_info') {
        Object.keys(newFacet.data).forEach((item) => {
          if (!/certifications/g.test(item.toLowerCase())) {
            delete newFacet.data[item];
          }
        });
      }
      return newFacet;
    });
  };

  /*
    this controls the view= query param for Content groups 
    in the "Activity Type" explore menu... 
    Explore Menu --> Activity Typ --> Career Path will push this url /browse/refined?resultsView=grid&view=careerPath
    based on the value returned from the map below
  */
  static getOriginalContentTypeName = (name) => {
    const originalContentTypeMap = {
      'Career Path': 'careerPath',
      'Assessment Path': 'assessmentPath',
      'Skill Path': 'skillPath',
      Collections: 'collections',
      'Custom Paths': 'curriculum',
      Course: 'course',
      'Virtual Lab': 'lab',
      'Practice Test': 'practiceTest',
      Challenges: 'challenges',
      Assessment: 'assessment',
      Certification: 'certification',
      'Threat Actor Campaign': 'threatActorCampaign',
      'Certification Prep': 'certificationPrep',
    };
    return originalContentTypeMap[name] || name;
  };

  // this is where browse refined filters name, and count are controlled
  static getViewOptions(data) {
    return [
      {
        text: 'All',
        count: data?.all?.nbHits || 0,
        value: 'all',
      },
      {
        text: 'Career Paths',
        count: data?.careerPath?.nbHits || 0,
        value: 'careerPath',
      },
      {
        text: 'Certification Prep',
        count: data?.certificationPrep?.nbHits || 0,
        value: 'certificationPrep',
      },
      {
        text: 'Threat Actor Campaigns',
        count: data?.threatActorCampaign?.nbHits || 0,
        value: 'threatActorCampaign',
      },
      {
        text: 'Collections',
        count: data?.collections?.nbHits || 0,
        value: 'collections',
      },
      {
        text: 'Courses',
        count: data?.course?.nbHits || 0,
        value: 'course',
      },
      {
        text: 'Virtual Labs',
        count: data?.lab?.nbHits || 0,
        value: 'lab',
      },
      {
        text: 'Practice Tests',
        count: data?.practiceTest?.nbHits || 0,
        value: 'practiceTest',
      },
      {
        text: 'Challenges',
        count: data?.challenges?.nbHits || 0,
        value: 'challenges',
      },
      {
        text: 'Assessments',
        count: data?.assessment?.nbHits || 0,
        value: 'assessment',
      },
      {
        text: 'Assessment Paths',
        count: data?.assessmentPath?.nbHits || 0,
        value: 'assessmentPath',
      },
      {
        text: 'Skill Paths',
        count: data?.skillPath?.nbHits || 0,
        value: 'skillPath',
      },
      {
        text: 'Custom Paths',
        count: data?.curriculum?.nbHits || 0,
        value: 'curriculum',
      },
    ];
  }

  static getContentOptions() {
    return [
      {
        text: 'All',
        facet: '',
        filter: '',
        value: 'all',
      },
      {
        text: 'New Releases',
        facet: 'new',
        filter: true,
        value: 'new',
      },
      {
        text: 'Coming Soon',
        facet: 'status',
        filter: 'Coming Soon',
        value: 'coming',
      },
      {
        text: 'Free',
        facet: 'license',
        filter: '1',
        value: 'license',
      },
    ];
  }
}
