import React from 'react';
import { twMerge } from 'tailwind-merge';
import CircularProgress from '../ProgressBar/CircularProgress';
import PracticeTestDisplayLabel from './PracticeTestDisplayLabel';
import OptionsMenu from '../OptionsMenu/OptionsMenu';
import FormatUtil from '../../utils/formatUtil';
import TeamsIcon from '../../Icons/TeamsIcon';
import Card from '../Card/Card';
import If from '../If/If';

function OptionsMenuWrapper({ menuOptions, menuClasses, buttonClassName }) {
  const buttonClasses = twMerge('ml-4 md:ml-8', buttonClassName);
  return menuOptions && menuOptions.length ? (
    <div className={buttonClasses}>
      <OptionsMenu options={menuOptions} menuClasses={twMerge('right-4', menuClasses)} />
    </div>
  ) : (
    <div className="hidden ml-4 md:block md:ml-8 md:w-10" />
  );
}

function TeamLogo({ team }) {
  if (!team) {
    return null;
  }
  const accessibilityText = `Goal set by ${team.name}`;
  const ariaLabel = { 'aria-label': accessibilityText };
  if (!team.logo_url) {
    return <TeamsIcon filled inverted classes="w-8 h-8 p-1 bg-black rounded mr-4" accessibilityProps={ariaLabel} />;
  }

  return <img className="mr-4 w-8 rounded border border-gray-300" src={team.logo_url} alt={accessibilityText} />;
}

function GoalTile({ goal, view, menuOptions, viewDetails, teamData, preventAnimation, menuClasses, buttonClassName }) {
  if (!goal) {
    return null;
  }
  const goalHasPracticeTest = goal.progress && 'score' in goal.progress;
  const showCircularProgress = view === 'in_progress';
  const progressPercentage = goal.percent_completed ? Math.floor(goal.percent_completed) : 0;
  return (
    <Card stackDisplay className="static mx-2">
      <div className="flex items-center">
        <button className="flex flex-1 items-center text-left" onClick={viewDetails}>
          <div className="shrink-0">
            <TeamLogo team={teamData} />
          </div>
          <If condition={showCircularProgress}>
            <div>
              <CircularProgress classes="w-10 mr-4" strokeWidth={20} percentageValue={progressPercentage} preventAnimation={preventAnimation}>
                {goalHasPracticeTest && <PracticeTestDisplayLabel passed={1 * goal.percent_completed === 100} omitText statusIconSize={6} />}
              </CircularProgress>
            </div>
          </If>
          <h3 className="text-xs font-bold sm:text-sm">{FormatUtil.formatLongText(goal.name, 38)}</h3>
        </button>
        <If condition={menuOptions?.length}>
          <div className="shrink">
            <OptionsMenuWrapper menuOptions={menuOptions} menuClasses={menuClasses} buttonClassName={buttonClassName} />
          </div>
        </If>
      </div>
    </Card>
  );
}

export default GoalTile;
