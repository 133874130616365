import React from 'react';
import CountUp from 'react-countup';
import If from '../If/If';

function ProgressBarDisplayLabel({
  isAggregateView,
  goalProgressPercentage,
  goalProgressDisplay,
  goalProgressSuffix,
  percentageOnly,
  wrapperClasses,
  progressClasses,
  percentageClasses,
}) {
  return (
    <div className={`inline-block ${wrapperClasses || ''}`}>
      <span className={progressClasses}>
        {isAggregateView ? 'Avg: ' : ''}
        <If condition={!percentageOnly}>
          <>
            <CountUp end={goalProgressDisplay} preserveValue duration={2} />
            {goalProgressSuffix}
          </>
        </If>
      </span>
      <If condition={percentageOnly}>
        <span className={percentageClasses}>
          <CountUp end={1 * goalProgressPercentage} preserveValue suffix="%" duration={2} />
        </span>
      </If>
      <If condition={!percentageOnly && !!goalProgressPercentage}>
        <span className={percentageClasses}>
          (<CountUp end={1 * goalProgressPercentage} preserveValue suffix="%" duration={2} />)
        </span>
      </If>
    </div>
  );
}

export default ProgressBarDisplayLabel;
