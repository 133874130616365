import React, { useMemo } from 'react';
import WidgetContainer from '../Container/WidgetContainer';
import Card from '../Card/Card';
import Header from '../Header/Header';
import If from '../If/If';
import LoadingCard from './LoadingCard';
import Icon from '../Icon/Icon';
import Button from '../Button/Button';
import LockedContainer from '../Container/LockedContainer';
import { useABM } from '../../providers/ABMProvider';
import AddLink from '../AddLink/AddLink';
import PermalinkUtil from '../../utils/permalinkUtil';

/**
 * Legend Card for Trending Content Widget
 * @returns {JSX} - The Legend Card
 */
function LegendCard() {
  return (
    <Card className="py-0 mb-3 bg-transparent border-0" inlineContent stackDisplay>
      <div className="flex flex-row">
        <Icon name="trending-up" className="shrink-0 w-7 h-7 fill-green-600" />
        <span className="ml-2 text-sm">Trending up</span>
      </div>
      <div className="flex flex-row">
        <Icon name="trending-down" className="shrink-0 w-7 h-7 fill-red-500" />
        <span className="ml-2 text-sm">Trending down</span>
      </div>
    </Card>
  );
}

/**
 * Trending Content Widget for ABM Page
 * @param {string} dataSetKey - key for designated data set
 * @returns {JSX} - The Trending Content Widget
 */
function TrendingContentWidget({ dataSetKey }) {
  const { isABMTeamLoading, isVerified, abmTeam } = useABM();

  const dataSet = useMemo(() => abmTeam?.dashboardData?.[dataSetKey], [abmTeam?.dashboardData, dataSetKey]);
  const title = useMemo(() => {
    switch (dataSetKey) {
      case 'trending_courses':
        return 'Trending Courses (last 90d)';
      case 'trending_staying_ahead':
        return 'Staying Ahead of Threats';
      default:
        return 'Example Title';
    }
  }, [dataSetKey]);
  return (
    <LockedContainer msg="Verify your company email to unlock" isLocked={!isVerified && !isABMTeamLoading} isFullWidth>
      <WidgetContainer>
        <Header as="h2" className="mb-4">
          {title}
        </Header>
        <LegendCard />
        {/* Loading State */}
        <If condition={isABMTeamLoading}>
          <LoadingCard />
          <LoadingCard />
          <LoadingCard />
          <LoadingCard />
          <LoadingCard />
        </If>
        {/* Empty State */}
        <If condition={!dataSet?.length && !isABMTeamLoading}>
          <Card>
            <p>No trending courses yet</p>
          </Card>
        </If>
        {/* Default State */}
        <If condition={dataSet?.length && !isABMTeamLoading}>
          {dataSet?.map(
            ({ content_description_id, content_description_title, content_description_permalink, content_description_type, new_enrollments_current, new_enrollments_previous }) => {
              const trend = new_enrollments_current - new_enrollments_previous;
              return (
                <AddLink to={PermalinkUtil.formatInApp(content_description_permalink, content_description_type)} key={content_description_id}>
                  <Card inlineContent inlineDisplay key={content_description_id} stackDisplay>
                    <Card.Section className="mr-2 w-8 ">
                      <If condition={!trend || trend > 0}>
                        <Icon name="trending-up" className="shrink-0 w-7 h-7 fill-green-600" />
                      </If>
                      <If condition={trend < 0}>
                        <Icon name="trending-down" className="shrink-0 w-7 h-7 fill-red-500" />
                      </If>
                    </Card.Section>
                    <Header as="h4" className="grow mb-0">
                      {content_description_title}
                    </Header>
                    <Button color="gray" icon={{ name: 'chevron-right', className: 'w-4 h-4' }} className="px-3" />
                  </Card>
                </AddLink>
              );
            }
          )}
        </If>
        {/* Error State? */}
      </WidgetContainer>
    </LockedContainer>
  );
}

export default TrendingContentWidget;
