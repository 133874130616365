import React from 'react';
import SideContentSlideout from '../SideContentSlideout/SideContentSlideout';
import Icon from '../Icon/Icon';

export default function MitreSlideoutHeader({ closeSlideout, subTechniqueSideSectionData, techniqueSideSectionData, setSubTechniqueSideSectionData, setTechniqueSideSectionData }) {
  return (
    <>
      <div className="hidden lg:flex">
        <SideContentSlideout.CloseButton onClick={closeSlideout} />
        {subTechniqueSideSectionData && techniqueSideSectionData && (
          <button className="flex items-center ml-4 text-sm hover:text-cyb-pink-500 underline" onClick={() => setSubTechniqueSideSectionData(null)}>
            <Icon name="chevron-left" className="mr-2 w-4 h-4" />
            Back to {techniqueSideSectionData.name}
          </button>
        )}
      </div>
      <div className="flex py-2 px-4 mx-auto max-w-132 lg:hidden">
        <button
          className="flex items-center text-sm hover:text-cyb-pink-500 underline"
          onClick={() => {
            setSubTechniqueSideSectionData(null);
            setTechniqueSideSectionData(null);
          }}
        >
          <Icon name="chevron-left" className="mr-2 w-4 h-4" />
          {subTechniqueSideSectionData ? '' : 'Back to '}Tactics
        </button>
        {subTechniqueSideSectionData && (
          <button
            className="flex items-center ml-4 text-sm hover:text-cyb-pink-500 underline"
            onClick={() => {
              setSubTechniqueSideSectionData(null);
            }}
          >
            <Icon name="chevron-left" className="mr-2 w-4 h-4" />
            Techniques
          </button>
        )}
      </div>
    </>
  );
}
