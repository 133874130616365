import React from 'react';
import AddLink from '../AddLink/AddLink';
import ChevronRight from '../../Icons/ChevronRight';
import BetaLabel from '../Label/BetaLabel';

function MinimalistConversationLinks({ data }) {
  if (!data || !data.length) {
    return null;
  }

  return data.map((item, idx) => {
    const { heading, description, href, action, disabled } = item;
    return (
      <div key={heading || href} className={`${idx > 0 ? 'border-t-xs py-12' : 'pb-12'} border-gray-400`}>
        <AddLink
          className={`flex w-full justify-between items-center hover:text-cyb-pink-500 ${disabled ? 'cursor-not-allowed' : ''}`}
          key={heading}
          onClick={() => action && action()}
          to={disabled ? null : href}
          disabled={disabled}
        >
          <div>
            <div className="flex gap-x-4 items-center">
              <h3 className="text-lg font-bold">{heading}</h3>
              {item.beta && <BetaLabel />}
            </div>
            {description && <p className="text-sm text-gray-600">{description}</p>}
          </div>
          <ChevronRight classes="text-gray-600 w-4 h-4 shrink-0" />
        </AddLink>
      </div>
    );
  });
}

export default MinimalistConversationLinks;
