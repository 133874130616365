import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import FocusLock from 'react-focus-lock';

import Container from '../Container/Container';
import Icon from '../Icon/Icon';
import DynamicForm from '../DynamicForm/DynamicForm';
import Loading from '../Loading/Loading';
import StyledError from '../Error/StyledError';
import X from '../../Icons/X';
import LabBasedReviewForm from '../../forms/reviews/LabBasedReviewForm';
import AssessmentBasedReviewForm from '../../forms/reviews/AssessmentBasedReviewForm';
import AudioVideoBasedReviewForm from '../../forms/reviews/AudioVideoBasedReviewForm';
import CourseBasedReviewForm from '../../forms/reviews/CourseBasedReviewForm';
import CollectionBasedReviewForm from '../../forms/reviews/CollectionBasedReviewForm';
import FormatUtil from '../../utils/formatUtil';

// Screen-sized close button
function ScreenClose({ isOpen, handleClose }) {
  return !isOpen ? null : (
    <button onClick={handleClose} className="fixed inset-0 z-10 w-full h-full cursor-default">
      &nbsp;
    </button>
  );
}

/**
 * Thank you message component
 */
function ThankYouMessage() {
  return (
    <div className="p-8 text-center">
      <Icon name="thumbs-up" className="mx-auto w-12 h-12 text-green-500" />
      <p className="text-xl font-bold">Thank you! We appreciate your feedback.</p>
    </div>
  );
}

function Form({ feedbackLoading, feedbackError, handleClose, form, isSidebar }) {
  if (feedbackLoading) {
    return (
      <Container>
        <Loading message="Loading..." />
      </Container>
    );
  }
  if (feedbackError) {
    return (
      <Container>
        <StyledError error={feedbackError} />
      </Container>
    );
  }
  return (
    <>
      {!isSidebar && (
        <button className="group float-right absolute top-4 right-4 z-10 cursor-pointer" onClick={handleClose} aria-label="Close Feedback Pane">
          <X classes="w-4 h-4 group-hover:text-cyb-pink-500 " />
        </button>
      )}
      <div className="-z-10 p-4">{form}</div>
    </>
  );
}

/**
 * Reviews dropdown
 */
const FeedbackPane = inject('feedbackStore')(
  observer(
    class FeedbackPane extends Component {
      state = {
        contentDescriptionId: null,
      };

      componentDidMount() {
        this.checkGetFeedback();
        window.addEventListener('keydown', this.close);
      }

      componentDidUpdate() {
        this.checkGetFeedback();
      }

      componentWillUnmount() {
        window.removeEventListener('keydown', this.close);
      }

      // allow feedback pane to be closed on press of esc key (accessibility reasons)
      close = (e) => {
        if (e && e.key === 'Escape' && this.props.isOpen) {
          this.props.toggleFeedbackDisplay();
        }
      };

      checkGetFeedback = () => {
        if (this.props.feedbackStore.contentDescriptionId && this.state.contentDescriptionId !== this.props.feedbackStore.contentDescriptionId) {
          const newState = {
            contentDescriptionId: this.props.feedbackStore.contentDescriptionId,
          };
          this.setState(newState, () => this.props.feedbackStore.getFeedback(this.props.feedbackStore.contentDescriptionId));
        }
      };

      /**
       * update form with user's new answers
       */
      updateForm = () => {
        const { originalReview, review, originalRatings, ratings } = this.props.feedbackStore;

        // check for update to review
        const areStringsSame = !!originalReview && !!originalReview.review ? originalReview.review === review : true;
        // check for updates to ratings
        const formattedNewRatings = FormatUtil.getKeyFromArray(ratings, 'rating');
        const formattedOriginalRatings = FormatUtil.getKeyFromArray(originalRatings, 'rating');
        const areArraysSame = FormatUtil.areArraysSame(formattedOriginalRatings, formattedNewRatings);
        // if there have been changes, set original values to the new values
        if (!areStringsSame) {
          this.props.feedbackStore.setOriginalReview({ review });
        }
        if (!areArraysSame) {
          this.props.feedbackStore.setOriginalRatings(ratings);
        }
        // fetch the feedback again
        this.props.feedbackStore.getFeedback(this.props.feedbackStore.contentDescriptionId);
      };

      /**
       * Display error component for 2.5 seconds
       */
      triggerError = () => {
        this.props.feedbackStore.displayError(true);
        setTimeout(() => {
          this.props.toggleFeedbackDisplay();
          this.props.feedbackStore.reset();
        }, 2500);
      };

      /**
       * Display thank you message for 2.5 seconds
       */
      triggerSuccess = () => {
        this.props.feedbackStore.displayThankYouMessage(true);
        setTimeout(() => {
          this.props.toggleFeedbackDisplay();
          this.updateForm();
          this.props.feedbackStore.reset();
        }, 2500);
      };

      /**
       *  Submit logic for the feedback form.
       */
      submitFeedbackForm = (data) => {
        // transform the form data into an object the back is expecting
        const submitData = {};
        const transformRatings = [];
        Object.keys(data).forEach((item) => {
          if (item === 'review') {
            submitData.review = data[item];
          } else {
            transformRatings.push({ question_type: item, rating: data[item] });
          }
        });
        submitData.ratings = transformRatings;
        this.props.feedbackStore.setRatings(submitData.ratings);
        this.props.feedbackStore.setReview({ review: submitData.review });
        this.props.feedbackStore.updateReviewsAndRatings(submitData).then(() => {
          const { reviewsAndRatingsError } = this.props.feedbackStore;
          if (reviewsAndRatingsError) {
            this.triggerError();
          } else {
            this.triggerSuccess();
          }
        });
      };

      /**
       * get the default value of the form field
       */
      getDefaultValue = (question_type) => {
        let foundItem = {};
        const { ratings } = this.props.feedbackStore;
        ratings.forEach((rating) => {
          if (rating.question_type === question_type) {
            foundItem = rating;
          }
          return null;
        });
        return foundItem.rating;
      };

      /**
       *  Clear out a server error for a given field
       */
      clearErrorField = (key) => {
        this.props.feedbackStore.clearFeedbackErrorsForKey(key);
      };

      getFormDisplay = ({ isSidebar, toggleFeedbackDisplay }) => {
        const { review, ratings, isThankYouMessageDisplayed, isErrorDisplayed, reviewsAndRatingsError, reviewsAndRatingsLoading, feedbackForm } = this.props.feedbackStore;
        let form = null;
        switch (feedbackForm) {
          case 'course':
            // Cybrary Live Sessions, Courses, MicroCourses
            form = CourseBasedReviewForm;
            break;
          case 'assessment':
            form = AssessmentBasedReviewForm;
            break;
          case 'lab':
            form = LabBasedReviewForm;
            break;
          case 'collection':
            form = CollectionBasedReviewForm;
            break;
          case 'video':
          default:
            // default to audio video review form
            form = AudioVideoBasedReviewForm;
            break;
        }
        if (form && form.fields && form.fields.cancelBtn) {
          form.fields.cancelBtn.onClick = toggleFeedbackDisplay;
        }
        if (isSidebar && form && form.columns) {
          form.columns = ['w-full flex-col', 'w-full flex-col', 'w-full flex-row justify-end items-center'];
        }
        // null state of form on render
        Object.keys(form.fields).forEach((field) => {
          if (field !== 'submit') {
            form.fields[field].defaultValue = '';
          }
        });
        // format default values on render
        if (!!review && review.review) {
          if (form.fields.review) {
            form.fields.review.defaultValue = review.review;
          }
        }
        if (!!ratings && ratings.length) {
          Object.keys(form.fields).forEach((field) => {
            if (field !== 'review' && field !== 'submit') {
              form.fields[field].defaultValue = this.getDefaultValue(field);
            }
          });
        }
        if (reviewsAndRatingsLoading) {
          return <Loading message="Submitting..." />;
        }
        if (isThankYouMessageDisplayed) {
          return <ThankYouMessage />;
        }
        if (isErrorDisplayed) {
          return <StyledError error={reviewsAndRatingsError} />;
        }
        return (
          <DynamicForm
            form={form}
            formName={form.name}
            formId={this.props.feedbackFormId}
            serverErrors={reviewsAndRatingsError}
            clearErrorForField={this.clearErrorField}
            onSubmit={this.submitFeedbackForm}
          />
        );
      };

      render() {
        const { feedbackLoading, feedbackError } = this.props.feedbackStore;
        const { isOpen, isSidebar, className = '', toggleFeedbackDisplay } = this.props;
        const displayClass = isOpen ? 'block' : 'hidden';
        const containerClass = !isSidebar
          ? `reviews-modal absolute bg-white rounded-sm shadow-lg border-xs border-gray-400 z-30 ${displayClass} ${className}`
          : 'reviews-sidebar w-full';
        const ariaProps = {
          role: 'dialog',
          tabIndex: '-1',
          'aria-modal': 'true',
          'aria-label': 'Feedback Pane',
        };
        return (
          <>
            <ScreenClose isOpen={isOpen && !isSidebar} handleClose={toggleFeedbackDisplay} />
            {isOpen || isSidebar ? (
              <FocusLock autoFocus={false} className={containerClass} returnFocus>
                <div {...ariaProps}>
                  <Form
                    feedbackLoading={feedbackLoading}
                    feedbackError={feedbackError}
                    handleClose={this.props.toggleFeedbackDisplay}
                    form={this.getFormDisplay({ isSidebar, toggleFeedbackDisplay })}
                    isSidebar={isSidebar}
                  />
                </div>
              </FocusLock>
            ) : null}
          </>
        );
      }
    }
  )
);

export default FeedbackPane;
