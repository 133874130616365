import React from 'react';
import If from '../If/If';
import ContentCard from '../Card/ContentCard';
import ContentTransformer from '../../transformers/contentTransformer';

function CertificationCareerPath({ data }) {
  if (!data) {
    return null;
  }
  const formattedContent = ContentTransformer.formatDataSources(data);
  return (
    <div>
      <h3 className="mb-2 text-lg font-bold">Career Path Featuring This Certification</h3>
      <p className="text-sm text-gray-600">Identify certifications that align with different career paths to help you achieve your career goals. </p>
      <div className="max-w-lg">
        <ContentCard data={formattedContent} gridShowDescription fluid />
      </div>
    </div>
  );
}

function AdditionalContent({ data, omitHeader }) {
  if (!data || !data.length) {
    return null;
  }

  return (
    <div>
      <If condition={!omitHeader}>
        <>
          <h2 className="mb-2 text-2xl font-black">Additional Content</h2>
          <p className="text-sm text-gray-600">Discover additional content aligned to this certification.</p>
        </>
      </If>
      {data && data.length && (
        <div className="flex relative flex-col gap-y-8 mb-6 sm:flex-row sm:flex-wrap sm:gap-8">
          {data.map((item) => {
            const formattedAlgoliaObj = ContentTransformer.formatDataSources(item);
            return (
              <div className="flex" key={item.id}>
                <ContentCard data={formattedAlgoliaObj} className="sm:w-60" />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default function CertificationAdditionalContent({ content, inline, omitHeader }) {
  if (!content || !content.length) {
    return null;
  }

  let wrapperClassName = 'flex flex-col';

  if (!inline) {
    wrapperClassName += 'lg:flex-row lg:gap-x-8 xl:gap-x-18';
  }

  const careerPaths = [];
  const additionalContent = [];
  content.forEach((item) => {
    if (item.content_type.nice_name === 'Career Path') {
      careerPaths.push(item);
    } else {
      additionalContent.push(item);
    }
  });

  return (
    <div className={wrapperClassName}>
      {/* Start of left side column */}
      <AdditionalContent data={additionalContent} omitHeader={omitHeader} />
      {/* Start of Right side column */}
      <div className="lg:shrink-0 lg:w-[424px]">
        <CertificationCareerPath data={careerPaths[0]} />
      </div>
    </div>
  );
}
