import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { useParams } from 'react-router-dom';
import Container from '../../components/Container/Container';
import Title from '../../components/Title/Title';
import TeamOwnerActivityLog from '../../components/AdminTeamActivityLogs/TeamOwnerActivityLog';
import ActionUtil from '../../utils/actionsUtil';

const ActivityLog = inject(
  'commonStore',
  'authStore',
  'userStore'
)(
  observer(({ commonStore, authStore, userStore }) => {
    const { team } = userStore;
    const { orgId } = useParams();
    const isDemo = orgId === 'demo';

    useEffect(() => {
      if (isDemo) {
        userStore.enterDemo();
      } else {
        userStore.setPreferredTeamInit('', orgId);
      }
      commonStore.setPageTitle(`Audit Log${isDemo ? ' Demo' : ''} | Cybrary`);
      authStore.fireAttributionEvent();
      // scroll to top
      ActionUtil.scrollToTop();
    }, []);

    return (
      <Container size="lg">
        <Title title="Audit Log" />
        <TeamOwnerActivityLog team={team} />
      </Container>
    );
  })
);

export default ActivityLog;
