import React, { useEffect } from 'react';
import useDiscourse from '../../../hooks/useDiscourse';
import StyledError from '../../Error/StyledError';
import Loading from '../../Loading/Loading';
import If from '../../If/If';
import TopForumsTopicRow from '../Rows/TopForumsTopicRow';
import WidgetContainer from '../../Container/WidgetContainer';
import Header from '../../Header/Header';
import { deconstructCategorySlug } from '../../../utils/discourseUtil';
import Button from '../../Button/Button';

/**
 * Displays top Topics (Discussions) from discourse for a specific category in a widget
 * Used on course/collection pages
 * @param {string} discourseCategorySlug - category slug to fetch topics for. From content meta. i.e. cert-prep/certified-information-systems-security-professional-cissp/6
 * @param {boolean} border - Whether or not to apply a border to the container
 * @param {string} className - Tailwind classes to apply to the container
 * @param {number} maxTopics - Max number of topics to display
 * @returns <TopForumTopicsForCategoryWidget />
 */
function TopForumTopicsForCategoryWidget({ discourseCategorySlug, maxTopics = 10 }) {
  const { topicsForCategory, isTopicsForCategoryLoading, topicsForCategoryError, fetchTopicsForCategory } = useDiscourse();

  // discourseCategorySlug looks like "cert-prep/cissp/8". We need to split the last part off to get the category ID and slug separated
  const { categoryId, categorySlug } = deconstructCategorySlug(discourseCategorySlug) || {};

  useEffect(() => {
    if (!topicsForCategory) {
      fetchTopicsForCategory(categorySlug, categoryId, 'latest', { ascending: false, order: 'posts', exclude_tag: 'hide-in-app' });
    }
  }, [topicsForCategory, categoryId, categorySlug]);

  const { topics } = topicsForCategory?.topic_list || {};
  const activeCategoryLink = discourseCategorySlug ? `${process.env.REACT_APP_FORUMS_URL}/c/${discourseCategorySlug}` : process.env.REACT_APP_FORUMS_URL;

  return (
    <WidgetContainer>
      <Header as="h3" className="pb-2 border-b border-b-gray-400">
        Forums
      </Header>
      <If condition={isTopicsForCategoryLoading}>
        <Loading message="Loading..." />
      </If>
      <If condition={topicsForCategoryError}>
        <StyledError error={topicsForCategoryError} />
      </If>
      <If condition={!!topics?.length}>
        {/** Clamp topics to maxTopics max */}
        {topics?.slice(0, maxTopics).map((topic) => (
          <TopForumsTopicRow key={topic.id} topic={topic} />
        ))}
      </If>
      <If condition={!isTopicsForCategoryLoading && !topicsForCategoryError && !topics?.length}>
        <p>No forums topics have been posted yet for this category.</p>
        <Button href={activeCategoryLink} color="gray" icon={{ name: 'external-link', position: 'right' }}>
          Start a new discussion
        </Button>
      </If>
    </WidgetContainer>
  );
}

export default TopForumTopicsForCategoryWidget;
