import React from 'react';
import GoalsUtil from '../../../utils/goalsUtil';
import Icon from '../../Icon/Icon';
import Button from '../../Button/Button';

function NoGoalsBanner({ userGoal, toggleOverlay, noItemsJsx = "You're almost done setting your goal" }) {
  const userGoalOutcomeMap = GoalsUtil.getUserGoalOutcomeMap();
  const goalVal = userGoalOutcomeMap?.[userGoal];
  const headingStyles = 'mb-2 text-2xs font-semibold text-gray-600 uppercase';
  return (
    <div className="mx-auto w-full">
      <div className="py-0 px-2 lg:py-6">
        <div className="py-0 px-2 mx-auto w-full max-w-[400px] lg:py-3">
          <div className="mb-4">
            <h2 className="mb-8 font-semibold text-center text-gray-600 bg-center">{noItemsJsx}</h2>
            {!!goalVal && (
              <>
                <p className={headingStyles}>Goal</p>
                <p className="text-lg font-bold">
                  {userGoal}
                  <button aria-label="Add Goal" id="add-learner-goal-icon" onClick={() => toggleOverlay('add', null, null, false)}>
                    <Icon className="inline-block mb-1 ml-3 w-4 h-4 hover:text-cyb-pink-500" name="edit" />
                  </button>
                </p>
              </>
            )}
            <p className={headingStyles}>How to get started</p>
            <p className="text-xs ">
              Need help getting started? Set your goal to get personalized learning recommendations. Set a deadline to hold yourself accountable and to measure your progress. Reach
              your goal faster with our guidance and support!
            </p>
          </div>
          <div className="text-center">
            <Button id="add-learner-goal-banner" color="pink" onClick={() => toggleOverlay('add', null, null, goalVal)}>
              + Set Your Goal
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NoGoalsBanner;
