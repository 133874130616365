import Bugsnag from '@bugsnag/js';
import { v4 as uuidv4 } from 'uuid';
import React, { Component } from 'react';
import AsyncSelect from 'react-select/async';
import MultiSelectRemoveSelection from '../FormFields/MultiSelectRemoveSelection';
import MultiSelectRemoveAllSelections from '../FormFields/MultiSelectRemoveAllSelections';
import ReactSelectMenuList from '../FormFields/ReactSelectMenuList';
import StyleUtil from '../../utils/styleUtil';
import { getCN } from '../../utils/formatUtil';

class AsyncMulti extends Component {
  state = { inputValue: [] };

  dropdownName = this.props.name || uuidv4();

  handleInputChange = (newValue) => {
    if (!newValue) {
      return null;
    }
    const inputValue = newValue.map((value) => {
      return {
        title: value.title,
        id: value.id,
        duration: value.duration,
        durationSeconds: value.durationSeconds,
        contentTypeTitle: value.contentTypeTitle,
        type: value.type,
        tags: value.tags,
      };
    });
    this.setState({ inputValue });
    return inputValue;
  };

  onDone = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.handleDone(this.state.inputValue);
  };

  handleFocus = () => {
    const element = document.querySelector(`.auto-text-${this.dropdownName}`);
    if (element) {
      element.classList.add('border-blue-500');
    }
  };

  handleBlur = () => {
    const element = document.querySelector(`.auto-text-${this.dropdownName}`);
    if (element) {
      element.classList.remove('border-blue-500');
    }
    if (this.props.onBlur) {
      this.props.onBlur();
    }
  };

  render() {
    const selectClass = getCN(`
    auto-text-${this.dropdownName}
    border rounded pl-1
    ${this.props.error ? 'border-red-500 bg-red-100 placeholder-gray-600' : 'border-gray-400 placeholder-gray-600'}
    ${this.props.disabled && 'bg-gray-300 cursor-not-allowed'}
  `);

    return (
      <div>
        {this.props.label ? <label htmlFor={this.props.inputId}>{this.props.label}</label> : null}
        <AsyncSelect
          isMulti
          placeholder={this.props.placeholder}
          getValue={(e) => Bugsnag.notify(e)}
          loadOptions={this.props.content}
          onChange={this.handleInputChange}
          inputId={this.props.inputId}
          removeSelected
          styles={StyleUtil.getReactSelectCustomStyles()}
          className={selectClass}
          components={{ MultiValueRemove: MultiSelectRemoveSelection, ClearIndicator: MultiSelectRemoveAllSelections, MenuList: ReactSelectMenuList }}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
        />
        <button onClick={this.onDone} className="py-2.5 px-6 mt-4 text-sm font-bold leading-5 text-center text-black bg-gray-200 rounded-sm">
          Add
        </button>
      </div>
    );
  }
}

export default AsyncMulti;
