import { useMemo, useState, useEffect, useCallback } from 'react';
import Bugsnag from '@bugsnag/js';
import Agents from '../agents/agents';

export default function usePracticeExamScores({ practiceExam }) {
  const [practiceExamScores, setPracticeExamScores] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchScores = useCallback(async () => {
    try {
      setLoading(true);

      let scores = [];
      if (practiceExam) {
        scores = await Agents.enrollments.getAllScores(practiceExam.content_description_id, '?sortCol=created_at&sortDirection=desc');
      }

      setPracticeExamScores(scores);
    } catch (e) {
      Bugsnag.notify(e);
      setError(e);
    } finally {
      setLoading(false);
    }
  }, [practiceExam]);

  useEffect(() => {
    if (practiceExam) {
      fetchScores();
    }
  }, [practiceExam]);

  return useMemo(
    () => ({
      practiceExamScores,
      loading,
      error,
    }),
    [practiceExam, practiceExamScores, loading, error]
  );
}
