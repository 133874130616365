import React, { useState, useEffect } from 'react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import { Combobox } from '@headlessui/react';
import Bugsnag from '@bugsnag/js';

// Fix the index of an item by it's id
function findIndexById(items, id, idKey = 'id', allowNull = false) {
  if (!items || (!allowNull && !id)) {
    return -1;
  }
  for (let i = 0; i < items.length; i++) {
    if (items[i][idKey] === id) {
      return i;
    }
  }
  return -1;
}

export default function ComboboxMultiselect({ placeholder, performQuery, onChange = null, options = [] }) {
  const [localOptions, setLocalOptions] = useState([]);
  const [values, setValues] = useState([]);

  const setter = (newVal) => {
    // If we have selected All, we must unset all values
    const oldIndexOfAll = findIndexById(values, '_all', 'value');
    const indexOfAll = findIndexById(newVal, '_all', 'value');
    // 1. If all was present before, and there's more than one now, we remove the all check
    // 2. if all was NOT present before, but is now, we show only ALL
    // 3. if we have clicked all while all was already selected, are we doing anything? I don't think so...
    // 4. If size was 1 before and is 1 now, we are always clicking ALL... (same as 3 then)
    if (oldIndexOfAll === -1 && indexOfAll !== -1) {
      // We are adding All
      // option 2
      setValues([newVal[indexOfAll]]);
      return;
    }
    if (oldIndexOfAll !== -1 && newVal?.length > 1) {
      newVal.splice(indexOfAll, 1);
      // option 1
      setValues(newVal);
      return;
    }
    // If we want to only disallow unselecting All this way, use the next line, otherwise use the one below
    if (values?.length === 1 && newVal?.length === 0) {
      return;
    }
    setValues(newVal);
  };

  useEffect(() => {
    const getOptions = async () => {
      if (performQuery) {
        try {
          const result = await performQuery();
          const newOptions = [
            {
              value: '_all',
              id: '_all',
              label: 'All',
            },
          ];
          result.forEach((item) => {
            const { id, name } = item;
            newOptions.push({
              value: id,
              key: id,
              label: name,
            });
          });
          setLocalOptions(newOptions);
          setValues([newOptions[0]]);
        } catch (err) {
          Bugsnag.notify(err);
        }
        return;
      }

      setLocalOptions(options || []);

      if (options.length > 0) {
        setValues([options[0]]);
      }
    };

    getOptions();
  }, []);

  useEffect(() => {
    if (!onChange || !values?.length) {
      return;
    }
    onChange(values);
  }, [values]);

  const displayValues = values?.length && values.map((val) => val.label).join(', ');
  const optionsClasses = 'overflow-auto absolute z-50 py-1 mt-1 w-full max-h-60 text-base bg-white rounded-sm focus:outline-none ring-1 ring-gray-400 shadow-lg sm:text-sm ring-5';
  const activeOptionClass = 'relative z-50 cursor-default select-none py-2 pl-9 pr-3 bg-indigo-600 text-white';
  const inactiveOptionClass = 'relative z-50 cursor-default select-none py-2 pl-9 pr-3 text-gray-900';
  const selectedSpanClass = 'block truncate z-50 font-semibold';
  const unselectedSpanClass = 'block truncate z-50';
  const activeCheckClass = 'absolute inset-y-0 left-0 flex items-center pl-4 text-white';
  const inactiveCheckClass = 'absolute inset-y-0 left-0 flex items-center pl-4 text-indigo-600';

  return (
    <Combobox multiple as="div" className="z-50" value={values} onChange={setter}>
      <div className="relative z-50">
        <Combobox.Button className="flex">
          <div className="inline-block overflow-hidden py-1.5 pr-10 pl-3 w-[220px] text-left truncate bg-white rounded-sm border border-gray-400 shadow-sm sm:text-md">
            {displayValues || placeholder}
          </div>
          <div className="flex absolute inset-y-0 right-0 items-center px-2 rounded-r-md focus:outline-none">
            <SelectorIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
          </div>
        </Combobox.Button>

        {localOptions.length > 0 && (
          <Combobox.Options className={optionsClasses}>
            {localOptions.map((option) => (
              <Combobox.Option key={option.value} value={option} className={({ active }) => (active ? activeOptionClass : inactiveOptionClass)}>
                {({ active, selected: isSelected }) => (
                  <>
                    <span className={isSelected ? selectedSpanClass : unselectedSpanClass}>{option.label}</span>

                    {isSelected && (
                      <span className={active ? activeCheckClass : inactiveCheckClass}>
                        <CheckIcon className="w-5 h-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}
