import { useState, useEffect } from 'react';
import { map, flatten } from 'lodash';
import Agents from '../agents/agents';
import { formatPath } from '../utils/pathsUtil';

/** Hook for managing Content Collection Paths  */
const useContentCollectionPath = (permalink, pathType = 'threatActors') => {
  const [path, setPath] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [coursesData, setCourses] = useState(null);
  const [mitreData, setMitreData] = useState({ courseIds: null, techniqueIds: null });

  // Fetch single path
  useEffect(() => {
    // fetch path and set state
    const fetching = async () => {
      try {
        const data = await Agents[pathType].getByPermalink(permalink);
        setPath(formatPath(data));
      } catch (e) {
        setError(e);
      }
      setLoading(false);
    };
    fetching();
  }, []);

  // Format courses
  useEffect(() => {
    const courses = path && path.courses ? path.courses : [];
    const data = courses.map((course) => {
      const { progress, content_type, average_rating, status } = course;
      return {
        ...course,
        average_rating: average_rating ? parseInt(average_rating, 10).toFixed(2) : null,
        comingSoon: status === 'Coming Soon',
        content_type: content_type.nice_name,
        progress: parseInt(progress, 10),
      };
    });
    setCourses(data);
  }, [path]);

  // Set Mitre data (courseIds and techniqueIds)
  useEffect(() => {
    const courses = path && path.courses ? path.courses : [];
    let mitreTechniqueIds = [];
    const mitreCourseIds = courses.map((course) => {
      const { attack_patterns } = course;
      if (Array.isArray(attack_patterns) && attack_patterns.length) {
        const techniqueIds = map(attack_patterns, 'id');
        mitreTechniqueIds.push(techniqueIds);
      }
      return course.id;
    });

    mitreTechniqueIds = flatten(mitreTechniqueIds);
    setMitreData({
      courseIds: mitreCourseIds,
      techniqueIds: mitreTechniqueIds,
    });
  }, [path]);

  return {
    loading,
    error,
    path,
    coursesData,
    ...mitreData,
  };
};

export default useContentCollectionPath;
