/* eslint-disable no-console */
import SockJS from 'sockjs-client';
// eslint-disable-next-line import/no-unresolved
import { Client } from '@stomp/stompjs';

/**
 * Class to manage sockets
 */
export class SocketConnection {
  baseUrl;

  client;

  /**
   * Keep a list of topics we are subscribed to. Helps with auto reconnecting
   * and allows you to subscribe outside the onConnect callback.
   * The subscribe and unsubscribe functions handle adding and removing topics.
   */
  topics = [];

  stompError;

  /**
   * Set base url and base websocket connection
   * SockJS is a type of websocket that works with old browsers
   * @param baseUrl
   */
  constructor(baseUrl) {
    this.baseUrl = baseUrl;

    this.client = new Client({
      webSocketFactory: () => {
        return new SockJS(this.baseUrl);
      },
    });
  }

  connect(id, jwt, userId) {
    const sessionId = id || '';
    this.client.connectHeaders = { 'clab-session-id': sessionId, 'clab-session-token': `${jwt}`, 'clab-user-id': `${userId}` };
    this.client.onConnect = () => {
      // On initial connection and auto reconnecting, subscribe to all `Topics[]` available
      this.subscribeAll();
    };

    this.client.debug = () => {
      // this.client.debug = (str: string) => {
      // eslint-disable-next-line no-console
      // console.log('Debugging: ', str);
    };

    this.client.onStompError = (error) => {
      this.stompError = error;
      throw error;
    };

    // Start the actual connection, nothing is called until this runs.
    this.client.activate();
  }

  subscribeAll() {
    this.topics.forEach((topic, index) => {
      const subscriber = this.client.subscribe(topic.name, (message) => topic.callback(message));
      this.topics[index].subscriber = subscriber;
    });
  }

  subscribe(name, callback) {
    const foundTopic = this.topics.find((topic) => topic.name === name);
    const { name: topicName } = foundTopic || {};

    if (!topicName && this.isConnected()) {
      const subscriber = this.client.subscribe(name, (message) => callback(message));
      this.topics.push({ name, subscriber, callback });
    } else if (!topicName) {
      this.topics.push({ name, callback });
    }
  }

  unsubscribe(name) {
    const foundTopic = this.topics.find((topic) => topic.name === name);
    // remove topic from topics array
    this.topics = this.topics.filter((topic) => topic.name !== name);

    if (foundTopic?.subscriber?.id && this.isConnected()) {
      foundTopic?.subscriber?.unsubscribe();
    }
  }

  /**
   * Disconnect from socket and clear topics
   */
  disconnect() {
    console.log(new Date(), 'INSIDE SOCKET CONNECTION DISCONNECT');
    this.client.deactivate();
    this.topics = [];
  }

  /**
   * Check if socket is connected
   * @returns boolean
   */
  isConnected() {
    return this.client.connected;
  }
}

const clabSocket = new SocketConnection(`${process.env.REACT_APP_CLAB_API_URL}/clab`);

/**
 * Add new global sockets with different connection url here and export them.
 * If it's not global, just import SocketConnection and use it within your component as shown above.
 */

export default clabSocket;
