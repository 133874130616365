import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import UserOnboardingRouter from './OnboardingRouters/UserOnboardingRouter';
import OnboardingContainer from './OnboardingContainer';
import OnboardingHeader from './OnboardingHeader';

const UserOnboardingPage = inject('commonStore')(
  observer(({ commonStore }) => {
    useEffect(() => {
      commonStore.hidePrimaryNav();
      commonStore.setHideFooter(true);
      return () => {
        commonStore.showPrimaryNav();
        commonStore.setHideFooter(false);
      };
    }, []);
    return (
      <OnboardingContainer>
        <OnboardingHeader />
        <UserOnboardingRouter />
      </OnboardingContainer>
    );
  })
);

export default UserOnboardingPage;
