import React from 'react';
import X from '../../Icons/X';

function AchievementsMessage({ classes, visible, children, onDismiss, width, immersiveView }) {
  if (!children) {
    return null;
  }
  const wrapperClasses = `${visible ? 'block' : 'hidden'} ${classes}`;
  const positionClasses = `${!immersiveView ? 'top-20 lg:top-16 right-8' : 'top-14 top-18 right-4'}`;
  return (
    <div
      style={{ width: `${width || '15.5rem'}` }}
      className={`absolute rounded-lg border shadow-2xl p-6 -ml-2 ${positionClasses} bg-cyb-blue-500 text-white border-cyb-blue-500 ${wrapperClasses}`}
    >
      <button aria-label="Dismiss Achievement Message" onClick={() => onDismiss()}>
        <X classes="w-4 h-4 absolute top-4 right-4 cursor-pointer" />
      </button>
      {children}
    </div>
  );
}

export default AchievementsMessage;
