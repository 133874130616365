import React from 'react';
import Tooltip from '../Tooltip/Tooltip';
import { getCN } from '../../utils/formatUtil';
import Icon from '../Icon/Icon';

function FieldLabel({ children, displayAsLegend, id, style, labelClass }) {
  if (displayAsLegend) {
    return (
      <legend htmlFor={id} style={style} className={labelClass}>
        {children}
      </legend>
    );
  }
  return (
    <label htmlFor={id} style={style} className={labelClass}>
      {children}
    </label>
  );
}

function Label(props) {
  const { type, id, label, style, className, required, tooltip, trigger, alignment, ratings, toggle, isHidden, displayAsLegend, quasiRequired } = props;
  const labelContent = toggle ? <ToggleLabel label={label} isHidden={isHidden} /> : label;

  // Hide label from the following types
  const validLabel = !['button', 'link', 'text-toggle', 'boolean', 'captcha', 'submit-captcha-v3'].includes(type);

  // use className prop to override default className
  const defaultClass = `${className || 'block text-gray-800 text-md mb-1'}`;

  let labelClass = `font-bold ${defaultClass}`;

  if (ratings) {
    labelClass = getCN(`
      ${defaultClass}
      ${alignment === 'inline' && 'float-left w-1/2 md:w-1/3 mr-8'}
    `);
  }

  if (!label || !validLabel) {
    return null;
  }
  /**
   * @important - `quasiRequired: true` on dynamic form is essentially the same as
   * `required: true`... however we did it for additional clarity... typically when adding `validations: ['required']`... Dynamic form handles adding `required:true` */

  /** quasi-required is a concept somebody wanted to make it appear required although it isn't required... idk bro i don't make the rules */
  const showRequiredAsterisk = required || quasiRequired;
  return (
    <FieldLabel displayAsLegend={displayAsLegend} id={id} style={style} labelClass={labelClass}>
      {labelContent}
      {!!showRequiredAsterisk && <span className="ml-1 text-red-700">*</span>}
      {tooltip && (
        <span className="pl-2">
          <Tooltip content={tooltip.content} triggerContent={tooltip.trigger} position={tooltip.position} trigger={trigger || 'mouseenter'} />
        </span>
      )}
    </FieldLabel>
  );
}

function ToggleLabel({ isHidden, label }) {
  return (
    <div className="flex items-center cursor-pointer">
      <Icon name={isHidden ? 'plus' : 'minus'} className="w-6 h-6 text-blue-500" />
      <div className="inline-block ml-2">{label}</div>
      <div className="inline-block ml-2">(optional)</div>
    </div>
  );
}

export default Label;
