import React from 'react';
import { observer, inject } from 'mobx-react';
import NotificationUtil from '../../utils/notificationUtil';
import './content-updates.css';
import Icon from '../Icon/Icon';

const ContentUpdateBanner = inject(
  'userStore',
  'notificationsStore'
)(
  observer(({ notificationsStore, userStore, content, cta, notificationId, page }) => {
    const dismissNotification = (id) => {
      NotificationUtil.dismissNotification(id);
      if (page === 'Paths') {
        notificationsStore.setCurriculaNotifications(userStore.userTeams);
      } else {
        notificationsStore.setContentNotifications(page, false);
      }
    };

    return (
      <div className="flex flex-col justify-between items-center py-4 px-6 text-sm bg-cyb-blue-500 rounded-sm lg:flex-row">
        <div className="flex-1 font-bold text-white lg:mr-4">{content}</div>
        <div className="flex items-center mt-4 lg:mt-0">
          <button onClick={() => dismissNotification(notificationId)} className="mr-4 text-white hover:text-black underline hover:underline cursor-pointer">
            {cta}
          </button>
          <button onClick={() => dismissNotification(notificationId)} aria-label="Dismiss Banner" className="group hover:text-black">
            <Icon name="x" className="w-5 h-5 text-white group-hover:text-black cursor-pointer" />
          </button>
        </div>
      </div>
    );
  })
);

export default ContentUpdateBanner;
