import React from 'react';
import ContentRenderer from './ContentRenderer';
import RichTextRenderer from './RichTextRenderer';

// Content renderer, used for rendering instruction for clab (taken from ContentWrapper inside LessonContent)
function InstructionsRenderer({ activity, content, userStore }) {
  // SLATE type is content created via CPIPE and stored in Contentful
  // Filtering out SLATE type content items from the rest of content
  // If SLATE type is present, only render that; ignore all the other content blocks
  const slateContent = content.filter((block) => block.type === 'slate');
  // I'm pretty sure that it'll all be lesson content when we are rendering instructions going forward, so we can probably drop the slate content here...
  const { lesson_content: lessonContent } = activity || {};
  const hasLessonContent = !!lessonContent?.content && !!lessonContent.content.content && !!lessonContent.content.content.length;
  const hasSlateContent = !!slateContent?.length;
  if (!hasLessonContent && !hasSlateContent) {
    return null;
  }
  if (hasLessonContent) {
    return <RichTextRenderer userStore={userStore} references={lessonContent.references} content={lessonContent.content.content} />;
  }
  return (
    <>
      {slateContent.map((block) => (
        <ContentRenderer key={block.id} content={block.content} activity={activity} userStore={userStore} />
      ))}
    </>
  );
}

export default InstructionsRenderer;
