import React from 'react';
import ContinueLearningBookmarksTab from './tabs/ContinueLearningBookmarksTab';
import ContinueLearningCurrentEnrollmentTab from './tabs/ContinueLearningCurrentEnrollmentTab';
import ContinueLearningTabInProgress from './tabs/ContinueLearningInProgressTab';
import BasicTabs from '../Tabs/BasicTabs';

// Tabs to display in the continue learning block
const TABS = [
  {
    id: 'current',
    title: 'Current',
    content: <ContinueLearningCurrentEnrollmentTab />,
    headerTitle: 'Continue Learning',
    headerTitleLink: '/my-learning',
  },
  {
    id: 'inProgress',
    title: 'In Progress',
    content: <ContinueLearningTabInProgress />,
    headerTitle: 'Continue Learning',
    headerTitleLink: '/my-learning',
  },
  {
    id: 'bookmarked',
    title: 'Bookmarked',
    content: <ContinueLearningBookmarksTab />,
    headerTitle: 'Continue Learning',
    headerTitleLink: '/bookmarks',
  },
];

/**
 * Tabbed interface to display the current course, in progress courses, and bookmarked courses
 * @returns <ContinueLearningTabs />
 */
export default function ContinueLearningTabs() {
  return (
    <div className="w-full h-full">
      {/** Tab Content */}
      <BasicTabs
        tabs={TABS}
        headerTitle="Continue Learning"
        headerTitleLink="/my-learning"
        omitContainerBorder
        navContainerClassName="pt-1 lg:px-4 lg:pt-3 mb-1"
        headerTitleClassName="pl-2 lg:px-0"
      />
    </div>
  );
}
