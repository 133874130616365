import React from 'react';

export default function DiscountBubble({ percentOff, discountText, whichProduct, originalDollarAmount, positionClasses, sizeClasses = 'w-16 h-16', textSize = 'text-xs' }) {
  const position = positionClasses || '';
  const bubbleClasses = `${position} ${sizeClasses} bg-cyb-blue-500 rounded-full flex flex-col items-center justify-center`;
  const hasDiscountText = discountText && discountText.length;
  const hasCoupon = whichProduct && whichProduct.couponId;

  // If any coupon discount is passed in, use that
  if (hasCoupon && hasDiscountText) {
    return (
      <div className={bubbleClasses.replace('flex-col', '')}>
        <div className={`font-bold text-white ${textSize} text-center`}>
          <span className="line-through">${originalDollarAmount}</span>
          <span className="mx-1">|</span>
          {discountText}
        </div>
      </div>
    );
  }

  // If any discount text is passed in, use that
  if (hasDiscountText) {
    return (
      <div className={bubbleClasses}>
        <div className={`font-bold text-white ${textSize} text-center`}>{discountText}</div>
      </div>
    );
  }
  // Otherwise if a percent off is passed in and not 0, use that
  if (percentOff && percentOff * 1) {
    return (
      <div className={bubbleClasses}>
        <div className="text-[.6rem] font-bold text-white">Additional</div>
        <div className="text-[.6rem] font-bold text-white">{percentOff}% OFF</div>
      </div>
    );
  }
  return <div className={sizeClasses} />;
}
