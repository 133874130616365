import React from 'react';
import AddLink from '../AddLink/AddLink';

function AdditionalResources({ items }) {
  if (!items || !items.length) {
    return null;
  }
  return (
    <ul className="list-none">
      {items.map((item) => (
        <li className="pb-6" key={item}>
          <AddLink target="_blank" className="hover:font-semibold text-black hover:text-black underline hover:underline" to={item}>
            {item}
          </AddLink>
        </li>
      ))}
    </ul>
  );
}

export default AdditionalResources;
