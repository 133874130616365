import React, { useState, useEffect } from 'react';
import Dropdown from '../Dropdown/Dropdown';

function MultiSelect(props) {
  const { name, options, placeholder, required, defaultValue, disabled, error, onChange, id, value, ariaLabel, ariaDescribedBy, onMouseDown, onBlur, classes } = props;

  const [input, setInput] = useState(() => {
    if (!defaultValue) {
      return [];
    }

    return options.reduce((items, item) => {
      if (defaultValue.indexOf(item.value) !== -1) {
        items.push({
          label: item.text,
          value: item.value,
        });
      }
      return items;
    }, []);
  });

  const handleChange = (val, action) => {
    let newValue = val;
    if (!newValue) {
      newValue = [];
    } else {
      newValue = val.map((item) => {
        return item.value;
      });
    }
    setInput(newValue);
    onChange(undefined, { name, value: newValue }, action);
    return newValue;
  };

  const handleMouseDown = (dropdownProps, action) => {
    return onMouseDown(dropdownProps, action);
  };

  useEffect(() => {
    if (defaultValue) {
      setInput(defaultValue);
      onChange(undefined, { name, value: defaultValue });
    }
  }, [defaultValue]);

  // If value is passed in, always use that (controlled elsewhere)
  const val = typeof value !== 'undefined' ? value : input;
  return (
    <>
      <Dropdown
        name={name}
        options={options}
        isMulti
        isSearchable
        isClearable
        value={val}
        onChange={handleChange}
        placeholder={placeholder}
        id={id}
        ariaLabel={ariaLabel}
        error={error}
        disabled={disabled}
        required={required}
        ariaDescribedBy={ariaDescribedBy}
        onMouseDown={onMouseDown ? handleMouseDown : null}
        onBlur={onBlur}
        classes={classes}
      />
      <div className="pb-1" />
    </>
  );
}

export default MultiSelect;
