import React from 'react';
import moment from 'moment';
import AddLink from '../../components/AddLink/AddLink';
import Download from '../../Icons/Download';
import FormatUtil from '../../utils/formatUtil';
import ShareCertificate from './ShareCertificate';
import { useProfileContext } from '../../providers/ProfileProvider';
import If from '../../components/If/If';

function CertificateModalContent({ cert, omitSocial }) {
  const { certificate_id, title, issued_at } = cert || {};
  const issuedAt = moment(issued_at).format('M/D/YYYY');

  const downloadUrl = FormatUtil.formatCertificateDownloadUrl(certificate_id);
  const { personalProfileData } = useProfileContext();
  const { username } = personalProfileData || {};
  const profileUrl = username ? `${process.env.REACT_APP_BASE_URL}/profile/${username}?tab=cert-completion&cert=${certificate_id}` : downloadUrl;
  return (
    <div className="flex flex-col justify-center px-6 pt-10 bg-white rounded">
      <img
        src="https://images.ctfassets.net/kvf8rpi09wgk/7a9Hi5dmSSnzuNJqMTcqUC/5264b28124e7ec8087d82c771a3f84bb/Cybrary-Cert.png?h=400"
        alt=""
        className="inline-block m-auto mb-3 w-auto max-h-64"
      />
      <h2 id="cert-title" className="my-2 font-semibold text-center md:text-lg">
        {title} <span className="sr-only"> Cert of completion </span>
      </h2>
      <If condition={issuedAt}>
        <p className="mb-1 text-center text-gray-600">Completed {issuedAt}</p>
      </If>
      <p className="mb-0 text-center text-gray-600">Certificate ID: {certificate_id}</p>

      <If condition={downloadUrl}>
        <div className="pb-6 m-auto mt-3  border-b-2">
          <AddLink
            target="_blank"
            to={downloadUrl}
            className="py-2.5 px-6 text-sm font-bold leading-5 text-center text-white hover:text-white bg-cyb-pink-500 hover:bg-pink-600 rounded-sm cursor-pointer"
          >
            <Download classes="w-6 h-6 inline-block mr-1" /> Download
          </AddLink>
        </div>
      </If>

      <ShareCertificate certificate={cert} omitSocial={omitSocial} downloadUrl={profileUrl} />
    </div>
  );
}

export default CertificateModalContent;
