/* eslint-disable no-console */
import React from 'react';
import RichTextRenderer from '../RichTextRenderer';

function IntroScreen({ start, lessonContent }) {
  if (!lessonContent) {
    return null;
  }
  const btnClasses = 'cursor-pointer bg-cyb-pink-500 rounded-md font-semibold py-3 px-16 hover:bg-pink-600 text-white text-center text-base';
  return (
    <div className="mx-auto w-full max-w-5xl text-gray-900 bg-white">
      <div className="py-10 px-8">
        <p className="mb-8 text-xl font-black">Introduction</p>

        <RichTextRenderer references={lessonContent.references} content={lessonContent.content.content} />

        <div className="flex flex-col items-center mt-10">
          <button type="button" onClick={start} className={btnClasses}>
            Start Assessment
          </button>
        </div>
      </div>
    </div>
  );
}

export default IntroScreen;
