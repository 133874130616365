import React, { memo } from 'react';
import AssessmentScoreSummary from '../../Immersive/Content/Assessment/AssessmentScoreSummary';
import { useCybAssessmentAnalytics } from '../../../providers/CybAssessmentAnalyticsProvider';
import { TrackAssessmentActivity } from './AssessmentSummaryTrack';

function AssessmentSummaryScore() {
  const { currentAssessment } = useCybAssessmentAnalytics();
  return (
    <div className="flex flex-col grow w-full md:w-1/2">
      <div className="flex grow justify-center items-center py-4 mb-5 rounded-sm border border-neutral-600">
        <AssessmentScoreSummary score={currentAssessment.score} timeInSeconds={currentAssessment['time-elapsed']} submissionTimestamp={currentAssessment['submitted-at']} />
      </div>
      <div className="flex flex-col grow items-center rounded-sm border border-neutral-600">
        <TrackAssessmentActivity assessment={currentAssessment} />
      </div>
    </div>
  );
}

export default memo(AssessmentSummaryScore);
