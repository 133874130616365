import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import ExampleSection from './ExampleSection';
import Header from '../../../components/Header/Header';
import Code from '../../../components/Code/Code';
import ListTable from '../../../components/ListTable/ListTable';

const LIST_TABLE_HEADINGS = ['course', 'domain', 'difficulty', 'XP', 'Status'];

const BASIC_LIST_TABLE_DATA = [
  {
    id: uuidv4(),
    values: [
      {
        key: 'course',
        value: 'IT & Cybersecurity Foundations',
        description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fringilla risus leo varius aliquet ac.
        Viverra id magna tincidunt turpis. Adipiscing morbi in mi arcu morbi congue senectus.
        Nunc, enim duis lectus commodo. Pharetra fusce at massa purus ultricies dolor.
        Et amet, pellentesque justo, blandit dolor lectus cursus condimentum sollicitudin.
        Tempor, laoreet nibh cras nunc vel consectetur semper.`,
        image: 'https://images.ctfassets.net/kvf8rpi09wgk/7vySqQuaehtH1HNqsI1fwU/9c0a4742c0366d8fae02bf3d56d65289/CP_IT_CyberSec_Foundations.png',
        href: '#',
      },
      {
        key: 'domain',
        value: 'Engineering and Operations	',
      },
      {
        key: 'difficulty',
        value: 'Beginner',
      },
      {
        key: 'xp',
        value: 650,
      },
      {
        key: 'status',
        value: 'Active',
      },
    ],
  },
];

function ListTableExampleSection() {
  return (
    <ExampleSection title="List Table">
      {/** Basic */}
      <Header as="h2">Basic</Header>
      <div className="flex flex-col mt-2">
        <ListTable headings={LIST_TABLE_HEADINGS} rows={BASIC_LIST_TABLE_DATA} />
      </div>
      <Code>
        {`
      const LIST_TABLE_HEADINGS = ['course', 'domain', 'difficulty', 'XP', 'Status'];

      const BASIC_LIST_TABLE_DATA = [
        {
          id: '1910299',
          values: [
            {
              key: 'course',
              value: 'IT & Cybersecurity Foundations',
              description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fringilla risus leo varius aliquet ac.
              Viverra id magna tincidunt turpis. Adipiscing morbi in mi arcu morbi congue senectus.
              Nunc, enim duis lectus commodo. Pharetra fusce at massa purus ultricies dolor.
              Et amet, pellentesque justo, blandit dolor lectus cursus condimentum sollicitudin.
              Tempor, laoreet nibh cras nunc vel consectetur semper.',
              image:
                'CP_IT_CyberSec_Foundations.png',
              href: '#',
            },
            {
              key: 'domain',
              value: 'Engineering and Operations	',
            },
            {
              key: 'difficulty',
              value: 'Beginner',
            },
            {
              key: 'xp',
              value: 650,
            },
            {
              key: 'status',
              value: 'Active',
            },
          ],
        },
      ];

      <ListTable headings={LIST_TABLE_HEADINGS} rows={BASIC_LIST_TABLE_DATA} />
        `}
      </Code>
    </ExampleSection>
  );
}

export default ListTableExampleSection;
