import React, { useState } from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { components } from 'react-select';

/** Type Ahead Core */
function TypeAhead(props) {
  const { name, options, placeholder, defaultValue, disabled, error, onChange, isMulti, showDropDown, agent, format } = props;

  const [input, setInput] = useState(defaultValue);

  /* options and value format: { value: 'orange', label: 'Orange' } */
  const defaultOptions = options;

  const getOptions = (value) => {
    // request to server to get options
    return new Promise((resolve, reject) => {
      let loadedOptions = [];
      if (value.length < 3) {
        resolve(loadedOptions);
      } else {
        agent(value)
          .then((response) => {
            loadedOptions = response.results.map((option) => {
              return format(option);
            });
            resolve(loadedOptions);
          })
          .catch(() => {
            reject(loadedOptions);
          });
      }
    });
  };

  const handleChange = (val) => {
    let newValue = val;
    if (!newValue && isMulti) {
      newValue = [];
    }
    setInput(newValue);
    onChange(undefined, { name, value: newValue });
  };

  const indicatorSeparatorStyle = {
    alignSelf: 'stretch',
    display: !showDropDown ? 'none' : 'block',
    marginBottom: 8,
    marginTop: 8,
    width: 1,
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  function IndicatorSeparator({ innerProps }) {
    return <span style={indicatorSeparatorStyle} {...innerProps} />;
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  function DropdownIndicator(innerProps) {
    return <components.DropdownIndicator {...innerProps}>{!showDropDown ? <div /> : null}</components.DropdownIndicator>;
  }

  const customStyles = {
    control: (provided) => ({
      ...provided,
      '&:hover': { borderColor: 'transparent' },
      border: 'transparent',
      boxShadow: 'none',
      backgroundColor: 'transparent',
    }),
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: '#0db350',
        color: '#fff',
        borderRadius: '0.25rem',
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      color: '#ff',
      fontSize: '0.7rem',
      paddingRight: '0.5rem',
      paddingLeft: '0.75rem',
      paddingTop: '0.25rem',
      paddingBottom: '0.25rem',
    }),
  };

  const onFocus = () => {
    document.querySelector(`.auto-text-${name}`).classList.add('border-blue-500');
  };

  const onBlur = () => {
    document.querySelector(`.auto-text-${name}`).classList.remove('border-blue-500');
  };

  return (
    <AsyncCreatableSelect
      components={{ IndicatorSeparator, DropdownIndicator }}
      name={name}
      openMenuOnClick={false}
      isClearable
      isMulti={isMulti}
      defaultOptions={defaultOptions}
      loadOptions={getOptions}
      defaultValue={input}
      onChange={handleChange}
      placeholder={placeholder}
      styles={customStyles}
      onFocus={onFocus}
      onBlur={onBlur}
      className={`auto-text-${name} py-1 border border-gray-400 rounded focus:outline-none pl-1 ${
        error ? 'border-red-500 bg-red-100 placeholder-gray-600' : 'border-gray-400 placeholder-gray-600'
      } 
        ${disabled ? 'bg-gray-300 cursor-not-allowed' : null}`}
    />
  );
}

export default TypeAhead;
