import { useContext } from 'react';
import { PaddleCheckoutContext } from '../../providers/PaddleCheckoutProvider';

/**
 * Hook to access the PaddleCheckoutContext
 * @see `usePaddleCheckout` For creating new checkouts
 * @see `usePaddlePricing` For getting pricing estimates
 * @see `usePaddleSubscription` For managing exsisting subscription items
 * @returns
 */
const usePaddleCheckout = () => useContext(PaddleCheckoutContext);

export default usePaddleCheckout;
