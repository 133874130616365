import React from 'react';

export default function TrendingUp({ classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 512 640" x="0px" y="0px" strokeWidth="1.5" stroke="currentColor" {...accessibilityProps}>
      <g>
        <path d="M511.58,140.9c-.07-.73-.18-1.45-.31-2.16l0-.14c-.14-.71-.31-1.41-.51-2.09,0-.06,0-.12-.05-.17-.2-.68-.44-1.34-.7-2l-.09-.23c-.26-.64-.55-1.26-.87-1.87,0-.09-.09-.18-.14-.27-.32-.61-.67-1.2-1-1.78l-.17-.25c-.4-.6-.83-1.18-1.28-1.74l-.12-.16c-.51-.62-1-1.21-1.6-1.77h0c-.57-.56-1.16-1.1-1.77-1.6l-.16-.13c-.56-.45-1.14-.88-1.74-1.28l-.26-.16q-.87-.56-1.77-1l-.28-.14q-.92-.47-1.86-.87l-.25-.09q-1-.39-2-.69l-.18-.06c-.68-.19-1.38-.37-2.08-.5l-.16,0c-.7-.13-1.41-.24-2.14-.31h-.14c-.72-.07-1.44-.1-2.18-.11H356.48a24,24,0,0,0,0,48h73.27L305.65,291.34l-102-102a24,24,0,0,0-33.94,0L7.34,351.79a24,24,0,1,0,33.94,33.95L186.72,240.29l102,102a24,24,0,0,0,33.94,0L463.69,201.18v73.26a24,24,0,0,0,48,0V143.24h0c0-.75,0-1.48-.1-2.21A.49.49,0,0,1,511.58,140.9Z" />
      </g>
    </svg>
  );
}
