import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import useBrowsePaths from '../../hooks/useBrowsePaths';
import Container from '../../components/Container/Container';
import Title from '../../components/Title/Title';
import StatusLegend from '../../components/ContentStatus/StatusLegend';
import PathTable from '../../components/Path/PathTable';
import PageLoadingState from '../../components/Loading/PageLoadingState';
import { getOptions, handleEnroll } from '../../utils/pathsUtil';
import PathSubscribeButton from '../../components/PathSubscribeButton/PathSubscribeButton';

const BrowseCybrarySelectPaths = inject(
  'commonStore',
  'authStore',
  'userStore',
  'enrollmentStore'
)(
  observer(({ commonStore, authStore, userStore, enrollmentStore }) => {
    const { loading, error, paths, pathType, fetchPaths } = useBrowsePaths();
    const contentTypeId = paths && paths.length ? paths[0].content_type.id : null;
    const navigate = useNavigate();

    useEffect(() => {
      fetchPaths('cybrarySelect');
      commonStore.setPageTitle(`Cybrary Select | Cybrary`);
      authStore.fireAttributionEvent();
    }, []);

    return (
      <PageLoadingState loading={loading} error={error}>
        <Container size="lg">
          <Title title="Cybrary Select" />
          <p className="mb-6 max-w-150 text-sm text-gray-600">
            Cybrary Select content provides organizations with advanced content over and above whats present in the catalog today, focused on high-value skills in cybersecurity.
          </p>
          <div className="flex flex-col justify-between items-center w-full lg:flex-row">
            {contentTypeId && <PathSubscribeButton contentTypeId={contentTypeId} />}
            <div className="w-full lg:max-w-min">
              <StatusLegend hideNoContent />
            </div>
          </div>
          <PathTable
            paths={paths}
            pathType={pathType}
            userStore={userStore}
            commonStore={commonStore}
            enrollmentStore={enrollmentStore}
            navigate={navigate}
            getOptions={getOptions}
            handleEnroll={handleEnroll}
          />
        </Container>
      </PageLoadingState>
    );
  })
);

export default BrowseCybrarySelectPaths;
