/**
 * Provide a centralized place to do permalink modifications.
 * For instance, labs/tests need to be prefixed with /catalog/
 */
export default class PermalinkUtil {
  /**
   * Format the permalink for an in-app link.
   */
  static formatInApp(permalink, type) {
    let relativeHref = 'browse/';

    // Cybrary Live doesn't use the /browse/ prefix in-app, everything else does.
    if (['Cybrary Live Session', 'Cybrary Live Series'].indexOf(type) >= 0) {
      relativeHref = '';
    } else if (type === 'Career Path' && permalink.includes('career-path/')) {
      const contentName = permalink.split('career-path/')[1];
      return `${relativeHref}paths/career-paths/${contentName}`;
    } else if (type === 'Certification Prep' && permalink.includes('certification-prep/')) {
      const contentName = permalink.split('certification-prep/')[1];
      return `${relativeHref}paths/cert-prep-paths/${contentName}`;
    } else if (type === 'Threat Actor Path' || type === 'threat_actor_path') {
      relativeHref += 'threat-actor-campaigns/';
    }
    return `/${relativeHref}${permalink}`;
  }

  /**
   * Format the url from logged out to logged in version.
   */
  static formatLoggedOutUrl(url) {
    let res = url.replace('https://www.cybrary.it', '');
    res = res.replace('/catalog', '/browse');
    return res;
  }

  static formatImageTextUrl = (url, urlReplacement) => {
    const urlReplace = urlReplacement || '#';
    let res = url.replace('https://www.cybrary.it', '');
    if (res.includes('/register/')) {
      res = res.replace('/register/', urlReplace);
    } else if (res.includes('/login/')) {
      res = res.replace('/login/', urlReplace);
    }
    return res;
  };

  static formatDynamicPathUrl(url) {
    let res = url.replace(/https:\/\/www.cybrary.it\//g, 'https://app.cybrary.it/');
    const courseStr = /course\//g;
    const catalogStr = /catalog\//g;
    if (courseStr.test(res)) {
      res = res.replace(courseStr, 'browse/course/');
    }
    if (catalogStr.test(res)) {
      res = res.replace(catalogStr, 'browse/');
    }
    return res;
  }

  // Test the url to see if it is external (starts with https) or not
  static isExternalLink(url) {
    const isBackend = url.indexOf('/courses/api') || url.indexOf('/auth/api') || url.indexOf('/tics/api');
    const isExternal = url.indexOf('https:') === 0;

    return isExternal || isBackend;
  }

  // checks if permalink contains trailing slash. If so removes it, else return permalink
  static removeTrailingSlash(permalink) {
    if (/\/$/.test(permalink)) {
      return permalink.slice(0, -1);
    }

    return permalink;
  }

  static redirectToDestination(destination, navigate, callback) {
    if (callback) {
      callback();
    }
    if (/browse\/refined/.test(window.location.href)) {
      const port = window.location.port ? `:${window.location.port}` : ``;
      window.location.href = `${window.location.protocol}//${window.location.hostname}${port}${destination}`;
    } else {
      navigate(destination);
    }
  }
}
