import React from 'react';
import { components } from 'react-select';
import Icon from '../Icon/Icon';

function RemoveSelection(props) {
  const mouseDown = props.onMouseDown ? { onMouseDown: () => props.onMouseDown(props, props.innerProps.onClick) } : {};
  return (
    <components.MultiValueRemove {...props}>
      <button onClick={props.innerProps.onClick} {...mouseDown} aria-label={`Remove ${props.data.label}`} className="px-1" type="button">
        <Icon name="x" className="w-3 h-3" />
      </button>
    </components.MultiValueRemove>
  );
}

export default RemoveSelection;
