// Base config for all teams demo promos
const TEAMS_DEMO_PROMO_DEFAULT = {
  // Enable or disable the promo
  isEnabled: true,

  // Set the priority of the promo. Higher number = shown first.
  priority: 1,

  // Show the promo on specific page(s). Uses regex matching on the current pathname
  isShownOnPages: ['/enterprise/demo/'],

  // Show the promo based on the user's role/state
  isShownTeamLearner: true,
  isShownTeamAdmin: true,
  isShownTeamOwner: true,
  isShownCipFree: true,
  isShownCipMonthly: true,
  isShownCipAnnual: true,
  isShownCipBiennial: true,
  isShownCipEduGovMilFree: true,
  isShownCipEduGovMilMonthly: true,
  isShownCipEduGovMilAnnual: true,
  isShownCipEduGovMilBiennial: true,

  // Set Snowplow tracking labels
  spLabel: 'requestDemoButton',
  spCategory: 'TeamsDemoBanner',

  // Configure CTA Link
  targetUrl: 'https://www.cybrary.it/demo-request',

  // Configure the attributes of the banner
  title: 'Make this dashboard yours',
  titleClassName: 'text-2xl',
  body: "Learn how you can start managing your team's development",
  iconName: 'dashboard', // @see https://app.blackwaterbay.cybrary.it/icons for available icons
  iconClassName: 'group-hover:-rotate-0 scale-90 group-hover:scale-100',
  ctaButtonText: 'Request Demo',
};

// All teams demo promos with their own unique configurations / titles / icons / etc
const TEAMS_DEMO_PROMOS = {
  DEFAULT: TEAMS_DEMO_PROMO_DEFAULT,
  MEMBERS: {
    ...TEAMS_DEMO_PROMO_DEFAULT,
    title: 'Upgrade to start managing your team',
    isShownOnPages: ['/enterprise/demo/organization/members'],
    priority: 2,
    iconName: 'user-group',
  },
  PATHS: {
    ...TEAMS_DEMO_PROMO_DEFAULT,
    title: 'Upgrade to create custom learning paths',
    isShownOnPages: ['/enterprise/demo/paths'],
    priority: 2,
    iconName: 'road',
  },
  GOALS: {
    ...TEAMS_DEMO_PROMO_DEFAULT,
    title: 'Upgrade to set custom learning goals for your team',
    isShownOnPages: ['/enterprise/demo/goals'],
    priority: 2,
    iconName: 'bullseye',
  },
  REPORTING: {
    ...TEAMS_DEMO_PROMO_DEFAULT,
    title: 'Upgrade to track your teams progress',
    isShownOnPages: ['/enterprise/demo/reporting'],
    priority: 2,
    iconName: 'line-graph',
  },
  SETTINGS: {
    ...TEAMS_DEMO_PROMO_DEFAULT,
    title: 'Upgrade to configure your team',
    isShownOnPages: ['/enterprise/demo/settings'],
    priority: 2,
    iconName: 'wrench',
  },
  LOGS: {
    ...TEAMS_DEMO_PROMO_DEFAULT,
    title: 'Upgrade to access audit logs for your team',
    isShownOnPages: ['/enterprise/demo/logs'],
    priority: 2,
    iconName: 'list',
  },
};

export default TEAMS_DEMO_PROMOS;
