import React, { memo, useMemo, useCallback } from 'react';
import Title from '../Title/Title';
import If from '../If/If';
import { useCybAssessmentPath } from '../../providers/CybAssessmentPathProvider';
import { useCybAssessmentPathContent } from '../../providers/CybAssessmentPathContentProvider';
import Loading from '../Loading/Loading';

/**
 * `AssessmentPathHeader` - header used in AssessmentPathResults & AssessmentResults page.
 * Used in `AssessmentPathTabs`.
 * @param {string} orgId - team/organization id
 * @param {boolean} asAssessment - header to be used for `AssessmentResults` page
 * @returns JSX.Element
 */
function AssessmentPathHeader({ orgId, asAssessment }) {
  const { currentAssessmentPath } = useCybAssessmentPath();
  const { currentAssessment, currentAssessmentScoreDistributions, isLoadingAssessmentScoreDistributions } = useCybAssessmentPathContent();

  const content = useMemo(() => (asAssessment ? currentAssessment : currentAssessmentPath), [asAssessment, currentAssessmentPath, currentAssessment]);

  const crumbs = useMemo(() => {
    const crumbies = [
      {
        href: `/enterprise/${orgId}/paths/assessments`,
        label: 'Paths',
      },
    ];

    if (asAssessment) {
      const label = currentAssessmentPath ? currentAssessmentPath.title : 'Assessment Path';
      const { pathname } = window.location;
      const segments = pathname.split('/');
      // Remove the last segment, which the assessment id
      segments.pop();
      // reconstruct href
      const href = segments.join('/');

      crumbies.push({
        href,
        label,
      });
    }

    return crumbies;
  }, [orgId, asAssessment, content, currentAssessmentPath]);

  const averageAssessmentScore = useMemo(() => {
    if (!asAssessment || !currentAssessment || !currentAssessmentScoreDistributions || isLoadingAssessmentScoreDistributions) {
      return null;
    }

    // Flatten the assessment_attempts arrays and extract all scores
    const allScores = currentAssessmentScoreDistributions.default.flatMap((distribution) => distribution.assessment_attempts).map((attempt) => parseFloat(attempt.score));

    // Calculate the total sum of all scores
    const totalSum = allScores.reduce((sum, score) => sum + score, 0);

    // Calculate the average score
    const totalAverage = totalSum / allScores.length;

    return Math.round(totalAverage);
  }, [asAssessment, currentAssessment, currentAssessmentScoreDistributions, isLoadingAssessmentScoreDistributions]);

  const renderTags = useCallback((term) => {
    const split = term.split('|');
    const category = split[0].toLowerCase();
    const value = split[1];

    if (category !== 'domains' && category !== 'topics') {
      return null;
    }

    return (
      <div className="py-2 px-4 mr-3 mb-3 bg-zinc-600" key={term}>
        <span className="text-sm text-white">{value}</span>
      </div>
    );
  }, []);

  return (
    <div className="flex flex-row justify-between">
      <div className="pr-4">
        <Title title={content.title} breadcrumbs={crumbs} wrapperClasses="pt-8" classes="translate-y-[-25px]" omitPadding />
        <p className="m-0 text-gray-600 text-md">Review your team&apos;s results for this assessment{asAssessment ? '' : ' path'}.</p>
        <If condition={content.terms_info?.length > 0}>
          <div className="flex flex-row flex-wrap items-center mt-5">{content.terms_info?.map(renderTags)}</div>
        </If>
      </div>
      <If condition={asAssessment}>
        <div className="flex flex-col justify-center items-center">
          <div className="flex flex-col justify-center items-center w-64 h-52 border-1 border-slate-300">
            <div className="flex flex-col justify-center items-center mb-5 h-10 text-[60px] font-thin">{averageAssessmentScore ? `${averageAssessmentScore}%` : <Loading />}</div>
            <span className="text-xs font-bold uppercase">Average Score</span>
          </div>
        </div>
      </If>
    </div>
  );
}

export default memo(AssessmentPathHeader);
