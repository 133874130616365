import React, { useMemo, useEffect, useCallback } from 'react';
import AdminTable from '../../AdminTable/AdminTable';
import AddLink from '../../AddLink/AddLink';
import { useCybMeasure } from '../../../providers/CybMeasureProvider';
import { formatTableColumns } from '../../Baseline/shared';
import BasicTabs from '../../Tabs/BasicTabs';
import { useCybAssessmentAnalytics } from '../../../providers/CybAssessmentAnalyticsProvider';

function getPathContentSummaryLink(pathId, tracks, column, fullRow, orgId, enrollmentId) {
  const fullPaths = Object.values(tracks ?? {});
  const fullPath = fullPaths?.[0]?.find((path) => path['content-id'] === pathId);
  const pathTopic = fullPath?.children.find((topic) => topic['content-name'] === column.display);
  const topicId = pathTopic?.['content-id'];
  const learnerId = fullRow[0].value;
  const learnerName = fullRow[1].value;

  const enrollmentParam = enrollmentId ? `&enrollmentId=${enrollmentId}` : '';

  return `${window.location.pathname}/paths/assessments/${pathId}/${topicId}/summary?teamId=${orgId}&learnerId=${learnerId}&learnerName=${learnerName}${enrollmentParam}`;
}

const RESULTS_PER_PAGE = 25;

function PreAssessmentTable({ orgId, goalId }) {
  const { tracks } = useCybAssessmentAnalytics();

  const { teamBaselineGoalDetails, preAssessmentGoalDetails, isLoadingPreAssessmentGoalDetails, getPreAssessmentGoalProgress } = useCybMeasure();

  const baselineGoal = useMemo(() => teamBaselineGoalDetails[`${orgId}_${goalId}`], [orgId, goalId, teamBaselineGoalDetails]);

  const preAssessmentDetails = useMemo(() => preAssessmentGoalDetails[`${orgId}_${goalId}`], [orgId, goalId, preAssessmentGoalDetails]);

  const renderColumn = useCallback(
    (row, fullRow, _headings, column, index) => {
      if (row.value !== undefined && column.showCol) {
        const percentage = Math.round(parseFloat(row.value));

        if (row.value === null) {
          if (fullRow[1].value === 'Average') {
            return <span className="text-gray-500">—</span>;
          }
          return <i>incomplete</i>;
        }

        if (column.key !== 'path_score' && fullRow[1].value !== 'Average') {
          const enrollmentId = fullRow[index - 1].value;
          const href = getPathContentSummaryLink(baselineGoal?.content_description_id, tracks, column, fullRow, orgId, enrollmentId);

          return (
            <AddLink className="text-cyb-pink-500 hover:underline" to={href} title="View results">
              {percentage}%
            </AddLink>
          );
        }

        return `${percentage}%`;
      }

      return '';
    },
    [window.location.pathname, baselineGoal?.content_description_id, tracks, orgId]
  );

  const formatColumns = useMemo(
    () =>
      formatTableColumns(
        preAssessmentDetails?.columns,
        preAssessmentDetails?.columns.filter((col) => col.key !== 'name').map((column) => column.key),
        renderColumn
      ),
    [renderColumn, preAssessmentDetails]
  );

  const tableData = useMemo(() => {
    const tableDataCopy = preAssessmentDetails?.tableData?.concat();

    // find index of `Average` row
    const index = tableDataCopy?.findIndex((rowArray) => rowArray.find((item) => item.value === 'Average'));

    if (index >= 0) {
      const hasHighlightedRow = tableDataCopy[index].find((entry) => entry.highlighted_row);

      if (!hasHighlightedRow) {
        // mark row as `highlighted_row`
        const highlightedRowEntry = {
          highlighted_row: true,
        };

        tableDataCopy[index].push(highlightedRowEntry);
      }
    }

    return tableDataCopy;
  }, [preAssessmentDetails]);

  const ignoredCols = useMemo(() => {
    const value = [];

    if (tableData) {
      // get the 'Average' row, which should be the last row
      const averageRow = tableData[tableData.length - 1];

      // find the index of the `highlighted_row` entry for that row
      const highlightedRowEntryIndex = averageRow?.findIndex((item) => item?.highlighted_row);

      if (highlightedRowEntryIndex >= 0) {
        value.push(highlightedRowEntryIndex);
      }
    }

    return value;
  }, [tableData]);

  const pagPagesCount = Math.ceil((preAssessmentDetails?.totalRecords ?? 0) / RESULTS_PER_PAGE);

  const onPageChange = useCallback(
    (page) => {
      const query = `?activePg=${page}&resultsPerPage=${RESULTS_PER_PAGE}`;

      getPreAssessmentGoalProgress(orgId, goalId, query);
    },
    [orgId, goalId]
  );

  useEffect(() => {
    if (!preAssessmentDetails && !isLoadingPreAssessmentGoalDetails) {
      getPreAssessmentGoalProgress(orgId, goalId);
    }
  }, [preAssessmentDetails, isLoadingPreAssessmentGoalDetails]);

  return (
    <AdminTable
      wrapperClassName="m-0 w-full"
      colClasses="whitespace-nowrap"
      headings={preAssessmentDetails?.columns}
      data={tableData}
      tableLoading={isLoadingPreAssessmentGoalDetails}
      colWidths={[
        { width: '20%', idx: 1 },
        { width: '12%', idx: 2 },
        { width: '18%', idx: 3 },
      ]}
      formatColumns={formatColumns}
      ignoredCols={ignoredCols}
      pagNumPgs={pagPagesCount > 1 ? pagPagesCount : null}
      pagChangePg={onPageChange}
      pagActivePg={preAssessmentDetails?.activePg}
      numOfResults={preAssessmentDetails?.totalRecords}
    />
  );
}

function RemediationTable({ orgId, goalId }) {
  const { tracks } = useCybAssessmentAnalytics();

  const { teamBaselineGoalDetails, remediationGoalDetails, isLoadingRemediationGoalDetails, getRemediationGoalProgress } = useCybMeasure();

  const baselineGoal = useMemo(() => teamBaselineGoalDetails[`${orgId}_${goalId}`], [orgId, goalId, teamBaselineGoalDetails]);

  const remediationDetails = useMemo(() => remediationGoalDetails[`${orgId}_${goalId}`], [orgId, goalId, remediationGoalDetails]);

  const renderColumn = useCallback(
    (row, fullRow, headings, column) => {
      if (row.value !== undefined && column.showCol) {
        const percentage = Math.round(parseFloat(row.value));

        if (row.value === null || (fullRow[1].value === 'Average' && column.key !== 'path_progress')) {
          return <span className="text-gray-500">—</span>;
        }

        if (column.key.includes('_completed') && fullRow[1].value !== 'Average') {
          // construct display value
          const columnIndex = headings.findIndex((heading) => heading.key === column.key);
          const topicTotalColumnIndex = columnIndex + 1;
          const displayValue = `${row.value}/${fullRow[topicTotalColumnIndex].value} courses`;
          if (row.value === 0 && fullRow[topicTotalColumnIndex].value === 0) {
            // Just show the text since this would be 0/0 courses
            return displayValue;
          }

          // construct href
          const href = getPathContentSummaryLink(baselineGoal?.content_description_id, tracks, column, fullRow, orgId);

          return (
            <AddLink className="text-cyb-pink-500 hover:underline" to={href} title="View results">
              {displayValue}
            </AddLink>
          );
        }

        return `${percentage}%`;
      }

      return '';
    },
    [window.location.pathname, baselineGoal?.content_description_id, remediationDetails?.topics, orgId]
  );

  const formatColumns = useMemo(
    () =>
      formatTableColumns(
        remediationDetails?.columns,
        remediationDetails?.columns.filter((col) => col.key !== 'name').map((column) => column.key),
        renderColumn
      ),
    [renderColumn, remediationDetails]
  );

  const tableData = useMemo(() => {
    const tableDataCopy = remediationDetails?.tableData?.concat();

    // find index of `Average` row
    const index = tableDataCopy?.findIndex((rowArray) => rowArray.find((item) => item.value === 'Average'));

    if (index >= 0) {
      const hasHighlightedRow = tableDataCopy[index].find((entry) => entry.highlighted_row);

      if (!hasHighlightedRow) {
        // mark row as `highlighted_row`
        const highlightedRowEntry = {
          highlighted_row: true,
        };

        tableDataCopy[index].push(highlightedRowEntry);
      }
    }

    return tableDataCopy;
  }, [remediationDetails]);

  const ignoredCols = useMemo(() => {
    const value = [];

    if (tableData) {
      // get the 'Average' row, which should be the last row
      const averageRow = tableData[tableData.length - 1];

      // find the index of the `highlighted_row` entry for that row
      const highlightedRowEntryIndex = averageRow?.findIndex((item) => item?.highlighted_row);

      if (highlightedRowEntryIndex >= 0) {
        value.push(highlightedRowEntryIndex);
      }
    }

    return value;
  }, [tableData]);

  const pagPagesCount = Math.ceil((remediationDetails?.totalRecords ?? 0) / RESULTS_PER_PAGE);

  const onPageChange = useCallback(
    (page) => {
      const query = `?activePg=${page}&resultsPerPage=${RESULTS_PER_PAGE}`;

      getRemediationGoalProgress(orgId, goalId, query);
    },
    [orgId, goalId]
  );

  useEffect(() => {
    if (!remediationDetails && !isLoadingRemediationGoalDetails) {
      getRemediationGoalProgress(orgId, goalId);
    }
  }, [remediationDetails, isLoadingRemediationGoalDetails]);

  return (
    <AdminTable
      wrapperClassName="m-0 w-full"
      colClasses="whitespace-nowrap"
      headings={remediationDetails?.columns}
      data={tableData}
      tableLoading={isLoadingRemediationGoalDetails}
      colWidths={[
        { width: '20%', idx: 1 },
        { width: '12%', idx: 2 },
        { width: '18%', idx: 3 },
      ]}
      formatColumns={formatColumns}
      ignoredCols={ignoredCols}
      pagNumPgs={pagPagesCount > 1 ? pagPagesCount : null}
      pagChangePg={onPageChange}
      pagActivePg={remediationDetails?.activePg}
      numOfResults={remediationDetails?.totalRecords}
    />
  );
}

function PostAssessmentTable({ orgId, goalId }) {
  const { tracks } = useCybAssessmentAnalytics();

  const { teamBaselineGoalDetails, postAssessmentGoalDetails, isLoadingPostAssessmentGoalDetails, getPostAssessmentGoalProgress } = useCybMeasure();

  const baselineGoal = useMemo(() => teamBaselineGoalDetails[`${orgId}_${goalId}`], [orgId, goalId, teamBaselineGoalDetails]);

  const postAssessmentDetails = useMemo(() => postAssessmentGoalDetails[`${orgId}_${goalId}`], [orgId, goalId, postAssessmentGoalDetails]);

  const renderColumn = useCallback(
    (row, fullRow, headings, column, index) => {
      if (row.value !== undefined && column.showCol) {
        const percentage = Math.round(parseFloat(row.value));

        if (row.value === null) {
          if (fullRow[1].value === 'Average') {
            return <span className="text-gray-500">—</span>;
          }
          return <i>incomplete</i>;
        }

        if (column.key.includes('_score') && fullRow[1].value !== 'Average') {
          // construct display value
          const enrollmentId = fullRow[index - 1].value;
          const topicDifferenceColumnIndex = index + 2;
          const differencePercentage = Math.round(parseFloat(fullRow[topicDifferenceColumnIndex].value));
          const sign = differencePercentage < 0 ? '-' : '+';
          const displayValue = `${percentage}% (${sign}${Math.abs(differencePercentage)}%)`;

          // construct href
          const href = getPathContentSummaryLink(baselineGoal?.content_description_id, tracks, column, fullRow, orgId, enrollmentId);

          return (
            <AddLink className="text-cyb-pink-500 hover:underline" to={href} title="View results">
              {displayValue}
            </AddLink>
          );
        }

        return `${percentage}%`;
      }

      return '';
    },
    [window.location.pathname, baselineGoal?.content_description_id, tracks, orgId]
  );

  const formatColumns = useMemo(
    () =>
      formatTableColumns(
        postAssessmentDetails?.columns,
        postAssessmentDetails?.columns.filter((col) => col.key !== 'name').map((column) => column.key),
        renderColumn
      ),
    [renderColumn, postAssessmentDetails]
  );

  const tableData = useMemo(() => {
    const tableDataCopy = postAssessmentDetails?.tableData?.concat();

    // find index of `Average` row
    const index = tableDataCopy?.findIndex((rowArray) => rowArray.find((item) => item.value === 'Average'));

    if (index >= 0) {
      const hasHighlightedRow = tableDataCopy[index].find((entry) => entry.highlighted_row);

      if (!hasHighlightedRow) {
        // mark row as `highlighted_row`
        const highlightedRowEntry = {
          highlighted_row: true,
        };

        tableDataCopy[index].push(highlightedRowEntry);
      }
    }

    return tableDataCopy;
  }, [postAssessmentDetails]);

  const ignoredCols = useMemo(() => {
    const value = [];

    if (tableData) {
      // get the 'Average' row, which should be the last row
      const averageRow = tableData[tableData.length - 1];

      // find the index of the `highlighted_row` entry for that row
      const highlightedRowEntryIndex = averageRow?.findIndex((item) => item?.highlighted_row);

      if (highlightedRowEntryIndex >= 0) {
        value.push(highlightedRowEntryIndex);
      }
    }

    return value;
  }, [tableData]);

  const pagPagesCount = Math.ceil((postAssessmentDetails?.totalRecords ?? 0) / RESULTS_PER_PAGE);

  const onPageChange = useCallback(
    (page) => {
      const query = `?activePg=${page}&resultsPerPage=${RESULTS_PER_PAGE}`;

      getPostAssessmentGoalProgress(orgId, goalId, query);
    },
    [orgId, goalId]
  );

  useEffect(() => {
    if (!postAssessmentDetails && !isLoadingPostAssessmentGoalDetails) {
      getPostAssessmentGoalProgress(orgId, goalId);
    }
  }, [postAssessmentDetails, isLoadingPostAssessmentGoalDetails]);

  return (
    <AdminTable
      wrapperClassName="m-0 w-full"
      colClasses="whitespace-nowrap"
      headings={postAssessmentDetails?.columns}
      data={tableData}
      tableLoading={isLoadingPostAssessmentGoalDetails}
      colWidths={[
        { width: '18%', idx: 1 },
        { width: '12%', idx: 2 },
        { width: '25%', idx: 3 },
      ]}
      formatColumns={formatColumns}
      ignoredCols={ignoredCols}
      pagNumPgs={pagPagesCount > 1 ? pagPagesCount : null}
      pagChangePg={onPageChange}
      pagActivePg={postAssessmentDetails?.activePg}
      numOfResults={postAssessmentDetails?.totalRecords}
    />
  );
}

function TeamBaselineGoalTabs({ orgId, goalId }) {
  const tabs = useMemo(
    () => [
      {
        id: 1,
        title: 'Pre-Assessment',
        content: <PreAssessmentTable orgId={orgId} goalId={goalId} />,
      },
      {
        id: 2,
        title: 'Remediation',
        content: <RemediationTable orgId={orgId} goalId={goalId} />,
      },
      {
        id: 3,
        title: 'Post-Assessment',
        content: <PostAssessmentTable orgId={orgId} goalId={goalId} />,
      },
    ],
    [orgId, goalId]
  );

  return <BasicTabs tabs={tabs} />;
}

export default TeamBaselineGoalTabs;
