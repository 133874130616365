import React from 'react';
import { inject, observer } from 'mobx-react';
import Header from '../Header/Header';
import Icon from '../Icon/Icon';
import Segment from '../Segment/Segment';
import AddLink from '../AddLink/AddLink';
import StyleUtil from '../../utils/styleUtil';
import UpgradeButton from '../UpgradeButton/UpgradeButton';
import Container from '../Container/Container';
import './StyledError.css';
import { HELP_DESK_LINKS } from '../../constants';

/**
 * Error component that displays a consistently presented error message when mounted.
 */
const StyledError = inject('userStore')(
  observer(({ error, location, contentType, userStore }) => {
    if (error.status === 404) {
      // content not found
      return (
        <div className="flex relative justify-center items-center w-full bg-white" style={{ height: 'calc(100vh - 77.25px)' }}>
          <div className="absolute z-0 text-12xl font-black text-gray-200 md:text-16xl lg:text-20xl">404</div>
          <div className="z-50 px-4 text-center lg:px-0">
            <h1 className="mb-6 text-3xl font-black">Content Not Found</h1>
            <p className="text-gray-600">We know, you were so excited and now you see nothing...</p>
            <p className="text-gray-600">Not even a clever image that would make this page semi-entertaining...</p>
          </div>
        </div>
      );
    }
    if (error.status === 403) {
      // unauthorized
      if (location === '/immersive') {
        return (
          <div className="styled-error">
            <div className="flex justify-center items-center text-white bg-black upgrade-paywall">
              <Container>
                <div className="lg:grid lg:grid-cols-12 xl:px-60">
                  <div className="lg:col-span-6">
                    <img src={StyleUtil.getTeaserImage(contentType)} alt="" style={{ margin: 'auto' }} />
                  </div>
                  <div className="lg:col-span-6">
                    <div className="p-4 text-left">
                      <Header as="h3" className="text-white">
                        Upgrade your account to access this {`${contentType}`} activity
                      </Header>
                      <div style={{ color: '#fff', fontSize: '16px' }}>
                        Unlock access to Cybrary&apos;s entire library of content including virtual labs, assessments, and premium courses by Upgrading to Cybrary Insider Pro or
                        Cybrary for Teams.
                      </div>

                      <div style={{ display: 'flex', margin: '1em 0' }}>
                        <UpgradeButton upgradeText="Upgrade" upgradeLinkParams="?appimv=upgradebtn" />
                      </div>
                    </div>
                  </div>
                </div>
              </Container>
            </div>
          </div>
        );
      }
      return (
        <div className="styled-error">
          <Segment basic className="text-center">
            <Icon name="face-frown" className="mx-auto mb-4 w-20 h-20 text-gray-600" />
            <p>Sorry! You don&apos;t have permission to access this.</p>
            {userStore.isFree && !userStore.isUserOnActiveTeam ? (
              <p>
                <UpgradeButton upgradeText="Upgrade" upgradeLinkParams="?appimv=upgradebtn" />
              </p>
            ) : null}
            <p>
              Go back{' '}
              <AddLink to="/">
                <Icon name="home" color="black" inverted />
                home
              </AddLink>{' '}
              or check out{' '}
              <AddLink to={HELP_DESK_LINKS.HOMEPAGE}>
                <Icon name="question circle outline" color="black" inverted />
                support
              </AddLink>
              .
            </p>
          </Segment>
        </div>
      );
    }
    if (error.status === 500 && location === '/immersive') {
      return (
        // show error for content that has been removed by vendor on immersive page
        <div className="styled-error">
          <div className="immersive-error-container">
            <div>
              <Header as="h3" className="immersive-error-header">
                Oops! Sorry...
              </Header>
              <p className="immersive-error-content">This content is currently unavailable. Please try again or contact support for assistance.</p>
            </div>
          </div>
        </div>
      );
    }

    // catch all error, server error
    return (
      <div className="styled-error">
        <Segment className={`border-none text-center ${location === '/immersive' ? 'immersive-error-container' : ''}`}>
          <div>
            <Icon name="face-frown" className="mx-auto mb-4 w-20 h-20 text-gray-600" />
            <p className={location === '/immersive' ? 'immersive-error-content' : ''}>Oops! Something went wrong.</p>
            <p className={location === '/immersive' ? 'immersive-error-content' : ''}>
              <Icon name="redo" size="small" color="black" inverted />
              Try refreshing the page.
            </p>
          </div>
        </Segment>
      </div>
    );
  })
);

export default StyledError;
