import React from 'react';

export default function License({ classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';
  return (
    <svg className={className} {...accessibilityProps} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 80.79" fill="currentColor" stroke="currentColor">
      <g>
        <path
          style={{ fillRule: 'evenodd', clipRule: 'evenodd' }}
          d="M41.66,45.93c2.36,2.04,9.12,2.46,11.64,3.87c0.8,0.45,1.53,1.02,2.12,1.79c1.45,1.91,1.56,4.35,2.11,6.68 
          c-0.13,1.38-0.91,2.17-2.44,2.28h-16.2c0.01-0.37,0.02-0.73,0.02-1.09l-2.33-8.1l1.14-1.25c0.37-0.57,0.53-1.12,0.4-1.64 
          c-0.39-1.57-1.94-1.33-3.2-1.33c-1.2,0-2.48-0.23-3.1,1c-0.29,0.57-0.17,1.22,0.34,1.98l1.14,1.25l-2.33,8.1 
          c0.05,0.37,0.1,0.73,0.15,1.09H15.34c-1.53-0.12-2.31-0.91-2.44-2.28c0.55-2.33,0.66-4.77,2.11-6.68c0.59-0.77,1.31-1.34,2.12-1.79 
          c2.48-1.39,9.06-1.82,11.52-3.77c0.37-0.81,0.75-1.95,0.99-2.67c0.01-0.04-0.02,0.06,0.1-0.3c0.27-0.82-1.35-2.42-1.89-3.29 
          l-2.02-3.21c-0.74-1.1-1.12-2.11-1.14-2.93c-0.01-0.39,0.05-0.74,0.2-1.05c0.15-0.32,0.38-0.6,0.69-0.81 
          c0.15-0.1,0.31-0.18,0.49-0.25c-0.13-1.74-0.18-3.93-0.1-5.76c0.04-0.44,0.13-0.87,0.25-1.31c0.74-2.64,3.01-4.54,5.59-5.43 
          c1.26-0.43,0.77-1.47,2.04-1.4c3.01,0.16,7.65,2.1,9.44,4.16c2.5,2.88,1.86,6.43,1.77,9.95v0c0.56,0.17,0.92,0.52,1.06,1.1 
          c0.16,0.64-0.01,1.53-0.55,2.75l0,0c-0.01,0.02-0.02,0.04-0.03,0.07l-2.3,3.78c-0.89,1.46-1.78,2.92-2.98,4.04 
          c-0.06,0.05-0.12,0.11-0.18,0.16c0.17,0.24,0.38,0.54,0.59,0.86C40.98,44.97,41.32,45.47,41.66,45.93L41.66,45.93z M7.12,0h108.65 
          c1.96,0,3.74,0.8,5.03,2.09l0.15,0.16c1.2,1.28,1.94,2.99,1.94,4.86v66.56c0,1.95-0.8,3.73-2.09,5.02l0,0l0,0 
          c-1.29,1.29-3.07,2.09-5.03,2.09H7.12c-1.95,0-3.73-0.8-5.02-2.09l0,0C0.8,77.42,0,75.64,0,73.68V7.12c0-1.95,0.8-3.73,2.09-5.02 
          l0,0C3.38,0.8,5.16,0,7.12,0L7.12,0z M68.13,58.23c-0.98,0-1.77-1.06-1.77-2.36s0.79-2.36,1.77-2.36h39.66 
          c0.98,0,1.77,1.06,1.77,2.36s-0.79,2.36-1.77,2.36H68.13L68.13,58.23z M68.13,43.98c-0.98,0-1.77-1.06-1.77-2.36 
          s0.79-2.36,1.77-2.36h37.81c0.98,0,1.77,1.06,1.77,2.36s-0.79,2.36-1.77,2.36H68.13L68.13,43.98z M68.13,29.73 
          c-0.98,0-1.77-1.06-1.77-2.36c0-1.3,0.79-2.36,1.77-2.36h25.33c0.98,0,1.77,1.06,1.77,2.36c0,1.3-0.79,2.36-1.77,2.36H68.13 
          L68.13,29.73z M115.76,4.72H7.12c-0.66,0-1.25,0.27-1.69,0.7l0,0l0,0c-0.43,0.43-0.7,1.03-0.7,1.69v66.56 
          c0,0.65,0.27,1.25,0.7,1.69l0,0l0,0c0.43,0.43,1.03,0.7,1.69,0.7h108.65c0.65,0,1.25-0.27,1.69-0.71l0,0 
          c0.43-0.43,0.7-1.03,0.7-1.69V7.12c0-0.62-0.23-1.18-0.61-1.6l-0.09-0.09C117.02,5,116.42,4.72,115.76,4.72L115.76,4.72z"
        />
      </g>
    </svg>
  );
}
