import { inject, observer } from 'mobx-react';
import React from 'react';
import Container from '../../components/Container/Container';
import withRouter from '../../components/Router/withRouter';
import SearchGrid from '../../components/SearchGrid/SearchGrid';
import Title from '../../components/Title/Title';

const CybraryChallenges = inject()(
  observer(() => {
    return (
      <Container size="card-container-v2">
        <Title title="Cybrary Challenges" omitPadding wrapperClasses="pt-8 md:pt-12 mb-4" />
        <p className="mb-0 max-w-150 text-sm text-gray-600">Exercise your problem-solving and creative thinking skills with security-centric puzzles.</p>
        <SearchGrid tags={[`Cybrary Challenge`]} />
      </Container>
    );
  })
);

export default withRouter(CybraryChallenges);
