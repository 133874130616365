import React, { lazy, Suspense } from 'react';
import { isObservableArray } from 'mobx';
import CountUp from 'react-countup';
import Loading from '../Loading/Loading';
import Segment from '../Segment/Segment';
import StyledError from '../Error/StyledError';
import NoResultsMessage from '../NoResultsMessage/NoResultsMessage';
import FormatUtil from '../../utils/formatUtil';
import TimeUtil from '../../utils/timeUtil';
import Container from '../Container/Container';
import If from '../If/If';
import './analytics.css';

const Doughnut = lazy(() => import('./Chart/Doughnut'));
const HorizontalBar = lazy(() => import('./Chart/HorizontalBar'));
const Pie = lazy(() => import('./Chart/Pie'));
const Bar = lazy(() => import('./Chart/Bar'));
const Line = lazy(() => import('./Chart/Line'));

function LoadingContainer() {
  return <Loading message="Loading..." wrapperClassName="py-6" messageClassName="text-lg" />;
}

class StatSection extends React.Component {
  getLabel = (label) => {
    let labelText = label;
    if (typeof label !== 'string') {
      if (!!label && label.title) {
        const labelString = label.title.split('_').join(' ');
        labelText = labelString.charAt(0).toUpperCase() + labelString.substring(1);
      } else if (!!label && label.label) {
        const labelString = label.label.split('_').join(' ');
        labelText = labelString.charAt(0).toUpperCase() + labelString.substring(1);
      } else if (!!label && label.period) {
        labelText = label.period;
        labelText = TimeUtil.getMetricIntervalLabel(label.period, this.props.interval);
      }
    } else {
      labelText = FormatUtil.getMetricLabel(label);
    }

    return labelText;
  };

  getOptionColor = (type, idx, colors) => {
    return type === 'mixed' && idx !== null ? colors[idx] : colors;
  };

  getDataSet = (idx, label, type) => {
    const isDashed = this.props.filters?.secondary === label;
    // If its mixed data, it's likely a full width component - Don't need to split the labels, so second param would be false
    return {
      label: this.getLabel(label, this.props.type !== 'mixed', type),
      type,
      fill: false,
      backgroundColor: this.props.backgroundColor ? this.props.backgroundColor : this.getOptionColor(this.props.type, idx, this.props.primaryColors),
      borderColor: this.props.borderColor ? this.props.borderColor : this.getOptionColor(this.props.type, idx, this.props.primaryColors),
      borderWidth: this.props.borderWidth ? this.props.borderWidth : 1,
      borderDash: isDashed ? [5] : undefined,
      data: [],
      maxBarThickness: 30,
      lineTension: 0,
      pointRadius: type === 'scatter' ? 4 : 2,
    };
  };

  getDataSets = (data) => {
    const retObj = {
      labels: [],
      datasets: [],
    };

    const dataArr = [];
    // If it's a mixed type, we need the each metric provided to be its own data set
    if (this.props.type === 'mixed') {
      Object.keys(data).forEach((key) => {
        const set = {};
        set[key] = data[key];
        dataArr.push(set);
      });
    } else {
      dataArr.push(data);
    }

    dataArr.forEach((set, idx) => {
      const metrics = Object.keys(set);
      // If it's not a mixed type, it doesn't need type defined here (the component is the type)
      const type = this.props.type === 'mixed' && !!this.props.mixedTypes && !!this.props.mixedTypes[idx] ? this.props.mixedTypes[idx] : '';
      let label = metrics[0];
      if (this.props.type !== 'mixed') {
        if (this.props.xAxisLabel) {
          label = this.props.xAxisLabel;
        } else if (this.props.yAxisLabel) {
          label = this.props.yAxisLabel;
        }
      }

      const dataset = this.getDataSet(idx, label, type);

      metrics.forEach((metric) => {
        const isArray = isObservableArray(set[metric]) || Array.isArray(set[metric]);
        if (isArray) {
          set[metric].forEach((item) => {
            if (idx === 0) {
              // Only need one set of labels -- Assumes that each item has the same labels
              retObj.labels.push(this.getLabel(item, this.props.type !== 'mixed', type));
            }
            dataset.data.push(item.count);
          });
        } else {
          retObj.labels.push(this.getLabel(metric, this.props.type !== 'mixed', type));
          dataset.data.push(set[metric].count);
        }
      });
      retObj.datasets.push(dataset);
    });

    return retObj;
  };

  formatAxisLabels = (value) => {
    if (typeof value === 'number') {
      const parsedNumber = FormatUtil.formatNumbers(value); // Add commas numbers

      if (this.props.yAxisFormat === 'percentage') {
        return `${parsedNumber}%`;
      }
      return parsedNumber;
    }
    return FormatUtil.formatLongText(value, 20); // Truncate dataset label
  };

  getOptions = (type) => {
    const options = {
      animation: {
        duration: this.props.animationDuration ? this.props.animationDuration : 1000,
        easing: 'linear',
      },
      responsive: true,
      maintainAspectRatio: false,
    };

    if (type === 'horizontal-bar' || type === 'bar' || type === 'mixed') {
      options.scales = {
        xAxes: [
          {
            scaleLabel: {
              display: !!this.props.xAxisLabel,
              labelString: this.props.xAxisLabel,
            },
            ticks: {
              beginAtZero: true,
              callback: (value) => this.formatAxisLabels(value),
            },
          },
        ],
        yAxes: [
          {
            scaleLabel: {
              display: !!this.props.yAxisLabel,
              labelString: this.props.yAxisLabel,
            },
            ticks: {
              beginAtZero: true,
              precision: 0,
              stepSize: this.props.yAxisStepSize,
              callback: (value) => this.formatAxisLabels(value),
              min: this.props.yAxisMin,
              max: this.props.yAxisMax,
            },
          },
        ],
      };

      if (this.props.onClick) {
        options.onClick = this.props.onClick;
      }
    }

    if (type === 'line') {
      options.scales = {
        xAxes: [
          {
            scaleLabel: {
              display: !!this.props.xAxisLabel,
              labelString: this.props.xAxisLabel,
            },
            ticks: {
              callback: (value) => this.formatAxisLabels(value),
            },
          },
        ],
        yAxes: [
          {
            scaleLabel: {
              display: !!this.props.yAxisLabel,
              labelString: this.props.yAxisLabel,
            },
            ticks: {
              precision: 0,
              callback: (value) => this.formatAxisLabels(value),
            },
          },
        ],
      };
    }

    return options;
  };

  getStatisticSize = (compact, topMetric) => {
    const number = topMetric.average_score || topMetric.count;
    if (!!compact || (number && number.toString().length > 6)) {
      return 'small';
    }
    if (number && number.toString().length > 4) {
      return 'medium';
    }

    return 'large';
  };

  getContent = (data, type, height, containerClasses = 'p-4') => {
    const { compact, mixedTypes, showLegend, labelWidth, legendPosition, topStatSuffix, displayTotal, statMeta } = this.props;
    const options = this.getOptions(type);
    const dataSets = this.getDataSets(data);

    const legendOpts = {
      display: showLegend !== false,
      position: legendPosition ?? 'bottom',
      labels: {
        boxWidth: labelWidth ?? 15,
      },
    };

    if (type === 'summary') {
      const metrics = Object.keys(data);
      const topMetric = data[metrics[0]];
      const statisticSize = this.getStatisticSize(compact, topMetric);
      let className = `summary-container ${statisticSize}`;
      if (compact) {
        className += ' compact';
      }
      if (metrics.length === 1) {
        className += ' single-stat';
      }
      return (
        <div className={className}>
          <div className="flex flex-col justify-center items-center mb-4">
            <p className="mb-0 text-xl-16 font-thin">
              <CountUp separator="," end={1 * (topMetric.count || topMetric.average_score || 0)} duration={3} suffix={topStatSuffix || ''} />
            </p>
            <p className="text-xs font-semibold uppercase text-grey-600">{FormatUtil.getMetricLabel(metrics[0])}</p>
          </div>
          <div className="substats">
            {metrics.map((item, idx) => {
              if (idx === 0 || (!data[item].count && !data[item].average_score)) return null;
              return (
                <div key={item} className="flex print:flex-col justify-center print:items-center items-end">
                  <p className="mb-0 text-3xl font-normal">
                    <CountUp separator="," end={1 * (data[item].count || data[item].average_score)} duration={3} />
                  </p>
                  <p className="pb-1 ml-2 text-base font-normal text-gray-600 normal-case">{FormatUtil.getMetricLabel(item)}</p>
                </div>
              );
            })}
          </div>
        </div>
      );
    }
    if (type === 'doughnut') {
      return (
        <div className="doughnut-container">
          <div className={containerClasses}>
            <Suspense fallback={<LoadingContainer />}>
              <Doughnut legend={legendOpts} data={dataSets} options={options} />
            </Suspense>
          </div>
          <If condition={!!displayTotal}>
            <p className="text-gray-500"> Total: {FormatUtil.getItemsCount(data)}</p>
          </If>
          <If condition={!!statMeta}>
            <p className="text-gray-500"> {statMeta}</p>
          </If>
        </div>
      );
    }
    if (type === 'bar') {
      return (
        <div className={`bar-container ${containerClasses}`}>
          <Suspense fallback={<LoadingContainer />}>
            <Bar legend={legendOpts} data={dataSets} options={options} />
          </Suspense>
        </div>
      );
    }
    if (type === 'line') {
      return (
        <div className={`line-container ${containerClasses}`}>
          <Suspense fallback={<LoadingContainer />}>
            <Line legend={legendOpts} data={dataSets} options={options} />
          </Suspense>
        </div>
      );
    }
    if (type === 'pie') {
      return (
        <div className={`pie-container ${containerClasses}`}>
          <Suspense fallback={<LoadingContainer />}>
            <Pie legend={legendOpts} data={dataSets} options={options} />
          </Suspense>
        </div>
      );
    }
    if (type === 'horizontal-bar') {
      return (
        <div className={`hor-bar-container ${containerClasses}`}>
          <Suspense fallback={<LoadingContainer />}>
            <HorizontalBar legend={legendOpts} data={dataSets} options={options} />
          </Suspense>
        </div>
      );
    }
    if (type === 'mixed') {
      return (
        <div className={`mixed-display-container ${containerClasses}`}>
          <Suspense fallback={<LoadingContainer />}>
            <If condition={mixedTypes.includes('bar')}>
              <Bar legend={legendOpts} data={dataSets} height={height} options={options} />
            </If>
            <If condition={!mixedTypes.includes('bar')}>
              <Line legend={legendOpts} data={dataSets} height={height} options={options} />
            </If>
          </Suspense>
        </div>
      );
    }
    return null;
  };

  checkIfEmpty = (data) => {
    let isEmpty = true;
    if (!data || data === 'null') {
      return true;
    }
    const dataArr = isObservableArray(data) || Array.isArray(data) ? data : [data];
    dataArr.forEach((set) => {
      const metrics = Object.keys(set);
      metrics.forEach((metric) => {
        const isArray = isObservableArray(set[metric]) || Array.isArray(set[metric]);
        if (isArray) {
          set[metric].forEach((item) => {
            if (item.count > 0 || item.average_score > 0) {
              isEmpty = false;
            }
          });
        } else if (set[metric] && (set[metric].count > 0 || set[metric].average_score > 0)) {
          isEmpty = false;
        }
      });
    });
    return isEmpty;
  };

  render() {
    const { loading, error, type, height, displayData, emptyText, showWhenEmpty, graphContainerClasses } = this.props;
    if (loading) {
      return <LoadingContainer />;
    }
    if (error) {
      return (
        <Container>
          <StyledError error={error} />
        </Container>
      );
    }
    // If Empty data set, show defined message or default message
    if (!showWhenEmpty && this.checkIfEmpty(displayData)) {
      return (
        <Segment className="border-none empty-stats-container">
          <NoResultsMessage message={emptyText || 'No data to display'} />
        </Segment>
      );
    }

    return <div className="p-0">{this.getContent(displayData, type, height, graphContainerClasses)}</div>;
  }
}

export default StatSection;
