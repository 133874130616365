import React from 'react';
import ExampleSection from './ExampleSection';
import Header from '../../../components/Header/Header';
import Code from '../../../components/Code/Code';
import ProgressBar from '../../../components/ProgressBar/ProgressBar';
import CircularProgress from '../../../components/ProgressBar/CircularProgress';

function ProgressExampleSection() {
  return (
    <ExampleSection title="Progress Bar / Spinner">
      <Header as="h2">Progress Bar</Header>
      <p>Progress bars take the progress property (0-100). You can optionally animate or recolor the bar and background colors.</p>
      <div className="flex-col mb-2 space-y-2">
        <ProgressBar progress={0} />
        <ProgressBar progress={25} animate />
        <ProgressBar progress={50} animate progressClassName="bg-cyb-green-500" />
        <ProgressBar progress={75} animate progressClassName="bg-cyb-blue-500" wrapperClassName="bg-blue-200" />
        <ProgressBar progress={100} animate progressClassName="bg-cyb-yellow-500" />
      </div>
      <Code>
        {`
        <div className="flex-col mb-2 space-y-2">
          <ProgressBar progress={0} />
          <ProgressBar progress={25} animate />
          <ProgressBar progress={50} animate progressClassName="bg-cyb-green-500" />
          <ProgressBar progress={75} animate progressClassName="bg-cyb-blue-500" wrapperClassName="bg-blue-200" />
          <ProgressBar progress={100} animate progressClassName="bg-cyb-yellow-500" />
        </div>
      `}
      </Code>
      <Header as="h2">Progress Spinners</Header>
      <Header as="h3">Sizes</Header>
      <p>Progress spinners take the percentageValue property (0-100) and can be any size. Children are rendered as text in the center of the spinner.</p>
      <div className="flex mb-2 space-x-2">
        <CircularProgress percentageValue={25} classes="w-12 h-12">
          25%
        </CircularProgress>
        <CircularProgress percentageValue={50} classes="w-16 h-16">
          50%
        </CircularProgress>
        <CircularProgress percentageValue={75} classes="w-32 h-32">
          75%
        </CircularProgress>
      </div>
      <Code>
        {`
      <div className="flex mb-2 space-x-2">
        <CircularProgress percentageValue={25} classes="w-12 h-12">
          25%
        </CircularProgress>
        <CircularProgress percentageValue={50} classes="w-16 h-16">
          50%
        </CircularProgress>
        <CircularProgress percentageValue={75} classes="w-32 h-32">
          75%
        </CircularProgress>
      </div>
        `}
      </Code>
      <Header as="h3">Color and Stroke</Header>
      <p>You can control the thickness and color of the spinner using the stroke properties.</p>
      <div className="flex mb-2 space-x-2">
        <CircularProgress percentageValue={25} classes="w-16 h-16" strokeWidth={4} strokeColor="text-blue-500">
          25%
        </CircularProgress>
        <CircularProgress percentageValue={50} classes="w-16 h-16" strokeWidth={12} strokeColor="text-green-500">
          50%
        </CircularProgress>
        <CircularProgress percentageValue={75} classes="w-16 h-16" strokeWidth={20} strokeColor="text-red-500">
          75%
        </CircularProgress>
      </div>
      <Code>
        {`
      <div className="flex mb-2 space-x-2">
        <CircularProgress percentageValue={25} classes="w-16 h-16" strokeWidth={4} strokeColor="text-blue-500">
            25%
        </CircularProgress>
        <CircularProgress percentageValue={50} classes="w-16 h-16" strokeWidth={12} strokeColor="text-green-500">
            50%
        </CircularProgress>
        <CircularProgress percentageValue={75} classes="w-16 h-16" strokeWidth={20} strokeColor="text-red-500">
            75%
        </CircularProgress>
      </div>
        `}
      </Code>
    </ExampleSection>
  );
}

export default ProgressExampleSection;
