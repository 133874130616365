import React from 'react';
import { inject, observer } from 'mobx-react';
import Table from '../../components/Table/Table';
import Loading from '../../components/Loading/Loading';
import StyledError from '../../components/Error/StyledError';
import Container from '../../components/Container/Container';

const OrgOwnersList = inject('enterpriseStore')(
  observer(function OrgOwnersList({ enterpriseStore, requestLicense, contactedAdmins = [] }) {
    const { loading, error, data } = enterpriseStore.userListData;
    if (loading) {
      return (
        <Container>
          <Loading message="Loading..." />
        </Container>
      );
    }
    if (error) {
      return (
        <Container>
          <StyledError error={error} />
        </Container>
      );
    }
    if (!data?.length) {
      return <p>There are no Org Owners to request from.</p>;
    }
    return (
      <Table className="mb-8">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell scope="col">Name</Table.HeaderCell>
            <Table.HeaderCell scope="col" />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data.map((owner) => {
            const alreadyContacted = contactedAdmins.includes(`${owner.value}`);
            return (
              <Table.Row key={owner.value}>
                <Table.Cell>{owner.text}</Table.Cell>
                <Table.Cell className="text-right">
                  <button
                    onClick={() => requestLicense(owner.value, owner.text)}
                    className="py-2.5 px-6 text-sm font-bold leading-5 text-center text-black bg-gray-200 disabled:bg-gray-300 rounded-sm"
                    disabled={alreadyContacted}
                  >
                    {alreadyContacted ? 'Requested' : 'Request'}
                  </button>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  })
);

export default OrgOwnersList;
