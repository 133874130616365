import React, { useMemo, useCallback } from 'react';
import ExampleSection from './ExampleSection';
import Header from '../../../components/Header/Header';
import Code from '../../../components/Code/Code';
import AdminTable from '../../../components/AdminTable/AdminTable';
import ProgressLabel from '../../../components/Enterprise/ProgressLabel';
import AddLink from '../../../components/AddLink/AddLink';
import { formatTableColumns, renderTrendingColumn } from '../../../components/Baseline/shared';

const BASIC_TABLE_DATA = {
  columns: [
    {
      key: 'id',
      type: 'string',
      showCol: false,
      sortKey: 'id',
    },
    {
      key: 'learner',
      type: 'string',
      showCol: true,
      display: 'Learner',
      sortKey: 'learner',
    },
    {
      key: 'badge_count',
      type: 'string',
      showCol: true,
      display: 'Badge Count',
      sortKey: 'badge_count',
    },
    {
      key: 'total_hours',
      type: 'string',
      showCol: true,
      display: 'Total Hours',
      sortKey: 'total_hours',
    },
    {
      key: 'rank',
      type: 'string',
      showCol: true,
      display: 'Rank',
      sortKey: 'rank',
    },
  ],
  tableData: [
    [
      {
        value: 1,
        type: 'string',
      },
      {
        value: 'Will Carlson',
        type: 'string',
      },
      {
        value: 12,
        type: 'string',
      },
      {
        value: 63,
        type: 'string',
      },
      {
        value: 1,
        type: 'string',
      },
    ],
    [
      {
        value: 2,
        type: 'string',
      },
      {
        value: 'Tyler Meadows',
        type: 'string',
      },
      {
        value: 9,
        type: 'string',
      },
      {
        value: 48.5,
        type: 'string',
      },
      {
        value: 2,
        type: 'string',
      },
    ],
    [
      {
        value: 3,
        type: 'string',
      },
      {
        value: 'Robert Moreno',
        type: 'string',
      },
      {
        value: 6,
        type: 'string',
      },
      {
        value: 40,
        type: 'string',
      },
      {
        value: 3,
        type: 'string',
      },
    ],
    [
      {
        value: 4,
        type: 'string',
      },
      {
        value: 'Michelle Christensen',
        type: 'string',
      },
      {
        value: 5,
        type: 'string',
      },
      {
        value: 38.5,
        type: 'string',
      },
      {
        value: 4,
        type: 'string',
      },
    ],
  ],
};

const CUSTOM_TABLE_DATA = {
  columns: [
    {
      key: 'user_id',
      type: 'string',
      showCol: false,
      sortKey: 'user_id',
    },
    {
      key: 'user_name',
      type: 'string',
      showCol: true,
      display: 'Name',
      sortKey: 'user_name',
    },
    {
      key: 'badge_count',
      type: 'string',
      showCol: true,
      display: 'Badge Count',
      sortKey: 'badge_count',
    },
    {
      key: 'score',
      type: 'string',
      showCol: true,
      display: 'Score',
      sortKey: 'score',
      tooltip: 'The average score of the user’s best attempts across all completed assessments',
    },
    {
      key: 'progress',
      type: 'string',
      showCol: true,
      display: 'Progress',
      sortKey: 'progress',
      tooltip: 'The progress of the user’s current assessment',
    },
    {
      key: 'trending',
      type: 'string',
      showCol: true,
      display: 'Trending(90d)',
      sortKey: 'trending',
    },
  ],
  tableData: [
    [
      {
        value: 1,
        type: 'string',
      },
      {
        value: 'Will Carlson',
        type: 'string',
      },
      {
        value: 12,
        type: 'string',
      },
      {
        value: '0.58285542372365711',
        type: 'string',
      },
      {
        value: 50,
        type: 'string',
      },
      {
        value: '0.231',
        type: 'string',
      },
    ],
    [
      {
        value: 2,
        type: 'string',
      },
      {
        value: 'Tyler Meadows',
        type: 'string',
      },
      {
        value: 9,
        type: 'string',
      },
      {
        value: 1.0,
        type: 'string',
      },
      {
        value: 100,
        type: 'string',
      },
      {
        value: 0,
        type: 'string',
      },
    ],
    [
      {
        value: 3,
        type: 'string',
      },
      {
        value: 'Robert Moreno',
        type: 'string',
      },
      {
        value: 6,
        type: 'string',
      },
      {
        value: 0.86,
        type: 'string',
      },
      {
        value: 50,
        type: 'string',
      },
      {
        value: 0,
        type: 'string',
      },
    ],
    [
      {
        value: 4,
        type: 'string',
      },
      {
        value: 'Michelle Christensen',
        type: 'string',
      },
      {
        value: 5,
        type: 'string',
      },
      {
        value: 0.55,
        type: 'string',
      },
      {
        value: 100,
        type: 'string',
      },
      {
        value: -0.2344444,
        type: 'string',
      },
    ],
  ],
};

function TableExampleSection() {
  const renderColumn = useCallback((row, fullRow, headings, column) => {
    if (row.value !== undefined && column.showCol) {
      const percentage = Math.round(parseFloat(row.value * 100));

      if (column.key === 'user_name') {
        return (
          <AddLink className="text-cyb-pink-500 underline" to="#">
            {row.value}
          </AddLink>
        );
      }

      if (column.key === 'trending') {
        return renderTrendingColumn(fullRow, headings, 'trending');
      }

      if (column.key === 'progress') {
        return <ProgressLabel progressNum={row.value} />;
      }

      return `${percentage}%`;
    }

    return '';
  }, []);

  const formatColumns = useMemo(() => formatTableColumns(CUSTOM_TABLE_DATA?.columns, ['user_name', 'score', 'trending', 'progress'], renderColumn), [renderColumn]);

  return (
    <ExampleSection title="Admin Table">
      {/** Basic */}
      <Header as="h2">Basic</Header>
      <div className="flex flex-col mt-2">
        <AdminTable wrapperClassName="m-0 w-full" className="table-fixed" colClasses="whitespace-nowrap" headings={BASIC_TABLE_DATA.columns} data={BASIC_TABLE_DATA.tableData} />
      </div>
      <Code>
        {`
      const BASIC_TABLE_DATA = {"columns":[{"key":"user_id","type":"string","showCol":false,"sortKey":"user_id"},{"key":"user_name","type":"string","showCol":true,"display":"Name","sortKey":"user_name"},{"key":"badge_count","type":"string","showCol":true,"display":"Badge Count","sortKey":"badge_count"},{"key":"score","type":"string","showCol":true,"display":"Score","sortKey":"score","tooltip":"The average score of the user’s best attempts across all completed assessments"},{"key":"trending","type":"string","showCol":true,"display":"Trending(90d)","sortKey":"trending"}],"tableData":[[{"value":1,"type":"string"},{"value":"Will Carlson","type":"string"},{"value":12,"type":"string"},{"value":"0.58285542372365711","type":"string"},{"value":"0.231","type":"string"}],[{"value":2,"type":"string"},{"value":"Tyler Meadows","type":"string"},{"value":9,"type":"string"},{"value":1,"type":"string"},{"value":0,"type":"string"}],[{"value":3,"type":"string"},{"value":"Robert Moreno","type":"string"},{"value":6,"type":"string"},{"value":0.86,"type":"string"},{"value":0,"type":"string"}],[{"value":4,"type":"string"},{"value":"Michelle Christensen","type":"string"},{"value":5,"type":"string"},{"value":0.55,"type":"string"},{"value":-0.2344444,"type":"string"}]]};

      <AdminTable
        wrapperClassName="m-0 w-full"
        className="table-fixed"
        colClasses="whitespace-nowrap"
        headings={BASIC_TABLE_DATA.columns}
        data={BASIC_TABLE_DATA.tableData}
      />
        `}
      </Code>
      {/** Custom Cell Rendering */}
      <Header as="h2">Custom Cell Rendering</Header>
      <div className="flex flex-col mt-2">
        <AdminTable
          wrapperClassName="m-0 w-full"
          className="table-fixed"
          colClasses="whitespace-nowrap"
          headings={CUSTOM_TABLE_DATA.columns}
          data={CUSTOM_TABLE_DATA.tableData}
          formatColumns={formatColumns}
        />
      </div>
      <Code>
        {`
      const CUSTOM_TABLE_DATA = {"columns":[{"key":"user_id","type":"string","showCol":false,"sortKey":"user_id"},{"key":"user_name","type":"string","showCol":true,"display":"Name","sortKey":"user_name"},{"key":"badge_count","type":"string","showCol":true,"display":"Badge Count","sortKey":"badge_count"},{"key":"score","type":"string","showCol":true,"display":"Score","sortKey":"score","tooltip":"The average score of the user’s best attempts across all completed assessments"},{"key":"progress","type":"string","showCol":true,"display":"Progress","sortKey":"progress","tooltip":"The progress of the user’s current assessment"},{"key":"trending","type":"string","showCol":true,"display":"Trending(90d)","sortKey":"trending"}],"tableData":[[{"value":1,"type":"string"},{"value":"Will Carlson","type":"string"},{"value":12,"type":"string"},{"value":"0.58285542372365711","type":"string"},{"value":50,"type":"string"},{"value":"0.231","type":"string"}],[{"value":2,"type":"string"},{"value":"Tyler Meadows","type":"string"},{"value":9,"type":"string"},{"value":1,"type":"string"},{"value":100,"type":"string"},{"value":0,"type":"string"}],[{"value":3,"type":"string"},{"value":"Robert Moreno","type":"string"},{"value":6,"type":"string"},{"value":0.86,"type":"string"},{"value":50,"type":"string"},{"value":0,"type":"string"}],[{"value":4,"type":"string"},{"value":"Michelle Christensen","type":"string"},{"value":5,"type":"string"},{"value":0.55,"type":"string"},{"value":100,"type":"string"},{"value":-0.2344444,"type":"string"}]]}

      <AdminTable
        wrapperClassName="m-0 w-full"
        className="table-fixed"
        colClasses="whitespace-nowrap"
        headings={CUSTOM_TABLE_DATA.columns}
        data={CUSTOM_TABLE_DATA.tableData}
        formatColumns={[
          {
            method: (row) => {
              return (
                <AddLink className="text-cyb-pink-500 underline" to="#">
                  {row.value}
                </AddLink>
              );
            },
            colIdx: FormatUtil.getColIndex(CUSTOM_TABLE_DATA.columns, 'user_name'),
          },
          {
            method: (row) => {
              return return \`$\{row}%\`;
            },
            colIdx: FormatUtil.getColIndex(CUSTOM_TABLE_DATA.columns, 'score'),
          },
          {
            method: (row, fullRow, headings) => {
              return renderTrendingColumn(fullRow, headings, 'trending');
            },
            colIdx: FormatUtil.getColIndex(CUSTOM_TABLE_DATA.columns, 'trending'),
          },
          {
            method: (row) => {
              return <ProgressLabel progressNum={row.value} />;
            },
            colIdx: FormatUtil.getColIndex(CUSTOM_TABLE_DATA.columns, 'progress'),
          },
        ]}
      />
        `}
      </Code>
      {/** Accordion */}
      <Header as="h2">Accordion</Header>
      <AdminTable
        wrapperClassName="m-0 w-full"
        className="table-fixed"
        colClasses="whitespace-nowrap"
        headings={BASIC_TABLE_DATA.columns}
        data={BASIC_TABLE_DATA.tableData}
        accordionMinRows={3}
      />
      <Code>
        {`
      const BASIC_TABLE_DATA = {"columns":[{"key":"user_id","type":"string","showCol":false,"sortKey":"user_id"},{"key":"user_name","type":"string","showCol":true,"display":"Name","sortKey":"user_name"},{"key":"badge_count","type":"string","showCol":true,"display":"Badge Count","sortKey":"badge_count"},{"key":"score","type":"string","showCol":true,"display":"Score","sortKey":"score","tooltip":"The average score of the user’s best attempts across all completed assessments"},{"key":"trending","type":"string","showCol":true,"display":"Trending(90d)","sortKey":"trending"}],"tableData":[[{"value":1,"type":"string"},{"value":"Will Carlson","type":"string"},{"value":12,"type":"string"},{"value":"0.58285542372365711","type":"string"},{"value":"0.231","type":"string"}],[{"value":2,"type":"string"},{"value":"Tyler Meadows","type":"string"},{"value":9,"type":"string"},{"value":1,"type":"string"},{"value":0,"type":"string"}],[{"value":3,"type":"string"},{"value":"Robert Moreno","type":"string"},{"value":6,"type":"string"},{"value":0.86,"type":"string"},{"value":0,"type":"string"}],[{"value":4,"type":"string"},{"value":"Michelle Christensen","type":"string"},{"value":5,"type":"string"},{"value":0.55,"type":"string"},{"value":-0.2344444,"type":"string"}]]};

      <AdminTable
        wrapperClassName="m-0 w-full"
        className="table-fixed"
        colClasses="whitespace-nowrap"
        headings={BASIC_TABLE_DATA.columns}
        data={BASIC_TABLE_DATA.tableData}
        accordionMinRows={3}
      />
        `}
      </Code>
    </ExampleSection>
  );
}

export default TableExampleSection;
