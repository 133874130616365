import Bugsnag from '@bugsnag/js';
import { observable, action, computed, makeObservable } from 'mobx';
import Agents from '../agents/agents';

class CertificateStore {
  isCertificateLoading = true;

  certificateError = null;

  certificate = null;

  courseId = null;

  courseProgress = null;

  certificates = null;

  transformedCerts = {};

  constructor() {
    makeObservable(this, {
      isCertificateLoading: observable,
      certificateError: observable,
      certificate: observable,
      courseId: observable,
      courseProgress: observable,
      certificates: observable,
      transformedCerts: observable,
      setCertificateLoading: action,
      setCertificateError: action,
      setCourseId: action,
      setCourseProgress: action,
      setCertificate: action,
      setCertificates: action,
      certificateCount: computed,
      getCourseProgress: action,
      getCertificate: action,
      setCertificateFromItem: action,
      getCertificateInformation: action,
      getCertificates: action,
      reset: action,
    });
  }

  setCertificateLoading(isCertificateLoading) {
    this.isCertificateLoading = isCertificateLoading;
  }

  setCertificateError(certificateError) {
    this.certificateError = certificateError;
  }

  setCourseId(courseId) {
    this.courseId = courseId;
  }

  setCourseProgress(courseProgress) {
    this.courseProgress = courseProgress;
  }

  setCertificate(certificate) {
    this.certificate = certificate;
  }

  setCertificates(certificates) {
    this.certificates = certificates;
  }

  get certificateCount() {
    let length = 0;
    if (this.certificates && Object.keys(this.certificates).length) {
      Object.keys(this.certificates).forEach((certType) => {
        length += this.certificates[certType].length;
      });
    }
    return length;
  }

  findCertificateById(id) {
    const intId = 1 * id;
    if (this.certificates) {
      const certificateKeys = Object.keys(this.certificates);
      for (let k = 0; k < certificateKeys.length; k++) {
        const prop = certificateKeys[k];
        if (this.certificates[prop].length) {
          for (let i = 0; i < this.certificates[prop].length; i++) {
            if (this.certificates[prop][i].enrollment_id === intId) {
              return this.certificates[prop][i];
            }
          }
        }
      }
    }
    return null;
  }

  getCourseProgress(id) {
    return Agents.catalog
      .getCourseProgress(id)
      .then(
        action('fetchSuccess', (response) => {
          this.setCourseProgress(response);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.setCertificateError(error);
          this.setCertificateLoading(false);
        })
      );
  }

  getCertificate(id) {
    this.setCertificateLoading(true);
    return Agents.catalog
      .getCourse(id)
      .then(
        action('fetchSuccess', (response) => {
          this.setCertificate({
            certificate: response.certificate,
            courseTitle: response.content_description.title,
            courseId: response.id,
            legacyCourseId: response.legacy_id,
          });
          this.setCertificateLoading(false);
          return true;
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.setCertificateError(error);
          this.setCertificateLoading(false);
          return false;
        })
      );
  }

  // We have certificate certificate information returned with content items now, so
  // this function enables us to just set the cert based on that item (if it exists)
  setCertificateFromItem(item) {
    const cert = item && item.certificate ? item.certificate : null;
    this.setCertificate(cert);
  }

  getCertificateInformation(id) {
    this.setCertificateLoading(true);
    return this.getCertificate(id).then((status) => {
      if (status) {
        return this.getCourseProgress(id);
      }
      throw this.error;
    });
  }

  getCertificates() {
    this.setCertificateLoading(true);
    return Agents.certificate
      .getCertificates()
      .then(
        action('fetchSuccess', (response) => {
          this.setCertificates(response);
          this.setCertificateLoading(false);
        })
      )
      .catch('fetchError', (error) => {
        Bugsnag.notify(error);
        this.setCertificateError(error);
        this.setCertificateLoading(false);
      });
  }

  reset() {
    // Reset to original state
    this.setCertificate(null);
    this.setCertificateError(null);
    this.setCertificateLoading(true);

    this.setCourseId(null);
    this.setCourseProgress(null);

    this.certificates = null;
  }
}

export default new CertificateStore();
