import React from 'react';

export default function ForumsIcon({ classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';

  return (
    <svg className={className} {...accessibilityProps} xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 48 48" fill="currentColor" stroke="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.24199 26.4192C9.37704 26.5551 9.42249 26.7564 9.35899 26.9372L9.04753 27.8242C8.70721 28.7933 8.32807 29.6686 7.94869 30.4389C9.12478 30.1237 10.2749 29.6537 11.1431 28.9757L11.8278 28.441C11.969 28.3307 12.1583 28.3044 12.3242 28.372L13.1287 28.6997C15.149 29.5228 17.4898 30 20 30C24.0274 30 27.5965 28.7732 30.1137 26.8853C32.6303 24.9978 34 22.5431 34 20C34 17.4569 32.6303 15.0022 30.1137 13.1147C27.5965 11.2268 24.0274 10 20 10C15.9726 10 12.4035 11.2268 9.88629 13.1147C7.36971 15.0022 6 17.4569 6 20C6 22.0684 6.89945 24.0606 8.5795 25.7522L9.24199 26.4192ZM6.63696 32.7576C6.04508 32.8523 5.51332 32.91 5.085 32.9451C4.65926 32.9801 4.40822 32.4819 4.65098 32.1304C4.87529 31.8056 5.14301 31.394 5.43023 30.9028C5.99192 29.9423 6.62822 28.6774 7.16049 27.1616C5.17509 25.1626 4 22.6842 4 20C4 13.3726 11.1634 8 20 8C28.8366 8 36 13.3726 36 20C36 26.6274 28.8366 32 20 32C17.2389 32 14.6411 31.4754 12.3741 30.5519C10.6896 31.8675 8.39738 32.4761 6.63696 32.7576Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.2988 30.8717C21.5494 30.9563 20.7817 31.0001 20 31.0001C19.9399 31.0001 19.88 30.9998 19.8201 30.9993C21.9473 34.0883 26.2448 36.2001 31.2 36.2001C33.4089 36.2001 35.4871 35.7805 37.3007 35.0417C38.4838 35.9656 40.0417 36.4537 41.3574 36.7115C41.9428 36.8262 42.4803 36.8953 42.9154 36.9369C43.3407 36.9776 43.5944 36.4798 43.3571 36.1246C43.1384 35.7973 42.8807 35.383 42.6115 34.8901C42.2283 34.1881 41.8218 33.3266 41.4716 32.3293C43.0599 30.7302 44 28.7475 44 26.6001C44 22.1692 39.9975 18.4394 34.5562 17.3335C34.792 18.0278 34.9377 18.7481 34.984 19.4875C36.5555 19.908 37.9398 20.5785 39.051 21.4119C40.9885 22.865 42 24.7198 42 26.6001C42 28.1316 41.3356 29.6282 40.0526 30.9199L39.3901 31.587C39.2551 31.7229 39.2096 31.9241 39.2731 32.105L39.5846 32.992C39.7467 33.4538 39.9197 33.8895 40.0972 34.2973C39.5092 34.0802 38.9679 33.806 38.5317 33.4654L37.847 32.9307C37.7059 32.8205 37.5165 32.7941 37.3506 32.8617L36.5461 33.1895C34.9792 33.8278 33.1579 34.2001 31.2 34.2001C28.0563 34.2001 25.2871 33.2419 23.349 31.7883C22.9617 31.4978 22.6114 31.1913 22.2988 30.8717Z"
      />
    </svg>
  );
}
