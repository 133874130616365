import { observable, action, makeObservable } from 'mobx';
import Bugsnag from '@bugsnag/js';
import Agents from '../agents/agents';
import GaUtil from '../utils/gaUtil';

class FeedbackStore {
  formSet = {
    courseType: ['Course', 'MicroCourse', 'Cybrary Live Series', 'Cybrary Live Session'],
    assessmentType: [
      'Assessment',
      'Practice Labs Exam',
      'Kaplan Practice Test',
      'CyberVista Practice Test',
      'CyDefe Assessment',
      'iMocha Test',
      'Next Tech Practice Test',
      'Rangeforce Assessment',
      'Next Tech Assessment',
      'Avatao Challenge',
      'Interview Mocha Test',
    ],
    labType: [
      'Practice Labs Lab',
      'Next Tech Course',
      'Next Tech Lab',
      'Next Tech Activity',
      'Next Tech Lesson',
      'Next Tech Project',
      'Next Tech Challenge',
      'Next Tech Quiz',
      'Avatao Tutorial',
      'Rangeforce Lab',
      'CyberScore Lab',
      'Skillable',
      'Skillable Lab',
      'Infosec Lab',
      'Virtual Lab',
      'Lab',
    ],
    collectionType: ['Career Path', 'Certification Prep'],
  };

  feedbackLoading = false;

  overallRatingsLoading = false;

  reviewsAndRatingsLoading = false;

  feedbackError = null;

  overallRatingsError = null;

  contentType = null;

  reviewsAndRatingsError = null;

  reviews = null;

  ratings = [];

  originalReview = null;

  originalRatings = [];

  overallRatings = [];

  isFeedbackModalOpen = false;

  contentDescriptionId = null;

  title = null;

  isThankYouMessageDisplayed = false;

  isErrorDisplayed = false;

  feedbackForm = null;

  constructor() {
    makeObservable(this, {
      formSet: observable,
      feedbackLoading: observable,
      overallRatingsLoading: observable,
      reviewsAndRatingsLoading: observable,
      feedbackError: observable,
      overallRatingsError: observable,
      contentType: observable,
      reviewsAndRatingsError: observable,
      reviews: observable,
      ratings: observable,
      originalReview: observable,
      originalRatings: observable,
      overallRatings: observable,
      isFeedbackModalOpen: observable,
      contentDescriptionId: observable,
      title: observable,
      isThankYouMessageDisplayed: observable,
      isErrorDisplayed: observable,
      feedbackForm: observable,
      setFeedbackLoading: action,
      setOverallRatingsLoading: action,
      setReviewsAndRatingsLoading: action,
      setFeedbackError: action,
      setOverallRatingsError: action,
      setReviewsAndRatingsError: action,
      setReview: action,
      setRatings: action,
      setOriginalReview: action,
      setOriginalRatings: action,
      setOverallRatings: action,
      openFeedbackModal: action,
      closeFeedbackModal: action,
      displayThankYouMessage: action,
      displayError: action,
      setFeedbackForm: action,
      clearFeedbackErrorsForKey: action,
      setContentDescriptionId: action,
      setContentType: action,
      setTitle: action,
      getFeedback: action,
      getFeedbackForm: action,
      getOverallRatings: action,
      updateReviewsAndRatings: action,
      reset: action,
    });
  }

  setFeedbackLoading(loading) {
    this.feedbackLoading = loading;
  }

  setOverallRatingsLoading(loading) {
    this.overallRatingsLoading = loading;
  }

  setReviewsAndRatingsLoading(loading) {
    this.reviewsAndRatingsLoading = loading;
  }

  setFeedbackError(error) {
    this.feedbackError = error;
  }

  setOverallRatingsError(error) {
    this.overallRatingsError = error;
  }

  setReviewsAndRatingsError(error) {
    this.reviewsAndRatingsError = error;
  }

  setReview(review) {
    this.review = review;
  }

  setRatings(ratings) {
    this.ratings = ratings || [];
  }

  setOriginalReview(review) {
    this.originalReview = review;
  }

  setOriginalRatings(ratings) {
    this.originalRatings = ratings;
  }

  setOverallRatings(ratings) {
    this.overallRatings = ratings || [];
  }

  openFeedbackModal() {
    this.isFeedbackModalOpen = true;
  }

  closeFeedbackModal() {
    this.isFeedbackModalOpen = false;
  }

  displayThankYouMessage(display) {
    this.isThankYouMessageDisplayed = display;
  }

  displayError(display) {
    this.isErrorDisplayed = display;
  }

  setFeedbackForm(form) {
    this.feedbackForm = form;
  }

  clearFeedbackErrorsForKey(key) {
    if (this.feedbackError && this.feedbackError.errors && this.feedbackError.errors[key]) {
      this.feedbackError.errors[key] = undefined;
    }
  }

  setContentDescriptionId(id) {
    this.contentDescriptionId = id;
  }

  setContentType(contentType) {
    this.contentType = contentType;
  }

  setTitle(title) {
    this.title = title;
  }

  getFeedback(id) {
    this.setFeedbackLoading(true);
    this.reset();
    return Agents.feedback
      .getFeedback(id)
      .then(
        action('fetchSuccess', (response) => {
          if (response) {
            if (response.ratings) {
              this.setRatings(response.ratings);
              this.setOriginalRatings(response.ratings);
            }
            if (response.review) {
              this.setReview(response.review);
              this.setOriginalReview(response.review);
            }
          }
          this.setFeedbackLoading(false);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.setFeedbackError(error);
          this.setFeedbackLoading(false);
        })
      );
  }

  getFeedbackForm = (type) => {
    // set form
    if (this.formSet.courseType.indexOf(type) !== -1) {
      // type is a course, microcourse, cybrary live
      this.setFeedbackForm('course');
    } else if (this.formSet.assessmentType.indexOf(type) !== -1) {
      // type is an assessment
      this.setFeedbackForm('assessment');
    } else if (this.formSet.labType.indexOf(type) !== -1) {
      // type is a lab
      this.setFeedbackForm('lab');
    } else if (this.formSet.collectionType.indexOf(type) !== -1) {
      // type is a collection
      this.setFeedbackForm('collection');
    } else {
      // type is a audio/video based activity
      this.setFeedbackForm('video');
    }
  };

  getOverallRatings() {
    const id = this.contentDescriptionId;
    this.setOverallRatingsLoading(true);
    return Agents.reviews
      .getOverallRatings(id)
      .then(
        action('fetchSuccess', (response) => {
          this.setOverallRatings(response);
          this.setOverallRatingsLoading(false);
        })
      )
      .catch(
        action('fetchError', (error) => {
          Bugsnag.notify(error);
          this.setOverallRatingsError(error);
          this.setOverallRatingsLoading(false);
        })
      );
  }

  updateReviewsAndRatings(ratingsData) {
    const data = ratingsData ? { ...ratingsData } : {};
    const id = this.contentDescriptionId;
    if (!!data && data.ratings) {
      // some handlers pass form data, so we need to parse out what we dont need to avoid request errors
      // i.e. filter "_inferred_country" and "formId" from the list of ratings
      const newRatings = data.ratings.filter((submission) => !['_inferred_country', 'formId'].includes(submission.question_type));
      data.ratings = newRatings;
    }
    this.setReviewsAndRatingsLoading(true);
    return Agents.feedback
      .updateOrCreate(id, data)
      .then(
        action('postSuccess', () => {
          this.setReviewsAndRatingsLoading(false);
          GaUtil.fireEvent(`${this.contentType} Review`, 'Product Interaction', this.title);
        })
      )
      .catch(
        action('postError', (error) => {
          Bugsnag.notify(error);
          this.setReviewsAndRatingsError(error);
          this.setReviewsAndRatingsLoading(false);
        })
      );
  }

  reset() {
    this.setReview(null);
    this.setRatings();
    this.setOriginalReview(null);
    this.setOriginalRatings();
    this.displayError(false);
    this.setReviewsAndRatingsError(null);
    this.displayThankYouMessage(false);
    this.setFeedbackError(false);
  }
}

export default new FeedbackStore();
