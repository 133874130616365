import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import Bugsnag from '@bugsnag/js';
import Agents from '../../agents/agents';
import Input from '../FormFields/Input';
import ValidationUtil from '../../utils/validationUtil';

const RecoveryEmailForm = inject(
  'profileStore',
  'commonStore'
)(
  observer(({ profileStore, commonStore, successCallback, dismissCallback }) => {
    const [submitDisabled, setSubmitDisabled] = useState(true);

    const setEmailInput = (email) => {
      profileStore.setNewEmailInput(email);
      // Test the email input is valid
      setSubmitDisabled(!ValidationUtil.checkValidEmail(email));
    };

    /**
     * Handle adding new emails
     */
    const handleAddAdditionalEmail = async () => {
      try {
        await Agents.authGoogle.magicAction({ action: 'addAccount', email: profileStore.newEmailInput });
        commonStore.triggerToast('success', {
          content: `We just sent a verification email to ${profileStore.newEmailInput}. Please check your email.`,
        });
        if (successCallback) {
          successCallback();
        }
      } catch (e) {
        Bugsnag.notify(e);
        commonStore.triggerToast('error', {
          content: 'Something went wrong. Unable to add recovery email.',
        });
      }
    };

    const additionalEmailClasses =
      'rounded-sm bg-cyb-pink-500 text-white font-bold text-center hover:text-white hover:bg-pink-600 leading-5 py-2.5 px-6 text-sm disabled:opacity-50';

    return (
      <div className="add-email-container">
        <div className="add-email">
          <div className="mb-4 input-container">
            <Input
              ariaLabel="Recovery Email"
              onChange={(event, val) => {
                setEmailInput(val.value);
              }}
            />
          </div>
          <div className="flex items-center">
            <button
              onClick={() => {
                handleAddAdditionalEmail();
              }}
              disabled={submitDisabled}
              className={additionalEmailClasses}
            >
              Send Verification
            </button>

            <button
              className="py-2.5 px-6 ml-4 text-sm font-bold leading-5 text-center text-black bg-gray-200 hover:bg-gray-300 rounded-sm"
              onClick={() => (dismissCallback ? dismissCallback() : null)}
            >
              Do this later
            </button>
          </div>
        </div>
      </div>
    );
  })
);

export default RecoveryEmailForm;
