import React from 'react';

export default function Algolia({ classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';
  return (
    <svg className={className} fill="currentColor" viewBox="0 0 120 120" xmlns="http://www.w3.org/2000/svg" {...accessibilityProps}>
      <g fill="none">
        <path
          d="m15.8-.001h88.4c8.7 0 15.8 7.065 15.8 15.8v88.405c0 8.7-7.065 15.795-15.8 15.795h-88.4c-8.7 0-15.8-7.06-15.8-15.795v-88.445c0-8.695 7.06-15.76 15.8-15.76"
          fill="currentColor"
        />
        <path
          d="m72.505 26.788v-4.115a5.209 5.209 0 0 0 -5.21-5.205h-12.145a5.209 5.209 0 0 0 -5.21 5.205v4.225c0 .47.435.8.91.69a37.966 
          37.966 0 0 1 10.57-1.49c3.465 0 6.895.47 10.21 1.38.44.11.875-.215.875-.69zm-33.285 5.385-2.075-2.075a5.206 5.206 0 0 0 -7.365 
          0l-2.48 2.475a5.185 5.185 0 0 0 0 7.355l2.04 2.04c.33.325.805.25 1.095-.075a39.876 39.876 0 0 1 3.975-4.66 37.68 37.68 0 0 1 
          4.7-4c.364-.22.4-.73.11-1.06zm22.164 13.065v17.8c0 .51.55.875 1.02.62l15.825-8.19c.36-.18.47-.62.29-.98-3.28-5.755-9.37-9.685-16.405-9.94-.365 
          0-.73.29-.73.69zm0 42.88c-13.195 0-23.915-10.705-23.915-23.88s10.72-23.875 23.915-23.875c13.2 0 23.916 10.7 23.916 23.875s-10.68 23.88-23.916 
          23.88zm0-57.8c-18.74 0-33.94 15.18-33.94 33.92 0 18.745 15.2 33.89 33.94 33.89s33.94-15.18 33.94-33.925-15.165-33.885-33.94-33.885zm177.83 
          64.86c-23.365.109-23"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
