import React, { useState, useMemo, useEffect } from 'react';
import { twMerge } from 'tailwind-merge';
import DashboardWidgetTitle from '../Dashboard/DashboardWidgetTitle';
import If from '../If/If';

const BUTTON_FONT_CLASSES = 'text-sm xs:text-base lg:text-sm font-semibold lg:font-normal';
const BUTTON_CLASSES = `inline-block px-1 pt-2 pb-2 lg:pb-3 lg:mx-0 border-b-2 w-full whitespace-nowrap lg:rounded-none ${BUTTON_FONT_CLASSES}`;
const ACTIVE_CLASSES = 'border-cyb-pink-500 font-semibold text-white lg:text-black bg-cyb-pink-500 lg:bg-transparent';
const INACTIVE_CLASSES = 'border-gray-300 cursor-pointer bg-gray-300 lg:bg-transparent';

/**
 * BasicTabs component for displaying tabs with customizable content
 * @param {Array} tabs - Array of objects with id, title, and content properties
 * @param {string} headerTitle - Default title of the tab header if the active tab does not have a header title
 * @param {string} headerTitleLink - Default link for the tab header if the active tab does not have a header link
 * @param {string} headerTitleClassName - Additional classes for the tab header
 * @param {boolean} omitContainerBorder - Whether to omit the container border
 * @param {string} buttonClassName - Additional classes for the tab buttons
 * @param {string} navClassName - Additional classes for the tab navigation
 * @param {string} navContainerClassName - Additional classes for the tab navigation container
 * @param {number} initTabIndex - Initial index of the active tab
 * @param {function} onChange - Function to call when the active tab changes
 * @returns {JSX.Element} - BasicTabs component
 * @example Example Tabs:
 * const tabs = [
 *   {
 *     id: 1,
 *     title: 'Tab 1',
 *     content: <div>Content for Tab 1</div>
 *     headerTitle: 'Tab 1',
 *     headerTitleLink: '/tab-1'
 *   },
 *   {
 *     id: 2,
 *     title: 'Tab 2',
 *     content: <div>Content for Tab 2</div>
 *     headerTitle: 'Tab 2',
 *     headerTitleLink: '/tab-2'
 *   }
 * ];
 * return <BasicTabs tabs={tabs} headerTitle="Example Tabs" headerTitleLink="/example" />;
 */
function BasicTabs({
  tabs,
  headerTitle,
  headerTitleLink,
  headerTitleClassName = '',
  omitContainerBorder = false,
  buttonClassName = '',
  navClassName = '',
  navContainerClassName = '',
  initTabIndex = 0,
  onChange,
}) {
  const [activeTab, setActiveTab] = useState(initTabIndex);

  // Reset active tab if initTabIndex changes
  useEffect(() => {
    setActiveTab(initTabIndex);
  }, [initTabIndex]);

  // If onChange is provided, call it with the active tab index
  useEffect(() => {
    if (onChange) {
      onChange(activeTab);
    }
  }, [activeTab]);

  const content = useMemo(() => tabs?.[activeTab]?.content, [activeTab]);

  const activeHeaderTitle = useMemo(() => tabs?.[activeTab]?.headerTitle || headerTitle, [headerTitle, activeTab]);
  const activeHeaderTitleLink = useMemo(() => tabs?.[activeTab]?.headerTitleLink || headerTitleLink, [headerTitleLink, activeTab]);
  const headerTitleClasses = twMerge('whitespace-nowrap', headerTitleClassName);
  const activeButtonClasses = twMerge(BUTTON_CLASSES, ACTIVE_CLASSES, buttonClassName);
  const inactiveButtonClasses = twMerge(BUTTON_CLASSES, INACTIVE_CLASSES, buttonClassName);
  const navClasses = twMerge('flex z-10 justify-evenly w-full align-middle mb-4 lg:mb-0', navClassName);
  const navContainerClasses = twMerge('justify-evenly items-center self-start w-full align-middle lg:flex', navContainerClassName);

  return (
    <div className="flex flex-col items-start mb-4 w-full lg:mb-0">
      <div className="mb-2 w-full">
        <div className={navContainerClasses}>
          <If condition={headerTitle}>
            <DashboardWidgetTitle title={activeHeaderTitle} link={activeHeaderTitleLink} className={headerTitleClasses} />
          </If>
          <nav className={navClasses}>
            {tabs.map(({ id, title }, index) => (
              <button
                key={id}
                aria-pressed={activeTab === index}
                aria-label={`View ${title} content`}
                className={activeTab === index ? activeButtonClasses : inactiveButtonClasses}
                onClick={() => setActiveTab(index)}
              >
                {title}
              </button>
            ))}
          </nav>
        </div>
        <If condition={!omitContainerBorder}>
          <div className="-mt-0.5 w-full h-0.5 bg-slate-200" />
        </If>
      </div>
      {content}
    </div>
  );
}

export default BasicTabs;
