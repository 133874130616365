import Bugsnag from '@bugsnag/js';
import { trackStructEvent, trackSelfDescribingEvent } from '@snowplow/browser-tracker';

/**
 * Fires a structured snowplow event with error handling.
 * @param {string} category The name for the group of objects you want to track e.g. 'immersive', 'onboarding'
 * @param {string} action A string which defines the type of user interaction for the object e.g. 'completed', 'shown', 'performedSearch'. TRY TO REUSE ACTIONS THE BEST WE CAN.
 * @param {string} label (Optional) A string which identifies the specific object being actioned e.g. ID of the video being played, or the SKU or the product added-to-basket
 * @param {string} property (Optional) A string describing the object or the action performed on it. This might be the quantity of an item added to basket
 * @param {float} value (Optional) A float to quantify or further describe the user action. e.g. Starting time of the video where play was just pressed
 * @return {boolean} true if the event fired
 */
export function trackSnowplowEvent({ category, action, property = '', label = '', value = 0.0 }) {
  if (!category || !action) {
    Bugsnag.notify(new Error('Failed to track event - Missing category/action'));
    return false;
  }
  trackStructEvent({
    category,
    action,
    property,
    label,
    value,
  });
  return true;
}

// keeping the elements we care about (INPUT, SELECT, TEXTAREA)
const filterFormElements = (domElements) => {
  // we only care about input, textarea, and select fields... querySelectorAll will returns an object
  const filteredElementsArray = [...domElements.querySelectorAll('input, select, textarea')];

  // remove items with dataSet of password
  return filteredElementsArray.filter((element) => element.dataset?.snowplowType !== 'password');
};

/**
  @params formId: string  that is used to grab the form element from the dom in order to format the snowplow form submission event
  @params additionalData: object that contains additional data that is not part of the NATIVE form submission but is needed for the snowplow event
  ex: phone number, we need to pass the formatted phone number with country code to snowplow
*/
export const fireFormSnowplowSubmission = (formId, additionalData = {}) => {
  const formElement = document.getElementById(formId);
  const { phone = '' } = additionalData;

  if (formId && formElement) {
    const { className = '' } = formElement;
    // convert classes into array with each element separated by space
    const formattedClassName = className.split(' ');
    const snowplowSubmissionElements = filterFormElements(formElement);

    const formattedSnowplowSubmissionElements = snowplowSubmissionElements.map((submissionElement) => {
      /* 
        name, value, nodeName, & type are snowplows element fields they accept. 
        But they don't care about 'type' for SELECT & TEXTAREA element 
      */
      const { id: name, value, nodeName, type } = submissionElement;
      if (nodeName === 'SELECT' || nodeName === 'TEXTAREA') {
        return { name, value, nodeName };
      }

      // ensure snowplow receives the correct phone number with country code that is submitted to the backend
      if (nodeName === 'INPUT' && type === 'tel') {
        return { name, value: phone, nodeName, type };
      }

      return { name, value, nodeName, type };
    });

    // snowplow custom context created by Kenny in order to pass user's data with form submissions
    trackSelfDescribingEvent({
      event: {
        schema: 'iglu:com.snowplowanalytics.snowplow/submit_form/jsonschema/1-0-0',
        data: {
          formId,
          formClasses: formattedClassName,
          elements: formattedSnowplowSubmissionElements,
        },
      },
    });
  }
};
