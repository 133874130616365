import React from 'react';
import DynamicForm from '../DynamicForm/DynamicForm';
import Social from './Social';
import LoginLinks, { ForgotPassword } from './LoginLinks';
import Divider from '../Divider/Divider';
import { APP_FORM_IDS } from '../../constants';

function Email({ startValidation, endValidation, onSubmit, submitting, email, rememberMe, handleSocialLogin }) {
  const LoginEmailForm = {
    name: 'Login Email Form',
    order: ['email', 'rememberMe', 'submit'],
    fields: {
      email: {
        type: 'email',
        label: 'Email',
        validations: ['required', 'email'],
        defaultValue: email,
        disabled: submitting,
      },
      rememberMe: {
        type: 'boolean',
        label: 'Remember Me',
        defaultValue: !!rememberMe,
        disabled: submitting,
        labelClass: 'font-bold',
      },
      submit: {
        type: 'button',
        color: 'pink',
        loading: submitting,
        label: 'Next',
        disabled: submitting,
        className: 'w-full grid justify-items-center',
      },
    },
  };
  return (
    <>
      <h1 className="my-8 text-2xl font-black text-center md:text-3xl lg:text-5xl">Sign In</h1>
      <div className="grid relative md:grid-cols-2">
        <div className="mx-auto w-full md:w-9/12">
          <DynamicForm
            customClassName="text-base"
            formId={APP_FORM_IDS.AUTH.EMAIL_LOGIN}
            form={LoginEmailForm}
            startValidation={startValidation}
            endValidation={endValidation}
            onSubmit={onSubmit}
          />
          <div className="mt-4 mb-8 text-right">
            <ForgotPassword />
          </div>
        </div>
        <Divider vertical className="hidden absolute inset-y-0 my-3 md:flex" style={{ left: 'calc(50% - 0.75rem)' }}>
          <span className="text-gray-600 uppercase">or</span>
        </Divider>
        <Divider horizontal className="flex mt-2 mb-6 md:hidden">
          <span className="text-gray-600 uppercase">or</span>
        </Divider>
        <div className="mx-auto w-full md:w-9/12">
          <Social handleClick={handleSocialLogin} />
        </div>
      </div>
      <LoginLinks omitLoginLink omitForgotPasswordLink />
    </>
  );
}

export default Email;
