import React, { createContext, useContext, useState, useMemo, useCallback, useEffect } from 'react';
import Bugsnag from '@bugsnag/js';
import { toJS } from 'mobx';
import Agents from '../agents/agents';

/** :/
 * @abstract This provider is used to fetch and store the user's custom paths data
 * @example - pathsData = [{
        "id": 67017,
        "team_id": 12061,
        "in_team_catalog": false,
        "author_id": 3014061,
        "name": "My Path",
        "duration_seconds": 15937,
        "description": "some bimbo created a path",
        "duration_in_days": null,
        "created_at": "2023-07-26 15:31:52",
        "email": "dhanlon@cybrary.it",
        "real_name": "Davidd Hanlon",
        "assigned_user_count": 0,
        "content_replaced_at": null,
        "content_archive_at": null}]
  * @important - "in_team_catalog" is a boolean that determines if the path publicly available or not
  * @example - activePathData = {{
    "id": 67017,
    "team_id": 12061,
    "name": "My Path",
    "duration_in_days": null,
    "duration_seconds": 15937,
    "description": "some bimbo created a path",
    "contents": [
        {
            "id": 8291,
            "title": "EC Council Certified Ethical Hacker v8",
            "duration_seconds": 14400,
            "type": "Practice Labs Exam",
            "archived_at": null,
            "tags": [],
            "replacement": null,
            "archive_at": null
        },
    ],
    "thumbnail_url": null,
    "content_replaced_at": null,
    "author_id": 3014061,
    "content_archive_at": null
}}
 */

const CustomPathsContext = createContext();
function CustomPathsProvider({ children, userStore, enrollmentStore }) {
  const defaultPathsData = [];
  const defaultActivePathData = {
    id: '',
    team_id: '',
    name: '',
    duration_seconds: 0,
    description: '',
    contents: [],
  };
  const [pathsData, setPathsData] = useState(defaultPathsData);
  const [enrolledPathsData, setEnrolledPathsData] = useState(defaultPathsData);
  const [activePathData, setActivePathData] = useState(defaultActivePathData);

  const [enrolledPathIds, setEnrolledPathIds] = useState([]);
  const [isCustomPathsLoading, setIsCustomPathsLoading] = useState(false);
  const [isEnrolledPathsLoading, setIsEnrolledPathsLoading] = useState(false);

  const teamIds = useMemo(() => Object.keys(userStore?.userTeams || {}), [userStore]);

  const isEntUser = userStore?.isEnterprise;

  /** @returns a boolean if the user is enrolled in the course */
  const getIsEnrolled = useCallback(
    (pathId) => {
      return enrolledPathIds.includes(pathId);
    },
    [enrolledPathIds]
  );
  /** Fetch Single Curriculum item details */
  const fetchCustomPathDetails = useCallback(async (teamId, pathId) => {
    try {
      let response = null;
      setIsCustomPathsLoading(true);
      /** ensure we have teamId before we make the request */
      if (teamId) {
        response = await Agents.enterprise.getCurriculum(teamId, pathId);
      }
      if (response) {
        setActivePathData(response);
      }
    } catch (error) {
      Bugsnag.notify(error);
    } finally {
      setIsCustomPathsLoading(false);
    }
  }, []);

  /** Fetch the user's "publicly available" custom paths data */
  const fetchPublicCustomPaths = useCallback(async () => {
    try {
      let responses = [];
      /** Don't make any requests unless we have an ent user and data isn't already saved */
      if (pathsData.length < 1) {
        setIsCustomPathsLoading(true);
        const requests = teamIds.map((teamId) => Agents.enterprise.getCurricula(teamId, ''));
        /** can be a scenario where a user is added to a team and one of the promises may return 403 b/c they aren't yet verified... poor UX to just fail all */
        responses = await Promise.allSettled(requests);
      }

      if (responses.length > 0) {
        /** grab response values returned from the promise.allSettled */
        /** ALL custom paths related to these teamIds are returned but we only wanna display publicly available ones */
        const responsesData = responses.map((response) => response.value);

        /* Merge together the arrays if necessary (all paths associated to a users teams */
        const mergedResponsesData = [].concat(...responsesData);

        /** The list of paths we will display to enroll in or continue... custom paths are curricula items with team_id */
        const publiclyAvailablePaths = mergedResponsesData.filter((path) => !!path && path.in_team_catalog === true && path.team_id !== null);

        setPathsData(publiclyAvailablePaths);
      }
      return true;
    } catch (error) {
      Bugsnag.notify(error);
      return false;
    } finally {
      setIsCustomPathsLoading(false);
    }
  }, []);

  const fetchEnrolledCustomPaths = useCallback(async () => {
    if (enrollmentStore && isEntUser) {
      setIsEnrolledPathsLoading(true);
      await enrollmentStore.getEnrollmentList('curriculum', 'incomplete');
      /** toJS converts ugly mobx code to pretty code :) */
      const enrolledPaths = toJS(enrollmentStore.enrollmentList.curriculum.data);

      /** if we refetch enrolledPaths, we want to enrollmentIds so the UI updates */
      const newEnrollmentIds = enrolledPaths?.map((path) => path.content_description_id) ?? [];
      setEnrolledPathIds(newEnrollmentIds);
      setEnrolledPathsData(enrolledPaths);
      setIsEnrolledPathsLoading(false);
    }
  }, [enrollmentStore]);

  /** fetching users custom paths on mount/app load */
  useEffect(() => {
    if (isEntUser) {
      fetchPublicCustomPaths();
    }
  }, [isEntUser]);

  /** fetching users custom path enrollments on mount (content_type_id = 5 for curriculum aka custom paths) */
  useEffect(() => {
    fetchEnrolledCustomPaths();
  }, [enrollmentStore, isEntUser]);

  /** CustomPathsProvider values returned for component consumption */
  const values = useMemo(
    () => ({
      isLoading: isCustomPathsLoading || isEnrolledPathsLoading,
      isCustomPathsLoading,
      isEnrolledPathsLoading,
      pathsData,
      activePathData,
      enrolledPathsData,
      getIsEnrolled,
      fetchEnrolledCustomPaths,
      fetchPublicCustomPaths,
      fetchCustomPathDetails,
      setEnrolledPathIds,
    }),
    [
      isCustomPathsLoading,
      isEnrolledPathsLoading,
      pathsData,
      activePathData,
      enrolledPathsData,
      getIsEnrolled,
      fetchEnrolledCustomPaths,
      fetchPublicCustomPaths,
      fetchCustomPathDetails,
      setEnrolledPathIds,
    ]
  );

  return <CustomPathsContext.Provider value={values}>{children}</CustomPathsContext.Provider>;
}

export const useCustomPathsContext = () => useContext(CustomPathsContext);
export default CustomPathsProvider;
