import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';
import { Helmet } from 'react-helmet';
import Loading from '../../components/Loading/Loading';
import Container from '../../components/Container/Container';
import ProfileHeaderSection from '../../components/Profile/ProfileHeaderSection/ProfileHeaderSection';
import ProfileStreaksAndSocials from '../../components/Profile/ProfileStreaksAndSocials/ProfileStreaksAndSocials';
import ProfileTabs from '../../components/Profile/ProfileTabs/ProfileTabs';
import usePublicProfileData from '../../hooks/usePublicProfileData';
import If from '../../components/If/If';

const PublicProfile = inject(
  'commonStore',
  'userStore'
)(
  observer(({ userStore, commonStore }) => {
    // Params
    const params = useParams();
    const { username: usernameFromURL } = params;

    // State
    const {
      isCertificatesDataLoading,
      isProfileDataLoading,
      isStatsDataLoading,
      isPrivate,
      error,
      username,
      personalData,
      certificationsData,
      profileStats,
      certificatesData,
      badges,
    } = usePublicProfileData(usernameFromURL);

    const {
      avatar_url = '',
      firstName = '',
      lastName = '',
      location = '',
      bio = '',
      pronouns = '',
      facebook = '',
      instagram = '',
      linkedin = '',
      github = '',
      twitter = '',
      website = '',
      tagline = '',
    } = personalData || {};
    const {
      daily_activity_streak = 0,
      completed_courses,
      completed_labs,
      completed_learning_hours,
      completed_assessments,
      level,
      experience_points,
      experience_points_taxonomy: xpTaxonomy,
    } = profileStats || {};
    const hasError = !!error;
    const isLoggedOut = !userStore?.user;

    useEffect(() => {
      commonStore.setPageTitle(`${usernameFromURL} | Cybrary`);
    }, []);

    if (isProfileDataLoading) {
      return <Loading />;
    }
    const handleClickShare = () => {
      commonStore.resetShareComponent();
      commonStore.triggerShareComponent({
        permalink: username,
        prefix: 'public-profile',
        type: 'public-profile',
        title: username,
        username,
      });
    };
    return (
      <Container size="md">
        {/** if logged out, user wont see the nav bar so we add a logo with link to www as our 'nav' */}
        <If condition={isLoggedOut}>
          <a href="https://www.cybrary.it/" className="block">
            <img
              src="https://images.ctfassets.net/kvf8rpi09wgk/6HtXDpiYT7GMU8yp0DS4Dy/5b42c213353afb410ac8e52e7db7c77f/cybrary_logo_black.svg"
              alt="Cybrary"
              width="120"
              height="31"
              className="pt-8 pb-4 mx-auto"
            />
          </a>
        </If>

        {/** Override default open graph social sharing meta data */}
        <Helmet>
          <meta property="og:title" content={`View ${username}'s Cybrary Profile`} />
          <meta property="og:description" content="Come see my learning progress, certifications, badges, and more!" />
          <If condition={avatar_url}>
            <meta property="og:image" content={avatar_url} />
          </If>
        </Helmet>

        <If condition={hasError}>
          <div className="text-center">{error?.message}</div>
        </If>
        <Fade triggerOnce cascade damping={0.3}>
          <div id="profile-header-section" className="mt-16">
            <ProfileHeaderSection
              isEditable={false}
              userName={personalData?.username}
              firstName={firstName}
              lastName={lastName}
              avatarUrl={avatar_url || 'https://assets.cybrary.it/uploads/user-avatars/profile.jpg'}
              pronouns={pronouns}
              websiteUrl={website}
              role={tagline}
              userLocation={typeof location === 'string' ? location : location?.format}
            />
          </div>
          {/** if isPublic, show streaks & social */}
          <If condition={!isPrivate}>
            <div id="streak-and-socials-container" className="mt-4">
              <ProfileStreaksAndSocials
                currentStreak={daily_activity_streak}
                linkedin={linkedin}
                facebook={facebook}
                twitter={twitter}
                github={github}
                instagram={instagram}
                currentLevel={level}
                currentXp={experience_points}
                handleClickShare={handleClickShare}
              />
            </div>
          </If>
          {/** if isPublic, and we have bio... show bio */}
          <If condition={!isPrivate && !!bio}>
            <div id="profile-bio-container" className="mt-4">
              <p id="profile-bio">{bio}</p>
            </div>
          </If>
        </Fade>
        <div id="tabs-container" className="mt-4">
          <ProfileTabs
            isPrivate={isPrivate}
            certificationsProfileData={certificationsData}
            certificates={certificatesData}
            completedCourses={completed_courses}
            completedLearningHours={completed_learning_hours}
            completedLabs={completed_labs}
            passedAssessments={completed_assessments}
            isCertsLoading={isCertificatesDataLoading}
            isStatsLoading={isStatsDataLoading}
            xpTaxonomy={xpTaxonomy}
            badges={badges}
            username={username}
          />
        </div>
      </Container>
    );
  })
);

export default PublicProfile;
