import React from 'react';

export default function ChevronDoubleLeft({ classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" {...accessibilityProps}>
      <path strokeLinecap="round" strokeLinejoin="round" d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5" />
    </svg>
  );
}
