import React, { useMemo } from 'react';
import Icon from '../../Icon/Icon';
import AddLink from '../../AddLink/AddLink';

function TeamsNoGoalsBanner({ view, hasGoalsInProgress, noItemsJsx }) {
  // Generic messages with slight language variations per section when no goals for that section

  const topText = useMemo(() => {
    if (noItemsJsx) {
      return noItemsJsx;
    }
    if (view !== 'in_progress' && hasGoalsInProgress) {
      return `You're still working on your goals!`;
    }
    switch (view) {
      case 'inProgress':
      case 'in_progress':
        return "You don't have any goals in progress.";
      case 'expired':
        return "You don't have any expired goals.";
      case 'completed':
        return "You don't have any completed goals.";
      default:
        return "You don't have any goals to show here.";
    }
  }, [view, hasGoalsInProgress, noItemsJsx]);

  const bottomText = useMemo(() => {
    if (view !== 'in_progress' && hasGoalsInProgress) {
      return (
        <>
          <p>Get started on your goals, today.</p>
          <AddLink className="text-cyb-pink-500 hover:text-black underline" to="/my-learning/goals">
            Start Learning
          </AddLink>
        </>
      );
    }
    return <p>Reach out to your Team Admin to get started on your goals today!</p>;
  }, [view, hasGoalsInProgress]);

  return (
    <div className="flex flex-col gap-y-4 items-center px-12 mx-auto max-w-[32rem] font-semibold text-center lg:py-6">
      <p className="mb-0">{topText}</p>
      <Icon name="goal" className="w-24 h-24" />
      {bottomText}
    </div>
  );
}
export default TeamsNoGoalsBanner;
