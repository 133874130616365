import React from 'react';
import AddLink from '../AddLink/AddLink';
import FilterUtil from '../../utils/filterUtil';
import Chevron from './Icons/Chevron';
import withRouter from '../Router/withRouter';
import PermalinkUtil from '../../utils/permalinkUtil';

export function SelectedMenu({ wrapperClass, linkClass, active, redirectToDestination, isEnterprise }) {
  return (
    <ul className={wrapperClass}>
      {active?.filters?.map((activeFilter) => {
        if (activeFilter === 'Live Training' && !isEnterprise) return null;
        const isContentGroup = active.facet === 'content_group';
        const filterName = isContentGroup ? FilterUtil.getOriginalContentTypeName(activeFilter) : activeFilter;
        const displayName = isContentGroup ? activeFilter : FilterUtil.transformFacetFilterName(activeFilter);
        const facetName = isContentGroup ? 'view' : active.facet;
        const link = `/browse/refined?${facetName}=${encodeURIComponent(filterName)}`;
        return (
          <li key={displayName} className="flex items-center">
            <AddLink key={filterName} className={linkClass} onClick={() => redirectToDestination(link)}>
              {displayName}
            </AddLink>
          </li>
        );
      })}
    </ul>
  );
}

export function MobileMenuItems({ item, searchFacets, facets, certCount, toggleMobileSidebar, handleSelect, redirectToDestination, active, isEnterprise }) {
  if (item.type === 'filter') {
    return searchFacets?.map((facetCategory, index) => {
      // Get the data for this facet
      const facet = FilterUtil.getFacetData(facets, facetCategory);
      const filters = FilterUtil.getFilters(facet, certCount);
      const filterHeader = FilterUtil.getFilterHeader(facetCategory);
      if (!facet || filterHeader === 'terms_info') {
        return null;
      }
      const isActiveMenuItem = active.index === index;
      const activeItemClass = isActiveMenuItem ? 'text-black font-semibold' : 'text-gray-600';
      const activeChevron = isActiveMenuItem ? 'rotate-180' : '';
      const activeDropDownMenu = isActiveMenuItem ? 'block lg:hidden' : 'hidden';
      return (
        <li key={filterHeader}>
          <button aria-label="Toggle List of Search Filters" className="group flex items-center py-5 px-6 cursor-pointer" onClick={() => handleSelect(index, facet.name, filters)}>
            <p className={`text-lg group-hover:text-black group-hover:font-semibold mb-0 ${activeItemClass}`}>{filterHeader}</p>
            <Chevron direction="down" classes={`h-6 w-6 ml-auto transform group-hover:text-black group-hover:font-semibold ${activeItemClass} ${activeChevron}`} />
          </button>
          <SelectedMenu
            wrapperClass={`pl-5 ${activeDropDownMenu}`}
            linkClass="flex items-center py-5 pr-4 pl-6 text-left cursor-pointer"
            redirectToDestination={redirectToDestination}
            active={active}
            isEnterprise={isEnterprise}
          />
        </li>
      );
    });
  }

  return (
    <li>
      <AddLink
        onClick={() => {
          toggleMobileSidebar();
          redirectToDestination(item.href);
        }}
        className="group flex items-center py-4 px-6 cursor-pointer"
      >
        <p className="text-lg text-gray-600 group-hover:text-black">{item.label}</p>
      </AddLink>
    </li>
  );
}

export function HeadingWrapper({ item }) {
  const marginTop = item.label === 'Featured' ? 'mt-2' : '';
  return (
    <li key={item.id} className={`mb-2 ${marginTop}`}>
      <h2 className="pr-4 pb-2 pl-6 text-xs font-bold uppercase lg:px-8 lg:pb-0 lg:text-2xs">{item.label}</h2>
    </li>
  );
}

function DesktopMenuWrapper({ items, mobile, redirectToDestination, isEnterprise }) {
  return (
    <ul className="relative pt-6 pb-4 w-[212px]">
      {items?.map((item) => {
        if (item.heading) {
          return <HeadingWrapper key={item.id} item={item} />;
        }
        // remove live training if not enterprise user
        if (item.label === 'Live Training' && !isEnterprise) {
          return null;
        }
        const isButton = item.type === 'button';
        const wrapperClass = isButton ? 'w-full px-4' : 'group flex items-center mb-2 hover:bg-gray-50';
        const linkClass = `${
          item.additionalLabels ? 'w-auto' : 'w-full'
        } flex items-center text-gray-600 hover:text-black text-lg lg:text-sm pl-6 lg:pl-8 pr-2 py-3 lg:py-2 cursor-pointer`;
        const buttonClass = 'bg-gray-200 flex items-center justify-center text-black text-sm w-full px-6 py-3 rounded-sm font-bold';
        const labelClass = isButton ? '' : 'pr-2 text-gray-600 group-hover:text-black';
        if (mobile && isButton) {
          return null;
        }
        return (
          <li key={item.id} className={wrapperClass}>
            <AddLink onClick={() => redirectToDestination(item.href)} className={isButton ? buttonClass : linkClass}>
              <span className={labelClass}>{item.label}</span>
            </AddLink>
          </li>
        );
      })}
    </ul>
  );
}

function DropdownMenu({ items, mobile, navigate, isEnterprise, onClick }) {
  const redirectToDestination = (destination) => {
    PermalinkUtil.redirectToDestination(destination, navigate, onClick);
  };

  if (mobile) {
    return (
      <ul className="mb-8">
        {items?.map((item) => {
          if (item.type === 'button') {
            return null;
          }
          // remove live training if not enterprise user
          if (item.label === 'Live Training' && !isEnterprise) {
            return null;
          }
          if (item.heading) {
            return <HeadingWrapper key={item.id} item={item} />;
          }
          return <MobileMenuItems key={item.id} item={item} redirectToDestination={redirectToDestination} toggleMobileSidebar={onClick} isEnterprise={isEnterprise} />;
        })}
      </ul>
    );
  }

  return (
    <div className="flex justify-between w-full max-h-[80vh] divide-x-1">
      <DesktopMenuWrapper items={items} mobile={mobile} redirectToDestination={redirectToDestination} isEnterprise={isEnterprise} />
    </div>
  );
}

export default withRouter(DropdownMenu);
