import React from 'react';
import Container from '../../components/Container/Container';
import Title from '../../components/Title/Title';
import Teaser from '../../components/Assessments/Teaser';
import BetaInvite from '../../components/Assessments/BetaInvite';
import AssessmentsContent from '../../components/Assessments/AssessmentsContent';

function Assessments() {
  return (
    <Container size="card-container-v2">
      <Title title="Announcing Cybrary Assessments (BETA)" />
      <p className="mb-6 text-sm">
        Cybrary Assessments provide organizations with the tools to measure cybersecurity capability using hyper-realistic, scenario-based experiences.
      </p>
      <div className="mb-12">
        <Teaser />
      </div>
      <div className="flex justify-between items-end mb-6">
        <div className="w-96 border-b border-gray-400">
          <div className="inline-block px-6 pb-2 border-b-4 border-gray-500">
            <p className="text-sm">Available Assessments</p>
          </div>
        </div>
        <BetaInvite />
      </div>
      <AssessmentsContent />
    </Container>
  );
}

export default Assessments;
