import React, { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import AddLink from '../AddLink/AddLink';
import ChevronLeft from '../../Icons/ChevronLeft';

function Breadcrumb({ crumb, lastCrumb }) {
  // return last breadcrumb with aria Current to the last bread crumb (the current page)
  const ariaCurrent = lastCrumb ? { 'aria-current': 'page' } : {};
  const linkClasses = useMemo(() => twMerge('group flex items-center text-black', lastCrumb ? '' : 'underline hover:text-cyb-pink-500'), [lastCrumb]);
  return (
    <li className="inline-block mr-2">
      <AddLink className={linkClasses} to={crumb.href} {...ariaCurrent} active={lastCrumb}>
        <ChevronLeft classes="inline-block -ml-1 w-4 h-4 text-black" />
        <span>{crumb.label}</span>
      </AddLink>
    </li>
  );
}

function Breadcrumbs({ crumbs, wrapperClasses }) {
  if (!crumbs || !crumbs.length) {
    return null;
  }

  const classes = twMerge('flex items-center text-sm pb-12', wrapperClasses);

  return (
    <nav aria-label="Breadcrumb">
      <ol className={classes}>
        {crumbs.map((crumb, idx) => {
          return <Breadcrumb key={crumb.href || crumb.label} crumb={crumb} lastCrumb={idx + 1 >= crumbs.length} />;
        })}
      </ol>
    </nav>
  );
}

export default Breadcrumbs;
