import React from 'react';
import { observer } from 'mobx-react';
import Header from '../Header/Header';
import ArchivedLabel from '../ContentUpdates/ArchivedLabel';
import BetaLabel from '../Label/BetaLabel';
import FormatUtil from '../../utils/formatUtil';
import Label from '../Label/Label';

const CurriculumItem = observer(({ curriculumItem, handleCurriculumItemLaunch }) => {
  const handleClick = (isArchived) => {
    if (!isArchived) {
      handleCurriculumItemLaunch(curriculumItem);
    }
  };

  const {
    content_description_title,
    content_description_type,
    learning_seconds_total,
    learning_seconds_completed,
    progress,
    content_description_thumbnail_url,
    content_description_archived_at,
  } = curriculumItem;

  const formattedImg = `${content_description_thumbnail_url}?w=165&q=60`;
  const timeRemaining = learning_seconds_total - learning_seconds_completed;
  const isArchived = !!content_description_archived_at;

  return (
    <div className="py-8 border-y border-gray-400">
      <div className="block sm:flex">
        <div className="!hidden w-full md:!inline-block md:w-1/4">
          <img src={formattedImg} alt="" className="max-w-[190px]" />
        </div>
        <div className="mb-4 w-full sm:w-1/2">
          <Header as="h3">{content_description_title}</Header>
          <div>
            <Label basic>{content_description_type}</Label>
            {!!learning_seconds_total && <Label basic>{FormatUtil.formatTime(learning_seconds_total, 'hm')} Total</Label>}
            {progress > 0 && progress < 100 && <Label basic>{FormatUtil.formatTime(timeRemaining, 'hm')} Remaining</Label>}
            {isArchived && <ArchivedLabel />}
            {curriculumItem?.tags?.includes('Beta') && <BetaLabel />}
          </div>
        </div>
        <div className="w-full sm:ml-auto sm:w-1/4">
          <Header as="h3">{progress || 0}% Completed</Header>
          <button
            disabled={isArchived}
            onClick={() => handleClick(isArchived)}
            className="py-2.5 px-6 text-sm font-bold leading-5 text-center text-white hover:text-white bg-cyb-pink-500 hover:bg-pink-600 rounded-sm"
          >
            {progress > 0 ? 'Continue' : 'Start'}
          </button>
        </div>
      </div>
    </div>
  );
});

export default CurriculumItem;
