import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import DynamicForm from '../../components/DynamicForm/DynamicForm';
import Loading from '../../components/Loading/Loading';
import StyledError from '../../components/Error/StyledError';
import Container from '../../components/Container/Container';

const NotificationPreferencesForm = inject('notificationsStore')(
  observer(
    class NotificationPreferencesForm extends Component {
      componentDidMount() {
        this.props.notificationsStore.getPreferences();
      }

      newForm = (storeData) => {
        const settingsForm = {
          order: ['notifications', 'submit'],
          fields: {
            notifications: {
              type: 'checkbox',
              label: 'Learning Reminders',
              options: [{ value: 'Opt Out' }],
              description: 'You will receive notifications for new goals, nudges to continue your progress, and reminders for your learning schedule.',
            },
            submit: {
              type: 'button',
              id: 'submit-notification-settings',
              color: 'gray',
              className: 'grey-submit-button',
              label: 'Save Changes',
            },
          },
        };

        return this.props.addDefaultValues(settingsForm, storeData);
      };

      render() {
        const { preferencesData, handlePreferencesSubmit } = this.props;
        const { loading, error, data } = preferencesData;
        const notificationForm = this.newForm(data);

        if (loading) {
          return (
            <Container>
              <Loading message="Loading..." />
            </Container>
          );
        }
        if (error) {
          return (
            <Container>
              <StyledError error={error} />
            </Container>
          );
        }
        return (
          <Container>
            <DynamicForm form={notificationForm} formName="notification-preferences" onSubmit={handlePreferencesSubmit} />
          </Container>
        );
      }
    }
  )
);

export default NotificationPreferencesForm;
