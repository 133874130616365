import React from 'react';
import moment from 'moment';
import queryString from 'query-string';
import Icon from '../Icon/Icon';
import Table from '../Table/Table';
import AddLink from '../AddLink/AddLink';
import FormatUtil from '../../utils/formatUtil';
import ArchivedLabel from '../ContentUpdates/ArchivedLabel';
import BetaLabel from '../Label/BetaLabel';
import Tooltip from '../Tooltip/Tooltip';

function LearningActivitiesLinks({ col, row, idx, columns }) {
  const queryParams = queryString.parse(window.location.search);
  const userNameIdx = FormatUtil.getColIndex(columns, 'name');
  const userIdIdx = FormatUtil.getColIndex(columns, 'id');
  const courseIdIdx = FormatUtil.getColIndex(columns, 'course_id');
  const courseNameIdx = FormatUtil.getColIndex(columns, 'course_title');
  const curriculaIdIdx = FormatUtil.getColIndex(columns, 'curriculum_id');
  const contentNameIdx = FormatUtil.getColIndex(columns, 'content_description_title');
  let href = null;

  if (idx === userNameIdx) {
    queryParams.userName = !!row[userNameIdx] && row[userNameIdx].value ? row[userNameIdx].value : null;
    queryParams.userId = !!row[userIdIdx] && row[userIdIdx].value ? row[userIdIdx].value : null;
    href = queryParams.userId ? `${window.location.pathname}?${queryString.stringify(queryParams)}` : null;
  } else if (idx === contentNameIdx) {
    queryParams.contentDescriptionId = !!row[curriculaIdIdx] && row[curriculaIdIdx].value ? row[curriculaIdIdx].value : null;
    queryParams.contentName = !!row[contentNameIdx] && row[contentNameIdx].value ? row[contentNameIdx].value : null;
    href = queryParams.contentDescriptionId ? `${window.location.pathname}?${queryString.stringify(queryParams)}` : null;
  } else if (idx === courseNameIdx) {
    queryParams.contentDescriptionId = !!row[courseIdIdx] && row[courseIdIdx].value ? row[courseIdIdx].value : null;
    queryParams.contentName = !!row[courseNameIdx] && row[courseNameIdx].value ? row[courseNameIdx].value : null;
    href = queryParams.contentDescriptionId ? `${window.location.pathname}?${queryString.stringify(queryParams)}` : null;
  }

  return (
    <Table.Cell key={col.value}>
      {href ? (
        <AddLink className="hover:font-semibold text-black hover:text-black underline hover:underline" to={href}>
          {col.value}
        </AddLink>
      ) : (
        col.value
      )}
    </Table.Cell>
  );
}

function GroupsColumn({ col }) {
  // Groups come in pipe | delimited string. Pipes in group names are escaped. Need to split on | if not preceded by \
  /** const groupsArray = col.value.split(/(?<!\\)\|/); */
  // If the group has a pipe | in it, which is escaped, need to replace \| with |
  /** const groupsArr = groupsArray.map(function (item) {
    return item.replace('\\|', '|');
  }); */
  const groupsArr = col && col.value ? col.value.split('|') : [];
  if (!groupsArr.length) {
    return <Table.Cell>No Groups</Table.Cell>;
  }
  if (groupsArr.length === 1) {
    return <Table.Cell>{groupsArr[0]}</Table.Cell>;
  }
  return (
    <Table.Cell>
      <Tooltip
        triggerContent={
          <span>
            <Icon name="user-group" className="inline-block w-4 h-4" /> {groupsArr.length} Groups
          </span>
        }
        content={groupsArr.join(', ')}
      />
    </Table.Cell>
  );
}

function ArchiveLabel({ row, columns }) {
  const archivedAtIdx = FormatUtil.getColIndex(columns, 'archived_at');
  return archivedAtIdx > -1 && !!row[archivedAtIdx].value ? <ArchivedLabel className="ml-2" /> : null;
}

function Beta({ row, columns }) {
  const tagsIdx = FormatUtil.getColIndex(columns, 'tags');
  const getLabel = tagsIdx && tagsIdx > -1 ? row[tagsIdx].value : null;
  return getLabel && getLabel.indexOf('Beta') !== -1 ? <BetaLabel className="ml-2" /> : null;
}

function TypeColumns({ col }) {
  return <Table.Cell>{col.value ? moment(col.value).format('MM/DD/YYYY') : null}</Table.Cell>;
}

function TableColumn({ col, row, props, colIdx }) {
  const columnKey = props.columns[colIdx].key;

  // This value has a custom mutation.  This overrides all
  if (Object.keys(props.columnMutations).indexOf(columnKey) !== -1) {
    return <Table.Cell>{props.columnMutations[columnKey](col.value)}</Table.Cell>;
  }

  const contentIdIdx = FormatUtil.getColIndex(props.columns, 'content_description_id');
  const contentTitle = FormatUtil.getColIndex(props.columns, 'content_description_title');
  const archivedLabel = <ArchiveLabel row={row} columns={props.columns} />;
  const groupsIdx = FormatUtil.getColIndex(props.columns, 'groups');
  const titleIdx = FormatUtil.getColIndex(props.columns, 'content_description_title');
  const betaLabel = <Beta row={row} columns={props.columns} />;
  if (props.reportsStore.reportItem.table.data.omittedHeadings[colIdx]) return null; // Omit showing the column if the heading has a showCol key set to false
  if ('type' in col && (col.type === 'datetime' || col.type === 'date')) {
    return <TypeColumns col={col} />;
  }

  if (groupsIdx === colIdx) {
    return <GroupsColumn col={col} />;
  }

  // If this is the assignment report, need to link the name column with the assignment ID
  if (props.reportId === 'assignment' && colIdx === 1) {
    const link =
      `/enterprise/${props.orgId}/reporting/report/assignment-details/` +
      `?assignment=${row[4].value}&userId=${row[0].value}&userName=${row[1].value}` +
      `&contentId=${props.reportParams.contentDescriptionId}&contentName=${encodeURIComponent(props.reportParams.contentName)}`;
    return (
      <Table.Cell>
        <Tooltip
          triggerContent={
            <AddLink className="hover:font-semibold text-black hover:text-black underline hover:underline" to={link}>
              {col.value}
            </AddLink>
          }
          content="View assignment details for user"
        />
      </Table.Cell>
    );
  }
  // If assignment-details, need to link content name to content description
  if (props.reportId === 'assignment-details' && colIdx === 3) {
    const link = `/browse/${row[4].value}`;
    return (
      <Table.Cell>
        <AddLink className="hover:font-semibold text-black hover:text-black underline hover:underline" to={link}>
          {col.value}
        </AddLink>
      </Table.Cell>
    );
  }
  // If content report, link to content Id specific reports
  if (props.reportId === 'content-type' && colIdx === titleIdx) {
    const contentTypeIdParams = props.reportsStore.getContentTypeIdsParam(props.reportParams['contentTypeIds[]']);
    const link =
      `/enterprise/${props.orgId}/reporting/report/content/?contentDescriptionId=${row[0].value}` +
      `&contentName=${encodeURIComponent(col.value)}${contentTypeIdParams}&contentType=${props.reportParams.contentType}`;
    return (
      <Table.Cell>
        <Tooltip
          position="top-start"
          triggerContent={
            <div>
              <AddLink className="hover:font-semibold text-black hover:text-black underline hover:underline" to={link}>
                {col.value}
              </AddLink>
              {archivedLabel}
              {betaLabel}
            </div>
          }
          content="View content details"
        />
      </Table.Cell>
    );
  }
  // If curricula overview report, link to curriculum content report
  if (props.reportId === 'curricula-overview' && colIdx === 1) {
    const link = `/enterprise/${props.orgId}/reporting/report/curricula-content/?contentDescriptionId=${row[contentIdIdx].value}&contentName=${encodeURIComponent(col.value)}`;
    return (
      <Table.Cell>
        <Tooltip
          omitTabIndex
          triggerContent={
            <AddLink className="hover:font-semibold text-black hover:text-black underline hover:underline" to={link}>
              {col.value}
            </AddLink>
          }
          content="View path content"
        />
      </Table.Cell>
    );
  }
  // If hands-on-leaderboard and contentId param 21, need to link to assessment dashboard
  if (props.reportId === 'leaderboard-hands-on' && 1 * props.reportParams['contentTypeIds[]'] === 21 && colIdx === contentTitle) {
    const link = `/enterprise/${props.orgId}/organization/content/${row[contentIdIdx].value}/dashboard`;
    return (
      <Table.Cell>
        <AddLink className="hover:font-semibold text-black hover:text-black underline hover:underline" to={link}>
          {col.value}
        </AddLink>
      </Table.Cell>
    );
  }
  if (props.reportId === 'learning-activities') {
    return <LearningActivitiesLinks col={col} row={row} idx={colIdx} columns={props.columns} />;
  }
  return <Table.Cell>{typeof col.value === 'number' ? FormatUtil.formatNumbers(col.value) : col.value}</Table.Cell>;
}

function TableRow(props) {
  if (!props.data.length) {
    return (
      <Table.Row>
        <Table.Cell colSpan={10} className="table-no-results">
          No results to display
        </Table.Cell>
      </Table.Row>
    );
  }

  return props.data.map((row, idx) => {
    const rowKey = `${row[0].value}_${idx}`; // should always be an ID of some sort
    return (
      <Table.Row key={rowKey}>
        {row.map((col, idx2) => {
          const colKey = `${rowKey}_${props.columns[idx2].key}`;
          return <TableColumn col={col} row={row} props={props} colIdx={idx2} key={colKey} />;
        })}
      </Table.Row>
    );
  });
}

export default TableRow;
