import React from 'react';
import { twMerge } from 'tailwind-merge';

function OnboardingHeader({ className }) {
  return (
    <img
      alt=""
      width="224"
      height="58"
      className={twMerge('mx-auto mt-4', className)}
      src="https://images.ctfassets.net/kvf8rpi09wgk/6HtXDpiYT7GMU8yp0DS4Dy/5b42c213353afb410ac8e52e7db7c77f/cybrary_logo_black.svg?w=250"
    />
  );
}

export default OnboardingHeader;
