import React, { useMemo } from 'react';

function Paths({ active }) {
  if (active) {
    return (
      <g>
        <path
          d="M32.75,23.72v-7.943h14.527c.271,0,.521-.146,.654-.383s.127-.526-.014-.758l-2.763-4.532,2.763-4.532c.141-.231,.146-.521,.014-.758s-.383-.383-.654-.383h-15.277c-.414,0-.75,.336-.75,.75V23.72L11.202,58.443c-.134,.232-.134,.518,0,.75s.382,.375,.649,.375H52.148c.268,0,.516-.143,.649-.375s.134-.518,0-.75L32.75,23.72Z"
          fill="black"
        />
        <path d="M18.441,32.805c-.268-.465-1.031-.465-1.299,0L2.351,58.426c-.134,.232-.134,.518,0,.75s.382,.375,.649,.375h5.831l12.526-21.696-2.916-5.051Z" fill="black" />
        <path d="M61.649,58.426l-14.792-25.621c-.268-.465-1.031-.465-1.299,0l-2.916,5.051,12.526,21.696h5.831c.268,0,.516-.143,.649-.375s.134-.518,0-.75Z" fill="black" />
      </g>
    );
  }
  return (
    <path
      d="M61.866,58.301l-14.792-25.621c-.357-.619-1.375-.619-1.732,0l-3.565,6.175-8.777-15.202v-7.625h14.277c.361,0,.695-.195,.872-.511,.177-.314,.17-.701-.019-1.01l-2.683-4.402,2.683-4.402c.188-.309,.195-.695,.019-1.01-.177-.315-.511-.511-.872-.511h-15.277c-.553,0-1,.447-1,1V23.653l-8.777,15.202-3.565-6.175c-.357-.619-1.375-.619-1.732,0L2.134,58.301c-.179,.31-.179,.69,0,1,.179,.31,.509,.5,.866,.5H61c.357,0,.687-.19,.866-.5,.179-.31,.179-.69,0-1ZM32,25.921l18.416,31.897H13.584l18.416-31.897Zm11.424-16.337c-.195,.319-.195,.722,0,1.041l2.073,3.402h-12.497V6.182h12.497l-2.073,3.402Zm-25.632,25.596l3.276,5.675-9.784,16.946H4.732l13.06-22.621Zm34.924,22.621l-9.784-16.946,3.276-5.675,13.06,22.621h-6.552Z"
      fill="currentColor"
    />
  );
}

export default function AchievementIcon({ id, active, classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const strokeProps = active ? {} : { stroke: 'currentColor' };

  const className = useMemo(() => {
    let base = classes || 'w-6 h-6';

    if (id === '-primary-nav') {
      base += ' translate-y-5px scale-100';
    }

    return base;
  }, [classes, id]);

  return (
    <svg className={className} fill="none" {...strokeProps} viewBox="0 0 64 80" xmlns="http://www.w3.org/2000/svg" {...accessibilityProps}>
      <Paths active={active} id={id} />
    </svg>
  );
}
