/**
 * A handful of helper functions used throughout the app.
 */

// Fix the index of an item by it's id
export function findIndexById(items, id, idKey = 'id') {
  if (!items || !items.length || !id) {
    return -1;
  }
  let ret = -1;
  items.forEach((item, i) => {
    if (item && item[idKey] === id) {
      ret = i;
    }
  });
  return ret;
}

export function findById(items, id, idKey = 'id', defaultValue = null) {
  const index = findIndexById(items, id, idKey);
  if (index === -1) {
    return defaultValue;
  }
  return items[index];
}

// Quick helper function to assist with debugging some quirks while using Firefox with Guac
export function isFirefox() {
  return navigator?.userAgent?.includes('Firefox');
}

// Prettier is forcing me to do a default export, I don't have one for this file, so...
export default {};
