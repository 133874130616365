import { useMemo } from 'react';
import { v4 as uuid } from 'uuid';

/**
 * @returns {string} a unique key best used for cases when you are mapping over an array
 * and need a unique key for each item when it doesn't come from the data itself
 */
function useUniqueKey() {
  return useMemo(uuid, []);
}
export default useUniqueKey;
