import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Navigate } from 'react-router-dom';
import Title from '../../components/Title/Title';
import Container from '../../components/Container/Container';
import RequestLicenseTeamSelect from '../../components/UpgradeButton/RequestLicenseTeamSelect';
import './enterprise.css';

const RequestTeamLicenseTeamSelector = inject(
  'commonStore',
  'userStore',
  'authStore'
)(
  observer(function RequestTeamLicenseTeamSelector({ commonStore, userStore, authStore }) {
    useEffect(() => {
      authStore.fireAttributionEvent();
      commonStore.setPageTitle('Request a License | Cybrary');
    });
    const { userTeams, activeTeams } = userStore;
    const { getRequestLicenseLink } = commonStore;

    // Get an array of teams that are active as those are the only ones you can request a license from
    // If no active teams, redirect user back to homepage
    if (!activeTeams?.length) {
      return <Navigate replace to="/" />;
    }
    // If user only has one active team, just push user to that team's request a license page
    if (activeTeams.length && activeTeams.length === 1) {
      const teamId = activeTeams[0] * 1; // make int
      return <Navigate replace to={getRequestLicenseLink(teamId)} />;
    }
    // Otherwise, show a list of active teams and let user choose which to request from
    return (
      <Container size="md" className="mb-12">
        <Title title="Request a License" />
        <RequestLicenseTeamSelect activeTeams={activeTeams} userTeams={userTeams} getRequestLicenseLink={getRequestLicenseLink} />
      </Container>
    );
  })
);

export default RequestTeamLicenseTeamSelector;
