import React from 'react';
import Label from '../Label/Label';
import Tooltip from '../Tooltip/Tooltip';

function ArchivedLabel({ className }) {
  return (
    <Tooltip
      content="This content is no longer available on the platform"
      triggerContent={
        <Label color="gray" className={`rounded-sm text-xs p-1 ${className}`}>
          Archived
        </Label>
      }
    />
  );
}

export default ArchivedLabel;
