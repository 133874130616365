import React from 'react';
import ExampleSection from './ExampleSection';
import Code from '../../../components/Code/Code';
import AddLink from '../../../components/AddLink/AddLink';

function LinksExampleSection() {
  return (
    <ExampleSection title="Links">
      <AddLink to="/components" target="_blank" title="Some title">
        Example Link to New tab
      </AddLink>
      <Code>
        {`
    <AddLink to="/components" target="_blank" title="Some title">
      Example Link to New tab
    </AddLink>
      `}
      </Code>
    </ExampleSection>
  );
}

export default LinksExampleSection;
