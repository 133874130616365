import React, { useMemo } from 'react';
import TeamOnboardingStep from '../../TeamOnboardingStep';
import { TEAM_ONBOARDING_STEPS, TEAM_ONBOARDING_OPTIONS, useTeamOnboarding } from '../../../../providers/TeamOnboardingProvider';
import RadioButtons from '../../../FormFields/RadioButtons';
import If from '../../../If/If';
import TextArea from '../../../FormFields/TextArea';

function SelectRoleStep() {
  const { setTeamRole, setTeamRoleOther, teamRoleOther, teamRole, setStep } = useTeamOnboarding();

  const options = useMemo(() => {
    return Object.keys(TEAM_ONBOARDING_OPTIONS.teamRole).map((key) => {
      const role = TEAM_ONBOARDING_OPTIONS.teamRole[key];
      return {
        key: role?.key,
        text: role?.text,
        value: role?.key,
      };
    });
  }, []);

  const handleChange = (e, { value }) => {
    if (value !== TEAM_ONBOARDING_OPTIONS.teamRole.OTHER.key) {
      setTeamRole(value);
      setTeamRoleOther(''); // Clear other role
      setStep(TEAM_ONBOARDING_STEPS.SELECT_GOALS);
    } else {
      setTeamRole(value);
    }
  };

  const isStepComplete = (teamRole && teamRole !== TEAM_ONBOARDING_OPTIONS.teamRole.OTHER.key) || teamRoleOther;

  return (
    <TeamOnboardingStep
      title="Question 1/3:"
      subtitle="Tell us about your role with the team"
      isNextStepDisabled={!isStepComplete}
      nextStep={TEAM_ONBOARDING_STEPS.SELECT_GOALS}
      isNextStepShown={isStepComplete}
      isPrevStepShown={false}
    >
      <RadioButtons options={options} onChange={handleChange} fieldsPerRow={1} inputClassName="my-6" labelClassName="text-black" value={teamRole} />
      <If condition={teamRole === TEAM_ONBOARDING_OPTIONS.teamRole.OTHER.key}>
        <div className="flex flex-col">
          <label htmlFor="teamRoleOther" className="font-bold text-black">
            Please specify
          </label>
          <TextArea id="teamRoleOther" onChange={(e, { value }) => setTeamRoleOther(value)} defaultValue={teamRoleOther} value={teamRoleOther} size={{ rows: 2 }} />
        </div>
      </If>
    </TeamOnboardingStep>
  );
}

export default SelectRoleStep;
