import React from 'react';
import ReactMarkdown from 'react-markdown';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary';
import Section from './Section';

function ImageSection({ src, highlight, alt, caption, noX = false, smallBorder = false }) {
  if (!src) {
    return null;
  }

  const imageClasses = 'mx-auto max-w-full';
  const borderClasses = smallBorder ? 'p-1' : 'p-4 md:p-8';

  return (
    <Section highlight={highlight} noX={noX}>
      <figure className={`flex flex-col justify-center items-center bg-gray-200 rounded-sm ${borderClasses}`}>
        <ErrorBoundary FallbackComponent={<img className={imageClasses} src={src} alt={alt} />}>
          <Zoom openText="Click to enlarge image" closeText="Click to reset image" zoomMargin={100}>
            <img className={imageClasses} src={src} alt={alt} />
          </Zoom>
        </ErrorBoundary>
        {!!caption && (
          <figcaption>
            <ReactMarkdown className="mt-1 -mb-4 text-sm text-center text-gray-700 has-markdown-inside">{caption}</ReactMarkdown>
          </figcaption>
        )}
      </figure>
    </Section>
  );
}

export default ImageSection;
