import { useEffect } from 'react';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';

function AuthRedirects() {
  const location = useLocation() || window.location;
  const navigate = useNavigate();
  const queryParams = queryString.parse(location.search);

  useEffect(() => {
    switch (queryParams.mode) {
      case 'verifyEmail':
        navigate(`/login/${location.search}`);
        break;
      case 'resetPassword':
        navigate(`/reset-password/${location.search}`);
        break;
      case 'signIn':
        navigate(`/email-login/${location.search}`);
        break;
      default:
        navigate(`/login/${location.search}`);
        break;
    }
  }, [queryParams]);

  // Returns nothing. Only used for routing user based on query params
  return null;
}

export default AuthRedirects;
