import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';
import { useCustomPathsContext } from '../../providers/CustomPathsProvider';
import Container from '../../components/Container/Container';
import AddLink from '../../components/AddLink/AddLink';
import Icon from '../../components/Icon/Icon';
import Divider from '../../components/Divider/Divider';
import Label from '../../components/Label/Label';
import Loading from '../../components/Loading/Loading';
import FormatUtil from '../../utils/formatUtil';
import CustomPathsEnrollmentCta from '../../components/CustomPaths/CustomPathsEnrollmentCta';

function PathRow({ id, name, type, index, duration }) {
  const durationText = FormatUtil.formatTime(duration, 'hm');
  return (
    <div id={`path-row-${id}`} className="flex flex-col justify-between items-start mb-2 sm:flex-row sm:items-center">
      <div id={`path-row-${id}-left-items`} className="flex gap-4 items-center sm:max-w-[80%]">
        <span className="index">{index + 1}.</span>
        <p className="mb-0 text-lg font-medium pt">{name}</p>
        <Label labelClasses="py-1 px-[7.5px]" basic>
          {type}
        </Label>
      </div>
      <div id={`path-row-${id}-left-items`} className="mt-4 sm:mt-0">
        <p className="text-sm font-medium">{durationText}</p>
      </div>
    </div>
  );
}

function PathItems({ items }) {
  if (items?.length < 1) return <p>Path does not have any items</p>;

  const totalDurationInSeconds = items.reduce((accumulator, item) => {
    return accumulator + item.duration_seconds;
  }, 0);

  const formattedTotalDuration = FormatUtil.formatTime(totalDurationInSeconds, 'hm');
  return (
    <>
      {items.map((item, index) => {
        const { id, title, type, duration_seconds } = item;
        return <PathRow key={id} id={id} name={title} type={type} index={index} duration={duration_seconds} />;
      })}
      <p className="mt-8 text-lg font-black">
        Total Time: <span className="pl-2 font-normal text-gray-600 text-md">{formattedTotalDuration}</span>
      </p>
    </>
  );
}

function CustomPathsDetails() {
  const params = useParams();
  const { teamId, pathId } = params;
  const { isLoading, fetchCustomPathDetails, activePathData, fetchEnrolledCustomPaths } = useCustomPathsContext();
  useEffect(() => {
    fetchCustomPathDetails(teamId, pathId);
    fetchEnrolledCustomPaths();
  }, [pathId]);

  const { name = '', description = '', contents = [] } = activePathData;
  if (isLoading) return <Loading />;
  const dividerLabelClasses = 'text-lg font-black';
  return (
    <Container size="md">
      <Fade triggerOnce>
        <div id="back-button-container" className="flex mt-8 text-gray-500 hover:text-gray-600">
          <Icon name="chevron-left" className="w-4 h-4" />
          <AddLink to="/custom-paths/all">
            <p className="text-xs underline hover:cursor-pointer">Custom Paths</p>
          </AddLink>
        </div>
        <div className="flex flex-col justify-between items-start md:flex-row md:items-center">
          <h1 className="my-8 text-5xl font-black">Path Details</h1>
          <CustomPathsEnrollmentCta item={activePathData} />
        </div>
        <Divider horizontal className="mt-24 mb-8">
          <p className={dividerLabelClasses}>Details</p>
        </Divider>
        <div className="flex gap-4 items-center">
          <div className="max-w-[70%] md:max-w-[50%]">
            <h2 className="my-4 text-lg font-bold">{name}</h2>
            <p className="text-sm font-normal text-gray-60">{description}</p>
          </div>
          <Label labelClasses="py-[7.5px] px-2.5" basic>
            <p>Custom Path</p>
          </Label>
        </div>
        <Divider horizontal className="my-8">
          <p className={dividerLabelClasses}> Path Items </p>
        </Divider>
        <PathItems items={contents} />
      </Fade>
    </Container>
  );
}
export default CustomPathsDetails;
