import React from 'react';
import OrderSummaryRow from './OrderSummaryRow';

/**
 * Discount row for order summary
 * Shows code and formatted discount amount
 * @param {number} discountAmount - Discount amount - Replaces recurring_totals
 * @param {string} code - Discount code
 * @param {string} rowClass - Class to apply to row
 * @returns {React.ReactNode} - Discount row
 */
function OrderSummaryDiscountRow({ discountAmount, code, rowClass }) {
  if (!discountAmount) {
    return null;
  }
  const title = `Savings Discount (${code || 'PROMO CODE'})`;
  return <OrderSummaryRow title={title} value={`-${discountAmount}`} className={rowClass} />;
}

export default OrderSummaryDiscountRow;
