import React from 'react';

function Shield({ classes, accessibilityProps = { role: 'none', focusable: false, 'aria-hidden': true } }) {
  const className = classes || 'w-6 h-6';
  // Splitting the path into two parts since the lines are too long otherwise
  const path1 = 'M12 2.94397C14.3567 5.05858 17.4561 6.15189 20.618 5.98397C20.867 6.94797 21 7.95797 21 8.99997C21 14.592 17.176 19.29 12 20.622C6.824 19.29 3 14.591 3';
  const path2 = '8.99997C2.99918 7.98252 3.12754 6.96908 3.382 5.98397C6.5439 6.15189 9.64327 5.05858 12 2.94397Z';
  const path = `${path1} ${path2}`;
  return (
    <svg className={className} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...accessibilityProps}>
      <path d={path} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default Shield;
