import React, { useState } from 'react';
import { observer, inject } from 'mobx-react';
import moment from 'moment';
import ContentUpdateSummary from './ContentUpdateSummary';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import Header from '../Header/Header';
import ComingSoonButton from '../BrowsePromo/ComingSoonButton';
import ContentUpdateLabel from './ContentUpdateLabel';
import './content-updates.css';
import Tooltip from '../Tooltip/Tooltip';
import NotificationUtil from '../../utils/notificationUtil';

function SwitchVersionActions({ archived, switchView, toggleModal }) {
  return (
    <div className="text-center sm:flex">
      {!archived && (
        <div className="w-full sm:w-1/2">
          <Button
            color="gray"
            onClick={(e) => {
              e.stopPropagation();
              toggleModal();
            }}
          >
            Stay in Current Version
          </Button>
        </div>
      )}
      <div className={!archived ? 'w-full sm:w-1/2' : 'w-full'}>
        <Button
          color="pink"
          onClick={(e) => {
            e.stopPropagation();
            switchView('confirm');
          }}
        >
          Switch to New Version
        </Button>
      </div>
    </div>
  );
}

function ModalActions({ switchVersion, isComingSoon, toggleModal, switchView, replacementData, contentTitle, archived }) {
  if (isComingSoon) {
    const newContentId = replacementData?.new_content_description_id ? replacementData.new_content_description_id : null;
    return (
      <div className="px-2 pb-4 mt-4 text-center">
        <ComingSoonButton contentDescriptionId={newContentId} title={contentTitle} instructionsTextClass="text-xs" />
      </div>
    );
  }
  return (
    <div className="modal-close">
      {switchVersion ? (
        <SwitchVersionActions archived={archived} switchView={switchView} toggleModal={toggleModal} />
      ) : (
        <Button
          color="gray"
          onClick={(e) => {
            e.stopPropagation();
            toggleModal();
          }}
        >
          Got it!
        </Button>
      )}
    </div>
  );
}

const ContentUpdateFlag = inject(
  'notificationsStore',
  'commonStore'
)(
  observer(
    ({
      notificationsStore,
      commonStore,
      data,
      progress,
      hasUpdated,
      switchToNewContentVersion,
      flagText,
      omitSummaryModal,
      popupText,
      showAsLabel,
      archived,
      summaryTitle,
      contentTitle,
    }) => {
      const [modalVisible, setModalVisible] = useState(false);
      const [view, setView] = useState('summary');
      const { contentReplacement, contentReplacementProgress } = notificationsStore;

      const toggleModal = () => {
        setModalVisible(!modalVisible);
        setView('summary');
      };

      const getUpdateDetails = (e, id) => {
        // Prevent bug where this auto submits dynamic form on click
        e.stopPropagation();
        e.preventDefault();
        notificationsStore.getContentReplacement(id, !!hasUpdated);
        toggleModal();
      };

      const switchToNewVersion = () => {
        if (!contentReplacement?.data?.old_content_description_id || !contentReplacement?.data?.new_content_description_id) {
          return commonStore.triggerToast('error', { content: 'Something went wrong. Unable to switch content versions.' });
        }
        return switchToNewContentVersion(
          contentReplacement.data.old_content_description_id,
          contentReplacement.data.new_content_description_id,
          contentReplacement.data.newContentType
        ).then(() => {
          toggleModal();
        });
      };

      if (!data && !flagText) {
        return null;
      }

      const isNewContentComingSoon = NotificationUtil.checkContentUpdateComingSoon(data);
      const defaultFlagContent = flagText || `New Version Released ${moment(data.scheduled_at).format('M/D/YYYY')}`;
      const flagContent = isNewContentComingSoon ? 'New Version Coming Soon' : defaultFlagContent;
      const labelClasses = omitSummaryModal ? 'content-update-flag no-click' : 'content-update-flag';
      const tabClasses = omitSummaryModal ? 'content-update-flag tab no-click' : 'content-update-flag tab';
      const clickAction = !omitSummaryModal && ((e) => getUpdateDetails(e, data.id));

      return (
        <>
          <Tooltip
            content={popupText}
            disabled={!popupText}
            omitTabIndex
            triggerContent={
              showAsLabel ? (
                <button onClick={clickAction}>
                  <ContentUpdateLabel basic className={labelClasses} labelText={flagContent} />
                </button>
              ) : (
                <button className={tabClasses} onClick={clickAction}>
                  {flagContent}
                </button>
              )
            }
          />
          <Modal open={modalVisible} toggle={toggleModal} className="content-update-modal" position="center" paddingBottom="pb-4" ariaLabelledBy="content-update">
            {view === 'summary' ? (
              <>
                <ContentUpdateSummary
                  data={contentReplacement.data}
                  loading={contentReplacement.loading}
                  error={contentReplacement.error}
                  summaryTitle={summaryTitle}
                  contentTitle={contentTitle}
                  progress={progress}
                  contentReplacementProgress={contentReplacementProgress}
                  isNewContentComingSoon={isNewContentComingSoon}
                  archived={archived}
                />
                <ModalActions
                  switchVersion={switchToNewContentVersion}
                  isComingSoon={isNewContentComingSoon}
                  replacementData={contentReplacement.data}
                  toggleModal={toggleModal}
                  switchView={(newView) => setView(newView)}
                  contentTitle={contentTitle}
                  archived={archived}
                />
              </>
            ) : (
              <div className="p-4 border-none confirm-content-switch">
                <Header className="mb-4 text-xl font-bold" as="h3">
                  You have chosen to Switch into the New Version
                </Header>
                <p>You&apos;re keeping up with industry trends!</p>
                <p>Please note you will be unenrolled from the older version and will no longer have access.</p>
                <p>Continue to Switch into the New Version?</p>
                <div className="text-right buttons-container">
                  <Button
                    color="gray"
                    className="mr-4"
                    onClick={() => {
                      setView('summary');
                    }}
                  >
                    No
                  </Button>
                  <Button
                    color="pink"
                    onClick={() => {
                      switchToNewVersion();
                    }}
                  >
                    Yes
                  </Button>
                </div>
              </div>
            )}
          </Modal>
        </>
      );
    }
  )
);

export default ContentUpdateFlag;
