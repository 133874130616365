import { useState } from 'react';
import { isEmpty } from 'lodash';
import queryString from 'query-string';
import Agents from '../agents/agents';
import { formatPath } from '../utils/pathsUtil';

/**
 * hook for loading content collection paths.
 * Used for TACs and cybrary select paths
 * @returns { loading, error, paths, fetchPaths }
 */
const useBrowsePaths = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [paths, setPaths] = useState(null);
  const [pathType, setPathType] = useState('threatActors');

  // Uses the Agent of the passed pathType to load in the required
  const fetchPaths = async (customPathType = 'threatActors', filter = null) => {
    try {
      setLoading(true);
      setPaths(null);
      setPathType(customPathType);
      const query = !isEmpty(filter) ? queryString.stringify(filter) : '';
      const allPaths = await Agents[customPathType].getPaths(query);
      const data = allPaths && allPaths.length ? allPaths.map((path) => formatPath(path)) : null;
      setPaths(data);
      setError(null);
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };

  return { loading, error, paths, pathType, fetchPaths };
};

export default useBrowsePaths;
