import React from 'react';
import { twMerge } from 'tailwind-merge';
import { useForumsSidebar } from '../../providers/ForumsSidebarProvider';
import { BASE_BUTTON_STYLES } from '../../constants';
import SearchInput from '../Search/SearchInput';
import { trackSnowplowEvent } from '../../utils/snowplowUtil';
import If from '../If/If';
import TopForumsTopicRow from './Rows/TopForumsTopicRow';
import NoForumTopics from './NoForumTopics';
import AddLink from '../AddLink/AddLink';

// Tabs to display in the continue learning block
const TABS = [
  {
    key: 'top',
    tabTitle: 'Top Discussions',
  },
  {
    key: 'new',
    tabTitle: 'New Discussions',
  },
];

// Active and Inactive classes for tab headers
const ACTIVE_CLASSES = ' border-cyb-pink-500 font-semibold text-black';
const INACTIVE_CLASSES = ' border-slate-200 cursor-pointer';
function ForumsSidebarHeader({ activeTab, setActiveTab }) {
  return (
    <div className="block items-center w-full align-middle lg:flex">
      <nav className="flex grow mx-8 space-x-8 lg:mx-16 lg:space-x-16">
        {TABS.map(({ key, tabTitle }) => (
          <button
            key={key}
            aria-pressed={activeTab === key}
            aria-label={`View ${tabTitle} content`}
            className={`inline-block text-sm px-1 w-full mb-4 border-b-4 pb-2 ${activeTab === key ? ACTIVE_CLASSES : INACTIVE_CLASSES}`}
            onClick={() => setActiveTab(key)}
          >
            {tabTitle}
          </button>
        ))}
      </nav>
    </div>
  );
}

function ForumsSidebar() {
  const { isOpen, close, newTopics, topTopics, activeCategory } = useForumsSidebar();
  const [activeTab, setActiveTab] = React.useState(TABS[0].key);
  const [searchValue, setSearchValue] = React.useState('');

  const handleSearchKeyPress = (e) => {
    // Check to see if we pressed enter key
    const key = e.which || e.keyCode;
    if (key === 13) {
      // fire Snowplow event when user searches using the forums sidebar search box
      trackSnowplowEvent({
        category: 'ForumsSidebarSearch',
        action: 'search',
        label: searchValue, // What the user searched for (e.g. "python")
        property: 'Searched using Forums Sidebar search box', // Description of the action
      });
      // Clear the input
      setSearchValue('');
      e.target.blur();
      // Open forums search results page in new tab
      window.open(`${process.env.REACT_APP_FORUMS_URL}/search?q=${searchValue}`, '_blank');
    }
  };

  const activeCategoryLink = activeCategory ? `${process.env.REACT_APP_FORUMS_URL}/c/${activeCategory}` : process.env.REACT_APP_FORUMS_URL;

  return (
    <>
      <If condition={isOpen}>
        <div className="fixed top-0 right-0 z-[10000] w-screen h-screen bg-gray-800/50 duration-0" onClick={close} role="button" tabIndex={0} />
      </If>
      <div
        className={`fixed ${
          isOpen ? 'translate-x-0' : 'translate-x-[400%]'
        } transition-transform duration-300 ease-in-out z-[10000] h-screen w-4/5 md:w-1/2 xl:w-1/3 top-0 right-0`}
      >
        <div className="fixed top-0 left-0 w-full h-screen bg-white shadow-[0_0px_30px_-5px_rgba(0,0,0,0.9)] ">
          <div className="flex flex-col justify-between h-full">
            <h2 className="p-4 text-xl font-bold ">Forum Discussions</h2>
            <div className="mb-4 border-b-xs border-gray-400" />
            <ForumsSidebarHeader activeTab={activeTab} setActiveTab={setActiveTab} />
            <SearchInput value={searchValue} onChange={(e) => setSearchValue(e.target.value)} placeholder="Search Forums" onKeyPress={handleSearchKeyPress} width="m-4" />
            <div className="overflow-y-auto grow">
              <If condition={activeTab === 'top'}>
                <div className="p-4 text-gray-600">
                  <If condition={!!topTopics?.length}>
                    <div className="flex flex-wrap justify-between mb-2">
                      {/** Clamp topics to 10 max */}
                      {topTopics?.slice(0, 10).map((topic) => (
                        <TopForumsTopicRow key={topic.id} topic={topic} className="w-full" />
                      ))}
                    </div>
                  </If>
                  <If condition={!topTopics?.length}>
                    <NoForumTopics categoryLink={activeCategoryLink} />
                  </If>
                </div>
              </If>
              <If condition={activeTab === 'new'}>
                <div className="p-4 text-gray-600">
                  <If condition={!!newTopics?.length}>
                    <div className="flex flex-wrap justify-between mb-2">
                      {/** Clamp topics to 10 max */}
                      {newTopics?.slice(0, 10).map((topic) => (
                        <TopForumsTopicRow key={topic.id} topic={topic} className="w-full" />
                      ))}
                    </div>
                  </If>
                  <If condition={!newTopics?.length}>
                    <NoForumTopics categoryLink={activeCategoryLink} />
                  </If>
                </div>
              </If>
            </div>
            <div className="flex justify-center items-center my-2 w-full">
              <AddLink className={twMerge(BASE_BUTTON_STYLES, 'w-4/5')} to={activeCategoryLink} target="_blank">
                Ask the Community
              </AddLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForumsSidebar;
