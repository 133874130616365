import React from 'react';
import { twMerge } from 'tailwind-merge';
import RadioButtons from '../../../FormFields/RadioButtons';
import Input from '../../../FormFields/Input';

function AssessmentTextField({ name, onChange, value, hint }) {
  const helpTextId = `${name}_text`;

  return (
    <>
      <Input name={name} type="text" onChange={onChange} value={value} />
      {!!hint && (
        <p id={helpTextId} className="text-xs italic text-gray-600">
          Hint: {hint}
        </p>
      )}
    </>
  );
}

function AssessmentFieldSwitch({ name, question, onChange, value }) {
  const { type, answer, hint } = question;

  const options = answer?.options?.map(({ text }) => {
    return {
      value: text,
      label: text,
    };
  });

  switch (type) {
    case 'CHOOSE_MULTIPLE':
    case 'BINARY':
      return <RadioButtons name={name} options={options} value={value} onChange={onChange} />;

    case 'VALUE':
      return <AssessmentTextField name={name} onChange={onChange} value={value} hint={hint} />;

    default:
      return null;
  }
}

function AssessmentQuestion({ question, name, value, onUpdate, next, previous, num, total, nextSubmitAction, className = '', nextShouldSubmit = false }) {
  if (!question) {
    return null;
  }

  const onChange = (e, changedValue) => {
    // The event wont be provided, but if it was, this is how we'd use it
    if (e) {
      e.preventDefault();
    }
    const { value: newValue } = changedValue;
    onUpdate(name, newValue);
  };

  const label = question.question;

  const backBtnClasses = `cursor-pointer bg-gray-300 rounded-md font-semibold py-3 px-10 hover:bg-gray-400 text-gray-800 text-center text-base`;
  const notAllowedClasses = num === 1 ? 'cursor-not-allowed' : '';
  const previousClasses = twMerge(backBtnClasses, notAllowedClasses);
  const btnClasses = 'cursor-pointer bg-cyb-pink-500 rounded-md font-semibold py-3 px-10 hover:bg-pink-600 min-w-[200px] text-white text-center text-base';
  const wrapperClasses = twMerge('mx-auto w-full max-w-2xl text-gray-900 bg-white', className);
  return (
    <div className={wrapperClasses}>
      <div className="py-10 px-8" key={`question-${num}`}>
        {!!total && (
          <p className="mb-8 text-lg font-black text-center">
            Question {num} of {total}
          </p>
        )}
        <div className="pb-2">
          <label htmlFor={name} className="text-base font-bold">
            {num}. {label}
          </label>
        </div>
        <AssessmentFieldSwitch name={name} question={question} onChange={onChange} value={value} />
        {/* <RadioButtons name={name} options={options} value={value} onChange={onChange} /> */}
        {!!next && !!previous && (
          <div className="flex justify-between mt-10">
            <button type="button" onClick={previous} className={previousClasses}>
              Previous
            </button>
            {!nextShouldSubmit && (
              <button type="button" onClick={next} className={btnClasses}>
                Next
              </button>
            )}
            {nextShouldSubmit && (
              <button type="button" onClick={nextSubmitAction} className={btnClasses}>
                Submit
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default AssessmentQuestion;
