import React, { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

/**
 * Horizontal rule component
 * @returns {JSX.Element} - the horizontal rule
 */
function HorizontalRule({ className = '' }) {
  const classes = useMemo(() => twMerge('mb-4 w-full border-b border-gray-600', className), [className]);
  return <div className={classes} />;
}

export default HorizontalRule;
