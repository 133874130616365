import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Loading from '../../../Loading/Loading';
import { ONBOARDING_PACKAGE_OPTIONS, RECOMMENDATIONS_OPTIONS, useUserOnboarding } from '../../../../providers/UserOnboardingProvider';
import UserOnboardingStep from '../../UserOnboardingStep';
import { DEFAULT_COOKIE_SETTINGS } from '../../../../constants';

function FinishedStep() {
  const { isSubmitting, cipGoal, savedFormFields, saveUserOnboardingData, exitUserOnboarding, packageSelected } = useUserOnboarding();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      await saveUserOnboardingData(savedFormFields);
      await exitUserOnboarding();

      // Capture where to put the user after we're done
      const selectedGoal = Object.values(RECOMMENDATIONS_OPTIONS).find((goal) => goal.value === cipGoal);

      // See if the user has a package selected
      if ([ONBOARDING_PACKAGE_OPTIONS.CIP.key, ONBOARDING_PACKAGE_OPTIONS.TEAMS.key].includes(packageSelected)) {
        // If they do, let's set a cookie for later storing their selectedGoal?.navigateTo and navigate them to the package navigation URL
        Cookies.set('cyb-onboarding-redirect', selectedGoal?.navigateTo || '/', DEFAULT_COOKIE_SETTINGS);
        const selectedPackage = Object.values(ONBOARDING_PACKAGE_OPTIONS).find((cybPackage) => cybPackage.key === packageSelected);
        // Handle internal / external navigation differently
        if (selectedPackage?.navigateTo.startsWith('http')) {
          window.location.href = selectedPackage?.navigateTo;
        } else {
          navigate(selectedPackage?.navigateTo || '/?error=package-not-found');
        }
      } else {
        // If they don't have a package selected, let's navigate them to the goal navigation URL
        navigate(selectedGoal?.navigateTo || '/');
      }
    })();
  }, []);

  const message = useMemo(() => (isSubmitting ? 'Saving your selections...' : 'Loading your content...'), [isSubmitting]);

  return (
    <UserOnboardingStep title="All finished!">
      <div className="flex flex-col justify-center items-center sm:flex-row sm:items-stretch sm:space-x-16">
        <Loading className="mt-8 w-16 h-16 border-8" message={message} />
      </div>
    </UserOnboardingStep>
  );
}

export default FinishedStep;
