import React from 'react';
import moment from 'moment';
import Agents from '../../agents/agents';
import Table from '../Table/Table';
import AdminEmailButtons from './CybraryAdminEmailButtons';
import WidgetContainer from '../Container/WidgetContainer';
import Header from '../Header/Header';

function CybraryAdminFirebase({ userEmails, commonStore }) {
  return (
    <WidgetContainer omitBorder omitBackground>
      <div className="flex justify-between items-center">
        <Header as="h2">Firebase</Header>
      </div>
      <div>
        <Header as="h3">User Emails</Header>
        <Table className="my-4">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>Verified</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>Created</Table.HeaderCell>
              <Table.HeaderCell>Last Login</Table.HeaderCell>
              <Table.HeaderCell>Password Changed</Table.HeaderCell>
              <Table.HeaderCell>Action</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          {userEmails.map((data) => {
            if (data.firebaseData) {
              const firebase = data.firebaseData;
              return (
                <Table.Body key={data.id}>
                  <Table.Row>
                    <Table.Cell>{data.email}</Table.Cell>
                    <Table.Cell>
                      <span className={`p-2 rounded text-sm border ${data.verified ? 'text-green-500 border border-green-500' : 'text-red-500 border-red-500'}`}>
                        {data.verified ? 'Yes' : 'No'}
                      </span>
                    </Table.Cell>
                    <Table.Cell>{firebase.disabled ? 'Inactive' : 'Active'}</Table.Cell>
                    <Table.Cell>{firebase.metadata.createdAt ? moment(firebase.metadata.createdAt).format('M/D/YYYY') : null}</Table.Cell>
                    <Table.Cell>{firebase.metadata.lastLoginAt ? moment(firebase.metadata.lastLoginAt).format('M/D/YYYY') : null}</Table.Cell>
                    <Table.Cell>
                      {firebase.metadata.passwordUpdatedAt && firebase.metadata.passwordUpdatedAt.date
                        ? moment(firebase.metadata.passwordUpdatedAt.date).format('M/D/YYYY')
                        : 'Never'}
                    </Table.Cell>
                    <Table.Cell>
                      <AdminEmailButtons text="Password Reset" email={data.email} commonStore={commonStore} sendEmail={Agents.authGoogle.passwordResetEmail} />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              );
            }
            return null;
          })}
        </Table>
        <Header as="h3">Providers</Header>
        <Table className="my-4">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>Display Name</Table.HeaderCell>
              <Table.HeaderCell>Provider Name</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          {userEmails.map((data) => {
            if (data.firebaseData && data.firebaseData.providerData) {
              return data.firebaseData.providerData.map((providerData) => (
                <Table.Body key={`${providerData.providerId}.${providerData.uid}`}>
                  <Table.Row>
                    <Table.Cell>{providerData.email}</Table.Cell>
                    <Table.Cell>{providerData.displayName}</Table.Cell>
                    <Table.Cell>{providerData.providerId}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              ));
            }
            return null;
          })}
        </Table>
      </div>
    </WidgetContainer>
  );
}

export default CybraryAdminFirebase;
